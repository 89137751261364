import React from 'react';
import { useTranslation } from 'react-i18next';
import shallow from 'zustand/shallow';
import { path } from 'ramda';

import { JobApplicationModals } from '../../JobApplications/JobApplicationModals';
import { useBanner } from '../../Navigation';
import { SignInModal } from '../../SignIn';
import { RegisterModal } from '../../Register';
import { CANDIDATE_GROUP } from '../../../constants';
import { LightboxedMainImage } from '../styled/MainImageWeb.styled';
import { useModalStore, MODAL_TYPE } from '../stores/modals';
import { handleMutation } from '../../../utils';
import { useMutateCareerPageLocationChatMutation } from '../../../graphql/generated';
import {
  FOHBannerType,
  FOHLightBoxModal,
  FOHMessageLocationModal
} from '../../../components';
import { AuthFlowSelectionForSigninOrSignupModal } from '../../GoogleSSOAuth/components';
import { useNavigation } from '../../../utils/navigation';

export const CareerPageScreenModals = ({
  currentMainImage,
  dropdownOptions,
  paginationLogic,
  refetchMe
}) => {
  const { t } = useTranslation('JobApplicationsFeature');
  const { setBanner } = useBanner();
  const { location } = useNavigation();

  const currentPath = path(['pathname'], location);

  const {
    appliedPositionSlug,
    closeModal,
    handleMessageSent,
    handleSignup,
    messageContent,
    messageReceipientId,
    modalState,
    openModal,
    setMessageContent,
    setMessageReceipientId
  } = useModalStore(
    state => ({
      appliedPositionSlug: state.appliedPositionSlug,
      closeModal: state.closeModal,
      handleMessageSent: state.handleMessageSent,
      handleSignup: state.handleSignup,
      messageContent: state.messageContent,
      messageReceipientId: state.messageReceipientId,
      modalState: state.modalState,
      openModal: state.openModal,
      setMessageContent: state.setMessageContent,
      setMessageReceipientId: state.setMessageReceipientId
    }),
    shallow
  );

  const [mutateCareerPageLocationChat, { loading: isChatMutationLoading }] =
    useMutateCareerPageLocationChatMutation({
      onCompleted: _data => {
        handleMessageSent();
        setBanner({
          bannerMessage: 'Message successfully sent',
          bannerType: FOHBannerType.SUCCESS
        });
      }
    });

  const handleMessageSubmit = async () =>
    await handleMutation(
      mutateCareerPageLocationChat({
        variables: {
          locationId: messageReceipientId,
          message: messageContent
        }
      })
    );

  const handleSignInModalSignIn = async () => {
    closeModal(MODAL_TYPE.signIn);
    await refetchMe();
  };

  return (
    <>
      <FOHLightBoxModal
        isLightBoxOpen={modalState.lightBox}
        onCloseLightBox={() => closeModal(MODAL_TYPE.lightBox)}
        paginationLogic={paginationLogic}
      >
        <LightboxedMainImage
          isCurrentImgVideo={
            currentMainImage?.getUploadFileExtension === '.mp4'
          }
          src={currentMainImage?.uploadFile}
        />
      </FOHLightBoxModal>

      <FOHMessageLocationModal
        handleClose={() => closeModal(MODAL_TYPE.message)}
        handleContentChange={setMessageContent}
        handleReceipientChange={setMessageReceipientId}
        handleSubmit={handleMessageSubmit}
        isLoading={isChatMutationLoading}
        isOpen={modalState.message}
        locationOptions={dropdownOptions}
        messageContent={messageContent}
        messageReceipientId={messageReceipientId}
      />

      <AuthFlowSelectionForSigninOrSignupModal
        currentPath={currentPath}
        setOpenRegister={handleSignup}
        setOpenSignIn={() => {
          closeModal(MODAL_TYPE.register);
          openModal(MODAL_TYPE.signIn);
        }}
      />

      <SignInModal
        closeModal={() => closeModal(MODAL_TYPE.signIn)}
        detailLabel={t('loginToComplete')}
        history={history}
        onSignIn={handleSignInModalSignIn}
        openSignIn={modalState.signIn}
        title={t('applyWithYourProfile')}
      />

      <RegisterModal
        close={() => closeModal(MODAL_TYPE.register)}
        group={CANDIDATE_GROUP}
        history={history}
        key={CANDIDATE_GROUP}
        onboarding={false}
        open={modalState.register}
        signUpFlow="job-application"
      />

      <JobApplicationModals
        closeModal={() => closeModal(MODAL_TYPE.apply)}
        isModalOpen={modalState.apply}
        slug={appliedPositionSlug}
      />
    </>
  );
};
