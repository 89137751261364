import React from 'react';

import { length, defaultTo } from 'ramda';

import {
  FOHHeaderText,
  Screen,
  FOHDetailText,
  FOHButton,
  FOHInverseButton,
  FOHLabel,
  FOHCircleLoader,
  FOHSpaceSmall,
  FOHSpaceLarge,
  FOHDivider,
  FOHSpaceSmallest,
  FOHColors,
  FOHView,
  FOHScrollView
} from '../../ingredients';
import {
  FOHSearchBar,
  FOHSearchBarProps,
  FOHLocationSelectCellProps,
  FOHLocationSelectCell,
  FOHResultCount
} from '../../recipes';
import { FOHLocationProps } from '../../typings';

export interface FOHEmployerLocationSearchProps
  extends FOHSearchBarProps,
    FOHLocationSelectCellProps {
  searchButtonTitle: string;
  onPressSearch: () => void;
  title: string;
  description: string;
  largeHeader?: boolean;
  loading?: boolean;
  disableNext?: boolean;
  next: () => void;
  nextLabel: string;
  locations?: Array<FOHLocationProps>;
  createLocation: () => void;
  currentSearchTerm?: string;
  createLocationLabel: string;
  cantFindLabel: string;
  restaurantCount: string;
  restaurantCountLabel: string;
}

export const FOHEmployerLocationSearch = (
  props: FOHEmployerLocationSearchProps
) => (
  <Screen>
    <FOHView
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 60,
        width: '100%',
        padding: 16
      }}
    >
      <FOHView style={{ maxWidth: 400 }}>
        <FOHHeaderText style={{ fontSize: props.largeHeader ? 30 : 20 }}>
          {props.title}
        </FOHHeaderText>
        <FOHView style={{ paddingTop: 8 }}>
          <FOHDetailText>{props.description}</FOHDetailText>
          <FOHView style={{ paddingTop: 24 }} />
          <FOHView style={{ flexDirection: 'row', justifyContent: 'center' }}>
            <FOHSearchBar
              style={{
                flex: 8
              }}
              onChangeSearch={props.onChangeSearch}
              searchValue={props.searchValue}
              searchPlaceholder={props.searchPlaceholder}
              onKeyPress={(e: any) => {
                if (e.nativeEvent.key == 'Enter') {
                  props.onPressSearch();
                }
              }}
            />
            <FOHView style={{ paddingLeft: 9 }} />
            <FOHButton
              onPress={props.onPressSearch}
              title={props.searchButtonTitle}
              style={{ flex: 3 }}
            />
          </FOHView>
          <FOHSpaceSmall />
          {!props.loading && !!props.currentSearchTerm && (
            <>
              <FOHSpaceLarge />
              <FOHResultCount
                count={props.restaurantCount}
                countLabel={props.restaurantCountLabel}
              />
              <FOHSpaceSmall />
              <FOHDivider />
              <FOHSpaceSmallest />
            </>
          )}
          {props.loading && <FOHCircleLoader />}
          <FOHScrollView
            key={'location-scrollview'}
            style={{
              maxHeight: length(defaultTo([], props.locations)) > 0 ? 400 : 0
            }}
          >
            {(props.locations || []).map(location => (
              <FOHLocationSelectCell
                {...location}
                key={`location-cell-${location.id}`}
                select={props.select}
                selected={props.selected}
                selectLabel={props.selectLabel}
                followerCountLabel={props.followerCountLabel}
                singleFollowerCountLabel={props.singleFollowerCountLabel}
              />
            ))}
          </FOHScrollView>
          <FOHView style={{ paddingTop: 24 }} />
          <FOHButton
            onPress={props.next}
            disabled={props.disableNext}
            title={props.nextLabel}
            style={{ maxWidth: '100%' }}
          />
          {!!props.currentSearchTerm && (
            <>
              <FOHView style={{ paddingTop: 24 }} />

              <FOHLabel>{props.cantFindLabel}</FOHLabel>
              <FOHView style={{ paddingTop: 8 }} />

              <FOHInverseButton
                color={FOHColors.BLACK}
                opaque={true}
                selected={true}
                textColor={FOHColors.WHITE}
                onPress={props.createLocation}
                title={props.createLocationLabel}
                style={{ maxWidth: '100%' }}
              />
            </>
          )}
        </FOHView>
      </FOHView>
    </FOHView>
  </Screen>
);
