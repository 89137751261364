import React from 'react';

import styled from 'styled-components/native';
import { Image, Text } from 'react-native';

import {
  FOHView,
  FOHHighlightText,
  FOHHeaderH2,
  FOHSpaceSmallest,
  FOHSmallDetailLabel,
  FOHSpace,
  FOHSpaceSmall,
  FOHRequiredMark,
  FOHLabelBold,
  FOHColors,
  FOHQuoteIcon,
  FOHFonts
} from '../../ingredients';

import { FOHProfileImage } from '../navigation/FOHProfileImage';

export interface FOHVideoScreeningFormProps {
  children?: React.ReactNode;
  titleDecorationImage?: string;
  header: string;
  headerHighlight: string;
  candidateName: string;
  detailLabel: string;
  videoLabel: string;
  isMobile: boolean;

  positionLabel: string;
  position: string;
  locationLabel: string;
  locationImage: string;
  locationName: string;
  locationAddress: string;

  customNoteLabel?: string;
  customNote?: string;

  hiringManagerProfileImage?: string;
  hiringManagerLabel?: string;
  hiringManagerName?: string;
}

export const FOHVideoScreeningForm = (props: FOHVideoScreeningFormProps) => {
  const PositionLocationInfo = () => (
    <PositionLocationBlock>
      <FOHView>
        <SmallGreyLabel>{props.positionLabel}</SmallGreyLabel>
        <FOHSpaceSmallest />
        <Label style={{ color: FOHColors.NARO_GREY }}>{props.position}</Label>
      </FOHView>
      <FOHSpace />
      <FOHView>
        <SmallGreyLabel>{props.locationLabel}</SmallGreyLabel>
        <FOHSpaceSmallest />
        <FOHView
          style={{
            flexDirection: 'row',
            flexWrap: 'wrap',
            paddingTop: 6,
            paddingBottom: 6
          }}
        >
          {props.locationImage ? (
            <>
              <FOHImage
                source={{ uri: props.locationImage }}
                style={{ width: 40, height: 40, borderRadius: 3 }}
              />
              <FOHView style={{ paddingLeft: 6 }} />
            </>
          ) : null}
          <FOHView style={{ flex: 1 }}>
            <FOHName>{props.locationName}</FOHName>
            <SmallGreyLabel>{props.locationAddress}</SmallGreyLabel>
          </FOHView>
        </FOHView>
      </FOHView>
    </PositionLocationBlock>
  );

  const NoteBlock = () => (
    <FOHView>
      <FOHView style={{ flexDirection: 'row', alignItems: 'center' }}>
        <FOHQuoteIcon style={{ width: 16, height: 16 }} />
        <FOHSpace />
        <SmallGreyLabel
          style={{
            fontSize: 16,
            lineHeight: 24,
            color: FOHColors.PACIFIC_BLUE
          }}
        >
          {props.customNoteLabel}
        </SmallGreyLabel>
        <FOHSpace />
      </FOHView>
      <Label
        style={{
          textAlign: 'left',
          fontSize: 14,
          lineHeight: 22,
          fontWeight: 'normal',
          color: FOHColors.NARO_GREY
        }}
      >
        {props.customNote}
      </Label>
    </FOHView>
  );

  const HiringManagerProfileBlock = () => (
    <Cell>
      <FOHProfileImage
        photo={props.hiringManagerProfileImage}
        style={{ width: 40, height: 40, borderRadius: 22 }}
      />
      <FOHSpaceSmall />
      <FOHView style={{ flex: 1 }}>
        <SmallGreyLabel style={{ textTransform: 'none' }}>
          {props.hiringManagerLabel}
        </SmallGreyLabel>
        <FOHName>{props.hiringManagerName}</FOHName>
      </FOHView>
    </Cell>
  );

  return (
    <FOHView style={{ maxWidth: 716 }}>
      <FOHView style={{ paddingHorizontal: 32 }}>
        <FOHView style={{ flexDirection: 'row' }}>
          <FOHImage
            mobile={props.isMobile}
            resizeMode="contain"
            source={{ uri: props.titleDecorationImage }}
          />
          <FOHView style={{ width: '100%', alignItems: 'flex-start' }}>
            <FOHView style={{ maxWidth: props.isMobile ? '70%' : '100%' }}>
              <FOHHighlightText
                labelCmp={FOHHeaderH2}
                emphasizedWord={props.headerHighlight}
                highlightColor={FOHColors.BLUE}
                boldLabelCmp={FOHHeaderH2}
              >
                {props.header}
              </FOHHighlightText>
            </FOHView>

            <FOHSpace />
            {!props.isMobile && <PositionLocationInfo />}
          </FOHView>
        </FOHView>
        {props.isMobile && <PositionLocationInfo />}
      </FOHView>

      <FOHView
        style={{
          margin: 5,
          padding: 32,
          borderRadius: 24,
          backgroundColor: '#FCFCFC'
        }}
      >
        {props.customNote && <NoteBlock />}
        <FOHSpace />
        <FOHView style={{ flexDirection: 'row', alignItems: 'center' }}>
          <FOHLabelBold>{props.videoLabel}</FOHLabelBold>
          <FOHRequiredMark
            style={{
              alignSelf: 'flex-start',
              marginTop: 1
            }}
          />
        </FOHView>
        <FOHSpaceSmall />
        <FOHView>{props.children}</FOHView>
        <FOHSpace />

        {props.hiringManagerProfileImage && <HiringManagerProfileBlock />}
      </FOHView>
    </FOHView>
  );
};

const FOHImage = styled(Image)<{ mobile?: boolean }>`
  width: ${(p: any) => (p.mobile ? '80px' : '185px')};
  height: ${(p: any) => (p.mobile ? '80px' : '185px')};
  borderwidth: 1;
  border-color: #eeeeee;
`;

const PositionLocationBlock = styled(FOHView)<{ mobile?: boolean }>`
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
  flexwrap: wrap;
  width: 100%;
  max-width: 318px;
`;

const FOHName = styled(FOHSmallDetailLabel)`
  line-height: 16px;
  font-weight: 600;
  color: ${FOHColors.NARO_GREY};
`;

const SmallGreyLabel = styled(FOHSmallDetailLabel)`
  text-transform: uppercase;
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${FOHColors.GREY};
`;

const Label = styled(Text)`
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: ${FOHColors.GREY};
`;

const Cell = styled(FOHView)`
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
