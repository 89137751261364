import React from 'react';

import { useTranslation } from 'react-i18next';
import { prop, path, defaultTo } from 'ramda';

import {
  FOHCandidatePositionDetailsType,
  FOHInterviewMeetingType,
  FOHPositionDetails,
  FOHView
} from '../../components';
import {
  GetCandidateWithApplicantsDocument,
  useGetEmployerDocumentsQuery,
  useMutateInterviewMutation
} from '../../graphql/generated';
import {
  toDateTime,
  unwrapEdgesAt,
  useIsMobile,
  parseFileNameFromS3Url,
  handleMutation
} from '../../utils';
import { FEED, INTERVIEW_SCHEDULE, MORE_POSITIONS } from '../../constants';
import { useNavigation } from '../../utils/navigation';

export const CandidatePositionOverview = props => {
  const {
    lastInterview,
    currentStage,
    isRejected,
    appliedPositionCount,
    employerCandidate,
    setSelectedTab,
    prescreenCompletedAt,
    screeningQuestionnaireCompletedAt,
    screeningVideoCompletedAt
  } = props;

  const { t } = useTranslation('CandidateFeed');
  const { isMobile } = useIsMobile();
  const { navigateTo } = useNavigation();

  const [interviewMutation] = useMutateInterviewMutation();

  const stageType = prop('stageType', currentStage);

  const employerDocumentsQuery = useGetEmployerDocumentsQuery({
    variables: {
      candidate: path(['employerCandidate', 'candidateProfile', 'id'], props)
    }
  });

  const employerDocuments = unwrapEdgesAt(
    0,
    defaultTo(
      [],
      path(['data', 'getEmployerDocuments', 'edges'], employerDocumentsQuery)
    )
  ).map(edge => ({
    sentAt: defaultTo('', toDateTime(prop('startingAt', edge))),
    files: defaultTo(
      [],
      path(['employmentdocumentfileSet', 'edges'], edge)
    ).map(item => path(['node', 'attachment'], item)),
    ...edge
  }));

  const offeredDocument = employerDocuments.find(
    doc => doc.documentType === 'OFFER'
  );
  const hiredDocument = employerDocuments.find(
    doc => doc.documentType === 'HIRED'
  );

  const files = prop('files', offeredDocument);

  const scheduledAt = prop('scheduledFor', lastInterview)
    ? defaultTo('', toDateTime(prop('scheduledFor', lastInterview)))
    : '';

  const interviewer = defaultTo(
    '',
    path(['interviewer', 'user'], lastInterview)
  );
  const interviewerName = interviewer.email
    ? `${interviewer.firstName} ${interviewer.lastName}`
    : t('removedUser');
  const interviewerEmail = interviewer.email;

  const offeredAt = prop('sentAt', offeredDocument);
  const hiredAt = prop('sentAt', hiredDocument);

  const prescreenCompletedOn = prescreenCompletedAt
    ? t('completedprescreenOnLabel', {
        completedOn: prescreenCompletedAt
      })
    : null;

  const screeningQuestionnaireCompletedOn = screeningQuestionnaireCompletedAt
    ? t('completedScreeningOnLabel', {
        completedOn: screeningQuestionnaireCompletedAt
      })
    : null;

  const screeningVideoCompletedOn = screeningVideoCompletedAt
    ? t('completedScreeningVideoOnLabel', {
        completedOn: screeningVideoCompletedAt
      })
    : null;

  const interviewTypeString =
    defaultTo('', prop('interviewType', lastInterview)) ===
    FOHInterviewMeetingType.PHONE
      ? t('InterviewFeature:phoneMeetingLabel')
      : defaultTo('', prop('interviewType', lastInterview)) ===
        FOHInterviewMeetingType.REMOTE
      ? t('InterviewFeature:remoteMeetingLabel')
      : t('InterviewFeature:locationMeetingLabel');

  const interviewPhone = defaultTo('', prop('phoneNumber', lastInterview));
  const interviewUrl = defaultTo('', prop('meetingUrl', lastInterview));

  const isManualMoved =
    stageType === 'INTERVIEWS'
      ? !scheduledAt
      : stageType === 'OFFERED'
      ? !(scheduledAt && offeredAt)
      : stageType === 'HIRED' || stageType === 'REJECTED'
      ? !(scheduledAt && offeredAt && hiredAt)
      : false;

  const employerCandidateNotifications = defaultTo(
    [],
    path(['employercandidateNotifications', 'edges'], employerCandidate)
  )
    .filter(
      notification =>
        path(['node', 'notificationType'], notification) !== 'CHAT'
    )
    .filter(
      notification => path(['node', 'dismissedAt'], notification) === null
    )
    .map(node => ({ ...prop('node', node) }));

  const notificationStatus = path(
    [0, 'status'],
    employerCandidateNotifications
  );
  const notificationType = path(
    [0, 'notificationType'],
    employerCandidateNotifications
  );

  const rejectedReasons = defaultTo(
    [],
    path(['rejectionReasons', 'edges'], employerCandidate)
  ).map(reason => defaultTo('', path(['node', 'name'], reason)));

  return (
    <FOHView
      style={{
        maxWidth: props.fullWidth ? '100%' : isMobile ? '100vw' : 1200,
        width: '100%',
        alignItems: 'center'
      }}
    >
      <FOHPositionDetails
        {...props}
        noApplicationDetailFoundLabel={t('noApplicationDetailLabel')}
        applicationStatusLabel={
          isRejected ? t('rejectedDetailLabel') : t('applicationDetailLabel')
        }
        applicationStatusType={
          isRejected
            ? FOHCandidatePositionDetailsType.REJECTED
            : FOHCandidatePositionDetailsType.APPLIED
        }
        hasCandidateRejected={isRejected}
        applicationDateLabel={t('applicationDate')}
        applicationDate={
          props?.appliedApplicationDetails?.appliedOnPositionApplicationDate &&
          toDateTime(
            props?.appliedApplicationDetails?.appliedOnPositionApplicationDate
          )
        }
        rejectionReasons={rejectedReasons}
        appliedPositionCount={appliedPositionCount}
        onPressMorePositions={() => {
          navigateTo(`${FEED}${MORE_POSITIONS}`);
        }}
        stageType={stageType}
        screeningDetailLabel={t('screeningDetailLabel')}
        moveManualActionLabel={t('moveManualActionLabel')}
        isManualMoved={isManualMoved}
        locationLabel={t('common:location')}
        position={
          props?.appliedApplicationDetails?.appliedPosition?.positionName
        }
        locationName={props?.appliedApplicationDetails?.appliedLocation?.name}
        locationAddr={
          props?.appliedApplicationDetails?.appliedLocation?.formattedAddress
        }
        hasInterview={!!prop('id', lastInterview)}
        scheduleLabel={t('InterviewFeature:scheduledFor')}
        scheduledAt={scheduledAt}
        interviewDetailLabel={t('interviewDetailBlockLabel')}
        interviewerLabel={t('interviewerLabel')}
        interviewerName={interviewerName}
        interviewerEmail={interviewerEmail}
        noInterviewerLabel={t('noInterviewerLabel')}
        interviewedForLabel={t('interviewedForLabel')}
        interviewTypeLabel={t('InterviewFeature:interviewTypeLabel')}
        interviewType={interviewTypeString}
        interviewPhone={interviewPhone}
        interviewUrl={interviewUrl}
        noInterviewScheduledLabel={t(
          'InterviewFeature:noInterviewScheduledLabel'
        )}
        noInterviewAssignedLabel={t(
          'InterviewFeature:noInterviewAssignedLabel'
        )}
        onEditInterview={() => {
          navigateTo(
            { pathname: `${FEED}${INTERVIEW_SCHEDULE}` },
            {
              state: {
                interviewId: lastInterview?.id ?? undefined
              }
            }
          );
        }}
        onCancelInterview={async closeConfirmationModal => {
          const [canceledInterviewResp, errorOnCanceled] = await handleMutation(
            interviewMutation({
              variables: {
                id: prop('id', lastInterview),
                status: 'canceled'
              },
              awaitRefetchQueries: true,
              refetchQueries: [
                {
                  query: GetCandidateWithApplicantsDocument,
                  variables: {
                    first: 5,
                    handle: props?.candidateHandle
                  }
                }
              ]
            })
          );

          if (errorOnCanceled || !canceledInterviewResp) {
            return;
          }

          closeConfirmationModal();
        }}
        offeredDetailLabel={t('offeredStageDetailLabel')}
        offeredLabel={t('offeredLabel')}
        offeredAt={prop('sentAt', offeredDocument)}
        offerLetterLabel={t('offerLetterLabel')}
        offeredFileName={parseFileNameFromS3Url(defaultTo('', files)[0])}
        offeredFileUrl={defaultTo('', files)[0]}
        onOfferedFilePress={() => {}}
        isOfferApproved={defaultTo(
          false,
          prop('status', offeredDocument) === 'ACCEPTED'
        )}
        approvedLabel={t('approvedLabel')}
        noOfferSentLabel={t('noOfferSentLabel')}
        noOfferAttachLabel={t('noOfferAttachLabel')}
        hiredDetailLabel={t('hiredStageDetailLabel')}
        hiredLabel={t('hiredLabel')}
        hiredAt={prop('sentAt', hiredDocument)}
        noHireSentLabel={t('noHireSentLabel')}
        phoneScreeningLabel={t('phoneScreeningLabel')}
        phoneScreeningAt={scheduledAt}
        noPhoneScreeningLabel={t('noPhoneScreeningLabel')}
        prescreenCompletedAt={prescreenCompletedOn}
        screeningQuestionsLabel={t('screeningQuestionsLabel')}
        screeningCompletedOn={screeningQuestionnaireCompletedOn}
        screeningQuestionsLinkText={t('viewScreeningLabel')}
        pendingAnswers={
          notificationStatus === 'PENDING' && notificationType === 'SCREENING'
        }
        pendingAnswersLabel={t('pendingScreeningLabel')}
        onPressScreeningQuestions={() => setSelectedTab('screening-detail')}
        noScreeningQuestionsLabel={t('noScreeningQuestionsLabel')}
        noScreeningVideosLabel={t('noScreeningVideosLabel')}
        screeningVideosLabel={t('screeningVideosLabel')}
        screeningVideoCompletedOn={screeningVideoCompletedOn}
        screeningVideoLinkText={t('viewScreeningLabel')}
        onPressScreeningVideo={() => {
          setSelectedTab('screening-detail');
        }}
      >
        {props.children}
      </FOHPositionDetails>
    </FOHView>
  );
};
