import React, { useState, useContext } from 'react';

import { useLocation, useParams } from 'react-router-dom';
import { prop } from 'ramda';

import { getSearchQuery } from '../Search';

const BreadCrumbsContext = React.createContext([[], () => {}]);

export const useBreadCrumbsContext = props => {
  const [crumbs, setBreadCrumbs] = useContext(BreadCrumbsContext);
  const params = useParams();
  const location = useLocation();

  const addBreadCrumb = newCrumbs => {
    if (prop('isFeed', props) === true) {
      setBreadCrumbs(newCrumbs);
    } else {
      setBreadCrumbs(
        newCrumbs
          .filter(({ route }) => location.pathname.includes(route))
          .map(({ route, q, ...rest }) => ({
            route:
              Object.keys(params).length > 0
                ? Object.keys(params).reduce(
                    (_route, param) =>
                      _route.replace(
                        `:${param}`,
                        params[param] || getSearchQuery(location, q)
                      ),
                    route
                  )
                : route,
            ...rest
          }))
      );
    }
  };

  const findBreadCrumb = (crumb, allCrumbs) => {
    for (let i = 0; i < allCrumbs.length; i++) {
      const { route, label } = allCrumbs[i];
      if (prop('route', crumb) === route && prop('label', crumb) === label) {
        return i;
      }
    }
    return undefined;
  };

  const removeBreadCrumb = crumbToRemove => {
    const crumbIndex = findBreadCrumb(crumbToRemove, crumbs);

    let tempCrumbs;

    if (crumbIndex !== undefined) {
      tempCrumbs = [...crumbs];
      tempCrumbs.splice(crumbIndex, 1);
    } else {
      tempCrumbs = crumbs;
    }

    setBreadCrumbs(tempCrumbs);
  };

  return {
    addBreadCrumb,
    removeBreadCrumb,
    crumbs
  };
};

export const BreadCrumbsContextProvider = props => {
  const [crumbs, setBreadCrumbs] = useState([]);

  return (
    <BreadCrumbsContext.Provider value={[crumbs, setBreadCrumbs]}>
      {props.children}
    </BreadCrumbsContext.Provider>
  );
};
