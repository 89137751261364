import React from 'react';

import styled from 'styled-components/native';
import { View, TouchableOpacity, Platform } from 'react-native';
import { useSpring, animated } from 'react-spring';

import {
  FixedTopContainer,
  FOHAvatar,
  FOHDropDownArrow,
  FOHTextButton,
  FOHFullLogo,
  FOHBurger,
  FOHButton,
  FOHSpace,
  FOHVerticalDivider,
  FOHLabelBold,
  FOHXIcon,
  FOHLogoMedium,
  FOHInverseButton,
  FOHAddButton,
  FullWidthCard,
  FOHCrownIcon,
  SPACING_SCALE,
  FOHNotificationIcon,
  FOHLogo,
  FOHColors
} from '../../ingredients';
import { getShortId } from '../../utils';
import { FOHBannerType } from '../../typings';

import { FOHBanner } from '../banners/FOHBanner';
import {
  FOHBorderlessSelectDropdown,
  FOHDropdownSelectOption
} from '../select/FOHBorderlessSelectDropdown';

import { FOHProfileImage } from './FOHProfileImage';
import { FOHTab } from './FOHTab';

export const TOP_NAV_HEIGHT = 50;

export interface FOHTopNavProps {
  hasMenu?: boolean;
  onMenuPress?: () => void;
  menuOpen?: boolean;
  children?: any;
  hasNotification?: boolean;
  photo?: string;
  user?: any;
  signInTitle?: string;
  viewDemoTitle?: string;
  signIn?: () => void;
  viewDemo?: () => void;
  onLogoPress?: () => void;
  fullLogo?: boolean;
  mediumLogo?: boolean;
  onBurgerPress?: () => void;
  burgerOpen?: boolean;
  logout?: () => void;
  logoutLabel?: string;
  bannerType?: FOHBannerType;
  bannerMessage?: string;
  links?: Array<FOHNavLink>;
  // Filter for candidate feed by location, but we may use them for other things
  filterSelectLabel?: string;
  onLocationFilterPress?: () => void;
  hasFilterSelect?: boolean;
  filter?: string;
  filterLabel?: string;
  setFilter?: (val: string) => void;
  filters?: Array<FOHDropdownSelectOption>;
  openFilters?: boolean;
  setOpenFilters?: (open: boolean) => void;
  addLocation?: () => void;
  addLocationLabel?: string;
  // tabs
  tabs?: Array<FOHNavLink>;
  // upgrade plan
  upgrade?: () => void;
  upgradeLabel?: string;
  inbox?: any;
  onSearch?: (text: string) => void;
  searchValue?: string;
  searchPlaceholder?: string;

  isHideTopNav?: boolean;
}

interface FOHNavLink {
  onPress: () => void;
  title: string;
  selected?: boolean;
}

export const FOHTopNav = (props: FOHTopNavProps) => {
  const isBannerVisible = props.bannerType && !!props.bannerMessage;

  const springProps = useSpring({
    config: { duration: 200 },
    from: { top: 0 },
    to: { top: isBannerVisible ? TOP_NAV_HEIGHT : 0 }
  });

  return (
    <>
      {!props.isHideTopNav ? (
        <TopNav
          style={{
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none'
          }}
        >
          {(props.onBurgerPress || props.onLocationFilterPress) && (
            <BurgerContainer>
              {props.onBurgerPress && (
                <TouchableOpacity
                  onPress={props.onBurgerPress}
                  style={{ width: 40, height: '100%' }}
                  testID={'NavBurgerMenu'}
                >
                  {props.burgerOpen ? <FOHXIcon /> : <FOHBurger />}
                  {!props.burgerOpen && props.hasNotification && (
                    <FOHNotificationIcon
                      style={{
                        position: 'absolute',
                        top: -4,
                        left: SPACING_SCALE.large
                      }}
                    />
                  )}
                </TouchableOpacity>
              )}

              {/* MOBILE EMPLOYERS LOCATION DROPDOWN IS A POPUP */}
              {!!props.onLocationFilterPress && (
                <>
                  <FOHSpace />
                  <TouchableOpacity onPress={props.onLocationFilterPress}>
                    <FOHBorderlessSelectDropdown
                      open={false}
                      setOpen={_open => {
                        props.onLocationFilterPress &&
                          props.onLocationFilterPress();
                      }}
                      labelStyle={{
                        maxWidth: 200,
                        maxHeight: TOP_NAV_HEIGHT,
                        overflow: 'hidden'
                      }}
                      selectedValue={props?.filter as string}
                      onValueChange={_filter => {}}
                      options={props.filters || []}
                      onSearch={props.onSearch}
                      searchValue={props.searchValue}
                      searchPlaceholder={props.searchPlaceholder}
                    ></FOHBorderlessSelectDropdown>
                  </TouchableOpacity>
                </>
              )}
            </BurgerContainer>
          )}

          {/* MOBILE EMPLOYERS DO NOT HAVE A LOGO */}
          {!props.onLocationFilterPress && (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                flex: 1
              }}
            >
              <LogoContainer
                onPress={() => props.onLogoPress && props.onLogoPress()}
                style={{
                  marginLeft: props.onBurgerPress ? 0 : 4,
                  flexDirection: 'row'
                }}
              >
                {props.mediumLogo ? (
                  <FOHLogoMedium />
                ) : props.fullLogo && !props.onBurgerPress ? (
                  <FOHFullLogo />
                ) : (
                  <FOHLogo />
                )}
              </LogoContainer>

              {props.tabs?.map(tab => (
                <View
                  style={{ paddingLeft: 24, paddingRight: 8, top: 6 }}
                  key={getShortId()}
                >
                  <FOHTab
                    selected={!!tab.selected}
                    title={tab.title}
                    onPress={tab.onPress}
                  />
                </View>
              ))}
            </View>
          )}

          <>
            {props.menuOpen && props.hasMenu && (
              <MenuContainer shadow={true}>
                {props.menuOpen &&
                  props.children &&
                  props.children.map((childCmp: any) => childCmp)}
              </MenuContainer>
            )}

            <AvatarContainer>
              {!!props.upgrade && (
                <>
                  <FOHInverseButton
                    color={'#000000'}
                    style={{
                      maxWidth: props.upgradeLabel ? 130 : 50,
                      width: '100%',
                      height: 36
                    }}
                    centerIcon={true}
                    icon={() => (
                      <FOHCrownIcon style={{ width: 18, height: 18 }} />
                    )}
                    onPress={props.upgrade}
                    title={props.upgradeLabel || ''}
                  />
                  <FOHSpace />
                </>
              )}

              <NavFilter {...props} />

              {!!props.inbox && (
                <>
                  {props.inbox()}
                  <FOHSpace />
                </>
              )}

              {props.links &&
                props.links.map(link => (
                  <View
                    style={{ paddingRight: '4%', paddingLeft: '4%' }}
                    key={link.title}
                  >
                    <FOHTextButton
                      title={link.title}
                      onPress={() => {
                        link.onPress();
                      }}
                    />
                  </View>
                ))}

              {props.hasMenu && (
                <TouchableOpacity
                  testID={'nav-profile-image'}
                  onPress={props.onMenuPress}
                  style={{ flexDirection: 'row', alignItems: 'center' }}
                >
                  {props.user && props.photo ? (
                    <FOHProfileImage photo={props.photo} key={props.photo} />
                  ) : (
                    <FOHAvatar />
                  )}
                  {props.fullLogo && <FOHDropDownArrow />}
                </TouchableOpacity>
              )}
              <View style={{ paddingLeft: 12 }} />
            </AvatarContainer>
          </>

          {!!props.signInTitle && (
            <>
              {props.mediumLogo ? (
                <FOHButton
                  style={{ width: 80, height: '100%' }}
                  title={props.signInTitle}
                  onPress={() => {
                    props.signIn && props.signIn();
                  }}
                />
              ) : (
                <FOHTextButton
                  title={props.signInTitle}
                  onPress={() => {
                    props.signIn && props.signIn();
                  }}
                />
              )}
              <View style={{ padding: '1%' }} />
            </>
          )}

          {!!props.viewDemoTitle && !props.mediumLogo && (
            <>
              <FOHInverseButton
                opaque={true}
                color={'#000000'}
                textColor={'#ffffff'}
                canSelect={true}
                selected={true}
                style={{ width: 100, height: '100%' }}
                title={props.viewDemoTitle}
                onPress={() => {
                  props.viewDemo && props.viewDemo();
                }}
              />
              <FOHSpace />
            </>
          )}

          {!!props.logoutLabel && (
            <>
              <FOHTextButton
                title={props.logoutLabel}
                onPress={() => {
                  props.logout && props.logout();
                }}
              />
              <View style={{ padding: '3%' }} />
            </>
          )}
        </TopNav>
      ) : (
        <></>
      )}

      {props.bannerMessage ? (
        <View
          //@ts-ignore position
          style={{
            top: 0,
            position: Platform.OS === 'web' ? 'fixed' : 'absolute',
            width: '100%',
            zIndex: 200
          }}
        >
          <AnimatedBanner
            style={springProps}
            message={props.bannerMessage!}
            //@ts-ignore bannerType
            bannerType={props.bannerType}
          />
        </View>
      ) : (
        <></>
      )}
    </>
  );
};

const NavFilter = (props: FOHTopNavProps) =>
  props.hasFilterSelect &&
  !props.onLocationFilterPress &&
  props.filters &&
  props.setFilter ? (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      <FOHLabelBold>{props.filterSelectLabel}</FOHLabelBold>
      <FOHBorderlessSelectDropdown
        open={props.openFilters || false}
        setOpen={props.setOpenFilters ? props.setOpenFilters : _open => {}}
        selectedValue={props?.filter as string}
        selectedLabel={props.filterLabel} // optional, only if selection is not in search results
        onValueChange={props.setFilter}
        options={props.filters}
        onSearch={props.onSearch}
        searchValue={props.searchValue}
        searchPlaceholder={props.searchPlaceholder}
      >
        {props.addLocation && props.addLocationLabel && (
          <FOHAddButton
            onPress={props.addLocation}
            title={props.addLocationLabel}
          />
        )}
      </FOHBorderlessSelectDropdown>
      <FOHSpace />
      <FOHVerticalDivider />
      <FOHSpace />
    </View>
  ) : (
    <></>
  );

const AnimatedBanner = animated(FOHBanner);

const BurgerContainer = styled(View)`
  flex: 1.5;
  justify-content: left;
  margin-left: 15px;
  flex-direction: row;
  align-items: center;
`;

const LogoContainer = styled(TouchableOpacity)`
  justify-content: left;
  margin-left: 30px;
  flex-direction: row;
`;

const AvatarContainer = styled(View)`
  flex: 1;
  z-index: 20;
  height: 100%;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const MenuContainer = styled(FullWidthCard)`
  z-index: 20;
  margin-left: 300;
  background-color: ${FOHColors.WHITE};
  top: ${TOP_NAV_HEIGHT};
  right: 20;
  position: absolute;
  max-width: 280px;
`;

const TopNav = styled(FixedTopContainer)`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${TOP_NAV_HEIGHT};
  border: 1px solid #e8e8e8;
  z-index: 9999;
`;
