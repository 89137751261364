import React from 'react';

import person from '../../../images/avatar.svg';
import largeavatar from '../../../images/largeavatar.svg';

import { FOHImage } from '../../ingredients';

export interface FOHAvatarProps {
  style?: any;
}

export const FOHAvatar = (props: FOHAvatarProps) => (
  <FOHImage
    source={{ uri: person }}
    style={{ width: 36, height: 36, ...props.style }}
  />
);

export const FOHLargeAvatar = (props: FOHAvatarProps) => (
  <FOHImage
    source={{ uri: largeavatar }}
    style={{ width: 80, height: 80, ...props.style }}
  />
);
