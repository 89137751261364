import React from 'react';

import { TouchableOpacity } from 'react-native';
import { defaultTo, prop } from 'ramda';

import {
  FOHLabel,
  FOHBlueAdd,
  FOHSpaceSmall,
  FOHColors
} from '../../ingredients';

export interface FOHAddButtonProps {
  onPress: () => void;
  title: string;
  style?: any;
  testID?: string;
  iconSize?: any;
}

export const FOHAddButton = (props: FOHAddButtonProps) => (
  <TouchableOpacity
    style={{
      flexDirection: 'row',
      alignItems: 'center',
      ...defaultTo({}, prop('style', props))
    }}
    onPress={props.onPress}
    testID={props.testID}
  >
    <FOHBlueAdd
      style={{
        width: props.iconSize ? props.iconSize : 24,
        height: props.iconSize ? props.iconSize : 24,
        tintColor: FOHColors.PRIMARY_TEAL_P1
      }}
    />
    <FOHSpaceSmall />
    <FOHLabel
      style={{
        color: FOHColors.PRIMARY_TEAL_P1,
        textDecorationLine: 'underline',
        textDecorationStyle: 'solid',
        fontWeight: '700'
      }}
      bold
    >
      {props.title}
    </FOHLabel>
  </TouchableOpacity>
);
