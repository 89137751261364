import React from 'react';

import { Image, Text } from 'react-native';
import styled from 'styled-components/native';

import { ALLOWED_ROLES_GROUPS } from '../../../constants';

import {
  FOHColors,
  FOHFonts,
  FOHView,
  FullWidthCard,
  FOHSpaceSmallest,
  FOHSpace,
  FOHSpaceSmall,
  FOHSpaceLarger,
  FOHLocationPin,
  FOHToolBoxIcon,
  FOHLinkOut,
  FOHSmallDetailLabel,
  FOHTextButton,
  FOHEditMarker,
  FOHBlackEyeIcon,
  FOHLabel
} from '../../ingredients';
import RestrictedView from '../../RestrictedView';

const FOHLocationCardContainer = styled(FullWidthCard)`
  align-items: center;
  flex-direction: row;
  padding: 12px;
  flex-wrap: wrap;
  border-radius: 4px;
  border: 1px solid ${FOHColors.GRAYSCALE_300};
  margin-bottom: 16;
`;

// Container 1
const FOHRestaurantProfileContainer = styled(FOHView)<{ mobile?: boolean }>`
  width: 70px;
  margin-left: ${(p: any) => (p.mobile ? '8px' : '0px')};
`;

const FOHRestaurantProfile = styled(Image)`
  width: 70px
  height: 70px;
  border-radius: 3px;
  object-fit: contain;
  resizeMode: contain;
`;

// Container 2
const FOHRestaurantNameLocationContainer = styled(FOHView)`
  flex: 1;
  min-width: 290px;
  padding-left: 8px;
`;

const FOHRestaurantNameLocationWrapper = styled(FOHView)`
  flex-wrap: wrap;
  flex-direction: row;
`;

const FOHRestaurantLocation = styled(FOHSmallDetailLabel)`
  line-height: 24px;
  font-size: 14px;
  font-weight: 600;
  color: ${FOHColors.GRAYSCALE_500};
  display: flex;
  margin-top: 8px;
`;

// Container 3
const FOHPositionsContainer = styled(FOHView)<{ mobile?: boolean }>`
  flex-direction: row;
  justify-content: flex-end;
  min-width: 140px;
  max-width: 213px;
  margin-left: ${(p: any) => (p.mobile ? '8px' : '0px')};
`;

// Container 4
const FOHPositionsLabel = styled(FOHSmallDetailLabel)`
  line-height: 16px;
  font-size: 12px;
  font-weight: 600;
  color: ${FOHColors.GRAYSCALE_400};
`;

const FOHPositionsCount = styled(FOHSmallDetailLabel)`
  line-height: 16px;
  font-size: 12px;
  color: ${FOHColors.GRAYSCALE_700};
  text-align: left;
  display: flex;
  align-items: start;
`;

const HiringViewPort = styled(FOHView)`
  width: 14px;
  height: 14px;
`;

const FOHUnlimitedHiringContainer = styled(FOHView)`
  flex-direction: row;
  justify-content: flex-start;
`;

const FOHSmallLabel = styled(Text)`
  font-weight: 700;
  font-size: 12px;
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  line-height: 20px;
  align-items: center;
  color: ${FOHColors.GRAYSCALE_700};
`;

// Container 5
const FOHButtonContainer = styled(FOHView)`
  margin-left: 10px;
  width: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;
  min-width: 136px;
  max-width: 152px;
`;

export interface FOHLocationCardProps {
  restaurantProfileURI: string;
  onPressRestaurantName: () => any;

  restaurantName: string;
  restaurantLocationAt: string;

  allPositionsLabel: string;
  allPositionsCount: number;

  activePositionsLabel: string;
  activePositionsCount: number;

  hiringIcon: () => any;
  hiringTypeLable: string;

  locationPlanLabel: string;
  locationPlanType: string;

  nextBillingLabel: string;
  nextBillingDate: string;

  editLocationLabel: string;
  onPressEditLocation: () => void;

  viewLocationLabel: string;
  onPressViewLocation: () => void;

  locationNumberLabel: string;
  locationNumber: string;

  mobile?: boolean;
}

export const FOHLocationCard = (props: FOHLocationCardProps) => {
  return (
    <FOHLocationCardContainer>
      <FOHRestaurantProfileContainer mobile={props.mobile}>
        <FOHRestaurantProfile
          source={{
            uri: props.restaurantProfileURI
          }}
        />
      </FOHRestaurantProfileContainer>
      <FOHSpaceSmall />
      <FOHRestaurantNameLocationContainer>
        {props.mobile ? (
          <FOHView style={{ flex: 1 }}>
            <FOHSpace />
            <FOHLinkOut
              noIcon={true}
              color={FOHColors.GRAYSCALE_G1}
              fontSize={18}
              href=""
              onPress={props.onPressRestaurantName}
              labelStyle={{ lineHeight: 24, color: FOHColors.GRAYSCALE_G1 }}
            >
              {props.restaurantName}
            </FOHLinkOut>
            <FOHView style={{ minWidth: 90, width: 92 }}></FOHView>
          </FOHView>
        ) : (
          <FOHRestaurantNameLocationWrapper>
            <FOHLinkOut
              noIcon={true}
              color={FOHColors.GRAYSCALE_G1}
              fontSize={18}
              href=""
              onPress={props.onPressRestaurantName}
              style={{ flex: 'unset' }}
              labelStyle={{ lineHeight: 24, color: FOHColors.GRAYSCALE_G1 }}
              testID="FOHLocationCard-location-name-link"
            >
              {props.restaurantName}
            </FOHLinkOut>
            <FOHSpaceSmall />
          </FOHRestaurantNameLocationWrapper>
        )}
        <FOHRestaurantLocation>
          <FOHLocationPin
            style={{
              width: 16,
              height: 16,
              tintColor: FOHColors.GRAYSCALE_400
            }}
          />
          <FOHSpaceSmall />
          {props.restaurantLocationAt}
        </FOHRestaurantLocation>
        {props.locationNumber ? (
          <FOHView style={{ marginTop: 6 }}>
            <FOHLabel
              heavy
              style={{
                color: FOHColors.GRAYSCALE_700,
                fontSize: 14,
                lineHeight: 20
              }}
            >
              {`${props.locationNumberLabel}: `}
              <FOHSmallDetailLabel>{props.locationNumber}</FOHSmallDetailLabel>
            </FOHLabel>
          </FOHView>
        ) : null}
      </FOHRestaurantNameLocationContainer>
      <FOHSpaceSmall />
      <FOHPositionsContainer mobile={props.mobile}>
        <FOHView>
          <FOHPositionsLabel>{props.allPositionsLabel}</FOHPositionsLabel>
          <FOHSpaceSmall />
          <FOHPositionsCount>
            <FOHToolBoxIcon
              style={{
                width: 13,
                height: 13,
                tintColor: FOHColors.GRAYSCALE_400
              }}
            />
            <FOHSpaceSmallest />
            {props.allPositionsCount}
          </FOHPositionsCount>
        </FOHView>
        <FOHSpaceLarger />
        <FOHSpaceSmall />
        <FOHView>
          <FOHPositionsLabel>{props.activePositionsLabel}</FOHPositionsLabel>
          <FOHSpaceSmall />
          <FOHPositionsCount>
            <FOHToolBoxIcon
              style={{
                width: 13,
                height: 13,
                tintColor: FOHColors.PRIMARY_TEAL_P1
              }}
            />
            <FOHSpaceSmallest />
            {props.activePositionsCount}
          </FOHPositionsCount>
        </FOHView>
        <FOHSpaceLarger />
        <FOHSpaceSmall />
        <FOHView>
          <FOHPositionsLabel>{props.locationPlanLabel}</FOHPositionsLabel>
          <FOHSpaceSmall />
          <FOHUnlimitedHiringContainer>
            <HiringViewPort>
              {props.hiringIcon && props.hiringIcon()}
            </HiringViewPort>
            <FOHSpaceSmall />
            <FOHSmallLabel>{props.hiringTypeLable}</FOHSmallLabel>
          </FOHUnlimitedHiringContainer>
        </FOHView>
      </FOHPositionsContainer>
      <FOHSpaceLarger />
      <RestrictedView
        allowedRoles={ALLOWED_ROLES_GROUPS.ROLES.ADMIN_USER}
        allowedGroups={[ALLOWED_ROLES_GROUPS.GROUPS.EMPLOYER]}
      >
        <FOHButtonContainer>
          <FOHTextButton
            title={props.editLocationLabel}
            disabled={false}
            icon={() => (
              <FOHEditMarker
                style={{ width: 14, height: 14, marginRight: 5 }}
              />
            )}
            onPress={props.onPressEditLocation}
            touchableStyle={{
              minWidth: 136,
              maxWidth: 152,
              height: 40
            }}
            style={{
              color: FOHColors.PRIMARY_TEAL_P1
            }}
            underline
          />
        </FOHButtonContainer>
      </RestrictedView>
      <FOHButtonContainer>
        <FOHTextButton
          title={props.viewLocationLabel}
          disabled={false}
          icon={() => (
            <FOHBlackEyeIcon
              style={{ height: 11, width: 18, marginRight: 5 }}
            />
          )}
          onPress={props.onPressViewLocation}
          touchableStyle={{
            minWidth: 136,
            maxWidth: 152,
            height: 40
          }}
          style={{
            color: FOHColors.GRAYSCALE_900
          }}
          underline
        />
      </FOHButtonContainer>
    </FOHLocationCardContainer>
  );
};
