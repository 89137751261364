import React from 'react';

import { View } from 'react-native';
import { defaultTo } from 'ramda';

import {
  FOHColors,
  FOHHighlightWordsLabel,
  FOHLabel,
  FOHLabelBold
} from '../../ingredients';
import { FOHCandidateStateBannerEnum } from '../../typings';

export interface FOHCandidateStateProps {
  candidateStateString: string;
  candidateState?: FOHCandidateStateBannerEnum;
}

export interface FOHCandidateStateBannerProps extends FOHCandidateStateProps {
  candidateName?: string;
  forLocation?: string;
  forPosition?: string;
}

export const FOHCandidateStateBanner = (
  props: FOHCandidateStateBannerProps
) => {
  return (
    <View
      style={{ backgroundColor: FOHColors.BACKGROUND_GRAY, padding: 12 }}
      testID="FOHCandidateStateBanner"
    >
      <FOHHighlightWordsLabel
        labelCmp={FOHLabel}
        emphasizedWords={[
          ...defaultTo('', props.candidateName).split(' '),
          ...defaultTo('', props.forLocation).split(' '),
          ...defaultTo('', props.forPosition).split(' ')
        ]}
        highlightColor="#000000"
        boldLabelCmp={FOHLabelBold}
      >
        {props.candidateStateString}
      </FOHHighlightWordsLabel>
    </View>
  );
};
