import { ENDORSEMENTS_URL } from '../constants/urls';

import { post, get, patch } from './index';

export const createEndorsement = data => {
  return post(ENDORSEMENTS_URL, data);
};

export const getEndorsementsForHandle = handle => {
  return get(`/profile/${handle}${ENDORSEMENTS_URL}`);
};

export const updateEndorsement = data => {
  return patch(`${ENDORSEMENTS_URL}/${data.id}`, data);
};

export const requestEndorsement = data => {
  return post(`${ENDORSEMENTS_URL}/request`, data);
};
