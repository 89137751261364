import React from 'react';

import reminder_icon from '../../../images/reminder_icon.svg';

import { FOHImage } from '../../ingredients';

export const FOHReminderIcon = (props: any) => (
  <FOHImage
    source={{ uri: reminder_icon }}
    style={
      props && !!props.style
        ? { width: 26, height: 26, ...props.style }
        : { width: 26, height: 26 }
    }
  />
);
