import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { path, prop, toLower } from 'ramda';

import {
  unwrapEdgesAt,
  useIsMobile,
  toDate,
  isScrollCloseToBottom,
  getAuth
} from '../../utils';
import { positionToSalaryText } from '../../utils/currency';
import {
  DISCOVER,
  HOME_PAGE,
  INTERVIEW_DETAIL_PATH,
  POSITIONS_SORT_BY_OPTIONS,
  RESTAURANT_JOBS
} from '../../constants';
import { useSearchInterviewPositionsQuery } from '../../graphql/generated';
import {
  FOHCandidateResultFilter,
  FOHColors,
  FOHFilterIcon,
  FOHInterviewRequestCell,
  FOHInverseButton,
  FOHScrollView,
  FOHView,
  FullWidthCard
} from '../../components';
import { useNavigation } from '../../utils/navigation';
import { FOH_INPUT_HEIGHT } from '../../components/constants';

import { useCandidateProfileMutation } from '../CandidateProfile/useCandidateProfileMutation';
import { useDirectMessage } from '../Chat/useDirectMessage';
import { useRespondToInterviewRequest } from '../InterviewRequest/useRespondToInterviewRequest';
import { fetchMorePositions } from '../EmployerPositions/fetchMorePositions';

import { HomeScrollFooter } from './HomeScrollFooter';
import { PositionTypeAheadSearchBar } from './PositionTypeAheadSearchBar';
import { PositionFilterDrawer } from './PositionFilterDrawer';

const FilterIcon = () => <FOHFilterIcon active={true} />;

export const CandidateInterviewRequests = props => {
  const { t } = useTranslation('CandidateHomeFeature');
  const { navigateTo } = useNavigation();

  const { me } = useCandidateProfileMutation();

  const { goToDirectMessageWith } = useDirectMessage({
    ...props,
    sendbirdAccessToken: path(['sendbirdAccessToken'], me)
  });

  const [openFilters, setOpenFilters] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [workingPreferences, setWorkingPreferences] = useState([]);
  const [sortBy, setSortBy] = useState('latest');

  const [loadingMore, setLoadingMore] = useState(false);

  const { isMobile } = useIsMobile();

  const benefits = workingPreferences.includes('isBenefitsRequired')
    ? {
        isBenefitsRequired: true
      }
    : {};

  const dressCode = workingPreferences.includes('canHaveDressCode')
    ? {
        canHaveDressCode: true
      }
    : {};

  const variables = {
    first: 10,
    after: '',
    hasInterview: true,
    interviewStatus: 'PENDING',
    sortBy: sortBy,
    searchTerms: selectedItems
      .map(item => prop('value', item))
      .filter(item => !!item),
    ...benefits,
    ...dressCode
  };
  const positionSearchQuery = useSearchInterviewPositionsQuery({
    variables: {
      ...variables
    }
  });

  const positions = unwrapEdgesAt(
    ['data', 'positions', 'edges'],
    positionSearchQuery
  );

  useEffect(() => {
    if (
      props.scrolledToBottom &&
      !positionSearchQuery.loading &&
      isMobile &&
      !loadingMore
    ) {
      setLoadingMore(true);
      fetchMorePositions(positionSearchQuery, variables).then(() => {
        setLoadingMore(false);
        props.setScrolledToBottom(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.scrolledToBottom]);

  const {
    accepting,
    declining,
    acceptInterviewRequest,
    declineInterviewRequest
  } = useRespondToInterviewRequest();

  return (
    <>
      <FOHView
        style={{
          flexDirection: isMobile ? 'column-reverse' : 'column'
        }}
      >
        <FullWidthCard
          style={{
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          <FOHView
            style={{
              padding: isMobile ? 10 : 16,
              flexDirection: 'row',
              flexWrap: 'wrap'
            }}
          >
            <FOHView
              style={{
                flex: 3,
                marginRight: 10,
                minWidth: 300,
                paddingBottom: isMobile ? 10 : 0,
                minHeight: 52,
                zIndex: 99
              }}
            >
              <PositionTypeAheadSearchBar
                {...props}
                tab="interview-requests"
                setSelectedItems={setSelectedItems}
                selectedItems={selectedItems}
                isMobile={isMobile}
              />
            </FOHView>
            <FOHView style={{ flex: 1, minWidth: 250 }}>
              <FOHInverseButton
                color={FOHColors.BLACK}
                title={t('moreFilters')}
                icon={FilterIcon}
                onPress={() => setOpenFilters(true)}
                centerIcon={true}
                style={{
                  width: isMobile ? '100%' : 250,
                  height: FOH_INPUT_HEIGHT
                }}
              />
            </FOHView>
          </FOHView>
        </FullWidthCard>
        <FOHCandidateResultFilter
          totalCount={
            positionSearchQuery.loading
              ? '...'
              : path(['data', 'positions', 'totalCount'], positionSearchQuery)
          }
          countHeader={t('interviewRequestsCountHeader')}
          showBestMatch={false}
          sortByLabel={t('sortByLabel')}
          sortBy={sortBy}
          setSortBy={setSortBy}
          sortByOptions={POSITIONS_SORT_BY_OPTIONS(t)}
        />
      </FOHView>
      <FOHScrollView
        scrollEventThrottle={200}
        onScroll={async ({ nativeEvent }) => {
          if (isScrollCloseToBottom(nativeEvent) && !loadingMore && !isMobile) {
            setLoadingMore(true);
            await fetchMorePositions(positionSearchQuery, variables);
            setLoadingMore(false);
          }
        }}
        style={{
          // no nested scrolling on mobile
          height: openFilters ? '40vh' : isMobile ? '100%' : '60vh',
          zIndex: -1
        }}
      >
        <>
          {positionSearchQuery.loading ? (
            <></>
          ) : (
            positions.map(position => {
              const schedulingEnabled = path(
                ['myInterview', 'createdBy', 'hasSchedule'],
                position
              );
              return (
                <FOHInterviewRequestCell
                  mobile={isMobile}
                  key={path(['id'], position)}
                  positionName={path(['positionName'], position)}
                  positionSlug={path(['slug'], position)}
                  positionId={path(['id'], position)}
                  hiringManagerName={path(
                    ['myInterview', 'createdBy', 'firstName'],
                    position
                  )}
                  hiringManagerImage={path(
                    ['myInterview', 'createdBy', 'profileImage'],
                    position
                  )}
                  hiringManagerPosition={t('interviewOwner')} // Position Owner
                  hiringManagerDetailText="" // It usually takes 1-2 weeks to hear back.
                  salaryText={positionToSalaryText(position, { t })}
                  salaryLabel={t('compensation')}
                  requestedLabel={t('requestOn')}
                  requestedAt={`${t('requested')} ${toDate(
                    path(['myInterview', 'createdAt'], position)
                  )}`}
                  // Apply
                  buttonCTALabel={
                    path(['myInterview', 'status'], position) !== 'PENDING'
                      ? t(
                          `common:interview.${toLower(
                            path(['myInterview', 'status'], position)
                          )}`
                        )
                      : t('respond')
                  }
                  acceptNowLabel={t('acceptNow')}
                  seeFullDetailsLabel={t('seeRequest')}
                  declineLabel={t('decline')}
                  decline={async () => {
                    !declining &&
                      (await declineInterviewRequest({
                        interviewId: path(['myInterview', 'id'], position)
                      }));
                  }}
                  seeFullDetail={() => {
                    const auth = getAuth();
                    const token = auth.token;
                    navigateTo(
                      `${INTERVIEW_DETAIL_PATH}/${path(
                        ['myInterview', 'uuid'],
                        position
                      )}/token/${token}`
                    );
                  }}
                  disabledCTA={
                    path(['myInterview', 'status'], position) !== 'PENDING'
                  }
                  acceptNow={async () => {
                    !accepting &&
                      (await acceptInterviewRequest({
                        interviewId: path(['myInterview', 'id'], position),
                        uuid: path(['myInterview', 'uuid'], position),
                        schedulingEnabled
                      }));
                  }}
                  // location
                  locationId={path(['location', 'id'], position)}
                  locationName={path(['location', 'name'], position)}
                  locationSlug={path(['location', 'slug'], position)}
                  onPressLocation={slug =>
                    isMobile
                      ? navigateTo(`${RESTAURANT_JOBS}/${slug}`)
                      : props.openLocationDetailsModal(slug)
                  }
                  locationImage={path(['location', 'image'], position)}
                  locationHref=""
                  onPressMessage={async () => {
                    await goToDirectMessageWith({
                      uuids: [
                        path(['myInterview', 'createdBy', 'uuid'], position)
                      ],
                      location: path(['location', 'id'], position),
                      position: prop('id', position),
                      interview: path(['myInterview', 'id'], position)
                    });
                  }}
                  messageLabel={t('chat')}
                />
              );
            })
          )}
        </>
        <HomeScrollFooter
          loading={positionSearchQuery.loading || loadingMore}
          hasNextPage={path(
            ['data', 'positions', 'pageInfo', 'hasNextPage'],
            positionSearchQuery
          )}
          noMorePositionsLabel={t('noMoreRequests')}
          noMorePositionsDetailLabel={t('applyByYourOwn')}
          noMoreSubmitBtnLabel={t('browseMoreJobs')}
          onSubmitPress={() => {
            if (path(['params', 'tab'], props.match) !== 'discover') {
              navigateTo(`${HOME_PAGE}${DISCOVER}`);
            }
          }}
        />
      </FOHScrollView>
      <PositionFilterDrawer
        workingPreferences={workingPreferences}
        setWorkingPreferences={setWorkingPreferences}
        openFilters={openFilters}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        setOpenFilters={setOpenFilters}
      />
    </>
  );
};
