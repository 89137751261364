import React, { useEffect, useMemo, useState } from 'react';

import styled from 'styled-components/native';
import { defaultTo, length, path } from 'ramda';
import { useTranslation } from 'react-i18next';
import { createEditor, Node } from 'slate';
import { withReact } from 'slate-react';

import { handleMutation } from '../../utils';
import {
  useEmployerMessageTemplatesQuery,
  useMutateSendQuestionnaireMutation
} from '../../graphql/generated';
import {
  getTemplatePlaceHolders,
  getPlaceHolderByAction,
  TEXT_QUESTIONNAIRE
} from '../../constants/templates';
import { FEED, TEMPLATES, UPGRADE } from '../../constants';
import {
  FOHLabel,
  FOHColors,
  FOHSpace,
  FOHView,
  FOHSpaceSmall,
  FOHQuestionnaireIcon,
  FOHFonts,
  FOHSpaceSmallest
} from '../../components';
import { useNavigation } from '../../utils/navigation';

import { useCandidateFeedContext } from '../CandidateFeed/useCandidateFeedContext';
import { useSelectPositionForSelectedLocationState } from '../EmployerPositions/useSelectPositionForSelectedLocationState';
import ActionModal from '../CandidateFeed/ActionModal';
import {
  withCharacterLimit,
  withCustomDelete,
  stripBracesFromPlaceholder
} from '../EmployerMessageTemplate/utils';
import { FOHMessageInputSection } from '../EmployerMessageTemplate/FOHMessageInputSection';

import { INITIAL_SLATE_VALUE } from './CONSTANTS';

const MAX_COUNT = 500;

const editor = withCharacterLimit(MAX_COUNT)(
  withReact(withCustomDelete(createEditor()))
);

export const ScreeningQuestionnaireModal = props => {
  const { t } = useTranslation('EmployerQuestionnaireModal');

  const { navigateTo } = useNavigation();

  const { activeStageId } = useCandidateFeedContext();

  const { employerCandidate } = props;
  const employerCandidatePreferredMatch = path(
    ['preferredMatch'],
    employerCandidate
  );
  const defaultPositionId = path(
    ['position', 'id'],
    employerCandidatePreferredMatch
  );
  const defaultLocationId = path(
    ['position', 'location', 'id'],
    employerCandidatePreferredMatch
  );

  const { selectedLocation, selectedPosition, setSelectedPosition } =
    useSelectPositionForSelectedLocationState({
      positionId: defaultPositionId,
      locationId: defaultLocationId
    });

  const [activeQuestionnaire, setActiveQuestionnaire] = useState({});
  const [questionnaireName, setQuestionnaireName] = useState('');
  const [template, setTemplate] = useState();
  const [open, setOpen] = useState(false);
  const [sending, setSending] = useState(false);
  const [search, setSearch] = useState('');
  const [placeHolderError, setPlaceHolderError] = useState('');

  const [inputMessageContent, setInputMessageContent] =
    useState(INITIAL_SLATE_VALUE);

  const messageTemplatesQuery = useEmployerMessageTemplatesQuery({
    variables: {
      search,
      category: 'SCREENING'
    }
  });

  const filteredTemplates = useMemo(() => {
    const templates = defaultTo(
      [],
      path(['data', 'employerMessageTemplates', 'edges'], messageTemplatesQuery)
    );

    return templates
      .map(edge => ({
        id: edge?.node.id,
        name: edge?.node.name,
        messageContent: edge.node.messageContent,
        templateType: edge.node.templateType,
        isDefault: edge.node.isDefault,
        category: edge.node.category,
        action: edge.node.action,
        createdAt:
          edge.node.screeningQuestionnaire?.edges?.[0]?.node?.createdAt,
        screeningQuestionnaireId:
          edge.node.screeningQuestionnaire?.edges?.[0]?.node?.id,
        screeningQuestion:
          edge.node.screeningQuestionnaire?.edges?.[0]?.node?.screeningQuestion?.edges?.map(
            questionEdge => {
              return {
                question: questionEdge.node.question,
                questionId: questionEdge.node.id,
                deleteQuestion: false
              };
            }
          )
      }))
      .filter(
        _template =>
          _template?.name?.toLowerCase()?.includes(search.toLowerCase()) &&
          _template?.action === 'questionnaire'
      );
  }, [messageTemplatesQuery, search]);

  useEffect(() => {
    if (length(filteredTemplates) > 0 && !template) {
      const defaultTemplate = filteredTemplates.find(
        _template => !!_template.isDefault
      );
      if (defaultTemplate) {
        console.log('defaultTemplate', defaultTemplate);
        setTemplate(defaultTemplate);
        handleOnUseTemplate(defaultTemplate);
      }
    }
  }, [filteredTemplates, template]);

  const [sendQuestionnaireMutation] = useMutateSendQuestionnaireMutation();

  const resetForm = () => {
    setOpen(false);
    setTemplate(undefined);
    setQuestionnaireName('');
    setActiveQuestionnaire({});
    setInputMessageContent(INITIAL_SLATE_VALUE);
  };

  const handleOnClose = async () => {
    setTemplate(undefined);
    setQuestionnaireName('');
    setActiveQuestionnaire({});
    setSelectedPosition(undefined);
    setSending(false);
    navigateTo(FEED);
  };

  const handleOnUseTemplate = (selectedTemplate = null) => {
    const temp = selectedTemplate ?? template;

    setOpen(false);
    const newValue = [
      {
        type: 'paragraph',
        children: [{ text: temp?.messageContent || '' }]
      }
    ];

    setInputMessageContent(newValue);
    editor.children = newValue;

    setActiveQuestionnaire({
      id: temp?.screeningQuestionnaireId,
      name: temp?.name,
      questionnaireType: temp?.action,
      screeningQuestion: temp?.screeningQuestion,
      createdAt: temp?.createdAt
    });
    setQuestionnaireName(temp?.name);
  };

  const sendQuestionnaire = async () => {
    const messageContent = inputMessageContent
      .map(n => Node.string(n))
      .join('\n');

    const screeningStage = props.stages.find(
      stage => stage.stageType === 'SCREENING'
    );

    const placeholder = getPlaceHolderByAction(TEXT_QUESTIONNAIRE);

    if (!messageContent.includes(placeholder)) {
      const errorPlaceholder = stripBracesFromPlaceholder(placeholder);

      setPlaceHolderError(
        t('MessageTemplatesFeature:requiredPlaceHolder', {
          placeholder: errorPlaceholder
        })
      );
      return;
    }

    setPlaceHolderError('');

    setSending(true);
    if (activeStageId !== screeningStage.id) {
      await props.moveEmployerCandidates({
        employerCandidateIds: [props.employerCandidateId],
        newStage: screeningStage.id,
        all: false,
        oldStage: activeStageId,
        positions: [selectedPosition],
        locations: [selectedLocation],
        search: ''
      });
      props.stagesQuery.refetch();
      props.candidateFeedQuery.refetch();

      setTimeout(() => {
        props.setEmployerCandidate(props.employerCandidate);
        props.setCandidateHandle(props.candidateHandle);
      }, 1000);
    }

    await handleMutation(
      sendQuestionnaireMutation({
        variables: {
          employerCandidateId: props.employerCandidateId,
          positionId: selectedPosition,
          questionnaireId: activeQuestionnaire.id,
          messageContent: messageContent
        }
      })
    );

    handleOnClose();
  };

  return (
    <ActionModal
      {...props}
      title={t('firstTimeHeaderTitle')}
      containerTestID="ScreeningQuestionnaireModal"
      HeaderImage={FOHQuestionnaireIcon}
      defaultLocation={defaultLocationId}
      defaultPosition={defaultPositionId}
      showLabel={t('common:show')}
      onPressUpgrade={() => navigateTo(`${FEED}${UPGRADE}`)}
      searchPlaceholder={t('common:search')}
      positionFieldLabel={t('common:position')}
      upgradeLabel={t('TopNavFeature:upgrade')}
      backButtonText={t('common:previous')}
      nextButtonText={sending ? t('actions:sendingLetter') : t('sendLabel')}
      onNextPress={sendQuestionnaire}
      onBackPress={handleOnClose}
      isNextDisabled={
        !selectedPosition || !activeQuestionnaire.id || sending === true
      }
      color={FOHColors.BLUE}
      setPosition={setSelectedPosition}
      containerStyle={{
        alignItems: 'flex-start',
        width: '100%',
        padding: 0
      }}
    >
      <FOHView style={{ minHeight: open ? 540 : undefined }}>
        <FOHMessageInputSection
          testID={'message-templates-section'}
          t={t}
          open={open}
          setOpen={setOpen}
          label={questionnaireName}
          editor={editor}
          template={template}
          setTemplate={setTemplate}
          onEditTemplate={() => {
            resetForm();
            navigateTo(TEMPLATES);
          }}
          search={search}
          setSearch={setSearch}
          filteredTemplates={filteredTemplates}
          resetForm={resetForm}
          handleOnUseTemplate={handleOnUseTemplate}
          placeholder={getTemplatePlaceHolders(t, TEXT_QUESTIONNAIRE)}
          placeHolderError={placeHolderError}
          inputMessageContent={inputMessageContent}
          setInputMessageContent={setInputMessageContent}
        >
          {activeQuestionnaire && Object.keys(activeQuestionnaire).length ? (
            <>
              <FOHSpace />
              <FOHSpaceSmallest />
              <FieldLabel>{t('common:questionnaireLabel')}</FieldLabel>
              <FOHSpaceSmall />
            </>
          ) : (
            <></>
          )}
          <FOHView>
            {activeQuestionnaire &&
            activeQuestionnaire?.screeningQuestion &&
            length(activeQuestionnaire?.screeningQuestion) > 0 ? (
              activeQuestionnaire?.screeningQuestion.map(
                (question, keyIndex) => (
                  <>
                    <FOHView
                      style={{
                        borderWidth: 1,
                        borderColor: FOHColors.GRAYSCALE_300,
                        borderRadius: 4,
                        paddingHorizontal: 16,
                        paddingVertical: 12,
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                        backgroundColor: FOHColors.WHITE
                      }}
                      testID={`QuestionnaireContainer-question-${keyIndex + 1}`}
                    >
                      <FOHLabel style={{ color: FOHColors.GRAYSCALE_900 }}>
                        {`${keyIndex + 1}.`}
                        <FOHSpace />
                        {question.question}
                      </FOHLabel>
                    </FOHView>
                    <FOHSpaceSmall />
                  </>
                )
              )
            ) : (
              <></>
            )}
          </FOHView>
        </FOHMessageInputSection>
      </FOHView>
    </ActionModal>
  );
};

export default ScreeningQuestionnaireModal;

const FieldLabel = styled(FOHLabel)`
  font-family: ${FOHFonts.MEDIUM};
  line-height: 16px;
  text-transform: uppercase;
  color: ${FOHColors.PRIMARY_TEAL_P1};
`;
