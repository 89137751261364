import React from 'react';

import { WithT } from 'i18next';

import {
  FOHButton,
  FOHColors,
  FOHView,
  FOHSpace,
  FOHSpaceLarger,
  FOHHeaderH2,
  FOHSmallDetailLabel
} from '../../components';

import { withReactiveModal, WithReactiveModalProps } from '../Navigation';

import { PLATFORM_TYPES } from './constants';

interface CampaignConfirmationModalProps extends WithReactiveModalProps, WithT {
  onPress: () => void;
  platformFor: string | undefined;
  confirmType: string;
  modalText: {
    headerLabel: string;
    indeedDetailLabel?: string;
    zipDetailLabel?: string;
    buttonLabel: string;
  };
}

const CampaignConfirmationModal = (props: CampaignConfirmationModalProps) => {
  const { t, modalText } = props;
  return (
    <FOHView
      style={{ alignItems: 'center', padding: 24 }}
      testID="CampaignConfirmationModal"
    >
      <FOHHeaderH2>{modalText.headerLabel}</FOHHeaderH2>
      <FOHSpace />
      <FOHSmallDetailLabel
        style={{ textAlign: 'justify', paddingHorizontal: 4 }}
      >
        {props?.platformFor === PLATFORM_TYPES.Indeed.toUpperCase()
          ? modalText.indeedDetailLabel
          : modalText.zipDetailLabel}
      </FOHSmallDetailLabel>
      <FOHSpaceLarger />
      <FOHView
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          paddingHorizontal: 4
        }}
      >
        <FOHButton
          title={t('common:cancel')}
          borderColor={FOHColors.BORDER_GRAY}
          color={FOHColors.WHITE}
          textColor={FOHColors.BLACK}
          style={{ width: 128 }}
          onPress={() => props.close && props.close()}
        />
        <FOHSpace />
        <FOHButton
          title={modalText.buttonLabel}
          color={
            props.confirmType === 'end'
              ? FOHColors.RED
              : FOHColors.PRIMARY_TEAL_P1
          }
          style={{ width: 128 }}
          onPress={props.onPress}
        />
      </FOHView>
    </FOHView>
  );
};

export default withReactiveModal(CampaignConfirmationModal);
