import React, { useState, useRef, useEffect, Fragment } from 'react';

import { prop, path, isEmpty, compose } from 'ramda';
import { useTranslation } from 'react-i18next';

import {
  FOHColors,
  FOHSearchBar,
  FOHBannerType,
  FOHView,
  FOHScrollView,
  FOHDivider,
  FOHSwitch,
  FOHLabeledCheckbox,
  FOHCapturePhoneModal,
  FOHUserNotificationPreference,
  FOHLabel,
  FOHSpace
} from '../../components';
import notification_ic from '../../images/notification_ic.svg';
import { handleMutation, useIsMobile } from '../../utils';
import {
  useGetEmployerNotificationPreferenceQuery,
  useMutateEmployerNotificationPreferenceMutation,
  useUpdateUserMutation
} from '../../graphql/generated';

import { useBanner } from '../Navigation/useBanner';
import { withEmployerSettings } from '../EmployerSettings/withEmployerSettings';
import { useGetMeData } from '../SignIn';

const NotificationSettings = _props => {
  const { isMobile } = useIsMobile();
  const { t } = useTranslation('NotificationPreference');
  const scrollRef = useRef();

  const { setBanner } = useBanner();
  const [searchText, setSearchText] = useState('');
  const [isUnsubscribed, setIsUnsubscribed] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [notificationPreferenceList, setNotificationPreferenceList] = useState(
    {}
  );
  const [previewList, setPreviewList] = useState([]);
  const [phone, setPhone] = useState('');
  const [openPhoneCaptureModal, setOpenPhoneCaptureModal] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [targetNotificationKey, setTargetNotificationKey] = useState();

  const { data: me, refetch: refetchMeQuery } = useGetMeData();

  const employerNotificationQuery = useGetEmployerNotificationPreferenceQuery({
    skip: !me?.employerProfileUser,
    variables: { employerProfileUserId: me?.employerProfileUser?.id }
  });

  const preferences = path(
    ['data', 'employerNotificationPreference'],
    employerNotificationQuery
  );

  const id = preferences?.id;

  const [userMutation] = useUpdateUserMutation();
  const [notificationMutation] =
    useMutateEmployerNotificationPreferenceMutation();
  // eslint-disable-next-line
  const savePreferences = async () => {
    if (!id) return;

    const { dailyDigest, chatMessage, interviewResponse } =
      notificationPreferenceList;
    const [, errors] = await handleMutation(
      notificationMutation({
        variables: {
          id,
          chatMessageSms: chatMessage.isTextChecked,
          chatMessageEmail: chatMessage.isEmailChecked,
          dailyDigestEmail: dailyDigest.isEmailChecked,
          interviewResponseSms: interviewResponse.isTextChecked,
          interviewResponseEmail: interviewResponse.isEmailChecked
        }
      })
    );
    setIsUpdated(false);
    if (errors) {
      setBanner({
        bannerMessage: t('preferencedNotSaved'),
        bannerType: FOHBannerType.DANGER
      });
    }
  };

  // Toggle all checkboxs
  const toggleAll = isText => {
    if (isEmpty(notificationPreferenceList)) {
      return;
    }

    setIsUpdated(true);
    let list = notificationPreferenceList;
    let isAnyNotifChecked = false;
    if (isText) {
      isAnyNotifChecked = Object.keys(notificationPreferenceList)?.every(
        key => notificationPreferenceList[key].isTextChecked === true
      );
      for (let key in list) {
        list[key] = { ...list[key], isTextChecked: !isAnyNotifChecked };
      }
    } else {
      isAnyNotifChecked = Object.keys(notificationPreferenceList)?.every(
        key => notificationPreferenceList[key].isEmailChecked === true
      );
      for (let key in list) {
        list[key] = { ...list[key], isEmailChecked: !isAnyNotifChecked };
      }
    }
    setNotificationPreferenceList(list);
  };

  const onCheckPress = (key, isText) => {
    if (isEmpty(notificationPreferenceList)) {
      return;
    }
    setIsUpdated(true);
    let targetNotification = notificationPreferenceList[key];

    if (isText) {
      targetNotification.isTextChecked = !targetNotification.isTextChecked;
    } else {
      targetNotification.isEmailChecked = !targetNotification.isEmailChecked;
    }
    notificationPreferenceList[key] = targetNotification;
    setNotificationPreferenceList(notificationPreferenceList);
  };

  const checkForPhoneNumber = (isAll, key) => {
    if (!me.phone) {
      if (key) {
        setTargetNotificationKey(key);
      } else {
        setIsSelectAll(true);
      }
      setOpenPhoneCaptureModal(true);
    } else {
      if (isAll) {
        toggleAll(true);
      } else {
        onCheckPress(key, true);
      }
    }
  };

  const onUnsubscribed = () => {
    setIsUpdated(true);
    let list = notificationPreferenceList;
    for (let key in list) {
      list[key] = {
        ...list[key],
        isTextChecked: false,
        isEmailChecked: false
      };
    }
    setNotificationPreferenceList(list);
  };

  const handleUnsubscribedChange = val => {
    setIsUnsubscribed(val);
    if (val) {
      onUnsubscribed();
    } else {
      setIsUpdated(false);
    }
  };

  // Refetch the notifications on mount
  useEffect(() => {
    (async () => {
      await employerNotificationQuery.refetch();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Filter settings
  useEffect(() => {
    if (isEmpty(notificationPreferenceList)) {
      return;
    }

    if (searchText) {
      let list = notificationPreferenceList;
      let filtered = {};
      for (let key in list) {
        if (
          list[key].title.toLowerCase().includes(searchText.toLowerCase()) ||
          list[key].description.toLowerCase().includes(searchText.toLowerCase())
        ) {
          filtered = { ...filtered, [key]: list[key] };
        }
      }
      setPreviewList(filtered);
    } else {
      setPreviewList(notificationPreferenceList);
    }
  }, [searchText, notificationPreferenceList]);

  // Set preferences from the query
  useEffect(() => {
    if (preferences) {
      if (isEmpty(preferences.notifications)) {
        return setBanner({
          bannerMessage: t('preferencesNotFound'),
          bannerType: FOHBannerType.DANGER
        });
      }

      let notificationObj = {};
      for (let key in preferences.notifications) {
        if (typeof preferences.notifications[key] === 'object') {
          notificationObj[key] = {
            title: t(`${key}Title`),
            description: t(`${key}Description`),
            isEmailChecked: !!preferences.notifications[key]?.email,
            isTextChecked: !!preferences.notifications[key]?.sms,
            isNotText: preferences?.notifications[key].sms === null,
            isNotEmail: preferences?.notifications[key].email === null
          };
        }
      }
      setNotificationPreferenceList(notificationObj);
    }
  }, [preferences, setBanner, t]);

  // Save preferences on update
  useEffect(() => {
    if (isUpdated) {
      savePreferences();
    }
  }, [isUpdated]);

  return (
    <FOHView>
      <FOHView
        style={{
          alignItems: 'flex-start',
          paddingHorizontal: isMobile ? 14 : 28
        }}
      >
        <FOHView
          style={{
            width: '100%',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            marginVertical: 16
          }}
        >
          <FOHView
            style={{ width: isMobile ? '100%' : 327, marginVertical: 10 }}
          >
            <FOHSearchBar
              onChangeSearch={text => setSearchText(text)}
              searchValue={searchText}
              searchPlaceholder={t('searchTextLabel')}
            />
          </FOHView>
          <FOHView
            style={{
              maxWidth: 298,
              minWidth: 232,
              width: '100%',
              marginVertical: 10
            }}
          >
            <FOHSwitch
              value={isUnsubscribed}
              onValueChange={val => handleUnsubscribedChange(val)}
              label={t('unsubscribeButtonLabel')}
              onColor={FOHColors.PACIFIC_BLUE}
              offColor={FOHColors.GRAY}
              onLabel={t('common:onLabel')}
              offLabel={t('common:offLabel')}
            />
          </FOHView>
        </FOHView>
        <FOHView
          style={{
            width: '100%',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
          testID="NotificationSettings-select-all"
        >
          <FOHView style={{ width: isMobile ? '35%' : '69%' }}>
            <FOHLabel style={{ color: isUnsubscribed ? FOHColors.GREY : '' }}>
              {t('PositionsFeature:selectAll')}
            </FOHLabel>
          </FOHView>
          <FOHView
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: isMobile ? '65%' : '31%',
              paddingRight: 10
            }}
          >
            <FOHView
              style={{
                width: '50%',
                alignItems: 'flex-end',
                marginRight: 'auto'
              }}
            >
              <FOHLabeledCheckbox
                selected={Object.keys(previewList)?.every(
                  key =>
                    previewList[key].isNotText ||
                    previewList[key].isTextChecked === true
                )}
                checkboxLabel={t('checkboxSMSLabel')}
                setSelected={() => {
                  checkForPhoneNumber(true);
                }}
                style={{
                  fontSize: 14,
                  cursor: isUnsubscribed ? 'default' : 'auto'
                }}
                reverse
              />
            </FOHView>
            <FOHView style={{ width: '50%', alignItems: 'flex-end' }}>
              <FOHLabeledCheckbox
                selected={Object.keys(previewList)?.every(
                  key =>
                    previewList[key].isNotEmail ||
                    previewList[key].isEmailChecked === true
                )}
                checkboxLabel={t('checkboxEmailLabel')}
                setSelected={() => {
                  toggleAll(false);
                }}
                style={{
                  fontSize: 14,
                  cursor: isUnsubscribed ? 'default' : 'auto'
                }}
                reverse
              />
            </FOHView>
          </FOHView>
        </FOHView>
        <FOHSpace />
        <FOHDivider />
        <FOHSpace />

        <FOHScrollView
          style={{
            height: isMobile ? undefined : '59vh',
            width: '100%'
          }}
          ref={scrollRef}
        >
          <FOHView>
            {previewList &&
              Object.keys(previewList).map((key, index) => (
                <Fragment key={index}>
                  <FOHView style={{ paddingBottom: isMobile ? 15 : 20 }}>
                    <FOHUserNotificationPreference
                      title={previewList[key].title}
                      description={previewList[key].description}
                      checkboxTextLabel={t('checkboxSMSLabel')}
                      checkboxEmailLabel={t('checkboxEmailLabel')}
                      emailSelected={previewList[key].isEmailChecked}
                      textSelected={previewList[key].isTextChecked}
                      setTextSelected={() => checkForPhoneNumber(false, key)}
                      setEmailSelected={() => onCheckPress(key, false)}
                      highlightedText={searchText}
                      isDisabled={isUnsubscribed}
                      isNotText={previewList[key].isNotText}
                      isNotEmail={previewList[key].isNotEmail}
                    />
                  </FOHView>
                </Fragment>
              ))}
          </FOHView>
        </FOHScrollView>
      </FOHView>
      {openPhoneCaptureModal && (
        <FOHCapturePhoneModal
          open={openPhoneCaptureModal}
          close={() => {
            setOpenPhoneCaptureModal(false);
          }}
          image={notification_ic}
          headerLabel={t('phoneCaptureTitleLabel')}
          detailLabel={t('phoneCaptureDetailLabel')}
          buttonLabel={t('phoneCaptureOKLabel')}
          confirm={async () => {
            const [, errors] = await handleMutation(
              userMutation({
                variables: {
                  id: prop('id', me),
                  firstName: prop('firstName', me),
                  lastName: prop('lastName', me),
                  email: prop('email', me),
                  phone
                }
              })
            );
            if (errors) {
              setBanner({
                bannerMessage: t('phoneNotSaved', { message: errors.phone }),
                bannerType: FOHBannerType.DANGER
              });
            } else {
              refetchMeQuery();
              if (isSelectAll === true) {
                toggleAll(true);
              } else {
                onCheckPress(targetNotificationKey, true);
              }
            }
            setOpenPhoneCaptureModal(false);
          }}
          cancel={() => {
            setOpenPhoneCaptureModal(false);
          }}
          dialog={true}
          imageHeight={158}
          imageWidth={176}
          phone={phone}
          setPhone={setPhone}
        />
      )}
    </FOHView>
  );
};

export default compose(withEmployerSettings)(NotificationSettings);
