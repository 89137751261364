import {
  useNavigate,
  To,
  NavigateOptions,
  useLocation
} from 'react-router-dom';

export const useNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateTo = (to: To, options?: NavigateOptions) => {
    navigate(to, options);
  };

  const goBack = () => {
    navigate(-1);
  };

  return { navigateTo, goBack, location };
};
