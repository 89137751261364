import React from 'react';

import { compose } from 'ramda';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components/native';

import {
  FOHButton,
  FOHColors,
  FOHFonts,
  FOHForm,
  FOHGrayX,
  FOHHeaderH2,
  FOHIconButton,
  FOHLocationPin,
  FOHSearchableSelectDropdown,
  FOHSelect,
  FOHSelectItem,
  FOHSmallDetailLabel,
  FOHSpace,
  FOHSpaceLarger,
  FOHTextField,
  FOHView
} from '../../../components';
import { useIsMobile } from '../../../utils';
import { ROLES } from '../../../constants';

const FOHAddOrEditTeammate = ({
  t,
  headerTitle,
  handleOnClose,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  checkEmail,
  permissionType,
  setPermissionType,
  permissionOptions,
  isOpenLocationDropdown,
  setIsOpenLocationDropdown,
  selectedLocationFilters,
  setLocationSearch,
  locationSearch,
  handleMultiSelectLocation,
  locationOptions,
  handleSelectAllLocations,
  submitBtnLabel,
  handleOnSubmit,
  errors,
  zIndex = undefined,
  isDisabledSubmit = false
}) => {
  const { isMobile } = useIsMobile();

  return (
    <FOHForm handleSubmit={handleOnSubmit}>
      <FOHView
        style={{
          width: '100%',
          padding: 17,
          backgroundColor: FOHColors.WHITE,
          borderWidth: 1,
          borderRadius: 4,
          borderColor: FOHColors.GRAYSCALE_200,
          zIndex: zIndex
        }}
      >
        <FOHView
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <FOHHeaderH2 style={{ fontFamily: FOHFonts.REGULAR }}>
            {headerTitle}
          </FOHHeaderH2>
          <FOHIconButton icon={() => <FOHGrayX />} onPress={handleOnClose} />
        </FOHView>
        <FOHSpace />
        <Row style={{ flexDirection: isMobile ? 'column' : 'row', zIndex: 3 }}>
          <Column1 style={{ width: isMobile ? '100%' : '47%' }}>
            <FOHView style={{ flex: 1 }}>
              <FOHTextField
                placeholder={t('firstNamePlaceholder')}
                value={firstName}
                required={true}
                onChangeText={value => setFirstName(value)}
                error={errors?.firstName || null}
              />
            </FOHView>
            <FOHSpace />
            <FOHView style={{ flex: 1 }}>
              <FOHTextField
                placeholder={t('lastNamePlaceholder')}
                value={lastName}
                required={true}
                onChangeText={value => setLastName(value)}
                error={errors?.lastName || null}
              />
            </FOHView>
          </Column1>
          {isMobile ? <FOHSpace /> : <FOHSpaceLarger />}
          <Column2 style={{ width: isMobile ? '100%' : '47%' }}>
            <FOHTextField
              placeholder={t('emailPlaceholder')}
              value={email}
              autoCompleteType={'email'}
              required={true}
              onChangeText={value => setEmail(value)}
              error={errors?.email || null}
              onBlur={() => {
                checkEmail(email);
              }}
            />
          </Column2>
        </Row>
        <FOHSpace />
        <Row style={{ flexDirection: isMobile ? 'column' : 'row', zIndex: 2 }}>
          <Column1 style={{ width: isMobile ? '100%' : '47%' }}>
            <FOHView style={{ flex: 1 }} testID="FOHSelect-Role">
              <FOHSelect
                selectedValue={permissionType}
                placeholder={t('Integrations:role')}
                onValueChange={value => setPermissionType(value)}
                style={{ width: '100%' }}
                neverEmpty
                testID="FOHSelect-add-edit-teammate"
              >
                {permissionOptions.map(permission => (
                  <FOHSelectItem
                    key={permission.label}
                    label={permission.label}
                    value={permission.value}
                    testID={`FOHSelect-cell-${permission.label}`}
                  />
                ))}
              </FOHSelect>
            </FOHView>
            <FOHSpace />
            <FOHView style={{ flex: 1 }}>
              <FOHSmallDetailLabel style={{ fontSize: 12, lineHeight: 18 }}>
                {t(
                  permissionOptions.find(
                    pType => pType.value === permissionType
                  )?.description
                )}
              </FOHSmallDetailLabel>
            </FOHView>
          </Column1>
          {isMobile ? <FOHSpace /> : <FOHSpaceLarger />}
          <Column2 style={{ width: isMobile ? '100%' : '47%' }}>
            {permissionType !== ROLES.ADMIN ? (
              <FOHSearchableSelectDropdown
                style={{ width: '100%' }}
                open={isOpenLocationDropdown}
                setOpen={open => setIsOpenLocationDropdown(open)}
                selectedValue={selectedLocationFilters}
                selectedLabel={
                  !selectedLocationFilters[0]
                    ? 'No location selected'
                    : selectedLocationFilters.length === 1
                    ? undefined
                    : `${selectedLocationFilters.length} Locations`
                }
                optionsContainerStyle={{
                  maxHeight: 400,
                  maxWidth: 590
                }}
                showLabel={t('common:show')}
                onSearchChange={setLocationSearch}
                searchValue={locationSearch}
                multiSelect={true}
                searchPlaceholder={t('common:search')}
                onValueChange={handleMultiSelectLocation}
                options={locationOptions}
                fieldLabel={t('locations').toUpperCase()}
                icon={() => <FOHLocationPin />}
                testID="add-edit-teammate-location-filter"
                selectAllLabel={t('CandidateFeed:selectAllLocations')}
                isSelectAllSelected={
                  locationOptions.length === selectedLocationFilters.length
                }
                onPressSelectAll={handleSelectAllLocations}
              />
            ) : null}
          </Column2>
        </Row>
        <FOHSpace />
        <Row style={{ flexDirection: isMobile ? 'column' : 'row', zIndex: 1 }}>
          <Column1 style={{ width: isMobile ? '100%' : '47%' }} />
          {isMobile ? null : <FOHSpaceLarger />}
          <Column2
            style={{
              width: isMobile ? '100%' : '47%',
              alignItems: isMobile ? 'center' : 'flex-end'
            }}
          >
            <FOHButton
              title={submitBtnLabel}
              onPress={handleOnSubmit}
              disabled={isDisabledSubmit}
              style={{
                maxWidth: 171,
                width: '100%'
              }}
            />
          </Column2>
        </Row>
      </FOHView>
    </FOHForm>
  );
};

const Row = styled(FOHView)`
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

const Column1 = styled(FOHView)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Column2 = styled(FOHView)`
  align-items: center;
`;

export default compose(withTranslation('MyTeamFeature'))(FOHAddOrEditTeammate);
