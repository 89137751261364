import React from 'react';

import { useTranslation } from 'react-i18next';
import { defaultTo, length, path } from 'ramda';

import {
  FOHLabel,
  FOHColors,
  FOHButton,
  FOHBannerType,
  FOHSpaceSmall,
  FOHSpaceLarge,
  FOHSpace,
  FullWidthCard,
  FOHView,
  FOH7ShiftsIcon,
  FOHBlueSuccessIcon,
  FOHXIcon,
  FOHDivider,
  FOHAccountConnected
} from '../../components';
import {
  GetCompaniesDocument,
  GetMeDocument,
  useDelete7ShiftsGuidMutation
} from '../../graphql/generated';
import { handleMutation, toLocaleDateTime, useIsMobile } from '../../utils';

import { useGetCompanies } from './useGetCompanies';

const Integration7Shifts = props => {
  const { setBanner, onPressAddAccount } = props;
  const { t } = useTranslation('Integrations');
  const { isMobile, width } = useIsMobile();

  const mobile = isMobile && width <= 627;

  const [delete7ShiftsGuidMutation] = useDelete7ShiftsGuidMutation();

  const { data } = useGetCompanies();

  const companies = defaultTo([], path(['sevenshiftsCompanies'], data));

  const hasCompanies = length(companies) > 0;

  const onPressDisconnect = async ID => {
    const [response, error] = await handleMutation(
      delete7ShiftsGuidMutation({
        variables: {
          id: ID
        },
        refetchQueries: [
          {
            query: GetCompaniesDocument,
            variables: {}
          },
          {
            query: GetMeDocument,
            variables: {}
          }
        ]
      })
    );

    if (error || !response) {
      setBanner({
        bannerType: FOHBannerType.ERROR,
        bannerMessage: error.message
      });
    }
  };

  return (
    <FOHView
      style={{
        width: '100%'
      }}
    >
      <FOHView
        style={{
          paddingTop: isMobile ? 8 : 16,
          borderRadius: 16
        }}
      >
        <FullWidthCard
          shadow
          noBorder
          style={{
            paddingHorizontal: isMobile ? 14 : 28,
            paddingTop: 28
          }}
        >
          <FOHView
            style={{
              flexDirection: mobile ? 'column' : 'row',
              alignItems: mobile ? 'flex-start' : 'center',
              justifyContent: 'space-between',
              flex: 1,
              flexWrap: 'wrap'
            }}
          >
            <FOHView
              style={{
                width: mobile ? '100%' : 'unset'
              }}
            >
              <FOH7ShiftsIcon />
            </FOHView>
            {mobile ? <FOHSpace /> : null}
            <FOHView
              style={{
                alignItems: width >= 467 ? 'center' : 'flex-end',
                justifyContent:
                  mobile && width >= 468 && hasCompanies
                    ? 'space-between'
                    : 'flex-end',
                flexDirection: width >= 467 ? 'row' : 'column',
                flex: mobile ? 'unset' : 1,
                flexWrap: 'wrap',
                width: mobile ? '100%' : 'unset'
              }}
            >
              {hasCompanies ? (
                <>
                  <FOHView
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      minWidth: 175,
                      width: 175,
                      height: 40,
                      backgroundColor: FOHColors.PRIMARY_TEAL_10,
                      borderRadius: 4
                    }}
                  >
                    <FOHBlueSuccessIcon
                      style={{ tintColor: FOHColors.PRIMARY_TEAL_300 }}
                    />
                    <FOHSpaceSmall />
                    <FOHLabel
                      style={{
                        color: FOHColors.PRIMARY_TEAL_300,
                        fontWeight: 700
                      }}
                    >
                      {t('connected')}
                    </FOHLabel>
                  </FOHView>
                  <FOHSpaceSmall />
                </>
              ) : null}
              <FOHView style={{ width: 175, maxWidth: 175 }}>
                <FOHButton
                  title={
                    hasCompanies ? t('addNewAccount') : t('addIntegration')
                  }
                  icon={
                    hasCompanies
                      ? () =>
                          FOHXIcon({
                            style: {
                              transform: [{ rotate: '135deg' }],
                              width: 15,
                              height: 15,
                              tintColor: FOHColors.WHITE
                            }
                          })
                      : undefined
                  }
                  onPress={onPressAddAccount}
                  selected={true}
                  style={{ height: 40, maxWidth: 175 }}
                  textStyle={{ color: FOHColors.WHITE, fontWeight: 700 }}
                />
              </FOHView>
            </FOHView>
          </FOHView>
          {isMobile ? <FOHSpace /> : <FOHSpaceLarge />}
          {hasCompanies && (
            <>
              <FOHDivider color={FOHColors.BORDER_GRAY} />
              {isMobile ? <FOHSpaceSmall /> : <FOHSpace />}
              <FOHView
                style={{
                  width: '100%'
                }}
              >
                <FOHView
                  style={{
                    paddingVertical: 5
                  }}
                >
                  <FOHLabel
                    style={{
                      fontSize: 18,
                      lineHeight: 24,
                      fontWeight: 600,
                      color: FOHColors.GRAYSCALE_900
                    }}
                  >
                    {t('yourConnectedAccounts')}
                  </FOHLabel>
                </FOHView>

                <FOHView
                  style={{
                    paddingVertical: 22,
                    paddingHorizontal: isMobile ? 0 : 10
                  }}
                >
                  {companies.map((company, index) => {
                    const createdAt = toLocaleDateTime(company?.createdAt);
                    const createdBy = `${path(
                      ['createdBy', 'firstName'],
                      company
                    )} ${path(['createdBy', 'lastName'], company)}`;

                    return (
                      <React.Fragment key={index}>
                        <FOHAccountConnected
                          accountName={company.companyName}
                          connectedBy={`${t('connected')}: ${createdAt} ${t(
                            'by'
                          )} ${createdBy}`}
                          disconnectLabel={t('disconnect')}
                          onPressDisconnect={() =>
                            onPressDisconnect(company?.id)
                          }
                        />
                        <FOHSpace />
                      </React.Fragment>
                    );
                  })}
                </FOHView>
              </FOHView>
            </>
          )}
        </FullWidthCard>
      </FOHView>
    </FOHView>
  );
};
export default Integration7Shifts;
