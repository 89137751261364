import React from 'react';

import { prop } from 'ramda';

import { FOHView } from '../../ingredients';

import { FOHSelectTextTagCell } from '../cells/FOHSelectTextTagCell';

import { FOHSelectItemProps } from './FOHSelect';

export interface FOHMultiSelectTextTagsProps {
  options: Array<FOHSelectItemProps>;
  selected?: Array<string>;
  selectItem: (val: string) => any;
  section?: string;
}

export const FOHMultiSelectTextTags = (props: FOHMultiSelectTextTagsProps) => (
  <FOHView style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
    {props.options.map(item => (
      <FOHSelectTextTagCell
        key={item.value}
        value={item.value}
        onPress={props.selectItem}
        label={item.label}
        selected={
          props.selected && props.selected.includes(prop('value', item))
            ? true
            : false
        }
      />
    ))}
  </FOHView>
);
