import React from 'react';

import styled from 'styled-components/native';

import {
  FOHButton,
  FOHHeaderText,
  Screen,
  FOHDetailText,
  FOHView
} from '../../ingredients';
import { FOHVideoInput, FOHVideoInputProps, FOHGoBack } from '../../recipes';

export interface FOHUploadVideoProps extends FOHVideoInputProps {
  handleSubmit: () => void;
  setVideo: (value: string) => void;
  uploadVideo: () => void;
  uploading: boolean;
  title?: string;
  submitLabel: string;
  detailText: string;
  disabledSubmit?: boolean;
  error?: any;
  goBack?: () => void;
  largeHeader?: boolean;
  isModal?: boolean;
}

export const FOHUploadVideoScreen = (props: FOHUploadVideoProps) => (
  <Screen>
    <FOHView
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: props.isModal ? 0 : 60
      }}
    >
      {props.goBack && <FOHGoBack goBack={props.goBack} />}
      <FOHView
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          width: '100%',
          maxWidth: 500
        }}
      >
        {!!props.title && (
          <FOHHeaderText
            style={{
              fontSize: props.largeHeader ? 30 : 25
            }}
          >
            {props.title}
          </FOHHeaderText>
        )}
      </FOHView>
      <FOHView style={{ paddingTop: 8 }} />
      <FOHView
        style={{
          width: props.largeHeader ? 500 : 260,
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <FOHDetailText>{props.detailText}</FOHDetailText>
      </FOHView>
      <FOHView style={{ paddingTop: 18 }} />
      <Row>
        <FOHVideoInput
          //@ts-ignore video
          video={props.video}
          pickVideo={props.pickVideo}
          VideoComponent={props.VideoComponent}
          uploadLabel={props.uploadLabel}
          error={props.error && props.error.photo ? props.error.photo : null}
        />
      </Row>
      <FOHView style={{ paddingTop: 8 }} />
      <FOHButton
        onPress={props.handleSubmit}
        title={props.submitLabel}
        disabled={props.disabledSubmit}
      />
    </FOHView>
  </Screen>
);

const Row = styled(FOHView)`
  width: 327px;
  display: flex;
  flex-direction: row;
  padding-bottom: 12px;
`;
