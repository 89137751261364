import React from 'react';

import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

export const Head = React.memo(
  ({ title = '', image = '', description = '', noIndex = false }) => {
    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
        {noIndex === true && <meta name="robots" content="noindex" />}
      </Helmet>
    );
  }
);

Head.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  description: PropTypes.string
};
