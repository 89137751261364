import React, { FunctionComponent } from 'react';

import job from '../../../images/job-position.svg';

import { FOHImage } from '../../ingredients';
import { FOHIconProps } from '../../typings';

export const FOHJobPositionIcon: FunctionComponent<FOHIconProps> = props => {
  return (
    <FOHImage
      source={{ uri: job }}
      style={[{ width: 22, height: 22 }, props?.style]}
    />
  );
};
