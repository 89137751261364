import React from 'react';

import { Dimensions, ScrollView, ViewStyle } from 'react-native';
import { defaultTo } from 'ramda';

import { FOHDialog } from '..';

export interface FOHPageModalProps {
  children?: any;
  open?: boolean;
  close?: () => any;
  modalStyle?: ViewStyle;
  leftContentShift?: number;
  containerStyle?: any;
}

export const FOHPageModal = (props: FOHPageModalProps) => (
  <FOHDialog
    open={props.open}
    close={props.close}
    leftContentShift={props.leftContentShift}
    style={{
      alignSelf: 'center',
      top: props.open ? 100 : 0,
      height: props.open ? '85%' : 0,
      width:
        Dimensions.get('window').width * 0.95 -
        defaultTo(0, props.leftContentShift) * 2,
      maxWidth: 1440,
      borderRadius: 6,
      ...props?.modalStyle
    }}
    containerStyle={props.containerStyle}
  >
    <ScrollView style={{ width: '100%' }}>{props.children}</ScrollView>
  </FOHDialog>
);
