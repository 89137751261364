import React, { Dispatch, Fragment, SetStateAction } from 'react';

import { View, ViewStyle, StyleProp } from 'react-native';
import { length } from 'ramda';
import styled from 'styled-components/native';

import { FOHOther } from '../../../features/CareerPage/adapters';

import {
  FOHColors,
  FOHTextField,
  FOHLabelBold,
  FOHRequiredMark,
  FOHView
} from '../../ingredients';

import { FOHSelectItemProps } from '../select/FOHSelect';

import { FOHSelectionCell } from './FOHSelectionCell';

export enum FOHQuestionType {
  Options = 'OPTIONS',
  OptionsOther = 'OPTIONS_WITH_OTHER',
  TxtInput = 'TEXT'
}

interface FOHQuestionOption extends FOHSelectItemProps {
  id: string;
  displayIcon?: string;
  displayLabel?: string;
  ordering?: number;
}

export interface FOHQuestionProps {
  category?: string;
  id: string;
  options: Array<FOHQuestionOption>;
  placeholder?: string;
  questionType: FOHQuestionType;
  required?: boolean;
  title: string;
}

export interface FOHSetAnswer {
  freeText?: string;
  optionId: string;
  questionId: string;
  value: string;
}

export interface FOHQuestionCellProps extends FOHQuestionProps {
  answer: FOHSetAnswer;
  careerPage?: boolean;
  cellWidth?: string;
  containerStyle?: StyleProp<ViewStyle>;
  updateAnswer:
    | ((answer: FOHSetAnswer) => void)
    | Dispatch<SetStateAction<FOHSetAnswer>>;
}

export const FOHQuestionCell = ({
  answer,
  careerPage = false,
  cellWidth = '50%',
  containerStyle,
  id,
  options = [],
  placeholder,
  questionType,
  required,
  title,
  updateAnswer
}: FOHQuestionCellProps) => {
  const { freeText = '', value = '' } = answer || {};
  const lastIndex = length(options) - 1;

  const isValueEqOther = value.toLowerCase() === FOHOther;
  const isOptionsWithOther = questionType === FOHQuestionType.OptionsOther;
  const isOptionsOrOther = [
    FOHQuestionType.Options,
    FOHQuestionType.OptionsOther
  ].includes(questionType);
  const isTextInput = questionType === FOHQuestionType.TxtInput;

  return (
    <View>
      <View style={{ flexDirection: 'row', marginBottom: 16 }}>
        <FOHLabelBold>{title}</FOHLabelBold>
        {required && <FOHRequiredMark />}
      </View>

      {isOptionsOrOther && (
        <CellWrapper style={containerStyle}>
          {options.map((option: FOHQuestionOption, i: number) => {
            const isLastIndex = i === lastIndex;
            const isSelected = option.value === value;
            const isSelectedEqOther =
              isValueEqOther && option.value === FOHOther;
            const isOtherTextField =
              isLastIndex && isOptionsWithOther && isValueEqOther;

            return (
              <Fragment key={`fragment-${i}`}>
                <FOHSelectionCell
                  bordered={careerPage}
                  cellStyle={{
                    backgroundColor: FOHColors.WHITE,
                    padding: 1,
                    paddingLeft: 4
                  }}
                  key={`${title}-${i}`}
                  label={option.label}
                  onPress={() =>
                    updateAnswer({
                      questionId: id,
                      optionId: option.id,
                      value: option.value
                    })
                  }
                  selected={isSelected || isSelectedEqOther}
                  style={{
                    backgroundColor: FOHColors.WHITE,
                    border: careerPage ? '1px solid #D0D5DD' : null,
                    minWidth: 'auto',
                    margin: CELL_GAP / 2,
                    flexBasis: `calc(${cellWidth} - ${CELL_GAP}px)`,
                    height: 50
                  }}
                  value={option.value}
                />

                {isOtherTextField && (
                  <FOHView
                    style={{
                      flexBasis: `calc(${cellWidth} - ${CELL_GAP}px)`,
                      margin: CELL_GAP / 2
                    }}
                  >
                    <FOHTextField
                      height="auto"
                      key={`${title}-${i + 1}`}
                      onChangeText={(text: string) =>
                        updateAnswer({
                          ...answer,
                          questionId: id,
                          freeText: text
                        })
                      }
                      placeholder={placeholder}
                      value={freeText}
                    />
                  </FOHView>
                )}
              </Fragment>
            );
          })}
        </CellWrapper>
      )}

      {isTextInput && (
        <FOHTextField
          placeholder={placeholder}
          value={freeText}
          onChangeText={(text: string) =>
            updateAnswer({
              ...answer,
              questionId: id,
              freeText: text
            })
          }
        />
      )}
    </View>
  );
};

const CELL_GAP = 12;
const CellWrapper = styled(View)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
