import { useState, useEffect } from 'react';

const MAX_STOCK_PHOTOS = 26;

export const useGetStockPhotos = getDataFromS3Callback => {
  const [stockPhotoCount, setStockPhotoCount] = useState(6);
  const [defaultStockPhotos, setDefaultStockPhotos] = useState([]);

  const loadMore = () => {
    if (stockPhotoCount < MAX_STOCK_PHOTOS) {
      setStockPhotoCount(stockPhotoCount + 3);
    }
  };

  useEffect(() => {
    // Load more stockPhotos when the list count changes
    const avatars = new Array(stockPhotoCount).fill({}).map((_, index) => {
      const avatar = getDataFromS3Callback(index + 1);
      return avatar;
    });
    setDefaultStockPhotos(avatars);
  }, [getDataFromS3Callback, stockPhotoCount]);

  return {
    defaultStockPhotos,
    loadMore
  };
};
