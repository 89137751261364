import React from 'react';

import { useGoogleMap } from '@react-google-maps/api';

export const MapCenter = props => {
  const map = useGoogleMap();
  const { getMapCenter, setGetMapCenter, setMapCenter } = props;
  // on drag of map update new center so we can send to the server
  React.useEffect(() => {
    const latitude = map.center ? map.center.lat() : null;
    const longitude = map.center ? map.center.lng() : null;
    if (getMapCenter && latitude && longitude) {
      setMapCenter({ latitude, longitude });
      setGetMapCenter(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMapCenter]);

  return <></>;
};
