import React from 'react';

import notificationCompletedInterviewCompleted from '../../../images/notification-completed-interview_completed.svg';

import { FOHImage } from '../../ingredients';

export interface FOHNotificationCompletedInterviewCompletedProps {}

export const FOHNotificationCompletedInterviewCompleted = (
  props: FOHNotificationCompletedInterviewCompletedProps
) => (
  <FOHImage
    {...props}
    source={{ uri: notificationCompletedInterviewCompleted }}
    style={{ width: 24, height: 24 }}
    testID={'FOHNotificationCompletedInterview'}
  />
);
