import React from 'react';

import styled from 'styled-components/native';
import { View, Text } from 'react-native';

import { FOHColors, FOHFonts } from '../../ingredients';

export interface FOHDayCircleProps {
  day: string;
  active?: boolean;
  disabled?: boolean;
}

export const FOHDayCircle = (props: FOHDayCircleProps) => (
  <Circle
    style={{
      backgroundColor: props.active
        ? props.disabled
          ? FOHColors.CYBERSPACE_GREY
          : '#38A56B'
        : '#C4C4C4'
    }}
  >
    <CircleText
      style={{
        color: FOHColors.WHITE
      }}
    >
      {props.day}
    </CircleText>
  </Circle>
);

const Circle = styled(View)`
  border-radius: 50%;
  height: 18px;
  width: 18px;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
  margin-top: 6px;
`;

const CircleText = styled(Text)`
  font-family: ${FOHFonts.MEDIUM};
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
`;
