import React from 'react';

import { path } from 'ramda';

import {
  FOHInverseButton,
  FOHSendMessageIcon,
  FOHBareTextField,
  FOHMessageTemplateBlue,
  FOHAttachmentIcon,
  FOHCalendarScheduledIcon,
  FOHDivider,
  FOHInverseButtonProps,
  FOHButtonBordered,
  FOHColors,
  FOHView
} from '../../ingredients';
import { FOH_INPUT_HEIGHT } from '../../constants';

export interface FOHCustomChatInputProps {
  placeholder?: string;
  message: string;
  setMessage: (message: string) => void;
  disabled?: boolean;
  onStartTyping?: () => void;
  onSendMessage: () => void;
  onFileUpload: () => void;
  onUseTemplate: () => void;
  onScheduleInterview: () => void;
  height?: number;
  setHeight: (height: number) => void;
  scheduleText: string;
  scheduleButtonProps?: FOHInverseButtonProps;
  isActionDisabled?: boolean;
}

export const FOHCustomChatInput = (props: FOHCustomChatInputProps) => (
  <FOHView style={{ flexDirection: 'column' }}>
    <FOHView
      style={{
        alignItems: 'center',
        marginBottom: 10,
        marginTop: 20
      }}
    >
      <FOHButtonBordered
        icon={() => <FOHCalendarScheduledIcon />}
        title={props.scheduleText}
        onPress={props.onScheduleInterview}
        style={{ width: 140, height: 40 }}
        {...props.scheduleButtonProps}
      />
    </FOHView>
    <FOHDivider />
    <FOHView
      style={{
        flexDirection: 'row',
        marginTop: 20,
        marginHorizontal: 20
      }}
    >
      <FOHView style={{ flex: 1 }}>
        <FOHBareTextField
          {...props}
          //@ts-ignore height
          height={props.height}
          disabled={props.isActionDisabled}
          value={props.message}
          onChangeText={props.setMessage}
          multiline
          // blurOnSubmit={true}
          placeholder={props.placeholder}
          paddingRight={'94px'}
          style={{ maxHeight: 300, minHeight: FOH_INPUT_HEIGHT }}
          onContentSizeChange={(event: any) => {
            const height = event.nativeEvent.contentSize.height;
            if (
              (props.height && props.height === height) ||
              (height > 300 && props.height === 300)
            ) {
              return;
            }
            if (props.height && height < 300) {
              if (props.height < height) {
                props.setHeight(event.nativeEvent.contentSize.height);
              } else {
                props.setHeight(props.height - 28);
              }
            } else {
              props.setHeight(300);
            }
          }}
          onKeyPress={(e: any) => {
            const key = path(['nativeEvent', 'key'], e);
            if (key === 'Enter' && !path(['nativeEvent', 'shiftKey'], e)) {
              e.preventDefault();
              props.onSendMessage();
            }
          }}
        />
        <FOHView
          style={{
            position: 'absolute',
            right: 5,
            bottom: 5,
            padding: 4,
            borderRadius: 4,
            backgroundColor: FOHColors.WHITE
          }}
        >
          <FOHInverseButton
            icon={() => <FOHAttachmentIcon />}
            title={''}
            testID={'message-attachment'}
            onPress={props.onFileUpload}
            color={FOHColors.LIGHT_BLUE}
            opaque={true}
            textColor={FOHColors.BLUE}
            selected={true}
            centerIcon={true}
            style={{ width: 33, height: 33, marginLeft: 8 }}
          />
        </FOHView>
        <FOHView
          style={{
            position: 'absolute',
            right: 45,
            bottom: 5,
            padding: 4,
            borderRadius: 4,
            backgroundColor: FOHColors.WHITE
          }}
        >
          <FOHInverseButton
            icon={() => <FOHMessageTemplateBlue />}
            title={''}
            testID={'message-template'}
            onPress={props.onUseTemplate}
            color={FOHColors.LIGHT_BLUE}
            opaque={true}
            textColor={FOHColors.BLUE}
            selected={true}
            centerIcon={true}
            style={{ width: 33, height: 33, marginLeft: 8 }}
          />
        </FOHView>
      </FOHView>
      <FOHView
        style={{
          justifyContent: 'flex-end'
        }}
      >
        <FOHInverseButton
          icon={() => <FOHSendMessageIcon />}
          title={''}
          testID={'send-message'}
          onPress={props.onSendMessage}
          color={FOHColors.LIGHT_BLUE}
          opaque={true}
          textColor={FOHColors.BLUE}
          selected={true}
          centerIcon={true}
          style={{ width: 50, height: 50, marginLeft: 8 }}
        />
      </FOHView>
    </FOHView>
  </FOHView>
);
