import React from 'react';

import styled from 'styled-components/native';
import { View } from 'react-native';

import { FOHTagSelect, FOHLabel, FOHTouchableOpacity } from '../../ingredients';

export interface FOHSelectTextTagCellProps {
  label: string;
  value: string;
  onPress: (value: string) => void;
  selected: boolean;
  style?: any;
  minWidth?: string;
}

export const FOHSelectTextTagCell = (props: FOHSelectTextTagCellProps) => (
  <Border
    selected={props.selected}
    style={{ ...props.style, minWidth: props.minWidth || 90 }}
  >
    <FOHTouchableOpacity
      style={{
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        height: '100%'
      }}
      testID={'select-cell'}
      onPress={e => {
        e.preventDefault();
        props.onPress(props.value);
      }}
    >
      <FOHLabel
        style={{
          fontSize: 12,
          color: props.selected ? '#51C1DB' : '#171717'
        }}
      >
        {props.label}
      </FOHLabel>
      <View style={{ padding: 6 }} />
      <FOHTagSelect selected={props.selected} />
    </FOHTouchableOpacity>
  </Border>
);

const Border = styled(View)<{ selected: boolean }>`
  display: inline-flex;
  align-items: start;
  flex-direction: column;
  border-radius: 16px;
  justify-content: center;
  min-width: 0px;
  height: 32px;
  border: 1px solid #171717;
  margin: 4px;
  padding-left: 20px;
  padding-right: 10px;
  border-color: ${(props: any) => (props.selected ? `#51C1DB` : `#171717`)};
  background-color: #ffffff;
`;
