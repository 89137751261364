import React from 'react';

import dashboard_ic from '../../../images/dashboard_ic.svg';

import { FOHImage } from '../../ingredients';

export const FOHDashboardIcon = (props: any) => (
  <FOHImage
    source={{ uri: dashboard_ic }}
    style={
      props && !!props.style
        ? { width: 24, height: 24, ...props.style }
        : { width: 24, height: 24 }
    }
  />
);
