import React from 'react';

import { prop, path } from 'ramda';
import { useTranslation } from 'react-i18next';

import { toDate, toDateTime } from '../../utils';
import {
  FOHCandidateStateBanner,
  FOHCandidateStateBannerEnum,
  FOHCandidateStateCardBanner
} from '../../components';

import { useCurrentLocationContext } from '../Locations/useCurrentLocationContext';

// order of importance for employer users, 1. scheduled/accepted, 2. application, 3. requested
const getCandidateStateString = (
  t,
  lastInterview,
  application,
  candidateFirstName,
  locationFilter
) => {
  const interviewDetails = {
    name: candidateFirstName,
    scheduledFor: toDateTime(prop('scheduledFor', lastInterview)),
    locationName: path(['location', 'name'], lastInterview),
    positionName: path(['position', 'positionName'], lastInterview)
  };
  return prop('status', lastInterview) === 'SCHEDULED'
    ? t('candidateStateInterview', {
        ...interviewDetails
      })
    : prop('status', lastInterview) === 'ACCEPTED'
    ? t('candidateStateInterviewUnscheduled', { ...interviewDetails })
    : prop('status', lastInterview) === 'PENDING' &&
      path(['location', 'id'], lastInterview) === locationFilter
    ? t('candidateStateInterviewPending', {
        ...interviewDetails
      })
    : !!application && prop('createdAt', application)
    ? // application at this location
      t('candidateStateApplied', {
        name: candidateFirstName,
        appliedOn: toDateTime(prop('createdAt', application)),
        locationName: path(['position', 'location', 'name'], application),
        positionName: path(['position', 'positionName'], application)
      })
    : // requested without an application
    lastInterview && prop('status', lastInterview) === 'PENDING'
    ? t('candidateStateInterviewPending', {
        ...interviewDetails
      })
    : '';
};

const getCandidateStateShortString = (
  t,
  lastInterview,
  application,
  locationFilter
) => {
  const interviewDetails = {
    scheduledFor: toDateTime(prop('scheduledFor', lastInterview)),
    locationName: path(['location', 'name'], lastInterview),
    positionName: path(['position', 'positionName'], lastInterview)
  };
  return (prop('status', lastInterview) === 'SCHEDULED' ||
    prop('status', lastInterview) === 'ACCEPTED') &&
    path(['location', 'id'], lastInterview) === locationFilter
    ? t('candidateStateInterviewThisLocation', {
        ...interviewDetails
      })
    : (prop('status', lastInterview) === 'SCHEDULED' ||
        prop('status', lastInterview) === 'ACCEPTED') &&
      path(['location', 'id'], lastInterview) !== locationFilter
    ? t('candidateStateInterviewOtherLocation')
    : !!application && prop('createdAt', application)
    ? // application at this location
      t('candidateStateAppliedShort', {
        appliedOn: toDate(prop('createdAt', application), 'M/dd/yyyy'),
        locationName: path(['position', 'location', 'name'], application),
        positionName: path(['position', 'positionName'], application)
      })
    : // requested without an application
    lastInterview && prop('status', lastInterview) === 'PENDING'
    ? t('candidateStateInterviewPendingShort', {
        ...interviewDetails
      })
    : '';
};

const getCandidateState = (lastInterview, application, locationFilter) => {
  // same cases as above
  return (prop('status', lastInterview) === 'SCHEDULED' ||
    prop('status', lastInterview) === 'ACCEPTED') &&
    path(['location', 'id'], lastInterview) === locationFilter
    ? FOHCandidateStateBannerEnum.SCHEDULED
    : (prop('status', lastInterview) === 'SCHEDULED' ||
        prop('status', lastInterview) === 'ACCEPTED') &&
      path(['location', 'id'], lastInterview) !== locationFilter
    ? FOHCandidateStateBannerEnum.REQUESTED_OR_SCHEDULED_BY_OTHER
    : !!application && prop('createdAt', application)
    ? // application at this location (more important than an interview request from another)
      FOHCandidateStateBannerEnum.APPLIED
    : // requested without an application
    lastInterview && prop('status', lastInterview) === 'PENDING'
    ? FOHCandidateStateBannerEnum.REQUESTED
    : '';
};

export const CandidateIRStateBanner = React.memo(props => {
  const { t } = useTranslation('InterviewFeature');
  const { application, lastInterview } = props;
  const { candidateFirstName } = props;
  const { locationFilter } = useCurrentLocationContext({ props });

  const candidateStateString = getCandidateStateString(
    t,
    lastInterview,
    application,
    candidateFirstName,
    locationFilter
  );

  if (!candidateStateString) {
    return <></>;
  }

  return (
    <FOHCandidateStateBanner
      candidateStateString={candidateStateString}
      candidateName={candidateFirstName}
      forLocation={
        lastInterview
          ? path(['location', 'name'], lastInterview)
          : path(['position', 'location', 'name'], application)
      }
      forPosition={
        lastInterview
          ? path(['position', 'positionName'], lastInterview)
          : path(['position', 'positionName'], application)
      }
    />
  );
});

export const CandidateStateCardBanner = props => {
  const { t } = useTranslation('InterviewFeature');
  const { application, lastInterview } = props;
  const { candidateFirstName } = props;
  const { locationFilter } = useCurrentLocationContext({ props });

  const candidateStateString = getCandidateStateString(
    t,
    lastInterview,
    application,
    candidateFirstName,
    locationFilter
  );

  const candidateState = getCandidateState(
    lastInterview,
    application,
    locationFilter
  );

  const candidateStateStringShort = getCandidateStateShortString(
    t,
    lastInterview,
    application,
    locationFilter
  );

  if (!candidateStateString) {
    return <></>;
  }

  return (
    <FOHCandidateStateCardBanner
      // on hover info
      candidateStateString={candidateStateString}
      candidateState={candidateState}
      shortenedStateString={candidateStateStringShort}
    />
  );
};
