import React from 'react';

import { defaultTo, prop } from 'ramda';

import sendMessage from '../../../images/send_message_icon.svg';

import { FOHImage } from '../../ingredients';

export const FOHSendMessageIcon = (props: any) => (
  <FOHImage
    source={{ uri: sendMessage }}
    style={{ width: 21, height: 24, ...defaultTo({}, prop('style', props)) }}
  />
);
