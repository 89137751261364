import React from 'react';

import { View } from 'react-native';
import styled from 'styled-components/native';

import {
  FOHLinkedInIcon,
  FOHFacebookIcon,
  FOHTwitterIcon,
  FOHEmailIcon,
  FOHLinkIcon,
  FOHTouchableOpacity
} from '../../ingredients';

export interface FOHShareProps {
  facebookPressed?: () => void;
  linkedInPressed?: () => void;
  twitterPressed?: () => void;
  emailPressed?: () => void;
  linkPressed?: () => void;
  left?: number;
}

export const FOHShare = (props: FOHShareProps) => (
  <View
    style={{
      justifyContent: 'center',
      alignItems: 'center'
    }}
  >
    <View style={{ left: props.left, zIndex: 21 }}>
      <GrayTriangle />
      <Triangle />
    </View>
    <ShareContainer>
      <FOHTouchableOpacity onPress={props.linkedInPressed}>
        <FOHLinkedInIcon />
      </FOHTouchableOpacity>
      <FOHTouchableOpacity onPress={props.facebookPressed}>
        <FOHFacebookIcon />
      </FOHTouchableOpacity>
      <FOHTouchableOpacity onPress={props.twitterPressed}>
        <FOHTwitterIcon />
      </FOHTouchableOpacity>
      <FOHTouchableOpacity onPress={props.emailPressed}>
        <FOHEmailIcon />
      </FOHTouchableOpacity>
      <FOHTouchableOpacity onPress={props.linkPressed}>
        <FOHLinkIcon />
      </FOHTouchableOpacity>
    </ShareContainer>
  </View>
);

const ShareContainer = styled(View)`
  padding-bottom: 6px;
  z-index: 19px;
  flex-direction: row;
  align-items: center;
  background-color: #ffffff;
  border-radius: 4px;
  width: 291px;
  justify-content: space-between;
  padding: 14px;
  border-color: #dddddd;
  border-width: 1px;
`;

const Triangle = styled(View)`
  z-index: 20px;
  width: 0px;
  height: 0px;
  top: 2px;
  border-left-width: 10px;
  border-right-width: 10px;
  border-bottom-width: 16px;
  border-style: solid;
  background-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #ffffff;
`;

const GrayTriangle = styled(View)`
  z-index: 19px;
  position: absolute;
  width: 0px;
  top: 0px;
  height: 0px;
  border-left-width: 10px;
  border-right-width: 10px;
  border-bottom-width: 16px;
  border-style: solid;
  background-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #dddddd;
`;
