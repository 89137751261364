import React from 'react';

import { StyleProp, ViewStyle } from 'react-native';

import { FontProperty } from '../../templates';
import { SPACING_SCALE, FOHLabel, FOHImage, FOHView } from '../../ingredients';

export interface FOHAnswerBadgeProps {
  displayIcon?: any;
  displayLabel?: string;
  fontProperty?: FontProperty;
  style?: StyleProp<ViewStyle>;
}

export const FOHAnswerBadge = (props: FOHAnswerBadgeProps) => (
  <FOHView
    style={[{ flexDirection: 'row', alignItems: 'flex-start' }, props.style]}
  >
    <FOHImage
      source={{ uri: props.displayIcon }}
      style={{
        width: 22,
        height: 20,
        marginRight: SPACING_SCALE.larger,
        marginTop: -1 // to optically center with FOHLabel
      }}
      resizeMode="contain"
    />

    <FOHLabel style={{ ...props.fontProperty }}>{props.displayLabel}</FOHLabel>
  </FOHView>
);
