import { path, length } from 'ramda';

import { handleMutation } from '../../utils';
import {
  useGetOrCreateSbChannelMutation,
  useRefreshSbTokenMutation
} from '../../graphql/generated';
import { SENDBIRD_CHAT } from '../../constants';
import { useNavigation } from '../../utils/navigation';

export const useDirectMessage = ({ sendbirdAccessToken = '' }) => {
  const { navigateTo } = useNavigation();
  const [getOrCreateSendBirdChannelWith] = useGetOrCreateSbChannelMutation();
  const [refreshSBUser] = useRefreshSbTokenMutation();

  const getChannelUrlFor = async ({
    uuids,
    position = '',
    location = '',
    interview = '',
    application = ''
  }) => {
    // needs two uuids
    if (length(uuids) === 0) {
      return;
    }
    if (!sendbirdAccessToken) {
      await handleMutation(refreshSBUser({}));
    }

    const [response, error] = await handleMutation(
      getOrCreateSendBirdChannelWith({
        variables: { uuids, position, location, interview, application }
      })
    );

    if (error) {
      return;
    }
    const sendbirdChannel = path(
      ['getOrCreateSendbirdChannelWith', 'sendbirdChannel'],
      response
    );
    return sendbirdChannel;
  };

  const goToDirectMessageWith = async ({
    uuids,
    position = '',
    location = '',
    interview = '',
    application = ''
  }) => {
    const sendbirdChannel = await getChannelUrlFor({
      uuids,
      position,
      location,
      interview,
      application
    });
    navigateTo(`${SENDBIRD_CHAT}/${sendbirdChannel}`);
    return sendbirdChannel;
  };

  return {
    getChannelUrlFor,
    goToDirectMessageWith,
    refreshSBUser
  };
};
