import React from 'react';

import hotel from '../../../images/hotels.svg';

import { FOHImage } from '../../ingredients';

export interface FOHHotelIconProps {
  style?: any;
}

export const FOHHotelIcon = (props: FOHHotelIconProps) => (
  <FOHImage
    source={{ uri: hotel }}
    style={
      props && !!props.style
        ? { width: 20, height: 18, ...props.style }
        : { width: 20, height: 18 }
    }
  />
);
