import React from 'react';

import status from '../../../images/looking_for_shift_status.svg';

import { FOHImage } from '../../ingredients';

export const FOHNewShiftStatusIcon = () => (
  <FOHImage source={{ uri: status }} style={{ width: 16, height: 16 }} />
);

export const FOHLargeNewShiftStatusIcon = () => (
  <FOHImage source={{ uri: status }} style={{ width: 20, height: 20 }} />
);
