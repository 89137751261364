import React from 'react';

import {
  FullWidthCard,
  FOHSpace,
  FOHSpaceSmall,
  FOHSmallDetailLabel,
  FOHDirectMessageIcon,
  FOHInverseButton,
  FOHDivider,
  FOHLabel,
  FOHView
} from '../../ingredients';

import {
  FOHPositionOverview,
  FOHPositionOverviewType
} from '../blocks/FOHPositionOverview';
import {
  FOHHiringManagerOverview,
  FOHHiringManagerProps
} from '../blocks/FOHHiringManagerOverview';

export interface FOHInterviewListCellProps
  extends FOHHiringManagerProps,
    FOHPositionOverviewType {
  mobile?: boolean;
  positionId: string;
  positionSlug: string;
  // Job Details
  onPressJobDetails: (slug: string) => void;
  // CTA
  buttonCTALabel: string;

  scheduledLabel: string;
  scheduledFor: string;

  interviewTypeLabel: string;
  interviewType?: string;

  interviewLocationLabel: string;
  interviewLocation?: string;

  messageLabel?: string;
  onPressMessage?: () => void;
}

export const FOHInterviewListCell = (props: FOHInterviewListCellProps) => (
  <FullWidthCard testID={'FOHInterviewListCell'}>
    <FOHView
      style={{
        flexDirection: props.mobile ? 'column' : 'row',
        flexWrap: 'wrap',
        flex: 1
      }}
    >
      <FOHView style={{ flex: 1, paddingLeft: 8 }}>
        <FOHPositionOverview
          {...props}
          noBorder={true}
          onPressLocation={props.onPressLocation}
          locationImage={props.locationImage || ''}
          postedDateText={''}
          padding={16}
        />
      </FOHView>
      {props.hiringManagerName && !props.mobile && (
        <FOHView
          style={{
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: props.mobile ? 'flex-start' : 'flex-end',
            paddingTop: 10,
            paddingBottom: 0,
            flexWrap: 'wrap'
          }}
        >
          {props.hiringManagerPosition && props.hiringManagerName && (
            <FOHHiringManagerOverview {...props} />
          )}
          {props.onPressMessage &&
            !!props.messageLabel &&
            !props.mobile &&
            props.hiringManagerName && (
              <FOHView
                style={{
                  paddingLeft: 24,
                  minWidth: 250,
                  paddingRight: 24
                }}
              >
                <FOHInverseButton
                  icon={FOHDirectMessageIcon}
                  centerIcon={true}
                  color={'#000000'}
                  textColor={'#ffffff'}
                  opaque={true}
                  testID={'on-press-message'}
                  selected={true}
                  onPress={props.onPressMessage}
                  title={props.messageLabel}
                  style={{
                    width: 250,
                    marginBottom: 10,
                    height: 46
                  }}
                />
              </FOHView>
            )}
        </FOHView>
      )}
    </FOHView>
    {!props.mobile && <FOHDivider />}
    <FOHView style={{ flexDirection: props.mobile ? 'column' : 'row' }}>
      <FOHView
        style={{
          flexDirection: 'row',
          flexWrap: 'wrap',
          paddingLeft: 24,
          paddingTop: props.mobile ? 0 : 12
        }}
      >
        <FOHView
          style={{
            alignItems: 'flex-start',
            paddingTop: 4,
            minWidth: 100
          }}
        >
          <FOHSmallDetailLabel
            style={{ textAlign: 'left', alignItems: 'flex-start' }}
          >
            {props.scheduledLabel}
          </FOHSmallDetailLabel>
          <FOHSpaceSmall />
          <FOHLabel
            style={{ maxWidth: props.mobile ? 250 : undefined }}
            numberOfLines={props.mobile ? 3 : 1}
          >
            {props.scheduledFor}
          </FOHLabel>
        </FOHView>
        <FOHSpace />
        {!!props.interviewType && (
          <FOHView
            style={{
              alignItems: 'flex-start',
              paddingTop: 4,
              minWidth: 100
            }}
          >
            <FOHSmallDetailLabel
              style={{ textAlign: 'left', alignItems: 'flex-start' }}
            >
              {props.interviewTypeLabel}
            </FOHSmallDetailLabel>
            <FOHSpaceSmall />
            <FOHLabel>{props.interviewType}</FOHLabel>
          </FOHView>
        )}
        <FOHSpace />
        {!!props.interviewLocation && (
          <FOHView
            style={{
              alignItems: 'flex-start',
              paddingTop: 4,
              minWidth: 100
            }}
          >
            <FOHSmallDetailLabel
              style={{ textAlign: 'left', alignItems: 'flex-start' }}
            >
              {props.interviewLocationLabel}
            </FOHSmallDetailLabel>
            <FOHSpaceSmall />
            <FOHLabel
              style={{ maxWidth: props.mobile ? 250 : undefined }}
              numberOfLines={props.mobile ? 2 : 1}
            >
              {props.interviewLocation}
            </FOHLabel>
          </FOHView>
        )}
        <FOHSpace />
      </FOHView>

      {props.mobile &&
        props.hiringManagerPosition &&
        props.hiringManagerName && (
          <FOHView
            style={{
              paddingLeft: 24,
              paddingTop: props.mobile ? 10 : 0
            }}
          >
            <FOHHiringManagerOverview {...props} flexStart={true} />
          </FOHView>
        )}

      <FOHView
        style={{
          flex: 1,
          minWidth: 270,
          alignItems: props.mobile ? 'center' : 'flex-end',
          justifyContent: 'center',
          paddingRight: props.mobile ? 0 : 24,
          paddingTop: 16,
          paddingBottom: props.mobile ? 10 : 20,
          flexDirection: props.mobile ? 'row' : 'column'
        }}
      >
        {props.onPressMessage &&
          !!props.messageLabel &&
          props.mobile &&
          props.hiringManagerName && (
            <FOHInverseButton
              icon={FOHDirectMessageIcon}
              centerIcon={true}
              color={'#000000'}
              textColor={'#ffffff'}
              opaque={true}
              testID={'on-press-message'}
              selected={true}
              onPress={props.onPressMessage}
              title={props.messageLabel}
              style={{
                width: props.mobile ? '45%' : 250,
                height: 46
              }}
            />
          )}
        {props.mobile && <FOHSpaceSmall />}
        <FOHInverseButton
          onPress={() => props.onPressJobDetails(props.positionSlug)}
          title={props.buttonCTALabel}
          color={'#ffffff'}
          textColor={'#000000'}
          style={{
            width: props.mobile ? '45%' : 250
          }}
        />
      </FOHView>
    </FOHView>
  </FullWidthCard>
);
