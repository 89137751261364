import React, { FunctionComponent } from 'react';

import { StyleProp, ImageStyle } from 'react-native';

import bluepin from '../../../images/blue_pin.svg';

import { FOHImage } from '../../ingredients';

interface FOHLocationPinProps {
  style?: StyleProp<ImageStyle>;
}

export const FOHLocationPin: FunctionComponent<FOHLocationPinProps> = ({
  style
}) => (
  <FOHImage
    source={{ uri: bluepin }}
    style={[{ width: 16, height: 16 }, style]}
  />
);
