import React from 'react';

import { WithT } from 'i18next';
import { compose } from 'ramda';

import {
  FOHButton,
  FOHColors,
  FOHView,
  FOHSpace,
  FOHSpaceLarger,
  FOHBanner,
  FOHBannerType,
  FOHHeaderH2,
  FOHSmallDetailLabel,
  FOHImageCell
} from '../../components';
import deleteAccountImage from '../../images/delete-account.svg';
import { useSoftDeleteCandidateUserMutation } from '../../graphql/generated';
import { CANDIDATE_ACCOUNT_MENU_PATH } from '../../constants';
import { useNavigation } from '../../utils/navigation';

import { useBanner } from '../Navigation/useBanner';
import { useLogout } from '../SignIn/useLogout';
import { withReactiveModal, WithReactiveModalProps } from '../Navigation';

interface DeleteCandidateModalProps extends WithReactiveModalProps, WithT {
  me: any;
}

const DeleteCandidateModal = (props: DeleteCandidateModalProps) => {
  const { t, me } = props;

  const { navigateTo } = useNavigation();

  const { banner, setBanner } = useBanner();

  const [deleteUser] = useSoftDeleteCandidateUserMutation();

  const { logoutWipeCache } = useLogout();

  const handleDeleteUser = async () => {
    await deleteUser({
      variables: {
        userId: me.id
      }
    });

    setBanner({
      bannerMessage: t('deleteSuccessBanner') as string,
      bannerType: FOHBannerType.SUCCESS as unknown as keyof typeof FOHBannerType
    });

    await logoutWipeCache();
  };

  return (
    <FOHView style={{ alignItems: 'center', padding: 24 }}>
      {banner.bannerMessage ? (
        <FOHBanner
          style={{ marginBottom: 30 }}
          {...banner}
          message={banner.bannerMessage}
        />
      ) : (
        <></>
      )}

      <FOHImageCell
        image={deleteAccountImage}
        style={{ height: 144, width: 144 }}
      />
      <FOHSpace />
      <FOHHeaderH2>{t('deleteConfirmationTitle')}</FOHHeaderH2>
      <FOHSpace />
      <FOHSmallDetailLabel
        style={{ textAlign: 'center', paddingHorizontal: 4 }}
      >
        {t('deleteConfirmationDetail')}
      </FOHSmallDetailLabel>
      <FOHSpaceLarger />
      <FOHView
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          paddingHorizontal: 4
        }}
      >
        <FOHButton
          title={t('common:cancel')}
          borderColor={FOHColors.BORDER_GRAY}
          color={FOHColors.WHITE}
          textColor={FOHColors.BLACK}
          style={{ width: 128 }}
          onPress={() => navigateTo(`${CANDIDATE_ACCOUNT_MENU_PATH}/`)}
        />
        <FOHSpace />
        <FOHButton
          title={t('deleteLabel')}
          color="#E5253C"
          style={{ width: 128 }}
          onPress={handleDeleteUser}
        />
      </FOHView>
    </FOHView>
  );
};

export default compose(withReactiveModal)(DeleteCandidateModal);
