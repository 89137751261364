import React from 'react';

import { StyleProp, TextStyle } from 'react-native';

import { FOHLabelBold } from '../text/FOHLabel';
import { SPACING_SCALE } from '../space/FOHSpace';
import { FOHTouchableOpacity } from '../containers/Containers';

export interface FOHAltTextButtonProps {
  onPress?: (arg: any) => void;
  title: string;
  style?: StyleProp<TextStyle>;
  wrapperStyle?: StyleProp<TextStyle>;
  icon?: () => any;
}

export const FOHAltTextButton = (props: FOHAltTextButtonProps) => (
  <FOHTouchableOpacity
    onPress={props.onPress}
    style={props?.icon ? { flexDirection: 'row', alignItems: 'center' } : {}}
  >
    <FOHLabelBold
      style={[
        {
          color: '#51c1db',
          fontSize: 18,
          marginRight: props.icon ? SPACING_SCALE.regular : ''
        },
        props.style
      ]}
    >
      {props.title}
    </FOHLabelBold>

    {props.icon ? props.icon() : <></>}
  </FOHTouchableOpacity>
);
