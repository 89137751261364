import React from 'react';

import { prop } from 'ramda';
import { useTranslation } from 'react-i18next';

import jb1 from '../../images/1jb.png';
import jb2 from '../../images/2jb.png';
import jb3 from '../../images/3jb.png';
import jb4 from '../../images/4jb.png';
import jb5 from '../../images/5jb.png';
import jb6 from '../../images/6jb.png';
import { BOOST, FEED } from '../../constants';
import { useNavigation } from '../../utils/navigation';
import { FOHNoApplicantsBanner } from '../../components';

export const NoApplicantsBanner = props => {
  const { t } = useTranslation('CandidateFeed');
  const { navigateTo, location: navLocation } = useNavigation();
  return (
    <FOHNoApplicantsBanner
      headerText={t('noApplicantsHeaderText')}
      headerHightlightText={t('noApplicantsHighlightText')}
      // following step 1
      detailText1={t('noApplicantsDetailText1')}
      // following step 2
      detailText2WithLink={t('noApplicantsDetailText2')}
      highlightLinkText={t('noApplicantsDetailText2Link')}
      onPressExplore={() => {
        props.goToCandidateDatabase();
      }}
      // following step 3
      detailText3WithLink={
        prop('showCampaignBoost', props) ? t('noApplicantsDetailText3') : ''
      }
      linkWord={
        prop('showCampaignBoost', props) ? t('noApplicantsDetailText3Link') : ''
      }
      onPressLink3={() => {
        navigateTo({
          pathname: `${FEED}${BOOST}/${prop('positionGlobalId', props)}`,
          search: navLocation.search
        });
      }}
      noIcon={true}
      smallDetailText={t('otherJobBoardText')}
      otherJobBoards={[
        {
          image: jb1
        },
        {
          image: jb2
        },
        {
          image: jb3
        },
        {
          image: jb4
        },
        {
          image: jb5
        },
        {
          image: jb6
        }
      ]}
    />
  );
};
