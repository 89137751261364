import React, { useState, useEffect } from 'react';

import { compose, path } from 'ramda';
import { useTranslation } from 'react-i18next';

import { multiSelectItem } from '../../utils/fieldHelpers';
import {
  ADDITIONAL_TRAINING_DROPDOWN,
  CANDIDATE_ACCOUNT_MENU_PATH,
  CANDIDATE_SKILLS_PATH
} from '../../constants';
import { useIsMobile, handle } from '../../utils';
import { useNavigation } from '../../utils/navigation';
import { FOHMultiSelectScreen } from '../../components';

import { withReactiveModal } from '../Navigation/withReactiveModal';

import { useCandidateProfileMutation } from './useCandidateProfileMutation';

const SkillsScreen = props => {
  const { navigateTo, location: navLocation } = useNavigation();

  const { me, updateCandidate } = useCandidateProfileMutation();
  const [skills, setSkills] = useState();
  // WAITING ON USER OBJECT
  useEffect(() => {
    setSkills(path(['candidateProfile', 'additionalTraining'], me));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    try {
      !props.isModal &&
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
    } catch (e) {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [error, setError] = useState();
  const { t } = useTranslation('SkillsFeature');
  const { isMobile } = useIsMobile();
  const [dirty, setDirty] = useState(false);

  const hasBack =
    navLocation.pathname === CANDIDATE_SKILLS_PATH &&
    !!path(['candidateProfile', 'onboardingCompletedAt'], me);

  return (
    <FOHMultiSelectScreen
      onboarding={props.onboarding}
      items={ADDITIONAL_TRAINING_DROPDOWN.map(option => ({
        value: option.value,
        label: t(option.label)
      }))}
      // max five
      selected={skills}
      selectItem={value => {
        setDirty(true);
        return multiSelectItem(value, setSkills, skills);
      }}
      title={t('skillsTitle')}
      submitLabel={
        path(['candidateProfile', 'onboardingCompletedAt'], me)
          ? t('common:save')
          : t('nextLabel')
      }
      isModal={props.isModal}
      error={error}
      largeHeader={!isMobile}
      previousTitle={
        props.onboarding ? t('CandidateOnboardingSteps:previous') : ''
      }
      goBack={
        hasBack
          ? () => navigateTo(CANDIDATE_ACCOUNT_MENU_PATH)
          : props.onboarding
          ? () => props.previousStep()
          : null
      }
      disabledSubmit={
        !!path(['candidateProfile', 'onboardingCompletedAt'], me) && !dirty
      }
      handleSubmit={async () => {
        const [resp] = await handle(
          updateCandidate({
            variables: {
              additionalTraining: skills
            }
          })
        );

        const err = path(['data', 'mutateCandidate', 'errors'], resp);
        if (resp && !err) {
          setDirty(false);
          if (props.close) return props.close();
          return navigateTo(
            path(['candidateProfile', 'onboardingCompletedAt'], me)
              ? CANDIDATE_ACCOUNT_MENU_PATH
              : props.onSubmit && props.onSubmit()
          );
        } else if (err) {
          setError(err);
        }
      }}
    />
  );
};

export const SKillsModal = compose(withReactiveModal)(SkillsScreen);

export default SkillsScreen;
