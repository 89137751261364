import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import {
  FOHGoogleAuthErrorHandler,
  FOHLoaderWithText,
  FOHView
} from '../../components';
import { EMPLOYER_SETTINGS_MENU, INTEGRATIONS } from '../../constants';
import {
  handleMutation,
  useIsMobile,
  getSessionStorageItem
} from '../../utils';
import {
  useGetMeQuery,
  useCreateIndeedTokenFromCodeMutation
} from '../../graphql/generated';
import { getTokenFromLocalStorage } from '../../apolloClient';
import { useNavigation } from '../../utils/navigation';

import { getSearchQuery } from '../Search';
import useGoogleSSOAuth from '../SignIn/useGoogleSSOAuth';

const IndeedOAuthRedirectScreen = () => {
  const { t } = useTranslation('GoogleSSOFeature');

  const { isMobile } = useIsMobile();
  const { navigateTo, location: navLocation } = useNavigation();

  const meQuery = useGetMeQuery({
    context: {
      header: getTokenFromLocalStorage()
    }
  });

  const OAUTH_STATE_KEY = 'react-use-oauth2-state-key';

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();

  const [indeedTokenMutation] = useCreateIndeedTokenFromCodeMutation();

  const { clearSessionStates } = useGoogleSSOAuth();

  // USED TO PERFORM STATE MATCH AND BE MUTATION CALL
  const performStateMatchAndMutation = async () => {
    setIsLoading(true);

    const sessionState = getSessionStorageItem(OAUTH_STATE_KEY);
    const receivedState = getSearchQuery(navLocation, 'state') as string;
    const indeedAuthCode = getSearchQuery(navLocation, 'code') as string;
    const indeedEmployerId = getSearchQuery(navLocation, 'employer') as string;

    if (sessionState !== receivedState) {
      clearSessionStates();
      setError('Incorrect state provided');
      setIsLoading(false);
      return;
    }

    const [response, _error] = await handleMutation(
      indeedTokenMutation({
        variables: {
          code: indeedAuthCode,
          employerId: indeedEmployerId
        }
      })
    );

    if (_error || !response) {
      setIsLoading(false);
      return setError(_error?.message);
    }

    meQuery && (await meQuery.refetch());

    clearSessionStates();
    setIsLoading(false);

    return response;
  };

  useEffect(() => {
    (async () => {
      const response = await performStateMatchAndMutation();

      // HANDLE FOR NAVIGATING AFTER SIGNUP
      if (response) {
        navigateTo(`${EMPLOYER_SETTINGS_MENU}${INTEGRATIONS}`);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FOHView
      style={{
        width: '100%',
        alignItems: 'center',
        paddingTop: 40,
        height: '100vh',
        paddingHorizontal: isMobile ? 15 : 0
      }}
    >
      {isLoading && <FOHLoaderWithText />}
      {error && (
        <FOHGoogleAuthErrorHandler
          label={t('errorLabel')}
          sublabel={t('errorSubLabel')}
          errorMessage={error}
          button1Label={t('retryBtnLabel')}
          onButton1Press={() => {
            navigateTo(`${EMPLOYER_SETTINGS_MENU}${INTEGRATIONS}`);
            clearSessionStates();
          }}
          button2Label={t('contactHelpLabel')}
          onButton2Press={() =>
            window?.open('https://help.fohandboh.com', '_blank')
          }
        />
      )}
    </FOHView>
  );
};

export default IndeedOAuthRedirectScreen;
