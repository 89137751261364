import { useApolloClient } from '@apollo/client';

import { logout } from '../../api/auth';
import { useTrackAmplitudeEvent } from '../../utils';

import { useGetMeData } from './useGetMeData';

export const useLogout = () => {
  const meQuery = useGetMeData();
  const client = useApolloClient();

  const { resetAmplitudeState } = useTrackAmplitudeEvent();

  const logoutWipeCache = async (onLogout?: () => void | undefined) => {
    await client.clearStore();
    await logout().then(async () => {
      //Reset when user logs out
      resetAmplitudeState();

      onLogout && onLogout();
      meQuery && meQuery.refetch();
      window.location.reload();
    });
  };

  return { logoutWipeCache };
};
