import React from 'react';

import { defaultTo, prop } from 'ramda';

import eye from '../../../images/gray_eye_icon.svg';

import { FOHImage } from '../../ingredients';

export const FOHGrayEyeIcon = (props: any) => (
  <FOHImage
    source={{ uri: eye }}
    style={{ width: 21, height: 14, ...defaultTo({}, prop('style', props)) }}
  />
);
