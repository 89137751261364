import React from 'react';

import { createRoot } from 'react-dom/client';
import './index.css';
// POLYFILLS
import 'url-search-params-polyfill';
import 'mdn-polyfills/Number.isNaN';
import ResizeObserver from 'resize-observer-polyfill';
// Libraries
import './i18n';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';

import { register as registerServiceWorker } from './registerServiceWorker';
import { apolloClient } from './apolloClient';
import 'react-native-web';
import { FOHLoadGoogleScript } from './components';
import ErrorBoundary from './features/ErrorBoundary';

if (typeof window !== 'undefined') {
  window.ResizeObserver = ResizeObserver;
}
//eslint-disable-next-line
const App = require('./App').default;

const ClientApp = () => (
  <BrowserRouter>
    <ApolloProvider client={apolloClient}>
      <ErrorBoundary>
        <FOHLoadGoogleScript
          options={{
            libraries: ['places']
          }}
        >
          <App />
        </FOHLoadGoogleScript>
      </ErrorBoundary>
    </ApolloProvider>
  </BrowserRouter>
);

const mountElement = document.getElementById('root');
const root = createRoot(mountElement);

root.render(<ClientApp />);

registerServiceWorker();
