import React from 'react';

import styled from 'styled-components/native';

import {
  FOHFonts,
  FOHLabel,
  FOHColors,
  FOHView,
  FOHBlackEyeIcon
} from '../../ingredients';

export interface FOHMessageTemplateNameProps {
  name?: string;
}

const TemplateName = styled(FOHLabel)`
  margin-left: 8px;
  width: 212px;
  font-family: ${FOHFonts.MEDIUM};
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: ${FOHColors.GRAYSCALE_700};
`;

export const FOHMessageTemplateName = (props: FOHMessageTemplateNameProps) => (
  <FOHView
    style={{
      flexDirection: 'row',
      alignItems: 'center'
    }}
  >
    <FOHBlackEyeIcon style={{ tintColor: FOHColors.GRAYSCALE_700 }} />
    <TemplateName>{props.name}</TemplateName>
  </FOHView>
);
