import React from 'react';

import benefits from '../../../images/profile_benefits_icon.svg';

import { FOHImage } from '../../ingredients';

export const FOHProfileBenefitsIcon = (props: any) => (
  <FOHImage
    source={{ uri: benefits }}
    style={{ width: 18, height: 22, ...props?.style }}
  />
);
