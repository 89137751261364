import React from 'react';

import { Text } from 'react-native';
import { prop, defaultTo } from 'ramda';

import {
  FOHButton,
  FOHCircleLoader,
  FOHFileIcon,
  FOHInverseButton,
  FOHModal,
  FOHPageModal,
  FOHSpace,
  FOHSpaceSmallest,
  FOHStatusProps,
  FOHTextButton,
  FOHFeedIcon,
  FOHView,
  FOHScrollView,
  FOHToolTipType
} from '../../ingredients';

import {
  FOHApplicantBlock,
  FOHApplicantBlockProps
} from '../blocks/FOHApplicantBlock';

const Header = (props: any) => (
  <FOHView
    style={{ paddingBottom: 10, zIndex: 2, paddingTop: 40 }}
    testID="FOHApplicantsModal"
  >
    <FOHView style={{ flexDirection: 'row' }}>
      <FOHView
        style={{
          flexDirection: props.isMobile ? 'column' : 'row',
          marginRight: 'auto'
        }}
      >
        <Text
          style={{
            fontSize: props.isMobile ? 14 : 24,
            color: '#171717',
            fontWeight: '600'
          }}
        >
          {props.labels.modalLabels.newApplicants}{' '}
        </Text>
        <Text
          style={{
            fontSize: props.isMobile ? 14 : 24,
            color: '#bdbdbd',
            fontWeight: '600'
          }}
        >
          {!props.isMobile && ''} {props.positionName}{' '}
          <>{props.positionType && `- ${props.positionType}`}</>
        </Text>
      </FOHView>
      {!props.isMobile && (
        <FOHView style={{ flexDirection: 'row' }}>
          <FOHTextButton
            title={props.labels.modalLabels.showAllApplicants}
            icon={() => FOHFeedIcon({ style: { width: 18, height: 18 } })}
            onPress={props.onShowAllApplicants}
          />
          <FOHSpace />
          <FOHInverseButton
            style={{ borderWidth: 0, width: 180, height: 40 }}
            title={props.labels.modalLabels.exportToCsv}
            icon={() => FOHFileIcon({ style: { width: 18, height: 18 } })}
            onPress={props.onExportToCsv}
          />
          <FOHSpaceSmallest />
          {!!props.onRequestAll && (
            <FOHButton
              style={{ borderWidth: 0, width: 160, height: 40 }}
              title={props.labels.modalLabels.requestAll}
              onPress={props.onRequestAll}
              disabled={!props?.schedulerHasInterviewSchedule}
              enableHover={!props?.schedulerHasInterviewSchedule && true}
              helpText={props?.noInterviewScheduleForSchedulerText}
              toolTipType={FOHToolTipType.LEFT}
            />
          )}
        </FOHView>
      )}
    </FOHView>
  </FOHView>
);

const Applicant = (props: FOHApplicantBlockProps) => (
  <FOHView style={{ marginTop: 10, zIndex: 3 }}>
    <FOHApplicantBlock {...props} />
  </FOHView>
);

const InnerView = (props: any) => {
  return (
    <FOHView
      style={{
        paddingHorizontal: props.isMobile ? 0 : 40,
        height: '100%',
        zIndex: 1
      }}
    >
      <Header {...props} />
      <FOHView
        style={{ height: '100%', maxHeight: props.isMobile ? 550 : 400 }}
      >
        <FOHScrollView
          scrollEventThrottle={200}
          style={{ width: '100%', maxHeight: 600 }}
          onScroll={props.onScroll}
        >
          <>
            {props.children
              ? props.children
              : props.applicants.map(
                  (
                    applicant: {
                      profile: FOHApplicantBlockProps['applicantProfile'];
                      status: FOHStatusProps;
                      isRequestSent?: boolean;
                    },
                    index: number
                  ) => (
                    <Applicant
                      isRequestSent={defaultTo(
                        false,
                        prop('isRequestSent', applicant)
                      )}
                      isMobile={props.isMobile!}
                      {...applicant.status}
                      applicantProfile={applicant.profile}
                      labels={props.labels.applicantLabels}
                      onRequestInterview={props.onApplicantRequestInterview}
                      onStartChat={props.onApplicantStartChat}
                      onPressProfile={props.onPressProfile}
                      schedulerHasInterviewSchedule={
                        props?.schedulerHasInterviewSchedule
                      }
                      noInterviewScheduleForSchedulerText={
                        props?.noInterviewScheduleForSchedulerText
                      }
                      key={index}
                    />
                  )
                )}
          </>
          {props.loading && <FOHCircleLoader />}
        </FOHScrollView>
      </FOHView>
      {props.isMobile && (
        <FOHView
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            bottom: 10,
            shadowColor: '#000000',
            shadowOpacity: 0.3,
            shadowOffset: { width: 0, height: -4 },
            shadowRadius: 6,
            width: '100%',
            position: 'absolute',
            borderColor: '#ffffff',
            backgroundColor: '#ffffff',
            borderWidth: 1,
            padding: 10
          }}
        >
          <FOHButton
            color="#ffffff"
            textColor="#171717"
            borderColor="#171717"
            style={{ flex: 0.5 }}
            title={props.labels.modalLabels.showAllApplicants}
            onPress={props.onShowAllApplicants}
          />
          <FOHSpaceSmallest />
          <FOHButton
            style={{
              flex: 0.5,
              marginLeft: 'auto'
            }}
            title={props.labels.modalLabels.requestAll}
            onPress={props.onRequestAll}
          />
        </FOHView>
      )}
    </FOHView>
  );
};

export interface FOHApplicantsModalProps {
  open?: boolean;
  close?: () => void;
  hasMore: boolean;
  onScroll: () => void;
  isMobile?: boolean;
  labels: {
    applicantLabels: FOHApplicantBlockProps['labels'];
    modalLabels: {
      newApplicants: string;
      showAllApplicants: string;
      exportToCsv: string;
      requestAll: string;
    };
  };
  applicants: Array<{
    profile: FOHApplicantBlockProps['applicantProfile'];
    status: FOHStatusProps;
    isRequestSent?: boolean;
  }>;
  onApplicantRequestInterview: (slug: string) => void;
  onApplicantStartChat: (slug: string) => void;
  onRequestAll?: () => void;
  onExportToCsv: () => void;
  onShowAllApplicants: () => void;
  onPressProfile?: (handle: string) => void;
  positionName: string;
  positionType: string;
  requestSentSlug?: string;
  leftContentShift?: number;
  children?: any;
  schedulerHasInterviewSchedule?: boolean;
  noInterviewScheduleForSchedulerText?: string;
}

export const FOHApplicantsModal = (props: FOHApplicantsModalProps) => {
  return props.isMobile ? (
    <FOHModal
      leftContentShift={props.leftContentShift}
      style={{ maxHeight: '100vh' }}
      innerStyle={{ height: '100%' }}
      open={props.open}
      close={props.close}
    >
      <InnerView {...props}>{props.children}</InnerView>
    </FOHModal>
  ) : (
    <FOHPageModal
      containerStyle={{
        paddingTop: 10
      }}
      {...props}
      open={props.open}
      close={props.close}
    >
      <InnerView {...props}>{props.children}</InnerView>
    </FOHPageModal>
  );
};
