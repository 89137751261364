import React, { useState } from 'react';

import { prop, defaultTo, compose, path } from 'ramda';
import { useTranslation } from 'react-i18next';

import {
  FOHButton,
  FOHView,
  FOHSpace,
  FOHPriceBlock,
  FOHInverseButton,
  FOHColors,
  FOHDetailText,
  FOHHeaderH2,
  FOHErrorLabel,
  FOHSpaceSmall
} from '../../components';
import { useIsMobile, handle } from '../../utils';
import {
  BILLING,
  EMPLOYER_SETTINGS_MENU,
  PLAN,
  getAppBaseUrl
} from '../../constants';
import { useNavigation } from '../../utils/navigation';
import { FOH_INPUT_HEIGHT } from '../../components/constants';

import { withReactiveModal } from '../Navigation';
import { useCurrentLocationContext } from '../Locations/useCurrentLocationContext';

import { withStripe } from './withStripe';
import { useCheckout } from './useCheckout';
import { usePaymentPlan, formatPricing } from './usePaymentPlan';
import { getPlanMeta } from './PricingBox';

const UpgradePlan = props => {
  const { navigateTo, location: navLocation } = useNavigation();
  const [selectedPlan, setSelectedPlan] = useState(
    prop('plan', defaultTo({ plan: 'payasyougo' }, navLocation?.state))
  );
  const [checkoutError, setCheckoutError] = useState(false);

  const [monthly] = useState(true);
  const { t } = useTranslation('PricingScreenFeature');
  const planMeta = getPlanMeta({ t, monthly });
  const { isMobile } = useIsMobile();
  const { locationFilter, currentLocation } = useCurrentLocationContext({
    props
  });
  const { redirectToStripeCheckout } = useCheckout(props);

  const getCheckoutResponse = async checkoutData => {
    const [, error] = await handle(redirectToStripeCheckout(checkoutData));
    if (error) {
      setCheckoutError(true);
    }
  };

  const { notSubscribedPrice, subscribedPrice, unpaidPositions, singlePrice } =
    usePaymentPlan({
      locationFilter
    });

  const prices = formatPricing({
    notSubscribedPrice,
    subscribedPrice,
    subscribed: false,
    monthly
  });
  const paygPrice = monthly
    ? defaultTo(
        '',
        path(['monthlyPayAsYouGo', 'displayPerMonth'], singlePrice)
      ).replace('.00', '')
    : path(['annualPayAsYouGo', 'displayPerMonth'], singlePrice);

  return (
    <>
      <FOHView
        style={{
          alignItems: 'flex-start',
          width: '100%',
          paddingLeft: 24,
          paddingBottom: 12
        }}
      >
        <FOHHeaderH2 style={{ textAlign: 'left' }}>
          {t('upgradeTitle', { location: prop('name', currentLocation) })}
        </FOHHeaderH2>
        <FOHDetailText>{t('upgradeDetail')}</FOHDetailText>
      </FOHView>
      <FOHView
        style={{
          flexDirection: isMobile ? 'column' : 'row',
          paddingBottom: 12,
          paddingHorizontal: isMobile ? 0 : 24,
          paddingTop: isMobile ? 0 : 12,
          width: '100%'
        }}
        testID="UpgradePlanModal"
      >
        <FOHPriceBlock
          {...planMeta[0]}
          detail=""
          asterisk={true}
          planText={t('payPerActivePosition', { price: paygPrice })}
          price={paygPrice}
          selected={selectedPlan === 'payasyougo'}
          selectText={t('selectPayAsYouGo')}
          onPressSelect={() => setSelectedPlan('payasyougo')}
          discountLabel={monthly ? undefined : t('annualPAYGDiscount')}
        ></FOHPriceBlock>
        <FOHSpace />
        <FOHPriceBlock
          {...planMeta[1]}
          detail=""
          price={path(['unlimited', 'displayPerMonth'], prices)}
          selected={selectedPlan === 'unlimited'}
          selectText={t('selectUnlimited')}
          onPressSelect={() => setSelectedPlan('unlimited')}
          discountLabel={monthly ? undefined : t('annualUnlimitedDiscount')}
        ></FOHPriceBlock>
      </FOHView>
      {checkoutError ? (
        <FOHView
          style={{
            justifyContent: 'center',
            alignItems: 'flex-start',
            wordBreak: 'break-word'
          }}
        >
          <FOHErrorLabel>{t('missingLocPosErrorMsg')}</FOHErrorLabel>
          <FOHSpaceSmall />
        </FOHView>
      ) : null}
      <FOHView
        style={{
          flexDirection: 'row',
          width: '100%',
          paddingLeft: 24,
          paddingRight: 24,
          paddingTop: 12,
          flex: 1
        }}
      >
        <FOHView style={{ flex: 1 }}>
          <FOHInverseButton
            onPress={() =>
              (window.location.href = 'mailto:sales@fohandboh.com')
            }
            title={t('chatWithSales')}
            color={FOHColors.BLACK}
            style={{ width: '100%', height: FOH_INPUT_HEIGHT }}
          />
        </FOHView>
        <FOHSpace />
        <FOHView style={{ flex: 1 }}>
          <FOHButton
            title={t('next')}
            onPress={() => {
              if (selectedPlan === 'payasyougo') {
                return navigateTo({
                  pathname: navLocation.pathname.replace(
                    '/upgrade',
                    '/payasyougo'
                  ),
                  search: `?location=${prop('id', currentLocation)}`,
                  state: { monthly }
                });
              }
              window &&
                getCheckoutResponse({
                  url: `${getAppBaseUrl()}${
                    props.backPath
                      ? props.backPath
                      : `${EMPLOYER_SETTINGS_MENU}${BILLING}${PLAN}`
                  }`,
                  positions: unpaidPositions.map(pos => prop('rowId', pos)),
                  locations: [prop('rowId', currentLocation)],
                  cycle: monthly ? 'monthly' : 'annual',
                  plan: selectedPlan
                });
            }}
            fullWidth={true}
          />
        </FOHView>
      </FOHView>
      <FOHSpace />
    </>
  );
};

export const UpgradePlanScreen = withStripe(UpgradePlan);
export const UpgradePlanModal = compose(
  withReactiveModal,
  withStripe
)(UpgradePlan);
