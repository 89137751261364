import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Dimensions } from 'react-native';
import { useMatch } from 'react-router-dom';

import {
  FOHButtonNavigation,
  FOHColors,
  FOHSelect,
  FOHSelectItem,
  FOHSettingsBanner,
  FOHSpaceSmall,
  FOHView,
  useIsRestricted
} from '../../components';
import { useNavigation } from '../../utils/navigation';
import {
  ALLOWED_ROLES_GROUPS,
  BILLING,
  EMPLOYER_SETTINGS_MENU,
  INTEGRATIONS,
  NOTIFICATIONS,
  PERSONAL_ACCOUNT,
  SCHEDULE,
  TEAM
} from '../../constants';

import { useBanner } from '../Navigation';

// Settings menus
const getSettingsMenu = (t, isRestricted = false) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isRestricted: isRestrictedExceptAdmin } = useIsRestricted({
    allowedRoles: ALLOWED_ROLES_GROUPS.ROLES.ADMIN,
    allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.EMPLOYER]
  });

  const settingMenusList = [
    {
      title: t('EmployerSettingsFeature:accountSideBar'),
      route: `${EMPLOYER_SETTINGS_MENU}${PERSONAL_ACCOUNT}`,
      key: 'userProfile',
      isRestricted: false
    },
    {
      title: t('NotificationPreference:notificationSideBar'),
      route: `${EMPLOYER_SETTINGS_MENU}${NOTIFICATIONS}`,
      key: 'userNotifications',
      isRestricted: false
    },
    {
      title: t('EmployerSettingsFeature:scheduleSideBar'),
      route: `${EMPLOYER_SETTINGS_MENU}${SCHEDULE}`,
      key: 'interviewAvailability',
      isRestricted: false
    },
    {
      title: t('EmployerSettingsFeature:teamSideBar'),
      route: `${EMPLOYER_SETTINGS_MENU}${TEAM}`,
      key: 'hiringTeam',
      isRestricted
    },
    {
      title: t('Integrations:integrationsHeaderTitle'),
      route: `${EMPLOYER_SETTINGS_MENU}${INTEGRATIONS}`,
      key: 'integrations',
      isRestricted: isRestrictedExceptAdmin
    },
    {
      title: t('PricingScreenFeature:bannerTitle'),
      route: `${EMPLOYER_SETTINGS_MENU}${BILLING}`,
      key: 'planBilling',
      isRestricted
    }
  ];

  return settingMenusList.filter(list => !list.isRestricted);
};

export const withEmployerSettings = Component => {
  return function WithEmployerSettings(props) {
    const { t } = useTranslation('EmployerSettingsFeature');
    const { banner } = useBanner();
    const { navigateTo } = useNavigation();

    const { isRestricted } = useIsRestricted({
      allowedRoles: ALLOWED_ROLES_GROUPS.ROLES.ADMIN_USER,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.EMPLOYER]
    });

    const settingMenus = getSettingsMenu(t, isRestricted);

    const matchedRoute = useMatch(`${EMPLOYER_SETTINGS_MENU}/*`);

    const [selectedRoute, setSelectedRoute] = useState(
      matchedRoute
        ? matchedRoute?.pathname
        : `${EMPLOYER_SETTINGS_MENU}${PERSONAL_ACCOUNT}`
    );
    const isMobile = Dimensions.get('window').width <= 960;

    const handleRouteChange = route => {
      setSelectedRoute(route);
      navigateTo(route);
    };

    return (
      <FOHView
        style={{
          backgroundColor: FOHColors.WHITE,
          width: '100%',
          height: '100vh',
          marginTop: isMobile ? 50 : 0,
          maxWidth: 1440
        }}
      >
        <FOHSettingsBanner
          {...banner}
          title={t('TopNavFeature:settings')}
          subTitle={t('manageConfigurationLabel')}
        />
        <FOHView
          style={{ paddingHorizontal: isMobile ? 24 : 35 }}
          testID="settings-buttons-navigation"
        >
          {isMobile ? (
            <FOHSelect
              selectedValue={selectedRoute}
              onValueChange={handleRouteChange}
              neverEmpty
              style={{
                width: '100%',
                maxWidth: '100%',
                fontSize: 16,
                lineHeight: 20,
                height: 44,
                color: FOHColors.GRAYSCALE_900,
                backgroundColor: FOHColors.GRAYSCALE_100,
                borderColor: FOHColors.GRAYSCALE_300,
                borderWidth: 1
              }}
            >
              {settingMenus.map((menu, index) => (
                <FOHSelectItem
                  key={`${menu.key}_${index}`}
                  label={menu.title}
                  value={menu.route}
                />
              ))}
            </FOHSelect>
          ) : (
            <FOHButtonNavigation
              routes={settingMenus}
              selectedRoute={selectedRoute}
              onPressRoute={handleRouteChange}
            />
          )}
        </FOHView>
        <FOHSpaceSmall />
        <FOHView
          style={{
            paddingHorizontal: isMobile ? 24 : 35,
            paddingBottom: isMobile ? 24 : 'unset'
          }}
        >
          <FOHView style={{ backgroundColor: FOHColors.GRAYSCALE_10 }}>
            <Component {...props}>{props.children}</Component>
          </FOHView>
        </FOHView>
      </FOHView>
    );
  };
};
