import React, { useEffect } from 'react';

import { StyleProp, TextInput, ViewStyle } from 'react-native';
import { remove, indexOf, dropLast, path, length } from 'ramda';
import styled from 'styled-components/native';

import {
  FOHFonts,
  FOHNavyX,
  FOHColors,
  FOHLabel,
  FOHTouchableOpacity,
  FOHView
} from '../../ingredients';

const ListTextField = (props: {
  value: string;
  setValue: (val: string) => any;
  onChangeText: () => void;
  style?: StyleProp<ViewStyle>;
  values: Array<string>;
  setValues: (vals: Array<string>) => any;
}) => {
  useEffect(() => {
    const hasSpace = new RegExp(/\s/);

    const newValue = hasSpace.test(props.value)
      ? [...props.value.split(/\s/)]
      : [];

    if (hasSpace.test(props.value)) {
      props.setValues([...props.values, ...newValue]);
      props.setValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  return (
    <FOHListContainer>
      {props.values.map((val: string, index: number) => (
        <FOHTextListCell
          key={`${index}val`}
          value={val}
          removeValueFromInput={() => {
            props.setValues([
              ...remove(indexOf(val, props.values), 1, props.values)
            ]);
          }}
        />
      ))}
      <TextField
        style={{ borderWidth: 0 }}
        {...props}
        onKeyPress={(e: Event) => {
          const key = path(['nativeEvent', 'key'], e);
          if (key === 'Backspace' && !props.value && length(props.values) > 0) {
            const remainingValues = dropLast(
              1,
              props.values.filter(val => !!val)
            );
            return props.setValues(remainingValues);
          }
        }}
      ></TextField>
    </FOHListContainer>
  );
};

export const FOHTextListCell = (props: {
  value: string;
  removeValueFromInput: () => any;
}) => {
  return props.value ? (
    <ListCellContainer>
      <FOHLabel
        style={{
          color: FOHColors.PRIMARY_TEAL_300
        }}
      >
        {props.value}
      </FOHLabel>
      <FOHTouchableOpacity
        onPress={props.removeValueFromInput}
        style={{ padding: 4 }}
      >
        <FOHNavyX />
      </FOHTouchableOpacity>
    </ListCellContainer>
  ) : (
    <></>
  );
};

export const FOHTextListTruncated = (props: {
  value: string;
  onPress: () => any;
}) => {
  return props.value ? (
    <ListCellContainer>
      <FOHTouchableOpacity onPress={props.onPress}>
        <FOHLabel
          style={{
            color: FOHColors.NAVY
          }}
        >
          {props.value}
        </FOHLabel>
      </FOHTouchableOpacity>
    </ListCellContainer>
  ) : (
    <></>
  );
};

const ListCellContainer = styled(FOHView)`
  border-radius: 10px;
  background-color: ${FOHColors.PRIMARY_TEAL_10};
  padding: 6px;
  margin: 3px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const FOHListContainer = styled(FOHView)`
  border: 1px solid #dbdbdb;
  width: 100%;
  line-height: 150%;
  padding-top: 6px;
  flex-direction: row;
  flex-wrap: wrap;
  border-radius: 4px;
  display: flex;
  overflow: hidden;
`;

const TextField = styled(TextInput)`
  background: ${FOHColors.WHITE};
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 200%;
  display: flex;
  align-items: center;
  flex: 1;
  height: 46;
  padding: 10px;
  outline-color: transparent;
`;

export const FOHListTextField = styled(ListTextField)``;
