import React from 'react';

import { Text } from 'react-native';
import styled from 'styled-components/native';

import { FOHView } from '../containers/Containers';

import { FOHFonts } from './FOHFonts';

export interface FOHAlertProps {
  message?: string;
}

export const FOHAlert = (props: FOHAlertProps) => (
  <FOHView style={{ width: '100%' }}>
    <AlertBox>
      {props.message ? <AlertText>{props.message}</AlertText> : null}
    </AlertBox>
  </FOHView>
);

export const AlertText = styled(Text)`
  font-family: ${FOHFonts.MEDIUM};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  align-items: center;
  color: #a16e08;
`;

const AlertBox = styled(FOHView)`
  padding: 12px 16px;
  border-radius: 4px;
  justify-content: center;
  margin: 10px;
  border-color: #f5ad22;
  border-width: 1px;
  background-color: #fce4b6;
`;
