import React from 'react';

import { WithT } from 'i18next';

import {
  FOHBlueSuccessIcon,
  FOHPremiumPlanIcon,
  FOHStopWatchIcon,
  FOHWhiteCrownIcon
} from '../../components';

interface Props extends WithT {
  monthly: boolean;
}

export const getPlanMeta = ({ t, monthly }: Props): any[] => [
  {
    title: t('payAsYouGo'),
    detail: t('payAsYouGoDetail'),
    asteriskText: monthly ? t('perActivePosition') : '',
    decoration: <FOHStopWatchIcon />,
    inverseColor: false,
    new: true,
    price: monthly ? t('$perPosition') : t('$annualPerPosition'),
    timePeriod: monthly ? t('slash-month') : t('slash-year'),
    priceDetail: monthly
      ? t('monthlyPriceDetail')
      : t('yearlyPriceDetailAsYouGo'),
    points: [
      {
        Icon: <FOHBlueSuccessIcon />,
        text: t('unlimitedHiresPerPosition')
      },
      {
        Icon: <FOHBlueSuccessIcon />,
        text: t('automaticPostTo60JobBoards')
      },
      {
        Icon: <FOHBlueSuccessIcon />,
        text: t('unlimitedInterviewRequestPerPosition')
      }
    ]
  },
  {
    title: t('alwaysHiring'),
    detail: t('alwaysHiringDetail'),
    decoration: <FOHWhiteCrownIcon />,
    inverseColor: false,
    new: true,
    price: monthly ? t('$unlimited') : t('$annualUnlimited'),
    timePeriod: monthly ? t('slash-month') : t('slash-year'),
    priceDetail: monthly ? t('monthlyPriceDetail') : t('yearlyPriceDetail'),
    points: [
      {
        Icon: <FOHBlueSuccessIcon />,
        text: t('unlimitedPositionsPerLocation')
      },
      {
        Icon: <FOHBlueSuccessIcon />,
        text: t('everythingInPayAsYouGoPlan')
      }
    ]
  },
  {
    title: t('enterprisePlan'),
    detail: t('enterprisePlanDetail'),
    decoration: <FOHPremiumPlanIcon />,
    inverseColor: false,
    new: true,
    customPrice: t('customPrice'),
    points: [
      {
        Icon: <FOHBlueSuccessIcon />,
        text: t('affectedByCovidAndNeedABreak')
      },
      {
        Icon: <FOHBlueSuccessIcon />,
        text: t('haveMultipleLocationsButOnABudget')
      },
      {
        Icon: <FOHBlueSuccessIcon />,
        text: t('getInTouch')
      }
    ]
  }
];
