import React from 'react';

import { defaultTo, prop } from 'ramda';

import play from '../../../images/play_icon.svg';

import { FOHImage } from '../../ingredients';

export const FOHPlayIcon = (props: any) => (
  <FOHImage
    source={{ uri: play }}
    style={{ width: 12, height: 14, ...defaultTo({}, prop('style', props)) }}
  />
);
