import React, { useState, useContext } from 'react';

import { path, length } from 'ramda';
import { useLocation } from 'react-router-dom';

import { useIsMobile } from '../../utils';
import { useNavigation } from '../../utils/navigation';
import { LOGIN_URL, SUBSCRIBE_LOCATIONS_POSITIONS } from '../../constants';

import { useFeatures } from '../SignIn/useFeatures';
import { useGetMeData } from '../SignIn';

const SidebarNavContext = React.createContext([false, () => {}]);

export const useSidebarNav = props => {
  const { location: navLocation } = useNavigation();
  // Feature flag todo
  const { loadingFeatureFlags } = useFeatures();
  const location = useLocation();
  const [state, setState] = useContext(SidebarNavContext);
  const { data: me } = useGetMeData();
  const isEmployer = !!path(['employerProfile', 'id'], me);

  const setExpandedNav = expandedNav => setState({ ...state, expandedNav });
  const setSearch = search => setState({ ...state, search });
  const setProfileDropdownOpen = profileDropdownOpen =>
    setState({ ...state, profileDropdownOpen });

  const openNavBarScreens = [
    '/settings/',
    '/chat',
    SUBSCRIBE_LOCATIONS_POSITIONS
  ];
  const alwaysOpenNav =
    props &&
    length(
      openNavBarScreens.filter(_path => navLocation.pathname.includes(_path))
    ) > 0;

  const isOnboarding = navLocation.pathname.includes('/onboarding/');
  const isHome =
    props &&
    (navLocation.pathname === '/' ||
      navLocation.pathname === '/demo' ||
      navLocation.pathname === '/pricing');
  const { isMobile } = useIsMobile();
  const expandedNav = state.expandedNav || alwaysOpenNav;
  const hasSideNav =
    location.pathname !== LOGIN_URL &&
    isEmployer &&
    !isOnboarding &&
    !isHome &&
    !isMobile;

  return {
    ...state,
    setSearch,
    expandedNav,
    alwaysOpenNav,
    setExpandedNav,
    // expandedNav && alwaysOpenNav ? 240 : 74
    contentShift: hasSideNav ? (alwaysOpenNav ? 120 : 37) : 0,
    hasSideNav,
    setProfileDropdownOpen,
    loadingFeatureFlags
  };
};

export const SidebarNavContextProvider = props => {
  const [state, setState] = useState({
    profileDropdownOpen: false,
    hasSideNav: false,
    expandedNav: false,
    search: ''
  });
  return (
    <SidebarNavContext.Provider value={[state, setState]}>
      {props.children}
    </SidebarNavContext.Provider>
  );
};
