import React from 'react';

import best_match from '../../../images/red_best_match.svg';

import { FOHImage } from '../../ingredients';

export const FOHRedBestMatchIcon = (props: any) => (
  <FOHImage
    source={{ uri: best_match }}
    style={{ width: 24, height: 24, ...props.style }}
  />
);
