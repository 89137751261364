import React from 'react';

import { FOHDirectMessageIcon } from '../icons/FOHDirectMessageIcon';
import { FOHTouchableOpacity } from '../containers/Containers';
import { FOHColors } from '../colors/FOHColors';

export interface FOHSmallMessageButtonProps {
  onPress: () => void;
  disabled?: boolean;
}

export const FOHSmallMessageButton = (props: FOHSmallMessageButtonProps) => (
  <FOHTouchableOpacity
    onPress={props.onPress}
    disabled={props.disabled}
    testID={'foh-message-dropdown-small'}
    style={{
      justifyContent: 'center',
      alignItems: 'center',
      width: 40,
      height: 40,
      borderRadius: 4,
      backgroundColor: props.disabled ? '#bdbdbd' : FOHColors.NARO_GREY
    }}
  >
    <FOHDirectMessageIcon />
  </FOHTouchableOpacity>
);
