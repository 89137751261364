import React, { useState, useEffect } from 'react';

import { prop, path, defaultTo } from 'ramda';

import {
  FOHScrollView,
  FOHView,
  FullWidthCard,
  FOHSpace,
  FOHSmallProfileCard
} from '../../components';
import { useIsMobile, isScrollCloseToBottom } from '../../utils';
import { useGetJobPositionQuery } from '../../graphql/generated';
import { CANDIDATE_PROFILE } from '../../constants';
import { useNavigation } from '../../utils/navigation';

import { UpdateStatusDropdwon } from '../CandidateProfile/UpdateStatusDropdown';
import { useCandidateProfileMutation } from '../CandidateProfile/useCandidateProfileMutation';
import { ProfileCompletionBlock } from '../CandidateProfile/ProfileCompletionBlock';
import { EditProfileModals } from '../CandidateProfile/EditProfileModals';
import { JobApplicationModals } from '../JobApplications/JobApplicationModals';
import { JobsModal } from '../JobApplications/OtherJobs';
import LocationDetailsModal from '../LocationFeed/LocationDetailsModal';
import WelcomeBanner from '../CandidateProfile/WelcomeBanner';
import { CompletedApplicationModal } from '../JobApplications/CompletedApplicationModal';
import { getSearchQuery } from '../Search';
import { usePositionTypes } from '../CandidateProfile/usePositionTypes';

import { CandidateHomeTabs } from './CandidateHomeTabs';
import { CandidateHomeRoutes } from './CandidateHomeRoutes';

export const CandidateHome = props => {
  const { navigateTo, location: navLocation } = useNavigation();

  const { me } = useCandidateProfileMutation();
  const { isMobile } = useIsMobile();
  const [positionSlug, setPositionSlug] = useState(null);

  const positionApplicationSlug = getSearchQuery(navLocation, 'position');
  const { getStringForPositionType } = usePositionTypes();

  const [locationSlug, setLocationSlug] = useState(null);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [openWelcome, setOpenWelcome] = useState(false);
  const [openApplicationComplete, setOpenApplicationComplete] = useState(false);
  const [scrolledToBottom, setScrolledToBottom] = useState(false);

  const [viewJobsModalOpen, setViewJobsModalOpen] = useState(false);
  const [viewLocationDetailsOpen, setViewLocationDetailsOpen] = useState(false);

  const positionAppliedQuery = useGetJobPositionQuery({
    skip: !positionApplicationSlug,
    variables: {
      slug: positionApplicationSlug
    }
  });

  const openApplicationModal = slug => {
    setPositionSlug(slug);
    setIsPreviewModalOpen(true);
  };
  const openViewJobsModal = slug => {
    setLocationSlug(slug);
    setViewJobsModalOpen(true);
  };
  const openLocationDetailsModal = slug => {
    setLocationSlug(slug);
    setViewLocationDetailsOpen(true);
  };

  useEffect(() => {
    const appliedPositionSlug = getSearchQuery(navLocation, 'position');
    appliedPositionSlug && setOpenApplicationComplete(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // no nested scrolls
  const MobileScroll = isMobile ? FOHScrollView : FOHView;

  return (
    <FOHView
      style={{
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
        backgroundColor: '#f5f5f5'
      }}
    >
      <MobileScroll
        style={{
          height: '96vh',
          minHeight: '96vh',
          paddingTop: 50,
          width: '100%',
          maxWidth: 1400,
          backgroundColor: '#f5f5f5'
        }}
        scrollEventThrottle={200}
        onScroll={async ({ nativeEvent }) => {
          if (isScrollCloseToBottom(nativeEvent)) {
            setScrolledToBottom(true);
          } else {
            setScrolledToBottom(false);
          }
        }}
      >
        <FOHView
          style={{
            // top: openWelcome ? 64 : 84,
            backgroundColor: '#f5f5f5'
          }}
        >
          <WelcomeBanner
            openWelcome={openWelcome}
            setOpenWelcome={setOpenWelcome}
            setOpenApplicationComplete={setOpenApplicationComplete}
            dialog={!props.isMobile}
            isMobile={isMobile}
          />
          {openWelcome && <FOHSpace />}
          <FOHView style={{ flexDirection: 'row' }}>
            {!isMobile && (
              <FOHView
                style={{
                  maxWidth: 300,
                  paddingLeft: isMobile ? 10 : 16,
                  paddingTop: 18
                }}
              >
                <FOHView style={{ zIndex: 99 }}>
                  <FOHSmallProfileCard
                    photo={prop('profileImage', me)}
                    name={prop('firstName', me)}
                    positions={defaultTo(
                      [],
                      path(['candidateProfile', 'positions'], me)
                    )
                      .map(position => getStringForPositionType(position))
                      .join(', ')}
                    viewProfile={() => {
                      navigateTo(
                        `${CANDIDATE_PROFILE}/${path(
                          ['candidateProfile', 'handle'],
                          me
                        )}`
                      );
                    }}
                    viewProfileLabel="Preview Profile"
                  >
                    <UpdateStatusDropdwon />
                  </FOHSmallProfileCard>
                </FOHView>
                <ProfileCompletionBlock
                  {...props}
                  parentRoute={navLocation.pathname}
                  small={true}
                />
              </FOHView>
            )}
            <FOHView
              style={{
                flex: 1,
                paddingLeft: isMobile ? 10 : 20,
                paddingRight: isMobile ? 10 : 20
              }}
            >
              {!isMobile && (
                <FullWidthCard
                  style={{
                    top: 18,
                    zIndex: 10,
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    borderBottomColor: '#ffffff'
                  }}
                >
                  <CandidateHomeTabs {...props} isMobile={isMobile} />
                </FullWidthCard>
              )}
              {isMobile && <FOHSpace />}
              <CandidateHomeRoutes
                {...props}
                // For mobile load more
                setScrolledToBottom={setScrolledToBottom}
                scrolledToBottom={scrolledToBottom}
                openApplicationModal={openApplicationModal}
                openViewJobsModal={openViewJobsModal}
                openLocationDetailsModal={openLocationDetailsModal}
              />
            </FOHView>
          </FOHView>
        </FOHView>
      </MobileScroll>
      <EditProfileModals {...props} />

      <JobApplicationModals
        {...props}
        // TODO: move this modal to it's own route
        slug={positionSlug}
        key={positionSlug}
        isModalOpen={isPreviewModalOpen && !props.settingValidEmail}
        closeModal={() => setIsPreviewModalOpen(false)}
      />
      <JobsModal
        {...props}
        open={viewJobsModalOpen}
        slug={locationSlug}
        close={() => setViewJobsModalOpen(false)}
      />
      <LocationDetailsModal
        open={viewLocationDetailsOpen}
        slug={locationSlug}
        setOpen={viewLocationDetailsOpen}
        setViewLocationDetailsOpen={setViewLocationDetailsOpen}
        closeUrlPath="/home/following"
        from="CandidateHome"
      />
      <CompletedApplicationModal
        {...props}
        position={path(['data', 'position'], positionAppliedQuery)}
        isOnboardingApplication={true}
        open={openApplicationComplete}
        close={() => setOpenApplicationComplete(false)}
      />
    </FOHView>
  );
};
