import React from 'react';

import { Image, Text, TouchableOpacity, View } from 'react-native';
import styled from 'styled-components/native';

import empty_resume from '../../../images/empty_resume.svg';

import {
  FOHView,
  FOHInverseButton,
  FOHSmallDetailLabel,
  FOHTrashCan,
  FOHSpaceSmall,
  FOHSpace,
  FOHHighlightText,
  FOHRequiredMark,
  FOHErrorLabel,
  FOHHeaderH2,
  FOHHeaderH4
} from '../../ingredients';
import { FOHSelect, FOHSelectItem } from '../../recipes';

import { FileType } from './FOHUploadResumeScreen';

export interface FOHYearsExpOption {
  label: string;
  value: string;
}

export interface FOHWorkHistoryOrUploadResumeScreenProps {
  headerText: string;
  subHeaderText: string;
  highlightTextInSubHeader: string;

  detailText: string;
  errorText: string;

  cardHeaderText: string;
  cardFooterText: string;

  uploadBtnLabel: string;
  fileTypeText: string;
  fileType: FileType;
  fileName: string;
  uploading: boolean;
  resume: string;
  Pdf: any;
  onClickUploadResume: () => any;
  removeResume: () => any;

  yearsExpChoices: Array<FOHYearsExpOption>;
  yearsOfExp: string;
  yearsExpPlaceholder: string;
  setYearsOfExp: (value: string) => any;

  employmentBtnLabel: string;
  onAddEmployment: () => any;

  children?: any;
}

export const FOHWorkHistoryOrUploadResumeScreen = (
  props: FOHWorkHistoryOrUploadResumeScreenProps
) => (
  <ContainerView>
    <Header>
      <FOHHeaderWithHighlightText>
        <FOHHighlightText
          labelCmp={FOHHeaderH2}
          emphasizedWord={props.highlightTextInSubHeader}
          highlightColor={'#38a56b'}
          boldLabelCmp={Text}
        >
          {`${props.headerText} ${props.subHeaderText}`}
        </FOHHighlightText>
        <FOHRequiredMark
          style={{
            width: 24,
            height: 24,
            position: 'absolute',
            right: 0,
            top: -24
          }}
        ></FOHRequiredMark>
      </FOHHeaderWithHighlightText>
    </Header>
    <FOHSpace />
    <StepCardForWorkHistoryOrUploadResume>
      <FOHSmallDetailWithErrorLabel>
        <FOHSmallDetailLabel>{props.detailText}</FOHSmallDetailLabel>
        <FOHSpaceSmall />
        <FOHErrorLabel>{props.errorText}</FOHErrorLabel>
      </FOHSmallDetailWithErrorLabel>
      <Body>
        <FOHHeaderH4>{props.cardHeaderText}</FOHHeaderH4>
        <FOHSpace />
        <UploadResumeCard>
          <ResumeBox
            onPress={props.onClickUploadResume}
            style={{ alignItems: 'center' }}
          >
            {!!props.resume && props.fileType === FileType.pdf ? (
              <props.Pdf
                source={{ uri: props.resume }}
                style={{ width: 80, height: 114 }}
              />
            ) : (
              <Image
                source={{
                  uri: props.resume ? props.resume : empty_resume
                }}
                style={{ width: 80, height: 114 }}
              />
            )}
          </ResumeBox>
          <FOHSpace />
          <FOHSmallDetailLabelWithButton>
            <FOHView
              style={{
                flex: 1,
                width: '100%',
                justifyContent: 'center'
              }}
            >
              <FOHSmallDetailLabel
                style={{
                  textAlign: 'center',
                  flexWrap: 'wrap',
                  width: '100%'
                }}
              >
                {props.fileTypeText}
              </FOHSmallDetailLabel>
            </FOHView>
            <FOHView style={{ flex: 1, width: '100%' }}>
              <FOHUploadButton>
                {!!props.resume && (
                  <>
                    <FOHInverseButton
                      onPress={props.removeResume}
                      title={''}
                      icon={FOHTrashCan}
                      centerIcon={true}
                      style={{
                        padding: 0,
                        margin: 0,
                        zIndex: 10,
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 50
                      }}
                    />
                    <FOHSpaceSmall />
                  </>
                )}
                <FOHInverseButton
                  color={'#000000'}
                  onPress={props.onClickUploadResume}
                  title={props.uploadBtnLabel}
                  fullWidth={true}
                  style={{
                    padding: 0,
                    margin: 0,
                    width: props.resume ? '80%' : '100%',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                />
              </FOHUploadButton>
            </FOHView>
          </FOHSmallDetailLabelWithButton>
        </UploadResumeCard>
        <FOHHeaderH4 style={{ marginTop: 20 }}>
          {props.cardFooterText}
        </FOHHeaderH4>
        <FOHSpace />
        <FlexRow>
          <FOHView style={{ flex: 1 }}>
            <FOHSelect
              placeholder={props.yearsExpPlaceholder}
              selectedValue={props.yearsOfExp}
              neverEmpty={true}
              onValueChange={(value: string) => props.setYearsOfExp(value)}
            >
              {props.yearsExpChoices.map(option => (
                <FOHSelectItem
                  key={option.label}
                  value={option.value}
                  label={option.label}
                />
              ))}
            </FOHSelect>
          </FOHView>
          <FOHView style={{ paddingLeft: 12 }} />
          <FOHView style={{ flex: 1 }}>
            <FOHInverseButton
              color={'#000000'}
              textColor={'#ffffff'}
              onPress={props.onAddEmployment}
              title={props.employmentBtnLabel}
              opaque={true}
              canSelect={true}
              selected={true}
            />
          </FOHView>
        </FlexRow>
        <FOHSpaceSmall />
        {!!props.children && (
          <EmploymentCardsContainer>{props.children}</EmploymentCardsContainer>
        )}
      </Body>
    </StepCardForWorkHistoryOrUploadResume>
    <FOHSpace />
  </ContainerView>
);

const ContainerView = styled(View)`
  width: 100%;
  align-items: center;
  padding-top: 30px;
`;

const StepCardForWorkHistoryOrUploadResume = styled(View)`
  justify-content: center;
  background-color: #fcfcfc;
  padding: 30px;
  border-radius: 24px;
  max-width: 570px;
  width: 100%;
  align-items: flex-start;
`;

const Header = styled(View)`
  margin-top: 36px;
  margin-bottom: 12px;
  justify-content: center;
`;

const FOHHeaderWithHighlightText = styled(View)`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const FOHSmallDetailWithErrorLabel = styled(View)`
  padding-bottom: 12px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

const Body = styled(View)`
  padding-top: 6px;
  width: 100%;
  align-items: flex-start;
`;

const ResumeBox = styled(TouchableOpacity)`
  border-radius: 6px;
  alignitems: center;
  border: 1px solid #e8e8e8;
`;

const UploadResumeCard = styled(View)`
  padding: 12px;
  align-items: center;
  flex-direction: row;
  border-radius: 6px;
  max-width: 500px;
  width: 100%;
  border: 1px solid #e8e8e8;
  background-color: #ffffff;
`;

const FOHSmallDetailLabelWithButton = styled(View)`
  align-items: center;
  justify-content: space-between;
  height: 114px;
  flex: 1;
  width: 100%;
`;

const FOHUploadButton = styled(View)`
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const FlexRow = styled(FOHView)`
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
`;

const EmploymentCardsContainer = styled(FOHView)`
  width: 100%;
  align-items: center;
  justify-content: center;
`;
