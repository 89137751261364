import React from 'react';

import { Dimensions } from 'react-native';
import { prop } from 'ramda';

import {
  FOHButton,
  FOHHeaderH2,
  FOHMoreInfoIcon,
  FOHReactiveModal,
  FOHReactiveModalProps,
  FOHScrollView,
  FOHSmallDetailLabel,
  FOHSpace,
  FOHSpaceSmall,
  FOHView
} from '../../ingredients';

import {
  FOHCandidateProfileBlockProps,
  FOHCandidateProfileBlock
} from '../blocks/FOHCandidateProfileBlock';
import {
  FOHPositionOverviewProps,
  FOHPositionOverview
} from '../blocks/FOHPositionOverview';
import {
  FOHApplicationMessageFormProps,
  FOHApplicationMessageForm
} from '../forms/FOHApplicationMessageForm';

export interface FOHApplicationMessageModalProps
  extends FOHReactiveModalProps,
    FOHPositionOverviewProps,
    FOHCandidateProfileBlockProps,
    FOHApplicationMessageFormProps {
  yourProfileLabel: string;
  yourProfileDetail: string;
  applyingToLabel: string;
  profileCompletenessInfo: string;
  profileCompletenessWidget?: any;
}

export const FOHApplicationMessageModal = (
  props: FOHApplicationMessageModalProps
) => (
  <FOHReactiveModal
    leftContentShift={props.leftContentShift}
    dialog={props.dialog}
    open={props.open}
    close={props.close}
    large={true}
    heightOpen={
      props.heightOpen || prop('height', Dimensions.get('window')) - 60
    }
    innerStyle={{
      paddingLeft: props.mobile ? 0 : 24,
      paddingBottom: 0,
      paddingRight: props.mobile ? 0 : 24
    }}
  >
    <FOHView
      style={{
        justifyContent: 'center',
        padding: props.dialog ? 0 : 24,
        paddingBottom: 0
      }}
    >
      <FOHScrollView
        style={{
          height: props.mobile
            ? prop('height', Dimensions.get('window')) - 250
            : '100%'
        }}
      >
        <FOHView
          style={{
            flexDirection: props.mobile ? 'column' : 'row-reverse',
            paddingLeft: props.mobile ? 0 : 24,
            paddingRight: props.mobile ? 0 : 24
          }}
        >
          <FOHView style={{ flex: 1 }}>
            <FOHHeaderH2 style={{ textAlign: 'left' }}>
              {props.applyingToLabel}
            </FOHHeaderH2>
            <FOHSpace />
            <FOHPositionOverview
              {...props}
              shadow={true}
              style={{ padding: props.mobile ? 12 : 24 }}
            />
            <FOHApplicationMessageForm
              {...props}
              submitLabel={props.mobile ? '' : props.submitLabel}
              onPressSubmit={props.mobile ? undefined : props.onPressSubmit}
            />
            <FOHSpaceSmall />
          </FOHView>
          {props.mobile && <FOHSpace />}
          <FOHView
            style={{
              flex: props.mobile ? undefined : 1,
              justifyContent: 'flex-start',
              maxWidth: 500,
              paddingRight: props.mobile ? 0 : 32
            }}
          >
            <FOHHeaderH2 style={{ textAlign: 'left' }}>
              {props.yourProfileLabel}
            </FOHHeaderH2>
            <FOHSmallDetailLabel>{props.yourProfileDetail}</FOHSmallDetailLabel>
            <FOHSpace />
            <FOHCandidateProfileBlock
              {...props}
              padding={0}
              removeCTA={true}
              readOnly={true}
              vertical={true}
              alignLeft={true}
            />
            {props.profileCompletenessWidget()}
            <FOHSmallDetailLabel>
              <FOHView style={{ top: 3, paddingRight: 2 }}>
                <FOHMoreInfoIcon />
              </FOHView>
              {props.profileCompletenessInfo}
            </FOHSmallDetailLabel>
            <FOHSpace />
          </FOHView>
        </FOHView>
      </FOHScrollView>
    </FOHView>
    {props.mobile && (
      <FOHView
        style={{
          bottom: 0,
          shadowColor: '#000000',
          shadowOpacity: 0.3,
          shadowOffset: { width: 0, height: -4 },
          shadowRadius: 6,
          width: '100%',
          padding: 10,
          paddingBottom: 40,
          borderColor: '#ffffff',
          borderWidth: 1
        }}
      >
        {props.submitLabel && props.onPressSubmit && (
          <FOHButton
            title={props.submitLabel}
            onPress={props.onPressSubmit}
            fullWidth={props.mobile}
            disabled={props.disableSubmit}
          ></FOHButton>
        )}
      </FOHView>
    )}
  </FOHReactiveModal>
);
