import { useEffect } from 'react';

import { path, prop, find, propEq } from 'ramda';
import { DateTime } from 'luxon';
import { useLocalStorage } from 'use-hooks';

import { unwrapEdgesAt } from '../../utils';
import {
  useMyPositionsQuery,
  usePositionByIdQuery
} from '../../graphql/generated';
import { useNavigation } from '../../utils/navigation';

import { getSearchQuery, QPOSITION } from '../Search';

export const useCurrentPosition = (
  props,
  location,
  positionsSearchText = ''
) => {
  const { location: navLocation } = useNavigation();
  const [positionId, setPositionId] = useLocalStorage('curr-position', ''); // Hook for Persist Position
  const myPositionsQuery = useMyPositionsQuery({
    variables: {
      location,
      search: positionsSearchText
    }
  });
  const positions = unwrapEdgesAt(
    ['data', 'myPositions', 'edges'],
    myPositionsQuery
  ).sort((posA, posB) =>
    // default feed to last created position
    DateTime.fromISO(prop('createdAt', posB)).diff(
      DateTime.fromISO(prop('createdAt', posA))
    )
  );

  const positionQuery = usePositionByIdQuery({
    skip: !positionId,
    variables: { id: positionId }
  });

  const currentPosition = path(['data', 'positionById'], positionQuery);

  useEffect(() => {
    if (!positionsSearchText) {
      const posId = getSearchQuery(navLocation, QPOSITION) || positionId;
      const firstPositionId = path([0, 'id'], positions) || positionId;
      const latestPosition = find(propEq('id', posId))(positions);
      // Persist position ID on location change & on set position hook
      setPositionId(
        latestPosition ? prop('id', latestPosition) : firstPositionId
      );
    }
  }, [
    myPositionsQuery.data,
    positionId,
    positions,
    positionsSearchText,
    props,
    navLocation,
    setPositionId
  ]);

  return {
    currentPosition,
    positionId,
    positions,
    setPositionId,
    loadingPositions: myPositionsQuery.loading
  };
};
