import React from 'react';

import styled from 'styled-components/native';
import { TouchableOpacity, Image } from 'react-native';

import {
  FOHColors,
  withError,
  FOHView,
  FOHEmptyImageUploadIcon,
  FOHButtonBordered
} from '../../ingredients';
import { FOH_INPUT_HEIGHT } from '../../constants';

import { FOHPhotoInputProps } from './FOHPhotoInput';

export interface FOHLargeImageInputProps extends FOHPhotoInputProps {
  center?: boolean;
}

const PhotoImage = styled(Image)`
  width: 100%;
  height: 100%;
  resizemode: contain;
`;

const PhotoContainer = styled(FOHView)`
  width: 291px;
  height: 254px;
  background: ${FOHColors.WHITE};
  border: 1px solid ${FOHColors.BACKGROUND_DARK_GRAY};
  max-width: 100%;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
`;

export const LargeImageInput = (props: FOHLargeImageInputProps) => (
  <FOHView style={{ alignItems: props.center ? 'center' : 'flex-start' }}>
    <TouchableOpacity onPress={props.pickPhoto} style={{ maxWidth: '100%' }}>
      <PhotoContainer>
        {props.photo ? (
          <PhotoImage source={{ uri: props.photo }} />
        ) : (
          <FOHEmptyImageUploadIcon />
        )}
        <FOHView style={{ paddingLeft: 13 }} />
      </PhotoContainer>
    </TouchableOpacity>
    <FOHView style={{ paddingTop: 18 }} />
    <FOHButtonBordered
      onPress={props.pickPhoto}
      title={props.uploadLabel}
      style={{
        maxWidth: 291,
        height: FOH_INPUT_HEIGHT
      }}
      color={FOHColors.WHITE}
    />
  </FOHView>
);

export const FOHLargeImageInput = withError(LargeImageInput);
