import React from 'react';

import dcicon from '../../../images/profile_dress_code_icon.svg';

import { FOHImage } from '../../ingredients';

export const FOHProfileDressCodeIcon = (props: any) => (
  <FOHImage
    source={{ uri: dcicon }}
    style={{ width: 18, height: 19, ...props?.style }}
  />
);
