import React, { useState } from 'react';

import { defaultTo, path, prop, length } from 'ramda';
import { useTranslation } from 'react-i18next';

import {
  FOHLabel,
  FOHLabelBold,
  FOHSpaceSmallest,
  FOHSpace,
  FOHSpaceSmall,
  FOHTouchableOpacity,
  FOHView,
  FOHSelectItem,
  FOHCheckbox,
  FOHSmallMessageButton,
  FOHBorderlessSelect,
  FOHCandidateNetworkCell
} from '../../../components';
import { isScrollCloseToBottom, unwrapEdgesAt } from '../../../utils';
import {
  INVITE_TO_APPLY,
  MOVE_CANDIDATE,
  NETWORK,
  PROFILE
} from '../../../constants';
import { useNavigation } from '../../../utils/navigation';

import { LogoLoader } from '../../Loading/LogoLoader';
import { usePositionTypes } from '../../CandidateProfile/usePositionTypes';
import { useStages } from '../../CandidateFeed/useStages';

import { InviteToApplyButton } from '../InviteToApplyButton';
import { useCandidateNetworkSearchContext } from '../useCandidateNetworkSearchHook';

export const CandidatesMobileList = props => {
  const { candidates, fetchMoreCandidates } = props;
  const { navigateTo } = useNavigation();

  const { t } = useTranslation('CandidateNetworkSearch');

  const [loadingMore, setLoadingMore] = useState(false);
  const {
    sortBy,
    isFilterDrawerOpen,
    setSortBy,
    sortByOptions,
    setPartialState,
    setActiveCandidateHandles,
    activeCandidateHandles
  } = useCandidateNetworkSearchContext();

  const { getStringForPositionType } = usePositionTypes();

  const { stages, moveEmployerCandidates, passiveStage } = useStages({
    polling: false
  });

  const multipleSelected = activeCandidateHandles.length > 1;

  return (
    <FOHView style={{ width: '100%', alignItems: 'center' }}>
      {!isFilterDrawerOpen && (
        <FOHView
          style={{
            background: '#F5F5F5',
            flexDirection: 'row',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            alignItems: 'center',
            padding: 8,
            borderRadius: 6,
            maxWidth: 1350,
            width: '100%'
          }}
        >
          <FOHTouchableOpacity style={{ flexDirection: 'row' }}>
            <FOHCheckbox selected={multipleSelected} />
            <FOHSpaceSmallest />
            <FOHLabel>{t('common:selectAll')}</FOHLabel>
          </FOHTouchableOpacity>
          <FOHView
            style={{
              flexDirection: 'row',
              minWidth: 200,
              alignItems: 'center'
            }}
          >
            <FOHLabelBold>{t('CandidateFeed:sortByLabel')}</FOHLabelBold>
            <FOHSpace />
            {length(sortByOptions) > 0 && (
              <FOHBorderlessSelect
                small={true}
                selectedValue={sortBy || ''}
                onValueChange={setSortBy}
              >
                {sortByOptions.map(option => (
                  <FOHSelectItem
                    value={option}
                    label={t(option)}
                    key={option}
                  />
                ))}
              </FOHBorderlessSelect>
            )}
          </FOHView>
        </FOHView>
      )}
      <FOHSpace />
      <FOHView
        style={{
          width: '100%'
        }}
        scrollEventThrottle={200}
        onScroll={async ({ nativeEvent }) => {
          if (isScrollCloseToBottom(nativeEvent) && !loadingMore) {
            setLoadingMore(true);
            await fetchMoreCandidates();
            setLoadingMore(false);
          }
        }}
      >
        {/* sorting bar should go here  */}
        <FOHView style={{ alignItems: 'center' }}>
          {candidates.map((candidate, index, all) => {
            const user = prop('user', candidate);
            const workHistory = unwrapEdgesAt(
              ['workHistory', 'edges'],
              candidate
            );
            const firstWorkHistory = prop(0, workHistory);
            const isSelected = activeCandidateHandles.includes(
              candidate.handle
            );

            return (
              <FOHView
                key={index}
                style={{
                  // should keep one card above the other, so that the  move to stage card is not covered by the cadidate card
                  zIndex: all.length - index,
                  width: '100%',
                  alignItems: 'center'
                }}
              >
                <FOHCandidateNetworkCell
                  key={candidate.handle}
                  name={`${prop('firstName', user)} ${prop('lastName', user)}`}
                  onPressName={() => {
                    setActiveCandidateHandles([candidate.handle]);
                    navigateTo(`${NETWORK}${PROFILE}`);
                  }}
                  onPressProfileImage={() => {
                    setActiveCandidateHandles([candidate.handle]);
                    navigateTo(`${NETWORK}${PROFILE}`);
                  }}
                  handle={candidate.handle}
                  selected={isSelected}
                  onCheckBoxPress={() => {
                    if (isSelected) {
                      setActiveCandidateHandles(
                        activeCandidateHandles.filter(
                          handle => handle !== candidate.handle
                        )
                      );
                    } else {
                      setActiveCandidateHandles([
                        ...activeCandidateHandles,
                        candidate.handle
                      ]);
                    }
                  }}
                  profileImage={prop('profileImage', user)}
                  statusType={prop('status', candidate)}
                  statusText={t(
                    `common:candidateStatusType.${prop('status', candidate)}`
                  )}
                  positions={defaultTo([], prop('positions', candidate))
                    .map(position => getStringForPositionType(position))
                    .join(', ')}
                  workHistoryPositionName={defaultTo(
                    '',
                    prop('position', firstWorkHistory)
                  )}
                  workHistoryEmployerName={defaultTo(
                    '',
                    path(['location', 'name'], firstWorkHistory)
                  )}
                  atLabel={
                    path(['location', 'name'], firstWorkHistory) ? t('at') : ''
                  }
                  cityZipString={defaultTo(
                    '-',
                    path(['addressPostalCode'], candidate)
                  )}
                  profileCompletenessLabel={t('profileCompletenessLabel')}
                  profileCompleteness={[
                    {
                      label: 'Profile Photo',
                      value: !!prop('profileImage', user)
                    },
                    {
                      label: 'Work History',
                      value: workHistory.length > 0
                    },
                    {
                      label: 'Profile Video',
                      value: !!candidate.profileVideo
                    },
                    {
                      label: 'Has Resume',
                      value: candidate.hasResume
                    },
                    {
                      label: 'Has Endorsements',
                      value: candidate.hasEndorsements
                    },
                    {
                      label: 'Has Contact Information',
                      value: candidate.hasContactInformation
                    }
                  ]}
                >
                  <InviteToApplyButton
                    onPress={() => {
                      setActiveCandidateHandles([candidate.handle]);
                      navigateTo(`${NETWORK}${INVITE_TO_APPLY}`);
                    }}
                    onStagePress={stage => {
                      setPartialState({
                        activeCandidateHandles: [candidate.handle],
                        activeMoveToStageId: stage.id
                      });
                      navigateTo(`${NETWORK}${MOVE_CANDIDATE}`);
                    }}
                    handle={candidate.handle}
                    selectedPosition=""
                    selectedLocation=""
                    style={{ height: 42, paddingHorizontal: 4 }}
                    // dont render expensive or polling
                    //hooks inside of maps
                    stages={stages}
                    moveEmployerCandidates={moveEmployerCandidates}
                    passiveStage={passiveStage}
                  />
                  <FOHSpaceSmall />

                  <FOHSmallMessageButton onPress={() => {}} />
                </FOHCandidateNetworkCell>
              </FOHView>
            );
          })}
          {loadingMore && <LogoLoader />}
        </FOHView>
      </FOHView>
    </FOHView>
  );
};
