import React from 'react';

import styled from 'styled-components/native';
import { Text } from 'react-native';
import { prop } from 'ramda';

import {
  Card,
  FOHView,
  FOHSmallDetailLabel,
  FOHColors,
  FOHLinkOut,
  FOHSpace,
  FOHSpaceSmall,
  FOHSpaceLarge,
  FOHCalendarIcon,
  FOHPhoneIcon,
  FOHClockIcon,
  FOHQuoteIcon,
  FOHDownloadLink,
  FOHFonts
} from '../../ingredients';

import { FOHFileCell, FOHFileCellProps } from '../cells/FOHFileCell';
import { FOHTextCell } from '../cells/FOHTextCell';
import { FOHCandidateLocationBlock } from '../blocks/FOHCandidateLocationBlock';
import { FOHProfileImage } from '../navigation/FOHProfileImage';

interface FOHCandidateWelcomeLetterProps {
  image: string;
  positionLabel: string;
  position: string;
  locationLabel: string;
  locationImage: string;
  locationName: string;
  locationAddress: string;
  startDateLabel: string;
  startDate: string;
  startTimeLabel: string;
  startTime: string;
  contactPersonLabel: string;
  contactPersonName: string;
  contactPersonNumber: string;
  customNoteLabel?: string;
  customNote?: string;
  hiringManagerProfile: string;
  hiringManagerLabel: string;
  hiringManagerName: string;

  attachedFileLabel?: string;
  attachedFiles?: Array<FOHFileCellProps>;
  attachedLinkLabel?: string;
  attachedLinks?: Array<string>;
  downloadFile: () => void;

  children?: any;
  isMobile?: boolean;
}

export const FOHCandidateWelcomeLetter = (
  props: FOHCandidateWelcomeLetterProps
) => (
  <>
    <FOHCandidateWelcomeCard mobile={props.isMobile}>
      <Container mobile={props.isMobile}>
        <TopBlock mobile={props.isMobile}>
          <FOHCandidateLocationBlock
            image={props.image}
            positionLabel={props.positionLabel}
            position={props.position}
            locationLabel={props.locationLabel}
            locationImage={props.locationImage}
            locationName={props.locationName}
            locationAddress={props.locationAddress}
            isMobile={props.isMobile}
          />
          {prop('isMobile', props) ? <FOHSpace /> : <FOHSpaceSmall />}
          <MainDetailBlock>
            <FOHSpaceSmall />
            <FOHView style={{ flexDirection: 'column' }}>
              <FOHTextCell
                icon={() => (
                  <FOHCalendarIcon
                    style={{ tintColor: FOHColors.PACIFIC_BLUE }}
                  />
                )}
                label={props.startDateLabel}
                value={props.startDate}
              />
              <FOHSpace />
              <FOHTextCell
                label={props.contactPersonLabel}
                value={props.contactPersonName}
              />
            </FOHView>
            <FOHSpace />
            <FOHView style={{ flexDirection: 'column' }}>
              <FOHTextCell
                icon={() => (
                  <FOHClockIcon
                    style={{
                      width: 20,
                      height: 20,
                      tintColor: FOHColors.PACIFIC_BLUE
                    }}
                  />
                )}
                label={props.startTimeLabel}
                value={props.startTime}
              />
              <FOHSpace />
              <FOHTextCell
                icon={() => (
                  <FOHPhoneIcon
                    style={{
                      width: 18,
                      height: 18,
                      tintColor: FOHColors.PACIFIC_BLUE
                    }}
                  />
                )}
                label={props.contactPersonLabel}
                value={props.contactPersonNumber}
              />
            </FOHView>
            <FOHSpaceSmall />
          </MainDetailBlock>
        </TopBlock>
        <FOHView
          style={{
            marginLeft: props.isMobile ? '10px' : '130px',
            marginRight: props.isMobile ? '10px' : '130px',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {prop('customNote', props) && (
            <>
              <FOHSpace />
              <CustomNotesBlock>
                <FOHView style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <FOHQuoteIcon style={{ width: 16, height: 16 }} />
                  <FOHSpaceSmall />
                  <SmallGreyLabel
                    style={{
                      fontSize: 16,
                      lineHeight: 24,
                      color: FOHColors.PACIFIC_BLUE
                    }}
                  >
                    {props.customNoteLabel}
                  </SmallGreyLabel>
                </FOHView>
                <FOHSpace />
                <Label
                  style={{
                    textAlign: 'left',
                    fontSize: 14,
                    lineHeight: 22,
                    fontWeight: 'normal',
                    color: FOHColors.NARO_GREY
                  }}
                >
                  {props.customNote}
                </Label>
                <FOHSpace />
                <FOHTextCell
                  icon={() => (
                    <FOHProfileImage
                      photo={props.hiringManagerProfile}
                      style={{ width: 40, height: 40, borderRadius: 22 }}
                    />
                  )}
                  label={props.hiringManagerLabel}
                  value={props.hiringManagerName}
                />
              </CustomNotesBlock>
            </>
          )}
          {prop('attachedFiles', props) && (
            <FileBlock>
              <FOHSpaceLarge />
              <FileName>{props.attachedFileLabel}</FileName>
              <AttachedFileBlock>
                {props.attachedFiles?.map((file: any, index: number) => {
                  return (
                    <FOHFileCell
                      key={index}
                      fileName={file.fileName}
                      progress={file.progress} // out of 100
                      size={file.size}
                      download={true}
                      onFileNamePress={props.downloadFile}
                      style={{ marginTop: 15 }}
                    >
                      {!!file.attachment && (
                        <FOHDownloadLink
                          href={file?.attachment}
                          name={file.fileName}
                        >
                          {file.fileName}
                        </FOHDownloadLink>
                      )}
                    </FOHFileCell>
                  );
                })}
              </AttachedFileBlock>
            </FileBlock>
          )}
          {prop('attachedLinks', props) && (
            <FileBlock>
              <FOHSpaceLarge />
              <FileName>{props.attachedLinkLabel}</FileName>

              {props.attachedLinks?.map(link => {
                return (
                  <>
                    <FOHSpace />
                    <FOHLinkOut
                      onPress={() => window.open(link, '_blank')}
                      href={link}
                      revertIcon={true}
                    >
                      {link}
                    </FOHLinkOut>
                  </>
                );
              })}
            </FileBlock>
          )}
        </FOHView>
      </Container>
    </FOHCandidateWelcomeCard>
  </>
);

const FOHCandidateWelcomeCard = styled(Card)<{ mobile?: boolean }>`
  max-width: 964px;
  min-width: 58%;
  width: 100%;
  padding: ${(p: any) => (p.mobile ? '5px' : '20px')};
`;

const Container = styled(FOHView)<{ mobile?: boolean }>`
  align-items: center;
  justify-content: flex-start;
  padding: ${(p: any) => (p.mobile ? '12px' : '18px')};
  width: 100%;
`;

const TopBlock = styled(FOHView)<{ mobile?: boolean }>`
  align-items: center;
  justify-content: ${(p: any) => (p.mobile ? 'center' : 'space-between')};
  flex-direction: ${(p: any) => (p.mobile ? 'column' : 'row')};
  flex-wrap: wrap;
  width: 100%;
`;

const MainDetailBlock = styled(FOHView)<{ mobile?: boolean }>`
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  flexwrap: wrap;
  width: 100%;
  border-radius: 16px;
  padding: ${(p: any) => (p.mobile ? '8px' : '12px')};
  max-width: 356px;
  background-color: ${FOHColors.LIGHT_GRAY};
`;

const CustomNotesBlock = styled(FOHView)<{ mobile?: boolean }>`
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  border-radius: 16px;
  padding: ${(p: any) => (p.mobile ? '8px' : '12px')};
  width: 100%;
  background-color: ${FOHColors.LIGHT_GRAY};
`;

export const FileBlock = styled(FOHView)<{ mobile?: boolean }>`
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const AttachedFileBlock = styled(FOHView)<{ mobile?: boolean }>`
  flex-direction: ${(p: any) => (p.mobile ? 'column' : 'row')};
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;

const SmallGreyLabel = styled(FOHSmallDetailLabel)`
  text-transform: uppercase;
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${FOHColors.GREY};
`;

const Label = styled(Text)`
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: ${FOHColors.GREY};
`;

export const FileName = styled(FOHSmallDetailLabel)`
  text-transform: uppercase;
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${FOHColors.GREY};
`;
