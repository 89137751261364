import React from 'react';

import {
  sendBirdSelectors,
  useSendbirdStateContext
} from '@sendbird/uikit-react';
import { path, defaultTo } from 'ramda';
import { useTranslation } from 'react-i18next';
import { useChannelContext } from '@sendbird/uikit-react/Channel/context';
import { trim } from 'lodash';
import { ConnectionState } from '@sendbird/chat';

import { FOHCustomChatInput, FOHView } from '../../components';
import {
  CANDIDATE_PROFILE,
  EDIT,
  FEED,
  INTERVIEW_SCHEDULE,
  SEND_OR_SET
} from '../../constants';
import { useNavigation } from '../../utils/navigation';

export const ChatInput = props => {
  const {
    // disabled,
    message,
    openMessageTemplate,
    setOpenMessageTemplate,
    height,
    setHeight,
    setMessage,
    employerCandidate,
    setActiveStageId,
    setCandidateHandle,
    setEmployerCandidate,
    handle,
    channelDetailsQuery,
    inputFile,
    setFileToUpload
  } = props;
  const { navigateTo, location } = useNavigation();
  const { t } = useTranslation('ChatFeature');

  const sendbirdState = useSendbirdStateContext();
  const sdkInstance = sendBirdSelectors.getSdk(sendbirdState);

  const {
    currentGroupChannel: channel,
    onBeforeSendUserMessage,
    onBeforeSendFileMessage
  } = useChannelContext();

  const sendTextMessage = () => {
    if (channel?.url) {
      sendBirdSelectors.getSendUserMessage(sendbirdState)(channel, {
        message: trim(message)
      });
    }

    return;
  };

  const lastInterview = path(
    ['data', 'channel', 'lastInterview'],
    channelDetailsQuery
  );

  return (
    <FOHView>
      <FOHCustomChatInput
        isActionDisabled={
          !sendbirdState?.config?.isOnline ||
          sdkInstance?.connectionState !== ConnectionState.OPEN
        }
        onSendMessage={() => {
          const trimText = defaultTo('', message).trim();
          if (!trimText) return;

          if (onBeforeSendUserMessage) {
            const params = onBeforeSendUserMessage(trimText);
            if (params) {
              sendTextMessage();
              setMessage('');
              setHeight(52);
            }
          } else {
            sendTextMessage();
            setMessage('');
            setHeight(52);
          }
        }}
        onFileUpload={() => {
          inputFile && inputFile.current.click();
        }}
        onUseTemplate={() => setOpenMessageTemplate(!openMessageTemplate)}
        message={message}
        setMessage={text => {
          setMessage(text);
          if (typeof channel.startTyping === 'function') {
            channel.startTyping();
          }
        }}
        height={height}
        setHeight={setHeight}
        placeholder={t('chatInputPlaceholder')}
        scheduleText={lastInterview ? t('Reschedule') : t('Schedule')}
        scheduleButtonProps={{
          style: {
            width: '100%',
            maxWidth: 144,
            height: 40,
            borderRadius: 100
          }
        }}
        onScheduleInterview={() => {
          if (location.pathname.includes(FEED)) {
            path(['stage', 'id'], employerCandidate) &&
              setActiveStageId(path(['stage', 'id'], employerCandidate));

            setTimeout(() => {
              setCandidateHandle(handle);
              setEmployerCandidate(employerCandidate);
            }, 1000);

            if (lastInterview) {
              navigateTo(
                { pathname: `${FEED}${INTERVIEW_SCHEDULE}` },
                {
                  state: {
                    interviewId: lastInterview?.id ?? undefined
                  }
                }
              );
            } else {
              navigateTo(`${FEED}${SEND_OR_SET}`);
            }
          } else if (handle) {
            if (lastInterview) {
              navigateTo(
                {
                  pathname: `${CANDIDATE_PROFILE}/${handle}${EDIT}${INTERVIEW_SCHEDULE}`
                },
                {
                  state: {
                    interviewId: lastInterview?.id ?? undefined
                  }
                }
              );
            } else {
              navigateTo(`${CANDIDATE_PROFILE}/${handle}${EDIT}${SEND_OR_SET}`);
            }
          }
        }}
      />
      <input
        type="file"
        id="file"
        accept="application/pdf,image/jpeg,image/png,application/msword,.doc,.docx, image/jpg"
        onChange={e => {
          const file = e.target.files[0];
          file && setFileToUpload(file);

          if (onBeforeSendFileMessage) {
            const params = onBeforeSendFileMessage(file);
            if (params) {
              channel.sendFileMessage(params);
            }
          }
          const params = { file: file };
          channel.sendFileMessage(params);
        }}
        ref={inputFile}
        style={{ display: 'none' }}
      />
    </FOHView>
  );
};
