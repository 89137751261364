import React, { useEffect, useState } from 'react';

import { compose } from 'ramda';
import { withTranslation, WithTranslation } from 'react-i18next';

import {
  FOHButton,
  FOHColors,
  FOHSpace,
  FOHSpaceLarge,
  FOHTextButton,
  FOHTextField,
  FOHView
} from '../../../components';
import { useIsMobile } from '../../../utils';

import { IntegrationIdentifiersChoices } from '../IntegrationIdentifiers';

interface AddOrEditIntegrationProps extends WithTranslation {
  onConnectOrSave: (
    integration: IntegrationIdentifiersChoices,
    identifier: string
  ) => void;
  onCancel: () => void;
  connectOrSaveBtnLabel: string;
  integrationIdentifierID?: string | null | undefined;
  integrationName: IntegrationIdentifiersChoices;
}

const AddOrEditIntegration: React.FC<AddOrEditIntegrationProps> = ({
  t,
  onConnectOrSave,
  onCancel,
  connectOrSaveBtnLabel,
  integrationIdentifierID = null,
  integrationName
}) => {
  const { isMobile, width } = useIsMobile();
  const mobile = isMobile && width <= 627;
  const [companyID, setCompanyID] = useState('');

  useEffect(() => {
    if (integrationIdentifierID) setCompanyID(integrationIdentifierID);
  }, [integrationIdentifierID]);

  return (
    <FOHView
      style={{ paddingVertical: 22, paddingHorizontal: isMobile ? 0 : 10 }}
    >
      <FOHView
        style={{
          minHeight: mobile ? 96 : 'unset',
          maxHeight: mobile ? 112 : 'unset',
          maxWidth: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: FOHColors.WHITE
        }}
      >
        <FOHView
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            flex: 1
          }}
        >
          <FOHView
            style={{
              flex: mobile ? 'unset' : 3,
              flexDirection: 'row',
              alignItems: 'center',
              width: mobile ? '100%' : 'unset'
            }}
          >
            <FOHView style={{ flex: 1, maxWidth: 320 }}>
              <FOHTextField
                placeholder={t('companyId')}
                value={companyID}
                required={true}
                onChangeText={value => setCompanyID(value)}
              />
            </FOHView>
          </FOHView>
          {mobile ? <FOHSpace /> : null}
          <FOHView
            style={{
              flex: mobile ? 'unset' : 2,
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
              width: mobile ? '100%' : 'unset'
            }}
          >
            <FOHTextButton
              title={t('common:cancel')}
              onPress={onCancel}
              style={{
                color: FOHColors.GRAYSCALE_900
              }}
              underline
            />
            <FOHSpaceLarge />
            <FOHButton
              textStyle={{ textTransform: 'capitalize' }}
              title={connectOrSaveBtnLabel}
              onPress={() => onConnectOrSave(integrationName, companyID)}
              disabled={!companyID || companyID === integrationIdentifierID}
              style={{ maxWidth: 141, width: '100%' }}
            />
          </FOHView>
        </FOHView>
      </FOHView>
    </FOHView>
  );
};

export default compose(withTranslation('Integrations'))(AddOrEditIntegration);
