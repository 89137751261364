import React from 'react';

import { defaultTo, prop } from 'ramda';

import lookingcharachter from '../../../images/lookingcharachter.svg';

import { FOHImage } from '../../ingredients';

export const FOHLookingCharacterIcon = (props: any) => (
  <FOHImage
    source={{ uri: lookingcharachter }}
    style={{
      width: 188,
      height: 330,
      ...defaultTo({}, prop('style', props))
    }}
  />
);
