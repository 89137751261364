import React from 'react';

import { FOHColors, FOHView } from '../../ingredients';

export interface FOHProgressBarProps {
  bgColor?: string;
  completed: number;
}

export const FOHProgressBar = (props: FOHProgressBarProps) => {
  const { bgColor, completed } = props;

  const containerStyles = {
    height: 8,
    width: '100%',
    backgroundColor: '#e0e0de',
    borderRadius: 100
  };

  const fillerStyles = {
    height: '100%',
    width: `${completed > 100 ? 100 : completed}%`,
    backgroundColor:
      completed === 100
        ? FOHColors.GRAYSCALE_G1
        : bgColor || FOHColors.PRIMARY_TEAL_P1,
    borderRadius: 100,
    textAlign: 'left'
  };

  return (
    <FOHView style={containerStyles}>
      <FOHView style={fillerStyles}></FOHView>
    </FOHView>
  );
};
