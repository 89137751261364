import React from 'react';

import { FOHTextField, FOHView } from '../../ingredients';

export interface FOHAddTeamMateProps {
  email: string;
  setEmail: (value: string) => any;
  emailPlaceholder: string;
  positionName: string;
  setPositionName?: (value: string) => any;
  positionNamePlaceholder: string;
}

export const FOHAddTeamMate = (props: FOHAddTeamMateProps) => (
  <FOHView>
    <FOHTextField
      onChangeText={props.setEmail}
      value={props.email}
      placeholder={props.emailPlaceholder}
    />
  </FOHView>
);
