import React, { FC } from 'react';
import styled from 'styled-components/native';

import {
  FOHColors,
  FOHFonts,
  FOHSmallDetailLabel,
  FOHSpaceHuge,
  FOHView
} from '../../../components';

interface CareerListHeaderCellProps {
  translationKeys: Record<string, string>;
}

export const CareerListHeaderCell: FC<CareerListHeaderCellProps> = ({
  translationKeys
}) => {
  return (
    <StyledFOHView>
      <StyledFOHViewContainer>
        <HeaderCellItem style={{ flex: 3, maxWidth: 278 }}>
          <CellText>{translationKeys.titlePageLabel}</CellText>
        </HeaderCellItem>
        <FOHSpaceHuge />
        <HeaderCellItem style={{ flex: 3, maxWidth: 364 }}>
          <CellText>{translationKeys.locationLabel}</CellText>
        </HeaderCellItem>
        <FOHSpaceHuge />
        <HeaderCellItem style={{ flex: 1, maxWidth: 118 }}>
          <CellText>{translationKeys.noOfPositionsLabel}</CellText>
        </HeaderCellItem>
        <FOHSpaceHuge />
        <HeaderCellItem style={{ flex: 1, maxWidth: 198 }}>
          <CellText>{translationKeys.createdByLabel}</CellText>
        </HeaderCellItem>
      </StyledFOHViewContainer>
    </StyledFOHView>
  );
};

const StyledFOHView = styled(FOHView)`
  width: 80%;
  padding-horizontal: 12px;
`;

const StyledFOHViewContainer = styled(FOHView)`
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const HeaderCellItem = styled(FOHView)`
  flex: 3;
`;

const CellText = styled(FOHSmallDetailLabel)`
  font-size: 12px;
  font-family: ${FOHFonts.MEDIUM};
  font-weight: 600;
  line-height: 16px;
  color: ${FOHColors.GRAYSCALE_500};
  text-transform: uppercase;
`;
