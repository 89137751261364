import React, { useMemo, useRef, useState, useEffect } from 'react';

import { compose, defaultTo, length, path, prop } from 'ramda';
import { useTranslation } from 'react-i18next';

import {
  FOHBannerType,
  FOHButton,
  FOHCircleLoader,
  FOHColors,
  FOHDivider,
  FOHLabel,
  FOHScrollView,
  FOHSearchBar,
  FOHSpace,
  FOHSpaceSmall,
  FOHView,
  FOHWhiteAddIcon
} from '../../components';
import {
  handleMutation,
  scrollToTop,
  toDate,
  unwrapEdges,
  unwrapEdgesAt,
  useIsMobile
} from '../../utils';
import {
  GetEmployerUsersDocument,
  useEmployerUserMutation,
  useGetEmployerUsersQuery,
  useResendEmployerUserInviteMutation
} from '../../graphql/generated';
import { ROLES } from '../../constants';
import { isEmailValid } from '../../components/utils';

import { withEmployerSettings } from '../EmployerSettings/withEmployerSettings';
import { useBanner } from '../Navigation';
import { useCandidateFeedContext } from '../CandidateFeed/useCandidateFeedContext';
import { useGetMeData } from '../SignIn';

import {
  FOHAddOrEditTeammate,
  FOHHiringTeammateCard,
  FOHShowMyLocationsModal,
  RemoveTeammateConfirmationModal
} from './components';

const OrganizeYourTeam = () => {
  const { t } = useTranslation('MyTeamFeature');

  const { t: errorsTFunction } = useTranslation('errors');

  const { isMobile } = useIsMobile();

  const scrollRef = useRef();
  const { setBanner } = useBanner();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [teammateSearchValue, setTeammateSearchValue] = useState('');
  const [isOpenLocationDropdown, setIsOpenLocationDropdown] = useState(false);
  const [isDisplayAddNewForm, setIsDisplayAddNewForm] = useState(false);
  const [isDisplayEditForm, setIsDisplayEditForm] = useState(false);
  const [errors, setErrors] = useState(null);
  const [isOpenShowMyLocationsModal, setIsOpenShowMyLocationsModal] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [viewLocations, setViewLocations] = useState([]);
  const [removeTeammateId, setRemoveTeammateId] = useState('');
  const [isOpenRemoveTeammateModal, setIsOpenRemoveTeammateModal] =
    useState(false);

  const {
    locationFilters,
    multiSelectLocation,
    selectAllLocations,
    setLocationSearch,
    locationSearch,
    myLocations,
    clearLocationFilters
  } = useCandidateFeedContext();

  const locationOptions = myLocations.map(location => ({
    label: prop('displayName', location),
    value: prop('id', location),
    sublabel: prop('formattedAddress', location),
    id: prop('id', location)
  }));

  const {
    data: employerUsers,
    loading: employerUsersQLoading,
    refetch: employerUsersQ
  } = useGetEmployerUsersQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      search: teammateSearchValue
    }
  });

  const myEmployerUsers = defaultTo(
    [],
    unwrapEdgesAt(['employerUsers', 'edges'], employerUsers)
  );

  const { data: me } = useGetMeData();

  const isAdmin = path(['employerProfileUser', 'role'], me) === ROLES.ADMIN;

  const [permissionType, setPermissionType] = useState(
    !isAdmin ? ROLES.USER : ROLES.ADMIN
  );

  const [employerUserMutation] = useEmployerUserMutation();
  const [resendInviteToEmployerUser] = useResendEmployerUserInviteMutation();

  const handleScrollToTop = () => {
    scrollToTop({});
    scrollRef.current &&
      scrollRef.current.scrollTo({
        y: 0,
        animated: true
      });
  };

  const handleResetFormValues = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    clearLocationFilters('');
    setPermissionType(!isAdmin ? ROLES.USER : ROLES.ADMIN);
  };

  const handleOnAddOrEditTeammate = async (employerUser = {}) => {
    setIsLoading(true);
    setErrors(null);

    const isNewEmployerUser = Object.keys(employerUser).length === 0;

    const variables = !isNewEmployerUser
      ? {
          // edit
          ...employerUser
        }
      : {
          // create
          firstName,
          lastName,
          email,
          role: permissionType,
          locations:
            permissionType === ROLES.ADMIN ? undefined : locationFilters
        };

    const [response, mutationError] = await handleMutation(
      employerUserMutation({
        variables,
        refetchQueries: [
          {
            query: GetEmployerUsersDocument,
            variables: {}
          }
        ]
      })
    );

    setIsLoading(false);

    if (!response || mutationError) {
      setErrors({
        email: isEmailValid(email, errorsTFunction)
      });
      setBanner({
        bannerType: FOHBannerType.DANGER,
        bannerMessage: mutationError?.message || t('common:errors.generalApi')
      });
      return;
    }

    handleScrollToTop();

    if (isDisplayAddNewForm) setIsDisplayAddNewForm(false);

    if (isDisplayEditForm) setIsDisplayEditForm(false);

    handleResetFormValues();

    setBanner({
      bannerType: FOHBannerType.SUCCESS,
      bannerMessage: t('common:success')
    });
  };

  const permissionOptions = useMemo(() => {
    const defaultOptions = [
      {
        value: ROLES.USER,
        label: t('thisLocation'),
        description: t('thisLocationDesc')
      },
      {
        value: ROLES.INTERVIEWER,
        label: t('interviewer'),
        description: t('interviewerDesc')
      }
    ];

    const permissions = isAdmin
      ? [
          {
            value: ROLES.ADMIN,
            label: t('admin'),
            description: t('allLocationsDesc')
          },
          ...defaultOptions
        ]
      : defaultOptions;

    return permissions;
  }, [me]);

  useEffect(() => {
    if (!employerUsersQLoading) {
      employerUsersQ();
    }
  }, [teammateSearchValue]);

  return (
    <FOHView
      style={{
        alignItems: isMobile ? 'center' : 'flex-start'
      }}
    >
      <FOHScrollView
        style={{
          minHeight: '80vh',
          height: isMobile ? 'unset' : '80vh',
          width: '100%'
        }}
        ref={scrollRef}
      >
        <FOHView
          style={{
            paddingHorizontal: isMobile ? 14 : 28,
            paddingTop: isMobile ? 14 : 20,
            paddingBottom: isMobile ? 14 : 50,
            width: '100%'
          }}
        >
          <FOHView
            style={{
              width: '100%',
              flexDirection: isMobile ? 'column' : 'row-reverse',
              justifyContent: isMobile ? 'center' : 'space-between',
              alignItems: 'flex-end'
            }}
          >
            <FOHButton
              title="Add a teammate"
              icon={FOHWhiteAddIcon}
              disabled={isDisplayAddNewForm || isDisplayEditForm}
              onPress={() => setIsDisplayAddNewForm(true)}
              style={{ width: '100%', maxWidth: 172 }}
            />
            <FOHSearchBar
              style={
                isMobile ? { width: '100%', marginTop: 10 } : { width: '40%' }
              }
              searchPlaceholder={t('searchTeammatePlaceholder')}
              onChangeSearch={setTeammateSearchValue}
              searchValue={teammateSearchValue}
              clearSearch={() => setTeammateSearchValue('')}
            />
          </FOHView>
          <FOHSpace />
          {isDisplayAddNewForm ? (
            <>
              <FOHAddOrEditTeammate
                headerTitle={t('InviteTeamMates:addTeamMateLabel')}
                handleOnClose={() => setIsDisplayAddNewForm(false)}
                firstName={firstName}
                setFirstName={setFirstName}
                lastName={lastName}
                setLastName={setLastName}
                email={email}
                setEmail={value => {
                  setEmail(value);
                  if (email.includes('@')) {
                    const error = isEmailValid(value, errorsTFunction);
                    if (error) {
                      setErrors({ email: error });
                    } else {
                      setErrors(null);
                    }
                  }
                }}
                checkEmail={value => {
                  const error = isEmailValid(value, errorsTFunction);
                  if (error) {
                    setErrors({ email: error });
                  } else {
                    setErrors(null);
                  }
                }}
                permissionType={permissionType}
                setPermissionType={setPermissionType}
                permissionOptions={permissionOptions}
                isOpenLocationDropdown={isOpenLocationDropdown}
                setIsOpenLocationDropdown={setIsOpenLocationDropdown}
                selectedLocationFilters={locationFilters}
                locationSearch={locationSearch}
                setLocationSearch={setLocationSearch}
                handleMultiSelectLocation={multiSelectLocation}
                locationOptions={locationOptions}
                handleSelectAllLocations={selectAllLocations}
                submitBtnLabel={t('inviteLabel')}
                isDisabledSubmit={
                  !firstName ||
                  !lastName ||
                  !email ||
                  isLoading ||
                  !permissionType ||
                  isEmailValid(email, errorsTFunction)
                }
                handleOnSubmit={() => handleOnAddOrEditTeammate()}
                errors={errors}
                zIndex={defaultTo(999, length(myLocations) + 2)}
              />
              <FOHSpace />
            </>
          ) : null}
          <FOHView
            style={{
              width: '100%',
              alignItems: 'flex-start',
              paddingVertical: 6
            }}
          >
            <FOHView
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%'
              }}
            >
              <FOHView style={{ flex: 3 }}>
                <FOHLabel
                  style={{
                    textTransform: 'capitalize',
                    color: FOHColors.GRAYSCALE_900
                  }}
                >
                  {t('user')}
                </FOHLabel>
              </FOHView>
              {!isMobile ? (
                <FOHView style={{ flex: 3 }}>
                  <FOHLabel
                    style={{
                      textTransform: 'capitalize',
                      color: FOHColors.GRAYSCALE_900
                    }}
                  >
                    {t('emailPlaceholder')}
                  </FOHLabel>
                </FOHView>
              ) : null}
              <FOHView style={{ flex: isMobile ? 1.5 : 2 }}>
                <FOHLabel
                  style={{
                    textTransform: 'capitalize',
                    color: FOHColors.GRAYSCALE_900
                  }}
                >
                  {t('Integrations:role')}
                </FOHLabel>
              </FOHView>
              <FOHView style={{ flex: 1 }}></FOHView>
            </FOHView>
            <FOHSpaceSmall />
            <FOHDivider />
            <FOHSpace />
            <FOHView style={{ width: '100%' }}>
              {employerUsersQLoading ? (
                <FOHView
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingTop: 12
                  }}
                >
                  <FOHCircleLoader />
                </FOHView>
              ) : length(myEmployerUsers) > 0 ? (
                myEmployerUsers.map((employerUser, keyIndex) => {
                  // Only Admins can see the Edit and Remove buttons
                  const canHideOnly =
                    (path(['employerProfileUser', 'id'], me) ===
                      prop('id', employerUser) &&
                      path(['role'], employerUser) === ROLES.ADMIN) ||
                    path(['employerProfileUser', 'role'], me) !== ROLES.ADMIN;

                  const selectedLocationFilters = defaultTo(
                    [],
                    unwrapEdges(path(['locations', 'edges'], employerUser))
                  )?.map(location => location.id);

                  return (
                    <>
                      <FOHHiringTeammateCard
                        key={keyIndex}
                        zIndex={length(myEmployerUsers) - (keyIndex + 1)}
                        profileImage={path(
                          ['user', 'profileImage'],
                          employerUser
                        )}
                        fullName={`${employerUser?.user?.firstName} ${employerUser?.user?.lastName}`}
                        email={path(['user', 'email'], employerUser)}
                        inviteSentDate={
                          path(['user', 'unclaimed'], employerUser)
                            ? `${toDate(
                                path(['createdAt'], employerUser),
                                'MM/dd/yyyy'
                              )}`
                            : null
                        }
                        onResendInvite={async () => {
                          await handleMutation(
                            resendInviteToEmployerUser({
                              variables: { id: prop('id', employerUser) }
                            })
                          );

                          handleScrollToTop();

                          setBanner({
                            bannerType: FOHBannerType.SUCCESS,
                            bannerMessage: t('common:emailSent')
                          });
                        }}
                        currentUserRole={
                          path(['role'], employerUser) === ROLES.ADMIN
                            ? t('admin')
                            : path(['role'], employerUser) === ROLES.USER
                            ? t('thisLocation')
                            : t('interviewer')
                        }
                        accessOnSelectedLocations={
                          path(['locations', 'edges'], employerUser)?.length ===
                          1
                            ? path(
                                ['locations', 'edges', '0', 'node', 'name'],
                                employerUser
                              )
                            : path(['role'], employerUser) !== ROLES.ADMIN
                            ? `${
                                path(['locations', 'edges'], employerUser)
                                  ?.length
                              } Locations`
                            : null
                        }
                        hideActions={canHideOnly}
                        onEditAction={isEditable => {
                          setIsDisplayEditForm(isEditable);

                          if (isDisplayAddNewForm)
                            setIsDisplayAddNewForm(false);
                        }}
                        onDeleteAction={() => {
                          setRemoveTeammateId(prop('id', employerUser));
                          setIsOpenRemoveTeammateModal(true);
                        }}
                        isActivated={!path(['user', 'unclaimed'], employerUser)}
                        firstName={path(['user', 'firstName'], employerUser)}
                        lastName={path(['user', 'lastName'], employerUser)}
                        permissionType={path(['role'], employerUser)}
                        permissionOptions={permissionOptions}
                        locationOptions={locationOptions}
                        locationFilters={selectedLocationFilters}
                        employerUser={employerUser}
                        handleOnEditFormSubmit={handleOnAddOrEditTeammate}
                        handleOnViewLocations={locations => {
                          setViewLocations(locations);
                          setIsOpenShowMyLocationsModal(true);
                        }}
                      />
                      <FOHSpace />
                    </>
                  );
                })
              ) : null}
            </FOHView>
          </FOHView>
        </FOHView>
      </FOHScrollView>
      {isOpenRemoveTeammateModal ? (
        <RemoveTeammateConfirmationModal
          open={isOpenRemoveTeammateModal}
          teammateId={removeTeammateId}
          close={() => {
            setIsOpenRemoveTeammateModal(false);
            setRemoveTeammateId('');
          }}
        />
      ) : null}
      {isOpenShowMyLocationsModal ? (
        <FOHShowMyLocationsModal
          dialog={!isMobile}
          open={isOpenShowMyLocationsModal}
          viewLocations={viewLocations}
          close={() => {
            setIsOpenShowMyLocationsModal(false);
            setViewLocations([]);
          }}
        />
      ) : null}
    </FOHView>
  );
};

export default compose(withEmployerSettings)(OrganizeYourTeam);
