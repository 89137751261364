import React from 'react';

import styled from 'styled-components/native';

import { FOHLabel } from '../../ingredients/text/FOHLabel';
import {
  FOHColors,
  FOHSpaceSmall,
  FOHSpaceSmallest,
  FOHTouchableOpacity,
  FOHView
} from '../../ingredients';

export interface FOHTabProps {
  icon?: any;
  fullWidth?: boolean;
  onPress?: () => any;
  selected: boolean;
  selectedIcon?: any;
  title?: string;
  tall?: boolean;
  testID?: string;
}

export const FOHTab = React.memo(
  ({
    fullWidth,
    icon,
    onPress,
    selected,
    selectedIcon,
    tall,
    testID,
    title
  }: FOHTabProps) => {
    const isIconShown = icon && !selected;
    const isSelectedIconShown = selectedIcon && selected;

    return (
      <FOHTouchableOpacity onPress={onPress} testID={testID}>
        <FOHView style={{ alignItems: 'center', justifyContent: 'flex-end' }}>
          <FOHView style={{ flexDirection: 'row', alignItems: 'center' }}>
            {isIconShown ? (
              <>
                {icon()}
                <FOHSpaceSmallest />
              </>
            ) : (
              <></>
            )}

            {isSelectedIconShown ? (
              <>
                {selectedIcon()} <FOHSpaceSmallest />
              </>
            ) : (
              <></>
            )}

            {title && (
              <TabLabel selected={selected} tall={tall}>
                {title}
              </TabLabel>
            )}
          </FOHView>

          {tall ? (
            <>
              <FOHSpaceSmall />
              <FOHSpaceSmallest />
            </>
          ) : (
            <FOHSpaceSmall />
          )}
          <Tab fullWidth={fullWidth} selected={selected} />
        </FOHView>
      </FOHTouchableOpacity>
    );
  }
);

const Tab = styled(FOHView)<{
  fullWidth?: FOHTabProps['fullWidth'];
  selected?: FOHTabProps['selected'];
}>`
  height: ${({ selected }: { selected: boolean }) => (selected ? '4px' : '0px')}
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  width: ${({ fullWidth }: { fullWidth: boolean }) =>
    fullWidth ? '100%' : '80px'};
  background-color: ${({ selected }: { selected: boolean }) =>
    selected ? FOHColors.GRAYSCALE_G1 : FOHColors.BORDER_GRAY};
`;

const TabLabel = styled(FOHLabel)<{ tall?: boolean; selected?: boolean }>`
  color: ${({ selected }: { selected: boolean }) =>
    selected ? FOHColors.GRAYSCALE_G1 : FOHColors.GRAYSCALE_700};
  font-weight: ${({ selected }: { selected: boolean }) =>
    selected ? 'bold' : 'normal'};
  font-size: ${({ tall }: { tall: boolean }) => (tall ? '14px' : 'inherit')};
`;
