import React, { FunctionComponent, Dispatch, SetStateAction } from 'react';

import { compose } from 'ramda';
import { TouchableOpacity, StyleProp, ViewStyle } from 'react-native';
import styled from 'styled-components/native';
import { useTheme } from 'styled-components';

import {
  FOHBlueSuccessIcon,
  FOHColors,
  FOHExpandIcon,
  FOHLabel,
  FOHLabelSmall,
  FOHView,
  FullWidthCard,
  withError
} from '../../ingredients';
import { FOH_INPUT_HEIGHT } from '../../constants';
import { useClickOutside } from '../../spices';
import { FOHIconProps } from '../../typings';
import { FOHSelectTemplateDefaultCell } from '../../recipes';

export interface FOHMessageTemplateSelectDropdownProps {
  backgroundColor?: string;
  fieldLabel?: string;
  icon?: FunctionComponent<FOHIconProps>;
  open: boolean;
  selectedLabel: string;
  setOpen: Dispatch<SetStateAction<boolean>>;
  placeholder: string;
  style?: StyleProp<ViewStyle>;
  testID?: string;
  disabled?: boolean;
  error?: any;
}

const MessageTemplateBadge: FunctionComponent<{
  icon: FOHMessageTemplateSelectDropdownProps['icon'];
  fieldLabel: FOHMessageTemplateSelectDropdownProps['fieldLabel'];
  disabled: FOHMessageTemplateSelectDropdownProps['disabled'];
  error: FOHMessageTemplateSelectDropdownProps['error'];
}> = ({ disabled, error, fieldLabel, icon: Icon }) => {
  const theme = useTheme();

  if (!Icon) return null;

  return (
    <FOHView
      style={{
        alignItems: 'center',
        flexDirection: 'row',
        left: -2,
        top: -2
      }}
    >
      <Icon
        style={{
          tintColor: error
            ? FOHColors.RED_300
            : disabled
            ? FOHColors.GRAYSCALE_400
            : theme?.brandColor || FOHColors.PRIMARY_TEAL_P1,
          height: 12,
          width: 12
        }}
      />
      <FOHLabelSmall
        style={{
          color: error
            ? FOHColors.RED_300
            : disabled
            ? FOHColors.GRAYSCALE_400
            : theme?.brandColor || FOHColors.PRIMARY_TEAL_P1,
          paddingLeft: 2,
          fontSize: 12,
          lineHeight: 12,
          textTransform: 'uppercase'
        }}
      >
        {fieldLabel}
      </FOHLabelSmall>
    </FOHView>
  );
};

const DropdownArrow: FunctionComponent<{
  open: boolean;
  error: any;
  disabled: boolean;
}> = ({ disabled, error, open }) => {
  return (
    <FOHView
      pointerEvents="none"
      style={{
        left: -2,
        top: 5
      }}
    >
      {open ? (
        <FOHBlueSuccessIcon
          style={{
            tintColor: error
              ? FOHColors.RED_300
              : disabled
              ? FOHColors.GRAYSCALE_400
              : FOHColors.PRIMARY_TEAL_P1,
            width: 24,
            height: 24
          }}
        />
      ) : (
        <FOHExpandIcon
          style={{
            tintColor: error
              ? FOHColors.RED_300
              : disabled
              ? FOHColors.GRAYSCALE_400
              : FOHColors.GRAYSCALE_500
          }}
        />
      )}
    </FOHView>
  );
};

export const FOHMessageTemplateSelectDropdown: FunctionComponent<
  FOHMessageTemplateSelectDropdownProps
> = ({
  style,
  disabled,
  fieldLabel,
  error,
  open,
  setOpen,
  testID,
  backgroundColor,
  selectedLabel = '',
  placeholder,
  icon,
  children
}) => {
  const [ref] = useClickOutside({
    close: () => setOpen(false)
  });

  return (
    <FOHView
      ref={ref}
      style={[{ width: '100%' }, style]}
      testID={'FOHMessageTemplateSelectDropdown'}
    >
      {selectedLabel === '' && !open ? (
        <FOHSelectTemplateDefaultCell
          labelPlaceholder={placeholder}
          onKeyPress={() => {
            if (!disabled) setOpen(true);
          }}
        />
      ) : (
        <SelectContainer
          //@ts-ignore disabled
          disabled={disabled}
          error={error}
          onPress={() => {
            if (!disabled) setOpen(prev => !prev);
          }}
          style={{
            backgroundColor: 'transparent',
            borderColor: open ? FOHColors.LIGHT_BLUE : 'transparent',
            borderRadius: 4,
            borderWidth: open ? 2 : 1,
            height: FOH_INPUT_HEIGHT
          }}
          testID={testID}
        >
          <FOHView
            style={{
              backgroundColor: disabled
                ? FOHColors.GRAYSCALE_100
                : backgroundColor || FOHColors.WHITE,
              borderColor: error
                ? FOHColors.RED_300
                : disabled
                ? FOHColors.GRAYSCALE_200
                : open
                ? FOHColors.PRIMARY_TEAL_P1
                : FOHColors.GRAYSCALE_300,
              borderRadius: 4,
              borderWidth: 1,
              flexDirection: 'row',
              height: FOH_INPUT_HEIGHT,
              justifyContent: 'space-between',
              padding: 8,
              width: '100%'
            }}
          >
            <FOHView>
              {icon ? (
                <MessageTemplateBadge
                  disabled={disabled}
                  error={error}
                  fieldLabel={fieldLabel}
                  icon={icon}
                />
              ) : (
                <></>
              )}
              <FOHLabel
                style={{
                  color: disabled
                    ? FOHColors.GRAYSCALE_400
                    : FOHColors.GRAYSCALE_900,
                  fontSize: 16,
                  lineHeight: 18,
                  fontWeight: 400
                }}
              >
                {selectedLabel}
              </FOHLabel>
            </FOHView>

            <DropdownArrow
              disabled={disabled || false}
              error={null}
              open={open}
            />
          </FOHView>
        </SelectContainer>
      )}
      {children ? (
        <FullWidthCard
          shadow={true}
          style={{
            padding: 15,
            position: 'absolute',
            top: 54,
            width: '100%',
            minWidth: 293,
            zIndex: 10
          }}
        >
          {children}
        </FullWidthCard>
      ) : (
        <></>
      )}
    </FOHView>
  );
};

const SelectContainerStyle = styled(TouchableOpacity)`
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: ${FOHColors.WHITE};
  border-radius: 4px;
`;

const SelectContainer = compose(withError)(SelectContainerStyle);
