import React from 'react';

import cal from '../../../images/calendar_ic.svg';

import { FOHImage } from '../../ingredients';

interface FOHCalenderIconProps {
  style?: any;
}

export const FOHCalendarIcon = (props: FOHCalenderIconProps) => (
  <FOHImage
    source={{ uri: cal }}
    style={
      props && !!props.style
        ? { width: 17, height: 18, ...props.style }
        : { width: 17, height: 18 }
    }
  />
);
