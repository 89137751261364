import React, { useRef } from 'react';

import { defaultTo } from 'ramda';
import { useHover } from 'react-native-web-hooks';
import { TouchableOpacity, View } from 'react-native';

import { useClickOutside } from '../../spices';
import {
  FOHSpaceSmall,
  FOHToolTip,
  FOHToolTipType,
  FOHButtonContainer,
  FOHColors,
  FOHCompoundDropdownButtonProps,
  FullWidthCard,
  FOHVerticalDivider,
  FOHDropDownArrow,
  ButtonText
} from '../../ingredients';

export const FOHCompoundDropdownButtonBordered = (
  props: FOHCompoundDropdownButtonProps
) => {
  const [ref] = useClickOutside({
    close: () => !!props.open && props.toggleOpen && props.toggleOpen()
  });
  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);
  const btnRef = useRef(null);
  const isBtnHover = useHover(btnRef);
  const dropdownRef = useRef(null);
  const isDropdownHover = useHover(dropdownRef);

  return (
    <View ref={ref}>
      <View
        style={{
          width: '100%',
          zIndex: 1
        }}
      >
        {props.disabled && props.isToolTip && (
          <View style={{ position: 'absolute' }}>
            <FOHToolTip
              type={FOHToolTipType.BOTTOM_CENTER}
              helpText={props.tipHelper}
              open={isHovered}
              style={{ width: 250, top: 85 }}
              width={250}
            />
          </View>
        )}
        <FOHButtonContainer
          ref={hoverRef}
          style={{
            backgroundColor: props.disabled
              ? FOHColors.GRAYSCALE_100
              : FOHColors.WHITE,
            borderRadius: 4,
            width: '100%',
            flexDirection: 'row',
            borderWidth: 1,
            borderColor: FOHColors.GRAYSCALE_300,
            ...(props.style ? props.style : {})
          }}
        >
          <TouchableOpacity
            ref={btnRef}
            onPress={props.onPress}
            disabled={props.disabled}
            testID={props.testID || 'foh-compound-dropdown-button-main-action'}
            style={{
              flex: 4,
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              paddingHorizontal: 16,
              borderRadius: 4,
              backgroundColor:
                !props.disabled && isBtnHover ? FOHColors.GRAYSCALE_10 : ''
            }}
          >
            {props.icon && (
              <>
                {props.icon}
                <FOHSpaceSmall />
              </>
            )}
            <ButtonText
              style={{
                color: props.disabled
                  ? FOHColors.GRAYSCALE_400
                  : props.textColor
                  ? props.textColor
                  : FOHColors.GRAYSCALE_900
              }}
            >
              {props.title}
            </ButtonText>
          </TouchableOpacity>

          <FOHVerticalDivider
            color={
              props.secondaryColor
                ? props.secondaryColor
                : FOHColors.GRAYSCALE_300
            }
          />
          <TouchableOpacity
            ref={dropdownRef}
            onPress={props.toggleOpen}
            disabled={props.disabled}
            testID={
              props.testID
                ? `${props.testID}-open-close`
                : 'foh-compound-dropdown-button-open-close'
            }
            style={{
              flex: 1,
              paddingHorizontal: 8,
              justifyContent: 'center',
              alignItems: 'center',
              borderBottomRightRadius: 4,
              borderTopRightRadius: 4,
              backgroundColor: props.open
                ? FOHColors.GRAYSCALE_200
                : !props.disabled && isDropdownHover
                ? FOHColors.GRAYSCALE_10
                : ''
            }}
          >
            <FOHDropDownArrow
              style={{
                tintColor: props.disabled
                  ? FOHColors.GRAYSCALE_400
                  : props.textColor
                  ? props.textColor
                  : FOHColors.GRAYSCALE_900,
                width: 32,
                height: 32,
                transform: props.open ? [{ rotate: '180deg' }] : []
              }}
            />
          </TouchableOpacity>
        </FOHButtonContainer>
        {props.open && (
          <FullWidthCard
            shadow={true}
            style={{
              padding: 24,
              position: 'absolute',
              top: props.menuTop || 54,
              width: '100%',
              minWidth: 293,
              zIndex: 10,
              right: 0,
              ...defaultTo({}, props.cardStyle)
            }}
          >
            {props.children}
          </FullWidthCard>
        )}
      </View>
    </View>
  );
};
