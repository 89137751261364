import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { prop, path, defaultTo } from 'ramda';

import { handleMutation } from '../../utils';
import {
  EmployerCandidateNoteDocument,
  useEmployerCandidateNoteQuery,
  useMutateCreateEmployerCandidateMutation,
  useMutateUpdateEmployerCandidateMutation
} from '../../graphql/generated';
import { FOHCandidateNotes, FOHView } from '../../components';

export const CandidateNotes = props => {
  const { employerCandidate } = props;

  const { t } = useTranslation('CandidateFeed');

  const candidateNotes = useEmployerCandidateNoteQuery({
    variables: { employerCandidate: prop('id', employerCandidate) }
  });
  const [updateCandidateNotes] = useMutateUpdateEmployerCandidateMutation();
  const [createCandidateNotes] = useMutateCreateEmployerCandidateMutation();

  const text = defaultTo(
    '',
    path(['data', 'employerCandidateNote', 'text'], candidateNotes)
  );
  const candidateNotesId = defaultTo(
    '',
    path(['data', 'employerCandidateNote', 'id'], candidateNotes)
  );

  const [notes, setNotes] = useState('');
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [submitLabel, setSubmitLabel] = useState(t('common:save'));
  const [, setError] = useState();

  const onPressSubmit = async () => {
    if (candidateNotesId) {
      const [, error] = await handleMutation(
        updateCandidateNotes({
          variables: {
            id: candidateNotesId,
            text: notes
          }
        })
      );
      if (error) {
        setError(error);
      }
    } else {
      const [, error] = await handleMutation(
        createCandidateNotes({
          variables: {
            employerCandidate: prop('id', employerCandidate),
            text: notes
          },
          refetchQueries: [
            {
              query: EmployerCandidateNoteDocument,
              variables: {
                employerCandidate: prop('id', employerCandidate)
              }
            }
          ]
        })
      );
      if (error) {
        setError(error);
      }
    }
  };

  useEffect(() => {
    if (text) {
      setNotes(text);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateNotes]);

  return (
    <FOHView style={{ padding: 10 }}>
      <FOHCandidateNotes
        message={notes}
        messagePlaceholder={t('notePlaceholder')}
        setMessage={message => {
          setNotes(message);
          setDisableSubmit(false);
          setSubmitLabel('Save');
        }}
        submitLabel={submitLabel}
        onPressSubmit={async () => {
          setDisableSubmit(true);
          setSubmitLabel(t('saving'));
          await onPressSubmit();
          setSubmitLabel(t('saved'));
        }}
        disableSubmit={disableSubmit}
        error={null}
      />
    </FOHView>
  );
};
