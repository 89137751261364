import React from 'react';

import styled from 'styled-components/native';

import {
  CardScreen,
  FOHView,
  FOHHeaderH1,
  FOHDetailText,
  FOHSmallDetailLabel,
  FOHLabel,
  FOHLabelMedium,
  FOHColors,
  FOHButton
} from '../../ingredients';
import { FOHMultipleProfilePhotos } from '../../recipes';

interface FOHGetFreePassiveCandidatesBannerProps {
  thumbnails: Array<{ uri: string }>;
  totalCount?: string | number;
  labelLHS: string;
  titleLHS: string;
  descLHS: string;
  onPressSubmit: () => void;
  submitBtnLabel: string;

  labelRHS: string;
  stepsRHS: Array<FOHPassiveWorkStepProps>;

  isMobile?: boolean;
}

interface FOHPassiveWorkStepProps {
  icon: () => void;
  headerText: string;
  detailText: string;
}

export const FOHGetFreePassiveCandidatesBanner = (
  props: FOHGetFreePassiveCandidatesBannerProps
) => (
  <FOHGetFreePassiveCandidatesBannerCard>
    <PassiveCandidatesBannerCardContainer>
      <PassiveCandidatesBannerCardWrapper>
        <LeftContainer
          style={{
            width: props.isMobile ? '100%' : '50%',
            padding: props.isMobile ? 24 : 48
          }}
        >
          <FOHMultipleProfilePhotos {...props} />
          <Row>
            <Label>{props.labelLHS}</Label>
          </Row>
          <Row>
            <FOHHeaderH1 style={{ textAlign: 'left' }}>
              {props.titleLHS}
            </FOHHeaderH1>
          </Row>
          <Row>
            <FOHDetailText style={{ lineHeight: 30, textAlign: 'left' }}>
              {props.descLHS}
            </FOHDetailText>
          </Row>
          <Row style={{ width: '100%' }}>
            <FOHButton
              style={{
                width: '100%',
                maxWidth: '400px'
              }}
              fullWidth={true}
              onPress={props.onPressSubmit}
              title={props.submitBtnLabel}
            />
          </Row>
        </LeftContainer>
        <RightContainer
          style={{
            width: props.isMobile ? '100%' : '50%',
            padding: props.isMobile ? 24 : 48
          }}
        >
          <Row>
            <Header>{props.labelRHS}</Header>
          </Row>
          {!!props.stepsRHS &&
            props.stepsRHS.map((step, keyIndex) => (
              <Row key={`card-steps-${keyIndex}`}>
                <Card key={keyIndex}>
                  <IconContainer>{step.icon()}</IconContainer>
                  <LabelsContainer>
                    <FOHLabelMedium
                      style={{
                        lineHeight: 24,
                        color: FOHColors.NARO_GREY,
                        fontWeight: '600',
                        marginBottom: 10
                      }}
                    >
                      {step.headerText}
                    </FOHLabelMedium>
                    <FOHSmallDetailLabel
                      style={{
                        lineHeight: 24
                      }}
                    >
                      {step.detailText}
                    </FOHSmallDetailLabel>
                  </LabelsContainer>
                </Card>
              </Row>
            ))}
        </RightContainer>
      </PassiveCandidatesBannerCardWrapper>
    </PassiveCandidatesBannerCardContainer>
  </FOHGetFreePassiveCandidatesBannerCard>
);

const FOHGetFreePassiveCandidatesBannerCard = styled(CardScreen)`
  align-items: center;
  border-radius: 6px;
  border: 1px solid #e8e8e8;
  background-color: ${FOHColors.WHITE};
  width: 100%;
`;

const PassiveCandidatesBannerCardContainer = styled(FOHView)`
  width: 100%;
  height: 100%;
`;

const PassiveCandidatesBannerCardWrapper = styled(FOHView)`
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-evenly;
`;

const LeftContainer = styled(FOHView)`
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  background-color: #fcfcfc;
`;

const RightContainer = styled(FOHView)`
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  background-color: ${FOHColors.WHITE};
`;

const Row = styled(FOHView)`
  padding: 12px;
  justify-content: center;
`;

const Label = styled(FOHLabel)`
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  color: ${FOHColors.PRIMARY_TEAL_400};
  text-align: left;
  letter-spacing: 0.12em;
`;

const Header = styled(FOHDetailText)`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  text-align: left;
  color: #bdbdbd;
`;

const Card = styled(FOHView)`
  flex-direction: row;
  padding-bottom: 10px;
`;

const IconContainer = styled(FOHView)`
  padding: 12px;
  width: 56px;
  height: 56px;
  justify-content: center;
  align-items: center;
  background-color: #e3f5f9;
  border-radius: 6px;
`;

const LabelsContainer = styled(FOHView)`
  margin-left: 20px;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
`;
