import React, { ReactNode } from 'react';

import styled from 'styled-components/native';
import { StyleProp, ViewStyle } from 'react-native';

import { FOHErrorLabel } from '../text/FOHErrorLabel';
import { FOHView } from '../containers/Containers';

export interface FOHRequiredFieldBulletProps {
  children: ReactNode;
  color?: string;
  style?: StyleProp<ViewStyle>;
  bulletStyle?: StyleProp<ViewStyle>;
  reverse?: boolean;
}

export const FOHRequiredFieldBullet: React.FC<FOHRequiredFieldBulletProps> = ({
  children,
  style,
  bulletStyle,
  reverse
}) => (
  <Row style={style} isReversed={reverse}>
    <FOHView
      style={{
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        ...((bulletStyle || {}) as ViewStyle)
      }}
    >
      <FOHErrorLabel style={{ padding: 0 }}>{'*'}</FOHErrorLabel>
    </FOHView>
    {children}
  </Row>
);

const Row = styled(FOHView)<{ isReversed?: boolean }>`
  flex-direction: ${(props: { isReversed?: boolean }) =>
    props.isReversed ? 'row-reverse' : 'row'};
  align-items: flex-start;
`;
