import * as urls from '../constants/urls';

const isEnglishLocale = lang => /^en.*/.test(lang);

export const getToLanguage = currentLang =>
  !currentLang || isEnglishLocale(currentLang) ? 'es' : 'en';

export const getLanguageName = currentLang =>
  isEnglishLocale(currentLang) ? 'English' : 'Español';

export const getTermsUrl = currentLang =>
  isEnglishLocale(currentLang) || !currentLang
    ? urls.TERMS_DOCUMENT_URL
    : urls.TERMS_DOCUMENT_SPANISH_URL;
