import React from 'react';

import hiring_badge from '../../../images/hiring_badge.svg';

import { FOHImage } from '../../ingredients';

export interface FOHHiringIconProps {
  style?: any;
}

export const FOHHiringIcon = (props: FOHHiringIconProps) => (
  <FOHImage
    source={{ uri: hiring_badge }}
    style={{ width: 10, height: 10, ...props.style }}
  />
);
