import React, { useMemo } from 'react';

import Calendar from 'react-calendar';
import { useSpring, animated } from 'react-spring';
import { Platform } from 'react-native';
import styled from 'styled-components/native';
import { DateTime } from 'luxon';
import { prop, defaultTo, compose } from 'ramda';

import { useClickOutside } from '../../spices';
import { FOH_INPUT_HEIGHT } from '../../constants';

import {
  FOHExpandIcon,
  FOHView,
  FOHLabelSmall,
  FOHCalendarIcon,
  FOHLabel,
  FOHColors,
  withError,
  FOHTouchableOpacity
} from '..';
import '../../../assets/calendar.css';

export enum FOHCalendarPositionType {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM'
}

export interface FOHCalendarPickerProps {
  value: any;
  setValue: (value: any) => void;
  error?: any;
  placeholder: string;
  width?: any;
  disabled?: any;
  btnBackground?: any;
  calendarCompProps?: any;
  defaultPlaceHolderText?: string;
  open: boolean;
  setOpen: (value: boolean) => void;
  calendarPositionType?: FOHCalendarPositionType;
}

export const FOHCalendarPicker = (props: FOHCalendarPickerProps) => {
  const { open, setOpen } = props;

  const dateString = useMemo(() => {
    if (props?.value) {
      const date = new Date(props.value);
      const iso = new Date(date.getTime()).toISOString();
      const dt = DateTime.fromISO(iso);
      return dt.toLocaleString(DateTime.DATE_MED);
    }
    return '';
  }, [props.value]);

  const animatedProps = useSpring({
    from: { xyz: [0, 0, 0] },
    to: async (next: any) => {
      await next({ xyz: open ? [0, 180, 0] : [0, 0, 0] });
    },
    config: { duration: 100 }
  }) as any;

  if (Platform.OS !== 'web') {
    return <></>;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [ref] = useClickOutside({
    close: () => setOpen(false)
  });

  return (
    <FOHView ref={ref}>
      {open ? (
        <>
          {props?.calendarPositionType === FOHCalendarPositionType.TOP ? (
            <CalendarContainer style={{ bottom: 58 }}>
              <Calendar
                view={'month'}
                onChange={(value: any) => {
                  props.setValue(value);
                  setOpen(false);
                }}
                value={props.value}
                {...props.calendarCompProps}
              />
            </CalendarContainer>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}

      <SelectContainer
        //@ts-ignore as we extended withError props w/ react-native TouchableOpacity element
        onPress={() => {
          if (!props.disabled) {
            setOpen(!open);
          }
        }}
        testID="calendar-picker-button"
        style={{
          backgroundColor: props.btnBackground || 'transparent',
          borderWidth: 2,
          borderColor: open ? FOHColors.LIGHT_BLUE : 'transparent',
          borderRadius: 8,
          width: '100%'
        }}
        disabled={props.disabled}
        error={props.error}
      >
        <FOHView
          style={{
            borderWidth: 1,
            borderColor: props.disabled
              ? FOHColors.GRAYSCALE_200
              : props.error
              ? FOHColors.RED_300
              : open
              ? FOHColors.PRIMARY_TEAL_100
              : FOHColors.GRAYSCALE_300,
            borderRadius: 4,
            flexDirection: 'row',
            alignItems: 'center',
            padding: 8,
            paddingHorizontal: 10,
            width: defaultTo(216, prop('width', props)),
            height: FOH_INPUT_HEIGHT,
            justifyContent: 'space-between',
            backgroundColor: props.disabled
              ? FOHColors.GRAYSCALE_100
              : 'transparent'
          }}
        >
          <FOHView style={{ flexDirection: 'row', alignItems: 'center' }}>
            <FOHCalendarIcon
              style={{
                tintColor: props.disabled
                  ? FOHColors.GRAYSCALE_400
                  : props.error
                  ? FOHColors.RED_300
                  : FOHColors.PRIMARY_TEAL_P1,
                top: -2,
                width: 20,
                height: 19
              }}
            />
            <FOHView>
              <FOHView
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  top: -2,
                  paddingLeft: 10
                }}
              >
                <FOHLabelSmall
                  style={{
                    color: FOHColors.GRAYSCALE_500,
                    fontSize: 12,
                    textTransform: 'uppercase'
                  }}
                >
                  {props.placeholder}
                </FOHLabelSmall>
              </FOHView>
              <FOHView
                style={{ flexDirection: 'row', alignItems: 'center', top: -2 }}
              >
                <FOHLabel
                  style={{
                    fontSize: 16,
                    lineHeight: 14,
                    paddingLeft: 10,
                    color: props.disabled
                      ? FOHColors.GRAYSCALE_400
                      : FOHColors.GRAYSCALE_900
                  }}
                >
                  {dateString || props?.defaultPlaceHolderText}
                </FOHLabel>
              </FOHView>
            </FOHView>
          </FOHView>
          <FOHView
            style={{
              left: 2,
              backgroundColor: FOHColors.WHITE
            }}
            pointerEvents="none"
          >
            <AnimatedArrowView
              style={{
                transform: animatedProps?.xyz?.to(
                  (_x: number, y: number, _z: number) => `rotate(${y}deg)`
                )
              }}
            >
              <FOHExpandIcon
                style={{
                  tintColor: props.disabled
                    ? FOHColors.GRAYSCALE_400
                    : props.error
                    ? FOHColors.RED_300
                    : open
                    ? FOHColors.PRIMARY_TEAL_P1
                    : FOHColors.GRAYSCALE_500
                }}
              />
            </AnimatedArrowView>
          </FOHView>
        </FOHView>
      </SelectContainer>

      {open ? (
        <>
          {!props?.calendarPositionType ||
          props?.calendarPositionType === FOHCalendarPositionType.BOTTOM ? (
            <CalendarContainer style={{ top: 58 }}>
              <Calendar
                view={'month'}
                onChange={(value: any) => {
                  props.setValue(value);
                  setOpen(false);
                }}
                value={props.value}
                {...props.calendarCompProps}
              />
            </CalendarContainer>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </FOHView>
  );
};

const SelectContainerStyle = styled(FOHTouchableOpacity)`
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: ${FOHColors.WHITE};
  border-radius: 4px;
`;

const CalendarContainer = styled(FOHView)`
  position: absolute;
  z-index: 99;
`;

const SelectContainer = compose(withError)(SelectContainerStyle);

const AnimatedArrowView = animated(FOHView);
