import React from 'react';

import styled from 'styled-components/native';
import { Image, Text } from 'react-native';
import { prop } from 'ramda';

import {
  Card,
  FOHScrollView,
  FOHView,
  FOHSmallDetailLabel,
  FOHColors,
  FOHSpace,
  FOHSpaceSmall,
  FOHSpaceSmallest,
  FOHFonts,
  FOHQuoteIcon,
  FOHClockIcon,
  FOHCompensationIcon,
  FOHCalendarIcon
} from '../../ingredients';
import { FileType } from '../../templates';

import { FOHProfileImage } from '../navigation/FOHProfileImage';

interface FOHCandidateOfferProps {
  image: string;
  positionLabel: string;
  position: string;
  locationLabel: string;
  locationImage: string;
  locationName: string;
  locationAddress: string;
  startDateLabel: string;
  startDate: string;
  startTimeLabel: string;
  startTime: string;
  compensationLabel: string;
  compensation: string;
  typeLabel: string;
  type: string;
  customNoteLabel?: string;
  customNote?: string;
  hiringManagerProfile?: string;
  hiringManagerLabel?: string;
  hiringManagerName?: string;

  OfferLetterCmp: any;
  fileType: FileType;
  offerLetter: string;

  children?: any;
  isMobile?: boolean;
}

export const FOHCandidateOffer = (props: FOHCandidateOfferProps) => (
  <>
    <FOHCandidateOfferCard mobile={props.isMobile}>
      <Container mobile={props.isMobile}>
        <TopBlock mobile={props.isMobile}>
          <ImageContainer mobile={props.isMobile}>
            <FOHImage
              mobile={props.isMobile}
              resizeMode="contain"
              source={{ uri: props.image }}
            />
          </ImageContainer>
          {prop('isMobile', props) ? <FOHSpace /> : <FOHSpaceSmall />}
          <PositionLocationBlock>
            <Field>
              <SmallGreyLabel>{props.positionLabel}</SmallGreyLabel>
              <FOHSpaceSmallest />
              <Label
                style={{ color: FOHColors.NARO_GREY }}
                testID={'FOHCandidateOffer-position-label'}
              >
                {props.position}
              </Label>
            </Field>
            <FOHSpace />
            <Field>
              <SmallGreyLabel>{props.locationLabel}</SmallGreyLabel>
              <FOHSpaceSmallest />
              <FOHView
                style={{
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  paddingTop: 6,
                  paddingBottom: 6
                }}
              >
                <Image
                  source={{ uri: props.locationImage }}
                  style={{ width: 40, height: 40, borderRadius: 3 }}
                />
                <FOHView style={{ paddingLeft: 6 }} />
                <FOHView
                  style={{ flex: 1 }}
                  testID={'FOHCandidateOffer-location-label'}
                >
                  <FOHName>{props.locationName}</FOHName>
                  <SmallGreyLabel>{props.locationAddress}</SmallGreyLabel>
                </FOHView>
              </FOHView>
            </Field>
          </PositionLocationBlock>
          {prop('isMobile', props) ? <FOHSpace /> : <FOHSpaceSmall />}
          <MainDetailBlock mobile={props.isMobile}>
            <FOHSpaceSmall />
            <Field
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <Cell>
                <FOHCalendarIcon
                  style={{ tintColor: FOHColors.PACIFIC_BLUE }}
                />
                <FOHView style={{ paddingLeft: 6 }} />
                <FOHView style={{ flex: 1 }}>
                  <SmallGreyLabel style={{ textTransform: 'none' }}>
                    {props.startDateLabel}
                  </SmallGreyLabel>
                  <FOHName testID={'FOHCandidateOffer-start-date'}>
                    {props.startDate}
                  </FOHName>
                </FOHView>
              </Cell>
              <Cell>
                <FOHClockIcon
                  style={{
                    width: 20,
                    height: 20,
                    tintColor: FOHColors.PACIFIC_BLUE
                  }}
                />
                <FOHView style={{ paddingLeft: 6 }} />
                <FOHView style={{ flex: 1 }}>
                  <SmallGreyLabel style={{ textTransform: 'none' }}>
                    {props.startTimeLabel}
                  </SmallGreyLabel>
                  <FOHName testID={'FOHCandidateOffer-start-time'}>
                    {props.startTime}
                  </FOHName>
                </FOHView>
              </Cell>
            </Field>
            <FOHSpace />
            <Field
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <Cell>
                <FOHCompensationIcon
                  style={{ tintColor: FOHColors.PACIFIC_BLUE }}
                />
                <FOHView style={{ paddingLeft: 6 }} />
                <FOHView style={{ flex: 1 }}>
                  <SmallGreyLabel style={{ textTransform: 'none' }}>
                    {props.compensationLabel}
                  </SmallGreyLabel>
                  <FOHName testID={'FOHCandidateOffer-compensation'}>
                    {props.compensation}
                  </FOHName>
                </FOHView>
              </Cell>
              <Cell>
                <FOHView style={{ flex: 1 }}>
                  <SmallGreyLabel style={{ textTransform: 'none' }}>
                    {props.typeLabel}
                  </SmallGreyLabel>
                  <FOHName testID={'FOHCandidateOffer-compensation-type'}>
                    {props.type}
                  </FOHName>
                </FOHView>
                <FOHSpace />
              </Cell>
            </Field>
            <FOHSpaceSmall />
          </MainDetailBlock>
        </TopBlock>
        {prop('customNote', props) && (
          <>
            <FOHSpace />
            <CustomNotesBlock>
              <FOHView style={{ flexDirection: 'row', alignItems: 'center' }}>
                <FOHQuoteIcon style={{ width: 16, height: 16 }} />
                <FOHSpaceSmall />
                <SmallGreyLabel
                  style={{
                    fontSize: 16,
                    lineHeight: 24,
                    color: FOHColors.PACIFIC_BLUE
                  }}
                >
                  {props.customNoteLabel}
                </SmallGreyLabel>
              </FOHView>
              <FOHSpace />
              <Label
                style={{
                  textAlign: 'left',
                  fontSize: 14,
                  lineHeight: 22,
                  fontWeight: 'normal',
                  color: FOHColors.NARO_GREY
                }}
                testID={'FOHCandidateOffer-custom-note'}
              >
                {props.customNote}
              </Label>
              <FOHSpace />
              <Cell>
                <FOHProfileImage
                  photo={props.hiringManagerProfile}
                  style={{ width: 40, height: 40, borderRadius: 22 }}
                />
                <FOHSpace />
                <FOHView style={{ flex: 1 }}>
                  <SmallGreyLabel style={{ textTransform: 'none' }}>
                    {props.hiringManagerLabel}
                  </SmallGreyLabel>
                  <FOHName>{props.hiringManagerName}</FOHName>
                </FOHView>
              </Cell>
            </CustomNotesBlock>
          </>
        )}
      </Container>
      <IframeContainer testID={'FOHCandidateOffer-offer-attachment'}>
        {prop('offerLetter', props) &&
        prop('fileType', props) === FileType.pdf ? (
          <props.OfferLetterCmp
            source={{ uri: props.offerLetter }}
            style={{ width: '100%', height: 430 }}
          />
        ) : (
          <FOHScrollView
            style={{
              width: '100%',
              maxHeight: 430
            }}
            contentContainerStyle={{
              padding: props.isMobile ? 12 : 42,
              backgroundColor: FOHColors.LIGHT_GRAY,
              borderRadius: 6
            }}
            automaticallyAdjustContentInsets
          >
            <Image
              source={{
                uri: props.offerLetter
              }}
              resizeMode="contain"
              style={{ width: '100%', height: 430, maxHeight: 768 }}
            />
          </FOHScrollView>
        )}
      </IframeContainer>
      {prop('children', props) && (
        <ButtonsContainer>
          <ButtonsWrapper>{props.children}</ButtonsWrapper>
        </ButtonsContainer>
      )}
    </FOHCandidateOfferCard>
  </>
);

const FOHCandidateOfferCard = styled(Card)<{ mobile?: boolean }>`
  max-width: 964px;
  min-width: ${(p: any) => (p.mobile ? '90%' : '58%')};
`;

const Container = styled(FOHView)<{ mobile?: boolean }>`
  align-items: center;
  justify-content: flex-start;
  padding: ${(p: any) => (p.mobile ? '12px' : '18px')};
  width: 100%;
`;

const TopBlock = styled(FOHView)<{ mobile?: boolean }>`
  align-items: center;
  justify-content: center;
  flex-direction: ${(p: any) => (p.mobile ? 'column' : 'row')};
  flex-wrap: wrap;
  width: 100%;
`;

const ImageContainer = styled(FOHView)<{ mobile?: boolean }>`
  justify-content: ${(p: any) => (p.mobile ? 'center' : 'flex-start')};
  width: ${(p: any) => (p.mobile ? '80px' : '132px')};
  height: ${(p: any) => (p.mobile ? '80px' : '124px')};
  align-self: ${(p: any) => (p.mobile ? 'center' : 'flex-start')};
  flex-direction: column;
`;

const FOHImage = styled(Image)<{ mobile?: boolean }>`
  width: ${(p: any) => (p.mobile ? '80px' : '132px')};
  height: ${(p: any) => (p.mobile ? '80px' : '124px')};
  borderwidth: 1;
  border-color: #eeeeee;
`;

const PositionLocationBlock = styled(FOHView)<{ mobile?: boolean }>`
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
  flexwrap: wrap;
  width: 100%;
  max-width: 318px;
`;

const Field = styled(FOHView)``;

const SmallGreyLabel = styled(FOHSmallDetailLabel)`
  text-transform: uppercase;
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${FOHColors.GREY};
`;

const Label = styled(Text)`
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: ${FOHColors.GREY};
`;

const FOHName = styled(FOHSmallDetailLabel)`
  line-height: 16px;
  font-weight: 600;
  color: ${FOHColors.NARO_GREY};
`;

const MainDetailBlock = styled(FOHView)<{ mobile?: boolean }>`
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
  flexwrap: wrap;
  width: 100%;
  border-radius: 16px;
  padding: ${(p: any) => (p.mobile ? '8px' : '12px')};
  max-width: ${(p: any) => (p.mobile ? '325px' : '300px')};
  background-color: ${FOHColors.LIGHT_GRAY};
`;

const Cell = styled(FOHView)`
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const CustomNotesBlock = styled(FOHView)<{ mobile?: boolean }>`
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  border-radius: 16px;
  padding: ${(p: any) => (p.mobile ? '8px' : '12px')};
  width: 100%;
  max-width: 767px;
  background-color: ${FOHColors.LIGHT_GRAY};
`;

const IframeContainer = styled(Card)`
  align-items: center;
  max-width: 100%;
  margin: 0px;
  padding: 0px;
`;

const ButtonsContainer = styled(FOHView)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  bottom: 0;
  max-width: 100%;
  height: 98px;
  background-color: ${FOHColors.WHITE};
  shadow-color: ${FOHColors.BLACK};
  shadow-opacity: 0.3;
  shadow-offset: { width: 0px, height: -4px };
  shadow-radius: 6px
`;

const ButtonsWrapper = styled(FOHView)`
  flex-direction: row;
  max-width: 570px;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
