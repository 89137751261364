import React from 'react';

import message from '../../../images/message_icon.svg';

import { FOHImage } from '../../ingredients';

export const FOHMessageIcon = (props: any) => (
  <FOHImage
    source={{ uri: message }}
    style={
      props && !!props.style
        ? { width: 20, height: 20, ...props.style }
        : { width: 20, height: 20 }
    }
  />
);
