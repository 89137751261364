import React, { useEffect } from 'react';

const OAUTH_STATE_KEY = 'react-use-oauth2-state-key';
const OAUTH_RESPONSE = 'react-use-oauth2-response';

const isSameState = receivedState => {
  const state = JSON.parse(
    window.opener.sessionStorage.getItem(OAUTH_STATE_KEY)
  );
  return state === receivedState;
};

const queryToObject = query => {
  const parameters = new URLSearchParams(query);
  return Object.fromEntries(parameters.entries());
};

const OAuth2Popup = props => {
  const {
    Component = (
      <div style={{ margin: '12px' }} data-testid="popup-loading">
        Loading...
      </div>
    )
  } = props;

  // On mount
  useEffect(() => {
    // TODO: handle canceled OAuth flow

    const payload = queryToObject(window.location.search.split('?')[1]);
    const state = payload && payload.state;
    const error = payload && payload.error;

    if (!window.opener) {
      throw new Error('No window opener');
    }

    if (error) {
      window.opener.postMessage({
        type: OAUTH_RESPONSE,
        error: decodeURI(error) || 'OAuth error: An error has occured.'
      });
    } else if (state && isSameState(state)) {
      console.log('Posting Message');
      window.opener.postMessage({
        type: OAUTH_RESPONSE,
        payload
      });
    } else {
      window.opener.postMessage({
        type: OAUTH_RESPONSE,
        error: 'OAuth error: State mismatch.'
      });
    }
  }, []);

  return Component;
};

export default OAuth2Popup;
