import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import {
  FOHAuthControlButtons,
  FOHColors,
  FOHFullLogo,
  FOHGoogleLogoIcon,
  FOHReactiveModal,
  FOHView
} from '../../../components';
import {
  getSessionStorageItem,
  getTermsUrl,
  removeSessionStorageItem,
  setAuthFlowControlRedirectURI,
  useIsMobile
} from '../../../utils';
import { GROUPS } from '../../../constants';

import { useGoogleSSOAuth } from '../../SignIn';
import { AUTH_DETAIL_STATE } from '../../SignIn/useGoogleSSOAuth';
import { storeRegisterAuth } from '../../Register/GroupSpecificSignUp';
import { storeLoginAuth } from '../../SignIn/LoginScreen';

import useAuthFlowSelectionContext from './useAuthFlowSelectionContext';

interface AuthFlowSelectionForSigninOrSignupModalProps {
  currentPath: string;
  setOpenRegister: (value: boolean) => void;
  setOpenSignIn: (value: boolean) => void;
}

const AuthFlowSelectionForSigninOrSignupModal = (
  props: AuthFlowSelectionForSigninOrSignupModalProps
) => {
  const { currentPath, setOpenRegister, setOpenSignIn } = props;
  const { t, i18n } = useTranslation('AuthFlowSelectionModal');
  const { isMobile } = useIsMobile();

  const authDetail = getSessionStorageItem(AUTH_DETAIL_STATE) || undefined;

  const {
    displayAuthControlButtonForSignup,
    handleDisplayAuthControlButtonForSignup,
    displayAuthControlButtons,
    handleDisplayAuthControlButtons,
    displayGroupChoiceButtons,
    handleDisplayGroupChoiceButtons,
    closeModalViews
  } = useAuthFlowSelectionContext();

  const [group, setGroup] = useState(GROUPS.candidates);

  const { initiateGoogleAuth } = useGoogleSSOAuth();

  return (
    <FOHReactiveModal
      open={displayAuthControlButtons}
      dialog={!isMobile}
      close={closeModalViews}
    >
      <FOHView
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          width: !isMobile ? 400 : '100%'
        }}
      >
        {
          //Signup flow w/ buttons such as "Continue with Google" and "Continue with Email".
          displayAuthControlButtonForSignup && displayAuthControlButtons ? (
            <FOHAuthControlButtons
              icon={() => <FOHFullLogo style={{ width: 167, height: 42 }} />}
              title={t('GoogleSSOFeature:signupLabel')}
              buttons={[
                {
                  label: t('GoogleSSOFeature:continueWithGoogleBtnLabel'),
                  onClick: () => {
                    localStorage.removeItem('auth');
                    // Remove authdetail for login if exist
                    if (authDetail && authDetail?.auth_type === 'login') {
                      removeSessionStorageItem(AUTH_DETAIL_STATE);
                    }
                    setAuthFlowControlRedirectURI(currentPath);
                    storeRegisterAuth(group);
                    initiateGoogleAuth();
                  },
                  icon: () => (
                    <FOHGoogleLogoIcon
                      style={{
                        height: 22,
                        width: 22,
                        tintColor: FOHColors.WHITE
                      }}
                    />
                  ),
                  opaque: true,
                  selected: true,
                  textColor: FOHColors.WHITE,
                  color: FOHColors.PRIMARY_TEAL_P1
                },
                {
                  label: t('GoogleSSOFeature:continueWithEmailBtnLabel'),
                  onClick: () => {
                    closeModalViews();
                    setAuthFlowControlRedirectURI(currentPath);
                    setOpenRegister(true);
                  }
                }
              ]}
              termsText={t('GoogleSSOFeature:termsText')}
              termsLinkText={t('GoogleSSOFeature:termsHighlightLinkText')}
              termsLinkToNavigate={i18n && getTermsUrl(i18n.language)}
            />
          ) : // Signup flow w/ buttons such as "I am an Employer", "I am a Job Seeker" and "Log in to an existing account"
          displayGroupChoiceButtons && displayAuthControlButtons ? (
            <FOHAuthControlButtons
              icon={() => <FOHFullLogo style={{ width: 167, height: 42 }} />}
              title={t('GoogleSSOFeature:signupLabel')}
              buttons={[
                {
                  label: t('GoogleSSOFeature:asEmployerBtnLabel'),
                  onClick: () => {
                    setGroup(GROUPS.employers);
                    handleDisplayGroupChoiceButtons(false);
                    handleDisplayAuthControlButtonForSignup(true);
                  }
                },
                {
                  label: t('GoogleSSOFeature:asCandidateBtnLabel'),
                  onClick: () => {
                    setGroup(GROUPS.candidates);
                    handleDisplayGroupChoiceButtons(false);
                    handleDisplayAuthControlButtonForSignup(true);
                  }
                },
                {
                  label: t('GoogleSSOFeature:loginWithAccountBtnLabel'),
                  onClick: () => {
                    handleDisplayGroupChoiceButtons(false);
                    handleDisplayAuthControlButtons(true);
                  },
                  color: FOHColors.BLACK,
                  textColor: FOHColors.WHITE,
                  opaque: true,
                  selected: true
                }
              ]}
            />
          ) : (
            // Sign in flow w/ buttons such as "Continue with Google" , "Continue with Email" and "Create a new account"
            <FOHAuthControlButtons
              icon={() => <FOHFullLogo style={{ width: 167, height: 42 }} />}
              title={t('GoogleSSOFeature:signInLabel')}
              buttons={[
                {
                  label: t('GoogleSSOFeature:continueWithGoogleBtnLabel'),
                  onClick: () => {
                    localStorage.removeItem('auth');
                    // Remove authDetail for register if exist
                    if (authDetail && authDetail?.auth_type === 'register') {
                      removeSessionStorageItem(AUTH_DETAIL_STATE);
                    }
                    setAuthFlowControlRedirectURI(currentPath);
                    storeLoginAuth();
                    initiateGoogleAuth();
                  },
                  icon: () => (
                    <FOHGoogleLogoIcon
                      style={{
                        height: 22,
                        width: 22,
                        tintColor: FOHColors.WHITE
                      }}
                    />
                  ),
                  opaque: true,
                  selected: true,
                  textColor: FOHColors.WHITE,
                  color: FOHColors.PRIMARY_TEAL_P1
                },
                {
                  label: t('GoogleSSOFeature:continueWithEmailBtnLabel'),
                  onClick: () => {
                    closeModalViews();
                    setOpenSignIn(true);
                  }
                },
                {
                  label: t('GoogleSSOFeature:createNewAccountLabel'),
                  onClick: () => handleDisplayGroupChoiceButtons(true),
                  color: FOHColors.BLACK,
                  textColor: FOHColors.WHITE,
                  opaque: true,
                  selected: true
                }
              ]}
            />
          )
        }
      </FOHView>
    </FOHReactiveModal>
  );
};

export default AuthFlowSelectionForSigninOrSignupModal;
