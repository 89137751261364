import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback
} from 'react';

import { compose, defaultTo, length, path, prop } from 'ramda';
import Loadable from '@loadable/component';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import {
  AmplitudeTrackingEvents,
  getAuthFlowControlRedirectURI,
  removeAuthFlowControlRedirectURI,
  useDisableBackgroundScroll,
  useTrackAmplitudeEvent,
  withIsMobile,
  handle
} from '../../utils';
import { fetchMore } from '../../utils/fetchMore';
import { FEED, ROLES } from '../../constants';
import {
  useEmployerCandidatesQuery,
  usePositionByIdQuery
} from '../../graphql/generated';
import {
  FOHButtonNavigation,
  FOHVideoStoryPlayer,
  FOHView,
  Screen
} from '../../components';
import { useNavigation } from '../../utils/navigation';

import Export7ShiftsModal from '../Integrations/Export7ShiftsModal';
import { PlanModals } from '../Billing/PlanModals';
import { SetPasswordModal } from '../ClaimAccount/SetPasswordModal';
import { ShareScheduleModals } from '../EmployerInterviewSchedule/ShareScheduleFormModal';
import { AddRejectReasonModal } from '../EmployerRejection/AddRejectReasonModal';
import { WelcomeModals } from '../Welcome/WelcomeModals';
import { OfferLetterModal } from '../Offers/OfferLetterModal';
import { SetInterviewModal } from '../EmployerInterviewSchedule/SetInterviewModal';
import { SendScheduleOrScheduleModal } from '../EmployerInterviewSchedule/SendScheduleOrSchedule';
import { ScreeningQuestionnaireModal } from '../EmployerScreening/EmployerQuestionaireModal';
import { VideoScreeningQuestionnaireModal } from '../EmployerScreening/EmployerVideoQuestionnaireModal';
import { withStripe } from '../Billing';
import { useChatWindow } from '../Chat/useChatWindow';
import { useDirectMessage } from '../Chat/useDirectMessage';
import { useSidebarNav } from '../Navigation/useSidebar';
import ScreeningChoiceModal from '../EmployerScreening/EmployerScreeningChoiceModal';
import { useGetMeData } from '../SignIn';
import { ManageLocationPositionModals } from '../EmployerDashbaord';

import { MultiPositionDetailModal } from './MultiPositionDetailModal';
import { NOTIFICATION_FILTER_TYPES } from './useNotificationFilters';
import { useCandidateFeedContext } from './useCandidateFeedContext';
import { useStages } from './useStages';
import { DemoVideoModal } from './DemoVideoModal';
import { CandidateInboxDetail } from './CandidateInboxDetail';
import NewInboxFeedBar from './NewInboxFeedbar';
import { useCandidateFeedFiltersContext } from './useCandidateFeedFiltersContext';
import {
  useCandidateFeedRefresh,
  useIgnoredStagesStore
} from './useCandidateFeedRefresh';

const BulkMessageModal = Loadable(() =>
  import('../EmployerMessageTemplate/BulkMessageModal')
);

const CandidateFeedModals = ({ children }) => <FOHView>{children}</FOHView>;

const CandidateInboxFeed = props => {
  const { navigateTo, goBack, location: navLocation } = useNavigation();

  const { modal } = useParams();
  // initial states
  const [search, setSearch] = useState('');
  const [video, setVideo] = useState('');
  const [isMounted, setIsMounted] = useState(false);
  const [pageTitle, setPageTitle] = useState('Candidate Feed - foh&boh');
  const [selectedTab, setSelectedTab] = useState('candidate-history');

  const storeRedirectUri = getAuthFlowControlRedirectURI();

  const { data: me } = useGetMeData();
  const isLoggedInUserHasInterviewer = useMemo(() => {
    const currentUserRole = defaultTo(
      null,
      path(['employerProfileUser', 'role'], me)
    );

    return currentUserRole === ROLES.INTERVIEWER;
  }, [me]);

  const isInvited = defaultTo(false, prop('unclaimed', me));

  const hasInterviewSchedule = useMemo(() => {
    return prop('hasSchedule', me);
  }, [me]);

  const { logEventMetrics } = useTrackAmplitudeEvent();

  const logStageViewedEvent = stageName => {
    const stageViewEventDetail = {
      event_type: AmplitudeTrackingEvents.STAGE_VIEWED.EVENT_TYPE,
      Stage: stageName
    };

    logEventMetrics(
      AmplitudeTrackingEvents.STAGE_VIEWED.EVENT_NAME,
      stageViewEventDetail
    );
  };

  const [isOpenInviteUserOrPasswordModal, setIsOpenInviteUserOrPasswordModal] =
    useState(!!isInvited || modal === 'password');

  const { hasSideNav } = useSidebarNav();

  // CHAT Window
  const { getChannelUrlFor } = useDirectMessage({
    ...props,
    sendbirdAccessToken: prop('sendbirdAccessToken', me)
  });
  const { setChannelUrlWithMessage } = useChatWindow();

  // Stages and filters
  const {
    candidateHandle,
    setCandidateHandle,
    employerCandidate,
    setEmployerCandidate,
    locationFilters,
    positionFilters,
    activeStageId,
    setActiveStageId,
    myLocations,
    setNotificationMessage,
    notificationStatusFilter,
    clearPositionFilters,
    sortBy,
    setSortBy
  } = useCandidateFeedContext();

  const addIgnoredStages = useIgnoredStagesStore(state => state.add);

  const {
    stages,
    stagesQuery,
    loadingStages,
    moveEmployerCandidates,
    notificationType,
    currentStage,
    interviewStage,
    offeredStage,
    hiredStage,
    filteredNotificationStatusValues
  } = useStages({
    activeStageId,
    polling: true,
    addIgnoredStages
  });

  const { refreshableStageTypes, removeRefreshableStageType } =
    useCandidateFeedRefresh({
      currentStages: stages,
      isLoadingStages: loadingStages
    });

  const singlePositionQuery = usePositionByIdQuery({
    skip: !prop(0, positionFilters) || length(positionFilters) > 1,
    variables: {
      id: prop(0, positionFilters)
    }
  });

  const { profileDetailFilters } = useCandidateFeedFiltersContext();

  // always pass up the same number of variables for each stage query to avoid more refetches
  // when we have to refetch the query on move
  const variables = {
    first: 20,
    stageId: activeStageId,
    positions: positionFilters.filter(position => position !== null),
    locations: locationFilters,
    search: search,
    notificationType:
      notificationStatusFilter && notificationStatusFilter !== 'ALL'
        ? notificationType
        : '',
    ...filteredNotificationStatusValues,
    profileDetailFilters:
      profileDetailFilters.length > 0 ? profileDetailFilters : [],
    sortBy: sortBy
  };

  const hasActiveStageId = activeStageId !== '';
  const candidateFeedQuery = useEmployerCandidatesQuery({
    skip: !hasActiveStageId,
    variables: activeStageId ? variables : {},
    fetchPolicy: 'cache-first'
  });

  const employerCandidates = defaultTo(
    [],
    path(['data', 'employerCandidates', 'edges'], candidateFeedQuery)
  ).map(node => ({
    ...prop('node', node)
  }));

  const blurStyle = isOpenInviteUserOrPasswordModal
    ? {
        filter: 'blur(4px)',
        position: 'absolute',
        width: '100vw',
        height: '100vh',
        zIndex: 999,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      }
    : {};
  useDisableBackgroundScroll([isOpenInviteUserOrPasswordModal]);

  const stageFilterButtons = stages.map(stage => {
    return {
      title: `${stage.name} (${prop('employerCandidateCount', stage)})`,
      route: stage.id,
      hasNotification: prop('hasNotification', stage) > 0,
      highlightUnselected: refreshableStageTypes.includes(stage.stageType),
      stageName: stage.name
    };
  });

  useEffect(() => {
    if (!activeStageId && stages.length > 0) {
      setActiveStageId(stages[0].id);
      setSearch('');
    }
  }, [stages]); // eslint-disable-line react-hooks/exhaustive-deps

  const vidRef = useRef(null);

  const selectedUnpaidLocations = defaultTo([], myLocations).filter(
    location =>
      locationFilters.includes(prop('id', location)) &&
      prop('paymentStatus', location) === 'SUBSCRIBED'
  );

  const togglePlaying = () => {
    vidRef && vidRef.current && !vidRef.current.paused
      ? vidRef.current.pause()
      : vidRef.current.play();
  };

  const VideoComponent = VideoCompProps => (
    <video
      ref={vidRef}
      webkit-playsinline="true" //eslint-disable-line
      playsInline
      autoPlay
      style={{ ...VideoCompProps.style }}
    >
      <track kind="captions" />
      <source src={video} type="video/mp4" />
    </video>
  );

  const setNextCandidate = () => {
    candidateFeedQuery.refetch();
    // dont set next candidate if we are in interview stage and all is selected and updating interview
    if (
      (notificationStatusFilter === 'ALL' &&
        NOTIFICATION_FILTER_TYPES.INTERVIEWS === notificationType) ||
      employerCandidates.length === 1
    ) {
      return;
    }
    // if an action is taken that moves the candidate, set the next candidate
    const nextCandidateIndex =
      employerCandidates.findIndex(
        empC => path(['candidateProfile', 'handle'], empC) === candidateHandle
      ) + 1;

    const nextCandidateHandle = defaultTo(
      undefined,
      path(
        [nextCandidateIndex, 'candidateProfile', 'handle'],
        employerCandidates
      )
    );

    const nextEmployerCandidate = defaultTo(
      undefined,
      prop(nextCandidateIndex, employerCandidates)
    );
    setEmployerCandidate(nextEmployerCandidate);
    setCandidateHandle(nextCandidateHandle);
    setSelectedTab('candidate-history');
  };

  const onDirectMessagePress = async (
    selectedEmployerCandidate,
    uuid,
    templateMessage = '',
    position = '',
    location = ''
  ) => {
    // default to just location of only position selected or first location if we cannot tell what position the user is for (multiple matches with multiple positions selected etc)
    const sendBirdChannel = await getChannelUrlFor({
      uuids: [uuid],
      location: location
        ? location
        : length(positionFilters) === 1
        ? path(['data', 'positionById', 'location', 'id'], singlePositionQuery)
        : prop(0, locationFilters),
      position: position
        ? position
        : length(positionFilters) === 1
        ? prop(0, positionFilters)
        : ''
    });
    setChannelUrlWithMessage(sendBirdChannel, templateMessage);
  };

  const endCursor = path(
    ['data', 'employerCandidates', 'pageInfo'],
    candidateFeedQuery
  );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetchMoreCandidates = useCallback(async () => {
    return await handle(
      fetchMore(candidateFeedQuery, variables, 'employerCandidates')
    ); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endCursor]);

  //Redirect to user where it's actually comes from Origin route
  useEffect(() => {
    if (storeRedirectUri) {
      removeAuthFlowControlRedirectURI();
      navigateTo(storeRedirectUri);
    }
  }, []);

  useEffect(() => {
    if (hasActiveStageId) {
      candidateFeedQuery.refetch();
    }
  }, [activeStageId]); //eslint-disable-line

  useEffect(() => {
    if (currentStage) {
      setPageTitle(`${currentStage.name} - Candidate Feed - foh&boh`);
    }
  }, [currentStage]);

  useEffect(() => {
    if (candidateHandle !== undefined) {
      setNotificationMessage('');
    }
  }, [candidateHandle]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsMounted(true);

    return () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    if (
      currentStage &&
      refreshableStageTypes.length > 0 &&
      refreshableStageTypes.includes(currentStage.stageType)
    ) {
      const employerCandidateListCount = path(
        ['data', 'employerCandidates', 'totalCount'],
        candidateFeedQuery
      );
      if (employerCandidateListCount === 0) {
        candidateFeedQuery.refetch();
        removeRefreshableStageType(currentStage.stageType);
      }
      const newNotifCount =
        currentStage.employerCandidateCount - employerCandidateListCount;
      newNotifCount > 0 &&
        setPageTitle(
          `${currentStage.name}(${newNotifCount}) - Candidate Feed - foh&boh`
        );
    }
  }, [currentStage, refreshableStageTypes]); //eslint-disable-line

  if (!isMounted) {
    return <></>;
  }

  return (
    <>
      {currentStage ? (
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
      ) : (
        <></>
      )}
      <Screen
        style={{
          paddingTop: hasSideNav ? 16 : 66,
          height: '100vh',
          // default to desktop on this page
          minWidth: 1280,
          overflowX: 'scroll',
          overflowY: 'hidden',
          alignItems: 'center',
          ...blurStyle
        }}
      >
        <FOHView
          style={{
            maxWidth: 2000,
            width: '100%'
          }}
        >
          <FOHView
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 2
            }}
          >
            <FOHView
              style={{
                width: '100%',
                zIndex: 1,
                paddingLeft: 16,
                paddingRight: 16
              }}
            >
              {/* paying by location for this view */}
              <NewInboxFeedBar
                {...props}
                employerCandidates={employerCandidates}
                ignoreAllStages={() =>
                  addIgnoredStages(stages.map(stage => stage.stageType))
                }
              />
              <FOHButtonNavigation
                routes={stageFilterButtons}
                selectedRoute={activeStageId}
                onPressRoute={(route, stageName) => {
                  const stageType = stages.find(
                    stage => stage.id === route
                  ).stageType;

                  setActiveStageId(route, false);
                  setSearch('');
                  removeRefreshableStageType(stageType);
                  logStageViewedEvent(stageName);
                  setSelectedTab('candidate-history');
                }}
                testID="candidate-stage-menu"
              ></FOHButtonNavigation>
            </FOHView>
            <FOHView
              style={{
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'center',
                width: '100%'
              }}
            >
              <CandidateInboxDetail
                {...props}
                setNextCandidate={setNextCandidate}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                fetchMore={fetchMoreCandidates}
                setActiveStageId={setActiveStageId}
                setCandidateHandle={setCandidateHandle}
                candidateHandle={candidateHandle}
                setEmployerCandidate={setEmployerCandidate}
                employerCandidate={employerCandidate}
                setVideo={setVideo}
                loadingCandidates={
                  (employerCandidates.length === 0 &&
                    candidateFeedQuery.loading) ||
                  loadingStages
                }
                employerCandidateCount={path(
                  ['data', 'employerCandidates', 'totalCount'],
                  candidateFeedQuery
                )}
                needsUpgrade={false}
                activeStageId={activeStageId}
                stages={stages}
                candidates={employerCandidates}
                candidateFeedQuery={candidateFeedQuery}
                onDirectMessagePress={onDirectMessagePress}
                search={search}
                setSearch={setSearch}
                refreshableStageTypes={refreshableStageTypes}
                removeRefreshableStageType={removeRefreshableStageType}
                refetchEmployerCandidates={candidateFeedQuery.refetch}
                addIgnoredStages={addIgnoredStages}
                setPageTitle={setPageTitle}
                setSortBy={setSortBy}
                hasInterviewSchedule={hasInterviewSchedule}
              />
            </FOHView>
          </FOHView>
          <FOHView
            style={{
              height: length(employerCandidates) === 0 ? '100vh' : '0px'
            }}
          />
        </FOHView>
      </Screen>
      <ManageLocationPositionModals from={FEED} />
      <PlanModals
        {...props}
        onClose={() => {
          clearPositionFilters();
        }}
        onCancel={() => {
          clearPositionFilters();
        }}
        backPath={FEED}
        backSearch={navLocation.search}
        locationId={path([0, 'id'], selectedUnpaidLocations)}
        isLoggedInUserHasInterviewer={isLoggedInUserHasInterviewer}
      />
      <AddRejectReasonModal {...props} />
      <FOHVideoStoryPlayer
        open={!!video}
        togglePlaying={togglePlaying}
        close={() => setVideo('')}
        profilePhoto={path(
          ['candidateProfile', 'user', 'profileImage'],
          employerCandidate
        )}
        video={video}
        VideoComponent={VideoComponent}
      />

      {isOpenInviteUserOrPasswordModal && (
        <SetPasswordModal
          open={isOpenInviteUserOrPasswordModal}
          close={() => setIsOpenInviteUserOrPasswordModal(false)}
        />
      )}

      <CandidateFeedModals>
        {modal === 'demo' && (
          <DemoVideoModal
            open={modal === 'demo'}
            close={() => {
              navigateTo({
                pathname: FEED,
                search: navLocation.search
              });
            }}
          />
        )}

        {modal === 'send-or-set' && (
          <SendScheduleOrScheduleModal
            {...props}
            close={() => {
              goBack();
            }}
            key={`${candidateHandle}-send-or-set`}
            open={modal === 'send-or-set'}
            handle={candidateHandle}
            employerCandidate={employerCandidate}
            hasInterviewSchedule={hasInterviewSchedule}
          />
        )}

        {modal === 'send-schedule' && (
          <ShareScheduleModals
            {...props}
            key={`${candidateHandle}-send`}
            open={modal === 'send-schedule'}
            handle={candidateHandle}
            employerCandidate={employerCandidate}
            setNextCandidate={setNextCandidate}
            interviewStage={interviewStage}
            stagesQuery={stagesQuery}
            moveEmployerCandidates={moveEmployerCandidates}
            pathname={FEED}
            selectedLocationFilters={locationFilters}
          />
        )}

        {modal === 'interview' && (
          <SetInterviewModal
            candidateHandle={candidateHandle}
            key={`${candidateHandle}-set`}
            open={modal === 'interview'}
            backPath={FEED}
            employerCandidate={employerCandidate}
            setNextCandidate={setNextCandidate}
            interviewStage={interviewStage}
            stagesQuery={stagesQuery}
            moveEmployerCandidates={moveEmployerCandidates}
            close={() => navigateTo(FEED)}
            selectedLocationFilters={locationFilters}
          />
        )}

        {(modal === 'send-offer' || modal === 'resend-offer') && (
          <OfferLetterModal
            {...props}
            key={`${candidateHandle}-sendoffer`}
            open={modal === 'send-offer' || modal === 'resend-offer'}
            candidateHandle={candidateHandle}
            employerCandidate={employerCandidate}
            onDirectMessagePress={onDirectMessagePress}
            candidateFeedQuery={candidateFeedQuery}
            setNotificationMessage={setNotificationMessage}
            setNextCandidate={setNextCandidate}
            offeredStage={offeredStage}
            stagesQuery={stagesQuery}
            moveEmployerCandidates={moveEmployerCandidates}
            close={() => navigateTo(FEED)}
            selectedLocationFilters={locationFilters}
          />
        )}

        {modal === 'send-to-7shifts' && (
          <Export7ShiftsModal
            {...props}
            key={`${candidateHandle}-sendto7shifts`}
            open={modal === 'send-to-7shifts'}
            employerCandidate={employerCandidate}
            candidateFeedQuery={candidateFeedQuery}
            close={() => navigateTo(FEED)}
          />
        )}

        {modal === 'welcome' && (
          <WelcomeModals
            {...props}
            key={`${candidateHandle}-welcome`}
            open={modal === 'welcome'}
            candidateHandle={candidateHandle}
            employerCandidate={employerCandidate}
            setNextCandidate={setNextCandidate}
            hiredStage={hiredStage}
            stagesQuery={stagesQuery}
            moveEmployerCandidates={moveEmployerCandidates}
            close={() => navigateTo(FEED)}
            selectedLocationFilters={locationFilters}
          />
        )}

        {modal === 'screening' && (
          <ScreeningChoiceModal
            {...props}
            close={() => {
              goBack();
            }}
            key={`${candidateHandle}-screening-choice`}
            open={modal === 'screening'}
            handle={candidateHandle}
            employerCandidate={employerCandidate}
          />
        )}

        {modal === 'screening-questions' && (
          <ScreeningQuestionnaireModal
            setEmployerCandidate={setEmployerCandidate}
            setCandidateHandle={setCandidateHandle}
            employerCandidate={employerCandidate}
            candidateHandle={candidateHandle}
            candidateFeedQuery={candidateFeedQuery}
            stages={stages}
            stagesQuery={stagesQuery}
            moveEmployerCandidates={moveEmployerCandidates}
            employerCandidateId={employerCandidate && employerCandidate.id}
            {...props}
            open={modal === 'screening-questions'}
            close={() => navigateTo(FEED)}
            selectedLocationFilters={locationFilters}
          />
        )}

        {modal === 'more-positions' && (
          <MultiPositionDetailModal
            {...props}
            employerCandidate={employerCandidate}
            candidateHandle={candidateHandle}
            open={modal === 'more-positions'}
            close={() => navigateTo(FEED)}
          />
        )}

        {modal === 'video-screening-questions' && (
          <VideoScreeningQuestionnaireModal
            setEmployerCandidate={setEmployerCandidate}
            setCandidateHandle={setCandidateHandle}
            employerCandidate={employerCandidate}
            candidateHandle={candidateHandle}
            candidateFeedQuery={candidateFeedQuery}
            stages={stages}
            stagesQuery={stagesQuery}
            moveEmployerCandidates={moveEmployerCandidates}
            employerCandidateId={employerCandidate && employerCandidate.id}
            {...props}
            open={modal === 'video-screening-questions'}
            close={() => navigateTo(FEED)}
            selectedLocationFilters={locationFilters}
          />
        )}

        {modal === 'bulk-message' && (
          <BulkMessageModal
            setCandidateHandle={setCandidateHandle}
            employerCandidate={employerCandidate}
            candidateHandle={candidateHandle}
            candidateFeedQuery={candidateFeedQuery}
            employerCandidateId={employerCandidate && employerCandidate.id}
            {...props}
            open={modal === 'bulk-message'}
            close={() => navigateTo(FEED)}
            selectedLocationFilters={locationFilters}
          />
        )}
      </CandidateFeedModals>
    </>
  );
};

export default compose(withIsMobile, withStripe)(CandidateInboxFeed);
