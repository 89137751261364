import React from 'react';

import styled from 'styled-components/native';
import { View } from 'react-native';
import { path, prop } from 'ramda';

import {
  FOHButton,
  FOHHeaderH3,
  FOHLabel,
  FOHSpaceSmall,
  FOHTextButton,
  FOHTextField,
  FullWidthCard,
  FOHMultiLineTextField
} from '../../ingredients';
import {
  FOHLabeledCheckbox,
  FOHDateSelect,
  FOHLocationOverview,
  FOHRestaurantLocation,
  FOHSelectItemProps,
  FOHDetailedSelectItemProps,
  FOHDropdownImageSearch
} from '../../recipes';

export interface FOHWorkHistoryFormProps
  extends FOHWorkHistoryFormFields,
    FOHWorkHistoryLabelProps,
    LocationSearchDropdown {
  setStartYear: (value: string) => void;
  setEndYear: (value: string) => void;
  setStartMonth: (value: string) => void;
  setEndMonth: (value: string) => void;
  setCurrent: (value: any) => void;
  setPosition: (value: string) => void;
  setResponsibilities?: (value: string) => void;
}

export interface FOHWorkHistoryLabelProps {
  startLabel: string;
  endLabel: string;
  monthPlaceholder: string;
  yearPlaceholder: string;
  months: Array<FOHSelectItemProps>;
  years: Array<FOHSelectItemProps>;
  positionPlaceholder: string;
  employerNamePlaceholder: string;
  currentLabel: string;
  suggestionLabel: string;
  clearSuggestionLabel: string;
  replaceLabel: string;
  responsibilitiesPlaceholder: string;
}

export interface FOHWorkHistoryFormFields {
  id?: string;
  position: string;
  current: boolean;
  endMonth: string;
  startMonth: string;
  startYear: string;
  endYear: string;
  responsibilities: string;
  error?: any;
}

interface LocationSearchDropdown {
  onChangeSearch: (val: string) => void;
  searchValue: string;
  searchPlaceholder: string;
  onKeyPressSearchBar?: (e: any) => void;
  locations: Array<FOHDetailedSelectItemProps>;
  selectedLocation?: FOHDetailedSelectItemProps;
  selectLocation: (item?: FOHDetailedSelectItemProps) => void;
  selectFreeTextLabel: string;
  loading?: boolean;
  clearSearch: () => void;
  closeLabel: string;
  searchDetailLabel: string;
  suggestedLocation?: FOHRestaurantLocation;
  clearSuggestedLocation: () => void;
}

export const FOHWorkHistoryForm = (props: FOHWorkHistoryFormProps) => (
  <>
    <Row>
      <FOHTextField
        placeholder={props.positionPlaceholder}
        value={props.position || ''}
        required={true}
        error={path(['position', '0'], props.error)}
        onChangeText={(value: string) => props.setPosition(value)}
      />
    </Row>

    <Row style={{ zIndex: 99 }}>
      <FOHDropdownImageSearch
        {...props}
        searchPlaceholder={props.employerNamePlaceholder}
        selectedItem={props.selectedLocation}
        selectItem={props.selectLocation}
        options={props.locations}
      />
    </Row>

    {!!props.suggestedLocation && (
      <Row
        style={{
          backgroundColor: '#fafafa',
          paddingLeft: 8,
          paddingRight: 8,
          paddingTop: 8,
          marginBottom: 12,
          paddingBottom: 0
        }}
      >
        <View style={{ width: '100%' }}>
          <FOHHeaderH3
            style={{
              textAlign: 'left',
              paddingTop: 4,
              paddingBottom: 4
            }}
          >
            {props.suggestionLabel}
          </FOHHeaderH3>
          <FullWidthCard style={{ padding: 12 }} shadow={true}>
            <FOHLocationOverview
              {...props.suggestedLocation}
              followerCountOnBottom={true}
            />
          </FullWidthCard>
          <FOHSpaceSmall />
          <Row
            style={{
              alignItems: 'center',
              justifyContent: 'flex-end',
              paddingRight: 16
            }}
          >
            <FOHTextButton
              onPress={props.clearSuggestedLocation}
              title={props.clearSuggestionLabel}
            />
            <FOHSpaceSmall />
            <FOHButton
              style={{ maxWidth: 150 }}
              title={props.replaceLabel}
              onPress={() => {
                props.suggestedLocation &&
                  props.selectLocation({
                    id: prop('id', props.suggestedLocation),
                    value: prop('id', props.suggestedLocation),
                    label: prop('name', props.suggestedLocation),
                    sublabel: prop('formattedAddress', props.suggestedLocation),
                    image: prop('image', props.suggestedLocation)
                  });
                props.clearSuggestedLocation();
              }}
            />
          </Row>
        </View>
      </Row>
    )}

    <Row>
      <View>
        <FOHLabel>{props.startLabel}</FOHLabel>
        <Row>
          <FOHDateSelect
            months={props.months}
            years={props.years}
            setMonth={(value: string) => props.setStartMonth(value)}
            month={props.startMonth}
            yearError={path(['start', '0'], props.error)}
            monthError={path(['start', '0'], props.error)}
            monthPlaceholder={props.monthPlaceholder}
            yearPlaceholder={props.yearPlaceholder}
            year={props.startYear}
            setYear={(value: string) => props.setStartYear(value)}
          />
        </Row>
      </View>
    </Row>

    {!props.current && (
      <Row>
        <View>
          <FOHLabel>{props.endLabel}</FOHLabel>
          <FOHDateSelect
            months={props.months}
            years={props.years}
            setMonth={(value: string) => props.setEndMonth(value)}
            month={props.endMonth}
            yearError={path(['start', '0'], props.error)}
            monthError={path(['start', '0'], props.error)}
            monthPlaceholder={props.monthPlaceholder}
            yearPlaceholder={props.yearPlaceholder}
            year={props.endYear}
            setYear={(value: string) => props.setEndYear(value)}
          />
        </View>
      </Row>
    )}
    <Row>
      <FOHLabeledCheckbox
        selected={props.current}
        checkboxLabel={props.currentLabel}
        setSelected={() => props.setCurrent(!props.current)}
      />
    </Row>
    {!!props.setResponsibilities && (
      <Row>
        <FOHMultiLineTextField
          onChangeText={props.setResponsibilities}
          value={props.responsibilities}
          placeholder={props.responsibilitiesPlaceholder}
          max={500}
        />
      </Row>
    )}
  </>
);

const Row = styled(View)`
  width: 327px;
  display: flex;
  flex-direction: row;
  padding-bottom: 12px;
`;
