import React, { useState, useEffect } from 'react';

import { compose, defaultTo, length, path, prop } from 'ramda';
import { withTranslation } from 'react-i18next';
import { Marker, InfoBox, MarkerClusterer } from '@react-google-maps/api';
import { useDebounce, useLocalStorage } from 'use-hooks';
import { useParams, useSearchParams } from 'react-router-dom';

import {
  CANDIDATE_GROUP,
  CANDIDATE_ONBOARDING_2,
  EMPLOYER_ONBOARDING_2,
  HIRING,
  RESTAURANT_JOBS
} from '../../constants';
import {
  FOHLabel,
  FOHLabelBold,
  FOHHighlightText,
  FOHMap,
  FOHLocationFeedScreen,
  FOHMapMarker,
  FOHMarkerCluster
} from '../../components';
import {
  withIsMobile,
  findById,
  formatFollowerCountLabel,
  softNavigate
} from '../../utils';
import { multiSelectItem } from '../../utils/fieldHelpers';
import { useSearchLocationsFeedQuery } from '../../graphql/generated';
import { useNavigation } from '../../utils/navigation';

import { RegisterModal } from '../Register';
import { SignInModal, useGetMeData } from '../SignIn';
import { WebMap, MapControls, MapCenter, MobileWebMap } from '../Map';
import {
  addSearchQuery,
  QPZIP,
  QPSEARCH,
  QPMAP,
  QDRESSCODE,
  QPOSITIONTYPES,
  QHEALTHINSURANCE,
  QRESTAURANTTYPES,
  getSearchQuery,
  removeSearchQuery
} from '../Search';
import { JobsModal } from '../JobApplications/OtherJobs';
import {
  AuthFlowSelectionForSigninOrSignupModal,
  useAuthFlowSelectionContext
} from '../GoogleSSOAuth/components';

import { useLocationMutation } from './useLocationMutation';
import LocationDetailsModal from './LocationDetailsModal';
import {
  LocationFilterSideBar,
  countActiveFilters
} from './LocationFilterSidebar';

const LocationFeedScreen = props => {
  // const { setBanner } = useBanner();

  const params = useParams();
  const { data: me, refetch: refetchMeQuery } = useGetMeData();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = props;

  const { navigateTo, location: navLocation } = useNavigation();

  const currentPath = path(['pathname'], navLocation);
  const { handleDisplayAuthControlButtons } = useAuthFlowSelectionContext();

  // modals and navigation
  const [openRegister, setOpenRegister] = useState(false);
  const [openSignIn, setOpenSignIn] = useState(false);
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [mapHidden, setMapHidden] = useState(false);
  const [mapOnly, setMapOnly] = useState(!!getSearchQuery(navLocation, QPMAP));

  // map state
  const [mapCenter, setMapCenter] = useState({
    latitude: 36.1627,
    longitude: 86.7816
  });
  const [openPins, setOpenPins] = useState([]);
  const [getMapCenter, setGetMapCenter] = useState(true);

  // User Actions
  const { optimisticToggleFavorite, optimisticToggleHidden } =
    useLocationMutation();

  // Search and Query state
  const [slug, setSlug] = useState();
  const [registerType, setRegisterType] = useState(CANDIDATE_GROUP);
  const [searchText, setSearchText] = useState(
    getSearchQuery(navLocation, QPSEARCH)
  );

  const [zipCode, setZipCode] = useState(
    getSearchQuery(navLocation, QPZIP) ||
      path(['candidateProfile', 'addressPostalCode'], me)
  );
  const debouncedSearch = useDebounce(searchText, 1000);
  const debouncedZip = useDebounce(zipCode, 1000);
  const debouncedCenter = useDebounce(mapCenter, 300);

  useEffect(() => {
    debouncedSearch
      ? addSearchQuery(
          searchParams,
          { key: QPSEARCH, value: debouncedSearch },
          setSearchParams
        )
      : removeSearchQuery(searchParams, QPSEARCH, setSearchParams);
  }, [debouncedSearch]); //eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    debouncedZip
      ? addSearchQuery(
          searchParams,
          { key: QPZIP, value: debouncedZip },
          setSearchParams
        )
      : removeSearchQuery(searchParams, QPZIP, setSearchParams);
  }, [debouncedZip]); //eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    mapOnly
      ? addSearchQuery(
          searchParams,
          { key: QPMAP, value: true },
          setSearchParams
        )
      : removeSearchQuery(searchParams, QPMAP, setSearchParams);
  }, [mapOnly]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setZipCode(
      getSearchQuery(navLocation, QPZIP) ||
        path(['candidateProfile', 'addressPostalCode'], me)
    );
  }, [me]); //eslint-disable-line react-hooks/exhaustive-deps

  // Map Support dialog
  const [supportOpen, setSupportOpen] = useLocalStorage(
    'map-support-dialog',
    true
  );

  // Filters
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({
    dressCode: getSearchQuery(navLocation, QDRESSCODE),
    restaurantTypes: getSearchQuery(navLocation, QRESTAURANTTYPES, true),
    healthInsurance: getSearchQuery(navLocation, QHEALTHINSURANCE),
    positionTypes: getSearchQuery(navLocation, QPOSITIONTYPES, true)
  });

  const transformedFilters = {
    ...appliedFilters,
    restaurantTypes: defaultTo([], prop('restaurantTypes', appliedFilters)).map(
      type => type.replace('_', '-')
    ),
    // only care if health care is true
    healthInsurance:
      prop('healthInsurance', appliedFilters) === true ? true : undefined,
    // only care if dress code is false
    dressCode:
      typeof prop('dressCode', appliedFilters) === 'boolean'
        ? prop('dressCode', appliedFilters)
        : undefined
  };

  const toggleFilterDrawerOpen = () => setFilterDrawerOpen(!filterDrawerOpen);
  // Search query
  const locationQuery = useSearchLocationsFeedQuery({
    variables: {
      ...transformedFilters,
      zipCode: length(debouncedZip) === 5 ? debouncedZip : '',
      search: debouncedSearch,
      first: 20,
      latitude: debouncedCenter.latitude
        ? parseFloat(debouncedCenter.latitude.toFixed(2))
        : null,
      longitude: debouncedCenter.longitude
        ? parseFloat(debouncedCenter.longitude.toFixed(2))
        : null
    }
  });

  // Transform data for display
  const locationsData = locationQuery.data;

  const locations = defaultTo(
    [],
    path(['data', 'locations', 'edges'], locationQuery)
  ).map(node => ({
    ...prop('node', node),
    followerCountLabel: formatFollowerCountLabel({
      followerCount: path(['node', 'followerCount'], node),
      t
    })
  }));

  const openLocations = locations
    .filter(loc => (length(openPins) > 0 ? openPins.includes(loc.id) : false))
    .sort((a, b) => openPins.indexOf(b.id) - openPins.indexOf(a.id));

  // popup window for desktop
  const navigateToLocation = id => {
    const location = findById(id, locations);
    const locationSlug = prop('slug', location);
    if (props.isMobile) {
      navigateTo({
        pathname: `${RESTAURANT_JOBS}/${locationSlug}`,
        search: path(['location', 'search'], props)
      });
    } else {
      setSlug(locationSlug);
      setOpenLocationModal(true);
      softNavigate(props, `${RESTAURANT_JOBS}`, locationSlug);
    }
  };

  const count =
    length(openLocations) > 0 ? length(openLocations) : length(locations);
  const totalCount =
    length(openLocations) > 0
      ? length(openLocations)
      : path(['locations', 'totalCount'], locationsData);

  const numberOfActiveFilters = countActiveFilters(appliedFilters);
  return (
    <>
      <FOHLocationFeedScreen
        svg={svgProps => <svg {...svgProps} />}
        path={pathProps => <path {...pathProps} />}
        filterTitle={`${t('filterTitle')}${
          numberOfActiveFilters > 0 ? ` (${numberOfActiveFilters})` : ''
        }`}
        loadMoreTitle={
          locationQuery.loading
            ? `${t('common:loading')}...`
            : t('loadMoreTitle')
        }
        hiringLabel={t('hiringButtonLabel')}
        hiringBtnHelpText={t('viewJobsButtonHelpText')}
        jobMatchLabel={t('jobMatchLabel')}
        searchPlaceholder={t('searchPlaceholder')}
        zipCodePlaceholder={t('zipCodePlaceholder')}
        applyFiltersTitle={t('applyFiltersTitle')}
        seeMoreLabel={t('seeMoreLabel')}
        hideLabel={t('hideLabel')}
        favoriteLabel={t('favoriteLabel')}
        tableHeader={t('tableHeader')}
        tableDescription={t('tableDescription')}
        restaurantCountLabel={
          locationQuery.loading ? '' : t('restaurantCountLabel')
        }
        supportCloseText={t('supportCloseText')}
        favoritedHelpText={t('favoritedHelpText')}
        hideMeHelpText={t('hideMeHelpText')}
        mapListButtonTitle={!mapOnly ? t('goToMap') : t('goToList')}
        loading={locationQuery.loading}
        noResultsHeader={t('noResultsHeader')}
        noResultsBody={t('noResultsBody')}
        noResultsButtonTitle={t('noResultsButtonTitle')}
        toggleMapHiddenLabel={t('toggleMapHiddenLabel')}
        noResultsButtonPress={() => {
          setZipCode('');
          setSearchText('');
        }}
        mapHidden={mapHidden}
        setMapHidden={setMapHidden}
        clearSelectionLabel={
          length(openPins) > 0
            ? props.isMobile
              ? t('clearSelectionLabelShort')
              : t('clearSelectionLabel')
            : undefined
        }
        clearSelection={
          length(openPins) > 0 ? () => setOpenPins([]) : undefined
        }
        supportTitleCmp={() => (
          <FOHHighlightText
            emphasizedWord={t('help')}
            labelCmp={FOHLabelBold}
            highlightColor="#F2C94C"
          >
            {t('needHelp')}
          </FOHHighlightText>
        )}
        supportBodyCmp={() => (
          <>
            <FOHLabel>
              <FOHHighlightText
                emphasizedWord={t('follow')}
                labelCmp={FOHLabel}
                highlightColor="#000000"
                boldLabelCmp={FOHLabelBold}
              >
                {t('mapTextFollow')}
              </FOHHighlightText>{' '}
              <FOHHighlightText
                emphasizedWord={t('hide')}
                labelCmp={FOHLabel}
                highlightColor="#000000"
                boldLabelCmp={FOHLabelBold}
              >
                {t('mapTextHide')}
              </FOHHighlightText>
            </FOHLabel>
          </>
        )}
        restaurantCount={
          locationQuery.loading
            ? `${t('common:loading')}...`
            : t('ofResults')
                .replace('{count}', count)
                .replace('{totalCount}', totalCount || 0)
        }
        seeMore={navigateToLocation}
        locations={length(openLocations) > 0 ? openLocations : locations}
        hasMoreLocations={
          !!path(['locations', 'pageInfo', 'hasNextPage'], locationsData) &&
          length(openPins) === 0
        }
        toggleFilterDrawerOpen={toggleFilterDrawerOpen}
        mapOnly={mapOnly && props.isMobile}
        listOnly={!mapOnly && props.isMobile}
        toggleMapList={() => setMapOnly(!mapOnly)}
        pressFavorite={id => {
          path(['candidateProfile', 'id'], me)
            ? optimisticToggleFavorite(findById(id, locations))
            : handleDisplayAuthControlButtons(true);
        }}
        pressHide={id => {
          path(['candidateProfile', 'id'], me)
            ? optimisticToggleHidden(findById(id, locations))
            : handleDisplayAuthControlButtons(true);
        }}
        openHiringModal={id => {
          const location = findById(id, locations);
          const locationSlug = prop('slug', location);
          navigateTo({
            pathname: `${RESTAURANT_JOBS}${HIRING}/${locationSlug}`,
            search: path(['location', 'search'], props)
          });
        }}
        supportOpen={supportOpen}
        closeSupport={() => setSupportOpen(false)}
        loadMore={() => {
          locationQuery.fetchMore({
            variables: {
              ...transformedFilters,
              after: locationQuery.data.locations.pageInfo.endCursor,
              zipCode: length(zipCode) === 5 ? zipCode : '',
              search: searchText,
              first: 10
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const newEdges = fetchMoreResult.locations.edges;
              const pageInfo = fetchMoreResult.locations.pageInfo;
              return newEdges.length
                ? {
                    locations: {
                      __typename: previousResult.locations.__typename,
                      edges: [...previousResult.locations.edges, ...newEdges],
                      pageInfo
                    }
                  }
                : previousResult;
            }
          });
        }}
        mobile={props.isMobile}
        onChangeSearch={text => {
          setSearchText(text);
        }}
        searchValue={searchText}
        zipCode={zipCode}
        setZipCode={val => {
          if (length(val) <= 5) {
            setZipCode(val);
          }
        }}
        dropdown={true}
        hasFiltersOn={numberOfActiveFilters > 0}
      >
        <FOHMap
          Cluster={MarkerClusterer}
          Map={props.isMobile ? MobileWebMap : WebMap}
          onZoomChanged={() => {
            setOpenPins([]);
          }}
          onDragEnd={() => {
            if (totalCount > count && length(openPins) === 0) {
              setGetMapCenter(String(new Date().getTime));
              setZipCode('');
            }
          }}
        >
          <MapControls
            key={`mapcontrols${path([0, 'id'], locations)}`}
            locations={locations}
            openLocations={openLocations}
            centerLat={path(['locations', 'centerLat'], locationsData)}
            centerLong={path(['locations', 'centerLng'], locationsData)}
          />
          <MapCenter
            key={`mapcontrols${getMapCenter}`}
            setMapCenter={setMapCenter}
            lastMapCenter={mapCenter}
            setGetMapCenter={setGetMapCenter}
            getMapCenter={getMapCenter}
          />
          <FOHMarkerCluster Cluster={MarkerClusterer}>
            {locations.map(marker => (
              <FOHMapMarker
                {...marker}
                key={marker.id}
                isActive={openPins.includes(marker.id)}
                setOpenPin={id => multiSelectItem(id, setOpenPins, openPins)}
                isOpen={prop(0, openPins) === marker.id}
                Callout={InfoBox}
                Marker={Marker}
                onImagePress={navigateToLocation}
                // animation={2}
                pressFavorite={() => {
                  path(['candidateProfile', 'id'], me)
                    ? optimisticToggleFavorite(marker)
                    : handleDisplayAuthControlButtons(true);
                }}
                pressHide={() => {
                  path(['candidateProfile', 'id'], me)
                    ? optimisticToggleHidden(marker)
                    : handleDisplayAuthControlButtons(true);
                }}
                onMarkerPress={() => {}}
              />
            ))}
          </FOHMarkerCluster>
        </FOHMap>
      </FOHLocationFeedScreen>

      {slug && (
        <LocationDetailsModal
          from="LocationFeedScreen"
          slug={slug}
          open={openLocationModal}
          setOpen={setOpenLocationModal}
          setRegisterType={setRegisterType}
          key={slug}
          closeUrlPath="/restaurant-jobs"
          openRegister={() => {
            handleDisplayAuthControlButtons(true);
            setOpenLocationModal(false);
          }}
        />
      )}
      <AuthFlowSelectionForSigninOrSignupModal
        currentPath={currentPath}
        setOpenRegister={setOpenRegister}
        setOpenSignIn={setOpenSignIn}
      />
      <SignInModal
        openSignIn={openSignIn}
        setOpenSignIn={val => setOpenSignIn(val)}
        onSignIn={async () => {
          setOpenSignIn(false);
          await refetchMeQuery();
          await locationQuery.refetch();
        }}
      />
      <RegisterModal
        open={openRegister}
        onboarding={false}
        key={registerType}
        signUpFlow={'location-feed'}
        close={() => setOpenRegister(false)}
        onRegister={async chosenRegisterType => {
          await refetchMeQuery();
          chosenRegisterType === CANDIDATE_GROUP
            ? navigateTo(CANDIDATE_ONBOARDING_2)
            : navigateTo(EMPLOYER_ONBOARDING_2);
        }}
        group={registerType}
      />
      <LocationFilterSideBar
        {...props}
        setAppliedFilters={setAppliedFilters}
        filterDrawerOpen={filterDrawerOpen}
        setFilterDrawerOpen={setFilterDrawerOpen}
      />
      <JobsModal
        {...props}
        open={prop('location', params)}
        slug={prop('location', params)}
        close={() => {
          navigateTo({
            pathname: RESTAURANT_JOBS,
            search: path(['location', 'search'], props)
          });
        }}
      />
    </>
  );
};

export default compose(
  withTranslation('LocationFeedFeature'),
  withIsMobile
)(LocationFeedScreen);
