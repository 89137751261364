import React from 'react';

import thumbDown from '../../../images/thumb_down.svg';

import { FOHImage } from '../../ingredients';

export const FOHThumbDownIcon = ({ style }: { style?: any }) => (
  <FOHImage
    source={{ uri: thumbDown }}
    style={{ width: 22, height: 20, ...style }}
  />
);
