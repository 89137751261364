import React from 'react';

import { prop } from 'ramda';
import styled from 'styled-components/native';
import {
  View,
  ScrollView,
  TouchableOpacity,
  Dimensions,
  Platform,
  Image
} from 'react-native';

import { FOHColors } from '../colors/FOHColors';

export const FOHView = styled(View)``;

export const FixedBottomContainer = styled(FOHView)`
  bottom: 0;
  position: fixed;
  background-color: ${FOHColors.WHITE};
  width: 100%;
  align-items: center;
  border: 1px solid ${FOHColors.GRAYSCALE_300};
  padding: 10px;
`;

export const FixedTopContainerUnderNavigation = styled(FOHView)`
  top: 50px;
  position: fixed;
  background-color: ${FOHColors.WHITE};
  width: 500px;
  align-items: center;
  max-width: 396px;
  border-bottom-width: 1px;
  border-bottom-color: ${FOHColors.GRAYSCALE_300};
  padding: 10px;
  z-index: 10;
`;

export const FixedTopContainer = styled(FOHView)`
  top: 0;
  position: fixed;
  background-color: ${FOHColors.WHITE};
  width: 100%;
  align-items: center;
  border: 1px solid ${FOHColors.GRAYSCALE_300};
  padding: 10px;
  z-index: 10;
`;

export const Screen = styled(FOHView)`
  height: 100%;
  width: 100%;
`;

export const Row = styled(FOHView)`
  width: 327px;
  display: flex;
  flex-direction: row;
  padding-bottom: 12px;
`;

export const FullWidthRow = styled(FOHView)`
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: row;
  padding-bottom: 12px;
`;

export const Card = styled(FOHView)`
  border-radius: 4px;
  margin: 6px;
  max-width: 400px;
  min-width: 48%;
  border: 1px solid ${FOHColors.GRAYSCALE_300};
  background-color: ${FOHColors.WHITE};
`;

export const FullWidthCard = styled(FOHView)<{
  noBorder?: boolean;
  selected?: boolean;
  selectedColor?: string;
  shadow?: boolean;
}>`
  border-radius: 4px;
  width: 100%;
  margin-bottom: 12px;
  border: ${(props?: any) =>
    prop('noBorder', props)
      ? `1px solid ${FOHColors.WHITE}`
      : prop('selected', props)
      ? props.selectedColor
        ? `${props.selectedColor}`
        : `${FOHColors.BLUE}`
      : `1px solid ${FOHColors.GRAYSCALE_300}`};
  background-color: ${FOHColors.WHITE};

  shadow-opacity: ${(props?: any) =>
    prop('selected', props) ? `0.6` : prop('shadow', props) ? `0.06` : `0`};
  shadow-radius: ${(props?: any) => (prop('selected', props) ? `4px` : `16px`)};
  shadow-color: ${(props?: any) =>
    prop('selected', props)
      ? props.selectedColor
        ? `${props.selectedColor}`
        : `${FOHColors.BLUE}`
      : `#000000`};
  shadow-offset: ${(props?: any) =>
    prop('selected', props)
      ? `{ width: 0px, height: 0px }`
      : `{ width: 4px, height: 0px }`};
`;

export const FlexCardTopContainer = styled(FOHView)`
  border-bottom-width: 1px;
  border-bottom-color: ${FOHColors.GRAYSCALE_300};
  background-color: ${FOHColors.WHITE};
  top: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

export const CardTopContainer = styled(FOHView)`
  border-bottom-width: 1px;
  min-height: 90px;
  border-bottom-color: ${FOHColors.GRAYSCALE_300};
  align-items: center;
  flex-direction: row;
  background-color: ${FOHColors.WHITE};
  top: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

export const CardScreen = styled(Screen)`
  background-color: #f5f5f5;
`;

export const FOHImage = styled(Image)``;
export const FOHScrollView = styled(ScrollView)``;
export const FOHTouchableOpacity = TouchableOpacity;

export const FOHDropZoneBox = styled(FOHView)`
  border: 1px dashed #dbdbdb;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 24px;
  flex-wrap: wrap;
  background-color: ${FOHColors.WHITE};
`;

interface FOHFixedHeightScrollViewProps {
  isMobile?: boolean;
  height?: number;
  heightToDecrease: number;
  testID?: string;
  children: React.ReactNode;
}

export const FOHFixedHeightScrollView: React.FC<
  FOHFixedHeightScrollViewProps
> = ({ isMobile, height, heightToDecrease, testID, children }) => {
  const calculatedHeight =
    isMobile && Platform.OS !== 'web'
      ? prop('height', Dimensions.get('window')) - (heightToDecrease || 0)
      : height
      ? height
      : '100%';

  const paddingBottom = isMobile ? heightToDecrease : 0;

  return (
    <ScrollView
      style={{
        width: '100%'
      }}
    >
      <FOHView
        style={{
          alignItems: 'center',
          height: calculatedHeight,
          paddingBottom: paddingBottom
        }}
        testID={testID}
      >
        {children}
      </FOHView>
    </ScrollView>
  );
};
