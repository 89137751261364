import React from 'react';

import { defaultTo, includes, path, prop } from 'ramda';
import { useTranslation } from 'react-i18next';

import {
  FOHLabel,
  FOHView,
  FOHSpaceSmall,
  FOHStatusType,
  FOHResumeIcon,
  FOHPrintableCandidateProfile
} from '../../components';
import { transformWorkHistoryForProfileCamel } from '../../api/transformers/candidates';
import { certs } from '../../constants';
import { snakeToCamel, toDate } from '../../utils';
import {
  useGetCandidateWithApplicantsQuery,
  useGetIsFollowingLocationsQuery,
  useGetMatchQuery
} from '../../graphql/generated';

import { usePaymentPlan } from '../Billing/usePaymentPlan';
import { usePositionTypes } from '../CandidateProfile/usePositionTypes';
import { useGetMeData } from '../SignIn';

import { useCandidateFeedContext } from './useCandidateFeedContext';

export const PrintableCandidateProfile = props => {
  const { canShowPayWall, candidateHandle, initialPosition } = props;

  const { t, i18n } = useTranslation('CandidateFeed');

  const { getStringForPositionType } = usePositionTypes();
  const candidateFeedContext = useCandidateFeedContext();

  const { locationFilters } = candidateFeedContext;

  const candidateQuery = useGetCandidateWithApplicantsQuery({
    variables: {
      first: 5,
      handle: candidateHandle
    }
  });

  const { data: me } = useGetMeData({});

  const getMatchQuery = useGetMatchQuery({
    skip: !candidateHandle || !prop('id', initialPosition),
    variables: {
      position: prop('id', initialPosition),
      handle: candidateHandle
    }
  });

  const isFollowingQuery = useGetIsFollowingLocationsQuery({
    variables: {
      handle: candidateHandle,
      locations: locationFilters
    }
  });

  const isRejected =
    prop(
      'stageType',
      props.stages.find(stage => stage.id === props.activeStageId)
    ) === 'REJECTED';

  const isFollowing = path(
    ['data', 'profile', 'isFollowingLocations'],
    isFollowingQuery
  );

  const isOwnProfile =
    candidateHandle === path(['candidateProfile', 'handle'], me);

  const isLoggedInAsEmployer = !!path(['employerProfile', 'id'], me);
  const isLoggedInAsCandidate = !!path(['candidateProfile', 'id'], me);

  const profile = path(['data', 'profile'], candidateQuery);

  const status = defaultTo('ACTIVELY_LOOKING', prop('status', profile));

  const { subscribed } = usePaymentPlan({
    locationFilter: locationFilters[0],
    currentPlanQ: true
  });

  return (
    <FOHView>
      <FOHPrintableCandidateProfile
        containerStyle={{
          zIndex: 95
        }}
        appliedPositionApplicationDate={
          props?.appliedApplicationDetails?.appliedOnPositionApplicationDate &&
          toDate(
            props?.appliedApplicationDetails?.appliedOnPositionApplicationDate,
            'MMMM dd, yyyy'
          )
        }
        appliedLocation={
          props?.appliedApplicationDetails?.appliedLocation?.displayName
        }
        appliedPosition={
          props?.appliedApplicationDetails?.appliedPosition?.positionName
        }
        rejectionReasons={props?.rejectionReasons || []}
        personalSummary={prop('personalSummary', profile)}
        name={`${defaultTo('', path(['user', 'firstName'], profile))} ${
          !canShowPayWall
            ? defaultTo('', path(['user', 'lastName'], profile))
            : ''
        }`}
        status={t(`common:candidateStatusType.${status}`)}
        statusType={
          status === 'APPLIED_TO_YOU' ? FOHStatusType.ACTIVELY_LOOKING : status
        }
        storyPhoto={path(['user', 'profileImage'], profile)}
        profileOverviewLabel={t('profileOverviewLabel')}
        statsLabel={t('ProfileFeature:statsLabel')}
        positionsLabel={t('ProfileFeature:positionsLabel')}
        positions={defaultTo([], prop('positions', profile))
          .map(pos => getStringForPositionType(pos))
          .join(', ')}
        skillsLabel={t('ProfileFeature:skillsLabel')}
        skills={defaultTo([], prop('additionalTraining', profile))
          .filter(cert => !includes(cert, certs))
          .map(cert => t(`common:additionalTraining.${snakeToCamel(cert)}`))}
        certificationsLabel={t('ProfileFeature:certificationsLabel')}
        certifications={defaultTo([], prop('additionalTraining', profile))
          .filter(cert => includes(cert, certs))
          .map(cert => t(`common:additionalTraining.${snakeToCamel(cert)}`))}
        askForBilling={false}
        hidden={
          !!path(['data', 'match', 'hiddenAt'], getMatchQuery) || isRejected
        }
        rejected={isRejected}
        isFollowing={isFollowing}
        myPositions={defaultTo([], prop('positions', profile)).map(position =>
          getStringForPositionType(position)
        )}
        workHistoryLabel={t('ProfileFeature:workHistoryLabel')}
        workHistory={transformWorkHistoryForProfileCamel({
          candidate: profile,
          t,
          language: i18n.language
        })}
        experienceLabel={t('ProfileFeature:experienceLabel')}
        yearsExp={
          prop('yearsExperience', profile)
            ? t(
                `common:yearsExperience.${prop(
                  'yearsExperience',
                  profile
                )}Years`
              )
            : ''
        }
        emptyWorkHistory={t('ProfileFeature:emptyWorkHistory')}
        backgroundColor="#ffffff"
        email={
          isOwnProfile || subscribed ? path(['user', 'email'], profile) : ''
        }
        phone={
          isOwnProfile || subscribed ? path(['user', 'phone'], profile) : ''
        }
        emailPlaceholder={t('ProfileFeature:email')}
        phonePlaceholder={t('ProfileFeature:phone')}
        contactInformationLabel={t('ProfileFeature:contactInformation')}
        contactHelpText={
          isOwnProfile
            ? t('ProfileFeature:contactHelpText')
            : t('ProfileFeature:contactHelpTextEmployer')
        }
        workPreferencesTableTitle={t(
          'ProfileFeature:workPreferencesTableTitle'
        )}
        employmentPreferencesLabel={t(
          'ProfileFeature:employmentPreferencesLabel'
        )}
        employmentPreferences={defaultTo(
          [],
          prop('employmentPreferences', profile)
        ).map(pref => t(`common:employmentPreferences.${snakeToCamel(pref)}`))}
        benefitsLabel={t('ProfileFeature:benefitsLabel')}
        benefitsValue={
          prop('isBenefitsRequired', profile) ? t('common:yes') : '-'
        }
        dressCodeLabel={t('ProfileFeature:dressCodeLabel')}
        dressCodeValue={
          prop('canHaveDressCode', profile)
            ? t('common:culturePreferences.flexible')
            : '-'
        }
        locationLabel={t('ProfileFeature:locationLabel')}
        locationValue={
          isLoggedInAsEmployer || isLoggedInAsCandidate
            ? prop('addressPostalCode', profile)
            : '-'
        }
        commuteTimeLabel={t('ProfileFeature:commuteTimeLabel')}
        commuteTimeValue={
          prop('commutePreference', profile)
            ? t(
                `common:commutePreferences.options.${prop(
                  'commutePreference',
                  profile
                )}`
              )
            : '-'
        }
      />
      {path(['resume'], profile) ? (
        <FOHView
          style={{
            height: '100%'
          }}
        >
          <FOHView
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              paddingHorizontal: 20,
              paddingTop: 20,
              paddingBottom: 10
            }}
          >
            <FOHResumeIcon
              style={{
                width: 20,
                height: 20
              }}
            />
            <FOHSpaceSmall />
            <FOHLabel
              style={{
                fontSize: 18,
                lineHeight: 28,
                fontWeight: '700'
              }}
            >
              {t('CandidateCardFeature:resumeLabel')}
            </FOHLabel>
          </FOHView>
          <FOHView
            style={{
              padding: 8,
              paddingLeft: 24,
              height: '100%'
            }}
          >
            {path(['resume'], profile).includes('.jpeg') ||
            path(['resume'], profile).includes('.jpg') ||
            path(['resume'], profile).includes('.png') ? (
              <img
                src={path(['resume'], profile)}
                alt={`${path(['user', 'lastName'], profile)}-resume`}
                style={{
                  width: '100%',
                  maxWidth: 800
                }}
              />
            ) : (
              <iframe
                title="resume-iframe"
                src={
                  path(['resume'], profile).includes('.docx') ||
                  path(['resume'], profile).includes('.doc') ||
                  path(['resume'], profile).includes('.odt')
                    ? `https://docs.google.com/gview?url=${path(
                        ['resume'],
                        profile
                      )}&embedded=true`
                    : path(['resume'], profile)
                }
                style={{
                  width: '100%',
                  height: '100%',
                  maxWidth: 1000
                }}
              />
            )}
          </FOHView>
        </FOHView>
      ) : (
        <></>
      )}
    </FOHView>
  );
};
