import React from 'react';

import styled from 'styled-components/native';

import {
  Row,
  FullWidthCard,
  FOHView,
  FOHSpaceSmall,
  FOHTextButton,
  FOHPhoneInput,
  FOHPasswordField,
  FOHLabelSmall,
  FOHHighlightLinkWithText,
  FOHTextField,
  FOHColors,
  FOHFonts
} from '../../ingredients';

import { FOHSelect, FOHSelectItem } from '../select/FOHSelect';
import { FOHTerms, FOHTermsProps } from '..';

import { FOHForm, onEnterKeyPresed } from './FOHForm';

export interface FOHRegisterFormProps extends Partial<FOHTermsProps> {
  signInInfoLabel?: string;
  signInLabel?: string;
  handleSignIn?: () => void;

  handleSubmit: () => void;
  // submitLabel: string;
  disabledSubmit?: boolean;
  firstName: string;
  firstNamePlaceholder: string;
  setFirstName: (value: string) => void;
  lastName: string;
  lastNamePlaceholder: string;
  setLastName: (value: string) => void;
  phoneNumber: string;
  phoneNumberPlaceholder: string;
  setPhoneNumber: (value: string) => void;
  email: string;
  emailPlaceholder: string;
  setEmail: (value: string) => void;

  password?: string;
  passwordPlaceholder?: string;
  showPassText?: string;
  hidePassText?: string;
  setPassword?: (value: string) => void;

  confirmPassword?: string;
  confirmPasswordPlaceholder?: string;
  setConfirmPassword?: (value: string) => void;

  // Candidate or employer
  registerOptions?: Array<FOHTUserypeOption>;
  registerType?: string;
  registerPlaceholder?: string;
  setRegisterType?: (value: string) => void;

  error?: any;
  hideRegisterType?: boolean;

  linkToNavigate?: string;
}

interface FOHTUserypeOption {
  label: string;
  value: string;
}

export const FOHRegisterForm = (props: FOHRegisterFormProps) => (
  <ContainerView>
    <WrapperView>
      <FOHForm
        handleSubmit={props.handleSubmit}
        disabledSubmit={props.disabledSubmit}
      >
        {props.signInInfoLabel && props.signInLabel && props.handleSignIn && (
          <>
            <FieldRow
              style={{
                padding: 3,
                margin: 0,
                backgroundColor: FOHColors.LIGHT_GREEN,
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <FOHLabelSmall
                style={{
                  fontSize: 12,
                  fontWeight: 'normal'
                }}
              >
                {props.signInInfoLabel}
              </FOHLabelSmall>
              <FOHTextButton
                style={{
                  textDecorationLine: 'underline',
                  fontSize: 12,
                  fontFamily: FOHFonts.REGULAR,
                  fontWeight: 'bold',
                  color: FOHColors.GREEN
                }}
                title={props.signInLabel}
                disabled={false}
                onPress={props.handleSignIn}
              />
            </FieldRow>
            <FOHSpaceSmall />
            <FOHSpaceSmall />
          </>
        )}
        <FieldRow>
          <FOHView style={{ flex: 1 }}>
            <FOHTextField
              placeholder={props.firstNamePlaceholder}
              value={props.firstName}
              onChangeText={(value: string) => props.setFirstName(value)}
              onKeyPress={(e: any) => {
                onEnterKeyPresed(e, props);
              }}
              error={
                props.error && props.error.firstName
                  ? props.error.firstName
                  : null
              }
            />
          </FOHView>
          <FOHView style={{ padding: 8 }} />
          <FOHView style={{ flex: 1 }}>
            <FOHTextField
              placeholder={props.lastNamePlaceholder}
              value={props.lastName}
              onChangeText={(value: string) => props.setLastName(value)}
              onKeyPress={(e: any) => {
                onEnterKeyPresed(e, props);
              }}
              error={
                props.error && props.error.lastName
                  ? props.error.lastName
                  : null
              }
            />
          </FOHView>
        </FieldRow>
        <FieldRow>
          <FOHView style={{ flex: 1 }}>
            <FOHPhoneInput
              placeholder={props.phoneNumberPlaceholder}
              value={props.phoneNumber}
              autoCompleteType={'tel'}
              onChangeText={(value: string) => props.setPhoneNumber(value)}
              onKeyPress={(e: any) => {
                onEnterKeyPresed(e, props);
              }}
              error={
                props.error && (props.error.phoneNumber || props.error.phone)
                  ? props.error.phoneNumber || props.error.phone
                  : null
              }
            />
          </FOHView>
          <FOHView style={{ padding: 8 }} />
          <FOHView style={{ flex: 1 }}></FOHView>
        </FieldRow>
        <FieldRow>
          <FOHTextField
            placeholder={props.emailPlaceholder}
            value={props.email}
            autoCompleteType={'email'}
            onChangeText={(value: string) => props.setEmail(value)}
            onKeyPress={(e: any) => {
              onEnterKeyPresed(e, props);
            }}
            error={props.error && props.error.email ? props.error.email : null}
          />
        </FieldRow>
        {props.setPassword || props.setConfirmPassword ? (
          <FieldRow>
            {props.setPassword ? (
              <FOHView style={{ flex: 1 }}>
                <FOHPasswordField
                  passwordPlaceholder={props.passwordPlaceholder}
                  password={props.password}
                  setPassword={(value: string) =>
                    props.setPassword && props.setPassword(value)
                  }
                  error={
                    props.error && props.error.password
                      ? props.error.password
                      : null
                  }
                  onKeyPress={(e: any) => {
                    onEnterKeyPresed(e, props);
                  }}
                  showPassText={props.showPassText}
                  hidePassText={props.hidePassText}
                />
              </FOHView>
            ) : (
              <></>
            )}
            <FOHView style={{ padding: 8 }} />
            {props.setConfirmPassword ? (
              <FOHView style={{ flex: 1 }}>
                <FOHTextField
                  secureTextEntry={true}
                  autoCompleteType={'password'}
                  placeholder={props.confirmPasswordPlaceholder}
                  value={props.confirmPassword}
                  onChangeText={(value: string) =>
                    props.setConfirmPassword && props.setConfirmPassword(value)
                  }
                  onKeyPress={(e: any) => {
                    onEnterKeyPresed(e, props);
                  }}
                  error={
                    props.error && props.error.confirmPassword
                      ? props.error.confirmPassword
                      : null
                  }
                />
              </FOHView>
            ) : (
              <></>
            )}
          </FieldRow>
        ) : (
          <></>
        )}
        {!props.hideRegisterType &&
        props.setRegisterType &&
        props.registerOptions ? (
          <FieldRow>
            <FOHSelect
              placeholder={props.registerPlaceholder || ''}
              selectedValue={props.registerType}
              onValueChange={(value: string) =>
                props.setRegisterType && props.setRegisterType(value)
              }
              error={
                props.error && props.error.registerType
                  ? props.error.registerType
                  : null
              }
            >
              {props.registerOptions.map(option => (
                <FOHSelectItem
                  key={option.value}
                  value={option.value}
                  label={option.label}
                />
              ))}
            </FOHSelect>
          </FieldRow>
        ) : (
          <></>
        )}
        {props.onPressTermsLink && props.setTerms ? (
          <FieldRow>
            <FOHTerms
              //@ts-ignore wrapped component w/ withErrors where prop not found
              onPressTermsLink={props.onPressTermsLink}
              terms={props.terms}
              setTerms={props.setTerms}
              termsLinkText={props.termsLinkText}
              termsText={props.termsText}
              error={
                props.error && props.error.terms ? props.error.terms : null
              }
            />
          </FieldRow>
        ) : (
          <></>
        )}
        {props.termsText && props.termsLinkText && props.linkToNavigate ? (
          <FieldRow
            style={{
              paddingBottom: 0,
              marginBottom: 0
            }}
          >
            <FOHHighlightLinkWithText
              labelStyle={{
                fontFamily: FOHFonts.MEDIUM,
                color: FOHColors.GRAYSCALE_700,
                marginLeft: 5
              }}
              labelCmp={FOHLabelSmall}
              linkStyle={{
                fontSize: 14,
                fontFamily: FOHFonts.MEDIUM
              }}
              linkWord={props.termsLinkText}
              highlightColor={FOHColors.PACIFIC_BLUE}
              linkHref={props.linkToNavigate}
              noIcon={true}
            >
              {props.termsText}
            </FOHHighlightLinkWithText>
          </FieldRow>
        ) : (
          <></>
        )}
      </FOHForm>
    </WrapperView>
  </ContainerView>
);

const WrapperView = styled(FullWidthCard)`
  background-color: #fcfcfc;
  padding: 26px;
  padding-botton: 13px;
  border-radius: 24px;
  max-width: 570px;
  min-width: 327px;
  widht: 570px;
`;

const FieldRow = styled(Row)`
  max-width: 525px;
  width: 100%;
`;

const ContainerView = styled(FOHView)`
  width: 100%;
  align-items: center;
`;
