import React from 'react';

import styled from 'styled-components/native';

import { FOHColors, FOHFonts, FOHLabel } from '../../ingredients';

interface FOHNewUpdateBadgeProps {
  label: string;
  style?: any;
}

export const FOHNewUpdateBadge = (props: FOHNewUpdateBadgeProps) => (
  <NewUpdateText style={{ ...props?.style }}>{props.label}</NewUpdateText>
);

const NewUpdateText = styled(FOHLabel)`
  font-size: 12px;
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: 700;
  color: ${FOHColors.PRIMARY_TEAL_300};
  border-radius: 100px;
  height: 24px;
  padding: 4px 12px;
  justify-content: center;
  background-color: ${FOHColors.PRIMARY_TEAL_10};
  text-align: center;
  line-height: 16px;
  word-break: break-word;
`;
