import React, { FunctionComponent, useState } from 'react';

import styled from 'styled-components/native';

import { CommonProps } from '../../typings';
import {
  FOHReactiveModal,
  FOHHeaderH2,
  FOHView,
  FOHDetailText,
  FOHMessageLocationIcon,
  SPACING_SCALE,
  FOHColors,
  FOHLocationPin,
  FOHMultiLineTextField,
  FOHInverseButton,
  FOHSendMessageIcon,
  FOHCircleLoader
} from '../../ingredients';
import {
  FOHSearchableSelectDropdown,
  FOHSearchableSelectDropdownProps
} from '../../recipes';
import { useIsMobile } from '../../spices';

import { CustomStyledElementProps } from './FOHLightBoxModal';

export const defaultTranslations = {
  dropdownLabel: 'Location',
  formDescription:
    'Fill up the form and our Team will get back to you within 48 hours!',
  labelNoLocation: 'No location selected',
  modalHeader: 'Get in touch',
  placeholder: 'Your message',
  submitLabel: 'Send message'
};

interface FOHMessageLocationModalProps {
  handleClose: () => void;
  handleContentChange: (value: string) => void;
  handleReceipientChange: (value: string) => void;
  handleSubmit: () => void;
  isLoading: boolean;
  isOpen: boolean;
  locationOptions: FOHSearchableSelectDropdownProps['options'];
  messageContent: string;
  messageReceipientId: string;
  translations?: Record<string, string>;
}

export const FOHMessageLocationModal: FunctionComponent<
  FOHMessageLocationModalProps
> = ({
  handleClose,
  handleContentChange,
  handleReceipientChange,
  handleSubmit,
  isLoading,
  isOpen,
  locationOptions,
  messageContent,
  messageReceipientId,
  translations = defaultTranslations
}) => {
  const isMobile = useIsMobile();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const computeLabel = (): string => {
    if (!messageReceipientId) return translations.labelNoLocation;

    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    const selectedOption = locationOptions?.find(
      ({ value }) => value === messageReceipientId
    )!;

    return `${selectedOption.label}, ${selectedOption.sublabel}`;
  };

  return (
    <FOHReactiveModal close={handleClose} dialog={!isMobile} open={isOpen}>
      <FOHView style={{ alignItems: 'center' }}>
        <FOHMessageLocationIcon
          style={{
            marginTop: isMobile ? SPACING_SCALE.regular : SPACING_SCALE.massive
          }}
        />
        <FOHHeaderH2>{translations.modalHeader}</FOHHeaderH2>

        {/* @ts-ignore to set unitless value */}
        <DetailText isMobile={isMobile} style={{ lineHeight: '1.5' }}>
          {translations.formDescription}
        </DetailText>

        <FormWrapper isMobile={isMobile}>
          <FOHSearchableSelectDropdown
            fieldLabel={translations.dropdownLabel}
            icon={FOHLocationPin}
            multiSelect={false}
            onValueChange={(value: number) =>
              handleReceipientChange(
                locationOptions.find(loc => loc.value === value.toString())!
                  .value
              )
            }
            open={isDropdownOpen}
            options={locationOptions}
            selectedLabel={computeLabel()}
            selectedValue={messageReceipientId}
            setOpen={setIsDropdownOpen}
            style={{
              backgroundColor: FOHColors.WHITE,
              width: '100%',
              zIndex: 1
            }}
          />
          <FOHMultiLineTextField
            onChangeText={(text: string) => handleContentChange(text)}
            placeholder={translations.placeholder}
            style={{ marginTop: SPACING_SCALE.larger }}
            value={messageContent}
          />

          <FOHView
            style={{
              marginTop: isMobile
                ? SPACING_SCALE.larger
                : SPACING_SCALE.massive * 2
            }}
          >
            {isLoading ? (
              <FOHCircleLoader />
            ) : (
              <FOHInverseButton
                color={FOHColors.BLACK}
                disabled={messageReceipientId === null || messageContent === ''}
                icon={() =>
                  FOHSendMessageIcon({ style: { tintColor: 'white' } })
                }
                onPress={handleSubmit}
                opaque={true}
                selected={true}
                style={{ marginLeft: 'auto', marginRight: 'auto' }}
                textColor={FOHColors.WHITE}
                title={translations.submitLabel}
              />
            )}
          </FOHView>
        </FormWrapper>
      </FOHView>
    </FOHReactiveModal>
  );
};

const FormWrapper = styled(FOHView)<CommonProps>`
  margin-bottom: ${({ isMobile }: CustomStyledElementProps) =>
    isMobile ? SPACING_SCALE.larger : 0}px;
  margin-top: ${SPACING_SCALE.large}px;
  width: 100%;
  padding: ${SPACING_SCALE.massive}px;
  border-radius: 24px;
  background-color: ${FOHColors.LIGHT_GRAY};
`;

const DetailText = styled(FOHDetailText)<CommonProps>`
  max-width: ${({ isMobile }: CustomStyledElementProps) =>
    isMobile ? '85%' : '66%'};
  margin: ${SPACING_SCALE.large}px auto;
  font-size: 18px;
`;
