import React, { useState, useEffect, useMemo } from 'react';

import styled from 'styled-components/native';
import { path, prop, length, defaultTo } from 'ramda';
import { createEditor, Node } from 'slate';
import { withReact } from 'slate-react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { useTimezoneSelect } from 'react-timezone-select';

import {
  generateTimeIntervals,
  handleMutation,
  setTimeOnDate,
  toDate
} from '../../utils';
import {
  getPlaceHolderByAction,
  getTemplatePlaceHolders,
  INTERVIEW_REQUEST_SEND_DATE_TIME
} from '../../constants/templates';
import {
  useEmployerMessageTemplatesQuery,
  useEmployerScheduleInterviewMutation,
  useGetCandidateQuery,
  GetCandidateDocument,
  useScheduleInterviewMutation,
  useGetInterviewDetailLazyQuery,
  useScheduleSlotsLazyQuery
} from '../../graphql/generated';
import {
  FEED,
  TEMPLATES,
  UPGRADE,
  INTERVIEW_LENGTH_OPTIONS
} from '../../constants';
import {
  FOHLabel,
  FOHColors,
  FOHSpace,
  FOHSpaceSmall,
  FOHView,
  FOHSelect,
  FOHSelectItem,
  FOHCalendarPicker,
  FOHInterviewMeetingType,
  FOHInterviewTypeFields,
  FOHInterviewAttendeesRole,
  FOHBorderlessSelect,
  FOHSearchableSelectDropdown,
  FOHLabeledCheckbox,
  FOHFonts
} from '../../components';
import { useNavigation } from '../../utils/navigation';

import { useSelectPositionForSelectedLocationState } from '../EmployerPositions/useSelectPositionForSelectedLocationState';
import { useCandidateFeedContext } from '../CandidateFeed/useCandidateFeedContext';
import ActionModal from '../CandidateFeed/ActionModal';
import {
  withCharacterLimit,
  withCustomDelete,
  stripBracesFromPlaceholder
} from '../EmployerMessageTemplate/utils';
import { FOHMessageInputSection } from '../EmployerMessageTemplate/FOHMessageInputSection';
import { useGetMeData } from '../SignIn';

import useInterviewAttendees from './useInterviewAttendees';

const MAX_COUNT = 500;

const editor = withCharacterLimit(MAX_COUNT)(
  withReact(withCustomDelete(createEditor()))
);

export const SetInterviewModal = props => {
  const { employerCandidate, candidateHandle, close } = props;

  const { navigateTo, goBack, location: navLocation } = useNavigation();

  const interviewID = navLocation?.state?.interviewId;

  const { t, i18n } = useTranslation('ScheduleInterviewFeature');

  const [currentDate] = useState(new Date());
  const [date, setDate] = useState(currentDate);
  const [timeFrom, setTimeFrom] = useState('');
  const [duration, setDuration] = useState('30');
  const [sending, setSending] = useState(false);
  const [interviewType, setInterviewType] = useState(
    FOHInterviewMeetingType.INPERSON
  );

  const [phone, setPhoneNumber] = useState('');
  const [remoteUrl, setRemoteUrl] = useState('');
  const [isCalendarOpen, setCalendarOpen] = useState(false);

  const [inputMessageContent, setInputMessageContent] = useState([
    {
      type: 'paragraph',
      children: [{ text: '' }]
    }
  ]);

  const [label, setLabel] = useState('');
  const [template, setTemplate] = useState();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [placeHolderError, setPlaceHolderError] = useState('');
  const [intervieweeAvailabilityError, setIntervieweeAvailabilityError] =
    useState('');
  const [interviewerTimezone, setInterviewerTimezone] = useState('');
  const [interviewerDropdownOpen, setInterviewerDropdownOpen] = useState(false);

  const timezones = {
    'America/New_York': 'Eastern Time',
    'America/Chicago': 'Central Time',
    'America/Boise': 'Mountain Time',
    'America/Phoenix': 'Arizona',
    'America/Los_Angeles': 'Pacific Time',
    'America/Juneau': 'Alaska',
    'Pacific/Honolulu': 'Hawaii',
    'Pacific/Midway': 'Midway Island, Samoa',
    'America/St_Johns': 'Newfoundland and Labrador'
  };

  const { options: timezonesOptions, parseTimezone } = useTimezoneSelect({
    labelStyle: 'original',
    displayValue: 'GMT',
    timezones: timezones
  });

  const [
    getInterviewDetails,
    { loading: loadingInterview, data: interviewData }
  ] = useGetInterviewDetailLazyQuery();

  const scheduledInterview = useMemo(
    () => defaultTo(null, prop('interview', interviewData)),
    [interviewData]
  );

  const setScheduledInterviewValues = () => {
    setInterviewType(scheduledInterview?.interviewType);
    setPhoneNumber(defaultTo('', scheduledInterview?.phoneNumber));
    setRemoteUrl(scheduledInterview?.meetingUrl);
    scheduledInterview?.scheduledFor !== null &&
      setDate(new Date(scheduledInterview?.scheduledFor));
    setDuration(getInterviewDuration(scheduledInterview?.duration));
    setSelectedInterviewer(
      scheduledInterview?.interviewAttendees
        .filter(
          attendee => attendee?.role === FOHInterviewAttendeesRole.INTERVIEWER
        )
        .map(interviewer => interviewer?.user?.id)?.[0]
    );
    setKeepMeUpdated(
      !!scheduledInterview?.interviewAttendees
        .filter(
          attendee => attendee?.role === FOHInterviewAttendeesRole.NOTIFY_ONLY
        )
        .map(interviewer => interviewer?.user?.id)?.[0]
    );
  };

  const getInterviewDuration = durationCode => {
    const durationMapping = {
      A_15: '15',
      A_30: '30',
      A_45: '45',
      A_60: '60'
    };
    return durationMapping[durationCode] || '30';
  };

  useEffect(() => {
    if (interviewID && !loadingInterview) {
      getInterviewDetails({
        variables: { id: interviewID },
        skip: !interviewID
      });
    }
  }, []);

  const messageTemplatesQuery = useEmployerMessageTemplatesQuery({
    variables: {
      search,
      category: 'INTERVIEW',
      action: 'interview-request-send-date-time'
    }
  });

  const filteredTemplates = useMemo(() => {
    const templates = defaultTo(
      [],
      path(['data', 'employerMessageTemplates', 'edges'], messageTemplatesQuery)
    );

    return templates
      .map(edge => ({
        id: edge?.node.id,
        name: edge?.node.name,
        messageContent: edge.node.messageContent,
        templateType: edge.node.templateType,
        isDefault: edge.node.isDefault,
        category: edge.node.category,
        action: edge.node.action
      }))
      .filter(_template =>
        _template?.name?.toLowerCase()?.includes(search.toLowerCase())
      );
  }, [messageTemplatesQuery, search]);

  useEffect(() => {
    if (length(filteredTemplates) > 0 && !template) {
      const defaultTemplate = filteredTemplates.find(
        _template => !!_template.isDefault
      );
      setTemplate(defaultTemplate);
      handleOnUseTemplate(defaultTemplate);
    }
  }, [filteredTemplates, template]);

  const candidateQuery = useGetCandidateQuery({
    skip: !candidateHandle,
    variables: { handle: candidateHandle }
  });

  const candidateId = path(['data', 'profile', 'user', 'id'], candidateQuery);

  const profile = path(['data', 'profile'], candidateQuery);
  const employerCandidatePreferredMatch = path(
    ['preferredMatch'],
    employerCandidate
  );

  const {
    selectedLocation,
    setSelectedPosition,
    selectedPosition,
    paidPositions
  } = useSelectPositionForSelectedLocationState({
    profile,
    positionId: scheduledInterview
      ? path(['position', 'id'], scheduledInterview)
      : path(['position', 'id'], employerCandidatePreferredMatch),
    locationId: scheduledInterview
      ? path(['location', 'id'], scheduledInterview)
      : path(['position', 'location', 'id'], employerCandidatePreferredMatch)
  });

  const { data: me } = useGetMeData();

  const loggedInUserId = useMemo(() => defaultTo('', prop('id', me)), [me]);

  const {
    interviewAttendees,
    interviewerSearch,
    setInterviewerSearch,
    selectedInterviewer,
    setSelectedInterviewer,
    keepMeUpdated,
    setKeepMeUpdated,
    manageInterviewAttendeesList,
    clearInterviewAttendeeStates,
    schedulerIsInterviewer,
    interviewersOptions,
    selectedInterviewerUser,
    createInterviewParticipants
  } = useInterviewAttendees({
    loggedInUserId,
    employerCandidate,
    selectedLocation,
    isActionModalOpen: props?.open
  });

  const interviewParticipants = useMemo(() => {
    if (selectedInterviewerUser && employerCandidate) {
      return createInterviewParticipants(
        selectedInterviewerUser,
        employerCandidate
      );
    }
    return [];
  }, [selectedInterviewerUser, employerCandidate]);

  const [getAvailableSlotsForInterviewer, { loading: loadingSlots, data }] =
    useScheduleSlotsLazyQuery({
      fetchPolicy: 'cache-and-network'
    });

  useEffect(() => {
    if (selectedInterviewerUser && currentDate && !loadingSlots) {
      getAvailableSlotsForInterviewer({
        variables: {
          startDate: currentDate.toISOString(),
          endDate: new Date(
            currentDate.getTime() + 60 * 24 * 60 * 60 * 1000
          )?.toISOString(),
          userId: selectedInterviewerUser?.user?.id
        }
      });
    }
  }, [selectedInterviewerUser, currentDate]);

  const availableDateSlots = path(['scheduleSlots'], data);
  const availableDates = availableDateSlots?.map(slot => slot.day);

  const times = useMemo(() => {
    if (!date) {
      return [];
    }

    if (schedulerIsInterviewer) {
      return generateTimeIntervals({
        numberOfIntervals: 28
      });
    }

    return (
      availableDateSlots
        ?.find(
          slot =>
            slot.day === toDate(new Date(date).toISOString(), 'yyyy-MM-dd')
        )
        ?.slots.map(slot => ({
          disabled: !slot.available,
          value: DateTime.fromISO(slot.start, {
            zone: interviewerTimezone
          }).toFormat('h:mm a'),
          label: DateTime.fromISO(slot.start, {
            zone: interviewerTimezone
          }).toFormat('h:mm a')
        })) || []
    );
  }, [availableDateSlots, date, interviewerTimezone]);

  useEffect(() => {
    if (times.length > 0) {
      if (scheduledInterview) {
        const interviewTime = DateTime.fromISO(
          scheduledInterview?.scheduledFor,
          {
            zone: interviewerTimezone || 'local'
          }
        ).toFormat('h:mm a');
        setTimeFrom(interviewTime);
      } else {
        setTimeFrom(times[0].value);
      }
    }
  }, [times, scheduledInterview]);

  useEffect(() => {
    if (
      availableDateSlots &&
      availableDateSlots.length > 0 &&
      !scheduledInterview
    ) {
      setDate(new Date(availableDateSlots[0].day));
    }
  }, [availableDateSlots]);

  useEffect(() => {
    if (
      selectedInterviewerUser !== null &&
      selectedInterviewerUser !== undefined
    ) {
      if (path(['user', 'timezone'], selectedInterviewerUser)) {
        const userTimezone = parseTimezone(
          path(['user', 'timezone'], selectedInterviewerUser)
        );
        setInterviewerTimezone(userTimezone?.value);
      }
    }
  }, [selectedInterviewerUser]);

  useEffect(() => {
    if (scheduledInterview) {
      setScheduledInterviewValues(scheduledInterview);
    }
  }, [scheduledInterview]);

  const employerProfileId = path(['employerProfile', 'id'], me);
  const createdBy = prop('id', me);

  const [employerScheduleInterviewMutation] =
    useEmployerScheduleInterviewMutation();
  const [interviewMutation] = useScheduleInterviewMutation();

  const { activeStageId, positionFilters, locationFilters } =
    useCandidateFeedContext();

  const handleOnUseTemplate = (selectedTemplate = null) => {
    const temp = selectedTemplate ?? template;

    const newValue = [
      {
        type: 'paragraph',
        children: [{ text: temp?.messageContent || '' }]
      }
    ];

    setInputMessageContent(newValue);
    editor.children = newValue;
    setLabel(temp?.name);
    setOpen(false);
  };

  const initiateMutation = async (
    _variables,
    mutationFunction,
    _refetchQueries
  ) => {
    const [response, errors] = await handleMutation(
      mutationFunction({
        variables: _variables,
        refetchQueries: _refetchQueries
      })
    );

    return {
      response,
      errors
    };
  };

  const handleSend = async () => {
    try {
      setPlaceHolderError('');
      setIntervieweeAvailabilityError('');
      setSending(true);

      const messageContent = inputMessageContent
        .map(n => Node.string(n))
        .join('\n');

      const placeholder = getPlaceHolderByAction(
        INTERVIEW_REQUEST_SEND_DATE_TIME
      );

      if (!messageContent.includes(placeholder)) {
        const errorPlaceholder = stripBracesFromPlaceholder(placeholder);

        setPlaceHolderError(
          t('MessageTemplatesFeature:requiredPlaceHolder', {
            placeholder: errorPlaceholder
          })
        );
        setSending(false);
        return;
      }

      const variables = {
        scheduledFor: setTimeOnDate(date, timeFrom, interviewerTimezone),
        duration,
        messageContent,
        interviewAttendees,
        interviewType:
          interviewType === FOHInterviewMeetingType.INPERSON
            ? 'inPerson'
            : interviewType === FOHInterviewMeetingType.PHONE
            ? 'phone'
            : 'remote',
        phoneNumber: phone,
        meetingUrl: remoteUrl
      };

      const refetchQueries = [
        {
          query: GetCandidateDocument,
          variables: {
            handle: candidateHandle
          }
        }
      ];

      const lastInterviewId = prop('id', scheduledInterview);
      const lastInterviewLocationId = path(
        ['location', 'id'],
        scheduledInterview
      );
      const lastInterviewPositionId = path(
        ['position', 'id'],
        scheduledInterview
      );
      const lastInterviewStatus = prop('status', scheduledInterview);

      let id =
        lastInterviewId &&
        lastInterviewLocationId === selectedLocation &&
        lastInterviewPositionId === selectedPosition &&
        lastInterviewStatus !== 'CANCELED'
          ? lastInterviewId
          : props?.edit && interviewID
          ? interviewID
          : null;

      if (id) {
        const { errors } = await initiateMutation(
          {
            ...variables,
            id: id
          },
          interviewMutation,
          refetchQueries
        );

        if (errors) {
          throw new Error(errors?.message);
        }
      } else {
        const { errors } = await initiateMutation(
          {
            ...variables,
            location: selectedLocation,
            position: selectedPosition,
            employerProfile: employerProfileId,
            createdBy: createdBy,
            candidate: candidateId
          },
          employerScheduleInterviewMutation,
          refetchQueries
        );

        if (errors) {
          throw new Error(errors.message);
        }
      }

      if (activeStageId !== props?.interviewStage?.id && !props?.edit) {
        await props.moveEmployerCandidates({
          employerCandidateIds: [prop('id', employerCandidate)],
          newStage: prop('id', props?.interviewStage),
          all: false,
          oldStage: activeStageId,
          positions: positionFilters,
          locations: locationFilters,
          search: ''
        });

        props.setNextCandidate &&
          props.setNextCandidate(prop('id', props?.interviewStage));

        props.stagesQuery.refetch();
        await candidateQuery.refetch();
      }

      clearInterviewAttendeeStates();

      setSending(false);
      close();
    } catch (error) {
      setSending(false);
      setIntervieweeAvailabilityError(
        error?.message || t('intervieweeAvailabilityError')
      );
    }
  };

  const resetForm = () => {
    setOpen(false);
    setTemplate(undefined);
    setLabel('');
    setPlaceHolderError('');
  };

  const handleKeepMeUpdated = () => {
    setKeepMeUpdated(!keepMeUpdated);
    manageInterviewAttendeesList(
      loggedInUserId,
      FOHInterviewAttendeesRole.NOTIFY_ONLY
    );
  };

  return (
    <ActionModal
      {...props}
      title={t(`setDateAndTimeLabel`, {
        interviewerAttendee: schedulerIsInterviewer
          ? t('common:yourLabel')
          : i18n?.language === 'en'
          ? `${selectedInterviewerUser?.user?.firstName}'s`
          : selectedInterviewerUser?.user?.firstName,
        candidateName: `${employerCandidate?.candidateProfile?.user?.firstName} ${employerCandidate?.candidateProfile?.user?.lastName}`
      })}
      participants={interviewParticipants}
      containerTestID="SetInterviewModal"
      defaultLocation={
        scheduledInterview
          ? path(['location', 'id'], scheduledInterview)
          : path(
              ['position', 'location', 'id'],
              employerCandidatePreferredMatch
            )
      }
      defaultPosition={
        scheduledInterview
          ? path(['position', 'id'], scheduledInterview)
          : path(['position', 'id'], employerCandidatePreferredMatch)
      }
      showLabel={t('common:show')}
      onPressUpgrade={() => navigateTo(`${FEED}${UPGRADE}`)}
      searchPlaceholder={t('common:search')}
      positionFieldLabel={t('common:position')}
      upgradeLabel={t('TopNavFeature:upgrade')}
      backButtonText={t('common:previous')}
      nextButtonText={
        sending ? t('InterviewFeature:scheduling') : t('common:send')
      }
      onBackPress={() => {
        if (props?.onPrevPress) {
          props.onPrevPress();
        } else {
          goBack();
        }
        resetForm();
      }}
      isNextDisabled={
        !timeFrom || sending || !selectedPosition || length(paidPositions) === 0
      }
      onNextPress={handleSend}
      setPosition={setSelectedPosition}
      positionContainerTestId="SetInterviewModal-position-filter"
      childrenContainerStyle={{
        zIndex: isCalendarOpen ? 4 : 1
      }}
      containerStyle={{
        alignItems: 'flex-start',
        width: '100%'
      }}
    >
      <FOHView style={{ zIndex: interviewerDropdownOpen ? 13 : 10 }}>
        <FOHView
          style={{
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <FOHView style={{ maxWidth: '57%', width: '100%' }}>
            <FieldLabel>
              {t('ScheduleInterviewFeature:interviewerSelectLabel')}
            </FieldLabel>
            <FOHSpaceSmall />
            <FOHSearchableSelectDropdown
              open={interviewerDropdownOpen}
              style={{ width: '100%', backgroundColor: FOHColors.WHITE }}
              maxHeight={33}
              setOpen={isOpen => {
                setInterviewerDropdownOpen(isOpen);
              }}
              selectedValue={selectedInterviewer}
              multiSelect={false}
              showLabel={t('common:show')}
              onSearchChange={setInterviewerSearch}
              searchValue={interviewerSearch}
              searchPlaceholder={t('common:search')}
              onValueChange={value => {
                setSelectedInterviewer(value);
                if (schedulerIsInterviewer) {
                  setInterviewType(FOHInterviewMeetingType.INPERSON);
                  setPhoneNumber('');
                  setRemoteUrl('');
                }
              }}
              options={interviewersOptions}
              testID={'SetInterviewModal-interviewer-filter'}
            />
          </FOHView>
          <FOHSpaceSmall />
          <FOHView style={{ maxWidth: '43%', width: '100%' }}>
            <FieldLabel>
              {t('EmployerInterviewScheduleFeature:interviewTypeLabel')}
            </FieldLabel>
            <FOHSpaceSmall />
            <FOHView style={{ width: '100%' }}>
              <FOHInterviewTypeFields
                whereQuestionLabel="" //no title
                dropdownLabel={t(
                  'EmployerInterviewScheduleFeature:interviewTypeLabel'
                )}
                inPersonLabel={t(
                  'EmployerInterviewScheduleFeature:inPersonLabel'
                )}
                remoteMeetingLabel={t(
                  'EmployerInterviewScheduleFeature:remoteMeetingLabel'
                )}
                isPhoneLabel={t(
                  'EmployerInterviewScheduleFeature:isPhoneLabel'
                )}
                remoteUrlPlaceholder={t(
                  'EmployerInterviewScheduleFeature:remoteUrlPlaceholder'
                )}
                phonePlaceholder={t(
                  'EmployerInterviewScheduleFeature:phonePlaceholder'
                )}
                interviewLengthPlaceholder={t(
                  'EmployerInterviewScheduleFeature:interviewLengthPlaceholder'
                )}
                where={interviewType}
                setWhere={setInterviewType}
                remoteUrl={remoteUrl}
                setRemoteUrl={setRemoteUrl}
                phone={phone}
                setPhone={setPhoneNumber}
                schedulerIsInterviewer={schedulerIsInterviewer}
              />
            </FOHView>
          </FOHView>
        </FOHView>
        {selectedInterviewer &&
        loggedInUserId &&
        selectedInterviewer !== loggedInUserId ? (
          <FOHView
            style={{
              zIndex: interviewerDropdownOpen ? -1 : 12
            }}
          >
            <FOHSpaceSmall />
            <FOHLabeledCheckbox
              disabled={!selectedInterviewer}
              checkboxLabel={t('ScheduleInterviewFeature:keepMeUpdated')}
              selected={defaultTo(true, keepMeUpdated)}
              setSelected={handleKeepMeUpdated}
            />
          </FOHView>
        ) : null}
      </FOHView>
      <FOHSpace />
      <FOHView
        style={{
          zIndex: 11
        }}
      >
        <FOHView
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            flex: 1
          }}
        >
          <FieldLabel>{t(`formInstructionLabel`)}</FieldLabel>
          <FOHView style={{ right: -8 }}>
            <FOHBorderlessSelect
              small={true}
              selectedValue={interviewerTimezone}
              onValueChange={setInterviewerTimezone}
              style={{
                fontSize: 14,
                lineHeight: 20,
                textAlign: 'center',
                height: 33
              }}
            >
              {timezonesOptions.map(option => (
                <FOHSelectItem
                  key={option.label}
                  value={option.value}
                  label={`${option.label.split(') ')[1]} (${option.abbrev})`}
                />
              ))}
            </FOHBorderlessSelect>
          </FOHView>
        </FOHView>
        <FOHView
          style={{
            display: 'flex',
            zIndex: 11
          }}
        >
          <FOHView
            style={{
              width: '100%'
            }}
          >
            <FOHView
              style={{
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'row',
                borderColor: intervieweeAvailabilityError ? 'red' : undefined,
                borderWidth: intervieweeAvailabilityError ? 1 : undefined
              }}
            >
              <FOHView style={{ width: '50%' }}>
                <FOHCalendarPicker
                  disabled={loadingSlots}
                  open={isCalendarOpen}
                  setOpen={setCalendarOpen}
                  value={date}
                  setValue={setDate}
                  placeholder="Date"
                  width="100%"
                  btnBackground={FOHColors.WHITE}
                  calendarCompProps={{
                    tileDisabled: ({ date: calendarDate }) => {
                      const parsedDate = new Date(currentDate.toISOString());
                      parsedDate.setHours(0, 0, 0, 0);

                      const calDate = new Date(calendarDate);
                      calDate.setHours(0, 0, 0, 0);

                      if (schedulerIsInterviewer) {
                        return calDate.getTime() < parsedDate.getTime();
                      }
                      return (
                        calDate.getTime() < parsedDate.getTime() ||
                        !availableDates?.includes(
                          toDate(calDate.toISOString(), 'yyyy-MM-dd')
                        )
                      );
                    }
                  }}
                />
              </FOHView>

              <FOHView style={{ width: '24%' }} testID="timeSelect">
                <FOHSelect
                  selectedValue={timeFrom}
                  onValueChange={value => setTimeFrom(value)}
                  placeholder={t('From')}
                  small={true}
                  neverEmpty={true}
                  disabled={loadingSlots}
                >
                  <FOHSelectItem
                    key={''}
                    label={'Select Time'}
                    value={''}
                    disabled={false}
                  />
                  {times.map(time => (
                    <FOHSelectItem
                      key={time.label}
                      label={time.label}
                      value={time.value}
                      enabled={!time.disabled}
                    />
                  ))}
                  {loadingSlots ? (
                    <FOHSelectItem
                      label={t('common:loading')}
                      value={t('common:loading')}
                    />
                  ) : null}
                </FOHSelect>
              </FOHView>
              <FOHView style={{ width: '24%' }}>
                <FOHSelect
                  selectedValue={duration}
                  onValueChange={value => setDuration(value)}
                  placeholder={t('Duration')}
                  small={true}
                  neverEmpty={true}
                  style={{ width: '100%' }}
                >
                  {INTERVIEW_LENGTH_OPTIONS.map(_duration => (
                    <FOHSelectItem
                      key={_duration.label}
                      label={_duration.label}
                      value={_duration.value}
                    />
                  ))}
                </FOHSelect>
              </FOHView>
            </FOHView>
            {intervieweeAvailabilityError ? (
              <FOHLabel
                style={{
                  fontSize: 14,
                  color: FOHColors.RED_300
                }}
              >
                {`*${intervieweeAvailabilityError}`}
              </FOHLabel>
            ) : (
              <></>
            )}
          </FOHView>
        </FOHView>
        <FOHSpace />
        <FOHMessageInputSection
          testID={'message-templates-section'}
          t={t}
          open={open}
          setOpen={setOpen}
          label={label}
          editor={editor}
          template={template}
          setTemplate={setTemplate}
          onEditTemplate={() => {
            resetForm();
            navigateTo(TEMPLATES);
          }}
          search={search}
          setSearch={setSearch}
          filteredTemplates={filteredTemplates}
          resetForm={resetForm}
          handleOnUseTemplate={handleOnUseTemplate}
          placeholder={getTemplatePlaceHolders(
            t,
            INTERVIEW_REQUEST_SEND_DATE_TIME
          )}
          placeHolderError={placeHolderError}
          inputMessageContent={inputMessageContent}
          setInputMessageContent={setInputMessageContent}
        />
      </FOHView>
    </ActionModal>
  );
};

const FieldLabel = styled(FOHLabel)`
  font-family: ${FOHFonts.MEDIUM};
  line-height: 16px;
  text-transform: uppercase;
  color: ${FOHColors.PRIMARY_TEAL_P1};
`;
