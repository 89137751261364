import React, { useState, useEffect, useCallback } from 'react';

import { Navigate } from 'react-router-dom';
import { compose, prop, path } from 'ramda';
import { withTranslation } from 'react-i18next';

import {
  FOHCircleLoader,
  FOHAuthControlButtons,
  Screen,
  FOHColors,
  FOHTouchableOpacity,
  FOHView,
  FOHFullLogo,
  FOHGoogleLogoIcon,
  FOHLeftArrow,
  FOHLoginScreen
} from '../../components';
import { login } from '../../api/auth';
import { withUser, withUpdateUser } from '../../api/hocs';
import { loginScreenErrorTransform } from '../../api/transformers/login';
import { getTokenFromLocalStorage } from '../../apolloClient';
import { useGetMeQuery } from '../../graphql/generated';
import {
  handle,
  storeAuth,
  getAuth,
  removeAuth,
  withIsMobile,
  getSessionStorageItem,
  removeSessionStorageItem,
  setSessionStorageItem
} from '../../utils';
import {
  CANDIDATE_PROFILE,
  FEED,
  LOGIN_URL,
  PASSWORD_RESET_PATH,
  REGISTER_URL
} from '../../constants/urls.js';
import { useNavigation } from '../../utils/navigation';

import useGoogleSSOAuth, { AUTH_DETAIL_STATE } from './useGoogleSSOAuth';

export const storeLoginAuth = () =>
  setSessionStorageItem(
    AUTH_DETAIL_STATE,
    {
      auth_type: 'login'
    },
    true
  );

const LoginScreen = props => {
  const { t } = props;

  const { navigateTo, location: navLocation } = useNavigation();
  const authDetail = getSessionStorageItem(AUTH_DETAIL_STATE) || undefined;

  const meQuery = useGetMeQuery({
    context: {
      header: getTokenFromLocalStorage()
    }
  });

  const { initiateGoogleAuth } = useGoogleSSOAuth();

  const loading = meQuery.loading;
  const [email, setEmail] = useState('');
  const [redirecting, setRedirecting] = useState(false);
  const [isFormEnabled, setIsFormEnabled] = useState(false);

  const [password, setPassword] = useState('');
  const [error, setError] = useState();
  const [isMounted, setIsMounted] = useState(false);
  const { from } = navLocation.state || '/';

  const userId = path(['data', 'me', 'rowId'], meQuery);
  const user = path(['data', 'me'], meQuery);
  const candidateProfile = path(['data', 'me', 'candidateProfile'], meQuery);

  const identifyUser = useCallback(async () => {
    if (!userId && !loading) {
      // Force to login again
      removeAuth();
    }
  }, [userId, loading]);

  useEffect(() => {
    if (!redirecting && userId) {
      identifyUser();
      setRedirecting(true);
    }
  }, [redirecting, identifyUser, userId]);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    if (authDetail && authDetail?.auth_type === 'register') {
      removeSessionStorageItem(AUTH_DETAIL_STATE);
      storeLoginAuth();
    } else {
      storeLoginAuth();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isMounted) {
    return <></>;
  }

  const handleOnSubmit = async () => {
    const loginData = { email, password };
    const [auth, respError] = await handle(login(loginData));
    if (respError) {
      const err = loginScreenErrorTransform(respError);
      return setError(err);
    } else {
      await storeAuth(auth);
      await meQuery.refetch();
      return user;
    }
  };

  if (userId !== null && !!redirecting) {
    const auth = getAuth();

    return (
      <Navigate
        to={
          from
            ? from
            : auth && Object.keys(auth).length > 0
            ? candidateProfile
              ? `${CANDIDATE_PROFILE}/${prop('handle', candidateProfile)}`
              : FEED
            : LOGIN_URL
        }
      />
    );
  }

  return (
    <>
      {!!getAuth() && loading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: 100,
            justifyContent: 'center'
          }}
        >
          <FOHCircleLoader />
        </div>
      ) : (
        <>
          {!isFormEnabled ? (
            <Screen
              style={{
                justifyContent: 'flex-start',
                alignItems: 'center',
                paddingTop: props.isMobile ? 60 : 120
              }}
            >
              <FOHAuthControlButtons
                icon={() => <FOHFullLogo style={{ width: 167, height: 42 }} />}
                title={t('GoogleSSOFeature:signInLabel')}
                buttons={[
                  {
                    label: t('GoogleSSOFeature:continueWithGoogleBtnLabel'),
                    onClick: () => initiateGoogleAuth(),
                    icon: () => (
                      <FOHGoogleLogoIcon
                        style={{
                          height: 22,
                          width: 22,
                          tintColor: FOHColors.WHITE
                        }}
                      />
                    ),
                    opaque: true,
                    selected: true,
                    textColor: FOHColors.WHITE,
                    color: FOHColors.PRIMARY_TEAL_P1
                  },
                  {
                    label: t('GoogleSSOFeature:continueWithEmailBtnLabel'),
                    onClick: () => setIsFormEnabled(true)
                  },
                  {
                    label: t('GoogleSSOFeature:createNewAccountLabel'),
                    onClick: () => navigateTo(REGISTER_URL),
                    color: FOHColors.BLACK,
                    textColor: FOHColors.WHITE,
                    opaque: true,
                    selected: true
                  }
                ]}
              />
            </Screen>
          ) : (
            <FOHView
              style={{
                paddingTop: props.isMobile ? 0 : 60
              }}
            >
              <FOHView
                style={{ position: 'absolute', top: 48, left: 48, zIndex: 1 }}
              >
                <FOHTouchableOpacity onPress={() => setIsFormEnabled(false)}>
                  <FOHLeftArrow style={{ width: 16, height: 12 }} />
                </FOHTouchableOpacity>
              </FOHView>
              <FOHLoginScreen
                title={t('loginTitle')}
                submitLabel={t('nextLabel')}
                email={email}
                emailPlaceholder={t('email')}
                setEmail={setEmail}
                password={password}
                noAccountLabel={t('noAccountLabel')}
                passwordPlaceholder={t('password')}
                setPassword={setPassword}
                error={error}
                forgotPasswordLabel={t('forgotPassword')}
                largeHeader={!props.isMobile}
                handleSubmit={handleOnSubmit}
                disabledSubmit={!email || !password}
                handleForgotPassword={() => navigateTo(PASSWORD_RESET_PATH)}
                hidePassText={t('common:hidePassText')}
                showPassText={t('common:showPassText')}
              />
            </FOHView>
          )}
        </>
      )}
    </>
  );
};

export default compose(
  withTranslation('LoginFeature'),
  withUpdateUser,
  withUser,
  withIsMobile
)(LoginScreen);
