import React, { FunctionComponent } from 'react';

import {
  FOHNextIcon,
  FOHPrevIcon,
  FOHTouchableOpacity,
  FOHWhitePlayIcon
} from '../../ingredients';
import { useIsMobile } from '../../spices';
import { MainImageMedia } from '../../templates/CareerPage/parts';
import { useDotPagination } from '../../spices/useDotPagination';

import {
  MainImageWrapper,
  Circle,
  DefaultImage,
  Dot,
  DotPaginationWrapper,
  NavigationWrapper
} from './styled/FOHMainImage.styled';

export interface PaginationLogic {
  currentPage: number;
  handleNextPage: () => void;
  handlePreviousPage: () => void;
  numberOfPages: number;
}
export interface CareerPageMainImageProps {
  isVideo?: boolean;
  navigation?: boolean;
  paginationLogic?: PaginationLogic;
  toggleLightBox: () => void;
  src?: string;
}

export const FOHCareerPageMainImage: FunctionComponent<
  CareerPageMainImageProps
> = ({
  isVideo,
  navigation,
  paginationLogic: {
    currentPage,
    handleNextPage,
    handlePreviousPage,
    numberOfPages = 0
  } = {},
  toggleLightBox,
  src,
  ...props
}) => {
  const isMobile = useIsMobile();
  const hasMultipleImages = numberOfPages > 1;

  const range = useDotPagination({
    currentPage: currentPage,
    siblingCount: 1,
    totalCount: numberOfPages
  });

  return (
    <MainImageWrapper isMobile={isMobile} {...props}>
      <FOHTouchableOpacity
        disabled={numberOfPages === 0}
        onPress={toggleLightBox}
        style={{
          flexDirection: 'row',
          height: '100%',
          width: '100%'
        }}
      >
        {src ? (
          <MainImageMedia isVideo={isVideo} src={src} />
        ) : (
          <DefaultImage />
        )}

        {isVideo && (
          <FOHWhitePlayIcon
            style={{
              bottom: 0,
              height: 50,
              left: 0,
              margin: 'auto',
              position: 'absolute',
              right: 0,
              top: 0,
              width: 40
            }}
          />
        )}
      </FOHTouchableOpacity>

      {hasMultipleImages && (
        <NavigationWrapper>
          {navigation && (
            <FOHTouchableOpacity onPress={handlePreviousPage}>
              <Circle>
                <FOHPrevIcon />
              </Circle>
            </FOHTouchableOpacity>
          )}

          {range && (
            <DotPaginationWrapper>
              {range.map(item => {
                return (
                  <Dot key={`page${item}`} selected={currentPage === item} />
                );
              })}
            </DotPaginationWrapper>
          )}

          {navigation && (
            <FOHTouchableOpacity onPress={handleNextPage}>
              <Circle>
                <FOHNextIcon testID="FOHNextIcon" />
              </Circle>
            </FOHTouchableOpacity>
          )}
        </NavigationWrapper>
      )}
    </MainImageWrapper>
  );
};
