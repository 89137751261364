import { StyleProp, ImageStyle } from 'react-native';

export interface FOHIconProps {
  style?: StyleProp<ImageStyle>;
}

export interface CommonProps {
  isMobile?: boolean;
}

export enum SalaryUnitChoices {
  HOUR = 'HOUR',
  HOURLY_TIPS = 'HOURLY_TIPS',
  YEAR = 'YEAR'
}

export enum PaymentPeriod {
  HOUR = 'hourly',
  YEAR = 'annually',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'HOURLY_TIPS' = 'hourly with tips'
}

export type PaymentPeriodString = 'HOUR' | 'YEAR' | 'HOURLY_TIPS';

export interface FOHLocationProps {
  followerCount?: number;
  formattedAddress: string;
  id: string;
  image?: string;
  latitude?: number;
  longitude?: number;
  name: string;
  rowId?: number;
  tags?: { id: string; name: string }[];
}

export const FileFields = {
  FileName: 'fileName',
  FileType: 'fileType',
  FileSize: 'fileSize'
} as const;

export enum FOHPlanState {
  unlimited = 'UNLIMITED',
  payasyougo = 'PAYASYOUGO',
  paygo = 'PAYGO',
  enterprise = 'ENTERPRISE'
}

export enum FOHPositionBillingStatusType {
  SUBSCRIBED = 'SUBSCRIBED',
  PAUSED = 'PAUSED'
}

export enum FOHPositionHiringStatusType {
  POSTED = 'POSTED',
  NOT_POSTED = 'NOT_POSTED'
}

export enum FOHBannerType {
  CAUTION = 'caution',
  DANGER = 'danger',
  SUCCESS = 'success',
  INFO = 'info'
}

export enum FOHCandidateStateBannerEnum {
  APPLIED = 'applied',
  REQUESTED = 'requested',
  SCHEDULED = 'scheduled',
  REQUESTED_OR_SCHEDULED_BY_OTHER = 'byOther'
}

export enum FOHNotificationType {
  TODO = 'TODO',
  PENDING = 'PENDING',
  DONE = 'DONE',
  DECLINED = 'DECLINED',
  INFO = 'INFO',
  UNSCHEDULED = 'UNSCHEDULED'
}

export enum FOHDismissedWarningKeys {
  DO_NOT_SHOW_ME_AGAIN = 'do-not-show-me-again'
}

export enum FOHEmployerStagesType {
  APPLICANTS = 'APPLICANTS',
  CANDIDATES = 'PASSIVE_CANDIDATES',
  INTERVIEWS = 'INTERVIEWS',
  HIRED = 'HIRED',
  REJECTED = 'REJECTED',
  SCREENING = 'SCREENING',
  OFFERED = 'OFFERED'
}

export enum FOHInterviewAttendeesRole {
  CANDIDATE = 'candidate',
  INTERVIEWER = 'interviewer',
  NOTIFY_ONLY = 'notify_only'
}

export enum FOHInterviewStatus {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  DECLINED = 'declined',
  SCHEDULED = 'scheduled',
  NOT_SCHEDULED = 'notscheduled'
}

export enum FOHCandidateListSortBy {
  LAST_UPDATED = 'LAST_UPDATED',
  CREATED_AT = 'CREATED_AT'
}
