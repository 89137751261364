import React, { isValidElement } from 'react';

import { StyleProp, View, ViewStyle } from 'react-native';

import pincluster from '../../../images/pin_cluster.svg';

// https://mapstyle.withgoogle.com/
export const gmapStyle = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5'
      }
    ]
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161'
      }
    ]
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#f5f5f5'
      }
    ]
  },
  {
    elementType: 'labels.text.fill',
    featureType: 'administrative.land_parcel',
    stylers: [
      {
        color: '#bdbdbd'
      }
    ]
  },
  {
    elementType: 'geometry',
    featureType: 'poi',
    stylers: [
      {
        color: '#eeeeee'
      }
    ]
  },
  {
    elementType: 'labels.text.fill',
    featureType: 'poi',
    stylers: [
      {
        color: '#757575'
      }
    ]
  },
  {
    featureType: 'poi.business',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    elementType: 'geometry',
    featureType: 'poi.park',
    stylers: [
      {
        color: '#e5e5e5'
      }
    ]
  },
  {
    elementType: 'labels.text',
    featureType: 'poi.park',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    elementType: 'labels.text.fill',
    featureType: 'poi.park',
    stylers: [
      {
        color: '#9e9e9e'
      }
    ]
  },
  {
    elementType: 'geometry',
    featureType: 'road',
    stylers: [
      {
        color: '#ffffff'
      }
    ]
  },
  {
    elementType: 'labels.text.fill',
    featureType: 'road.arterial',
    stylers: [
      {
        color: '#757575'
      }
    ]
  },
  {
    elementType: 'geometry',
    featureType: 'road.highway',
    stylers: [
      {
        color: '#dadada'
      }
    ]
  },
  {
    elementType: 'labels.text.fill',
    featureType: 'road.highway',
    stylers: [
      {
        color: '#616161'
      }
    ]
  },
  {
    elementType: 'labels.text.fill',
    featureType: 'road.local',
    stylers: [
      {
        color: '#9e9e9e'
      }
    ]
  },
  {
    elementType: 'geometry',
    featureType: 'transit.line',
    stylers: [
      {
        color: '#e5e5e5'
      }
    ]
  },
  {
    elementType: 'geometry',
    featureType: 'transit.station',
    stylers: [
      {
        color: '#eeeeee'
      }
    ]
  },
  {
    elementType: 'geometry',
    featureType: 'water',
    stylers: [
      {
        color: '#c9c9c9'
      }
    ]
  },
  {
    elementType: 'labels.text.fill',
    featureType: 'water',
    stylers: [
      {
        color: '#9e9e9e'
      }
    ]
  }
];

export interface FOHMapProps {
  children?: any;
  Cluster?: any;
  latitude: number;
  latitudeDelta: number;
  longitude: number;
  longitudeDelta: number;
  Map?: any;
  setCenterLat?: () => void;
  setCenterLong?: () => void;
  style?: StyleProp<ViewStyle>;
}

export const FOHMarkerCluster = (props: any) => (
  <props.Cluster
    maxZoom={13}
    styles={[
      {
        height: 32,
        textColor: '#ffffff',
        url: pincluster,
        width: 32
      }
    ]}
  >
    {(clusterer: any) =>
      props.children.map((child: any) => {
        if (isValidElement(child)) {
          const pinProps = child.props as any;

          return {
            ...child,
            props: { ...pinProps, clusterer }
          };
        }

        return child;
      })
    }
  </props.Cluster>
);

export const FOHMap = (props: FOHMapProps) => {
  const setCoordinates =
    props.latitude && props.longitude
      ? {
          center: { lat: props.latitude, lng: props.longitude }
        }
      : undefined;

  return (
    <View
      style={{
        position: 'absolute',
        width: '100%',
        zIndex: 99,
        ...((props.style || {}) as ViewStyle)
      }}
    >
      <props.Map
        {...props}
        {...setCoordinates}
        customMapStyle={gmapStyle}
        initialRegion={{
          latitude: props.latitude,
          latitudeDelta: props.latitudeDelta,
          longitude: props.longitude,
          longitudeDelta: props.longitudeDelta
        }}
        key="map"
        options={{
          disableDefaultUI: true,
          keyboardShortcuts: true,
          maxZoom: 17,
          panControl: true,
          scaleControl: true,
          // https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions
          // streetView: undefined,
          styles: gmapStyle,
          zoomControl: true
        }}
      >
        {/* If you pass in Controls as children, directly use the cluster component in your render tree  */}
        {props.Cluster ? (
          <FOHMarkerCluster Cluster={props.Cluster}>
            {props.children}
          </FOHMarkerCluster>
        ) : (
          props.children
        )}
      </props.Map>
    </View>
  );
};
