import React from 'react';

import notificationCompletedOffers from '../../../images/notification-completed-offers.svg';

import { FOHImage } from '../../ingredients';

export interface FOHNotificationCompletedOffersProps {}

export const FOHNotificationCompletedOffers = (
  props: FOHNotificationCompletedOffersProps
) => (
  <FOHImage
    {...props}
    source={{ uri: notificationCompletedOffers }}
    style={{ width: 24, height: 24 }}
    testID={'offer-notification-badge'}
  />
);
