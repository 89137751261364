import React, { useState, useCallback } from 'react';

import { Slate, Editable, ReactEditor } from 'slate-react';
import { Editor } from 'slate';

import { FOHFonts, FOHSmallDetailLabel, FOHView } from '../../components';

import { handleDecorate } from './utils';

const renderElement = props => {
  switch (props.element.type) {
    default:
      return (
        <p
          style={{
            fontFamily: FOHFonts.REGULAR,
            fontSize: 14,
            margin: 0,
            lineHeight: 2
          }}
          {...props.attributes}
        >
          {props.children}
        </p>
      );
  }
};

const Leaf = props => {
  if (props.leaf.highlight) {
    return (
      <span
        {...props.attributes}
        style={{
          backgroundColor: '#E3F5F9',
          padding: 3,
          fontWeight: 'bold',
          borderRadius: 10,
          color: '#035081'
        }}
      >
        {props.children}
      </span>
    );
  }

  return (
    <span
      {...props.attributes}
      style={{
        fontWeight: props.leaf.bold ? 'bold' : 'normal'
      }}
    >
      {props.children}
    </span>
  );
};

export const SlateInput = props => {
  const { editor, value, setValue, maxCount, placeholder, disabled } = props;

  const renderLeaf = useCallback(
    leafProps => {
      return <Leaf {...leafProps} />;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  );

  const decorate = useCallback(
    entry => handleDecorate(entry),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  );

  const [count, setCount] = useState(0);

  return (
    <Slate
      style={{ minHeight: 300 }}
      editor={editor}
      value={value}
      onChange={newValue => {
        const contentLength = Editor.string(editor, []).length;
        if (contentLength <= maxCount) {
          setCount(contentLength);
          setValue(newValue);
        }
      }}
    >
      <Editable
        readOnly={disabled}
        decorate={decorate}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        onKeyDown={event => {
          if (
            Editor.string(editor, []).length >= maxCount &&
            event.key !== 'Backspace' &&
            event.key !== 'ArrowLeft' &&
            event.key !== 'ArrowRight' &&
            event.key !== 'ArrowDown' &&
            event.key !== 'ArrowUp'
          ) {
            event.preventDefault();
          }
        }}
        onPaste={event => {
          event.preventDefault();

          const data = event.clipboardData.getData('text/plain');
          const nextCount = count + data.length;
          if (nextCount < maxCount) {
            ReactEditor.insertData(editor, event.clipboardData);
          }

          return true;
        }}
        style={{ minHeight: 300 }}
      />
      {placeholder ? (
        <FOHView
          style={{
            position: 'absolute',
            left: 12,
            top: 10,
            padding: 4,
            borderRadius: 6,
            backgroundColor: '#ffffff'
          }}
        >
          <FOHSmallDetailLabel style={{ fontSize: 12 }}>
            {placeholder}
          </FOHSmallDetailLabel>
        </FOHView>
      ) : (
        <></>
      )}
      <FOHView
        style={{
          position: 'absolute',
          right: 10,
          bottom: 10,
          padding: 4,
          borderRadius: 6,
          backgroundColor: '#ffffff'
        }}
      >
        {maxCount ? (
          <FOHSmallDetailLabel>{`${count}/${String(
            maxCount
          )}`}</FOHSmallDetailLabel>
        ) : (
          <></>
        )}
      </FOHView>
    </Slate>
  );
};

export default SlateInput;
