import { FunctionComponent } from 'react';

import { TextInput, TextInputProps, ViewStyle, StyleProp } from 'react-native';
import styled from 'styled-components/native';
import { compose } from 'ramda';

import { FOH_INPUT_HEIGHT } from '../../constants';

import { withError } from '../text/withError';
import { withPlaceholderText } from '../text/withPlaceholderText';
import { withRequired } from '../text/withRequired';
import { FOHFonts } from '../text/FOHFonts';
import { FOHColors } from '../colors/FOHColors';

export interface FOHTextFieldProps extends TextInputProps {
  underline?: boolean;
  error?: any;
  height?: any;
  paddingRight?: any;
  testID?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  label?: string;
  style?: StyleProp<ViewStyle>;
  focused?: boolean;
}

const TextField = styled(TextInput)`
  background-color: ${(props: FOHTextFieldProps) =>
    props.disabled ? FOHColors.GRAYSCALE_100 : FOHColors.WHITE};
  border-color: ${(props: FOHTextFieldProps) =>
    props.error
      ? FOHColors.RED_300
      : props.disabled
      ? FOHColors.GRAYSCALE_200
      : props.focused
      ? FOHColors.BLUE
      : FOHColors.GRAYSCALE_300};
  border-top-width: ${(props: FOHTextFieldProps) =>
    props.underline ? `0px` : `1px`};
  border-left-width: ${(props: FOHTextFieldProps) =>
    props.underline ? `0px` : `1px`};
  border-right-width: ${(props: FOHTextFieldProps) =>
    props.underline ? `0px` : `1px`};
  border-bottom-width: 1px;
  border-style: solid;
  border-radius: ${(props: FOHTextFieldProps) =>
    props.underline ? `0px` : `4px`};
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 200%;
  display: flex;
  align-items: center;
  width: 100%;
  height: ${(props: FOHTextFieldProps) =>
    props.height ? props.height : FOH_INPUT_HEIGHT};
  padding: ${(props: FOHTextFieldProps) => (props.underline ? `0px` : `10px`)};
  padding-right: ${(props: FOHTextFieldProps) =>
    props.paddingRight ? props.paddingRight : `0px`};
  overflow: ${(props: FOHTextFieldProps) =>
    props.height === 300 ? `auto` : `hidden`};
  color: ${(props: FOHTextFieldProps) =>
    props.disabled ? FOHColors.GRAYSCALE_500 : FOHColors.GRAYSCALE_900};
  outline-style: none;
`;

export const FOHBareTextField: FunctionComponent<FOHTextFieldProps> = compose(
  withError,
  withRequired
)(TextField);

export const FOHTextField: FunctionComponent<FOHTextFieldProps> = compose(
  withError,
  withRequired,
  withPlaceholderText
)(TextField);
