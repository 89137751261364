import { isEmpty } from 'ramda';

interface AddressData {
  address_components: {
    long_name: string;
    short_name: string;
    types: string[];
  }[];
}

export const getAddressInfo = (addressData: AddressData) => {
  const result: {
    street1: string;
    city: string;
    state: string;
    postalCode: string;
  } = {
    street1: '',
    city: '',
    state: '',
    postalCode: ''
  };

  if (
    !addressData ||
    !addressData.address_components ||
    isEmpty(addressData?.address_components)
  )
    return result;

  addressData?.address_components.forEach(component => {
    const types = component.types;

    if (types.includes('street_number')) {
      result.street1 = component.long_name + ' ';
    }

    if (types.includes('route')) {
      result.street1 += component.long_name;
    }

    if (types.includes('locality')) {
      result.city = component.long_name;
    }

    if (types.includes('administrative_area_level_1')) {
      result.state = component.short_name;
    }

    if (types.includes('postal_code')) {
      result.postalCode = component.long_name;
    }
  });

  return result;
};

export const autoCompleteAPIOptions = {
  componentRestrictions: { country: ['us', 'ca'] }
};
