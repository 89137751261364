import React from 'react';

import { Text, Dimensions } from 'react-native';
import styled from 'styled-components/native';
import { prop } from 'ramda';

import {
  FOHSmallDetailLabel,
  FOHBlueFrameIcon,
  FOHSpace,
  FOHFonts,
  FOHView
} from '../../ingredients';

export interface FOHExternalApplicationHeaderProps {
  applicationTitle: string;
  applicationDetail?: string;
  applicationTotalNumber?: string;
  applicationNumber?: string;
}

export const FOHExternalApplicationHeader = (
  props: FOHExternalApplicationHeaderProps
) => (
  <FOHView
    style={{
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      width: '100%'
    }}
  >
    <FOHView style={{ flexDirection: 'row', maxWidth: 300 }}>
      <FOHView style={{ marginTop: '4px' }}>
        <FOHBlueFrameIcon />
      </FOHView>
      <FOHSpace />
      <FOHView>
        <FOHApplicationFormLabel
          style={{
            flexDirection: 'row',
            maxWidth: prop('width', Dimensions.get('window')) <= 960 ? 250 : 290
          }}
        >
          {props.applicationTitle}
        </FOHApplicationFormLabel>
        <ApplicationDetailLabel
          style={{
            flexDirection: 'row',
            maxWidth: prop('width', Dimensions.get('window')) <= 960 ? 250 : 290
          }}
        >
          {props.applicationDetail}
        </ApplicationDetailLabel>
      </FOHView>
    </FOHView>
    <ApplicationStepText
      style={{
        maxWidth: prop('width', Dimensions.get('window')) <= 960 ? 50 : 100
      }}
    >
      {`${props.applicationNumber}/${props.applicationTotalNumber}`}
    </ApplicationStepText>
  </FOHView>
);

const FOHApplicationFormLabel = styled(Text)`
  font-family: ${FOHFonts.BOLD}
  font-style: normal;
  font-weight: 700;
  font-size: ${() =>
    prop('width', Dimensions.get('window')) <= 960 ? '24px' : '32px'};
  line-height: 40px;
  align-items: center;
  color: #17abcc;
`;

const ApplicationStepText = styled(FOHApplicationFormLabel)`
  color: #dbdbdb;
`;

const ApplicationDetailLabel = styled(FOHSmallDetailLabel)`
  font-size: 16px;
`;
