import React from 'react';

import incomplete from '../../../images/incompletestep.svg';

import { FOHImage } from '../../ingredients';

export const FOHNextStep = (props: any) => (
  <FOHImage
    source={{ uri: incomplete }}
    style={{ width: 25, height: 25, ...props.style }}
  />
);
