import React from 'react';

import trash from '../../../images/trash_can_blk.svg';

import { FOHImage } from '../../ingredients';

export const FOHTrashCanBlk = (props: any) => (
  <FOHImage
    source={{ uri: trash }}
    style={{ width: 16, height: 16, ...props?.style }}
  />
);
