import React, { useState } from 'react';

import { withTranslation } from 'react-i18next';
import { compose, prop, length } from 'ramda';
import Loadable from '@loadable/component';

import {
  FOHFixedHeightScrollView,
  FOHFormFlowButtons,
  FOHNewPositionScreen,
  FOHView
} from '../../components';
import {
  AVAILABILITY_WEEKDAYS_CAMEL,
  AVAILABILITY_SHIFTS_MAP,
  ADDITIONAL_TRAINING_DROPDOWN,
  YEARS_EXPERIENCE_DROPDOWN,
  EXTERNAL_APPLICATION_WIDTH,
  EXTERNAL_APPLICATION_HEIGHTTODECREASE,
  PENDING_EMPLOYER_PROFILE
} from '../../constants';
import { withPositions, withUser, withUpdateUser } from '../../api/hocs';
import { scrollToTop, withIsMobile, multiSelectItem } from '../../utils';

import { useCurrentLocationContext } from '../Locations/useCurrentLocationContext';
import { useMyLocationsData } from '../Locations';

import { usePositionMutation } from './usePositionMutation';
import { paymentTypeOptions } from './positionPayments';

import '../EmployerSettings/quill-styles.css';

const JobDescriptionEditor = Loadable(() =>
  import('../EmployerPositions/JobDescriptionEditor')
);

const FirstPosition = props => {
  const { t } = props;
  const [openDropdown, setOpenDropdown] = useState('');
  const location = props?.location;

  const { selectLocationFilter } = useCurrentLocationContext({
    skipLocationsQuery: true,
    props
  });

  const { data: myLocations } = useMyLocationsData();

  const {
    name,
    setName,
    error,
    training,
    setTraining,
    positionTypeOptions,
    setPositionTypeText,
    positionTypeText,
    selectedPositionTypes,
    setSelectedPositionTypes,
    yearsExp,
    setYearsExp,
    notifications,
    setSendNotifications,
    availability,
    selectAvailability,
    allAvailabilitySelected,
    toggleAllAvailability,
    inProgress,
    minPay,
    setMinPay,
    maxPay,
    setMaxPay,
    paymentType,
    setPaymentType,
    jobDescription,
    setJobDescription,
    includeSalary,
    setIncludeSalary,
    handleSave
  } = usePositionMutation({
    initialLocation: location?.id,
    selectLocationFilter,
    initialLocationSlug: location?.slug,
    myLocations: myLocations
  });

  const handleSubmit = async () => {
    const positionResponse = await handleSave();
    if (!positionResponse) {
      scrollToTop({});
      return;
    }
    scrollToTop({});

    props?.finishOnboarding(PENDING_EMPLOYER_PROFILE);
  };

  return (
    <FOHView
      style={{
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <FOHView
        style={{
          width: '100%',
          maxWidth: props.isMobile ? '100%' : EXTERNAL_APPLICATION_WIDTH
        }}
      >
        <FOHFixedHeightScrollView
          isMobile={props.isMobile}
          heightToDecrease={EXTERNAL_APPLICATION_HEIGHTTODECREASE}
        >
          <FOHView
            style={{
              width: '100%',
              maxWidth: props.isMobile ? '100%' : EXTERNAL_APPLICATION_WIDTH
            }}
          >
            <FOHNewPositionScreen
              {...props}
              positionNamePlaceholder={t('positionNamePlaceholder')}
              positionTypePlaceholder={t('positionTypePlaceholder')}
              availabilitySectionLabel={t('availabilitySectionLabel')}
              doneText={
                inProgress
                  ? t('CandidateFeed:generatingMatches')
                  : t('doneText')
              }
              modalHeaderText={t(openDropdown)}
              cancelText={t('cancel')}
              mobile={props.isMobile}
              trainingPlaceholder={t('trainingPlaceholder')}
              locationPlaceholder={t('locationPlaceholder')}
              experiencePlaceholder={t('experiencePlaceholder')}
              sendNotificationsLabel={t('sendNotificationsLabel')}
              benefitsPlaceholder={t('benefitsPlaceholder')}
              minPay={minPay}
              minPayLabel={t('minPayLabel')}
              setMinPay={val => (!val ? setMinPay('$') : setMinPay(val))}
              maxPay={maxPay}
              maxPayLabel={t('maxPayLabel')}
              setMaxPay={val => (!val ? setMaxPay('$') : setMaxPay(val))}
              paymentType={paymentType}
              paymentTypeLabel={t('paymentTypeLabel')}
              paymentTypeOptions={paymentTypeOptions(t)}
              setPaymentType={setPaymentType}
              locations={myLocations.map(loc => ({
                label: loc.displayName,
                value: loc.rowId
              }))}
              close={() => setOpenDropdown('')}
              key={location}
              cancel={() => goBack()}
              goBack={() => goBack()}
              setOpenDropdown={id => setOpenDropdown(id)}
              openDropdown={openDropdown}
              positionName={name}
              setPositionName={setName}
              closeLabel={
                length(selectedPositionTypes) > 0
                  ? t('PositionSearchAheadFeature:applySearchLabel')
                  : t('PositionSearchAheadFeature:closeLabel')
              }
              positionTypes={positionTypeOptions}
              setPositionTypeText={setPositionTypeText}
              positionTypeText={positionTypeText}
              clearPositionTypes={() => {
                setPositionTypeText('');
                setSelectedPositionTypes([]);
              }}
              selectedPositionTypes={selectedPositionTypes}
              setSelectedPositionTypes={value => {
                setPositionTypeText('');
                multiSelectItem(
                  value,
                  setSelectedPositionTypes,
                  selectedPositionTypes
                );
              }}
              training={ADDITIONAL_TRAINING_DROPDOWN.map(option => ({
                value: option.value,
                label: t(option.label)
              }))}
              selectedTraining={training}
              setSelectedTraining={value =>
                multiSelectItem(value, setTraining, training)
              }
              preferredExperience={[
                { label: t('CandidateFeed:showAll'), value: 'show_all' },
                ...YEARS_EXPERIENCE_DROPDOWN.map(position => ({
                  value: position.value,
                  label: t(position.label)
                }))
              ]}
              selectedPreferredExperience={yearsExp}
              setSelectedPreferredExperience={value => setYearsExp(value)}
              sendNotifications={notifications}
              toggleNotifications={() => setSendNotifications(!notifications)}
              availabilitySections={AVAILABILITY_WEEKDAYS_CAMEL.map(
                position => ({
                  value: position.value,
                  label: t(position.label)
                })
              )}
              shifts={AVAILABILITY_SHIFTS_MAP(t)}
              selectedAvailability={availability}
              selectAvailability={(value, section) =>
                selectAvailability({
                  value,
                  section
                })
              }
              selectAllAvailabilityLabel={t('PositionsFeature:selectAll')}
              allAvailabilitySelected={allAvailabilitySelected}
              onSelectAllAvailability={toggleAllAvailability}
              title={t('title')}
              description={t('description')}
              align={props.isInSettings ? 'left' : 'center'}
              selectedLocation={location?.rowId}
              setSelectedLocation={() => {}}
              includeSalaryTermsText={t(
                'PositionsFeature:includeSalaryTermsText'
              )}
              includeSalaryLabel={t('PositionsFeature:includeSalaryLabel')}
              includeSalaryValue={includeSalary}
              toggleIncludeSalary={() => setIncludeSalary(!includeSalary)}
              isMobile={props.isMobile}
              disabledSubmit={inProgress}
              onCancel={undefined}
              cancelButtonLabel={t('cancelText')}
              error={error}
              onSubmit={handleSubmit}
            >
              <JobDescriptionEditor
                description={jobDescription}
                setDescription={setJobDescription}
                error={prop('jobDescription', error)}
                {...props}
              />
            </FOHNewPositionScreen>
          </FOHView>
        </FOHFixedHeightScrollView>
      </FOHView>
      <FOHView
        style={{
          width: '100%',
          marginBottom: props.isMobile ? 0 : 100
        }}
      >
        <FOHFormFlowButtons
          isMobile={true}
          nextButtonlabel={inProgress ? t('common:loading') : t('common:next')}
          onPressNextButton={handleSubmit}
          isNextDisabled={inProgress}
          previousButtonLabel={t('common:previous')}
          onPressPreviousButton={props.previousStep}
        />
      </FOHView>
    </FOHView>
  );
};

export default compose(
  withIsMobile,
  withPositions,
  withUpdateUser,
  withUser,
  withTranslation('PositionsFeatureOnboarding')
)(FirstPosition);
