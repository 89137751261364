import React from 'react';
import { useTranslation } from 'react-i18next';
import { compose } from 'ramda';

import {
  FOHButton,
  FOHColors,
  FOHView,
  FOHSpace,
  FOHSpaceLarger,
  FOHBannerType,
  FOHHeaderH2,
  FOHSmallDetailLabel,
  FOHImageCell
} from '../../components';
import deleteAccountImage from '../../images/delete-account.svg';
import { useBanner } from '../Navigation/useBanner';
import { withReactiveModal, WithReactiveModalProps } from '../Navigation';
import {
  GetCareerPagesDocument,
  useMutateDeleteCareerPageMutation
} from '../../graphql/generated';
import { CAREER_PAGE_DASHBOARD } from '../../constants';
import { handleMutation } from '../../utils';
import { useNavigation } from '../../utils/navigation';

interface DeleteCandidateModalProps extends WithReactiveModalProps {
  careerPageTitle: string;
  careerPageId: string;
  employerProfileId: string;
}

const DeleteConfirmationModal = (props: DeleteCandidateModalProps) => {
  const { t } = useTranslation('CareerPage');

  const { navigateTo } = useNavigation();

  const [mutateDeleteCareerPageMutation] = useMutateDeleteCareerPageMutation();

  const { setBanner } = useBanner();

  const handleOnDeleteCareerPage = async () => {
    const [response, error] = await handleMutation(
      mutateDeleteCareerPageMutation({
        refetchQueries: [
          {
            query: GetCareerPagesDocument,
            variables: {
              employerProfile: props.employerProfileId,
              first: 10
            }
          }
        ],
        variables: {
          careerPageId: props.careerPageId
        }
      })
    );

    if (error || !response) {
      setBanner({
        bannerMessage: t('deleteErrorBanner') as string,
        bannerType:
          FOHBannerType.DANGER as unknown as keyof typeof FOHBannerType
      });
      return;
    }

    setBanner({
      bannerMessage: t('deleteSuccessBanner') as string,
      bannerType: FOHBannerType.SUCCESS as unknown as keyof typeof FOHBannerType
    });

    props.close && props.close();
    navigateTo(CAREER_PAGE_DASHBOARD, { replace: true });
  };

  return (
    <FOHView
      style={{ alignItems: 'center', padding: 24 }}
      testID="DeleteConfirmationModal"
    >
      <FOHImageCell
        image={deleteAccountImage}
        style={{ height: 144, width: 144 }}
      />
      <FOHSpace />
      <FOHHeaderH2>{t('deleteConfirmationTitle')}</FOHHeaderH2>
      <FOHSpace />
      <FOHSmallDetailLabel
        style={{ paddingHorizontal: 4, textAlign: 'center' }}
      >
        {t('deleteConfirmationDetail', { pageTitle: props.careerPageTitle })}
      </FOHSmallDetailLabel>
      <FOHSpaceLarger />
      <FOHView
        style={{
          alignItems: 'center',
          flexDirection: 'row',
          paddingHorizontal: 4
        }}
      >
        <FOHButton
          borderColor={FOHColors.BORDER_GRAY}
          color={FOHColors.WHITE}
          onPress={() => props.close && props.close()}
          style={{ width: 128 }}
          textColor={FOHColors.BLACK}
          title={t('common:cancel')}
        />
        <FOHSpace />
        <FOHButton
          color={FOHColors.RED}
          onPress={handleOnDeleteCareerPage}
          style={{ width: 128 }}
          title={t('deleteLabel')}
        />
      </FOHView>
    </FOHView>
  );
};

export default compose(withReactiveModal)(DeleteConfirmationModal);
