import React, { useState, useEffect } from 'react';

import { withTranslation } from 'react-i18next';
import { compose, path, length } from 'ramda';
import Confetti from 'react-confetti';

import { handle } from '../../utils';
import { FOHGuideBanner } from '../../components';

import { useCandidateProfileMutation } from './useCandidateProfileMutation';

const WelcomeBanner = props => {
  const {
    openWelcome,
    setOpenWelcome,
    t,
    setOpenApplicationComplete,
    openCompleteProfile
  } = props;
  const [confetti, setConfetti] = useState(false);
  const { me, updateCandidate } = useCandidateProfileMutation();
  const profile = path(['candidateProfile'], me);

  const setHasCompletedOnboarding = async _me => {
    if (
      path(['candidateProfile', 'id'], _me) &&
      length(path(['candidateProfile', 'positions'], _me)) !== 0 &&
      !path(['candidateProfile', 'onboardingCompletedAt'], _me) &&
      !openCompleteProfile &&
      !path(['employerProfile', 'id'], _me)
    ) {
      setOpenWelcome(true);
      setOpenApplicationComplete(true);
      await handle(
        updateCandidate({
          variables: {
            hasCompletedOnboarding: true
          }
        })
      );
    }
  };

  useEffect(() => {
    setHasCompletedOnboarding(me);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  useEffect(() => {
    if (openWelcome) {
      setTimeout(() => setConfetti(false), 3000);
      setConfetti(true);
    }
  }, [openWelcome]);

  return (
    <>
      <Confetti
        width={window.width}
        height={window.height}
        colors={['#E5253C', '#F5AD22', '#51C1DB', '#035081', '#38A56B']}
        style={{ zIndex: 10000 }}
        recycle={confetti}
        numberOfPieces={confetti ? 500 : 0}
        onConfettiComplete={() => {
          setConfetti(false);
        }}
      />
      {openWelcome && <div style={{ top: '60px' }}></div>}
      <FOHGuideBanner
        open={openWelcome}
        mobile={props.isMobile}
        close={() => setOpenWelcome(!openWelcome)}
        iconText={t('CandidateWelcomePage:congrats')}
        title={t('CandidateWelcomePage:pageTitle')}
        body={t('CandidateWelcomePage:pageSubTitle')}
        buttonLabel={t('CandidateWelcomePage:exploreRestaurants')}
        onButtonPress={async () => {
          setOpenWelcome(false);
        }}
      />
    </>
  );
};

export default compose(withTranslation())(WelcomeBanner);
