import React, { useState, useEffect, useRef, useMemo } from 'react';

import styled from 'styled-components/native';
import { prop, length, path, defaultTo } from 'ramda';
import { useTranslation } from 'react-i18next';
import { createEditor, Node } from 'slate';
import { withReact } from 'slate-react';

import {
  CANDIDATE_PROFILE,
  EMPLOYER_SETTINGS_MENU,
  FEED,
  SCHEDULE,
  TEMPLATES,
  UPGRADE
} from '../../constants';
import { useIsMobile } from '../../utils';
import {
  useGetCandidateQuery,
  useEmployerMessageTemplatesQuery,
  useUserInterviewScheduleQuery
} from '../../graphql/generated';
import {
  getPlaceHolderByAction,
  getTemplatePlaceHolders,
  INTERVIEW_REQUEST_SEND_SCHEDULE
} from '../../constants/templates';
import {
  FOHLabel,
  FOHColors,
  FOHSpace,
  FOHSpaceSmall,
  FOHView,
  FOHSmallDetailLabel,
  FOHInterviewMeetingType,
  FOHInterviewAttendeesRole,
  FOHFonts,
  FOHSearchableSelectDropdown,
  FOHLabeledCheckbox,
  FOHInterviewTypeFields,
  FOHTouchableOpacity,
  FOHOpenIcon,
  FOHSpaceMassive,
  FOHSpaceSmallest
} from '../../components';
import { useNavigation } from '../../utils/navigation';

import { useCandidateFeedContext } from '../CandidateFeed/useCandidateFeedContext';
import { useCandidateRequestInterview } from '../InterviewRequest/useCandidateRequestInterview';
import { useSelectPositionForSelectedLocationState } from '../EmployerPositions/useSelectPositionForSelectedLocationState';
import ActionModal from '../CandidateFeed/ActionModal';
import {
  withCharacterLimit,
  withCustomDelete,
  stripBracesFromPlaceholder
} from '../EmployerMessageTemplate/utils';
import { FOHMessageInputSection } from '../EmployerMessageTemplate/FOHMessageInputSection';
import { useGetMeData } from '../SignIn';

import useInterviewAttendees from './useInterviewAttendees';

export const findUserById = (interviewAttendeesList, userId) => {
  return interviewAttendeesList.find(attendee => attendee.user.id === userId);
};

const MAX_COUNT = 500;

const editor = withCharacterLimit(MAX_COUNT)(
  withReact(withCustomDelete(createEditor()))
);

const ShareScheduleFormModal = props => {
  const { t, i18n } = useTranslation('EmployerInterviewScheduleFeature');

  const { isInSettings, employerCandidate } = props;

  const { data: me } = useGetMeData();

  const loggedInUserId = useMemo(() => defaultTo('', prop('id', me)), [me]);

  const { navigateTo, goBack } = useNavigation();
  const { isMobile } = useIsMobile();

  const modal = defaultTo(false, prop('isModal', props));
  const modalTimeSlot = defaultTo(false, prop('modalTimeSlot', props));

  const scrollRef = useRef();

  const [selectedLength, setSelectedLength] = useState(60);
  const [interviewType, setInterviewType] = useState(
    FOHInterviewMeetingType.INPERSON
  );
  const [phone, setPhone] = useState('');
  const [remoteUrl, setRemoteUrl] = useState('');
  const [interviewWindow, setInterviewWindow] = useState(2);
  const [sending, setSending] = useState(false);
  const [inputMessageContent, setInputMessageContent] = useState([
    {
      type: 'paragraph',
      children: [{ text: '' }]
    }
  ]);
  const [label, setLabel] = useState('');
  const [template, setTemplate] = useState();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [placeHolderError, setPlaceHolderError] = useState('');
  const [interviewerDropdownOpen, setInterviewerDropdownOpen] = useState(false);

  const messageTemplatesQuery = useEmployerMessageTemplatesQuery({
    variables: {
      search,
      category: 'INTERVIEW',
      action: 'interview-request-send-schedule'
    }
  });

  const filteredTemplates = useMemo(() => {
    const templates = defaultTo(
      [],
      path(['data', 'employerMessageTemplates', 'edges'], messageTemplatesQuery)
    );

    return templates
      .map(edge => ({
        id: edge?.node.id,
        name: edge?.node.name,
        messageContent: edge.node.messageContent,
        templateType: edge.node.templateType,
        isDefault: edge.node.isDefault,
        category: edge.node.category,
        action: edge.node.action
      }))
      .filter(_template =>
        _template?.name?.toLowerCase()?.includes(search.toLowerCase())
      );
  }, [messageTemplatesQuery, search]);

  useEffect(() => {
    if (length(filteredTemplates) > 0 && !template) {
      const defaultTemplate = filteredTemplates.find(
        _template => !!_template.isDefault
      );
      setTemplate(defaultTemplate);
      handleOnUseTemplate(defaultTemplate);
    }
  }, [filteredTemplates, template]);

  const employerCandidatePreferredMatch = path(
    ['preferredMatch'],
    employerCandidate
  );

  const {
    selectedLocation,
    setSelectedPosition,
    selectedPosition,
    paidPositions
  } = useSelectPositionForSelectedLocationState({
    profile: props.profile,
    positionId: path(['position', 'id'], employerCandidatePreferredMatch),
    locationId: path(
      ['position', 'location', 'id'],
      employerCandidatePreferredMatch
    )
  });

  const {
    interviewAttendees,
    interviewerSearch,
    setInterviewerSearch,
    selectedInterviewer,
    setSelectedInterviewer,
    keepMeUpdated,
    setKeepMeUpdated,
    manageInterviewAttendeesList,
    clearInterviewAttendeeStates,
    schedulerIsInterviewer,
    interviewersOptions,
    selectedInterviewerUser,
    createInterviewParticipants
  } = useInterviewAttendees({
    loggedInUserId,
    employerCandidate,
    selectedLocation,
    isActionModalOpen: props?.open
  });

  const interviewParticipants = useMemo(() => {
    if (selectedInterviewerUser && employerCandidate) {
      return createInterviewParticipants(
        selectedInterviewerUser,
        employerCandidate
      );
    }
    return [];
  }, [selectedInterviewerUser, employerCandidate]);

  const userInterviewScheduleQuery = useUserInterviewScheduleQuery({
    skip: !selectedInterviewer || !loggedInUserId,
    variables: {
      userId: selectedInterviewer || loggedInUserId
    }
  });

  const interviewSchedule = path(
    ['data', 'userInterviewSchedule'],
    userInterviewScheduleQuery
  );

  const interviewScheduleRanges = useMemo(
    () =>
      defaultTo(
        [],
        path(
          ['data', 'userInterviewSchedule', 'interviewScheduleRanges'],
          userInterviewScheduleQuery
        )
      ),
    [interviewSchedule]
  );

  useEffect(() => {
    if (interviewSchedule) {
      setRemoteUrl(prop('locationUrl', interviewSchedule));
      setInterviewType(prop('location', interviewSchedule));
      setSelectedLength(
        prop('durationMinutes', interviewSchedule).match(/(\d+)$/)[0]
      );
      setInterviewWindow(prop('maxWeeksOut', interviewSchedule));
      prop('locationPhone', interviewSchedule) &&
        setPhone(prop('locationPhone', interviewSchedule));
    }
  }, [
    interviewSchedule,
    userInterviewScheduleQuery.data,
    userInterviewScheduleQuery.loading
  ]);

  const saveSchedule = async () => {
    const messageContent = inputMessageContent
      .map(n => Node.string(n))
      .join('\n');

    const placeholder = getPlaceHolderByAction(INTERVIEW_REQUEST_SEND_SCHEDULE);

    if (!messageContent.includes(placeholder)) {
      const errorPlaceholder = stripBracesFromPlaceholder(placeholder);

      setPlaceHolderError(
        t('MessageTemplatesFeature:requiredPlaceHolder', {
          placeholder: errorPlaceholder
        })
      );
      return;
    }

    setPlaceHolderError('');

    setSending(true);
    // send interview for this position and location
    props.onSubmitSchedulePress &&
      (await props.onSubmitSchedulePress(
        selectedPosition,
        selectedLocation,
        messageContent,
        interviewAttendees,
        interviewType,
        phone,
        remoteUrl
      ));

    clearInterviewAttendeeStates();
    setSending(false);

    if (path(['current', 'scrollTo'], scrollRef)) {
      scrollRef.current.scrollTo({
        y: 0,
        animated: true
      });
    }
    if (!modal && !modalTimeSlot) {
      navigateTo(
        {
          pathname: FEED
        },
        {
          state: {
            showUpdateScheduleBanner: true
          }
        }
      );
    }
  };

  const handleOnUseTemplate = (selectedTemplate = null) => {
    const temp = selectedTemplate ?? template;

    const newValue = [
      {
        type: 'paragraph',
        children: [{ text: temp?.messageContent || '' }]
      }
    ];

    setInputMessageContent(newValue);
    editor.children = newValue;
    setLabel(temp?.name);
    setOpen(false);
  };

  const resetForm = () => {
    setOpen(false);
    setTemplate(undefined);
    setPlaceHolderError('');
    setLabel('');
  };

  const handleKeepMeUpdated = () => {
    setKeepMeUpdated(!keepMeUpdated);
    manageInterviewAttendeesList(
      loggedInUserId,
      FOHInterviewAttendeesRole.NOTIFY_ONLY
    );
  };

  return (
    <ActionModal
      {...props}
      title={t('shareYourScheduleTitle', {
        interviewerAttendee: schedulerIsInterviewer
          ? t('common:yourLabel')
          : i18n?.language === 'en'
          ? `${selectedInterviewerUser?.user?.firstName}'s`
          : selectedInterviewerUser?.user?.firstName,
        candidateName: `${employerCandidate?.candidateProfile?.user?.firstName} ${employerCandidate?.candidateProfile?.user?.lastName}`
      })}
      participants={interviewParticipants}
      defaultLocation={path(
        ['position', 'location', 'id'],
        employerCandidatePreferredMatch
      )}
      defaultPosition={path(
        ['position', 'id'],
        employerCandidatePreferredMatch
      )}
      showLabel={t('common:show')}
      onPressUpgrade={() => navigateTo(`${FEED}${UPGRADE}`)}
      searchPlaceholder={t('common:search')}
      positionFieldLabel={t('common:position')}
      upgradeLabel={t('TopNavFeature:upgrade')}
      backButtonText={t('common:previous')}
      nextButtonText={sending ? t('actions:sending') : t('common:send')}
      onBackPress={() => {
        goBack();
        resetForm();
      }}
      isNextDisabled={
        sending ||
        length(paidPositions) === 0 ||
        length(Object.keys(interviewScheduleRanges)) === 0
      }
      onNextPress={saveSchedule}
      setPosition={setSelectedPosition}
      containerStyle={{
        alignItems: 'flex-start',
        backgroundColor: isInSettings
          ? '#f5f5f5'
          : isMobile
          ? '#ffffff'
          : undefined,
        width: '100%'
      }}
      positionContainerTestId="ShareScheduleModal-position-filter"
    >
      <FOHView style={{ zIndex: 13 }} testID={'ShareScheduleFormModal'}>
        <FOHView
          style={{
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start'
          }}
        >
          <FOHView style={{ maxWidth: '57%', width: '100%' }}>
            <FieldLabel>
              {t('ScheduleInterviewFeature:interviewerSelectLabel')}
            </FieldLabel>
            <FOHSpaceSmall />
            <FOHSearchableSelectDropdown
              open={interviewerDropdownOpen}
              style={{ width: '100%', backgroundColor: FOHColors.WHITE }}
              maxHeight={33}
              setOpen={isOpen => {
                setInterviewerDropdownOpen(isOpen);
              }}
              selectedValue={selectedInterviewer}
              onSearchChange={setInterviewerSearch}
              searchValue={interviewerSearch}
              searchPlaceholder={t('common:search')}
              onValueChange={value => {
                setSelectedInterviewer(value);
                if (schedulerIsInterviewer) {
                  setInterviewType(FOHInterviewMeetingType.INPERSON);
                  setPhone('');
                  setRemoteUrl('');
                }
              }}
              options={interviewersOptions}
              testID={'ShareScheduleModal-interviewer-filter'}
            />
          </FOHView>
          <FOHSpaceSmall />
          <FOHView style={{ maxWidth: '43%', width: '100%' }}>
            <FieldLabel>
              {t('EmployerInterviewScheduleFeature:interviewTypeLabel')}
            </FieldLabel>
            <FOHSpaceSmall />
            <FOHView style={{ width: '100%' }}>
              <FOHInterviewTypeFields
                whereQuestionLabel="" //no title
                dropdownLabel={t(
                  'EmployerInterviewScheduleFeature:interviewTypeLabel'
                )}
                inPersonLabel={t(
                  'EmployerInterviewScheduleFeature:inPersonLabel'
                )}
                remoteMeetingLabel={t(
                  'EmployerInterviewScheduleFeature:remoteMeetingLabel'
                )}
                isPhoneLabel={t(
                  'EmployerInterviewScheduleFeature:isPhoneLabel'
                )}
                remoteUrlPlaceholder={t(
                  'EmployerInterviewScheduleFeature:remoteUrlPlaceholder'
                )}
                phonePlaceholder={t(
                  'EmployerInterviewScheduleFeature:phonePlaceholder'
                )}
                interviewLengthPlaceholder={t(
                  'EmployerInterviewScheduleFeature:interviewLengthPlaceholder'
                )}
                where={interviewType}
                setWhere={setInterviewType}
                remoteUrl={remoteUrl}
                setRemoteUrl={setRemoteUrl}
                phone={phone}
                setPhone={setPhone}
                schedulerIsInterviewer={schedulerIsInterviewer}
              />
            </FOHView>
          </FOHView>
        </FOHView>
        {selectedInterviewer &&
        loggedInUserId &&
        selectedInterviewer !== loggedInUserId ? (
          <FOHView
            style={{
              zIndex: interviewerDropdownOpen ? -1 : 12
            }}
          >
            <FOHSpaceSmall />
            <FOHLabeledCheckbox
              disabled={!selectedInterviewer}
              checkboxLabel={t('ScheduleInterviewFeature:keepMeUpdated')}
              selected={defaultTo(true, keepMeUpdated)}
              setSelected={handleKeepMeUpdated}
            />
          </FOHView>
        ) : (
          <></>
        )}
      </FOHView>
      <FOHSpace />
      <FOHSpaceSmallest />
      <FOHView testID="interview-schedule-detail">
        <FOHView style={{ flexDirection: 'row', alignItems: 'center' }}>
          <FieldLabel>{t('scheduleDetailsTitle')}</FieldLabel>
          {schedulerIsInterviewer ? (
            <>
              <FOHSpaceSmall />
              <FOHTouchableOpacity
                accessibilityRole="link"
                onPress={() =>
                  navigateTo(`${EMPLOYER_SETTINGS_MENU}${SCHEDULE}`)
                }
              >
                <FOHOpenIcon style={{ width: 16, height: 16 }} />
              </FOHTouchableOpacity>
            </>
          ) : (
            <></>
          )}
        </FOHView>
        <FOHSpace />
        <FOHView style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
          <FOHView style={{ width: '57%' }}>
            <FieldLabel
              style={{
                color: FOHColors.GRAYSCALE_600,
                fontFamily: FOHFonts.MEDIUM,
                fontWeight: 600
              }}
            >
              {t('daysAndTimesLabel')}
            </FieldLabel>
            <FOHSpaceSmall />
            <FOHView>
              {length(interviewScheduleRanges) > 0 ? (
                <>
                  {interviewScheduleRanges.map((range, keyIndex) => (
                    <SchedulesLabel
                      style={{
                        textTransform: 'capitalize',
                        textWrap: 'balance'
                      }}
                      key={keyIndex}
                    >
                      {range}
                    </SchedulesLabel>
                  ))}
                </>
              ) : (
                <></>
              )}
            </FOHView>
          </FOHView>
          <FOHSpaceSmall />
          <FOHView
            style={{
              width: '43%',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'flex-start'
            }}
          >
            <FOHView>
              <FieldLabel
                style={{
                  color: FOHColors.GRAYSCALE_600,
                  fontFamily: FOHFonts.MEDIUM,
                  fontWeight: 600
                }}
              >
                {t('durationLabel')}
              </FieldLabel>
              <FOHSpaceSmall />
              <SchedulesLabel
                style={{ textTransform: 'none' }}
              >{`${selectedLength} mins`}</SchedulesLabel>
            </FOHView>
            <FOHSpaceMassive />
            <FOHView>
              <FieldLabel
                style={{
                  color: FOHColors.GRAYSCALE_600,
                  fontFamily: FOHFonts.MEDIUM,
                  fontWeight: 600
                }}
              >
                {t('howFarOutLabel')}
              </FieldLabel>
              <FOHSpaceSmall />
              <SchedulesLabel
                style={{ textTransform: 'none' }}
              >{`${interviewWindow} weeks`}</SchedulesLabel>
            </FOHView>
          </FOHView>
        </FOHView>
      </FOHView>
      <FOHSpace />
      <FOHMessageInputSection
        testID={'message-templates-section'}
        t={t}
        open={open}
        setOpen={setOpen}
        label={label}
        editor={editor}
        template={template}
        setTemplate={setTemplate}
        onEditTemplate={() => {
          resetForm();
          navigateTo(TEMPLATES);
        }}
        search={search}
        setSearch={setSearch}
        filteredTemplates={filteredTemplates}
        resetForm={resetForm}
        handleOnUseTemplate={handleOnUseTemplate}
        placeholder={getTemplatePlaceHolders(
          t,
          INTERVIEW_REQUEST_SEND_SCHEDULE
        )}
        placeHolderError={placeHolderError}
        inputMessageContent={inputMessageContent}
        setInputMessageContent={setInputMessageContent}
      />
    </ActionModal>
  );
};

export const ShareScheduleModals = props => {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const { open, handle, employerCandidate, selectedLocationFilters } = props;
  const { navigateTo } = useNavigation();

  const candidateQuery = useGetCandidateQuery({
    skip: !handle,
    variables: { handle }
  });

  const profile = path(['data', 'profile'], candidateQuery);

  const name = path(['data', 'profile', 'user', 'firstName'], candidateQuery)
    ? `${path(['data', 'profile', 'user', 'firstName'], candidateQuery)} ${path(
        ['data', 'profile', 'user', 'lastName'],
        candidateQuery
      )}`
    : '';

  const { activeStageId, positionFilters, locationFilters } =
    useCandidateFeedContext();

  const { sendInterviewRequest } = useCandidateRequestInterview();

  const onPressSendSchedule = async (
    position,
    location,
    messageContent = null,
    interviewAttendees,
    interviewType = null,
    phoneNumber = null,
    meetingUrl = null
  ) => {
    await sendInterviewRequest({
      userId: path(['user', 'id'], profile),
      position,
      location,
      messageContent,
      interviewAttendees,
      interviewType,
      phoneNumber,
      meetingUrl
    });
    // move candidate but dont set the next candidate in the detail view
    if (activeStageId !== props.interviewStage.id) {
      await props.moveEmployerCandidates({
        employerCandidateIds: [prop('id', employerCandidate)],
        newStage: prop('id', props.interviewStage),
        all: false,
        oldStage: activeStageId,
        positions: positionFilters,
        locations: locationFilters,
        search: ''
      });

      props.stagesQuery.refetch();
      await candidateQuery.refetch();
    }

    props.setNextCandidate &&
      props.setNextCandidate(prop('id', props.interviewStage));
    navigateTo({
      pathname:
        props.pathname && props.pathname.includes(FEED)
          ? FEED
          : `${CANDIDATE_PROFILE}/${handle}`
    });
  };

  return (
    <>
      <ShareScheduleFormModal
        employerCandidate={employerCandidate}
        open={open}
        handle={handle}
        name={name}
        profile={profile}
        close={() => {
          navigateTo({
            pathname:
              props.pathname && props.pathname.includes(FEED)
                ? FEED
                : `${CANDIDATE_PROFILE}/${handle}`
          });
        }}
        selectedLocationFilters={selectedLocationFilters}
        onSubmitSchedulePress={onPressSendSchedule}
      />
    </>
  );
};

const FieldLabel = styled(FOHLabel)`
  font-family: ${FOHFonts.MEDIUM};
  line-height: 16px;
  text-transform: uppercase;
  color: ${FOHColors.PRIMARY_TEAL_P1};
`;

const SchedulesLabel = styled(FOHSmallDetailLabel)`
  font-family: ${FOHFonts.REGULAR};
  line-height: 24px;
  font-size: 16px;
  text-transform: uppercase;
`;
