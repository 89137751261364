import React from 'react';

import { useDisableBackgroundScroll, useIsMobile } from '../../utils';
import { FOHReactiveModal } from '../../components';

import { useSidebarNav } from './useSidebar';

export interface WithReactiveModalProps {
  open: boolean;
  history?: any;
  close?: VoidFunction | undefined;
  style?: any;
  children?: React.ReactNode;
}

export const withReactiveModal =
  <Tprops extends object>(Cmp: React.FC<WithReactiveModalProps & Tprops>) =>
  (props: WithReactiveModalProps & Tprops) => {
    const { isMobile } = useIsMobile();

    const { contentShift } = useSidebarNav(props);

    useDisableBackgroundScroll([props.open]);

    return (
      <FOHReactiveModal
        open={props.open}
        leftContentShift={contentShift}
        dialog={!isMobile}
        close={props.close}
        large={isMobile}
        style={{
          ...props.style,
          width: '100%'
        }}
      >
        <Cmp {...props} isModal={true} dialog={!isMobile}>
          {props.children}
        </Cmp>
      </FOHReactiveModal>
    );
  };
