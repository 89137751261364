import React, { useMemo } from 'react';

import { defaultTo, length, prop } from 'ramda';

import {
  FOHView,
  FOHHeaderH3,
  FOHSpace,
  FOHSpaceSmall,
  FOHCheckbox,
  FOHSmallDetailLabel,
  FOHDivider,
  FOHFetchIcon,
  FOHInverseButton,
  FOHColors,
  FOHTouchableOpacity,
  FOHLabel,
  FOHFonts
} from '../../ingredients';
import { FOHEmployerStagesType } from '../../typings';

import {
  FOHBorderlessSelect,
  FOHDropdownSelectOption,
  FOHSearchBar,
  FOHSearchBarProps,
  FOHSelectItem,
  FOHTabList,
  TabListItemProps
} from '..';

interface FOHCandidateInboxListBlockProps extends FOHSearchBarProps {
  applicationsLabel: string;
  applicationsNumber: number;
  sortByLabel: string;
  selectAllLabel: string;
  sortOptions?: Array<FOHDropdownSelectOption>;
  sortSelectedValue?: string;
  isSelectAllSelected: boolean;
  onSortValueChange?: (value: string) => void;
  onPressSelectAll?: () => void;
  children?: any;
  filterList?: Array<TabListItemProps>;
  onFilterChange?: (value: string) => void;
  activeFilter?: string;
  onRefreshButtonPress?: () => void;
  refreshButtonProps: any;
  refreshButtonLabel: string;
  currentStage: any;
}

export const FOHCandidateInboxListBlock = (
  props: FOHCandidateInboxListBlockProps
) => {
  const hasPassiveCandidatesStage = useMemo(
    () =>
      defaultTo(
        false,
        props.currentStage &&
          prop('stageType', props.currentStage) ===
            FOHEmployerStagesType.CANDIDATES
      ),
    [props.currentStage]
  );

  return (
    <FOHView style={{ alignItems: 'flex-start', height: '100%' }}>
      <FOHView
        style={{
          alignItems: 'flex-start',
          paddingHorizontal: 16,
          paddingTop: 16,
          width: '100%'
        }}
      >
        <FOHSearchBar {...props} />
        <FOHSpace />
        <FOHView
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            flexWrap: 'wrap'
          }}
        >
          <FOHView>
            <FOHHeaderH3>
              {`${props.applicationsLabel} (${prop(
                'applicationsNumber',
                props
              )})`}
            </FOHHeaderH3>
          </FOHView>
          <FOHSpace />
          {hasPassiveCandidatesStage ? (
            <>
              {prop('sortOptions', props) &&
                length(prop('sortOptions', props)!) > 0 && (
                  <FOHView
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      paddingBottom: 4,
                      right: -12
                    }}
                  >
                    <FOHLabel
                      style={{
                        fontFamily: FOHFonts.BOLD,
                        color: FOHColors.GRAYSCALE_700,
                        fontSize: 14,
                        lineHeight: 20,
                        fontWeight: 700
                      }}
                    >
                      {prop('sortByLabel', props)}
                    </FOHLabel>
                    <FOHView style={{ maxWidth: 300 }}>
                      <FOHBorderlessSelect
                        selectedValue={prop('sortSelectedValue', props)}
                        onValueChange={prop('onSortValueChange', props)!}
                        small={true}
                        style={{
                          fontSize: 14,
                          lineHeight: 20,
                          color: FOHColors.GRAYSCALE_700,
                          textAlign: 'center'
                        }}
                      >
                        {prop('sortOptions', props)?.map((option, keyIndex) => {
                          return (
                            <FOHSelectItem
                              key={`${keyIndex}${option.value}`}
                              {...option}
                            />
                          );
                        }) || []}
                      </FOHBorderlessSelect>
                    </FOHView>
                  </FOHView>
                )}
            </>
          ) : (
            <></>
          )}
          {props.onRefreshButtonPress ? (
            <FOHInverseButton
              title={props.refreshButtonLabel}
              icon={() =>
                FOHFetchIcon({
                  active: true,
                  style: { tintColor: FOHColors.GRAYSCALE_900 }
                })
              }
              style={{ maxWidth: 200 }}
              onPress={props.onRefreshButtonPress}
              color={FOHColors.BACKGROUND_DARK_GRAY}
              textColor={'#000000'}
              canSelect={true}
              selected={true}
              {...props.refreshButtonProps}
            />
          ) : (
            <></>
          )}
        </FOHView>
        <FOHSpace />
        {!!props.filterList &&
        !!props.activeFilter &&
        !!props.onFilterChange ? (
          <>
            <FOHView style={{ width: '100%' }}>
              <FOHTabList
                tabs={props.filterList}
                selectedTab={props.activeFilter}
                onPressTab={props.onFilterChange}
                style={{
                  paddingTop: 0,
                  paddingLeft: 0,
                  paddingRight: 0
                }}
                tabStyle={{
                  minWidth: 80,
                  width: '100%',
                  backgroundColor: 'transparent'
                }}
              />
            </FOHView>
            <FOHSpace />
          </>
        ) : (
          <></>
        )}
        <FOHView
          style={{
            flexDirection: 'row',
            justifyContent: prop('onPressSelectAll', props)
              ? 'space-between'
              : 'flex-end',
            width: '100%'
          }}
        >
          {prop('onPressSelectAll', props) && (
            <FOHView
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                paddingBottom: 8
              }}
            >
              <FOHTouchableOpacity
                onPress={e => {
                  e.preventDefault();
                  if (prop('onPressSelectAll', props)) {
                    prop('onPressSelectAll', props)!();
                  }
                }}
              >
                <FOHCheckbox selected={prop('isSelectAllSelected', props)} />
              </FOHTouchableOpacity>
              <FOHSpaceSmall />
              <FOHSmallDetailLabel>
                {prop('selectAllLabel', props)}
              </FOHSmallDetailLabel>
            </FOHView>
          )}
          {!hasPassiveCandidatesStage &&
            prop('sortOptions', props) &&
            length(prop('sortOptions', props)!) > 0 && (
              <FOHView
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  paddingBottom: 4,
                  right: -12
                }}
              >
                <FOHLabel
                  style={{
                    fontFamily: FOHFonts.BOLD,
                    color: FOHColors.GRAYSCALE_700,
                    fontSize: 14,
                    lineHeight: 20,
                    fontWeight: 700
                  }}
                >
                  {prop('sortByLabel', props)}
                </FOHLabel>
                <FOHView style={{ maxWidth: 300 }}>
                  <FOHBorderlessSelect
                    selectedValue={prop('sortSelectedValue', props)}
                    onValueChange={prop('onSortValueChange', props)!}
                    small={true}
                    style={{
                      fontSize: 14,
                      lineHeight: 20,
                      color: FOHColors.GRAYSCALE_700,
                      textAlign: 'center'
                    }}
                  >
                    {prop('sortOptions', props)?.map((option, keyIndex) => {
                      return (
                        <FOHSelectItem
                          key={`${keyIndex}${option.value}`}
                          {...option}
                        />
                      );
                    }) || []}
                  </FOHBorderlessSelect>
                </FOHView>
              </FOHView>
            )}
        </FOHView>
      </FOHView>

      <FOHDivider />
      <>{props.children}</>
    </FOHView>
  );
};
