import { Text } from 'react-native';
import styled from 'styled-components/native';

import { FOHFonts } from './FOHFonts';

export const FOHTagText = styled(Text)`
  font-family: ${FOHFonts.MEDIUM}
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  align-items: center;
  color: #171717;
`;
