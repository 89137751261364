import React from 'react';

import {
  FOHContactMeIcon,
  FOHEditMarker,
  FOHLabel,
  FOHMoreInfoBadge,
  FOHSpaceSmall,
  FOHSpace,
  FOHCopyIcon,
  FullWidthCard,
  FOHHighlightText,
  FOHLabelBold,
  FOHSmallDetailLabel,
  FOHSpaceSmallest,
  FOHDivider,
  FOHColors,
  FOHView,
  FOHTouchableOpacity
} from '../../ingredients';

export interface FOHContactCardProps {
  email?: string;
  emailPlaceholder: string;
  phone?: string;
  phonePlaceholder: string;
  contactInformationLabel: string;
  contactHelpText: string;
  onEditContactCardPress?: () => void;
  onCopyEmail?: () => void;
  onCopyPhone?: () => void;
  style?: any;
  contactFieldsWrapperStyle?: any;
  contactFieldsWrapperRef?: any;
  showInfoLabelOnly?: boolean;
  hasWhiteBackground?: boolean;
  copyLabel?: string;
}

export const FOHContactCard = (props: FOHContactCardProps) => (
  <FullWidthCard
    noBorder={props.hasWhiteBackground}
    style={{
      justifyContent: 'space-between',
      padding: props.hasWhiteBackground ? 0 : 16,
      ...props.style
    }}
  >
    <FOHView
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        zIndex: 10
      }}
    >
      <FOHView
        style={{
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <FOHContactMeIcon style={{ tintColor: FOHColors.GRAYSCALE_G1 }} />
        <FOHSpaceSmall />
        <FOHLabelBold
          style={{
            fontSize: 18,
            lineHeight: 28
          }}
        >
          {props.contactInformationLabel}
        </FOHLabelBold>
        <FOHSpaceSmall />
        <FOHSpaceSmallest />
        <FOHMoreInfoBadge toolTipText={props.contactHelpText} offset={70} />
      </FOHView>
      {props.onEditContactCardPress ? (
        <FOHTouchableOpacity
          onPress={props.onEditContactCardPress}
          testID={`edit-contact-card`}
        >
          <FOHEditMarker />
        </FOHTouchableOpacity>
      ) : (
        <></>
      )}
    </FOHView>
    <FOHSpace />
    <FullWidthCard
      noBorder={!props.hasWhiteBackground}
      style={{ padding: props.hasWhiteBackground ? 16 : 0 }}
    >
      {!!props.phone && !!props.email ? (
        <FOHView
          style={{
            ...props.contactFieldsWrapperStyle,
            flexDirection: 'row',
            flexWrap: 'wrap'
          }}
        >
          <FOHView
            style={{
              zIndex: 9,
              flexDirection: 'row',
              justifyContent: 'space-between',
              flex: 1,
              minWidth: 260,
              maxWidth: 426,
              paddingHorizontal: 8
            }}
          >
            <FOHView style={{ width: '100%' }}>
              <FOHSmallDetailLabel
                style={{
                  marginTop: 6,
                  marginLeft: 0,
                  zIndex: 2,
                  fontSize: 16,
                  lineHeight: 24
                }}
                color={FOHColors.GRAYSCALE_500}
              >
                {props.emailPlaceholder}
              </FOHSmallDetailLabel>
              <FOHSpaceSmallest />
              <FOHLabel
                numberOfLines={1}
                ellipsizeMode="tail"
                style={{
                  maxWidth: '90%',
                  paddingBottom: 2,
                  color: FOHColors.GRAYSCALE_700,
                  lineHeight: 24,
                  fontWeight: '400'
                }}
              >
                {props.email}
              </FOHLabel>
              <FOHSpaceSmall />
              <FOHDivider />
            </FOHView>
            {props.onCopyEmail ? (
              <FOHTouchableOpacity
                onPress={props.onCopyEmail}
                style={{ right: props.copyLabel ? 56 : 24, paddingTop: 34 }}
              >
                <FOHView style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <FOHCopyIcon
                    style={{
                      width: 16,
                      height: 18,
                      marginRight: 4,
                      tintColor: FOHColors.PRIMARY_TEAL_P1
                    }}
                  />
                  {props.copyLabel ? (
                    <FOHLabelBold
                      style={{
                        color: FOHColors.PRIMARY_TEAL_P1,
                        fontSize: 16,
                        textDecorationLine: 'underline',
                        flex: 1
                      }}
                    >
                      {props.copyLabel}
                    </FOHLabelBold>
                  ) : (
                    <></>
                  )}
                </FOHView>
              </FOHTouchableOpacity>
            ) : (
              <></>
            )}
          </FOHView>
          <FOHView
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              flex: 1,
              minWidth: 260,
              maxWidth: 426,
              paddingHorizontal: 8
            }}
            ref={props.contactFieldsWrapperRef}
          >
            <FOHView style={{ width: '100%' }}>
              <FOHSmallDetailLabel
                style={{
                  marginTop: 6,
                  marginLeft: 0,
                  zIndex: 2,
                  fontSize: 16,
                  lineHeight: 24
                }}
                color={FOHColors.GRAYSCALE_500}
              >
                {props.phonePlaceholder}
              </FOHSmallDetailLabel>
              <FOHSpaceSmallest />
              <FOHLabel
                numberOfLines={1}
                ellipsizeMode="tail"
                style={{
                  maxWidth: '90%',
                  paddingBottom: 2,
                  color: FOHColors.GRAYSCALE_700,
                  lineHeight: 24,
                  fontWeight: '400'
                }}
              >
                {props.phone}
              </FOHLabel>
              <FOHSpaceSmall />
              <FOHDivider />
            </FOHView>
            {props.onCopyPhone ? (
              <FOHTouchableOpacity
                onPress={props.onCopyPhone}
                style={{ right: props.copyLabel ? 56 : 24, paddingTop: 34 }}
              >
                <FOHView style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <FOHCopyIcon
                    style={{
                      width: 16,
                      height: 18,
                      marginRight: 4,
                      tintColor: FOHColors.PRIMARY_TEAL_P1
                    }}
                  />
                  {props.copyLabel ? (
                    <FOHLabelBold
                      style={{
                        color: FOHColors.PRIMARY_TEAL_P1,
                        fontSize: 16,
                        textDecorationLine: 'underline',
                        flex: 1
                      }}
                    >
                      {props.copyLabel}
                    </FOHLabelBold>
                  ) : (
                    <></>
                  )}
                </FOHView>
              </FOHTouchableOpacity>
            ) : (
              <></>
            )}
          </FOHView>
        </FOHView>
      ) : (
        <FOHView>
          <FOHHighlightText
            labelCmp={FOHLabel}
            emphasizedWord={
              'accepts an interview, applies to a position, or follows a location.'
            }
            highlightColor={'#000000'}
            boldLabelCmp={FOHLabelBold}
          >
            {props.contactHelpText}
          </FOHHighlightText>
        </FOHView>
      )}
    </FullWidthCard>
  </FullWidthCard>
);
