import React from 'react';

import group_gray from '../../../images/group_gray.svg';

import { FOHImage } from '../../ingredients';

export const FOHTeamIcon = (props: any) => (
  <FOHImage
    source={{ uri: group_gray }}
    style={
      props && !!props.style
        ? { width: 24, height: 24, ...props.style }
        : { width: 24, height: 24 }
    }
  />
);
