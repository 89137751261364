import React from 'react';

import { defaultTo, prop } from 'ramda';

import icon from '../../../images/candidate_offer.png';

import { FOHImage } from '..';

export const FOHOfferIcon = (props: any) => (
  <FOHImage
    source={{ uri: icon }}
    style={{
      width: 78,
      height: 82,
      top: 7,
      ...defaultTo({}, prop('style', props))
    }}
  />
);
