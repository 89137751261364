import React from 'react';

import calender_scheduled from '../../../images/calender_scheduled.svg';

import { FOHImage, FOHColors } from '../../ingredients';

export const FOHBlueCalendarScheduledIcon = (props: any) => (
  <FOHImage
    source={{ uri: calender_scheduled }}
    style={
      props && !!props.style
        ? { width: 20, height: 20, ...props.style }
        : { width: 20, height: 20, tintColor: FOHColors.PACIFIC_BLUE }
    }
  />
);
