import React, { useState, useEffect } from 'react';

import { prop, defaultTo } from 'ramda';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  FOHAuthControlButtons,
  FOHColors,
  FOHReactiveModal,
  FOHFullLogo,
  FOHGoogleLogoIcon,
  FOHView,
  FOHSetPasswordForm
} from '../../components';
import {
  GetEmployerUsersDocument,
  useUpdateUserMutation
} from '../../graphql/generated';
import { FEED } from '../../constants';
import { useIsMobile, handleMutation, browserTimezone } from '../../utils';
import { useNavigation } from '../../utils/navigation';

import { useSidebarNav } from '../Navigation/useSidebar';
import { useGetMeData, useGoogleSSOAuth } from '../SignIn';
import { storeLoginAuth } from '../SignIn/LoginScreen';
import { useCurrentLocationContext } from '../Locations/useCurrentLocationContext';

export const SetPasswordModal = props => {
  const t = prop('t', useTranslation('EmployerAccountFeature'));
  const { navigateTo } = useNavigation();

  const { modal } = useParams();

  const { data: me } = useGetMeData();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [terms, setTerms] = useState(false);
  const [error, setError] = useState(false);

  const [authControlButtonsDisplay, setIsDisplayAuthControlButtons] = useState(
    modal !== 'password'
  );

  const { locationFilter } = useCurrentLocationContext({ props });

  const [userMutation] = useUpdateUserMutation();
  useEffect(() => {
    if (prop('email', me) || prop('firstName', me) || prop('lastName', me)) {
      // do not expose indeed emails to candidate
      !defaultTo('', prop('email', me)).includes('indeedemail') &&
        setEmail(defaultTo('', prop('email', me)));
      setLastName(defaultTo('', prop('lastName', me)));
      setFirstName(defaultTo('', prop('firstName', me)));
    }
  }, [me]);

  const { isMobile } = useIsMobile();
  const { contentShift } = useSidebarNav(props);
  const { initiateGoogleAuth } = useGoogleSSOAuth();

  const handleFormSubmit = async () => {
    const [, errors] = await handleMutation(
      userMutation({
        variables: {
          id: prop('id', me),
          firstName,
          lastName,
          terms,
          password,
          confirmPassword,
          email,
          timezone: browserTimezone
        },
        refetchQueries: [
          {
            query: GetEmployerUsersDocument,
            variables: {
              location: locationFilter
            }
          }
        ]
      })
    );

    if (errors) {
      return setError({
        passwordConfirm: prop('non_field_errors', errors),
        // Global error on email
        email: defaultTo('', prop('message', errors)).includes(
          'Duplicate entry'
        )
          ? 'You already have an account. Please logout and sign in.'
          : prop('email', errors),
        ...errors
      });
    }

    props.close && props.close();

    navigateTo(FEED, {
      state: {
        openWelcome: true
      }
    });
  };

  return (
    <FOHReactiveModal
      leftContentShift={contentShift}
      open={props.open}
      dialog={!isMobile}
    >
      <FOHView style={{ justifyContent: 'center', alignItems: 'center' }}>
        {authControlButtonsDisplay ? (
          <FOHView style={{ width: 400 }}>
            <FOHAuthControlButtons
              icon={() => <FOHFullLogo style={{ width: 167, height: 42 }} />}
              title={t('GoogleSSOFeature:invitedAuthLabel')}
              buttons={[
                {
                  label: t('GoogleSSOFeature:continueWithGoogleBtnLabel'),
                  onClick: () => {
                    localStorage.removeItem('auth');
                    storeLoginAuth();
                    initiateGoogleAuth();
                  },
                  icon: () => (
                    <FOHGoogleLogoIcon
                      style={{
                        height: 22,
                        width: 22,
                        tintColor: FOHColors.WHITE
                      }}
                    />
                  ),
                  opaque: true,
                  selected: true,
                  textColor: FOHColors.WHITE,
                  color: FOHColors.PRIMARY_TEAL_P1
                },
                {
                  label: t('GoogleSSOFeature:continueWithEmailBtnLabel'),
                  onClick: () => setIsDisplayAuthControlButtons(false)
                }
              ]}
            />
          </FOHView>
        ) : (
          <FOHSetPasswordForm
            title={t('setPasswordTitle')}
            detail={t('setPasswordDetail')}
            needsName={true}
            firstName={firstName}
            firstNamePlaceholder="First Name"
            setFirstName={setFirstName}
            lastName={lastName}
            lastNamePlaceholder="Last Name"
            setLastName={setLastName}
            // placeholders are the same
            emailPlaceholder={t('emailPlaceholder')}
            passwordPlaceholder={t('passwordPlaceholder')}
            passwordPlaceholderConfirm={t('confirmPasswordPlaceholder')}
            error={error}
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            passwordConfirm={confirmPassword}
            setPasswordConfirm={setConfirmPassword}
            submitLabel="Submit"
            disabledSubmit={
              !email ||
              !password ||
              !confirmPassword ||
              !terms ||
              !firstName ||
              !lastName
            }
            handleSubmit={handleFormSubmit}
            terms={terms}
            termsText="I have reviewed and accept the"
            onPressTermsLink={() =>
              window.open(
                'https://fohboh-assets-prod.s3.us-east-1.amazonaws.com/terms_agreement.pdf?'
              )
            }
            termsLinkText={t('RegisterFeature:termsAgreement')}
            setTerms={() => setTerms(!terms)}
            hidePassText={t('common:hidePassText')}
            showPassText={t('common:showPassText')}
          />
        )}
      </FOHView>
    </FOHReactiveModal>
  );
};
