export const getMonths = () => {
  const lengthOfMonths = new Array(12).fill(0);
  const months = lengthOfMonths.map((_, i) => {
    const month = String(i + 1);
    return {
      label: month,
      value: month
    };
  });
  return months;
};

export const getYears = () => {
  const d = new Date();
  const currentYear = d.getFullYear();
  const numberOfYears = currentYear - 1979;
  const lengthOfYears = new Array(numberOfYears).fill(0);
  const years = lengthOfYears.map((_, i) => {
    const year = String(currentYear - i);
    return {
      label: year,
      value: year
    };
  });
  return years;
};
