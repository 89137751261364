import React from 'react';

import { withTranslation, useTranslation } from 'react-i18next';
import { compose, path, prop, defaultTo } from 'ramda';

import { useGetCandidateNameQuery } from '../../graphql/generated';
import { FOHInterviewRequestModal } from '../../components';

import { useCurrentLocationContext } from '../Locations/useCurrentLocationContext';
import { usePaymentPlan } from '../Billing/usePaymentPlan';
import { useSidebarNav } from '../Navigation/useSidebar';

import { useRequestInterview } from './useRequestInterview';

const InterviewRequestModal = props => {
  const {
    handle,
    t,
    index,
    openInterviewRequest,
    setOpenInterviewRequest,
    initialPosition,
    search
  } = props;

  const candidateQuery = useGetCandidateNameQuery({
    skip: !handle,
    variables: { handle }
  });

  const candidate = path(['data', 'profile'], candidateQuery);
  const interviewStatus = path(
    ['data', 'profile', 'lastInterview', 'status'],
    candidateQuery
  );

  const { locationFilter } = useCurrentLocationContext({ props });

  const { canChangeHiringFreely } = usePaymentPlan({
    locationFilter,
    currentPlanQ: true
  });

  const {
    sendingIR,
    sendInterviewRequest,
    position,
    selectPosition,
    myPositions
  } = useRequestInterview(
    props,
    initialPosition,
    candidate,
    canChangeHiringFreely
  );

  const irTranslations = prop('t', useTranslation('InterviewRequestFeature'));
  const { contentShift } = useSidebarNav(props);

  return (
    <FOHInterviewRequestModal
      leftContentShift={contentShift}
      dialog={!props.isMobile}
      open={
        openInterviewRequest &&
        !!path(['user', 'firstName'], candidate) &&
        !candidateQuery.loading
      }
      heightOpen={480} // height open for mobile modal
      close={() => setOpenInterviewRequest(false)}
      title={`${t('common:interview.sendInterview')} ${defaultTo(
        '',
        path(['user', 'firstName'], candidate)
      )}`}
      positionPlaceholder={irTranslations('positionPlaceholder')}
      myPositions={defaultTo([], myPositions)}
      cancelText={t('common:cancel')}
      testID={`submit-request-interview-${index}`}
      selectedPosition={position}
      sendingIR={sendingIR}
      selectPosition={value => selectPosition(value)}
      submit={async () => {
        await sendInterviewRequest({
          search: defaultTo('', search),
          incrementCount: interviewStatus !== 'PENDING'
        });
        props.handleInterviewRequestSubmitSuccess();
        setOpenInterviewRequest(false);
      }}
      submitText={sendingIR ? t('actions:sending') : t('actions:submit')}
    />
  );
};

export default compose(withTranslation('CandidateCardFeature'))(
  InterviewRequestModal
);
