import React from 'react';

import { defaultTo, prop } from 'ramda';

import icon from '../../../images/meatball.svg';

import { FOHImage } from '../../ingredients';

export const FOHMoreOptionIcon = (props: any) => (
  <FOHImage
    source={{ uri: icon }}
    style={{ width: 37, height: 37, ...defaultTo({}, prop('style', props)) }}
  />
);
