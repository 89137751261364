import React, { useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { defaultTo, path, prop } from 'ramda';
import { Autocomplete } from '@react-google-maps/api';

import {
  FOHEmployerLocationVerify,
  FOHScrollView,
  FOHView,
  FOHTextField,
  FOHFormFlowButtons
} from '../../components';
import { handleMutation, useIsMobile } from '../../utils';
import {
  GetMyLocationsDashboardDocument,
  GetMyLocationsDocument,
  useMutateCreateLocationMutation,
  useMutateUpdateLocationImageMutation
} from '../../graphql/generated';
import {
  EMPLOYER_DASHBOARD,
  FEED,
  LOCATION_CREATION_SUCCESS_MODAL
} from '../../constants';
import { useNavigation } from '../../utils/navigation';
import { useDismissableModals } from '../../hooks';

import { getAddressInfo, autoCompleteAPIOptions } from './constants';

const AddNewLocation = props => {
  const { t } = useTranslation('ClaimLocationFeature');
  const { navigateTo, location: navLocation } = useNavigation();
  const { isMobile } = useIsMobile();

  const reader = new FileReader();
  const inputFile = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const [originFrom] = useState(
    prop('originFrom', defaultTo(null, navLocation?.state))
  );

  const [companyName, setCompanyName] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [summary, setSummary] = useState('');
  const [image, setImage] = useState('');
  const [optimisticImage, setOptimisticImage] = useState('');
  const [fileToUpload, setFileToUpload] = useState(null);

  const [searchResult, setSearchResult] = useState('');

  const handleLoadScript = autocomplete => setSearchResult(autocomplete);

  const handlePlaceSelect = () => {
    if (searchResult === null) return;

    const selectedPlace = searchResult.getPlace();
    const addressInfo = getAddressInfo(selectedPlace);

    setAddress1(addressInfo.street1);
    setCity(addressInfo.city);
    setState(addressInfo.state);
    setPostalCode(addressInfo.postalCode);
  };

  const [indeedEasyapplyOptout, setIndeedEasyapplyOptout] = useState(false);

  const [createLocation] = useMutateCreateLocationMutation();
  const [mutateLocationImage] = useMutateUpdateLocationImageMutation();

  const { dismissableModals } = useDismissableModals();

  reader.onload = e => {
    const blob = e.target.result;
    setImage(blob);
    setOptimisticImage(blob);
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    const variables = {
      name: companyName,
      summary,
      addressStreet1: address1,
      addressStreet2: address2,
      addressCity: city,
      addressState: state,
      addressPostalCode: postalCode,
      indeedEasyapplyOptoutDefault: indeedEasyapplyOptout
    };

    const [locationResponse] = await handleMutation(
      createLocation({
        variables,
        refetchQueries: [
          {
            query: GetMyLocationsDocument
          },
          {
            query: GetMyLocationsDocument,
            variables: {
              search: ''
            }
          },
          {
            query: GetMyLocationsDashboardDocument,
            variables: {
              first: 5,
              after: '',
              search: '',
              sortBy: 'alpha'
            }
          }
        ]
      })
    );

    if (fileToUpload) {
      const imageVariables = {
        fileName: fileToUpload.name,
        fileType: fileToUpload.type
      };

      const [res] = await handleMutation(
        mutateLocationImage({
          variables: {
            locationId: locationResponse?.mutateLocation?.location?.id,
            ...imageVariables
          }
        })
      );

      // Upload image
      await fetch(path(['mutateLocationImage', 'imageUploadUrl'], res), {
        method: 'PUT',
        body: fileToUpload
      });
    }
    setIsLoading(false);
    await (async () => {
      let redirectTo =
        originFrom && originFrom === EMPLOYER_DASHBOARD
          ? EMPLOYER_DASHBOARD
          : FEED;

      let queryParams;

      if (
        dismissableModals.includes(LOCATION_CREATION_SUCCESS_MODAL) &&
        originFrom
      ) {
        queryParams = {
          search: `?modal=${LOCATION_CREATION_SUCCESS_MODAL}`
        };
      }

      const navStates = {
        location: {
          id: locationResponse?.mutateLocation?.location?.id,
          name: locationResponse?.mutateLocation?.location?.name
        },
        origin: originFrom ?? undefined
      };

      navigateTo(
        {
          pathname: redirectTo,
          ...(queryParams || {})
        },
        {
          ...((queryParams && { state: navStates }) || {})
        }
      );
    })();
  };

  return (
    <>
      <FOHScrollView
        style={{
          maxHeight: isMobile ? '100vh' : undefined,
          alignItmes: 'center',
          paddingBottom: 25
        }}
      >
        <FOHEmployerLocationVerify
          title={t('title')}
          description={t('description')}
          uploadImageLabel={t('uploadImageLabel')}
          companyNamePlaceholder={t('companyNamePlaceholder')}
          street1Placeholder={t('street1Placeholder')}
          street2Placeholder={t('street2Placeholder')}
          cityPlaceholder={t('cityPlaceholder')}
          statePlaceholder={t('statePlaceholder')}
          postalCodePlaceholder={t('postalCodePlaceholder')}
          summaryPlaceholder={t('summaryPlaceholder')}
          largeHeader={true}
          loading={false}
          image={isLoading ? optimisticImage || image : image}
          pickPhoto={() => {
            inputFile && inputFile.current.click();
          }}
          vertical={isMobile}
          companyName={companyName}
          setCompanyName={setCompanyName}
          street1={address1}
          setStreet1={setAddress1}
          street2={address2}
          setStreet2={setAddress2}
          city={city}
          setCity={setCity}
          state={state}
          setState={setState}
          postalCode={postalCode}
          setPostalCode={setPostalCode}
          summary={summary}
          setSummary={setSummary}
          indeedEasyapplyOptoutLabel={t(
            'EditLocationBannerFeature:indeedEasyapplyOptoutLabel'
          )}
          indeedEasyapplyOptout={indeedEasyapplyOptout}
          setIndeedEasyapplyOptout={setIndeedEasyapplyOptout}
          autoCompleteInput={
            <Autocomplete
              onPlaceChanged={handlePlaceSelect}
              onLoad={handleLoadScript}
              options={autoCompleteAPIOptions}
            >
              <FOHTextField
                placeholder={t('street1Placeholder')}
                value={address1 || ''}
                required={true}
                autoCompleteType={'street-address'}
                onChangeText={value => setAddress1(value)}
              />
            </Autocomplete>
          }
        />
        <FOHView
          style={{
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <FOHView
            style={{
              width: '100%',
              maxWidth: isMobile ? '100%' : 601,
              paddingHorizontal: isMobile ? 15 : 0
            }}
          >
            <FOHFormFlowButtons
              {...props}
              isNextDisabled={
                isLoading ||
                !city ||
                !address1 ||
                !companyName ||
                !state ||
                !postalCode
              }
              nextButtonlabel={
                isLoading ? t('common:loading') : t('PositionsFeature:doneText')
              }
              onPressNextButton={handleSubmit}
              previousButtonLabel={t('cancelText')}
              onPressPreviousButton={() => navigateTo(FEED)}
            />
          </FOHView>
        </FOHView>
      </FOHScrollView>
      <input
        type="file"
        id="file"
        accept="image/jpeg,image/png, image/jpg"
        onChange={e => {
          const file = e.target.files[0];
          file && setFileToUpload(file);
          file && reader.readAsDataURL(file);
        }}
        ref={inputFile}
        style={{ display: 'none' }}
      />
    </>
  );
};

export default AddNewLocation;
