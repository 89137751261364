import { Text } from 'react-native';
import styled from 'styled-components/native';

import { FOHColors } from '../colors/FOHColors';

import { FOHFonts } from './FOHFonts';

export const FOHAltSectionLabel = styled(Text)`
  font-family: ${FOHFonts.MEDIUM};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
  color: ${FOHColors.NARO_GREY};
`;
