import React from 'react';

import { useIsMobile } from '../../utils';

import {
  FOHLogo,
  FOHColors,
  FOHLabel,
  FOHView,
  Screen,
  FOHInverseButton,
  FOHSpaceLarge,
  FOHSpaceLarger,
  FOHTextButton,
  FOHFonts,
  FOHSpace
} from '../ingredients';

interface FOHGoogleAuthErrorHandlerProps {
  label: string;
  sublabel: string;
  errorMessage: string;
  button1Label: string;
  button2Label: string;
  button3Label?: string;
  onButton1Press: () => void;
  onButton2Press: () => void;
  onButton3Press?: () => void;
}

const FOHGoogleAuthErrorHandler = (props: FOHGoogleAuthErrorHandlerProps) => {
  const { isMobile } = useIsMobile();

  return (
    <Screen
      style={{
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingTop: isMobile ? 60 : 120
      }}
    >
      <FOHView
        style={{
          maxWidth: 728,
          width: '100%',
          alignItems: 'flex-start',
          paddingHorizontal: 62,
          paddingVertical: 49
        }}
      >
        <FOHView
          style={{
            alignItems: 'center',
            width: '100%'
          }}
        >
          <FOHLogo style={{ width: 32, height: 48 }} />
        </FOHView>
        <FOHSpaceLarger />
        <FOHView>
          <FOHLabel bold={true} style={{ fontSize: 24, lineHeight: 40 }}>
            {props.label}
          </FOHLabel>
          <FOHLabel>{props.sublabel}</FOHLabel>
          <FOHLabel>{props.errorMessage}</FOHLabel>
        </FOHView>
        <FOHSpaceLarger />
        <FOHView
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          <FOHView style={{ maxWidth: '47%', width: '100%' }}>
            <FOHInverseButton
              style={{
                flex: 1
              }}
              opaque
              selected
              title={props.button1Label}
              onPress={props.onButton1Press}
              textColor={FOHColors.WHITE}
              color={FOHColors.PRIMARY_TEAL_P1}
            />
          </FOHView>
          <FOHSpaceLarge />
          <FOHView style={{ maxWidth: '47%', width: '100%' }}>
            <FOHInverseButton
              style={{
                flex: 1
              }}
              title={props.button2Label}
              onPress={props.onButton2Press}
            />
          </FOHView>
        </FOHView>
        {props?.button3Label && props?.onButton3Press ? (
          <FOHView style={{ width: '100%' }}>
            <FOHSpace />
            <FOHTextButton
              title={props.button3Label}
              underline
              style={{
                fontWeight: 500,
                fontFamily: FOHFonts.MEDIUM
              }}
              onPress={props?.onButton3Press}
            />
          </FOHView>
        ) : null}
      </FOHView>
    </Screen>
  );
};

export default FOHGoogleAuthErrorHandler;
