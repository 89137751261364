import React from 'react';

import { FOHInverseButton, FOHColors, FOHView } from '../../ingredients';
import {
  FOHStatsCard,
  FOHUserStatsCardProps,
  FOHWorkHistoryTable,
  FOHWorkHistoryTableProps,
  FOHAvailabilityProfileTableProps,
  FOHRecommendationCellProps,
  FOHWorkPreferencesTable,
  FOHWorkPreferencesTableProps,
  FOHContactCard,
  FOHContactCardProps,
  FOHAvailabilityProfileTable
} from '../../recipes';

import { FOHResumeBlock, FOHResumeBlockProps } from './FOHResumeBlock';
export interface FOHProfileDetailBlockProps
  extends FOHUserStatsCardProps,
    FOHResumeBlockProps,
    FOHWorkHistoryTableProps,
    FOHAvailabilityProfileTableProps,
    FOHWorkPreferencesTableProps,
    FOHContactCardProps {
  children: any;
  vertical?: boolean;
  statsCTA?: string;
  statsCTAIcon?: any;
  clickedStatsCTA?: () => any;
  recommendationsLabel?: string;
  recommendations?: Array<FOHRecommendationCellProps>;
  isContactInfoAtBottom?: boolean;
  backgroundColor?: string;
  contactInfoRef?: any;
}

export const FOHProfileDetailBlock = (props: FOHProfileDetailBlockProps) => (
  <FOHView
    style={{
      flexDirection: 'column',
      backgroundColor: props.backgroundColor || '#fafafa'
    }}
  >
    <FOHView
      style={{
        flexDirection: props.vertical ? 'column' : 'row',
        width: '100%',
        backgroundColor: props.backgroundColor || '#fafafa',
        paddingLeft: props.vertical ? 0 : 20,
        paddingRight: props.vertical ? 0 : 20,
        paddingTop: props.vertical ? 0 : 16,
        flex: 1
      }}
    >
      <FOHView
        style={{
          flex: 3,
          alignItems: 'center',
          paddingRight: props.vertical ? 0 : 24
        }}
      >
        <FOHWorkHistoryTable
          openWorkHistoryEdit={props.openWorkHistoryEdit}
          workHistoryLabel={props.workHistoryLabel}
          workHistory={props.workHistory}
          emptyWorkHistory={props.emptyWorkHistory}
          addWorkHistoryLabel={props.addWorkHistoryLabel}
          emptyWorkHistoryHeader={props.emptyWorkHistoryHeader}
          emptyWorkHistoryDetail={props.emptyWorkHistoryDetail}
          askForWorkHistoryBtnLabel={props.askForWorkHistoryBtnLabel}
          onPressAskForWorkHistory={props.onPressAskForWorkHistory}
          emptyWorkHistoryImage={props.emptyWorkHistoryImage}
          experienceLabel={props.experienceLabel}
          yearsExp={props.yearsExp}
          openExperienceEdit={props.openExperienceEdit}
        />
        {props.children}
        <FOHView ref={props.contactInfoRef} style={{ width: '100%' }}>
          <FOHContactCard
            {...props}
            style={{
              marginTop: 45
            }}
            hasWhiteBackground={props.backgroundColor === FOHColors.WHITE}
            showInfoLabelOnly={!props.email || !props.phone}
            contactFieldsWrapperStyle={{
              flexDirection: 'row'
            }}
          />
        </FOHView>
        {props.onOpenEditAvailability ? (
          <FOHAvailabilityProfileTable
            maxWidth={'100%'}
            onOpenEditAvailability={props.onOpenEditAvailability}
            availabilityLabel={props.availabilityLabel}
            availability={props.availability}
          />
        ) : (
          <></>
        )}
      </FOHView>
      <FOHView style={{ flex: 2 }}>
        {props.clickedStatsCTA && props.statsCTA ? (
          <FOHInverseButton
            title={props.statsCTA}
            icon={props.statsCTAIcon}
            onPress={props.clickedStatsCTA}
            textColor={FOHColors.GRAYSCALE_700}
            style={{
              width: '100%',
              maxWidth: 600,
              marginBottom: 24
            }}
          />
        ) : (
          <></>
        )}

        {props.onPressResumeCTA ? (
          <FOHView
            style={{
              alignItems: 'center'
            }}
          >
            <FOHResumeBlock
              resumeCtaText={props.resumeCtaText} // update || preview resume
              onPressResumeCTA={props.onPressResumeCTA} /// upload || open tab
              resumeUpdatedAt={props.resumeUpdatedAt}
              resumeName={props.resumeName}
              resume={props.resume}
              resumeLabel={props.resumeLabel} // my resume
              resumeViewer={props.resumeViewer}
            />
          </FOHView>
        ) : (
          <></>
        )}

        <FOHView style={{ paddingTop: 24 }}>
          <FOHStatsCard maxWidth={'100%'} {...props} />
        </FOHView>
        <FOHWorkPreferencesTable
          {...props}
          workPreferencesTableTitle={props.workPreferencesTableTitle}
          employmentPreferencesLabel={props.employmentPreferencesLabel}
          employmentPreferences={props.employmentPreferences}
          benefitsLabel={props.benefitsLabel}
          benefitsValue={props.benefitsValue}
          dressCodeLabel={props.dressCodeLabel}
          dressCodeValue={props.dressCodeValue}
        />
      </FOHView>
    </FOHView>
  </FOHView>
);
