import { useEffect, useState } from 'react';

import { path, length } from 'ramda';

import { unwrapEdgesAt } from '../../utils';
import { useMyPositionsQuery } from '../../graphql/generated';

import { useMyLocationsData } from '../Locations';

export const useSelectPositionForSelectedLocationState = (
  { profile, locationId, positionId, locationIds } = {
    profile: {
      lastInterview: undefined,
      lastPositionApplication: undefined
    },
    locationId: '',
    positionId: '',
    locationIds: []
  }
) => {
  // GET_CANDIDATE_QUERY
  const lastInterview = path(['lastInterview'], profile);
  const lastPositionApplication = path(['lastPositionApplication'], profile);

  const lastPositionId = lastInterview
    ? path(['lastInterview', 'position', 'id'], profile)
    : lastPositionApplication
    ? path(['lastPositionApplication', 'position', 'id'], profile)
    : undefined;

  const lastLocationId = lastInterview
    ? path(['lastInterview', 'location', 'id'], profile)
    : lastPositionApplication
    ? path(['lastPositionApplication', 'position', 'location', 'id'], profile)
    : undefined;

  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedPosition, setSelectedPosition] = useState('');
  const [locationSearch, setLocationSearch] = useState('');
  const [positionSearch, setPositionSearch] = useState('');

  const { data: myLocations } = useMyLocationsData({ locationSearch });

  const myPositionsQuery = useMyPositionsQuery({
    variables: {
      search: positionSearch,
      locations:
        locationIds && length(locationIds) > 0 ? locationIds : undefined,
      location:
        !locationIds || length(locationIds) === 0 ? selectedLocation : undefined
    }
  });

  const myPositions = unwrapEdgesAt(
    ['data', 'myPositions', 'edges'],
    myPositionsQuery
  );

  const paidPositions = myPositions.filter(
    pos =>
      path(['paymentStatus'], pos) === 'SUBSCRIBED' ||
      path(['location', 'paymentStatus'], pos) === 'SUBSCRIBED'
  );

  const indexablePositions = myPositions.filter(pos => pos.indexable);

  useEffect(() => {
    if (
      lastPositionId &&
      lastLocationId &&
      !selectedLocation &&
      !selectedPosition
    ) {
      setSelectedLocation(lastLocationId);
      setSelectedPosition(lastPositionId);
    } else if (!selectedLocation && myLocations.length > 0) {
      setSelectedLocation(path(['0', 'id'], myLocations));
    }
  }, [
    myLocations,
    myPositions,
    lastLocationId,
    lastPositionId,
    selectedLocation,
    selectedPosition,
    paidPositions
  ]);

  useEffect(() => {
    setSelectedLocation(locationId);
    setSelectedPosition(positionId);
  }, [positionId, locationId]);

  return {
    selectedLocation,
    myLocations,
    setSelectedLocation,
    selectedPosition,
    myPositions,
    paidPositions,
    setSelectedPosition,
    locationSearch,
    setLocationSearch,
    positionSearch,
    setPositionSearch,
    indexablePositions
  };
};
