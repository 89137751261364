import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  FOHAuthControlButtons,
  FOHFullLogo,
  FOHGoogleLogoIcon,
  FOHColors,
  FOHView,
  Screen
} from '../../components';
import { GROUPS, REGISTER_URL } from '../../constants';
import {
  getSessionStorageItem,
  removeSessionStorageItem,
  setSessionStorageItem,
  getTermsUrl,
  useIsMobile
} from '../../utils';
import { useNavigation } from '../../utils/navigation';

import { useGoogleSSOAuth } from '../SignIn';
import { AUTH_DETAIL_STATE } from '../SignIn/useGoogleSSOAuth';

export const storeRegisterAuth = (selectedGroup: GROUPS) =>
  setSessionStorageItem(
    AUTH_DETAIL_STATE,
    {
      group: selectedGroup,
      auth_type: 'register'
    },
    true
  );

const GroupSpecificSignUp = () => {
  const { t, i18n } = useTranslation('GoogleSSOFeature');

  const { navigateTo, location } = useNavigation();
  const { state } = location;

  const { isMobile } = useIsMobile();

  const { initiateGoogleAuth } = useGoogleSSOAuth();

  const authDetail = getSessionStorageItem(AUTH_DETAIL_STATE) || undefined;

  const { group } = useParams<{
    group: string;
  }>();

  useEffect(() => {
    const selectedGroup: GROUPS =
      group === 'employer' ? GROUPS.employers : GROUPS.candidates;
    if (authDetail && authDetail?.auth_type === 'login') {
      removeSessionStorageItem(AUTH_DETAIL_STATE);
      storeRegisterAuth(selectedGroup);
    } else {
      storeRegisterAuth(selectedGroup);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FOHView
      style={{
        width: '100%',
        alignItems: 'center',
        paddingTop: 40,
        height: '100vh',
        paddingHorizontal: isMobile ? 15 : 0
      }}
    >
      <Screen
        style={{
          justifyContent: 'flex-start',
          alignItems: 'center',
          paddingTop: isMobile ? 60 : 80
        }}
      >
        <FOHAuthControlButtons
          icon={() => <FOHFullLogo style={{ width: 167, height: 42 }} />}
          title={t('signupLabel')}
          buttons={[
            {
              label: t('continueWithGoogleBtnLabel'),
              onClick: () => initiateGoogleAuth(),
              icon: () => (
                <FOHGoogleLogoIcon
                  style={{ height: 22, width: 22, tintColor: FOHColors.WHITE }}
                />
              ),
              opaque: true,
              selected: true,
              textColor: FOHColors.WHITE,
              color: FOHColors.PRIMARY_TEAL_P1
            },
            {
              label: t('continueWithEmailBtnLabel'),
              onClick: () => navigateTo(REGISTER_URL, { state })
            }
          ]}
          termsText={t('termsText')}
          termsLinkText={t('termsHighlightLinkText')}
          termsLinkToNavigate={i18n && getTermsUrl(i18n.language)}
        />
      </Screen>
    </FOHView>
  );
};

export default GroupSpecificSignUp;
