import { DateTime } from 'luxon';

export const setTimeOnDate = (date, timeString, timeZone = 'UTC') => {
  const [hours] = timeString.split(':');
  const minutes = timeString.split(':')[1].split(' ')[0];
  const isAm = timeString.split(' ')[1] === 'AM';
  const newDateTime = DateTime.fromObject({
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
    hour: isAm || hours === '12' ? parseInt(hours) : parseInt(hours) + 12,
    minute: parseInt(minutes),
    zone: timeZone
  });

  return newDateTime.toISO();
};
