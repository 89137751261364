import React from 'react';

import { useTranslation } from 'react-i18next';
import { compose, path, prop, defaultTo } from 'ramda';
import { useParams } from 'react-router-dom';

import { withIsMobile, unwrapEdgesAt, toDate } from '../../utils';
import decoration from '../../images/welcome_letter.svg';
import { useGetEmployerDocumentQuery } from '../../graphql/generated';
import {
  FOHHighlightText,
  FOHColors,
  FOHView,
  FOHFixedHeightScrollView,
  FOHHeaderH1,
  FOHCandidateWelcomeLetter
} from '../../components';

const CandidateWelcomeLetter = props => {
  const params = useParams();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { global_emp_doc_id } = params;
  const { t } = useTranslation('Welcome');

  const empDocumentQuery = useGetEmployerDocumentQuery({
    variables: { id: global_emp_doc_id }
  });

  const empDocument = defaultTo(
    {},
    path(['data', 'getEmployerDocument'], empDocumentQuery)
  );

  const locationAddress = [
    path(['position', 'location', 'addressStreet1'], empDocument),
    path(['position', 'location', 'addressCity'], empDocument),
    path(['position', 'location', 'addressState'], empDocument) +
      ' ' +
      path(['position', 'location', 'addressPostalCode'], empDocument)
  ].join(', ');

  const startDate = toDate(prop('startingAt', empDocument), 'LLL dd, yyyy');

  const startTime = toDate(prop('startingAt', empDocument), 'h:mm a');

  const files = unwrapEdgesAt(
    0,
    defaultTo([], path(['employmentdocumentfileSet', 'edges'], empDocument))
  );

  const attachedFiles = files
    .filter(file => file.name)
    .map(edge => {
      return {
        ...{
          fileName: prop('name', edge),
          size: `${prop('size', edge)}kb`,
          download: true,
          attachment: prop('attachment', edge)
        }
      };
    });

  const attachedLinks = files
    .filter(file => file.url)
    .map(edge => prop('url', edge));

  return (
    <FOHView
      style={{
        width: '100%',
        height: '100vh',
        alignItems: 'center',
        paddingTop: 60,
        backgroundColor: '#E5E5E5'
      }}
    >
      <FOHView
        style={{
          width: '100%'
        }}
      >
        <FOHFixedHeightScrollView
          isMobile={props.isMobile}
          heightToDecrease={100}
        >
          <FOHView
            style={{
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <FOHView
              style={{
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                paddingHorizontal: 15,
                paddingVertical: 20
              }}
            >
              <FOHHighlightText
                labelCmp={FOHHeaderH1}
                emphasizedWord="Team!"
                highlightColor={FOHColors.PACIFIC_BLUE}
                boldLabelCmp={FOHHeaderH1}
              >
                {props.welcomeTeamLabel}
              </FOHHighlightText>
            </FOHView>
            <FOHCandidateWelcomeLetter
              isMobile={props.isMobile}
              image={decoration}
              positionLabel={t('common:position')}
              position={path(['position', 'positionName'], empDocument)}
              locationLabel={t('common:location')}
              locationImage={path(
                ['position', 'location', 'image'],
                empDocument
              )}
              locationName={path(['position', 'location', 'name'], empDocument)}
              locationAddress={locationAddress}
              startDateLabel={t('OffersFeature:startDateLabel')}
              startDate={startDate}
              startTimeLabel={t('OffersFeature:startTimeLabel')}
              startTime={startTime}
              contactPersonLabel={t('contactPersonLabel')}
              contactPersonName={prop('contactName', empDocument)}
              contactPersonNumber={prop('contactPhone', empDocument)}
              customNoteLabel={t('hiringMessageLabel')}
              customNote={prop('note', empDocument)}
              hiringManagerProfile={path(
                ['position', 'createdBy', 'profileImage'],
                empDocument
              )}
              hiringManagerLabel={t('hiringManagerLabel')}
              hiringManagerName={path(
                ['position', 'createdBy', 'firstName'],
                empDocument
              )}
              attachedFileLabel={t('attachedFileLabel')}
              attachedFiles={attachedFiles}
              attachedLinkLabel={t('attachedLinkLabel')}
              attachedLinks={attachedLinks}
            />
          </FOHView>
        </FOHFixedHeightScrollView>
      </FOHView>
    </FOHView>
  );
};

export default compose(withIsMobile)(CandidateWelcomeLetter);
