import React from 'react';

import {
  FOHSmallDetailLabel,
  FullWidthCard,
  FOHHeaderH1,
  FOHHeaderH2,
  FOHSpace,
  FOHView,
  FOHImage
} from '../../ingredients';

export interface FOHExpiredJobBlockProps {
  title: string;
  detailLabel: string;
  notFoundImage: string;
  mobile?: boolean;
}

export const FOHExpiredJobBlock = (props: FOHExpiredJobBlockProps) => {
  const Header = props.mobile ? FOHHeaderH2 : FOHHeaderH1;

  return (
    <FullWidthCard style={{ padding: 24 }}>
      <FOHView
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          flex: 1
        }}
      >
        <FOHView style={{ flex: 1 }}>
          <Header
            style={{
              textAlign: 'left'
            }}
          >
            {props.title}
          </Header>
          <FOHSpace />
          <FOHSmallDetailLabel>{props.detailLabel}</FOHSmallDetailLabel>
          <FOHSpace />
          <FOHSpace />
        </FOHView>
        <FOHView style={{ flex: 1, alignItems: 'flex-end' }}>
          <FOHImage
            source={{ uri: props.notFoundImage }}
            style={{
              width: props.mobile ? 107 : 185,
              height: props.mobile ? 80 : 138
            }}
          />
          <FOHSpace />
          <FOHSpace />
        </FOHView>
      </FOHView>
    </FullWidthCard>
  );
};
