import React from 'react';

import { ImageStyle, StyleProp } from 'react-native';

import play from '../../../images/white_play.svg';

import { FOHImage } from '../../ingredients';

export const FOHWhitePlayIcon = ({
  style
}: {
  style?: StyleProp<ImageStyle>;
}) => <FOHImage source={{ uri: play }} style={style} />;
