import React from 'react';

import styled from 'styled-components/native';
import { View } from 'react-native';

import { FOHHeaderText, FOHTouchableOpacity } from '../../ingredients';

export interface FOHSideMenuCellProps {
  label: string;
  active?: boolean;
  onPress: () => void;
}

export const FOHSideMenuCell = (props: FOHSideMenuCellProps) => (
  <FOHTouchableOpacity onPress={props.onPress}>
    <Border style={{ backgroundColor: props.active ? '#F7F7F7' : '#FFFFFF' }}>
      <CellText>{props.label}</CellText>
    </Border>
  </FOHTouchableOpacity>
);

const Border = styled(View)`
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: -1px;
  background-color: #ffffff;
`;

const CellText = styled(FOHHeaderText)`
  font-size: 18px;
  text-align: left;
`;
