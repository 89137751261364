import { defaultTo, prop } from 'ramda';

import { useGetFilteredEmployerMessageTemplatesQuery } from '../../graphql/generated';

interface UseFilteredEmployerMessageTemplatesOptions {
  isSkipped: boolean;
  searchText: string;
}

export const useFilteredEmployerMessageTemplates = (
  options: UseFilteredEmployerMessageTemplatesOptions
): {
  data: any[];
  loading: boolean;
  error?: Error;
  refetch: () => void;
} => {
  const { isSkipped, searchText } = options;

  const { data, loading, error, refetch } =
    useGetFilteredEmployerMessageTemplatesQuery({
      skip: isSkipped,
      variables: { search: searchText, isSimpleOnly: true }
    });

  const employerMessageTemplates = defaultTo(
    null,
    prop('employerMessageTemplates', data) as any
  );

  const templates = defaultTo([], employerMessageTemplates?.edges).map(
    (edge: any) => {
      return { ...edge.node };
    }
  );

  return { data: templates, loading, error, refetch };
};
