import React, { useEffect, useMemo, useState } from 'react';

import { path, defaultTo, prop } from 'ramda';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  FOHCircleLoader,
  FOHSpace,
  FOHSpaceLarger,
  Screen,
  FOHView,
  FOHBannerType,
  FOHHeaderH2,
  FOHHeaderH1,
  FOHInterviewConfirmationChat,
  FOHInterviewMeetingType,
  FOHTopThreeLocationsList
} from '../../components';
import {
  useGetInterviewMessagingQuery,
  useGetInterviewScheduleQuery,
  useSearchLocationsFeedLazyQuery
} from '../../graphql/generated';
import {
  unwrapEdges,
  useIsMobile,
  formatFollowerCountLabel,
  toDateTime
} from '../../utils';
import {
  CANDIDATE_ONBOARDING_1,
  HOME_PAGE,
  RESTAURANT_JOBS
} from '../../constants';
import { useNavigation } from '../../utils/navigation';

import { SignInModal, useGetMeData } from '../SignIn';
import { QPOSITIONTYPES, QPZIP } from '../Search';
import { LogoLoader } from '../Loading/LogoLoader';
import { useBanner } from '../Navigation/useBanner';
import { useDirectMessage } from '../Chat/useDirectMessage';

const CandidateAddToCalendarScreen = props => {
  const { isMobile } = useIsMobile();
  const { t } = useTranslation('InterviewFeature');

  const { navigateTo } = useNavigation();

  const { uuid } = useParams();

  const { setBanner } = useBanner();

  const meQuery = useGetMeData();

  const isCandidateLoggedIn = useMemo(() => {
    return !!path(['data', 'candidateProfile', 'id'], meQuery);
  }, [meQuery]);

  const [startDate] = useState(new Date());
  const [endDate] = useState(
    new Date(startDate.getTime() + 1000 * 60 * 60 * 24 * 30)
  );

  const interviewRequestQuery = useGetInterviewScheduleQuery({
    skip: !uuid || !isCandidateLoggedIn,
    variables: {
      uuid,
      endDate: startDate.toISOString(),
      startDate: endDate.toISOString()
    }
  });

  const positions = path(
    ['data', 'interview', 'position', 'positionType'],
    interviewRequestQuery
  );

  const zipCode = path(
    ['data', 'candidateProfile', 'addressPostalCode'],
    meQuery
  );

  const interview = path(['data', 'interview'], interviewRequestQuery);
  const position = path(['position'], interview);
  const location = path(['location'], interview);

  const { goToDirectMessageWith, refreshSBUser } = useDirectMessage({
    ...props,
    sendbirdAccessToken: path(['data', 'sendbirdAccessToken'], meQuery)
  });

  const interviewMessaging = useGetInterviewMessagingQuery({
    variables: {
      location: prop('id', location),
      slug: prop('slug', location)
    },
    skip: !prop('id', location)
  });

  const [searchLocations, { data: locationsData, loading: locationsLoading }] =
    useSearchLocationsFeedLazyQuery();

  useEffect(() => {
    if (positions && zipCode && location) {
      const positionTypes = positions
        .filter(item => item !== null && item !== undefined)
        .map(item => item);

      searchLocations({
        variables: {
          positionTypes,
          zipCode,
          first: 3,
          after: prop('id', location) // don't show current location
        }
      });
    }
  }, [positions, zipCode, location]);

  const locations = unwrapEdges(path(['locations', 'edges'], locationsData));

  const meetingType = prop('interviewType', interview);

  const mustSignIn =
    (!path(['data', 'id'], meQuery) && !meQuery?.loading) ||
    (!interviewRequestQuery.loading && !prop('id', interview));

  const sbtoken = path(['data', 'sendbirdAccessToken'], meQuery);

  useEffect(() => {
    if (isCandidateLoggedIn && !sbtoken) {
      // cannot await in an effect
      refreshSBUser();
    }
  }, [refreshSBUser, sbtoken]);

  const interviewConfirmString = !prop('scheduledFor', interview)
    ? t('youAcceptedTheInterview', { locationName: prop('name', location) })
    : t('youScheduledAnInterview', { locationName: prop('name', location) });
  return (
    <>
      {!mustSignIn && !interviewRequestQuery.loading ? (
        <Screen
          style={{
            alignItems: 'center',
            paddingTop: '60px',
            backgroundColor: '#F5F5F5',
            minHeight: '100vh'
          }}
        >
          <FOHView
            style={{
              maxWidth: 800,
              width: '100%',
              padding: isMobile ? '8px' : 0
            }}
          >
            {isMobile ? (
              <FOHHeaderH2>{interviewConfirmString}</FOHHeaderH2>
            ) : (
              <FOHHeaderH1>{interviewConfirmString}</FOHHeaderH1>
            )}
            <FOHSpaceLarger />
            <FOHInterviewConfirmationChat
              onPressSeeMessages={async () => {
                await goToDirectMessageWith({
                  uuids: [path(['createdBy', 'uuid'], interview)],
                  location: prop('id', location),
                  position: prop('id', position),
                  interview: prop('id', interview)
                });
              }}
              seeMessagesLabel={t('seeMessages')}
              nextStepsLabel={t('hiringManagerLeftYouANote', {
                locationName: prop('name', location),
                interviewerName: path(['createdBy', 'firstName'], interview)
              })}
              scheduledLabel="SCHEDULED"
              hiringManagerLabel={t('hiringManager')}
              hiringManagerPhoto={path(
                ['createdBy', 'profileImage'],
                interview
              )}
              hiringManagerEmail={path(['createdBy', 'email'], interview)}
              onPressHiringManagerEmail={email => {
                const hasClipboard =
                  navigator &&
                  navigator.clipboard &&
                  navigator.clipboard.writeText;
                hasClipboard && navigator.clipboard.writeText(email);
                hasClipboard &&
                  setBanner({
                    bannerType: FOHBannerType.SUCCESS,
                    bannerMessage: t('ProfileFeature:copiedToClipboard')
                  });
              }}
              message={t('interviewDetailsSentToEmail')}
              noSchedule={!prop('scheduledFor', interview)}
              noScheduleLabel={t('noScheduleSetForEmployer')}
              headerLabel={
                prop('scheduledFor', interview)
                  ? toDateTime(prop('scheduledFor', interview))
                  : ''
              }
              addLabel={t('addToCalendarLabel')}
              onAddPress={() => {
                if (window) {
                  window.open(prop('ics', interview));
                }
              }}
              id={prop('id', position)}
              name={prop('name', location)}
              image={prop('image', location)}
              formattedAddress={prop('formattedAddress', location)}
              followerCountLabel={formatFollowerCountLabel({
                followerCount: prop('followerCount', location),
                t
              })}
              tags={prop('tags', location)}
              summary={prop('summary', location)}
              acceptLabel={t('acceptLabel')}
              declineLabel={t('declineLabel')}
              positionLabel={position ? t('positionLabel') : ''}
              positionName={position ? prop('positionName', position) : ''}
              interviewLengthLabel={t('interviewLengthLabel')}
              interviewLength={t(prop('duration', interview))}
              locationLabel={
                meetingType === FOHInterviewMeetingType.PHONE
                  ? t('phoneMeetingLabelLong')
                  : meetingType === FOHInterviewMeetingType.REMOTE
                  ? t('remoteMeetingLabelLong')
                  : t('locationLabelLong')
              } // zoom, location, or phone
              locationName={prop('formattedAddress', location)}
              interviewMeetingType={meetingType}
              interviewMeetingTypeLabel={
                meetingType === FOHInterviewMeetingType.PHONE
                  ? t('phoneMeetingLabel')
                  : meetingType === FOHInterviewMeetingType.REMOTE
                  ? t('remoteMeetingLabel')
                  : t('locationMeetingLabel')
              }
              whereLabel={t('whereLabel')} //zoom, or phone
              where={
                meetingType === FOHInterviewMeetingType.PHONE
                  ? prop('phoneNumber', interview)
                  : meetingType === FOHInterviewMeetingType.REMOTE
                  ? prop('meetingUrl', interview)
                  : prop('formattedAddress', location)
              } // if not in person
              interviewAcceptedMessaging={defaultTo(
                '',
                path(
                  ['data', 'interviewMessaging', 'interviewAcceptedMessaging'],
                  interviewMessaging
                )
              )}
              furtherProcessLable={t('furtherProcessLabel')}
              onPressFurtherProcess={
                path(
                  ['data', 'interviewMessaging', 'furtherProcessUrl'],
                  interviewMessaging
                )
                  ? () => {
                      window &&
                        window.open(
                          path(
                            ['data', 'interviewMessaging', 'furtherProcessUrl'],
                            interviewMessaging
                          )
                        );
                    }
                  : undefined
              }
            />
            {(meQuery?.loading && interviewRequestQuery.data) ||
            locationsLoading ? (
              <FOHCircleLoader />
            ) : (
              <FOHTopThreeLocationsList
                locations={locations}
                tableTitle={t('exploreSimilarEmployers')}
                onPressLocation={slug => {
                  navigateTo({
                    pathname: `${RESTAURANT_JOBS}/${slug}`,
                    search: `?${QPOSITIONTYPES}=${defaultTo(
                      [],
                      prop('positionType', position)
                    ).join(',')}&${QPZIP}=${zipCode}`
                  });
                }}
                ctaText={t('exploreEmployersNowLabel')}
                onPressCTR={() => navigateTo(HOME_PAGE)}
                mobile={isMobile}
              />
            )}
          </FOHView>
          <FOHSpaceLarger />
          <FOHSpace />
        </Screen>
      ) : (
        <Screen
          style={{
            alignItems: 'center',
            paddingTop: '60px',
            backgroundColor: '#F5F5F5',
            minHeight: '100vh'
          }}
        >
          <LogoLoader />
        </Screen>
      )}
      <SignInModal
        {...props}
        openSignIn={mustSignIn}
        setOpenSignIn={() => {}}
        handleSignUp={() => navigateTo(CANDIDATE_ONBOARDING_1)}
        onSignIn={async () => {
          meQuery?.refetch();
          await interviewRequestQuery.refetch();
        }}
        error={{ alert: t('signInError') }}
      />
    </>
  );
};

export default CandidateAddToCalendarScreen;
