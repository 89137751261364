import React from 'react';

import {
  FOHLabelBold,
  FOHSpace,
  FOHSpaceLarger,
  FOHLeftArrow,
  FOHRightArrow,
  FOHView,
  FOHTouchableOpacity
} from '../../ingredients';

import {
  FOHDayScheduleProps,
  FOHDayScheduleCell
} from '../cells/FOHDayScheduleCell';

export interface FOHPaginatedSchedulePickerProps {
  days: Array<FOHDayScheduleProps>;
  nextWeekLabel: string;
  selectedDay: string;
  setSelectedDay: (id: string) => void;
  previousWeekLabel: string;
  nextWeek: () => void;
  previousWeek: () => void;
  mobile?: boolean;
}

export const FOHPaginatedSchedulePicker = (
  props: FOHPaginatedSchedulePickerProps
) => (
  <FOHView
    style={{
      flexDirection: props.mobile ? 'column' : 'row',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap'
    }}
  >
    <FOHView
      style={{
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'flex-end'
      }}
    >
      <PrevWeek {...props} />
      {props.mobile && (
        <>
          <FOHSpaceLarger /> <NextWeek {...props} />
        </>
      )}
    </FOHView>
    <FOHView style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
      <FOHSpace />
      {props.days.map((day: any, index: number) => (
        <FOHView key={index} style={{ padding: 8 }}>
          <FOHDayScheduleCell
            {...day}
            selected={props.selectedDay === day.id}
            onPress={props.setSelectedDay}
          />
        </FOHView>
      ))}
      <FOHSpace />
    </FOHView>
    {!props.mobile && (
      <FOHView
        style={{
          flexDirection: 'row',
          flex: 1,
          justifyContent: 'flex-start'
        }}
      >
        <NextWeek {...props} />
      </FOHView>
    )}
  </FOHView>
);

const PrevWeek = (props: FOHPaginatedSchedulePickerProps) => (
  <FOHTouchableOpacity
    style={{
      flexDirection: 'row',
      alignItems: 'center'
    }}
    onPress={props.previousWeek}
  >
    <FOHLeftArrow />
    <FOHSpace />
    <FOHLabelBold>{props.previousWeekLabel}</FOHLabelBold>
  </FOHTouchableOpacity>
);

const NextWeek = (props: FOHPaginatedSchedulePickerProps) => (
  <FOHTouchableOpacity
    onPress={props.nextWeek}
    style={{ flexDirection: 'row', alignItems: 'center' }}
  >
    <FOHLabelBold>{props.nextWeekLabel}</FOHLabelBold>
    <FOHSpace />
    <FOHRightArrow />
  </FOHTouchableOpacity>
);
