import React from 'react';

import { prop, path } from 'ramda';
import { useLocalStorage } from 'use-hooks';

import { toRelativeTime } from '../../utils';
import { useGetChannelQuery } from '../../graphql/generated';
import { SENDBIRD_CHAT } from '../../constants';
import { FOHChannelPreview } from '../../components';
import { useNavigation } from '../../utils/navigation';

import { needsLinksHidden, obfuscateLinks } from './utils';

const ChannelPreview = props => {
  const { channel, isCandidate, selectChannel, selectedChannels } = props;

  const { navigateTo } = useNavigation();

  const [isArchiveButtonVisible] = useLocalStorage(
    'is-chat-archive-button-visible-flag',
    false
  );

  const formattedLastReadMessageTime = toRelativeTime(
    new Date(prop('myLastRead', channel)).toISOString()
  );
  const channelDetailsQuery = useGetChannelQuery({
    skip: !isCandidate,
    variables: { sendbirdChannelId: prop('url', channel) }
  });
  const restaurantName =
    path(['data', 'channel', 'location', 'name'], channelDetailsQuery) ||
    path(['data', 'channel', 'lastLocation', 'name'], channelDetailsQuery);
  const restaurantImage =
    path(['data', 'channel', 'location', 'image'], channelDetailsQuery) ||
    path(['data', 'channel', 'lastLocation', 'image'], channelDetailsQuery);
  const candidateMember = channelDetailsQuery.loading
    ? []
    : prop('members', channel).filter(
        member => member.metaData.is_candidate === 'True'
      );

  return (
    <FOHChannelPreview
      onChannelPress={() =>
        navigateTo(`${SENDBIRD_CHAT}/${prop('url', channel)}`)
      }
      isActive={prop('url', channel) === props.currentChannelUrl}
      members={candidateMember}
      restaurantName={isCandidate ? restaurantName : ''}
      restaurantImage={
        isCandidate ? (channelDetailsQuery.loading ? '' : restaurantImage) : ''
      }
      channelUrl={prop('url', channel)}
      sendbirdUserId={props.sendbirdUserId}
      unreadMessageCount={prop('unreadMessageCount', channel)}
      lastMessage={
        path(['lastMessage', 'message'], channel) &&
        needsLinksHidden(path(['lastMessage', 'message'], channel), isCandidate)
          ? {
              ...prop('lastMessage', channel),
              message: obfuscateLinks(path(['lastMessage', 'message'], channel))
            }
          : prop('lastMessage', channel)
      }
      formattedLastReadMessageTime={formattedLastReadMessageTime}
      isChannelSelected={selectedChannels.includes(channel.url)}
      onChannelCheckboxPress={
        isArchiveButtonVisible
          ? () => {
              selectChannel(channel.url);
            }
          : undefined
      }
    ></FOHChannelPreview>
  );
};

export default ChannelPreview;
