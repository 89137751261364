import React, { FunctionComponent } from 'react';

interface MainImageMediaProps {
  isVideo?: boolean;
  src: string;
}

export const MainImageMedia: FunctionComponent<MainImageMediaProps> = ({
  isVideo = false,
  src
}) =>
  isVideo ? (
    <video //eslint-disable-line
      src={src}
      style={{
        objectFit: 'cover',
        width: '100%'
      }}
    />
  ) : (
    <img
      alt="CareerPage main media asset"
      src={src}
      style={{ height: '100%', objectFit: 'contain', width: '100%' }}
    />
  );
