import React, { useState } from 'react';

import {
  FullWidthCard,
  FOHSpace,
  FOHSpaceSmall,
  FOHSmallDetailLabel,
  FOHDirectMessageIcon,
  FOHInverseButton,
  FOHDivider,
  FOHLabel,
  FOHDropdownButton,
  FOHTextButton,
  FOHMoneyIcon,
  FOHView
} from '../../ingredients';

import {
  FOHPositionOverview,
  FOHPositionOverviewType
} from '../blocks/FOHPositionOverview';
import {
  FOHHiringManagerOverview,
  FOHHiringManagerProps
} from '../blocks/FOHHiringManagerOverview';

export interface FOHInterviewRequestCellProps
  extends FOHHiringManagerProps,
    FOHPositionOverviewType {
  mobile?: boolean;
  positionId: string;
  positionSlug: string;
  // CTA
  buttonCTALabel: string; // Respond
  disabledCTA?: boolean;

  salaryLabel?: string;
  salaryText?: string;

  acceptNowLabel: string;
  seeFullDetailsLabel: string;
  declineLabel: string;

  decline: () => void;
  seeFullDetail: () => void;
  acceptNow: () => void;

  requestedAt: string;
  requestedLabel: string;

  messageLabel?: string;
  onPressMessage?: () => void;
}

export const FOHInterviewRequestCell = (
  props: FOHInterviewRequestCellProps
) => {
  const [openRespond, setOpenRespond] = useState(false);

  return (
    <FullWidthCard testID={`FOHInterviewRequestCell-${props.positionName}`}>
      <FOHView
        style={{
          flexDirection: props.mobile ? 'column' : 'row',
          flexWrap: 'wrap',
          flex: 1
        }}
      >
        <FOHView style={{ flex: 1, paddingLeft: 8 }}>
          <FOHPositionOverview
            {...props}
            noBorder={true}
            onPressLocation={props.onPressLocation}
            locationImage={props.locationImage || ''}
            postedDateText={''}
            padding={16}
          />
        </FOHView>
        {props.hiringManagerName && !props.mobile && (
          <FOHView
            style={{
              flex: 1,
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: props.mobile ? 'flex-start' : 'flex-end',
              paddingTop: 10,
              paddingBottom: 0,
              flexWrap: 'wrap'
            }}
          >
            {props.hiringManagerPosition && props.hiringManagerName && (
              <FOHHiringManagerOverview {...props} />
            )}
            {props.onPressMessage &&
              !!props.messageLabel &&
              !props.mobile &&
              props.hiringManagerName && (
                <FOHView
                  style={{
                    paddingLeft: 24,
                    minWidth: 250,
                    paddingRight: 24
                  }}
                >
                  <FOHInverseButton
                    icon={FOHDirectMessageIcon}
                    centerIcon={true}
                    color={'#000000'}
                    textColor={'#ffffff'}
                    opaque={true}
                    testID={'on-press-message'}
                    selected={true}
                    onPress={props.onPressMessage}
                    title={props.messageLabel}
                    style={{
                      width: 250,
                      marginBottom: 10,
                      height: 46
                    }}
                  />
                </FOHView>
              )}
          </FOHView>
        )}
      </FOHView>
      {!props.mobile && <FOHDivider />}
      <FOHView style={{ flexDirection: props.mobile ? 'column' : 'row' }}>
        <FOHView
          style={{
            flexDirection: 'row',
            flexWrap: 'wrap',
            paddingLeft: 24,
            paddingTop: props.mobile ? 0 : 12
          }}
        >
          <FOHView
            style={{
              alignItems: 'flex-start',
              paddingTop: 4,
              minWidth: 100
            }}
          >
            <FOHSmallDetailLabel
              style={{ textAlign: 'left', alignItems: 'flex-start' }}
            >
              {props.requestedLabel}
            </FOHSmallDetailLabel>
            <FOHSpaceSmall />
            <FOHLabel
              style={{ maxWidth: props.mobile ? 250 : undefined }}
              numberOfLines={props.mobile ? 3 : 1}
            >
              {props.requestedAt}
            </FOHLabel>
          </FOHView>
          <FOHSpace />
          {!!props.salaryText && (
            <FOHView
              style={{
                alignItems: 'flex-start',
                paddingTop: 4,
                minWidth: 100
              }}
            >
              <FOHSmallDetailLabel
                style={{ textAlign: 'left', alignItems: 'flex-start' }}
              >
                {props.salaryLabel}
              </FOHSmallDetailLabel>
              <FOHSpaceSmall />
              <FOHView style={{ flexDirection: 'row' }}>
                <FOHMoneyIcon />
                <FOHSpaceSmall />
                <FOHLabel>{props.salaryText}</FOHLabel>
              </FOHView>
            </FOHView>
          )}
          <FOHSpace />
        </FOHView>

        {props.mobile &&
          props.hiringManagerPosition &&
          props.hiringManagerName && (
            <FOHView
              style={{
                paddingLeft: 24,
                paddingTop: props.mobile ? 10 : 0
              }}
            >
              <FOHHiringManagerOverview {...props} flexStart={true} />
            </FOHView>
          )}

        <FOHView
          style={{
            flex: 1,
            minWidth: 270,
            alignItems: props.mobile ? 'center' : 'flex-end',
            justifyContent: 'center',
            paddingRight: props.mobile ? 0 : 24,
            paddingTop: 16,
            paddingBottom: props.mobile ? 10 : 20,
            flexDirection: props.mobile ? 'row' : 'column'
          }}
        >
          {props.onPressMessage &&
            !!props.messageLabel &&
            props.mobile &&
            props.hiringManagerName && (
              <FOHInverseButton
                icon={FOHDirectMessageIcon}
                centerIcon={true}
                color={'#000000'}
                textColor={'#ffffff'}
                opaque={true}
                testID={'on-press-message'}
                selected={true}
                onPress={props.onPressMessage}
                title={props.messageLabel}
                style={{
                  width: props.mobile ? '45%' : 250,
                  height: 46
                }}
              />
            )}
          {props.mobile && <FOHSpaceSmall />}
          <FOHView
            style={{
              justifyContent: 'flex-end',
              flex: props.mobile ? undefined : 1,
              width: props.mobile ? '45%' : undefined
            }}
          >
            <FOHDropdownButton
              title={props.buttonCTALabel}
              toggleOpen={() => setOpenRespond(!openRespond)}
              disabled={props.disabledCTA}
              open={openRespond}
              style={{ height: 44, width: props.mobile ? '100%' : 250 }}
              menuTop={-110}
              testID={'FOHInterviewRequestCell-respond-button'}
            >
              <FOHTextButton
                title={props.acceptNowLabel}
                style={{ textDecoration: 'underline' }}
                onPress={props.acceptNow}
                testID={'FOHInterviewRequestCell-accept-now-link'}
              />
              <FOHSpace />
              <FOHTextButton
                title={props.declineLabel}
                onPress={() => {
                  props.decline();
                  setOpenRespond(false);
                }}
                style={{ textDecoration: 'underline' }}
                testID={'FOHInterviewRequestCell-decline-link'}
              />
              <FOHSpace />
              <FOHTextButton
                title={props.seeFullDetailsLabel}
                onPress={props.seeFullDetail}
                style={{ textDecoration: 'underline' }}
                testID={'FOHInterviewRequestCell-see-full-details-link'}
              />
            </FOHDropdownButton>
          </FOHView>
        </FOHView>
      </FOHView>
    </FullWidthCard>
  );
};
