import React, { FunctionComponent } from 'react';

import goldheart from '../../../images/gold_heart.svg';
import heart_outline from '../../../images/heart_outline.svg';
import heart from '../../../images/heart.svg';
import white_heart from '../../../images/white_heart.svg';

import { FOHIconProps } from '../../typings';
import { FOHImage } from '../../ingredients';

export const FOHFavorite: FunctionComponent<
  FOHIconProps & {
    active?: boolean;
    outline?: boolean;
    white?: boolean;
  }
> = ({ active = false, outline = false, white = false, style }) => {
  let uri = heart;
  if (white) uri = white_heart;
  if (active) uri = goldheart;
  if (outline) uri = heart_outline;

  return (
    <FOHImage
      source={{ uri }}
      style={[{ width: 16, height: 14 }, style]}
      resizeMode={'contain'}
    />
  );
};
