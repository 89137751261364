import React from 'react';

import { length } from 'ramda';

import {
  FullWidthCard,
  FOHSpace,
  FOHDetailText,
  FOHSpaceSmall,
  FOHJobsIcon,
  FOHView
} from '../../ingredients';
import { FOHSmallJobPost, FOHJobCellProps, FOHJobCell } from '../../recipes';

export interface FOHOtherJobsCardProps extends FOHJobCellProps {
  title: string;
  jobs: Array<FOHSmallJobPost>;
  noBorder?: boolean;
  style?: any;
}

export const FOHOtherJobsCard = (props: FOHOtherJobsCardProps) => (
  <FullWidthCard
    noBorder={props.noBorder}
    style={{ padding: props.noBorder ? 0 : 24, paddingTop: 16, ...props.style }}
  >
    <FOHView
      style={{
        flexDirection: 'row',
        alignItems: 'center'
      }}
    >
      <FOHJobsIcon />
      <FOHSpaceSmall />
      <FOHDetailText>{props.title}</FOHDetailText>
    </FOHView>
    <FOHSpace />
    {props.jobs.map((job: any, i: number) => (
      <React.Fragment key={`job-cell-${i}`}>
        <FOHJobCell
          {...job}
          onPress={props.onPress}
          a={props.a}
          ctaText={props.ctaText}
          hasBottomDivider={props.noBorder && i !== length(props.jobs) - 1}
        />
        {i !== length(props.jobs) - 1 && <FOHSpace />}
      </React.Fragment>
    ))}
  </FullWidthCard>
);
