export enum FOHColors {
  BACKGROUND_DARK_GRAY = '#DBDBDB',
  BACKGROUND_GRAY = '#f5f5f5',
  BLACK = '#000000',
  BLUE = '#51c2db',
  BORDER_GRAY = '#E8E8E8',
  CYBERSPACE_GREY = '#4F4F4F',
  GOLD = '#F5AD22',
  GRAY = '#cccccc',
  VERY_LIGHT_GRAY = '#dddddd',
  GRAYSCALE_1 = '#eeeeee',
  GRAYSCALE_10 = '#F9FAFB',
  GRAYSCALE_100 = '#F2F4F7',
  GRAYSCALE_200 = '#E4E7EC',
  GRAYSCALE_300 = '#D0D5DD',
  GRAYSCALE_400 = '#98A2B3',
  GRAYSCALE_500 = '#667085',
  GRAYSCALE_600 = '#475467',
  GRAYSCALE_700 = '#344054',
  GRAYSCALE_G1 = '#1D2939',
  GRAYSCALE_900 = '#101828',
  GREEN = '#38a56b',
  GREEN_10 = '#ECFDF3',
  GREEN_100 = '#D1FADF',
  GREEN_200 = '#12B76A',
  GREEN_300 = '#027A48',
  GREEN_400 = '#054F31',
  GREY = '#878787',
  LIGHT_GRAY = '#FCFCFC',
  LIGHT_BLUE = '#E3F5F9',
  LIGHT_GOLD = '#fdf6e2',
  LIGHT_GREEN = '#DFF1E7',
  LIGHT_GREEN_500 = '#02B56A',
  LIGHT_ORANGE = '#FEEDE4',
  LIGHT_RED = '#FBDCE0',
  NARO_GREY = '#171717',
  NAVY = '#035081',
  ORANGE = '#F16110',
  DARK_ORANGE = '#FB7448',
  PACIFIC_BLUE = '#17ABCC',
  PRIMARY_TEAL_10 = '#EBF7FA',
  PRIMARY_TEAL_100 = '#C2E7F0',
  PRIMARY_TEAL_200 = '#80CEE0',
  PRIMARY_TEAL_P1 = '#009DC1',
  PRIMARY_TEAL_300 = '#14687A',
  PRIMARY_TEAL_400 = '#51c1db',
  LIGHT_BLUE_10 = '#D8ECF1',
  RED = '#E5253C',
  RED_10 = '#FEE4E2',
  RED_100 = '#FECDCA',
  RED_200 = '#F97066',
  RED_300 = '#D92D20',
  RED_400 = '#B42318',
  SECONDARY_10 = '#F7F1F2',
  SECONDARY_100 = '#E4D3D5',
  SECONDARY_200 = '#C9A7AB',
  SECONDARY_300 = '#AA767C',
  SECONDARY_400 = '#7F3F46',
  SILVER = '#BDBDBD',
  SILVER_POLISH = '#C6C6C6',
  WHITE = '#FFFFFF',
  YELLOW_10 = '#FFFAEB',
  YELLOW_100 = '#FEF0C7',
  YELLOW_200 = '#FEDF89',
  YELLOW_300 = '#FDB022',
  YELLOW_400 = '#D26200',
  YELLOW_500 = '#F4AC36',
  DARK_BLUE = '#003a9b'
}
