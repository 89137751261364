import React from 'react';

import styled from 'styled-components/native';
import { View } from 'react-native';
import { defaultTo, prop } from 'ramda';

import { FOHLabeledCheckbox } from '../checkbox/FOHLabeledCheckbox';
import { FOHAvailabilitySection } from '../sections/FOHAvailabilitySection';

export interface FOHAvailabilityTableProps {
  sections: Array<FOHSectionProps>;
  selected: Array<string>;
  shifts: Array<Array<FOHShift>>;
  mobile?: boolean;
  selectItem: (value: string, section: string) => void;
  selectAllLabel: string;
  allSelected?: boolean;
  onSelectAll: () => void;
}

export interface FOHSectionProps {
  value: string;
  label: string;
}

export interface FOHShift {
  label: string;
  value: string;
}

export const FOHAvailabilityTable = (props: FOHAvailabilityTableProps) => (
  <TableContainer>
    {!!props.selectAllLabel && (
      <View
        style={{
          position: 'absolute',
          right: 0,
          zIndex: 200
        }}
      >
        <FOHLabeledCheckbox
          selected={defaultTo(false, props.allSelected)}
          checkboxLabel={props.selectAllLabel}
          setSelected={props.onSelectAll}
        />
      </View>
    )}
    {props.sections.map(section => (
      <FOHAvailabilitySection
        key={prop('label', section)}
        singleRow={!props.mobile}
        shifts={props.shifts}
        selected={props.selected}
        section={section.value}
        label={section.label}
        selectItem={props.selectItem}
        allSelected={props.allSelected}
      />
    ))}
  </TableContainer>
);

const TableContainer = styled(View)`
  height: 100%;
`;
