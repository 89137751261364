import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { path, toLower, defaultTo, compose, length } from 'ramda';

import { multiSelectItem } from '../../utils/fieldHelpers';
import {
  CANDIDATE_ACCOUNT_MENU_PATH,
  OPTIONS_INDUSTRY_SELECTION_RADIO
} from '../../constants';
import { useIsMobile, handle } from '../../utils';
import {
  FOHFixedHeightScrollView,
  FOHView,
  FOHButton,
  FOHCircleLoader,
  FOHSpace,
  FOHHotelIcon,
  FOHRestaurantIcon,
  FOHHeaderText,
  FOHMultiSelectSearchForm,
  FOHFormFlowButtons
} from '../../components';
import { MAX_SCREEN_WIDTH } from '../../components/constants';
import { useNavigation } from '../../utils/navigation';

import { withReactiveModal } from '../Navigation/withReactiveModal';

import { useCandidateProfileMutation } from './useCandidateProfileMutation';
import { usePositionTypes } from './usePositionTypes';

const PositionTypeScreen = props => {
  const { navigateTo } = useNavigation();

  const { me, updateCandidate } = useCandidateProfileMutation();
  const { t } = useTranslation('CandidatePositionFeature');
  const { isMobile } = useIsMobile();

  const [selectedItems, setSelectedItems] = useState([]);

  const industries = OPTIONS_INDUSTRY_SELECTION_RADIO.map(industry => ({
    icon: industry.value === 'hotel' ? <FOHHotelIcon /> : <FOHRestaurantIcon />,
    label: t(industry.label),
    value: industry.value
  }));

  const { positionTypes, loadingPositionTypes, getStringForPositionType } =
    usePositionTypes();

  const [error, setError] = useState();
  const [text, setText] = useState('');
  const [selectedIndustry, setSelectedIndustry] = useState('');

  const naiveFilter = item =>
    (toLower(text).includes(toLower(item.label)) ||
      toLower(item.label).includes(toLower(text)) ||
      !text) &&
    ((selectedIndustry === 'hotel' && item.value.includes('hotel_')) ||
      // banquet server is in both industries
      item.value === 'banquet_server' ||
      (selectedIndustry === 'restaurant' && !item.value.includes('hotel_')) ||
      !selectedIndustry);

  useEffect(() => {
    if (path(['candidateProfile', 'positions'], me)) {
      const previousPositions = path(['candidateProfile', 'positions'], me).map(
        position => ({
          value: position,
          label: getStringForPositionType(position)
        })
      );
      setSelectedItems(defaultTo([], previousPositions));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path(['candidateProfile', 'positions'], me)]);

  useEffect(() => {
    try {
      !props.isModal &&
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
    } catch (e) {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setError(length(selectedItems) > 5 ? t('hitMaxLabel') : '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  const handleSubmit = async () => {
    const selectedPositions = selectedItems
      .filter(item => item.value !== null && item.value !== undefined)
      .map(item => item.value);

    const [resp] = await handle(
      updateCandidate({
        variables: {
          positions: selectedPositions
        }
      })
    );

    const err = path(['data', 'mutateCandidate', 'errors'], resp);

    if (resp && !err) {
      if (props.close) return props.close();

      return path(['candidateProfile', 'onboardingCompletedAt'], me)
        ? navigateTo(CANDIDATE_ACCOUNT_MENU_PATH)
        : props.onSubmit && props.onSubmit(resp);
    } else if (err) {
      setError(err);
    }
  };

  return (
    <FOHView
      style={{
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: props.isInSettings ? 60 : 30
      }}
    >
      <FOHView
        style={{
          maxWidth: isMobile ? '100%' : MAX_SCREEN_WIDTH,
          width: '100%'
        }}
      >
        <FOHFixedHeightScrollView isMobile={isMobile} heightToDecrease={140}>
          <FOHView
            style={{
              width: '100%',
              maxWidth: isMobile ? '100%' : MAX_SCREEN_WIDTH
            }}
          >
            <FOHView
              style={{
                maxWidth: 396,
                width: '100%',
                alignSelf: 'center',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <FOHHeaderText
                style={{
                  fontSize: 30,
                  paddingHorizontal: 20,
                  paddingVertical: 10
                }}
              >
                {t('positionTitle')}
              </FOHHeaderText>
            </FOHView>
            {loadingPositionTypes ? (
              <FOHCircleLoader />
            ) : (
              <FOHMultiSelectSearchForm
                mobile={isMobile}
                formTitle={t('positionSubTitle')}
                options={industries}
                selectedIndustry={selectedIndustry}
                setSelectedIndustry={selectedInd =>
                  selectedIndustry === selectedInd
                    ? setSelectedIndustry()
                    : setSelectedIndustry(selectedInd)
                }
                formDetailLabel={t('detailText')}
                onChangeSearch={setText}
                mergeSections={true}
                noFreeText={true}
                searchValue={text}
                searchPlaceholder={t('common:search')}
                truncationLimit={2}
                clearSearch={() => {
                  setText('');
                  setSelectedItems(['']);
                }}
                sections={[
                  {
                    title: 'positions',
                    items: positionTypes.filter(naiveFilter)
                  }
                ]}
                selectedItems={selectedItems}
                error={error}
                selectItem={item => {
                  setText('');
                  return multiSelectItem(item, setSelectedItems, selectedItems);
                }}
                handleSubmit={handleSubmit}
              />
            )}
          </FOHView>
          {props.isModal && (
            <>
              <FOHSpace />
              <FOHSpace />
              <FOHButton
                title={
                  path(['candidateProfile', 'onboardingCompletedAt'], me)
                    ? t('common:save')
                    : t('nextLabel')
                }
                onPress={handleSubmit}
                disabled={!!error}
              />
            </>
          )}
        </FOHFixedHeightScrollView>
        <FOHView
          style={{
            width: '100%',
            maxWidth: isMobile ? '100%' : MAX_SCREEN_WIDTH,
            marginBottom: isMobile ? 0 : 10
          }}
        >
          {!props.isModal && (
            <>
              <FOHSpace />
              <FOHSpace />
              <FOHFormFlowButtons
                isMobile={isMobile}
                nextButtonlabel={
                  path(['candidateProfile', 'onboardingCompletedAt'], me)
                    ? t('common:save')
                    : t('nextLabel')
                }
                onPressNextButton={handleSubmit}
                isNextDisabled={!!error}
              />
            </>
          )}
        </FOHView>
      </FOHView>
    </FOHView>
  );
};

export const PositionTypeModal = compose(withReactiveModal)(PositionTypeScreen);

export default PositionTypeScreen;
