import { CANDIDATE_GROUP } from './groups';
import { SLUGS } from './slugs';

export const LOGIN_URL = '/login';
export const REGISTER_URL = '/register';

export const PASSWORD_RESET_URL = '/password-reset';
export const PASSWORD_RESET_RETURN_URL = '/password-reset-confirm';

export const REGISTER_CANDIDATE_URL = `${REGISTER_URL}?group=${CANDIDATE_GROUP}`;
export const FEED = '/feed';
export const FEED_WITH_SEGMENTS = '/feed/:modal?/:position?';
export const CANDIDATE_ONBOARDING_FORM_URL = `${REGISTER_URL}/candidate`;
export const CANDIDATE_REGISTER_CONFIRMATION_URL = `${CANDIDATE_ONBOARDING_FORM_URL}/confirmation`;
export const CANDIDATE_PROFILE_FORM_URL = `/candidate/profile`;
export const PAGE_NOT_FOUND_URL = '/page-not-found';
export const HOME_URL = '/';
export const POSITIONS_URL = '/positions';
export const LOCATIONS_URL = '/locations';
export const ENDORSEMENTS_URL = '/endorsements';
export const SHARE_URLS_URL = '/share-urls';

export const REGISTER_PATH = '/register';
export const PASSWORD_RESET_PATH = '/password-reset';
export const PASSWORD_RESET_CONFIRM_PATH = '/password-reset-confirm';

// Candidate Routes
export const CANDIDATE_POSITION_PATH = '/candidate/positions';
export const CANDIDATE_ONBOARDING = '/candidate/onboarding';
export const CANDIDATE_ONBOARDING_1 = `${CANDIDATE_ONBOARDING}/1`;
export const CANDIDATE_ONBOARDING_2 = `${CANDIDATE_ONBOARDING}/2`;
export const EDIT = '/edit';
export const CANDIDATE_EDIT_AVAILABILITY = `${EDIT}/availability`;
export const CANDIDATE_EDIT_WORK_HISTORY = `${EDIT}/workhistory`;
export const CANDIDATE_EDIT_DRESS_CODE = `${EDIT}/dresscode`;
export const CANDIDATE_EDIT_BENEFITS = `${EDIT}/benefits`;
export const CANDIDATE_EDIT_TOP_THREE = `${EDIT}/top-three`;
export const CANDIDATE_EDIT_COMMUTE = `${EDIT}/commute`;
export const CANDIDATE_EDIT_SKILLS = `${EDIT}/skills`;
export const CANDIDATE_EDIT_EXPERIENCE = `${EDIT}/experience`;
export const CANDIDATE_EDIT_POSITIONS = `${EDIT}/positions`;
export const CANDIDATE_EDIT_CONTACT = `${EDIT}/contact`;
export const CANDIDATE_EDIT_SUMMARY = `${EDIT}/summary`;
export const CANDIDATE_EDIT_RESTAURANT_TYPES = `${EDIT}/style`;
export const CANDIDATE_EDIT_IMAGE = `${EDIT}/image`;
export const CANDIDATE_EDIT_VIDEO = `${EDIT}/video`;
export const CANDIDATE_EDIT_REFERENCES = `${EDIT}/references`;
export const CANDIDATE_EDIT_RESUME = `${EDIT}/resume`;
export const CANDIDATE_EDIT_CONTACT_INFO = `${EDIT}/contact`;
export const CANDIDATE_AVAILABILITY_PATH = '/candidate/availability';
export const CANDIDATE_COMMUTE_PATH = '/candidate/commute';
export const CANDIDATE_PHOTO_PATH = '/candidate/photo';
export const CANDIDATE_VIDEO_PATH = '/candidate/video';
export const CANDIDATE_WORK_HISTORY_PATH = '/candidate/work-history';
export const CANDIDATE_ADD_EMPLOYMENT_PATH = '/candidate/employment';
export const CANDIDATE_LOCATION_PATH = '/candidate/location';
export const CANDIDATE_PERSONAL_SUMMARY_PATH = '/candidate/summary';
export const CANDIDATE_ACCOUNT_MENU_PATH = '/candidate/account';
export const CANDIDATE_ACCOUNT_DELETE_PATH = '/candidate/account/:modal';
export const CANDIDATE_ACCOUNT_INFO_PATH = '/candidate/account/info';
export const CANDIDATE_ACCOUNT_DELETE = '/candidate/account/delete';
export const CANDIDATE_SKILLS_PATH = '/candidate/skills';
export const CANDIDATE_RESTAURANT_STYLE_PATH = '/candidate/restaurant-style';
export const CANDIDATE_TOP_THREE_PATH = '/candidate/top-three';
export const CANDIDATE_RESUME = '/candidate/resume';
export const CANDIDATE_YEARS_EXPERIENCE_PATH = '/candidate/years';
export const CANDIDATE_REQ_ENDORSE_PATH = `/candidate/endorse`;
export const EMPLOYER_PROFILE_PATH = `/employer/profile`;
export const CANDIDATE_DRESS_CODE_PATH = '/candidate/dresscode';
export const CANDIDATE_BENEFITS_PATH = '/candidate/benefits';
export const CANDIDATE_WORK_PREFERENCES_PATH = '/candidate/work-preferences';
export const CANDIDATE_CONFIRM_EMAIL_PATH = '/candidate/confirm-email';
export const INTERVIEW_DETAIL_PATH = '/interview-detail';
export const INTERVIEW_SCHEDULE_PATH = '/interview-schedule';
export const INTERVIEW_SCHEDULE_PATH_WITH_PARAMS =
  '/interview-schedule/:uuid/token/';
export const INTERVIEW_ADD_TO_CALENDAR_PATH = '/interview-calendar';
export const EMPLOYER_CONFIGURE_INTERVIEW_SCHEDULE_PATH =
  '/employer/configure-schedule';
export const EMPLOYER_EDIT_INTERVIEW_TIME_SLOTS_PATH =
  '/employer/edit-interview-time';
export const CANDIDATE_NOTIFICATION_PREFERENCE_PATH =
  '/candidate/notification-preference';
export const CLAIM_ACCOUNT_PATH = '/claim-account';
export const CANDIDATE_OFFERS = '/offers/:id';
export const EMPLOYER_CANDIDATE_QUESTIONNAIRE = '/questionnaires/:uuid';
export const CANDIDATE_WELCOME_LETTER = '/welcome/:global_emp_doc_id';

export const CAMPAIGN_HOME = '/campaigns';
export const CAMPAIGN_CREATE = `${CAMPAIGN_HOME}/new`;
export const CAMPAIGN_DETAILS = `${CAMPAIGN_HOME}/:campaignId/:modal?`;
// ROUTES V2 FOHComponents - end
// Static assets
// TODO: Fix django static path
export const TERMS_DOCUMENT_URL =
  'https://fohboh-assets-prod.s3.us-east-1.amazonaws.com/terms_agreement.pdf?';
export const TERMS_DOCUMENT_SPANISH_URL =
  'https://fohboh-assets-prod.s3.us-east-1.amazonaws.com/terms_agreement_es.pdf?';

// API only URLs
export const USER_URL = '/users';
export const CURRENT_USER_URL = '/users';
export const PROFILE_VIDEO_URL = '/profile-video';
export const LOCATION_IMAGE_URL = '/location-image';
export const INTERVIEW_URL = '/interviews';
export const CANDIDATE_PROFILE_URL = '/candidate-profiles';
export const CANDIDATE_WORK_HISTORY_URL = '/candidate-work-history';
export const PASSWORD_RESET_REQUEST_URL = '/password/reset/';
export const PASSWORD_RESET_CONFIRM_URL = '/password/reset/confirm/';

export const ADD_NEW_LOCATION = '/locations/new';
export const ADD_NEW_POSITION = '/positions/v2/new';

export const EMPLOYER_SETTINGS_MENU = '/employer/settings';
export const TEMPLATES = '/templates';
export const MESSAGING = '/messaging';
export const INTEGRATIONS = '/integrations';
export const NOTIFICATIONS = '/notifications';
export const SCHEDULE = '/schedule';
export const LOCATION = '/location';
export const PERSONAL_ACCOUNT = '/account';
export const TEAM = '/team';
export const BILLING = '/billing';
export const REJECTIONS = '/rejections';

export const SEND_TO_7SHIFT = '/send-to-7shifts';
export const JOBS = '/jobs';
export const JOBS_WITH_PARAMS = '/jobs/*';
export const MORE_POSITIONS = '/more-positions';
export const ANSWER_ON_QUESTION = '/question-answer';

export const PLAN = '/plan';
export const UPGRADE = '/upgrade';
export const PAYASYOUGO = '/payasyougo';
export const DEMO = '/demo';
export const PRICING = '/pricing';
export const BOOST = '/boost';
export const HIRING = '/hiring';

export const RESTAURANT_JOBS = '/restaurant-jobs';
export const RESTAURANT_JOBS_LOCATION = '/restaurant-jobs/:location';
export const RESTAURANT_JOBS_HIRING_BY_LOCATION = `${RESTAURANT_JOBS}/hiring/:location`;
export const HOME_PAGE = '/home';
export const DISCOVER = '/discover';
export const APPLICATIONS = '/applications';

export const NETWORK = '/network';
export const CANDIDATE_DATABASE_MODAL = `${NETWORK}/:modal`;
export const INVITE_TO_APPLY = '/invite-to-apply';
export const MOVE_CANDIDATE = '/move-candidate';
export const PROFILE = '/profile';
export const BULK_INVITE_CONFIRMATION = '/bulk-invite-confirmation';

export const EMPLOYERS = '/employers';
export const EMPLOYER_DASHBOARD = '/dashboard';
export const EMPLOYER_DASHBOARD_BY_LOCATION = `${EMPLOYER_DASHBOARD}/:locationId?`;
export const EMPLOYER_DASHBOARD_MODAL = `${EMPLOYER_DASHBOARD}/:locationId?/:positionId?/:modal?`;
export const CHATS = '/chats';
export const APPLICANTS = '/applicants';
export const NEW_APPLICANTS = '/new_applicants';
export const MATCHES = '/matches';
export const NEW_MATCHES = '/new_matches';
export const ALL_LOCATION_UPGRADE = `/all${UPGRADE}`;
export const COMPLETE_APPLICATION = '/complete-application';

export const NOT_AUTHORIZED = '/not-authorized';

export const SENDBIRD_CHAT = '/chat';
export const SENDBIRD_CHAT_CHANNEL = `${SENDBIRD_CHAT}/:channel?`;
export const SENDBIRD_CHAT_CHANNEL_MODAL = `${SENDBIRD_CHAT_CHANNEL}/:modal?`;
export const SENDBIRD_CHAT_WITH_PARAMS = '/chat/*';

export const REASON = '/reason';
export const REQUESTS = '/requests';

export const SCREENING_CHOICES = '/screening';
export const SCREENING_QUESTIONS = '/screening-questions';
export const VIDEO_SCREENING_QUESTIONS = '/video-screening-questions';
export const SEND_OFFER = '/send-offer';
export const INTERVIEW_SCHEDULE = '/interview';
export const SEND_SCHEDULE = '/send-schedule';
export const SEND_OR_SET = '/send-or-set';
export const WELCOME = '/welcome';
export const BULK_MESSAGE = '/bulk-message';
export const ADD_REJECT_REASON = '/add-reject-reason';
export const AI_MODAL_TO_MANAGE_POSITIONS = '/ai-manage-positions';

export const EMPLOYER_ONBOARDING = '/employer/onboarding';
export const EMPLOYER_ONBOARDING_WITH_PARAMS = '/employer/onboarding/:step/*';
export const EMPLOYER_ONBOARDING_1 = `${EMPLOYER_ONBOARDING}/1`;
export const EMPLOYER_ONBOARDING_2 = `${EMPLOYER_ONBOARDING}/2`;
export const EMPLOYER_ONBOARDING_3 = `${EMPLOYER_ONBOARDING}/3`;
export const EMPLOYER_ONBOARDING_4 = `${EMPLOYER_ONBOARDING}/4`;
export const EMPLOYER_ONBOARDING_5 = `${EMPLOYER_ONBOARDING}/5`;

export const GOOGLE_OAUTH_CALLBACK = '/oauth/google/callback';
export const GROUP_SPECIFIC_REGISTER = `${REGISTER_URL}/:group`;

export const CANDIDATE_SPECIFIC_REGISTER = `${REGISTER_URL}/candidate`;
export const EMPLOYER_SPECIFIC_REGISTER = `${REGISTER_URL}/employer`;
export const POSITIONS_V2 = `${POSITIONS_URL}/v2/:positionId`;

export const UPCOMING_INTERVIEWS_SCHEDULE = '/upcoming';
export const UPCOMING_INTERVIEWS_SCHEDULE_MODAL = `${UPCOMING_INTERVIEWS_SCHEDULE}/:modal?`;

export const INDEED_OAUTH = '/indeed/oauth';
export const SEVEN_SHIFTS_OAUTH = '/7shifts/oauth';
export const CANDIDATE_ACCOUNT_MENU_MODAL = `${CANDIDATE_ACCOUNT_MENU_PATH}/:modal`;

export const CANDIDATE_HOME = '/home';
export const CANDIDATE_HOME_TAB = `${CANDIDATE_HOME}/:tab`;
export const CANDIDATE_HOME_TAB_EDIT_MODAL = `${CANDIDATE_HOME}/:tab/edit/:modal`;
export const CANDIDATE_HOME_TAB_HIRING_BY_SLUG = `${CANDIDATE_HOME}/:tab/hiring/:slug`;
export const CANDIDATE_HOME_EDIT_MODAL = `${CANDIDATE_HOME}/edit/:modal`;

export const CANDIDATE_PROFILE = '/u';
export const CANDIDATE_PROFILE_BY_HANDLE = `${CANDIDATE_PROFILE}/:handle`;
export const CANDIDATE_PROFILE_MODAL_BY_HANDLE = `${CANDIDATE_PROFILE_BY_HANDLE}/:modal`;
export const CANDIDATE_PROFILE_EDIT_MODAL_BY_HANDLE = `${CANDIDATE_PROFILE_BY_HANDLE}/edit/:modal`;

export const CAREER_PAGE_BY_SLUG = `/restaurant/:${SLUGS.careerPage}`;
export const CAREER_PAGE_EDIT_BY_SLUG = `/restaurant/edit/:${SLUGS.careerPage}`;
export const CAREER_PAGE_MODAL_BY_SLUG = `/restaurant/:${SLUGS.careerPage}/:modal`;
export const CAREER_PAGE_EDIT_MODAL_BY_SLUG = `/restaurant/edit/:${SLUGS.careerPage}/:modal`;
export const CAREER_PAGE_EDIT_PREVIEW_BY_SLUG = `/restaurant/edit/:${SLUGS.careerPage}/preview`;

export const LOGOUT = '/logout';

export const CAREER_PAGE_DASHBOARD = '/careers';
export const CREATE_NEW_CAREER_PAGE = `${CAREER_PAGE_DASHBOARD}/create`;
export const CAREER_DASHBOARD_WITH_SEGMENTS = `${CAREER_PAGE_DASHBOARD}/:modal?`;

export const CANDIDATE_ONBOARDING_WITH_PARAMS = '/candidate/onboarding/:step/*';

export const LOCATION_CREATION_SUCCESS_MODAL = 'location-creation-success';
export const POSITION_CREATION_SUCCESS_MODAL = 'position-creation-success';

export const PENDING_EMPLOYER_PROFILE = '/pending';
export const SUBSCRIBE_LOCATIONS_POSITIONS = '/subscribe';

export const HELP_REQUEST_FORM = '/help/submit-request';
