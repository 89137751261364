import React from 'react';

import copy from '../../../images/copy_to_board.svg';

import { FOHImage } from '../../ingredients';

export const FOHCopyIcon = (props: any) => (
  <FOHImage
    source={{ uri: copy }}
    style={{ width: 18, height: 20, ...props?.style }}
  />
);
