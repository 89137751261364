import React, { useEffect, useMemo } from 'react';

import { defaultTo, prop, toLower } from 'ramda';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  ADD_NEW_POSITION,
  AI_MODAL_TO_MANAGE_POSITIONS,
  BILLING,
  EMPLOYER_DASHBOARD,
  EMPLOYER_SETTINGS_MENU,
  FEED,
  LOCATION_CREATION_SUCCESS_MODAL,
  NETWORK,
  PLAN,
  POSITION_CREATION_SUCCESS_MODAL,
  TEAM,
  UPGRADE
} from '../../constants';
import { useNavigation } from '../../utils/navigation';
import { FOHPlanState } from '../../components';
import { useDismissableModals } from '../../hooks';

import { useCurrentLocationContext } from '../Locations/useCurrentLocationContext';

import { FOHLocationPositionCreationSuccessModal } from './components';

export const ManageLocationPositionModals = props => {
  const { t } = useTranslation('EmployerDashboardFeature');
  const [searchParams, setResetSearchParams] = useSearchParams();
  const modal = searchParams.get('modal');
  const { navigateTo, location: navLocation } = useNavigation();
  const { handleModalDismiss } = useDismissableModals();
  const { selectLocationFilter } = useCurrentLocationContext({
    props,
    skipLocationsQuery: true
  });

  const location = defaultTo(null, prop('location', navLocation?.state));
  const position = defaultTo(null, prop('position', navLocation?.state));
  const originFrom = defaultTo(FEED, prop('origin', navLocation?.state));

  const closeModal = () => {
    const modalToDismiss =
      modal === LOCATION_CREATION_SUCCESS_MODAL
        ? LOCATION_CREATION_SUCCESS_MODAL
        : POSITION_CREATION_SUCCESS_MODAL;
    handleModalDismiss(modalToDismiss);
    setResetSearchParams({});
    navigateTo({ pathname: originFrom });
  };

  const handleBillingNavigation = (plan = FOHPlanState.unlimited) => {
    selectLocationFilter(location?.id);
    navigateTo(
      props?.from === FEED
        ? `${FEED}${UPGRADE}`
        : `${EMPLOYER_SETTINGS_MENU}${BILLING}${PLAN}${UPGRADE}`,
      {
        state: {
          plan: toLower(plan),
          monthly: true
        }
      }
    );
  };

  useEffect(() => {
    if (!location && !position && modal) {
      setResetSearchParams({});
    }
  }, [modal, location, position]);

  const nextStepsAfterLocationCreation = useMemo(
    () => [
      {
        actionLabel: t('billingActionLabel'),
        onActionClick: () => handleBillingNavigation()
      },
      {
        actionLabel: t('addTeammateActionLabel'),
        onActionClick: () => navigateTo(`${EMPLOYER_SETTINGS_MENU}${TEAM}`)
      },
      {
        actionLabel: t('goToDashboardActionLabel'),
        onActionClick: () => navigateTo(EMPLOYER_DASHBOARD)
      }
    ],
    []
  );

  const nextStepsAfterPositionCreation = useMemo(
    () => [
      {
        actionLabel: t('exploreCandidateDatabaseActionLabel'),
        onActionClick: () => navigateTo(NETWORK)
      },
      {
        actionLabel: t('turnedOnAIActionLabel'),
        onActionClick: () =>
          navigateTo(`${FEED}${AI_MODAL_TO_MANAGE_POSITIONS}`)
      },
      {
        actionLabel: t('exploreFeedActionLabel'),
        onActionClick: () => navigateTo(FEED)
      }
    ],
    []
  );

  return (
    <>
      {(modal === LOCATION_CREATION_SUCCESS_MODAL && location) ||
      (modal === POSITION_CREATION_SUCCESS_MODAL && position) ? (
        <FOHLocationPositionCreationSuccessModal
          open={
            modal === LOCATION_CREATION_SUCCESS_MODAL ||
            modal === POSITION_CREATION_SUCCESS_MODAL
          }
          close={closeModal}
          actionsLabel={t('yourNextStepsLabel')}
          headerLabel={
            modal === LOCATION_CREATION_SUCCESS_MODAL
              ? t('locationCreatedSuccessLabel')
              : t('positionCreatedSuccessLabel')
          }
          detailLabel={
            modal === LOCATION_CREATION_SUCCESS_MODAL
              ? t('locationCreatedSuccessDetailLabel', {
                  locationName: location?.name
                })
              : t('positionCreatedSuccessDetailLabel', {
                  positionName: position?.name
                })
          }
          highlightLocationOrPositionName={location?.name || position?.name}
          buttonLabel={
            modal === LOCATION_CREATION_SUCCESS_MODAL
              ? t('createFirstPositionBtnLabel')
              : t('billingBtnLabel')
          }
          onButtonPress={() => {
            selectLocationFilter(location?.id);
            modal === LOCATION_CREATION_SUCCESS_MODAL
              ? navigateTo(ADD_NEW_POSITION, { state: { originFrom } })
              : handleBillingNavigation(FOHPlanState.payasyougo);
          }}
          actions={
            modal === LOCATION_CREATION_SUCCESS_MODAL
              ? nextStepsAfterLocationCreation
              : nextStepsAfterPositionCreation
          }
        />
      ) : null}
    </>
  );
};
