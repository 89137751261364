import React, { useEffect } from 'react';
import { defaultTo, path } from 'ramda';
import { useParams } from 'react-router-dom';

import {
  useGetCareerPageAnswersQuery,
  useGetCareerPageDataQuery,
  useGetCareerPageAccessQuery
} from '../../../src/graphql/generated';
import { FullScreenLogoLoader } from '../Loading/LogoLoader';
import { CAREER_PAGE_DASHBOARD, SLUGS } from '../../constants';
import { useFeatures } from '../SignIn';
import { useAsyncFn } from '../../utils/useAsync';
import { useNavigation } from '../../utils/navigation';

import {
  adaptAnswersData,
  adaptMainImageData,
  adaptMainImageGalleryDataAsync
} from './adapters';
import { dataURLtoBlob, nullsToEmptyStrings } from './utils';
import { CareerPageForm } from './Form';

export const CareerPageFormScreen = () => {
  const params = useParams();
  const { navigateTo } = useNavigation();
  const slug = params[SLUGS.careerPage];

  const queryVars = { variables: { slug } };
  const { loadingFeatureFlags } = useFeatures();

  const { data: pageData, loading: loadingPage } =
    useGetCareerPageDataQuery(queryVars);
  const { data: ansData, loading: loadingAns } =
    useGetCareerPageAnswersQuery(queryVars);

  const careerPageID = path(
    ['careerPageBySlug', 'edges', 0, 'node', 'id'],
    pageData
  );
  const selectedLocations = defaultTo(
    [],
    path(
      ['careerPageBySlug', 'edges', 0, 'node', 'selectedLocations'],
      pageData
    )
  );

  const userAccessQuery = useGetCareerPageAccessQuery({
    skip: !careerPageID,
    variables: {
      id: careerPageID
    }
  });

  const careerPageData = pageData?.careerPageBySlug?.edges?.[0]?.node;
  const { cover } = careerPageData || {};

  const { data: coverState, loading: adaptingCoverImage } = useAsyncFn(
    () => dataURLtoBlob(cover),
    [cover]
  );

  const { data: adaptedGalleries, loading: adaptingMainImageGallery } =
    useAsyncFn(
      () => adaptMainImageGalleryDataAsync(careerPageData),
      [pageData]
    );

  useEffect(() => {
    if (userAccessQuery && userAccessQuery?.data) {
      const haveEditAccess = path(
        ['data', 'careerPageEditAccess', 'canManage'],
        userAccessQuery
      );

      if (!haveEditAccess) navigateTo(CAREER_PAGE_DASHBOARD, { replace: true });
    }
  }, [userAccessQuery?.data]);

  if (
    loadingFeatureFlags ||
    loadingPage ||
    loadingAns ||
    adaptingCoverImage ||
    adaptingMainImageGallery
  )
    return <FullScreenLogoLoader />;

  return (
    <CareerPageForm
      answers={adaptAnswersData(ansData)}
      data={{ ...nullsToEmptyStrings(careerPageData), cover: coverState }}
      galleriesArray={adaptedGalleries}
      mainImageArray={adaptMainImageData(pageData)}
      selectedLocations={selectedLocations}
      slug={slug}
    />
  );
};
