import {
  flatten,
  prop,
  includes,
  defaultTo,
  path,
  find,
  filter,
  toLower
} from 'ramda';
import { DateTime } from 'luxon';

import {
  shifts,
  EMPLOYMENT_PREFERENCES_DROPDOWN,
  availabilityDayKeys,
  availabilityDayKeysCamel
} from '../../constants/candidates';
import { getAppBaseUrl } from '../../constants';

export const fromFormToApi = fields => {
  let availabilityMap = {};
  let employmentPreferences = [];

  // Translate employment preferences into list
  if (fields['employment_preference_1']) {
    employmentPreferences.push(fields['employment_preference_1']);
  }

  if (fields['employment_preference_2']) {
    employmentPreferences.push(fields['employment_preference_2']);
  }

  if (fields['employment_preference_3']) {
    employmentPreferences.push(fields['employment_preference_3']);
  }

  delete fields['employment_preference_1'];
  delete fields['employment_preference_2'];
  delete fields['employment_preference_3'];

  Object.keys(fields).forEach(field => {
    if (field.includes('availability') && !field.includes('zip')) {
      const segments = field.split('_');
      const value = segments.slice(2).join('_');
      const key = segments.slice(0, 2).join('_');
      const checked = fields[field];

      if (!availabilityMap[key]) {
        availabilityMap[key] = [];
      }

      if (checked) {
        availabilityMap[key].push(value);
      }

      delete fields[field];
    }

    // Delete all fields with value of empty string
    if (typeof fields[field] === 'string' && !fields[field].trim().length) {
      delete fields[field];
    }
  });

  return {
    ...fields,
    ...availabilityMap,
    employment_preferences: employmentPreferences
  };
};

export const transformEmploymentPreferencesFromApi = fields => {
  let employmentPreferences = fields['employment_preferences'] || [];

  employmentPreferences = filter(
    employmentPreferences,
    preference =>
      !!find(EMPLOYMENT_PREFERENCES_DROPDOWN, preferenceChoice => {
        return preference === preferenceChoice.value;
      })
  );

  return employmentPreferences;
};

export const transformEmploymentPreferencesToApi = fields => {
  let employmentPreferences = [];

  // Translate employment preferences into list
  if (fields['employment_preference_1']) {
    employmentPreferences.push(fields['employment_preference_1']);
  }

  if (fields['employment_preference_2']) {
    employmentPreferences.push(fields['employment_preference_2']);
  }

  if (fields['employment_preference_3']) {
    employmentPreferences.push(fields['employment_preference_3']);
  }

  return {
    employment_preferences: employmentPreferences
  };
};

export const transformAvailabilitiesFromApi = fields => {
  let availabilityMap = {
    availability_first_shift: [],
    availability_second_shift: [],
    availability_third_shift: [],
    availability_fourth_shift: []
  };

  availabilityDayKeys.forEach(day => {
    let dayShifts = fields[day] || [];

    if (dayShifts.includes('breakfast')) {
      availabilityMap['availability_first_shift'].push(day);
    }

    if (dayShifts.includes('lunch')) {
      availabilityMap['availability_second_shift'].push(day);
    }

    if (dayShifts.includes('dinner')) {
      availabilityMap['availability_third_shift'].push(day);
    }

    if (dayShifts.includes('late_night')) {
      availabilityMap['availability_fourth_shift'].push(day);
    }
  });

  return availabilityMap;
};

export const transformAvailabilitiesToApi = fields => {
  let availabilityMap = {};

  availabilityDayKeys.forEach(day => {
    let firstShiftValues = fields['availability_first_shift'] || [];
    let secondShiftValues = fields['availability_second_shift'] || [];
    let thirdShiftValues = fields['availability_third_shift'] || [];
    let fourthShiftValues = fields['availability_fourth_shift'] || [];

    availabilityMap[day] = [];

    if (firstShiftValues.includes(day)) {
      availabilityMap[day].push('breakfast');
    }

    if (secondShiftValues.includes(day)) {
      availabilityMap[day].push('lunch');
    }

    if (thirdShiftValues.includes(day)) {
      availabilityMap[day].push('dinner');
    }

    if (fourthShiftValues.includes(day)) {
      availabilityMap[day].push('late_night');
    }
  });

  return availabilityMap;
};

export const transformEmploymentFromApi = employment => {
  let result = {
    id: employment.id,
    employer_name: employment.employer_name,
    position: employment.position,
    present: employment.present
  };
  let startMonth;
  let startYear;
  let endMonth;
  let endYear;

  if (employment.start) {
    startYear = employment.start.split('-')[0];
    startMonth = employment.start.split('-')[1];
    result['start_year'] = startYear;
    result['start_month'] = startMonth;
  }

  if (employment.end) {
    endYear = employment.end.split('-')[0];
    endMonth = employment.end.split('-')[1];
    result['end_year'] = endYear;
    result['end_month'] = endMonth;
  }

  return result;
};

export const transformWorkHistoryFromApi = workHistory =>
  workHistory.map(employment => transformEmploymentFromApi(employment));

export const transformCandidateProfileFromApi = candidate_profile => {
  let formattedAvailabilities =
    transformAvailabilitiesFromApi(candidate_profile);
  let formattedEmploymentPreferences =
    transformEmploymentPreferencesFromApi(candidate_profile);
  let workHistory = transformWorkHistoryFromApi(candidate_profile.work_history);

  return {
    profile: {
      ...candidate_profile,
      ...formattedAvailabilities,
      ...formattedEmploymentPreferences,
      work_history: workHistory
    }
  };
};

export const transformAvailabilityFromAPI = ({ allAvailability }) =>
  flatten(
    Object.keys(allAvailability)
      .filter(field => {
        return field.includes('availability_');
      })
      .map(availabilityField => {
        const availabilitieDays =
          prop(availabilityField, allAvailability) || [];
        return availabilitieDays.map(
          availabilityDay => `${availabilityField}-${availabilityDay}`
        );
      })
  );

export const transformAvailabilityToAPI = ({ availability }) =>
  availability.reduce(
    (acc, current) => {
      const currentDay = current.split('-')[0];
      const shift = current.split('-')[1];
      return {
        ...acc,
        [currentDay]: acc[currentDay] ? [...acc[currentDay], shift] : [shift]
      };
    },
    {
      availability_monday: [],
      availability_tuesday: [],
      availability_wednesday: [],
      availability_thursday: [],
      availability_friday: [],
      availability_saturday: [],
      availability_sunday: []
    }
  );

export const transformAvailabilityToGraphQL = ({ availability }) =>
  availability.reduce(
    (acc, current) => {
      const currentDay = current.split('-')[0];
      const shift = current.split('-')[1];
      return {
        ...acc,
        [currentDay]: acc[currentDay] ? [...acc[currentDay], shift] : [shift]
      };
    },
    {
      availabilityMonday: [],
      availabilityTuesday: [],
      availabilityWednesday: [],
      availabilityThursday: [],
      availabilityFriday: [],
      availabilitySaturday: [],
      availabilitySunday: []
    }
  );

export const transformAvailabilityFromProfile = ({ profile }) =>
  flatten(
    Object.keys(profile)
      .filter(field => {
        return field.includes('availability');
      })
      .map(availabilityField => {
        const availabilitieDays = prop(availabilityField, profile) || [];
        return availabilitieDays.map(
          availabilityDay => `${availabilityField}-${availabilityDay}`
        );
      })
  );

export const transformAvailabilityToVariables = ({ availability }) =>
  availability.reduce((acc, current) => {
    const currentDay = current.split('-')[0];
    const shift = current.split('-')[1];
    return {
      availabilityMonday: [],
      availabilityTuesday: [],
      availabilityWednesday: [],
      availabilityThursday: [],
      availabilityFriday: [],
      availabilitySaturday: [],
      availabilitySunday: [],
      ...acc,
      [currentDay]: acc[currentDay] ? [...acc[currentDay], shift] : [shift]
    };
  }, {});

export const transformWorkHistoryForAPI = ({
  employerName,
  startMonth,
  startYear,
  endMonth,
  endYear,
  present,
  position
}) =>
  present
    ? {
        employer_name: employerName,
        position,
        present,
        // january is 0
        start:
          startMonth && startYear
            ? new Date(startYear, startMonth).toISOString().split('T')[0]
            : ''
      }
    : {
        employer_name: employerName,
        position,
        present: false,
        start:
          startMonth && startYear
            ? new Date(startYear, startMonth).toISOString().split('T')[0]
            : '',
        // january is 0
        end:
          endMonth && endYear
            ? new Date(endYear, endMonth).toISOString().split('T')[0]
            : ''
      };

export const transformWorkHistoryToVariables = ({
  employerName,
  startMonth,
  startYear,
  endMonth,
  endYear,
  present,
  position,
  responsibilities
}) =>
  present
    ? {
        employerName,
        position,
        present,
        responsibilities,
        // january is 0
        start:
          startMonth && startYear
            ? new Date(startYear, startMonth).toISOString().split('T')[0]
            : ''
      }
    : {
        employerName,
        position,
        present: false,
        responsibilities,
        start:
          startMonth && startYear
            ? new Date(startYear, startMonth).toISOString().split('T')[0]
            : '',
        // january is 0
        end:
          endMonth && endYear
            ? new Date(endYear, endMonth).toISOString().split('T')[0]
            : ''
      };

export const transformWorkHistoryFromAPI = ({
  id,
  employer_name,
  position,
  start,
  end,
  present
}) => ({
  id,
  employerName: employer_name,
  // january is 0
  startMonth: new Date(start).getMonth() + 1,
  startYear: new Date(start).getFullYear(),
  // january is 0
  endMonth: new Date(end).getMonth() + 1,
  endYear: new Date(end).getFullYear(),
  present,
  position
});

export const getStartEndMonthYear = ({ start, end }) => ({
  // january is 0
  startMonth: new Date(start).getMonth() + 1,
  startYear: new Date(start).getFullYear(),
  // january is 0
  endMonth: new Date(end).getMonth() + 1,
  endYear: new Date(end).getFullYear()
});

export const accountInfoToAPI = ({ firstName, lastName, phone, email }) => ({
  first_name: firstName,
  last_name: lastName,
  phone,
  email
});

// TODO: Format work history
const formatWorkHistory = (iso, language) =>
  iso
    ? DateTime.fromISO(iso).setLocale(language).toLocaleString({
        month: 'short',
        year: 'numeric'
      })
    : '';

export const transformWorkHistoryForProfile = ({ candidate, t, language }) =>
  candidate.work_history.map(wh => ({
    employerName: wh.employer_name,
    position: wh.position,
    start: formatWorkHistory(wh.start, language),
    end: wh.present ? t('present') : formatWorkHistory(wh.end, language)
  }));

export const transformWorkHistoryForProfileCamel = ({
  candidate,
  t,
  language
}) =>
  defaultTo([], path(['workHistory', 'edges'], candidate)).map(node => ({
    id: path(['node', 'id'], node),
    employerName: path(['node', 'employerName'], node),
    responsibilities: path(['node', 'responsibilities'], node),
    image: path(['node', 'location', 'image'], node),
    locationLink: path(['node', 'location', 'slug'], node)
      ? `${getAppBaseUrl()}/restaurant-jobs/${path(
          ['node', 'location', 'slug'],
          node
        )}`
      : '',
    position: path(['node', 'position'], node),
    start: formatWorkHistory(path(['node', 'start'], node), language),
    end: path(['node', 'present'], node)
      ? t('present')
      : formatWorkHistory(path(['node', 'end'], node), language)
  }));

export const transformAvailabilityForProfileCamel = (profile, t) => {
  return availabilityDayKeysCamel.map(availabilityDay => ({
    day: t(
      `common:availability.${toLower(availabilityDay.split('availability')[1])}`
    ),
    shifts: shifts.map(shift => ({
      label: t(shift.label),
      active: includes(
        shift.value,
        defaultTo([], path([availabilityDay], profile))
      )
    }))
  }));
};
