import { useCallback } from 'react';

import { path, prop, defaultTo } from 'ramda';

import { findById } from '../../utils';
import { useTypeAheadSearchQuery } from '../../graphql/generated';

export const usePositionTypes = (
  { skipTypeAheadQuery } = { skipTypeAheadQuery: false }
) => {
  const typeAheadQuery = useTypeAheadSearchQuery({
    skip: skipTypeAheadQuery,
    variables: { search: '' }
  });

  const positionTypesFromSearchAhead = defaultTo(
    [],
    path(['data', 'searchAhead', 'groups'], typeAheadQuery)
  ).filter(group => prop('group', group) === 'Position Types');

  const positionTypes = defaultTo(
    [{ value: '', label: '' }],
    path([0, 'values'], positionTypesFromSearchAhead)
  );

  const getStringForPositionType = useCallback(
    positionType =>
      prop('label', findById(positionType, positionTypes, 'value')),
    [positionTypes]
  );

  return {
    positionTypes,
    loadingPositionTypes: typeAheadQuery.loading,
    getStringForPositionType
  };
};
