import React from 'react';

import attachment from '../../../images/fetch.svg';

import { FOHImage } from '../../ingredients';

export const FOHFetchIcon = (props: any) => (
  <FOHImage
    source={{ uri: attachment }}
    style={
      props && !!props.style
        ? { width: 24, height: 24, ...props.style }
        : { width: 24, height: 24 }
    }
  />
);
