import React, { useState, useEffect, useRef } from 'react';

import { compose, path, prop } from 'ramda';
import { useTranslation } from 'react-i18next';

import { uploadProfileVideo } from '../../api/profiles';
import { handle, useIsMobile } from '../../utils';
import {
  CANDIDATE_ACCOUNT_MENU_PATH,
  CANDIDATE_VIDEO_PATH
} from '../../constants';
import { useNavigation } from '../../utils/navigation';
import { FOHUploadVideoScreen } from '../../components';

import { withReactiveModal } from '../Navigation/withReactiveModal';

import { useCandidateProfileMutation } from './useCandidateProfileMutation';

const UploadVideoScreen = props => {
  const { navigateTo, location: navLocation } = useNavigation();

  const { me, updateCandidate } = useCandidateProfileMutation();
  const reader = new FileReader();

  const [video, setVideo] = useState('');
  const [uploading, setUploading] = useState('');

  const [fileToUpload, setFileToUpload] = useState(null);

  const [error, setError] = useState();
  const { t } = useTranslation('UploadVideoFeature');
  const { isMobile } = useIsMobile;

  useEffect(() => {
    const userVideo = path(['candidateProfile', 'profileVideo'], me);
    setVideo(userVideo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prop('candidateProfile', me)]);

  const inputFile = useRef(null);

  reader.onload = e => {
    const blob = e.target.result;
    setVideo(blob);
  };

  const VideoComponent = _props => (
    <video width="327" height="170" controls>
      <track kind="captions" />
      <source src={video} type="video/mp4" />
    </video>
  );

  const hasBack =
    navLocation.pathname === CANDIDATE_VIDEO_PATH &&
    !!path(['candidateProfile', 'onboardingCompletedAt'], me);

  return (
    <>
      <input
        type="file"
        id="file"
        accept="video/*"
        onChange={e => {
          const file = e.target.files[0];
          file && setFileToUpload(file);
          file && reader.readAsDataURL(file);
        }}
        ref={inputFile}
        style={{ display: 'none' }}
      />
      <FOHUploadVideoScreen
        pickVideo={() => {
          return inputFile && inputFile.current && inputFile.current.click();
        }}
        fullName={me ? `${prop('firstName', me)} ${prop('lastName', me)}` : ''}
        key={me}
        video={video}
        setVideo={setVideo}
        error={error}
        isModal={props.isModal}
        uploading={uploading}
        VideoComponent={VideoComponent}
        title={t('videoTitle')}
        disabledSubmit={uploading}
        uploadVideo={() => {
          return inputFile && inputFile.current && inputFile.current.click();
        }}
        uploadLabel={t('uploadVideo')}
        detailText={t(`uploadVideoDetail`)}
        goBack={hasBack ? () => navigateTo(CANDIDATE_ACCOUNT_MENU_PATH) : null}
        submitLabel={
          !uploading
            ? path(['candidateProfile', 'onboardingCompletedAt'], me)
              ? t('common:save')
              : t('nextLabel')
            : t('uploading')
        }
        handleSubmit={async () => {
          if (!fileToUpload) {
            await handle(updateCandidate({ variables: {} }));

            if (props.close) return props.close();

            return path(['candidateProfile', 'onboardingCompletedAt'], me)
              ? navigateTo(CANDIDATE_ACCOUNT_MENU_PATH)
              : props.onSubmit && props.onSubmit();
          }

          setUploading(true);
          const [res, imageUploadError] = await handle(
            uploadProfileVideo(fileToUpload)
          );
          const [resp] = !imageUploadError
            ? await handle(
                updateCandidate({
                  variables: {
                    profileVideoId: res.id
                  }
                })
              )
            : null;

          setUploading(false);

          const err = path(['data', 'mutateCandidate', 'errors'], resp);

          if (resp) {
            props.onSubmit && props.onSubmit();
            if (props.close) return props.close();
            navigateTo(
              path(['candidateProfile', 'onboardingCompletedAt'], me)
                ? CANDIDATE_ACCOUNT_MENU_PATH
                : props.onSubmit && props.onSubmit()
            );
          } else {
            setError(err);
          }
        }}
        largeHeader={!isMobile}
      />
    </>
  );
};

export const UploadVideoModal = compose(withReactiveModal)(UploadVideoScreen);

export default UploadVideoScreen;
