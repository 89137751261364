import React, { useRef } from 'react';

import { StyleProp, ViewStyle } from 'react-native';
import { useHover } from 'react-native-web-hooks';

import { FOHTouchableOpacity } from '../containers/Containers';
import { FOHToolTip, FOHToolTipType } from '../tooltips/FOHToolTip';

export interface FOHIconButtonProps {
  onPress: (e: any) => void;
  disabled?: boolean;
  style?: StyleProp<ViewStyle>;
  icon: () => React.ReactNode;
  testID?: string;
  enableHover?: boolean;
  helpText?: string;
  toolTipType?: FOHToolTipType;
}

export const FOHIconButton = (props: FOHIconButtonProps) => {
  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);

  return (
    <FOHTouchableOpacity
      disabled={props.disabled}
      onPress={props.onPress}
      style={props.style}
      testID={props.testID}
      ref={hoverRef}
    >
      {!props.disabled && props.enableHover ? (
        <FOHToolTip
          helpText={props.helpText}
          open={isHovered}
          type={props.toolTipType || FOHToolTipType.LEFT}
          style={
            props.toolTipType === FOHToolTipType.LEFT
              ? { bottom: -30, right: 25 }
              : { bottom: 5, right: -40 }
          }
        />
      ) : null}
      {props.icon()}
    </FOHTouchableOpacity>
  );
};
