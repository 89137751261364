import React, { useMemo, useRef, useState } from 'react';

import { useHover } from 'react-native-web-hooks';
import { defaultTo, path, prop } from 'ramda';
import { useTranslation } from 'react-i18next';

import {
  FOHLabelBold,
  FOHColors,
  FOHView,
  FOHImage,
  FOHScrollView,
  FOHTouchableOpacity,
  FOHToolTip,
  FOHToolTipType,
  FOHBanner,
  FOHBannerType,
  FOHSearchableSelectDropdown,
  FOHSpace,
  FOHSpaceLarge,
  FOHSpaceHuge,
  FOHLocationPin,
  FOHRequiredMark,
  FOHToolBoxIcon,
  FOHTextField,
  FOHDivider,
  FOHDetailText,
  FOHHeaderH2,
  // FOHLabeledRadio,
  FOHFormFlowButtons
} from '../../components';
import header_image from '../../images/create_campaign_header.svg';
import { useCreateJobBoardCampaignMutation } from '../../graphql/generated';
import { handleMutation, useIsMobile } from '../../utils';
import { CAMPAIGN_HOME } from '../../constants';
import { useNavigation } from '../../utils/navigation';

import { useSelectPositionForSelectedLocationState } from '../EmployerPositions/useSelectPositionForSelectedLocationState';
import { useBanner } from '../Navigation';
import { useGetMeData } from '../SignIn';

import { PLATFORMS_LIST, PLATFORM_TYPES } from './constants';

interface HeaderProps {
  title: string;
  subTitle: string;
  HeaderImage: any;
}

const Header = (props: HeaderProps) => {
  const { title, subTitle, HeaderImage } = props;

  return (
    <FOHView
      style={{
        flexDirection: 'row',
        alignItems: 'center'
      }}
    >
      <FOHView>
        <HeaderImage />
      </FOHView>
      <FOHSpace />
      <FOHView>
        <FOHHeaderH2 style={{ textAlign: 'left' }}>{title}</FOHHeaderH2>
        <FOHDetailText style={{ textAlign: 'left' }}>{subTitle}</FOHDetailText>
      </FOHView>
    </FOHView>
  );
};

export const CreateCampaign = () => {
  const { t } = useTranslation('CampaignsFeature');
  const { navigateTo, goBack } = useNavigation();

  const { isMobile } = useIsMobile();

  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);

  const [name, setName] = useState('');
  const { banner, setBanner } = useBanner();

  const { data } = useGetMeData();

  const integrations: string[] = defaultTo([], path(['integrations'], data));

  const isIndeedConnected = integrations.includes('indeed');

  // const [selectedPlatform, setSelectedPlatform] = useState<
  //   keyof typeof PLATFORM_TYPES
  // >(isIndeedConnected ? PLATFORM_TYPES.Indeed : PLATFORM_TYPES.ZipRecruiter);

  const [locationOpen, setLocationOpen] = useState(false);
  const [positionOpen, setPositionOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [createCampaignMutation] = useCreateJobBoardCampaignMutation();

  const {
    selectedLocation,
    myLocations,
    setSelectedLocation,
    selectedPosition,
    indexablePositions,
    setSelectedPosition,
    locationSearch,
    positionSearch,
    setLocationSearch,
    setPositionSearch
  } = useSelectPositionForSelectedLocationState();

  const locationOptions = myLocations.map(loc => ({
    label: prop('displayName', loc),
    value: prop('id', loc),
    sublabel: prop('formattedAddress', loc)
  }));

  const positionOptions = indexablePositions.map(pos => ({
    label: prop('positionName', pos),
    value: prop('id', pos),
    sublabel: '',
    // prompt for upgrade label
    upgrade:
      path(['paymentStatus'], pos) !== 'SUBSCRIBED' &&
      path(['location', 'paymentStatus'], pos) !== 'SUBSCRIBED',
    locationIdToUpgrade: path(['location', 'id'], pos)
  }));

  const handleCancelPress = () => {
    goBack();
  };

  const handleNextPress = async () => {
    setLoading(true);
    const [response, error] = await handleMutation(
      createCampaignMutation({
        variables: {
          name,
          platform: 'indeed',
          positionId: selectedPosition
        }
      })
    );

    if (error || !response) {
      setLoading(false);
      return setBanner({
        bannerType:
          FOHBannerType.DANGER as unknown as keyof typeof FOHBannerType,
        bannerMessage: error?.message || t('createCampaignErrorLabel')
      });
    }

    setBanner({
      bannerType:
        FOHBannerType.SUCCESS as unknown as keyof typeof FOHBannerType,
      bannerMessage: t('createCampaignSuccessLabel')
    });
    const campaignId = path(
      ['createJobBoardCampaign', 'campaign', 'id'],
      response
    );

    setTimeout(() => {
      setLoading(false);
      navigateTo(`${CAMPAIGN_HOME}/${campaignId}`);
    }, 2000);
  };

  const getPlatformsList = useMemo(() => {
    // Check whether no oauth token for employer
    if (!isIndeedConnected) {
      return PLATFORMS_LIST.map(platform => {
        if (platform.value === PLATFORM_TYPES.Indeed) {
          return {
            ...platform,
            disabled: true
          };
        } else {
          return platform;
        }
      });
    }

    return PLATFORMS_LIST;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrations]);

  return (
    <FOHScrollView
      style={{
        height: isMobile ? '82vh' : '100vh',
        width: isMobile ? undefined : '100%'
      }}
    >
      <FOHView style={{ height: '100vh' }}>
        {banner?.bannerMessage ? (
          //@ts-ignore passing bannerType through spread operator
          <FOHBanner
            style={{ marginBottom: 30 }}
            {...banner}
            message={banner?.bannerMessage}
          />
        ) : (
          <></>
        )}

        <FOHView style={{ paddingHorizontal: 100, zIndex: 2 }}>
          <FOHSpaceLarge />
          <Header
            title={t('createCampaignHeader')}
            subTitle={t('createCampaignHeaderSubTitle')}
            HeaderImage={() => (
              <FOHImage
                source={{ uri: header_image }}
                style={{
                  height: 67,
                  width: 96
                }}
              />
            )}
          />
          <FOHSpace />
          <FOHView style={{ zIndex: 3 }}>
            <FOHView style={{ maxWidth: 400 }}>
              <FOHView style={{ flexDirection: 'row', alignItems: 'center' }}>
                <FOHLabelBold>{t('campaignNameLabel')}</FOHLabelBold>
                <FOHRequiredMark
                  style={{
                    alignSelf: 'flex-start',
                    marginTop: 1
                  }}
                />
              </FOHView>
            </FOHView>
            <FOHTextField
              onChangeText={setName}
              value={name}
              placeholder={t('campaignNamePlaceholder')}
              required={true}
              style={{ width: 400 }}
            />
          </FOHView>

          <FOHSpace />

          <FOHView style={{ width: 400, zIndex: 2 }}>
            <FOHView style={{ flexDirection: 'row', alignItems: 'center' }}>
              <FOHLabelBold>{t('selectPositionLabel')}</FOHLabelBold>
              <FOHRequiredMark
                style={{
                  alignSelf: 'flex-start',
                  marginTop: 1
                }}
              />
            </FOHView>
            <FOHSpace />
            <FOHView style={{ zIndex: 3, width: '100%' }}>
              <FOHSearchableSelectDropdown
                open={locationOpen}
                setOpen={(open: any) => {
                  setPositionOpen(false);
                  setLocationOpen(open);
                }}
                selectedValue={selectedLocation}
                selectedLabel={undefined}
                showLabel="show"
                onSearchChange={setLocationSearch}
                searchValue={locationSearch}
                multiSelect={false}
                searchPlaceholder={t('common:search')}
                onValueChange={(value: any) => {
                  setSelectedLocation(value);
                }}
                options={locationOptions}
                fieldLabel="location"
                icon={() => <FOHLocationPin />}
                style={{ width: '100%', backgroundColor: FOHColors.WHITE }}
                testID="campaign-select-location"
              ></FOHSearchableSelectDropdown>
            </FOHView>
            <FOHSpace />
            <FOHView style={{ zIndex: 2, width: '100%' }}>
              <FOHSearchableSelectDropdown
                open={positionOpen}
                style={{ width: '100%', backgroundColor: FOHColors.WHITE }}
                setOpen={(open: any) => {
                  setPositionOpen(open);
                  setLocationOpen(false);
                }}
                selectedValue={selectedPosition}
                multiSelect={false}
                onSearchChange={setPositionSearch}
                searchValue={positionSearch}
                searchPlaceholder={t('common:search')}
                onValueChange={(value: any) => {
                  setSelectedPosition(value);
                }}
                options={positionOptions}
                fieldLabel="position"
                upgradeLabel="upgrade"
                onPressUpgrade={() => {}}
                icon={() => (
                  <FOHToolBoxIcon
                    style={{
                      width: 14,
                      height: 14,
                      tintColor: FOHColors.PACIFIC_BLUE
                    }}
                  />
                )}
                testID="campaign-select-position"
              ></FOHSearchableSelectDropdown>
            </FOHView>
          </FOHView>

          <FOHSpaceLarge />

          <FOHView style={{ width: 400, zIndex: 1 }}>
            {/* <FOHView style={{ flexDirection: 'row', alignItems: 'center' }}>
              <FOHLabelBold>{t('selectPlatformLabel')}</FOHLabelBold>
              <FOHRequiredMark
                style={{
                  alignSelf: 'flex-start',
                  marginTop: 1
                }}
              />
            </FOHView> */}
            <FOHSpace />
            <FOHView style={{ flexDirection: 'row' }}>
              {getPlatformsList.map((platform, index) => {
                const isIndeedDisabled =
                  platform.value === PLATFORM_TYPES.Indeed && platform.disabled;
                return (
                  <React.Fragment key={index}>
                    {isIndeedDisabled ? (
                      <FOHToolTip
                        helpText={t('notBeingConnected')}
                        open={isHovered}
                        type={FOHToolTipType.CENTER}
                        style={{
                          top: -70,
                          left: -64,
                          width: 300,
                          bottom: undefined
                        }}
                      />
                    ) : (
                      <></>
                    )}
                    <FOHView ref={isIndeedDisabled ? hoverRef : undefined}>
                      <FOHTouchableOpacity
                        style={{
                          //@ts-ignore pointerEvents
                          pointerEvents: platform.disabled ? 'none' : 'unset',
                          opacity: platform.disabled ? 0.5 : 1
                        }}
                        // onPress={() => setSelectedPlatform(platform.value)}
                        testID={`campaign-platform-${index}`}
                      >
                        <FOHView
                          style={{
                            alignItems: 'center',
                            background: FOHColors.WHITE,
                            border: `1px solid ${FOHColors.GRAYSCALE_300}`,
                            borderRadius: 4,
                            padding: 30
                          }}
                        >
                          {/* <FOHLabeledRadio
                            selected={platform.value === selectedPlatform}
                            label=""
                            setSelected={() => {
                              setSelectedPlatform(platform.value);
                            }}
                          />
                          <FOHSpace /> */}
                          <FOHImage
                            source={{ uri: platform.logo }}
                            style={{ height: 32, width: 125 }}
                          />
                        </FOHView>
                      </FOHTouchableOpacity>
                    </FOHView>
                    <FOHSpaceLarge />
                  </React.Fragment>
                );
              })}
            </FOHView>
          </FOHView>
          <FOHSpaceHuge />
          <FOHSpaceHuge />
        </FOHView>

        <FOHView
          style={{
            justifyContent: 'flex-end',
            paddingBottom: 15,
            backgroundColor: FOHColors.WHITE,
            width: '100%',
            minHeight: 72,
            flex: 1,
            zIndex: 1
          }}
        >
          <FOHDivider />
          <FOHSpace />
          <FOHView style={{ paddingHorizontal: 32 }}>
            <FOHFormFlowButtons
              nextButtonlabel={t('common:next')}
              onPressNextButton={handleNextPress}
              previousButtonLabel={t('common:cancel')}
              onPressPreviousButton={handleCancelPress}
              isNextDisabled={
                !isIndeedConnected ||
                !selectedPosition ||
                !name ||
                name.length < 4 ||
                // !selectedPlatform ||
                isLoading
              }
            />
          </FOHView>
        </FOHView>
      </FOHView>
    </FOHScrollView>
  );
};

export default CreateCampaign;
