import React from 'react';

import { Dimensions, View } from 'react-native';
import styled from 'styled-components/native';
import { path, prop, length } from 'ramda';
import LinearGradient from 'react-native-web-linear-gradient';

import {
  FullWidthCard,
  FOHProgressRocketIcon,
  FOHLabelBold,
  FOHSpaceSmall,
  FOHNextIcon,
  FOHPrevIcon,
  FOHDetailText,
  FOHInverseButton,
  FOHTouchableOpacity,
  FOHContrastText,
  FOHContrastDetailText,
  FOHColors
} from '../../ingredients';

export interface FOHProfileCompletionBlockProps {
  percentage: number;
  yourScoreLabel: string;
  score: string;
  completeProfileTitle: string;
  completeProfileDetailText: string;
  previousLabel: string;
  nextLabel: string;
  next: () => any;
  previous: () => any;
  stepIndex: number;
  steps: Array<FOHCompletionStep>;
  small?: boolean;
  readOnly?: boolean;
}

export interface FOHCompletionStep {
  title: string;
  detail: string;
  buttonLabel: string;
  onPress: () => any;
}

export const FOHProfileCompletionBlock = (
  props: FOHProfileCompletionBlockProps
) => {
  const lowCutoff = 20;
  const highCutoff = 80;

  const accentColor =
    props.percentage > lowCutoff
      ? props.percentage > highCutoff
        ? FOHColors.GREEN
        : FOHColors.GOLD
      : FOHColors.RED;

  const iconType =
    props.percentage > lowCutoff
      ? props.percentage > highCutoff
        ? 'green'
        : 'gold'
      : 'red';

  // mobile or small screen
  const mobile = props.small || prop('width', Dimensions.get('window')) < 1200;
  const barWidth = mobile ? 250 : 370;
  // 17 is half the size of the circle
  const left = barWidth * 0.01 * props.percentage - 17;
  // white break lines
  const firstBreakLeft = barWidth * 0.01 * lowCutoff - 4;
  const secondBreakLeft = barWidth * 0.01 * highCutoff - 4;

  const step = path(['steps', props.stepIndex], props) as FOHCompletionStep;

  return (
    <View
      style={{
        maxWidth: 600,
        width: '100%',
        justifyContent: mobile ? 'center' : 'flex-start',
        alignItems: mobile ? 'center' : 'flex-start'
      }}
    >
      <FullWidthCard
        shadow={true}
        style={{
          padding: 16,
          paddingBottom: props.readOnly ? 32 : 24,
          maxWidth: mobile ? '100%' : 600,
          // width: 455,
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {!props.readOnly && (
          <View
            style={{
              backgroundColor: accentColor,
              padding: 8,
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 6
            }}
          >
            <FOHContrastText style={{ fontSize: 16, textAlign: 'center' }}>
              {props.completeProfileTitle}
            </FOHContrastText>
            <FOHContrastDetailText
              style={{
                textAlign: 'center'
              }}
            >
              {props.completeProfileDetailText}
            </FOHContrastDetailText>
          </View>
        )}
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: 16,
            width: '100%'
          }}
        >
          <FOHLabelBold
            style={{
              textAlign: 'left'
            }}
          >
            {props.yourScoreLabel}
          </FOHLabelBold>
          <FOHSpaceSmall />
          <FOHProgressRocketIcon color={iconType} />
          <FOHSpaceSmall />
          <FOHLabelBold
            style={{
              textAlign: 'left',
              color: accentColor
            }}
          >
            {props.score}
          </FOHLabelBold>
        </View>
        <LinearGradient
          start={{ x: 0, y: 0 }}
          end={{ x: 1, y: 0 }}
          locations={[
            0,
            (props.percentage || 1) * 0.0099,
            (props.percentage || 1) * 0.01,
            1
          ]}
          colors={['#fefefe', accentColor, '#DBDBDB', '#DBDBDB']}
          style={{
            width: barWidth,
            height: 8,
            justifyContent: 'center',
            borderRadius: 6
          }}
        >
          <Break style={{ left: firstBreakLeft }} />
          <Break style={{ left: secondBreakLeft }} />
          <Circle style={{ backgroundColor: accentColor, left }}>
            <FOHContrastText
              style={{
                fontSize: 10
              }}
            >{`${props.percentage}%`}</FOHContrastText>
          </Circle>
        </LinearGradient>
        {!props.readOnly && length(prop('steps', props)) > 0 && (
          <>
            <View
              style={{
                paddingTop: 16,
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%'
              }}
            >
              <FOHTouchableOpacity
                onPress={props.previous}
                disabled={props.stepIndex === 0}
                style={{
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <FOHPrevIcon
                  style={{
                    tintColor: props.stepIndex === 0 ? '#BDBDBD' : '#000000'
                  }}
                />
                <FOHLabelBold
                  style={{
                    color: props.stepIndex === 0 ? '#BDBDBD' : '#000000'
                  }}
                >
                  {props.previousLabel}
                </FOHLabelBold>
              </FOHTouchableOpacity>
              <FOHTouchableOpacity
                onPress={props.next}
                disabled={props.stepIndex === length(props.steps) - 1}
                style={{
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <FOHLabelBold
                  style={{
                    color:
                      props.stepIndex === length(props.steps) - 1
                        ? '#BDBDBD'
                        : '#000000'
                  }}
                >
                  {props.nextLabel}
                </FOHLabelBold>
                <FOHNextIcon
                  style={{
                    tintColor:
                      props.stepIndex === length(props.steps) - 1
                        ? '#BDBDBD'
                        : '#000000'
                  }}
                />
              </FOHTouchableOpacity>
            </View>
            {step && (
              <View
                style={{
                  width: '100%',
                  padding: 16,
                  paddingBottom: 0
                }}
              >
                <FOHLabelBold
                  style={{
                    textAlign: 'left'
                  }}
                >{`${props.stepIndex + 1}. ${path(
                  ['title'],
                  step
                )}`}</FOHLabelBold>
                <FOHDetailText
                  style={{
                    textAlign: 'left',
                    lineHeight: 20,
                    fontSize: 12
                  }}
                >
                  {path(['detail'], step)}
                </FOHDetailText>
                <View
                  style={{
                    paddingTop: 8,
                    width: '100%',
                    alignItems: 'center'
                  }}
                >
                  <FOHInverseButton
                    onPress={prop('onPress', step)}
                    title={prop('buttonLabel', step)}
                    color={'#000000'}
                    style={{
                      maxWidth: '100%'
                    }}
                  />
                </View>
              </View>
            )}
          </>
        )}
      </FullWidthCard>
    </View>
  );
};

const Circle = styled(View)`
  border-width: 2px;
  border-color: #ffffff;
  border-radius: 40px;
  width: 34px;
  height: 34px;
  position: absolute;
  justify-content: center;
  align-items: center;
`;

const Break = styled(View)`
  width: 8px;
  height: 32px;
  background-color: #ffffff;
  position: absolute;
`;
