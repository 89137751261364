export const toPascalCase = value => {
  return `${value.substr(0, 1)}${value.substr(1).toLowerCase()}`;
};

export const pluralize = (singular, plural) => num => {
  return num === 1 ? singular : plural;
};

export const multiWordToCamelCase = str =>
  str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
      index === 0 ? word.toLowerCase() : word.toUpperCase()
    )
    .replace(/\s+/g, '');

export const extractStringBetweenRoundBrackets = inputString => {
  const regex = /\(([^)]+)\)/;
  const match = regex.exec(inputString);

  return match && match[1] ? match[1] : null;
};

export const getInitials = person => {
  const firstInitial = person?.firstName?.charAt(0)?.toUpperCase() || '';
  const lastInitial = person?.lastName?.charAt(0)?.toUpperCase() || '';
  return `${firstInitial}${lastInitial}`;
};
