import React from 'react';

import { View } from 'react-native';
import styled from 'styled-components/native';
import { length } from 'ramda';

import { FOHSelectionCell } from '../../recipes/cells/FOHSelectionCell';
import {
  FOHErrorLabel,
  FOHHeaderH3,
  FOHSpace,
  FOHView,
  FOHHeaderH2
} from '../../ingredients';
import { FOHSelectItemProps } from '../../recipes';

import { FOHOpenSearch, FOHOpenSearchProps } from '../search/FOHOpenSearch';

import { FOHForm } from './FOHForm';

export interface FOHMultiSelectSearchFormProps extends FOHOpenSearchProps {
  formTitle: string;
  formDetailLabel?: string;
  mobile?: boolean;
  handleSubmit: () => void;
  error?: string;
  options?: Array<FOHIndustrySelectOptionProps>;
  selectedIndustry?: string;
  setSelectedIndustry?: (val: string) => void;
}

export interface FOHIndustrySelectOptionProps extends FOHSelectItemProps {
  icon?: any;
}

export const FOHMultiSelectSearchForm = (
  props: FOHMultiSelectSearchFormProps
) => (
  <FOHForm handleSubmit={props.handleSubmit}>
    <FormContainer mobile={props.mobile}>
      <FOHHeaderH2 style={{ textAlign: 'left' }}>{props.formTitle}</FOHHeaderH2>
      <FOHSpace />
      <FOHView
        style={{
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: 'row',
          display: 'flex',
          flexWrap: 'wrap'
        }}
      >
        {!!props.options &&
          length(props.options) > 0 &&
          props.options.map((option: any, i: number) => (
            <FOHSelectionCell
              key={i}
              minWidth={props.mobile ? '100%' : '48%'}
              label={option.label}
              icon={!!option.icon && option.icon}
              value={option.value}
              onPress={() => {
                props.setSelectedIndustry &&
                  props.setSelectedIndustry(option.value);
              }}
              selected={option.value === props.selectedIndustry}
            />
          ))}
      </FOHView>
      <FOHSpace />
      <FOHHeaderH3 style={{ textAlign: 'left' }}>
        {props.formDetailLabel}
      </FOHHeaderH3>
      <FOHSpace />
      <FOHErrorLabel>{props.error}</FOHErrorLabel>
      {!!props.error && <FOHSpace />}
      <FOHOpenSearch {...props} />
    </FormContainer>
  </FOHForm>
);

const FormContainer = styled(View)<{ mobile?: boolean }>`
  padding: ${(p: any) => (p.mobile ? '10px' : '32px')};
  width: 100%;
  background: #fcfcfc;
  border-radius: 24px;
`;
