import React from 'react';

import { prop, defaultTo, path } from 'ramda';
import { useTranslation } from 'react-i18next';

import { useIsMobile, toDateTime, unwrapEdgesAt } from '../../utils';
import { useGetCandidateWithApplicantsQuery } from '../../graphql/generated';
import { FOHMultiPositionForm, FOHStatusType, FOHView } from '../../components';

import { withReactiveModal } from '../Navigation/withReactiveModal';

const MultiPositionForm = props => {
  const { isInSettings } = props;
  const { t } = useTranslation('CandidateFeed');
  const { isMobile } = useIsMobile();

  const { candidateHandle } = props;

  const candidateQuery = useGetCandidateWithApplicantsQuery({
    skip: !candidateHandle,
    variables: {
      first: 5,
      handle: candidateHandle
    }
  });

  const profile = path(['data', 'profile'], candidateQuery);

  const status = defaultTo('ACTIVELY_LOOKING', prop('status', profile));

  const candidateName = path(
    ['data', 'profile', 'user', 'firstName'],
    candidateQuery
  )
    ? `${path(['data', 'profile', 'user', 'firstName'], candidateQuery)} ${path(
        ['data', 'profile', 'user', 'lastName'],
        candidateQuery
      )}`
    : '';

  const applications = unwrapEdgesAt(
    0,
    defaultTo([], path(['applicationsForEmployer', 'edges'], profile))
  ).map(application => ({
    positionName: defaultTo(
      '',
      path(['position', 'positionName'], application)
    ),
    locationName: defaultTo(
      '',
      path(['position', 'location', 'name'], application)
    ),
    formattedAddress: path(
      ['position', 'location', 'formattedAddress'],
      application
    ),
    applicationDate: toDateTime(path(['createdAt'], application))
  }));

  return (
    <FOHView
      style={{
        alignItems: 'flex-start',
        backgroundColor: isMobile ? '#ffffff' : undefined,
        width: isInSettings || isMobile ? '100%' : 627,
        paddingLeft: 60,
        paddingRight: 60,
        paddingTop: 24
      }}
    >
      <FOHMultiPositionForm
        title={t('candidateAppliedMoreLabel')}
        name={candidateName}
        photo={path(['user', 'profileImage'], profile)}
        positions={defaultTo([], prop('positions', profile)).map(pos =>
          t(`common:positions:${pos}`)
        )}
        statusType={
          status === 'APPLIED_TO_YOU' ? FOHStatusType.ACTIVELY_LOOKING : status
        }
        statusText={t(`common:candidateStatusType.${status}`)}
        appliedForLabel={t('CandidateFeed:appliedFor')}
        locationLabel={t('common:location')}
        applicationDateLabel={t('CandidateFeed:applicationDate')}
        applications={applications}
      />
    </FOHView>
  );
};

export const MultiPositionDetailModal = withReactiveModal(MultiPositionForm);
