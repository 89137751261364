import { useState } from 'react';

import { path, prop, toLower } from 'ramda';

import { handle, handleMutation } from '../../utils';
import {
  SearchInterviewPositionsDocument,
  useMutateInterviewMutation
} from '../../graphql/generated';
import {
  HOME_PAGE,
  INTERVIEW_ADD_TO_CALENDAR_PATH,
  INTERVIEW_SCHEDULE_PATH,
  REQUESTS
} from '../../constants';
import { useNavigation } from '../../utils/navigation';
import { FOHNotificationType } from '../../components';

export const getScheduleError = path([
  'data',
  'mutateInterview',
  'errors',
  0,
  'messages',
  0
]);

export const useRespondToInterviewRequest = () => {
  const { navigateTo } = useNavigation();

  const [interviewMutation] = useMutateInterviewMutation();
  const [error, setError] = useState(false);
  const [accepting, setAccepting] = useState(false);
  const [declining, setDeclining] = useState(false);
  const [scheduling, setScheduling] = useState(false);

  const acceptInterviewRequest = async ({
    interviewId,
    uuid,
    schedulingEnabled
  }) => {
    if (!schedulingEnabled) {
      setAccepting(true);
      const [acceptInterviewResp, errorOnAccept] = await handleMutation(
        interviewMutation({
          variables: {
            id: interviewId,
            status: 'accepted'
          },
          refetchQueries: [
            {
              query: SearchInterviewPositionsDocument,
              variables: {
                after: '',
                first: 10,
                hasInterview: true,
                interviewStatus: 'ACCEPTED',
                searchTerms: []
              }
            }
          ]
        })
      );

      setAccepting(false);

      if (prop('error', acceptInterviewResp) || errorOnAccept) {
        return setError(true);
      }

      navigateTo(`${INTERVIEW_ADD_TO_CALENDAR_PATH}/${uuid}`);
    } else {
      // skip accept and go onto schedule to prevent sending multiple emails
      // and we want candidates to schedule whenever possible
      navigateTo(`${INTERVIEW_SCHEDULE_PATH}/${uuid}/token/`);
    }
  };

  const declineInterviewRequest = async ({ interviewId }) => {
    setDeclining(true);

    const [declineInterviewResp, errorOnDecline] = await handleMutation(
      interviewMutation({
        variables: {
          id: interviewId,
          status: 'declined'
        },
        refetchQueries: [
          {
            query: SearchInterviewPositionsDocument,
            variables: {
              after: '',
              first: 10,
              hasInterview: true,
              interviewStatus: 'PENDING',
              searchTerms: []
            }
          }
        ]
      })
    );

    if (prop('error', declineInterviewResp) || errorOnDecline) {
      return setError(true);
    }

    setDeclining(false);

    navigateTo(`${HOME_PAGE}${REQUESTS}`);
  };

  const scheduleInterview = async ({
    interviewId,
    uuid,
    selectedTime,
    noneOfTheseTimesWork,
    candidateCustomMessage = ''
  }) => {
    setScheduling(true);
    if (noneOfTheseTimesWork) {
      const [scheduleInterviewResp, errorOnSchedule] = await handle(
        interviewMutation({
          variables: {
            id: interviewId,
            status: toLower(FOHNotificationType.UNSCHEDULED),
            candidateMessage: candidateCustomMessage
          },
          refetchQueries: [
            {
              query: SearchInterviewPositionsDocument,
              variables: {
                after: '',
                first: 10,
                hasInterview: true,
                interviewStatus: 'ACCEPTED',
                searchTerms: []
              }
            }
          ]
        })
      );

      setScheduling(false);

      if (errorOnSchedule || getScheduleError(scheduleInterviewResp)) {
        return setError(
          errorOnSchedule || getScheduleError(scheduleInterviewResp)
        );
      }

      // go to old accepted screen
      navigateTo(`${INTERVIEW_ADD_TO_CALENDAR_PATH}/${uuid}`);
    } else if (selectedTime) {
      const [
        interviewScheduleSelectedTimeResp,
        errorOnInterviewScheduleSelectedTime
      ] = await handle(
        interviewMutation({
          variables: {
            id: interviewId,
            scheduledFor: selectedTime
          },
          refetchQueries: [
            {
              query: SearchInterviewPositionsDocument,
              variables: {
                after: '',
                first: 10,
                hasInterview: true,
                interviewStatus: 'ACCEPTED',
                searchTerms: []
              }
            }
          ]
        })
      );

      setScheduling(false);

      if (
        errorOnInterviewScheduleSelectedTime ||
        getScheduleError(interviewScheduleSelectedTimeResp)
      ) {
        return setError(
          errorOnInterviewScheduleSelectedTime ||
            getScheduleError(interviewScheduleSelectedTimeResp)
        );
      }

      navigateTo(`${INTERVIEW_ADD_TO_CALENDAR_PATH}/${uuid}`);
    }
  };

  return {
    error,
    accepting,
    declining,
    scheduling,
    acceptInterviewRequest,
    declineInterviewRequest,
    scheduleInterview
  };
};
