import React, { useEffect, useState } from 'react';

import { Platform } from 'react-native';
import { path } from 'ramda';

import { FOHView } from '../../ingredients';

export interface FOHFormProps {
  handleSubmit?: () => any;
  disabledSubmit?: boolean;
  children: any;
  id?: string;
}
// Helper function for text fields that trigger submit
export const onEnterKeyPresed = (e: any, props: any) => {
  const key = path(['nativeEvent', 'key'], e);
  if (key === 'Enter') {
    !props.disabledSubmit && props.handleSubmit && props.handleSubmit();
  }
};

const XPForm = (props: any) =>
  Platform.OS === 'web' ? (
    <form id={props.id} onSubmit={props.onSubmit}>
      {props.children}
    </form>
  ) : (
    <FOHView>{props.children}</FOHView>
  );

const XPSubmit = () =>
  Platform.OS === 'web' ? (
    <input
      type="submit"
      value="Submit"
      style={{
        display: 'none'
      }}
    />
  ) : (
    <></>
  );

export const FOHForm = (props: FOHFormProps) => {
  const keyPressed = useKeyPressWeb('Enter');
  useEffect(() => {
    if (keyPressed) {
      !props.disabledSubmit && props.handleSubmit && props.handleSubmit();
    }
  }, [keyPressed]);

  return (
    <XPForm
      id={props.id}
      onSubmit={props.disabledSubmit ? () => {} : props.handleSubmit}
    >
      {props.children}
      <XPSubmit />
    </XPForm>
  );
};

const useKeyPressWeb = (targetKey: string) => {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false);

  // If pressed key is our target key then set to true
  const downHandler = ({ key }: { key: string }) => {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  };

  // If released key is our target key then set to false
  const upHandler = ({ key }: { key: string }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  // Add event listeners
  useEffect(() => {
    if (Platform.OS === 'web') {
      window.addEventListener('keydown', downHandler);
      window.addEventListener('keyup', upHandler);
      // Remove event listeners on cleanup
      return () => {
        window.removeEventListener('keydown', downHandler);
        window.removeEventListener('keyup', upHandler);
      };
    }
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return keyPressed;
};
