import React from 'react';

import { compose, path, defaultTo, prop } from 'ramda';
import { withTranslation } from 'react-i18next';

import { formatFollowerCountLabel } from '../../utils';
import { EMPLOYER_SETTINGS_MENU, LOCATION } from '../../constants';
import { useNavigation } from '../../utils/navigation';
import { FOHJobPositionLocationCard } from '../../components';

const JobLocationDetails = props => {
  const { t, positionQuery, me } = props;
  const { navigateTo } = useNavigation();

  const position = path(['data', 'position'], positionQuery);
  const location = path(['location'], position);
  const tags = defaultTo([], path(['tags', 'edges'], location)).map(node => ({
    ...prop('node', node)
  }));

  const canEditPosition =
    defaultTo('', path(['employerProfile', 'id'], position)) ===
      defaultTo('', path(['employerProfile', 'id'], me)) &&
    path(['employerProfile', 'id'], me);

  return (
    <FOHJobPositionLocationCard
      aboutEmployerLabel={t('aboutEmployerLabel')}
      summaryLabel={t('summaryLabel')}
      // TODO: make a string generator util for this badge
      followersLabel={formatFollowerCountLabel({
        followerCount: prop('followerCount', location),
        t
      })}
      tags={tags}
      {...location}
      editLabel={t('PositionsFeature:editLocationLabel')}
      onEditPress={
        canEditPosition
          ? () => navigateTo(`${EMPLOYER_SETTINGS_MENU}${LOCATION}`)
          : undefined
      }
    ></FOHJobPositionLocationCard>
  );
};

export default compose(withTranslation('JobApplicationsFeature'))(
  JobLocationDetails
);
