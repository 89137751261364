import React from 'react';

import { ImageStyle } from 'react-native';

import upload_icon from '../../../images/upload_icon.svg';

import { FOHImage } from '../../ingredients';

interface FOHUploadIconProps {
  style?: ImageStyle;
}

export const FOHUploadIcon = (props: FOHUploadIconProps) => (
  <FOHImage
    source={{ uri: upload_icon }}
    style={{ width: 18, height: 20, ...props.style }}
  />
);
