import {
  REGISTER_URL,
  LOGIN_URL,
  PASSWORD_RESET_REQUEST_URL,
  PASSWORD_RESET_CONFIRM_URL
} from '../constants/urls';

import api from './index';

export const login = credentials => {
  return api.post(LOGIN_URL, credentials, {
    headers: { Authorization: undefined }
  });
};

export const register = async credentials => {
  const response = await api.post(REGISTER_URL, credentials);
  return response;
};

export const logout = async () => {
  return new Promise(resolve => {
    if (localStorage) localStorage.clear();
    if (sessionStorage) sessionStorage.clear();

    resolve();
  });
};

export const passwordResetRequest = email => {
  return api.post(PASSWORD_RESET_REQUEST_URL, email);
};

export const passwordResetConfirmation = data => {
  return api.post(PASSWORD_RESET_CONFIRM_URL, data);
};
