import styled from 'styled-components/native';
import { Text } from 'react-native';

import { FOHFonts } from './FOHFonts';

export const FOHErrorLabel = styled(Text)`
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: #e5253c;
  padding-top: 4px;
`;
