import React from 'react';

import { StyleProp, ImageStyle } from 'react-native';

import x_icon from '../../../images/x_icon.svg';

import { FOHImage } from '../../ingredients';

const largeSize = { width: 32, height: 32 };
const baseSize = { width: 24, height: 25 };

export const FOHXIcon = ({
  large,
  style,
  testID
}: {
  large?: boolean;
  style?: StyleProp<ImageStyle>;
  testID?: string;
}) => (
  <FOHImage
    source={{ uri: x_icon }}
    style={[large ? largeSize : baseSize, style]}
    testID={testID}
  />
);
