import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo
} from 'react';

import { useReactToPrint } from 'react-to-print';
import { useTranslation } from 'react-i18next';
import { prop, path, defaultTo, length } from 'ramda';

import {
  FOHLabel,
  FOHColors,
  FOHView,
  FOHImage,
  FOHInverseButton,
  FOHScrollView,
  FOHTouchableOpacity,
  FOHSpace,
  FOHSpaceSmall,
  FOHStatusType,
  FOH7ShiftsIcon,
  FOHContactMeIcon,
  FOHLeftArrow,
  FOHResumeIcon,
  FOHRightArrow,
  FOHSendMessageIcon,
  FOHDivider,
  FOHButtonBordered,
  FOHButton,
  FOHDirectMessageIcon,
  FOHIconDropdownButton,
  FOHMessageFormSurveyIcon,
  FOHChatCreateNewIcon,
  FOHCalendarScheduledIcon,
  FOHTextButton,
  FOHFonts,
  FOHTabList,
  FOHCircleLoader,
  FOHDetailText,
  FOHHeaderH3,
  FOHSmallDetailLabel,
  FOHCandidateInboxListBlock,
  FOHProfileInterviewBlock,
  FOHEmptyStageCell,
  FOHNoResumeCell,
  FOHGetMoreApplicantsCard,
  FOHBanner,
  FOHEmployerStagesType,
  FOHCandidateListSortBy
} from '../../components';
import {
  envVariables,
  BULK_MESSAGE,
  CAMPAIGN_CREATE,
  CANDIDATE_PROFILE,
  FEED,
  getAppBaseUrl,
  INTERVIEW_STATUS_NOTSCHEDULED,
  LOCATION_PAYMENT_STATUS_SUBSCRIBED,
  NETWORK,
  SCREENING_CHOICES,
  SEND_OFFER,
  SEND_OR_SET,
  SEND_SCHEDULE,
  SEND_TO_7SHIFT,
  WELCOME
} from '../../constants';
import {
  handleMutation,
  isScrollCloseToBottom,
  useIsMobile,
  openNewTab,
  AmplitudeTrackingEvents,
  useTrackAmplitudeEvent,
  unwrapEdgesWithoutNode,
  toDateTime,
  unwrapEdges
} from '../../utils';
import no_resume_empty from '../../images/no_resume_empty.svg';
import indeed_logo from '../../images/indeed_logo.svg';
import {
  useEmployerCandidateNotificationsQuery,
  useGetCandidateWithApplicantsQuery,
  useGetIsFollowingLocationsQuery,
  usePositionByIdQuery,
  useGetPreferredMatchQuery,
  usePositionApplicationMutation,
  useInterviewScheduleQuery,
  useEmployerCandidateQuestionnairesQuery
} from '../../graphql/generated';
import { useNavigation } from '../../utils/navigation';

import { CandidateProfileOverview } from '../CandidateProfile/CandidateProfileOverview';
import { LogoLoader } from '../Loading/LogoLoader';
import { RejectButton } from '../EmployerRejection/RejectButton';
import { PreferredMatchSelect } from '../PreferredMatch/PreferredMatchSelect';
import { useCandidateRequestInterview } from '../InterviewRequest/useCandidateRequestInterview';
import { useBanner } from '../Navigation/useBanner';
import { useGetMeData } from '../SignIn';

import { CandidatePositionOverview } from './CandidatePositionOverview';
import { NotificationBanner } from './NotificationBanner';
import { CandidateInboxCell } from './CandidateInboxCell';
import { NoApplicantsBanner } from './NoApplicantsBanner';
import { FreePassiveCandidatesBanner } from './FreePassiveCandidatesBanner';
import { MultiCandidateSelectionDetail } from './MultiCandidateSelectionDetail';
import { NoPositionsMultiCandidateDetail } from './NoPositionsMultiCandidateDetail';
import { CandidateNotes } from './CandidateNotes';
import { PrintableCandidateProfile } from './PrintableCandidateProfile';
import { NeedMoreCandidateCard } from './NeedMoreCandidateCard';
import { useStages } from './useStages';
import { useCandidateFeedContext } from './useCandidateFeedContext';
import { CandidateScreeningDetail } from './CandidateScreeningDetail';

const ContactIcon = props => (
  <FOHContactMeIcon
    style={{ height: 22, width: 24, top: -2, ...props?.style }}
  />
);

const ResumeIcon = props => (
  <FOHResumeIcon style={{ height: 22, width: 24, top: -2, ...props?.style }} />
);

export const CandidateInboxDetail = props => {
  const {
    search,
    loadingCandidates,
    setVideo,
    candidateHandle,
    setCandidateHandle,
    setEmployerCandidate,
    setNextCandidate,
    selectedTab,
    setSelectedTab,
    candidateFeedQuery,
    addIgnoredStages,
    setPageTitle,
    hasInterviewSchedule
  } = props;
  const { t } = useTranslation('CandidateFeed');

  const candidateProfileRef = useRef();

  const [loadingMore, setLoadingMore] = useState(false);
  const [canShowPayWall, setShowPaywall] = useState(0);
  const [isMounted, setIsMounted] = useState(false);
  const [isOpenMoreOptions, setIsOpenMoreOptions] = useState(false);
  const { banner, setBanner } = useBanner();
  const { navigateTo } = useNavigation();

  const candidateFeedContext = useCandidateFeedContext();

  const {
    activeStageId,
    positionFilters,
    locationFilters,
    allCandidates,
    setAllCandidates,
    selectedEmployerCandidates,
    multiSelectEmployerCandidate,
    clearEmployerCandidates,
    movingEmployerCandidateId,
    notificationStatusFilter,
    setNotificationStatusFilter,
    notificationMessage,
    sortBy
  } = candidateFeedContext;

  const multiSelectActive =
    length(selectedEmployerCandidates) > 1 || allCandidates;

  const positionQuery = usePositionByIdQuery({
    skip: !prop(0, positionFilters) || length(positionFilters) > 1,
    variables: { id: prop(0, positionFilters) }
  });

  const { data: me } = useGetMeData();
  const userRole = path(['employerProfileUser', 'role'], me);

  const integrations = defaultTo([], prop('integrations', me));

  const isEnabled7Shifts = integrations.includes('sevenshifts');

  const {
    moveEmployerCandidates,
    skipToStages,
    backToStages,
    interviewStage,
    currentStage,
    passiveStage,
    notificationStatuses,
    appliedStage,
    screeningStage,
    offeredStage,
    hiredStage,
    rejectedStage
  } = useStages({ activeStageId, addIgnoredStages });

  const canBulkEdit = prop('id', passiveStage) !== activeStageId;

  const employerCandidateNotificationsQuery =
    useEmployerCandidateNotificationsQuery({
      skip: !activeStageId,
      variables: {
        stageId: activeStageId,
        employerCandidateIds: [
          prop('id', props.employerCandidate)
            ? prop('id', props.employerCandidate)
            : path(['employerCandidate', 'id'], props)
        ].filter(id => !!id),
        first: 1,
        positions: positionFilters,
        locations: locationFilters
      }
    });

  const employerCandidate = path(
    ['data', 'employerCandidates', 'edges', 0, 'node'],
    employerCandidateNotificationsQuery
  );
  const hasEmployerCandidateId = !!prop('id', employerCandidate);

  const interviewScheduleQuery = useInterviewScheduleQuery({});

  const employerCandidateQuestionnairesQuery =
    useEmployerCandidateQuestionnairesQuery({
      skip: !hasEmployerCandidateId,
      variables: {
        employerCandidate: prop('id', employerCandidate)
      }
    });

  const getScreeningDataByType = type => {
    const candidateQuestionnaires = defaultTo(
      [],
      path(
        ['data', 'employerCandidateQuestionnaires', 'edges'],
        employerCandidateQuestionnairesQuery
      )
    );

    const sortedQuestionnaires = candidateQuestionnaires
      .map((questionnaire, index) => ({
        index,
        submittedAt: path(['node', 'submittedAt'], questionnaire)
      }))
      .filter(questionnaire => questionnaire.submittedAt)
      .sort((a, b) => new Date(b.submittedAt) - new Date(a.submittedAt));

    const index = sortedQuestionnaires.findIndex(questionnaire => {
      const questionnaireType = path(
        ['node', 'screeningQuestionnaire', 'questionnaireType'],
        candidateQuestionnaires[questionnaire.index]
      );
      return questionnaireType === type;
    });

    if (index === -1) {
      return [];
    }

    return candidateQuestionnaires[sortedQuestionnaires[index].index];
  };

  const prescreener = useMemo(() => {
    const employerCandidateQuestionnaire = getScreeningDataByType('PRESCREEN');

    return {
      questions: defaultTo(
        [],
        unwrapEdgesWithoutNode(
          path(
            ['node', 'screeningQuestionnaire', 'screeningQuestion', 'edges'],
            employerCandidateQuestionnaire
          )
        )
      ),
      employerCandidateScreeningQuestionnaire: employerCandidateQuestionnaire
    };
  }, [employerCandidateQuestionnairesQuery]);

  const screeningQuestions = useMemo(() => {
    const employerCandidateQuestionnaire = getScreeningDataByType('TEXT');

    return {
      questions: defaultTo(
        [],
        unwrapEdgesWithoutNode(
          path(
            ['node', 'screeningQuestionnaire', 'screeningQuestion', 'edges'],
            employerCandidateQuestionnaire
          )
        )
      ),
      employerCandidateScreeningQuestionnaire: employerCandidateQuestionnaire
    };
  }, [employerCandidateQuestionnairesQuery]);

  const screeningVideo = useMemo(() => {
    const employerCandidateQuestionnaire = getScreeningDataByType('VIDEO');

    return {
      videoQuestionnaires: defaultTo(
        [],
        unwrapEdgesWithoutNode(
          path(
            ['node', 'screeningQuestionnaire', 'screeningQuestion', 'edges'],
            employerCandidateQuestionnaire
          )
        )
      ),
      employerCandidateScreeningVideoQuestionnaire:
        employerCandidateQuestionnaire
    };
  }, [employerCandidateQuestionnairesQuery]);

  const prescreenAnswers = useMemo(() => {
    if (length(prescreener?.questions) > 0) {
      return prescreener?.questions.map(edge => ({
        questionText: path(['node', 'answers', 0, 'questionText'], edge),
        answers: path(['node', 'answers'], edge).map(answer => {
          return path(['answer'], answer);
        })
      }));
    }
    return [];
  }, [prescreener]);

  const prescreenCompletedAt = useMemo(() => {
    if (length(prescreener?.questions) > 0) {
      const submittedAt = path(
        ['node', 'submittedAt'],
        prescreener?.employerCandidateScreeningQuestionnaire
      );
      return submittedAt ? toDateTime(submittedAt) : null;
    }
    return null;
  }, [prescreener]);

  const screeningAnswers = useMemo(() => {
    if (length(screeningQuestions?.questions) > 0) {
      return screeningQuestions?.questions.map(edge => ({
        questionText: path(['node', 'answers', 0, 'questionText'], edge),
        answers: path(['node', 'answers'], edge).map(answer => {
          return path(['answer'], answer);
        })
      }));
    }
    return [];
  }, [screeningQuestions]);

  const screeningQuestionsCompletedAt = useMemo(() => {
    if (length(screeningQuestions?.questions) > 0) {
      const submittedAt = path(
        ['node', 'submittedAt'],
        screeningQuestions?.employerCandidateScreeningQuestionnaire
      );
      return submittedAt ? toDateTime(submittedAt) : null;
    }
    return null;
  }, [screeningQuestions]);

  const videoAnswer = useMemo(() => {
    if (length(screeningVideo?.videoQuestionnaires) > 0) {
      const sortedVideo = screeningVideo?.videoQuestionnaires
        ?.map(edge => ({
          uploadAt: path(['node', 'answers', 0, 'updatedAt'], edge),
          file: path(['node', 'answers', 0, 'screeningAnswerVideo'], edge)
        }))
        .filter(elm => elm.file)
        .sort((a, b) => new Date(b.uploadAt) - new Date(a.uploadAt));

      return sortedVideo[0] || {};
    }
    return {};
  }, [screeningVideo]);

  const screeningVideoCompletedAt = useMemo(() => {
    if (videoAnswer) {
      const uploadAt = prop('uploadAt', videoAnswer);
      return uploadAt ? toDateTime(uploadAt) : null;
    }
    return null;
  }, [videoAnswer]);

  const schedulingEnabled = useMemo(() => {
    return path(
      ['data', 'me', 'interviewschedule', 'enabled'],
      interviewScheduleQuery
    );
  }, [interviewScheduleQuery]);

  const promptSchedule =
    !hasInterviewSchedule || (hasInterviewSchedule && !schedulingEnabled);

  // candidate queries
  const candidateQuery = useGetCandidateWithApplicantsQuery({
    skip: !candidateHandle,
    variables: {
      first: 5,
      handle: candidateHandle
    }
  });

  const getPreferredMatchQuery = useGetPreferredMatchQuery({
    skip: !hasEmployerCandidateId,
    variables: {
      employerCandidateId: prop('id', employerCandidate)
    }
  });

  const sendOfferActionConfig = useCallback(() => {
    return {
      actionLabel: t('sendAnOfferLabel'),
      onActionClick: () => navigateTo(`${FEED}${SEND_OFFER}`),
      icon: () => (
        <FOHMessageFormSurveyIcon
          style={{ width: 16, height: 16, tintColor: FOHColors.GRAYSCALE_G1 }}
        />
      )
    };
  }, []);

  const sendWelcomeActionConfig = useCallback(() => {
    return {
      actionLabel: t('ChatFeature:sendWelcomeLabbel'),
      onActionClick: () => navigateTo(`${FEED}${WELCOME}`),
      icon: () => (
        <FOHChatCreateNewIcon
          style={{ width: 16, height: 16, tintColor: FOHColors.GRAYSCALE_G1 }}
        />
      )
    };
  }, []);

  const sendInterviewActionConfig = useCallback(() => {
    return {
      actionLabel: t('InterviewFeature:scheduleInterview'),
      onActionClick: () => navigateTo(`${FEED}${SEND_OR_SET}`),
      icon: () => (
        <FOHCalendarScheduledIcon
          style={{ width: 16, height: 16, tintColor: FOHColors.GRAYSCALE_G1 }}
        />
      )
    };
  }, []);

  const sendScreeningActionConfig = useCallback(() => {
    return {
      actionLabel: t('sendScreeningLabel'),
      onActionClick: () => navigateTo(`${FEED}${SCREENING_CHOICES}`),
      icon: () => (
        <FOHMessageFormSurveyIcon
          style={{ width: 16, height: 16, tintColor: FOHColors.GRAYSCALE_G1 }}
        />
      )
    };
  }, []);

  const commonFeedActionButtonsConfig = {
    primaryButton: {
      buttonLabel: t('InterviewFeature:scheduleInterview'),
      onPress: () => navigateTo(`${FEED}${SEND_OR_SET}`)
    },
    secondaryButton: {
      buttonLabel: t('sendScreeningLabel'),
      onPress: () => navigateTo(`${FEED}${SCREENING_CHOICES}`)
    },
    additionalActions: [sendOfferActionConfig(), sendWelcomeActionConfig()]
  };

  const stageFeedActionButtonsConfig = {
    PASSIVE_CANDIDATES: commonFeedActionButtonsConfig,
    APPLICANTS: commonFeedActionButtonsConfig,
    SCREENING: commonFeedActionButtonsConfig,
    INTERVIEWS: {
      primaryButton: {
        buttonLabel: t('sendAnOfferLabel'),
        onPress: () => navigateTo(`${FEED}${SEND_OFFER}`)
      },
      secondaryButton: {
        buttonLabel: t('InterviewFeature:scheduleInterview'),
        onPress: () => navigateTo(`${FEED}${SEND_OR_SET}`)
      },
      additionalActions: [
        sendScreeningActionConfig(),
        sendWelcomeActionConfig()
      ]
    },
    OFFERED: {
      primaryButton: {
        buttonLabel: t('ChatFeature:sendWelcomeLabbel'),
        onPress: () => navigateTo(`${FEED}${WELCOME}`)
      },
      secondaryButton: {
        buttonLabel: t('sendAnOfferLabel'),
        onPress: () => navigateTo(`${FEED}${SEND_OFFER}`)
      },
      additionalActions: [
        sendScreeningActionConfig(),
        sendInterviewActionConfig()
      ]
    },
    HIRED: {
      primaryButton: {
        buttonLabel: t('ChatFeature:sendWelcomeLabbel'),
        onPress: () => navigateTo(`${FEED}${WELCOME}`)
      },
      additionalActions: [
        sendScreeningActionConfig(),
        sendInterviewActionConfig(),
        sendOfferActionConfig()
      ]
    },
    REJECTED: {
      additionalActions: [
        sendScreeningActionConfig(),
        sendInterviewActionConfig(),
        sendOfferActionConfig(),
        sendWelcomeActionConfig()
      ]
    }
  };

  const activeStageFeedActionButtons = useMemo(() => {
    return stageFeedActionButtonsConfig[currentStage?.stageType];
  }, [currentStage]);

  useEffect(() => {
    if (hasEmployerCandidateId) {
      getPreferredMatchQuery.refetch();
    }
  }, [employerCandidate]); //eslint-disable-line

  const isFollowingQuery = useGetIsFollowingLocationsQuery({
    variables: {
      handle: candidateHandle,
      locations: locationFilters
    }
  });

  const [positionApplicationMutation] = usePositionApplicationMutation();

  const isFollowing = path(
    ['data', 'profile', 'isFollowingLocations'],
    isFollowingQuery
  );

  useEffect(() => {
    if (prop('stageType', currentStage) === 'PASSIVE_CANDIDATES') {
      const hasPaidForAPosition =
        defaultTo([], candidateFeedContext.myPositions).filter(
          position =>
            prop('paymentStatus', position) ===
            LOCATION_PAYMENT_STATUS_SUBSCRIBED
        ).length > 0;
      setShowPaywall(
        !hasPaidForAPosition &&
          props.selectedUnpaidLocations &&
          props.selectedUnpaidLocations.length > 0
      );
    } else {
      setShowPaywall(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    candidateFeedContext.myPositions,
    positionFilters,
    props.selectedUnpaidLocations,
    activeStageId,
    currentStage
  ]);

  const profile = path(['data', 'profile'], candidateQuery);
  const status = defaultTo('ACTIVELY_LOOKING', prop('status', profile));

  const handlePrint = useReactToPrint({
    content: () => candidateProfileRef.current,
    documentTitle: `Resume_${profile?.user?.firstName}_${profile?.user?.lastName}.pdf`
  });

  const {
    sendingIR,
    selectPositionId,
    sendInterviewRequest,
    position,
    myPositions
  } = useCandidateRequestInterview();

  const interviewStatus = candidateQuery.loading
    ? ''
    : defaultTo(
        INTERVIEW_STATUS_NOTSCHEDULED,
        path(['lastInterview', 'status'], profile)
      );

  const resumeRef = useRef(null);
  const contactInfoRef = useRef(null);
  const screeningDetailRef = useRef(null);
  const profileInformationBlockRef = useRef(null);
  const chatPosition = useMemo(
    () =>
      defaultTo(
        undefined,
        path(
          ['data', 'employerCandidate', 'preferredMatch', 'position', 'id'],
          getPreferredMatchQuery
        )
      ),
    [getPreferredMatchQuery]
  );

  const chatLocation = path(
    [
      'data',
      'employerCandidate',
      'preferredMatch',
      'position',
      'location',
      'id'
    ],
    getPreferredMatchQuery
  );

  const application =
    chatPosition !== undefined
      ? defaultTo(
          [],
          unwrapEdges(path(['applicationsForEmployer', 'edges'], profile))
        ).find(app => app?.position?.id === chatPosition)
      : undefined;

  const lastPositionApplication = useMemo(
    () => defaultTo(null, path(['lastPositionApplication'], profile)),
    [profile]
  );

  const appliedApplicationDetails = useMemo(() => {
    const appliedPosition = defaultTo(
      null,
      application?.position || lastPositionApplication?.position
    );
    const appliedLocation = defaultTo(
      null,
      application?.position?.location ||
        lastPositionApplication?.position?.location
    );
    const appliedOnPositionApplicationDate =
      application?.createdAt || lastPositionApplication?.createdAt;

    return {
      appliedPosition,
      appliedLocation,
      appliedOnPositionApplicationDate: appliedOnPositionApplicationDate ?? null
    };
  }, [lastPositionApplication, application]);

  useEffect(() => {
    (async () => {
      if (
        status === 'APPLIED_TO_YOU' &&
        application &&
        path(['node', 'viewedAt'], application) === null
      ) {
        await handleMutation(
          positionApplicationMutation({
            variables: {
              id: path(['node', 'id'], application),
              status: 'under_review',
              setViewedAt: true
            }
          })
        );
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application]);

  useEffect(() => {
    if (selectedTab === 'resume') {
      setTimeout(() => {
        if (resumeRef.current) {
          resumeRef.current.scrollIntoView();
        }
      }, 100);
    } else if (selectedTab === 'contact-information') {
      setTimeout(() => {
        if (contactInfoRef.current) {
          contactInfoRef.current.scrollIntoView();
        }
      }, 100);
    } else if (selectedTab === 'screening-detail') {
      setTimeout(() => {
        if (screeningDetailRef.current) {
          screeningDetailRef.current.scrollIntoView();
        }
      }, 100);
    }
  }, [selectedTab]);

  const vidRef = useRef(null);

  const VideoComponent = compProps => (
    //eslint-disable-next-line
    <video
      controls
      ref={vidRef}
      playsInline
      autoPlay
      style={{ ...compProps.style }}
    >
      <source src={path(['profileVideo'], profile)} type="video/mp4" />
    </video>
  );

  const selectedCandidateCount = allCandidates
    ? props.employerCandidateCount
    : length(selectedEmployerCandidates);

  const candidateCountsInStage = defaultTo(
    0,
    path(['data', 'employerCandidates', 'totalCount'], candidateFeedQuery)
  );

  const sevenshiftsId = defaultTo('', prop('sevenshiftsId', employerCandidate));
  const redirect7ShiftsURL = defaultTo(
    '',
    prop('sevenshiftsUrl', employerCandidate)
  );

  useEffect(() => {
    const firstEmpCandHandle = defaultTo(
      undefined,
      path([0, 'candidateProfile', 'handle'], props.candidates)
    );
    const candidateHandles = props.candidates.map(item =>
      defaultTo(undefined, path(['candidateProfile', 'handle'], item))
    );
    const firstEmpCandidate = defaultTo(undefined, prop(0, props.candidates));
    if (
      length(selectedEmployerCandidates) <= 1 &&
      (!candidateHandle ||
        (firstEmpCandHandle && !candidateHandles.includes(candidateHandle)))
    ) {
      setCandidateHandle(firstEmpCandHandle);
      setEmployerCandidate(firstEmpCandidate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    candidateCountsInStage,
    candidateFeedQuery.data,
    candidateHandle,
    currentStage,
    props.candidates,
    search,
    selectedEmployerCandidates,
    setCandidateHandle,
    setEmployerCandidate
  ]);

  const isRejected =
    prop(
      'stageType',
      props.stages.find(stage => stage.id === props.activeStageId)
    ) === 'REJECTED';

  const { height } = useIsMobile();

  useEffect(() => {
    setIsOpenMoreOptions(false);
  }, [activeStageId]);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  const noCandidatesDetails = StageId => {
    const translationsStage =
      StageId === prop('id', passiveStage)
        ? 'candidates'
        : StageId === prop('id', screeningStage)
        ? 'screening'
        : StageId === prop('id', interviewStage)
        ? 'interview'
        : StageId === prop('id', offeredStage)
        ? 'offered'
        : StageId === prop('id', hiredStage)
        ? 'hired'
        : StageId === prop('id', rejectedStage)
        ? 'rejected'
        : 'default';

    const translations = {
      headerText: t(`${translationsStage}EmptyStageHeader`),
      detailText: t(`${translationsStage}EmptyStageDetails`),
      ctaButtonLabel: t(`${translationsStage}CtaButtonLabel`),
      ctaUrl: t(`${translationsStage}CtaUrl`),
      learnMoreButtonLabel: t('learnMoreButtonLabel'),
      learnMoreUrl: t(`${translationsStage}LearnMoreUrl`),
      learnMoreGif: `https://${envVariables.AWS_S3_BUCKET_NAME}.s3.amazonaws.com/media/${translationsStage}_empty.gif`
    };

    return translations;
  };

  const { logEventMetrics } = useTrackAmplitudeEvent();

  const logMoveCandidateClickedEvent = movedCandidatesCount => {
    const moveCandidateEventDetail = {
      event_type: AmplitudeTrackingEvents.MOVE_CANDIDATE_CLICKED.EVENT_TYPE,
      page: 'feed',
      method: movedCandidatesCount > 1 ? 'bulk' : 'individual'
    };

    logEventMetrics(
      AmplitudeTrackingEvents.MOVE_CANDIDATE_CLICKED.EVENT_NAME,
      moveCandidateEventDetail
    );
  };

  const successMove = candidateStageMoves => {
    const moved = path(
      ['data', 'bulkMoveEmployerCandidatesToStage', 'moved'],
      candidateStageMoves
    );

    const candidateName = path(
      ['data', 'bulkMoveEmployerCandidatesToStage', 'candidateName'],
      candidateStageMoves
    );
    const stageName = path(
      ['data', 'bulkMoveEmployerCandidatesToStage', 'stageName'],
      candidateStageMoves
    );
    const movedCandidatesCount = path(
      ['data', 'bulkMoveEmployerCandidatesToStage', 'movedCandidatesCount'],
      candidateStageMoves
    );

    if (!moved) {
      return;
    }

    logMoveCandidateClickedEvent(movedCandidatesCount);

    setBanner({
      bannerType: 'SUCCESS',
      bannerMessage: candidateName
        ? t('moveCandidateMessage', {
            candidateName: candidateName,
            stageName: stageName
          })
        : t('bulkMoveCandidateMessage', {
            movedCandidateCount: movedCandidatesCount,
            stageName: stageName
          })
    });
    setNextCandidate();
    clearEmployerCandidates();
  };
  // block move if no preferred match set
  const hasNotSetPreferredMatch = !path(
    ['data', 'employerCandidate', 'preferredMatch', 'id'],
    getPreferredMatchQuery
  );

  const tabs = [
    {
      title: t('candidateHistoryLabel'),
      route: 'candidate-history'
    },
    {
      title: t('MessageTemplatesFeature:screening'),
      route: 'screening-detail'
    },
    {
      title: t('profileOverviewLabel'),
      route: 'profile-overview'
    },
    {
      title: t('ProfileFeature:contactInformation'),
      route: 'contact-information',
      icon: () => (
        <ContactIcon
          style={{
            tintColor: FOHColors.GRAYSCALE_700
          }}
        />
      ),
      selectedIcon: () => (
        <ContactIcon
          style={{
            tintColor: FOHColors.GRAYSCALE_G1
          }}
        />
      )
    },
    {
      title: t('CandidateCardFeature:resumeLabel'),
      route: 'resume',
      icon: () => (
        <ResumeIcon
          style={{
            tintColor: FOHColors.GRAYSCALE_700
          }}
        />
      ),
      selectedIcon: () => (
        <ResumeIcon
          style={{
            tintColor: FOHColors.GRAYSCALE_G1
          }}
        />
      )
    },
    {
      title: t('notes'),
      route: 'notes'
    }
  ];
  const shouldRefreshStage = props.refreshableStageTypes.includes(
    prop('stageType', currentStage)
  );

  const handleOnRefresh = () => {
    props.refetchEmployerCandidates();
    props.removeRefreshableStageType(currentStage.stageType);
    setPageTitle(`${currentStage.name} - Candidate Feed - foh&boh`);
  };

  const onSortValueChange = value => {
    const details = {
      event_type: AmplitudeTrackingEvents.FEED_SORTBY_CHANGED.EVENT_TYPE,
      sort_order: value
    };

    logEventMetrics(
      AmplitudeTrackingEvents.FEED_SORTBY_CHANGED.EVENT_NAME,
      details
    );
    props.setSortBy(value);
  };

  if (!isMounted) {
    return <></>;
  }

  return canShowPayWall && length(props.candidates) > 0 ? (
    <FreePassiveCandidatesBanner candidates={props.candidates} />
  ) : (
    <FOHView style={{ width: '100%' }}>
      <FOHSpace />
      <FOHView
        style={{
          flexDirection: 'row',
          width: '100%'
        }}
      >
        {length(props.candidates) >= 0 ||
        search ||
        (notificationStatuses.length > 0 &&
          // allow 0 states for all notification filters besides all
          notificationStatusFilter !== 'ALL') ? (
          <FOHView
            style={{
              flex: 1,
              borderRightColor: '#dddddd',
              borderRightWidth: 1,
              maxWidth: 404,
              height: height - 172
            }}
          >
            <FOHView
              style={{
                borderColor: '#dddddd',
                borderTopWidth: 1,
                borderBottomWidth: 1,
                borderRightWidth: 0,
                height: '100%'
              }}
            >
              <FOHCandidateInboxListBlock
                searchValue={props.search}
                searchPlaceholder={`${t('ChatFeature:candidate')}${' '}${t(
                  'SearchLocationsFeature:searchPlaceholder'
                )}...`}
                currentStage={currentStage}
                applicationsLabel={
                  props.stages &&
                  props.stages.find(
                    stage => stage.id === props.activeStageId
                  ) &&
                  props.stages.find(stage => stage.id === props.activeStageId)
                    .name
                }
                applicationsNumber={
                  props.employerCandidateCount
                    ? props.employerCandidateCount
                    : 0
                }
                onChangeSearch={val => props.setSearch(val)}
                selectAllLabel={t('common:selectAll')}
                isSelectAllSelected={allCandidates}
                onPressSelectAll={
                  canBulkEdit
                    ? () => {
                        setAllCandidates(!allCandidates);
                      }
                    : undefined
                }
                filterList={
                  notificationStatuses.length > 0
                    ? notificationStatuses
                    : undefined
                }
                activeFilter={notificationStatusFilter}
                onFilterChange={setNotificationStatusFilter}
                refreshButtonLabel={t('RefreshFeedButtonLabel')}
                onRefreshButtonPress={
                  shouldRefreshStage ? handleOnRefresh : undefined
                }
                sortByLabel={`${t('sortByLabel')}:`}
                sortOptions={[
                  {
                    label: 'Last updated',
                    value: FOHCandidateListSortBy.LAST_UPDATED
                  },
                  {
                    label: 'Application date',
                    value: FOHCandidateListSortBy.CREATED_AT
                  }
                ]}
                sortSelectedValue={sortBy}
                onSortValueChange={onSortValueChange}
              >
                <FOHView style={{ position: 'relative', width: '100%' }}>
                  <FOHScrollView
                    scrollEventThrottle={200}
                    onScroll={async ({ nativeEvent }) => {
                      if (isScrollCloseToBottom(nativeEvent) && !loadingMore) {
                        setLoadingMore(true);
                        await props.fetchMore();
                        setLoadingMore(false);
                      }
                    }}
                    style={{
                      width: '100%',
                      height:
                        currentStage?.stageType ===
                        FOHEmployerStagesType.INTERVIEWS
                          ? height - 400
                          : currentStage?.stageType ===
                            FOHEmployerStagesType.CANDIDATES
                          ? height - 323
                          : height - 347 ?? 700
                    }}
                  >
                    {length(positionFilters) > 0 && !loadingCandidates ? (
                      props.candidates.map((empCandidate, i) => {
                        return (
                          <CandidateInboxCell
                            zIndex={length(props.candidates) - i}
                            key={`${empCandidate.id}`}
                            employerCandidate={empCandidate}
                            candidateHandle={candidateHandle}
                            setCandidateHandle={setCandidateHandle}
                            setEmployerCandidate={selectedNewEmpCandidate => {
                              setEmployerCandidate(selectedNewEmpCandidate);
                              setSelectedTab('candidate-history');
                              setTimeout(() => {
                                if (profileInformationBlockRef?.current) {
                                  profileInformationBlockRef?.current?.scrollIntoView(
                                    { behavior: 'smooth' }
                                  );
                                }
                              }, 100);
                            }}
                            selected={
                              defaultTo(
                                [],
                                selectedEmployerCandidates
                              ).includes(prop('id', empCandidate)) ||
                              allCandidates
                            }
                            loading={
                              movingEmployerCandidateId ===
                              prop('id', empCandidate)
                            }
                            onDirectMessagePress={props.onDirectMessagePress}
                            // todo: (John) enable bulk editing when feedback is complete
                            canBulkEdit={canBulkEdit}
                            multiSelectEmployerCandidate={
                              multiSelectEmployerCandidate
                            }
                            candidates={props.candidates}
                          />
                        );
                      })
                    ) : (
                      <></>
                    )}
                    {prop('stageType', currentStage) ===
                    'PASSIVE_CANDIDATES' ? (
                      <NeedMoreCandidateCard />
                    ) : (
                      <></>
                    )}
                    {loadingMore || loadingCandidates ? (
                      <FOHView
                        style={{
                          alignItems: 'center',
                          width: '100%',
                          paddingTop: 8
                        }}
                      >
                        <LogoLoader />
                        <FOHSpace />
                      </FOHView>
                    ) : (
                      <></>
                    )}
                  </FOHScrollView>
                </FOHView>
              </FOHCandidateInboxListBlock>
            </FOHView>
          </FOHView>
        ) : (
          <></>
        )}
        <FOHView style={{ flex: 2 }}>
          {candidateQuery.loading || loadingCandidates ? (
            <FOHView
              style={{
                width: '100%',
                top: 280,
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <LogoLoader />
            </FOHView>
          ) : length(locationFilters) === 0 ? (
            <>
              <FOHSpace />
              <NoPositionsMultiCandidateDetail
                activeStageId={activeStageId}
                currentStage={currentStage}
                forLocation={true}
              />
            </>
          ) : length(positionFilters) === 0 ? (
            <>
              <FOHSpace />
              <NoPositionsMultiCandidateDetail
                activeStageId={activeStageId}
                currentStage={currentStage}
              />
            </>
          ) : length(props.candidates) === 0 ? (
            <>
              {prop('stageType', currentStage) === 'APPLICANTS' &&
              props.search === '' ? (
                <FOHView style={{ flexDirection: 'row' }}>
                  <FOHView style={{ flex: 2.2 }}>
                    <NoApplicantsBanner
                      positionGlobalId={prop(0, positionFilters)}
                      goToCandidateDatabase={() => navigateTo(NETWORK)}
                      showCampaignBoost={true}
                    />
                  </FOHView>
                  <FOHView style={{ flex: 0.8 }}>
                    <FOHGetMoreApplicantsCard
                      key={'boostcard'}
                      headerText={t('boostPostCardHeaderTitle')}
                      subHeaderText={''}
                      detailTextComp={
                        <FOHView style={{ alignItems: 'center' }}>
                          <FOHDetailText>{t('detailCompText1')}</FOHDetailText>
                          <FOHImage
                            resizeMode={'contain'}
                            style={{ height: 30, width: 128 }}
                            source={{
                              uri: indeed_logo
                            }}
                          />
                          <FOHDetailText>{t('detailCompText3')}</FOHDetailText>
                        </FOHView>
                      }
                      submitBtnLabel={t('boostPostCardButton')}
                      onPressSubmit={() => navigateTo(CAMPAIGN_CREATE)}
                      helpText={t('boostDisabledText')}
                      disabled={
                        length(positionFilters) === 0 ||
                        length(positionFilters) > 1 ||
                        userRole !== 'ADMIN'
                      }
                    />
                  </FOHView>
                </FOHView>
              ) : (
                <FOHEmptyStageCell
                  translations={noCandidatesDetails(activeStageId)}
                  onCta={ctaUrl => navigateTo(`${ctaUrl}`)}
                  onLearnMore={learnMoreUrl => {
                    window &&
                      window.open &&
                      window.open(`${learnMoreUrl}`, '_blank');
                  }}
                  image={no_resume_empty}
                />
              )}
            </>
          ) : !path(['user', 'id'], profile) &&
            length(selectedEmployerCandidates) === 0 ? (
            // fallback to loader instead of empty profile
            <FOHView
              style={{
                width: '100%',
                top: 280,
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <LogoLoader />
            </FOHView>
          ) : (
            <>
              <FOHView style={{ height: height - 172 }}>
                <FOHView
                  style={{
                    width: '100%',
                    height: 72,
                    justifyContent: 'center',
                    zIndex: 99,
                    borderColor: FOHColors.VERY_LIGHT_GRAY,
                    borderWidth: 1,
                    borderLeftWidth: 0,
                    borderRightWidth: 0,
                    paddingVertical: 16,
                    paddingHorizontal: 13
                  }}
                  testID="feedActionsBar"
                >
                  {!hasNotSetPreferredMatch ? (
                    <FOHView
                      style={{
                        width: '100%',
                        height: '100%',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <FOHView
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-start'
                        }}
                      >
                        {!multiSelectActive &&
                        activeStageFeedActionButtons?.primaryButton ? (
                          <FOHButton
                            style={{
                              minWidth: 181,
                              maxWidth: 194,
                              width: '100%',
                              height: 40
                            }}
                            title={
                              activeStageFeedActionButtons.primaryButton
                                .buttonLabel
                            }
                            onPress={
                              activeStageFeedActionButtons.primaryButton.onPress
                            }
                          />
                        ) : (
                          <></>
                        )}
                        {!multiSelectActive &&
                        activeStageFeedActionButtons?.secondaryButton ? (
                          <>
                            <FOHSpace />
                            <FOHButtonBordered
                              color={FOHColors.WHITE}
                              style={{
                                minWidth: 181,
                                maxWidth: 194,
                                width: '100%',
                                height: 40
                              }}
                              title={
                                activeStageFeedActionButtons.secondaryButton
                                  .buttonLabel
                              }
                              onPress={
                                activeStageFeedActionButtons.secondaryButton
                                  .onPress
                              }
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </FOHView>
                      <FOHView
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-end'
                        }}
                      >
                        <FOHInverseButton
                          color={FOHColors.GRAYSCALE_700}
                          textColor={FOHColors.WHITE}
                          style={{
                            height: 40,
                            width: 87,
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                          icon={() => <FOHDirectMessageIcon />}
                          onPress={async () => {
                            if (multiSelectActive) {
                              navigateTo(`${FEED}${BULK_MESSAGE}`);
                            } else {
                              await props.onDirectMessagePress(
                                employerCandidate,
                                profile.user.uuid,
                                '',
                                chatPosition,
                                chatLocation
                              );
                            }
                          }}
                          title={t('CandidateCardFeature:chat')}
                          opaque
                          selected
                          centerIcon
                          testID={'MessageButton-main-action'}
                          disabled={hasNotSetPreferredMatch}
                        />
                        <FOHSpace />
                        <FOHView>
                          <RejectButton
                            {...props}
                            disabled={hasNotSetPreferredMatch}
                            isRejected={isRejected}
                            setNextCandidate={setNextCandidate}
                            employerCandidate={employerCandidate}
                            setCandidateHandle={setCandidateHandle}
                            setEmployerCandidate={setEmployerCandidate}
                            activeStageId={activeStageId}
                            selectedCandidateCount={selectedCandidateCount}
                          />
                        </FOHView>
                        <FOHSpace />
                        <FOHView style={{ maxWidth: 37, height: 40 }}>
                          <FOHIconDropdownButton
                            isOpen={isOpenMoreOptions}
                            toggleIsOpen={() =>
                              setIsOpenMoreOptions(!isOpenMoreOptions)
                            }
                            testID="moreOptionsMenu"
                          >
                            <FOHScrollView
                              style={{
                                maxHeight: '65vh',
                                height: '90%'
                              }}
                              scrollEventThrottle={200}
                            >
                              <FOHView>
                                {!multiSelectActive &&
                                activeStageFeedActionButtons?.additionalActions &&
                                length(
                                  activeStageFeedActionButtons.additionalActions
                                ) > 0 ? (
                                  <FOHSmallDetailLabel
                                    style={{
                                      textTransform: 'UPPERCASE',
                                      paddingBottom: 12
                                    }}
                                  >
                                    {`${t('AdditionalActions')}...`}
                                  </FOHSmallDetailLabel>
                                ) : (
                                  <></>
                                )}
                                {!multiSelectActive ? (
                                  activeStageFeedActionButtons?.additionalActions?.map(
                                    (action, index) => (
                                      <FOHView
                                        key={index}
                                        style={{
                                          paddingTop: 12,
                                          paddingBottom:
                                            index ===
                                            length(
                                              activeStageFeedActionButtons.additionalActions
                                            ) -
                                              1
                                              ? 24
                                              : 12,
                                          flexDirection: 'row',
                                          justifyContent: 'flex-start',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <FOHTextButton
                                          style={{
                                            fontSize: 16,
                                            lineHeight: 24,
                                            fontWeight: 400,
                                            fontFamily: FOHFonts.REGULAR,
                                            paddingLeft: 4
                                          }}
                                          icon={action.icon}
                                          color={FOHColors.GRAYSCALE_G1}
                                          onPress={action.onActionClick}
                                          title={action.actionLabel}
                                          numberOfLines={1}
                                        />
                                      </FOHView>
                                    )
                                  )
                                ) : (
                                  <></>
                                )}
                                {length(skipToStages) > 0 ? (
                                  <FOHSmallDetailLabel
                                    style={{
                                      textTransform: 'UPPERCASE',
                                      paddingBottom: 12
                                    }}
                                  >
                                    {`${t('moveToLabel')}...`}
                                  </FOHSmallDetailLabel>
                                ) : (
                                  <></>
                                )}
                                {skipToStages.map((stage, index) =>
                                  stage.id !== activeStageId ? (
                                    <FOHView
                                      key={stage.id}
                                      style={{
                                        paddingTop: 12,
                                        paddingBottom:
                                          index === skipToStages.length - 1
                                            ? 24
                                            : 12,
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <FOHTouchableOpacity
                                        style={{
                                          flexDirection: 'row',
                                          alignItems: 'center'
                                        }}
                                        onPress={async () => {
                                          const candidateStageMoves =
                                            await moveEmployerCandidates({
                                              employerCandidateIds: [
                                                prop('id', employerCandidate),
                                                ...selectedEmployerCandidates
                                              ],
                                              all: allCandidates,
                                              newStage: stage.id,
                                              oldStage: activeStageId,
                                              positions: positionFilters,
                                              locations: locationFilters,
                                              search
                                            });
                                          successMove(candidateStageMoves);
                                          clearEmployerCandidates();
                                          setIsOpenMoreOptions(false);
                                        }}
                                        testID={`moveto-${stage.stageType}`}
                                      >
                                        <FOHRightArrow />
                                        <FOHSpaceSmall />
                                        <FOHLabel>
                                          {prop('name', stage)}
                                        </FOHLabel>
                                      </FOHTouchableOpacity>
                                    </FOHView>
                                  ) : (
                                    <></>
                                  )
                                )}
                                {length(backToStages) > 0 ? (
                                  <FOHSmallDetailLabel
                                    style={{
                                      textTransform: 'UPPERCASE',
                                      paddingBottom: 12
                                    }}
                                  >
                                    {`${t('backToLabel')}...`}
                                  </FOHSmallDetailLabel>
                                ) : (
                                  <></>
                                )}
                                {backToStages.map(stage =>
                                  stage.id !== activeStageId ? (
                                    <FOHView
                                      key={stage.id}
                                      style={{
                                        padding: 12,
                                        paddingLeft: 0,
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <FOHTouchableOpacity
                                        style={{
                                          flexDirection: 'row',
                                          alignItems: 'center'
                                        }}
                                        onPress={async () => {
                                          const candidateStageMoves =
                                            await moveEmployerCandidates({
                                              employerCandidateIds: [
                                                prop('id', employerCandidate),
                                                ...selectedEmployerCandidates
                                              ],
                                              all: allCandidates,
                                              newStage: stage.id,
                                              oldStage: activeStageId,
                                              positions: positionFilters,
                                              locations: locationFilters,
                                              search
                                            });
                                          successMove(candidateStageMoves);
                                          clearEmployerCandidates();
                                          setIsOpenMoreOptions(false);
                                        }}
                                        testID={`moveto-${stage.stageType}`}
                                      >
                                        <FOHLeftArrow />
                                        <FOHSpaceSmall />
                                        <FOHLabel>
                                          {prop('name', stage)}
                                        </FOHLabel>
                                      </FOHTouchableOpacity>
                                    </FOHView>
                                  ) : (
                                    <></>
                                  )
                                )}
                              </FOHView>
                            </FOHScrollView>
                          </FOHIconDropdownButton>
                        </FOHView>
                        <FOHSpace />
                      </FOHView>
                    </FOHView>
                  ) : (
                    <FOHView
                      style={{
                        width: '100%',
                        height: '100%',
                        alignItems: getPreferredMatchQuery?.loading
                          ? 'center'
                          : 'flex-start',
                        justifyContent: 'center'
                      }}
                    >
                      {getPreferredMatchQuery?.loading ? (
                        <FOHCircleLoader />
                      ) : (
                        <FOHLabel style={{ color: FOHColors.GRAYSCALE_500 }}>
                          {t('selectCandidateToEnableActions')}
                        </FOHLabel>
                      )}
                    </FOHView>
                  )}
                </FOHView>
                {selectedCandidateCount > 1 || allCandidates ? (
                  <FOHView style={{ paddingTop: 16 }}>
                    <MultiCandidateSelectionDetail
                      activeStageId={activeStageId}
                      currentStage={currentStage}
                      selectedCandidateCount={selectedCandidateCount}
                    />
                  </FOHView>
                ) : (
                  <FOHScrollView
                    style={{
                      zIndex: 96,
                      paddingTop: 16,
                      borderColor: '#dddddd',
                      borderBottomWidth: 1
                    }}
                  >
                    <NotificationBanner
                      {...props}
                      setSelectedTab={setSelectedTab}
                      lastInterview={path(['lastInterview'], profile)}
                      employerCandidate={employerCandidate}
                      notificationMessage={notificationMessage}
                      sendDirectMessageToCandidate={() => {
                        props.onDirectMessagePress(
                          employerCandidate,
                          profile.user.uuid,
                          '',
                          chatPosition,
                          chatLocation
                        );
                      }}
                    />
                    <FOHView
                      style={{ zIndex: 95 }}
                      ref={profileInformationBlockRef}
                    >
                      <FOHProfileInterviewBlock
                        key={candidateHandle}
                        preferredMatch={defaultTo(
                          undefined,
                          path(
                            ['data', 'employerCandidate', 'preferredMatch'],
                            getPreferredMatchQuery
                          )
                        )}
                        submitText={
                          sendingIR ? t('actions:sending') : t('actions:submit')
                        }
                        positionPlaceholder={t(
                          'InterviewRequestFeature:positionPlaceholder'
                        )}
                        interviewRequestLabel={t(
                          'InterviewRequestFeature:interviewRequestLabel'
                        )}
                        personalSummary={prop('personalSummary', profile)}
                        name={`${defaultTo(
                          '',
                          path(['user', 'firstName'], profile)
                        )} ${
                          !canShowPayWall
                            ? defaultTo('', path(['user', 'lastName'], profile))
                            : ''
                        }`}
                        status={t(`common:candidateStatusType.${status}`)}
                        statusType={
                          status === 'APPLIED_TO_YOU'
                            ? FOHStatusType.ACTIVELY_LOOKING
                            : status
                        }
                        storyPhoto={path(['user', 'profileImage'], profile)}
                        myPositions={defaultTo([], myPositions).map(pos => ({
                          id: pos.id,
                          positionName: pos.positionName
                        }))}
                        selectPosition={val => selectPositionId(val)}
                        selectedPosition={position}
                        submit={async () => {
                          if (promptSchedule) {
                            return navigateTo(`${FEED}${SEND_SCHEDULE}`);
                          }

                          await sendInterviewRequest({
                            userId: path(['user', 'id'], profile)
                          });

                          if (prop('id', interviewStage)) {
                            const candidateStageMoves =
                              await moveEmployerCandidates({
                                employerCandidateIds: [
                                  prop('id', employerCandidate),
                                  ...selectedEmployerCandidates
                                ],
                                all: allCandidates,
                                newStage: prop('id', interviewStage),
                                oldStage: activeStageId,
                                positions: positionFilters,
                                locations: locationFilters,
                                search
                              });

                            const moved = path(
                              [
                                'data',
                                'bulkMoveEmployerCandidatesToStage',
                                'moved'
                              ],
                              candidateStageMoves
                            );

                            const movedCandidatesCount = path(
                              [
                                'data',
                                'bulkMoveEmployerCandidatesToStage',
                                'movedCandidatesCount'
                              ],
                              candidateStageMoves
                            );

                            if (moved) {
                              await logMoveCandidateClickedEvent(
                                movedCandidatesCount
                              );
                            }
                          }
                          props.onDirectMessagePress(
                            employerCandidate,
                            path(['user', 'uuid'], profile),
                            '',
                            chatPosition,
                            chatLocation
                          );
                          await candidateQuery.refetch();
                          clearEmployerCandidates();
                        }}
                        sendingIR={sendingIR}
                        disabledSubmit={
                          interviewStatus === 'DECLINED'
                          // current commenting payment related code ||
                          // !canRequestInterview(me, initialPosition, needsUpgrade) ||
                          // sendingIR
                        }
                        hidden={isRejected}
                        rejected={isRejected}
                        rejectionReasons={defaultTo(
                          [],
                          path(['rejectionReasons', 'edges'], employerCandidate)
                        ).map(reason =>
                          defaultTo('', path(['node', 'name'], reason))
                        )}
                        video={path(['profileVideo'], profile)}
                        VideoComponent={VideoComponent}
                        openVideo={() => {
                          setVideo(path(['profileVideo'], profile));
                        }}
                        isFollowing={isFollowing}
                        handlePrint={handlePrint}
                      />
                    </FOHView>
                    <FOHView style={{ zIndex: 94 }}>
                      <FOHView
                        style={{
                          flexDirection: 'row',
                          width: '100%'
                        }}
                      >
                        <FOHView
                          style={{
                            width: isEnabled7Shifts ? '80%' : '100%'
                          }}
                        >
                          <FOHTabList
                            style={{
                              justifyContent: 'flex-start',
                              alignItems: 'flex-start'
                            }}
                            tabStyle={{
                              paddingRight: 20,
                              paddingLeft: 4,
                              zIndex: -1
                            }}
                            selectedTab={selectedTab}
                            key={'profileTabs'}
                            onPressTab={tab => {
                              setSelectedTab(tab);
                            }}
                            tabs={defaultTo([], tabs).filter(tab =>
                              (!path(['resume'], profile) &&
                                tab.route === 'resume') ||
                              (tab.route === 'screening-detail' &&
                                !screeningQuestionsCompletedAt &&
                                !screeningVideoCompletedAt &&
                                !prescreenCompletedAt)
                                ? false
                                : true
                            )}
                            tabOptions={{
                              tall: true
                            }}
                          />
                        </FOHView>
                        {isEnabled7Shifts ? (
                          <FOHView
                            style={{
                              width: '20%',
                              alignItems: 'center'
                            }}
                          >
                            {!sevenshiftsId ? (
                              <FOHInverseButton
                                style={{
                                  paddingVertical: 8,
                                  width: 160,
                                  minWidth: 160
                                }}
                                title={t('Integrations:sendTo')}
                                icon={() =>
                                  FOHSendMessageIcon({
                                    style: {
                                      tintColor: FOHColors.DARK_ORANGE,
                                      width: 14,
                                      height: 14
                                    }
                                  })
                                }
                                backIcon={() =>
                                  FOH7ShiftsIcon({
                                    style: { width: 44, height: 16 }
                                  })
                                }
                                onPress={() =>
                                  navigateTo(`${FEED}${SEND_TO_7SHIFT}`)
                                }
                              />
                            ) : (
                              <FOHInverseButton
                                style={{
                                  paddingVertical: 8,
                                  width: 160,
                                  minWidth: 160
                                }}
                                title={t('Integrations:viewTo')}
                                icon={() =>
                                  FOHSendMessageIcon({
                                    style: {
                                      tintColor: FOHColors.DARK_ORANGE,
                                      width: 14,
                                      height: 14
                                    }
                                  })
                                }
                                backIcon={() =>
                                  FOH7ShiftsIcon({
                                    style: { width: 44, height: 16 }
                                  })
                                }
                                onPress={() => openNewTab(redirect7ShiftsURL)}
                              />
                            )}
                            <FOHDivider />
                          </FOHView>
                        ) : (
                          <></>
                        )}
                      </FOHView>
                      {selectedTab === 'screening-detail' &&
                      (screeningQuestionsCompletedAt ||
                        screeningVideoCompletedAt ||
                        prescreenCompletedAt) ? (
                        <FOHView ref={screeningDetailRef}>
                          <CandidateScreeningDetail
                            employerCandidatePrescreenQuestionnaire={
                              prescreener?.employerCandidateScreeningQuestionnaire
                            }
                            employerCandidateScreeningQuestionnaire={
                              screeningQuestions?.employerCandidateScreeningQuestionnaire
                            }
                            employerCandidateScreeningVideoQuestionnaire={
                              screeningVideo?.employerCandidateScreeningVideoQuestionnaire
                            }
                            prescreenAnswers={prescreenAnswers}
                            prescreenCompletedOn={prescreenCompletedAt}
                            questionnaire={screeningAnswers}
                            screeningQuestionsCompletedOn={
                              screeningQuestionsCompletedAt
                            }
                            screeningVideoCompletedOn={
                              screeningVideoCompletedAt
                            }
                            setVideo={setVideo}
                            screeningVideoAnswer={videoAnswer}
                            storyPhoto={path(['user', 'profileImage'], profile)}
                          />
                        </FOHView>
                      ) : selectedTab === 'candidate-history' ? (
                        <FOHView style={{ padding: 20 }}>
                          <CandidatePositionOverview
                            {...props}
                            appliedApplicationDetails={
                              appliedApplicationDetails
                            }
                            isRejected={isRejected}
                            appliedPositionCount={defaultTo(
                              0,
                              length(
                                path(
                                  ['applicationsForEmployer', 'edges'],
                                  profile
                                )
                              ) - 1
                            )}
                            lastInterview={path(['lastInterview'], profile)}
                            employerCandidate={employerCandidate}
                            currentStage={currentStage}
                            prescreenCompletedAt={prescreenCompletedAt}
                            screeningQuestionnaireCompletedAt={
                              screeningQuestionsCompletedAt
                            }
                            screeningVideoCompletedAt={
                              screeningVideoCompletedAt
                            }
                            setSelectedTab={setSelectedTab}
                          >
                            {!isRejected &&
                            activeStageId !== prop('id', appliedStage) &&
                            activeStageId !== prop('id', rejectedStage) ? (
                              <PreferredMatchSelect
                                employerCandidateId={prop(
                                  'id',
                                  employerCandidate
                                )}
                                onChangePosition={async () => {
                                  await candidateFeedQuery.refetch();
                                }}
                                preferredMatchQuery={getPreferredMatchQuery}
                              />
                            ) : (
                              <></>
                            )}
                          </CandidatePositionOverview>
                        </FOHView>
                      ) : selectedTab === 'notes' ? (
                        <CandidateNotes
                          key={`${candidateHandle}-note`}
                          employerCandidate={employerCandidate}
                        />
                      ) : (
                        <FOHView syle={{ paddingTop: 24 }}>
                          <FOHView
                            style={{
                              paddingHorizontal: 24,
                              paddingTop: 24
                            }}
                          >
                            <FOHHeaderH3
                              style={{
                                textAlign: 'left',
                                lineHeight: 28,
                                fontWeight: '700',
                                color: FOHColors.GRAYSCALE_G1
                              }}
                            >
                              {t('profileOverviewLabel')}
                            </FOHHeaderH3>
                          </FOHView>
                          <CandidateProfileOverview
                            {...props}
                            contactInfoRef={contactInfoRef}
                            backgroundColor={FOHColors.WHITE}
                            setVideo={setVideo}
                            handle={candidateHandle}
                            fullWidth={true}
                            hideResume={true}
                            onDirectMessage={() => {
                              props.onDirectMessagePress(
                                employerCandidate,
                                profile.user.uuid,
                                '',
                                chatPosition,
                                chatLocation
                              );
                            }}
                          />
                          {!path(['resume'], profile) ? (
                            <FOHView
                              style={{
                                backgroundColor: FOHColors.WHITE
                              }}
                            >
                              <FOHView
                                style={{
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  paddingHorizontal: 20,
                                  paddingTop: 20,
                                  paddingBottom: 10
                                }}
                              >
                                <FOHResumeIcon
                                  style={{
                                    width: 20,
                                    height: 20
                                  }}
                                />
                                <FOHSpaceSmall />
                                <FOHLabel
                                  style={{
                                    fontSize: 18,
                                    lineHeight: 28,
                                    fontWeight: '700'
                                  }}
                                >
                                  {t('CandidateCardFeature:resumeLabel')}
                                </FOHLabel>
                              </FOHView>
                              <FOHView
                                ref={resumeRef}
                                style={{
                                  paddingHorizontal: 20,
                                  paddingVertical: 20,
                                  backgroundColor: FOHColors.LIGHT_GRAY
                                }}
                              >
                                <FOHNoResumeCell
                                  headerText={t(
                                    'ProfileFeature:noResumeHeaderText'
                                  )}
                                  detailText={t(
                                    'ProfileFeature:noResumeDetailText'
                                  )}
                                  submitBtnLabel={t(
                                    'ProfileFeature:askForResumeBtnLabel'
                                  )}
                                  onPress={() => {
                                    props.onDirectMessagePress(
                                      employerCandidate,
                                      profile.user.uuid,
                                      `${t('askForResume', {
                                        name: path(
                                          [
                                            'data',
                                            'profile',
                                            'user',
                                            'firstName'
                                          ],
                                          candidateQuery
                                        ),
                                        employerName: prop('firstName', me),
                                        locationName: path(
                                          [
                                            'data',
                                            'positionById',
                                            'location',
                                            'name'
                                          ],
                                          positionQuery
                                        ),
                                        candidateProfileURL: `${getAppBaseUrl()}${CANDIDATE_PROFILE}/${candidateHandle}`
                                      })}`,
                                      chatPosition,
                                      chatLocation
                                    );
                                  }}
                                  image={no_resume_empty}
                                />
                              </FOHView>
                            </FOHView>
                          ) : (
                            <FOHView
                              style={{
                                height: '100%',
                                backgroundColor: FOHColors.WHITE
                              }}
                              ref={resumeRef}
                            >
                              <FOHView
                                style={{
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  paddingHorizontal: 20,
                                  paddingTop: 20,
                                  paddingBottom: 10
                                }}
                              >
                                <FOHResumeIcon
                                  style={{
                                    width: 20,
                                    height: 20
                                  }}
                                />
                                <FOHSpaceSmall />
                                <FOHLabel
                                  style={{
                                    fontSize: 18,
                                    lineHeight: 28,
                                    fontWeight: '700'
                                  }}
                                >
                                  {t('CandidateCardFeature:resumeLabel')}
                                </FOHLabel>
                              </FOHView>
                              {/* https://stackoverflow.com/a/27958186/6207061 displaying docx on a webpage */}
                              <FOHView
                                style={{
                                  padding: 8,
                                  paddingLeft: 24,
                                  height: '100%'
                                }}
                              >
                                {path(['resume'], profile).includes('.jpeg') ||
                                path(['resume'], profile).includes('.jpg') ||
                                path(['resume'], profile).includes('.png') ? (
                                  <img
                                    src={path(['resume'], profile)}
                                    alt={`${path(
                                      ['user', 'lastName'],
                                      profile
                                    )}-resume`}
                                    style={{
                                      width: '100%',
                                      maxWidth: 800
                                    }}
                                  />
                                ) : (
                                  <iframe
                                    title={'resume-iframe'}
                                    src={
                                      path(['resume'], profile).includes(
                                        '.docx'
                                      ) ||
                                      path(['resume'], profile).includes(
                                        '.doc'
                                      ) ||
                                      path(['resume'], profile).includes('.odt')
                                        ? `https://docs.google.com/gview?url=${path(
                                            ['resume'],
                                            profile
                                          )}&embedded=true`
                                        : path(['resume'], profile)
                                    }
                                    style={{
                                      width: '100%',
                                      height: '100%',
                                      maxWidth: 1000
                                    }}
                                  />
                                )}
                              </FOHView>
                            </FOHView>
                          )}
                        </FOHView>
                      )}
                    </FOHView>
                    <FOHView style={{ display: 'none' }}>
                      <FOHView ref={candidateProfileRef}>
                        <PrintableCandidateProfile
                          {...props}
                          canShowPayWall={canShowPayWall}
                          candidateHandle={candidateHandle}
                          appliedApplicationDetails={appliedApplicationDetails}
                          rejectionReasons={defaultTo(
                            [],
                            path(
                              ['rejectionReasons', 'edges'],
                              employerCandidate
                            )
                          ).map(reason =>
                            defaultTo('', path(['node', 'name'], reason))
                          )}
                        />
                      </FOHView>
                    </FOHView>
                  </FOHScrollView>
                )}
              </FOHView>
            </>
          )}
        </FOHView>
      </FOHView>
      {banner.bannerType && banner.bannerMessage ? (
        <FOHView
          style={{
            position: 'absolute',
            zIndex: 9999,
            width: '100%',
            bottom: 0,
            paddingLeft: 20,
            paddingRight: 20
          }}
        >
          <FOHView style={{ width: '78%', alignSelf: 'flex-end' }}>
            <FOHBanner {...banner} message={banner?.bannerMessage} />
          </FOHView>
        </FOHView>
      ) : (
        <></>
      )}
    </FOHView>
  );
};
