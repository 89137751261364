import React from 'react';

import styled from 'styled-components/native';
import { Platform } from 'react-native';

import { FOHAvailabilityTable, FOHAvailabilityTableProps } from '../../recipes';
import {
  FOHButton,
  FOHHeaderText,
  FixedTopContainerUnderNavigation,
  FixedBottomContainer,
  Screen,
  FOHErrorLabel,
  FOHSpace,
  FOHTextButton,
  FOHView,
  FOHScrollView
} from '../../ingredients';

export interface FOHAvailabilityScreenProps extends FOHAvailabilityTableProps {
  handleSubmit: () => void;
  submitLabel: string;
  title?: string;
  error?: any;
  disabledSubmit?: boolean;
  goBack?: () => void;
  mobile?: boolean;
  largeHeader?: boolean;
  previousTitle?: string;
  isModal?: boolean;
}

export const FOHAvailabilityScreen = (props: FOHAvailabilityScreenProps) => {
  return (
    <Screen>
      <FOHView
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: 100
        }}
      >
        {!!props.title && (
          <FixedTopContainerUnderNavigation
            style={{
              top: props.isModal ? 10 : 50,
              position:
                props.largeHeader || props.isModal ? 'absolute' : 'fixed'
            }}
          >
            <FOHView
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingTop: props.largeHeader || props.isModal ? 0 : 20
              }}
            >
              <FOHHeaderText style={{ fontSize: 30 }}>
                {props.title}
              </FOHHeaderText>
            </FOHView>
            <FOHSpace />
          </FixedTopContainerUnderNavigation>
        )}
        <FOHScrollView
          style={{
            maxHeight: props.isModal ? 300 : '100%'
          }}
        >
          {props.largeHeader && <FOHSpace />}
          <ContainerView>
            <FOHAvailabilityTable
              mobile={props.mobile}
              shifts={props.shifts}
              sections={props.sections}
              selected={props.selected}
              selectItem={props.selectItem}
              selectAllLabel={props.selectAllLabel}
              allSelected={props.allSelected}
              onSelectAll={props.onSelectAll}
            />
          </ContainerView>
        </FOHScrollView>
        <FixedBottomContainer
          style={{
            position:
              Platform.OS === 'web' && !props.isModal ? 'fixed' : 'relative',
            borderColor: !props.isModal ? '#e8e8e8' : '#ffffff'
          }}
        >
          <FOHErrorLabel>
            {props.error && props.error.availability
              ? props.error.availability
              : null}
          </FOHErrorLabel>
          <FOHButton
            onPress={props.handleSubmit}
            title={props.submitLabel}
            disabled={props.disabledSubmit}
          />
          {!!props.previousTitle && props.goBack && (
            <>
              <FOHSpace />
              <FOHTextButton
                title={props.previousTitle}
                onPress={props.goBack}
              />
            </>
          )}
        </FixedBottomContainer>
      </FOHView>
    </Screen>
  );
};

const ContainerView = styled(FOHView)`
  width: 100%;
  padding-bottom: 100px;
  padding-top: 20px;
`;
