import React, { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { compose, defaultTo, length, path, prop } from 'ramda';

import {
  FOHView,
  FOHSpace,
  FOHPriceBlock,
  FOHDetailText,
  FOHHeaderH1,
  FOHFormFlowButtons,
  FOHErrorLabel,
  FOHSpaceLarge,
  FOHSpaceLarger
} from '../../components';
import { useIsMobile, removeOnboardingKeys, handle } from '../../utils';
import { getAppBaseUrl, SUBSCRIBE_LOCATIONS_POSITIONS } from '../../constants';

import { useMyLocationsData } from '../Locations';

import { useCheckout } from './useCheckout';
import { formatPricing, usePaymentPlan } from './usePaymentPlan';
import { withStripe } from './withStripe';
import { getPlanMeta } from './PricingBox';

const OnboardingBilling = props => {
  const { t } = useTranslation('PricingScreenFeature');
  const { isMobile } = useIsMobile();

  const [selectedPlan, setSelectedPlan] = useState('payasyougo');
  const [stripeCheckoutError, setStripeCheckoutError] = useState(false);

  const [monthly] = useState(true);
  const planMeta = getPlanMeta({ t, monthly });

  const { redirectToStripeCheckout } = useCheckout(props);

  const { data: myLocations } = useMyLocationsData();

  const location = useMemo(() => {
    if (myLocations && length(myLocations) > 0) {
      return myLocations[0];
    }
    return undefined;
  }, [myLocations]);

  const { notSubscribedPrice, subscribedPrice, unpaidPositions, singlePrice } =
    usePaymentPlan({
      locationFilter: location?.id
    });

  const prices = formatPricing({
    notSubscribedPrice,
    subscribedPrice,
    subscribed: false,
    monthly
  });

  const paygPrice = monthly
    ? defaultTo(
        '',
        path(['monthlyPayAsYouGo', 'displayPerMonth'], singlePrice)
      ).replace('.00', '')
    : path(['annualPayAsYouGo', 'displayPerMonth'], singlePrice);

  const handleStripeCheckout = async variables => {
    const [, checkoutError] = await handle(redirectToStripeCheckout(variables));

    if (checkoutError) {
      setStripeCheckoutError(true);
    }
  };

  const handleCheckout = () => {
    if (!window) return;

    setStripeCheckoutError(false);

    const redirectURL = `${getAppBaseUrl()}${SUBSCRIBE_LOCATIONS_POSITIONS}?checkout`;

    const commonVariables = {
      success_url: `${redirectURL}=success`,
      cancel_url: `${redirectURL}=cancel`,
      positions: unpaidPositions.map(pos => prop('rowId', pos)),
      cycle: monthly ? 'monthly' : 'annual',
      plan: selectedPlan
    };

    removeOnboardingKeys();

    const variables =
      selectedPlan === 'payasyougo'
        ? commonVariables
        : {
            ...commonVariables,
            locations: [prop('rowId', location)]
          };

    handleStripeCheckout(variables);
  };

  return (
    <>
      <FOHView>
        <FOHView
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            paddingHorizontal: 16,
            paddingTop: 60,
            paddingBottom: 16
          }}
        >
          <FOHView style={{ justifyContent: 'center', alignItems: 'center' }}>
            <FOHHeaderH1>
              {t('upgradeTitle', { location: prop('name', location) })}
            </FOHHeaderH1>
            <FOHDetailText>{t('upgradeDetail')}</FOHDetailText>
          </FOHView>
          <FOHSpaceLarger />
          <FOHView
            style={{
              flexDirection: isMobile ? 'column' : 'row',
              paddingBottom: 12
            }}
            testID="UpgradePlanModal"
          >
            <FOHPriceBlock
              {...planMeta[0]}
              detail=""
              asterisk={true}
              planText={t('payPerActivePosition', { price: paygPrice })}
              price={paygPrice}
              selected={selectedPlan === 'payasyougo'}
              selectText={t('selectPayAsYouGo')}
              onPressSelect={() => setSelectedPlan('payasyougo')}
              discountLabel={monthly ? undefined : t('annualPAYGDiscount')}
            ></FOHPriceBlock>
            <FOHSpace />
            <FOHPriceBlock
              {...planMeta[1]}
              detail=""
              price={path(['unlimited', 'displayPerMonth'], prices)}
              selected={selectedPlan === 'unlimited'}
              selectText={t('selectUnlimited')}
              onPressSelect={() => setSelectedPlan('unlimited')}
              discountLabel={monthly ? undefined : t('annualUnlimitedDiscount')}
            ></FOHPriceBlock>
          </FOHView>
          {stripeCheckoutError ? (
            <FOHView
              style={{
                justifyContent: 'center',
                alignItems: 'flex-start',
                wordBreak: 'break-word'
              }}
            >
              <FOHErrorLabel>{t('stripeCheckoutErrorMsg')}</FOHErrorLabel>
            </FOHView>
          ) : null}
          <FOHSpace />
        </FOHView>
      </FOHView>
      <FOHSpaceLarge />
      <FOHView
        style={{
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <FOHView
          style={{
            width: '100%',
            maxWidth: isMobile ? '100%' : 734,
            paddingHorizontal: isMobile ? 15 : 0
          }}
        >
          <FOHFormFlowButtons
            nextButtonlabel={t('checkoutLabel')}
            onPressNextButton={handleCheckout}
          />
        </FOHView>
      </FOHView>
      <FOHSpaceLarge />
    </>
  );
};

export default compose(withStripe)(OnboardingBilling);
