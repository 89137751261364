import React from 'react';

import { Screen, FOHHeaderText, FOHView } from '../../ingredients';
import { FOHSignInForm, FOHSignInFormProps } from '../../recipes';

export interface FOHLoginScreenProps extends FOHSignInFormProps {
  largeHeader?: boolean;
  title: string;
}

export const FOHLoginScreen = (props: FOHLoginScreenProps) => (
  <Screen>
    <FOHView
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 60
      }}
    >
      <FOHHeaderText style={{ fontSize: props.largeHeader ? 30 : 20 }}>
        {props.title}
      </FOHHeaderText>
      <FOHView style={{ paddingTop: 18 }} />
      <FOHSignInForm
        submitLabel={props.submitLabel}
        emailPlaceholder={props.emailPlaceholder}
        setEmail={props.setEmail}
        email={props.email}
        error={props.error}
        passwordPlaceholder={props.passwordPlaceholder}
        setPassword={props.setPassword}
        password={props.password}
        handleSubmit={props.handleSubmit}
        forgotPasswordLabel={props.forgotPasswordLabel}
        handleForgotPassword={props.handleForgotPassword}
        handleSignUp={props.handleSignUp}
        signUpLabel={props.signUpLabel}
        showPassText={props.showPassText}
        hidePassText={props.hidePassText}
      />
    </FOHView>
  </Screen>
);
