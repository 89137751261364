import React, { useMemo, useRef, useState } from 'react';

import styled from 'styled-components/native';
import { TouchableOpacity } from 'react-native';
import { prop } from 'ramda';
import { useHover } from 'react-native-web-hooks';

import { InterviewCancellationModal } from '../../../features/UpcomingAgenda/components';

import {
  FullWidthCard,
  FOHView,
  Row,
  FOHSmallDetailLabel,
  FOHDetailText,
  FOHInformationBlackIcon,
  FOHHeaderH3,
  FOHSpaceSmall,
  FOHSpaceSmallest,
  FOHLabel,
  FOHDivider,
  FOHColors,
  FOHCalendarScheduledIcon,
  FOHCircleSuccessIcon,
  FOHHiredIcon,
  FOHNotificationPendingScreening,
  FOHFonts,
  FOHDownloadLink,
  FOHThumbDownIcon,
  FOHApplicantIcon,
  FOHRejectionTag,
  FOHMultiPositionTag,
  FOHSpace,
  FOHGrayTag,
  FOHTextButton,
  FOHEditMarker,
  FOHTrashCan,
  FOHLinkOut
} from '../../ingredients';
import { FOHEmployerStagesType } from '../../typings';

import { FOHCandidatePositionDetailsType } from './FOHCandidatePositionDetails';

export interface FOHPositionDetailsProps {
  stageType?: string;
  moveManualActionLabel: string;
  isManualMoved?: boolean;

  isOfferApproved?: boolean;
  approvedLabel?: string;

  children: React.ReactNode;

  applicationStatusLabel: string;
  applicationStatusType: FOHCandidatePositionDetailsType;
  position: string | null;
  locationName: string | null;
  locationAddr: string | null;
  applicationDateLabel?: string;
  applicationDate?: string | null;
  rejectionReasons?: Array<string>;
  appliedPositionCount?: number;
  onPressMorePositions: () => void;
  hasCandidateRejected?: boolean;
  noApplicationDetailFoundLabel: string;

  hasInterview: boolean;

  interviewDetailLabel: string;

  scheduleLabel: string;
  scheduledAt?: string;

  interviewerLabel: string;
  interviewerName?: string;
  interviewerEmail?: string;
  noInterviewerLabel: string;

  interviewedForLabel: string;

  interviewTypeLabel: string;
  interviewType?: string;
  interviewPhone?: string;
  interviewUrl?: string;
  noInterviewScheduledLabel?: string;
  noInterviewAssignedLabel?: string;

  onEditInterview?: () => void;
  onCancelInterview?: (callback: () => void) => void;

  isOffered: boolean;

  offeredLabel: string;
  offeredAt?: string;

  offerLetterLabel: string;
  offeredFileName?: string;
  offeredFileUrl?: string;
  onOfferedFilePress?: () => void;
  noOfferSentLabel?: string;
  noOfferAttachLabel?: string;

  hiredAt?: string;
  hiredLabel?: string;
  noHireSentLabel?: string;

  screeningDetailLabel: string;
  phoneScreeningAt?: string;
  phoneScreeningLabel: string;
  noPhoneScreeningLabel?: string;

  screeningQuestionsLabel: string;
  prescreenCompletedAt: string;
  screeningCompletedOn: string;
  screeningQuestionsLinkText?: string;
  onPressScreeningQuestions?: () => void;
  noScreeningQuestionsLabel?: string;
  pendingAnswersLabel?: string;
  pendingAnswers?: boolean;

  screeningVideosLabel: string;
  screeningVideoCompletedOn: string;
  onPressScreeningVideo?: () => void;
  screeningVideoLinkText?: string;
  noScreeningVideosLabel?: string;
}

export const FOHPositionDetails = (props: FOHPositionDetailsProps) => (
  <>
    <FullWidthCard
      style={{ padding: 24, backgroundColor: FOHColors.LIGHT_GRAY, zIndex: 5 }}
    >
      <LocationBlock style={{ zIndex: 2 }}>
        {!props.hasCandidateRejected ? (
          <FOHView
            style={{
              flex: 1,
              alignItems: 'flex-start',
              paddingHorizontal: 20,
              paddingVertical: 3
            }}
            testID={'FOHPositionDetails-position-block'}
          >
            {props.children}
            <FOHSpaceSmallest />
            <FOHDivider />
          </FOHView>
        ) : null}
        <FOHView
          style={{
            flex: 1,
            alignItems: 'flex-start',
            paddingHorizontal: 20,
            paddingVertical: 3
          }}
          testID={'FOHPositionDetails-location-block'}
        >
          <FOHView
            style={{
              width: '100%',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <FOHView
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                marginTop: 4
              }}
            >
              {props.applicationStatusType ===
              FOHCandidatePositionDetailsType.REJECTED ? (
                <FOHThumbDownIcon
                  style={{
                    marginRight: 6,
                    marginLeft: 2,
                    width: 14,
                    height: 14,
                    tintColor: FOHColors.CYBERSPACE_GREY
                  }}
                />
              ) : (
                <FOHApplicantIcon
                  style={{
                    marginRight: 6,
                    marginLeft: 2,
                    width: 18,
                    height: 18
                  }}
                  active={true}
                />
              )}
              <FOHSmallDetailLabel
                style={{
                  alignItems: 'flex-start',
                  color: FOHColors.CYBERSPACE_GREY
                }}
              >
                {props.applicationStatusLabel}
              </FOHSmallDetailLabel>
            </FOHView>
            {props.appliedPositionCount &&
            props.appliedPositionCount > 0 &&
            props.onPressMorePositions ? (
              <FOHView
                style={{
                  alignItems: 'flex-end'
                }}
              >
                <FOHMultiPositionTag
                  value={props.appliedPositionCount}
                  onPress={props.onPressMorePositions}
                />
              </FOHView>
            ) : (
              <></>
            )}
          </FOHView>
          <FOHSpaceSmall />
          <Block style={{ height: 61, paddingBottom: 13, width: '100%' }}>
            {props.position && props.locationAddr ? (
              <FOHLabel
                style={{
                  alignItems: 'flex-start',
                  lineHeight: 24,
                  fontWeight: 600,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {props.position ? `${props.position}${`  `}` : null}
                <FOHSmallDetailLabel
                  style={{
                    fontSize: 12,
                    lineHeight: 16,
                    color: FOHColors.CYBERSPACE_GREY
                  }}
                >
                  {props.locationAddr ? props.locationAddr : null}
                </FOHSmallDetailLabel>
              </FOHLabel>
            ) : (
              <FOHHeaderH3
                style={{
                  color: FOHColors.GREY,
                  fontFamily: FOHFonts.REGULAR,
                  fontStyle: 'italic',
                  fontSize: 16,
                  fontWeight: 500
                }}
              >
                {props.noApplicationDetailFoundLabel}
              </FOHHeaderH3>
            )}
            <FOHSpaceSmall />
            <FOHSmallDetailLabel
              style={{
                fontSize: 12,
                lineHeight: 16,
                color: FOHColors.CYBERSPACE_GREY
              }}
            >
              {props.applicationDate && !props.hasCandidateRejected ? (
                `${props.applicationDateLabel}: ${props.applicationDate}`
              ) : (
                <>
                  {props.rejectionReasons ? (
                    props.rejectionReasons.map(reason => (
                      <FOHRejectionTag
                        label={reason}
                        key={reason}
                        style={{ paddingRight: 8 }}
                      />
                    ))
                  ) : (
                    <></>
                  )}
                </>
              )}
            </FOHSmallDetailLabel>
          </Block>
          <FOHSpaceSmallest />
          <FOHDivider />
        </FOHView>
      </LocationBlock>
      {props?.stageType !== FOHEmployerStagesType.SCREENING &&
      props.isManualMoved ? (
        <FOHView
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 16,
            zIndex: 1
          }}
        >
          <FOHInformationBlackIcon
            style={{
              width: 16,
              height: 16
            }}
          />
          <FOHSpaceSmall />
          <FOHSmallDetailLabel style={{ alignItems: 'flex-start' }}>
            {props.moveManualActionLabel}
          </FOHSmallDetailLabel>
        </FOHView>
      ) : (
        <></>
      )}
    </FullWidthCard>
    {props?.stageType === FOHEmployerStagesType.HIRED ||
    props?.stageType === FOHEmployerStagesType.REJECTED ? (
      <HiredStageDetailBlock {...props} />
    ) : (
      <></>
    )}
    {props?.stageType &&
    ![
      FOHEmployerStagesType.APPLICANTS,
      FOHEmployerStagesType.CANDIDATES,
      FOHEmployerStagesType.SCREENING,
      FOHEmployerStagesType.INTERVIEWS
    ].includes(props.stageType as any) ? (
      <OfferedStageDetailBlock {...props} />
    ) : (
      <></>
    )}
    <InterviewStageDetailBlock {...props} />
    {props?.stageType &&
    ![FOHEmployerStagesType.CANDIDATES].includes(props.stageType as any) ? (
      <ScreeningStageDetailBlock {...props} />
    ) : (
      <></>
    )}
  </>
);

const InfoCmp = (props: any) => (
  <FOHView style={{ flexDirection: 'row', alignItems: 'center' }}>
    {props.children}
    <FOHHeaderH3
      style={{
        color: FOHColors.GREY,
        fontFamily: FOHFonts.REGULAR,
        fontStyle: 'italic',
        fontSize: 16,
        fontWeight: 500,
        marginVertical: 12
      }}
    >
      {props.label}
    </FOHHeaderH3>
  </FOHView>
);

const ScreeningStageDetailBlock = (props: any) => (
  <StageContainer style={{ zIndex: 1 }} testID={'ScreeningQuestions'}>
    <FOHDetailText style={{ alignItems: 'flex-start' }}>
      {props.screeningDetailLabel}
    </FOHDetailText>

    <FullWidthCard
      style={{
        padding: 24,
        backgroundColor: FOHColors.LIGHT_GRAY,
        flexDirection: 'row',
        alignItems: 'center'
      }}
    >
      <Row style={{ width: '50%' }}>
        <FOHView
          style={{
            flex: 1,
            alignItems: 'flex-start',
            paddingHorizontal: 20,
            paddingVertical: 3
          }}
          testID={'ScreeningQuestions-text-response'}
        >
          <FOHSmallDetailLabel style={{ alignItems: 'flex-start' }}>
            {props.screeningQuestionsLabel}
          </FOHSmallDetailLabel>
          <FOHSpaceSmallest />
          {!props.prescreenCompletedAt && !props.screeningCompletedOn && (
            <FOHView style={{ flexDirection: 'row', alignItems: 'center' }}>
              <FOHHeaderH3
                style={{
                  color: FOHColors.GREY,
                  fontFamily: FOHFonts.REGULAR,
                  fontStyle: 'italic',
                  fontSize: 16,
                  fontWeight: 500
                }}
              >
                {props.noScreeningQuestionsLabel}
              </FOHHeaderH3>
            </FOHView>
          )}
          {(props.screeningCompletedOn || props.pendingAnswers) && (
            <Block>
              {props.screeningCompletedOn &&
              props.onPressScreeningQuestions &&
              !props.pendingAnswers ? (
                <FOHView style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <FOHLabel>{props.screeningCompletedOn}</FOHLabel>
                  <FOHSpaceSmall />
                  <TouchableOpacity
                    onPress={props.onPressScreeningQuestions}
                    testID={'text-questionnaire-view-more-link'}
                  >
                    <FOHLabel
                      style={{
                        textDecorationLine: 'underline',
                        color: FOHColors.PRIMARY_TEAL_P1
                      }}
                    >
                      {props.screeningQuestionsLinkText}
                    </FOHLabel>
                  </TouchableOpacity>
                </FOHView>
              ) : (
                <>
                  {props.pendingAnswersLabel && props.pendingAnswers ? (
                    <FOHView
                      style={{ flexDirection: 'row', alignItems: 'center' }}
                    >
                      <FOHNotificationPendingScreening />
                      <FOHSpaceSmall />
                      <FOHHeaderH3
                        style={{
                          color: FOHColors.GREY,
                          fontFamily: FOHFonts.REGULAR,
                          fontStyle: 'italic',
                          fontSize: 16,
                          fontWeight: 500
                        }}
                      >
                        {props.pendingAnswersLabel}
                      </FOHHeaderH3>
                    </FOHView>
                  ) : null}
                </>
              )}
            </Block>
          )}
          {props.prescreenCompletedAt && (
            <Block>
              {props.onPressScreeningQuestions ? (
                <FOHView style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <FOHLabel>{props.prescreenCompletedAt}</FOHLabel>
                  <FOHSpaceSmall />
                  <TouchableOpacity
                    onPress={props.onPressScreeningQuestions}
                    testID={'text-questionnaire-view-more-link'}
                  >
                    <FOHLabel
                      style={{
                        textDecorationLine: 'underline',
                        color: FOHColors.PRIMARY_TEAL_P1
                      }}
                    >
                      {props.screeningQuestionsLinkText}
                    </FOHLabel>
                  </TouchableOpacity>
                </FOHView>
              ) : null}
            </Block>
          )}
          <FOHSpaceSmallest />
          <FOHDivider />
        </FOHView>
      </Row>
      <Row style={{ width: '50%' }}>
        <FOHView
          style={{
            flex: 1,
            alignItems: 'flex-start',
            paddingHorizontal: 20,
            paddingVertical: 3
          }}
          testID={'ScreeningQuestions-video-response'}
        >
          <FOHSmallDetailLabel style={{ alignItems: 'flex-start' }}>
            {props.screeningVideosLabel}
          </FOHSmallDetailLabel>
          <FOHSpaceSmallest />
          <Block>
            {props.screeningVideoCompletedOn && props.onPressScreeningVideo ? (
              <FOHView>
                <FOHView style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <FOHLabel>{props.screeningVideoCompletedOn}</FOHLabel>
                  <FOHSpaceSmall />
                  <TouchableOpacity
                    onPress={props.onPressScreeningVideo}
                    testID={'video-questionnaire-view-more-link'}
                  >
                    <FOHLabel
                      style={{
                        textDecorationLine: 'underline',
                        color: FOHColors.PRIMARY_TEAL_P1
                      }}
                    >
                      {props.screeningVideoLinkText}
                    </FOHLabel>
                  </TouchableOpacity>
                </FOHView>
              </FOHView>
            ) : (
              <FOHView style={{ flexDirection: 'row', alignItems: 'center' }}>
                <FOHHeaderH3
                  style={{
                    color: FOHColors.GREY,
                    fontFamily: FOHFonts.REGULAR,
                    fontStyle: 'italic',
                    fontSize: 16,
                    fontWeight: 500,
                    marginVertical: 12
                  }}
                >
                  {props.noScreeningVideosLabel}
                </FOHHeaderH3>
              </FOHView>
            )}
          </Block>
          <FOHSpaceSmallest />
          <FOHDivider />
        </FOHView>
      </Row>
    </FullWidthCard>
  </StageContainer>
);

const InterviewStageDetailBlock = (props: any) => {
  const locName = prop('locationName', props);
  const locAddr = prop('locationAddr', props);

  const [
    showInterviewCanceledConfirmation,
    setShowInterviewCanceledConfirmation
  ] = useState(false);

  const trashCanBtnHoverRef = useRef(null);
  const isTrashCanBtnHovered = useHover(trashCanBtnHoverRef);

  const pencilBtnHoverRef = useRef(null);
  const isPencilBtnHovered = useHover(pencilBtnHoverRef);

  const candidateName = `${props?.employerCandidate?.candidateProfile?.user
    ?.firstName}${' '}${props?.employerCandidate?.candidateProfile?.user
    ?.lastName}`;

  const hasUpcomingInterview = useMemo(() => {
    const scheduleAtDate = new Date(props?.lastInterview?.scheduledFor);
    const currentDate = new Date();
    return scheduleAtDate >= currentDate;
  }, [props.scheduledAt]);

  return (
    <StageContainer style={{ zIndex: 2 }}>
      <FOHDetailText style={{ alignItems: 'flex-start' }}>
        {props.interviewDetailLabel}
      </FOHDetailText>
      <FullWidthCard
        style={{ padding: 24, backgroundColor: FOHColors.LIGHT_GRAY }}
      >
        <Row style={{ width: '100%', zIndex: 12 }}>
          <FOHView
            style={{
              flex: 1,
              alignItems: 'flex-start',
              paddingHorizontal: 20,
              paddingVertical: 3
            }}
            testID={'FOHPositionDetails-scheduled-for-block'}
          >
            <FOHSmallDetailLabel style={{ alignItems: 'flex-start' }}>
              {props.scheduleLabel}
            </FOHSmallDetailLabel>
            <FOHSpaceSmallest />
            <Block style={{ width: '100%', position: 'relative', zIndex: 8 }}>
              {props.scheduledAt ? (
                <>
                  <FOHView
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      width: '100%'
                    }}
                  >
                    <FOHCalendarScheduledIcon />
                    <FOHSpaceSmall />
                    <FOHView
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flex: 1
                      }}
                    >
                      <FOHView
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-start'
                        }}
                      >
                        <FOHHeaderH3
                          style={{
                            fontFamily: FOHFonts.REGULAR,
                            fontSize: 16,
                            fontWeight: 600
                          }}
                        >
                          {props.scheduledAt}
                        </FOHHeaderH3>
                        <FOHSpace />
                        {props?.lastInterview?.status === 'CANCELED' && (
                          <FOHGrayTag
                            label="Canceled"
                            style={{
                              backgroundColor: FOHColors.RED_100,
                              color: FOHColors.GRAYSCALE_900,
                              fontFamily: FOHFonts.MEDIUM,
                              fontSize: 14,
                              lineHeight: 22,
                              marginTop: 0
                            }}
                          />
                        )}
                      </FOHView>
                      {hasUpcomingInterview && (
                        <FOHView
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-end'
                          }}
                        >
                          <FOHView ref={pencilBtnHoverRef}>
                            <FOHTextButton
                              onPress={props.onEditInterview}
                              icon={() => (
                                <FOHEditMarker
                                  style={{
                                    marginRight: 6,
                                    tintColor: isPencilBtnHovered
                                      ? FOHColors.PRIMARY_TEAL_P1
                                      : FOHColors.NARO_GREY,
                                    width: 16,
                                    height: 16
                                  }}
                                />
                              )}
                              title=""
                            />
                          </FOHView>
                          {props?.lastInterview?.status !== 'CANCELED' ? (
                            <>
                              <FOHSpaceSmall />
                              <FOHView ref={trashCanBtnHoverRef}>
                                <FOHTextButton
                                  onPress={() => {
                                    setShowInterviewCanceledConfirmation(true);
                                  }}
                                  icon={() => (
                                    <FOHTrashCan
                                      style={{
                                        tintColor: isTrashCanBtnHovered
                                          ? FOHColors.RED
                                          : FOHColors.NARO_GREY,
                                        width: 18,
                                        height: 18
                                      }}
                                    />
                                  )}
                                  title=""
                                />
                              </FOHView>
                            </>
                          ) : null}
                        </FOHView>
                      )}
                    </FOHView>
                  </FOHView>
                  {props?.lastInterview?.status !== 'CANCELED' &&
                  showInterviewCanceledConfirmation ? (
                    <InterviewCancellationModal
                      candidateName={candidateName}
                      onPressGoBack={() =>
                        setShowInterviewCanceledConfirmation(false)
                      }
                      onPressConfirm={() => {
                        const closeModal = () =>
                          setShowInterviewCanceledConfirmation(false);
                        props.onCancelInterview(closeModal);
                      }}
                      styles={{
                        backgroundColor: FOHColors.LIGHT_GRAY,
                        borderColor: FOHColors.GRAYSCALE_300
                      }}
                    />
                  ) : null}
                </>
              ) : (
                <FOHView style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <FOHHeaderH3
                    style={{
                      color: FOHColors.GREY,
                      fontFamily: FOHFonts.REGULAR,
                      fontStyle: 'italic',
                      fontSize: 16,
                      fontWeight: 500,
                      marginVertical: 12
                    }}
                  >
                    {props.noInterviewScheduledLabel}
                  </FOHHeaderH3>
                </FOHView>
              )}
            </Block>
            <FOHSpaceSmallest />
            <FOHDivider />
          </FOHView>
          <FOHView
            style={{
              flex: 1,
              alignItems: 'flex-start',
              paddingHorizontal: 20,
              paddingVertical: 3
            }}
            testID={'FOHPositionDetails-interviewer-block'}
          >
            <FOHSmallDetailLabel style={{ alignItems: 'flex-start' }}>
              {props.interviewerLabel}
            </FOHSmallDetailLabel>
            <FOHSpaceSmallest />
            <Block style={{ zIndex: 7 }}>
              {props.interviewerName ? (
                <FOHView style={{ flexDirection: 'column' }}>
                  <FOHSmallDetailLabel
                    style={{
                      fontSize: 16,
                      lineHeight: 24,
                      color: FOHColors.NARO_GREY
                    }}
                  >
                    {props.interviewerName}
                  </FOHSmallDetailLabel>
                  {props.interviewerEmail && (
                    <FOHLinkOut
                      noIcon={true}
                      href={`mailto:${props.interviewerEmail}`}
                      labelStyle={{
                        color: FOHColors.PRIMARY_TEAL_P1,
                        fontSize: 16,
                        lineHeight: 24,
                        fontWeight: 400,
                        fontFamily: FOHFonts.REGULAR
                      }}
                    >
                      {props.interviewerEmail}
                    </FOHLinkOut>
                  )}
                </FOHView>
              ) : (
                <FOHView style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <FOHHeaderH3
                    style={{
                      color: FOHColors.GREY,
                      fontFamily: FOHFonts.REGULAR,
                      fontStyle: 'italic',
                      fontSize: 16,
                      fontWeight: 500,
                      marginVertical: 12
                    }}
                  >
                    {props.noInterviewerLabel}
                  </FOHHeaderH3>
                </FOHView>
              )}
            </Block>
            <FOHSpaceSmallest />
            <FOHDivider />
          </FOHView>
        </Row>
        {props.hasInterview ? (
          <Row style={{ width: '100%' }}>
            <FOHView
              style={{
                flex: 1,
                alignItems: 'flex-start',
                paddingHorizontal: 20,
                paddingVertical: 3,
                zIndex: 11
              }}
              testID={'FOHPositionDetails-interview-type-block'}
            >
              <FOHSmallDetailLabel style={{ alignItems: 'flex-start' }}>
                {props.interviewTypeLabel}
              </FOHSmallDetailLabel>
              <FOHSpaceSmallest />
              <Block>
                {props.interviewType ? (
                  <FOHView style={{ flexDirection: 'row' }}>
                    <FOHSmallDetailLabel
                      style={{
                        fontSize: 16,
                        lineHeight: 24,
                        color: FOHColors.NARO_GREY
                      }}
                    >
                      {props.interviewType}
                    </FOHSmallDetailLabel>
                    {props.interviewPhone !== '' && (
                      <>
                        <FOHSpaceSmall />
                        <FOHSmallDetailLabel
                          style={{
                            fontSize: 16,
                            lineHeight: 24,
                            color: FOHColors.NARO_GREY
                          }}
                        >
                          {props.interviewPhone}
                        </FOHSmallDetailLabel>
                      </>
                    )}
                    {props.interviewUrl !== '' && (
                      <>
                        <FOHSpaceSmall />
                        <FOHLinkOut
                          href={props.interviewUrl}
                          style={{
                            fontSize: 16,
                            lineHeight: 24,
                            color: FOHColors.NARO_GREY
                          }}
                        >
                          {props.interviewUrl}
                        </FOHLinkOut>
                      </>
                    )}
                  </FOHView>
                ) : (
                  <FOHView
                    style={{ flexDirection: 'row', alignItems: 'center' }}
                  >
                    <FOHHeaderH3
                      style={{
                        color: FOHColors.GREY,
                        fontFamily: FOHFonts.REGULAR,
                        fontStyle: 'italic',
                        fontSize: 16,
                        fontWeight: 500,
                        marginVertical: 12
                      }}
                    >
                      {props.noInterviewAssignedLabel}
                    </FOHHeaderH3>
                  </FOHView>
                )}
              </Block>
              <FOHSpaceSmallest />
              <FOHDivider />
            </FOHView>

            <FOHView
              style={{
                flex: 1,
                alignItems: 'flex-start',
                paddingHorizontal: 20,
                paddingVertical: 3
              }}
              testID={'FOHPositionDetails-interviewed-for-block'}
            >
              {props?.position && (locName || locAddr) && (
                <>
                  <FOHSmallDetailLabel style={{ alignItems: 'flex-start' }}>
                    {props.interviewedForLabel}
                  </FOHSmallDetailLabel>
                  <FOHSpaceSmallest />
                  <Block>
                    <FOHLabel
                      style={{
                        alignItems: 'flex-start',
                        lineHeight: 24,
                        fontWeight: 600
                      }}
                    >
                      {props.position}
                    </FOHLabel>
                    <FOHSpaceSmallest />
                    <FOHSmallDetailLabel
                      style={{
                        fontSize: 12,
                        lineHeight: 16,
                        color: FOHColors.CYBERSPACE_GREY
                      }}
                    >
                      {`${locName ? `${locName},  ` : ''}${locAddr}`}
                    </FOHSmallDetailLabel>
                  </Block>
                  <FOHSpaceSmallest />
                  <FOHDivider />
                </>
              )}
            </FOHView>
          </Row>
        ) : null}
      </FullWidthCard>
    </StageContainer>
  );
};

const OfferedStageDetailBlock = (props: any) => {
  return (
    <StageContainer style={{ zIndex: 3 }}>
      <FOHDetailText style={{ alignItems: 'flex-start' }}>
        {props.offeredDetailLabel}
      </FOHDetailText>
      <FullWidthCard
        style={{ padding: 24, backgroundColor: FOHColors.LIGHT_GRAY }}
      >
        <Row style={{ width: '100%' }}>
          <FOHView
            style={{
              flex: 1,
              alignItems: 'flex-start',
              paddingHorizontal: 20,
              paddingVertical: 3
            }}
          >
            <FOHSmallDetailLabel style={{ alignItems: 'flex-start' }}>
              {props.offeredLabel}
            </FOHSmallDetailLabel>
            <FOHSpaceSmallest />
            <Block style={{ width: '100%' }}>
              {props.offeredAt ? (
                <FOHView
                  style={{
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%'
                  }}
                >
                  <FOHView
                    style={{ flexDirection: 'row', alignItems: 'center' }}
                  >
                    {props.isOfferApproved ? (
                      <>
                        <FOHCircleSuccessIcon
                          style={{ tintColor: FOHColors.BLACK }}
                        />
                        <FOHSpaceSmall />
                      </>
                    ) : (
                      <></>
                    )}
                    <FOHHeaderH3
                      style={{
                        color: !props.isOfferApproved
                          ? FOHColors.GREY
                          : FOHColors.NARO_GREY,
                        fontFamily: FOHFonts.REGULAR,
                        fontStyle: !props.isOfferApproved ? 'italic' : 'normal',
                        fontSize: 16,
                        fontWeight: 600
                      }}
                    >
                      {props.offeredAt}
                    </FOHHeaderH3>
                  </FOHView>
                  <FOHView>
                    {props.isOfferApproved ? (
                      <FOHView
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          padding: 5,
                          borderRadius: 17,
                          backgroundColor: FOHColors.LIGHT_GREEN
                        }}
                      >
                        <FOHCircleSuccessIcon
                          style={{
                            tintColor: FOHColors.GREEN,
                            width: 17,
                            height: 17
                          }}
                        />
                        <FOHSpaceSmallest />
                        <FOHLabel style={{ fontSize: 12, lineHeight: 16 }}>
                          {props.approvedLabel}
                        </FOHLabel>
                      </FOHView>
                    ) : (
                      <></>
                    )}
                  </FOHView>
                </FOHView>
              ) : (
                <InfoCmp label={props.noOfferSentLabel} />
              )}
            </Block>
            <FOHSpaceSmallest />
            <FOHDivider />
          </FOHView>
          <FOHView
            style={{
              flex: 1,
              alignItems: 'flex-start',
              paddingHorizontal: 20,
              paddingVertical: 3
            }}
          >
            <FOHSmallDetailLabel style={{ alignItems: 'flex-start' }}>
              {props.offerLetterLabel}
            </FOHSmallDetailLabel>
            <FOHSpaceSmallest />
            <Block>
              {props.offeredFileName && props.offeredFileUrl ? (
                <FOHView style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <FOHDownloadLink
                    href={props.offeredFileUrl}
                    name={props.offeredFileName}
                  >
                    {props.offeredFileName}
                  </FOHDownloadLink>
                </FOHView>
              ) : (
                <InfoCmp label={props.noOfferAttachLabel}></InfoCmp>
              )}
            </Block>
            <FOHSpaceSmallest />
            <FOHDivider />
          </FOHView>
        </Row>
      </FullWidthCard>
    </StageContainer>
  );
};

const HiredStageDetailBlock = (props: any) => {
  return (
    <StageContainer style={{ zIndex: 4 }}>
      <FOHDetailText style={{ alignItems: 'flex-start' }}>
        {props.hiredDetailLabel}
      </FOHDetailText>
      <FullWidthCard
        style={{ padding: 24, backgroundColor: FOHColors.LIGHT_GRAY }}
      >
        <Row style={{ width: '100%' }}>
          <FOHView
            style={{
              flex: 1,
              alignItems: 'flex-start',
              paddingHorizontal: 20,
              paddingVertical: 3
            }}
          >
            <FOHSmallDetailLabel style={{ alignItems: 'flex-start' }}>
              {props.hiredLabel}
            </FOHSmallDetailLabel>
            <FOHSpaceSmallest />
            <Block>
              {props.hiredAt ? (
                <FOHView style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <FOHHiredIcon />
                  <FOHSpaceSmall />
                  <FOHHeaderH3
                    style={{
                      color: FOHColors.GREEN,
                      fontFamily: FOHFonts.REGULAR,
                      fontStyle: 'normal',
                      fontSize: 16,
                      fontWeight: 600
                    }}
                  >
                    {props.hiredAt}
                  </FOHHeaderH3>
                </FOHView>
              ) : (
                <InfoCmp label={props.noHireSentLabel} />
              )}
            </Block>
            <FOHSpaceSmallest />
            <FOHDivider />
          </FOHView>
        </Row>
      </FullWidthCard>
    </StageContainer>
  );
};

const Block = styled(FOHView)`
  align-items: flex-start;
  justify-content: center;
  height: 50px;
`;

const LocationBlock = styled(Row)`
  width: 100%;
`;

const StageContainer = styled(FOHView)`
  width: 100%;
`;
