import React, { useState, useEffect } from 'react';

import { prop } from 'ramda';
import { useTranslation } from 'react-i18next';

import {
  FOHSpace,
  FOHBannerType,
  FOHReactiveModal,
  FOHHeaderH1,
  FOHContactForm
} from '../../components';
import { handleMutation } from '../../utils/handleAwait';

import { useSidebarNav } from '../Navigation/useSidebar';
import { useBanner } from '../Navigation/useBanner';

import { useCandidateProfileMutation } from './useCandidateProfileMutation';

export const EditContactInformationModal = props => {
  const { me, updateCandidate } = useCandidateProfileMutation();
  const [phone, setPhone] = useState(prop('phone', me));
  const [email, setEmail] = useState(prop('email', me));
  const [error, setError] = useState(null);

  const { t } = useTranslation('ProfileFeature');
  const { setBanner } = useBanner();

  const { isMobile } = props;

  useEffect(() => {
    setError(null);
  }, [phone, email]);

  useEffect(() => {
    prop('email', me) && setEmail(prop('email', me));
    prop('phone', me) && setPhone(prop('phone', me));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open, prop('email', me), prop('phone', me)]);

  const { contentShift } = useSidebarNav(props);

  return (
    <FOHReactiveModal
      leftContentShift={contentShift}
      open={props.open}
      dialog={!isMobile}
      close={props.close}
    >
      <FOHHeaderH1>{t('contactInformation')}</FOHHeaderH1>
      <FOHSpace />
      <FOHContactForm
        emailPlaceholder={t('email')}
        phoneNumberPlaceholder={t('phone')}
        email={email}
        setEmail={setEmail}
        phoneNumber={phone}
        setPhoneNumber={setPhone}
        submitLabel={t('save')}
        disabledSubmit={!email || !phone}
        error={error}
        handleSubmit={async () => {
          const [, errorOnUpdateCandidate] = await handleMutation(
            updateCandidate({ variables: { email, phone } })
          );
          if (errorOnUpdateCandidate) {
            return setError(errorOnUpdateCandidate);
          }
          setError(null);
          setBanner({
            bannerType: FOHBannerType.SUCCESS,
            bannerMessage: t('common:success')
          });
          props.close();
        }}
      />
    </FOHReactiveModal>
  );
};
