import { DateTime } from 'luxon';

export const generateTimeIntervals = ({
  minuteInterval = 30,
  startHour = 6,
  startMinute = 0,
  numberOfIntervals = 48
}) => {
  const start = DateTime.local()
    .startOf('day')
    .set({ hour: startHour, minute: startMinute });
  const getMinutesFor = i => i * minuteInterval;
  return Array.from({ length: numberOfIntervals }).map((num, i) => {
    const time = start.plus({ minutes: getMinutesFor(i) }).toFormat('h:mm a');
    // value and label to make this easier for select inputs
    return {
      label: time,
      value: time
    };
  });
};

export const generateTimeDurations = ({ numberOfIntervals = 10 }) => {
  const start = 30;

  return Array.from({ length: numberOfIntervals }).map((num, i) => {
    const time = start * (i + 1);
    // value and label to make this easier for select inputs
    return {
      label: `${time} minutes`,
      value: time
    };
  });
};
