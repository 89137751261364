import React from 'react';

import { withTranslation } from 'react-i18next';
import { InfoBox, Marker } from '@react-google-maps/api';
import { compose, defaultTo, path, prop } from 'ramda';

import {
  withIsMobile,
  unwrapEdges,
  formatFollowerCountLabel,
  scrollToTop
} from '../../utils';
import {
  EMPLOYER_GROUP,
  CANDIDATE_GROUP,
  EMPLOYER_ONBOARDING_2,
  JOBS
} from '../../constants';
import {
  useGetFeedLocationQuery,
  useGetOtherJobPositionsByLocationQuery
} from '../../graphql/generated';
import { useNavigation } from '../../utils/navigation';
import {
  FOHLocationDetailsModal,
  FOHMap,
  FOHMapMarker,
  FOHPositionBillingStatusType
} from '../../components';

import { formatOtherJobs } from '../JobApplications/OtherJobs';
import { useSidebarNav } from '../Navigation/useSidebar';
import { SmallWebMap } from '../Map';
import { useEmployerOnboardingContext } from '../EmployerOnboarding';
import { useGetMeData } from '../SignIn';

import { useLocationMutation } from './useLocationMutation';

const LocationDetailsModal = props => {
  const { open, setOpen, slug, openRegister, setRegisterType } = props;
  const { goBack, navigateTo } = useNavigation();
  const { data: me } = useGetMeData();
  const isCandidate = !!path(['candidateProfile', 'id'], me);
  const { t } = props;
  const locationQuery = useGetFeedLocationQuery({
    variables: {
      slug
    }
  });
  const otherJobsQuery = useGetOtherJobPositionsByLocationQuery({
    variables: {
      slug
    }
  });

  const jobs = formatOtherJobs(
    defaultTo(
      [],
      path(['data', 'location', 'positions', 'edges'], otherJobsQuery)
    )
  )
    .map(job => ({
      ...job,
      formattedAddress: path(
        ['data', 'location', 'formattedAddress'],
        otherJobsQuery
      )
    }))
    .filter(
      position =>
        prop('hiring', position) === true &&
        (prop('paymentStatus', position) ===
          FOHPositionBillingStatusType.SUBSCRIBED ||
          path(['location', 'paymentStatus'], position) ===
            FOHPositionBillingStatusType.SUBSCRIBED)
    );

  const { optimisticToggleFavorite, optimisticToggleHidden } =
    useLocationMutation();

  const { setEmployerOnboardingState } = useEmployerOnboardingContext();

  const location = path(['data', 'location'], locationQuery);
  const id = prop('id', location);
  const { contentShift } = useSidebarNav(props);

  return (
    <FOHLocationDetailsModal
      leftContentShift={contentShift}
      open={open && !!id && !locationQuery.loading}
      close={() => {
        // Else section here is for Restaurant matches page
        if ('setViewLocationDetailsOpen' in props) {
          props.setViewLocationDetailsOpen(false);
        } else {
          setOpen(false);
          goBack();
        }
      }}
      {...location}
      hourlyRatesTitle={t('hourlyRatesTitle')}
      requirementsLabel={t('requirementsLabel')}
      summaryTitle={t('summaryTitle')}
      claimBusinessLabel={t('claimBusinessLabel')}
      benefitsLabel={t('benefitsLabel')}
      locationLabel={t('locationLabel')}
      answersLabel={`${t('workingAt')} ${prop('name', location)}`}
      followerCountLabel={formatFollowerCountLabel({
        followerCount: prop('followerCount', location),
        t
      })}
      answers={unwrapEdges(path(['answers', 'edges'], location))}
      favoriteLabel={t('favoriteLabel')}
      hideLabel={t('hideLabel')}
      positionRates={[]}
      id={id}
      vertical={props.isMobile}
      isFavorited={prop('isFavorited', location)}
      isHidden={prop('isHidden', location)}
      pressFavorite={() => {
        setRegisterType && setRegisterType(CANDIDATE_GROUP);
        isCandidate ? optimisticToggleFavorite(location) : openRegister();
      }}
      pressHide={() => {
        setRegisterType && setRegisterType(CANDIDATE_GROUP);
        isCandidate ? optimisticToggleHidden(location) : openRegister();
      }}
      claimBusiness={
        isCandidate || path(['employerProfile', 'id'], location)
          ? null
          : () => {
              setEmployerOnboardingState({
                location
              });
              setRegisterType(EMPLOYER_GROUP);
              prop('id', me)
                ? navigateTo(EMPLOYER_ONBOARDING_2)
                : openRegister();
            }
      }
      jobs={jobs}
      jobSectionTitle={`JOBS AT ${path(
        ['data', 'location', 'name'],
        otherJobsQuery
      )}`}
      onJobPress={(_slug, _externalApplyUrl) => {
        if (_externalApplyUrl && window && window.open) {
          return window.open(_externalApplyUrl, '_blank').focus();
        }
        scrollToTop({ smooth: false });
        navigateTo(`${JOBS}/${_slug}`);
      }}
      a={anchorProps => <a {...anchorProps}>{anchorProps.children}</a>}
      jobCTAText={t('JobApplicationsFeature:ctaTextOtherJob')}
    >
      <FOHMap
        latitude={defaultTo('', path(['latitude'], location))}
        longitude={defaultTo('', path(['longitude'], location))}
        latitudeDelta={0.0922}
        longitudeDelta={0.0421}
        key={path(['latitude'], location)}
        style={{
          top: props.isMobile ? 0 : isCandidate ? 400 : 440,
          width: props.isMobile ? '100%' : '424px'
        }}
        Map={SmallWebMap}
      >
        <FOHMapMarker
          {...location}
          setOpenPin={() => {}}
          isOpen={false}
          Callout={InfoBox}
          Marker={Marker}
          isActive={true}
          onMarkerPress={_id => {}}
        />
      </FOHMap>
    </FOHLocationDetailsModal>
  );
};

export default compose(
  withTranslation('LocationDetailFeature'),
  withIsMobile
)(LocationDetailsModal);
