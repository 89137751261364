import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { prop, length } from 'ramda';
import { useSearchParams } from 'react-router-dom';

import {
  OPTIONS_YES_NO_DROPDOWN,
  RESTAURANT_TYPES_DROPDOWN
} from '../../constants';
import { multiSelectItem } from '../../utils/fieldHelpers';
import { useNavigation } from '../../utils/navigation';
import { FOHLocationFilterSidebar } from '../../components';

import {
  addSearchQueries,
  removeSearchQueries,
  getSearchQuery,
  QPOSITIONTYPES,
  QRESTAURANTTYPES,
  QHEALTHINSURANCE,
  QDRESSCODE
} from '../Search';
import { usePositionTypes } from '../CandidateProfile/usePositionTypes';

export const countActiveFilters = appliedFilters => {
  return Object.keys(appliedFilters).reduce((acc, key) => {
    const filter = prop(key, appliedFilters);
    return filter === undefined ||
      filter === null ||
      length(filter) === 0 ||
      filter === ''
      ? acc
      : acc + 1;
  }, 0);
};

export const LocationFilterSideBar = props => {
  const {
    setAppliedFilters,
    filterDrawerOpen,
    setFilterDrawerOpen,
    shouldClearFilters
  } = props;
  const { location: navLocation } = useNavigation();
  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation('LocationFilterFeature');

  const { positionTypes: positionFilters } = usePositionTypes();
  // filter drop down states
  const [openPositions, setOpenPositions] = useState(false);
  const [openHealthInsurance, setOpenHealthInsurance] = useState(false);
  const [openDressCode, setOpenDressCode] = useState(false);
  const [openRestaurantTypes, setOpenRestaurantTypes] = useState(false);
  // filters (to keep track of selection state)
  const [positionTypes, setPositionTypes] = useState(
    getSearchQuery(navLocation, QPOSITIONTYPES, true)
  );

  const [healthInsurance, setHealthInsurance] = useState(
    getSearchQuery(navLocation, QHEALTHINSURANCE)
  );
  const [dressCode, setDressCode] = useState(
    getSearchQuery(navLocation, QDRESSCODE)
  );
  const [restaurantTypes, setRestaurantTypes] = useState(
    getSearchQuery(navLocation, QRESTAURANTTYPES, true)
  );

  const binaryOptions = OPTIONS_YES_NO_DROPDOWN(t);
  const toggleFilterDrawerOpen = () => setFilterDrawerOpen(!filterDrawerOpen);
  const clearFilters = () => {
    setPositionTypes([]);
    setHealthInsurance();
    setDressCode();
    setRestaurantTypes([]);
    setAppliedFilters({
      positionTypes: undefined,
      restaurantTypes: undefined,
      healthInsurance: undefined,
      dressCode: undefined
    });
    removeSearchQueries(
      searchParams,
      [QPOSITIONTYPES, QRESTAURANTTYPES, QHEALTHINSURANCE, QDRESSCODE],
      setSearchParams
    );
    toggleFilterDrawerOpen();
  };

  const applyFilters = ({
    positionTypes: _positionTypes,
    restaurantTypes: _restaurantTypes,
    healthInsurance: _healthInsurance,
    dressCode: _dressCode
  }) => {
    addSearchQueries(
      searchParams,
      [
        { key: QDRESSCODE, value: _dressCode },
        { key: QHEALTHINSURANCE, value: _healthInsurance },
        { key: QRESTAURANTTYPES, values: _restaurantTypes },
        { key: QPOSITIONTYPES, values: _positionTypes }
      ],
      setSearchParams
    );
    setAppliedFilters({
      positionTypes: _positionTypes,
      restaurantTypes: _restaurantTypes,
      healthInsurance: _healthInsurance,
      dressCode: _dressCode
    });
  };

  // set defualts from query string, fallback to user profile
  useEffect(() => {
    applyFilters({
      positionTypes: positionTypes.filter(
        val => val !== '' && val !== undefined
      ),
      healthInsurance: healthInsurance,
      dressCode: dressCode,
      restaurantTypes: restaurantTypes.filter(
        val => val !== '' && val !== undefined
      )
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (shouldClearFilters) {
      clearFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldClearFilters]);

  return (
    <FOHLocationFilterSidebar
      filterDrawerOpen={filterDrawerOpen}
      toggleFilterDrawerOpen={toggleFilterDrawerOpen}
      applyFiltersTitle={t('applyFiltersTitle')}
      filterTitle={t('filterTitle')}
      clearFiltersLabel={t('clearFiltersLabel')}
      sortBySectionTitle={t('sortBySectionTitle')}
      clearFilters={clearFilters}
      applyFilters={() => {
        applyFilters({
          positionTypes,
          healthInsurance,
          dressCode,
          restaurantTypes
        });
        toggleFilterDrawerOpen();
      }}
      sortBy={[
        {
          filters: positionFilters,
          filterOpen: openPositions,
          filterPlaceholder: t('positionsFilterPlaceholder'),
          onFilterSelectPress: () => setOpenPositions(!openPositions),
          selectedFilters: positionTypes,
          selectFilter: id =>
            multiSelectItem(id, setPositionTypes, positionTypes)
        }
      ]}
      filters={[
        {
          filters: RESTAURANT_TYPES_DROPDOWN.map(item => ({
            value: item.value,
            label: t(item.label)
          })),
          filterOpen: openRestaurantTypes,
          filterPlaceholder: t('restaurantTypeFilterPlaceholder'),
          selectedFilters: restaurantTypes,
          onFilterSelectPress: () =>
            setOpenRestaurantTypes(!openRestaurantTypes),
          selectFilter: id =>
            multiSelectItem(id, setRestaurantTypes, restaurantTypes)
        },
        {
          filters: binaryOptions,
          filterOpen: openHealthInsurance,
          filterPlaceholder: t('healthInsuranceFilterPlaceholder'),
          onFilterSelectPress: () =>
            setOpenHealthInsurance(!openHealthInsurance),
          selectedFilters:
            healthInsurance === false || healthInsurance === true
              ? [healthInsurance]
              : [],
          selectFilter: val => {
            healthInsurance === false ||
            (healthInsurance === true && val === true)
              ? setHealthInsurance()
              : setHealthInsurance(val);
          }
        },
        {
          filters: [
            { value: true, label: t('mayHaveDressCode') },
            { value: false, label: t('noDressCode') }
          ],
          filterOpen: openDressCode,
          filterPlaceholder: t('dressCodeFilterPlaceholder'),
          onFilterSelectPress: () => setOpenDressCode(!openDressCode),
          selectedFilters:
            dressCode === false || dressCode === true ? [dressCode] : [],
          selectFilter: val => {
            dressCode === false || (dressCode === true && val === true)
              ? setDressCode()
              : setDressCode(val);
          }
        }
      ]}
    />
  );
};
