import React, { useRef } from 'react';

import styled from 'styled-components/native';
import { View, TextInput, ScrollView, TouchableOpacity } from 'react-native';
import {
  defaultTo,
  path,
  length,
  prop,
  indexOf,
  flatten,
  last,
  propEq,
  find
} from 'ramda';
import { useFocus } from 'react-native-web-hooks';

import {
  FOHCircleLoader,
  FOHGrayX,
  FOHHeaderH4,
  FOHLabel,
  FOHLabelBold,
  FOHSearch,
  FOHSmallDetailLabel,
  FOHSpace,
  FOHSpaceSmall,
  FOHTextListCell,
  FOHFonts,
  FOHColors
} from '../../ingredients';
import { FOH_INPUT_HEIGHT } from '../../constants';

import { FOHSelectCheckboxTable } from '../tables/FOHSelectCheckboxTable';
import { FOHSelectItemProps } from '../select/FOHSelect';

export interface FOHOpenSearchProps {
  onChangeSearch: (val: string) => void;
  searchValue: string;
  searchPlaceholder: string;
  onKeyPressSearchBar?: (e: any) => void;
  sections: Array<Section>;
  selectedItems: Array<FOHSelectItemProps>;
  mergeSections?: boolean;
  selectItem: (item: FOHSelectItemProps) => void;
  freeTextLabel: string;
  selectFreeTextLabel: string;
  loading?: boolean;
  truncationLimit: number;
  clearSearch: () => void;
  searchDetailLabel: string;
  noIcon?: boolean;
  noFreeText?: boolean;
  disabled?: boolean;
}

interface Section {
  title: string;
  items: Array<FOHSelectItemProps>;
}

export const FOHOpenSearch = (props: FOHOpenSearchProps) => {
  const allItems = flatten(props.sections.map(section => section.items));
  const allItemsByValue = allItems.reduce(
    (acc, item) => ({
      ...acc,
      [item.value]: { ...item }
    }),
    {}
  );

  const ref = useRef(null);
  const isInputActive = useFocus(ref);

  return (
    <View>
      <View
        style={{
          backgroundColor: props.disabled
            ? FOHColors.GRAYSCALE_100
            : FOHColors.WHITE
        }}
      >
        <Container
          style={{
            borderWidth: 1,
            borderRadius: 4,
            minHeight: FOH_INPUT_HEIGHT,
            flexWrap: 'wrap',
            paddingLeft: props.noIcon ? 1 : 10,
            paddingRight: 34,
            backgroundColor: props.disabled
              ? FOHColors.GRAYSCALE_100
              : FOHColors.WHITE,
            borderColor: props.disabled
              ? FOHColors.GRAYSCALE_200
              : FOHColors.GRAYSCALE_300
          }}
        >
          <TouchableOpacity
            onPress={() => {
              if (!props.disabled) {
                props.clearSearch();
              }
            }}
            style={{
              position: 'absolute',
              right: 10,
              top: 12,
              zIndex: 99
            }}
          >
            <FOHGrayX
              style={{
                tintColor: props.disabled
                  ? FOHColors.GRAYSCALE_400
                  : isInputActive
                  ? FOHColors.PRIMARY_TEAL_P1
                  : FOHColors.GRAYSCALE_500
              }}
            />
          </TouchableOpacity>
          {!props.noIcon && (
            <View>
              <FOHSearch style={{ tintColor: FOHColors.GRAYSCALE_500 }} />
            </View>
          )}
          <FOHSpaceSmall />
          {props.selectedItems.map(
            (item: FOHSelectItemProps, index: number) => (
              <FOHTextListCell
                key={`${index}val`}
                value={defaultTo(
                  prop('label', item),
                  path([prop('value', item), 'label'], allItemsByValue)
                )}
                removeValueFromInput={() => {
                  props.selectItem(
                    prop(
                      indexOf(item, props.selectedItems),
                      props.selectedItems
                    )
                  );
                }}
              />
            )
          )}
          <Input
            ref={ref}
            onChangeText={props.onChangeSearch}
            value={props.searchValue}
            placeholder={
              length(props.selectedItems) > 0 ? '' : props.searchPlaceholder
            }
            onKeyPress={(e: any) => {
              const key = path(['nativeEvent', 'key'], e);
              if (
                key === 'Backspace' &&
                !props.searchValue &&
                length(props.selectedItems) > 0
              ) {
                const lastValue = last(props.selectedItems);
                return lastValue && props.selectItem(lastValue);
              } else if (
                key === 'Enter' &&
                props.searchValue &&
                !props.noFreeText
              ) {
                props.selectItem({
                  value: props.searchValue,
                  label: `"${props.searchValue}"`
                });
                props.onChangeSearch('');
              }
              props.onKeyPressSearchBar && props.onKeyPressSearchBar(e);
            }}
            disabled={props.disabled}
          />
        </Container>

        {!props.disabled && (
          <ListContainer
            style={{
              width: '100%',
              zIndex:
                (defaultTo(0, path(['style', 'zIndex'], props)) as number) + 11
            }}
          >
            <ScrollView>
              <FOHSpace />
              {!props.noFreeText && props.searchValue && (
                <TouchableOpacity
                  onPress={() => {
                    props.selectItem({
                      value: props.searchValue,
                      label: `"${props.searchValue}"`
                    });
                    props.onChangeSearch('');
                  }}
                >
                  {props.searchValue && (
                    <FOHLabel>
                      {props.selectFreeTextLabel}
                      <FOHSpaceSmall />
                      <FOHLabelBold>{`"${props.searchValue}"`}</FOHLabelBold>
                      <FOHSpaceSmall />
                      <FOHSmallDetailLabel>
                        {props.searchDetailLabel}
                      </FOHSmallDetailLabel>
                    </FOHLabel>
                  )}
                  <FOHSpace />
                </TouchableOpacity>
              )}
              {props.loading ? (
                <View
                  style={{
                    paddingRight: 24
                  }}
                >
                  <FOHCircleLoader />
                </View>
              ) : (
                props.sections.map(section => (
                  <View key={prop('title', section)}>
                    {length(prop('items', section)) > 0 && (
                      <>
                        {!props.mergeSections && (
                          <>
                            <FOHHeaderH4
                              style={{
                                color: '#BDBDBD',
                                textAlign: 'left'
                              }}
                            >
                              {prop('title', section)}
                            </FOHHeaderH4>
                            <FOHSpaceSmall />
                          </>
                        )}
                        <FOHSelectCheckboxTable
                          items={section.items}
                          borders={false}
                          selected={props.selectedItems.map(item => item.value)}
                          selectItem={(value: any) => {
                            const item = find(
                              propEq('value', value),
                              section.items
                            );
                            item &&
                              props.selectItem({
                                value: prop('value', item),
                                label: prop('label', item)
                              });
                          }}
                        />
                      </>
                    )}
                  </View>
                ))
              )}
            </ScrollView>
          </ListContainer>
        )}
      </View>
    </View>
  );
};

const Input = styled(TextInput)`
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  display: flex;
  align-items: center;
  height: 14px;
  flex-direction: row;
  padding: 10px;
  padding-left: 0px;
  outline-color: transparent;
  flex: 1;
  outline-color: transparent;
  background: ${(props: any) =>
    props.disabled ? FOHColors.GRAYSCALE_100 : FOHColors.WHITE};
`;

const Container = styled(View)`
  background: ${FOHColors.WHITE}
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: row;
  border-radius: 4px;
  padding: 4px;
`;

const ListContainer = styled(View)`
  height: 350px;
  background-color: ${FOHColors.WHITE}
  width: 327px;
  justify-content: left;
  border-radius: 4px;
  z-index: 11;
  border-radius: 4px;
`;
