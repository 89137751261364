import React, { useState, useEffect } from 'react';

import { compose, defaultTo, path, prop } from 'ramda';
import { withTranslation } from 'react-i18next';
import { Autocomplete } from '@react-google-maps/api';

import {
  FOHEmployerLocationVerify,
  FOHFixedHeightScrollView,
  FOHView,
  FOHTextField,
  FOHFormFlowButtons
} from '../../components';
import { handleMutation, withIsMobile } from '../../utils';
import { EXTERNAL_APPLICATION_HEIGHTTODECREASE } from '../../constants';
import {
  GetMyLocationsDocument,
  useMutateCreateLocationMutation,
  useGetLocationQuery,
  useMutateUpdateLocationMutation
} from '../../graphql/generated';

import { JoinTeamModal } from '../EmployerTeam/JoinTeamModal';

import { getAddressInfo, autoCompleteAPIOptions } from './constants';

const VerifyOrEditLocation = props => {
  const [openJoinTeam, setOpenJoinTeam] = useState(
    !!path(['invitedBy'], props)
  );

  const { t } = props;

  const locationQuery = useGetLocationQuery({
    skip: !props?.location?.id,
    variables: { id: props?.location?.id }
  });

  const location = path(['data', 'locationById'], locationQuery);

  const [createLocation] = useMutateCreateLocationMutation();
  const [mutateLocation] = useMutateUpdateLocationMutation();

  const [updating, setUpdating] = useState(false);

  const [companyName, setCompanyName] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [summary, setSummary] = useState('');
  const [indeedEasyapplyOptout, setIndeedEasyapplyOptout] = useState(false);

  const [searchResult, setSearchResult] = useState('');

  const handleLoadScript = autocomplete => setSearchResult(autocomplete);

  const handlePlaceSelect = () => {
    if (searchResult === null) return;

    const selectedPlace = searchResult.getPlace();
    const addressInfo = getAddressInfo(selectedPlace);

    setAddress1(addressInfo.street1);
    setCity(addressInfo.city);
    setState(addressInfo.state);
    setPostalCode(addressInfo.postalCode);
  };

  useEffect(() => {
    setCompanyName(defaultTo('', prop('name', location)));
    setAddress1(defaultTo('', prop('addressStreet1', location)));
    setAddress2(defaultTo('', prop('addressStreet2', location)));
    setCity(defaultTo('', prop('addressCity', location)));
    setState(defaultTo('', prop('addressState', location)));
    setPostalCode(defaultTo('', prop('addressPostalCode', location)));
    setSummary(defaultTo('', prop('summary', location)));
    setIndeedEasyapplyOptout(
      defaultTo(false, prop('indeedEasyapplyOptoutDefault', location))
    );
  }, [location]);

  const handleSubmit = async () => {
    setUpdating(true);
    const newLocation = !location || !location?.id || !props.location;

    const variables = {
      name: companyName,
      summary,
      addressStreet1: address1,
      addressStreet2: address2,
      addressCity: city,
      addressState: state,
      addressPostalCode: postalCode,
      indeedEasyapplyOptoutDefault: indeedEasyapplyOptout
    };

    const refetchQueries = [
      {
        query: GetMyLocationsDocument,
        variables: {
          search: ''
        }
      }
    ];

    const [locationResponse] = await handleMutation(
      newLocation
        ? createLocation({
            variables,
            refetchQueries: refetchQueries
          })
        : mutateLocation({
            variables: {
              ...variables,
              id: location?.id
            },
            refetchQueries: refetchQueries
          })
    );

    const newOrUpdatedLocation = locationResponse?.mutateLocation?.location;
    props.setLocation(newOrUpdatedLocation);
    setUpdating(false);

    return await props.nextStep();
  };

  return (
    <>
      <FOHView
        style={{
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <FOHView
          style={{
            width: '100%'
          }}
        >
          <FOHFixedHeightScrollView
            isMobile={props.isMobile}
            heightToDecrease={EXTERNAL_APPLICATION_HEIGHTTODECREASE}
          >
            <FOHView
              style={{
                width: '100%'
              }}
            >
              <FOHEmployerLocationVerify
                key={prop('slug', props.location)}
                title={t('title')}
                description={t('description')}
                companyNamePlaceholder={t('companyNamePlaceholder')}
                street1Placeholder={t('street1Placeholder')}
                street2Placeholder={t('street2Placeholder')}
                cityPlaceholder={t('cityPlaceholder')}
                statePlaceholder={t('statePlaceholder')}
                postalCodePlaceholder={t('postalCodePlaceholder')}
                summaryPlaceholder={t('summaryPlaceholder')}
                largeHeader={true}
                loading={locationQuery?.loading}
                vertical={props.isMobile}
                companyName={companyName}
                setCompanyName={setCompanyName}
                street1={address1}
                setStreet1={setAddress1}
                street2={address2}
                setStreet2={setAddress2}
                city={city}
                disabledSubmit={
                  updating ||
                  !city ||
                  !address1 ||
                  !companyName ||
                  !state ||
                  !postalCode
                }
                setCity={setCity}
                state={state}
                setState={setState}
                postalCode={postalCode}
                setPostalCode={setPostalCode}
                summary={summary}
                setSummary={setSummary}
                indeedEasyapplyOptoutLabel={t(
                  'EditLocationBannerFeature:indeedEasyapplyOptoutLabel'
                )}
                indeedEasyapplyOptout={indeedEasyapplyOptout}
                setIndeedEasyapplyOptout={setIndeedEasyapplyOptout}
                handleSubmit={handleSubmit}
                autoCompleteInput={
                  <Autocomplete
                    onPlaceChanged={handlePlaceSelect}
                    onLoad={handleLoadScript}
                    options={autoCompleteAPIOptions}
                  >
                    <FOHTextField
                      placeholder={t('street1Placeholder')}
                      value={address1 || ''}
                      required={true}
                      autoCompleteType={'street-address'}
                      onChangeText={value => setAddress1(value)}
                    />
                  </Autocomplete>
                }
              />
            </FOHView>
          </FOHFixedHeightScrollView>
        </FOHView>
        <FOHView
          style={{
            width: '100%',
            marginBottom: props.isMobile ? 0 : 100
          }}
        >
          <FOHFormFlowButtons
            isMobile={true}
            nextButtonlabel={
              updating ? `${t('common:loading')}...` : t('submitLabel')
            }
            onPressNextButton={handleSubmit}
            isNextDisabled={
              updating ||
              !city ||
              !address1 ||
              !companyName ||
              // !summary ||
              !state ||
              !postalCode
            }
            previousButtonLabel={t('common:previous')}
            onPressPreviousButton={props.previousStep}
          />
        </FOHView>
      </FOHView>
      <JoinTeamModal
        {...props}
        close={() => {
          setOpenJoinTeam(false);
        }}
        open={
          !!path(['location', 'slug'], props) &&
          !!path(['invitedBy'], props) &&
          openJoinTeam
        }
      />
    </>
  );
};

export default compose(
  withIsMobile,
  withTranslation('ClaimLocationFeature')
)(VerifyOrEditLocation);
