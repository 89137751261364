import React, { ReactNode } from 'react';

import { StyleProp, View, ViewStyle } from 'react-native';
import { prop } from 'ramda';

import { FOHSmallDetailLabel } from './FOHSmallDetailLabel';

interface WithPlaceholderTextProps {
  value?: any;
  selectedValue?: any;
  neverEmpty?: boolean;
  placeholder?: string;
  underline?: boolean;
  style?: StyleProp<ViewStyle>;
  children?: ReactNode;
}

export const withPlaceholderText =
  (Cmp: React.ComponentType<any>) => (props: WithPlaceholderTextProps) => {
    const hasValue =
      props.value ||
      props.selectedValue ||
      props.value === false ||
      props.selectedValue === false ||
      props.neverEmpty;

    return (
      <View style={{ width: '100%' }}>
        <FOHSmallDetailLabel
          style={{
            marginTop: 2,
            marginLeft: prop('underline', props) ? 0 : 10,
            position: 'absolute',
            zIndex: 2,
            fontSize: 12,
            textTransform: 'uppercase'
          }}
        >
          {hasValue ? props.placeholder : ''}
        </FOHSmallDetailLabel>
        <Cmp
          style={{
            ...(props.style as ViewStyle),
            paddingTop: hasValue ? 18 : 5
          }}
          {...props}
        >
          {props.children}
        </Cmp>
      </View>
    );
  };
