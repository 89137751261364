import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { defaultTo, path, prop } from 'ramda';
import { Helmet } from 'react-helmet';

import { useIsMobile } from '../../utils';
import { useGetMyApplicationsQuery } from '../../graphql/generated';
import { CANDIDATE_PROFILE } from '../../constants';
import { useNavigation } from '../../utils/navigation';
import { FOHGoToModal } from '../../components';

import { useGetMeData } from '../SignIn';

export const CompletedApplicationModal = props => {
  const isOnboardingApplication = defaultTo(
    false,
    props.isOnboardingApplication
  );
  const { navigateTo } = useNavigation();
  const { t } = useTranslation('JobApplicationsModalFeature');
  const myApplications = useGetMyApplicationsQuery({
    skip: !isOnboardingApplication || !props.open
  });
  const { isMobile } = useIsMobile();
  const { open, close } = props;
  // If they signed up due to a job application, query for position since it will be the only one
  const position =
    props.position ||
    path(
      ['data', 'myApplications', 'edges', 0, 'node', 'position'],
      myApplications
    );

  const location = prop('location', position);
  const { data: me } = useGetMeData();
  const handle = useMemo(
    () => defaultTo(null, path(['candidateProfile', 'handle'], me)),
    [me]
  );

  return (
    <>
      <Helmet>
        {open && !!position && (
          <script
            type="text/javascript"
            src="https://click.appcast.io/pixels/generic3-12496.js?ent=196"
          />
        )}
      </Helmet>
      <FOHGoToModal
        open={open && !!position}
        close={close}
        dialog={!isMobile}
        headerLabel={t('headerLabel', {
          location: prop('name', location),
          position: prop('positionName', position)
        })}
        buttonLabel={t('buttonLabel')}
        detailLabel={t('detailText')}
        preLinkText={t('preLinkText')}
        linkText={t('linkText')}
        onPressLink={() => {
          isOnboardingApplication
            ? close()
            : navigateTo(`${CANDIDATE_PROFILE}/${handle}`);
        }}
        onPressButton={close}
      />
    </>
  );
};
