import React from 'react';

import { Text } from 'react-native';
import { prop } from 'ramda';
import styled from 'styled-components/native';

import { FOHSuggestionIcon } from '../icons/FOHSuggestionIcon';
import { FOHSpaceSmall } from '../space/FOHSpace';
import { FOHView } from '../containers/Containers';
import { FOHColors } from '../colors/FOHColors';

import { FOHFonts } from './FOHFonts';

export const FOHSuggestionText = (props: { children: string }) => (
  <FOHView style={{ flexDirection: 'row', alignItems: 'center' }}>
    <FOHSuggestionIcon />
    <FOHSpaceSmall />
    <FOHSuggestionLabel>{props.children}</FOHSuggestionLabel>
  </FOHView>
);

const FOHSuggestionLabel = styled(Text)`
  font-family: ${FOHFonts.BOLD};
  font-style: normal;
  font-weight: ${(props: any) => (prop('heavy', props) ? '600' : '500')};
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  color: ${FOHColors.PACIFIC_BLUE};
`;
