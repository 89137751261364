import { FOHColors } from './ingredients/colors/FOHColors';
import { FOHNotificationType } from './typings';

export const selectItem = (
  item: any,
  setState: (value: any) => void,
  state: any
): void => {
  if (Array.isArray(state)) {
    if (state.includes(item)) {
      return setState(state.filter(stateItem => stateItem !== item));
    } else {
      return setState([...state, item]);
    }
  }

  return setState(item);
};

export const getColorByBannerType = (type: string) => {
  switch (type) {
    case FOHNotificationType.TODO:
      return { light: FOHColors.LIGHT_RED, dark: FOHColors.RED };
    case FOHNotificationType.DONE:
      return { light: FOHColors.LIGHT_GREEN, dark: FOHColors.GREEN };
    case FOHNotificationType.PENDING:
      return { light: FOHColors.LIGHT_GOLD, dark: FOHColors.GOLD };
    case FOHNotificationType.UNSCHEDULED:
      return { light: FOHColors.LIGHT_ORANGE, dark: FOHColors.ORANGE };
    case FOHNotificationType.DECLINED:
      return { light: FOHColors.LIGHT_RED, dark: FOHColors.RED };
    case FOHNotificationType.INFO:
      return {
        light: FOHColors.BACKGROUND_GRAY,
        dark: FOHColors.BACKGROUND_DARK_GRAY
      };
    default:
      return {
        light: FOHColors.BACKGROUND_GRAY,
        dark: FOHColors.BACKGROUND_DARK_GRAY
      };
  }
};

export const selectExternalApplicationTagItem = (
  value: any,
  setSelected: (props: any) => any,
  selected: Array<any>,
  message: string,
  setMessage: (props: any) => any
) => {
  if (selected.includes(value)) {
    const newSelection = selected.filter(item => item !== value);
    message = message.replace(value, '').replace(value.toLowerCase(), '');
    return setSelected(newSelection), setMessage(message);
  } else {
    return (
      setSelected([...selected, value]), setMessage([message, value].join(' '))
    );
  }
};

export const getShortId = () => {
  return Math.random().toString(36).slice(2);
};

export const formatFileSize = (bytes: number) => {
  const sufixes = ['B', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sufixes[i]}`;
};

export const getTextWidthFromFont = (txt: string, font: string) => {
  const element = document.createElement('canvas');
  const context = element.getContext('2d');
  if (!context) return 0;
  context.font = font;
  return context.measureText(txt).width;
};

export const trimText = (textToTrim: string, trimLength: number): string => {
  if (textToTrim.length > trimLength) {
    return `${textToTrim.slice(0, trimLength)}...`;
  } else {
    return textToTrim;
  }
};

export const currencyFormat = (value: number): string => {
  return value.toLocaleString('en-US', { style: 'currency', currency: 'usd' });
};

export const isEmailValid = (email: string, t: any): undefined | string => {
  if (!email) return undefined;

  // taken from employers.utils.email_validate
  const regex = new RegExp(
    '\\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Z|a-z]{2,}\\b'
  );

  if (email.includes(' ')) return t('emailSpacesError');

  if (!regex.test(email)) return t('emailInvalidError');

  return '';
};
