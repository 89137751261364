import React, { useRef } from 'react';

import styled from 'styled-components/native';
import { View, TouchableOpacity } from 'react-native';
import { path } from 'ramda';
import { useHover } from 'react-native-web-hooks';

import { FOH_INPUT_HEIGHT } from '../../constants';

import { FOHToolTip, FOHToolTipType } from '../tooltips/FOHToolTip';
import { FOHColors } from '../colors/FOHColors';

import { ButtonText, FOHButtonProps } from './FOHButton';

export const FOHButtonSecondary = (props: FOHButtonProps) => {
  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);

  const Icon = props?.icon;

  return (
    <TouchableOpacity
      disabled={props.disabled}
      testID={props.testID || `button-${props.title}`}
      onPress={props.onPress}
      style={[
        {
          width: '100%',
          maxWidth: props.fullWidth ? '100%' : 325
        },
        props.style
      ]}
    >
      <StyledButton
        style={{
          backgroundColor: props.disabled
            ? FOHColors.GRAYSCALE_100
            : isHovered
            ? props.color || FOHColors.GRAYSCALE_900
            : props.color || FOHColors.GRAYSCALE_700,
          height: path(['style', 'height'], props) || FOH_INPUT_HEIGHT,
          padding: 8,
          borderWidth: 1,
          borderColor:
            props.borderColor ?? props.disabled
              ? props.borderColor || FOHColors.GRAYSCALE_100
              : props.color || FOHColors.GRAYSCALE_700
        }}
      >
        {props.enableHover && (
          <FOHToolTip
            helpText={props.helpText}
            open={isHovered}
            type={props.toolTipType}
            style={
              props.toolTipType === FOHToolTipType.LEFT
                ? { width: 200, right: 60, top: 10 }
                : { width: 200 }
            }
          />
        )}
        {Icon && <Icon />}
        <ContrastButtonText
          ref={hoverRef}
          style={[
            {
              color: props.disabled
                ? FOHColors.GRAYSCALE_400
                : props.textColor || FOHColors.WHITE,
              paddingLeft: Icon ? 6 : 0
            },
            props?.textStyle
          ]}
        >
          {props.title}
        </ContrastButtonText>
      </StyledButton>
    </TouchableOpacity>
  );
};

const ContrastButtonText = styled(ButtonText)`
  color: ${FOHColors.WHITE};
`;

const StyledButton = styled(View)`
  align-items: center;
  border-radius: 4px;
  flex-direction: row;
  justify-content: center;
  height: ${FOH_INPUT_HEIGHT}px;
`;
