import React, { useState, useEffect } from 'react';

import { compose, path, prop } from 'ramda';
import { useTranslation } from 'react-i18next';

import {
  useIsMobile,
  selectExternalApplicationTagItem,
  shuffleArray,
  handle
} from '../../utils';
import {
  CANDIDATE_ACCOUNT_MENU_PATH,
  CANDIDATE_PERSONAL_SUMMARY_PATH,
  EXTERNAL_APPLICATION_HEIGHTTODECREASE
} from '../../constants';
import { useNavigation } from '../../utils/navigation';
import {
  FOHFixedHeightScrollView,
  FOHView,
  FOHButton,
  FOHExternalApplicationSummaryForm,
  FOHFormFlowButtons
} from '../../components';

import { withReactiveModal } from '../Navigation/withReactiveModal';

import { useCandidateProfileMutation } from './useCandidateProfileMutation';

const MAX_SCREEN_WIDTH = 570;

const PersonalSummary = props => {
  const { t } = useTranslation('PersonalSummaryFeature');
  const { navigateTo, location: navLocation } = useNavigation();

  const { isMobile } = useIsMobile();
  const { me, updateCandidate } = useCandidateProfileMutation();

  const [summary, setSummary] = useState('');
  const [selected, setSelected] = useState(['']);
  const [suggestionOptions] = useState(
    shuffleArray([
      t('ExternalApplicationFeature:summaryOption1'),
      t('ExternalApplicationFeature:summaryOption2'),
      t('ExternalApplicationFeature:summaryOption3'),
      t('ExternalApplicationFeature:summaryOption4'),
      t('ExternalApplicationFeature:summaryOption5'),
      t('ExternalApplicationFeature:summaryOption6'),
      t('ExternalApplicationFeature:summaryOption7'),
      t('ExternalApplicationFeature:summaryOption8'),
      t('ExternalApplicationFeature:summaryOption9'),
      t('ExternalApplicationFeature:summaryOption10')
    ]).map(option => ({ label: option, value: option }))
  );
  const [error, setError] = useState('');

  const hasBack =
    navLocation.pathname === CANDIDATE_PERSONAL_SUMMARY_PATH &&
    !!path(['candidateProfile', 'onboardingCompletedAt'], me);

  const handleSubmit = async () => {
    const [resp] = await handle(
      updateCandidate({
        variables: {
          personalSummary: summary
        }
      })
    );

    const err = path(['data', 'mutateCandidate', 'errors'], resp);

    if (resp && !err) {
      if (props.close) return props.close();

      return path(['candidateProfile', 'onboardingCompletedAt'], me)
        ? navigateTo(CANDIDATE_ACCOUNT_MENU_PATH)
        : props.onSubmit && props.onSubmit();
    } else if (err) {
      setError(err);
    }
  };

  useEffect(() => {
    setSummary(path(['candidateProfile', 'personalSummary'], me));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prop('candidateProfile', me)]);

  return (
    <>
      <FOHView
        style={{
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: props.isInSettings ? 60 : 0
        }}
      >
        <FOHFixedHeightScrollView
          isMobile={isMobile}
          heightToDecrease={EXTERNAL_APPLICATION_HEIGHTTODECREASE}
        >
          <FOHView
            style={{
              width: '100%',
              maxWidth: isMobile ? '100%' : MAX_SCREEN_WIDTH
            }}
          >
            <FOHExternalApplicationSummaryForm
              message={summary}
              messagePlaceholder={t('personalSummaryPlaceholder')}
              handleMessage={value => setSummary(value)}
              applicationMessageTitle={t('summaryTitle')}
              applicationMessageDetail={t('detailText')}
              suggestionText={t(
                'ExternalApplicationFeature:summaryFormSuggestionText'
              )}
              selectedSuggessions={selected}
              suggestionOptions={suggestionOptions}
              selectItem={val =>
                selectExternalApplicationTagItem(
                  val,
                  setSelected,
                  selected,
                  summary,
                  setSummary
                )
              }
              submitLabel={t('actions:submit')}
              handleSubmit={handleSubmit}
              // props.error.message
              error={error}
              mobile={isMobile}
            />
          </FOHView>
          {/* Make sure this looks good when opening up as a modal on the /u/handle page */}
          {props.isModal && (
            <FOHButton title={t('common:save')} onPress={handleSubmit} />
          )}
        </FOHFixedHeightScrollView>
        <FOHView
          style={{
            width: '100%',
            maxWidth: isMobile ? '100%' : MAX_SCREEN_WIDTH,
            marginBottom: isMobile ? 0 : 10,
            alignItems: 'center'
          }}
        >
          {!props.isModal && (
            <FOHFormFlowButtons
              isMobile={isMobile}
              nextButtonlabel={
                path(['candidateProfile', 'onboardingCompletedAt'], me)
                  ? t('common:save')
                  : t('nextLabel')
              }
              onPressNextButton={handleSubmit}
              isNextDisabled={!summary}
              previousButtonLabel={t('common:previous')}
              onPressPreviousButton={
                hasBack
                  ? () => navigateTo(CANDIDATE_ACCOUNT_MENU_PATH)
                  : props.previousStep
              }
            />
          )}
        </FOHView>
      </FOHView>
    </>
  );
};

export const PersonalSummaryModal = compose(withReactiveModal)(PersonalSummary);

export default PersonalSummary;
