import React, { useState, useEffect } from 'react';

import { prop } from 'ramda';

import { FOHView } from '../../components';

import { SetValidEmailModal } from '../ClaimAccount/SetValidEmailModal';

import TopNav from './TopNav';
import { useBanner } from './useBanner';
import { NavigationMenu } from './NavigationMenu';
import { SidebarMenu } from './SidebarMenu';
import { useSidebarNav } from './useSidebar';

export const withNav = Cmp => props => {
  const [burgerOpen, setBurgerOpen] = useState(false);
  const [changeEmail, setChangeEmail] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  // TODO setbanner with context
  const { banner } = useBanner();
  const { hasSideNav, contentShift, loadingFeatureFlags } =
    useSidebarNav(props);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  const openSetPasswordAndEmail = () => {
    setChangeEmail(true);
  };

  if (!isMounted || loadingFeatureFlags) {
    return <></>;
  }

  return (
    <>
      {!props.hideTopNav && !hasSideNav && (
        <TopNav
          setBurgerOpen={setBurgerOpen}
          burgerOpen={burgerOpen}
          bannerType={prop('bannerType', banner)}
          bannerMessage={prop('bannerMessage', banner)}
        />
      )}
      {hasSideNav && <SidebarMenu {...props} />}
      {hasSideNav ? (
        <FOHView
          style={{
            paddingLeft: contentShift * 2,
            minHeight: '100vh'
          }}
        >
          <Cmp
            {...props}
            burgerOpen={burgerOpen}
            setBurgerOpen={setBurgerOpen}
            openSetPasswordAndEmail={openSetPasswordAndEmail}
          >
            {props.children}
          </Cmp>
        </FOHView>
      ) : (
        <Cmp
          {...props}
          burgerOpen={burgerOpen}
          setBurgerOpen={setBurgerOpen}
          openSetPasswordAndEmail={openSetPasswordAndEmail}
        >
          {props.children}
        </Cmp>
      )}
      {/* Global Modal Components */}
      <SetValidEmailModal
        setOpen={setChangeEmail}
        open={changeEmail}
        close={() => {
          setChangeEmail(false);
        }}
      />
      {burgerOpen && (
        <NavigationMenu
          {...props}
          burgerOpen={burgerOpen}
          setBurgerOpen={setBurgerOpen}
        />
      )}
    </>
  );
};
