import React, { FunctionComponent } from 'react';

import styled from 'styled-components/native';

import {
  FOHColors,
  FOHHeaderH4,
  FOHTouchableOpacity,
  SPACING_SCALE
} from '../../ingredients';
import { FileFields } from '../../typings';
import { formatFileSize } from '../../utils';

import {
  FOHEditCoverPhotoButtonProps,
  FilePreviewSetter,
  CoverMode
} from '../buttons/FOHEditCoverPhotoButton';

export type FOHPickStockPhotoFormProps = Pick<
  FOHEditCoverPhotoButtonProps,
  'stockPhotos' | 'setCoverAsset' | 'translations' | 'blobToFile'
> & {
  setFilePreview: FilePreviewSetter;
};

export const FOHPickStockPhotoForm: FunctionComponent<
  FOHPickStockPhotoFormProps
> = ({
  stockPhotos = [],
  setCoverAsset,
  setFilePreview,
  blobToFile,
  translations
}) => {
  const handlePickImage = (file: File) => {
    setFilePreview({
      [FileFields.FileName]: file.name,
      [FileFields.FileType]: formatFileSize(file.size),
      url: URL.createObjectURL(file)
    });

    setCoverAsset({
      color: null,
      image: {
        file,
        fileName: file.name,
        fileType: file.type,
        url: URL.createObjectURL(file)
      },
      mode: CoverMode.Image
    });
  };

  return (
    <>
      <SectionHeader
        style={{
          marginTop: SPACING_SCALE.huge,
          marginBottom: SPACING_SCALE.regular
        }}
      >
        {translations.pickStockPhotoHeader}
      </SectionHeader>

      <ImagesWrapper>
        {stockPhotos.map(photo => (
          <FOHTouchableOpacity
            style={{
              marginRight: 7,
              marginTop: SPACING_SCALE.regular
            }}
            key={photo.toString()}
            onPress={() => blobToFile(photo, handlePickImage)}
          >
            <PickImage source={{ uri: photo as string }} />
          </FOHTouchableOpacity>
        ))}
      </ImagesWrapper>
    </>
  );
};

const SectionHeader = styled(FOHHeaderH4)`
  font-size: 16px;
  text-transform: uppercase;
  color: ${FOHColors.SILVER};
  text-align: start;
`;

const PickImage = styled.Image`
  width: 99px;
  height: 80px;
  border-radius: 3;
`;

const SectionWrapper = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
`;

const ImagesWrapper = styled(SectionWrapper)`
  margin-right: -7;
`;
