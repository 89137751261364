import React from 'react';

import blue_add from '../../../images/blue_add.svg';

import { FOHImage } from '../../ingredients';

export const FOHBlueAdd = (props: any) => (
  <FOHImage
    source={{ uri: blue_add }}
    style={
      props && !!props.style
        ? { width: 22, height: 22, ...props.style }
        : { width: 22, height: 22 }
    }
  />
);
