import { last } from 'ramda';

import { envVariables } from '../../constants';

const FILE_SIZE_SUFFIXES = ['B', 'KB', 'MB', 'GB', 'TB'];

export const getStockPhoto = index =>
  `https://${envVariables.AWS_S3_BUCKET_NAME}.s3.amazonaws.com/coverimages/coverimage${index}.jpg`;

export const formatFileSize = bytes => {
  const i = Math.floor(Math.log(bytes) / Math.log(1024));

  return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${FILE_SIZE_SUFFIXES[i]}`;
};
const fileNameFromUrl = url => last(url.split('/')).split('?')[0];

export const blobToFile = async (dataURL, callback) => {
  const imageString = last(dataURL.split('/'));

  // to fix cors issue added random key
  fetch(dataURL + '?randomKey=value')
    .then(res => res.blob())
    .then(blob => {
      return new File([blob], imageString || 'image.jpg', {
        type: `image/${imageString?.split('.')[1]}`
      });
    })
    .then(callback)
    .catch(err => err);
};

export const dataURLtoBlob = async dataURL => {
  try {
    if (!dataURL) return {};

    const res = await fetch(dataURL);
    const blob = await res.blob();
    const file = new File([blob], 'image.jpg');

    return {
      fileName: fileNameFromUrl(dataURL),
      fileSize: formatFileSize(file.size),
      fileType: dataURL.includes('.mp4') ? 'video/mp4' : 'image/jpeg',
      url: URL.createObjectURL(file)
    };
  } catch (e) {
    throw new Error(e);
  }
};

export const nullsToEmptyStrings = obj =>
  Object.keys(obj).reduce((acc, key) => {
    acc[key] = obj[key] || '';
    return acc;
  }, {});

export const createSlugFromString = str => {
  return str
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-') // Replace non-alphanumeric characters with a hyphen
    .replace(/^-+|-+$/g, '') // Remove leading and trailing hyphens
    .trim(); // Trim any remaining whitespace
};
