import React from 'react';

import styled from 'styled-components/native';
import { View, TextInput } from 'react-native';

import {
  FOHGeoSearchIcon,
  FOHSpaceSmallest,
  withPlaceholderText,
  FOHFonts,
  FOHColors
} from '../../ingredients';
import { FOH_INPUT_HEIGHT } from '../../constants';

export interface FOHLocationSearchBarProps {
  onChangeLocation: (val: string) => void;
  locationSearchValue: string;
  locationSearchPlaceholder: string;
  onKeyPressSearchLocationSearchBar?: (e: any) => void;
}

export const FOHLocationSearchBar = (props: FOHLocationSearchBarProps) => (
  <Container>
    <View>
      <FOHGeoSearchIcon />
    </View>
    <FOHSpaceSmallest />
    <LocationInput
      //@ts-ignore onChangeText
      onChangeText={props.onChangeLocation}
      value={props.locationSearchValue}
      placeholder={props.locationSearchPlaceholder}
      onKeyPress={props.onKeyPressSearchLocationSearchBar}
    />
  </Container>
);

const Input = styled(TextInput)`
  background: ${FOHColors.WHITE};
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  display: flex;
  align-items: center;
  width: 90%;
  height: 46;
  flex-direction: row;
  padding: 5px 10px;
  outline-color: transparent;
`;

export const LocationInput = withPlaceholderText(Input);

const Container = styled(View)`
  background: ${FOHColors.WHITE};
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 200%;
  align-items: center;
  height: ${FOH_INPUT_HEIGHT}px;
  flex-direction: row;
  padding: 8px;
  flex: 1;
`;
