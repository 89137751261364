import React, { useEffect, useRef, useState } from 'react';

import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { animated } from 'react-spring';
import { DocumentNode, QueryOptions } from '@apollo/client';
import { useHover } from 'react-native-web-hooks';

import {
  CANDIDATE_PROFILE,
  INTERVIEW_SCHEDULE,
  UPCOMING_INTERVIEWS_SCHEDULE
} from '../../constants';
import {
  FOHLabel,
  FOHLabelSmall,
  FOHColors,
  FOHView,
  FOHButton,
  FOHGrayX,
  FOHDivider,
  FOHTextButton,
  FOHSectionLabel,
  FOHProfileImage,
  FOHTouchableOpacity,
  FOHLabelMedium,
  FOHSpaceSmall,
  FOHInverseButton,
  FOHThumbDownIcon,
  FOHLinkOut,
  FOHTrashCan,
  FOHEditMarker,
  FOHFonts
} from '../../components';
import avatar from '../../assets/avatar.svg';
import { useNavigation } from '../../utils/navigation';
import { handleMutation } from '../../utils';

import { InterviewCancellationModal } from './components';

interface ViewEventModalProps {
  close: () => void;
  event: any;
  position?: any;
  agendaQuery: any;
  rejectCandidateMutation: (arg: any) => void;
  moveEmployerCandidatesMutation: (arg: any) => void;
  hiredStageId: string;
  refetchQueries: Array<string | DocumentNode | QueryOptions>;
  interviewMutation: (arg: any) => void;
}

const ViewEventModal = (props: ViewEventModalProps) => {
  const {
    close,
    event,
    position,
    refetchQueries,
    moveEmployerCandidatesMutation,
    hiredStageId,
    rejectCandidateMutation,
    interviewMutation
  } = props;
  const { t } = useTranslation('UpcomingInterviews');

  const { navigateTo } = useNavigation();

  const modalRef = useRef(null as any);

  const trashCanBtnHoverRef = useRef(null);
  const isTrashCanBtnHovered = useHover(trashCanBtnHoverRef);

  const pencilBtnHoverRef = useRef(null);
  const isPencilBtnHovered = useHover(pencilBtnHoverRef);

  const [
    showInterviewCanceledConfirmation,
    setShowInterviewCanceledConfirmation
  ] = useState<boolean>(false);

  const [modalPosition, setModalPosition] = useState(
    position
      ? {
          top: position.y,
          left: position.x + position.width + 10
        }
      : {
          top: 100,
          left: 100
        }
  );

  const date = DateTime.fromISO(event.dateOfInterview);
  const startDate = date.setLocale('en-US').toLocaleString({
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZoneName: 'short'
  });

  const candidateName =
    event?.employerCandidate?.candidateProfile?.user?.firstName +
    ' ' +
    event?.employerCandidate?.candidateProfile?.user?.lastName;

  const candidateInitials =
    event?.employerCandidate?.candidateProfile?.user?.firstName
      .slice(0, 1)
      .toUpperCase() +
    event?.employerCandidate?.candidateProfile?.user?.lastName
      .slice(0, 1)
      .toUpperCase();

  const candidateProfileImage =
    event.employerCandidate?.candidateProfile?.user?.profileImage;

  const isRejected =
    event.employerCandidate?.stage?.name.toLowerCase() === 'rejected';
  const isHired =
    event.employerCandidate?.stage?.name.toLowerCase() === 'hired';

  const onPressHire = async () => {
    moveEmployerCandidatesMutation({
      variables: {
        employerCandidateIds: [event.employerCandidate.id],
        positions: [event.positionOfInterview.id],
        oldStage: event.employerCandidate.stage.id,
        newStage: hiredStageId
      },
      awaitRefetchQueries: true,
      refetchQueries
    });
  };

  const onPressReject = async () => {
    rejectCandidateMutation({
      variables: {
        employerCandidates: [event.employerCandidate.id],
        stage: event.employerCandidate.stage.id,
        rejectReasons: []
      },
      awaitRefetchQueries: true,
      refetchQueries
    });
  };

  const onPressCanceledInterview = async () => {
    const [canceledInterviewResp, errorOnCanceled] = await handleMutation(
      interviewMutation({
        variables: {
          id: event?.interviewId,
          status: 'canceled'
        },
        awaitRefetchQueries: true,
        refetchQueries
      })
    );

    if (errorOnCanceled || !canceledInterviewResp) {
      return;
    }

    setShowInterviewCanceledConfirmation(false);
    close();
  };

  // Flipping the Event Modal when overflows out of screen
  useEffect(() => {
    if (modalRef?.current && position) {
      const currentModalPosition = modalRef?.current?.getBoundingClientRect();
      // Modal overflows right of the screen
      if (currentModalPosition?.right >= window?.innerWidth - 10) {
        setModalPosition(prev => ({
          ...prev,
          left: position?.x - currentModalPosition.width - 10
        }));
      }
      // Modal overflows bottom of the screen
      if (currentModalPosition?.bottom >= window?.innerHeight - 10) {
        setModalPosition(prev => ({
          ...prev,
          top: position?.y + position?.height - currentModalPosition.height
        }));
      }
    }
  }, [modalRef?.current]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FOHView
      ref={modalRef}
      style={{
        width: '366px',
        position: 'absolute',
        backgroundColor: FOHColors.WHITE,
        top: modalPosition.top,
        left: modalPosition.left,
        borderRadius: 4,
        borderColor: FOHColors.BORDER_GRAY,
        borderWidth: 1,
        shadowOpacity: 0.06,
        shadowRadius: 10,
        shadowColor: FOHColors.BLACK,
        shadowOffset: { width: 20, height: 10 },
        zIndex: 9
      }}
      testID={`viewEventModal-${candidateName}`}
    >
      <FOHView
        style={{
          width: '100%',
          height: 40,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
          paddingRight: 10,
          paddingTop: 10
        }}
      >
        <FOHView ref={pencilBtnHoverRef}>
          <FOHTextButton
            onPress={() => {
              navigateTo(
                {
                  pathname: `${UPCOMING_INTERVIEWS_SCHEDULE}${INTERVIEW_SCHEDULE}`
                },
                {
                  state: {
                    interviewId: event?.interviewId
                  }
                }
              );
            }}
            icon={() => (
              <FOHEditMarker
                style={{
                  marginRight: 6,
                  tintColor: isPencilBtnHovered
                    ? FOHColors.PRIMARY_TEAL_P1
                    : FOHColors.NARO_GREY,
                  width: 16,
                  height: 16
                }}
              />
            )}
            title=""
            testID="viewEventModal-reschedule-button"
          />
        </FOHView>
        <FOHSpaceSmall />
        <FOHView ref={trashCanBtnHoverRef}>
          <FOHTextButton
            onPress={() => {
              setShowInterviewCanceledConfirmation(true);
            }}
            icon={() => (
              <FOHTrashCan
                style={{
                  tintColor: isTrashCanBtnHovered
                    ? FOHColors.RED
                    : FOHColors.NARO_GREY,
                  width: 18,
                  height: 18
                }}
              />
            )}
            title=""
            testID="viewEventModal-canceled-interview"
          />
        </FOHView>
        <FOHSpaceSmall />
        <FOHView>
          <FOHTextButton
            onPress={() => {
              close();
            }}
            icon={() => (
              <FOHGrayX
                style={{
                  tintColor: FOHColors.NARO_GREY
                }}
              />
            )}
            title=""
            testID="viewEventModal-close-modal"
          />
        </FOHView>
      </FOHView>
      <FOHSpaceSmall />
      <FOHView
        style={{
          padding: 16,
          paddingTop: 0,
          position: 'relative'
        }}
      >
        <FOHView>
          <FOHTouchableOpacity
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
            onPress={() => {
              window &&
                window.open(
                  `${CANDIDATE_PROFILE}/${event?.employerCandidate?.candidateProfile?.handle}`,
                  '_blank'
                );
            }}
          >
            <AnimatedDonut
              style={{
                borderWidth: 1,
                borderColor: FOHColors.PRIMARY_TEAL_400,
                borderRadius: '50%',
                justifyContent: 'center',
                alignItems: 'center',
                width: 30,
                height: 30
              }}
            >
              <FOHView
                style={{
                  borderRadius: '50%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: FOHColors.GRAYSCALE_100,
                  height: 27,
                  width: 27
                }}
              >
                {event.candidateProfileImage ? (
                  <FOHProfileImage
                    photo={candidateProfileImage || avatar}
                    style={{
                      height: 27,
                      width: 27
                    }}
                  />
                ) : (
                  <FOHLabelSmall style={{ fontFamily: FOHFonts.MEDIUM }}>
                    {candidateInitials}
                  </FOHLabelSmall>
                )}
              </FOHView>
            </AnimatedDonut>
            <FOHSpaceSmall />
            <FOHSectionLabel
              style={{
                fontSize: 20,
                lineHeight: 26,
                marginBottom: 4,
                textDecorationLine: 'underline',
                fontFamily: FOHFonts.MEDIUM
              }}
            >
              {candidateName}, {event?.positionOfInterview?.positionName}{' '}
            </FOHSectionLabel>
          </FOHTouchableOpacity>
          <FOHSpaceSmall />
          <FOHView
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <FOHLabelMedium
              style={{
                color: FOHColors.PRIMARY_TEAL_P1
              }}
            >
              {startDate?.toString()}
            </FOHLabelMedium>
          </FOHView>
          <FOHView
            style={{
              width: '100%',
              marginTop: 30,
              marginBottom: 24
            }}
          >
            {event?.interviewType === 'phone' ? (
              <>
                <FOHLabelSmall testID="viewEventModal-interview-type">
                  {t('InterviewFeature:interviewTypeLabel')}:{' '}
                  {t('InterviewFeature:phoneMeetingLabel')}
                </FOHLabelSmall>
                <FOHLabelSmall
                  style={{ marginBottom: 20 }}
                  testID="viewEventModal-location"
                >
                  {t('ProfileFeature:phone')}: {event?.phoneNumber}
                </FOHLabelSmall>
              </>
            ) : event?.interviewType === 'remote' ? (
              <>
                <FOHLabelSmall testID="viewEventModal-interview-type">
                  {t('InterviewFeature:interviewTypeLabel')}:{' '}
                  {t('InterviewFeature:remoteMeetingLabel')}
                </FOHLabelSmall>
                <FOHLabelSmall
                  style={{ marginBottom: 20 }}
                  testID="viewEventModal-location"
                >
                  {t('meetingLink')}:{' '}
                  <FOHLinkOut href={event?.meetingUrl}>
                    {event?.meetingUrl}
                  </FOHLinkOut>
                </FOHLabelSmall>
              </>
            ) : (
              <>
                <FOHLabelSmall testID="viewEventModal-interview-type">
                  {t('InterviewFeature:interviewTypeLabel')}:{' '}
                  {t('InterviewFeature:locationMeetingLabel')}
                </FOHLabelSmall>
                <FOHLabelSmall
                  style={{ marginBottom: 20 }}
                  testID="viewEventModal-location"
                >
                  {t('location')}: {event?.locationOfInterview?.name},{' '}
                  {event?.locationOfInterview?.formattedAddress}
                </FOHLabelSmall>
              </>
            )}
            <FOHLabel testID="viewEventModal-requested-by">
              {t('interviewRequester')}: {event?.createdBy?.firstName}{' '}
              {event?.createdBy?.lastName}
            </FOHLabel>
          </FOHView>
        </FOHView>
        <FOHDivider />
        <FOHView
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            paddingTop: 16
          }}
        >
          <FOHInverseButton
            title={t('RejectReasonFeature:rejectLabel')}
            color={FOHColors.BLACK}
            icon={() => (
              <FOHThumbDownIcon
                style={{
                  tintColor: isRejected
                    ? FOHColors.SILVER_POLISH
                    : FOHColors.BLACK,
                  width: 13,
                  height: 13
                }}
              />
            )}
            style={{ width: 120, height: 32 }}
            onPress={() => {
              onPressReject();
              close();
            }}
            disabled={isRejected}
          />
          <FOHButton
            title={t('hireLabel')}
            color={FOHColors.PACIFIC_BLUE}
            style={{ width: 120, height: 32 }}
            onPress={() => {
              onPressHire();
              close();
            }}
            disabled={isHired}
          />
        </FOHView>

        {showInterviewCanceledConfirmation && (
          <InterviewCancellationModal
            candidateName={candidateName}
            onPressGoBack={() => setShowInterviewCanceledConfirmation(false)}
            onPressConfirm={onPressCanceledInterview}
          />
        )}
      </FOHView>
    </FOHView>
  );
};

const AnimatedDonut = animated(FOHView);

ViewEventModal.propTypes = {
  close: PropTypes.func,
  event: PropTypes.object,
  position: PropTypes.object
};

export default ViewEventModal;
