import React from 'react';

import styled from 'styled-components/native';
import { Image } from 'react-native';

import {
  FullWidthCard,
  FOHView,
  FOHSpaceLarger,
  FOHSpace,
  FOHSmallDetailLabel,
  FOHButton,
  FOHColors,
  FOHFonts,
  FOHHeaderH3
} from '../../ingredients';

export interface FOHEmptyWorkHistoryCellProps {
  isMobile?: boolean;
  image: string;
  headerText: string;
  detailText: string;
  submitBtnLabel: string;
  onPress: () => void;
}

export const FOHEmptyWorkHistoryCell = (
  props: FOHEmptyWorkHistoryCellProps
) => (
  <FullWidthCard style={{ padding: 16 }}>
    <Container>
      {!props.isMobile ? <FOHSpaceLarger /> : <></>}
      <ImageContainer mobile={props.isMobile}>
        <FOHImage
          source={{
            uri: props?.image
          }}
        />
      </ImageContainer>
      {!props.isMobile ? <FOHSpaceLarger /> : <></>}
      <Block mobile={props.isMobile}>
        <FOHHeaderH3>{props.headerText}</FOHHeaderH3>
        <FOHSpace />
        <FOHDetail>{props.detailText}</FOHDetail>
        <FOHSpace />
        <FOHButton
          style={{
            maxWidth: 248,
            width: '100%'
          }}
          title={props.submitBtnLabel}
          onPress={props.onPress}
          color={FOHColors.WHITE}
          textColor={FOHColors.BLACK}
          borderColor={FOHColors.SILVER_POLISH}
        />
      </Block>
    </Container>
  </FullWidthCard>
);

const Container = styled(FOHView)`
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
`;

const ImageContainer = styled(FOHView)<{ mobile?: boolean }>`
  width: ${(p: any) => (p.mobile ? '100%' : '125px')};
  height: ${(p: any) => (p.mobile ? '100%' : '183px')};
  align-items: center;
  justify-content: center;
`;

const FOHImage = styled(Image)`
  max-width: 125px;
  width: 100%;
  height: 183px;
  object-fit: cover;
`;

const Block = styled(FOHView)<{ mobile?: boolean }>`
  padding: ${(p: any) => (p.mobile ? '10px' : '5px')};
  align-items: ${(p: any) => (p.mobile ? 'center' : 'flex-start')};
  justify-content: ${(p: any) => (p.mobile ? 'center' : 'space-between')};
  max-width: ${(p: any) => (p.mobile ? '100%' : '348px')};
  width: 100%;
`;

const FOHDetail = styled(FOHSmallDetailLabel)`
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  max-width: 348px;
  text-align: left;
`;
