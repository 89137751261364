import React, { FunctionComponent } from 'react';

import {
  FOHExpandableText,
  FOHSectionLabel,
  SPACING_SCALE
} from '../../../ingredients';

import { FontProperty } from '../../';

export interface FOHCareerPageTabsProps {
  content?: string | React.ReactNode;
  fontProperty?: FontProperty;
  translations: Record<string, string>;
  wordCount: number;
}

export const OverviewContent: FunctionComponent<FOHCareerPageTabsProps> = ({
  content,
  fontProperty,
  translations,
  wordCount
}) => {
  if (!content) return null;

  return (
    <>
      <FOHSectionLabel
        style={{
          marginBottom: SPACING_SCALE.large,
          ...fontProperty
        }}
      >
        {translations.blockHeaderOverview}
      </FOHSectionLabel>

      <FOHExpandableText
        collapseLabel={translations.collapseDescriptionLabel}
        expandLabel={translations.expandDescriptionLabel}
        fontProperty={fontProperty}
        maxRows={5}
        wordCount={wordCount}
      >
        {content}
      </FOHExpandableText>
    </>
  );
};
