import React from 'react';

import styled from 'styled-components/native';
import { Platform } from 'react-native';
import { length } from 'ramda';

import { FOHSelectionTable, FOHSelectionTableProps } from '../../recipes';
import {
  FOHButton,
  FOHHeaderText,
  FOHErrorLabel,
  FixedTopContainerUnderNavigation,
  FixedBottomContainer,
  Screen,
  FOHDetailText,
  FOHSpace,
  FOHTextButton,
  FOHView,
  FOHScrollView
} from '../../ingredients';

export interface FOHMultiSelectScreenProps extends FOHSelectionTableProps {
  handleSubmit: () => void;
  submitLabel: string;
  title?: string;
  error?: any;
  maxCount?: number;
  hitMaxLabel?: string;
  disabledSubmit?: boolean;
  detailText?: string;
  goBack?: () => void;
  previousTitle?: string;
  largeHeader?: boolean;
  onboarding?: boolean;
  isModal?: boolean;
}

export const FOHMultiSelectScreen = (props: FOHMultiSelectScreenProps) => (
  <Screen>
    <FOHView
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop:
          (!props.largeHeader && props.onboarding) || props.isModal ? 50 : 100
      }}
    >
      {props.title && (
        <FixedTopContainerUnderNavigation
          style={{
            top: props.isModal ? 10 : 50,
            position: props.largeHeader || props.isModal ? 'absolute' : 'fixed',
            backgroundColor: '#ffffff'
          }}
        >
          <FOHView
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              paddingTop: props.largeHeader ? 0 : 10,
              paddingLeft: 10,
              paddingRight: 10,
              width: '100%',
              maxWidth: 500,
              backgroundColor: '#ffffff'
            }}
          >
            <FOHHeaderText
              style={{
                fontSize: 30
              }}
            >
              {props.title}
            </FOHHeaderText>
          </FOHView>
        </FixedTopContainerUnderNavigation>
      )}
      <FOHScrollView
        style={{
          paddingTop: !props.detailText ? 32 : 10,
          maxHeight: props.isModal ? 300 : '100%'
        }}
      >
        <FOHSpace />
        <ContainerView>
          {!!props.detailText && (
            <>
              <FOHView
                style={{
                  paddingTop: 24,
                  paddingBottom: 18,
                  alignItems: 'center',
                  justifyContent: 'center',
                  maxWidth: 400
                }}
              >
                <FOHDetailText>{props.detailText}</FOHDetailText>
              </FOHView>
            </>
          )}
          <FOHView
            style={{
              paddingTop: !props.detailText ? (props.largeHeader ? 36 : 72) : 0
            }}
          ></FOHView>
          <FOHSelectionTable
            selectAll={props.selectAll}
            allSelected={props.allSelected}
            selectAllLabel={props.selectAllLabel}
            items={props.items}
            selected={props.selected}
            selectItem={props.selectItem}
          />
          <FOHSpace />
          <FOHSpace />
          <FOHSpace />
          {!!props.previousTitle && (
            <>
              <FOHSpace />
              <FOHSpace />
            </>
          )}
        </ContainerView>
      </FOHScrollView>
      <FixedBottomContainer
        style={{
          position:
            Platform.OS === 'web' && !props.isModal ? 'fixed' : 'relative',
          borderColor: !props.isModal ? '#e8e8e8' : '#ffffff'
        }}
      >
        {!!props.maxCount &&
          !!props.hitMaxLabel &&
          props.maxCount < length(props.selected.filter(s => s !== '')) && (
            <>
              <FOHErrorLabel>{props.hitMaxLabel}</FOHErrorLabel>
              <FOHView style={{ paddingBottom: 10 }} />
            </>
          )}
        <FOHButton
          onPress={props.handleSubmit}
          title={props.submitLabel}
          disabled={props.disabledSubmit}
        />
        {!!props.previousTitle && props.goBack && (
          <>
            <FOHSpace />
            <FOHTextButton title={props.previousTitle} onPress={props.goBack} />
          </>
        )}
      </FixedBottomContainer>
    </FOHView>
  </Screen>
);

const ContainerView = styled(FOHView)`
  width: 100%;
  padding-bottom: 100px;
  padding: 16px;
  padding-top: 20px;
  padding-bottom: 50px;
`;
