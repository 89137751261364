import React, { useState, useRef, useEffect, useMemo } from 'react';

import { path, last, prop, defaultTo } from 'ramda';
import { useTranslation } from 'react-i18next';

import {
  CANDIDATE_GROUP,
  CANDIDATE_ONBOARDING_2,
  EMPLOYER_ONBOARDING_2
} from '../../constants';
import {
  useIsMobile,
  scrollToRef,
  scrollToTop,
  handleMutation
} from '../../utils';
import small_404 from '../../images/404_Сharacter_small.svg';
import large_404 from '../../images/404_Сharacter_large.svg';
import {
  useGetJobPositionQuery,
  useMutatePositionApplicationMutation
} from '../../graphql/generated';
import {
  FOHCircleLoader,
  FOHSpace,
  CardScreen,
  FOHView,
  FullWidthCard,
  FOHAltTextButton,
  FOHExpiredJobBlock
} from '../../components';
import { useNavigation } from '../../utils/navigation';

import { Head } from '../Head';
import { SignInModal, useGetMeData } from '../SignIn';
import { RegisterModal } from '../Register';
import {
  AuthFlowSelectionForSigninOrSignupModal,
  useAuthFlowSelectionContext
} from '../GoogleSSOAuth/components';

import { TopJobMatches } from './TopJobMatches';
import { JobApplicationModals } from './JobApplicationModals';
import OtherJobs from './OtherJobs';
import { JobDetails } from './JobDetails';
import JobLocationDetails from './JobLocationDetails';

const JobScreen = props => {
  const { t } = useTranslation('JobApplicationsFeature');
  const { isMobile } = useIsMobile();
  const { navigateTo, location: navLocation } = useNavigation();

  const [openRegister, setOpenRegister] = useState(false);
  const [openSignIn, setOpenSignIn] = useState(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [applicationModalSlug, setApplicationModalSlug] = useState(false);

  const { handleDisplayAuthControlButtons } = useAuthFlowSelectionContext();

  const [applyToPosition] = useMutatePositionApplicationMutation();

  const searchUrl = path(['search'], navLocation);
  const params = new URLSearchParams(searchUrl);
  const utmSource = params.get('utm_source');

  const currentPath = path(['pathname'], navLocation);
  const slug = last(currentPath.split('/'));

  const { data: me, refetch: refetchMeQuery } = useGetMeData();
  const isSignedInCandidate = !!path(['candidateProfile', 'id'], me);
  const positionQuery = useGetJobPositionQuery({
    skip: !slug,
    variables: {
      slug
    }
  });

  const position = useMemo(
    () => path(['data', 'position'], positionQuery) ?? null,
    [positionQuery]
  );
  const location = useMemo(
    () => prop('location', position) ?? null,
    [position]
  );

  const pageTitle = useMemo(() => {
    const jobTitle = prop('positionName', position) ?? 'Unknown';
    const locationName = prop('name', location) ?? 'Unknown';
    const city = prop('addressCity', location) ?? 'Unknown';

    return `${jobTitle} job at ${locationName} in ${city} - Foh&boh`;
  }, [position, location]);

  const { loading } = positionQuery;

  const scrollRef = useRef(null);

  useEffect(() => {
    if (slug) {
      setApplicationModalSlug(slug);
    }
  }, [slug]);

  const openJobApplicationModalForSlug = _slug => {
    setApplicationModalSlug(_slug);
    setIsPreviewModalOpen(true);
  };

  return (
    <>
      <Head
        title={pageTitle}
        description={prop('jobDescription', position) ?? undefined}
        image={prop('image', location) ?? undefined}
        noIndex={prop('allowIndexing', position) === false}
      />
      <CardScreen style={{ minHeight: '100vh', paddingBottom: 100 }}>
        <FOHView
          style={{
            paddingTop: isMobile ? '24px' : '60px',
            flexDirection: isMobile ? 'column' : 'row'
          }}
        >
          {!isMobile && <FOHView style={{ flex: 1 }} />}
          <FOHView style={{ flex: 4 }}>
            {prop('hiring', position) && !loading ? (
              <JobDetails
                me={me}
                isSignedInCandidate={isSignedInCandidate}
                position={position}
                loading={loading}
                scrollToLocation={() => {
                  scrollToRef(scrollRef);
                }}
                apply={() => {
                  if (position.externalApplyUrl) {
                    return window
                      ?.open(position.externalApplyUrl, '_blank')
                      ?.focus();
                  }

                  if (
                    defaultTo('', prop('email', me)).includes('indeedemail')
                  ) {
                    return props.openSetPasswordAndEmail();
                  }

                  isSignedInCandidate
                    ? setIsPreviewModalOpen(true)
                    : handleDisplayAuthControlButtons(true);
                }}
              />
            ) : !positionQuery.loading ? (
              <>
                <FOHView style={{ paddingTop: isMobile ? 40 : 0 }} />
                <FOHExpiredJobBlock
                  title={t('jobExpiredTitle')}
                  detailLabel={t('jobExpiredDetail')}
                  notFoundImage={isMobile ? small_404 : large_404}
                  key={path(['id'], position)}
                  mobile={isMobile}
                />
                <FOHSpace />
                {isSignedInCandidate && (
                  <TopJobMatches
                    {...props}
                    openJobApplicationModalForSlug={
                      openJobApplicationModalForSlug
                    }
                  />
                )}
              </>
            ) : (
              <>
                <FOHView style={{ paddingTop: 60 }} />
                <FOHCircleLoader />
                <FOHView style={{ paddingTop: 60 }} />
              </>
            )}
          </FOHView>
          {!isMobile && <FOHSpace />}
          <FOHView style={{ flex: 3 }} ref={scrollRef}>
            <JobLocationDetails
              {...props}
              me={me}
              positionQuery={positionQuery}
            />
            <OtherJobs {...props} slug={slug} />
            {isMobile && (
              <FullWidthCard
                style={{
                  height: '50px',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <FOHAltTextButton
                  style={{
                    textAlign: 'center'
                  }}
                  onPress={() => scrollToTop({ smooth: true })}
                  title={t('scrollToTop')}
                />
              </FullWidthCard>
            )}
          </FOHView>
          {!isMobile && <FOHView style={{ flex: 1 }} />}
        </FOHView>
      </CardScreen>
      <AuthFlowSelectionForSigninOrSignupModal
        currentPath={currentPath}
        setOpenRegister={setOpenRegister}
        setOpenSignIn={setOpenSignIn}
      />
      <SignInModal
        title={t('applyWithYourProfile')}
        detailLabel={t('loginToComplete')}
        openSignIn={openSignIn}
        setOpenSignIn={val => setOpenSignIn(val)}
        onSignIn={async () => {
          setOpenSignIn(false);
          refetchMeQuery();
          setIsPreviewModalOpen(true);
        }}
      />
      <RegisterModal
        open={openRegister}
        key={CANDIDATE_GROUP}
        onboarding={false}
        signUpFlow={'job-application'}
        close={() => setOpenRegister(false)}
        onRegister={async chosenRegisterType => {
          if (chosenRegisterType === CANDIDATE_GROUP) {
            refetchMeQuery();
            await handleMutation(
              applyToPosition({
                variables: {
                  position: path(['id'], position),
                  initialMessage: ''
                }
              })
            );
            navigateTo(CANDIDATE_ONBOARDING_2);
          } else {
            navigateTo(EMPLOYER_ONBOARDING_2);
          }
        }}
        group={CANDIDATE_GROUP}
      />

      <JobApplicationModals
        {...props}
        slug={applicationModalSlug || slug}
        utmSource={utmSource}
        isModalOpen={isPreviewModalOpen}
        closeModal={() => setIsPreviewModalOpen(false)}
      />
    </>
  );
};

export default JobScreen;
