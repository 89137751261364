import React from 'react';

import commute from '../../../images/profile_commute_icon.svg';

import { FOHImage } from '../../ingredients';

export const FOHProfileCommuteIcon = (props: any) => (
  <FOHImage
    source={{ uri: commute }}
    style={{ width: 16, height: 19, ...props?.style }}
  />
);
