export const needsLinksHidden = (message, isCandidate) => {
  if (isCandidate) {
    return false;
  }

  const domains = ['fhbh.link', 'foh.li', 'fohboh.li'];

  if (domains.some(domain => message.includes(domain))) {
    return true;
  }
};

export const obfuscateLinks = message => {
  const regex = /https:\/\/f.*\.li.*\/.*/g;

  if (!message) {
    return message;
  }

  return message
    .split(' ')
    .map(word => word.replace(regex, 'https://***'))
    .join(' ');
};
