import React from 'react';

import styled from 'styled-components/native';

import { FOHTextField, FOHTextFieldProps } from '../../ingredients';

export const Row = styled.View`
  flex-direction: row;
  margin-bottom: 16px;
  z-index: 1;
`;

export const InputWrapper = styled.View`
  flex: 1;
  margin-right: 16px;
  flex-direction: row;
  z-index: 1;
`;

export const Input = styled((props: any) => (
  <FOHTextField {...props} />
))<FOHTextFieldProps>`
  padding-top: 16;
  padding-bottom: 0;
`;
