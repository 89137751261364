import React from 'react';

import { StyleProp, ViewStyle } from 'react-native';
import styled from 'styled-components/native';

import { FOHSpaceSmall, FOHView, FOHColors } from '../../ingredients';

export interface FOHBulletProps {
  children: any;
  color?: string;
  style?: StyleProp<ViewStyle>;
  bulletStyle?: StyleProp<ViewStyle>;
}

export const FOHBullet = (props: FOHBulletProps) => (
  <Row
    style={{
      ...((props?.style || {}) as ViewStyle)
    }}
  >
    <Bullet
      style={{
        top: 5,
        backgroundColor: props.color || FOHColors.PACIFIC_BLUE,
        ...((props?.bulletStyle || {}) as ViewStyle)
      }}
    />
    <FOHSpaceSmall />
    {props.children}
  </Row>
);

const Row = styled(FOHView)`
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
`;

const Bullet = styled(FOHView)`
  border-radius: 50%;
  width: 8px;
  height: 8px;
`;
