import React from 'react';

import styled from 'styled-components/native';
import { Image, Text } from 'react-native';
import { prop } from 'ramda';

import {
  FOHView,
  FOHSmallDetailLabel,
  FOHColors,
  FOHSpace,
  FOHSpaceSmall,
  FOHSpaceSmallest,
  FOHFonts
} from '../../ingredients';

interface FOHCandidateLocationBlockProps {
  image: string;
  positionLabel: string;
  position: string;
  locationLabel: string;
  locationImage: string;
  locationName: string;
  locationAddress: string;

  isMobile?: boolean;
}

export const FOHCandidateLocationBlock = (
  props: FOHCandidateLocationBlockProps
) => (
  <LocationDetailBlock mobile={props.isMobile}>
    <ImageContainer mobile={props.isMobile}>
      <FOHImage
        mobile={props.isMobile}
        resizeMode="contain"
        source={{ uri: props.image }}
      />
    </ImageContainer>
    {prop('isMobile', props) ? <FOHSpace /> : <FOHSpaceSmall />}
    <PositionDetail>
      <Field>
        <SmallGreyLabel>{props.positionLabel}</SmallGreyLabel>
        <FOHSpaceSmallest />
        <Label style={{ color: FOHColors.NARO_GREY }}>{props.position}</Label>
      </Field>
      <FOHSpace />
      <Field>
        <SmallGreyLabel>{props.locationLabel}</SmallGreyLabel>
        <FOHSpaceSmallest />
        <FOHView
          style={{
            flexDirection: 'row',
            flexWrap: 'wrap',
            paddingTop: 6,
            paddingBottom: 6
          }}
        >
          <Image
            source={{ uri: props.locationImage }}
            style={{ width: 40, height: 40, borderRadius: 3 }}
          />
          <FOHView style={{ paddingLeft: 6 }} />
          <FOHView style={{ flex: 1 }}>
            <FOHName>{props.locationName}</FOHName>
            <SmallGreyLabel>{props.locationAddress}</SmallGreyLabel>
          </FOHView>
        </FOHView>
      </Field>
    </PositionDetail>
  </LocationDetailBlock>
);

const LocationDetailBlock = styled(FOHView)<{ mobile?: boolean }>`
  align-items: center;
  justify-content: center;
  flex-direction: ${(p: any) => (p.mobile ? 'column' : 'row')};
  flexwrap: wrap;
`;

const PositionDetail = styled(FOHView)<{ mobile?: boolean }>`
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
  flexwrap: wrap;
  width: 100%;
  max-width: 318px;
`;

const Field = styled(FOHView)``;

const SmallGreyLabel = styled(FOHSmallDetailLabel)`
  text-transform: uppercase;
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${FOHColors.GREY};
`;

const Label = styled(Text)`
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: ${FOHColors.GREY};
`;

const FOHName = styled(FOHSmallDetailLabel)`
  line-height: 16px;
  font-weight: 600;
  color: ${FOHColors.NARO_GREY};
`;

const FOHImage = styled(Image)<{ mobile?: boolean }>`
  width: ${(p: any) => (p.mobile ? '80px' : '132px')};
  height: ${(p: any) => (p.mobile ? '80px' : '124px')};
  borderwidth: 1;
  border-color: #eeeeee;
`;

const ImageContainer = styled(FOHView)<{ mobile?: boolean }>`
  justify-content: ${(p: any) => (p.mobile ? 'center' : 'flex-start')};
  width: ${(p: any) => (p.mobile ? '80px' : '132px')};
  height: ${(p: any) => (p.mobile ? '80px' : '124px')};
  align-self: ${(p: any) => (p.mobile ? 'center' : 'flex-start')};
  flex-direction: column;
`;
