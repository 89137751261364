import React, { useState, useEffect, useRef, useCallback } from 'react';

import { prop, path } from 'ramda';

import { useCandidateProfileMutation } from '../../features/CandidateProfile/useCandidateProfileMutation';
import { handleMutation } from '../../utils/handleAwait';
import { handle } from '../../utils';
import { imageFileResizer } from '../../utils/resizeImage';
import { useMutateProfileImageMutation } from '../../graphql/generated';

export const useUploadCandidateAvatar = () => {
  const { me, updateCandidateWithPhoto } = useCandidateProfileMutation();
  const [mutateProfileImage] = useMutateProfileImageMutation();

  const reader = new FileReader();

  const [photo, setPhoto] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fileToUpload, setFileToUpload] = useState(null);

  const [error, setError] = useState();

  const setRemoteImage = useCallback(async () => {
    // need to fetch from a different folder than the HTML uses to avoid a cors error
    const image = await fetch(photo.replace('/avatars/', '/avatars-download/'));
    const blob = await image.blob();
    setFileToUpload(blob);
  }, [photo]);

  const profileImageFromQuery = prop('profileImage', me);

  useEffect(() => {
    const userPhoto = profileImageFromQuery;
    userPhoto && setPhoto(userPhoto);
  }, [profileImageFromQuery]);

  useEffect(() => {
    if (photo && photo.includes('https')) {
      // cannot await in useEffect
      setRemoteImage();
    }
  }, [photo, setRemoteImage]);

  const inputFileRef = useRef(null);

  reader.onload = e => {
    const blob = e.target.result;
    setPhoto(blob);
  };

  const resizeFile = file => {
    return new Promise(resolve => {
      imageFileResizer(file, 600, 600, uri => {
        resolve(uri);
      });
    });
  };

  // render this input
  const UploadInput = React.memo(() => (
    <input
      type="file"
      id="file"
      onChange={async e => {
        const file = e.target.files[0];
        const image = await resizeFile(file);
        file && setFileToUpload(image);
        file && reader.readAsDataURL(image);
      }}
      ref={inputFileRef ? inputFileRef : null}
      style={{ display: 'none' }}
      accept="image/jpeg, image/png, image/jpg"
    />
  ));

  const submitPhoto = async () => {
    if (!fileToUpload && prop('profileImage', me)) {
      return true;
    }

    setLoading(true);
    const [res, imageCreateError] = await handleMutation(
      mutateProfileImage({
        variables: {
          fileType: fileToUpload?.type ?? '',
          fileName:
            // bust image cache with getSeconds()
            fileToUpload?.name || `profileImage-${new Date().getMilliseconds()}`
        }
      })
    );

    const [, imageUploadError] = await handle(
      fetch(path(['mutateProfileImage', 'imageUploadUrl'], res), {
        method: 'PUT',
        body: fileToUpload ?? undefined
      })
    );

    const [resp] = !imageUploadError
      ? await handle(
          updateCandidateWithPhoto({
            variables: {
              profileImageId: path(
                ['mutateProfilePhoto', 'profileImage', 'rowId'],
                res
              )
            }
          })
        )
      : null;
    setLoading(false);

    const err = path(['data', 'mutateCandidate', 'errors'], resp);

    if (err || imageUploadError || imageCreateError) {
      setError(err ? err.messages : 'Upload error');
      return false;
    }
    return true;
  };

  // opens photo picker
  const pickPhoto = () => inputFileRef && inputFileRef.current.click();

  return {
    me,
    submitPhoto,
    photo,
    pickPhoto,
    setPhoto,
    UploadInput,
    loading,
    error
  };
};
