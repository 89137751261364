import React from 'react';

import { useTranslation } from 'react-i18next';

import {
  FOHButton,
  FOHColors,
  FOHHeaderH3,
  FOHSpace,
  FOHSpaceLarger,
  FOHView
} from '../../../components';

const InterviewCancellationModal: React.FC<{
  candidateName: string;
  onPressGoBack: () => void;
  onPressConfirm: () => void;
  styles?: any;
}> = props => {
  const { t } = useTranslation('UpcomingInterviews');

  return (
    <FOHView
      style={{
        alignItems: 'center',
        padding: 16,
        position: 'absolute',
        backgroundColor: FOHColors.WHITE,
        borderRadius: 4,
        borderColor: FOHColors.BORDER_GRAY,
        borderWidth: 1,
        shadowOpacity: 0.06,
        shadowRadius: 15,
        shadowColor: FOHColors.BLACK,
        shadowOffset: { width: 20, height: 15 },
        top: 5,
        right: 40,
        width: 354,
        ...props?.styles
      }}
    >
      <FOHSpace />
      <FOHHeaderH3>
        {t('canceledConfirmationLabel', {
          candidate: props.candidateName
        })}
      </FOHHeaderH3>
      <FOHSpaceLarger />
      <FOHView
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          paddingHorizontal: 4
        }}
      >
        <FOHButton
          title={t('goBackBtnLabel')}
          borderColor={FOHColors.BORDER_GRAY}
          color={FOHColors.WHITE}
          textColor={FOHColors.BLACK}
          style={{ width: 128, height: 32 }}
          onPress={props.onPressGoBack}
        />
        <FOHSpace />
        <FOHButton
          title={t('confirmBtnLabel')}
          color={FOHColors.RED}
          style={{ width: 128, height: 32 }}
          onPress={props.onPressConfirm}
        />
      </FOHView>
    </FOHView>
  );
};

export default InterviewCancellationModal;
