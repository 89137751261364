import React from 'react';

import styled from 'styled-components/native';
import { StyleProp, View, ViewStyle } from 'react-native';

import { FOHContrastText } from '../text/FOHContrastText';
import { FOHColors } from '../colors/FOHColors';

export enum FOHToolTipType {
  CENTER = 'center',
  RIGHT = 'right',
  BOTTOM_CENTER = 'bottom_center',
  LEFT = 'left'
}

const Triangle = styled(View)`
  z-index: 18px;
  width: 0px;
  height: 0px;
  bottom: 5px;
  transform: rotate(180deg);
  border-left-width: 10px;
  border-right-width: 10px;
  border-bottom-width: 16px;
  border-style: solid;
  background-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #000000;
`;

const TopTriangle = styled(View)`
  z-index: 18px;
  width: 0px;
  height: 0px;
  bottom: 0px;
  border-left-width: 10px;
  border-right-width: 10px;
  border-bottom-width: 16px;
  border-style: solid;
  background-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #000000;
`;

const TriangleRight = styled(View)`
  z-index: 18px;
  width: 0px;
  height: 0px;
  bottom: 5px;
  right: -30px;
  justify-content: center;
  border-left-width: 10px;
  border-right-width: 10px;
  border-bottom-width: 16px;
  border-style: solid;
  background-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #000000;
  transform: rotate(90deg);
`;

const Container = styled(View)`
  padding-bottom: 6px;
  z-index: 19px;
  flex-direction: row;
  align-items: center;
  background-color: #000000;
  border-radius: 6px;
  max-width: 291px;
  justify-content: center;
  padding: 14px;
  opacity: 1;
`;

export interface FOHToolTipProps {
  helpText?: string;
  open?: boolean;
  style?: StyleProp<ViewStyle>;
  children?: React.ReactNode;
  type?: FOHToolTipType;
  rightOffset?: number;
  width?: number;
  bgColor?: string;
}

export const FOHToolTip = (props: FOHToolTipProps) =>
  props.open && (props.helpText || props.children) ? (
    <View
      style={{
        justifyContent: 'center',
        alignItems: props.type === FOHToolTipType.RIGHT ? 'flex-end' : 'center',
        padding: 10,
        position: 'absolute',
        right: props.rightOffset || undefined,
        zIndex: 9999,
        bottom: 30,
        width: props.width || undefined,
        maxWidth: props.width || 291,
        ...((props.style || {}) as ViewStyle)
      }}
    >
      {props.type === FOHToolTipType.BOTTOM_CENTER ? (
        <View>
          <TopTriangle
            style={{
              borderBottomColor: props.bgColor || FOHColors.BLACK
            }}
          />
        </View>
      ) : (
        <></>
      )}

      <Container
        style={{
          width: props.width || undefined,
          maxWidth: props.width || 291,
          backgroundColor: props.bgColor || FOHColors.BLACK
        }}
      >
        {props.children ? (
          props.children
        ) : (
          <FOHContrastText numberOfLines={5}>{props.helpText}</FOHContrastText>
        )}
        {props.type === FOHToolTipType.LEFT ? (
          <View>
            <TriangleRight
              style={{
                borderBottomColor: props.bgColor || FOHColors.BLACK
              }}
            />
          </View>
        ) : (
          <></>
        )}
      </Container>
      {props.type !== FOHToolTipType.BOTTOM_CENTER &&
      props.type !== FOHToolTipType.LEFT ? (
        <View
          style={{
            paddingRight: props.type === FOHToolTipType.RIGHT ? 30 : 0
          }}
        >
          <Triangle
            style={{
              borderBottomColor: props.bgColor || FOHColors.BLACK
            }}
          />
        </View>
      ) : (
        <></>
      )}
    </View>
  ) : (
    <></>
  );
