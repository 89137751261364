import React from 'react';

import { defaultTo } from 'ramda';

import complete from '../../../images/completedstep.svg';

import { FOHImage } from '../../ingredients';

export const FOHCompletedStep = (props: any) => (
  <FOHImage
    source={{ uri: complete }}
    style={{ width: 25, height: 25, ...defaultTo({}, props.style) }}
  />
);
