import React from 'react';

import notificationAlertScreening from '../../../images/notification-alert-screening.svg';

import { FOHImage } from '../../ingredients';

export interface FOHNotificationAlertScreeningProps {}

export const FOHNotificationAlertScreening = (
  props: FOHNotificationAlertScreeningProps
) => (
  <FOHImage
    {...props}
    source={{ uri: notificationAlertScreening }}
    style={{ width: 24, height: 24 }}
    testID={'FOHNotificationAlertScreening'}
  />
);
