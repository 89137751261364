import React from 'react';

import styled from 'styled-components/native';
import { StyleProp, ViewStyle } from 'react-native';

import { FOHView } from '../containers/Containers';

interface FlexSpaceProps {
  flex?: number;
  style?: StyleProp<ViewStyle>;
  children?: React.ReactNode;
}

export const FOHFlexSpace = (props: FlexSpaceProps) => (
  <FOHView
    style={{
      flex: props.flex || 1,
      ...((props.style || {}) as ViewStyle)
    }}
  >
    {props.children}
  </FOHView>
);

export const SPACING_SCALE = {
  smallest: 2,
  small: 4,
  regular: 8,
  large: 12,
  larger: 16,
  huge: 24,
  massive: 32
} as const;

export const FOHSpaceSmallest = styled(FOHView)`
  padding: ${SPACING_SCALE.smallest}px;
`;

export const FOHSpaceSmall = styled(FOHView)`
  padding: ${SPACING_SCALE.small}px;
`;

export const FOHSpace = styled(FOHView)`
  padding: ${SPACING_SCALE.regular}px;
`;

export const FOHSpaceLarge = styled(FOHView)`
  padding: ${SPACING_SCALE.large}px;
`;

export const FOHSpaceLarger = styled(FOHView)`
  padding: ${SPACING_SCALE.larger}px;
`;

export const FOHSpaceHuge = styled(FOHView)`
  padding: ${SPACING_SCALE.huge}px;
`;

export const FOHSpaceMassive = styled(FOHView)`
  padding: ${SPACING_SCALE.massive}px;
`;
