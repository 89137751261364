import React from 'react';

import styled from 'styled-components/native';

import { FOHView, FOHColors } from '../../ingredients';

export const FOHNotificationIcon = (props: any) => <Notification {...props} />;

const Notification = styled(FOHView)`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${(props: any) =>
    props.backgroundColor ? props.backgroundColor : FOHColors.GREEN};
`;
