import React, { FunctionComponent } from 'react';

import { View, StyleProp, ViewStyle } from 'react-native';
import styled from 'styled-components/native';

import {
  FOHNotificationIcon,
  FOHDivider,
  FOHVerticalDivider,
  FOHSpaceLarger
} from '../../ingredients';
import { FOHTab, FOHTabProps } from '../../recipes';
import { getShortId } from '../../utils';

export interface TabListItemProps {
  divider?: boolean;
  icon?: React.ReactElement;
  notification?: boolean;
  route: string;
  selectedIcon?: React.ReactElement;
  style?: StyleProp<ViewStyle>;
  title: string;
}
export interface FOHTabListProps {
  onPressTab: (route: TabListItemProps['route']) => void;
  selectedTab: TabListItemProps['route'];
  style?: StyleProp<ViewStyle>;
  tabContainerStyle?: StyleProp<ViewStyle>;
  tabOptions?: {
    [key in keyof Partial<Pick<FOHTabProps, 'fullWidth' | 'tall'>>]: boolean;
  };
  tabs: Array<TabListItemProps>;
  tabStyle?: StyleProp<ViewStyle>;
  testID?: string;
}

export const FOHTabList: FunctionComponent<FOHTabListProps> = ({
  onPressTab,
  selectedTab,
  style,
  tabContainerStyle,
  tabOptions,
  tabStyle,
  tabs,
  testID
}) => {
  if (!tabs.length) return null;

  return (
    <>
      <ListContainer style={style}>
        {tabs.map((tab, index) => (
          <TabContainer
            key={getShortId()}
            style={tabContainerStyle}
            testID={`FOHTabList-${tab.title}`}
          >
            {tab.divider ? (
              <View
                key={`${index}divider`}
                style={{ flex: 0, flexDirection: 'row' }}
              >
                <FOHVerticalDivider />
                <FOHSpaceLarger />
              </View>
            ) : (
              <></>
            )}

            <TabWrapper style={[tabStyle, tab.style]}>
              <FOHTab
                icon={tab.icon}
                onPress={() => onPressTab(tab.route)}
                selected={selectedTab === tab.route}
                selectedIcon={tab.selectedIcon}
                testID={testID}
                title={tab.title}
                {...tabOptions}
              />

              {tab.notification ? (
                <FOHNotificationIcon
                  style={{ position: 'absolute', top: 0, right: -5 }}
                />
              ) : (
                <></>
              )}
            </TabWrapper>
          </TabContainer>
        ))}
      </ListContainer>

      <FOHDivider />
    </>
  );
};

const ListContainer = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  padding-left: 16;
  padding-right: 16;
  padding-top: 16;
  width: 100%;
`;

const TabContainer = styled(View)`
  flex-direction: row;
`;

const TabWrapper = styled(View)`
  align-items: center;
  flex-direction: row;
  flex-grow: 1;
  justify-content: center;
`;

// const Notification = styled(FOHNotificationIcon)<{ props?: any }>`
//   position: absolute;
//   right: 0px;
//   top: -5px;
// `;
