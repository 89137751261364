import React from 'react';

import { FOHView, FOHCautionIcon, FOHErrorLabel } from '../../ingredients';

export interface WithErrorProps {
  error?: any;
  children?: React.ReactNode;
}

export const withError =
  (Cmp: React.ComponentType<any>) => (props: WithErrorProps) => {
    return (
      <FOHView style={{ width: '100%' }}>
        <Cmp {...props}>{props.children}</Cmp>
        {props.error ? (
          <FOHErrorLabel>
            <FOHView style={{ alignItems: 'flex-end', flexDirection: 'row' }}>
              <FOHCautionIcon
                style={{
                  tintColor: 'red',
                  width: 14,
                  height: 14,
                  marginRight: 4
                }}
              />
              {props.error}
            </FOHView>
          </FOHErrorLabel>
        ) : (
          <></>
        )}
      </FOHView>
    );
  };
