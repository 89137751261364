import React from 'react';

import { compose, prop, path } from 'ramda';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';

import {
  FOHButton,
  FOHLabelBold,
  FOHView,
  FOHSpace,
  FOHInverseButton,
  FOHColors,
  FOHHeaderH2,
  FOHSmallDetailLabel,
  FOHLocationOverview
} from '../../components';
import { formatFollowerCountLabel } from '../../utils';
import {
  MyPositionsDocument,
  useGetFeedLocationQuery
} from '../../graphql/generated';
import { FEED } from '../../constants';
import { useNavigation } from '../../utils/navigation';

import { withReactiveModal } from '../Navigation/withReactiveModal';
import { useEmployerOnboardingContext } from '../EmployerOnboarding/useEmployerOnboardingContext';
import { useCurrentLocationContext } from '../Locations/useCurrentLocationContext';
import { useMyLocationsData } from '../Locations';

export const JoinTeam = props => {
  const { t } = useTranslation('MyTeamFeature');
  const { navigateTo } = useNavigation();

  const onboardingState = useEmployerOnboardingContext();
  // populate cache with my positions before navigating
  const { selectLocationFilter } = useCurrentLocationContext({
    props
  });

  const { refetch: myLocationsQueryRefetch } = useMyLocationsData();

  const locationQuery = useGetFeedLocationQuery({
    variables: {
      slug: path(['location', 'slug'], onboardingState)
    }
  });

  const client = useApolloClient();

  const location = path(['data', 'location'], locationQuery);

  return (
    <FOHView
      style={{
        alignItems: 'center',
        padding: 24
      }}
    >
      <FOHHeaderH2 style={{ maxWidth: 500 }}>
        {t('hasInvitedYouToJoin', {
          name: prop('invitedBy', onboardingState) || 'hello@fohandboh.com'
        })}
      </FOHHeaderH2>
      <FOHSmallDetailLabel
        style={{
          textAlign: 'center',
          justifyContent: 'center',
          maxWidth: 500
        }}
      >
        {t('joinTeamDetails')}
      </FOHSmallDetailLabel>
      <FOHSpace />
      <FOHLabelBold>{t('youAreInvitedTo')}</FOHLabelBold>
      <FOHSpace />
      <FOHView>
        <FOHLocationOverview
          id={prop('id', location)}
          name={prop('name', location)}
          formattedAddress={prop('formattedAddress', location)}
          image={prop('image', location)}
          followerCountLabel={formatFollowerCountLabel({
            followerCount: prop('followerCount', location),
            t
          })}
          tags={prop('tags', location)}
        />
      </FOHView>
      <FOHButton
        title={t('joinTheTeam')}
        onPress={async () => {
          await myLocationsQueryRefetch();
          selectLocationFilter(prop('id', location));
          await client.query({
            query: MyPositionsDocument,
            variables: { location: prop('id', location), search: '' }
          });
          navigateTo(FEED);
        }}
        style={{
          width: '100%',
          maxWidth: 300
        }}
      />
      <FOHSpace />
      <FOHInverseButton
        title={t('joinAnotherBusiness')}
        onPress={() => {
          props.close();
        }}
        style={{
          width: '100%',
          maxWidth: 300
        }}
        color={FOHColors.BLACK}
      />
    </FOHView>
  );
};

export const JoinTeamModal = compose(withReactiveModal)(JoinTeam);
