import React from 'react';

import { Image, TouchableOpacity, View } from 'react-native';
import styled from 'styled-components/native';

import empty_resume from '../../../images/empty_resume.svg';

import {
  Card,
  FOHDetailText,
  FOHHeaderText,
  FOHInverseButton,
  Screen,
  FOHSmallDetailLabel,
  FOHLinkOut,
  FOHTrashCan,
  FOHSpaceSmall
} from '../../ingredients';

export interface FOHUploadResumeScreenProps {
  resume: string;
  Pdf: any;
  uploadResume: () => void;
  removeResume: () => void;
  onPressResume: () => void;
  uploading: boolean;
  title: string;
  submitLabel: string;
  detailText: string;
  fileTypeText: string;
  disabledSubmit?: boolean;
  error?: any;
  largeHeader?: boolean;
  fileType: FileType;
  fileName: string;
  isModal?: boolean;
}

export enum FileType {
  pdf = 'pdf',
  image = 'image'
}

export const FOHUploadResumeScreen = (props: FOHUploadResumeScreenProps) => (
  <Screen>
    <View
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: props.isModal ? 10 : 60
      }}
    >
      <FOHHeaderText style={{ fontSize: 30 }}>{props.title}</FOHHeaderText>
      <View style={{ paddingTop: 8 }} />
      <Row>
        <FOHDetailText>{props.detailText}</FOHDetailText>
      </Row>
      <Card style={{ padding: 24, alignItems: 'center' }}>
        <View style={{ paddingTop: 18 }} />
        <ResumeBox
          onPress={props.uploadResume}
          style={{ alignItems: 'center' }}
        >
          {!!props.resume && props.fileType === FileType.pdf ? (
            <props.Pdf
              source={{ uri: props.resume }}
              style={{ width: 224, height: 317 }}
            />
          ) : (
            <Image
              source={{
                uri: props.resume ? props.resume : empty_resume
              }}
              style={{ width: 224, height: 317 }}
            />
          )}
        </ResumeBox>
        {!!props.resume && (
          <FOHLinkOut href={props.resume}>{props.fileName}</FOHLinkOut>
        )}

        <View style={{ paddingTop: 12, paddingBottom: 12, width: 200 }}>
          <FOHSmallDetailLabel style={{ textAlign: 'center' }}>
            {props.fileTypeText}
          </FOHSmallDetailLabel>
        </View>
        <View style={{ flexDirection: 'row' }}>
          {!!props.resume && (
            <>
              <FOHInverseButton
                onPress={props.removeResume}
                title={''}
                icon={FOHTrashCan}
                centerIcon={true}
                style={{
                  padding: 0,
                  margin: 0,
                  zIndex: 10,
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 50
                }}
              />
              <FOHSpaceSmall />
            </>
          )}
          <FOHInverseButton
            color={'#000000'}
            onPress={props.uploadResume}
            title={props.submitLabel}
            disabled={props.disabledSubmit}
            style={{
              width: props.resume ? 200 : 250
            }}
          />
        </View>
      </Card>
      <View style={{ paddingTop: 18 }} />
    </View>
  </Screen>
);

export const ResumeBox = styled(TouchableOpacity)`
  border-radius: 6px;
  alignitems: center;
  border: 1px solid #e8e8e8;
  margin-bottom: 12px;
`;

const Row = styled(View)`
  width: 327px;
  display: flex;
  flex-direction: row;
  padding-bottom: 12px;
`;
