import { path } from 'ramda';

import { browserTimezone } from '../../utils';

export const fromFormToApi = fields => {
  return {
    ...fields
  };
};

export const registerScreenPostTransform = ({
  firstName,
  lastName,
  phone,
  registerType,
  email,
  terms,
  password
}) => ({
  first_name: firstName,
  last_name: lastName,
  phone,
  email,
  password,
  group: registerType,
  terms,
  timezone: browserTimezone
});

export const registerScreenErrorTransform = error => ({
  firstName: path(['first_name', 0], error),
  lastName: path(['last_name', 0], error),
  phone: path(['phone', 0], error),
  registerType: path(['group', 0], error),
  email: path(['email', 0], error),
  terms: path(['terms', 0], error),
  password: path(['password', 0], error)
});
