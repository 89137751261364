import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { defaultTo, path, prop } from 'ramda';

import { LOGIN_URL, PAGE_NOT_FOUND_URL } from '../../constants/urls';
import { LoggedInProvider } from '../../context/LoggedInContext';
import { RestrictedView } from '../../components';
import { useNavigation } from '../../utils/navigation';
import { GROUPS } from '../../constants';
import {
  AmplitudeTrackingEvents,
  getAuth,
  useTrackAmplitudeEvent
} from '../../utils';

import { useGetMeData } from './useGetMeData';

const ProtectedRoute = props => {
  const {
    component: Component,
    allowedGroups,
    allowedRoles,
    updateUser,
    user,
    ...rest
  } = props;

  const { data: me } = useGetMeData();
  const authState = getAuth() || null;

  const userId = defaultTo(null, prop('id', me));
  const employerProfileId = defaultTo(
    null,
    path(['employerProfile', 'id'], me)
  );

  const { location } = useNavigation();

  const { logEventMetrics } = useTrackAmplitudeEvent();

  useEffect(() => {
    if (
      location?.pathname &&
      !!allowedGroups.includes(GROUPS.employers) &&
      userId &&
      employerProfileId
    ) {
      const pageViewedEvent = {
        user_id: userId,
        account_id: employerProfileId,
        event_type: AmplitudeTrackingEvents.PAGE_VIEWED.EVENT_TYPE,
        'Page URL': window?.location?.href,
        'Page Domain': window?.location?.hostname,
        'Page Path': location?.pathname,
        'Referrer URL':
          document?.referrer || window?.document?.referrer || undefined
      };

      logEventMetrics(
        AmplitudeTrackingEvents.PAGE_VIEWED.EVENT_NAME,
        pageViewedEvent,
        {
          user_id: userId
        }
      );
    }
  }, [location?.pathname, userId, employerProfileId]);

  if (!prop('id', me)) {
    return (
      <Navigate
        to={{
          pathname: LOGIN_URL
        }}
        state={authState ? { from: location } : undefined}
      />
    );
  }

  return (
    <LoggedInProvider value={true}>
      <RestrictedView
        allowedGroups={allowedGroups}
        allowedRoles={allowedRoles}
        redirectUrl={PAGE_NOT_FOUND_URL}
      >
        <Component {...rest} {...props} />
      </RestrictedView>
    </LoggedInProvider>
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  allowedGroups: PropTypes.arrayOf(PropTypes.string),
  user: PropTypes.object
};

export default ProtectedRoute;
