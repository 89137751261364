import React from 'react';

import { defaultTo, prop } from 'ramda';

import crown from '../../../images/crown.svg';

import { FOHImage } from '../../ingredients';

export const FOHWhiteCrownIcon = (props: any) => (
  <FOHImage
    source={{ uri: crown }}
    style={{ width: 56, height: 56, ...defaultTo({}, prop('style', props)) }}
  />
);
