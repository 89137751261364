import React from 'react';

import { length, prop, defaultTo } from 'ramda';

import { FOHDotMenu, FOHProfileImage } from '../../recipes';
import {
  FOHLabel,
  FOHTextButton,
  FOHShrinkIcon,
  FOHExpandChatIcon,
  FOHSpaceSmall,
  FOHWhiteX,
  FullWidthCard,
  FOHColors,
  FOHView,
  FOHTouchableOpacity
} from '../../ingredients';

export interface FOHSmallChannelHeaderProps {
  channelName: string;
  channelDetail: string;
  onPressChannelDetail?: () => void;
  avatar?: string;
  onPressAvatar: () => void;
  toggleExpanded: () => void;
  expanded?: boolean;
  hasUnread?: boolean;
  close: () => void;
  onPressOptions?: () => void;
  openOptions?: boolean;
  options?: Array<FOHChatOption>;
  isActionsDisabled: boolean;
  actionsMenuStyle: any;
}

interface FOHChatOption {
  title: string;
  onPress: () => void;
}

export const FOHSmallChannelHeader = (props: FOHSmallChannelHeaderProps) => (
  <FOHView
    style={{
      width: '100%',
      backgroundColor: props.hasUnread
        ? FOHColors.PRIMARY_TEAL_300
        : FOHColors.GRAYSCALE_G1,
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
      height: 56
    }}
    testID={'FOHSmallChannelHeader'}
  >
    <FOHView
      style={{
        paddingLeft: 4,
        paddingTop: 4,
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%'
      }}
    >
      <FOHView style={{ flexDirection: 'row' }}>
        <FOHTouchableOpacity onPress={props.onPressAvatar}>
          <FOHProfileImage photo={props.avatar} />
        </FOHTouchableOpacity>
        <FOHView style={{ justifyContent: 'center', alignItems: 'flex-start' }}>
          <FOHLabel
            numberOfLines={1}
            style={{
              color: '#ffffff',
              lineHeight: 14,
              fontSize: 14,
              textAlign: 'left'
            }}
          >
            {props.channelName}
          </FOHLabel>
          <FOHTouchableOpacity onPress={props.onPressChannelDetail}>
            <FOHLabel
              numberOfLines={1}
              style={{
                color: '#ffffff',
                textDecorationLine: 'underline',
                fontSize: 12,
                lineHeight: 12,
                textAlign: 'left'
              }}
            >
              {props.channelDetail}
            </FOHLabel>
          </FOHTouchableOpacity>
        </FOHView>
      </FOHView>
      <FOHView
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          ...defaultTo(
            {},
            !!props.isActionsDisabled && prop('actionsMenuStyle', props)
          )
        }}
      >
        {props.options ? (
          <FOHDotMenu
            openDotMenu={props.openOptions}
            openLeft={true}
            setOpenDotMenu={() =>
              props.onPressOptions && props.onPressOptions()
            }
            tintColor={'#ffffff'}
            menuTestId={'chat-window-hamburger-button'}
          >
            <FullWidthCard style={{ paddingTop: 12, paddingBottom: 12 }}>
              {!!props.options &&
                length(props.options) > 0 &&
                props.options.map((option, index) => (
                  <FOHTextButton
                    key={`text-${index}`}
                    onPress={option.onPress}
                    title={option.title}
                    style={{ padding: 12 }}
                    testID={'chat-window-see-full-chat-button'}
                  />
                ))}
            </FullWidthCard>
          </FOHDotMenu>
        ) : (
          <></>
        )}
        <FOHSpaceSmall />
        <FOHSpaceSmall />
        <FOHTouchableOpacity onPress={props.toggleExpanded}>
          {props.expanded ? <FOHShrinkIcon /> : <FOHExpandChatIcon />}
        </FOHTouchableOpacity>
        <FOHSpaceSmall />
        <FOHTouchableOpacity
          onPress={props.close}
          testID={'chat-window-close-button'}
        >
          <FOHWhiteX style={{ width: 24, height: 24 }} />
        </FOHTouchableOpacity>
        <FOHSpaceSmall />
      </FOHView>
    </FOHView>
  </FOHView>
);
