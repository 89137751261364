import { path, prop } from 'ramda';

export const handle = promise =>
  promise
    .then(data => [data, undefined])
    .catch(error => Promise.resolve([undefined, error]));

export const handleMutation = promise =>
  promise
    // serializer mutation errors
    .then(response => {
      const data = path(['data'], response);
      const mutation = prop(0, Object.keys(data));
      const errors = path([mutation, 'errors'], data);
      if (errors) {
        const formattedError =
          errors !== null
            ? errors.reduce((acc, cur) => {
                return {
                  ...acc,
                  [prop('field', cur)]: path(['messages', 0], cur)
                };
              }, {})
            : {};
        return [data, formattedError];
      }
      return [data, undefined];
    })
    .catch(error => Promise.resolve([undefined, error]));
