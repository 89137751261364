import React from 'react';

import styled from 'styled-components/native';

import {
  FullWidthCard,
  FOHView,
  FOHLinkOut,
  FOHLabel,
  FOHNextIcon,
  FOHColors,
  FOHFonts
} from '../../ingredients';

export interface FOHBreadcrumbProps {
  routes: Array<NavigationProps>;
}

interface NavigationProps {
  route: string;
  label: string;
}

export const FOHBreadcrumb = (props: FOHBreadcrumbProps) => (
  <FOHBreadcrumbContainer>
    {props.routes.map((route, index) => {
      if (index !== props.routes.length - 1) {
        return (
          <FOHBreadcrumbWrapper key={`breadcrumb-${index}`}>
            <FOHLink
              noIcon={true}
              href={route.route}
              labelStyle={{
                lineHeight: 24,
                fontSize: 16,
                fontFamily: FOHFonts.MEDIUM,
                color: FOHColors.GRAYSCALE_G1
              }}
            >
              {route.label}
            </FOHLink>
            <FOHNextIcon style={{ tintColor: FOHColors.GRAYSCALE_G1 }} />
          </FOHBreadcrumbWrapper>
        );
      } else {
        return (
          <FOHBreadcrumbWrapper key={`breadcrumb-${index}`}>
            <FOHLabelWithNoLink
              style={{
                lineHeight: 24,
                fontSize: 16,
                color: FOHColors.GRAYSCALE_G1
              }}
            >
              {route.label}
            </FOHLabelWithNoLink>
          </FOHBreadcrumbWrapper>
        );
      }
    })}
  </FOHBreadcrumbContainer>
);

const FOHBreadcrumbContainer = styled(FullWidthCard)`
  align-items: center;
  border: none;
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  background-color: none;
  margin-bottom: 0;
`;

const FOHBreadcrumbWrapper = styled(FOHView)`
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const FOHLink = FOHLinkOut;

const FOHLabelWithNoLink = styled(FOHLabel)`
  font-weight: normal;
  font-style: normal;
  text-align: center;
  margin-left: 3px;
`;
