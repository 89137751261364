import React from 'react';

import styled from 'styled-components/native';
import { StyleProp, ViewStyle } from 'react-native';

import { FOHColors } from '../colors/FOHColors';
import { FOHView } from '../containers/Containers';

export interface FOHDividerProps {
  width?: number;
  color?: string;
  style?: StyleProp<ViewStyle>;
}

export const FOHDivider = (props: FOHDividerProps) => (
  <Divider
    style={{
      width: props.width || '100%',
      backgroundColor: props.color || FOHColors.GRAYSCALE_300,
      ...((props.style || {}) as ViewStyle)
    }}
  />
);

const Divider = styled(FOHView)`
  height: 1px;
`;
