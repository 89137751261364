import React from 'react';

import chef_hat from '../../../images/chef_hat.svg';

import { FOHImage } from '../../ingredients';

export const FOHChefHatIcon = (props: any) => (
  <FOHImage
    source={{ uri: chef_hat }}
    style={{ width: 18, height: 19, ...props?.style }}
  />
);
