import React from 'react';

import { Image } from 'react-native';
import styled from 'styled-components/native';

import {
  FOHSmallDetailLabel,
  FOHHeaderText,
  FOHLocationPin,
  FOHDivider,
  FOHFollowBadge,
  FOHView
} from '../../ingredients';
import { FOHLocationProps } from '../../typings';

import { FOHSelectionCell } from './FOHSelectionCell';

interface CellProps extends FOHLocationProps, FOHLocationSelectCellProps {}

export interface FOHLocationSelectCellProps {
  select: (id: string) => void;
  selected?: string;
  selectLabel: string;
  followerCountLabel: string;
  singleFollowerCountLabel: string;
}

export const FOHLocationSelectCell = (props: CellProps) => (
  <FOHView
    style={{
      flexDirection: 'column',
      justifyContent: 'flex-start'
    }}
  >
    <FOHView style={{ paddingTop: 24 }} />

    <FOHView style={{ flexDirection: 'row', paddingLeft: 4 }}>
      <FOHView style={{ width: 96, height: 96 }}>
        <RoundedImage source={{ uri: props.image }} />
      </FOHView>
      <FOHView
        style={{
          flexDirection: 'column',
          justifyContent: 'flex-start',
          paddingLeft: 16
        }}
      >
        {props.followerCount && props.followerCount > 0 ? (
          <FOHFollowBadge
            label={
              props.followerCount > 1
                ? `${props.followerCount} ${props.followerCountLabel}`
                : `${props.followerCount} ${props.singleFollowerCountLabel}`
            }
          />
        ) : (
          <></>
        )}
        <FOHView style={{ paddingTop: 4 }} />
        <FOHHeaderText
          style={{
            lineHeight: '120%',
            paddingBottom: 0,
            fontSize: 24,
            textAlign: 'left',
            maxWidth: 240
          }}
        >
          {props.name}
        </FOHHeaderText>
        <FOHView style={{ paddingTop: 4 }} />
        <FOHView style={{ flexDirection: 'row' }}>
          <FOHLocationPin />
          <FOHView style={{ paddingLeft: 4 }} />
          <FOHSmallDetailLabel style={{ maxWidth: 220 }}>
            {props.formattedAddress}
          </FOHSmallDetailLabel>
        </FOHView>
      </FOHView>
    </FOHView>
    <FOHView style={{ paddingTop: 4 }} />
    <FOHSelectionCell
      label={props.selectLabel}
      value={props.id}
      onPress={() => props.select(props.id)}
      selected={props.selected === props.id}
    />
    <FOHView style={{ paddingTop: 24 }} />
    <FOHDivider />
  </FOHView>
);

const RoundedImage = styled(Image)`
  width: 96px;
  height: 96px;
  border-radius: 6px;
  background-color: #f2f2f2;
`;
