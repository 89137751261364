import React, { useRef } from 'react';

import styled from 'styled-components/native';
import { useHover } from 'react-native-web-hooks';
import { Text } from 'react-native';

import {
  FOHFonts,
  FOHColors,
  FOHStatus,
  FOHStatusTypes,
  FOHCheckbox,
  FOHTouchableOpacity,
  FOHView
} from '../../ingredients';

export interface FOHLabaeledCheckboxProps {
  selected?: boolean;
  checkboxLabel?: string;
  checkboxIcon?: typeof FOHStatusTypes;
  setSelected?: (checked?: boolean) => void;
  style?: any;
  reverse?: boolean;
  disabled?: boolean;
}

export const FOHLabeledCheckbox = (props: FOHLabaeledCheckboxProps) => {
  const ref = useRef(null);
  const hoverRef = useHover(ref);

  return (
    <FOHTouchableOpacity
      style={{
        flexDirection: props.reverse ? 'row-reverse' : 'row',
        alignItems: 'center',
        backgroundColor:
          !props.disabled && hoverRef ? FOHColors.GRAYSCALE_100 : '',
        borderRadius: 4,
        width: 'max-content',
        padding: 4,
        //@ts-ignore cursor
        cursor: props.disabled ? 'initial' : 'pointer'
      }}
      ref={ref}
      onPress={() => props.setSelected && props.setSelected(!props.selected)}
      testID={`foh-labeled-checkbox-${props.checkboxLabel}`}
    >
      <FOHCheckbox
        selected={
          props.disabled
            ? props.selected
              ? true
              : false
            : props.selected || false
        }
        isDisabled={props.disabled ? true : false}
      />
      <FOHView
        style={{
          paddingLeft: props.reverse ? 0 : 6,
          paddingRight: props.reverse ? 6 : 0
        }}
      />
      <CheckboxLabel
        style={{
          ...props.style,
          color: props.disabled
            ? FOHColors.GRAYSCALE_400
            : hoverRef || props.selected
            ? FOHColors.GRAYSCALE_G1
            : FOHColors.GRAYSCALE_700
        }}
      >
        {props.checkboxIcon ? (
          <FOHStatus statusType={props.checkboxIcon[0]} statusText="" />
        ) : (
          <></>
        )}
        <>{`${props.checkboxLabel} `}</>{' '}
      </CheckboxLabel>
    </FOHTouchableOpacity>
  );
};

export const CheckboxLabel = styled(Text)`
  font-family: ${FOHFonts.MEDIUM};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${FOHColors.GRAYSCALE_700};
`;
