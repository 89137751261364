import React from 'react';

import { Picker, Platform } from 'react-native';
import styled from 'styled-components/native';

import {
  FOHClockIcon,
  FOHExpandIcon,
  FOHColors,
  withPlaceholderText,
  FOHFonts,
  FOHView
} from '../../ingredients';
import { FOHSelectItem, FOHSelectItemProps } from '../../recipes';
import { FOH_INPUT_HEIGHT } from '../../constants';

export interface FOHTimeSelectProps {
  setTime: (time?: string) => void;
  placeholder?: string;
  width?: string;
  time?: string;
  timeChoices: Array<FOHSelectItemProps>;
}

export const FOHTimeSelect = (props: FOHTimeSelectProps) => (
  <Container
    width={props.width ? props.width : '160px'}
    testID={'FOHTimeSelect'}
  >
    <FOHView>
      <FOHClockIcon
        style={{
          width: 18,
          height: 18,
          tintColor: FOHColors.BLUE
          // paddingRight: 2
        }}
      />
    </FOHView>
    <TimeInput
      placeholder={props.placeholder}
      //@ts-ignore As we extended withPlaceholderText props w/ react-native Input element so getting errors related to props in ts
      onValueChange={props.setTime}
      selectedValue={props.time}
    >
      {props.timeChoices.map((time, i) => (
        <FOHSelectItem {...time} key={`${i}-time`} />
      ))}
    </TimeInput>
    <FOHView
      pointerEvents="none"
      style={{
        right: 8,
        position: 'absolute'
      }}
    >
      <FOHExpandIcon />
    </FOHView>
  </Container>
);

const Input = styled(Picker)`
  background: #ffffff;
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  align-items: center;
  width: 90%;
  height: 46;
  flex-direction: row;

  padding: 5px 10px;
  padding-left: ${Platform.OS === 'web' &&
  window &&
  window.navigator &&
  window.navigator.userAgent &&
  window.navigator.userAgent.indexOf('Firefox') > 0
    ? '4px'
    : '10px'};
  outline-color: transparent;
  border-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  justify-content: center;
`;

const TimeInput = withPlaceholderText(Input);

const Container = styled(FOHView)<{ width: string }>`
  background: #ffffff;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 200%;
  align-items: center;
  height: ${FOH_INPUT_HEIGHT}px;
  flex-direction: row;
  padding: 8px;
  width: ${(p: any) => p.width};
`;
