import React, { useContext, useState, createContext, ReactNode } from 'react';

interface AuthFlowSelectionContextProps {
  displayAuthControlButtons: boolean;
  setDisplayAuthControlButtons: React.Dispatch<React.SetStateAction<boolean>>;
  displayGroupChoiceButtons: boolean;
  setDisplayGroupChoiceButtons: React.Dispatch<React.SetStateAction<boolean>>;
  displayAuthControlButtonForSignup: boolean;
  setDisplayAuthControlButtonForSignup: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

const AuthFlowSelectionContext = createContext<
  AuthFlowSelectionContextProps | undefined
>(undefined);

function useAuthFlowSelectionContext() {
  const context = useContext(AuthFlowSelectionContext);
  if (!context) {
    throw new Error(
      'useAuthFlowSelectionContext must be used within an AuthFlowSelectionContextProvider'
    );
  }

  const {
    displayAuthControlButtons,
    setDisplayAuthControlButtons,
    displayGroupChoiceButtons,
    setDisplayGroupChoiceButtons,
    displayAuthControlButtonForSignup,
    setDisplayAuthControlButtonForSignup
  } = context;

  const handleDisplayAuthControlButtons = (isDisplay: boolean) =>
    setDisplayAuthControlButtons(isDisplay);
  const handleDisplayGroupChoiceButtons = (isDisplay: boolean) =>
    setDisplayGroupChoiceButtons(isDisplay);
  const handleDisplayAuthControlButtonForSignup = (isDisplay: boolean) =>
    setDisplayAuthControlButtonForSignup(isDisplay);

  const closeModalViews = () => {
    setDisplayAuthControlButtons(false);
    setDisplayGroupChoiceButtons(false);
    setDisplayAuthControlButtonForSignup(false);
  };

  return {
    displayAuthControlButtons,
    handleDisplayAuthControlButtons,
    displayGroupChoiceButtons,
    handleDisplayGroupChoiceButtons,
    displayAuthControlButtonForSignup,
    handleDisplayAuthControlButtonForSignup,
    closeModalViews
  };
}

export default useAuthFlowSelectionContext;

export const AuthFlowSelectionContextProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [displayAuthControlButtons, setDisplayAuthControlButtons] =
    useState<boolean>(false);
  const [displayGroupChoiceButtons, setDisplayGroupChoiceButtons] =
    useState<boolean>(false);
  const [
    displayAuthControlButtonForSignup,
    setDisplayAuthControlButtonForSignup
  ] = useState<boolean>(false);

  return (
    <AuthFlowSelectionContext.Provider
      value={{
        displayAuthControlButtons,
        setDisplayAuthControlButtons,
        displayGroupChoiceButtons,
        setDisplayGroupChoiceButtons,
        displayAuthControlButtonForSignup,
        setDisplayAuthControlButtonForSignup
      }}
    >
      {children}
    </AuthFlowSelectionContext.Provider>
  );
};
