import React from 'react';

import values from '../../../images/profile_values_icon.svg';

import { FOHImage } from '../../ingredients';

export const FOHProfileValuesIcon = (props: any) => (
  <FOHImage
    source={{ uri: values }}
    style={{ width: 16, height: 22, ...props?.style }}
  />
);
