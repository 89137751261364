import React from 'react';

import { find, propEq } from 'ramda';

import {
  FOHHeaderText,
  Screen,
  FOHDetailText,
  FOHButton,
  FOHAltSectionLabel,
  FOHCircleLoader,
  FOHView,
  FOHScrollView
} from '../../ingredients';
import {
  FOHQuestionProps,
  FOHQuestionCell,
  FOHSetAnswer,
  FOHForm
} from '../../recipes';

export interface FOHEmployerOnboardingQuestionsProps {
  title?: string;
  tableTitle?: string;
  description?: string;
  hideTitles?: boolean;
  questions: Array<FOHQuestionProps>;
  answers: Array<FOHSetAnswer>;
  disabledSubmit?: boolean;
  loading?: boolean;
  updateAnswer: (answer: any) => void;
  next?: () => void;
  nextLabel: string;
}

export const FOHEmployerOnboardingQuestions = (
  props: FOHEmployerOnboardingQuestionsProps
) => {
  return (
    <Screen>
      <FOHForm {...props} handleSubmit={props.next}>
        <FOHView
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: props.hideTitles ? 0 : 60,
            width: '100%',
            padding: 16
          }}
        >
          <FOHView style={{ maxWidth: 400 }}>
            {!props.hideTitles && (
              <FOHHeaderText style={{ fontSize: 30 }}>
                {props.title}
              </FOHHeaderText>
            )}
            <FOHView style={{ paddingTop: 8, justifyContent: 'center' }}>
              {!props.hideTitles && (
                <>
                  <FOHDetailText>{props.description}</FOHDetailText>
                  <FOHView style={{ paddingTop: 24 }} />
                  <FOHAltSectionLabel
                    style={{
                      textAlign: 'center',
                      fontSize: 18
                    }}
                  >
                    {props.tableTitle}
                  </FOHAltSectionLabel>{' '}
                </>
              )}
              <FOHView style={{ paddingTop: 16 }} />
              <FOHScrollView style={{ height: '100%' }}>
                {props.loading && <FOHCircleLoader />}
                {props.questions.map(question => {
                  return (
                    <React.Fragment key={`question-cell-${question.id}`}>
                      <FOHQuestionCell
                        {...question}
                        answer={
                          find(
                            propEq('questionId', question.id),
                            props.answers as any
                          ) as any
                        }
                        key={question.id}
                        updateAnswer={props.updateAnswer}
                      />
                      <FOHView style={{ paddingTop: 24 }} />
                    </React.Fragment>
                  );
                })}
              </FOHScrollView>
              <FOHView style={{ paddingTop: 24 }} />
              {props.next && !!props.nextLabel && (
                <FOHButton
                  onPress={props.next}
                  title={props.nextLabel}
                  disabled={props.disabledSubmit}
                  style={{ maxWidth: '100%' }}
                />
              )}
            </FOHView>
          </FOHView>
        </FOHView>
      </FOHForm>
    </Screen>
  );
};
