import React from 'react';

import { path, defaultTo, prop } from 'ramda';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

import { localizedDays } from '../../constants/candidates';
import { useIsMobile } from '../../utils';
import { EMPLOYER_SETTINGS_MENU, POSITIONS_URL } from '../../constants';
import {
  FOHJobPositionCard,
  FOHPositionBillingStatusType,
  SalaryUnitChoices
} from '../../components';
import { useNavigation } from '../../utils/navigation';

import { useDirectMessage } from '../Chat/useDirectMessage';

export const JobDetails = props => {
  const { position, loading, location, me } = props;
  const { navigateTo } = useNavigation();

  const { t } = useTranslation('JobApplicationsFeature');
  const { isMobile } = useIsMobile();

  const loc = location || prop('location', position);

  const answers = defaultTo([], path(['answers', 'edges'], loc)).map(node => ({
    ...prop('node', node)
  }));

  const baseSalaryMin = Intl.NumberFormat('us', {
    style: 'currency',
    currency: 'USD'
  }).format(defaultTo(0, prop('baseSalaryMin', position)));
  const baseSalaryMax = Intl.NumberFormat('us', {
    style: 'currency',
    currency: 'USD'
  }).format(defaultTo(0, prop('baseSalaryMax', position)));

  const annual =
    prop('baseSalaryUnitText', position) === SalaryUnitChoices.YEAR;

  const hourlyWithTips =
    prop('baseSalaryUnitText', position) === SalaryUnitChoices.HOURLY_TIPS;

  const isActivelyHiringAndSubscribedPosition =
    prop('hiring', position) === true &&
    (prop('paymentStatus', position) ===
      FOHPositionBillingStatusType.SUBSCRIBED ||
      path(['location', 'paymentStatus'], position) ===
        FOHPositionBillingStatusType.SUBSCRIBED);

  const paymentString = prop('baseSalaryMin', position)
    ? `${baseSalaryMin} - ${baseSalaryMax} ${
        annual
          ? t('annualSalary')
          : hourlyWithTips
          ? t('PositionsFeature:hourlyTipsWage')
          : t('hourlyWage')
      }`
    : '';

  const postedAt = prop('posted', position)
    ? DateTime.fromISO(prop('posted', position)).toLocaleString()
    : '';

  const { goToDirectMessageWith } = useDirectMessage({
    ...props,
    sendbirdAccessToken: defaultTo('', path(['sendbirdAccessToken'], me))
  });

  const canEditPosition =
    defaultTo('', path(['employerProfile', 'id'], position)) ===
      defaultTo('', path(['employerProfile', 'id'], me)) &&
    path(['employerProfile', 'id'], me);

  return (
    <FOHJobPositionCard
      {...position}
      jobDescription={defaultTo('', prop('jobDescription', position))}
      positionLabel={t('positionLabel')}
      experienceLabel={t('experienceLabel')}
      answersLabel={`${t('answersLabel')} ${path(['name'], loc)}`}
      applyCTALabel={
        path(['hasApplied'], position)
          ? t('applicationSubmitted')
          : t('applyCTALabel')
      }
      applyDisabled={
        path(['hasApplied'], position) || !isActivelyHiringAndSubscribedPosition
      }
      jobDescriptionLabel={t('jobDescriptionLabel')}
      answers={answers}
      locationName={path(['name'], location)}
      onPressLocationName={props.scrollToLocation}
      postedAtLabel={`${t('postedAtLabel')} ${postedAt}`}
      salaryRangeLabel={!loading ? paymentString : ''}
      employmentTypeLabel={t('employmentTypeLabel')}
      employmentType="Full or Part time"
      preferredExperience={
        position && prop('preferredExperience', position)
          ? t(
              `common:yearsExperience.${prop(
                'preferredExperience',
                position
              )}Years`
            )
          : ''
      }
      days={localizedDays(t)}
      vertical={isMobile}
      apply={props.apply ? () => props.apply() : undefined}
      hiringManagerName={path(['createdBy', 'firstName'], position)}
      hiringManagerImage={path(['createdBy', 'profileImage'], position)}
      hiringManagerPosition={t('CandidateHomeFeature:interviewOwner')} // Position Owner
      onPressMessage={
        // only signed in candidates can chat
        props.isSignedInCandidate && path(['hasApplied'], position)
          ? async () => {
              await goToDirectMessageWith({
                uuids: [path(['createdBy', 'uuid'], position)],
                location: path(['location', 'id'], position),
                position: prop('id', position)
              });
            }
          : undefined
      }
      messageLabel={t('CandidateHomeFeature:chat')}
      editDetailsLabel={t('PositionsFeature:editDetailsLabel')}
      onEditDetailsPress={
        canEditPosition
          ? () => {
              navigateTo(
                `${EMPLOYER_SETTINGS_MENU}${POSITIONS_URL}/${prop(
                  'id',
                  position
                )}`
              );
            }
          : undefined
      }
      editDescriptionLabel={t('PositionsFeature:editDescriptionLabel')}
      onEditDescriptionPress={
        canEditPosition
          ? () => {
              navigateTo(
                `${EMPLOYER_SETTINGS_MENU}${POSITIONS_URL}/${prop(
                  'id',
                  position
                )}`
              );
            }
          : undefined
      }
    >
      {props.children}
    </FOHJobPositionCard>
  );
};
