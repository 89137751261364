import { Text } from 'react-native';
import styled from 'styled-components/native';

import { FOHColors } from '../colors/FOHColors';

import { FOHFonts } from './FOHFonts';

export const FOHDetailText = styled(Text)`
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 200%;
  display: flex;
  align-items: center;
  color: ${FOHColors.GRAYSCALE_500};
  opacity: 0.8;
  text-align: center;
`;
