import React, { useState, useEffect, useRef, useMemo } from 'react';

import { compose, prop, path, defaultTo, length, toLower } from 'ramda';
import { withTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  FOHButton,
  FOHInverseButton,
  FOHView,
  FOHSpace,
  FOHSpaceSmall,
  FOHBannerType,
  FOHReactiveModal,
  FOHHeaderH2,
  FOHStatusType,
  FOHProfileScreen
} from '../../components';
import {
  INTERVIEW_STATUS_NOTSCHEDULED,
  EMPLOYER_GROUP,
  getRequestInterviewString,
  CANDIDATE_GROUP,
  CANDIDATE_ONBOARDING_2,
  PAGE_NOT_FOUND_URL,
  CANDIDATE_EDIT_SUMMARY,
  CANDIDATE_EDIT_REFERENCES,
  FEED,
  CANDIDATE_PROFILE
} from '../../constants';
import {
  useGetCandidateQuery,
  useGetEmployerCandidateForHandleQuery
} from '../../graphql/generated';
import { createEndorsement } from '../../api/endorsements';
import {
  withIsMobile,
  handle,
  copyProfileToClipboard,
  share,
  useDisableBackgroundScroll
} from '../../utils';
import { useNavigation } from '../../utils/navigation';

import InterviewRequestModal from '../InterviewRequest/InterviewRequestModal';
import SignInModal from '../SignIn/SignInModal';
import { SetInterviewModal } from '../EmployerInterviewSchedule/SetInterviewModal';
import { SendScheduleOrScheduleModal } from '../EmployerInterviewSchedule/SendScheduleOrSchedule';
import { ShareScheduleModals } from '../EmployerInterviewSchedule/ShareScheduleFormModal';
import { RegisterModal } from '../Register';
import { CompletedApplicationModal } from '../JobApplications/CompletedApplicationModal';
import { Head } from '../Head';
import { BillingBanner } from '../Billing';
import { PlanModals } from '../Billing/PlanModals';
import { CandidateIRStateBanner } from '../InterviewRequest/CandidateIRStateBanner';
import { useBanner } from '../Navigation/useBanner';
import { useCurrentLocationContext } from '../Locations/useCurrentLocationContext';
import { useDirectMessage } from '../Chat/useDirectMessage';
import { useChatWindow } from '../Chat/useChatWindow';
import { usePaymentPlan } from '../Billing/usePaymentPlan';
import { useSidebarNav } from '../Navigation/useSidebar';
import { LocationContextSelector } from '../Navigation/LocationContextSelector';
import {
  AuthFlowSelectionForSigninOrSignupModal,
  useAuthFlowSelectionContext
} from '../GoogleSSOAuth/components';
import { useMyLocationsData } from '../Locations';

import { useCandidateProfileMutation } from './useCandidateProfileMutation';
import { UpdateOptionsDropdown } from './UpdateOptionsDropdown';
import { UpdateStatusDropdwon } from './UpdateStatusDropdown';
import { ProfileCompletionBlock } from './ProfileCompletionBlock';
import { EditProfileModals } from './EditProfileModals';
import { CandidateProfileOverview } from './CandidateProfileOverview';
import WelcomeBanner from './WelcomeBanner';

const ProfileScreen = props => {
  const { navigateTo, goBack } = useNavigation();
  const { handle: userHandle, modal } = useParams();
  const { setBanner } = useBanner();
  const { me, meQuery } = useCandidateProfileMutation();

  const { handleDisplayAuthControlButtons } = useAuthFlowSelectionContext();

  const isLoggedInAsEmployer = useMemo(
    () => !!path(['employerProfile', 'id'], me),
    [me]
  );
  const isLoggedInAsCandidate = useMemo(
    () => !!path(['candidateProfile', 'id'], me),
    [me]
  );

  const isLoggedIn = isLoggedInAsCandidate || isLoggedInAsEmployer;

  const hasInterviewSchedule = useMemo(() => prop('hasSchedule', me), [me]);

  const candidateQuery = useGetCandidateQuery({
    skip: !userHandle,
    variables: {
      handle: userHandle
    }
  });

  const employerCandidateQuery = useGetEmployerCandidateForHandleQuery({
    skip: !userHandle || !isLoggedInAsEmployer,
    variables: {
      handle: userHandle
    }
  });

  const employerCandidate = path(
    ['data', 'profile', 'employerCandidate'],
    employerCandidateQuery
  );

  const { error } = candidateQuery;
  const profile = path(['data', 'profile'], candidateQuery);

  const { t } = props;
  const isOwnProfile = userHandle === path(['candidateProfile', 'handle'], me);

  const [endorsement, setEndorsement] = useState(null);

  const [shareOpen, setShareOpen] = useState(false);
  const [videoOpen, setVideoOpen] = useState(false);
  const [videoHasPlayed, setVideoHasPlayed] = useState(false);

  const vidRef = useRef(null);

  // modals
  const [openInterviewRequest, setOpenInterviewRequest] = useState(false);
  const [openApplicationComplete, setOpenApplicationComplete] = useState(false);
  const [openSignIn, setOpenSignIn] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);
  const [openCompleteProfile, setOpenCompleteProfile] = useState(false);
  const [openWelcome, setOpenWelcome] = useState(false);
  const [openBilling, setOpenBilling] = useState(false);

  const currentPath = path(['history', 'location', 'pathname'], props);

  // open drowdown for editing profile photo and video
  const [openManageProfile, setOpenManageProfile] = useState(false);
  useDisableBackgroundScroll([
    openInterviewRequest,
    openSignIn,
    openRegister,
    modal && modal !== 'edit'
  ]);

  const toggleVideoPlayback = () => {
    vidRef && vidRef.current && !vidRef.current.paused
      ? vidRef.current.pause()
      : vidRef.current.play();
  };

  const VideoComponent = VideoCompProps => (
    <video
      controls
      ref={vidRef}
      playsInline
      autoPlay
      style={{ ...VideoCompProps.style }}
    >
      <track kind="captions" />
      <source src={path(['profileVideo'], profile)} type="video/mp4" />
    </video>
  );

  // payment and interviews
  const { locationFilter } = useCurrentLocationContext();
  const { subscribedPositions, subscribed, loadingPlan } = usePaymentPlan({
    locationFilter,
    currentPlanQ: isLoggedInAsEmployer,
    positionsQ: isLoggedInAsEmployer
  });

  const { refetch: myLocationsQueryRefetch } = useMyLocationsData();

  const { getChannelUrlFor } = useDirectMessage(props);
  const { setChannelUrlWithMessage } = useChatWindow();

  const onDirectMessage = async () => {
    const sendBirdChannel = await getChannelUrlFor({
      uuids: [path(['user', 'uuid'], profile)],
      location: locationFilter
    });
    setChannelUrlWithMessage(sendBirdChannel, '');
  };

  useEffect(() => {
    if (
      prop('id', me) &&
      length(path(['candidateProfile', 'positions'], me)) === 0
    ) {
      setOpenCompleteProfile(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endorsement]);

  useEffect(() => {
    if (meQuery.loading) {
      return;
    }

    isLoggedInAsEmployer && setOpenBilling(!subscribed);
  }, [subscribed, isLoggedInAsEmployer]);

  useEffect(() => {
    if (prop('id', profile)) {
      setVideoHasPlayed(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const redirectToNotFoundOrSignIn = open => {
    if (open) {
      !openRegister && handleDisplayAuthControlButtons(true);
    } else {
      navigateTo(
        {
          pathname: PAGE_NOT_FOUND_URL
        },
        {
          state: {
            type: 'profile'
          }
        }
      );
    }
  };

  useEffect(() => {
    if (error && !openSignIn && !meQuery.loading && !candidateQuery.loading) {
      redirectToNotFoundOrSignIn(!prop('id', me));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, meQuery.loading, candidateQuery.loading, openSignIn]);

  const interviewStatus = toLower(
    defaultTo(
      INTERVIEW_STATUS_NOTSCHEDULED,
      path(['lastInterview', 'status'], profile)
    )
  );
  const candidateStatus = path(['status'], profile);
  const closedToOffers =
    candidateStatus === FOHStatusType.CLOSED_TO_OFFERS &&
    !path(['hasAppliedToEmployer'], profile);
  const openForInterview = !closedToOffers;

  const CandidateStateBanner =
    isLoggedInAsEmployer &&
    (!!path(['lastPositionApplication'], profile) ||
      !!path(['lastInterview'], profile)) ? (
      <CandidateIRStateBanner
        {...props}
        candidateFirstName={defaultTo('', path(['user', 'firstName'], profile))}
        application={path(['lastPositionApplication'], profile)}
        lastInterview={path(['lastInterview'], profile)}
      />
    ) : undefined;

  const { contentShift, hasSideNav } = useSidebarNav(props);

  return (
    <>
      <Head
        title={`Foh&boh | ${path(['user', 'firstName'], profile)}`}
        description={path(['personalSummary'], profile)}
        image={path(['profileImage'], me)}
        noIndex={true}
      />
      <FOHView
        style={{
          paddingTop: openWelcome ? 60 : 0
        }}
      />
      {/* only if sidebar is enabled */}
      <FOHView style={{ width: '100%', alignItems: 'center', zIndex: 90 }}>
        <FOHView style={{ width: '100%', maxWidth: 1200 }}>
          {hasSideNav && (
            <LocationContextSelector {...props} viewPosition="relative" />
          )}
          <WelcomeBanner
            openCompleteProfile={openCompleteProfile}
            setOpenApplicationComplete={setOpenApplicationComplete}
            openWelcome={openWelcome}
            setOpenWelcome={setOpenWelcome}
            dialog={!props.isMobile}
            isMobile={props.isMobile}
          />
          {!subscribed && isLoggedInAsEmployer && !loadingPlan && (
            <div style={{ paddingTop: '60px' }}>
              <BillingBanner
                {...props}
                pathname={`${CANDIDATE_PROFILE}/${userHandle}/upgrade`}
                handle={userHandle}
                openBilling={!subscribed && openBilling && !loadingPlan}
                setOpenBilling={setOpenBilling}
              />
            </div>
          )}
        </FOHView>
      </FOHView>
      <FOHView style={{ zIndex: 89 }}>
        <FOHProfileScreen
          ProfileStateBannerComponent={CandidateStateBanner}
          mainProfileButtonTitle={
            isLoggedInAsCandidate
              ? isOwnProfile
                ? t('requestEndorsements')
                : null
              : t(
                  isLoggedInAsEmployer
                    ? getRequestInterviewString(interviewStatus)
                    : t('hireCTA')
                )
          }
          noInterviewScheduleForSchedulerText={t(
            'common:noInterviewScheduleForSchedulerText'
          )}
          disabledMainProfileButton={
            (isLoggedInAsCandidate && !openForInterview) ||
            (isLoggedInAsEmployer &&
              (!path(['employerProfile', 'subscribed'], me) ||
                (length(subscribedPositions) === 0 && !subscribed) ||
                !hasInterviewSchedule))
          }
          onPressMessage={
            isLoggedInAsEmployer && subscribed
              ? () => {
                  onDirectMessage();
                }
              : undefined
          }
          messageLabel={t('messageLabel')}
          statusType={candidateStatus}
          status={
            isLoggedIn
              ? t(`common:candidateStatusType.${candidateStatus}`)
              : null
          }
          // endorseCTA={t('endorseCTA')}
          // positionsLabel={t('positionsLabel')}
          // recommendationsLabel={t('recommendationsLabel')}
          shareCTA={t('shareCTA')}
          videoLabel={t('videoLabel')}
          personalSummaryLabel={t('personalSummaryLabel')}
          recommendCTA={t('recommendCTA')}
          personalSummary={prop('personalSummary', profile)}
          name={`${defaultTo('', path(['user', 'firstName'], profile))} ${
            subscribed ? defaultTo('', path(['user', 'lastName'], profile)) : ''
          }`}
          storyPhoto={path(['user', 'profileImage'], profile)}
          video={path(['profileVideo'], profile)}
          StatusComponent={
            isLoggedIn && isOwnProfile ? <UpdateStatusDropdwon /> : undefined
          }
          canManageProfileOption={
            isOwnProfile
              ? () => {
                  setOpenManageProfile(!openManageProfile);
                }
              : undefined
          }
          OptionsComponent={
            <UpdateOptionsDropdown
              open={openManageProfile}
              setSelectedOption={
                isOwnProfile
                  ? status => {
                      navigateTo(
                        `${CANDIDATE_PROFILE}/${userHandle}/${status.route}`
                      );
                      setOpenManageProfile(false);
                    }
                  : undefined
              }
            />
          }
          openEditPersonalSummary={
            isOwnProfile
              ? () => {
                  navigateTo(
                    `${CANDIDATE_PROFILE}/${userHandle}${CANDIDATE_EDIT_SUMMARY}`
                  );
                }
              : undefined
          }
          // animation has fired
          videoHasPlayed={videoHasPlayed}
          videoOpen={videoOpen}
          ProfileCompleteComponent={
            isOwnProfile ? <ProfileCompletionBlock {...props} /> : undefined
          }
          mainProfileButtonPressed={
            isLoggedInAsCandidate && isOwnProfile
              ? () =>
                  navigateTo(
                    `${CANDIDATE_PROFILE}/${userHandle}${CANDIDATE_EDIT_REFERENCES}`
                  )
              : () => {
                  setBanner(null);
                  setEndorsement(null);
                  isLoggedInAsEmployer && !isOwnProfile
                    ? openForInterview && setOpenInterviewRequest(true)
                    : handleDisplayAuthControlButtons(true);
                }
          }
          showShare={shareOpen}
          sharePressed={() => {
            setShareOpen(!shareOpen);
          }}
          linkedInPressed={() => share(`linkedin`, profile)}
          facebookPressed={() => share(`facebook`, profile)}
          twitterPressed={() => {
            share('twitter', profile);
          }}
          emailPressed={() => share('email', profile)}
          linkPressed={() => {
            copyProfileToClipboard({ setBanner, ...props }, profile);
          }}
          toggleVideoPlayback={toggleVideoPlayback}
          closeVideo={() => setVideoOpen(false)}
          openVideo={() => {
            setVideoOpen(true);
            setBanner(null);
          }}
          VideoComponent={VideoComponent}
          vertical={props.vertical || props.isMobile}
        >
          <CandidateProfileOverview
            {...props}
            handle={userHandle}
            endorsement={endorsement}
            setEndorsement={setEndorsement}
            setVideoOpen={setVideoOpen}
            setOpenRegister={handleDisplayAuthControlButtons}
            isLoggedIn={isLoggedIn}
            onDirectMessage={onDirectMessage}
          />
        </FOHProfileScreen>
      </FOHView>
      <AuthFlowSelectionForSigninOrSignupModal
        currentPath={currentPath}
        setOpenRegister={setOpenRegister}
        setOpenSignIn={setOpenSignIn}
      />
      <SignInModal
        openSignIn={openSignIn}
        setOpenSignIn={val => {
          setOpenSignIn(val);
          if (val === false && error) {
            redirectToNotFoundOrSignIn(false);
          }
        }}
        onSignIn={async () => {
          await myLocationsQueryRefetch();
          await meQuery.refetch();
          return await candidateQuery
            .refetch()
            .then(() => {
              setBanner({
                bannerType: FOHBannerType.SUCCESS,
                bannerMessage: t('loginSuccess')
              });
              setOpenSignIn(false);
            })
            .catch(() => {
              redirectToNotFoundOrSignIn(!prop('id', me));
              setOpenSignIn(false);
            });
        }}
      />
      <RegisterModal
        open={openRegister}
        key={endorsement}
        onboarding={false}
        signUpFlow={endorsement ? 'endorsement' : 'request-interview'}
        title={endorsement ? t('createAnAccountToEndorse') : null}
        detailText={
          endorsement ? null : t('createAnAccountToRequestAnInterview')
        }
        close={() => {
          setOpenRegister(false);
          if (error) {
            redirectToNotFoundOrSignIn(false);
          }
        }}
        onRegister={
          endorsement === null
            ? null
            : async () => {
                setOpenRegister(false);
                await meQuery.refetch();
                const [res, err] = await handle(createEndorsement(endorsement));
                if (res && !err) {
                  setEndorsement(null);
                }
              }
        }
        group={!endorsement ? EMPLOYER_GROUP : CANDIDATE_GROUP}
      />
      <FOHReactiveModal
        open={openCompleteProfile}
        dialog={!props.isMobile}
        close={() => setOpenCompleteProfile(false)}
        leftContentShift={contentShift}
      >
        <FOHView
          style={{
            padding: '24px',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <FOHHeaderH2>{t('completeYourOwnProfile')}</FOHHeaderH2>
          <FOHSpace />
          <FOHButton
            title={t('createProfileButton')}
            style={{
              maxWidth: '325px'
            }}
            onPress={() => navigateTo(CANDIDATE_ONBOARDING_2)}
          />
          <FOHSpaceSmall />
          <FOHInverseButton
            title={t('laterButton')}
            style={{
              maxWidth: '325px'
            }}
            onPress={() => setOpenCompleteProfile(false)}
          />
        </FOHView>
      </FOHReactiveModal>
      <CompletedApplicationModal
        {...props}
        isOnboardingApplication={true}
        open={openApplicationComplete}
        close={() => setOpenApplicationComplete(false)}
      />
      {isLoggedInAsEmployer && (
        <InterviewRequestModal
          {...props}
          handle={userHandle}
          openInterviewRequest={openInterviewRequest}
          setOpenInterviewRequest={val => {
            setOpenInterviewRequest(val);
            setBanner(null);
          }}
          handleInterviewRequestSubmitSuccess={async () => {
            candidateQuery && (await candidateQuery.refetch());
          }}
        />
      )}
      <EditProfileModals {...props} />
      {isLoggedInAsEmployer && (
        <PlanModals
          {...props}
          backPath={`${CANDIDATE_PROFILE}/${userHandle}`}
          backSearch={`?location=${locationFilter}`}
        />
      )}
      {isLoggedInAsEmployer && (
        <SendScheduleOrScheduleModal
          {...props}
          close={() => {
            goBack();
          }}
          key={`${userHandle}-send-or-set`}
          open={modal === 'send-or-set'}
          handle={userHandle}
          employerCandidate={employerCandidate}
          pathname={`${CANDIDATE_PROFILE}/${userHandle}/edit`}
        />
      )}
      {isLoggedInAsEmployer && (
        <ShareScheduleModals
          {...props}
          key={`${userHandle}-send`}
          open={modal === 'send-schedule'}
          handle={userHandle}
          employerCandidate={employerCandidate}
          setNextCandidate={() => {}}
          pathname={`${CANDIDATE_PROFILE}/${userHandle}/edit`}
        />
      )}
      {isLoggedInAsEmployer && (
        <SetInterviewModal
          candidateHandle={userHandle}
          key={`${userHandle}-set`}
          open={modal === 'interview'}
          backPath={FEED}
          employerCandidate={employerCandidate}
          close={() => {
            navigateTo(`${CANDIDATE_PROFILE}/${userHandle}`);
          }}
          pathname={`${CANDIDATE_PROFILE}/${userHandle}/edit`}
        />
      )}
    </>
  );
};

export default compose(
  withIsMobile,
  withTranslation('ProfileFeature')
)(ProfileScreen);
