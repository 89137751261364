import React from 'react';

import styled from 'styled-components/native';

import {
  FullWidthCard,
  FOHView,
  Row,
  FOHSmallDetailLabel,
  FOHTextField,
  FOHHeaderText,
  FOHHighlightText,
  FOHSpace,
  FOHColors
} from '../../ingredients';

import { FOHSelect, FOHSelectItem } from '../select/FOHSelect';

export interface FOHCommuteOption {
  label: string;
  value: string;
}

interface FOHLocationCommuteFormProps {
  zipCode: string;
  zipCodePlaceholder: string;
  setZipCode: (value: string) => void;
  commuteTimeOptions: Array<FOHCommuteOption>;
  commuteTime: string;
  commuteTimePlaceholder: string;
  setCommuteTime: (value: string) => void;
  title?: string;
  detailText: string;
  error?: any;
}

export const FOHLocationCommuteForm = (props: FOHLocationCommuteFormProps) => {
  return (
    <ContainerView>
      {!!props.title && (
        <Row>
          <FOHHighlightText
            labelCmp={FOHHeaderText}
            emphasizedWord={'*'}
            highlightColor={FOHColors.RED}
            boldLabelCmp={FOHHeaderText}
          >
            {props.title}
          </FOHHighlightText>
        </Row>
      )}
      <WrapperView>
        <FOHSmallDetailLabel>{props.detailText}</FOHSmallDetailLabel>
        <FOHSpace />
        <FOHFieldsContainer>
          <FlexRow>
            <FOHView style={{ flex: 1, minWidth: 90 }}>
              <FOHTextField
                autoCompleteType={'postal-code'}
                keyboardType={'numeric'}
                placeholder={props.zipCodePlaceholder}
                value={props.zipCode || ''}
                onChangeText={(value: any) =>
                  value.length <= 5 ? props.setZipCode(value) : undefined
                }
                error={props.error && props.error.zip ? props.error.zip : null}
              />
            </FOHView>
            <FOHView style={{ flex: 1, marginLeft: 10, minWidth: 180 }}>
              <FOHSelect
                placeholder={props.commuteTimePlaceholder}
                selectedValue={props.commuteTime}
                neverEmpty={true}
                onValueChange={(value: string) => props.setCommuteTime(value)}
                error={
                  props.error && props.error.permissionType
                    ? props.error.permissionType
                    : null
                }
              >
                {props.commuteTimeOptions.map(option => (
                  <FOHSelectItem
                    key={option.value}
                    value={option.value}
                    label={option.label}
                  />
                ))}
              </FOHSelect>
            </FOHView>
          </FlexRow>
        </FOHFieldsContainer>
      </WrapperView>
    </ContainerView>
  );
};

const ContainerView = styled(FOHView)`
  width: 100%;
  align-items: center;
`;

const WrapperView = styled(FullWidthCard)`
  background-color: #fcfcfc;
  padding: 32px;
  border-radius: 24px;
  max-width: 570px;
  width: 100%;
`;

const FOHFieldsContainer = styled(FOHView)`
  width: 100%;
  align-items: center;
`;

const FlexRow = styled(FOHView)`
  flex-direction: row;
  padding: 4px;
  width: 100%;
`;
