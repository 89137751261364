import React from 'react';

import { length } from 'ramda';

import { FOHLinkOut } from '../links/FOHLinkOut';

import { FOHLabel } from './FOHLabel';

export interface FOHHighlightLinkWithTextProps {
  labelCmp?: any;
  labelStyle?: any;
  linkWord: string;
  highlightColor: string;
  linkCmp?: any;
  linkHref: string;
  noIcon?: boolean;
  linkStyle?: any;
  children: string;
  onPress?: () => void;
  iconStyle?: any;
}

export const FOHHighlightLinkWithText = (
  props: FOHHighlightLinkWithTextProps
) => {
  const Label = props.labelCmp || FOHLabel;
  const Link = props.linkCmp || FOHLinkOut;
  const unstyledWords = props.children
    ? props.children.split(props.linkWord)
    : [];
  // react-native labels are nestable
  return (
    <Label style={{ ...props.labelStyle }}>
      {unstyledWords.map((word, index) => (
        <React.Fragment key={index + 1}>
          {word}
          {index !== length(unstyledWords) - 1 ? (
            <Link
              iconStyle={props?.iconStyle}
              noIcon={props.noIcon}
              href={props.linkHref}
              onPress={props.onPress}
              labelStyle={{
                ...props.linkStyle,
                color: props.highlightColor
              }}
            >
              {props.linkWord}
            </Link>
          ) : (
            <></>
          )}
        </React.Fragment>
      ))}
    </Label>
  );
};
