import React, { useState, useEffect } from 'react';

import { compose, path, prop, length } from 'ramda';
import { useTranslation } from 'react-i18next';

import { multiSelectItem } from '../../utils/fieldHelpers';
import {
  EMPLOYMENT_PREFERENCES_DROPDOWN,
  CANDIDATE_ACCOUNT_MENU_PATH,
  CANDIDATE_TOP_THREE_PATH
} from '../../constants';
import { useIsMobile, handle } from '../../utils';
import { useNavigation } from '../../utils/navigation';
import { FOHMultiSelectScreen } from '../../components';

import { withReactiveModal } from '../Navigation/withReactiveModal';

import { useCandidateProfileMutation } from './useCandidateProfileMutation';

const TopThreeScreen = props => {
  const { navigateTo, location: navLocation } = useNavigation();

  const { me, updateCandidate } = useCandidateProfileMutation();
  const [topChoices, setTopChoices] = useState(['']);

  useEffect(() => {
    try {
      !props.isModal &&
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
    } catch (e) {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [error, setError] = useState();
  const { t } = useTranslation('TopThreeFeature');
  const { isMobile } = useIsMobile;

  const [dirty, setDirty] = useState(false);

  const hasBack =
    navLocation.pathname === CANDIDATE_TOP_THREE_PATH &&
    !!path(['candidateProfile', 'onboardingCompletedAt'], me);

  // WAITING ON USER OBJECT
  useEffect(() => {
    setTopChoices(path(['candidateProfile', 'employmentPreferences'], me));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prop('candidateProfile', me)]);

  return (
    <FOHMultiSelectScreen
      onboarding={props.onboarding}
      items={EMPLOYMENT_PREFERENCES_DROPDOWN.map(position => ({
        value: position.value,
        label: t(position.label)
      }))}
      // max five
      selected={topChoices || ['']}
      isModal={props.isModal}
      selectItem={value => {
        setDirty(true);
        multiSelectItem(value, setTopChoices, topChoices);
      }}
      title={t('topThreeTitle')}
      submitLabel={
        path(['candidateProfile', 'onboardingCompletedAt'], me)
          ? t('common:save')
          : t('nextLabel')
      }
      detailText={t('detailText')}
      maxCount={3}
      hitMaxLabel={t('hitMaxLabel')}
      largeHeader={!isMobile}
      disabledSubmit={
        length((topChoices || ['']).filter(choice => choice !== '')) > 3 ||
        (!!path(['candidateProfile', 'onboardingCompletedAt'], me) && !dirty)
      }
      error={error}
      previousTitle={
        props.onboarding ? t('CandidateOnboardingSteps:previous') : ''
      }
      goBack={
        hasBack
          ? () => navigateTo(CANDIDATE_ACCOUNT_MENU_PATH)
          : props.onboarding
          ? () => props.previousStep()
          : null
      }
      handleSubmit={async () => {
        const employmentPreferences = (topChoices || ['']).filter(
          choice => choice !== ''
        );
        const [resp] = await handle(
          updateCandidate({
            variables: {
              employmentPreferences
            }
          })
        );

        const err = path(['data', 'mutateCandidate', 'errors'], resp);

        if (resp && !err) {
          setDirty(false);
          props.onSubmit && props.onSubmit(resp);
          if (props.close) return props.close();

          return navigateTo(
            path(['candidateProfile', 'onboardingCompletedAt'], me)
              ? CANDIDATE_ACCOUNT_MENU_PATH
              : props.onSubmit && props.onSubmit()
          );
        } else if (err) {
          setError(err);
        }
      }}
    />
  );
};

export const TopThreeModal = compose(withReactiveModal)(TopThreeScreen);

export default TopThreeScreen;
