import React, { Fragment } from 'react';

import { prop, defaultTo, length } from 'ramda';
import { ScrollView, TouchableOpacity, View } from 'react-native';
import styled from 'styled-components/native';

import {
  FOHFonts,
  FOHView,
  FOHSpace,
  FOHLabel,
  FOHColors,
  FOHEditMarkerBlack,
  FOHXIcon,
  FOHDivider,
  FOHVerticalDivider,
  ButtonText
} from '../../ingredients';

import { FOHSearchBar, FOHSearchBarProps, FOHMessageTemplateName } from '..';

const MessageActionLabel = styled(FOHLabel)`
  margin-left: 10px;
  padding-bottom: 8px;
  font-size: 16px;
  line-height: 24px;
  color: ${FOHColors.GRAYSCALE_700};
`;

const TemplateTitle = styled(FOHLabel)`
  color: ${FOHColors.GRAYSCALE_700};
  text-transform: uppercase;
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
  margin-left: 16px;
`;

const MessageActionTitle = styled(FOHLabel)`
  color: ${FOHColors.GRAYSCALE_700};
  text-transform: uppercase;
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  padding-top: 8px;
  margin-left: 10px;
`;

const MessageIconView = styled(View)`
  background-color: 'transparent';
  margin-right: 4px;
  justify-content: center;
  align-items: center;
`;

const CloseTemplate = styled(View)`
  z-index: 99999;
  top: 9px;
  right: 15px;
  position: absolute;
`;

export interface MessageTemplateType {
  name: string;
  messageContent: string;
  id: string;
}

interface FOHMessageTemplateProps extends FOHSearchBarProps {
  templateTitle?: string;
  templateCategory?: string;
  messageTemplates: Array<MessageTemplateType>;
  setTemplate: (template: any) => any;
  selectedTemplate: any;
  scrollHeight?: number;
  onScroll?: () => any;
  children?: any;
  height?: number;
  editTemplateLabel: string;
  editTemplateLabelColor?: string;
  editTemplate: () => any;
  onUseTemplate: () => any;
  useLabel: string;
  close?: () => any;
}

const MessageTemplateContent = ({ text }: { text: string }) => {
  const regex = /\{\{(.+?)\}\}/g;

  const matchingParts = defaultTo([], text?.match(regex));

  // @ts-ignore reduce
  const splitableString = matchingParts.reduce((acc: string, curr: string) => {
    const nospaceVar = curr.replace(/\s/g, '-');
    return acc.replace(curr, nospaceVar);
  }, text);

  // make behavior of split same across chrome and safari
  let parts = splitableString.split(/\s/g);
  parts = parts
    .filter((part: string) => part !== ' ')
    .map((part: string, i: number) => {
      if (part === '' && parts[i - 1] === '') {
        return '\n\n';
      } else {
        return part;
      }
    });

  return (
    <FOHLabel>
      {parts.map((part: string, i: number) => {
        return length(
          matchingParts.filter(
            matchPart => part && part.replace(/-/g, ' ').includes(matchPart)
          )
        ) > 0 ? (
          <FOHLabel key={`${part}${i}`}>
            <FOHLabel
              style={{
                backgroundColor: FOHColors.GRAYSCALE_100,
                color: FOHColors.GRAYSCALE_700,
                fontWeight: '600',
                fontSize: 12,
                lineHeight: 16,
                paddingHorizontal: 8,
                paddingVertical: 4
              }}
            >
              {part.replace(/-/g, ' ')}
            </FOHLabel>{' '}
          </FOHLabel>
        ) : (
          <FOHLabel
            key={`${part}${i}`}
            style={{ color: FOHColors.GRAYSCALE_700 }}
          >
            {part.replace(' ', '')}{' '}
          </FOHLabel>
        );
      })}
    </FOHLabel>
  );
};

export const FOHMessageTemplate = (props: FOHMessageTemplateProps) => {
  return (
    <FOHView
      style={{
        flexDirection: 'column',
        justifyContent: 'flex-start',
        display: 'flex'
      }}
    >
      <CloseTemplate>
        <TouchableOpacity onPress={props.close} testID="close-modal">
          <FOHXIcon style={{ tintColor: FOHColors.GRAYSCALE_700 }} />
        </TouchableOpacity>
      </CloseTemplate>
      <FOHView
        style={{
          flexDirection: 'row',
          justifyContent: 'flex-start',
          display: 'flex',
          // paddingBottom: 15,
          height: props.height || 300
        }}
      >
        <FOHView
          style={{
            alignItems: 'flex-start',
            flex: 3,
            marginRight: 12
          }}
        >
          {props?.templateTitle ? (
            <TemplateTitle>{props.templateTitle}</TemplateTitle>
          ) : (
            <></>
          )}
          <FOHView
            style={{
              alignItems: 'flex-start',
              paddingHorizontal: 16,
              width: '100%',
              justifyContent: 'center'
            }}
          >
            <FOHSearchBar
              {...props}
              style={{ height: 40 }}
              inputStyle={{ height: 34 }}
            />
            <FOHSpace />
          </FOHView>

          <ScrollView
            scrollEventThrottle={200}
            onScroll={prop('onScroll', props)}
            style={{
              width: '100%',
              marginBottom: 10,
              maxHeight: props.scrollHeight ?? 700
            }}
          >
            {props.messageTemplates
              ? props.messageTemplates.map((template, keyIndex) => {
                  const isSelectTemplateMatched =
                    (template &&
                      props?.selectedTemplate &&
                      props?.selectedTemplate?.id === template?.id) ||
                    false;
                  return (
                    <FOHView
                      style={{
                        marginHorizontal: 12,
                        backgroundColor: isSelectTemplateMatched
                          ? FOHColors.GRAYSCALE_10
                          : FOHColors.WHITE
                      }}
                      key={keyIndex}
                    >
                      {props?.templateCategory ? (
                        <MessageActionTitle
                          style={{
                            fontWeight: isSelectTemplateMatched ? '600' : '400',
                            color: isSelectTemplateMatched
                              ? FOHColors.GRAYSCALE_900
                              : FOHColors.GRAYSCALE_700
                          }}
                        >
                          {props?.templateCategory}
                        </MessageActionTitle>
                      ) : (
                        <></>
                      )}
                      <MessageActionLabel
                        onPress={() => {
                          props.setTemplate(template);
                        }}
                        key={template?.id}
                        style={{
                          color: isSelectTemplateMatched
                            ? FOHColors.GRAYSCALE_900
                            : FOHColors.GRAYSCALE_700,
                          fontWeight: isSelectTemplateMatched ? '600' : '400'
                        }}
                      >
                        {template?.name}
                      </MessageActionLabel>
                    </FOHView>
                  );
                })
              : null}
          </ScrollView>
        </FOHView>
        <FOHView style={{ alignItems: 'center' }}>
          <FOHVerticalDivider />
        </FOHView>
        <FOHView
          style={{
            alignItems: 'flex-start',
            flex: 4,
            marginLeft: 24
          }}
        >
          {props?.selectedTemplate ? (
            <>
              <FOHView
                style={{
                  alignItems: 'flex-start',
                  paddingHorizontal: 16,
                  width: '100%',
                  marginBottom: 16,
                  marginTop: 12
                }}
              >
                <FOHMessageTemplateName name={props?.selectedTemplate?.name} />
              </FOHView>

              <ScrollView
                scrollEventThrottle={200}
                onScroll={prop('onScroll', props)}
                style={{
                  width: '100%',
                  maxHeight: props.scrollHeight ?? 700
                }}
              >
                <FOHLabel style={{ marginLeft: 15 }}>
                  {props?.selectedTemplate?.messageContent ? (
                    <MessageTemplateContent
                      text={props?.selectedTemplate?.messageContent}
                    />
                  ) : (
                    <></>
                  )}
                  {props?.selectedTemplate?.screeningQuestion &&
                  length(props?.selectedTemplate?.screeningQuestion) > 0 ? (
                    <FOHView style={{ width: '95%' }}>
                      <FOHSpace />
                      {props?.selectedTemplate?.screeningQuestion?.map(
                        (questionObj: any, keyIndex: number) => (
                          <Fragment key={keyIndex}>
                            <FOHView
                              style={{
                                backgroundColor: FOHColors.GRAYSCALE_100,
                                paddingHorizontal: 12,
                                paddingVertical: 8,
                                borderRadius: 4,
                                width: '100%'
                              }}
                            >
                              <FOHLabel>
                                {length(
                                  props?.selectedTemplate?.screeningQuestion
                                ) === 1
                                  ? `Q. `
                                  : `${keyIndex + 1}. `}
                                {questionObj?.question}
                              </FOHLabel>
                            </FOHView>
                            <FOHSpace />
                          </Fragment>
                        )
                      )}
                    </FOHView>
                  ) : (
                    <></>
                  )}
                </FOHLabel>
              </ScrollView>
            </>
          ) : null}
        </FOHView>
      </FOHView>
      <FOHDivider />
      <FOHView
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          display: 'flex',
          marginTop: 19,
          marginBottom: 7
        }}
      >
        <TouchableOpacity
          onPress={props.editTemplate}
          disabled={false}
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row'
          }}
          testID="FOHMessageTemplate-edit-templates"
        >
          <MessageIconView>
            <FOHEditMarkerBlack
              style={{
                tintColor:
                  props.editTemplateLabelColor || FOHColors.GRAYSCALE_700
              }}
            />
          </MessageIconView>
          <ButtonText
            style={{
              color: props.editTemplateLabelColor || FOHColors.GRAYSCALE_700,
              fontSize: 16,
              textDecorationLine: 'underline'
            }}
          >
            {props.editTemplateLabel}
          </ButtonText>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={props.onUseTemplate}
          disabled={false}
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row'
          }}
          testID="FOHMessageTemplate-apply"
        >
          <ButtonText
            style={{
              backgroundColor: FOHColors.GRAYSCALE_700,
              color: FOHColors.WHITE,
              borderRadius: 4,
              minWidth: 87,
              maxHeight: 32,
              fontSize: 16,
              padding: 7,
              lineHeight: 'none'
            }}
          >
            {props.useLabel}
          </ButtonText>
        </TouchableOpacity>
      </FOHView>
    </FOHView>
  );
};
