import React, { useState, useRef, useEffect } from 'react';

import { path, prop } from 'ramda';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@react-google-maps/api';

import {
  FOHBannerType,
  FOHSettingsBanner,
  FOHSpace,
  FOHScrollView,
  FOHView,
  FOHEditLocationForm,
  FOHButton,
  FOHGrayEyeIcon,
  FOHButtonBordered,
  FOHTextField
} from '../../components';
import {
  useGetLocationToClaimQuery,
  useMutateUpdateLocationImageMutation,
  useMutateUpdateLocationMutation
} from '../../graphql/generated';
import {
  handleMutation,
  scrollToTop,
  unwrapEdgesAt,
  useIsMobile
} from '../../utils';

import { useBanner } from '../Navigation/useBanner';
import { EmployerOnboardingQuestions } from '../EmployerOnboarding/EmployerOnboardingQuestions';
import { LocationContextSelector } from '../Navigation/LocationContextSelector';

import { useCurrentLocationContext } from './useCurrentLocationContext';
import { getAddressInfo, autoCompleteAPIOptions } from './constants';

const DEFUALT_TAB = 'overview';

const LocationOverview = props => {
  const reader = new FileReader();
  const { isMobile } = useIsMobile();

  const { t } = useTranslation('ClaimLocationFeature');
  const { location, locationQuery, triggerSave, setTriggerSave } = props;
  const [mutateLocation] = useMutateUpdateLocationMutation();
  const [mutateLocationImage] = useMutateUpdateLocationImageMutation();

  const [companyName, setCompanyName] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [summary, setSummary] = useState('');
  const [image, setImage] = useState('');
  const [fileToUpload, setFileToUpload] = useState(null);
  const [indeedEasyapplyOptout, setIndeedEasyapplyOptout] = useState(false);

  const [searchResult, setSearchResult] = useState('');

  const handleLoadScript = autocomplete => setSearchResult(autocomplete);

  const handlePlaceSelect = () => {
    if (searchResult === null) return;

    const selectedPlace = searchResult.getPlace();
    const addressInfo = getAddressInfo(selectedPlace);

    setAddress1(addressInfo.street1);
    setCity(addressInfo.city);
    setState(addressInfo.state);
    setPostalCode(addressInfo.postalCode);
  };

  const inputFile = useRef(null);

  useEffect(() => {
    setCompanyName(prop('name', location));
    setAddress1(prop('addressStreet1', location));
    setAddress2(prop('addressStreet2', location));
    setCity(prop('addressCity', location));
    setState(prop('addressState', location));
    setPostalCode(prop('addressPostalCode', location));
    setSummary(prop('summary', location));
    setImage(prop('image', location));
    setIndeedEasyapplyOptout(prop('indeedEasyapplyOptoutDefault', location));
  }, [location]);

  reader.onload = e => {
    const blob = e.target.result;
    setImage(blob);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const submit = async () => {
    const variables = {
      id: path(['id'], location),
      name: companyName,
      summary,
      addressStreet1: address1,
      addressStreet2: address2,
      addressCity: city,
      addressState: state,
      addressPostalCode: postalCode,
      indeedEasyapplyOptoutDefault: indeedEasyapplyOptout
    };

    if (fileToUpload) {
      // claim the image and get the S3 Url
      const imageVariables = {
        fileName: fileToUpload.name,
        fileType: fileToUpload.type
      };

      const [res] = await handleMutation(
        mutateLocationImage({
          variables: {
            locationId: path(['id'], location),
            ...imageVariables
          }
        })
      );

      // Upload image
      await fetch(path(['mutateLocationImage', 'imageUploadUrl'], res), {
        method: 'PUT',
        body: fileToUpload
      });
    }

    await handleMutation(
      mutateLocation({
        variables: {
          ...variables
        }
      })
    );

    props.setSettingsBanner &&
      props.setSettingsBanner({
        bannerType: FOHBannerType.SUCCESS,
        bannerMessage: t('common:success')
      });
    props.scrollUp && props.scrollUp();
  };

  useEffect(() => {
    if (triggerSave) {
      submit();
      setTriggerSave(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerSave]);

  return (
    <FOHView>
      <FOHSpace />
      <input
        type="file"
        id="file"
        accept="image/jpeg, image/png, image/jpg"
        onChange={e => {
          const file = e.target.files[0];
          file && setFileToUpload(file);
          file && reader.readAsDataURL(file);
        }}
        ref={inputFile}
        style={{ display: 'none' }}
      />
      <FOHEditLocationForm
        uploadImageLabel={t('uploadImageLabel')}
        submitLabel={t('submitLabel')}
        companyNamePlaceholder={t('companyNamePlaceholder')}
        street1Placeholder={t('street1Placeholder')}
        street2Placeholder={t('street2Placeholder')}
        cityPlaceholder={t('cityPlaceholder')}
        statePlaceholder={t('statePlaceholder')}
        postalCodePlaceholder={t('postalCodePlaceholder')}
        summaryPlaceholder={t('summaryPlaceholder')}
        largeHeader={true}
        loading={locationQuery.loading}
        image={image}
        pickPhoto={() => {
          inputFile && inputFile.current.click();
        }}
        vertical={isMobile}
        companyName={companyName}
        setCompanyName={setCompanyName}
        street1={address1}
        setStreet1={setAddress1}
        street2={address2}
        setStreet2={setAddress2}
        city={city}
        disabledSubmit={
          !city ||
          !address1 ||
          !companyName ||
          !summary ||
          !state ||
          !postalCode
        }
        setCity={setCity}
        state={state}
        setState={setState}
        postalCode={postalCode}
        setPostalCode={setPostalCode}
        summary={summary}
        setSummary={setSummary}
        indeedEasyapplyOptoutLabel={t('indeedEasyapplyOptoutLabel')}
        indeedEasyapplyOptout={indeedEasyapplyOptout}
        setIndeedEasyapplyOptout={setIndeedEasyapplyOptout}
        autoCompleteInput={
          <Autocomplete
            onPlaceChanged={handlePlaceSelect}
            onLoad={handleLoadScript}
            options={autoCompleteAPIOptions}
          >
            <FOHTextField
              placeholder={t('street1Placeholder')}
              value={address1 || ''}
              required={true}
              autoCompleteType={'street-address'}
              onChangeText={value => setAddress1(value)}
            />
          </Autocomplete>
        }
      />
    </FOHView>
  );
};

const EditLocation = props => {
  const { currentLocation } = useCurrentLocationContext({
    props,
    skipLocationsQuery: true
  });

  const scrollRef = useRef();

  const { t } = useTranslation('EditLocationBannerFeature');
  const { isMobile } = useIsMobile();
  // get entire location
  const locationQuery = useGetLocationToClaimQuery({
    skip: !prop('slug', currentLocation),
    variables: {
      slug: prop('slug', currentLocation)
    }
  });
  const [selectedTab, setSelectedTab] = useState(DEFUALT_TAB);
  const [triggerSave, setTriggerSave] = useState(false);

  const [answers, setAnswers] = useState();

  const location = path(['data', 'location'], locationQuery);

  const { banner, setBanner } = useBanner();

  const scrollUp = () => {
    // scroll window
    scrollToTop({});
    // scroll nested view
    scrollRef.current &&
      scrollRef.current.scrollTo({
        y: 0,
        animated: true
      });
  };

  useEffect(() => {
    if (locationQuery.data) {
      const nodes = unwrapEdgesAt(
        ['data', 'location', 'answers', 'edges'],
        locationQuery
      );
      const ans = nodes.map(_answer => ({
        questionId: path(['question', 'id'], _answer),
        freeText: prop('response', _answer),
        optionId: path(['option', 'id'], _answer),
        value:
          path(['option', 'value'], _answer) !== prop('response', _answer)
            ? 'other'
            : prop('response', _answer)
      }));
      setAnswers(ans);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      <LocationContextSelector {...props} />

      <FOHView
        style={{
          backgroundColor: '#F5F5F5',
          height: '100%',
          minHeight: '100vh',
          paddingTop: 50
        }}
      >
        <FOHSettingsBanner
          title={prop('name', location)}
          tabs={[
            { label: t('overview'), value: DEFUALT_TAB },
            { label: t('culture'), value: 'culture' }
          ]}
          setSelectedTab={route => {
            setSelectedTab(route);
          }}
          selectedTab={selectedTab}
          {...banner}
        >
          <FOHView
            style={{
              width: '100%',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <FOHButtonBordered
              icon={FOHGrayEyeIcon}
              title={t('preview')}
              onPress={() => {
                window &&
                  window.open(`/restaurant-jobs/${prop('slug', location)}`);
              }}
              style={{
                maxWidth: isMobile ? 155 : 200
              }}
            />
            <FOHSpace />
            <FOHButton
              title={t('save')}
              onPress={() => {
                setTriggerSave(true);
              }}
              style={{
                maxWidth: isMobile ? 155 : 200
              }}
            />
          </FOHView>
        </FOHSettingsBanner>
        <FOHScrollView
          ref={scrollRef}
          style={{
            height: isMobile ? '60vh' : '80vh',
            width: isMobile ? undefined : '100%'
          }}
        >
          <FOHView
            style={{
              alignItems: isMobile ? 'center' : 'flex-start',
              paddingBottom: 200,
              paddingLeft: 30
            }}
          >
            {/* React router was giving me trouble and not rerendering on history.push conditional render for now*/}
            {selectedTab === DEFUALT_TAB ? (
              <LocationOverview
                {...props}
                location={location}
                t={t}
                scrollUp={scrollUp}
                locationQuery={locationQuery}
                triggerSave={triggerSave}
                setTriggerSave={setTriggerSave}
                setSettingsBanner={setBanner}
              />
            ) : (
              <EmployerOnboardingQuestions
                location={location}
                scrollUp={scrollUp}
                answers={answers}
                setAnswers={setAnswers}
                hideTitles={true}
                nextStep={() => {}}
                triggerSave={triggerSave}
                setTriggerSave={setTriggerSave}
                setSettingsBanner={setBanner}
              />
            )}
          </FOHView>
        </FOHScrollView>
        <FOHSpace />
      </FOHView>
    </>
  );
};

export default EditLocation;
