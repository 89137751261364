import React, { useState, useEffect } from 'react';

import { compose, defaultTo, path, last, prop } from 'ramda';
import { withTranslation } from 'react-i18next';
import { Marker, InfoBox } from '@react-google-maps/api';

import {
  CANDIDATE_GROUP,
  EMPLOYER_GROUP,
  CANDIDATE_ONBOARDING_2,
  EMPLOYER_ONBOARDING_4,
  JOBS,
  EMPLOYER_ONBOARDING_2
} from '../../constants';
import {
  withIsMobile,
  unwrapEdges,
  formatFollowerCountLabel,
  scrollToTop
} from '../../utils';
import {
  useGetFeedLocationQuery,
  useGetOtherJobPositionsByLocationQuery
} from '../../graphql/generated';
import {
  FOHBannerType,
  FOHLocationDetailScreen,
  FOHMap,
  FOHMapMarker,
  FOHPositionBillingStatusType
} from '../../components';
import { useNavigation } from '../../utils/navigation';

import { RegisterModal } from '../Register';
import { SignInModal, useGetMeData } from '../SignIn';
import { useEmployerOnboardingContext } from '../EmployerOnboarding';
import { SmallWebMap, SmallMobileWebMap } from '../Map';
import { formatOtherJobs } from '../JobApplications/OtherJobs';
import { Head } from '../Head';
import { useBanner } from '../Navigation/useBanner';
import {
  AuthFlowSelectionForSigninOrSignupModal,
  useAuthFlowSelectionContext
} from '../GoogleSSOAuth/components';

import { useLocationMutation } from './useLocationMutation';

const LocationDetailScreen = props => {
  const { goBack, location: navLocation } = useNavigation();
  const { setBanner } = useBanner();
  const { navigateTo } = useNavigation();

  const currentPath = path(['pathname'], navLocation);
  const { handleDisplayAuthControlButtons } = useAuthFlowSelectionContext();

  const [openRegister, setOpenRegister] = useState(false);
  const [registerType, setRegisterType] = useState(CANDIDATE_GROUP);

  const [openSignIn, setOpenSignIn] = useState(false);
  const { data: me, refetch: refetchMeQuery } = useGetMeData();
  const isCandidate = !!path(['candidateProfile', 'id'], me);

  const { optimisticToggleFavorite, optimisticToggleHidden } =
    useLocationMutation();

  const { t } = props;
  const slug = last(path(['pathname'], navLocation).split('/'));
  const locationQuery = useGetFeedLocationQuery({
    variables: {
      slug
    }
  });

  const location = path(['data', 'location'], locationQuery);
  const id = prop('id', location);

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    } catch (e) {
      return;
    }
  }, []);

  const { setEmployerOnboardingState } = useEmployerOnboardingContext();

  const otherJobsQuery = useGetOtherJobPositionsByLocationQuery({
    variables: {
      slug
    }
  });

  const jobs = formatOtherJobs(
    defaultTo(
      [],
      path(['data', 'location', 'positions', 'edges'], otherJobsQuery)
    )
  )
    .map(job => ({
      ...job,
      formattedAddress: path(
        ['data', 'location', 'formattedAddress'],
        otherJobsQuery
      )
    }))
    .filter(
      position =>
        prop('hiring', position) === true &&
        (prop('paymentStatus', position) ===
          FOHPositionBillingStatusType.SUBSCRIBED ||
          path(['location', 'paymentStatus'], position) ===
            FOHPositionBillingStatusType.SUBSCRIBED)
    );

  return (
    <>
      <Head
        title={`Restaurant Jobs at ${prop('name', location)} in
        ${prop('addressCity', location)} - Foh&boh`}
        image={prop('image', location)}
        description={`Search restaurant jobs at ${prop(
          'name',
          location
        )} in ${prop(
          'addressCity',
          location
        )}. Foh&boh is the best place to find local restaurant jobs. Create a profile and get matched with top restaurants.`}
      />
      <FOHLocationDetailScreen
        {...location}
        navigateBack={() => goBack()}
        backText={t('backText')}
        hourlyRatesTitle={t('hourlyRatesTitle')}
        requirementsLabel={t('requirementsLabel')}
        summaryTitle={t('summaryTitle')}
        claimBusinessLabel={t('claimBusinessLabel')}
        benefitsLabel={t('benefitsLabel')}
        favoriteLabel={t('favoriteLabel')}
        locationLabel={t('locationLabel')}
        hideLabel={t('hideLabel')}
        answersLabel={`${t('workingAt')} ${prop('name', location)}`}
        answers={unwrapEdges(path(['answers', 'edges'], location))}
        positionRates={[]}
        id={id}
        followerCountLabel={formatFollowerCountLabel({
          followerCount: prop('followerCount', location),
          t
        })}
        vertical={props.isMobile}
        isFavorited={prop('isFavorited', location)}
        isHidden={prop('isHidden', location)}
        pressFavorite={() => {
          setRegisterType && setRegisterType(CANDIDATE_GROUP);
          isCandidate
            ? optimisticToggleFavorite(location)
            : handleDisplayAuthControlButtons(true);
        }}
        pressHide={() => {
          setRegisterType && setRegisterType(CANDIDATE_GROUP);
          isCandidate
            ? optimisticToggleHidden(location)
            : handleDisplayAuthControlButtons(true);
        }}
        claimBusiness={
          isCandidate || path(['employerProfile', 'id'], location)
            ? null
            : () => {
                setEmployerOnboardingState({
                  location
                });
                setRegisterType && setRegisterType(EMPLOYER_GROUP);
                path(['employerProfile', 'id'], me)
                  ? navigateTo(EMPLOYER_ONBOARDING_4)
                  : handleDisplayAuthControlButtons(true);
              }
        }
        jobs={jobs}
        jobSectionTitle={`JOBS AT ${path(
          ['data', 'location', 'name'],
          otherJobsQuery
        )}`}
        onJobPress={(_slug, _externalApplyUrl) => {
          scrollToTop({ smooth: false });
          if (_externalApplyUrl && window && window.open) {
            return window.open(_externalApplyUrl, '_blank').focus();
          }
          navigateTo(`${JOBS}/${_slug}`);
        }}
        a={anchorProps => <a {...anchorProps}>{anchorProps.children}</a>}
        jobCTAText={t('JobApplicationsFeature:ctaTextOtherJob')}
      >
        <FOHMap
          latitude={defaultTo(36.12366, path(['latitude'], location))}
          longitude={defaultTo(-86.79014, path(['longitude'], location))}
          latitudeDelta={0.0922}
          longitudeDelta={0.0421}
          key={path(['latitude'], location)}
          style={{
            top: props.isMobile ? 24 : isCandidate ? 400 : 440,
            width: props.isMobile ? '100%' : '424px'
          }}
          Map={props.isMobile ? SmallMobileWebMap : SmallWebMap}
        >
          <FOHMapMarker
            {...location}
            setOpenPin={() => {}}
            isOpen={false}
            Callout={InfoBox}
            Marker={Marker}
            isActive={true}
            onMarkerPress={_id => {}}
          />
        </FOHMap>
      </FOHLocationDetailScreen>
      <AuthFlowSelectionForSigninOrSignupModal
        currentPath={currentPath}
        setOpenRegister={setOpenRegister}
        setOpenSignIn={setOpenSignIn}
      />
      <SignInModal
        openSignIn={openSignIn}
        setOpenSignIn={val => setOpenSignIn(val)}
        onSignIn={async () => {
          setBanner({
            bannerType: FOHBannerType.SUCCESS,
            bannerMessage: t('loginSuccess')
          });
          setOpenSignIn(false);
          await refetchMeQuery();
          await locationQuery.refetch();
        }}
      />
      <RegisterModal
        open={openRegister}
        onboarding={false}
        key={registerType}
        signUpFlow={'location-detail'}
        close={() => setOpenRegister(false)}
        onRegister={async chosenRegisterType => {
          await refetchMeQuery();
          chosenRegisterType === CANDIDATE_GROUP
            ? navigateTo(CANDIDATE_ONBOARDING_2)
            : navigateTo(EMPLOYER_ONBOARDING_2);
        }}
        group={registerType}
      />
    </>
  );
};

export default compose(
  withTranslation('LocationDetailFeature'),
  withIsMobile
)(LocationDetailScreen);
