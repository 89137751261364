import React, { useState } from 'react';

import { withTranslation } from 'react-i18next';
import { compose } from 'ramda';

import { storeAuth, handle, withIsMobile } from '../../utils';
import { login } from '../../api/auth';
import { loginScreenErrorTransform } from '../../api/transformers/login';
import { withUpdateUser, withUser } from '../../api/hocs';
import { PASSWORD_RESET_PATH } from '../../constants';
import { FOHSignInModal } from '../../components';
import { useNavigation } from '../../utils/navigation';

import { useSidebarNav } from '../Navigation/useSidebar';

// TODO: Switch to meQuery
const SignInModal = props => {
  const { t, user, openSignIn, setOpenSignIn, closeModal } = props || {};
  const { navigateTo } = useNavigation();

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState(props.error || {});
  const { contentShift } = useSidebarNav(props);

  // handle cases when direct method or useState setter is passed
  const handleClose = closeModal ? closeModal : () => setOpenSignIn(false);

  return (
    <FOHSignInModal
      leftContentShift={contentShift}
      dialog={!props.isMobile}
      open={openSignIn}
      close={handleClose}
      title={props.title || t('loginTitle')}
      detailLabel={props.detailLabel || ''}
      submitLabel={t('nextLabel')}
      noAccountLabel={t('noAccountLabel')}
      email={email}
      emailPlaceholder={t('email')}
      setEmail={setEmail}
      password={password}
      passwordPlaceholder={t('password')}
      setPassword={setPassword}
      error={error}
      forgotPasswordLabel={t('forgotPassword')}
      signUpLabel={t('noAccount')}
      largeHeader={!props.isMobile}
      handleSubmit={async () => {
        const loginData = { email, password };
        const [auth, respError] = await handle(login(loginData));
        if (respError) {
          const err = loginScreenErrorTransform(respError);
          return setError(err);
        } else {
          storeAuth(auth);
          props.onSignIn && props.onSignIn();
          return user;
        }
      }}
      disabledSubmit={!email || !password}
      handleForgotPassword={() => navigateTo(PASSWORD_RESET_PATH)}
      handleSignUp={props.handleSignUp}
    />
  );
};

export default compose(
  withTranslation('LoginFeature'),
  withUpdateUser,
  withUser,
  withIsMobile
)(SignInModal);
