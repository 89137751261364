import { replace, compose, prop } from 'ramda';

const replaceVariables = (variables, mutation) => {
  const replacements = Object.keys(variables).map(key => {
    const type = typeof prop(key, variables);
    const value =
      type === 'string' ? `"${prop(key, variables)}"` : prop(key, variables);

    return replace(`$${key}`, value);
  }, []);
  return compose(...replacements)(mutation);
};

export const generateScheduleRangeMutations = ({
  updates = [],
  creates = [],
  deletes = []
}) => {
  const createScheduleRange = `mutateInterviewScheduleRange(
        input: {
          schedule: $schedule
          day: $day
          startHour: $startHour
          startMinute: $startMinute
          endHour: $endHour
          endMinute: $endMinute
        }
      ) {
        interviewScheduleRange {
          id
          day
          startHour
          startMinute
          endHour
          endMinute
        }
      }
  `;
  const updateScheduleRange = `mutateInterviewScheduleRange(
        input: {
          id: $id
          day: $day
          startHour: $startHour
          startMinute: $startMinute
          endHour: $endHour
          endMinute: $endMinute
        }
      ) {
        interviewScheduleRange {
          id
          day
          startHour
          startMinute
          endHour
          endMinute
        }
      }
  `;

  const deleteScheduleRange = `deleteInterviewScheduleRange(input: { clientMutationId: $id }) {
        id
        message
      }
  `;

  const createRangeMutations = creates.map(variables =>
    replaceVariables(variables, createScheduleRange)
  );

  const updateRangeMutations = updates.map(variables =>
    replaceVariables(variables, updateScheduleRange)
  );

  const deleteRangeMutations = deletes.map(variables =>
    replaceVariables(variables, deleteScheduleRange)
  );

  return {
    createRangeMutations,
    updateRangeMutations,
    deleteRangeMutations
  };
};
