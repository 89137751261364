import React from 'react';

import {
  FOHButton,
  FOHHeaderText,
  Screen,
  FOHDetailText,
  FOHTextButton,
  FOHErrorLabel,
  FOHView
} from '../../ingredients';

export interface FOHEmailVerificationProps {
  handleSubmit: () => void;
  title?: string;
  submitLabel: string;
  detailText: string;
  disabledSubmit?: boolean;
  skipForNowLabel: string;
  skip: () => void;
  error?: any;
  goBack?: () => void;
  largeHeader?: boolean;
}

export const FOHEmailVerificationScreen = (
  props: FOHEmailVerificationProps
) => (
  <Screen>
    <FOHView
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 60
      }}
    >
      <FOHView
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <FOHHeaderText
          style={{
            fontSize: props.largeHeader ? 30 : 20
          }}
        >
          {props.title}
        </FOHHeaderText>
      </FOHView>

      <FOHView style={{ width: 325, paddingTop: 8, paddingBottom: 8 }}>
        <FOHDetailText>{props.detailText}</FOHDetailText>
      </FOHView>

      <FOHErrorLabel style={{ padding: 8 }}>
        {props.error ? props.error : null}
      </FOHErrorLabel>

      <FOHButton
        onPress={props.handleSubmit}
        title={props.submitLabel}
        disabled={props.disabledSubmit}
      />

      <FOHView style={{ paddingTop: 18 }}>
        <FOHTextButton title={props.skipForNowLabel} onPress={props.skip} />
      </FOHView>
    </FOHView>
  </Screen>
);
