import React, { useState, useEffect, useMemo } from 'react';

import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import {
  defaultTo,
  path,
  prop,
  length,
  toLower,
  findIndex,
  propEq
} from 'ramda';
import { useParams } from 'react-router-dom';

import {
  useIsMobile,
  formatFollowerCountLabel,
  toDate,
  toDateTime,
  toShortDay,
  toTime
} from '../../utils';
import {
  INTERVIEW_STATUS_SCHEDULED,
  INTERVIEW_STATUS_DECLINED,
  INTERVIEW_STATUS_ACCEPTED
} from '../../constants';
import { useGetInterviewScheduleQuery } from '../../graphql/generated';
import {
  FOHView,
  FullWidthCard,
  Screen,
  FOHSpace,
  FOHSpaceLarger,
  FOHHeaderH1,
  FOHLocationOverview,
  FOHCandidateInterviewScheduleForm,
  FOHInterviewMeetingType
} from '../../components';
import { useNavigation } from '../../utils/navigation';

import {
  getScheduleError,
  useRespondToInterviewRequest
} from './useRespondToInterviewRequest';

const CandidateScheduleInterviewScreen = () => {
  const { isMobile } = useIsMobile();
  const { t } = useTranslation('InterviewFeature');
  const { goBack } = useNavigation();

  const [selectedDay, setSelectedDay] = useState();
  const [selectedTime, setSelectedTime] = useState();
  const [noneOfTheseTimesWork, setNoneOfTheseTimesWork] = useState(false);
  const [candidateCustomMessage, setCandidateCustomMessage] = useState('');

  const { uuid } = useParams();

  const [weekOffset, setWeekOffset] = useState(0);

  const [startDate] = useState(
    DateTime.now().startOf('week').plus({ weeks: weekOffset }).toJSDate()
  );

  const [endDate] = useState(
    DateTime.fromJSDate(startDate).plus({ days: 30 }).toJSDate()
  );

  const interviewRequestQuery = useGetInterviewScheduleQuery({
    skip: !uuid,
    variables: {
      uuid,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString()
    }
  });
  const interview = path(['data', 'interview'], interviewRequestQuery);

  const status = toLower(defaultTo('', path(['status'], interview)));
  const hasScheduled = status === INTERVIEW_STATUS_SCHEDULED;
  const hasDeclined = status === INTERVIEW_STATUS_DECLINED;
  const hasAccepted = status === INTERVIEW_STATUS_ACCEPTED;

  const position = path(['position'], interview);
  const location = path(['location'], interview);

  const maxWeeksOut = Math.min(interview?.maxWeeksOut, 3);

  const interviewSchedule = useMemo(
    () =>
      defaultTo([], path(['interviewScheduleSlots'], interview))
        .map((schedule, i) => ({
          id: `${i}`,
          disabled:
            DateTime.fromFormat(
              prop('day', schedule),
              'yyyy-MM-dd'
            ).toISODate() < DateTime.now().toISODate() ||
            DateTime.fromFormat(
              prop('day', schedule),
              'yyyy-MM-dd'
            ).toISODate() >=
              DateTime.now()
                .plus({ days: maxWeeksOut * 7 })
                .toISODate() ||
            length(
              prop('slots', schedule).filter(slot => !!prop('available', slot))
            ) === 0 ||
            hasAccepted ||
            hasDeclined ||
            hasScheduled,
          date: toDate(prop('day', schedule)),
          shortDateName: toShortDay(prop('day', schedule))
        }))
        .slice(
          weekOffset > 0 ? weekOffset * 7 - 1 : weekOffset,
          weekOffset > 0 ? weekOffset * 7 - 1 + 7 : weekOffset + 7
        ),
    [interview, weekOffset]
  );

  const timeSlots = defaultTo(
    [],
    path(
      [
        'data',
        'interview',
        'interviewScheduleSlots',
        parseInt(selectedDay),
        'slots'
      ],
      interviewRequestQuery
    )
  )
    .filter(slot => !!prop('available', slot))
    .map(slot => ({
      label: toTime(prop('start', slot)),
      value: slot.start
    }));

  const meetingType = prop('interviewType', interview);

  const { error, scheduling, scheduleInterview } =
    useRespondToInterviewRequest();

  useEffect(() => {
    if (interviewSchedule) {
      const firstOpenDay = findIndex(propEq('disabled', false))(
        interviewSchedule
      );
      setSelectedDay(`${firstOpenDay}`);
      const firstTimeSlot = path(
        [
          'data',
          'interview',
          'position',
          'interviewSchedule',
          parseInt(firstOpenDay),
          'slots',
          0
        ],
        interviewRequestQuery
      );
      prop('available', firstTimeSlot) &&
        setSelectedTime(prop('start', firstTimeSlot));
    }
  }, [interviewRequestQuery.data]); //eslint-disable-line

  return (
    <Screen
      style={{
        alignItems: 'center',
        paddingTop: '32px',
        backgroundColor: '#F5F5F5',
        minHeight: '100vh',
        padding: isMobile ? '8px' : '24px'
      }}
    >
      <FOHView
        style={{
          maxWidth: '100vw',
          flex: 1,
          padding: isMobile ? '8px' : '24px',
          paddingTop: '40px'
        }}
      >
        <FOHHeaderH1>
          {hasScheduled
            ? `${t('scheduledFor')} ${toDateTime(
                defaultTo('', prop('scheduledFor', interview))
              )}`
            : t('whenWouldYouLikeToInterview')}
        </FOHHeaderH1>
        <FOHSpaceLarger />
        <FullWidthCard style={{ padding: isMobile ? '16px' : '24px', flex: 1 }}>
          <FOHLocationOverview
            {...location}
            followerCountLabel={formatFollowerCountLabel({
              followerCount: prop('followerCount', location),
              t
            })}
          />
        </FullWidthCard>
        <FOHSpace />
        <FOHView
          style={{
            maxWidth: isMobile ? '100vw' : '760px',
            flex: 1
          }}
        >
          <FOHCandidateInterviewScheduleForm
            error={
              error
                ? defaultTo(t('expiredInterview'), getScheduleError(error))
                : undefined
            }
            noneOfTheseTimesWork={noneOfTheseTimesWork}
            setNoneOfTheseTimesWork={val => {
              setSelectedTime();
              setSelectedDay();
              setNoneOfTheseTimesWork(val);
            }}
            positionLabel={position ? t('positionLabel') : ''}
            positionName={position ? prop('positionName', position) : ''}
            interviewLengthLabel={t('interviewLengthLabel')}
            interviewLength={t(prop('duration', interview))}
            locationLabel={
              meetingType === FOHInterviewMeetingType.PHONE
                ? t('phoneMeetingLabelLong')
                : meetingType === FOHInterviewMeetingType.REMOTE
                ? t('remoteMeetingLabelLong')
                : t('locationLabelLong')
            } // zoom, location, or phone
            locationName={prop('formattedAddress', location)}
            interviewMeetingType={meetingType}
            interviewMeetingTypeLabel={
              meetingType === FOHInterviewMeetingType.PHONE
                ? t('phoneMeetingLabel')
                : meetingType === FOHInterviewMeetingType.REMOTE
                ? t('remoteMeetingLabel')
                : t('locationMeetingLabel')
            }
            whereLabel={t('whereLabel')} //zoom, or phone
            where={
              meetingType === FOHInterviewMeetingType.PHONE
                ? prop('phoneNumber', interview)
                : meetingType === FOHInterviewMeetingType.REMOTE
                ? prop('meetingUrl', interview)
                : prop('formattedAddress', location)
            } // if not in person
            timeSlots={timeSlots}
            stepLabel={t('stepLabel')}
            selectDayLabel={t('selectDayLabel')}
            selectTimeLabel={t('selectTimeLabel')}
            noneOfTheseTimesWorkLabel={t('noneOfTheseTimesWorkLabel')}
            candidateCustomMessage={candidateCustomMessage}
            setCandidateCustomMessage={setCandidateCustomMessage}
            candidateCustomMessagePlaceholder={t(
              'alertHiringManagerForCustomMessageLabel'
            )}
            selectedTime={selectedTime}
            setSelectedTime={time => {
              setNoneOfTheseTimesWork(false);
              setSelectedTime(time);
            }}
            days={interviewSchedule}
            nextWeekLabel={t('nextWeekLabel')}
            selectedDay={selectedDay}
            setSelectedDay={day => {
              setSelectedDay(day);
              setSelectedTime();
            }}
            previousWeekLabel={t('previousWeekLabel')}
            nextWeek={() => {
              if (weekOffset < maxWeeksOut) {
                setWeekOffset(weekOffset + 1);
              }
            }}
            previousWeek={() => {
              if (weekOffset >= 1) {
                setWeekOffset(weekOffset - 1);
              }
            }}
            goBackLabel={t('goBackLabel')}
            goBack={() => {
              goBack();
            }}
            submitScheduleLabel={
              scheduling
                ? t('scheduling')
                : hasScheduled
                ? `${t('scheduledFor')} ${toDateTime(
                    defaultTo('', prop('scheduledFor', interview))
                  )}`
                : hasDeclined
                ? t(status)
                : hasAccepted
                ? t(status)
                : noneOfTheseTimesWork
                ? t('acceptInterview')
                : t('pending') //default to pending string 'Schedule'
            }
            disabledSubmitSchedule={
              scheduling ||
              hasAccepted ||
              hasScheduled ||
              hasDeclined ||
              (!selectedTime && !noneOfTheseTimesWork)
            }
            submitSchedule={async () => {
              await scheduleInterview({
                interviewId: prop('id', interview),
                uuid,
                noneOfTheseTimesWork,
                candidateCustomMessage,
                selectedTime
              });
            }}
            vertical={isMobile}
          />
        </FOHView>
      </FOHView>
      <FOHSpaceLarger />
      <FOHSpace />
    </Screen>
  );
};

export default CandidateScheduleInterviewScreen;
