import React from 'react';

import { defaultTo, prop } from 'ramda';

import icon from '../../../images/candidate_hire.png';

import { FOHImage } from '..';

export const FOHHiredLetterIcon = (props: any) => (
  <FOHImage
    source={{ uri: icon }}
    style={{
      width: 94,
      height: 72,
      right: 8,
      ...defaultTo({}, prop('style', props))
    }}
  />
);
