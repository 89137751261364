import { useState } from 'react';

import { path, prop } from 'ramda';
import { useLocalStorage } from 'use-hooks';

import { useSelectPositionForSelectedLocationState } from '../EmployerPositions/useSelectPositionForSelectedLocationState';

export const INBOX_FILTER = 'inbox';

export const ARCHIVE_FILTER = 'archived';

const defaultState = {
  searchText: '',
  chatListArchiveFilter: INBOX_FILTER,
  selectedChannels: [],
  isSelectAllActive: false,
  isArchivingAllChannels: false
};

type ChatState = {
  searchText: string;
  selectedChannels: Array<string>;
  isSelectAllActive: boolean;
  isArchivingAllChannels: boolean;
};

export const useChatFiltersState = ({
  setCurrentChannel
}: {
  setCurrentChannel: (channel: string) => void;
}) => {
  const [state, setState] = useState<ChatState>(defaultState);
  const [chatListArchiveFilter, setChatListArchiveFilter] = useLocalStorage(
    'chat-list-archive-filter',
    INBOX_FILTER
  );

  const setFiltersState = (partialState: any) => {
    setState({ ...state, ...partialState });
  };

  const [locationAndPosition, setLocationAndPosition] = useLocalStorage(
    'chat-filter-location-position',
    {
      location: '',
      position: ''
    }
  );

  const {
    selectedLocation,
    myLocations,
    setSelectedLocation,
    selectedPosition,
    myPositions,
    setSelectedPosition,
    locationSearch,
    positionSearch,
    setLocationSearch,
    setPositionSearch
  } = useSelectPositionForSelectedLocationState({
    profile: {
      lastInterview: undefined,
      lastPositionApplication: undefined
    },
    locationId: locationAndPosition.location,
    positionId: locationAndPosition.position,
    locationIds: []
  });

  const locationOptions = myLocations.map((loc: any) => ({
    label: prop('displayName', loc),
    value: prop('id', loc),
    sublabel: prop('formattedAddress', loc)
  }));

  const positionOptions = myPositions.map(pos => ({
    label: prop('positionName', pos),
    value: prop('id', pos),
    sublabel: '',
    // prompt for upgrade label
    upgrade:
      path(['paymentStatus'], pos) !== 'SUBSCRIBED' &&
      path(['location', 'paymentStatus'], pos) !== 'SUBSCRIBED',
    locationIdToUpgrade: path(['location', 'id'], pos)
  }));

  const selectAllChannels = (allChannelUrls: string[]) => {
    if (allChannelUrls.length === state.selectedChannels.length) {
      return setState({
        ...state,
        selectedChannels: [],
        isSelectAllActive: false
      });
    }
    return setState({
      ...state,
      selectedChannels: allChannelUrls,
      isSelectAllActive: true
    });
  };

  const selectChannel = (channelUrl: string) => {
    if (state.selectedChannels.includes(channelUrl)) {
      const filteredChannels = state.selectedChannels.filter(
        channel => channel !== channelUrl
      );
      return setState({ ...state, selectedChannels: filteredChannels });
    }
    return setState({
      ...state,
      selectedChannels: [...state.selectedChannels, channelUrl]
    });
  };

  const toggleSelectAll = () => {
    return setState({ ...state, isSelectAllActive: !state.isSelectAllActive });
  };

  const setIsArchivingAllChannels = (isArchivingAllChannels: boolean) => {
    if (isArchivingAllChannels) {
      return setState({
        ...state,
        isArchivingAllChannels
      });
    } else {
      return setState({
        ...state,
        selectedChannels: [],
        isSelectAllActive: false,
        isArchivingAllChannels
      });
    }
  };

  const setPosition = (position: string) => {
    setLocationAndPosition({
      ...locationAndPosition,
      position: position
    });
    setSelectedPosition(position);
    setCurrentChannel('');
  };

  const setLocation = (location: string) => {
    setLocationAndPosition({
      ...locationAndPosition,
      location: location,
      position: ''
    });
    setSelectedLocation(location);
    setSelectedPosition('');
    setCurrentChannel('');
  };

  const setChatListFilter = (filter: string) => {
    setChatListArchiveFilter(filter);
    setCurrentChannel('');
  };

  return {
    ...state,
    locationOptions,
    positionOptions,
    selectedLocation,
    myLocations,
    setSelectedLocation: setLocation,
    selectedPosition,
    myPositions,
    setSelectedPosition: setPosition,
    locationSearch,
    positionSearch,
    setLocationSearch,
    setPositionSearch,
    setFiltersState,
    selectChannel,
    selectAllChannels,
    toggleSelectAll,
    setIsArchivingAllChannels,
    setChatListArchiveFilter: setChatListFilter,
    chatListArchiveFilter
  };
};
