import React from 'react';

import { Text } from 'react-native';

import {
  FOHDivider,
  FOHHeaderH2,
  FOHHighlightWordsLabel,
  FOHSmallDetailLabel,
  FOHSpaceSmall,
  FOHSpace,
  FOHTextButton,
  FOHRedX,
  FOHFileIcon,
  FOHDetailText,
  FOHColors,
  FOHView
} from '../../ingredients';
import {
  FOHMultipleProfilePhotos,
  FOHMultipleProfilePhotosProps
} from '../../recipes';

export interface FOHMultipleCandidatesSelectedProps
  extends FOHMultipleProfilePhotosProps {
  selectionText: string;
  selectionHighlightText: string;
  detailLabel: string;
  deselectLabel?: string;
  exportToCSVLabel?: string;
  deselectAll: () => void;
  exportToCSV?: () => void;
  youCanAlsoLabel: string;
  orLabel?: string;
}

export const FOHMultipleCandidatesSelected = (
  props: FOHMultipleCandidatesSelectedProps
) => (
  <FOHView style={{ alignItems: 'center' }}>
    <FOHView style={{ width: 500, alignItems: 'center', left: -75 }}>
      <FOHMultipleProfilePhotos {...props} />
    </FOHView>
    <FOHHighlightWordsLabel
      labelCmp={FOHHeaderH2}
      highlightColor={FOHColors.BLUE}
      emphasizedWords={props.selectionHighlightText.split(' ')}
      boldLabelCmp={Text}
    >
      {props.selectionText}
    </FOHHighlightWordsLabel>
    <FOHSpaceSmall />
    <FOHSmallDetailLabel>{props.detailLabel}</FOHSmallDetailLabel>
    <FOHSpace />
    <FOHView style={{ width: 250, alignItems: 'center' }}>
      <FOHDivider />
    </FOHView>
    <FOHSpace />
    <FOHView>
      <FOHDetailText>{props.youCanAlsoLabel}</FOHDetailText>
    </FOHView>
    <FOHView style={{ alignItems: 'center', flexDirection: 'row' }}>
      {!!props.exportToCSVLabel && !!props.exportToCSV ? (
        <FOHTextButton
          icon={() => <FOHFileIcon style={{ width: 16, height: 20 }} />}
          onPress={props.exportToCSV}
          title={props.exportToCSVLabel}
          style={{ textDecorationLine: 'underline' }}
        />
      ) : (
        <></>
      )}
      {props.exportToCSVLabel ? (
        <>
          <FOHSpace />
          <FOHDetailText>{props.orLabel}</FOHDetailText>
          <FOHSpace />
        </>
      ) : (
        <></>
      )}

      {!!props.deselectAll && !!props.deselectLabel ? (
        <FOHTextButton
          icon={FOHRedX}
          onPress={props.deselectAll}
          title={props.deselectLabel}
          style={{ textDecorationLine: 'underline' }}
        />
      ) : (
        <></>
      )}
    </FOHView>
  </FOHView>
);
