import React from 'react';

import status from '../../../images/closed_to_offers_status.svg';

import { FOHImage } from '../../ingredients';

export const FOHNotLookingStatusIcon = () => (
  <FOHImage source={{ uri: status }} style={{ width: 16, height: 9 }} />
);

export const FOHLargeNotLookingStatusIcon = () => (
  <FOHImage source={{ uri: status }} style={{ width: 22, height: 12 }} />
);
