import React, { useEffect, useState } from 'react';

import { defaultTo, length, path, prop } from 'ramda';
import { useTranslation } from 'react-i18next';

import { handleMutation, multiSelectItem, useIsMobile } from '../../utils';
import {
  EmployerCandidatesDocument,
  EmployerStagesDocument,
  useEmployerCandidatesQuery,
  useGetEmployerRejectionReasonsQuery,
  useRejectCandidatesMutation,
  useUnrejectCandidatesMutation
} from '../../graphql/generated';
import {
  ADD_REJECT_REASON,
  EMPLOYER_SETTINGS_MENU,
  FEED,
  REJECTIONS
} from '../../constants';
import {
  FOHLabel,
  FOHColors,
  FOHView,
  FOHTouchableOpacity,
  FOHSpaceSmall,
  FOHCheckbox,
  FOHThumbDownIcon,
  FOHUnrejectIcon,
  FOHRejectDropdownButton,
  FOHEmployerStagesType
} from '../../components';
import { useNavigation } from '../../utils/navigation';

import { useCandidateNetworkSearchContext } from '../CandidateNetworkSearch/useCandidateNetworkSearchHook';
import { useStages } from '../CandidateFeed/useStages';
import { useCandidateFeedContext } from '../CandidateFeed/useCandidateFeedContext';

export const RejectButton = props => {
  const { navigateTo } = useNavigation();
  const {
    isRejected,
    employerCandidate,
    activeStageId,
    selectedCandidateCount,
    addIgnoredStages
  } = props;
  const {
    positionFilters,
    locationFilters,
    selectedEmployerCandidates,
    clearEmployerCandidates,
    allCandidates,
    setMovingEmployerCandidateId,
    notificationStatusFilter
  } = useCandidateFeedContext();

  const { profileDetailFilters } = useCandidateNetworkSearchContext();
  const {
    rejectedStage,
    interviewStage,
    currentStage,
    notificationType,
    filteredNotificationStatusValues
  } = useStages({
    activeStageId
  });

  const { t } = useTranslation('RejectReasonFeature');

  const [rejectReasonOpen, setRejectReasonOpen] = useState(false);
  const [selectedRejectionReasons, setSelectedRejectionReasons] = useState([]);

  const rejectionReasonsQuery = useGetEmployerRejectionReasonsQuery({});
  // only need this for refetching on unreject
  const employercandidatesQuery = useEmployerCandidatesQuery({
    skip: true
  });

  const rejectionReasons = defaultTo(
    [],
    path(['data', 'employerRejectionReasons', 'edges'], rejectionReasonsQuery)
  ).map(reason => {
    return { ...reason.node };
  });

  // todo make reject hook
  const [rejectCandidates] = useRejectCandidatesMutation();
  const [unrejectCandidates] = useUnrejectCandidatesMutation();

  useEffect(() => {
    if (isRejected) {
      const rejectReasonIds = defaultTo(
        [],
        defaultTo([], path(['rejectionReasons', 'edges'], employerCandidate))
          .map(edge => edge.node.id)
          .filter(id => id != null)
      );
      setSelectedRejectionReasons(rejectReasonIds);
    }
  }, [employerCandidate, isRejected]);

  const interviewRefetchQueries =
    activeStageId === prop('id', interviewStage)
      ? [
          {
            query: EmployerCandidatesDocument,
            variables: {
              first: 20,
              after: '',
              stageId: activeStageId,
              positions: positionFilters,
              locations: locationFilters,
              search: '',
              notificationType:
                notificationStatusFilter !== 'ALL' ? notificationType : '',
              notificationStatuses: []
            }
          },
          {
            query: EmployerCandidatesDocument,
            variables: {
              first: 20,
              after: '',
              stageId: activeStageId,
              positions: positionFilters,
              locations: locationFilters,
              search: '',
              notificationType:
                notificationStatusFilter !== 'ALL' ? notificationType : '',
              ...filteredNotificationStatusValues
            }
          }
        ]
      : [];

  // todo figure out how to refetch previous stage for unreject
  const refetchQueries = [
    ...interviewRefetchQueries,
    {
      query: EmployerCandidatesDocument,
      variables: {
        first: 20,
        after: '',
        stageId: prop('id', rejectedStage),
        positions: positionFilters,
        locations: locationFilters,
        search: ''
      }
    },
    {
      query: EmployerStagesDocument,
      variables: {
        positions: positionFilters,
        locations: locationFilters
      }
    },
    {
      query: EmployerStagesDocument,
      variables: {
        positions: positionFilters,
        locations: locationFilters,
        profileDetailFilters
      }
    },
    // todo check if active stage is interview stage
    {
      query: EmployerCandidatesDocument,
      variables: {
        first: 20,
        after: '',
        stageId: activeStageId,
        positions: positionFilters,
        locations: locationFilters,
        search: ''
      }
    }
  ];

  const { height } = useIsMobile();

  return (
    <FOHView style={{ width: '100%', maxWidth: 220 }}>
      <FOHRejectDropdownButton
        disabled={props.disabled}
        tipHelper={t('CandidateFeed:disableButtonHelperText')}
        buttonTitle={
          isRejected
            ? selectedCandidateCount > 0
              ? `${t('unrejectLabel')} (${selectedCandidateCount})`
              : t('unrejectLabel')
            : selectedCandidateCount > 0
            ? `${t('rejectLabel')} (${selectedCandidateCount})`
            : t('rejectLabel')
        }
        cardTitle={t('rejectReasonsTitle')}
        buttonIcon={
          isRejected ? (
            <FOHUnrejectIcon />
          ) : (
            <FOHThumbDownIcon
              style={{
                tintColor: props.disabled ? FOHColors.WHITE : FOHColors.BLACK,
                width: 18,
                height: 18,
                top: 2
              }}
            />
          )
        }
        maxHeight={height - 252}
        onButtonPress={async () => {
          if (!length(selectedEmployerCandidates) > 0) {
            setMovingEmployerCandidateId(employerCandidate.id);
          }
          if (isRejected) {
            addIgnoredStages([
              FOHEmployerStagesType.APPLICANTS,
              FOHEmployerStagesType.SCREENING,
              FOHEmployerStagesType.INTERVIEWS,
              FOHEmployerStagesType.OFFERED,
              FOHEmployerStagesType.REJECTED
            ]);
            const [response] = await handleMutation(
              unrejectCandidates({
                variables: {
                  employerCandidates:
                    length(selectedEmployerCandidates) > 0
                      ? selectedEmployerCandidates
                      : [employerCandidate.id],
                  locations: locationFilters,
                  positions: positionFilters,
                  all: allCandidates
                },
                refetchQueries
              })
            );

            if (
              path(['unrejectCandidates', 'toStage', 'id'], response) ===
              prop('id', interviewStage)
            ) {
              employercandidatesQuery.refetch &&
                (await employercandidatesQuery.refetch({
                  first: 20,
                  after: '',
                  stageId: path(
                    ['unrejectCandidates', 'toStage', 'id'],
                    response
                  ),
                  positions: positionFilters,
                  locations: locationFilters,
                  notificationType: '',
                  notificationStatuses: [], // refresh the all filter for stages with notification filters
                  search: ''
                }));
            } else {
              employercandidatesQuery.refetch &&
                (await employercandidatesQuery.refetch({
                  first: 20,
                  after: '',
                  stageId: path(
                    ['unrejectCandidates', 'toStage', 'id'],
                    response
                  ),
                  positions: positionFilters,
                  locations: locationFilters,
                  search: ''
                }));
            }
            props.setNextCandidate();
          } else {
            addIgnoredStages([currentStage.stageType, rejectedStage.stageType]);
            await handleMutation(
              rejectCandidates({
                variables: {
                  all: allCandidates,
                  positions: positionFilters,
                  locations: locationFilters,
                  employerCandidates:
                    length(selectedEmployerCandidates) > 0
                      ? selectedEmployerCandidates
                      : [employerCandidate.id],
                  stage: activeStageId,
                  rejectReasons: selectedRejectionReasons
                },
                awaitRefetchQueries: true,
                refetchQueries
              })
            );
          }
          setRejectReasonOpen(false);
          clearEmployerCandidates();
          setMovingEmployerCandidateId(undefined);
          props.setNextCandidate();
        }}
        open={rejectReasonOpen}
        setOpen={() => setRejectReasonOpen(!rejectReasonOpen)}
        onEditPress={() => navigateTo(`${EMPLOYER_SETTINGS_MENU}${REJECTIONS}`)}
        onApplyPress={async () => {
          await handleMutation(
            rejectCandidates({
              variables: {
                all: allCandidates,
                positions: positionFilters,
                locations: locationFilters,
                employerCandidates:
                  length(selectedEmployerCandidates) > 0
                    ? selectedEmployerCandidates
                    : [employerCandidate.id],
                stage: activeStageId,
                rejectReasons: selectedRejectionReasons
              },
              refetchQueries
            })
          );
          setRejectReasonOpen(false);
          clearEmployerCandidates();
          props.setNextCandidate();
        }}
        onAddNewPress={() => navigateTo(`${FEED}${ADD_REJECT_REASON}`)}
        editLabel={t('edit')}
        addNewLabel={t('addNew')}
        applyLabel={
          length(selectedRejectionReasons) === 0 ? t('rejectLabel') : t('apply')
        }
      >
        {rejectionReasons.map(reason => (
          <FOHView key={reason.id}>
            <FOHTouchableOpacity
              onPress={() => {
                multiSelectItem(
                  reason.id,
                  setSelectedRejectionReasons,
                  selectedRejectionReasons
                );
              }}
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                backgroundColor: selectedRejectionReasons.includes(reason.id)
                  ? FOHColors.GRAYSCALE_10
                  : '',
                paddingHorizontal: 16,
                paddingVertical: 8
              }}
              testID={`reject-reason-${reason.name}`}
            >
              <FOHCheckbox
                selected={selectedRejectionReasons.includes(reason.id)}
              ></FOHCheckbox>
              <FOHLabel
                style={{
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <FOHSpaceSmall />
                {reason.name}
              </FOHLabel>
            </FOHTouchableOpacity>
            <FOHSpaceSmall />
          </FOHView>
        ))}
      </FOHRejectDropdownButton>
    </FOHView>
  );
};
