import React, { useState, useEffect } from 'react';

import { compose, path, prop, defaultTo } from 'ramda';
import { useTranslation } from 'react-i18next';

import {
  FOHFixedHeightScrollView,
  FOHInverseButton,
  FOHView,
  FOHButton,
  FOHSpace,
  FOHColors,
  FOHDetailText,
  FOHHeaderText,
  FOHPickAvatarForm,
  FOHAreYouSureModal,
  FOHFormFlowButtons
} from '../../components';
import { useIsMobile } from '../../utils';
import {
  envVariables,
  CANDIDATE_ACCOUNT_MENU_PATH,
  EXTERNAL_APPLICATION_HEIGHTTODECREASE,
  EXTERNAL_APPLICATION_WIDTH,
  EXTERNAL_MAX_AVATAR_COUNT
} from '../../constants';
import search_charachter from '../../images/search_charachter.svg';
import { useNavigation } from '../../utils/navigation';

import { withReactiveModal } from '../Navigation/withReactiveModal';
import { useUploadCandidateAvatar } from '../UploadFiles/useUploadCandidateAvatar';

import { usePositionTypes } from './usePositionTypes';

const getAvatarForHtml = index =>
  `https://${envVariables.AWS_S3_BUCKET_NAME}.s3.amazonaws.com/avatars/ava${index}.png`;

const UploadPhotoScreen = props => {
  const { navigateTo } = useNavigation();

  const { t } = useTranslation('UploadPhotoFeature');
  const { isMobile } = useIsMobile();
  const {
    me,
    submitPhoto,
    photo,
    setPhoto,
    pickPhoto,
    UploadInput,
    loading
    // error
  } = useUploadCandidateAvatar();

  const { getStringForPositionType } = usePositionTypes();

  const [avatarCount, setAvatarCount] = useState(10);
  const [defaultAvatars, setDefaultAvatars] = useState([]);
  const [areYouSureOpen, setAreYouSureOpen] = useState();
  const [areYouSurePhoto, setAreYouSurePhoto] = useState('');

  const loadMore = () => {
    if (avatarCount < EXTERNAL_MAX_AVATAR_COUNT) {
      setAvatarCount(avatarCount + 10);
    }
  };

  const handleSubmit = async () => {
    await submitPhoto().then(fileIsUploaded => {
      if (fileIsUploaded) {
        if (props.close) props.close();
        path(['candidateProfile', 'onboardingCompletedAt'], me)
          ? navigateTo(CANDIDATE_ACCOUNT_MENU_PATH)
          : props.onSubmit && props.onSubmit();
      }
    });
  };

  useEffect(() => {
    // Load more avatars when the list count changes
    const avatars = new Array(avatarCount).fill({}).map((_, index) => {
      const avatar = getAvatarForHtml(index + 1);
      return avatar;
    });
    setDefaultAvatars(avatars);
  }, [avatarCount]);

  return (
    <>
      <FOHView
        style={{
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          paddingTop: props.isModal ? 0 : 30
        }}
      >
        <FOHView style={{ maxWidth: isMobile ? '100%' : 540, width: '100%' }}>
          <FOHFixedHeightScrollView
            isMobile={isMobile}
            heightToDecrease={EXTERNAL_APPLICATION_HEIGHTTODECREASE}
          >
            <FOHView
              style={{
                width: '100%',
                maxWidth: isMobile ? '100%' : EXTERNAL_APPLICATION_WIDTH,
                alignItems: 'center'
              }}
            >
              <FOHHeaderText style={{ fontSize: 30 }}>
                {t('photoTitle')}
              </FOHHeaderText>
              <FOHView
                style={{
                  width: isMobile ? '100%' : 327,
                  paddingBottom: 12
                }}
              >
                <FOHDetailText>{t(`uploadPhotoDetail`)}</FOHDetailText>
              </FOHView>
              <UploadInput />
              <FOHPickAvatarForm
                pickPhoto={pickPhoto}
                photo={photo}
                defaultPhotos={defaultAvatars}
                setPhoto={newPhoto => {
                  if (photo && !photo.includes('https')) {
                    setAreYouSureOpen(true);
                    setAreYouSurePhoto(newPhoto);
                  } else {
                    setPhoto(newPhoto);
                  }
                }}
                isMobile={isMobile}
                user={{
                  name: prop('firstName', me),
                  position: defaultTo(
                    [],
                    path(['candidateProfile', 'positions'], me)
                  )
                    .map(pos => getStringForPositionType(pos))
                    .join(', ')
                }}
                localization={{
                  title: '',
                  subTitle: '',
                  avatarSelectTitle: t(
                    'ExternalApplicationFeature:avatarSelectTitle'
                  ),
                  loadMoreTitle: t('loadMoreTitle'),
                  uploadTitle: loading
                    ? t('common:loading')
                    : t('common:upload')
                }}
              />
              <FOHSpace />
              {avatarCount < EXTERNAL_MAX_AVATAR_COUNT && (
                <FOHInverseButton
                  textColor={FOHColors.BLACK}
                  title={t('common:loadMore')}
                  onPress={loadMore}
                />
              )}
              <FOHSpace />
              <FOHSpace />
            </FOHView>
            {props.isModal && (
              <>
                <FOHSpace />
                <FOHButton
                  title={
                    loading
                      ? t('uploading')
                      : path(['candidateProfile', 'onboardingCompletedAt'], me)
                      ? t('common:save')
                      : t('nextLabel')
                  }
                  disabled={loading || !photo}
                  onPress={handleSubmit}
                />
              </>
            )}
          </FOHFixedHeightScrollView>
          <FOHView
            style={{
              width: '100%',
              maxWidth: isMobile ? '100%' : EXTERNAL_APPLICATION_WIDTH,
              marginBottom: isMobile ? 0 : 10
            }}
          >
            {!props.isModal && (
              <FOHFormFlowButtons
                isMobile={isMobile}
                nextButtonlabel={
                  loading
                    ? t('uploading')
                    : path(['candidateProfile', 'onboardingCompletedAt'], me)
                    ? t('common:save')
                    : t('nextLabel')
                }
                onPressNextButton={handleSubmit}
                isNextDisabled={loading || !photo}
                previousButtonLabel={t('common:previous')}
                onPressPreviousButton={props.previousStep}
              />
            )}
            {!isMobile && (
              <>
                <FOHSpace />
              </>
            )}
          </FOHView>
        </FOHView>
      </FOHView>
      <FOHAreYouSureModal
        open={areYouSureOpen}
        close={() => {
          setAreYouSureOpen(false);
        }}
        image={search_charachter}
        headerLabel={t('ExternalApplicationFeature:areYouSure')}
        detailLabel={t(
          'ExternalApplicationFeature:areYouSureYouWantToRemoveYourPhoto'
        )}
        buttonLabel={t('ExternalApplicationFeature:yesReplacePhoto')}
        confirm={() => {
          setPhoto(areYouSurePhoto);
          setAreYouSureOpen(false);
        }}
        cancel={() => {
          setAreYouSureOpen(false);
        }}
        cancelText={t('ExternalApplicationFeature:noKeepPhoto')}
        dialog={!isMobile}
        imageHeight={155}
        imageWidth={105}
      />
    </>
  );
};

export const UploadPhotoModal = compose(withReactiveModal)(UploadPhotoScreen);

export default UploadPhotoScreen;
