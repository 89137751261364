import React from 'react';

import { defaultTo, prop } from 'ramda';

import stopWatch from '../../../images/stop_watch_.svg';

import { FOHImage } from '../../ingredients';

export const FOHStopWatchIcon = (props: any) => (
  <FOHImage
    source={{ uri: stopWatch }}
    style={{ width: 56, height: 56, ...defaultTo({}, prop('style', props)) }}
  />
);
