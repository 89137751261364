import React from 'react';

import trush_can_grey from '../../../images/trush_can.svg';

import { FOHImage } from '../../ingredients';

export interface FOHGrayCircleTrashCanIconProps {
  style?: any;
}

export const FOHGrayCircleTrashCanIcon = (
  props: FOHGrayCircleTrashCanIconProps
) => (
  <FOHImage
    source={{ uri: trush_can_grey }}
    style={{ width: 30, height: 30, ...props.style }}
  />
);
