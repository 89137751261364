import React from 'react';

import person from '../../../images/person_gray.svg';

import { FOHImage } from '../../ingredients';

export const FOHPersonGrayIcon = (props: any) => (
  <FOHImage
    source={{ uri: person }}
    style={{ width: 24, height: 24 }}
    {...props}
  />
);
