import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useSidebarNav } from '../../features/Navigation/useSidebar';
import { useIsMobile, useNavigation } from '../../utils';
import {
  FOHView,
  Screen,
  FOHHelpRequestModal,
  HelpRequestDataType,
  FOHBanner,
  FOHBannerType,
  FOHHelpRequestChoiceModal
} from '../../components';
import { useMutateSendHelpRequestMutation } from '../../graphql/generated';

import { useBanner } from '../Navigation';
import { useGetMeData } from '../SignIn';

const HelpRequestScreen = (props: any) => {
  const { isMobile } = useIsMobile();
  const { t } = useTranslation('HelpRequestScreen');
  const { data: me } = useGetMeData();
  const { goBack } = useNavigation();
  const { contentShift } = useSidebarNav(props);
  const { banner, setBanner } = useBanner();
  const [isHelpFormOpen, setIsHelpFormOpen] = useState<boolean>(false);
  const [sendHelpRequestMutation] = useMutateSendHelpRequestMutation();

  const sendHelpRequest = async (requestData: HelpRequestDataType) => {
    const variables = {
      email: requestData.email,
      firstName: requestData.firstName,
      lastName: requestData.lastName,
      employerName: requestData.employerName,
      message: requestData.message
      // attachments: requestData.fileData ? requestData.fileData : null
    };

    try {
      await sendHelpRequestMutation({ variables });
      setBanner({
        bannerMessage: t('helpRequestSuccessBanner') as string,
        bannerType:
          FOHBannerType.SUCCESS as unknown as keyof typeof FOHBannerType
      });
      goBack();
    } catch {
      setBanner({
        bannerMessage: t('helpRequestErrorBanner') as string,
        bannerType:
          FOHBannerType.DANGER as unknown as keyof typeof FOHBannerType
      });
      return;
    }
  };

  const blurStyle = {
    filter: 'blur(4px)',
    position: 'absolute',
    width: '100vw',
    height: '100vh',
    zIndex: 9900,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  };

  return (
    <FOHView
      style={{
        width: '100%',
        alignItems: 'center',
        paddingTop: 40,
        height: '100vh',
        paddingHorizontal: isMobile ? 15 : 0
      }}
    >
      {banner.bannerMessage ? (
        <FOHBanner
          style={{ marginBottom: 30, zIndex: 9999 }}
          {...banner}
          message={banner.bannerMessage}
        />
      ) : (
        <></>
      )}
      <Screen
        style={{
          justifyContent: 'flex-start',
          alignItems: 'center',
          paddingTop: isMobile ? 60 : 120,
          ...blurStyle
        }}
      />
      {isHelpFormOpen ? (
        <FOHHelpRequestModal
          me={me}
          close={goBack}
          onPressSubmit={sendHelpRequest}
          onPressCancel={() => setIsHelpFormOpen(false)}
          leftContentShift={contentShift}
        />
      ) : (
        <FOHHelpRequestChoiceModal
          close={goBack}
          onPressOpenHelpForm={() => setIsHelpFormOpen(true)}
          leftContentShift={contentShift}
        />
      )}
    </FOHView>
  );
};

export default HelpRequestScreen;
