import React from 'react';

import { View, Text } from 'react-native';
import styled from 'styled-components';

import { FOHFonts, FOHSpaceSmall, FOHSuccessIcon } from '../../ingredients';

import { BannerView } from '../banners/FOHBanner';

const Circle = styled(View)`
  border-radius: 50%;
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
  margin-top: 6px;
  background-color: #229557;
`;

export interface FOHCandidateMoveSuccessBannerProps {
  message: string;
}

export const FOHCandidateMoveSuccessBanner = (
  props: FOHCandidateMoveSuccessBannerProps
) => {
  return (
    <BannerView
      style={{
        width: '100%',
        backgroundColor: '#38A56B',
        borderRadius: 6,
        marginLeft: 'auto'
      }}
    >
      <Circle
        style={{ backgroundColor: 'white', borderRadius: 20, padding: 2 }}
      >
        <FOHSuccessIcon
          style={{
            tintColor: 'green'
          }}
        />
      </Circle>
      <FOHSpaceSmall />
      <Text
        style={{
          color: 'white',
          fontFamily: FOHFonts.REGULAR,
          fontWeight: '600'
        }}
      >
        {props.message}
      </Text>
    </BannerView>
  );
};
