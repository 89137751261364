import React from 'react';

import { useTranslation } from 'react-i18next';

import {
  FOHButton,
  FOHColors,
  FOHView,
  FOHSpace,
  FOHSpaceLarger,
  FOHBannerType,
  FOHHeaderH2,
  FOHSmallDetailLabel,
  FOHReactiveModal
} from '../../../components';
import { handleMutation } from '../../../utils';
import {
  GetEmployerUsersDocument,
  useRemoveEmployerUserMutationMutation
} from '../../../graphql/generated';

import { WithReactiveModalProps } from '../../Navigation';
import { useBanner } from '../../Navigation/useBanner';

interface RemoveTeammateConfirmationModalProps extends WithReactiveModalProps {
  teammateId: string;
}

const RemoveTeammateConfirmationModal = (
  props: RemoveTeammateConfirmationModalProps
) => {
  const { t } = useTranslation('MyTeamFeature');

  const [removeTeammate] = useRemoveEmployerUserMutationMutation();

  const { setBanner } = useBanner();

  const handleOnRemoveTeammate = async () => {
    const [response, error] = await handleMutation(
      removeTeammate({
        refetchQueries: [
          {
            query: GetEmployerUsersDocument,
            variables: {}
          }
        ],
        variables: {
          id: props.teammateId
        }
      })
    );

    if (error || !response) {
      setBanner({
        bannerMessage: t('removeTeammateError') as string,
        bannerType:
          FOHBannerType.DANGER as unknown as keyof typeof FOHBannerType
      });
      return;
    }

    props.close && props.close();
  };

  return (
    <FOHReactiveModal open={props.open} close={props.close} dialog={true}>
      <FOHView
        style={{ alignItems: 'center' }}
        testID="RemoveTeammateConfirmationModal"
      >
        <FOHHeaderH2>{t('removeTeammateConfirmationHeader')}</FOHHeaderH2>
        <FOHSpace />
        <FOHSmallDetailLabel
          style={{ paddingHorizontal: 4, textAlign: 'center' }}
        >
          {t('removeTeammateConfirmationDescription')}
        </FOHSmallDetailLabel>
        <FOHSpaceLarger />
        <FOHView
          style={{
            alignItems: 'center',
            flexDirection: 'row',
            paddingHorizontal: 4
          }}
        >
          <FOHButton
            borderColor={FOHColors.BORDER_GRAY}
            color={FOHColors.WHITE}
            onPress={() => props.close && props.close()}
            style={{ width: 128 }}
            textColor={FOHColors.BLACK}
            title={t('common:cancel')}
          />
          <FOHSpace />
          <FOHButton
            color={FOHColors.RED}
            onPress={handleOnRemoveTeammate}
            style={{ width: 128 }}
            title={t('remove')}
          />
        </FOHView>
      </FOHView>
    </FOHReactiveModal>
  );
};

export default RemoveTeammateConfirmationModal;
