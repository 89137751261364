import React, { useEffect, useRef, useState } from 'react';

import { animated, useSpring } from 'react-spring';

import {
  FOHVerticalDivider,
  FOHNotificationIcon,
  FOHSpaceSmallest,
  FOHLabel,
  FOHSpaceSmall,
  FOHColors,
  FOHView,
  FOHTouchableOpacity
} from '../../ingredients';

export interface FOHButtonNavigationProps {
  routes: Array<ButtonNav>;
  selectedRoute: string;
  onPressRoute: (route: string, stageName: string) => void;
  testID?: string;
  highlightUnselected: boolean;
}

interface ButtonNav {
  title: string;
  route: string;
  count?: number;
  hasNotification?: boolean;
  highlightUnselected: boolean;
  stageName: string;
}

export const FOHButtonNavigation = (props: FOHButtonNavigationProps) => (
  <FOHView testID={props.testID}>
    <FOHView
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: 8,
        paddingRight: 0,
        backgroundColor: FOHColors.GRAYSCALE_100,
        borderRadius: 4
      }}
    >
      {props.routes.map((route, index) => (
        <FOHView
          key={`${index}-${route.route}-container`}
          style={{ flexDirection: 'row' }}
        >
          <FOHView style={{ flexDirection: 'row' }} key={`${index}divider`}>
            {index !== 0 && <FOHVerticalDivider color={'#DBDBDB'} />}
          </FOHView>
          <FOHView
            key={`${index}${route.route}`}
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              paddingLeft: index === 0 ? 0 : 10,
              paddingRight: 10
            }}
            testID={`FOHButtonNav-container-${route.title}`}
          >
            <FOHButtonNav
              onPress={() => {
                props.onPressRoute(route.route, route.stageName);
              }}
              hasNotification={route.hasNotification}
              title={`${route.title}${
                route.count || route.count === 0 ? ` (${route.count})` : ''
              }`}
              selected={props.selectedRoute === route.route}
              testID="FOHButtonNav-button"
              highlightUnselected={route.highlightUnselected}
            />
          </FOHView>
        </FOHView>
      ))}
    </FOHView>
  </FOHView>
);

export interface FOHButtonNavProps {
  selected: boolean;
  onPress?: () => void;
  title: string;
  hasNotification?: boolean;
  testID?: string;
  highlightUnselected: boolean;
}

const AnimatedContainerStyle = {
  width: '100%',
  borderRadius: 4,
  height: 32,
  padding: 8,
  justifyContent: 'center',
  alignItems: 'center'
};

export const FOHButtonNav = React.memo((props: FOHButtonNavProps) => {
  const { selected, highlightUnselected, hasNotification, title } = props;

  const [isBlinking, setIsBlinking] = useState(highlightUnselected);
  const [resetAnimation, setResetAnimation] = useState(false);
  const [blinkCount, setBlinkCount] = useState(0);

  const blinkInterval = useRef<NodeJS.Timeout | undefined>(undefined);

  const animationProps = useSpring({
    from: { opacity: 1 },
    to: {
      backgroundColor: isBlinking
        ? FOHColors.BACKGROUND_DARK_GRAY
        : FOHColors.GRAYSCALE_100
    }
  });

  useEffect(() => {
    if (!!highlightUnselected && !selected) {
      // If animation already set then mark as false
      if (resetAnimation) {
        setResetAnimation(false);
      }

      blinkInterval.current = setInterval(() => {
        setIsBlinking(prevBlinking => !prevBlinking);
        setBlinkCount(prevCount => prevCount + 1);
      }, 1500);

      return () => {
        blinkInterval.current && clearInterval(blinkInterval.current);
      };
    }
  }, [highlightUnselected]);

  useEffect(() => {
    // We have to blink the stage twice only and after stop blink
    if (blinkCount === 5) {
      setBlinkCount(0);
      setIsBlinking(false);
      setResetAnimation(true);
      blinkInterval.current && clearInterval(blinkInterval.current);
    }
  }, [blinkCount]);

  return (
    <FOHTouchableOpacity
      onPress={props.onPress}
      style={{ width: '100%' }}
      testID={props.testID}
    >
      <AnimatedCotainer
        style={
          highlightUnselected && !selected && !resetAnimation
            ? { ...AnimatedContainerStyle, ...animationProps }
            : {
                ...AnimatedContainerStyle,
                backgroundColor: props.selected
                  ? FOHColors.GRAYSCALE_700
                  : props.highlightUnselected
                  ? FOHColors.BACKGROUND_DARK_GRAY
                  : 'transparent'
              }
        }
      >
        <FOHView
          style={{
            flexDirection: 'row',
            paddingTop: 8,
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <>
            {hasNotification ? (
              <>
                <FOHNotificationIcon backgroundColor={FOHColors.RED_200} />
                <FOHSpaceSmallest />
              </>
            ) : (
              <></>
            )}
          </>
          <FOHLabel
            bold={highlightUnselected}
            heavy={selected}
            numberOfLines={1}
            style={{
              color: selected ? FOHColors.WHITE : FOHColors.GRAYSCALE_700,
              lineHeight: 18
            }}
          >
            {title}
          </FOHLabel>
        </FOHView>
        <FOHSpaceSmall />
      </AnimatedCotainer>
    </FOHTouchableOpacity>
  );
});

const AnimatedCotainer = animated(FOHView);
