import React from 'react';

import fileicon from '../../../images/fileicon.svg';

import { FOHImage } from '../../ingredients';

export const FOHFileIcon = (props: any) => (
  <FOHImage
    source={{ uri: fileicon }}
    style={
      props && !!props.style
        ? { width: 30, height: 34, ...props?.style }
        : { width: 30, height: 34 }
    }
    resizeMode={'contain'}
  />
);
