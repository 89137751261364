import styled from 'styled-components/native';

import { FOHColors, FOHView, SPACING_SCALE } from '../../../ingredients';

export const Wrapper = styled(FOHView)`
  margin-top: 40px;
  margin-right: -24px;
  margin-left: -24px;
  border-radius: 8px;
  padding: 24px;
  background-color: ${FOHColors.LIGHT_GRAY};
`;

export const QuestionsWrapper = styled(FOHView)`
  margin-top: ${SPACING_SCALE.huge};
  display: grid;
  row-gap: ${SPACING_SCALE.huge};
`;
