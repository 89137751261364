import React from 'react';

import styled from 'styled-components/native';

import { FOHLabel, FOHColors } from '../../ingredients';

interface FOHGreenTagProps {
  label: string;
  style?: any;
}

export const FOHGreenTag = (props: FOHGreenTagProps) => (
  <GreenTagText style={{ ...props.style }}>{props.label}</GreenTagText>
);

const GreenTagText = styled(FOHLabel)`
  font-size: 12px;
  color: #ffffff;
  text-align: center;
  border-radius: 100px;
  height: 24px;
  padding-left: 12px;
  padding-right: 12px;
  justify-content: center;
  background-color: ${FOHColors.GREEN};
  text-align: center;
  line-height: 20px;
  padding-top: 2px;
  padding-bottom: 2px;
  word-break: break-word;
  margin-top: 4px;
`;
