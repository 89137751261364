import React from 'react';

import {
  Screen,
  FOHButton,
  FOHView,
  FOHColors,
  FOHLabel,
  FullWidthCard,
  FOHLookingCharacterIcon,
  FOHHeaderH2,
  FOHSpace,
  FOHSpaceLarger,
  FOHSpaceHuge
} from '../../ingredients';

export interface FOHDefaultErrorBoundaryProps {
  title: string;
  description: string;
  btnLabel: string;
  onPressBtn: () => void;
}

export const FOHDefaultErrorBoundary = (
  props: FOHDefaultErrorBoundaryProps
) => (
  <Screen
    style={{
      background: FOHColors.GRAYSCALE_10,
      height: '100%',
      minHeight: '100vh',
      alignItems: 'center'
    }}
  >
    <FullWidthCard
      shadow={true}
      style={{
        padding: 24,
        top: 221,
        width: '100%',
        maxWidth: 768
      }}
    >
      <FOHView style={{ flexDirection: 'row' }}>
        <FOHSpace />
        <FOHLookingCharacterIcon />
        <FOHSpaceHuge />
        <FOHView style={{ alignItems: 'center', top: 45 }}>
          <FOHHeaderH2
            style={{
              color: FOHColors.GRAYSCALE_900,
              fontSize: 40,
              lineHeight: 60,
              fontWeight: 600
            }}
          >
            {props.title}
          </FOHHeaderH2>
          <FOHSpace />
          <FOHLabel
            style={{
              color: FOHColors.GRAYSCALE_400,
              fontSize: 18,
              lineHeight: 28
            }}
          >
            {props.description}
          </FOHLabel>
          <FOHSpaceLarger />
          <FOHButton
            style={{ maxWidth: 171, height: 40 }}
            title={props.btnLabel}
            color={FOHColors.PRIMARY_TEAL_P1}
            onPress={props.onPressBtn}
          />
        </FOHView>
      </FOHView>
    </FullWidthCard>
  </Screen>
);
