import React, { useState, useEffect } from 'react';

import 'react-native-web';
import Loadable from '@loadable/component';
import { path, prop, slice, length, defaultTo } from 'ramda';
import { useTranslation } from 'react-i18next';

import { getToLanguage } from '../../utils/i18n';
import { removeAuth, useIsMobile } from '../../utils';
import { setUserPreferredLanguage } from '../../api/users';
import {
  LOGIN_URL,
  FEED,
  CANDIDATE_ONBOARDING_2,
  REGISTER_URL,
  ADD_NEW_LOCATION,
  UPGRADE,
  EMPLOYER_SETTINGS_MENU,
  BILLING,
  PLAN,
  HOME_URL,
  DEMO,
  RESTAURANT_JOBS,
  PERSONAL_ACCOUNT,
  LOCATION,
  POSITIONS_URL,
  TEAM,
  CANDIDATE_ACCOUNT_MENU_PATH,
  HOME_PAGE,
  EMPLOYER_DASHBOARD,
  PRICING,
  CANDIDATE_ONBOARDING,
  EMPLOYER_ONBOARDING,
  COMPLETE_APPLICATION,
  NOT_AUTHORIZED,
  EMPLOYERS,
  GOOGLE_OAUTH_CALLBACK,
  GROUP_SPECIFIC_REGISTER,
  EMPLOYER_SPECIFIC_REGISTER,
  CANDIDATE_SPECIFIC_REGISTER,
  CANDIDATE_PROFILE,
  ALLOWED_ROLES_GROUPS
} from '../../constants';
import { useGetMyPhotoQuery } from '../../graphql/generated';
import {
  FOHSearchBar,
  FOHSpaceSmallest,
  FOHSpaceSmall,
  FOHSpace,
  FOHView,
  FOHScrollView,
  FOHModal,
  FOHSelectionCell,
  FOHGrayCrownIcon,
  FOHGrayHelpIcon,
  FOHInterviewIcon,
  FOHPersonGrayIcon,
  FOHSettingsGrayIcon,
  FOHTeamIcon,
  FOHToolBoxIcon,
  FOHDivider,
  FOHInverseButton,
  FOHColors,
  FOHAddButton,
  FOHDetailText,
  FOHHeaderH1,
  FOHMenuItemCell,
  FOHTopNav,
  RestrictedView
} from '../../components';
import { useNavigation } from '../../utils/navigation';

import useLogoutRedirectContext from '../SignIn/useLogoutRedirectContext';
import { useCurrentLocationContext } from '../Locations/useCurrentLocationContext';
import { usePaymentPlan } from '../Billing/usePaymentPlan';
import { useEmployerOnboardingContext } from '../EmployerOnboarding';
import { useGetMeData } from '../SignIn';

const LoadableSendbirdInbox = Loadable(() => import('../Chat/SendbirdInbox'));

export const getProfilePhoto = path(['data', 'me', 'profileImage']);
const getHandle = path(['candidateProfile', 'handle']);
export const getCandidateProfile = prop('candidateProfile');
export const getEmployerId = path(['employerProfile', 'id']);

export const getIsHome = location =>
  location &&
  (location.pathname === HOME_URL ||
    location.pathname === EMPLOYERS ||
    location.pathname === PRICING ||
    location.pathname === DEMO);

export const hasUpgradeModal = location =>
  location.pathname.includes(`${BILLING}${PLAN}`) ||
  location.pathname.includes(FEED) ||
  location.pathname.includes(`${CANDIDATE_PROFILE}/`);

const TopNav = props => {
  const {
    data: me,
    refetch: refetchMeQuery,
    loading: loadingMe
  } = useGetMeData();
  const myPhotoQuery = useGetMyPhotoQuery({});
  const profileImage = getProfilePhoto(myPhotoQuery);

  const { location: navLocation, navigateTo } = useNavigation();

  const [menuOpen, setMenuOpen] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  const [search, setSearch] = useState('');

  const [locsationFilterModalOpen, setLocationFilterModalOpen] =
    useState(false);
  const { resetEmployerOnboarding } = useEmployerOnboardingContext();

  const { setLogoutWipeCacheClicked } = useLogoutRedirectContext();

  const { i18n, t } = useTranslation('TopNavFeature');
  const toLanguage = getToLanguage(i18n.language);

  const onboardingComplete = !!path(
    ['candidateProfile', 'onboardingCompletedAt'],
    me
  );
  const isLocationFeed = navLocation.pathname.includes(RESTAURANT_JOBS);

  const isCandidateHome = slice(0, 5, navLocation.pathname).includes(HOME_PAGE);
  const isHome = getIsHome(navLocation);

  const hasNotSignedIn = !me || isHome;

  const hasEmployerProfile = !!getEmployerId(me);

  const hasCandidateProfile = !!getCandidateProfile(me);

  const hasUnseenInterviewRequest = length(prop('notifications', me)) > 0;

  const feedPage = navLocation.pathname.includes(FEED);

  const topNavRestrictedRoutes = [
    LOGIN_URL,
    REGISTER_URL,
    GOOGLE_OAUTH_CALLBACK,
    GROUP_SPECIFIC_REGISTER,
    EMPLOYER_SPECIFIC_REGISTER,
    CANDIDATE_SPECIFIC_REGISTER
  ].includes(navLocation.pathname);

  const settings = navLocation.pathname.includes(EMPLOYER_SETTINGS_MENU);

  const profile = navLocation.pathname.includes(`${CANDIDATE_PROFILE}/`);

  const notAuthorized = navLocation.pathname.includes(NOT_AUTHORIZED);

  const verified = !!path(['employerProfile', 'verified'], me);

  const hasLocationSelector =
    feedPage || settings || (profile && hasEmployerProfile);

  const { selectLocationFilter, locationFilter, myLocations, currentLocation } =
    useCurrentLocationContext({
      skipLocationsQuery: !hasLocationSelector,
      props,
      search
    });

  const { subscribed, loadingPlan } = usePaymentPlan({
    locationFilter: locationFilter,
    currentPlanQ: hasLocationSelector
  });

  useEffect(() => {
    navLocation.pathname.includes(`${CANDIDATE_PROFILE}/`) &&
      !onboardingComplete &&
      refetchMeQuery();
    hasEmployerProfile && refetchMeQuery();
  }, [hasEmployerProfile, onboardingComplete, refetchMeQuery]);

  const inOnboarding =
    (navLocation.pathname.includes(CANDIDATE_ONBOARDING) &&
      !navLocation.pathname.includes(PERSONAL_ACCOUNT)) ||
    (navLocation.pathname.includes(EMPLOYER_ONBOARDING) &&
      !navLocation.pathname.includes(REGISTER_URL)) ||
    navLocation.pathname.includes(COMPLETE_APPLICATION);

  const { isMobile } = useIsMobile();

  return (
    <>
      <FOHTopNav
        isHideTopNav={topNavRestrictedRoutes}
        upgrade={
          hasLocationSelector && !subscribed && !loadingPlan
            ? () =>
                navigateTo({
                  pathname: hasUpgradeModal(navLocation)
                    ? `${navLocation.pathname}${UPGRADE}`
                    : `${EMPLOYER_SETTINGS_MENU}${BILLING}${PLAN}${UPGRADE}`,
                  search: navLocation.search
                })
            : undefined
        }
        upgradeLabel={isMobile ? '' : t('upgrade')}
        hasMenu={
          !hasNotSignedIn && (onboardingComplete || profile || !inOnboarding)
        }
        fullLogo={
          !isMobile && (!onboardingComplete || !inOnboarding || hasNotSignedIn)
        }
        mediumLogo={isHome && isMobile}
        // Banner is in different spot when in settings
        bannerType={settings ? null : props.bannerType}
        bannerMessage={settings ? null : props.bannerMessage}
        logout={async () => {
          setLogoutWipeCacheClicked(true);
        }}
        logoutLabel={notAuthorized ? t('signOut') : null}
        photo={profileImage}
        key={`${prop('id', me)}`}
        user={me ? { me: me } : undefined}
        onBurgerPress={
          isMobile &&
          !inOnboarding &&
          (isHome || hasCandidateProfile || hasEmployerProfile)
            ? () => {
                props.setBurgerOpen(!props.burgerOpen);
              }
            : null
        }
        burgerOpen={props.burgerOpen}
        // TODO: tabs should work for employers too
        tabs={
          isHome && !isMobile
            ? [
                {
                  title: t('home'),
                  onPress: () => {
                    navigateTo(HOME_URL);
                  },
                  selected: navLocation.pathname === HOME_URL
                },
                {
                  title: t('demo'),
                  onPress: () => {
                    navigateTo(DEMO);
                  },
                  selected: navLocation.pathname === DEMO
                },
                {
                  title: t('pricing'),
                  onPress: () => {
                    navigateTo(PRICING);
                  },
                  selected: navLocation.pathname === PRICING
                }
              ]
            : hasEmployerProfile && !isMobile && !inOnboarding
            ? [
                {
                  title: t('dashboard'),
                  onPress: () => {
                    navigateTo(EMPLOYER_DASHBOARD);
                  },
                  selected: navLocation.pathname.includes(EMPLOYER_DASHBOARD)
                },
                {
                  title: t('candidateFeed'),
                  onPress: () => {
                    navigateTo(FEED);
                  },
                  selected: navLocation.pathname.includes(FEED)
                },
                {
                  title: t('restaurantProfile'),
                  onPress: () => {
                    navigateTo(`${EMPLOYER_SETTINGS_MENU}${LOCATION}`);
                  },
                  selected: settings
                }
              ]
            : hasCandidateProfile && !isMobile && !inOnboarding
            ? [
                {
                  title: t('jobs'),
                  onPress: () => {
                    navigateTo(HOME_PAGE);
                  },
                  selected: isCandidateHome
                },
                {
                  title: t('exploreEmployers'),
                  onPress: () => {
                    navigateTo(RESTAURANT_JOBS);
                  },
                  selected: isLocationFeed
                },
                {
                  title: t('profileLabel'),
                  onPress: () => {
                    navigateTo(`${CANDIDATE_PROFILE}/${getHandle(me)}`);
                  },
                  selected: navLocation.pathname.includes(
                    `${CANDIDATE_PROFILE}/`
                  )
                }
              ]
            : undefined
        }
        onPressTab
        onLogoPress={() =>
          hasEmployerProfile
            ? inOnboarding
              ? () => {}
              : navigateTo(FEED)
            : onboardingComplete
            ? navigateTo(HOME_PAGE)
            : hasNotSignedIn && window
            ? (window.location.href = HOME_URL)
            : null
        }
        hasNotification={hasUnseenInterviewRequest}
        filterSelectLabel={isMobile ? '' : t('filterSelectLabel')}
        signInTitle={
          (!me && profile && !loadingMe) || isHome ? t('signIn') : null
        }
        signIn={() => {
          navigateTo(LOGIN_URL);
        }}
        viewDemoTitle={isHome ? t('DemoScreenFeature:viewDemo') : ''}
        viewDemo={() => navigateTo(DEMO)}
        menuOpen={menuOpen}
        onMenuPress={() => {
          if (isMobile && hasCandidateProfile) {
            props.setBurgerOpen(false);
            navigateTo(`${CANDIDATE_PROFILE}/${getHandle(me)}`);
          } else {
            setMenuOpen(!menuOpen);
          }
        }}
        hasFilterSelect={hasLocationSelector}
        onSearch={setSearch}
        searchValue={search}
        searchPlaceholder={t('common:search')}
        onLocationFilterPress={
          hasLocationSelector && isMobile
            ? () => {
                setLocationFilterModalOpen(true);
              }
            : undefined
        }
        filter={locationFilter}
        filterLabel={prop('name', currentLocation)}
        setFilter={filter => {
          selectLocationFilter(filter);
          setOpenFilters(false);
        }}
        openFilters={openFilters}
        setOpenFilters={setOpenFilters}
        filters={defaultTo(
          [],
          myLocations.map(loc => ({
            label: prop('displayName', loc),
            value: prop('id', loc),
            sublabel: prop('formattedAddress', loc)
          }))
        )}
        addLocationLabel={t('addLocation')}
        addLocation={() => {
          setOpenFilters(false);
          resetEmployerOnboarding();
          navigateTo(ADD_NEW_LOCATION);
        }}
        inbox={
          !isHome && !inOnboarding && !hasNotSignedIn
            ? () => (
                <LoadableSendbirdInbox
                  key={prop('sendbirdAccessToken', me)}
                  me={me}
                  isCandidate={hasCandidateProfile}
                  sendbirdUserId={prop('uuid', me)}
                  sendbirdAccessToken={prop('sendbirdAccessToken', me)}
                  mobile={isMobile}
                  onPress={() => {
                    setOpenFilters(false);
                    props.setBurgerOpen(false);
                  }}
                  inboxLabel={t('Inbox')}
                />
              )
            : undefined
        }
      >
        <FOHSpaceSmallest />
        {getHandle(me) ? (
          <>
            <FOHMenuItemCell
              label={t('profileLabel')}
              icon={() => (
                <FOHPersonGrayIcon style={{ height: 20, width: 20 }} />
              )}
              onPress={() => {
                setMenuOpen(false);
                if (getHandle(me)) {
                  if (onboardingComplete && window) {
                    navigateTo(`${CANDIDATE_PROFILE}/${getHandle(me)}`);
                  } else {
                    navigateTo(CANDIDATE_ONBOARDING_2);
                  }
                }
              }}
            />
            <FOHMenuItemCell
              label={t('toLanguage')}
              icon={() => (
                <FOHInterviewIcon style={{ width: 24, height: 24 }} />
              )}
              onPress={() => {
                i18n.changeLanguage(toLanguage);
                setUserPreferredLanguage(toLanguage);
                setMenuOpen(false);
              }}
            />
            <FOHMenuItemCell
              label={t('settings')}
              icon={FOHSettingsGrayIcon}
              onPress={() => {
                setMenuOpen(false);
                hasCandidateProfile
                  ? navigateTo(CANDIDATE_ACCOUNT_MENU_PATH)
                  : navigateTo(EMPLOYER_SETTINGS_MENU);
              }}
            />
          </>
        ) : null}

        {hasEmployerProfile ? (
          <>
            <FOHMenuItemCell
              label={t('account')}
              icon={() => (
                <FOHPersonGrayIcon style={{ height: 20, width: 20 }} />
              )}
              onPress={() => {
                setMenuOpen(false);
                navigateTo(`${EMPLOYER_SETTINGS_MENU}${PERSONAL_ACCOUNT}`);
              }}
            />
            <FOHMenuItemCell
              label={t('howItWorks')}
              icon={FOHGrayHelpIcon}
              onPress={() => {
                setMenuOpen(false);
                navigateTo(`${FEED}${DEMO}`);
              }}
            />
            <FOHDetailText style={{ paddingLeft: 24 }}>
              {t('manage')}
            </FOHDetailText>
            <FOHMenuItemCell
              label={t('hiringTeam')}
              icon={FOHTeamIcon}
              onPress={() => {
                setMenuOpen(false);
                navigateTo(`${EMPLOYER_SETTINGS_MENU}${TEAM}`);
              }}
            />
            <FOHMenuItemCell
              label={t('positions')}
              icon={FOHToolBoxIcon}
              onPress={() => {
                setMenuOpen(false);
                navigateTo(`${EMPLOYER_SETTINGS_MENU}${POSITIONS_URL}`);
              }}
            />
            <FOHMenuItemCell
              testID="nav-billing"
              icon={FOHGrayCrownIcon}
              label={t('planAndBilling')}
              key={String(verified)}
              onPress={() => {
                setMenuOpen(false);
                navigateTo(`${EMPLOYER_SETTINGS_MENU}${BILLING}${PLAN}`);
              }}
            />
            <FOHMenuItemCell
              label={t('settings')}
              icon={FOHSettingsGrayIcon}
              onPress={() => {
                setMenuOpen(false);
                isMobile
                  ? navigateTo(EMPLOYER_SETTINGS_MENU)
                  : navigateTo(`${EMPLOYER_SETTINGS_MENU}${LOCATION}`);
              }}
            />
          </>
        ) : null}
        <FOHView style={{ padding: 24 }}>
          <FOHInverseButton
            title={t('signOut')}
            color={FOHColors.BLACK}
            onPress={async () => {
              removeAuth();
              setMenuOpen(false);
              setLogoutWipeCacheClicked(true);
              refetchMeQuery();
            }}
          />
        </FOHView>
      </FOHTopNav>

      {hasLocationSelector && isMobile && (
        <FOHModal
          open={locsationFilterModalOpen}
          close={() => {
            setLocationFilterModalOpen(false);
          }}
        >
          <FOHHeaderH1>{t('chooseLocation')}</FOHHeaderH1>
          <FOHSpace />
          <FOHSearchBar
            onChangeSearch={setSearch}
            searchValue={search}
            searchPlaceholder={t('common:search')}
          />
          <FOHSpaceSmall />
          <FOHScrollView style={{ height: 300 }}>
            {myLocations.map(_loc => (
              <FOHSelectionCell
                key={prop('id', _loc)}
                label={`${prop('displayName', _loc)} - ${prop(
                  'formattedAddress',
                  _loc
                )}`}
                value={prop('id', _loc)}
                selected={prop('id', _loc) === locationFilter}
                onPress={() => {
                  selectLocationFilter(prop('id', _loc));
                  setLocationFilterModalOpen(false);
                }}
              />
            ))}
          </FOHScrollView>
          <FOHDivider />
          <FOHSpace />
          <RestrictedView
            allowedRoles={ALLOWED_ROLES_GROUPS.ROLES.ADMIN}
            allowedGroups={[ALLOWED_ROLES_GROUPS.GROUPS.EMPLOYER]}
          >
            <FOHAddButton
              title={t('addLocation')}
              onPress={() => {
                setOpenFilters(false);
                resetEmployerOnboarding();
                navigateTo(ADD_NEW_LOCATION);
              }}
            />
          </RestrictedView>
        </FOHModal>
      )}
    </>
  );
};

export default TopNav;
