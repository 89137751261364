import React, { useState, useContext, useEffect } from 'react';

import { prop, path } from 'ramda';
import { useSearchParams } from 'react-router-dom';

import { useGetLocationQuery } from '../../graphql/generated';
import { useNavigation } from '../../utils/navigation';

import { getSearchQuery, QLOCATIONID, addSearchQuery } from '../Search';

import { useMyLocationsData } from './useMyLocationsData';

const CurrentLocationContext = React.createContext([{}, () => {}]);

// Location context in the top nav
export const useCurrentLocationContext = (
  { skipLocationsQuery, props, search } = { skipLocationsQuery: false }
) => {
  const [state, setState] = useContext(CurrentLocationContext);
  const { location: navLocation } = useNavigation();

  const [params, setParams] = useSearchParams();

  const { data: myLocations } = useMyLocationsData({
    skipLocationsQuery,
    locationSearch: search
  });

  // eslint-disable-next-line
  const selectLocationFilter = locationFilter => {
    if (!locationFilter) return;

    setState({
      locationFilter
    });
    navLocation &&
      navLocation.search &&
      addSearchQuery(
        params,
        {
          key: QLOCATIONID,
          value: locationFilter
        },
        setParams
      );
  };

  useEffect(() => {
    const searchParamLocationId = getSearchQuery(navLocation, QLOCATIONID);
    if (
      searchParamLocationId &&
      searchParamLocationId !== 'undefined' &&
      !state.locationFilter
    ) {
      selectLocationFilter(searchParamLocationId);
    } else if (myLocations && !state.locationFilter) {
      let newlocation = myLocations.find(
        _location => _location.hasPositions === true
      );
      newlocation =
        newlocation === undefined ? prop(0, myLocations) : newlocation;
      selectLocationFilter(prop('id', newlocation));
    }
  }, [
    navLocation,
    myLocations,
    props,
    selectLocationFilter,
    state.locationFilter
  ]);

  const currentLocationQuery = useGetLocationQuery({
    skip: !state.locationFilter,
    variables: { id: state.locationFilter }
  });

  const currentLocation = path(['data', 'locationById'], currentLocationQuery);

  return {
    currentLocation,
    myLocations,
    // selects location for entire app
    selectLocationFilter,
    ...state
  };
};

export const CurrentLocationContextProvider = props => {
  const { location: navLocation } = useNavigation();
  const initialFilter = getSearchQuery(navLocation, QLOCATIONID);
  const [state, setState] = useState({
    locationFilter:
      initialFilter && initialFilter !== 'undefined' ? initialFilter : undefined
  });
  return (
    <CurrentLocationContext.Provider value={[state, setState]}>
      {props.children}
    </CurrentLocationContext.Provider>
  );
};
