import { includes, indexOf } from 'ramda';

export const multiSelectItem = (value, setSelected, selected = []) => {
  const indexToRemove = indexOf(value, selected);
  if (indexToRemove !== -1) {
    const newSelection = selected.filter((_, index) => index !== indexToRemove);
    setSelected(newSelection);
  } else if (selected && includes(value, selected)) {
    const newSelection = selected.filter(item => item !== value);
    setSelected(newSelection);
  } else if (selected) {
    setSelected([...selected, value]);
  } else {
    setSelected([value]);
  }
  return value;
};

export const dropdownSearchSelectItem = (value, setSelected, selected) => {
  if (selected.includes(value)) {
    const newSelection = selected.filter(item => item !== value);
    return setSelected(newSelection);
  } else {
    return setSelected([...selected, value]);
  }
};

export const optionsYesNo = t => [
  {
    label: t('common:yes'),
    value: true
  },
  {
    label: t('common:no'),
    value: false
  }
];
export const selectExternalApplicationTagItem = (
  value,
  setSelected,
  selected,
  message,
  setMessage
) => {
  if (selected.includes(value)) {
    const newSelection = selected.filter(item => item !== value);
    message = message
      .replace(`${value}, `, '')
      .replace(value.toLowerCase(), '');
    setSelected(newSelection);
    setMessage(message);
    return;
  } else {
    setSelected([...selected, value]);
    setMessage([message, `${value}, `].join(''));
    return;
  }
};
