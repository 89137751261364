import React from 'react';

import { FOHLocationTagProps, FOHTag, FOHView } from '../../ingredients';

export interface FOHTagListProps {
  tags: Array<FOHLocationTagProps>;
  renderOtherTags?: () => any;
}

export const FOHTagList = (props: FOHTagListProps) => (
  <FOHView
    style={{
      flexDirection: 'row',
      maxWidth: '100%',
      flexWrap: 'wrap',
      alignItems: 'center'
    }}
  >
    {props.renderOtherTags && props.renderOtherTags()}
    {props.tags.map(tag => (
      <FOHView key={tag.name} style={{ flexDirection: 'row' }}>
        <FOHTag label={tag.name} />
        <FOHView style={{ padding: 2 }} />
      </FOHView>
    ))}
  </FOHView>
);
