import React, { useRef } from 'react';

import { Linking, Platform, TouchableOpacity, View } from 'react-native';
import { useHover } from 'react-native-web-hooks';

import {
  FOHColors,
  FOHSpaceSmallest,
  FOHSpace,
  FOHOpenIcon,
  FOHLabelBold
} from '../../ingredients';

import { FOHAccessibilityLinkProps } from './FOHAccessibilityLink';

interface FOHLinkOutProps extends FOHAccessibilityLinkProps {
  noIcon?: boolean;
  color?: string;
  fontSize?: number;
  onPress?: () => void;
  labelStyle?: any;
  revertIcon?: boolean;
  testID?: string;
  style?: any;
  iconStyle?: any;
}

export const FOHLinkOut = (props: FOHLinkOutProps) => {
  const ref = useRef(null);
  const hoverRef = useHover(ref);

  const handleOnClick = () => {
    if (props.onPress) {
      props.onPress();
    } else {
      if (Platform.OS === 'web') {
        window.open(props.href, '_blank');
      } else {
        Linking.openURL(props.href);
      }
    }
  };

  return (
    <TouchableOpacity
      style={{ flex: 1, ...props?.style }}
      onPress={handleOnClick}
      accessibilityRole={props.onPress ? undefined : 'link'}
      testID={props.testID}
      ref={ref}
    >
      <View style={{ flexDirection: 'row', flex: 1 }}>
        <FOHLabelBold
          style={{
            fontSize: props.fontSize || 16,
            textDecorationLine: 'underline',
            flex: 1,
            color: hoverRef
              ? FOHColors.PRIMARY_TEAL_300
              : props.color || FOHColors.PRIMARY_TEAL_P1,
            ...props?.labelStyle
          }}
        >
          {!props.noIcon && props.revertIcon ? (
            <>
              <FOHOpenIcon style={props?.iconStyle} />
              <FOHSpace />
            </>
          ) : (
            <></>
          )}
          {props.children}
          {!props.noIcon && !props.revertIcon ? (
            <>
              <FOHSpaceSmallest />
              <FOHOpenIcon style={props?.iconStyle} />
            </>
          ) : (
            <></>
          )}
        </FOHLabelBold>
      </View>
    </TouchableOpacity>
  );
};
