import React from 'react';

import {
  FOHView,
  FOHReactiveModal,
  FOHReactiveModalProps,
  FOHDetailText,
  FOHHeaderText
} from '../../ingredients';

import { FOHSignInForm, FOHSignInFormProps } from '..';

export interface FOHSignisModalrops
  extends FOHSignInFormProps,
    FOHReactiveModalProps {
  title: string;
  detailLabel?: string;
  cancelText?: string;
  showPassText?: string;
  hidePassText?: string;
}

export const FOHSignInModal = (props: FOHSignisModalrops) => (
  <FOHReactiveModal
    leftContentShift={props.leftContentShift}
    dialog={props.dialog}
    open={props.open}
    close={props.close}
  >
    <FOHView
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 18
      }}
    >
      <FOHHeaderText>{props.title}</FOHHeaderText>
      {!!props.detailLabel && (
        <FOHDetailText>{props.detailLabel}</FOHDetailText>
      )}
      <FOHView
        style={{
          paddingTop: 24
        }}
      />
      <FOHSignInForm
        {...props}
        submitLabel={props.submitLabel}
        emailPlaceholder={props.emailPlaceholder}
        setEmail={props.setEmail}
        email={props.email}
        passwordPlaceholder={props.passwordPlaceholder}
        setPassword={props.setPassword}
        password={props.password}
        handleSubmit={props.handleSubmit}
        forgotPasswordLabel={props.forgotPasswordLabel}
        handleForgotPassword={props.handleForgotPassword}
        handleSignUp={props.handleSignUp}
        signUpLabel={props.signUpLabel}
        error={props.error}
        showPassText={props.showPassText}
        hidePassText={props.hidePassText}
      />
    </FOHView>
  </FOHReactiveModal>
);
