/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { useParams } from 'react-router-dom';

import {
  useGetCareerPageAnswersDisplayQuery,
  useGetCareerPageDataQuery,
  useGetCareerPageLocationsQuery
} from '../../../src/graphql/generated';
import { SLUGS } from '../../constants';
import { CustomSizeLogoLoader } from '../../features/Loading/LogoLoader';
import { useGetMeData } from '../SignIn';

import {
  adaptAnswersDisplayData,
  adaptLocations,
  adaptMainImageData,
  adaptPageData
} from './adapters';
import { CareerPage } from './CareerPage';

export const CareerPageScreen = () => {
  const params = useParams();
  const { modal, [SLUGS.careerPage]: slug } = params;

  const queryVars = { variables: { slug } };

  const { data: meData, loading: loadingMe, refetch } = useGetMeData();
  const { data: pageData, loading: loadingPage } =
    useGetCareerPageDataQuery(queryVars);
  const { data: locData, loading: loadingLoc } =
    useGetCareerPageLocationsQuery(queryVars);
  const { data: answersDisplayData, loading: loadingAns } =
    useGetCareerPageAnswersDisplayQuery(queryVars);

  if (loadingPage || loadingAns || loadingLoc || loadingMe)
    return <CustomSizeLogoLoader height="100vh" width="100vw" />;

  return (
    <CareerPage
      answers={adaptAnswersDisplayData(answersDisplayData)}
      data={adaptPageData(pageData)}
      locations={adaptLocations(locData, true)}
      mainImageArray={adaptMainImageData(pageData)}
      meQuery={{ refetchMe: refetch, userData: meData }}
      modalRouteParam={modal}
    />
  );
  // return null;
};
