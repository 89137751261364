import React, { FunctionComponent } from 'react';

import { StyleProp, ViewStyle } from 'react-native';
import styled from 'styled-components/native';

import { FOHView, SPACING_SCALE } from '../../ingredients';
import { FOHTabList, FOHTabListProps } from '../../recipes';

import { FontProperty } from '../';

import { DetailsRow } from './styled/FOHCareerPageShared.styled';
import { OverviewContent } from './parts';

export interface FOHCareerPageTabsProps
  extends Pick<FOHTabListProps, 'onPressTab' | 'selectedTab' | 'tabs'> {
  fontProperty?: FontProperty;
  overviewContent?: string | React.ReactElement;
  overViewWordCount: number;
  style?: StyleProp<ViewStyle>;
  translations: Record<string, string>;
}

export const FOHCareerPageTabs: FunctionComponent<FOHCareerPageTabsProps> = ({
  fontProperty,
  onPressTab,
  overViewWordCount,
  overviewContent,
  selectedTab,
  style,
  tabs,
  translations
}) => {
  if (!overviewContent) return null;
  const hasMultipletabs = tabs?.length > 1;

  return (
    <FOHView
      style={[
        {
          marginTop: hasMultipletabs ? 0 : SPACING_SCALE.larger,
          width: '100%'
        },
        style
      ]}
    >
      {hasMultipletabs && (
        <DetailsRow style={{ marginBottom: SPACING_SCALE.huge }}>
          <FOHTabList
            onPressTab={onPressTab}
            selectedTab={selectedTab}
            style={
              {
                columnGap: 30,
                justifyContent: 'flex-start',
                ...fontProperty
              } as StyleProp<ViewStyle>
            }
            tabOptions={{ fullWidth: true, tall: true }}
            tabs={tabs}
          />
        </DetailsRow>
      )}

      <DetailsRow>
        <FOHCareerPageTabContent selected={selectedTab === tabs[0].route}>
          <OverviewContent
            content={overviewContent}
            fontProperty={fontProperty}
            translations={translations}
            wordCount={overViewWordCount}
          />
        </FOHCareerPageTabContent>

        {/* Add new tabs as necessary */}
      </DetailsRow>
    </FOHView>
  );
};

const FOHCareerPageTabContent = styled(FOHView)<{ selected: boolean }>`
  display: ${({ selected }: { selected: boolean }) =>
    selected ? 'flex' : 'none'};
  align-items: flex-start;
`;
