import React, { FC } from 'react';

import styled from 'styled-components/native';
import { animated } from 'react-spring';

import {
  FOHColors,
  FOHHeaderH2,
  FOHLabel,
  FOHProfileImage,
  FOHView
} from '../../../components';
import { getInitials } from '../../../utils';

interface InterviewParticipant {
  firstName: string;
  lastName: string;
  uri: string;
  role: string;
}

interface FOHAvatarsOverlayOrImageWithHeaderProps {
  title: string;
  HeaderImage: any;
  candidateName?: string;
  interviewParticipants?: InterviewParticipant[] | undefined;
}

export const FOHAvatarsOverlayOrImageWithHeader: FC<
  FOHAvatarsOverlayOrImageWithHeaderProps
> = ({ title, HeaderImage, candidateName, interviewParticipants }) => (
  <FOHView
    style={{
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      paddingHorizontal: 48,
      width: '100%'
    }}
  >
    {interviewParticipants || HeaderImage ? (
      <FOHView style={{ maxWidth: 112, width: '100%' }}>
        {interviewParticipants ? (
          <ImagesContainer style={{ height: 72 }}>
            {interviewParticipants.map(
              (participant: InterviewParticipant, keyIndex: number) => {
                const initial = getInitials(participant);

                const customStyle =
                  keyIndex !== 0
                    ? {
                        left: -10,
                        transform: `rotateY(-1deg)`
                      }
                    : {};

                return (
                  <AnimatedDonut
                    key={keyIndex + 1}
                    style={{
                      borderWidth: 1,
                      borderColor: FOHColors.PRIMARY_TEAL_400,
                      borderRadius: '50%',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: 50,
                      height: 50,
                      ...customStyle
                    }}
                  >
                    <Circle>
                      {participant.uri ? (
                        <FOHProfileImage
                          photo={participant.uri}
                          style={{
                            height: 48,
                            width: 48
                          }}
                        />
                      ) : (
                        <FOHLabel>{initial}</FOHLabel>
                      )}
                    </Circle>
                  </AnimatedDonut>
                );
              }
            )}
          </ImagesContainer>
        ) : (
          <ImagesContainer style={{ height: 82 }}>
            <HeaderImage />
          </ImagesContainer>
        )}
      </FOHView>
    ) : (
      <></>
    )}
    <FOHView
      style={{
        textAlign: 'left',
        alignItems: 'flex-start',
        justifyContent: 'flex-start'
      }}
    >
      <FOHView
        style={{
          width: '100%',
          flexDirection: 'row'
        }}
      >
        <FOHHeaderH2
          style={{
            textAlign: 'left',
            lineHeight: 32,
            color: FOHColors.NARO_GREY
          }}
        >
          {title}
        </FOHHeaderH2>
        {candidateName ? (
          <FOHHeaderH2
            numberOfLines={1}
            style={{
              color: FOHColors.SILVER_POLISH,
              alignItems: 'flex-start',
              textAlign: 'left',
              paddingLeft: 8,
              fontWeight: 400,
              maxWidth: 218
            }}
          >
            {`· ${candidateName} ·`}
          </FOHHeaderH2>
        ) : (
          <></>
        )}
      </FOHView>
    </FOHView>
  </FOHView>
);

const ImagesContainer = styled(FOHView)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transform-style: preserve-3d;
`;

const Circle = styled(FOHView)`
  border-radius: 50%;
  width: 48px;
  justify-content: center;
  align-items: center;
  height: 48px;
  background: ${FOHColors.GRAYSCALE_100};
`;

const AnimatedDonut = animated(FOHView);
