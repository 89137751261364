import React from 'react';

import crown from '../../../images/gold_crown.svg';

import { FOHImage } from '../../ingredients';

export const FOHCrownIcon = (props: any) => (
  <FOHImage
    source={{ uri: crown }}
    style={{ width: 28, height: 26 }}
    {...props}
  />
);
