import React, { useState } from 'react';

import { withTranslation } from 'react-i18next';
import { compose, path, isEmpty } from 'ramda';

import { handle } from '../../utils';
import { withUser, withEndorsements, withUpdateUser } from '../../api/hocs';
import { createEndorsement } from '../../api/endorsements';
import no_recommendation from '../../images/no_recommendation.svg';
import { FOHEndorseTable } from '../../components';

const EndorseTable = props => {
  const { t, canRecommend, canView } = props;
  const [relation, setRelation] = useState(
    path(['endorsement', 'employer_name'], props)
  );
  const [error, setError] = useState();
  const [endorsement, setEndorsement] = useState(
    path(['endorsement', 'body'], props)
  );
  const [isEndorsing, setIsEndorsing] = useState(!!props.endorsement);
  const clearEndorsement = () => {
    setRelation('');
    setEndorsement('');
    props.onSubmitEndorsment({
      endorsement: null,
      user: props.user,
      addEndorsement: null
    });
  };

  return (
    <FOHEndorseTable
      endorseTableTitle={t('endorseTableTitle')}
      endorsementFormTitle={t('endorsementFormTitle') + props.name + '?'}
      endorseHeaderText={t('endorseHeaderText')}
      noEndorsementImage={no_recommendation}
      relationPlaceholder={t('relationPlaceholder')}
      cancelLabel={t('cancelLabel')}
      recommendCTA={t('letRecommendation')}
      submitLabel={t('submitLabel')}
      endorsementPlaceholder={t('endorsementPlaceholder') + props.name}
      isEndorsing={isEndorsing}
      key={props.handle}
      canViewOnly={canView}
      recommend={canRecommend ? () => setIsEndorsing(true) : null}
      endorsement={endorsement}
      error={error}
      setEndorsement={setEndorsement}
      relation={relation}
      setRelation={setRelation}
      recommendations={props.endorsements.map(rec => ({
        relation: rec.employer_name
          ? `${t('workedTogetherAt')}` + rec.employer_name
          : '',
        endorser: rec.endorser,
        recommendation: rec.body,
        date: new Date(rec.created_at).toLocaleString('default', {
          year: 'numeric',
          month: 'short'
        })
      }))}
      handleSubmit={async () => {
        const req = {
          body: endorsement,
          employer_name: relation,
          candidate: path(['profile', 'rowId'], props)
        };
        // pass everything profile will need to add endorsement on register
        props.onSubmitEndorsment &&
          props.onSubmitEndorsment({
            endorsement: req,
            user: props.user
          });
        if (props.user && !isEmpty(props.user)) {
          const [res, err] = await handle(createEndorsement(req));
          if (res && !err) {
            props.addEndorsement({ ...res });
            setIsEndorsing(false);
          } else if (err) {
            setError(err.fieldErrors);
          }
        }
      }}
      cancel={() => {
        clearEndorsement();
        setIsEndorsing(false);
      }}
    />
  );
};

export default compose(
  // TODO: replace with GraphQL
  withUpdateUser,
  withUser,
  withTranslation('EndorseFeature'),
  withUser,
  withEndorsements
)(EndorseTable);
