import { prop } from 'ramda';

export const MONTHS_DROPDOWN = [
  { label: 'months:january', value: 0 },
  { label: 'months:february', value: 1 },
  { label: 'months:march', value: 2 },
  { label: 'months:april', value: 3 },
  { label: 'months:may', value: 4 },
  { label: 'months:june', value: 5 },
  { label: 'months:july', value: 6 },
  { label: 'months:august', value: 7 },
  { label: 'months:september', value: 8 },
  { label: 'months:october', value: 9 },
  { label: 'months:november', value: 10 },
  { label: 'months:december', value: 11 }
];

export const AVAILABILITY_WEEKDAYS = [
  { label: 'common:availability.monday', value: 'availability_monday' },
  { label: 'common:availability.tuesday', value: 'availability_tuesday' },
  { label: 'common:availability.wednesday', value: 'availability_wednesday' },
  { label: 'common:availability.thursday', value: 'availability_thursday' },
  { label: 'common:availability.friday', value: 'availability_friday' },
  { label: 'common:availability.saturday', value: 'availability_saturday' },
  { label: 'common:availability.sunday', value: 'availability_sunday' }
];

export const AVAILABILITY_WEEKDAYS_CAMEL = [
  { label: 'common:availability.monday', value: 'availabilityMonday' },
  { label: 'common:availability.tuesday', value: 'availabilityTuesday' },
  { label: 'common:availability.wednesday', value: 'availabilityWednesday' },
  { label: 'common:availability.thursday', value: 'availabilityThursday' },
  { label: 'common:availability.friday', value: 'availabilityFriday' },
  { label: 'common:availability.saturday', value: 'availabilitySaturday' },
  { label: 'common:availability.sunday', value: 'availabilitySunday' }
];

export const availabilityDayKeys = [
  'availability_monday',
  'availability_tuesday',
  'availability_wednesday',
  'availability_thursday',
  'availability_friday',
  'availability_saturday',
  'availability_sunday'
];

export const getStringKeyForScheduleDay = dayKey => {
  return prop(dayKey, {
    A_0: 'common:availability:monday',
    A_1: 'common:availability:tuesday',
    A_2: 'common:availability:wednesday',
    A_3: 'common:availability:thursday',
    A_4: 'common:availability:friday',
    A_5: 'common:availability:saturday',
    A_6: 'common:availability:sunday'
  });
};

export const getDayForScheduleDayKey = dayKey => {
  return prop(dayKey, {
    A_0: 'availability_monday',
    A_1: 'availability_tuesday',
    A_2: 'availability_wednesday',
    A_3: 'availability_thursday',
    A_4: 'availability_friday',
    A_5: 'availability_saturday',
    A_6: 'availability_sunday'
  });
};

export const availabilityDayKeysCamel = [
  'availabilityMonday',
  'availabilityTuesday',
  'availabilityWednesday',
  'availabilityThursday',
  'availabilityFriday',
  'availabilitySaturday',
  'availabilitySunday'
];

export const localizedDays = t =>
  [
    'common:availability:monday',
    'common:availability:tuesday',
    'common:availability:wednesday',
    'common:availability:thursday',
    'common:availability:friday',
    'common:availability:saturday',
    'common:availability:sunday'
  ].map(day => t(day));

export const ALL_DAY = 'all_day';
export const FIRST_SHIFT = 'breakfast';
export const SECOND_SHIFT = 'lunch';
export const THIRD_SHIFT = 'dinner';
export const FOURTH_SHIFT = 'late_night';

export const shifts = [
  { label: 'common:availability.firstShift', value: FIRST_SHIFT },
  { label: 'common:availability.secondShift', value: SECOND_SHIFT },
  { label: 'common:availability.thirdShift', value: THIRD_SHIFT },
  { label: 'common:availability.fourthShift', value: FOURTH_SHIFT }
];

// Availability
export const AVAILABILITY_DAYS = AVAILABILITY_WEEKDAYS;
export const AVAILABILITY_SHIFTS = shifts;
export const AVAILABILITY_SHIFTS_MAP = t => [
  [
    { label: t('common:availability.firstShift'), value: FIRST_SHIFT },
    { label: t('common:availability.secondShift'), value: SECOND_SHIFT }
  ],
  [
    { label: t('common:availability.thirdShift'), value: THIRD_SHIFT },
    { label: t('common:availability.fourthShift'), value: FOURTH_SHIFT }
  ]
];

// Restaurant types
export const RESTAURANT_TYPES_DROPDOWN = [
  { label: 'common:restaurantTypes.bar', value: 'bar' },
  { label: 'common:restaurantTypes.cafeBistro', value: 'cafe-bistro' },
  {
    label: 'common:restaurantTypes.cafeteria',
    value: 'cafeteria'
  },
  {
    label: 'common:restaurantTypes.casualDining',
    value: 'casual-dining'
  },
  {
    label: 'common:restaurantTypes.coffeeTeaJuiceBar',
    value: 'coffee-tea-juice-bar'
  },
  { label: 'common:restaurantTypes.countryClub', value: 'country-club' },
  { label: 'common:restaurantTypes.fastCasual', value: 'fast-casual' },
  {
    label: 'common:restaurantTypes.fastFood',
    value: 'fast-food'
  },
  {
    label: 'common:restaurantTypes.upscaleCasualFineDining',
    value: 'upscale-casual-fine-dining'
  }
];

// Additional Training
export const ADDITIONAL_TRAINING_DROPDOWN = [
  { label: 'common:additionalTraining.abcCard', value: 'abc_card' },
  { label: 'common:additionalTraining.butchering', value: 'butchering' },
  { label: 'common:additionalTraining.cicerone', value: 'cicerone' },
  { label: 'common:additionalTraining.costing', value: 'costing' },
  {
    label: 'common:additionalTraining.craftCocktails',
    value: 'craft_cocktails'
  },
  {
    label: 'common:additionalTraining.highVolumeExperience',
    value: 'high_volume_experience'
  },
  { label: 'common:additionalTraining.laborCost', value: 'labor_cost' },
  {
    label: 'common:additionalTraining.localPurveyorKnowledge',
    value: 'local_purveyor_knowledge'
  },
  {
    label: 'common:additionalTraining.menuDevelopment',
    value: 'menu_development'
  },
  { label: 'common:additionalTraining.posKnowledge', value: 'pos_knowledge' },
  {
    label: 'common:additionalTraining.servSafeCertificate',
    value: 'servsafe_certificate'
  },
  { label: 'common:additionalTraining.scheduling', value: 'scheduling' },
  {
    label: 'common:additionalTraining.specificCuisine',
    value: 'specific_cuisine'
  },
  { label: 'common:additionalTraining.wineKnowledge', value: 'wine_knowledge' }
];

// Years of Experience
export const YEARS_EXPERIENCE_DROPDOWN = [
  { label: 'common:yearsExperience.under2Years', value: 'under_2' },
  { label: 'common:yearsExperience.2-5Years', value: '2-5' },
  { label: 'common:yearsExperience.5-10Years', value: '5-10' },
  { label: 'common:yearsExperience.over10Years', value: '10_up' }
];

// Employment Preferences
export const EMPLOYMENT_PREFERENCES_DROPDOWN = [
  { label: 'common:employmentPreferences.compensation', value: 'compensation' },
  {
    label: 'common:employmentPreferences.growthOpportunity',
    value: 'growth_opportunity'
  },
  { label: 'common:employmentPreferences.community', value: 'community' },
  {
    label: 'common:employmentPreferences.mgmtTransparency',
    value: 'mgt_transparency'
  },
  { label: 'common:employmentPreferences.recognition', value: 'recognition' },
  { label: 'common:employmentPreferences.diversity', value: 'diversity' },
  {
    label: 'common:employmentPreferences.missionVisionValues',
    value: 'mission_vision_values'
  }
];

// Commute Preference
export const COMMUTE_PREFERENCES_DROPDOWN = [
  { label: 'common:commutePreferences.options.10Minutes', value: '10_minutes' },
  { label: 'common:commutePreferences.options.30Minutes', value: '30_minutes' },
  { label: 'common:commutePreferences.options.1Hour', value: '1_hour' },
  { label: 'common:commutePreferences.options.2Hours', value: '2_hours' }
];

export const STRICT_DRESS_CODE = 'STRICT_DRESS_CODE';
export const MUST_HAVE_BENEFITS = 'MUST_HAVE_BENEFITS';

// Interview statuses
export const INTERVIEW_STATUS_PENDING = 'pending';
export const INTERVIEW_STATUS_ACCEPTED = 'accepted';
export const INTERVIEW_STATUS_SCHEDULED = 'scheduled';
export const INTERVIEW_STATUS_SECOND_INTERVIEW = 'second-interview';
export const INTERVIEW_STATUS_DECLINED = 'declined';
export const INTERVIEW_STATUS_NOTSCHEDULED = 'notscheduled';
export const INTERVIEW_STATUS_NO_SHOW = 'no-show';
export const INTERVIEW_STATUS_EXPIRED = 'expired';

// TODO expired can rerequest
export const getRequestInterviewString = status =>
  ({
    [INTERVIEW_STATUS_PENDING]: 'common:interview.rerequest',
    [INTERVIEW_STATUS_ACCEPTED]: 'common:interview.rerequest',
    [INTERVIEW_STATUS_DECLINED]: 'common:interview.declined_interview',
    [INTERVIEW_STATUS_NOTSCHEDULED]: 'common:interview.notscheduled',
    [INTERVIEW_STATUS_SCHEDULED]: 'common:interview.rerequest',
    [INTERVIEW_STATUS_NO_SHOW]: 'common:interview.rerequest',
    [INTERVIEW_STATUS_EXPIRED]: 'common:interview.rerequest'
  })[status];

export const OPTIONS_YES_NO_DROPDOWN = t => [
  {
    label: t('common:yes'),
    value: true
  },
  {
    label: t('common:no'),
    value: false
  }
];

export const certs = ['abc_card', 'cicerone', 'serv_safe_certificate'];

export const POSITIONS_SORT_BY_OPTIONS = t => [
  {
    value: 'best',
    label: t('bestMatch')
  },
  {
    value: 'latest',
    label: t('latest')
  }
];

export const OPTIONS_INDUSTRY_SELECTION_RADIO = [
  {
    label: 'common:hotels',
    value: 'hotel'
  },
  {
    label: 'common:restaurants',
    value: 'restaurant'
  }
];

export const LOCATION_PAYMENT_STATUS_SUBSCRIBED = 'SUBSCRIBED';
export const LOCATION_PAYMENT_STATUS_PAUSED = 'PAUSED';
