import React from 'react';

import {
  FOHReactiveModal,
  FOHHeaderText,
  FOHDetailText,
  FOHButton,
  FOHSpace,
  FOHTextField,
  FOHView
} from '../../ingredients';

type voidHandler = () => void;

export interface FOHAddRejectReasonModalProps {
  open: boolean;
  close: voidHandler;
  headerLabel: string;
  detailLabel?: string;
  saveButtonLabel: string;
  onSavePress: voidHandler;
  onTextChange: (text: string) => void;
  textValue: string;
  textPlaceholder?: string;
  leftContentShift?: number;
}

export const FOHAddRejectReasonModal = (
  props: FOHAddRejectReasonModalProps
) => {
  return (
    <FOHReactiveModal
      leftContentShift={props.leftContentShift}
      open={props.open}
      close={props.close}
      dialog={true}
      testID={'FOHAddRejectionReasonModal'}
    >
      <FOHView
        style={{
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <FOHHeaderText>{props.headerLabel}</FOHHeaderText>
        <FOHDetailText>{props.detailLabel}</FOHDetailText>
        <FOHSpace />
        <FOHTextField
          onChangeText={props.onTextChange}
          value={props.textValue}
          placeholder={props.textPlaceholder}
          testID={'FOHAddRejectReasonModal-textfield'}
        />
        <FOHSpace />
        <FOHButton
          onPress={props.onSavePress}
          title={props.saveButtonLabel}
          testID={'FOHAddRejectReasonModal-save-button'}
        />
        <FOHSpace />
      </FOHView>
    </FOHReactiveModal>
  );
};
