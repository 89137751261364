import React from 'react';

import { View } from 'react-native';
import styled from 'styled-components/native';
import { length } from 'ramda';

import {
  FOHSectionLabel,
  FullWidthCard,
  FOHSmallDetailLabel,
  FOHLabel,
  FOHSpace,
  FOHProfileValuesIcon,
  FOHProfileBenefitsIcon,
  FOHProfileDressCodeIcon,
  FOHSpaceSmall,
  FOHBullet,
  FOHEditMarker,
  FOHSpaceSmallest,
  FOHColors,
  FOHTouchableOpacity
} from '../../ingredients';

export interface FOHWorkPreferencesTableProps {
  workPreferencesTableTitle: string;
  employmentPreferencesLabel: string;
  employmentPreferences?: Array<string>;
  benefitsLabel: string;
  benefitsValue: string;
  dressCodeLabel: string;
  dressCodeValue: string;
  openDressCodeEdit?: () => void;
  openBenefitsEdit?: () => void;
  openEmploymentPreferencesEdit?: () => void;
}

export const FOHWorkPreferencesTable = (
  props: FOHWorkPreferencesTableProps
) => (
  <View style={{ maxWidth: 600, width: '100%' }}>
    <FOHSectionLabel
      style={{
        paddingBottom: 8,
        lineHeight: 24,
        color: FOHColors.GRAYSCALE_700
      }}
    >
      {props.workPreferencesTableTitle}
    </FOHSectionLabel>
    <FullWidthCard style={{ marginBottom: 24, padding: 16 }}>
      {(props.employmentPreferences &&
        length(props.employmentPreferences) > 0) ||
      props.openEmploymentPreferencesEdit ? (
        <>
          <SpacedRow>
            <Row>
              <FOHProfileValuesIcon
                style={{
                  tintColor: FOHColors.GRAYSCALE_400,
                  height: 20,
                  width: 14,
                  marginHorizontal: 3
                }}
              />
              <FOHSpaceSmall />
              <FOHSmallDetailLabel
                style={{
                  fontSize: 16,
                  lineHeight: 24,
                  color: FOHColors.GRAYSCALE_400
                }}
              >
                {props.employmentPreferencesLabel}
              </FOHSmallDetailLabel>
            </Row>
            {props.openEmploymentPreferencesEdit ? (
              <FOHTouchableOpacity
                testID={`edit-top-three`}
                onPress={props.openEmploymentPreferencesEdit}
              >
                <FOHEditMarker />
              </FOHTouchableOpacity>
            ) : (
              <></>
            )}
          </SpacedRow>
          <FOHSpaceSmall />
          <View style={{ paddingLeft: 28 }}>
            {props.employmentPreferences ? (
              props.employmentPreferences.map(pref => (
                <View key={pref}>
                  <Row>
                    <FOHBullet
                      color={FOHColors.PRIMARY_TEAL_100}
                      bulletStyle={{ top: 8 }}
                    >
                      <FOHLabel
                        style={{
                          lineHeight: 24,
                          fontWeight: '600',
                          color: FOHColors.GRAYSCALE_700
                        }}
                      >
                        {pref}
                      </FOHLabel>
                    </FOHBullet>
                  </Row>
                  <FOHSpaceSmall />
                </View>
              ))
            ) : (
              <></>
            )}
          </View>
          <FOHSpace />
        </>
      ) : (
        <></>
      )}
      <SpacedRow>
        <Row>
          <FOHProfileBenefitsIcon
            style={{
              tintColor: FOHColors.GRAYSCALE_400,
              width: 16,
              height: 20,
              marginHorizontal: 2
            }}
          />
          <FOHSpaceSmall />
          <FOHSmallDetailLabel
            style={{
              fontSize: 16,
              lineHeight: 24,
              color: FOHColors.GRAYSCALE_400
            }}
          >
            {props.benefitsLabel}
          </FOHSmallDetailLabel>
        </Row>
        {props.openBenefitsEdit ? (
          <FOHTouchableOpacity
            testID={`edit-benefits`}
            onPress={props.openBenefitsEdit}
          >
            <FOHEditMarker />
          </FOHTouchableOpacity>
        ) : (
          <></>
        )}
      </SpacedRow>
      <FOHSpaceSmall />
      <FOHLabel
        style={{
          paddingLeft: 28,
          lineHeight: 24,
          fontWeight: '600',
          color: FOHColors.GRAYSCALE_700
        }}
      >
        {props.benefitsValue}
      </FOHLabel>
      <FOHSpaceSmallest />
      <FOHSpace />
      <SpacedRow>
        <Row>
          <FOHProfileDressCodeIcon
            style={{ tintColor: FOHColors.GRAYSCALE_400, marginHorizontal: 2 }}
          />
          <FOHSpaceSmall />
          <FOHSmallDetailLabel
            style={{
              fontSize: 16,
              lineHeight: 24,
              color: FOHColors.GRAYSCALE_400
            }}
          >
            {props.dressCodeLabel}
          </FOHSmallDetailLabel>
        </Row>
        {props.openDressCodeEdit ? (
          <FOHTouchableOpacity
            testID={`edit-dresscode`}
            onPress={props.openDressCodeEdit}
          >
            <FOHEditMarker />
          </FOHTouchableOpacity>
        ) : (
          <></>
        )}
      </SpacedRow>
      <FOHSpaceSmall />
      <FOHLabel
        style={{
          paddingLeft: 28,
          lineHeight: 24,
          fontWeight: '600',
          color: FOHColors.GRAYSCALE_700
        }}
      >
        {props.dressCodeValue}
      </FOHLabel>
    </FullWidthCard>
  </View>
);

const Row = styled(View)`
  flex-direction: row;
  align-items: center;
`;

const SpacedRow = styled(View)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
