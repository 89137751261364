import React from 'react';
import styled from 'styled-components';

import { FOHHeaderText } from '../../../components';
import { useIsMobile } from '../../../components/spices';

const LightBoxedVideo = styled.video`
  ::-webkit-media-controls-mute-button,
  ::-webkit-media-controls-start-playback-button,
  ::-webkit-media-controls-fullscreen-button {
    display: none;
  }

  height: ${props => (props.isMobile ? '523px' : '600px')};
  width: ${props => (props.isMobile ? '100%' : '600px')};
  object-fit: ${props => (props.isMobile ? 'contain' : 'cover')};
`;

export const LightboxedMainImage = ({ isCurrentImgVideo, src }) => {
  const isMobile = useIsMobile();
  return isCurrentImgVideo ? (
    <>
      <LightBoxedVideo
        controls
        controlsList="nodownload nofullscreen noremoteplayback noplaybackrate"
        disablePictureInPicture
        isMobile={isMobile}
        src={src}
      />

      <FOHHeaderText
        style={{
          color: 'white',
          fontSize: 24,
          left: 24,
          position: 'absolute',
          top: 16
        }}
      >
        {src.split('/').at(-1)}
      </FOHHeaderText>
    </>
  ) : (
    <img
      alt="CareerPage gallery media asset"
      src={src}
      style={{
        height: isMobile ? 523 : 600,
        objectFit: isMobile ? 'contain' : 'cover',
        width: isMobile ? '100%' : 600
      }}
    />
  );
};
