import React, { useState, Fragment, useEffect } from 'react';

import { prop } from 'ramda';
import { Image, Text } from 'react-native';
import styled from 'styled-components/native';

import {
  Card,
  FOHSpace,
  FOHSpaceSmall,
  FOHSpaceLarge,
  FOHView,
  FOHSmallDetailLabel,
  FOHHighlightText,
  FOHLabel,
  FOHMultiLineTextField,
  FOHButton,
  FOHColors,
  FOHFonts
} from '../../ingredients';

import { FOHForm } from './FOHForm';

export interface FOHEmployerCandidateQuestionnaireProps {
  onSubmit: () => void;
  disabledSubmit: boolean;
  submitBtnLabel: string;

  image: string;
  headerText: string;
  highLightedHeaderText: string;
  detailText: string;

  positionLabel: string;
  position: string;
  locationLabel: string;
  locationImage: string;
  locationName: string;
  locationAddress: string;
  textFieldPlaceholder: string;

  questions: Array<{
    questionText: string;
    questionId: string;
  }>;
  answers: Array<{
    questionId?: string;
    questionText?: string;
    answer: string;
  }>;
  onInputChange: (answeredQues: any) => void;

  isMobile?: boolean;
  errors?: any;
}

export const FOHEmployerCandidateQuestionnaire = (
  props: FOHEmployerCandidateQuestionnaireProps
) => {
  const [answerValues, setAnswerValues] = useState<Array<string>>([]);

  const updateAnswerValues = (newValue: string, idx: number) => {
    setAnswerValues(
      answerValues.map((answer: string, i: number) => {
        if (i === idx) {
          return newValue;
        } else {
          return answer;
        }
      })
    );
  };

  useEffect(() => {
    if (props.questions.length > 0) {
      setAnswerValues(new Array(props.questions.length).fill(''));
    }
  }, [props?.questions?.length]);

  return (
    <FOHEmployerCandidateQuestionnaireCard>
      <FOHForm
        handleSubmit={props.onSubmit}
        disabledSubmit={props.disabledSubmit}
      >
        <FormContainer mobile={props.isMobile}>
          <TopBlockContainer mobile={props.isMobile}>
            <ImageContainer mobile={props.isMobile}>
              <FOHImage
                mobile={props.isMobile}
                resizeMode="contain"
                source={{ uri: props.image }}
              />
              {!!prop('isMobile', props) && (
                <>
                  <FOHSpace />
                  <FOHHighlightText
                    labelCmp={MobileHeaderText}
                    emphasizedWord={props.highLightedHeaderText}
                    highlightColor={FOHColors.PACIFIC_BLUE}
                    boldLabelCmp={MobileHeaderText}
                  >
                    {props.headerText}
                  </FOHHighlightText>
                </>
              )}
            </ImageContainer>
            {prop('isMobile', props) ? <FOHSpace /> : <FOHSpaceLarge />}
            <TopBlock mobile={props.isMobile}>
              {!prop('isMobile', props) && (
                <>
                  <FOHHighlightText
                    labelCmp={HeaderText}
                    emphasizedWord={props.highLightedHeaderText}
                    highlightColor={FOHColors.PACIFIC_BLUE}
                    boldLabelCmp={HeaderText}
                  >
                    {props.headerText}
                  </FOHHighlightText>
                  <FOHSpace />
                </>
              )}
              <LabelBlock>
                <DetailText mobile={props.isMobile}>
                  {props.detailText}
                </DetailText>
              </LabelBlock>
              <FOHSpace />
              <PositionLocationBlock mobile={props.isMobile}>
                <Field>
                  <SmallGreyLabel>{props.positionLabel}</SmallGreyLabel>
                  <FOHSpaceSmall />
                  <Label>{props.position}</Label>
                </Field>
                <FOHSpace />
                <Field>
                  <SmallGreyLabel>{props.locationLabel}</SmallGreyLabel>
                  <FOHSpaceSmall />
                  <FOHView
                    style={{
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      paddingTop: 6,
                      paddingBottom: 6
                    }}
                  >
                    <Image
                      source={{ uri: props.locationImage }}
                      style={{ width: 40, height: 40, borderRadius: 3 }}
                    />
                    <FOHView style={{ paddingLeft: 6 }} />
                    <FOHView style={{ flex: 1 }}>
                      <FOHName>{props.locationName}</FOHName>
                      <SmallGreyLabel>{props.locationAddress}</SmallGreyLabel>
                    </FOHView>
                  </FOHView>
                </Field>
              </PositionLocationBlock>
            </TopBlock>
          </TopBlockContainer>
          <FOHSpace />
          <FormBlock mobile={props.isMobile}>
            <FOHSpaceSmall />
            {props?.questions?.length > 0 &&
              props.questions.map((que, keyIndex) => (
                <Fragment key={keyIndex}>
                  <FormRow key={keyIndex}>
                    <FOHLabel>
                      <>{`${keyIndex + 1}.`}</>
                      <FOHSpaceSmall />
                      {que?.questionText}
                    </FOHLabel>
                    <FOHSpace />
                    <FOHView style={{ width: '100%' }}>
                      <FOHMultiLineTextField
                        max={500}
                        placeholder={props.textFieldPlaceholder}
                        required={true}
                        onChangeText={(value: string) => {
                          props.onInputChange({ ...que, answer: value });
                          updateAnswerValues(value, keyIndex);
                        }}
                        error={
                          props.errors && props.errors[que?.questionId]
                            ? props.errors[que?.questionId]
                            : null
                        }
                        value={answerValues[keyIndex]}
                      />
                    </FOHView>
                  </FormRow>
                  <FOHSpaceLarge />
                </Fragment>
              ))}
          </FormBlock>
          <FOHSpaceLarge />
          <ButtonContainer>
            <FOHButton
              onPress={props.onSubmit}
              title={props.submitBtnLabel}
              disabled={props.disabledSubmit}
              color={
                props.disabledSubmit ? FOHColors.SILVER : FOHColors.PACIFIC_BLUE
              }
              textColor={FOHColors.WHITE}
            />
          </ButtonContainer>
        </FormContainer>
      </FOHForm>
    </FOHEmployerCandidateQuestionnaireCard>
  );
};

const FOHEmployerCandidateQuestionnaireCard = styled(Card)`
  max-width: 964px;
  min-width: 48%;
`;

const FormContainer = styled(FOHView)<{ mobile?: boolean }>`
  align-items: flex-start;
  justify-content: flex-start;
  padding: 24px;
  padding: ${(p: any) => (p.mobile ? '12px' : '24px')};
  width: 100%;
`;

const TopBlockContainer = styled(FOHView)<{ mobile?: boolean }>`
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: ${(p: any) => (p.mobile ? 'column' : 'row')};
  flex-wrap: wrap;
`;

const ImageContainer = styled(FOHView)<{ mobile?: boolean }>`
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: ${(p: any) => (p.mobile ? 'row' : 'column')};
`;

const FOHImage = styled(Image)<{ mobile?: boolean }>`
  width: ${(p: any) => (p.mobile ? '80px' : '168px')};
  height: ${(p: any) => (p.mobile ? '80px' : '168px')};
  borderwidth: 1;
  border-color: #eeeeee;
`;

const TopBlock = styled(FOHView)<{ mobile?: boolean }>`
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-left: ${(p: any) => (p.mobile ? '0px' : '8px')};
  flex: ${(p: any) => (p.mobile ? 'none' : '1')};
`;

const LabelBlock = styled(FOHView)`
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 454px;
  width: 100%;
`;

const PositionLocationBlock = styled(FOHView)<{ mobile?: boolean }>`
  flex-direction: ${(p: any) => (p.mobile ? 'column' : 'row')};
  justify-content: ${(p: any) => (p.mobile ? 'flex-start' : 'space-between')};
  display: flex;
  flexwrap: wrap;
  width: 100%;
`;

const MobileHeaderText = styled(Text)`
  font-family: ${FOHFonts.REGULAR};
  font-weight: 600;
  font-style: normal;
  font-size: 24px;
  line-height: 26px;
  color: ${FOHColors.NARO_GREY};
`;

const HeaderText = styled(Text)`
  font-family: ${FOHFonts.REGULAR};
  font-weight: 600;
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
  color: ${FOHColors.NARO_GREY};
`;

const DetailText = styled(FOHSmallDetailLabel)<{ mobile?: boolean }>`
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: ${(p: any) => (p.mobile ? 'left' : 'justify')};
  color: ${FOHColors.GREY};
`;

const Field = styled(FOHView)``;

const SmallGreyLabel = styled(FOHSmallDetailLabel)`
  text-transform: uppercase;
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: ${FOHColors.GREY};
`;

const Label = styled(Text)`
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #17abcc;
`;

const FOHName = styled(FOHSmallDetailLabel)`
  line-height: 16px;
  font-weight: 500;
  color: ${FOHColors.NARO_GREY};
`;

const FormBlock = styled(FOHView)<{ mobile?: boolean }>`
  padding: ${(p: any) => (p.mobile ? '16px' : '32px')};
  width: 100%;
  background: #fcfcfc;
  border-radius: 20px;
`;

const FormRow = styled(FOHView)`
  align-items: flex-start;
  justify-content: flex-start;
`;

const ButtonContainer = styled(FOHView)`
  align-items: center;
  justify-content: center;
  width: 100%;
`;
