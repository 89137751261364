import React from 'react';

import styled from 'styled-components/native';

import {
  FOHMessageTemplateBlue,
  FOHLabel,
  FOHColors,
  FOHView,
  FOHTouchableOpacity
} from '../../ingredients';

export interface FOHSelectTemplateDefaultCellProps {
  labelPlaceholder: string;
  style?: any;
  onKeyPress: (e: any) => void;
}

export const FOHSelectTemplateDefaultCell = (
  props: FOHSelectTemplateDefaultCellProps
) => (
  <FOHTouchableOpacity onPress={props.onKeyPress}>
    <Container style={{ ...props.style }}>
      <FOHView>
        <FOHMessageTemplateBlue
          style={{ tintColor: FOHColors.GRAYSCALE_400 }}
        />
      </FOHView>
      <FOHLabel
        style={{
          marginLeft: 8,
          color: FOHColors.GRAYSCALE_500,
          fontWeight: 400,
          lineHeight: 18
        }}
      >
        {props.labelPlaceholder}
      </FOHLabel>
    </Container>
  </FOHTouchableOpacity>
);

const Container = styled(FOHView)`
  background: #ffffff;
  border: 1px solid #e4e7ec;
  border-radius: 6px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  flex-direction: row;
  padding: 10px;
`;
