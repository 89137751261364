import React from 'react';

import {
  FOHSpaceSmall,
  FOHSpace,
  FOHNotificationIcon,
  FOHButton,
  FOHButtonBordered,
  FOHView,
  FOHColors
} from '../../ingredients';

import { FOHIconTextEditCell } from './FOHIconTextEditCell';

export interface FOHRejectionEditCellProps {
  onChangeLabel: (val: any) => void;
  onSavePress: (val: any) => void;
  onCancelPress: (val: any) => void;
  labelValue: string;
  labelPlaceholder: string;
  saveButtonLabel: string;
  cancelButtonLabel: string;
  label: string;
  noIcon?: boolean;
}

export const FOHRejectionEditCell = (props: FOHRejectionEditCellProps) => {
  return (
    <FOHView
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: 6,
        flexWrap: 'wrap',
        padding: 24,
        paddingTop: 16,
        paddingBottom: 16,
        backgroundColor: '#FCFCFC'
      }}
    >
      <FOHView
        style={{
          flexDirection: 'row',
          flex: 1,
          flexWrap: 'wrap',
          alignItems: 'center'
        }}
      >
        <FOHNotificationIcon backgroundColor={FOHColors.PRIMARY_TEAL_P1} />
        <FOHSpace />
        <FOHIconTextEditCell
          noIcon={props.noIcon}
          onChangeLabel={props.onChangeLabel}
          labelValue={props.labelValue}
          labelPlaceholder={props.labelPlaceholder}
          style={{ height: 40 }}
          inputStyle={{ fontSize: 16, lineHeight: 24 }}
        />
      </FOHView>
      <FOHView style={{ flexDirection: 'row', alignItems: 'center' }}>
        <FOHButtonBordered
          style={{
            height: 40,
            width: 80
          }}
          color={FOHColors.WHITE}
          title={props.cancelButtonLabel}
          onPress={props.onCancelPress}
        />
        <FOHSpaceSmall />
        <FOHButton
          style={{
            height: 40,
            width: 80
          }}
          title={props.saveButtonLabel}
          onPress={props.onSavePress}
        />
      </FOHView>
    </FOHView>
  );
};
