import React from 'react';

import { StyleProp, ViewStyle } from 'react-native';

import { useClickOutside } from '../../spices';

import { FOHColors } from '../colors/FOHColors';
import { FOHView, FullWidthCard } from '../containers/Containers';
import { FOHMoreOptionIcon } from '../icons/FOHMoreOptionIcon';

import { FOHIconButton } from './FOHIconButton';

export interface FOHIconDropdownButtonProps {
  testID?: string;
  isOpen: boolean;
  toggleIsOpen: () => void;
  children: React.ReactNode;
  style?: StyleProp<ViewStyle>;
}

export const FOHIconDropdownButton = (props: FOHIconDropdownButtonProps) => {
  const [ref] = useClickOutside({
    close: () => !!props.isOpen && props.toggleIsOpen && props.toggleIsOpen()
  });

  return (
    <FOHView style={{ width: '100%' }} ref={ref}>
      <FOHIconButton
        onPress={props.toggleIsOpen}
        icon={() => (
          <FOHMoreOptionIcon
            style={{
              tintColor: props.isOpen
                ? FOHColors.GRAYSCALE_900
                : FOHColors.GRAYSCALE_400
            }}
          />
        )}
        style={{
          height: 52,
          borderRadius: 4,
          width: '100%',
          flexDirection: 'row'
        }}
        testID={props.testID}
      />
      {props.isOpen ? (
        <FullWidthCard
          style={{
            padding: 24,
            position: 'absolute',
            top: 56,
            width: 293,
            left: -255,
            maxHeight: '65vh'
          }}
          noBorder
          shadow
          testID={`${props.testID}-menu`}
        >
          {props.children}
        </FullWidthCard>
      ) : (
        <></>
      )}
    </FOHView>
  );
};
