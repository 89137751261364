import React from 'react';

import { path, prop, defaultTo } from 'ramda';
import { useTranslation } from 'react-i18next';

import {
  FOHButton,
  FOHSpace,
  FOHLabel,
  FOHLabelBold,
  FOHView,
  FullWidthCard,
  FOHLocationOverview
} from '../../components';
import {
  useGetFeedLocationQuery,
  useGetInterviewScheduleQuery
} from '../../graphql/generated';
import { formatFollowerCountLabel, toDateTime } from '../../utils';
import { RESTAURANT_JOBS } from '../../constants';
import { useNavigation } from '../../utils/navigation';

// Render in conversation
export const SBLocationCard = ({
  slug

  // message,
}) => {
  const locationQuery = useGetFeedLocationQuery({ variables: { slug } });
  const { navigateTo } = useNavigation();
  const location = path(['data', 'location'], locationQuery);
  const { t } = useTranslation();
  // _sender
  return (
    <FullWidthCard
      style={{
        maxWidth: 336,
        padding: 24
      }}
      shadow={true}
    >
      <FOHLocationOverview
        largePhoto={true}
        id={prop('id', location)}
        name={prop('name', location)}
        formattedAddress={prop('formattedAddress', location)}
        image={prop('image', location)}
        followerCountLabel={formatFollowerCountLabel({
          followerCount: prop('followerCount', location),
          t
        })}
        tags={prop('tags', location)}
      />
      <FOHButton
        title="view jobs"
        onPress={() => {
          navigateTo(`${RESTAURANT_JOBS}/${slug}`);
        }}
      />
    </FullWidthCard>
  );
};

export const SBInterviewCard = ({ interviewUUID }) => {
  const interviewRequestQuery = useGetInterviewScheduleQuery({
    variables: {
      uuid: interviewUUID,
      token: ''
    }
  });
  const interview = path(['data', 'interview'], interviewRequestQuery);

  return (
    <FOHView>
      <FOHLabelBold>
        <>{`Interview ${prop('status', interview)} `}</>
        <FOHLabel>
          {toDateTime(defaultTo('', prop('scheduledFor', interview)))}
        </FOHLabel>
      </FOHLabelBold>
      <FOHSpace />
    </FOHView>
  );
};
