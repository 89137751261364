import React from 'react';

import { defaultTo, prop } from 'ramda';
import styled from 'styled-components/native';
import { View } from 'react-native';

import { FOHDivider, FOHSpaceSmall } from '../../ingredients';

import { FOHSelectionCell } from '../cells/FOHSelectionCell';

export interface FOHSelectionTableProps {
  items: Array<FOHSelectionItem>;
  selected: Array<string>;
  selectItem: (value: string) => void;
  selectAll?: () => any;
  allSelected?: boolean;
  selectAllLabel?: string;
  checkboxes?: boolean;
  bordered?: boolean;
}

export interface FOHSelectionItem {
  label: string;
  value: string;
}

export const FOHSelectionTable = (props: FOHSelectionTableProps) => (
  <TableContainer>
    {props.selectAll && !!props.selectAllLabel ? (
      <>
        <FOHSelectionCell
          value={'select-all'}
          onPress={props.selectAll}
          label={props.selectAllLabel}
          selected={!!props.allSelected}
          key={`select-all`}
          checkboxes={props.checkboxes}
          bordered={props.bordered}
        />
        <FOHSpaceSmall />
        <FOHDivider />
        <FOHSpaceSmall />
      </>
    ) : (
      <></>
    )}
    {defaultTo([], props.items as any).map((item: any) => (
      <FOHSelectionCell
        key={prop('value', item)}
        value={prop('value', item)}
        onPress={props.selectItem}
        label={prop('label', item)}
        selected={
          props.selected && props.selected.includes(prop('value', item))
        }
        checkboxes={props.checkboxes}
        bordered={props.bordered}
      />
    ))}
  </TableContainer>
);

const TableContainer = styled(View)``;
