import { path, defaultTo } from 'ramda';

import { getStripeSession } from '../../api/stripePayment';

// Only availble after withPayment
export const useCheckout = props => {
  const redirectToStripeCheckout = async ({
    url,
    success_url,
    cancel_url,
    // Array of position row ids
    positions,
    // Array of location row ids
    locations,
    // payasyougo || unlimited
    plan,
    // monthly || annual
    cycle
  }) => {
    await getStripeSession({
      url: defaultTo('', url).split('?')[0],
      success_url: success_url
        ? defaultTo('', success_url).split('&')[0]
        : undefined,
      cancel_url: cancel_url ? defaultTo('', cancel_url) : undefined,
      positions: defaultTo([], positions).join(','),
      locations: defaultTo([], locations).join(','),
      plan,
      cycle
    })
      .then(async sessionResponse => {
        const sessionId = path(['stripe_session', 'id'], sessionResponse);
        await props.stripe.redirectToCheckout({
          sessionId
        });
      })
      .catch(_error => {
        throw new Error(_error);
      });
  };

  const redirectToStripeCheckoutForBoost = async ({
    url,
    position,
    daysToBoost,
    campaignId = ''
  }) => {
    // Stripe Session for boosting posts on Zip Recruiter, one off payments
    const sessionResponse = await getStripeSession({
      url,
      positions: `${position}`,
      daysToBoost,
      campaignId
    });
    const sessionId = path(['stripe_session', 'id'], sessionResponse);
    await props.stripe.redirectToCheckout({
      sessionId
    });
  };
  return {
    redirectToStripeCheckout,
    redirectToStripeCheckoutForBoost
  };
};
