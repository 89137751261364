import React, { useEffect, useState } from 'react';

import { path } from 'ramda';

import { storeAuth, getAuth, handleMutation } from '../../utils';
import { APPLICATIONS, HOME_PAGE, LOGIN_URL } from '../../constants';
import { logout } from '../../api/auth';
import {
  useGetMeQuery,
  useMutateClaimTokenSignInMutation,
  useMutateMergeCandidatesMutation
} from '../../graphql/generated';
import { useNavigation } from '../../utils/navigation';

import { getSearchQuery } from '../Search';
import { LogoLoader } from '../Loading/LogoLoader';

const ClaimAccountScreen = () => {
  console.log('claimAccount');
  const { navigateTo, location: navLocation } = useNavigation();

  const [claimTokenSignInMutation] = useMutateClaimTokenSignInMutation();
  const [mergeIndeedCandidatesMutation] = useMutateMergeCandidatesMutation();
  const [uid, setUid] = useState('');
  const [claimToken, setClaimToken] = useState('');
  const [redirectPath, setRedirectPath] = useState(LOGIN_URL);
  const meQuery = useGetMeQuery({
    // revisit this skip logic , why is it needed?
    skip: true
  });

  useEffect(() => {
    setUid(getSearchQuery(navLocation, 'uid'));
    setClaimToken(getSearchQuery(navLocation, 'token'));
    setRedirectPath(getSearchQuery(navLocation, 'next'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navLocation]);

  const signIn = async (_uid, _claimToken, _redirectPath) => {
    const queryEmail = getSearchQuery(navLocation, 'email');
    const oldAuth = getAuth();

    if (queryEmail && queryEmail.includes('@indeedemail') && oldAuth) {
      console.log('merge indeed candidates path', queryEmail);
      // API Call MergeCandidateProfiles
      const [resp, error] = await handleMutation(
        mergeIndeedCandidatesMutation({
          variables: { indeedEmail: queryEmail }
        })
      );

      const auth = path(['mergeCandidatesMutation', 'authorization'], resp);
      // set to a valid token
      if (auth && !error) {
        console.log('merge indeed candidates path, setting auth and no error');
        // In case they are logged in as a different account, log them out and refetch.
        await logout();
        storeAuth({ token: auth });
        if (meQuery) {
          await meQuery.refetch();
          return navigateTo(_redirectPath);
        }
      }
      console.log(
        'merge indeed candidates path error - sending to login',
        error
      );
      if (oldAuth && error && error?.message?.includes('Duplicate entry')) {
        return navigateTo(`${HOME_PAGE}${APPLICATIONS}`);
      }
      return navigateTo(LOGIN_URL);
    }

    const [res, error] = await handleMutation(
      claimTokenSignInMutation({
        variables: {
          uid: _uid,
          claimToken: _claimToken
        }
      })
    );

    if (error || !res) {
      console.log('magic link path error', error);
      return navigateTo(LOGIN_URL);
    }
    const auth = path(['claimTokenSignInMutation', 'authorization'], res);
    if (auth) {
      console.log('magic link path no error, setting auth token');
      // In case they are logged in as a different account, log them out and refetch.
      await logout();
      storeAuth({ token: auth });
      if (meQuery) {
        await meQuery.refetch();
        console.log('refetching user');
        navigateTo(_redirectPath);
      } else {
        console.log('could not refetch user redirecting to login');
        navigateTo(LOGIN_URL);
      }
    }
  };

  useEffect(() => {
    if (uid && claimToken) {
      signIn(uid, claimToken, redirectPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid, claimToken, redirectPath]);

  return <LogoLoader />;
};

export default ClaimAccountScreen;
