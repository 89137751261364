import React from 'react';

import styled from 'styled-components/native';

import {
  FOHView,
  FOHHeaderH3,
  FOHDetailText,
  FOHSpace,
  FOHButton,
  FOHReactiveModal,
  FOHMultiLineTextField
} from '../../ingredients';

import { FOHPositionOverview } from '../blocks/FOHPositionOverview';
import {
  FOHSelectionTable,
  FOHSelectionTableProps
} from '../tables/FOHSelectionTable';

export interface FOHCancelReasonModalProps extends FOHSelectionTableProps {
  open?: boolean;
  close: () => void;
  dialog?: boolean;

  headerLabel: string;
  subHeaderLabel: string;

  // location
  locationImage?: string;
  positionName?: string;
  location?: string;

  feedback: string;
  inputPlaceholder: string;
  setFeedback: (text: string) => void;

  submitBtnLabel: string;
  onPressSubmitBtn: () => void;
  disablSubmit?: boolean;
  hideTextField?: boolean;
  leftContentShift?: number;

  error?: string;
}

export const FOHCancelReasonModal = (props: FOHCancelReasonModalProps) => (
  <FOHReactiveModal
    leftContentShift={props.leftContentShift}
    dialog={props.dialog}
    open={props.open}
    close={props.close}
    testID={'FOHCancelReasonModal'}
  >
    <FOHView
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        padding: props.dialog ? 20 : 14
      }}
    >
      <FOHHeaderH3>{props.headerLabel}</FOHHeaderH3>
      <FOHSubHeader>{props.subHeaderLabel}</FOHSubHeader>
      <FOHSpace />
      {props.positionName ? (
        <FOHPositionOverview
          padding={15}
          memoImageStyle={{
            width: 40,
            height: 40
          }}
          headerStyle={{ fontSize: 14, lineHeight: 20 }}
          positionName={props.positionName}
          // location
          locationImage={props.locationImage ? props.locationImage : ''}
          locationDetailText={props.location}
          locationName={''}
          locationHref={''}
          locationSlug={''}
          onPressLocation={_slug => {}}
        />
      ) : (
        <></>
      )}
      <FOHSpace />
      <FOHView style={{ width: '100%' }}>
        <FOHSelectionTable {...props} checkboxes={true} />
      </FOHView>
      <FOHSpace />
      {!props.hideTextField && (
        <FOHView style={{ flex: 1, width: '100%' }}>
          <FOHMultiLineTextField
            onChangeText={props.setFeedback}
            value={props.feedback}
            placeholder={props.inputPlaceholder}
            max={1000}
            error={props.error || null}
            required={true}
          />
        </FOHView>
      )}
      <FOHSpace />
      <FOHSpace />
      <FOHButton
        title={props.submitBtnLabel}
        disabled={props.disablSubmit}
        onPress={props.onPressSubmitBtn}
      />
    </FOHView>
  </FOHReactiveModal>
);

const FOHSubHeader = styled(FOHDetailText)`
  font-size: 14px;
  line-height: 24px;
`;
