import React from 'react';

import { defaultTo, prop } from 'ramda';

import filter_on from '../../../images/filter_on.svg';
import filter_off from '../../../images/filter_off.svg';

import { FOHImage } from '../../ingredients';

export interface FOHFilterIconProps {
  active?: boolean;
  style?: any;
}

export const FOHFilterIcon = (props: FOHFilterIconProps) => (
  <FOHImage
    source={{ uri: props.active ? filter_on : filter_off }}
    style={{ width: 16, height: 16, ...defaultTo({}, prop('style', props)) }}
    resizeMode={'contain'}
  />
);
