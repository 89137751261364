import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/native';
import { prop } from 'ramda';

import {
  FOHBannerType,
  FOHCheckbox,
  FOHColors,
  FOHEnterpriseLaptopIcon,
  FOHFonts,
  FOHLabel,
  FOHRedX,
  FOHRequiredFieldBullet,
  FOHScrollView,
  FOHSearchBar,
  FOHSpace,
  FOHSpaceLarge,
  FOHSpaceSmallest,
  FOHTextButton,
  FOHTouchableOpacity,
  FOHView,
  FOHTextField,
  SPACING_SCALE,
  FOHDivider,
  FOHSmallDetailLabel,
  FOHFormFlowButtons
} from '../../../components';
import { createSlugFromString } from '../utils';
import {
  useBanner,
  withReactiveModal,
  WithReactiveModalProps
} from '../../Navigation';
import { useCandidateFeedContext } from '../../CandidateFeed/useCandidateFeedContext';
import { useCreateCareerPageMutation } from '../../../graphql/generated';
import { handleMutation } from '../../../utils';
import { CAREER_PAGE_DASHBOARD } from '../../../constants';
import { useNavigation } from '../../../utils/navigation';
import { FOHAvatarsOverlayOrImageWithHeader } from '../../CandidateFeed/components';

interface CreateNewCareerPageModalProps extends WithReactiveModalProps {
  testID: string;
  isRestrictedExceptAdmin: boolean;
}

const CreateNewCareerPageModal: FC<CreateNewCareerPageModalProps> = ({
  close,
  isRestrictedExceptAdmin,
  testID
}) => {
  const { t } = useTranslation('CareerPage');

  const [pageTitle, setPageTitle] = useState<string>('');
  const [pageSlug, setPageSlug] = useState<string>('');

  const [createCareerPageMutation] = useCreateCareerPageMutation();

  const {
    clearLocationFilters,
    locationFilters,
    locationSearch,
    multiSelectLocation,
    myLocations,
    selectAllLocations,
    setLocationSearch
  } = useCandidateFeedContext();
  const { setBanner } = useBanner();

  const { navigateTo } = useNavigation();

  const locationOptions = myLocations.map((location: any) => ({
    label: prop('displayName', location),
    sublabel: prop('formattedAddress', location),
    value: prop('id', location)
  }));

  const handleOnCreate = async () => {
    const [response, error] = await handleMutation(
      createCareerPageMutation({
        variables: {
          selectedLocations: locationFilters,
          slug: pageSlug,
          title: pageTitle
        }
      })
    );

    if (error || !response) {
      setBanner({
        bannerMessage: error?.message || (t('createErrorBanner') as string),
        bannerType:
          FOHBannerType.DANGER as unknown as keyof typeof FOHBannerType
      });
      return;
    }

    setBanner({
      bannerMessage: t('createSuccessBanner') as string,
      bannerType: FOHBannerType.SUCCESS as unknown as keyof typeof FOHBannerType
    });

    close && close();
  };

  useEffect(() => {
    // NAVIGATE BACK TO CAREER DASHBOARD IF isRestrictedExceptAdmin is TRUE
    if (isRestrictedExceptAdmin) {
      navigateTo(CAREER_PAGE_DASHBOARD, { replace: true });
    }

    // CLEAR OUT LOCATIONS FILTERS ON PAGE MOUNT
    clearLocationFilters();

    // CLEAR OUT ON PAGE UNMOUNT
    return () => clearLocationFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FOHView style={{ width: '100%' }} testID={testID}>
      <FOHAvatarsOverlayOrImageWithHeader
        HeaderImage={() => (
          <FOHEnterpriseLaptopIcon style={{ height: 82, width: 72 }} />
        )}
        title={t('createNewCareerPageLabel')}
      />
      <FOHView style={{ paddingHorizontal: 40, width: '100%' }}>
        <FOHView
          style={{
            backgroundColor: FOHColors.GRAYSCALE_10,
            borderRadius: 24,
            marginVertical: 24,
            padding: 32,
            zIndex: 2
          }}
        >
          <Row>
            <FOHRequiredFieldBullet reverse>
              <FOHLabel
                style={{
                  fontWeight: 600,
                  lineHeight: 18
                }}
              >
                {t('careerPageTitle')}
              </FOHLabel>
            </FOHRequiredFieldBullet>
            <FOHRequiredFieldBullet>
              <FOHSmallDetailLabel
                style={{
                  color: FOHColors.RED_300,
                  fontSize: 12,
                  fontWeight: 600,
                  lineHeight: 16
                }}
              >
                {t('requiredFieldMessageForEditPageSlug')}
              </FOHSmallDetailLabel>
            </FOHRequiredFieldBullet>
          </Row>
          <FOHSpace />
          <Row>
            <FOHView style={{ width: '49%' }}>
              <FOHTextField
                onChangeText={(value: string) => {
                  setPageTitle(value);

                  const slug = createSlugFromString(value);
                  setPageSlug(slug);
                }}
                placeholder={t('pageTitleLabel')}
                required={true}
                value={pageTitle || ''}
              />
            </FOHView>
            <FOHSpace />
            <FOHView style={{ width: '49%' }}>
              <FOHTextField
                onChangeText={(value: string) => setPageSlug(value)}
                placeholder={t('pageSlugLabel')}
                required={true}
                value={pageSlug || ''}
              />
            </FOHView>
          </Row>
          <FOHSpaceLarge />
          <Row>
            <FOHRequiredFieldBullet reverse>
              <FOHLabel
                style={{
                  fontWeight: 600,
                  lineHeight: 18
                }}
              >
                {t('whatLocationLikeToInclude')}
              </FOHLabel>
            </FOHRequiredFieldBullet>
          </Row>
          <FOHSpace />
          <Row>
            <FOHSearchBar
              inputStyle={{
                height: 'fit-content',
                lineHeight: 1
              }}
              onChangeSearch={(text: string) => setLocationSearch(text)}
              searchPlaceholder={t('findYourLocationsLabel')}
              searchValue={locationSearch || ''}
              style={{
                height: 52
              }}
            />
          </Row>
          <FOHSpaceLarge />
          <Row>
            <FOHView
              style={{
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%'
              }}
            >
              <FOHView
                style={{
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'flex-start'
                }}
              >
                <FOHTouchableOpacity
                  onPress={e => {
                    e.preventDefault();
                    selectAllLocations();
                  }}
                  style={{ alignItems: 'center', flexDirection: 'row' }}
                  testID="FOHCheckBox-Select All"
                >
                  <FOHCheckbox
                    selected={locationOptions.length === locationFilters.length}
                  />
                  <FOHSmallDetailLabel
                    style={{
                      color: FOHColors.GRAYSCALE_700,
                      fontSize: 16,
                      lineHeight: 24,
                      marginLeft: SPACING_SCALE.regular
                    }}
                  >
                    {t('selectAllLocationsLabel')}
                  </FOHSmallDetailLabel>
                </FOHTouchableOpacity>
                <FOHSpaceSmallest />
                <FOHSmallDetailLabel testID="CreateNewCareerModal-locations-count">{`( ${
                  locationFilters.length
                } ${t('of')} ${locationOptions.length} )`}</FOHSmallDetailLabel>
              </FOHView>
              <FOHView style={{ alignItems: 'center' }}>
                <FOHTextButton
                  disabled={false}
                  icon={() => <FOHRedX style={{ height: 8, width: 8 }} />}
                  onPress={clearLocationFilters}
                  style={{
                    color: FOHColors.RED_300,
                    fontFamily: FOHFonts.REGULAR,
                    fontSize: 16,
                    fontWeight: 700,
                    lineHeight: 24
                  }}
                  title={t('deselectAllLabel')}
                  underline
                />
              </FOHView>
            </FOHView>
          </Row>
          <FOHSpace />
          <Row>
            <FOHDivider />
          </Row>
          <Row>
            <FOHScrollView style={{ maxHeight: 273, paddingVertical: 10 }}>
              {locationOptions?.length > 0 &&
                locationOptions.map((option: any, index: number) => (
                  <FOHTouchableOpacity
                    key={`option-${option.value}_${index + 1}`}
                    onPress={() => multiSelectLocation(option.value)}
                    style={{
                      backgroundColor: FOHColors.GRAYSCALE_10,
                      justifyContent: 'center',
                      marginBottom: 12,
                      minHeight: 52,
                      minWidth: 100
                    }}
                    testID={`list-option-${option.label}`}
                  >
                    <FOHView
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                      }}
                    >
                      <FOHView
                        style={{
                          alignItems: 'center',
                          flexDirection: 'row',
                          width: '90%'
                        }}
                      >
                        <FOHCheckbox
                          isDisabled={
                            option?.upgrade ||
                            (option?.isUserRestricted && option?.isPaused)
                          }
                          selected={locationFilters?.includes(option.value)}
                          style={{ marginRight: SPACING_SCALE.regular }}
                        />

                        <FOHView
                          style={{
                            justifyContent: 'center',
                            maxWidth: '100%'
                          }}
                        >
                          <FOHLabel
                            style={{
                              color: FOHColors.GRAYSCALE_900,
                              lineHeight: 22,
                              maxWidth: 300,
                              minHeight: 24
                            }}
                          >
                            {option.label}
                          </FOHLabel>
                          {option?.sublabel && (
                            <FOHSmallDetailLabel
                              numberOfLines={1}
                              style={{
                                color: FOHColors.GRAYSCALE_600
                              }}
                            >
                              {option?.sublabel}
                            </FOHSmallDetailLabel>
                          )}
                        </FOHView>
                      </FOHView>
                    </FOHView>
                  </FOHTouchableOpacity>
                ))}
            </FOHScrollView>
          </Row>
        </FOHView>
        <FOHFormFlowButtons
          isNextDisabled={
            locationFilters?.length === 0 || !pageTitle || !pageSlug
          }
          nextButtonlabel={t('createLabel')}
          onPressNextButton={handleOnCreate}
          onPressPreviousButton={close}
          previousButtonLabel={t('common:cancel')}
        />
      </FOHView>
    </FOHView>
  );
};

const Row = styled(FOHView)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export default withReactiveModal(CreateNewCareerPageModal);
