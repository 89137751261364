import React from 'react';

import {
  FOHButton,
  FOHHeaderH2,
  FOHSpace,
  FOHTextField,
  FullWidthRow,
  FOHDetailText,
  FOHView,
  FOHPasswordField
} from '../../ingredients';

import { FOHTerms, FOHTermsProps } from '../checkbox/FOHTerms';

import { FOHForm } from './FOHForm';

export interface FOHSetPasswordFormProps extends FOHTermsProps {
  title: string;
  detail: string;
  needsName?: boolean;
  firstName: string;
  firstNamePlaceholder: string;
  setFirstName: (value: string) => void;
  lastName: string;
  lastNamePlaceholder: string;
  setLastName: (value: string) => void;
  email: string;
  emailPlaceholder: string;
  setEmail: (value: string) => void;
  password: string;
  passwordPlaceholder: string;
  setPassword: (value: string) => void;
  passwordConfirm: string;
  passwordPlaceholderConfirm: string;
  setPasswordConfirm: (value: string) => void;
  submitLabel: string;
  handleSubmit: () => void;
  disabledSubmit?: boolean;
  error?: any;
  showPassText?: string;
  hidePassText?: string;
}

export const FOHSetPasswordForm = (props: FOHSetPasswordFormProps) => (
  <>
    <FOHForm
      handleSubmit={props.handleSubmit}
      disabledSubmit={props.disabledSubmit}
    >
      <FOHView style={{ maxWidth: 500, alignItems: 'center' }}>
        <FOHHeaderH2>{props.title}</FOHHeaderH2>
        <FOHSpace />
        <FOHDetailText>{props.detail}</FOHDetailText>
        <FOHSpace />
        {props.needsName ? (
          <FullWidthRow>
            <FOHView style={{ flex: 1 }}>
              <FOHTextField
                placeholder={props.firstNamePlaceholder}
                value={props.firstName}
                required={true}
                onChangeText={(value: string) => props.setFirstName(value)}
                error={
                  props.error && props.error.firstName
                    ? props.error.firstName
                    : null
                }
              />
            </FOHView>
            <FOHView style={{ padding: 4 }} />
            <FOHView style={{ flex: 1 }}>
              <FOHTextField
                placeholder={props.lastNamePlaceholder}
                value={props.lastName}
                required={true}
                onChangeText={(value: string) => props.setLastName(value)}
                error={
                  props.error && props.error.lastName
                    ? props.error.lastName
                    : null
                }
              />
            </FOHView>
          </FullWidthRow>
        ) : (
          <></>
        )}
        <FullWidthRow>
          <FOHTextField
            placeholder={props.emailPlaceholder}
            value={props.email}
            required={true}
            autoCompleteType={'email'}
            onChangeText={(value: string) => props.setEmail(value)}
            error={props.error && props.error.email ? props.error.email : null}
          />
        </FullWidthRow>
        <FullWidthRow>
          <FOHPasswordField
            passwordPlaceholder={props.passwordPlaceholder}
            password={props.password}
            setPassword={(value: string) =>
              props.setPassword && props.setPassword(value)
            }
            error={
              props.error && props.error.password ? props.error.password : null
            }
            showPassText={props.showPassText}
            hidePassText={props.hidePassText}
          />
        </FullWidthRow>
        <FullWidthRow>
          <FOHTextField
            secureTextEntry={true}
            autoCompleteType={'password'}
            required={true}
            placeholder={props.passwordPlaceholderConfirm}
            value={props.passwordConfirm}
            onChangeText={(value: string) => props.setPasswordConfirm(value)}
            error={
              props.error && props.error.passwordConfirm
                ? props.error.passwordConfirm
                : null
            }
          />
        </FullWidthRow>
        <FullWidthRow>
          <FOHTerms
            //@ts-ignore wrapped component w/ withErrors where prop not found
            onPressTermsLink={props.onPressTermsLink}
            terms={props.terms}
            setTerms={props.setTerms}
            termsLinkText={props.termsLinkText}
            termsText={props.termsText}
            error={props.error && props.error.terms ? props.error.terms : null}
          />
        </FullWidthRow>
        <FOHButton
          onPress={props.handleSubmit}
          title={props.submitLabel}
          disabled={props.disabledSubmit}
        />
      </FOHView>
    </FOHForm>
  </>
);
