import { defaultTo } from 'ramda';
import { Text as SlateText, Transforms, Editor } from 'slate';

export const TEMPLATE_VARIABLES = [
  {
    title: 'Candidate First Name',
    value: 'Candidate First Name'
  },
  {
    title: 'Location Name',
    value: 'Location Name'
  },
  {
    title: 'Position Name',
    value: 'Position Name'
  },
  {
    title: 'Position Link',
    value: 'Position Link'
  }
];

export const getTextRanges = (node, path) => {
  const ranges = [];
  const { text } = node;
  const regexBraces = /\{\{[^}]*\}\}/g;

  let slicedText = text;
  let parts = defaultTo([], slicedText.match(regexBraces));
  let lastOffset = 0;

  for (let part of parts) {
    const offset = slicedText.indexOf(part);

    if (offset !== -1) {
      ranges.push({
        anchor: { path, offset: offset + lastOffset },
        focus: { path, offset: offset + part.length + lastOffset }
      });

      slicedText = slicedText.slice(offset + part.length);
      parts = defaultTo([], slicedText.match(regexBraces));
      parts = [];
      lastOffset = offset + part.length + lastOffset;
    }
  }

  return ranges;
};

export const getEditorTextRanges = editor => {
  const ranges = [];
  for (const [node, path] of Editor.nodes(editor, {
    at: [],
    match: SlateText.isText
  })) {
    if (SlateText.isText(node)) {
      ranges.push(...getTextRanges(node, path));
    }
  }
  return ranges;
};

export const stripBracesFromPlaceholder = placeholder => {
  const errorPlaceholder = placeholder.replace(/{{/, '*').replace(/}}/, '*');

  return errorPlaceholder;
};

export const handleDecorate = ([node, path]) => {
  const ranges = [];

  if (SlateText.isText(node)) {
    const currentRanges = getTextRanges(node, path);

    const rangesWithHighlights = [];

    currentRanges.forEach(text => {
      rangesWithHighlights.push({
        ...text,
        highlight: true,
        highlightColor: '#E3F5F9'
      });
    });

    ranges.push(...rangesWithHighlights);
  }

  return ranges;
};

export const withCustomDelete = editor => {
  editor.deleteBackward = () => {
    const currentSelection = editor.selection;

    const ranges = defaultTo([], getEditorTextRanges(editor));

    const rangeToDelete = ranges.find(
      range =>
        range !== null && range.focus.offset === currentSelection.focus.offset
    );

    if (rangeToDelete) {
      Transforms.delete(editor, {
        at: rangeToDelete,
        reverse: true
      });
    } else {
      Transforms.delete(editor, {
        at: editor.selection,
        distance: 1,
        reverse: true
      });
    }
  };
  return editor;
};

export const withCharacterLimit = maxLength => {
  return editor => {
    if (!editor || !editor?.insertText) return editor;

    const { insertText } = editor;

    editor.insertText = text => {
      if (Editor.string(editor, []).length < maxLength) {
        insertText(text);
      } else {
        return;
      }
    };

    return editor;
  };
};
