import React from 'react';

import crown from '../../../images/crown_gray.svg';

import { FOHImage } from '../../ingredients';

export const FOHGrayCrownIcon = (props: any) => (
  <FOHImage
    source={{ uri: crown }}
    style={{ width: 24, height: 24 }}
    {...props}
  />
);
