import React from 'react';

import { defaultTo, prop } from 'ramda';

import visa from '../../../images/visa.svg';

import { FOHImage } from '../../ingredients';

export const FOHVisaIcon = (props: any) => (
  <FOHImage
    source={{ uri: visa }}
    style={{ width: 24, height: 24, ...defaultTo({}, prop('style', props)) }}
  />
);
