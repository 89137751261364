import React, { useRef } from 'react';

import { path } from 'ramda';
import { useHover } from 'react-native-web-hooks';
import styled from 'styled-components/native';

import {
  FOHToolTip,
  FOHToolTipType,
  FOHColors,
  FOHTouchableOpacity,
  FOHView
} from '../../ingredients';
import { FOH_INPUT_HEIGHT } from '../../constants';

import { ButtonText, FOHButtonProps } from './FOHButton';

export const FOHButtonBordered = (props: FOHButtonProps) => {
  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);

  const Icon = props?.icon;

  return (
    <FOHTouchableOpacity
      disabled={props.disabled}
      testID={props.testID || `button-${props.title}`}
      onPress={props.onPress}
      style={[
        {
          width: '100%',
          maxWidth: props.fullWidth ? '100%' : 325
        },
        props.style
      ]}
    >
      <StyledButton
        ref={hoverRef}
        style={{
          backgroundColor: props.disabled
            ? FOHColors.GRAYSCALE_100
            : isHovered
            ? !props.color || props.color === FOHColors.WHITE
              ? FOHColors.GRAYSCALE_100
              : props.color
            : props.color || 'transparent',
          height: path(['style', 'height'], props) || FOH_INPUT_HEIGHT,
          padding: 8,
          borderWidth: 1,
          borderColor:
            props.borderColor ?? props.disabled
              ? props.borderColor || FOHColors.GRAYSCALE_300
              : !props.color || props.color === FOHColors.WHITE
              ? FOHColors.GRAYSCALE_300
              : props.color
        }}
      >
        {props.enableHover ? (
          <FOHToolTip
            helpText={props.helpText}
            open={isHovered}
            type={props.toolTipType}
            style={
              props.toolTipType === FOHToolTipType.LEFT
                ? { width: 200, right: 60, top: 10 }
                : { width: 200 }
            }
          />
        ) : (
          <></>
        )}
        {Icon ? <Icon /> : <></>}
        <ContrastButtonText
          style={[
            {
              color: props.disabled
                ? FOHColors.GRAYSCALE_400
                : props.textColor || FOHColors.GRAYSCALE_700,
              paddingLeft: Icon ? 6 : 0
            },
            props?.textStyle
          ]}
        >
          {props.title}
        </ContrastButtonText>
      </StyledButton>
    </FOHTouchableOpacity>
  );
};

const ContrastButtonText = styled(ButtonText)`
  color: ${FOHColors.WHITE};
`;

const StyledButton = styled(FOHView)`
  align-items: center;
  border-radius: 4px;
  flex-direction: row;
  justify-content: center;
  height: ${FOH_INPUT_HEIGHT}px;
`;
