import React, { useState, useEffect } from 'react';

import { compose, path, prop } from 'ramda';
import { useTranslation } from 'react-i18next';

import { useIsMobile, handle } from '../../utils';
import {
  COMMUTE_PREFERENCES_DROPDOWN,
  CANDIDATE_ACCOUNT_MENU_PATH,
  CANDIDATE_LOCATION_PATH,
  EXTERNAL_APPLICATION_HEIGHTTODECREASE,
  EXTERNAL_APPLICATION_WIDTH
} from '../../constants';
import {
  FOHFixedHeightScrollView,
  FOHView,
  FOHButton,
  FOHSpace,
  FOHLocationCommuteForm,
  FOHFormFlowButtons
} from '../../components';
import { useNavigation } from '../../utils/navigation';

import { withReactiveModal } from '../Navigation/withReactiveModal';

import { useCandidateProfileMutation } from './useCandidateProfileMutation';

export const LocationScreen = props => {
  const { t } = useTranslation('LocationFeature');
  const { location: navLocation, navigateTo } = useNavigation();

  const { me, updateCandidate } = useCandidateProfileMutation();

  const [zipCode, setZipCode] = useState('');
  const [commuteTime, setCommuteTime] = useState('30_minutes');
  const { isMobile } = useIsMobile();

  useEffect(() => {
    setZipCode(path(['candidateProfile', 'addressPostalCode'], me));
    path(['candidateProfile', 'commutePreference'], me) &&
      setCommuteTime(path(['candidateProfile', 'commutePreference'], me));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prop('candidateProfile', me)]);

  const [error, setError] = useState('');

  const hasBack =
    navLocation.pathname === CANDIDATE_LOCATION_PATH &&
    !!path(['candidateProfile', 'onboardingCompletedAt'], me);

  const handleSubmit = async () => {
    const [resp] = await handle(
      updateCandidate({
        variables: {
          addressPostalCode: zipCode,
          commutePreference: commuteTime
        }
      })
    );

    const err = path(['data', 'mutateCandidate', 'errors'], resp);

    if (resp && !err) {
      if (props.close) return props.close();

      return path(['candidateProfile', 'onboardingCompletedAt'], me)
        ? navigateTo(CANDIDATE_ACCOUNT_MENU_PATH)
        : props.onSubmit && props.onSubmit();
    } else if (err) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { address_postal_code } = err;
      setError({ zipCode: address_postal_code });
    }
  };

  return (
    <>
      <FOHView
        style={{
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: 60
        }}
      >
        <FOHView style={{ maxWidth: isMobile ? '100%' : 540, width: '100%' }}>
          <FOHFixedHeightScrollView
            isMobile={isMobile}
            heightToDecrease={EXTERNAL_APPLICATION_HEIGHTTODECREASE}
          >
            <FOHView
              style={{
                width: '100%',
                maxWidth: isMobile ? '100%' : EXTERNAL_APPLICATION_WIDTH
              }}
            >
              <FOHLocationCommuteForm
                title={t('howFarCanYouCommuteTitle')}
                detailText={t(`detailText`)}
                zipCodePlaceholder={t('zipCodePlaceholder')}
                setZipCode={setZipCode}
                zipCode={zipCode}
                commuteTimeOptions={COMMUTE_PREFERENCES_DROPDOWN.map(time => ({
                  label: t(time.label),
                  value: time.value
                }))}
                commuteTime={commuteTime}
                commuteTimePlaceholder={t('desiredCommuteTimePlaceholder')}
                setCommuteTime={setCommuteTime}
                error={error}
              />
            </FOHView>
            {props.isModal && (
              <>
                <FOHSpace />
                <FOHButton
                  title={
                    path(['candidateProfile', 'onboardingCompletedAt'], me)
                      ? t('common:save')
                      : t('nextLabel')
                  }
                  disabled={!zipCode || !commuteTime}
                  onPress={handleSubmit}
                />
              </>
            )}
          </FOHFixedHeightScrollView>
          <FOHSpace />
          <FOHView
            style={{
              width: '100%',
              maxWidth: isMobile ? '100%' : EXTERNAL_APPLICATION_WIDTH,
              marginBottom: isMobile ? 0 : 10,
              alignItems: 'center'
            }}
          >
            {!props.isModal && (
              <FOHFormFlowButtons
                isMobile={isMobile}
                nextButtonlabel={
                  path(['candidateProfile', 'onboardingCompletedAt'], me)
                    ? t('common:save')
                    : t('nextLabel')
                }
                onPressNextButton={handleSubmit}
                isNextDisabled={!zipCode || !commuteTime}
                previousButtonLabel={t('common:previous')}
                onPressPreviousButton={
                  hasBack
                    ? () => navigateTo(CANDIDATE_ACCOUNT_MENU_PATH)
                    : props.previousStep
                }
              />
            )}
          </FOHView>
        </FOHView>
      </FOHView>
    </>
  );
};

export const CommuteEditModal = compose(withReactiveModal)(LocationScreen);

export default LocationScreen;
