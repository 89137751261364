import React from 'react';

import styled from 'styled-components/native';
import { defaultTo, prop } from 'ramda';

import {
  FOHView,
  FOHSpaceSmallest,
  FOHLabel,
  FOHColors,
  FOHSearch,
  FOHStaticLoaderIcon
} from '../../ingredients';

export interface FOHApplicationStatusBadgeProps {
  label: string;
  type: FOHAppliedStatusType;
}

export enum FOHAppliedStatusType {
  PENDING = 'PENDING',
  UNDER_REVIEW = 'UNDER_REVIEW'
}

const getIcon = (type: FOHAppliedStatusType) =>
  defaultTo(
    () => <></>,
    prop(type, {
      [FOHAppliedStatusType.PENDING]: () => (
        <FOHStaticLoaderIcon style={{ top: 4 }} />
      ),
      [FOHAppliedStatusType.UNDER_REVIEW]: () => (
        <FOHSearch
          style={{ width: 16, height: 16, top: 4, tintColor: FOHColors.ORANGE }}
        />
      )
    })
  );

export const FOHApplicationStatusBadge = (
  props: FOHApplicationStatusBadgeProps
) => (
  <FOHView style={{ flexDirection: 'row' }}>
    <TagText
      style={{
        backgroundColor:
          props.type === FOHAppliedStatusType.UNDER_REVIEW
            ? FOHColors.LIGHT_ORANGE
            : FOHColors.BACKGROUND_GRAY
      }}
    >
      {getIcon(props.type)()}
      <FOHSpaceSmallest />
      {props.label}
    </TagText>
  </FOHView>
);

const TagText = styled(FOHLabel)`
  font-size: 12px;
  color: #000000;
  text-align: center;
  border-radius: 100px;
  height: 24px;
  padding-left: 12px;
  padding-right: 12px;
  justify-content: center;
  background-color: #f5f5f5;
  text-align: center;
  line-height: 20px;
  word-break: break-word;
  align-items: center;
  flex-direction: row;
`;
