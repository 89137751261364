import { useMemo } from 'react';

import { defaultTo, path, prop } from 'ramda';

import {
  useGetCurrentPlanQuery,
  useGetMyPositionsPlanQuery,
  useCalculatePriceQuery
} from '../../graphql/generated';
import { unwrapEdgesAt } from '../../utils';
import { FOHPlanState, FOHPositionBillingStatusType } from '../../components';

const removeZeroCents = price => defaultTo('', price).replace('.00', '');

export const formatPricing = ({
  notSubscribedPrice,
  subscribedPrice,
  subscribed
}) => {
  const prices = subscribed ? subscribedPrice : notSubscribedPrice;
  return {
    payasyougo: {
      displayPerMonth: removeZeroCents(
        path(['monthlyPayAsYouGo', 'displayPerMonth'], prices)
      ),
      // : removeZeroCents(path(['annualPayAsYouGo', 'displayPerMonth'], prices)),
      total: removeZeroCents(path(['monthlyPayAsYouGo', 'total'], prices))
      // : removeZeroCents(path(['annualPayAsYouGo', 'total'], prices))
    },
    unlimited: {
      displayPerMonth: removeZeroCents(
        path(['monthlyUnlimited', 'displayPerMonth'], prices)
      ),
      // : removeZeroCents(path(['annualUnlimited', 'displayPerMonth'], prices)),
      total: removeZeroCents(path(['monthlyUnlimited', 'total'], prices))
      // : removeZeroCents(path(['annualUnlimited', 'total'], prices))
    }
  };
};

export const usePaymentPlan = ({
  locationFilter,
  currentPlanQ = false,
  positionsQ = false,
  notSubscribedQ = false,
  subscribedQ = false,
  singleQ = false
}) => {
  const currentPlanQuery = useGetCurrentPlanQuery({
    skip: !locationFilter || !currentPlanQ,
    variables: { location: locationFilter }
  });

  const myPositionsQuery = useGetMyPositionsPlanQuery({
    skip: !locationFilter || !positionsQ,
    variables: { location: locationFilter }
  });

  const notSubscribedPriceQuery = useCalculatePriceQuery({
    skip: !locationFilter || !notSubscribedQ,
    variables: { location: locationFilter, paymentStatus: 'notsubscribed' }
  });

  const subscribedPriceQuery = useCalculatePriceQuery({
    skip: !locationFilter || !subscribedQ,
    variables: { location: locationFilter, paymentStatus: 'subscribed' }
  });

  const singlePriceQuery = useCalculatePriceQuery({
    skip: !locationFilter || !singleQ,
    variables: { location: locationFilter, singleposition: true }
  });

  const notSubscribedPrice = useMemo(
    () => path(['data', 'calculatePrice'], notSubscribedPriceQuery),
    [notSubscribedPriceQuery]
  );
  const subscribedPrice = useMemo(
    () => path(['data', 'calculatePrice'], subscribedPriceQuery),
    [subscribedPriceQuery]
  );
  const singlePrice = useMemo(
    () => path(['data', 'calculatePrice'], singlePriceQuery),
    [singlePriceQuery]
  );

  const myPositions = useMemo(
    () => unwrapEdgesAt(['data', 'myPositions', 'edges'], myPositionsQuery),
    [myPositionsQuery]
  );

  const unpaidPositions = useMemo(
    () =>
      myPositions.filter(
        position =>
          !prop('payment', position) ||
          prop('paymentStatus', position) === 'PAUSED'
      ),
    [myPositions]
  );

  const expiringPositions = useMemo(
    () =>
      myPositions.filter(
        position =>
          prop('paymentStatus', position) === 'PAUSED' &&
          !path(['payment', 'subscriptionEndedOn'], position)
      ),
    [myPositions]
  );

  const locationSubscriptionEnded = useMemo(
    () =>
      !!path(
        ['data', 'locationById', 'payment', 'subscriptionEndedOn'],
        currentPlanQuery
      ),
    [currentPlanQuery]
  );

  const locationSubscribed = useMemo(() => {
    const paymentStatus = path(
      ['data', 'locationById', 'paymentStatus'],
      currentPlanQuery
    );
    const paymentId = path(
      ['data', 'locationById', 'payment', 'id'],
      currentPlanQuery
    );
    return (
      paymentStatus === 'SUBSCRIBED' && paymentId && !locationSubscriptionEnded
    );
  }, [currentPlanQuery, locationSubscriptionEnded]);

  const subscribedPositions = useMemo(
    () =>
      myPositions.filter(
        position => prop('paymentStatus', position) === 'SUBSCRIBED'
      ),
    [myPositions]
  );

  const subscribedPosition = prop(0, subscribedPositions);

  const planName = useMemo(() => {
    const paymentPlanName = path(
      ['data', 'locationById', 'payment', 'planName'],
      currentPlanQuery
    );
    return paymentPlanName && !locationSubscriptionEnded
      ? paymentPlanName
      : FOHPlanState.payasyougo;
  }, [currentPlanQuery, locationSubscriptionEnded]);

  const canChangeHiringFreely = useMemo(() => {
    return (
      planName === FOHPlanState.unlimited ||
      planName === FOHPlanState.enterprise
    );
  }, [planName]);

  const getCurrentPlanName = position => {
    if (
      path(['location', 'paymentStatus'], position) ===
      FOHPositionBillingStatusType.SUBSCRIBED
    ) {
      return path(['location', 'payment', 'planName'], position);
    } else if (
      path(['paymentStatus'], position) ===
      FOHPositionBillingStatusType.SUBSCRIBED
    ) {
      return path(['payment', 'planName'], position);
    } else {
      return null;
    }
  };

  return {
    // any payment query is loading
    loadingPlan:
      currentPlanQuery.loading ||
      myPositionsQuery.loading ||
      notSubscribedPriceQuery.loading ||
      subscribedPriceQuery.loading ||
      singlePriceQuery.loading,
    // the location that is subscribed use if stripe plan is enterprise/unlimited
    subscribedLocation: path(['data', 'locationById'], currentPlanQuery),
    // can toggle a position hiring / paused without payment modals
    canChangeHiringFreely,
    // all positions that are not currently being paid for PAYG
    unpaidPositions,
    // every subscribed position PAYG
    subscribedPositions,
    // positions that have been turned from hiring to paused with payments that have not expired PAYG
    expiringPositions,
    // The prices of activating all unsubscribed positions for any plan or cycle
    notSubscribedPrice,
    // The current prices all subscribed positions for any plan or cycle
    subscribedPrice,
    // The prices of 1 position for any plan or cycle
    singlePrice,
    // Unlimited current price
    locationChargeAmount: locationSubscribed
      ? path(
          [
            'data',
            'locationById',
            'payment',
            'subscription',
            'displayChargeAmount'
          ],
          currentPlanQuery
        )
      : undefined,
    // When the subscription was started, or when the first position was subscribed
    startedOn:
      path(['data', 'locationById', 'payment', 'planName'], currentPlanQuery) &&
      !locationSubscriptionEnded
        ? path(
            ['data', 'locationById', 'payment', 'startedOn'],
            currentPlanQuery
          )
        : path(['payment', 'startedOn'], subscribedPosition),
    // Location is subscribed or there is a subscribed position for this location
    subscribed: locationSubscribed ? true : !!prop('id', subscribedPosition),
    // The plan the user selected
    planName,
    // The user's billing cycle for location, or first subscribed position.
    // defaults to monthly
    cycle: 'monthly',
    //can use payment information if position is undefined or not available
    currentPlanQuery,
    //can use to get current plan name
    getCurrentPlanName
  };
};
