import queryString from 'query-string';

export const parseQueryString = search =>
  queryString.parse(search.replace(/^\?/, ''));

export const stringifyQueryObject = (query = {}) => {
  for (let key in query) {
    const value = query[key];

    if (Array.isArray(value) && value.length < 1) {
      delete query[key];
    }
  }

  return queryString.stringify(query);
};

export const parseAndCastQueryString = (search, props = []) => {
  let queryObject = parseQueryString(search);

  props.forEach(({ key, type }) => {
    if (key in queryObject) {
      let value = queryObject[key];

      switch (type) {
        case 'integer':
          value = parseInt(value);
          break;
        case 'float':
          value = parseFloat(value);
          break;
        case 'array':
          value = Array.isArray(value) ? value : [value];
          break;
        default:
          break;
      }

      queryObject[key] = value;
    }
  });

  return queryObject;
};
