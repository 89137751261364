import React from 'react';

import skills_icon from '../../../images/skills_icon.svg';

import { FOHImage } from '../../ingredients';

export const FOHProfileSkillsIcon = (props: any) => (
  <FOHImage
    source={{ uri: skills_icon }}
    style={{ width: 22, height: 22, ...props?.style }}
  />
);
