import React from 'react';

import styled from 'styled-components/native';
import { defaultTo, length, prop } from 'ramda';

import {
  FOHProfileImage,
  FOHContactCard,
  FOHStatsCard,
  FOHWorkPreferencesTable,
  WorkHistory,
  FOHSmallWorkHistoryCell
} from '../../recipes';
import {
  FOHFonts,
  FOHHeaderH3,
  FOHStatus,
  FOHStatusType,
  FOHSpaceSmall,
  FOHSpaceSmallest,
  FOHSmallDetailLabel,
  FOHSectionLabel,
  FOHLabel,
  FOHFollowBadge,
  FOHDivider,
  FOHChefHatIcon,
  Screen,
  FOHView,
  FullWidthCard,
  FOHSpaceLarge,
  FOHColors,
  FOHRejectionTag,
  FOHLabelBold,
  FOHSpace
} from '../../ingredients';

interface FOHPrintableCandidateProfileProps {
  testID?: string;
  hidden?: boolean;
  rejected?: boolean;
  storyPhoto: string;
  profileOverviewLabel: string;
  containerStyle?: any;
  name: string;
  isFollowing?: boolean;
  followingBadgeLabel?: string;
  followingHelpText?: string;
  myPositions: Array<string>;
  status?: string;
  statusType?: FOHStatusType;
  personalSummary?: string;
  experienceLabel: string;
  yearsExp: string;
  workHistoryLabel: string;
  workHistory?: Array<WorkHistory>;
  emptyWorkHistory?: string;

  locationLabel: string;
  locationValue?: string;
  commuteTimeLabel: string;
  commuteTimeValue?: string;
  positionsLabel: string;

  skillsLabel: string;
  skills?: Array<string>;
  certificationsLabel: string;
  certifications?: Array<string>;
  statsLabel: string;
  maxWidth?: string;

  workPreferencesTableTitle: string;
  employmentPreferencesLabel: string;
  employmentPreferences?: Array<string>;
  benefitsLabel: string;
  benefitsValue: string;
  dressCodeLabel: string;
  dressCodeValue: string;

  email?: string;
  emailPlaceholder: string;
  phone?: string;
  phonePlaceholder: string;
  contactInformationLabel: string;
  contactHelpText: string;
  style?: any;
  contactFieldsWrapperStyle?: any;
  contactFieldsWrapperRef?: any;
  showInfoLabelOnly?: boolean;
  hasWhiteBackground?: boolean;
  backgroundColor?: string;
  contactInfoRef?: any;
  rejectionReasons?: Array<string>;
  appliedPosition: string;
  appliedLocation: string;
  appliedPositionApplicationDate: string | null;
}

export const FOHPrintableCandidateProfile = (
  props: FOHPrintableCandidateProfileProps
) => {
  return (
    <Screen style={{ padding: 30 }}>
      <FOHView
        style={{
          flexDirection: 'row',
          paddingBottom: 18,
          display: 'flex',
          justifyContent: 'flex-start',
          width: '100%',
          backgroundColor: '#FFFFFF',
          paddingLeft: 40,
          paddingRight: 40,
          ...props.containerStyle
        }}
        testID={props.testID}
      >
        <FOHView style={{ flexDirection: 'row', width: '100%' }}>
          <FOHView
            style={{ flex: 5, flexDirection: 'row', alignItems: 'center' }}
          >
            <FOHView style={{ paddingTop: 6, alignItems: 'center' }}>
              <Donut
                style={{
                  backgroundColor:
                    props.hidden || props.rejected ? '#eeeeee' : '#51C1DB',
                  padding: 1
                }}
                key={props.storyPhoto}
              >
                <FOHProfileImage
                  photo={props.storyPhoto}
                  style={{
                    height: 100,
                    width: 100,
                    opacity: props.hidden || props.rejected ? 0.3 : undefined
                  }}
                />
              </Donut>
            </FOHView>
            <FOHView
              style={{
                flexDirection: 'column',
                justifyContent: 'flex-start',
                paddingLeft: 12,
                flex: 1
              }}
            >
              <FOHView style={{ paddingHorizontal: 12, width: '100%' }}>
                <FOHView
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start'
                  }}
                >
                  <FOHSectionLabel
                    numberOfLines={1}
                    style={{
                      fontSize: 32,
                      lineHeight: 40,
                      textAlign: 'left',
                      fontFamily: FOHFonts.MEDIUM,
                      fontWeight: '600'
                    }}
                  >
                    {props.name}
                    {props.isFollowing && !props.hidden ? (
                      <>
                        <FOHSpaceSmall />
                        <FOHFollowBadge
                          small={true}
                          label={props.followingBadgeLabel!}
                          helpText={props.followingHelpText}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </FOHSectionLabel>
                  <FOHSpaceLarge />
                  {props.status && props.statusType ? (
                    <>
                      <FOHStatus
                        statusText={props.status}
                        statusType={props.statusType}
                      />
                      <FOHSpaceSmall />
                    </>
                  ) : (
                    <></>
                  )}
                </FOHView>
                <FOHSpaceSmallest />
                <FOHView
                  style={{
                    width: '100%',
                    flex: 1,
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start'
                  }}
                >
                  {!props.rejected &&
                  props?.appliedPosition &&
                  props?.appliedLocation ? (
                    <>
                      <FOHLabel
                        style={{
                          fontSize: 16,
                          lineHeight: 20,
                          color: FOHColors.GRAYSCALE_700,
                          fontFamily: FOHFonts.MEDIUM,
                          maxWidth: '100%'
                        }}
                      >
                        {`Applied for `}
                        <FOHLabelBold
                          style={{ color: FOHColors.GRAYSCALE_900 }}
                        >
                          {props?.appliedPosition}
                        </FOHLabelBold>
                        {` at `}
                        <FOHLabelBold
                          style={{ color: FOHColors.GRAYSCALE_900 }}
                        >
                          {props?.appliedLocation}
                        </FOHLabelBold>
                        {props?.appliedPositionApplicationDate && (
                          <>
                            {` on `}
                            <FOHLabelBold
                              style={{ color: FOHColors.GRAYSCALE_900 }}
                            >
                              {props?.appliedPositionApplicationDate}
                            </FOHLabelBold>
                          </>
                        )}
                      </FOHLabel>
                    </>
                  ) : null}
                  {props.rejected &&
                  props?.rejectionReasons &&
                  length(props?.rejectionReasons) > 0 ? (
                    <>
                      {props.rejectionReasons.map(reason => (
                        <FOHRejectionTag
                          label={reason}
                          key={reason}
                          style={{ paddingRight: 8 }}
                        />
                      ))}
                    </>
                  ) : null}
                </FOHView>
              </FOHView>
            </FOHView>
          </FOHView>
        </FOHView>
      </FOHView>
      <FOHView style={{ width: '100%', paddingHorizontal: 16 }}>
        <FOHLabel
          style={{
            textAlign: 'left',
            justifyContent: 'flex-start'
          }}
        >
          {props.personalSummary}
        </FOHLabel>
        <FOHSpace />
        <FOHDivider />
      </FOHView>
      <FOHView style={{ padding: 16 }}>
        <FOHHeaderH3 style={{ textAlign: 'left' }}>
          {props.profileOverviewLabel}
        </FOHHeaderH3>
        <FOHView
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            flex: 1
          }}
        >
          <FOHView style={{ flex: 3, alignItems: 'center', paddingRight: 24 }}>
            <FOHView style={{ alignItems: 'flex-start', width: '100%' }}>
              <FOHSectionLabel style={{ paddingBottom: 6 }}>
                {props.workHistoryLabel}
              </FOHSectionLabel>
            </FOHView>

            <FullWidthCard style={{ marginBottom: 24, padding: 24 }}>
              <FOHView>
                <StatLabelContainer>
                  <IconDataContainer>
                    <FOHChefHatIcon />
                    <FOHSmallDetailLabel style={{ paddingLeft: 6 }}>
                      {props.experienceLabel}
                    </FOHSmallDetailLabel>
                  </IconDataContainer>
                </StatLabelContainer>
                <FOHLabel style={{ paddingLeft: 24 }}>
                  {props.yearsExp}
                </FOHLabel>
                <FOHSpaceSmallest />
                <FOHView style={{ paddingVertical: 10, paddingHorizontal: 3 }}>
                  <FOHDivider />
                </FOHView>
              </FOHView>
              <FOHView>
                {props.workHistory ? (
                  props.workHistory.map((history, index) => (
                    <FOHView key={prop('id', history)}>
                      <FOHSmallWorkHistoryCell
                        id={prop('id', history)}
                        key={prop('id', history)}
                        position={history.position}
                        employerName={history.employerName}
                        start={history.start}
                        end={history.end}
                        image={history.image}
                        locationLink={history.locationLink}
                        responsibilities={history.responsibilities}
                      />
                      {length(defaultTo([], props.workHistory)) - 1 !==
                      index ? (
                        <FOHDivider />
                      ) : (
                        <></>
                      )}
                    </FOHView>
                  ))
                ) : (
                  <></>
                )}
                {length(defaultTo([], props.workHistory)) === 0 ? (
                  <FOHLabel>{props.emptyWorkHistory}</FOHLabel>
                ) : (
                  <></>
                )}
              </FOHView>
            </FullWidthCard>

            <FOHView ref={props.contactInfoRef} style={{ width: '100%' }}>
              <FOHContactCard
                {...props}
                style={{
                  marginTop: 45
                }}
                hasWhiteBackground={props.backgroundColor === '#ffffff'}
                showInfoLabelOnly={!props.email || !props.phone}
                contactFieldsWrapperStyle={{
                  flexDirection: 'row'
                }}
              />
            </FOHView>
          </FOHView>
          <FOHView style={{ flex: 2 }}>
            <FOHView style={{ paddingTop: 12 }}>
              <FOHStatsCard
                maxWidth={'100%'}
                {...props}
                positions={props.myPositions}
              />
              <FOHWorkPreferencesTable
                {...props}
                workPreferencesTableTitle={props.workPreferencesTableTitle}
                employmentPreferencesLabel={props.employmentPreferencesLabel}
                employmentPreferences={props.employmentPreferences}
                benefitsLabel={props.benefitsLabel}
                benefitsValue={props.benefitsValue}
                dressCodeLabel={props.dressCodeLabel}
                dressCodeValue={props.dressCodeValue}
              />
            </FOHView>
          </FOHView>
        </FOHView>
      </FOHView>
    </Screen>
  );
};

const Donut = styled(FOHView)`
  border-radius: 50%;
  width: 104px;
  justify-content: center;
  align-items: center;
  height: 104px;
`;

const StatLabelContainer = styled(FOHView)`
  padding-bottom: 6px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const IconDataContainer = styled(FOHView)`
  padding-bottom: 6px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
