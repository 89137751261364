import React, { Fragment } from 'react';

import styled from 'styled-components/native';
import { prop } from 'ramda';

import {
  FOHColors,
  FOHDetailText,
  FOHScrollView,
  FOHView
} from '../../ingredients';

import { FOHSideMenuCell } from '../cells/FOHSideMenuCell';

export interface FOHSideMenuTableProps {
  tableTitle: string;
  navigation: Array<FOHNavigation>;
  activeScreen: string;
  navigateTo: (route: string) => void;
}

interface FOHNavigation {
  label: string;
  route: string;
}

export const FOHSideMenuTable = (props: FOHSideMenuTableProps) => (
  <ContainerView style={{ maxWidth: 288 }}>
    <FOHScrollView>
      <FOHView style={{ paddingLeft: 24, paddingBottom: 16 }}>
        <FOHDetailText>{props.tableTitle}</FOHDetailText>
      </FOHView>
      {props.navigation.map((nav, i) =>
        prop('label', nav) ? (
          <FOHSideMenuCell
            label={nav.label}
            key={i}
            active={nav.route === props.activeScreen}
            onPress={() => props.navigateTo(nav.route)}
          />
        ) : (
          <Fragment key={i}></Fragment>
        )
      )}
    </FOHScrollView>
  </ContainerView>
);

const ContainerView = styled(FOHView)`
  width: 100%;
  border: 1px solid #e8e8e8;
  height: 100vh;
  background-color: ${FOHColors.WHITE};
  z-index: 180;
  position: fixed;
  top: 50px;
`;
