import React from 'react';

import { defaultTo, prop } from 'ramda';

import success from '../../../images/blue_success.svg';

import { FOHImage } from '../../ingredients';

export interface FOHBlueSuccessIconProps {
  style?: any;
}

export const FOHBlueSuccessIcon = (props: FOHBlueSuccessIconProps) => (
  <FOHImage
    source={{ uri: success }}
    style={{ width: 16, height: 16, ...defaultTo({}, prop('style', props)) }}
  />
);
