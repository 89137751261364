import React from 'react';

import styled from 'styled-components/native';
import { View } from 'react-native';

import {
  FOHImage,
  FOHLabel,
  FOHSmallDetailLabel,
  FOHTextButton,
  FOHView,
  FOHColors
} from '../../ingredients';

import { FOHWorkHistoryBaseProps } from './FOHWorkHistoryCell';

interface FOHEmploymentCardProps extends FOHWorkHistoryBaseProps {
  id: string;
  position: string;
  employerName: string;
  employerImg?: string;
  employerAddr?: string;
  start: string;
  end: string;
  roleLabel?: string;
  role?: string;
  testID?: string;
}

export const FOHEmploymentCard = (props: FOHEmploymentCardProps) => (
  <Border testID={props.testID}>
    <Row style={{ justifyContent: 'space-between' }}>
      <Field>
        <SmallGreyLabel>{props.positionLabel}</SmallGreyLabel>
        <Label>{props.position}</Label>
      </Field>
      <Field>
        <Row style={{ paddingRight: 18, paddingTop: 8 }}>
          <FOHTextButton
            color={FOHColors.BLUE}
            title={props.editLabel}
            onPress={() => props.onEditPress(props.id)}
          />
          <View style={{ paddingLeft: 24 }} />
          <FOHTextButton
            title={props.removeLabel}
            onPress={() => props.onRemovePress(props.id)}
          />
        </Row>
      </Field>
    </Row>
    <Row>
      <Field>
        <SmallGreyLabel>{props.employerNameLabel}</SmallGreyLabel>
        <FOHView
          style={{
            flexDirection: 'row',
            flexWrap: 'wrap',
            paddingTop: 6,
            paddingBottom: 6
          }}
        >
          <FOHImage
            source={{ uri: props.employerImg }}
            style={{ width: 32, height: 32, borderRadius: 3 }}
          />
          <FOHView style={{ paddingLeft: 6 }} />
          <FOHView style={{ flex: 1 }}>
            <FOHName>{props.employerName}</FOHName>
            {props.employerAddr && (
              <SmallGreyLabel>{props.employerAddr}</SmallGreyLabel>
            )}
          </FOHView>
        </FOHView>
      </Field>
    </Row>
    <Row style={{ justifyContent: 'space-evenly' }}>
      <Field style={{ flex: 1 }}>
        <SmallGreyLabel>{props.startLabel}</SmallGreyLabel>
        <Label>{props.start}</Label>
      </Field>
      <Field style={{ flex: 1 }}>
        <SmallGreyLabel>{props.endLabel}</SmallGreyLabel>
        <Label>{props.end}</Label>
      </Field>
    </Row>
    {!!props.role && (
      <Row>
        <Field style={{ flex: 1, minWidth: 327 }}>
          <SmallGreyLabel>{props.roleLabel}</SmallGreyLabel>
          <Label style={{ paddingRight: 18 }}>
            {props.role.length > 60
              ? `${props.role.slice(0, 60)}...`
              : props.role}
          </Label>
        </Field>
      </Row>
    )}
  </Border>
);

const Border = styled(View)`
  border-radius: 6px;
  width: 100%;
  min-width: 327px;
  max-width: 506px;
  height: 266px;
  padding-top: 6px;
  border: 1px solid #e8e8e8;
  margin: 4px;
  margin-top: 8px;
`;

const Row = styled(View)`
  flex-direction: row;
  justify-content: left;
  padding-left: 18px;
  padding-top: 12px;
`;

const Field = styled(View)`
  justify-content: left;
`;

const FOHName = styled(FOHSmallDetailLabel)`
  line-height: 16px;
  font-weight: 500;
  color: ${FOHColors.NARO_GREY};
`;

const SmallGreyLabel = styled(FOHSmallDetailLabel)`
  font-size: 12px;
  line-height: 16px;
  display: flex;
`;

const Label = styled(FOHLabel)`
  font-size: 14px;
  line-height: 24px;
  display: flex;
`;
