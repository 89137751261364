import React, { FunctionComponent } from 'react';

import message from '../../../images/message_location.svg';

import { FOHImage } from '../../ingredients';
import { FOHIconProps } from '../../typings';

export const FOHMessageLocationIcon: FunctionComponent<FOHIconProps> = (
  props: any
) => (
  <FOHImage
    source={{ uri: message }}
    style={[{ width: 60, height: 45 }, props?.style]}
  />
);
