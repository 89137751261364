import { useState, useRef, useEffect } from 'react';

import { Platform } from 'react-native';

interface UseClickOutsideProps {
  close: () => void;
}

export function useClickOutside({
  close
}: UseClickOutsideProps): [React.RefObject<any>, boolean] {
  const ref = useRef<any>();

  const [state, setState] = useState({
    hasClickedOutside: false
  });

  useEffect(() => {
    if (state.hasClickedOutside) {
      close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.hasClickedOutside]);

  const handleEvent = (e: Event) => {
    if (ref.current) {
      if (!ref.current.contains(e.target as Node)) {
        setState({ hasClickedOutside: true });
      } else {
        setState({ hasClickedOutside: false });
      }
    }
  };

  useEffect(() => {
    if (Platform.OS === 'web') {
      if (window && window.PointerEvent) {
        if (document.addEventListener)
          document.addEventListener('pointerdown', handleEvent);
      } else {
        if (document.addEventListener) {
          document.addEventListener('mousedown', handleEvent);
          document.addEventListener('touchstart', handleEvent);
        }
      }
    }

    return () => {
      if (Platform.OS === 'web') {
        if (window && window.PointerEvent) {
          if (document.removeEventListener)
            document.removeEventListener('pointerdown', handleEvent);
        } else {
          if (document.removeEventListener) {
            document.removeEventListener('mousedown', handleEvent);
            document.removeEventListener('touchstart', handleEvent);
          }
        }
      }
    };
  }, []);

  if (Platform.OS !== 'web') {
    return [ref, false];
  }

  return [ref, state.hasClickedOutside];
}
