import React, { useState, useEffect } from 'react';

import { compose, path, prop, defaultTo } from 'ramda';
import { useTranslation } from 'react-i18next';

import { handleMutation } from '../../utils';
import {
  CANDIDATE_ACCOUNT_MENU_PATH,
  CANDIDATE_WORK_PREFERENCES_PATH
} from '../../constants';
import { useUpdateCandidateMutation } from '../../graphql/generated';
import { useNavigation } from '../../utils/navigation';
import {
  FOHView,
  Screen,
  FOHButton,
  FOHHeaderRadioQuestionCell,
  FOHStatusCell,
  FOHGoBack
} from '../../components';

import { withReactiveModal } from '../Navigation/withReactiveModal';

import { getDefaultStatusOptions } from './UpdateStatusDropdown';
import { useCandidateProfileMutation } from './useCandidateProfileMutation';

const WorkPreferencesScreen = props => {
  const { navigateTo, location: navLocation } = useNavigation();

  const { withoutStatus, withoutDresscode, withoutBenefits } = {
    // show every question by default
    withoutStatus: false,
    withoutDresscode: false,
    withoutBenefits: false,
    ...props
  };
  const [dressCode, setDressCode] = useState();
  const [benefits, setBenefits] = useState();
  const [status, setStatus] = useState();
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const { me } = useCandidateProfileMutation();
  const [mutateCandidate] = useUpdateCandidateMutation();
  const { t } = useTranslation('WorkPreferencesFeature');

  const hasBack =
    navLocation.pathname === CANDIDATE_WORK_PREFERENCES_PATH &&
    me &&
    !!path(['candidateProfile', 'onboardingCompletedAt'], me);

  useEffect(() => {
    if (me) {
      setStatus({ value: path(['candidateProfile', 'status'], me) });
      setBenefits({
        value: defaultTo(
          false,
          path(['candidateProfile', 'isBenefitsRequired'], me)
        )
      });
      setDressCode({
        value: defaultTo(
          true,
          path(['candidateProfile', 'canHaveDressCode'], me)
        )
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prop('id', me)]);

  const optionsYesNo = [
    {
      label: t('yes'),
      value: true
    },
    {
      label: t('no'),
      value: false
    }
  ];

  return (
    <Screen>
      {props.goBack && (
        <FOHGoBack
          goBack={
            hasBack ? () => navigateTo(CANDIDATE_ACCOUNT_MENU_PATH) : null
          }
        />
      )}
      <FOHView
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: props.isModal ? 0 : 60,
          marginLeft: 10,
          marginRight: 10,
          marginBottom: 10
        }}
      >
        {!withoutStatus && (
          <FOHStatusCell
            header={t('statusHeader')}
            description={t('statusDescription')}
            options={getDefaultStatusOptions(t)}
            answer={status}
            updateAnswer={value => {
              setDisabledSubmit(false);
              return setStatus(value);
            }}
          />
        )}
        {!withoutBenefits && (
          <FOHView style={{ marginTop: '40px', width: '100%' }}>
            <FOHHeaderRadioQuestionCell
              id="benefits"
              header={t('benefitsHeader')}
              description={t('benefitsDescription')}
              options={optionsYesNo}
              answer={benefits}
              updateAnswer={value => {
                setDisabledSubmit(false);
                return setBenefits(value);
              }}
            />
          </FOHView>
        )}
        {!withoutDresscode && (
          <FOHView style={{ marginTop: '40px', width: '100%' }}>
            <FOHHeaderRadioQuestionCell
              id="dressCode"
              header={t('dressCodeHeader')}
              description={t('dressCodeDescription')}
              options={optionsYesNo}
              answer={dressCode}
              updateAnswer={value => {
                setDisabledSubmit(false);
                return setDressCode(value);
              }}
            />
          </FOHView>
        )}
        <FOHButton
          style={{ marginTop: '32px' }}
          title={
            path(['candidateProfile', 'onboardingCompletedAt'], me)
              ? t('common:save')
              : t('nextLabel')
          }
          disabled={disabledSubmit}
          onPress={async () => {
            setDisabledSubmit(true);
            await handleMutation(
              mutateCandidate({
                variables: {
                  id: path(['candidateProfile', 'id'], me),
                  status: prop('value', status),
                  isBenefitsRequired: prop('value', benefits),
                  canHaveDressCode: prop('value', dressCode)
                }
              })
            );
            if (props.close) return props.close();
            return path(['candidateProfile', 'onboardingCompletedAt'], me)
              ? navigateTo(CANDIDATE_ACCOUNT_MENU_PATH)
              : props.onSubmit && props.onSubmit();
          }}
        />
      </FOHView>
    </Screen>
  );
};

export const WorkPreferencesModal = compose(withReactiveModal)(
  WorkPreferencesScreen
);

export default WorkPreferencesScreen;
