import {
  useGetCompaniesQuery,
  GetCompaniesQuery
} from '../../graphql/generated';

export const useGetCompanies = (): {
  data: GetCompaniesQuery | undefined;
  loading: boolean;
  error?: Error;
  refetch: () => void;
} => {
  const { data, loading, error, refetch } = useGetCompaniesQuery({});

  return { data, loading, error, refetch };
};
