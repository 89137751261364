import React from 'react';

import { StyleProp, ViewStyle } from 'react-native';
import { defaultTo } from 'ramda';

import {
  FOHColors,
  FOHToolBoxIcon,
  FullWidthCard,
  FOHLabelSmall,
  FOHView,
  FOHSpaceSmall
} from '../../ingredients';

import { FOHSearchableSelectDropdown } from '../select/FOHSearchableSelectDropdown';

export interface FOHPreferredMatchBlockProps {
  positionSelectLabel: string;
  positions: Array<{
    label: string;
    value: string;
    sublabel: string;
  }>;
  positionPlaceholder: string;
  positionSearchText: string;
  searchPlaceholder: string;
  setPositionSearchText: (text: string) => void;
  selectedPosition: string;
  setSelectedPosition: (position: string) => void;
  selectedPositionLabel: string;
  optionsContainerStyle?: StyleProp<ViewStyle>;
}

export const FOHPreferredMatchBlock = (props: FOHPreferredMatchBlockProps) => {
  const [open, setOpen] = React.useState(false);

  return (
    <FullWidthCard
      style={{
        backgroundColor: FOHColors.LIGHT_GRAY,
        borderColor: FOHColors.LIGHT_GRAY,
        minWidth: 290,
        width: '100%',
        maxHeight: 208,
        marginBottom: 0
      }}
    >
      <FOHView style={{ zIndex: 2, paddingBottom: 8 }}>
        <FOHView style={{ flexDirection: 'row', alignItems: 'center' }}>
          <FOHToolBoxIcon
            style={{
              width: 14,
              height: 14,
              marginRight: 6,
              marginLeft: 2,
              tintColor: FOHColors.CYBERSPACE_GREY
            }}
          />
          <FOHLabelSmall
            style={{
              color: FOHColors.CYBERSPACE_GREY
            }}
          >
            {props.positionSelectLabel}
          </FOHLabelSmall>
        </FOHView>
        <FOHSpaceSmall />
        <FOHSearchableSelectDropdown
          open={open}
          setOpen={setOpen}
          selectedValue={props.selectedPosition}
          selectedLabel={props.selectedPositionLabel}
          onSearchChange={props.setPositionSearchText}
          searchValue={props.positionSearchText}
          searchPlaceholder={props.searchPlaceholder}
          onValueChange={(value: string) => {
            props.setSelectedPosition(value);
          }}
          options={props.positions}
          fieldLabel={props.positionPlaceholder}
          style={{
            width: '100%',
            backgroundColor: '#ffffff'
          }}
          icon={() => <></>}
          optionsContainerStyle={defaultTo({}, props.optionsContainerStyle)}
          testID={'FOHPreferredMatchBlock-position-menu'}
          showSecondaryText
        />
      </FOHView>
    </FullWidthCard>
  );
};
