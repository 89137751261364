import React from 'react';

import notificationPendingScreening from '../../../images/notification-pending-screening.svg';

import { FOHImage } from '../../ingredients';

export interface FOHNotificationPendingScreeningProps {}

export const FOHNotificationPendingScreening = (
  props: FOHNotificationPendingScreeningProps
) => (
  <FOHImage
    {...props}
    source={{ uri: notificationPendingScreening }}
    style={{ width: 24, height: 24 }}
    testID={'FOHNotificationPendingScreening'}
  />
);
