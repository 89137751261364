import { path, defaultTo } from 'ramda';

import { unwrapEdgesAt } from '../../utils';
import { useGetMyFeatureFlagsQuery } from '../../graphql/generated';

export const useFeatures = () => {
  const featureFlagsQuery = useGetMyFeatureFlagsQuery({});
  const waffleFlags = defaultTo(
    [],
    path(['data', 'waffleFlags'], featureFlagsQuery)
  );

  const featureFlags = [
    ...waffleFlags,
    ...unwrapEdgesAt(['data', 'me', 'featureFlags', 'edges'], featureFlagsQuery)
  ];

  return {
    featureFlags,
    loadingFeatureFlags: featureFlagsQuery.loading
  };
};
