import React from 'react';

import loc from '../../../images/profile_location_icon.svg';

import { FOHImage } from '../../ingredients';

export const FOHProfileLocationIcon = (props: any) => (
  <FOHImage
    source={{ uri: loc }}
    style={{ width: 16, height: 22, ...props?.style }}
  />
);
