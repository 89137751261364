import React from 'react';

import styled from 'styled-components/native';
import { View, TextInput } from 'react-native';
import { defaultTo, prop } from 'ramda';

import { FOHEditMarker } from '../../ingredients';
import { FOH_INPUT_HEIGHT } from '../../constants';
import { FOHFonts } from '../../ingredients/text/FOHFonts';

export interface FOHIconTextEditCellProps {
  onChangeLabel: (val: string) => void;
  labelValue: string;
  labelPlaceholder: string;
  style?: any;
  onKeyPress?: (e: any) => void;
  noIcon?: boolean;
  inputStyle?: any;
}

export const FOHIconTextEditCell = (props: FOHIconTextEditCellProps) => (
  <Container style={{ ...props.style }}>
    {!defaultTo(false, prop('noIcon', props)) && (
      <View>
        <FOHEditMarker style={{ height: 18, width: 18 }} />
      </View>
    )}
    <Input
      onChangeText={props.onChangeLabel}
      value={props.labelValue}
      placeholder={props.labelPlaceholder}
      onKeyPress={props.onKeyPress}
      style={{
        height: props?.style?.height ? props?.style?.height - 6 : 46,
        ...props.inputStyle
      }}
    />
  </Container>
);

const Input = styled(TextInput)`
  background: #ffffff;
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 200%;
  display: flex;
  align-items: center;
  width: 100%;
  height: 46;
  flex-direction: row;
  padding: 10px;
  outline-color: transparent;
`;

const Container = styled(View)`
  background: #ffffff;
  border: 1px solid #51c1db;
  border-radius: 6px;
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 200%;
  display: flex;
  align-items: center;
  width: 94%;
  height: ${FOH_INPUT_HEIGHT}px;
  flex-direction: row;
  padding: 10px;
`;
