import React, { useRef } from 'react';

import { TouchableOpacity, View, Text } from 'react-native';
import { useHover } from 'react-native-web-hooks';

import { ALLOWED_ROLES_GROUPS } from '../../../constants';

import { FOHPlanState, FOHPositionHiringStatusType } from '../../typings';
import RestrictedView from '../../RestrictedView';
import {
  FOHBlackEyeIcon,
  FOHColors,
  FOHCrownIcon,
  FOHDivider,
  FOHFonts,
  FOHLabelBold,
  FOHLink,
  FOHSwitch,
  FOHTextButton,
  FOHVerticalDivider,
  FullWidthCard
} from '../../ingredients';

import { FOHInfoCell, InfoCellProps } from '../cells/FOHInfoCell';
import {
  FOHPositionHiringStatusDropdown,
  FOHPositionHiringStatusDropdownProps
} from '../select/FOHPositionHiringStatusDropdown';

type VoidHandler = () => void;

interface Position {
  slug: string;
  positionName: string;
}

interface InfoCellsPayload {
  chatsStarted: InfoCellProps;
  matches: InfoCellProps;
  applicants: InfoCellProps;
  requested: InfoCellProps;
  accepted: InfoCellProps;
  completed: InfoCellProps;
}

export interface FOHPositionDashboardCardProps
  extends FOHPositionHiringStatusDropdownProps {
  position: Position;
  infoCellsPayload: InfoCellsPayload;
  onPressPositionName: (slug: string) => void;
  dateLabels: {
    postedLabel: string;
    latestUpdateLabel: string;
    nextBillingLabel: string;
    expirationDateLabel: string;
  };
  dateInfo: {
    posted: string;
    latestUpdate: string;
    nextBilling: string;
    expiration: string;
  };
  planStatus: FOHPlanState;
  showReactivate?: boolean;
  isExpired?: boolean;
  notificationsLabel: string;
  editPositionsLabel: string;
  onEditPress: VoidHandler;
  onReactivatePress: VoidHandler;
  toggleNotifications?: VoidHandler;
  notifications?: boolean;
  reactivateLabel: string;
  newLabel: string;
  mobile?: boolean;
  viewPositionLabel: string;
  onPressViewPosition: VoidHandler;
}

export const FOHPositionDashboardCard = (
  props: FOHPositionDashboardCardProps
) => {
  const {
    position,
    infoCellsPayload,
    dateLabels,
    dateInfo,
    statusType: positionStatus,
    planStatus,
    isExpired,
    notificationsLabel,
    editPositionsLabel,
    onEditPress,
    onReactivatePress,
    reactivateLabel,
    newLabel,
    viewPositionLabel,
    onPressViewPosition
  } = props;

  const {
    postedLabel,
    latestUpdateLabel,
    nextBillingLabel,
    expirationDateLabel
  } = dateLabels;

  const { expiration, latestUpdate, nextBilling, posted } = dateInfo;

  const infos: InfoCellProps[] = Object.keys(infoCellsPayload).map(
    key => infoCellsPayload[key as keyof InfoCellsPayload]
  );
  const ref = useRef(null);
  const isHovered = useHover(ref);

  const ALLOWED_ROLES_GROUPS_PERMISSION = {
    allowedRoles: ALLOWED_ROLES_GROUPS.ROLES.ADMIN_USER,
    allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.EMPLOYER]
  };

  return (
    <FullWidthCard
      style={{
        flexDirection: 'row',
        padding: 0,
        flexWrap: 'wrap',
        marginBottom: 16
      }}
    >
      <View style={{ width: '100%' }}>
        <View style={{ flexDirection: 'row', padding: 18, zIndex: 200 }}>
          <View
            style={{
              paddingRight: 12,
              minWidth: 100,

              paddingBottom: 10
            }}
          >
            <View
              key={position?.slug}
              style={{
                flexWrap: 'wrap'
              }}
            >
              <View style={{ flexDirection: 'column' }}>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    flex: 1,
                    flexWrap: 'wrap'
                  }}
                >
                  <View>
                    <TouchableOpacity
                      onPress={() => props.onPressPositionName(position.slug)}
                      ref={ref}
                    >
                      <FOHLabelBold
                        style={{
                          textAlign: 'left',
                          textDecorationLine: 'underline',
                          fontSize: 24,
                          lineHeight: 32,
                          color:
                            positionStatus ===
                            FOHPositionHiringStatusType.NOT_POSTED
                              ? FOHColors.GRAYSCALE_400
                              : isHovered
                              ? FOHColors.GRAYSCALE_600
                              : FOHColors.GRAYSCALE_G1
                        }}
                      >
                        {position.positionName}
                      </FOHLabelBold>
                    </TouchableOpacity>
                  </View>
                </View>
                <View
                  style={{
                    alignItems: 'flex-start',
                    minWidth: 200,
                    marginTop: 8
                  }}
                >
                  <FOHPositionHiringStatusDropdown {...props} />
                </View>
              </View>
            </View>
          </View>
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
              zIndex: 199,
              flexWrap: 'wrap'
            }}
          >
            {infos.map((info, idx) => {
              const colors = {
                backgroundColor:
                  info.backgroundColor ??
                  positionStatus === FOHPositionHiringStatusType.NOT_POSTED
                    ? FOHColors.GRAYSCALE_100
                    : info.new && info.new !== 0
                    ? FOHColors.PRIMARY_TEAL_10
                    : FOHColors.GRAYSCALE_100,
                textColor:
                  info.textColor ??
                  positionStatus === FOHPositionHiringStatusType.NOT_POSTED
                    ? FOHColors.GRAYSCALE_700
                    : info.new && info.new !== 0
                    ? FOHColors.PRIMARY_TEAL_300
                    : FOHColors.GRAYSCALE_700
              };
              return (
                <View key={idx}>
                  <FOHInfoCell
                    key={info.title}
                    {...info}
                    {...colors}
                    newLabel={newLabel}
                    testID={`FOHInfoCell-${info.title}`}
                  />
                  {info.isDividerNext && <FOHVerticalDivider />}
                </View>
              );
            })}
          </View>
        </View>
        <FOHDivider />
        <View
          style={{
            flexDirection: props.mobile ? 'column' : 'row',
            paddingHorizontal: 16,
            paddingVertical: 8,
            justifyContent: props.mobile ? 'flex-start' : 'space-between',
            flexWrap: 'wrap',
            alignItems: 'center'
          }}
        >
          {isExpired ? (
            <Text style={{ fontFamily: FOHFonts.MEDIUM, color: '#878787' }}>
              <Text
                style={{
                  fontFamily: FOHFonts.MEDIUM,
                  color: FOHColors.RED,
                  fontWeight: '600'
                }}
              >
                {expirationDateLabel}
              </Text>
              <>{`: `}</>
              <Text
                style={{ color: FOHColors.GRAYSCALE_G1, fontWeight: '600' }}
              >
                {expiration}
              </Text>
              <>{` .`}</>
            </Text>
          ) : (
            <></>
          )}
          {planStatus === FOHPlanState.payasyougo && !isExpired ? (
            <Text style={{ fontFamily: FOHFonts.MEDIUM, color: '#878787' }}>
              <Text style={{ color: FOHColors.GREEN, fontWeight: '600' }}>
                {nextBillingLabel}
              </Text>
              <>{`: `}</>
              <Text
                style={{ color: FOHColors.GRAYSCALE_G1, fontWeight: '600' }}
              >
                {nextBilling}
              </Text>
              <>{` .`}</>
            </Text>
          ) : (
            <></>
          )}
          <Text
            style={{
              fontFamily: FOHFonts.MEDIUM,
              color:
                positionStatus === FOHPositionHiringStatusType.NOT_POSTED
                  ? FOHColors.GRAYSCALE_400
                  : FOHColors.GRAYSCALE_500,
              lineHeight: 20,
              fontWeight: '600'
            }}
          >
            {postedLabel}: {posted} · {latestUpdateLabel}: {latestUpdate}
          </Text>
          <View
            style={{
              flexDirection: props.mobile ? 'column' : 'row',
              justifyContent: props.mobile ? 'flex-start' : 'space-between',
              alignItems: props.mobile ? 'flex-start' : 'center'
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <Text
                style={{
                  fontFamily: FOHFonts.MEDIUM,
                  color:
                    positionStatus === FOHPositionHiringStatusType.NOT_POSTED
                      ? FOHColors.GRAYSCALE_400
                      : FOHColors.GRAYSCALE_G1,
                  fontSize: 16,
                  lineHeight: 24,
                  fontWeight: '600'
                }}
              >
                {notificationsLabel}
              </Text>
              <View style={{ marginLeft: 8 }} />
              <FOHSwitch
                onValueChange={() =>
                  props.toggleNotifications && props.toggleNotifications()
                }
                value={!!props.notifications}
                onLabel={'On'}
                offLabel={'Off'}
              />
            </View>
            <RestrictedView {...ALLOWED_ROLES_GROUPS_PERMISSION}>
              {!props.mobile && (
                <FOHVerticalDivider
                  style={{
                    marginLeft: 18,
                    height: 24,
                    backgroundColor: FOHColors.GRAYSCALE_200
                  }}
                />
              )}
              <TouchableOpacity
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginLeft: 18
                }}
                onPress={() => {
                  props.showReactivate ? onReactivatePress() : onEditPress();
                }}
              >
                {props.showReactivate ? (
                  <>
                    <FOHCrownIcon style={{ height: 18, width: 18 }} />
                    <Text
                      style={{
                        fontFamily: FOHFonts.BOLD,
                        color: FOHColors.GOLD,
                        textDecorationLine: 'underline',
                        fontSize: 16,
                        lineHeight: 24,
                        fontWeight: '700',
                        marginLeft: 7
                      }}
                    >
                      {reactivateLabel}
                    </Text>
                  </>
                ) : (
                  <FOHLink
                    linkText={editPositionsLabel}
                    onPress={onEditPress}
                  />
                )}
              </TouchableOpacity>
              <FOHTextButton
                title={viewPositionLabel}
                disabled={false}
                icon={() => (
                  <FOHBlackEyeIcon style={{ height: 11, width: 18 }} />
                )}
                onPress={onPressViewPosition}
                touchableStyle={{
                  minWidth: 136,
                  maxWidth: 152,
                  height: 40
                }}
                style={{
                  color: FOHColors.GRAYSCALE_900
                }}
                underline
              />
            </RestrictedView>
          </View>
        </View>
      </View>
    </FullWidthCard>
  );
};
