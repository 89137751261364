import React, { useRef, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { prop, path, includes, defaultTo, isEmpty, length } from 'ramda';
import { DateTime } from 'luxon';

import {
  FOHColors,
  FOHView,
  FOHBannerType,
  FOHPlayIcon,
  FOHProfileDetailBlock,
  ResumeViewer
} from '../../components';
import work_history_empty from '../../images/work_history_empty.svg';
import {
  useGetCandidateQuery,
  usePositionByIdQuery
} from '../../graphql/generated';
import {
  transformAvailabilityForProfileCamel,
  transformWorkHistoryForProfileCamel
} from '../../api/transformers/candidates';
import {
  useIsMobile,
  parseFileNameFromS3Url,
  snakeToCamel,
  copyContactInfoToClipboard
} from '../../utils';
import {
  CANDIDATE_EDIT_AVAILABILITY,
  certs,
  getAppBaseUrl,
  CANDIDATE_EDIT_WORK_HISTORY,
  CANDIDATE_EDIT_DRESS_CODE,
  CANDIDATE_EDIT_BENEFITS,
  CANDIDATE_EDIT_TOP_THREE,
  CANDIDATE_EDIT_COMMUTE,
  CANDIDATE_EDIT_SKILLS,
  CANDIDATE_EDIT_EXPERIENCE,
  CANDIDATE_EDIT_POSITIONS,
  CANDIDATE_EDIT_CONTACT,
  CANDIDATE_PROFILE
} from '../../constants';
import { useNavigation } from '../../utils/navigation';

import { useBanner } from '../Navigation/useBanner';
import { useDirectMessage } from '../Chat/useDirectMessage';
import { useChatWindow } from '../Chat/useChatWindow';
import { useCandidateFeedContext } from '../CandidateFeed/useCandidateFeedContext';
import { usePositionTypes } from '../CandidateProfile/usePositionTypes';
import EndorseTable from '../Endorse/EndorseTable';

import { useResumeMutations } from './useResumeMutations';
import { useCandidateProfileMutation } from './useCandidateProfileMutation';

const toDate = isoString => {
  return DateTime.fromISO(isoString, {
    zone: 'local'
  }).toFormat('M/dd/yyyy');
};

export const CandidateProfileOverview = props => {
  const { setBanner } = useBanner();
  const {
    setOpenRegister,
    endorsement,
    setEndorsement,
    isLoggedIn
    // onDirectMessage
  } = props;
  const { t, i18n } = useTranslation('ProfileFeature');

  const { navigateTo } = useNavigation();

  const { isMobile } = useIsMobile();
  const [hasScrolled, setHasScrolled] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  const endorseRef = useRef(null);
  const inputFile = useRef(null);

  const { me } = useCandidateProfileMutation();
  const { fileToUpload, setFileToUpload } = useResumeMutations(
    () => {
      setBanner({
        bannerType: FOHBannerType.SUCCESS,
        bannerMessage: t('common:success')
      });
    },
    () => {
      setBanner({
        bannerType: FOHBannerType.DANGER,
        bannerMessage: `${t('common:failedToUpload')}`
      });
    }
  );

  const userHandle = props.handle;
  const candidateQuery = useGetCandidateQuery({
    variables: {
      handle: userHandle
    }
  });

  const profile = path(['data', 'profile'], candidateQuery);

  const { locationFilters, positionFilters } = useCandidateFeedContext({
    skipPositionsQuery: !isLoggedIn
  });

  const positionQuery = usePositionByIdQuery({
    skip: !prop(0, positionFilters) || length(positionFilters) > 1,
    variables: { id: prop(0, positionFilters) }
  });

  const { setChannelUrlWithMessage } = useChatWindow();

  const { getChannelUrlFor } = useDirectMessage({
    ...props,
    sendBirdAccessToken: prop('sendBirdAccessToken', me)
  });

  const { getStringForPositionType } = usePositionTypes();

  const isOwnProfile = userHandle === path(['candidateProfile', 'handle'], me);

  const isLoggedInAsEmployer = !!path(['employerProfile', 'id'], me);
  const isLoggedInAsCandidate = !!path(['candidateProfile', 'id'], me);

  // do not sroll the window when we are in a modal
  useEffect(() => {
    if (!props.isInModal && window && window.scroll) {
      setHasScrolled(true);
      const isEndorsingHash =
        path(['history', 'location', 'hash'], props) === '#endorse';
      if (isEndorsingHash) {
        // opens endorse form
        setEndorsement({});
      }

      isEndorsingHash
        ? window &&
          window.scroll &&
          window.scroll({
            top:
              path(['current', 'offsetTop'], endorseRef) +
              length(path(['workHistory', 'edges'], profile)) * 60,
            left: 0,
            behavior: 'smooth'
          })
        : window &&
          !hasScrolled &&
          window.scroll &&
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  const selectedLocation =
    length(positionFilters) === 1
      ? path(['data', 'positionById', 'location', 'id'], positionQuery)
      : prop(0, locationFilters);

  if (!isMounted) {
    return <></>;
  }

  return (
    <FOHView
      style={{
        maxWidth: props.fullWidth ? '100%' : isMobile ? '100vw' : 1200,
        width: '100%',
        zIndex: -1
      }}
    >
      <FOHProfileDetailBlock
        // ref for scrolling on candidate details in the inbox feed
        contactInfoRef={props.contactInfoRef}
        backgroundColor={props.backgroundColor || FOHColors.WHITE}
        endorseCTA={t('endorseCTA')}
        positionsLabel={t('positionsLabel')}
        statsLabel={t('statsLabel')}
        recommendationsLabel={t('recommendationsLabel')}
        shareCTA={t('shareCTA')}
        experienceLabel={t('experienceLabel')}
        certificationsLabel={t('certificationsLabel')}
        skillsLabel={t('skillsLabel')}
        videoLabel={t('videoLabel')}
        workHistoryLabel={t('workHistoryLabel')}
        availabilityLabel={t('availabilityLabel')}
        emptyWorkHistory={t('emptyWorkHistory')}
        recommendCTA={t('recommendCTA')}
        workPreferencesTableTitle={t('workPreferencesTableTitle')}
        employmentPreferencesLabel={t('employmentPreferencesLabel')}
        benefitsLabel={t('benefitsLabel')}
        dressCodeLabel={t('dressCodeLabel')}
        locationLabel={t('locationLabel')}
        commuteTimeLabel={t('commuteTimeLabel')}
        addWorkHistoryLabel={t('addWorkHistory')}
        onOpenEditAvailability={
          isOwnProfile
            ? () =>
                navigateTo(
                  `${CANDIDATE_PROFILE}/${userHandle}${CANDIDATE_EDIT_AVAILABILITY}`
                )
            : null
        }
        openWorkHistoryEdit={
          isOwnProfile
            ? id => {
                navigateTo(
                  `${CANDIDATE_PROFILE}/${userHandle}${CANDIDATE_EDIT_WORK_HISTORY}?id=${id}`
                );
              }
            : null
        }
        openDressCodeEdit={
          isOwnProfile
            ? () => {
                navigateTo(
                  `${CANDIDATE_PROFILE}/${userHandle}${CANDIDATE_EDIT_DRESS_CODE}`
                );
              }
            : null
        }
        openBenefitsEdit={
          isOwnProfile
            ? () => {
                navigateTo(
                  `${CANDIDATE_PROFILE}/${userHandle}${CANDIDATE_EDIT_BENEFITS}`
                );
              }
            : null
        }
        openEmploymentPreferencesEdit={
          isOwnProfile
            ? () => {
                navigateTo(
                  `${CANDIDATE_PROFILE}/${userHandle}${CANDIDATE_EDIT_TOP_THREE}`
                );
              }
            : null
        }
        openLocationEdit={
          isOwnProfile
            ? () => {
                navigateTo(
                  `${CANDIDATE_PROFILE}/${userHandle}${CANDIDATE_EDIT_COMMUTE}`
                );
              }
            : null
        }
        openCommuteTimeEdit={
          isOwnProfile
            ? () => {
                navigateTo(
                  `${CANDIDATE_PROFILE}/${userHandle}${CANDIDATE_EDIT_COMMUTE}`
                );
              }
            : null
        }
        openSkillsEdit={
          isOwnProfile
            ? () => {
                navigateTo(
                  `${CANDIDATE_PROFILE}/${userHandle}${CANDIDATE_EDIT_SKILLS}`
                );
              }
            : null
        }
        openExperienceEdit={
          isOwnProfile
            ? () => {
                navigateTo(
                  `${CANDIDATE_PROFILE}/${userHandle}${CANDIDATE_EDIT_EXPERIENCE}`
                );
              }
            : null
        }
        openPositionsEdit={
          isOwnProfile
            ? () => {
                navigateTo(
                  `${CANDIDATE_PROFILE}/${userHandle}${CANDIDATE_EDIT_POSITIONS}`
                );
              }
            : null
        }
        // Contact Card
        // TODO pass up position/location context to backend can_see_contact_information method
        copyLabel={t('Copy')}
        email={path(['user', 'email'], profile)}
        phone={path(['user', 'phone'], profile)}
        emailPlaceholder={t('email')}
        phonePlaceholder={t('phone')}
        contactInformationLabel={t('contactInformation')}
        contactHelpText={
          isOwnProfile ? t('contactHelpText') : t('contactHelpTextEmployer')
        }
        onEditContactCardPress={
          isOwnProfile
            ? () => {
                navigateTo(
                  `${CANDIDATE_PROFILE}/${userHandle}${CANDIDATE_EDIT_CONTACT}`
                );
              }
            : undefined
        }
        // employers can copy contact information
        onCopyEmail={
          !isOwnProfile
            ? () => {
                copyContactInfoToClipboard(
                  { setBanner, profile, t },
                  path(['user', 'email'], profile)
                );
              }
            : undefined
        }
        onCopyPhone={
          !isOwnProfile
            ? () =>
                copyContactInfoToClipboard(
                  { setBanner, t },
                  path(['user', 'phone'], profile)
                )
            : undefined
        }
        //
        resumeCtaText={
          isOwnProfile
            ? t('uploadResume')
            : isLoggedInAsEmployer
            ? t('viewResume')
            : t('viewResume')
        }
        // TODO: pass undefined to block resumes, do not allow non employers to view resume?
        onPressResumeCTA={
          props.hideResume
            ? undefined
            : isOwnProfile
            ? () => {
                inputFile && inputFile.current.click();
              }
            : path(['resume'], profile)
            ? () => {
                window && window.open(path(['resume'], profile));
              }
            : undefined
        }
        resumeUpdatedAt={
          path(['resumeUploadDate'], profile)
            ? toDate(path(['resumeUploadDate'], profile))
            : ''
        }
        resumeName={
          prop('name', fileToUpload) ||
          parseFileNameFromS3Url(path(['resume'], profile))
        }
        resume={path(['resume'], profile)}
        resumeLabel={t('resumeLabel')}
        resumeViewer={
          isOwnProfile ? ResumeViewer.candidate : ResumeViewer.employer
        }
        employmentPreferences={defaultTo(
          [],
          prop('employmentPreferences', profile)
        ).map(pref => t(`common:employmentPreferences.${snakeToCamel(pref)}`))}
        benefitsValue={
          prop('isBenefitsRequired', profile) ? t('common:yes') : '-'
          // t('common:no')
        }
        dressCodeValue={
          prop('canHaveDressCode', profile)
            ? t('common:culturePreferences.flexible')
            : '-'
          // t('common:no')
        }
        locationValue={
          isLoggedInAsEmployer || isLoggedInAsCandidate
            ? prop('addressPostalCode', profile)
            : '-'
        }
        commuteTimeValue={
          prop('commutePreference', profile)
            ? t(
                `common:commutePreferences.options.${prop(
                  'commutePreference',
                  profile
                )}`
              )
            : '-'
        }
        availability={transformAvailabilityForProfileCamel(profile, t)}
        yearsExp={
          prop('yearsExperience', profile)
            ? t(
                `common:yearsExperience.${prop(
                  'yearsExperience',
                  profile
                )}Years`
              )
            : ''
        }
        certifications={defaultTo([], prop('additionalTraining', profile))
          .filter(cert => includes(cert, certs))
          .map(cert => t(`common:additionalTraining.${snakeToCamel(cert)}`))}
        workHistory={transformWorkHistoryForProfileCamel({
          candidate: profile,
          t,
          language: i18n.language
        })}
        emptyWorkHistoryHeader={t('emptyWorkHistoryHeaderText')}
        emptyWorkHistoryDetail={t('emptyWorkHistoryDetailText')}
        askForWorkHistoryBtnLabel={t('askForWorkHistoryBtnLabel')}
        onPressAskForWorkHistory={async () => {
          // onDirectMessage();
          if (!path(['id'], me)) {
            setOpenRegister(true);
          } else {
            // onDirectMessage();
            const sendBirdChannel = await getChannelUrlFor({
              uuids: [
                path(['data', 'profile', 'user', 'uuid'], candidateQuery)
              ],
              location: selectedLocation,
              position: prop(0, positionFilters) || ''
            });

            await setChannelUrlWithMessage(
              sendBirdChannel,
              `${t('askForWorkHistory', {
                name: path(
                  ['data', 'profile', 'user', 'firstName'],
                  candidateQuery
                ),
                employerName: prop('firstName', me),
                locationName: path(
                  ['data', 'positionById', 'location', 'name'],
                  positionQuery
                ),
                candidateProfileURL: `${getAppBaseUrl()}${CANDIDATE_PROFILE}/${userHandle}`
              })}`
            );
          }
        }}
        emptyWorkHistoryImage={work_history_empty}
        skills={defaultTo([], prop('additionalTraining', profile))
          .filter(cert => !includes(cert, certs))
          .map(cert => t(`common:additionalTraining.${snakeToCamel(cert)}`))}
        statsCTA={path(['profileVideo'], profile) ? t('playVideoCTA') : null}
        clickedStatsCTA={
          path(['profileVideo'], profile)
            ? () => {
                props.setVideoOpen && props.setVideoOpen(true);
                props.setVideo &&
                  props.setVideo(path(['profileVideo'], profile));
              }
            : null
        }
        statsCTAIcon={() =>
          FOHPlayIcon({
            small: true,
            style: { tintColor: FOHColors.GRAYSCALE_700 }
          })
        }
        positions={defaultTo([], prop('positions', profile)).map(position =>
          getStringForPositionType(position)
        )}
        vertical={isMobile}
        testID="candidate-profile-detail"
      >
        <div ref={endorseRef} />
        <EndorseTable
          profile={profile}
          handle={userHandle}
          endorsement={endorsement}
          key={`endorsement-${userHandle}-${endorsement}`}
          canRecommend={!isOwnProfile && !props.isInModal}
          canView={isOwnProfile}
          // eslint-disable-next-line @typescript-eslint/no-shadow
          onSubmitEndorsment={({ endorsement, user }) => {
            if (!user || isEmpty(user)) {
              endorsement && setOpenRegister(true);
              setEndorsement(endorsement);
            }
          }}
          name={path(['user', 'firstName'], profile)}
        />
      </FOHProfileDetailBlock>
      <input
        type="file"
        id="file"
        accept="application/pdf,application/msword,.doc,.docx,.txt,.rtf,.html,.pages"
        onChange={e => {
          const file = e.target.files[0];
          // triggers resume upload
          file && setFileToUpload(file);
        }}
        ref={inputFile}
        style={{ display: 'none' }}
      />
    </FOHView>
  );
};
