import React, { useRef, useEffect } from 'react';

import styled from 'styled-components/native';
import { ScrollView } from 'react-native';
import { length } from 'ramda';

import {
  FOHButton,
  FOHInverseButton,
  FOHSupportDialog,
  FOHTextButton,
  FOHLoader,
  FOHLoaderProps,
  GrayBlur,
  FOHLabelBold,
  FOHSpaceLarge,
  FOHSpaceSmall,
  FOHSpace,
  FOHFlexSpace,
  FOHTextField,
  FOHFilterIcon,
  FOHDetailText,
  FOHDivider,
  FOHHeaderText,
  FOHView
} from '../../ingredients';
import {
  FOHRestaurantLocation,
  FOHNoResults,
  FOHNoResultsProps,
  FOHSearchBar,
  FOHRestaurantLocationCell,
  FOHResultCount
} from '../../recipes';
import { FOH_INPUT_HEIGHT } from '../../constants';

export interface FOHRestaurantLocationTableProps
  extends FOHNoResultsProps,
    FOHLoaderProps {
  locations: Array<FOHRestaurantLocation>;
  tableHeader: string;
  tableDescription: string;
  restaurantCountLabel: string;
  restaurantCount: string;
  favoriteLabel: string;
  hideLabel: string;
  hideMeHelpText?: string;
  favoritedHelpText?: string;

  hiringLabel?: string;
  openHiringModal?: (id: string) => any;
  hiringBtnHelpText?: string;

  jobMatchLabel?: string;
  seeMoreLabel: string;
  seeMore: (id: string) => any;
  pressFavorite: (id: string) => any;
  pressHide: (id: string) => any;
  mobile?: boolean;
  listOnly?: boolean;
  mapOnly?: boolean;
  toggleMapList?: () => any;
  mapListButtonTitle?: string;
  loadMoreTitle: string;
  loadMore: () => any;
  loading?: boolean;
  hasMoreLocations?: boolean;
  children?: any;
  supportTitleCmp: any;
  supportBodyCmp: any;
  closeSupport: () => any;
  supportOpen: boolean;
  supportCloseText?: string;
  hideZip?: boolean;
  scrollToTop?: boolean;
  clearSelectionLabel?: string;
  clearSelection?: () => any;
  onPressFilters?: () => any;
  filterButtonTitle?: string;
  setMapHidden: (val: boolean) => any;
  mapHidden?: boolean;
  toggleMapHiddenLabel: string;
  hasFiltersOn?: boolean;
  onChangeSearch: (val: string) => any;
  searchValue: string;
  searchPlaceholder: string;
  setZipCode: (val: string) => any;
  zipCode: string;
  zipCodePlaceholder: string;
}

export const FOHRestaurantLocationTable = (
  props: FOHRestaurantLocationTableProps
) => {
  const scrollRef = useRef<ScrollView>(null);
  useEffect(() => {
    scrollRef.current &&
      scrollRef.current.scrollTo({
        x: 0,
        y: 0,
        animated: true
      });
  }, [props.loading]);
  return (
    <TableContainer
      style={{
        flexDirection: props.mobile ? 'column' : 'row'
      }}
    >
      <FOHView
        style={{
          flex: 3
        }}
      >
        <TableHeader {...props} />
        <FOHView
          style={{
            paddingTop: 12,
            width: '100%',
            justifyContent: 'flex-start',
            paddingLeft: props.mobile ? 24 : 0,
            paddingRight: props.mobile ? 24 : 0
          }}
        >
          {props.mobile && (
            <FOHView>
              <FOHButton
                onPress={() => props.toggleMapList && props.toggleMapList()}
                title={props.mapListButtonTitle || ''}
                style={{
                  maxWidth: '100%'
                }}
              />
              <FOHSpace />
            </FOHView>
          )}
          <FOHView
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexWrap: 'wrap'
            }}
          >
            {props.mapHidden && <FOHFlexSpace flex={0.5} />}
            <FOHView
              style={{
                flexDirection: props.mobile ? 'column' : 'row',
                width: props.mobile ? '100%' : undefined,
                left: props.mobile ? 0 : 24,
                minWidth: props.mobile ? '100%' : 700
              }}
            >
              <FOHSearchBar
                onChangeSearch={props.onChangeSearch}
                searchValue={props.searchValue}
                searchPlaceholder={props.searchPlaceholder}
                style={{
                  width: props.mobile ? '100%' : '60%'
                }}
              />
              <FOHSpaceSmall />
              <FOHView style={{ flexDirection: 'row' }}>
                <FOHView style={{ flex: 2 }}>
                  <FOHTextField
                    placeholder={props.zipCodePlaceholder}
                    value={props.zipCode || ''}
                    onChangeText={props.setZipCode}
                    autoCompleteType={'postal-code'}
                  />
                </FOHView>
                <FOHSpaceSmall />
                {props.onPressFilters && !!props.filterButtonTitle && (
                  <FOHView style={{ flex: 3 }}>
                    <FOHInverseButton
                      icon={() => <FOHFilterIcon active={props.hasFiltersOn} />}
                      textColor={'#000000'}
                      title={props.filterButtonTitle}
                      style={{
                        width: props.mobile ? '100%' : 132,
                        height: FOH_INPUT_HEIGHT
                      }}
                      onPress={props.onPressFilters}
                    />
                  </FOHView>
                )}
              </FOHView>
              <FOHSpaceSmall />
            </FOHView>
            {!props.mapHidden && <FOHSpace />}
            {props.mapHidden && <FOHFlexSpace flex={0.5} />}
          </FOHView>
          {!props.loading && length(props.locations) !== 0 ? (
            <CountSection {...props} />
          ) : (
            <FOHSpace />
          )}
        </FOHView>
        <FOHDivider />
        {!props.mapOnly && (
          <ScrollView
            ref={scrollRef}
            style={{
              height: props.mobile ? '100%' : '80vh',
              width: '100%',
              paddingLeft: props.mobile ? 12 : 26,
              paddingRight: props.mobile ? 12 : 26,
              backgroundColor: props.mapHidden ? '#F5F5F5' : '#ffffff'
            }}
          >
            {props.loading && (
              <FOHView
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingTop: 12
                }}
              >
                <FOHLoader svg={props.svg} path={props.path} />
              </FOHView>
            )}
            {!props.loading &&
              length(props.locations) === 0 &&
              !!props.noResultsButtonTitle &&
              props.noResultsButtonPress && (
                <>
                  <FOHSpace />
                  <FOHNoResults
                    noResultsBody={props.noResultsBody}
                    noResultsHeader={props.noResultsHeader}
                    noResultsButtonPress={props.noResultsButtonPress}
                    noResultsButtonTitle={props.noResultsButtonTitle}
                  />
                </>
              )}
            <FOHView
              style={{
                paddingTop: 20,
                backgroundColor: props.mapHidden ? '#F5F5F5' : '#ffffff',
                flexWrap: 'wrap',
                flexDirection: props.mapHidden ? 'row' : 'column',
                justifyContent: props.mapHidden ? 'center' : 'flex-start',
                alignItems: 'center'
              }}
            >
              {props.locations.map(location => (
                <>
                  <FOHRestaurantLocationCell
                    id={location.id}
                    key={location.id}
                    hasJobMatch={location.hasJobMatch}
                    jobMatchLabel={props.jobMatchLabel}
                    isHiring={location.isHiring}
                    hiringLabel={props.hiringLabel}
                    favoriteLabel={props.favoriteLabel}
                    hideLabel={props.hideLabel}
                    disabledHiringButton={location?.hiringPositionCount === 0}
                    hiringBtnHelpText={props.hiringBtnHelpText}
                    image={String(location.image)}
                    name={location.name}
                    isCard={props.mapHidden}
                    vertical={props.mobile || props.mapHidden}
                    formattedAddress={location.formattedAddress}
                    tags={location.tags}
                    isFavorited={location.isFavorited}
                    isHidden={location.isHidden}
                    seeMoreLabel={props.seeMoreLabel}
                    favoritedHelpText={props.favoritedHelpText}
                    hideMeHelpText={props.hideMeHelpText}
                    followerCountLabel={location.followerCountLabel}
                    seeMore={() => props.seeMore && props.seeMore(location.id)}
                    pressFavorite={() =>
                      props.pressFavorite && props.pressFavorite(location.id)
                    }
                    pressHide={() =>
                      props.pressHide && props.pressHide(location.id)
                    }
                    openHiringModal={
                      props.openHiringModal && location.isHiring
                        ? () => {
                            props.openHiringModal &&
                              props.openHiringModal(location.id);
                          }
                        : undefined
                    }
                  />
                  {/* TODO: Can remove mapHidden check when removing non-map view */}
                  {!props.mapHidden && (
                    <>
                      <FOHSpace />
                      <FOHDivider />
                      <FOHSpace />
                    </>
                  )}
                </>
              ))}
            </FOHView>
            <FOHView
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                paddingTop: 12,
                paddingBottom: 48,
                width: '100%'
              }}
            >
              {props.hasMoreLocations && (
                <FOHInverseButton
                  title={props.loadMoreTitle}
                  textColor={'#000000'}
                  style={{
                    width: '100%'
                  }}
                  onPress={props.loadMore}
                />
              )}
            </FOHView>
          </ScrollView>
        )}
      </FOHView>
      {!props.listOnly && (
        <>
          <FOHView style={{ flex: props.mapHidden ? 0 : 2, height: '96vh' }}>
            <FOHView
              style={{
                position: 'absolute',
                bottom: props.mobile ? '-65vh' : 0,
                zIndex: 999
              }}
            >
              {props.supportTitleCmp && props.supportBodyCmp && (
                <FOHSupportDialog
                  open={props.supportOpen}
                  titleCmp={props.supportTitleCmp}
                  doneText={props.supportCloseText}
                  close={props.closeSupport}
                >
                  {props.supportBodyCmp()}
                </FOHSupportDialog>
              )}
            </FOHView>
            {props.loading && props.mapOnly && (
              <>
                <GrayBlur
                  style={{
                    top: 0,
                    zIndex: 99999,
                    position: 'absolute',
                    height: '65vh'
                  }}
                />
                <FOHView
                  style={{
                    zIndex: 99999,
                    paddingTop: 24,
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%'
                  }}
                >
                  <FOHLoader svg={props.svg} path={props.path} />
                  <FOHLabelBold style={{ color: '#ffffff' }}>
                    {props.restaurantCountLabel}
                  </FOHLabelBold>
                </FOHView>
              </>
            )}
            {props.children}
          </FOHView>
        </>
      )}
    </TableContainer>
  );
};

const TableContainer = styled(FOHView)`
  flex-direction: row;
`;

const TableHeader = (props: FOHRestaurantLocationTableProps) => (
  <FOHView style={{ flexDirection: 'row' }}>
    {props.mapHidden && <FOHFlexSpace flex={0.5} />}
    <FOHView
      style={{
        justifyContent: 'flex-start',
        paddingLeft: props.mapHidden ? 0 : 24,
        flex: 2
      }}
    >
      <FOHHeaderText
        style={{
          fontSize: props.mobile ? 24 : 30,
          textAlign: 'left',
          flex: 1,
          paddingTop: props.mobile ? 0 : 32,
          lineHeight: '120%'
        }}
      >
        {props.tableHeader}
      </FOHHeaderText>
      <FOHDetailText
        style={{
          textAlign: 'left',
          flex: 1,
          paddingTop: 8,
          lineHeight: '120%'
        }}
      >
        {props.tableDescription}
      </FOHDetailText>
    </FOHView>
    {props.mapHidden && <FOHFlexSpace flex={0.5} />}
  </FOHView>
);

const CountSection = (props: FOHRestaurantLocationTableProps) => (
  <FOHView style={{ flexDirection: 'row' }}>
    {props.mapHidden && <FOHFlexSpace flex={0.5} />}
    <FOHView style={{ flexDirection: 'row', flex: 2 }}>
      {!props.mobile && <FOHSpaceLarge />}
      <FOHView
        style={{
          paddingTop: 24,
          paddingBottom: 24
        }}
      >
        <FOHResultCount
          count={props.restaurantCount}
          countLabel={props.restaurantCountLabel}
        />
      </FOHView>
      {props.clearSelection && !!props.clearSelectionLabel && (
        <FOHView
          style={{
            justifyContent: 'flex-end',
            alignItems: 'center',
            flex: 1,
            flexDirection: 'row',
            paddingRight: 24
          }}
        >
          <FOHTextButton
            style={{
              color: '#51C1DB'
            }}
            title={props.clearSelectionLabel}
            onPress={props.clearSelection}
          />
        </FOHView>
      )}
    </FOHView>
    {props.mapHidden && <FOHFlexSpace flex={0.5} />}
  </FOHView>
);
