import React from 'react';

import crown from '../../../images/blue_crown_icon.svg';

import { FOHImage } from '../../ingredients';

export const FOHBlueCrownIcon = (props: any) => (
  <FOHImage
    source={{ uri: crown }}
    style={{ width: 14, height: 14 }}
    {...props}
  />
);
