import React from 'react';

import { useTranslation } from 'react-i18next';
import { useSpring, animated } from 'react-spring';

import {
  FOHLabel,
  FOHLoader,
  FOHSpaceLarge,
  FOHView,
  Screen
} from '../ingredients';

const AnimatedView = animated(FOHView);

const FOHLoaderWithText: React.FC = () => {
  const { t } = useTranslation('common');

  const styles = useSpring({
    loop: true,
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 1000 }
  });

  return (
    <Screen
      style={{
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <FOHLoader />
      <FOHSpaceLarge />
      <AnimatedView style={styles}>
        <FOHLabel>{t('loading')}...</FOHLabel>
      </AnimatedView>
    </Screen>
  );
};

export default FOHLoaderWithText;
