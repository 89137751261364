import React, { FunctionComponent } from 'react';

import laptop from '../../../images/enterprise_laptop_icon.svg';

import { FOHImage } from '../../ingredients';
import { FOHIconProps } from '../../typings';

export const FOHEnterpriseLaptopIcon: FunctionComponent<FOHIconProps> = ({
  style
} = {}) => (
  <FOHImage
    source={{ uri: laptop }}
    style={[{ width: 120, height: 136 }, style]}
  />
);
