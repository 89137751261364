import React, { FunctionComponent } from 'react';

import styled from 'styled-components/native';
import { Text } from 'react-native';
import { Noop } from 'react-hook-form';

import {
  FOHBlackEyeIcon,
  FOHButton,
  FOHColors,
  FOHDetailText,
  FOHHeaderH4,
  FOHHeaderH2,
  FOHOpenIcon,
  FOHPageModal,
  FOHView,
  SPACING_SCALE,
  FOHStarIcon,
  FOHTouchableOpacity,
  FOHNotebookWithSuccessIcon,
  FOHBlackHelpIcon
} from '../../ingredients';

export interface FOHIntegrateCareerPageModalProps {
  onViewAsCandidatePress: Noop;
  onCopyLinkPress: Noop;
  isIntegrateCareerPageModalOpen: boolean;
  handleCloseIntegrateCareerPageModal: Noop;
  careerPageUrl: string;
  onHelpCenterLinkPress: Noop;
  translations: {
    integrateCareerPageModalTitle: string;
    integrateCareerPageModalReady: string;
    integrateCareerPageModalTitleCD: string;
    integrateCareerPageModalDescription: string;
    integrateCareerPageModalCareerPageLink: string;
    integrateCareerPageModalYourCareerPageLink: string;
    integrateCareerPageModalViewAsCandidate: string;
    integrateCareerPageModalCopyLinkButton: string;
    integrateCareerPageModalHelpCenterHeader: string;
    integrateCareerPageModalFirstStepHeader: string;
    integrateCareerPageModalFirstStepDescription: string;
    integrateCareerPageModalSecondStepHeader: string;
    integrateCareerPageModalSecondStepDescription: string;
    integrateCareerPageModalThirdStepHeader: string;
    integrateCareerPageModalThirdStepDescription: string;
    integrateCareerPageModalHelpCenterDescription: string;
    integrateCareerPageModalHelpCenterCD: string;
    integrateCareerPageModalHelpCenterLink: string;
  };
}

export const FOHIntegrateCareerPageModal: FunctionComponent<
  FOHIntegrateCareerPageModalProps
> = ({
  careerPageUrl,
  handleCloseIntegrateCareerPageModal,
  isIntegrateCareerPageModalOpen,
  onCopyLinkPress,
  onHelpCenterLinkPress,
  onViewAsCandidatePress,
  translations
}) => {
  return (
    <FOHPageModal
      close={handleCloseIntegrateCareerPageModal}
      modalStyle={{
        maxWidth: 768
      }}
      open={isIntegrateCareerPageModalOpen}
    >
      <Wrapper>
        <HeaderSection>
          <FOHNotebookWithSuccessIcon
            style={{
              marginLeft: SPACING_SCALE.massive,
              marginRight: SPACING_SCALE.massive
            }}
          />
          <FOHView style={{ flex: 1 }}>
            <ModalHeaderText>
              {translations.integrateCareerPageModalTitle}{' '}
              <Text style={{ color: FOHColors.BLUE }}>
                {translations.integrateCareerPageModalReady}
              </Text>{' '}
              {translations.integrateCareerPageModalTitleCD}
            </ModalHeaderText>

            <FOHDetailText>
              {translations.integrateCareerPageModalDescription}
            </FOHDetailText>
          </FOHView>
        </HeaderSection>

        <CopyLinkSection>
          <FOHView
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <CopyLinkSectionHeader>
              {translations.integrateCareerPageModalCareerPageLink}
            </CopyLinkSectionHeader>

            <FOHBlackEyeIcon
              style={{
                marginRight: SPACING_SCALE.regular,
                tintColor: FOHColors.BLUE
              }}
            />
            <FOHTouchableOpacity onPress={onViewAsCandidatePress}>
              <FOHHeaderH4
                style={{
                  color: FOHColors.BLUE,
                  textDecorationLine: 'underline'
                }}
              >
                {translations.integrateCareerPageModalViewAsCandidate}
              </FOHHeaderH4>
            </FOHTouchableOpacity>
          </FOHView>
          <FOHView
            style={{ flexDirection: 'row', marginTop: SPACING_SCALE.larger }}
          >
            <LinkWrapper>
              <FOHOpenIcon style={{ margin: 14 }} />
              <FOHView>
                <Text style={{ color: FOHColors.GREY, fontSize: 12 }}>
                  {translations.integrateCareerPageModalYourCareerPageLink}
                </Text>

                <Text>{careerPageUrl}</Text>
              </FOHView>
            </LinkWrapper>
            <FOHButton
              color={FOHColors.LIGHT_BLUE}
              onPress={onCopyLinkPress}
              style={{
                width: 128
              }}
              textColor={FOHColors.PACIFIC_BLUE}
              title={translations.integrateCareerPageModalCopyLinkButton}
            />
          </FOHView>
        </CopyLinkSection>
        <HowToDoItSection>
          <HowToDoItSectionHeader>
            {translations.integrateCareerPageModalHelpCenterHeader}
          </HowToDoItSectionHeader>
          <StepsSection>
            <Step>
              <StepNumber>1</StepNumber>
              <StepHeader>
                {translations.integrateCareerPageModalFirstStepHeader}
              </StepHeader>
              <StepDescription>
                {translations.integrateCareerPageModalFirstStepDescription}
              </StepDescription>
            </Step>
            <Step>
              <StepNumber>2</StepNumber>
              <StepHeader>
                {translations.integrateCareerPageModalSecondStepHeader}
              </StepHeader>
              <StepDescription>
                {translations.integrateCareerPageModalSecondStepDescription}
              </StepDescription>
            </Step>
            <Step>
              <StepNumber>3</StepNumber>
              <StepHeader>
                {translations.integrateCareerPageModalThirdStepHeader}
              </StepHeader>
              <StepDescription>
                {translations.integrateCareerPageModalThirdStepDescription}
              </StepDescription>
            </Step>
          </StepsSection>
        </HowToDoItSection>

        <SupportSection>
          <FOHStarIcon
            style={{
              height: 16,
              marginRight: SPACING_SCALE.regular,
              tintColor: FOHColors.GREEN,
              width: 16
            }}
          />
          <FOHView style={{ flex: 1 }}>
            <Text>
              {translations.integrateCareerPageModalHelpCenterDescription}
            </Text>

            <FOHView style={{ alignItems: 'flex-end', flexDirection: 'row' }}>
              <Text
                style={{
                  marginTop: SPACING_SCALE.larger
                }}
              >
                {translations.integrateCareerPageModalHelpCenterCD}
              </Text>
              <FOHBlackHelpIcon
                style={{
                  height: 16,
                  marginLeft: SPACING_SCALE.regular,
                  marginRight: SPACING_SCALE.small,
                  tintColor: FOHColors.GREEN,
                  width: 16
                }}
              />
              <FOHTouchableOpacity onPress={onHelpCenterLinkPress}>
                <Text
                  style={{
                    color: FOHColors.GREEN,
                    textDecorationLine: 'underline'
                  }}
                >
                  {translations.integrateCareerPageModalHelpCenterLink}
                </Text>
              </FOHTouchableOpacity>
            </FOHView>
          </FOHView>
        </SupportSection>
      </Wrapper>
    </FOHPageModal>
  );
};

const ModalHeaderText = styled(FOHHeaderH2)`
  text-align: start;
`;
const Wrapper = styled.View`
  padding: 40px;
  margin-top: -${SPACING_SCALE.huge}px;
`;

const Step = styled.View`
  flex: 1;
  margin-right: 44px;
  font-weight: bold;
`;

const StepsSection = styled.View`
  flex-direction: row;
  margin-top: ${SPACING_SCALE.huge}px;
`;

const StepNumber = styled.Text`
  background-color: ${FOHColors.LIGHT_GREEN};
  color: ${FOHColors.GREEN};
  font-weight: 500;
  border-radius: 6px;
  font-size: 20px;
  justify-content: center;
  text-align: center;
  line-height: 32px;
  align-items: center;
  width: 32px;
  height: 32px;
`;

const StepHeader = styled(FOHHeaderH4)`
  margin-top: ${SPACING_SCALE.larger}px;
  text-align: left;
`;

const StepDescription = styled(FOHDetailText)`
  text-align: left;
  font-size: 14px;
  width: 182px;
  line-height: 24px;
  margin-top: ${SPACING_SCALE.regular}px;
`;

const CopyLinkSection = styled.View`
  background-color: ${FOHColors.LIGHT_GRAY};
  padding: ${SPACING_SCALE.massive}px;
  margin-top: ${SPACING_SCALE.huge}px;
`;

const CopyLinkSectionHeader = styled(FOHHeaderH4)`
  margin-right: auto;
  display: inline;
`;

const LinkWrapper = styled.View`
  flex-direction: row;
  border: 1px solid ${FOHColors.BACKGROUND_DARK_GRAY};
  border-radius: 6px;
  align-items: center;
  flex: 1;
  margin-right: ${SPACING_SCALE.regular}px;
`;

const HeaderSection = styled.View`
  flex-direction: row;
`;

const HowToDoItSection = styled.View`
  margin-top: 40px;
`;
const HowToDoItSectionHeader = styled(FOHHeaderH2)`
  color: ${FOHColors.GREEN};
`;

const SupportSection = styled.View`
  margin-top: 40px;
  border-radius: 6px;
  flex-direction: row;
  align-items: flex-start;
  padding-top: ${SPACING_SCALE.large}px;
  padding-bottom: ${SPACING_SCALE.larger}px;
  padding-left: ${SPACING_SCALE.larger}px;
  padding-right: ${SPACING_SCALE.larger}px;
  background-color: ${FOHColors.LIGHT_GREEN};
`;
