import { useMemo } from 'react';

import { range } from 'ramda';

export const DOTS = '...';

interface UseDotPaginationProps {
  currentPage?: number;
  siblingCount?: number;
  totalCount?: number;
}

export const useDotPagination = ({
  currentPage,
  siblingCount = 1,
  totalCount = 0
}: UseDotPaginationProps) => {
  const paginationRange = useMemo(() => {
    if (currentPage != null) {
      const totalPageCount = totalCount + 1;
      const totalPageNumbers = siblingCount + 5;

      if (totalPageNumbers >= totalPageCount) {
        return range(1, totalPageCount);
      }

      const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
      const rightSiblingIndex = Math.min(
        currentPage + siblingCount,
        totalPageCount
      );

      const shouldShowLeftDots = leftSiblingIndex > 2;
      const shouldShowRightDots = rightSiblingIndex < totalPageCount;

      const firstPageIndex = 1;
      const lastPageIndex = totalPageCount;

      if (!shouldShowLeftDots && shouldShowRightDots) {
        const leftItemCount = 2 + 2 * siblingCount;
        const leftRange = range(1, leftItemCount);

        return [...leftRange, DOTS, totalPageCount];
      }

      if (shouldShowLeftDots && !shouldShowRightDots) {
        const rightItemCount = 2 + 2 * siblingCount;
        const rightRange = range(
          totalPageCount - rightItemCount + 1,
          totalPageCount
        );

        return [firstPageIndex, DOTS, ...rightRange];
      }

      if (shouldShowLeftDots && shouldShowRightDots) {
        const middleRange = range(leftSiblingIndex, rightSiblingIndex);

        return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
      }
    }
  }, [totalCount, siblingCount, currentPage]);

  return paginationRange?.filter(page => page !== DOTS);
};
