import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { path } from 'ramda';
import { useLocalStorage } from 'use-hooks';

import {
  FOHButton,
  FOHColors,
  FOHView,
  FOHSpaceSmallest,
  FOHMessageBoxIcon,
  FOHMessageIcon
} from '../../components';
import {
  GetChannelsCountQuery,
  useGetChannelsCountQuery
} from '../../graphql/generated';

import { INBOX_FILTER, ARCHIVE_FILTER } from './useChatFiltersState';

interface ChatFilterTabsProps {
  setChatFilter: (filter: string) => void;
  activeChatFilter: string;
  selectedLocation?: string;
  hideChannelLoading: boolean;
  selectedPosition?: string;
}

const ChatFilterTabs = (props: ChatFilterTabsProps) => {
  const { setChatFilter, activeChatFilter, hideChannelLoading } = props;
  const { t } = useTranslation('SendBirdFeature');
  const [isChatCountVisible] = useLocalStorage(
    'is-chat-count-visible-flag',
    false
  );
  const isArchiveActive = activeChatFilter === ARCHIVE_FILTER;

  const countsQuery = useGetChannelsCountQuery({
    skip: !props.selectedLocation,
    variables: {
      locationId: props.selectedLocation,
      positionId: props.selectedPosition
    }
  });

  const counts = path(
    ['data', 'channelsCount'],
    countsQuery
  ) as GetChannelsCountQuery['channelsCount'];

  useEffect(() => {
    if (props.selectedLocation) {
      countsQuery.refetch();
    }
  }, [hideChannelLoading, props.selectedLocation]); // eslint-disable-line

  return (
    <FOHView
      style={{
        flexDirection: 'row',
        backgroundColor: FOHColors.GRAYSCALE_100,
        width: 300,
        height: 40,
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 'auto',
        padding: 4,
        marginRight: 4
      }}
    >
      <FOHButton
        title={`${t('inboxLabel')}${
          isChatCountVisible ? ` (${counts ? counts.unhidden : ''})` : ''
        }`}
        color={
          !isArchiveActive ? FOHColors.GRAYSCALE_G1 : FOHColors.GRAYSCALE_100
        }
        textColor={!isArchiveActive ? FOHColors.WHITE : FOHColors.GRAYSCALE_700}
        style={{ width: 150, height: 32 }}
        onPress={() => setChatFilter(INBOX_FILTER)}
        icon={() => (
          <FOHMessageIcon
            style={{
              tintColor: !isArchiveActive
                ? FOHColors.WHITE
                : FOHColors.GRAYSCALE_G1
            }}
          />
        )}
      />
      <FOHSpaceSmallest />
      <FOHButton
        title={`${t('archivedLabel')}${
          isChatCountVisible ? ` (${counts ? counts.hidden : ''})` : ''
        }`}
        style={{ width: 150, height: 32 }}
        color={
          isArchiveActive ? FOHColors.GRAYSCALE_G1 : FOHColors.GRAYSCALE_100
        }
        textColor={isArchiveActive ? FOHColors.WHITE : FOHColors.GRAYSCALE_700}
        onPress={() => setChatFilter(ARCHIVE_FILTER)}
        icon={() => (
          <FOHMessageBoxIcon
            style={{
              tintColor: isArchiveActive
                ? FOHColors.WHITE
                : FOHColors.GRAYSCALE_G1
            }}
          />
        )}
      />
    </FOHView>
  );
};

export default ChatFilterTabs;
