import React from 'react';

import { defaultTo, prop } from 'ramda';
import { ImageStyle, ViewStyle } from 'react-native';

import career_page_navigation_menu_blue from '../../../images/career_page_navigation_menu_blue.svg';

import { FOHImage } from '../../ingredients';

interface Props {
  style?: ViewStyle;
}

export const FOHBlueCareerIcon: React.FC<Props> = props => (
  <FOHImage
    source={{ uri: career_page_navigation_menu_blue }}
    style={{
      width: 24,
      height: 24,
      ...(defaultTo({}, prop('style', props)) as ImageStyle)
    }}
  />
);
