import React, { FunctionComponent } from 'react';

import { ScrollView, ScrollViewProps } from 'react-native';
import { ThemeProvider } from 'styled-components';

import { useIsMobile } from '../../spices';

import {
  CareerPageContainer,
  CareerPageMain,
  CareerPageScreen,
  CareerPageScreenBackground,
  CareerPageSidebar,
  ScrollViewWrapper,
  ActionBarWrapper
} from './styled';

export { CareerPageScreenBackground, ActionBarWrapper };
export interface FOHCareerPageLayoutProps {
  aside: React.ReactNode;
  background: React.ReactNode;
  children: React.ReactNode;
  innerRef: React.RefObject<ScrollView>;
  isEmployer: boolean;
  onScrollHandler: ScrollViewProps['onScroll'];
  theme: any;
}

export const FOHCareerPageLayout: FunctionComponent<
  FOHCareerPageLayoutProps
> = ({
  aside,
  background,
  children,
  innerRef,
  isEmployer,
  onScrollHandler,
  theme = {}
}) => {
  const isMobile = useIsMobile();

  return (
    <ThemeProvider theme={theme}>
      <ScrollViewWrapper>
        <ScrollView
          onScroll={onScrollHandler}
          ref={innerRef}
          scrollEventThrottle={200}
        >
          <CareerPageScreen
            isEmployer={isEmployer}
            isMobile={isMobile}
            style={{
              backgroundColor: theme?.pageBackground
                ? theme.pageBackground
                : 'rgb(245, 245, 245)'
            }}
            testID="CareerPageScreen"
          >
            <CareerPageContainer
              isMobile={isMobile}
              style={{
                backgroundColor: theme?.tableBackground
                  ? theme.tableBackground
                  : 'white'
              }}
              testID="CareerPageContainer"
            >
              <CareerPageSidebar isMobile={isMobile}>{aside}</CareerPageSidebar>
              <CareerPageMain>{children}</CareerPageMain>
            </CareerPageContainer>

            {background}
          </CareerPageScreen>
        </ScrollView>
      </ScrollViewWrapper>
    </ThemeProvider>
  );
};
