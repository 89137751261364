import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { useIsMobile } from '../../../utils';
// import { WebDropZone } from '../../../features/Welcome/webDropZone';

import {
  FOHView,
  FOHReactiveModal,
  FOHTextField,
  FOHSectionLabel,
  FOHColors,
  FOHMultiLineTextField,
  FOHHeaderH1,
  FOHDetailText
} from '../../ingredients';

import { FOHForm } from '../forms/FOHForm';
import { FOHFormFlowButtons } from '../buttons/FOHFormFlowButtons';

// interface FileDataType {
//   fileType: string;
//   fileString: string;
// }
export interface HelpRequestDataType {
  email: string;
  firstName: string;
  lastName: string;
  employerName: string;
  message: string;
  // fileData?: FileDataType[];
}

interface FOHHelpRequestModalProps {
  me?: any;
  onPressCancel: () => void;
  close: () => void;
  onPressSubmit: (requestData: HelpRequestDataType) => void;
  leftContentShift: number;
}

export const FOHHelpRequestModal = (props: FOHHelpRequestModalProps) => {
  const { me, close, onPressSubmit, onPressCancel, leftContentShift } = props;
  const { isMobile } = useIsMobile();
  const { t } = useTranslation('HelpRequestScreen');

  const [email, setEmail] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [employerName, setEmployerName] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  // const [files, setFiles] = useState<any[]>([]);

  const isSubmitDisabled =
    !email || !firstName || !lastName || !employerName || !message;

  // SAVE FOR ADDITION OF ATTACHMENTS
  // const fileToBlob = async (fileToConvert: File): Promise<string> => {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       if (reader.result) {
  //         console.log(reader.result)
  //         const b64String = (reader.result as string).split(',')[1]
  //         resolve(b64String);
  //       } else {
  //         reject;
  //       }
  //     };
  //     reader.onerror = reject;
  //     reader.readAsDataURL(fileToConvert);
  //   });
  // };

  const handleSendRequest = async () => {
    const requestData: HelpRequestDataType = {
      email,
      firstName,
      lastName,
      employerName,
      message
      // fileData: undefined - // SAVE FOR ADDITION OF ATTACHMENTS
    };

    // SAVE FOR ADDITION OF ATTACHMENTS
    // if (files && files.length > 0) {
    //   const fileDataPromises = files.map(async file => {
    //     const fileString = await fileToBlob(file);
    //     const fileType = file.type;

    //     return { fileType, fileString };
    //   });
    //   const fileData = await Promise.all(fileDataPromises);

    //   requestData.fileData = fileData;
    // }

    onPressSubmit(requestData);
  };

  useEffect(() => {
    if (me) {
      setEmail(me.email ? me.email : '');
      setFirstName(me.firstName);
      setLastName(me.lastName);
      setEmployerName(
        me.employerProfile?.companyName ? me.employerProfile?.companyName : ''
      );
    }
  }, []);

  const labelStyle = {
    lineHeight: 16,
    color: FOHColors.PRIMARY_TEAL_P1,
    textTransform: 'uppercase',
    marginBottom: 5
  };

  const rowStyle = {
    width: '100%',
    marginTop: isMobile ? 0 : 20,
    flexDirection: isMobile ? 'column' : 'row',
    justifyContent: 'space-between'
  };

  const formSectionStyle = {
    width: isMobile ? '100%' : '48%',
    marginTop: isMobile ? 20 : 0
  };

  return (
    <FOHReactiveModal
      leftContentShift={leftContentShift}
      open={true}
      dialog={!isMobile}
      close={close}
    >
      <FOHView
        style={{
          width: '90%',
          marginTop: 16
        }}
      >
        <FOHHeaderH1>{t('helpRequestModalHeaderText')}</FOHHeaderH1>
        <FOHDetailText>{t('helpRequestModalDetailText')}</FOHDetailText>
      </FOHView>
      <FOHView
        style={{
          width: '90%',
          backgroundColor: FOHColors.GRAYSCALE_10,
          padding: 18,
          marginTop: 16,
          marginBottom: 24,
          borderRadius: 24,
          zIndex: 2
        }}
      >
        <FOHForm>
          <FOHView style={rowStyle}>
            <FOHView style={formSectionStyle}>
              <FOHSectionLabel style={labelStyle}>
                {t('EmployerAccountFeature:firstNamePlaceholder')}
              </FOHSectionLabel>
              <FOHTextField
                value={firstName}
                autoCompleteType="name"
                onChangeText={(value: string) => setFirstName(value)}
                required
                style={{ flexDirection: 'row' }}
              />
            </FOHView>
            <FOHView style={formSectionStyle}>
              <FOHSectionLabel style={labelStyle}>
                {t('EmployerAccountFeature:lastNamePlaceholder')}
              </FOHSectionLabel>
              <FOHTextField
                value={lastName}
                autoCompleteType="name"
                onChangeText={(value: string) => setLastName(value)}
                required
                style={{ flexDirection: 'row' }}
              />
            </FOHView>
          </FOHView>
          <FOHView style={rowStyle}>
            <FOHView style={formSectionStyle}>
              <FOHSectionLabel style={labelStyle}>
                {t('ProfileFeature:email')}
              </FOHSectionLabel>
              <FOHTextField
                value={email}
                autoCompleteType="email"
                onChangeText={(value: string) => setEmail(value)}
                required
                style={{ flexDirection: 'row' }}
              />
            </FOHView>
            <FOHView style={formSectionStyle}>
              <FOHSectionLabel style={labelStyle}>
                {t('common:workHistory:employerNameLabel')}
              </FOHSectionLabel>
              <FOHTextField
                value={employerName}
                onChangeText={(value: string) => setEmployerName(value)}
                style={{ flexDirection: 'row' }}
              />
            </FOHView>
          </FOHView>
          <FOHView style={{ marginTop: 20 }}>
            <FOHSectionLabel style={labelStyle}>
              {t('howCanWeHelp')}
            </FOHSectionLabel>
            <FOHMultiLineTextField
              style={{
                height: 250
              }}
              value={message}
              onChangeText={setMessage}
              placeholder={t('ProfileFeature:messageLabel')}
            />
          </FOHView>
          {/* SAVE FOR ADDITION OF ATTACHMENTS */}
          {/* <FOHView style={rowStyle}> 
            <FOHSectionLabel style={labelStyle}>
              {t('uploadAttachmentsLabel')}
            </FOHSectionLabel>
            <WebDropZone files={files} setFiles={setFiles} />
          </FOHView> */}
        </FOHForm>
      </FOHView>
      <FOHView style={{ width: '90%', marginTop: 40 }}>
        <FOHFormFlowButtons
          nextButtonlabel={t('common:send')}
          onPressNextButton={handleSendRequest}
          isNextDisabled={isSubmitDisabled}
          previousButtonLabel={t('common:cancel')}
          onPressPreviousButton={onPressCancel}
        />
      </FOHView>
    </FOHReactiveModal>
  );
};
