import React, { useRef } from 'react';

import { StyleProp, ViewStyle } from 'react-native';
import styled from 'styled-components/native';
import { useHover } from 'react-native-web-hooks';
import { path } from 'ramda';

import {
  FOHToolTip,
  FOHToolTipType,
  FOHColors,
  FOHTouchableOpacity,
  FOHView
} from '../../ingredients';

import { FOHButtonProps, ButtonText } from './FOHButton';

export interface FOHInverseButtonProps extends FOHButtonProps {
  buttonHorPadding?: number;
  canSelect?: boolean;
  centerIcon?: boolean;
  color?: string;
  borderColor?: string;
  enableHover?: boolean;
  enableUnderline?: boolean;
  helpText?: string;
  icon?: any;
  backIcon?: any;
  opaque?: boolean;
  selected?: boolean;
  style?: StyleProp<ViewStyle>;
  textColor?: string;
  toolTipType?: FOHToolTipType;
}

const getTextColor = (props: any) =>
  props.disabled && !props.canSelect
    ? FOHColors.SILVER_POLISH
    : props.textColor || props.color || FOHColors.GRAYSCALE_900;

const getBorderColor = (props: any) =>
  (props.disabled && !props.canSelect) || !props.selected
    ? FOHColors.GRAYSCALE_300
    : props.borderColor || props.color || FOHColors.GRAYSCALE_300;

const getButtonColor = (props: any) =>
  (props.disabled && !props.canSelect) || !props.selected
    ? FOHColors.WHITE
    : props.opaque
    ? props.color
    : props.color + '40' || FOHColors.WHITE;

const getOpacity = (props: any) =>
  props.selected && props.canSelect ? 0.8 : 1.0;

export const FOHInverseButton = (props: FOHInverseButtonProps) => {
  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);

  return (
    <FOHTouchableOpacity
      disabled={props.disabled}
      onPress={props.onPress}
      testID={props.testID || `inverse-button-${props.title}`}
      style={[{ width: '100%', maxWidth: 375 }, props.style]}
    >
      <StyledButton
        style={{
          backgroundColor: getButtonColor(props),
          opacity: getOpacity(props),
          borderColor: getBorderColor(props),
          height: path(['style', 'height'], props) || 40,
          borderRadius: path(['style', 'borderRadius'], props) || 4,
          ...(props?.buttonHorPadding && {
            paddingLeft: props.buttonHorPadding,
            paddingRight: props.buttonHorPadding
          })
        }}
      >
        {props.enableHover ? (
          <FOHToolTip
            helpText={props.helpText}
            open={isHovered}
            type={props.toolTipType}
            style={
              props.toolTipType === FOHToolTipType.LEFT
                ? { width: 200, right: 60, top: 10 }
                : {}
            }
          />
        ) : (
          <></>
        )}

        <FOHView
          ref={hoverRef}
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            maxWidth: '100%',
            left: !!props.icon && !props.centerIcon ? -6 : 0,
            right: !!props.backIcon && !props.centerIcon ? -6 : 0
          }}
        >
          {props.icon ? props.icon() : undefined}
          {props.title ? (
            <ButtonText
              numberOfLines={2}
              style={{
                paddingLeft: props.icon ? 6 : 0,
                paddingRight: props.backIcon ? 6 : 0,
                color: getTextColor(props),
                opacity: 1.0,
                textDecorationLine: props.enableUnderline ? 'underline' : 'none'
              }}
            >
              {props.title}
            </ButtonText>
          ) : (
            <></>
          )}
          {props.backIcon ? props.backIcon() : undefined}
        </FOHView>
      </StyledButton>
    </FOHTouchableOpacity>
  );
};

const StyledButton = styled(FOHView)`
  align-items: center;
  border-radius: 4px;
  justify-content: center;
  height: 40px;
  border: 1px solid ${FOHColors.GRAYSCALE_300};
  background-color: ${FOHColors.WHITE};
  flex-direction: row;
  width: 100%;
`;
