import React from 'react';

import { StyleProp } from 'react-native';

import {
  FOHHeaderText,
  Screen,
  FOHModalProps,
  FOHSpace,
  FOHSpaceSmall,
  FOHSmallDetailLabel,
  FOHToolBoxIcon,
  FOHInverseButton,
  FOHBoostIcon,
  FOHColors,
  FOHView
} from '../../ingredients';
import {
  FOHFormFlowButtons,
  FOHGoBack,
  FOHNewPositionForm,
  FOHNewPositionFormProps
} from '../../recipes';

interface FOHAddPositionScreenProps
  extends FOHModalProps,
    FOHNewPositionFormProps {
  title?: string;
  description?: string;
  error?: any;
  largeHeader?: boolean;
  mobile?: boolean;
  align?: StyleProp<any>;
  onBoostPost?: () => void;
  boostPostTitle?: string;
  isMobile?: boolean;
  submitButtonLabel?: string;
  cancelButtonLabel?: string;
  onCancel?: () => void;
  onSubmit?: () => void;
  hideHeader?: boolean;
}

export const FOHNewPositionScreen = (props: FOHAddPositionScreenProps) => (
  <>
    <Screen>
      {!!props.onCancel && props.mobile && (
        <FOHGoBack goBack={props.onCancel} />
      )}

      <FOHView
        style={{
          justifyContent: props.align || 'center',
          alignItems: props.align || 'center',
          paddingTop: props.hideHeader ? 10 : 72
        }}
      >
        {!props.hideHeader && (
          <FOHView
            style={{
              justifyContent:
                props.align || props.isMobile ? 'center' : 'flex-start',
              paddingLeft: 26,
              paddingRight: 26
            }}
          >
            <FOHView
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent:
                  props.align || props.isMobile ? 'center' : 'flex-start',
                flexWrap: props.isMobile ? 'wrap-reverse' : 'nowrap'
              }}
            >
              <FOHHeaderText
                style={{
                  textAlign: props.align || 'left',
                  color: FOHColors.PRIMARY_TEAL_P1
                }}
              >
                <FOHToolBoxIcon
                  style={{
                    tintColor: FOHColors.PRIMARY_TEAL_P1,
                    height: 26,
                    width: 26
                  }}
                />
                <FOHSpaceSmall />
                {props.title}
              </FOHHeaderText>
              {!!props.onBoostPost && !!props.boostPostTitle && (
                <FOHInverseButton
                  icon={() => (
                    <FOHBoostIcon style={{ tintColor: FOHColors.WHITE }} />
                  )}
                  color={FOHColors.GREEN}
                  style={{
                    maxWidth: 190,
                    marginLeft: props.isMobile ? '' : 'auto'
                  }}
                  textColor={FOHColors.WHITE}
                  onPress={props.onBoostPost}
                  title={props.boostPostTitle}
                  opaque={true}
                  selected={true}
                />
              )}
            </FOHView>

            {!!props.description && (
              <FOHView
                style={{
                  width: '100%',
                  alignItems: 'flex-start',
                  maxWidth: 580
                }}
              >
                <FOHSpaceSmall />
                <FOHSmallDetailLabel
                  style={{
                    textAlign: 'left'
                  }}
                >
                  {props.description}
                </FOHSmallDetailLabel>
                <FOHSpace />
              </FOHView>
            )}
          </FOHView>
        )}
        <FOHView
          style={{
            width: '100%',
            maxWidth: 580,
            alignItems: 'center'
          }}
        >
          <FOHNewPositionForm {...props} />
          <FOHSpace />
          {!!props.onSubmit && props.mobile && !!props.submitButtonLabel && (
            <>
              <FOHSpace />
              <FOHSpace />
              <FOHSpace />
            </>
          )}

          <FOHView
            style={{
              width: '100%',
              maxWidth: props.isMobile ? '100%' : 580
            }}
          >
            {!!props.onSubmit && !!props.submitButtonLabel && (
              <FOHFormFlowButtons
                {...props}
                isNextDisabled={props.disabledSubmit}
                nextButtonlabel={props.submitButtonLabel!}
                onPressNextButton={props.onSubmit!}
                previousButtonLabel={props.cancelButtonLabel!}
                onPressPreviousButton={props.onCancel}
                nextButtonStyle={
                  props.onCancel
                    ? {}
                    : {
                        maxWidth: 280,
                        marginRight: 'auto',
                        marginLeft: 'auto'
                      }
                }
              />
            )}
          </FOHView>
        </FOHView>
      </FOHView>
    </Screen>
  </>
);
