import React, { useState, useRef } from 'react';

import { useHover } from 'react-native-web-hooks';
import { View } from 'react-native';
import { prop, toLower, defaultTo } from 'ramda';

import {
  FOHView,
  FOHSectionLabel,
  FOHLocationPin,
  FOHCurrencyInput,
  FOHSwitch,
  FOHMultiLineTextField,
  FOHTimeSelect,
  FOHToolTip,
  FOHToolTipType,
  FOHColors,
  FOHSpace,
  FOHSpaceSmallest
} from '../../ingredients';

import { FOHScheduleFormTitleBlock } from '../blocks/FOHScheduleFormTitleBlock';
import { FOHSearchableSelectDropdown } from '../select/FOHSearchableSelectDropdown';
import { FOHDropdownSelectOption } from '../select/FOHBorderlessSelectDropdown';
import {
  FOHSelectItemProps,
  FOHSelect,
  FOHSelectItem
} from '../select/FOHSelect';

export interface FOHOfferFormProps {
  children?: React.ReactNode;
  canlendarCmp?: any;
  titleLabel: string;
  candidateName: string;
  detailLabel: string;
  decorationImage: string;
  locationSubtitleLabel: string;

  style?: any;
  searchPlaceholder?: string;
  locationSelectedLabel?: string;
  locationSecondarySelectedText?: string;

  locationIcon?: any;
  locationOptions: Array<FOHDropdownSelectOption>;
  selectedLocation: string;
  selectedLocationLabel?: string;
  onLocationValueChange: (value: string) => void;
  locationLabel?: string;

  positionOptions: Array<FOHDropdownSelectOption>;
  selectedPosition: string;
  selectedPositionLabel?: string;
  onPositionValueChange: (value: string) => void;
  positionLabel?: string;

  candidateSectionLabel: string;
  time: string;
  setTime: (time?: string) => void;
  timePlaceholder: string;
  timeChoices: Array<FOHSelectItemProps>;

  compensationSectionLabel: string;
  compensation: string;
  setCompensation: (value: number) => void;
  compensationPlaceholder: string;

  typeLabel: string;
  selectedUnit: string;
  setSelectedUnit: (value: string) => void;
  unitOptions: Array<FOHSelectItemProps>;

  documentSectionLabel: string;

  customNoteLabel: string;
  includeCustomNote: boolean;
  setIncludeCustomNote: (val: boolean) => void;
  customNote: string;
  setCustomNote: (val: string) => void;
  customNotePlaceholder: string;
  customNoteHelper: string;

  isMobile?: boolean;
  error?: any;
  hideLocationSelection?: boolean;
  hideHeaderBlock?: boolean;

  documentsUpload?: React.ReactNode;
}

export const FOHOfferLetterForm = (props: FOHOfferFormProps) => {
  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);

  const [locationOpen, setLocationOpen] = useState(false);
  const [positionOpen, setPositionOpen] = useState(false);
  const [locationText, setLocationText] = useState('');
  const [positionText, setPositionText] = useState('');

  const locationOptions = props.locationOptions.filter(
    loc =>
      !locationText ||
      toLower(prop('label', loc)).includes(toLower(locationText)) ||
      toLower(prop('sublabel', loc)).includes(toLower(locationText))
  );

  const positionOptions = props.positionOptions.filter(
    loc =>
      !positionText ||
      toLower(prop('label', loc)).includes(toLower(positionText)) ||
      toLower(prop('sublabel', loc)).includes(toLower(positionText))
  );

  return (
    <FOHView style={{ maxWidth: 716 }} testID={'FOHOfferLetterForm'}>
      {!props.hideHeaderBlock ? (
        <FOHScheduleFormTitleBlock
          titleLabel={props.titleLabel}
          candidateName={props.candidateName}
          detailString={props.detailLabel}
          decorationImage={props.decorationImage}
        />
      ) : (
        <></>
      )}
      <FOHView>
        {!props.hideLocationSelection ? (
          <>
            <FOHSectionLabel>{props.locationSubtitleLabel}</FOHSectionLabel>
            <FOHView style={{ zIndex: 3 }}>
              <FOHView style={{ marginVertical: 5, zIndex: 4 }}>
                <FOHSearchableSelectDropdown
                  open={locationOpen}
                  setOpen={setLocationOpen}
                  selectedValue={props.selectedLocation}
                  selectedLabel={props.selectedLocationLabel}
                  onSearchChange={setLocationText}
                  searchValue={locationText}
                  searchPlaceholder={props.searchPlaceholder}
                  onValueChange={(value: string) => {
                    props.onLocationValueChange(value);
                  }}
                  options={locationOptions}
                  fieldLabel={props.locationLabel}
                  icon={FOHLocationPin}
                  style={{ width: '100%', backgroundColor: FOHColors.WHITE }}
                  testID={'FOHOfferLetterForm-location-dropdown'}
                ></FOHSearchableSelectDropdown>
              </FOHView>
              <FOHView style={{ marginTop: 5, marginBottom: 16, zIndex: 3 }}>
                <FOHSearchableSelectDropdown
                  open={positionOpen}
                  setOpen={setPositionOpen}
                  selectedValue={props.selectedPosition}
                  selectedLabel={props.selectedPositionLabel}
                  onSearchChange={setPositionText}
                  searchValue={positionText}
                  searchPlaceholder={props.searchPlaceholder}
                  onValueChange={(value: string) => {
                    props.onPositionValueChange(value);
                  }}
                  options={positionOptions}
                  fieldLabel={props.positionLabel}
                  icon={FOHLocationPin}
                  style={{ width: '100%', backgroundColor: FOHColors.WHITE }}
                  testID={'FOHOfferLetterForm-position-dropdown'}
                ></FOHSearchableSelectDropdown>
              </FOHView>
            </FOHView>
          </>
        ) : (
          <></>
        )}
        <FOHSectionLabel
          style={{
            lineHeight: 16,
            color: FOHColors.PRIMARY_TEAL_P1,
            textTransform: 'uppercase'
          }}
        >
          {props.candidateSectionLabel}
        </FOHSectionLabel>
        <FOHView
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            marginVertical: 5,
            zIndex: 2
          }}
        >
          <FOHView style={{ width: '49%' }}>{props.canlendarCmp}</FOHView>
          <FOHSpace />
          <FOHView style={{ width: '49%' }}>
            <FOHTimeSelect
              setTime={props.setTime}
              time={props.time}
              placeholder={props.timePlaceholder}
              timeChoices={props.timeChoices}
              width={'50%'}
            />
          </FOHView>
        </FOHView>
        <FOHSpace />
        <FOHView>{props.children}</FOHView>
        <FOHSpace />
        <FOHSpaceSmallest />
        <FOHSectionLabel
          style={{
            lineHeight: 16,
            color: FOHColors.PRIMARY_TEAL_P1,
            textTransform: 'uppercase'
          }}
        >
          {props.compensationSectionLabel}
        </FOHSectionLabel>
        <FOHView
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            marginVertical: 8,
            zIndex: 1
          }}
        >
          <FOHView style={{ width: '49%' }}>
            <FOHCurrencyInput
              onChangeText={(_text, rawText) => {
                props.setCompensation(parseFloat(rawText) / 100);
              }}
              value={props.compensation}
              placeholder={props.compensationPlaceholder}
            />
          </FOHView>
          <FOHSpace />
          <FOHView style={{ width: '24%' }}>
            <FOHSelect
              placeholder={props.typeLabel}
              neverEmpty={true}
              selectedValue={props.selectedUnit}
              onValueChange={(val: string) => props.setSelectedUnit(val)}
              testID={'pay-type-menu'}
            >
              {props.unitOptions.map((opt, index) => (
                <FOHSelectItem {...opt} key={index} />
              ))}
            </FOHSelect>
          </FOHView>
        </FOHView>
        <FOHSpace />
        <FOHSectionLabel
          style={{
            lineHeight: 16,
            color: FOHColors.PRIMARY_TEAL_P1,
            textTransform: 'uppercase'
          }}
        >
          {props.documentSectionLabel}
        </FOHSectionLabel>
        <FOHView
          style={{ marginTop: 8, marginBottom: 5 }}
          testID={'FOHOfferLetterForm-dropzone'}
        >
          {props.documentsUpload}
        </FOHView>
        <FOHSpace />
        <FOHView
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            zIndex: 1
          }}
        >
          <FOHSectionLabel
            style={{
              lineHeight: 16,
              color: FOHColors.PRIMARY_TEAL_P1,
              textTransform: 'uppercase'
            }}
          >
            {props.customNoteLabel}
          </FOHSectionLabel>
          <FOHView>
            <View style={{ position: 'absolute' }}>
              <FOHToolTip
                type={FOHToolTipType.LEFT}
                helpText={props.customNoteHelper}
                open={isHovered}
                style={{ width: 200, right: 170, top: 6 }}
                width={500}
              />
            </View>
            <View ref={hoverRef}>
              <FOHSwitch
                value={defaultTo(true, props.includeCustomNote)}
                onValueChange={props.setIncludeCustomNote}
              />
            </View>
          </FOHView>
        </FOHView>
        {props.includeCustomNote ? (
          <FOHView style={{ marginVertical: 5 }}>
            <FOHMultiLineTextField
              onChangeText={props.setCustomNote}
              value={props.customNote}
              placeholder={props.customNotePlaceholder}
              max={500}
              style={{ paddingRight: 7 }}
              testID={'FOHOfferLetterForm-custom-note'}
            />
          </FOHView>
        ) : (
          <></>
        )}
      </FOHView>
    </FOHView>
  );
};
