import React, { FunctionComponent, MutableRefObject, useRef } from 'react';

import { View } from 'react-native';
import { useOnClickOutside } from 'use-hooks';
import styled from 'styled-components/native';

import { CommonProps } from '../../typings';
import {
  FOHTouchableOpacity,
  FOHView,
  FOHWhiteX,
  FOHPrevIcon,
  FOHNextIcon,
  GrayBlur,
  FOHBlankImage
} from '../../ingredients';
import { useIsMobile } from '../../spices';
import { PaginationLogic } from '../../templates';

interface FOHLightBoxModalProps {
  isLightBoxOpen: boolean;
  paginationLogic: Pick<
    PaginationLogic,
    'handleNextPage' | 'handlePreviousPage' | 'numberOfPages'
  >;
  onCloseLightBox: () => void;
}

export const FOHLightBoxModal: FunctionComponent<FOHLightBoxModalProps> = ({
  children,
  isLightBoxOpen,
  onCloseLightBox,
  paginationLogic: { handleNextPage, handlePreviousPage, numberOfPages }
}) => {
  const wrapperRef = useRef<View>(null);
  const isMobile = useIsMobile();
  const hasMultipleImages = numberOfPages > 1;

  useOnClickOutside(
    wrapperRef as unknown as MutableRefObject<Node>,
    onCloseLightBox
  );

  if (!isLightBoxOpen) return null;

  return (
    <ModalWrapper isMobile={isMobile}>
      <GrayBlur />
      <ContentWrapper isMobile={isMobile} ref={wrapperRef}>
        {hasMultipleImages && (
          <FOHTouchableOpacity
            onPress={handlePreviousPage}
            style={{ zIndex: 2 }}
          >
            <Circle isMobile={isMobile} style={{ left: -16, marginRight: 24 }}>
              <FOHPrevIcon />
            </Circle>
          </FOHTouchableOpacity>
        )}

        <MediaWrapper isMobile={isMobile}>
          <FOHTouchableOpacity onPress={onCloseLightBox}>
            <FOHWhiteX
              style={{
                color: 'white',
                position: 'absolute',
                right: 0,
                top: -20,
                transform: 'translateY(-100%)'
              }}
            />
          </FOHTouchableOpacity>

          <FOHView style={{ borderRadius: 8, overflow: 'hidden' }}>
            {children || <FOHBlankImage />}
          </FOHView>
        </MediaWrapper>

        {hasMultipleImages && (
          <FOHTouchableOpacity onPress={handleNextPage} style={{ zIndex: 2 }}>
            <Circle isMobile={isMobile} style={{ marginLeft: 24, right: -16 }}>
              <FOHNextIcon />
            </Circle>
          </FOHTouchableOpacity>
        )}
      </ContentWrapper>
    </ModalWrapper>
  );
};

export interface CustomStyledElementProps {
  isMobile?: boolean;
}

export const ContentWrapper = styled.View<CommonProps>`
  flex-direction: row;
  height: 100%;
  margin: auto;
  align-items: center;

  ${({ isMobile }: CustomStyledElementProps) =>
    isMobile &&
    `
  margin-left: 24px;
  width: calc(100% - 48px);
  margin-right: 24px;
    `};
`;

export const ModalWrapper = styled.View<CommonProps>`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;

  /* margin for not overlapping with navbar */
  ${({ isMobile }: CustomStyledElementProps) =>
    isMobile &&
    `
  margin-top: 116px;
  height: auto;
`}
`;

export const Circle = styled.View<CommonProps>`
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 52px;
  height: 52px;

  ${({ isMobile }: CustomStyledElementProps) =>
    isMobile &&
    `
    z-index: 2;
    width: 32px;
    position: absolute;
    height: 32px;
    margin:0;
    `}
`;

export const MediaWrapper = styled.View<CommonProps>`
  width: ${({ isMobile }: CustomStyledElementProps) => isMobile && '100%'};
`;
