import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { defaultTo, last, length, prop } from 'ramda';
import Loadable from '@loadable/component';

import { FOHNewPositionScreen, FOHView } from '../../components';
import { multiSelectItem, scrollToTop, useIsMobile } from '../../utils';
import {
  BOOST,
  EMPLOYER_DASHBOARD,
  FEED,
  POSITION_CREATION_SUCCESS_MODAL
} from '../../constants';
import { useNavigation } from '../../utils/navigation';
import { useDismissableModals } from '../../hooks';

import { QLOCATIONID, QPOSITION } from '../Search';
import { useCurrentLocationContext } from '../Locations/useCurrentLocationContext';

import { usePositionMutation } from './usePositionMutation';
import { paymentTypeOptions } from './positionPayments';

// react-quil does not SSR
const JobDescriptionEditor = Loadable(() =>
  import('../EmployerPositions/JobDescriptionEditor')
);

const NewPositionScreen = props => {
  const { navigateTo, location: navLocation, goBack } = useNavigation();
  const { t } = useTranslation('PositionsFeature');
  const { isMobile } = useIsMobile();
  const paths = navLocation.pathname.split('/');
  const positionGlobalId = last(paths) || '';

  const originFrom = defaultTo(null, prop('originFrom', navLocation?.state));

  const { selectLocationFilter, currentLocation, locationFilter, myLocations } =
    useCurrentLocationContext({
      skipLocationsQuery: false,
      props
    });

  const { dismissableModals } = useDismissableModals();

  const [openDropdown, setOpenDropdown] = useState('');

  const {
    position,
    name,
    setName,
    error,
    selectedPositionTypes,
    setSelectedPositionTypes,
    selectedLocation,
    setSelectedLocation,
    inProgress,
    minPay,
    setMinPay,
    maxPay,
    setMaxPay,
    paymentType,
    setPaymentType,
    handleSave,
    positionTypeText,
    setPositionTypeText,
    positionTypeOptions,
    includeSalary,
    setIncludeSalary,
    notifications,
    setSendNotifications,
    jobDescription,
    setJobDescription,
    indeedEasyapplyOptout,
    setIndeedEasyapplyOptout
  } = usePositionMutation({
    positionGlobalId: positionGlobalId !== 'new' ? positionGlobalId : '',
    initialLocation: locationFilter,
    selectLocationFilter,
    myLocations: myLocations,
    initialLocationSlug: prop('slug', currentLocation)
  });
  console.log(currentLocation);

  useEffect(() => {
    if (locationFilter) setSelectedLocation(locationFilter);
  }, [locationFilter]);

  return (
    <FOHView
      style={{
        maxHeight: isMobile ? '72vh' : undefined,
        alignItmes: 'center',
        paddingBottom: isMobile ? 0 : 100
      }}
    >
      <FOHNewPositionScreen
        {...props}
        title={
          positionGlobalId !== 'new' ? t('editPosition') : t('addPosition')
        }
        align={'center'}
        description={t('positionExplanation')}
        positionNamePlaceholder={t('positionNamePlaceholder')}
        positionTypePlaceholder={t('positionTypePlaceholder')}
        doneText={t('doneText')}
        trainingPlaceholder={t('trainingPlaceholder')}
        locationPlaceholder={t('locationPlaceholder')}
        experiencePlaceholder={t('experiencePlaceholder')}
        sendNotificationsLabel={t('sendNotificationsLabel')}
        sendNotifications={notifications}
        toggleNotifications={() => setSendNotifications(!notifications)}
        benefitsPlaceholder={t('benefitsPlaceholder')}
        dressCodePlaceholder={t('dressCodePlaceholder')}
        locations={myLocations.map(loc => ({
          label: prop('displayName', loc),
          value: prop('id', loc),
          sublabel: prop('formattedAddress', loc)
        }))}
        selectedLocation={selectedLocation}
        setSelectedLocation={val => {
          val && val !== '' && setSelectedLocation(val);
        }}
        locationSeachPlaceholder={t('common:search')}
        key={position}
        setOpenDropdown={id => setOpenDropdown(id)}
        openDropdown={openDropdown}
        close={() => setOpenDropdown('')}
        closeLabel={
          length(selectedPositionTypes) > 0
            ? t('PositionSearchAheadFeature:applySearchLabel')
            : t('PositionSearchAheadFeature:closeLabel')
        }
        positionName={name}
        setPositionName={setName}
        positionTypes={positionTypeOptions}
        setPositionTypeText={setPositionTypeText}
        positionTypeText={positionTypeText}
        clearPositionTypes={() => {
          setPositionTypeText('');
          setSelectedPositionTypes([]);
        }}
        selectedPositionTypes={selectedPositionTypes}
        setSelectedPositionTypes={value => {
          setPositionTypeText('');
          multiSelectItem(
            value,
            setSelectedPositionTypes,
            selectedPositionTypes
          );
        }}
        includeSalaryTermsText={t('includeSalaryTermsText')}
        includeSalaryLabel={t('includeSalaryLabel')}
        includeSalaryValue={includeSalary}
        toggleIncludeSalary={() => setIncludeSalary(!includeSalary)}
        minPay={minPay}
        minPayLabel={t('minPayLabel')}
        setMinPay={val => (!val ? setMinPay('$') : setMinPay(val))}
        maxPay={maxPay}
        maxPayLabel={t('maxPayLabel')}
        setMaxPay={val => (!val ? setMaxPay('$') : setMaxPay(val))}
        paymentType={paymentType}
        paymentTypeLabel={t('paymentTypeLabel')}
        paymentTypeOptions={paymentTypeOptions(t)}
        setPaymentType={setPaymentType}
        onBoostPost={
          positionGlobalId !== 'new'
            ? () => {
                navigateTo({
                  pathname: `${FEED}${BOOST}/${positionGlobalId}`,
                  search: navLocation.search
                });
              }
            : undefined
        }
        boostPostTitle={t('CandidateFeed:boostPostCardButton')}
        indeedEasyapplyOptoutLabel={t('indeedEasyapplyOptoutLabel')}
        indeedEasyapplyOptout={indeedEasyapplyOptout}
        setIndeedEasyapplyOptout={setIndeedEasyapplyOptout}
        isMobile={isMobile}
        disabledSubmit={inProgress}
        mobile={isMobile}
        onSubmit={async () => {
          const res = await handleSave();
          if (!res || (!res.locationGlobalId && !res.positionGlobalId)) {
            scrollToTop({});
            return;
          }

          scrollToTop({});

          await (async () => {
            let redirectTo =
              originFrom && originFrom !== FEED ? originFrom : FEED;

            const navStates = {
              position: {
                name: res?.position?.positionName,
                location: {
                  id: res?.locationGlobalId
                }
              },
              origin: originFrom ?? undefined
            };

            let queryParams;

            if (
              dismissableModals.includes(POSITION_CREATION_SUCCESS_MODAL) &&
              currentLocation.paymentStatus !== 'SUBSCRIBED'
            ) {
              queryParams = {
                search:
                  redirectTo === FEED
                    ? `?${QLOCATIONID}=${res?.locationGlobalId}&${QPOSITION}=${res?.positionGlobalId}&modal=${POSITION_CREATION_SUCCESS_MODAL}`
                    : `?modal=${POSITION_CREATION_SUCCESS_MODAL}`
              };
            }

            const pathToNavigate =
              redirectTo === FEED
                ? FEED
                : `${EMPLOYER_DASHBOARD}/${res?.locationGlobalId}`;

            navigateTo(
              {
                pathname: pathToNavigate,
                ...(queryParams || {})
              },
              {
                ...((queryParams && { state: navStates }) || {})
              }
            );
          })();
        }}
        onCancel={goBack}
        cancelButtonLabel={t('cancelText')}
        submitButtonLabel={
          inProgress ? t('CandidateFeed:generatingMatches') : t('doneText')
        }
        error={error}
      >
        <JobDescriptionEditor
          description={jobDescription}
          setDescription={setJobDescription}
          error={prop('jobDescription', error)}
          {...props}
          testID="new-position-job-description"
        />
      </FOHNewPositionScreen>
    </FOHView>
  );
};

export default NewPositionScreen;
