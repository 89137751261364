import React from 'react';

import resume_icon from '../../../images/resume_icon.svg';

import { FOHImage } from '../../ingredients';

export const FOHResumeIcon = (props?: any) => (
  <FOHImage
    source={{ uri: resume_icon }}
    style={{
      width: 16,
      height: 16,
      ...props?.style
    }}
  />
);
