import React, { FunctionComponent } from 'react';

import notebook_with_success from '../../../images/notebook_with_success.svg';

import { FOHImage } from '../../ingredients';
import { FOHIconProps } from '../../typings';

export const FOHNotebookWithSuccessIcon: FunctionComponent<FOHIconProps> = ({
  style
}) => (
  <FOHImage
    source={{ uri: notebook_with_success }}
    style={[{ width: 103, height: 103 }, style]}
  />
);
