import React from 'react';

import notificationPendingInterview from '../../../images/notification-pending-interview.svg';

import { FOHImage } from '../../ingredients';

export interface FOHNotificationPendingInterviewProps {}

export const FOHNotificationPendingInterview = (
  props: FOHNotificationPendingInterviewProps
) => (
  <FOHImage
    {...props}
    source={{ uri: notificationPendingInterview }}
    style={{ width: 24, height: 24 }}
    testID={'FOHNotificationPendingInterview'}
  />
);
