import React, { useEffect, useMemo, useState } from 'react';

import { defaultTo, path, prop } from 'ramda';
import { Navigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  FOHColors,
  FOHFonts,
  FOHLabel,
  FOHLinkOut,
  FOHSmallDetailLabel,
  FOHSpace,
  FOHSpaceLarge,
  FOHSpaceSmallest,
  FOHView,
  Screen
} from '../../components';
import { FEED, ROLES, SUBSCRIBE_LOCATIONS_POSITIONS } from '../../constants';
import {
  useDisableBackgroundScroll,
  useIsMobile,
  useNavigation
} from '../../utils';

import { useGetMeData } from '../SignIn';
import { SetPasswordModal } from '../ClaimAccount/SetPasswordModal';
import { OnboardingBilling } from '../Billing';
import { LogoLoader } from '../Loading/LogoLoader';

interface SubscribeProps {}

const Subscribe: React.FC<SubscribeProps> = props => {
  const { t } = useTranslation('PendingEmployerProfile');
  const { modal } = useParams();

  const { location: navLocation } = useNavigation();

  const { isMobile } = useIsMobile();

  const { data: me, refetch: meQueryRefetch } = useGetMeData({
    fetchPolicy: 'cache-and-network'
  });

  const isInvited = defaultTo(false, prop('unclaimed', me));

  const [isOpenInviteUserOrPasswordModal, setIsOpenInviteUserOrPasswordModal] =
    useState(!!isInvited || modal === 'password');

  const [isLoading, setIsLoading] = useState(false);

  const employerProfileStatus = defaultTo(
    null,
    path(['employerProfile', 'status'], me)
  );

  const currentUserRole = defaultTo(
    null,
    path(['employerProfileUser', 'role'], me)
  ) as keyof typeof ROLES;

  const isEmployerProfileSubscribed = useMemo(
    () => employerProfileStatus && employerProfileStatus === 'SUBSCRIBED',
    [employerProfileStatus]
  );

  const blurStyle = useMemo(() => {
    return isOpenInviteUserOrPasswordModal
      ? {
          filter: 'blur(4px)',
          position: 'absolute',
          width: '100vw',
          height: '100vh',
          zIndex: 999,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0
        }
      : {};
  }, [isOpenInviteUserOrPasswordModal]);

  useDisableBackgroundScroll([isOpenInviteUserOrPasswordModal]);

  useEffect(() => {
    if (
      navLocation?.pathname === SUBSCRIBE_LOCATIONS_POSITIONS &&
      navLocation?.search.includes('checkout=success')
    ) {
      setIsLoading(true);
      setTimeout(() => {
        meQueryRefetch();
        setIsLoading(false);
      }, 3000);
    }
  }, [navLocation?.pathname, navLocation?.search]);

  return (
    <>
      {isEmployerProfileSubscribed && (
        <Navigate
          to={{
            pathname: FEED
          }}
        />
      )}
      {isLoading && (
        <Screen style={{ height: '100vh' }}>
          <FOHView
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
              paddingHorizontal: 16,
              paddingTop: 60,
              paddingBottom: 16
            }}
          >
            <LogoLoader />
          </FOHView>
        </Screen>
      )}
      {currentUserRole &&
      (currentUserRole === ROLES.USER ||
        currentUserRole === ROLES.INTERVIEWER) ? (
        <FOHView
          style={{
            width: '100%',
            alignItems: 'center',
            paddingTop: 40,
            height: '100vh',
            paddingHorizontal: isMobile ? 15 : 0
          }}
        >
          <Screen
            style={{
              justifyContent: 'flex-start',
              alignItems: 'center',
              paddingTop: isMobile ? 60 : 120,
              ...blurStyle
            }}
          >
            <FOHView
              style={{
                maxWidth: 821,
                width: '100%',
                alignItems: 'flex-start',
                paddingHorizontal: 62,
                paddingVertical: 49
              }}
            >
              <FOHView>
                <FOHLabel bold={true} style={{ fontSize: 44, lineHeight: 56 }}>
                  {t('accountSetupInProgressHeader')}
                </FOHLabel>
                <FOHSpaceLarge />
                <FOHLabel style={{ fontSize: 34, lineHeight: 46 }}>
                  {t('accountSetupBodyTextForNonAdmin')}
                </FOHLabel>
              </FOHView>
              <FOHSpace />
              <FOHView
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  width: '100%',
                  flexWrap: 'wrap'
                }}
              >
                <FOHSmallDetailLabel style={{ fontSize: 16, lineHeight: 24 }}>
                  {t('accountSetupFooterText')}
                </FOHSmallDetailLabel>
                <FOHSpaceSmallest />
                <FOHLinkOut
                  noIcon={true}
                  href={'mailto:support@fohandboh.com'}
                  labelStyle={{
                    color: FOHColors.PRIMARY_TEAL_400,
                    fontSize: 16,
                    lineHeight: 24,
                    fontWeight: 400,
                    fontFamily: FOHFonts.REGULAR
                  }}
                >
                  {t('contactSupportBtnText')}
                </FOHLinkOut>
              </FOHView>
            </FOHView>
          </Screen>
          {isOpenInviteUserOrPasswordModal && (
            <SetPasswordModal
              open={isOpenInviteUserOrPasswordModal}
              close={() => setIsOpenInviteUserOrPasswordModal(false)}
            />
          )}
        </FOHView>
      ) : null}
      {currentUserRole && currentUserRole === ROLES.ADMIN ? (
        <OnboardingBilling {...props} />
      ) : null}
    </>
  );
};

export default Subscribe;
