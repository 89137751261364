import React, { useRef, useEffect } from 'react';

import styled from 'styled-components/native';
import { useHover } from 'react-native-web-hooks';
import { Text } from 'react-native';

import {
  FOHView,
  FOHToolTip,
  FOHLabel,
  FOHBestMatchIcon
} from '../../ingredients';

interface FOHBestMatchBadgeProps {
  label: string;
  helpText?: string;
  style?: any;
  onHovering?: (hovering: boolean) => any;
}

export const FOHBestMatchBadge = (props: FOHBestMatchBadgeProps) => {
  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);

  useEffect(() => {
    props.onHovering && props.onHovering(isHovered);
  }, [isHovered]);

  return (
    <BadgeContainer ref={hoverRef}>
      <BadgeText
        style={{
          ...props.style,
          backgroundColor: '#035081',
          paddingLeft: 12,
          paddingRight: 12
        }}
      >
        <FOHView
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            flexDirection: 'row'
          }}
        >
          {props.helpText ? (
            <FOHToolTip
              open={isHovered}
              style={{
                width: 250,
                bottom: 10,
                right: -40
              }}
            >
              <FOHLabel style={{ color: '#ffffff' }}>{props.helpText}</FOHLabel>
            </FOHToolTip>
          ) : (
            <></>
          )}
          <FOHBestMatchIcon />
          <FOHView style={{ paddingLeft: 2 }} />
          <Text> {props.label}</Text>
        </FOHView>
      </BadgeText>
    </BadgeContainer>
  );
};

const BadgeContainer = styled(FOHView)`
  height: 24px;
  align-items: center;
  flex-direction: row;
`;

const BadgeText = styled(FOHLabel)`
  font-size: 12px;
  color: #ffffff;
  text-align: center;
  border-radius: 100px;
  height: 24px;
  justify-content: center;
  text-align: center;
  line-height: 100%;
  padding-top: 2px;
  padding-bottom: 2px;
  word-break: break-word;
  align-items: center;
  font-size: 12px;
`;
