import React from 'react';

import { getPositions } from '../positions';
const { useReducer, useEffect } = React;

const positionsReducer = (state, action) => {
  switch (action.type) {
    case 'setPositions':
      return [...action.positions];
    case 'updatePosition':
      return [
        action.position,
        ...state.filter(position => position.id !== action.position.id)
      ];
    case 'removePosition':
      return [...state.filter(position => position.id !== action.positionId)];
    case 'addPosition':
      return [action.position, ...state];
    default:
      return [];
  }
};

const positionActions = positionsDispatch => ({
  setPositions: positions =>
    positionsDispatch({ type: 'setPositions', positions: positions }),
  updatePosition: position =>
    positionsDispatch({ type: 'updatePosition', position }),
  removePosition: positionId =>
    positionsDispatch({ type: 'removePosition', positionId }),
  addPosition: position => positionsDispatch({ type: 'addPosition', position })
});

export const withPositions = Cmp => props => {
  const [positions, positionsDispatch] = useReducer(positionsReducer, []);
  const actions = positionActions(positionsDispatch);
  useEffect(() => {
    getPositions().then(res => {
      actions.setPositions(res.results);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Cmp {...props} positions={positions} {...actions} />;
};
