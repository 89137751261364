import React, { useState } from 'react';

import { withTranslation } from 'react-i18next';
import { compose, path, length } from 'ramda';

import { withIsMobile, handle } from '../../utils';
import { withUpdateUser, withUser } from '../../api/hocs';
import { requestEndorsement } from '../../api/endorsements';
import { FOHBannerType, FOHEmailListModal } from '../../components';

import { useBanner } from '../Navigation/useBanner';
import { useGetMeData } from '../SignIn';

const RequestEndorsementModal = props => {
  const { open, t } = props;
  const { setBanner } = useBanner();

  const [text, setText] = useState('');
  const [emails, setEmails] = useState(text.split(/(\s*,?\s*)+/));
  const [error, setError] = useState();

  const meQuery = useGetMeData();

  return (
    <FOHEmailListModal
      title={t('requestEndorsements')}
      detailText={t('requestEndorsementsDetail')}
      open={open}
      dialog={!props.isMobile}
      submitLabel={t('requestEndorsementsSubmit')}
      close={props.close}
      cancel={props.close}
      cancelText={t('requestEndorsementsCancel')}
      error={path(['email'], error)}
      handleSubmit={async () => {
        setError();
        const emailsToRequest = length(emails) > 0 ? [...emails, text] : [text];
        const requests = emailsToRequest
          .filter(email => email.includes('@') && email !== '')
          .map(async emailToRequest => {
            return requestEndorsement({ email: emailToRequest });
          });

        const [, err] = await handle(Promise.all(requests));
        if (err && err.fieldErrors) {
          setError(err.fieldErrors);
          return;
        }
        meQuery && meQuery.refetch();
        setBanner({
          bannerType: FOHBannerType.SUCCESS,
          bannerMessage: props.t('requestSent')
        });
        props.close();
      }}
      emails={emails}
      setEmails={setEmails}
      input={text}
      setInput={value => setText(value.replace(',', ' '))}
    />
  );
};

export default compose(
  withTranslation('EndorseFeature'),
  withUpdateUser,
  withUser,
  withIsMobile
)(RequestEndorsementModal);
