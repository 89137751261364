import React from 'react';

import { TouchableOpacity, View } from 'react-native';
import styled from 'styled-components/native';
import { length, defaultTo, prop } from 'ramda';

import {
  FOHLabel,
  FullWidthCard,
  FOHSectionLabel,
  FOHSpace,
  FOHButton,
  FOHDivider,
  FOHChefHatIcon,
  FOHSmallDetailLabel,
  FOHSpaceSmall,
  FOHEditMarker,
  FOHColors
} from '../../ingredients';

import { FOHEmptyWorkHistoryCell } from '../cells/FOHEmptyWorkHistoryCell';
import { FOHSmallWorkHistoryCell } from '../cells/FOHSmallWorkHistoryCell';

export interface WorkHistory {
  id?: string;
  end: string;
  start: string;
  position: string;
  employerName: string;
  image?: string;
  responsibilities?: string;
  locationLink?: string;
}
export interface FOHWorkHistoryTableProps {
  workHistoryLabel: string;
  workHistory?: Array<WorkHistory>;
  emptyWorkHistory?: string;
  openWorkHistoryEdit?: (id: string) => void;
  addWorkHistoryLabel: string;
  emptyWorkHistoryHeader: string;
  emptyWorkHistoryDetail: string;
  askForWorkHistoryBtnLabel: string;
  onPressAskForWorkHistory: () => void;
  emptyWorkHistoryImage: string;
  experienceLabel: string;
  yearsExp: string;
  openExperienceEdit?: () => void;
}

export const FOHWorkHistoryTable = (props: FOHWorkHistoryTableProps) => (
  <View style={{ width: '100%' }}>
    <FOHSectionLabel style={{ paddingBottom: 8, lineHeight: 24 }}>
      {props.workHistoryLabel}
    </FOHSectionLabel>
    {((props.workHistory && length(props.workHistory) !== 0) ||
      props.openWorkHistoryEdit) && (
      <FullWidthCard style={{ marginBottom: 24, padding: 16 }}>
        <View>
          <StatLabelContainer>
            <IconDataContainer>
              <FOHChefHatIcon style={{ tintColor: FOHColors.SILVER }} />
              <FOHSmallDetailLabel
                style={{ paddingLeft: 6, color: FOHColors.GRAYSCALE_500 }}
              >
                {props.experienceLabel}
              </FOHSmallDetailLabel>
            </IconDataContainer>
            {props.openExperienceEdit ? (
              <TouchableOpacity
                testID={`edit-experience`}
                onPress={props.openExperienceEdit}
              >
                <FOHEditMarker />
              </TouchableOpacity>
            ) : (
              <></>
            )}
          </StatLabelContainer>
          <FOHLabel style={{ paddingLeft: 24, lineHeight: 24 }}>
            {props.yearsExp}
          </FOHLabel>
          <FOHSpaceSmall />
          <View style={{ paddingTop: 8, paddingHorizontal: 3 }}>
            <FOHDivider />
          </View>
        </View>
        <View>
          {props.workHistory ? (
            props.workHistory.map((history, index) => (
              <View key={prop('id', history)}>
                <FOHSmallWorkHistoryCell
                  id={prop('id', history)}
                  key={prop('id', history)}
                  position={history.position}
                  employerName={history.employerName}
                  start={history.start}
                  end={history.end}
                  edit={props.openWorkHistoryEdit}
                  image={history.image}
                  locationLink={history.locationLink}
                  responsibilities={history.responsibilities}
                />
                {length(defaultTo([], props.workHistory)) - 1 !== index && (
                  <>
                    <FOHSpace />
                    <FOHDivider />
                  </>
                )}
              </View>
            ))
          ) : (
            <></>
          )}
          {/* {length(defaultTo([], props.workHistory)) === 0 && (
          <FOHLabel>{props.emptyWorkHistory}</FOHLabel>
        )} */}
        </View>
        {props.openWorkHistoryEdit ? (
          <>
            <FOHSpace />
            <FOHButton
              fullWidth={true}
              onPress={() =>
                props.openWorkHistoryEdit && props.openWorkHistoryEdit('')
              }
              title={props.addWorkHistoryLabel}
            />
          </>
        ) : (
          <></>
        )}
      </FullWidthCard>
    )}
    {length(defaultTo([], props.workHistory)) === 0 &&
    !props.openWorkHistoryEdit ? (
      <FullWidthCard style={{ marginBottom: 24, padding: 16 }}>
        <View>
          <StatLabelContainer>
            <IconDataContainer>
              <FOHChefHatIcon style={{ tintColor: FOHColors.SILVER }} />
              <FOHSmallDetailLabel
                style={{ paddingLeft: 6, color: FOHColors.GRAYSCALE_500 }}
              >
                {props.experienceLabel}
              </FOHSmallDetailLabel>
            </IconDataContainer>
            {props.openExperienceEdit ? (
              <TouchableOpacity
                testID={`edit-experience`}
                onPress={props.openExperienceEdit}
              >
                <FOHEditMarker />
              </TouchableOpacity>
            ) : (
              <></>
            )}
          </StatLabelContainer>
          <FOHLabel style={{ paddingLeft: 24, lineHeight: 24 }}>
            {props.yearsExp}
          </FOHLabel>
          <FOHSpaceSmall />
          <View style={{ paddingVertical: 10, paddingHorizontal: 3 }}>
            <FOHDivider />
          </View>
        </View>
        <FOHSpaceSmall />
        <View>
          <FOHEmptyWorkHistoryCell
            headerText={props.emptyWorkHistoryHeader}
            detailText={props.emptyWorkHistoryDetail}
            submitBtnLabel={props.askForWorkHistoryBtnLabel}
            onPress={props.onPressAskForWorkHistory}
            image={props.emptyWorkHistoryImage}
          />
        </View>
      </FullWidthCard>
    ) : (
      <></>
    )}
  </View>
);

const StatLabelContainer = styled(View)`
  padding-bottom: 4px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const IconDataContainer = styled(View)`
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
