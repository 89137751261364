import { path } from 'ramda';

import {
  FEED,
  CANDIDATE_ONBOARDING_FORM_URL,
  CANDIDATE_ACCOUNT_MENU_PATH,
  CANDIDATE_ONBOARDING_2,
  CANDIDATE_PROFILE
} from '../constants/urls';

import {
  userIsEmployer,
  userHasEmployerProfile,
  userHasCandidateProfile
} from './users';
import {
  getSessionStorageItem,
  removeSessionStorageItem,
  setSessionStorageItem
} from './sessionStorage';

export const getLoginRedirectPath = user => {
  if (userIsEmployer(user)) {
    return userHasEmployerProfile(user) ? FEED : '/register';
  }

  return userHasCandidateProfile(user) || user.hasCompletedOnboarding
    ? path(['candidate_profile', 'handle'], user)
      ? `${CANDIDATE_PROFILE}/${path(['candidate_profile', 'handle'], user)}`
      : CANDIDATE_ACCOUNT_MENU_PATH
    : CANDIDATE_ONBOARDING_2;
};

export const getUserProfileRedirectUrl = user => {
  return userIsEmployer(user) ? '/register' : CANDIDATE_ONBOARDING_FORM_URL;
};

export const AUTH_FLOW_CONTROL_REDIRECT_URI_KEY = 'redirectUri';
export const setAuthFlowControlRedirectURI = storedRedirectUri =>
  setSessionStorageItem(AUTH_FLOW_CONTROL_REDIRECT_URI_KEY, storedRedirectUri);
export const removeAuthFlowControlRedirectURI = () =>
  removeSessionStorageItem(AUTH_FLOW_CONTROL_REDIRECT_URI_KEY);
export const getAuthFlowControlRedirectURI = () =>
  getSessionStorageItem(AUTH_FLOW_CONTROL_REDIRECT_URI_KEY);
