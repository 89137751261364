import React from 'react';

import { View } from 'react-native';
import styled from 'styled-components/native';

import {
  FOHInterviewDetailsBlockProps,
  FOHInterviewDetailsBlock,
  FOHLocationOverview,
  FOHLocationOverviewProps,
  FOHProfileImage
} from '../../recipes';
import {
  FullWidthCard,
  FOHDivider,
  FOHSpace,
  FOHLabel,
  FOHButton,
  FOHInverseButton,
  FOHCalendarIcon,
  FOHMessageIcon,
  FOHHeaderH3,
  FOHHeaderH2
} from '../../ingredients';
import { FOHColors } from '../../ingredients/colors/FOHColors';

export interface FOHInterviewConfirmationChatProps
  extends FOHInterviewDetailsBlockProps,
    FOHLocationOverviewProps {
  nextStepsLabel: string;
  scheduledLabel: string;
  headerLabel: string;
  addLabel: string;
  onAddPress: () => any;
  seeMessagesLabel: string;
  onPressSeeMessages: () => any;
  hiringManagerLabel: string;
  hiringManagerPhoto: string;
  hiringManagerEmail: string;
  onPressHiringManagerEmail: (email: string) => any;
  noSchedule?: boolean;
  noScheduleLabel?: string;
  interviewAcceptedMessaging?: string;
  furtherProcessLable?: string;
  onPressFurtherProcess?: () => any;
}

export const FOHInterviewConfirmationChat = (
  props: FOHInterviewConfirmationChatProps
) => (
  <FullWidthCard style={{ padding: 24, maxWidth: 900 }}>
    {props.noSchedule ? (
      <RedBox>
        <FOHHeaderH3>{props.noScheduleLabel}</FOHHeaderH3>
      </RedBox>
    ) : (
      <GreenBox>
        <View
          style={{ flex: 1, minWidth: 250 }}
          testID={'FOHInterviewConfirmationChat-green-box'}
        >
          <FOHLabel style={{ color: FOHColors.GREEN }}>
            {props.scheduledLabel}
          </FOHLabel>
          <FOHHeaderH2 style={{ textAlign: 'left' }}>
            {props.headerLabel}
          </FOHHeaderH2>
        </View>
        <View
          style={{
            flex: 1,
            justifyContent: 'flex-end',
            alignItems: 'center',
            minWidth: 200
          }}
        >
          <FOHInverseButton
            icon={FOHCalendarIcon}
            color={'#38A56B'}
            style={{
              maxWidth: 300,
              minWidth: 200,
              width: '100%',
              height: 40
            }}
            textColor={'#ffffff'}
            onPress={props.onAddPress}
            title={props.addLabel}
            opaque={true}
            canSelect={true}
            selected={true}
          />
        </View>
      </GreenBox>
    )}
    <FOHSpace />
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <FOHProfileImage
        photo={props.hiringManagerPhoto}
        style={{ width: 58, height: 58 }}
      />
      <ChatCircle style={{ left: -16 }}>
        <FOHMessageIcon
          style={{ tintColor: '#ffffff', width: 28, height: 28 }}
        />
        <RedCircle />
      </ChatCircle>
    </View>
    <FOHSpace />
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <View style={{ maxWidth: 300 }}>
        <FOHLabel style={{ textAlign: 'center' }}>
          {props.nextStepsLabel}
        </FOHLabel>
      </View>
    </View>
    <FOHSpace />
    <View
      style={{
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <FOHButton
        onPress={props.onPressSeeMessages}
        title={props.seeMessagesLabel}
        testID={'open-message'}
      />
    </View>
    <FOHSpace />
    <FOHDivider />
    <FOHSpace />
    <FOHLocationOverview {...props} />
    <FOHSpace />
    <FOHInterviewDetailsBlock {...props} />
  </FullWidthCard>
);

const GreenBox = styled(View)`
  background: ${FOHColors.LIGHT_GREEN};
  flex-direction: row;
  border-radius: 6px;
  padding: 24px;
  flex-wrap: wrap;
`;

// @ts-ignore RedBox
const RedBox = styled(GreenBox)`
  background: ${FOHColors.LIGHT_RED};
`;

const RedCircle = styled(View)`
  width: 16px;
  position: absolute;
  height: 16px;
  border-radius: 50%;
  background: ${FOHColors.RED};
  border-width: 2px;
  border-color: #ffffff;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
`;

const ChatCircle = styled(View)`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background: ${FOHColors.BLUE};
  border-width: 2px;
  border-color: #ffffff;
  justify-content: center;
  align-items: center;
`;
