import React from 'react';

import {
  FOHReactiveModal,
  FOHReactiveModalProps,
  FOHHeaderText,
  FOHListTextField,
  FOHButton,
  FOHTextButton,
  FOHDetailText,
  FOHErrorLabel,
  FOHScrollView,
  FOHView
} from '../../ingredients';

export interface FOHEmailListProps {
  title: string;
  detailText: string;
  handleSubmit: () => void;
  input: string;
  setInput: (val: string) => void;
  emails: Array<string>;
  setEmails: (vals: Array<string>) => void;
  submitLabel: string;
  cancelText?: string;
  error?: string;
  cancel: () => void;
}

export interface FOHEmailListModalProps
  extends FOHReactiveModalProps,
    FOHEmailListProps {}

export const FOHEmailListModal = (props: FOHEmailListModalProps) => (
  <FOHReactiveModal
    leftContentShift={props.leftContentShift}
    dialog={props.dialog}
    open={props.open}
    close={props.close}
  >
    <FOHScrollView>
      <FOHView
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: 18,
          width: props.dialog ? 400 : '100%',
          paddingLeft: props.dialog ? 40 : 0,
          paddingRight: props.dialog ? 40 : 0,
          paddingBottom: props.dialog ? 40 : 0,
          backgroundColor: '#ffffff'
        }}
      >
        <FOHHeaderText>{props.title}</FOHHeaderText>
        <FOHView style={{ padding: 6 }} />
        <FOHDetailText>{props.detailText}</FOHDetailText>
        <FOHView
          style={{
            paddingTop: 24
          }}
        />
        <FOHListTextField
          values={props.emails}
          setValues={props.setEmails}
          onChangeText={props.setInput}
          value={props.input}
          setValue={props.setInput}
        />
        <FOHView style={{ padding: 6 }} />
        {props.error && (
          <>
            <FOHErrorLabel>{props.error}</FOHErrorLabel>{' '}
            <FOHView style={{ padding: 6 }} />
          </>
        )}
        <FOHButton title={props.submitLabel} onPress={props.handleSubmit} />
        <FOHView style={{ padding: 6 }} />
        {!!props.cancelText && (
          <FOHTextButton title={props.cancelText} onPress={props.cancel} />
        )}
      </FOHView>
    </FOHScrollView>
  </FOHReactiveModal>
);
