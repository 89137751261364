import React from 'react';

import { withTranslation, WithTranslation } from 'react-i18next';
import { compose } from 'ramda';

import {
  FOHColors,
  FOHEditMarkerBlack,
  FOHFonts,
  FOHLabel,
  FOHSmallDetailLabel,
  FOHSpace,
  FOHTextButton,
  FOHView,
  FOHXIcon
} from '../../../components';
import { useIsMobile } from '../../../utils';

import { IntegrationIdentifiersChoices } from '../IntegrationIdentifiers';

interface IntegratedCompanyInfoViewProps extends WithTranslation {
  integrationIdentifierID: string;
  integrationName: IntegrationIdentifiersChoices;
  editLabel: string;
  onEditPress: () => void;
  disconnectLabel: string;
  onDisconnectPress: (integration: IntegrationIdentifiersChoices) => void;
}

const IntegratedCompanyInfoView: React.FC<IntegratedCompanyInfoViewProps> = ({
  t,
  integrationIdentifierID,
  integrationName,
  editLabel,
  onEditPress,
  disconnectLabel,
  onDisconnectPress
}) => {
  const { isMobile, width } = useIsMobile();
  const mobile = isMobile && width <= 627;

  return (
    <FOHView
      style={{ paddingVertical: 22, paddingHorizontal: isMobile ? 0 : 10 }}
    >
      <FOHView
        style={{
          minHeight: mobile ? 96 : 'unset',
          maxHeight: mobile ? 112 : 'unset',
          maxWidth: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: FOHColors.WHITE,
          height: 40
        }}
      >
        <FOHView
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            flex: 1
          }}
        >
          <FOHView
            style={{
              flex: mobile ? 'unset' : 3,
              width: mobile ? '100%' : 'unset',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <FOHLabel
              style={{
                fontFamily: FOHFonts.REGULAR,
                color: FOHColors.GRAYSCALE_G1,
                fontWeight: 600
              }}
            >
              {t('companyId')}
            </FOHLabel>
            <FOHSpace />
            <FOHSmallDetailLabel style={{ fontSize: 16, lineHeight: 22 }}>
              {integrationIdentifierID}
            </FOHSmallDetailLabel>
          </FOHView>
          {mobile ? <FOHSpace /> : null}
          <FOHView
            style={{
              flex: mobile ? 'unset' : 2,
              width: mobile ? '100%' : 'unset',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end'
            }}
          >
            <FOHTextButton
              title={editLabel}
              disabled={false}
              icon={() =>
                FOHEditMarkerBlack({ style: { width: 16, height: 16, top: 1 } })
              }
              onPress={onEditPress}
              underline
            />
            <FOHSpace />
            <FOHTextButton
              title={disconnectLabel}
              disabled={false}
              icon={() =>
                FOHXIcon({
                  large: false,
                  style: { width: 16, height: 16, top: 1 }
                })
              }
              onPress={() => onDisconnectPress(integrationName)}
              underline
            />
          </FOHView>
        </FOHView>
      </FOHView>
    </FOHView>
  );
};

export default compose(withTranslation('Integrations'))(
  IntegratedCompanyInfoView
);
