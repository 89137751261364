import React from 'react';

import styled from 'styled-components/native';
import { View, TouchableOpacity } from 'react-native';
import { prop } from 'ramda';

import {
  FOHAltSectionLabel,
  FOHLargeRadioSelect,
  FOHLargeLookingStatusIcon,
  FOHLargeOpenToOffersStatusIcon,
  FOHLargeNewShiftStatusIcon,
  FOHLargeNotLookingStatusIcon,
  FOHStatusType,
  FOHDetailText,
  FOHStatusTypes
} from '../../ingredients';

export interface FOHStatusSelectProps {
  label: string;
  description: string;
  value: FOHStatusType;
}

export interface FOHStatusSelectCellProps extends FOHStatusSelectProps {
  onPress: (value: string) => void;
  selected: boolean;
  style?: any;
  testID?: string;
}

export const FOHStatusSelectCell = (props: FOHStatusSelectCellProps) => {
  const value = prop('value', props);
  const validStatus = FOHStatusTypes.includes(value);
  const StatusIcon = validStatus
    ? {
        ACTIVELY_LOOKING: FOHLargeLookingStatusIcon,
        OPEN_TO_OFFERS: FOHLargeOpenToOffersStatusIcon,
        LOOKING_FOR_SHIFT: FOHLargeNewShiftStatusIcon,
        CLOSED_TO_OFFERS: FOHLargeNotLookingStatusIcon
      }[value]
    : FOHLargeLookingStatusIcon;
  return (
    <Border style={{ ...props.style, maxWidth: 418 }}>
      <TouchableOpacity
        style={{
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          width: '100%',
          height: '100%'
        }}
        onPress={e => {
          e.preventDefault();
          props.onPress(props.value);
        }}
        testID={props.testID}
      >
        <View
          style={{
            paddingTop: 14,
            paddingRight: 16,
            paddingLeft: 14
          }}
        >
          <FOHLargeRadioSelect selected={props.selected} />
        </View>
        <View style={{ paddingTop: 16, paddingRight: 10 }}>
          <StatusIcon />
        </View>
        <View style={{ paddingTop: 18, flex: 1 }}>
          <StatusLabel>{props.label}</StatusLabel>
          <StatusDescription>{props.description}</StatusDescription>
        </View>
      </TouchableOpacity>
    </Border>
  );
};

const Border = styled(View)`
  border-radius: 6px;
  width: 100%;
  height: 68px;
  border: 1px solid #e8e8e8;
  margin-bottom: 4px;
  margin-top: 4px;
`;

export const StatusLabel = styled(FOHAltSectionLabel)`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 4px;
`;

export const StatusDescription = styled(FOHDetailText)`
  font-size: 12px;
  text-align: left;
  line-height: normal;
`;
