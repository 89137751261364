import React from 'react';

import styled from 'styled-components/native';
import { View } from 'react-native';

import {
  FOHLabel,
  FOHSmallDetailLabel,
  FOHTextButton
} from '../../ingredients';

export interface FOHWorkHistoryBaseProps {
  positionLabel: string;
  employerNameLabel: string;
  editLabel: string;
  onEditPress: (id: string) => void;
  onRemovePress: (id: string) => void;
  removeLabel: string;
  startLabel: string;
  endLabel: string;
}

interface FOHWorkHistoryCellProps extends FOHWorkHistoryBaseProps {
  id: string;
  position: string;
  employerName: string;
  start: string;
  end: string;
}

export const FOHWorkHistoryCell = (props: FOHWorkHistoryCellProps) => (
  <Border>
    <Row>
      <Field>
        <FOHSmallDetailLabel>{props.positionLabel}</FOHSmallDetailLabel>
        <FOHLabel>{props.position}</FOHLabel>
      </Field>
    </Row>
    <Row>
      <Field>
        <FOHSmallDetailLabel>{props.employerNameLabel}</FOHSmallDetailLabel>
        <FOHLabel>{props.employerName}</FOHLabel>
      </Field>
    </Row>
    <Row>
      <Field>
        <FOHSmallDetailLabel>{props.startLabel}</FOHSmallDetailLabel>
        <FOHLabel>{props.start}</FOHLabel>
      </Field>
      <Field style={{ paddingLeft: 30 }}>
        <FOHSmallDetailLabel>{props.endLabel}</FOHSmallDetailLabel>
        <FOHLabel>{props.end}</FOHLabel>
      </Field>
    </Row>
    <Row>
      <FOHTextButton
        title={props.editLabel}
        onPress={() => props.onEditPress(props.id)}
      />
      <View style={{ paddingLeft: 24 }} />
      <FOHTextButton
        title={props.removeLabel}
        onPress={() => props.onRemovePress(props.id)}
      />
    </Row>
  </Border>
);

const Border = styled(View)`
  border-radius: 6px;
  width: 327px;
  height: 236px;
  padding-top: 6px;
  border: 1px solid #e8e8e8;
  margin: 4px;
`;

const Row = styled(View)`
  flex-direction: row;
  justify-content: left;
  padding-left: 18px;
  padding-top: 12px;
`;

const Field = styled(View)`
  justify-content: left;
`;
