import React, { useState, useEffect } from 'react';

import styled from 'styled-components/native';
import { View, Text, TouchableOpacity } from 'react-native';

import { FOHColors, FOHFonts } from '../../ingredients';

interface FOHMoneySelectorProps {
  min: number;
  max: number;
  default: number;
  value?: number;
  disabled?: boolean;
  unityToChangeBy: number;
  onNumChange: (num: number) => void;
}

export const FOHMoneySelector = (props: FOHMoneySelectorProps) => {
  const [num, setNum] = useState(props.value ? props.value : props.default);

  useEffect(() => {
    if (props.value) {
      setNum(props.value);
    }
  }, [props.value]);

  const _onNumChange = (number: number) => {
    setNum(number);
    props.onNumChange(number);
  };

  const _increase = () => {
    if (props.disabled) return;

    if (props.max > num) {
      const tempNum = num + props.unityToChangeBy;
      if (typeof props.value === 'undefined') {
        setNum(tempNum);
      }

      _onNumChange(tempNum);
    }
  };

  const _decrease = () => {
    if (props.disabled) return;

    if (props.min < num) {
      const tempNum = num - props.unityToChangeBy;
      if (typeof props.value === 'undefined') {
        setNum(tempNum);
      }

      _onNumChange(tempNum);
    }
  };

  return (
    <FOHSelectorContainer
      style={[
        { opacity: props.disabled ? 0.7 : 1 },
        {
          backgroundColor: props.disabled
            ? FOHColors.BACKGROUND_GRAY
            : 'transparent'
        }
      ]}
    >
      <FOHButton
        disabled={props.disabled}
        onPress={_decrease}
        testID={'FOHMoneySelector-decrease'}
      >
        <FOHText>-</FOHText>
      </FOHButton>
      <FOHSelectorTextContainer>
        <FOHSelectorText testID={'FOHMoneySelector-value'}>
          ${parseFloat(num.toString()).toFixed(2)}
        </FOHSelectorText>
      </FOHSelectorTextContainer>
      <FOHButton
        disabled={props.disabled}
        onPress={_increase}
        testID={'FOHMoneySelector-increase'}
      >
        <FOHText>+</FOHText>
      </FOHButton>
    </FOHSelectorContainer>
  );
};

const FOHSelectorContainer = styled(View)`
  border: 1px solid #dbdbdb;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  flex-direction: row;
  overflow: hidden;
  min-width: 144px;
`;

const FOHButton = styled(TouchableOpacity)`
  flex: 1;
  padding: 12px;
  align-items: center;
  justify-content: center;
  height: 40px;
`;

const FOHText = styled(Text)`
  color: #38a56b;
  text-align: center;
  font-size: 14px;
  line-height: 24px;
`;

const FOHSelectorTextContainer = styled(View)`
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  height: 40px;
`;

const FOHSelectorText = styled(Text)`
  text-align: center;
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #171717;
`;
