import React, { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { compose, defaultTo, path, prop } from 'ramda';

import {
  FOHLabel,
  FOHColors,
  FOHButton,
  FOHBannerType,
  FOHView,
  FOHScrollView,
  FOHSpace,
  FOHLink,
  FOHDetailText,
  FOHHeaderH2
} from '../../components';
import { useGetIndeedBillingStatusQuery } from '../../graphql/generated';
import { useIsMobile } from '../../utils';
import { envVariables } from '../../constants';

import { useOAuth2 } from '../Campaigns';
import { useBanner, withReactiveModal } from '../Navigation';
import { OAUTH_PROVIDERS } from '../Campaigns/constants';
import { withEmployerSettings } from '../EmployerSettings/withEmployerSettings';
import { useGetMeData } from '../SignIn';

import IntegrationIndeed from './IntegrationIndeed';
import Integration7Shifts from './Integration7Shifts';
import IntegrationIdentifiers from './IntegrationIdentifiers';
import { FOHXIntegrationCard } from './components';

const GoToIndeedModalView = (props: {
  buttonLabel: string;
  title: string;
  message: string;
  linkText: string;
  closeModal: VoidFunction;
}) => {
  const { title, message, linkText, closeModal, buttonLabel } = props;
  return (
    <FOHView
      style={{
        alignItems: 'center',
        paddingHorizontal: 40
      }}
      testID="GoToIndeedModal"
    >
      <FOHHeaderH2 style={{ textAlign: 'left' }}>{title}</FOHHeaderH2>
      <FOHSpace />
      <FOHDetailText>{message}</FOHDetailText>
      <FOHSpace />

      <FOHLink
        onPress={() => {
          window.open &&
            window.open(
              `https://secure.indeed.com/account/authorizedApplications`,
              '_blank'
            );
        }}
        linkText={linkText}
        // noIcon={true}
      />
      <FOHSpace />

      <FOHButton
        title={buttonLabel}
        onPress={closeModal}
        selected={true}
        style={{ height: 40 }}
        // @ts-ignore font-weight 700 as type number
        textStyle={{ color: FOHColors.WHITE, fontWeight: 700 }}
      />
    </FOHView>
  );
};
const GoToIndeedModal = withReactiveModal(GoToIndeedModalView);

const Integrations = () => {
  const { t } = useTranslation('Integrations');
  const { isMobile } = useIsMobile();

  const [goToIndeedModalActive, setGoToIndeedModalActive] = useState(false);

  const { setBanner } = useBanner();

  const { data: me } = useGetMeData();

  const employerProfileId = useMemo(
    () => defaultTo(null, path(['employerProfile', 'rowId'], me)),
    [me]
  );

  const indeedBillingStatusQuery = useGetIndeedBillingStatusQuery({});
  const userEmail = defaultTo('', prop('email', me)) as string;
  const email = encodeURIComponent(userEmail as string);
  const billingConfirmed = path(
    ['data', 'getIndeedBillingStatus', 'isBillingConfirmed'],
    indeedBillingStatusQuery
  ) as boolean;

  const { error, getAuth } = useOAuth2({
    onSuccess: async payload => {
      if (payload.provider === OAUTH_PROVIDERS.Indeed) {
        await indeedBillingStatusQuery.refetch();
        if (!billingConfirmed) {
          window.open(
            `https://ats-management.indeed.com/accountsetup?__email=${email}&ak=${envVariables.INDEED_ATS_KEY}`,
            '_blank'
          );
        }
      }
    },
    onError: payload => console.log('Error', payload)
  });

  const handleAddAccountPress = async (
    provider: keyof typeof OAUTH_PROVIDERS
  ) => {
    await getAuth(provider);

    if (error) {
      setBanner({
        bannerType:
          FOHBannerType.DANGER as unknown as keyof typeof FOHBannerType,
        bannerMessage: (error as any).message
      });
    }
  };

  return (
    <FOHView
      style={{
        alignItems: isMobile ? 'center' : 'flex-start'
      }}
    >
      <FOHView
        style={{
          width: '100%'
        }}
      >
        <FOHScrollView
          style={{
            height: isMobile ? 'unset' : '80vh',
            width: '100%'
          }}
        >
          <FOHView
            style={{
              // marginHorizontal: 28,
              paddingHorizontal: isMobile ? 14 : 28,
              borderRadius: 16
            }}
          >
            <IntegrationIndeed
              onPressAddAccount={() =>
                handleAddAccountPress(OAUTH_PROVIDERS.Indeed)
              }
              setBanner={setBanner as any}
              setGoToIndeedModalActive={setGoToIndeedModalActive}
              isBillingConfirmed={billingConfirmed}
            />
            <Integration7Shifts
              setBanner={setBanner}
              onPressAddAccount={() =>
                handleAddAccountPress(OAUTH_PROVIDERS.SevenShifts)
              }
            />
            <IntegrationIdentifiers setBanner={setBanner as any} />
            <FOHXIntegrationCard employerProfileID={employerProfileId} />
            <FOHView
              style={{
                paddingTop: isMobile ? 18 : 35,
                paddingBottom: isMobile ? 20 : 40,
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <FOHLabel
                style={{
                  color: FOHColors.GRAYSCALE_600,
                  fontWeight: 400,
                  fontSize: 14,
                  lineHeight: 20,
                  textAlign: 'center'
                }}
              >
                {t('stayTunedWithMoreIntegrations')}
              </FOHLabel>
            </FOHView>
          </FOHView>
        </FOHScrollView>
      </FOHView>
      <GoToIndeedModal
        open={goToIndeedModalActive}
        history={history}
        close={() => setGoToIndeedModalActive(false)}
        title={t('goToIndeedTitle')}
        linkText={t('indeedLinkText')}
        message={t('goToIndeedMessage')}
        buttonLabel={t('common:ok')}
        closeModal={() => setGoToIndeedModalActive(false)}
      />
    </FOHView>
  );
};

export default compose(withEmployerSettings)(Integrations);
