import React from 'react';

import fileicon from '../../../images/download_file_ic.svg';

import { FOHImage } from '../../ingredients';

export const FOHDownloadFileIcon = (props: any) => (
  <FOHImage
    source={{ uri: fileicon }}
    style={{ width: 30, height: 34, ...props.style }}
  />
);
