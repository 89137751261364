import { useEffect } from 'react';

import { useLocalStorage } from 'use-hooks';
import { create } from 'zustand';

import {
  LOCATION_CREATION_SUCCESS_MODAL,
  POSITION_CREATION_SUCCESS_MODAL
} from '../constants';

const STORAGE_KEY = 'dismissed-modals';

type DismissableModalStoreState = {
  dismissableModals: string[];
  setDismissableModals: (modalDismiss: string[]) => void;
};

const useDismissableModalStore = create<DismissableModalStoreState>(set => ({
  dismissableModals: [],
  setDismissableModals: modalDismiss => set({ dismissableModals: modalDismiss })
}));

export const useDismissableModals = () => {
  const dismissableModalList = `${LOCATION_CREATION_SUCCESS_MODAL},${POSITION_CREATION_SUCCESS_MODAL}`;

  const { dismissableModals, setDismissableModals } =
    useDismissableModalStore();

  const [dismissedModals, setDismissedModals] = useLocalStorage<string[]>(
    STORAGE_KEY,
    []
  );

  useEffect(() => {
    const newModals = dismissableModalList
      .split(',')
      .filter(modal => !dismissedModals.includes(modal));
    setDismissableModals(newModals);
  }, [dismissedModals, dismissableModalList, setDismissableModals]);

  const handleModalDismiss = (dismissModal: string) => {
    setDismissedModals([...dismissedModals, dismissModal]);
    setDismissableModals(dismissableModals.filter(m => m !== dismissModal));
  };

  return { dismissableModals, handleModalDismiss };
};
