import React from 'react';

import { defaultTo } from 'ramda';

import contact from '../../../images/contact_me.svg';

import { FOHImage } from '../../ingredients';

export const FOHContactMeIcon = (props: any) => (
  <FOHImage
    source={{ uri: contact }}
    style={{ width: 22, height: 20, ...defaultTo({}, props.style) }}
  />
);
