import React from 'react';

import campaign from '../../../images/campaigns.svg';

import { FOHImage, FOHColors } from '../../ingredients';

export const FOHGrayCampaignIcon = (props: any) => (
  <FOHImage
    source={{ uri: campaign }}
    style={
      props && !!props.style
        ? {
            width: 24,
            height: 24,
            tintColor: FOHColors.GRAYSCALE_300,
            ...props.style
          }
        : { width: 24, height: 24, tintColor: FOHColors.GRAYSCALE_300 }
    }
  />
);
