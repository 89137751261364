import React from 'react';

import selected from '../../../images/circle_success.svg';
import unselected from '../../../images/black_add.svg';

import { FOHImage } from '../../ingredients';

interface FOHTagSelectProps {
  selected: boolean;
}

export const FOHTagSelect = (props: FOHTagSelectProps) => (
  <FOHImage
    source={{ uri: props.selected ? selected : unselected }}
    style={{ width: 16, height: 16 }}
  />
);
