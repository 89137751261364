import { prop } from 'ramda';

export const loginScreenErrorTransform = ({ detail, fieldErrors }) => ({
  detail: prop(0, detail),
  email: prop([0, 'email'], fieldErrors),
  password: prop([0, 'password'], fieldErrors)
});

export const passwordResetReturnScreenErrorTransform = ({
  detail,
  fieldErrors
}) => {
  let detailError = prop(0, detail);
  if (prop('token', fieldErrors) || prop('uid', fieldErrors)) {
    detailError =
      'There was a problem with your password reset link. Please try again.';
  }
  return {
    detail: detailError,
    password: prop('new_password1', fieldErrors),
    passwordConfirm: prop('new_password2', fieldErrors)
  };
};
