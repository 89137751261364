import React, { useState } from 'react';

import styled from 'styled-components/native';
import { path, prop } from 'ramda';
import { useTranslation } from 'react-i18next';

import {
  FOHColors,
  FOHView,
  FOHFormFlowButtons,
  FOHLabel,
  FOHFonts,
  FOHBorderlessSelectDropdown,
  FOHPositionBillingStatusType,
  FOHSpaceSmallest,
  FOHErrorLabel
} from '../../components';

import { useSelectPositionForSelectedLocationState } from '../EmployerPositions/useSelectPositionForSelectedLocationState';
import { withReactiveModal } from '../Navigation';

import { FOHAvatarsOverlayOrImageWithHeader } from './components';

const ActionModal = props => {
  const {
    title,
    HeaderImage,
    participants,
    defaultLocation,
    defaultPosition,
    onPressUpgrade,
    searchPlaceholder,
    upgradeLabel,
    nextButtonText,
    backButtonText,
    onNextPress,
    onBackPress,
    containerStyle,
    isNextDisabled,
    setPosition,
    positionContainerTestId,
    containerTestID,
    candidateName,
    childrenContainerStyle = {},
    selectedLocationFilters = []
  } = props;

  const [positionOpen, setPositionOpen] = useState(false);

  const { t } = useTranslation('PositionsFeature');

  const {
    selectedPosition,
    myPositions,
    setSelectedPosition,
    positionSearch,
    setPositionSearch
  } = useSelectPositionForSelectedLocationState({
    positionId: defaultPosition,
    locationId: defaultLocation,
    locationIds: selectedLocationFilters
  });

  const positionOptions = myPositions.map(pos => ({
    label: `${prop('positionName', pos)} - `,
    value: prop('id', pos),
    sublabel: `${path(['location', 'name'], pos)} - ${path(
      ['location', 'formattedAddress'],
      pos
    )}`,
    upgrade:
      path(['paymentStatus'], pos) !==
        FOHPositionBillingStatusType.SUBSCRIBED &&
      path(['location', 'paymentStatus'], pos) !==
        FOHPositionBillingStatusType.SUBSCRIBED,
    locationIdToUpgrade: path(['location', 'id'], pos)
  }));

  return (
    <FOHView style={{ ...containerStyle }} testID={containerTestID}>
      <FOHAvatarsOverlayOrImageWithHeader
        title={title}
        HeaderImage={HeaderImage}
        candidateName={candidateName}
        interviewParticipants={participants}
      />
      <FOHView style={{ paddingHorizontal: 24, width: '100%' }}>
        <FOHView
          style={{
            backgroundColor: FOHColors.GRAYSCALE_10,
            padding: 18,
            marginTop: 16,
            marginBottom: 24,
            borderRadius: 24,
            zIndex: 2
          }}
        >
          <FieldLabel>{t('positionLabel')}</FieldLabel>
          <FOHSpaceSmallest />
          <FOHView style={{ zIndex: 3, width: '100%' }}>
            <FOHBorderlessSelectDropdown
              testID={positionContainerTestId}
              open={positionOpen}
              setOpen={open => setPositionOpen(open)}
              labelStyle={{
                width: '100%'
              }}
              optionsContainerStyle={{
                left: 0,
                maxWidth: 527
              }}
              backgroundColor={'transparent'}
              selectedValue={selectedPosition}
              onValueChange={value => {
                if (setPosition) {
                  setPosition(value);
                }
                setSelectedPosition(value);
                setPositionOpen(false);
              }}
              options={positionOptions || []}
              onSearch={setPositionSearch}
              searchValue={positionSearch}
              searchPlaceholder={searchPlaceholder}
              upgradeLabel={upgradeLabel}
              onPressUpgrade={onPressUpgrade}
            />
          </FOHView>
          {!selectedPosition ? (
            <FOHView
              style={{
                justifyContent: 'center',
                alignItems: 'flex-start',
                wordBreak: 'break-word',
                marginTop: 5
              }}
            >
              <FOHErrorLabel>{`*${t(
                'CandidateFeed:NoPositionsSelectedDetailLabel'
              )}`}</FOHErrorLabel>
            </FOHView>
          ) : null}
          <FOHView style={{ paddingTop: 12, ...childrenContainerStyle }}>
            {props.children}
          </FOHView>
        </FOHView>
        <FOHFormFlowButtons
          nextButtonlabel={nextButtonText}
          onPressNextButton={onNextPress}
          isNextDisabled={isNextDisabled}
          previousButtonLabel={backButtonText}
          onPressPreviousButton={onBackPress}
        />
      </FOHView>
    </FOHView>
  );
};

export default withReactiveModal(ActionModal);

const FieldLabel = styled(FOHLabel)`
  font-family: ${FOHFonts.MEDIUM};
  line-height: 16px;
  text-transform: uppercase;
  color: ${FOHColors.PRIMARY_TEAL_P1};
`;
