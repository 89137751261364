import React, { useRef } from 'react';

import { Text } from 'react-native';
import styled from 'styled-components/native';
import { useHover } from 'react-native-web-hooks';

import {
  FOHTouchableOpacity,
  FOHView,
  FOHRadioSelect,
  FOHColors,
  FOHFonts,
  FOHStatus,
  FOHStatusTypes
} from '../../ingredients';

export interface FOHLabeledRadioProps {
  selected?: boolean;
  label?: string;
  icon?: typeof FOHStatusTypes;
  setSelected?: () => void;
  style?: any;
  reverse?: boolean;
  disabled?: boolean;
}

export const FOHLabeledRadio = (props: FOHLabeledRadioProps) => {
  const ref = useRef(null);
  const hoverRef = useHover(ref);

  return (
    <FOHTouchableOpacity
      style={{
        flexDirection: props.reverse ? 'row-reverse' : 'row',
        alignItems: 'center',
        backgroundColor:
          !props.disabled && hoverRef ? FOHColors.GRAYSCALE_100 : '',
        borderRadius: 4,
        width: 'max-content',
        padding: 4,
        //@ts-expect-error cursor is not yet supported by react-native but should work on web.
        cursor: props.disabled ? 'initial' : 'pointer'
      }}
      ref={ref}
      onPress={props.setSelected}
      testID={`foh-labeled-radio-${props.label}`}
    >
      <FOHRadioSelect
        selected={props.disabled ? false : props.selected || false}
        isDisabled={props.disabled ? true : false}
      />
      <FOHView
        style={{
          paddingLeft: props.reverse ? 0 : 6,
          paddingRight: props.reverse ? 6 : 0
        }}
      />
      <RadioLabel
        style={{
          ...props.style,
          color: props.disabled
            ? FOHColors.GRAYSCALE_400
            : hoverRef || props.selected
            ? FOHColors.GRAYSCALE_G1
            : FOHColors.GRAYSCALE_700
        }}
      >
        {props.icon && <FOHStatus statusType={props.icon[0]} statusText="" />}
        <>{`${props.label} `}</>
      </RadioLabel>
    </FOHTouchableOpacity>
  );
};

export const RadioLabel = styled(Text)`
  font-family: ${FOHFonts.MEDIUM};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${FOHColors.GRAYSCALE_700};
`;
