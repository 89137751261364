import React from 'react';

import { TouchableOpacity, View } from 'react-native';
import styled from 'styled-components/native';

import { useClickOutside } from '../../spices/useClickOutside';

import { FOHDirectMessageIcon } from '../icons/FOHDirectMessageIcon';
import { FOHColors } from '../colors/FOHColors';
import { FOHFonts } from '../text/FOHFonts';
import { FOHLabel } from '../text/FOHLabel';

import { FOHCompoundDropdownButtonSecondary } from './FOHCompoundDropdownButtonSecondary';

export interface FOHMessageActionItem {
  groupTitle: string;
  groupActions: Array<any>;
}

export interface FOHMessageDropdownProps {
  title: string;
  testID?: string;
  open?: boolean;
  onPress: () => any;
  toggleOpen: () => any;
  children?: any;
  style?: any;
  menuTop?: number;
  disabled?: boolean;
  messageActions: Array<FOHMessageActionItem>;
  tipHelper?: string;
  maxHeight?: number;
}

const GroupTitle = styled(FOHLabel)`
  color: ${FOHColors.GRAYSCALE_400};
  text-transform: uppercase;
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  cursor: default;
  user-select: none;
  margin-bottom: 20px;
  margin-top: 24px;
`;

const MessageActionLabel = styled(FOHLabel)`
  margin-left: 10px;
  color: ${FOHColors.GRAYSCALE_900};
`;

const MessageActionContainer = styled(TouchableOpacity)`
  margin: 10px 8px !important;
  padding: 4px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const FOHMessageDropdown = (props: FOHMessageDropdownProps) => {
  const [ref] = useClickOutside({
    close: () => !!props.open && props.toggleOpen && props.toggleOpen()
  });

  return (
    <View ref={ref}>
      <View
        style={{
          alignItems: 'flex-end',
          minWidth: 170,
          zIndex: 1
        }}
      >
        <FOHCompoundDropdownButtonSecondary
          disabled={props.disabled}
          isToolTip={true}
          tipHelper={props.tipHelper}
          icon={<FOHDirectMessageIcon />}
          testID={props.testID || `${props.title}-dropdown-main-action`}
          title={props.title}
          open={props.open}
          onPress={props.onPress}
          style={{
            maxWidth: 220
          }}
          cardStyle={{
            minWidth: props.children ? 730 : 366,
            maxHeight: 500,
            height: props?.maxHeight || 500,
            overflowY: 'scroll'
          }}
          toggleOpen={props.toggleOpen}
        >
          {props.children ??
            props.messageActions.map(({ groupTitle, groupActions }) => (
              <React.Fragment key={groupTitle}>
                {groupTitle && (
                  <GroupTitle key={groupTitle}>{groupTitle}</GroupTitle>
                )}
                {groupActions.map(action => (
                  <MessageActionContainer
                    onPress={action.onAction}
                    key={action.title}
                    testID={`FOHMessageDropdown-${action.title}`}
                  >
                    {action.icon && (
                      <action.icon
                        style={{
                          width: '20px',
                          height: '20px',
                          tintColor: FOHColors.GRAYSCALE_700
                        }}
                      />
                    )}
                    <MessageActionLabel>{action.title}</MessageActionLabel>
                  </MessageActionContainer>
                ))}
              </React.Fragment>
            ))}
        </FOHCompoundDropdownButtonSecondary>
      </View>
    </View>
  );
};
