import React, { useEffect, useMemo, useState } from 'react';

import { prop, defaultTo, path, length } from 'ramda';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-hooks';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/native';

import {
  checkIfWarningIsDismissedByKey,
  findById,
  handle,
  handleMutation,
  toDateTime,
  unwrapEdgesAt,
  useIsMobile
} from '../../utils';
import { fetchMore } from '../../utils/fetchMore';
import {
  GetMyDashboardPositionsDocument,
  useGetLocationDashboardQuery,
  useGetMyDashboardPositionsQuery,
  useMutatePositionMutation
} from '../../graphql/generated';
import {
  ADD_NEW_POSITION,
  ALLOWED_ROLES_GROUPS,
  ALL_LOCATION_UPGRADE,
  APPLICANTS,
  CHATS,
  EMPLOYER_DASHBOARD,
  EMPLOYER_SETTINGS_MENU,
  FEED,
  JOBS,
  MATCHES,
  NEW_APPLICANTS,
  NEW_MATCHES,
  POSITIONS_URL,
  ROLES
} from '../../constants';
import {
  FOHFonts,
  FOHColors,
  FOHLabel,
  FOHCircleLoader,
  FOHSearchBar,
  FOHSpaceSmall,
  FOHSpaceLarge,
  FOHView,
  FOHTouchableOpacity,
  FOHPositionDashboardCard,
  FOHPositionBillingStatusType,
  FOHBannerType,
  FOHCheckbox,
  FOHStopWatchIcon,
  FOHWhiteCrownIcon,
  FOHInverseButton,
  FOHPositionHiringStatusType,
  FOHDismissedWarningKeys,
  Screen,
  RestrictedView,
  FOHWhiteAddIcon,
  FOHHeaderH2,
  FOHTextButton,
  FOHBackIcon,
  FOHImage,
  FOHLocationPin,
  FOHSmallDetailLabel,
  FOHMessageIcon,
  FOHPlanState,
  FOHSpaceSmallest,
  FOHPremiumPlanIcon
} from '../../components';
import { useNavigation } from '../../utils/navigation';

import { usePaymentPlan } from '../Billing/usePaymentPlan';
import { PlanModals } from '../Billing/PlanModals';
import { useCurrentLocationContext } from '../Locations/useCurrentLocationContext';
import { useBanner } from '../Navigation/useBanner';
import { useSidebarNav } from '../Navigation/useSidebar';
import { usePositionHiringStatus } from '../EmployerPositions/usePositionHiringStatus';
import { NotHiringConfirmationWarningModal } from '../EmployerPositions/components';
import { useGetMeData } from '../SignIn';

import { ApplicantsModal } from './ApplicantsModal';
import { NewChatsModal } from './NewChatsModal';
import { ManageLocationPositionModals } from './ManageLocationPositionModals';

const FOHRowDirection = styled(FOHView)`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const PositionDashboardCell = props => {
  const { t } = useTranslation('EmployerDashboardFeature');
  const { navigateTo } = useNavigation();
  const { setBanner } = useBanner();

  const { selectLocationFilter } = useCurrentLocationContext({
    props
  });

  const {
    position,
    hiringStatus,
    location,
    canChangeHiringFreely,
    refetchQueryParams,
    subscribed
  } = props;

  const [openStatus, setOpenStatus] = useState();
  const { statusOptions } = usePositionHiringStatus(hiringStatus);

  const [positionMutation] = useMutatePositionMutation();
  const { isMobile } = useIsMobile();
  const { data: me } = useGetMeData();

  const isInterviewer = defaultTo(
    false,
    path(['employerProfileUser', 'role'], me) === ROLES.INTERVIEWER
  );

  return (
    <FOHPositionDashboardCard
      key={prop('positionName', position)}
      billingDate="1/16/21"
      mobile={isMobile}
      billingLabel={t('billoingLabel')}
      dateInfo={{
        expiration: '1/16/21',
        latestUpdate: toDateTime(prop('updatedAt', position)),
        nextBilling: '1/16/21',
        posted: toDateTime(prop('posted', position))
      }}
      dateLabels={{
        expirationDateLabel: t('expirationDate'),
        latestUpdateLabel: t('latestUpdate'),
        nextBillingLabel: t('nextBillingLabel'),
        postedLabel: t('postedLabel')
      }}
      editPositionsLabel={t('editPosition')}
      infoCellsPayload={{
        chatsStarted: {
          title: t('chatStarted'),
          onPress:
            path(['dashboardCounts', 'chat', 'total'], position) === 0
              ? undefined
              : () => {
                  navigateTo(
                    `${EMPLOYER_DASHBOARD}/${prop('id', location)}/${prop(
                      'id',
                      position
                    )}${CHATS}`
                  );
                },
          ...path(['dashboardCounts', 'chat'], position)
        },
        applicants: {
          title: t('applicants'),
          onPress: !prop('hiring', position)
            ? () => {}
            : path(['dashboardCounts', 'applicants', 'new'], position) === 0
            ? () => {
                navigateTo(
                  `${EMPLOYER_DASHBOARD}/${prop('id', location)}/${prop(
                    'id',
                    position
                  )}${APPLICANTS}`
                );
              }
            : () => {
                navigateTo(
                  `${EMPLOYER_DASHBOARD}/${prop('id', location)}/${prop(
                    'id',
                    position
                  )}${NEW_APPLICANTS}`
                );
              },
          ...path(['dashboardCounts', 'applicants'], position)
        },
        matches: {
          title: t('matches'),
          onPress: !prop('hiring', position)
            ? () => {}
            : path(['dashboardCounts', 'candidates', 'new'], position) === 0
            ? () => {
                navigateTo(
                  `${EMPLOYER_DASHBOARD}/${prop('id', location)}/${prop(
                    'id',
                    position
                  )}${MATCHES}`
                );
              }
            : () => {
                navigateTo(
                  `${EMPLOYER_DASHBOARD}/${prop('id', location)}/${prop(
                    'id',
                    position
                  )}${NEW_MATCHES}`
                );
              },
          ...path(['dashboardCounts', 'candidates'], position)
        },

        requested: {
          title: t('interviewRequested'),
          onPress: () => {
            navigateTo(
              `${FEED}?tab=pending&location=${prop(
                'id',
                location
              )}&position=${prop('id', position)}`
            );
          },
          ...path(['dashboardCounts', 'interviews', 'pending'], position)
        },
        accepted: {
          title: t('accepted'),
          onPress: () => {
            navigateTo(
              `${FEED}?tab=accepted&location=${prop(
                'id',
                location
              )}&position=${prop('id', position)}`
            );
          },
          ...path(['dashboardCounts', 'interviews', 'accepted'], position)
        },
        completed: {
          title: t('completed'),
          onPress: () => {
            navigateTo(
              `${FEED}?tab=postInterview&location=${prop(
                'id',
                location
              )}&position=${prop('id', position)}`
            );
          },
          ...path(['dashboardCounts', 'interviews', 'completed'], position)
        }
      }}
      newLabel={t('new')}
      notificationsLabel={t('dailyMatchNotifications')}
      onPressPositionName={() => {
        navigateTo(
          `${FEED}?location=${path(['id'], location)}&position=${prop(
            'id',
            position
          )}`
        );
      }}
      onEditPress={() =>
        navigateTo(
          `${EMPLOYER_SETTINGS_MENU}${POSITIONS_URL}/${prop('id', position)}`
        )
      }
      viewPositionLabel={t('positionPage')}
      onPressViewPosition={() => {
        navigateTo(`${JOBS}/${prop('slug', position)}`);
      }}
      planStatus="unlimited"
      position={{
        positionName: prop('positionName', position),
        slug: prop('slug', position)
      }}
      price="$90"
      priceLabel="Price"
      reactivateLabel="Reactivate"
      notifications={prop('sendNotifications', position)}
      toggleNotifications={async () => {
        await handle(
          positionMutation({
            variables: {
              id: prop('id', position),
              positionName: prop('positionName', position),
              positionType: prop('positionType', position),
              sendNotifications: !prop('sendNotifications', position),
              location: prop('id', location)
            }
          })
        );
      }}
      selectStatus={async status => {
        const isSubscribed =
          path(['paymentStatus'], position) ===
            FOHPositionBillingStatusType.SUBSCRIBED ||
          path(['location', 'paymentStatus'], position) ===
            FOHPositionBillingStatusType.SUBSCRIBED;

        setOpenStatus(false);

        if (
          prop('value', status) === prop('hiringStatus', position) ||
          (prop('value', status) === FOHPositionHiringStatusType.NOT_POSTED &&
            !prop('hiringStatus', position))
        ) {
          return;
        }

        const refetchQueries = [
          {
            query: GetMyDashboardPositionsDocument,
            variables: refetchQueryParams
          }
        ];

        // selecting not posted marked hiring = False
        if (prop('value', status) === FOHPositionHiringStatusType.NOT_POSTED) {
          const [response, error] = await handleMutation(
            positionMutation({
              variables: {
                id: prop('id', position),
                hiring: false
              },
              refetchQueries
            })
          );

          if (error || !response) {
            return setBanner({
              bannerType: FOHBannerType.DANGER,
              bannerMessage:
                error?.message || t('unableToChangeHiringStatusError')
            });
          }

          if (
            !checkIfWarningIsDismissedByKey(
              FOHDismissedWarningKeys.DO_NOT_SHOW_ME_AGAIN
            ) &&
            !canChangeHiringFreely
          ) {
            props.setIsOpenNotPostedWarningModal(true);
          }

          return;
        }

        // selecting posted
        if (prop('value', status) === FOHPositionHiringStatusType.POSTED) {
          if (isSubscribed) {
            // if position or position/location is subscribed then marked hiring = True
            return await handle(
              positionMutation({
                variables: {
                  id: prop('id', position),
                  hiring: true
                },
                refetchQueries
              })
            );
          } else {
            // if position or position/location is not subscribed then navigating to upgrade
            selectLocationFilter(prop('id', location));
            navigateTo(
              `${EMPLOYER_DASHBOARD}/${prop(
                'id',
                location
              )}${ALL_LOCATION_UPGRADE}`
            );
          }
        }
      }}
      setOpenStatus={setOpenStatus}
      statusLabel="Status"
      statusOptions={statusOptions}
      openStatus={openStatus}
      statusText={prop('statusText', position)}
      statusType={prop('statusType', position)}
      subscribed={subscribed}
      userIsInterviewer={isInterviewer}
    />
  );
};

export const PositionDashboard = props => {
  const { t } = useTranslation('EmployerDashboardFeature');
  const params = useParams();
  const { navigateTo, location: navLocation } = useNavigation();
  const { locationId, modal } = params;
  const [loadingMore, setLoadingMore] = useState();
  const [includePaused, setIncludePaused] = useState(true);
  const [search, setSearch] = useState('');
  const [pollInterval, setPollInterval] = useState(0);
  const [isOpenNotPostedWarningModal, setIsOpenNotPostedWarningModal] =
    useState(false);

  const debouncedSearch = useDebounce(search, 1000);

  const locationID = useMemo(
    () => (locationId && locationId !== 'undefined' ? locationId : undefined),
    [locationId]
  );

  const { subscribed, canChangeHiringFreely } = usePaymentPlan({
    locationFilter: locationID,
    currentPlanQ: true
  });

  const { statusOptions } = usePositionHiringStatus();

  const getFullStatus = hiringStatus =>
    findById(hiringStatus, statusOptions, 'value');

  const variables = {
    location: locationID,
    first: 10,
    after: '',
    search: debouncedSearch
  };

  const locationQuery = useGetLocationDashboardQuery({
    skip: !locationID,
    variables: { location: locationID }
  });

  const positionsQuery = useGetMyDashboardPositionsQuery({
    skip: !locationID,
    pollInterval,
    variables: includePaused
      ? variables
      : {
          ...variables,
          hiring: true
        }
  });

  const { selectLocationFilter } = useCurrentLocationContext({
    props
  });

  const positions = defaultTo(
    [],
    unwrapEdgesAt(['data', 'myPositions', 'edges'], positionsQuery)
  ).map(position => ({
    ...position,
    statusText: defaultTo(
      statusOptions[1].label,
      prop('label', getFullStatus(prop('hiringStatus', position)))
    ),
    statusType: defaultTo(
      statusOptions[1].value,
      prop('value', getFullStatus(prop('hiringStatus', position)))
    )
  }));

  const location = path(['data', 'locationById'], locationQuery);

  const loadMore = async () => {
    if (!loadingMore) {
      setLoadingMore(true);
      await fetchMore(positionsQuery, variables, 'myPositions');
      setLoadingMore(false);
    }
  };

  const { setBanner } = useBanner();

  useEffect(() => {
    if (pollInterval > 0) {
      setTimeout(() => setPollInterval(0), 20000);
    }
  }, [pollInterval]);
  const { hasSideNav } = useSidebarNav(props);

  return (
    <Screen
      style={{
        minWidth: 1280,
        alignItems: 'flex-start'
      }}
    >
      <FOHView
        style={{
          width: '100%',
          backgroundColor: FOHColors.GRAYSCALE_10,
          minHeight: '100vh',
          paddingBottom: 60,
          paddingTop: hasSideNav ? 0 : 50,
          maxWidth: 1920
        }}
      >
        <FOHView
          style={{
            width: '100%',
            height: '100%',
            alignItems: 'flex-start',
            paddingHorizontal: 24,
            paddingVertical: 16,
            backgroundColor: FOHColors.WHITE
          }}
        >
          <FOHView
            style={{
              width: '100%',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center'
            }}
          >
            <FOHTextButton
              onPress={() => {
                navigateTo(EMPLOYER_DASHBOARD);
              }}
              icon={() => (
                <FOHBackIcon
                  style={{ height: 20, width: 20, marginHorizontal: 2 }}
                />
              )}
              title=""
            />
            <FOHSpaceSmall />
            {locationQuery.loading ? (
              <FOHCircleLoader />
            ) : (
              <FOHRowDirection>
                <FOHImage
                  style={{
                    width: 70,
                    height: 50,
                    borderRadius: 4,
                    objectFit: 'contain',
                    resizeMode: 'contain'
                  }}
                  source={{
                    uri: prop('image', location)
                  }}
                />
                <FOHSpaceSmall />
                <FOHHeaderH2
                  style={{
                    fontWeight: 700,
                    fontStyle: 'normal'
                  }}
                >
                  {prop('name', location)}
                </FOHHeaderH2>
                <FOHSpaceLarge />
                <FOHRowDirection style={{ flex: 1 }}>
                  <FOHLocationPin
                    style={{
                      width: 16,
                      height: 16,
                      tintColor: FOHColors.GRAYSCALE_400
                    }}
                  />
                  <FOHSpaceSmall />
                  <FOHSmallDetailLabel
                    style={{
                      color: FOHColors.GRAYSCALE_400
                    }}
                  >
                    {prop('formattedAddress', location)}
                  </FOHSmallDetailLabel>
                </FOHRowDirection>
                <FOHSpaceLarge />
                {prop('locationPhoneNumber', location) ? (
                  <>
                    <FOHRowDirection>
                      <FOHMessageIcon
                        style={{
                          width: 16,
                          height: 16,
                          tintColor: FOHColors.GRAYSCALE_700
                        }}
                      />
                      <FOHSpaceSmall />
                      <FOHLabel
                        heavy
                        style={{
                          color: FOHColors.GRAYSCALE_700,
                          fontSize: 14,
                          lineHeight: 20
                        }}
                      >
                        {`${t('locationPhoneNumberLabel')}:`}
                        {`  `}
                        <FOHSmallDetailLabel>
                          {prop('locationPhoneNumber', location)}
                        </FOHSmallDetailLabel>
                      </FOHLabel>
                    </FOHRowDirection>
                    <FOHSpaceLarge />
                  </>
                ) : null}
                <FOHRowDirection>
                  <FOHLabel
                    style={{ color: FOHColors.GRAYSCALE_600, marginRight: 8 }}
                  >
                    {t('locationPlanLabel')}
                  </FOHLabel>
                  <FOHRowDirection>
                    <FOHView style={{ width: 24, height: 24 }}>
                      {path(['payment', 'planName'], location) ===
                      FOHPlanState.enterprise ? (
                        <FOHPremiumPlanIcon
                          style={{ width: 22, height: 22, top: 2 }}
                        />
                      ) : path(['payment', 'planName'], location) ===
                        FOHPlanState.unlimited ? (
                        <FOHWhiteCrownIcon
                          style={{ width: 20, height: 20, top: 1 }}
                        />
                      ) : (
                        <FOHStopWatchIcon
                          style={{
                            width: 20,
                            height: 20,
                            top: 1
                          }}
                        />
                      )}
                    </FOHView>
                    <FOHSpaceSmallest />
                    <FOHLabel style={{ color: FOHColors.GRAYSCALE_500 }}>
                      {path(['payment', 'planName'], location) === 'ENTERPRISE'
                        ? t('PricingScreenFeature:enterprise')
                        : path(['payment', 'planName'], location) ===
                          'UNLIMITED'
                        ? `${t('PricingScreenFeature:unlimited')}`
                        : `${t('PricingScreenFeature:payAsYouGo')}`}
                    </FOHLabel>
                  </FOHRowDirection>
                </FOHRowDirection>
              </FOHRowDirection>
            )}
          </FOHView>
          <FOHSpaceSmallest />
          <FOHView
            style={{
              width: '100%',
              flexDirection: 'row',
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingLeft: 30
            }}
          >
            <FOHView
              style={{
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center'
              }}
            >
              <FOHView style={{ padding: 5, maxWidth: 328, width: '100%' }}>
                <FOHSearchBar
                  onChangeSearch={text => setSearch(text)}
                  searchValue={search}
                  searchPlaceholder={`${t('common:search')}`}
                  style={{ height: 48, maxWidth: 550, width: '100%' }}
                  inputStyle={{ height: 42 }}
                />
              </FOHView>
              <FOHSpaceLarge />
              <FOHTouchableOpacity
                onPress={() => setIncludePaused(!includePaused)}
                testID="includePaused"
                style={{ flexDirection: 'row' }}
              >
                <FOHCheckbox selected={includePaused} />
                <FOHSpaceSmall />
                <FOHLabel
                  style={{
                    lineHeight: 24,
                    fontFamily: FOHFonts.MEDIUM,
                    color: FOHColors.GRAYSCALE_G1
                  }}
                >
                  {t('includePausedPositions')}
                </FOHLabel>
              </FOHTouchableOpacity>
            </FOHView>
            <RestrictedView
              allowedRoles={ALLOWED_ROLES_GROUPS.ROLES.ADMIN_USER}
              allowedGroups={[ALLOWED_ROLES_GROUPS.GROUPS.EMPLOYER]}
            >
              <FOHView
                style={{ maxWidth: 157, width: '100%', marginRight: 16 }}
              >
                <FOHInverseButton
                  title={t('addNewPositionButton')}
                  icon={() => (
                    <FOHWhiteAddIcon style={{ width: 16, height: 16 }} />
                  )}
                  onPress={() => {
                    selectLocationFilter(locationID);
                    navigateTo(`${ADD_NEW_POSITION}`, {
                      state: { originFrom: navLocation?.pathname }
                    });
                  }}
                  style={{ height: 48, flex: 1, alignItems: 'center' }}
                  opaque
                  selected
                  textColor={FOHColors.WHITE}
                  color={FOHColors.PRIMARY_TEAL_P1}
                />
              </FOHView>
            </RestrictedView>
          </FOHView>
        </FOHView>
        <FOHView
          style={{
            width: '100%',
            padding: 20,
            backgroundColor: FOHColors.GRAYSCALE_10
          }}
        >
          {positions
            .sort((a, b) =>
              prop('positionName', a).localeCompare(prop('positionName', b))
            )
            .map((position, index) => (
              <FOHView
                key={index}
                style={{ zIndex: length(positions) - index }}
              >
                <PositionDashboardCell
                  {...props}
                  refetchQueryParams={
                    includePaused
                      ? variables
                      : {
                          ...variables,
                          hiring: true
                        }
                  }
                  isOpenNotPostedWarningModal={isOpenNotPostedWarningModal}
                  setIsOpenNotPostedWarningModal={
                    setIsOpenNotPostedWarningModal
                  }
                  location={location}
                  key={prop('positionName', position)}
                  position={position}
                  hiringStatus={path([0, 'hiringStatus'], positions)}
                  canChangeHiringFreely={canChangeHiringFreely}
                  subscribed={subscribed}
                />
              </FOHView>
            ))}
          {path(
            ['data', 'myPositions', 'pageInfo', 'hasNextPage'],
            positionsQuery
          ) && (
            <FOHInverseButton
              onPress={loadMore}
              title={t('loadMore')}
            ></FOHInverseButton>
          )}
        </FOHView>

        {/* New Applicants or New Matches */}
        <ApplicantsModal
          {...props}
          onRequestAllInterviews={async success => {
            if (success) {
              positionsQuery.refetch();
              setBanner({
                bannerType: FOHBannerType.SUCCESS,
                bannerMessage: t('interviewsSentSuccess')
              });
              setPollInterval(1500);
            } else {
              setBanner({
                bannerType: FOHBannerType.DANGER,
                bannerMessage: t('interviewSentFailure')
              });
            }
          }}
          onRequestInterview={() => {
            positionsQuery.refetch();
          }}
          open={
            modal === 'new_applicants' ||
            modal === 'new_matches' ||
            modal === 'applicants' ||
            modal === 'matches'
          }
          close={() =>
            navigateTo(`${EMPLOYER_DASHBOARD}/${prop('id', location)}`)
          }
        />
        {/* <MatchModal/> */}
        {/* <RecentAcceptModal/> */}
        <NewChatsModal
          {...props}
          open={modal === 'chats'}
          locationId={prop('id', location)}
        />
        <PlanModals
          {...props}
          backPath={`${EMPLOYER_DASHBOARD}/${prop('id', location)}`}
        />
        <ManageLocationPositionModals
          from={`${EMPLOYER_DASHBOARD}/${prop('id', location)}`}
        />
        <NotHiringConfirmationWarningModal
          confirmIUnderstand={() => setIsOpenNotPostedWarningModal(false)}
          open={isOpenNotPostedWarningModal}
        />
      </FOHView>
    </Screen>
  );
};
