import React from 'react';

import {
  FOHSpace,
  FOHHeaderH3,
  FOHTextField,
  FOHPhoneInput,
  FOHView
} from '../../ingredients';

import { FOHSelectItem } from '../select/FOHSelect';
import { FOHBorderlessSelect } from '../select/FOHBorderlessSelect';
import { FOHInterviewMeetingType } from '../blocks/FOHInterviewDetailsBlock';

export interface FOHInterviewTypeFieldsProps {
  whereQuestionLabel?: string;
  inPersonLabel: string;
  remoteMeetingLabel: string;
  isPhoneLabel: string;
  where: FOHInterviewMeetingType;
  setWhere: (remote: FOHInterviewMeetingType) => void;
  remoteUrlPlaceholder: string;
  remoteUrl: string;
  setRemoteUrl: (url: string) => void;
  phonePlaceholder: string;
  phone: string;
  setPhone: (url: string) => void;
  error?: any;
  schedulerIsInterviewer?: boolean | undefined;
}

export const FOHInterviewTypeFields = (props: FOHInterviewTypeFieldsProps) => (
  <>
    {props.whereQuestionLabel ? (
      <>
        <FOHHeaderH3 style={{ textAlign: 'left' }}>
          {props.whereQuestionLabel}
        </FOHHeaderH3>
        <FOHSpace />
      </>
    ) : (
      <></>
    )}
    <FOHView
      style={{
        flexDirection: 'row',
        flexWrap: 'wrap',
        flex: 1,
        width: '100%',
        alignItems: 'center'
      }}
    >
      <FOHView
        style={{
          width:
            props.where === FOHInterviewMeetingType.INPERSON
              ? '100%'
              : undefined
        }}
      >
        <FOHBorderlessSelect
          selectedValue={props.where}
          onValueChange={(value: any) => props.setWhere(value)}
          small={true}
          style={{
            width: '100%',
            maxWidth: '100%',
            fontSize: 14,
            lineHeight: 20,
            height: 33
          }}
          // disabled={!props.schedulerIsInterviewer}
        >
          <FOHSelectItem
            label={props.inPersonLabel}
            value={FOHInterviewMeetingType.INPERSON}
          />
          <FOHSelectItem
            label={props.remoteMeetingLabel}
            value={FOHInterviewMeetingType.REMOTE}
          />
          <FOHSelectItem
            label={props.isPhoneLabel}
            value={FOHInterviewMeetingType.PHONE}
          />
        </FOHBorderlessSelect>
      </FOHView>
      <FOHView
        style={{
          width:
            props.where === FOHInterviewMeetingType.INPERSON ? undefined : '50%'
        }}
      >
        {props.where === FOHInterviewMeetingType.REMOTE ? (
          <>
            <FOHView style={{ width: 142 }}>
              <FOHTextField
                placeholder={
                  props.remoteUrl ? undefined : props.remoteUrlPlaceholder
                }
                value={props.remoteUrl}
                onChangeText={props.setRemoteUrl}
                style={{
                  height: 33
                }}
              />
            </FOHView>
          </>
        ) : (
          <></>
        )}
        {props.where === FOHInterviewMeetingType.PHONE ? (
          <>
            <FOHView style={{ width: 142 }}>
              <FOHPhoneInput
                small={true}
                maxHeight={33}
                value={props.phone}
                keyboardType="numeric"
                autoCompleteType={'tel'}
                onChangeText={(value: string) => {
                  if (value) props.setPhone(value);
                  else props.setPhone(props.phone);
                }}
                error={
                  props.error && (props.error.phoneNumber || props.error.phone)
                    ? props.error.phoneNumber || props.error.phone
                    : null
                }
                containerStyle={{
                  height: 33
                }}
              />
            </FOHView>
          </>
        ) : (
          <></>
        )}
      </FOHView>
    </FOHView>
  </>
);
