import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FOHFonts,
  FOHColors,
  FOHButton,
  FOHBannerType,
  FOHTextButton,
  FOHView,
  FOHSpaceLarge,
  FOHGoldStar,
  FOHGrayEyeIcon,
  FOHInverseButton,
  FOHIntegrateCareerPageModal,
  ActionBarWrapper
} from '../../components';
import { copyToClipboard } from '../../utils';
import { useBanner } from '../Navigation/useBanner';

import { FORM_ID } from './consts';

export const FormActionBar = ({
  handleOpenDeleteConfirmationModal,
  isDeleteDisabled,
  isSubmitDisabled,
  slug
}) => {
  const { t } = useTranslation('CareerPage');
  const { setBanner } = useBanner();

  const [isIntegrateCareerPageModalOpen, setIsIntegrateCareerPageModalOpen] =
    useState(false);

  const buttonRef = useRef();

  const handleCloseIntegrateCareerPageModal = () =>
    setIsIntegrateCareerPageModalOpen(false);

  const careerPageUrl = `${document.location.origin}/restaurant/${slug}`;

  const handleCopyLink = () =>
    copyToClipboard({
      content: careerPageUrl,
      onSuccess: () =>
        setBanner({
          bannerMessage: t('copiedToClipboard'),
          bannerType: FOHBannerType.SUCCESS
        })
    });

  const handleOpenHelpCenterLink = () =>
    window.open('https://help.fohandboh.com/en/', '_blank');
  const handleOpenPreviewAsCandidate = () =>
    window.open(`${document.location.pathname}/preview`);

  return (
    <ActionBarWrapper>
      <button form={FORM_ID} hidden ref={buttonRef} type="submit" />
      <FOHView
        style={{
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%'
        }}
      >
        <FOHView
          style={{
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <FOHInverseButton
            enableUnderline
            icon={FOHGrayEyeIcon}
            onPress={handleOpenPreviewAsCandidate}
            style={{ height: 40, width: 230 }}
            title={t('viewAsCandidateButton')}
          />
          <FOHSpaceLarge />
          <FOHButton
            color={FOHColors.GOLD}
            icon={() => (
              <FOHGoldStar
                style={{
                  height: 20,
                  marginRight: 8,
                  tintColor: FOHColors.WHITE,
                  width: 20
                }}
              />
            )}
            onPress={() => setIsIntegrateCareerPageModalOpen(true)}
            style={{ height: 40, marginRight: 'auto', width: 250 }}
            textStyle={{
              fontFamily: FOHFonts.MEDIUM,
              fontSize: 16,
              fontWeight: '600'
            }}
            title={t('integrateWithMySiteButton')}
          />
        </FOHView>

        <FOHView
          style={{
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <FOHButton
            disabled={isSubmitDisabled}
            onPress={() => buttonRef.current.click()}
            style={{ height: 40, width: 250 }}
            title={t('saveChangesButton')}
          />
          <FOHSpaceLarge />
          <FOHTextButton
            disabled={isDeleteDisabled}
            onPress={handleOpenDeleteConfirmationModal}
            style={{
              color: FOHColors.RED_300,
              fontFamily: FOHFonts.REGULAR,
              fontSize: 16,
              fontWeight: 700,
              lineHeight: 24
            }}
            testID="FormActionBar-delete-button"
            title={t('deleteLabel')}
            underline
          />
        </FOHView>
      </FOHView>

      <FOHIntegrateCareerPageModal
        careerPageUrl={careerPageUrl}
        handleCloseIntegrateCareerPageModal={
          handleCloseIntegrateCareerPageModal
        }
        isIntegrateCareerPageModalOpen={isIntegrateCareerPageModalOpen}
        onCopyLinkPress={handleCopyLink}
        onHelpCenterLinkPress={handleOpenHelpCenterLink}
        onViewAsCandidatePress={handleOpenPreviewAsCandidate}
        translations={{
          integrateCareerPageModalCareerPageLink: t(
            'integrateCareerPageModalCareerPageLink'
          ),
          integrateCareerPageModalCopyLinkButton: t(
            'integrateCareerPageModalCopyLinkButton'
          ),
          integrateCareerPageModalDescription: t(
            'integrateCareerPageModalDescription'
          ),
          integrateCareerPageModalFirstStepDescription: t(
            'integrateCareerPageModalFirstStepDescription'
          ),
          integrateCareerPageModalFirstStepHeader: t(
            'integrateCareerPageModalFirstStepHeader'
          ),
          integrateCareerPageModalHelpCenterCD: t(
            'integrateCareerPageModalHelpCenterCD'
          ),
          integrateCareerPageModalHelpCenterDescription: t(
            'integrateCareerPageModalHelpCenterDescription'
          ),
          integrateCareerPageModalHelpCenterHeader: t(
            'integrateCareerPageModalHelpCenterHeader'
          ),
          integrateCareerPageModalHelpCenterLink: t(
            'integrateCareerPageModalHelpCenterLink'
          ),
          integrateCareerPageModalReady: t('integrateCareerPageModalReady'),
          integrateCareerPageModalSecondStepDescription: t(
            'integrateCareerPageModalSecondStepDescription'
          ),
          integrateCareerPageModalSecondStepHeader: t(
            'integrateCareerPageModalSecondStepHeader'
          ),
          integrateCareerPageModalThirdStepDescription: t(
            'integrateCareerPageModalThirdStepDescription'
          ),
          integrateCareerPageModalThirdStepHeader: t(
            'integrateCareerPageModalThirdStepHeader'
          ),
          integrateCareerPageModalTitle: t('integrateCareerPageModalTitle'),
          integrateCareerPageModalTitleCD: t('integrateCareerPageModalTitleCD'),
          integrateCareerPageModalViewAsCandidate: t(
            'integrateCareerPageModalViewAsCandidate'
          ),
          integrateCareerPageModalYourCareerPageLink: t(
            'integrateCareerPageModalYourCareerPageLink'
          )
        }}
      />
    </ActionBarWrapper>
  );
};
