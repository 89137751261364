import React from 'react';

import { Image } from 'react-native';
import styled from 'styled-components/native';

import {
  FOHButton,
  FullWidthCard,
  FOHLeftArrow,
  ButtonText,
  FOHAltSectionLabel,
  FOHView,
  FOHTouchableOpacity
} from '../../ingredients';
import { FOHLocationDetailsProps, FOHLocationDetails } from '../../recipes';

export interface FOHLocationBlockProps extends FOHLocationDetailsProps {
  children?: any;
  claimBusiness?: () => void;
  claimBusinessLabel?: string;
  vertical?: boolean;
  modal?: boolean;
  backText?: string;
  navigateBack?: () => void;
  locationLabel?: string;
}

export const FOHLocationBlock = (props: FOHLocationBlockProps) => (
  <FOHView
    style={{
      flexDirection: props.vertical ? 'column' : 'row',
      left: 0,
      width: '100%',
      backgroundColor: props.vertical || props.modal ? '#ffffff' : '#F5F5F5'
    }}
  >
    <FOHView
      style={{
        flex: 3,
        justifyContent: 'center',
        width: '100%'
      }}
    >
      {props.image && props.vertical && (
        <LocationImage
          resizeMode="contain"
          source={{ uri: props.image }}
          style={{
            width: '100%',
            borderRadius: 0
          }}
        />
      )}
      <FOHView style={{ flex: 1, marginLeft: props.vertical ? 0 : 32 }}>
        {props.navigateBack && !!props.backText && !props.vertical ? (
          <FOHTouchableOpacity
            onPress={props.navigateBack}
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              padding: props.vertical ? 32 : 16
            }}
          >
            <FOHLeftArrow />
            <FOHView style={{ padding: 2 }} />
            <ButtonText>{props.backText}</ButtonText>
          </FOHTouchableOpacity>
        ) : (
          <></>
        )}
        <FullWidthCard
          style={{
            padding: props.vertical ? 16 : 32,
            width: '100%',
            backgroundColor: '#ffffff',
            borderColor: props.modal || props.vertical ? '#ffffff' : '#f5f5f5'
          }}
        >
          <FOHLocationDetails
            vertical={props.vertical}
            favoriteLabel={props.favoriteLabel}
            hideLabel={props.hideLabel}
            hourlyRatesTitle={props.hourlyRatesTitle}
            positionRates={props.positionRates}
            id={props.id}
            name={props.name}
            formattedAddress={props.formattedAddress}
            tags={props.tags}
            isFavorited={props.isFavorited}
            isHidden={props.isHidden}
            pressHide={props.pressHide}
            pressFavorite={props.pressFavorite}
            answersLabel={props.answersLabel}
            answers={props.answers}
            summaryTitle={props.summaryTitle}
            summary={props.summary}
            claimBusinessLabel={props.claimBusinessLabel}
            claimBusiness={props.claimBusiness}
            followerCountLabel={props.followerCountLabel}
            jobs={props.jobs}
            jobSectionTitle={props.jobSectionTitle}
            onJobPress={props.onJobPress}
            a={props.a}
            jobCTAText={props.jobCTAText}
          />
        </FullWidthCard>
      </FOHView>
    </FOHView>
    {!props.vertical && <FOHView style={{ padding: 24 }} />}
    <FOHView
      style={{
        flex: 2,
        left: '0'
      }}
    >
      {props.claimBusiness && !!props.claimBusinessLabel && !props.vertical ? (
        <FOHView
          style={{
            width: 424,
            paddingBottom: 25,
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <FOHButton
            style={{
              maxWidth: 424
            }}
            title={props.claimBusinessLabel}
            onPress={props.claimBusiness}
          />
        </FOHView>
      ) : (
        <></>
      )}

      {props.image && !props.vertical && (
        <LocationImage
          source={{ uri: props.image }}
          resizeMode="contain"
          style={{ marginBottom: props.claimBusiness ? 40 : 64 }}
        />
      )}

      <FOHView
        style={{
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          paddingLeft: props.vertical ? 16 : 0,
          zIndex: 9999
        }}
      >
        <FOHAltSectionLabel>{props.locationLabel}</FOHAltSectionLabel>
      </FOHView>

      {/* pass map in as child */}
      {props.children}
    </FOHView>
  </FOHView>
);

const LocationImage = styled(Image)`
  border-radius: 3px;
  width: 424px;
  height: 300px;
  background-color: #f2f2f2;
`;
