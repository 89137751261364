import React from 'react';

import {
  FOHHeaderText,
  FOHDetailText,
  FOHButton,
  FOHErrorLabel,
  Screen,
  FOHTextField,
  Row,
  FOHPasswordField,
  FOHView
} from '../../ingredients';

export interface FOHPasswordResetReturnScreenProps {
  handleSubmit: () => void;
  password: string;
  passwordPlaceholder: string;
  setPassword: (value: string) => void;
  passwordConfirm: string;
  passwordConfirmPlaceholder: string;
  setPasswordConfirm: (value: string) => void;
  submitLabel: string;
  title: string;
  detailText: string;
  error?: any;
  disabledSubmit?: boolean;
  largeHeader?: boolean;
  showPassText?: string;
  hidePassText?: string;
}

export const FOHPasswordResetReturnScreen = (
  props: FOHPasswordResetReturnScreenProps
) => (
  <Screen>
    <FOHView
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 60
      }}
    >
      <FOHHeaderText style={{ fontSize: props.largeHeader ? 30 : 20 }}>
        {props.title}
      </FOHHeaderText>
      <FOHView style={{ paddingTop: 8 }} />
      <Row>
        <FOHDetailText>{props.detailText}</FOHDetailText>
      </Row>
      <FOHView style={{ paddingTop: 18 }} />
      <Row>
        <FOHPasswordField
          passwordPlaceholder={props.passwordPlaceholder}
          password={props.password}
          setPassword={(value: string) =>
            props.setPassword && props.setPassword(value)
          }
          error={
            props.error && props.error.password ? props.error.password : null
          }
          showPassText={props.showPassText}
          hidePassText={props.hidePassText}
        />
      </Row>
      <Row>
        <FOHTextField
          placeholder={props.passwordConfirmPlaceholder}
          value={props.passwordConfirm}
          secureTextEntry={true}
          autoCompleteType={'password'}
          onChangeText={(value: any) => props.setPasswordConfirm(value)}
          error={
            props.error && props.error.passwordConfirm
              ? props.error.passwordConfirm
              : null
          }
        />
      </Row>
      <FOHErrorLabel style={{ paddingBottom: 8 }}>
        {props.error ? props.error.detail : null}
      </FOHErrorLabel>
      <FOHButton
        onPress={props.handleSubmit}
        title={props.submitLabel}
        disabled={props.disabledSubmit}
      />
    </FOHView>
  </Screen>
);
