import React from 'react';

import hired from '../../../images/hired_icon.svg';

import { FOHImage } from '../../ingredients';

export const FOHHiredIcon = ({ style }: { style?: any }) => (
  <FOHImage
    source={{ uri: hired }}
    style={{ width: 22, height: 19, ...style }}
    testID="FOHHiredIcon"
  />
);
