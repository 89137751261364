import React, { useState, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { defaultTo, length, path, prop } from 'ramda';

import {
  FOHLabelSmallest,
  FOHLabelBold,
  FOHColors,
  Screen,
  FOHSpaceSmall,
  FOHSpace,
  FOHView,
  FOHDropZoneBox,
  FOHBannerType,
  FOHEditMarker,
  FOHInverseButton,
  FOHSmallDetailLabel,
  FOHEmployerCandidateQuestionnaire,
  FOHVideoScreeningForm,
  FOHFormFlowButtons,
  FOHVideoPlayer
} from '../../components';
import { findById, handleMutation, useIsMobile } from '../../utils';
import screening_ques from '../../images/screening_ques.svg';
import video_screening_question from '../../images/video_screening_question.svg';
import {
  useEmployerCandidateQuestionnaireQuery,
  useScreeningAnswerMutation
} from '../../graphql/generated';
import { HOME_PAGE } from '../../constants';
import { useNavigation } from '../../utils/navigation';

import { useBanner } from '../Navigation/useBanner';
import { getSearchQuery, QTOKEN } from '../Search';

const hasAnsweredRequiredQuestion = (
  questions,
  answers = [{ questionId: '' }]
) => {
  if (!questions || length(questions) === 0) {
    return true;
  }

  return (
    length(
      questions.filter(question => {
        const answer = findById(
          prop('questionId', question),
          answers,
          'questionId'
        );
        return prop('answer', answer);
      })
    ) !== length(questions)
  );
};

const VideoUploadBlock = props => {
  const { isMobile, setVideo, video, setVideoFile, videoFile, t } = props;

  const reader = new FileReader();

  const inputFile = useRef(null);

  reader.onload = e => {
    const blob = e.target.result;
    setVideo(blob);
  };

  const VideoComponent = _props => (
    <video
      width={isMobile ? '114' : '50'}
      height={isMobile ? '114' : '50'}
      controls
    >
      <track kind="captions" />
      <source src={video} type="video/mp4" />
    </video>
  );

  return (
    <>
      <FOHDropZoneBox>
        <FOHView
          style={{
            justifyContent: 'space-between',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center'
          }}
          testID="VideoUploadBlock"
        >
          <FOHView style={{ width: isMobile ? 114 : 50 }}>
            <input
              type="file"
              id="file"
              onChange={e => {
                const file = e.target.files[0];
                if (file) {
                  setVideoFile(file);
                  reader.readAsDataURL(file);
                }
              }}
              ref={inputFile ? inputFile : null}
              style={{ display: 'none' }}
            />

            <FOHVideoPlayer
              video={video}
              VideoComponent={VideoComponent}
              height={isMobile ? 114 : 50}
              pickVideo={() => {
                inputFile && inputFile.current.click();
              }}
            />
          </FOHView>
          {isMobile ? <FOHSpaceSmall /> : <FOHSpace />}

          <FOHView
            style={{
              flexDirection: isMobile ? 'column' : 'row',
              flexWrap: 'wrap',
              flex: 1,
              alignItems: isMobile ? 'flex-start' : 'center',
              justifyContent: 'space-between'
            }}
          >
            {videoFile ? (
              <FOHView style={{ maxWidth: '50%' }}>
                <FOHLabelBold style={{ textDecorationLine: 'underline' }}>
                  {videoFile.name}
                </FOHLabelBold>
                <FOHLabelSmallest>
                  {Math.round(videoFile.size / 1024)} Mb
                </FOHLabelSmallest>
              </FOHView>
            ) : (
              <FOHSmallDetailLabel>
                {t('videoSupportedLabel')}
              </FOHSmallDetailLabel>
            )}
            <FOHSpace />
            {inputFile &&
              (video ? (
                <FOHView style={{ width: isMobile ? '100%' : 165 }}>
                  <FOHInverseButton
                    textColor={FOHColors.BLUE}
                    color={FOHColors.LIGHT_BLUE}
                    selected={true}
                    icon={() => <FOHEditMarker />}
                    onPress={() => inputFile && inputFile.current.click()}
                    title={t('replaceLabel')}
                  />
                </FOHView>
              ) : (
                <FOHView
                  style={{ minWidth: 60, width: isMobile ? '100%' : 165 }}
                >
                  <FOHInverseButton
                    onPress={() => inputFile && inputFile.current.click()}
                    title={t('uploadVideoLabel')}
                  />
                </FOHView>
              ))}
          </FOHView>
          <FOHSpace />
        </FOHView>
      </FOHDropZoneBox>
    </>
  );
};

const EmployerCandidateQuestionnaire = () => {
  const { t } = useTranslation('EmployerCandidateQuestionnaireFeature');
  const { navigateTo, location: navLocation } = useNavigation();

  const { setBanner } = useBanner();

  const { isMobile } = useIsMobile();

  const [answers, setAnswers] = React.useState([]);
  const [errors, setErrors] = React.useState('');
  const [videoFile, setVideoFile] = useState(undefined);
  const [video, setVideo] = useState('');

  const paths = defaultTo('', path(['pathname'], navLocation)).split('/');
  const uuid = prop(2, paths);
  const token = prop(4, paths) || getSearchQuery(navLocation, QTOKEN);

  const [screeningQuestionnaireMutation] = useScreeningAnswerMutation();

  const employerCandidateQuestionnaireQuery =
    useEmployerCandidateQuestionnaireQuery({
      variables: { uuid, token }
    });

  const employerCandidateQuestionnaire = path(
    ['data', 'employerCandidateQuestionnaire'],
    employerCandidateQuestionnaireQuery
  );

  const questions = defaultTo(
    [],
    path(
      ['screeningQuestionnaire', 'screeningQuestion', 'edges'],
      employerCandidateQuestionnaire
    )
  ).map(que => ({
    questionText: path(['node', 'question'], que),
    questionId: path(['node', 'id'], que)
  }));

  const handleSubmit = async () => {
    if (
      employerCandidateQuestionnaire?.screeningQuestionnaire
        ?.questionnaireType === 'VIDEO'
    ) {
      const firstQuestion = prop(0, questions);

      const [res, error] = await handleMutation(
        screeningQuestionnaireMutation({
          variables: {
            employerCandidateQuestionnaireId: prop(
              'id',
              employerCandidateQuestionnaire
            ),
            questionsAnswered: [
              {
                questionId: prop('questionId', firstQuestion),
                questionText: prop('questionText', firstQuestion),
                answer: '',
                fileName: videoFile.name,
                fileType: videoFile.type
              }
            ]
          }
        })
      );
      if (error || !res) {
        setBanner({
          bannerType: FOHBannerType.ERROR,
          bannerMessage: error.message
        });
        setErrors(prop('message', error));
      } else {
        const uploadUrl = path(
          ['createScreeningAnswer', 'answersDetail', 0, 'signedS3Url'],
          res
        );

        await fetch(uploadUrl, {
          method: 'PUT',
          body: videoFile
        });

        setBanner({
          bannerType: FOHBannerType.SUCCESS,
          bannerMessage: 'Success'
        });
      }
    } else {
      const [res, error] = await handleMutation(
        screeningQuestionnaireMutation({
          variables: {
            employerCandidateQuestionnaireId: prop(
              'id',
              employerCandidateQuestionnaire
            ),
            questionsAnswered: answers
          }
        })
      );

      if (error || !res) {
        setBanner({
          bannerType: FOHBannerType.ERROR,
          bannerMessage: error.message
        });
        setErrors(prop('message', error));
      }

      setBanner({
        bannerType: FOHBannerType.SUCCESS,
        bannerMessage: 'Success'
      });
    }

    navigateTo(HOME_PAGE);
  };

  const employerUser = path(
    ['employerCandidate', 'employerProfile', 'users', 'edges', 0, 'node'],
    employerCandidateQuestionnaire
  );
  return (
    <Screen>
      <FOHView
        style={{
          alignItems: 'center',
          width: '100%',
          minHeight: '100vh',
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
          paddingTop: 50
        }}
      >
        {employerCandidateQuestionnaire &&
        employerCandidateQuestionnaire.screeningQuestionnaire
          .questionnaireType === 'VIDEO' ? (
          <FOHView>
            <FOHSpace />
            <FOHVideoScreeningForm
              isMobile={isMobile}
              detailLabel={t('videoDetailLabel')}
              candidateName={
                employerCandidateQuestionnaire.employerCandidate
                  .candidateProfile.user.firstName
              }
              titleDecorationImage={video_screening_question}
              header={t('videoHeader')}
              headerHighlight={t('videoHeaderHighlight')}
              videoLabel={t('videoResponseLabel')}
              positionLabel={t('positionLabel')}
              position={defaultTo(
                '',
                path(
                  ['position', 'positionName'],
                  employerCandidateQuestionnaire
                )
              )}
              locationLabel={t('locationLabel')}
              locationImage={defaultTo(
                '',
                path(
                  ['position', 'location', 'image'],
                  employerCandidateQuestionnaire
                )
              )}
              locationName={defaultTo(
                '',
                path(
                  ['position', 'location', 'name'],
                  employerCandidateQuestionnaire
                )
              )}
              locationAddress={defaultTo(
                '',
                path(
                  ['position', 'location', 'formattedAddress'],
                  employerCandidateQuestionnaire
                )
              )}
              customNoteLabel={t('customNoteLabel')}
              customNote={questions.length > 0 ? questions[0].questionText : ''}
              hiringManagerProfileImage={path(['profileImage'], employerUser)}
              hiringManagerLabel={t('hiringManagerLabel')}
              hiringManagerName={path(['firstName'], employerUser)}
            >
              <VideoUploadBlock
                isMobile={isMobile}
                videoFile={videoFile}
                setVideoFile={setVideoFile}
                video={video}
                setVideo={setVideo}
                t={t}
              />
            </FOHVideoScreeningForm>
            <FOHSpace />
            <FOHView style={{ width: '100%', justifyContent: 'center' }}>
              <FOHFormFlowButtons
                isMobile={isMobile}
                nextButtonlabel={t('videoSubmitLabel')}
                onPressNextButton={handleSubmit}
                nextButtonStyle={{
                  maxWidth: 270,
                  marginLeft: 'auto',
                  marginRight: 'auto'
                }}
              />
            </FOHView>
          </FOHView>
        ) : (
          <FOHEmployerCandidateQuestionnaire
            errros={errors}
            isMobile={isMobile}
            onSubmit={handleSubmit}
            disabledSubmit={hasAnsweredRequiredQuestion(questions, answers)}
            submitBtnLabel={t('nextLabel')}
            image={screening_ques}
            headerText={t('timeForScreeningQuestionsLabel')}
            highLightedHeaderText={t('highlightedLabel')}
            detailText={t('detailLabel')}
            positionLabel={t('positionLabel')}
            position={defaultTo(
              '',
              path(['position', 'positionName'], employerCandidateQuestionnaire)
            )}
            locationLabel={t('locationLabel')}
            locationImage={defaultTo(
              '',
              path(
                ['position', 'location', 'image'],
                employerCandidateQuestionnaire
              )
            )}
            locationName={defaultTo(
              '',
              path(
                ['position', 'location', 'name'],
                employerCandidateQuestionnaire
              )
            )}
            locationAddress={defaultTo(
              '',
              path(
                ['position', 'location', 'formattedAddress'],
                employerCandidateQuestionnaire
              )
            )}
            textFieldPlaceholder={t('inputPlaceHolder')}
            questions={questions}
            answers={answers}
            onInputChange={answered => {
              const targetIndex = answers.findIndex(
                que => prop('questionId', que) === prop('questionId', answered)
              );
              if (targetIndex !== -1) {
                answers[targetIndex].answer = defaultTo(
                  '',
                  prop('answer', answered)
                );
                setAnswers(answers);
              } else {
                setAnswers([...answers, answered]);
              }
            }}
          />
        )}
      </FOHView>
    </Screen>
  );
};

export default EmployerCandidateQuestionnaire;
