import React from 'react';

import styled from 'styled-components/native';

import { useIsMobile } from '../../../utils';

import {
  FOHLabel,
  FOHView,
  FOHRestaurantIcon,
  FOHColors,
  FOHSpace,
  FOHLabelSmall,
  FOHXIcon,
  FOHTextButton,
  FOHTextButtonProps
} from '../../ingredients';

export interface FOHAccountConnectedProps {
  accountName: string | null | undefined;
  connectedBy: string;
  disconnectLabel: string;
  onPressDisconnect: () => void;
  disconnectButtonProps: Partial<FOHTextButtonProps>;
}

export const FOHAccountConnected = (props: FOHAccountConnectedProps) => {
  const { isMobile, width } = useIsMobile();

  const mobile = isMobile && width <= 527;

  return (
    <Container
      style={{
        minHeight: mobile ? 96 : 'unset',
        maxHeight: mobile ? 112 : 'unset'
      }}
    >
      <Wrapper>
        <LeftBlock
          style={{
            flex: mobile ? 'unset' : 3,
            maxWidth: mobile ? '100%' : 'unset'
          }}
        >
          <IconContainer>
            <FOHRestaurantIcon style={{ width: 26, height: 26 }} />
          </IconContainer>
          <FOHSpace />
          <FOHView
            style={{
              flex: 1
            }}
          >
            <FOHLabel
              style={{
                color: FOHColors.GRAYSCALE_700,
                fontWeight: 600
              }}
              testID={'FOHAccountConnected-account-name'}
            >
              {props.accountName}
            </FOHLabel>
            <FOHLabelSmall
              style={{
                color: FOHColors.GRAYSCALE_700,
                fontWeight: 400
              }}
            >
              {props.connectedBy}
            </FOHLabelSmall>
          </FOHView>
        </LeftBlock>
        <RightBlock
          style={{
            flex: mobile ? 'unset' : 1.5,
            alignItems: 'flex-end',
            width: mobile ? '100%' : 'unset'
          }}
        >
          <FOHTextButton
            title={props.disconnectLabel}
            disabled={false}
            icon={() =>
              FOHXIcon({
                large: false,
                style: { width: 16, height: 16, top: 1 }
              })
            }
            onPress={props.onPressDisconnect}
            underline
            {...props.disconnectButtonProps}
            testID={'FOHAccountConnected-disconnect'}
          />
        </RightBlock>
      </Wrapper>
    </Container>
  );
};

const Container = styled(FOHView)`
  width: 100%;
  align-items: center;
  height: 60px;
  justify-content: center;
  background-color: ${FOHColors.WHITE};
`;

const Wrapper = styled(FOHView)`
  flex-direction: row;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  flex: 1;
`;

const LeftBlock = styled(FOHView)`
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
`;

const IconContainer = styled(FOHView)`
  background-color: ${FOHColors.GRAYSCALE_100};
  border-radius: 50%;
  padding: 15px;
`;

const RightBlock = styled(FOHView)`
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
`;
