import React, { useEffect } from 'react';

import { length, path, prop, defaultTo } from 'ramda';
import { useTranslation } from 'react-i18next';
import { create } from 'zustand';
import { useParams } from 'react-router-dom';

import {
  FOHColors,
  FOHSpace,
  FOHView,
  RestrictedView,
  useIsRestricted,
  FOHSearchableSelectDropdown,
  FOHPlanState,
  FOHBlueAdd,
  FOHFilterIcon,
  FOHLocationPin,
  FOHToolBoxIcon,
  FOHButtonBordered,
  FOHLink,
  FOHPositionBillingStatusType
} from '../../components';
import { findById } from '../../utils';
import {
  ADD_NEW_LOCATION,
  ADD_NEW_POSITION,
  ALLOWED_ROLES_GROUPS,
  FEED,
  UPGRADE
} from '../../constants';
import { useNavigation } from '../../utils/navigation';

import { usePaymentPlan } from '../Billing/usePaymentPlan';
import { useCurrentLocationContext } from '../Locations/useCurrentLocationContext';
import { BoxOverLay } from '../CandidateNetworkSearch/SearchAndFilters';

import { useCandidateFeedContext } from './useCandidateFeedContext';
import { useCandidateFeedFiltersContext } from './useCandidateFeedFiltersContext';
import { FOHFeedAnnouncements } from './components';

export const useDropdownsStore = create(set => ({
  locationOpen: false,
  positionOpen: false,
  setLocationOpen: locationOpen =>
    set(state => ({
      ...state,
      locationOpen
    })),
  setPositionOpen: positionOpen =>
    set(state => ({
      ...state,
      positionOpen
    }))
}));

const NewInboxFeedBar = props => {
  const { t } = useTranslation('CandidateFeed');
  const { modal } = useParams();

  const { locationOpen, positionOpen, setLocationOpen, setPositionOpen } =
    useDropdownsStore();

  const { navigateTo, location: navLocation } = useNavigation();
  const { isRestricted } = useIsRestricted({
    allowedRoles: ALLOWED_ROLES_GROUPS.ROLES.ADMIN_USER,
    allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.EMPLOYER]
  });

  const { setIsFilterDrawerOpen, isFilterDrawerOpen, profileDetailFilters } =
    useCandidateFeedFiltersContext();
  const {
    myPositions,
    myLocations,
    positionFilters,
    locationFilters,
    multiSelectLocation,
    selectAllLocations,
    multiSelectPosition,
    setPositionSearch,
    setLocationSearch,
    positionSearch,
    locationSearch,
    myPositionsQuery,
    selectAllPositions
  } = useCandidateFeedContext();

  // control location selection of payment modal via context
  const { selectLocationFilter, locationFilter } = useCurrentLocationContext({
    skipLocationsQuery: true
  });
  const { getCurrentPlanName } = usePaymentPlan({
    locationFilter,
    currentPlanQ: true
  });

  const locationOptions = myLocations.map(loc => ({
    label: prop('displayName', loc),
    value: prop('id', loc),
    sublabel: prop('formattedAddress', loc)
  }));

  const positionOptions =
    locationFilters.length < 1
      ? []
      : myPositions.map(pos => {
          const isHiring = path(['hiring'], pos) || false;
          const planName = getCurrentPlanName(pos);
          const isNotSubscribed =
            path(['paymentStatus'], pos) !==
              FOHPositionBillingStatusType.SUBSCRIBED &&
            path(['location', 'paymentStatus'], pos) !==
              FOHPositionBillingStatusType.SUBSCRIBED;

          return {
            label: prop('positionName', pos),
            value: prop('id', pos),
            sublabel: `${prop('location', pos)?.displayName} - ${
              prop('location', pos)?.formattedAddress
            }`,
            // prompt for upgrade label
            upgrade:
              (planName === FOHPlanState.payasyougo || planName === null) &&
              !isRestricted &&
              isNotSubscribed,
            // paused when role is interviewer and position/location
            isPaused:
              ((planName === FOHPlanState.unlimited ||
                planName === FOHPlanState.enterprise) &&
                !isHiring) ||
              ((planName === FOHPlanState.payasyougo || planName === null) &&
                ((isRestricted && isNotSubscribed) ||
                  (!isHiring && !isNotSubscribed))),
            // restrict the user on action clickable
            isUserRestricted:
              (planName === FOHPlanState.payasyougo || planName === null) &&
              isNotSubscribed &&
              isRestricted,
            locationIdToUpgrade: path(['location', 'id'], pos)
          };
        });

  // check if position from redirect URL params is paid for
  const firstPosition = findById(prop(0, positionFilters), myPositions);
  const firstPositionLocationIsSubscribed =
    path(['location', 'paymentStatus'], firstPosition) ===
    FOHPositionBillingStatusType.SUBSCRIBED;
  const firstPositionIsSubscribed =
    path(['paymentStatus'], firstPosition) ===
      FOHPositionBillingStatusType.SUBSCRIBED ||
    firstPositionLocationIsSubscribed;
  const firstPositionsLocationId = path(['location', 'id'], firstPosition);
  const loadingPositions = myPositionsQuery.loading;
  const pathname = defaultTo('', navLocation.pathname);

  // Effect for opening payment modal on new feed
  useEffect(() => {
    if (
      !firstPositionIsSubscribed &&
      !loadingPositions &&
      !pathname.includes(UPGRADE) &&
      !modal &&
      firstPositionsLocationId
    ) {
      selectLocationFilter(firstPositionsLocationId);
      navigateTo(`${FEED}${UPGRADE}`);
    }
  }, [
    firstPositionIsSubscribed,
    loadingPositions,
    firstPositionsLocationId,
    pathname,
    modal,
    selectLocationFilter
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FOHView style={{ zIndex: 1000 }}>
      <FOHView
        style={{
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          zIndex: 1000,
          justifyContent: 'space-between'
        }}
      >
        <FOHView
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-start',
            paddingBottom: 8
          }}
        >
          <FOHSearchableSelectDropdown
            style={{ width: 230 }}
            open={locationOpen}
            setOpen={open => {
              setPositionOpen(false);
              setLocationOpen(open);
            }}
            selectedValue={locationFilters}
            selectedLabel={
              !prop(0, locationFilters)
                ? 'No location selected'
                : length(locationFilters) === 1
                ? undefined
                : `${length(locationFilters)} Locations`
            }
            showLabel={t('Show')}
            onSearchChange={setLocationSearch}
            searchValue={locationSearch}
            multiSelect={true}
            searchPlaceholder={t('common:search')}
            onValueChange={value => {
              props.ignoreAllStages();
              multiSelectLocation(value);
            }}
            options={locationOptions}
            fieldLabel={t('locations').toUpperCase()}
            icon={() => <FOHLocationPin />}
            testID="candidate-feed-location-filter"
            selectAllLabel={t('selectAllLocations')}
            isSelectAllSelected={
              locationOptions.length === locationFilters.length
            }
            onPressSelectAll={selectAllLocations}
          >
            <RestrictedView
              allowedRoles={ALLOWED_ROLES_GROUPS.ROLES.ADMIN}
              allowedGroups={[ALLOWED_ROLES_GROUPS.GROUPS.EMPLOYER]}
            >
              <FOHLink
                onPress={() => {
                  navigateTo(`${ADD_NEW_LOCATION}`, {
                    state: { originFrom: FEED }
                  });
                }}
                linkText={t('TopNavFeature:addLocation')}
                Icon={FOHBlueAdd}
                iconSize={24}
              />
            </RestrictedView>
          </FOHSearchableSelectDropdown>
          <FOHSpace />
          <FOHSearchableSelectDropdown
            style={{ width: 230 }}
            open={positionOpen}
            setOpen={open => {
              setPositionOpen(open);
              setLocationOpen(false);
            }}
            selectedValue={positionFilters || []}
            multiSelect={true}
            selectedLabel={
              !prop(0, positionFilters)
                ? 'No position selected'
                : length(positionFilters) === 1
                ? undefined
                : `${length(positionFilters)} Positions`
            }
            showLabel={t('Show')}
            onSearchChange={setPositionSearch}
            searchValue={positionSearch}
            searchPlaceholder={t('common:search')}
            onValueChange={value => {
              props.ignoreAllStages();
              multiSelectPosition(value);
            }}
            options={positionOptions}
            fieldLabel={t('positions').toUpperCase()}
            upgradeLabel={t('upgrade')}
            pausedLabel={t('PricingScreenFeature:notPostedLabel')}
            onPressUpgrade={({ locationIdToUpgrade }) => {
              selectLocationFilter(locationIdToUpgrade);
              navigateTo(`${FEED}${UPGRADE}`);
            }}
            icon={() => (
              <FOHToolBoxIcon
                style={{
                  width: 14,
                  height: 14,
                  tintColor: FOHColors.PACIFIC_BLUE
                }}
              />
            )}
            testID="candidate-feed-position-filter"
            selectAllLabel={t('selectAllPositions')}
            isSelectAllSelected={
              positionOptions.filter(position => !position.upgrade).length !==
                0 &&
              positionOptions.filter(position => !position.upgrade).length ===
                positionFilters.length
            }
            onPressSelectAll={selectAllPositions}
          >
            <RestrictedView
              allowedRoles={ALLOWED_ROLES_GROUPS.ROLES.ADMIN_USER}
              allowedGroups={[ALLOWED_ROLES_GROUPS.GROUPS.EMPLOYER]}
            >
              <FOHLink
                onPress={() => {
                  navigateTo(`${ADD_NEW_POSITION}`, {
                    state: { originFrom: FEED }
                  });
                }}
                linkText={t('createJobLabel').replace('+ ', '')}
                testID="candidate-feed-position-filter-add-position"
                Icon={FOHBlueAdd}
                iconSize={24}
              />
            </RestrictedView>
          </FOHSearchableSelectDropdown>
          <FOHSpace />
          <FOHView>
            <FOHButtonBordered
              icon={() => (
                <FOHFilterIcon
                  active={isFilterDrawerOpen}
                  style={{ tintColor: FOHColors.GRAYSCALE_700 }}
                />
              )}
              title={`${t('advancedFilters')}${
                profileDetailFilters.length
                  ? ` (${profileDetailFilters.length})`
                  : ''
              }`}
              onPress={() => {
                setIsFilterDrawerOpen(!isFilterDrawerOpen);
              }}
              selected={isFilterDrawerOpen ? true : false}
              opaque={true}
              style={{ width: 200, marginRight: 8, height: 52 }}
              testID="CandidateFeed-filters-button"
            />
          </FOHView>
        </FOHView>
      </FOHView>
      <BoxOverLay
        isFilterDrawerOpen={isFilterDrawerOpen}
        {...props}
        hideDivider={!isFilterDrawerOpen}
        hideCandidateCount={true}
        isCandidateFeed={true}
      ></BoxOverLay>
    </FOHView>
  );
};

export default NewInboxFeedBar;
