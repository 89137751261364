import React, { useMemo, useState } from 'react';

import Loadable from '@loadable/component';
import { SendBirdProvider } from '@sendbird/uikit-react';
import { defaultTo, prop } from 'ramda';

import { useGetMeData } from '../SignIn';

import { useChatWindow } from './useChatWindow';
import { colorSet, sendBirdAppId } from './SendBirdChat';

const ChatWindow = Loadable(() => import('../Chat/ChatWindow'));

export const withChatWindow = Cmp => props => {
  const { channelUrl } = useChatWindow();

  const [stringSet] = useState({
    PLACE_HOLDER__NO_CHANNEL: 'No conversation selected'
  });

  const { data: me } = useGetMeData({});
  const sendbirdUserId = useMemo(() => defaultTo(null, prop('uuid', me)), [me]);

  const photo = useMemo(() => defaultTo(null, prop('profileImage', me)), [me]);

  const sendbirdAccessToken = useMemo(
    () => defaultTo(null, prop('sendbirdAccessToken', me)),
    [me]
  );

  if (!me || !sendbirdUserId || !sendbirdAccessToken) {
    return <Cmp {...props} />;
  }

  return (
    <SendBirdProvider
      appId={sendBirdAppId}
      userId={sendbirdUserId}
      accessToken={sendbirdAccessToken}
      colorSet={colorSet}
      profileUrl={photo || undefined}
      stringSet={stringSet}
    >
      <Cmp {...props} />
      {channelUrl && <ChatWindow {...props} key={channelUrl} />}
    </SendBirdProvider>
  );
};
