import React from 'react';

import { TouchableOpacity, View } from 'react-native';
import { prop, defaultTo } from 'ramda';

import { useClickOutside } from '../../spices';
import { FOHPositionHiringStatusType } from '../../typings';
import {
  FOHColors,
  FOHDropDownArrow,
  FOHEyeIcon,
  FOHHide,
  FOHLabelBold,
  FOHSpaceSmall,
  FullWidthCard
} from '../../ingredients';

import { StatusDescription, StatusLabel } from '../cells/FOHStatusSelectCell';

export interface FOHPositionHiringStatusSelectProps {
  label: string;
  description: string;
  value: FOHPositionHiringStatusType;
}

export interface FOHPositionHiringStatusDropdownProps {
  statusType: FOHPositionHiringStatusType;
  statusText: string;
  openStatus: boolean;
  setOpenStatus: (open: boolean) => void;
  selectStatus: (status: any) => void;
  statusOptions: Array<FOHPositionHiringStatusSelectProps>;
  subscribed?: boolean;
  userIsInterviewer?: boolean;
}

const PostedHiringStatusIcon = () => (
  <FOHEyeIcon
    style={{ tintColor: FOHColors.GREEN_200, height: 20, width: 20 }}
  />
);

const NotPostedHiringStatusIcon = () => (
  <FOHHide
    style={{ tintColor: FOHColors.YELLOW_300, height: 20, width: 20 }}
    active
  />
);

const getHiringStatusIcon = (value: any) =>
  defaultTo(
    PostedHiringStatusIcon,
    prop(value, {
      [FOHPositionHiringStatusType.POSTED]: PostedHiringStatusIcon,
      [FOHPositionHiringStatusType.NOT_POSTED]: NotPostedHiringStatusIcon
    })
  );

export const FOHPositionHiringStatusDropdown = (
  props: FOHPositionHiringStatusDropdownProps
) => {
  const [ref] = useClickOutside({
    close: () =>
      !!props.openStatus && props.setOpenStatus && props.setOpenStatus(false)
  });

  const isDropdownDisabled = props.userIsInterviewer && !props.subscribed;

  return (
    <>
      <View ref={ref}>
        <TouchableOpacity
          onPress={() => props.setOpenStatus(!props.openStatus)}
          testID="positionHiringStatusDropdown"
          style={{
            flexDirection: 'row',
            alignItems: 'center'
          }}
          disabled={isDropdownDisabled}
        >
          {getHiringStatusIcon(props.statusType)()}
          <FOHSpaceSmall />
          <FOHLabelBold
            style={{
              fontSize: 14,
              lineHeight: 20,
              color: isDropdownDisabled
                ? FOHColors.GRAYSCALE_400
                : FOHColors.GRAYSCALE_700
            }}
          >
            {` ${props.statusText}`}
          </FOHLabelBold>
          {!isDropdownDisabled && <FOHDropDownArrow />}
        </TouchableOpacity>
        {props.openStatus ? (
          <FullWidthCard
            shadow={true}
            style={{
              position: 'absolute',
              top: 32,
              borderRadius: 4,
              padding: 12,
              paddingTop: 0,
              paddingBottom: 16,
              minWidth: 260,
              backgroundColor: FOHColors.WHITE
            }}
          >
            {props.statusOptions.map((status, index) => (
              <TouchableOpacity
                style={{
                  flexDirection: 'row'
                }}
                key={index}
                onPress={() => props.selectStatus(status)}
                testID={`positionHiringStatusDropdown-${status.value}`}
              >
                <View style={{ paddingTop: 16, paddingRight: 10 }}>
                  {getHiringStatusIcon(status.value)()}
                </View>
                <View style={{ paddingTop: 18, flex: 1 }}>
                  <StatusLabel>{status.label}</StatusLabel>
                  <StatusDescription>{status.description}</StatusDescription>
                </View>
              </TouchableOpacity>
            ))}
          </FullWidthCard>
        ) : (
          <></>
        )}
      </View>
    </>
  );
};
