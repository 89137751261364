import React from 'react';

import { StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native';
import { animated, useSpring } from 'react-spring';

import {
  FOHFileIcon,
  FOHLabel,
  FOHSmallDetailLabel,
  FOHSpace,
  FOHXIcon,
  FOHDownloadFileIcon
} from '../../ingredients';

export interface FOHFileCellProps {
  children?: React.ReactNode;
  fileName: string;
  delete?: () => void;
  progress: number; // out of 100
  size: string; // kb
  attachment?: string;
  download?: boolean;
  onFileNamePress?: () => void;
  style?: StyleProp<ViewStyle>;
}

export const FOHFileCell = (props: FOHFileCellProps) => {
  const spring = useSpring({
    to: async (next: any) => {
      await next({ width: 100 });
    },
    from: { width: 1 },
    config: { duration: 200 }
  });

  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: 6,
        flexWrap: 'wrap',
        marginTop: 24,
        ...(props.style as ViewStyle)
      }}
    >
      <View style={{ flexDirection: 'row', flex: 1, flexWrap: 'wrap' }}>
        {props.download ? <FOHDownloadFileIcon /> : <FOHFileIcon />}
        <FOHSpace />
        <View style={{ flexWrap: 'wrap', flex: 1 }}>
          {props.children ? (
            props.children
          ) : (
            <TouchableOpacity
              style={{
                flex: 1,
                flexWrap: 'wrap',
                maxWidth: 250,
                maxHeight: 200
              }}
              onPress={props.onFileNamePress}
              disabled={!props.download}
            >
              <FOHLabel
                style={{
                  textDecorationLine: props.download ? 'underline' : undefined
                }}
              >
                {props.fileName}
              </FOHLabel>
            </TouchableOpacity>
          )}
          <View style={{ flexDirection: 'row' }}>
            {props.progress >= 100 || props.download ? (
              <FOHSmallDetailLabel>{props.size}</FOHSmallDetailLabel>
            ) : (
              <View
                style={{
                  marginTop: 5,
                  flexDirection: 'row',
                  width: 100,
                  height: 6,
                  justifyContent: 'flex-start',
                  borderRadius: 6,
                  backgroundColor: '#eeeeee'
                }}
              >
                <ProgressBar
                  style={{
                    height: 5,
                    ...spring,
                    backgroundColor: '#38A56B',
                    borderRadius: 6
                  }}
                />
              </View>
            )}
          </View>
        </View>
      </View>
      {props.delete && (
        <TouchableOpacity
          onPress={props.delete}
          style={{
            width: 32,
            backgroundColor: '#F5F5F5',
            borderRadius: 16,
            justifyContent: 'center',
            alignItems: 'center',
            height: 32
          }}
          testID={'FOHFileCell-delete-button'}
        >
          <FOHXIcon />
        </TouchableOpacity>
      )}
    </View>
  );
};

const ProgressBar = animated(View);
