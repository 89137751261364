import React from 'react';

import styled from 'styled-components/native';
import { length, prop } from 'ramda';

import {
  FOHLabelSmall,
  FOHLabelBold,
  FOHColors,
  FOHFonts,
  FOHSpace,
  FOHView,
  FOHVerticalDivider,
  FOHHighlightText,
  FOHTextButton
} from '../../ingredients';
import { FOHNotificationType } from '../../typings';
import { getColorByBannerType } from '../../utils';

const Container = styled(FOHView)`
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

const Wrapper = styled(FOHView)<{ type?: string }>`
	align-items: center;
	justify-content: space-between;
	flex-direction: row;
	width: 100%;
	background-color: ${(p: any) =>
    p.type ? getColorByBannerType(p.type)?.light : FOHColors.BACKGROUND_GRAY}
	border-radius: 6px;
	padding: 12px;
  display: flex;
  flex-wrap: wrap;
`;

const LeftBlock = styled(FOHView)<{ length?: boolean }>`
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex: 1;
`;

const NotificationText = styled(FOHLabelSmall)`
  line-height: 24px;
  font-style: normal;
  font-weight: normal;
  color: ${FOHColors.NARO_GREY};
`;

const NotificationTextBold = styled(FOHLabelBold)`
  font-size: 14px;
  line-height: 24px;
  color: ${FOHColors.NARO_GREY};
  width: 100%;
`;

const RightBlock = styled(FOHView)<{ mobile?: boolean }>`
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  min-width: 200px
  max-width: 327px;
`;

interface FOHNotificationActionProps {
  title: string;
  onPress: () => void;
}

interface FOHNotificationBannerProps {
  notificationIcon?: any;
  notificationIconColor?: string;
  type?: FOHNotificationType;
  emphasizedWord: string;
  message: string;
  actions?: Array<FOHNotificationActionProps>;
  isMobile?: boolean;
}

export const FOHNotificationBanner = (props: FOHNotificationBannerProps) => {
  const NotificationIcon = props.notificationIcon;

  return (
    <Container testID={`FOHNotificationBanner-${props.type}`}>
      <Wrapper type={props.type}>
        <LeftBlock length={props.actions && length(props.actions) > 0}>
          {prop('notificationIcon', props) ? (
            <NotificationIcon
              style={{
                tintColor: prop('notificationIconColor', props),
                width: 18,
                height: 20
              }}
            />
          ) : (
            <></>
          )}
          <FOHSpace />
          <FOHHighlightText
            labelCmp={NotificationText}
            emphasizedWord={props.emphasizedWord}
            highlightColor={FOHColors.NARO_GREY}
            boldLabelCmp={NotificationTextBold}
          >
            {props.message}
          </FOHHighlightText>
        </LeftBlock>
        {props.actions && length(props.actions) > 0 ? (
          <RightBlock mobile={props.isMobile}>
            {props.actions.map((action, keyIndex) => (
              <React.Fragment key={`${keyIndex}${action.title}`}>
                <FOHTextButton
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontFamily: FOHFonts.REGULAR,
                    fontSize: 14,
                    lineHeight: 24,
                    paddingHorizontal: 8,
                    textDecorationLine: 'underline'
                  }}
                  title={action.title}
                  onPress={action.onPress}
                />
                {props.actions && keyIndex !== length(props.actions) - 1 ? (
                  <FOHVerticalDivider
                    height={16}
                    color={
                      (props.type && getColorByBannerType(props.type)?.dark) ||
                      FOHColors.BACKGROUND_DARK_GRAY
                    }
                  />
                ) : (
                  <></>
                )}
              </React.Fragment>
            ))}
          </RightBlock>
        ) : (
          <></>
        )}
      </Wrapper>
    </Container>
  );
};
