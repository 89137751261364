import React from 'react';

import blankimage from '../../../images/blankimage.svg';

import { FOHImage } from '../../ingredients';

export const FOHBlankImage = (props: any) => (
  <FOHImage
    source={{ uri: blankimage }}
    style={{ width: 32, height: 32 }}
    {...props}
  />
);
