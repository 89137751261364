import React from 'react';

import { useIsMobile } from '../../../utils';

import {
  FOHLabel,
  FOHLabelBold,
  FOHLabelSmall,
  FOHView,
  FOHColors,
  FOHHighlightText
} from '../../ingredients';
import { FOHLabeledCheckbox } from '../../recipes';

export interface FOHUserNotificationPreferenceProps {
  title: string;
  description: string;
  checkboxTextLabel: string;
  checkboxEmailLabel: string;
  textSelected: boolean;
  emailSelected: boolean;
  setTextSelected: (bool: boolean) => void;
  setEmailSelected: (bool: boolean) => void;
  highlightedText?: string;
  isDisabled?: boolean;
  isNotText?: boolean;
  isNotEmail?: boolean;
  testID?: string;
}

export const FOHUserNotificationPreference = (
  props: FOHUserNotificationPreferenceProps
) => {
  const { isMobile } = useIsMobile();

  return (
    <FOHView
      style={{
        width: '100%',
        backgroundColor: FOHColors.WHITE,
        borderRadius: 4,
        borderWidth: 1,
        borderColor: FOHColors.GRAYSCALE_300,
        paddingVertical: 12,
        paddingHorizontal: 10
      }}
      testID={`FOHUserNotifictionPreference-${props.title}`}
    >
      <FOHView
        style={{
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: 'center',
          flex: 1,
          flexWrap: 'wrap'
        }}
        testID={`FOHUserNotificationPreference-${props.title}`}
      >
        {props.highlightedText ? (
          <FOHView
            style={{
              flexDirection: 'column',
              width: isMobile ? '100%' : '69%'
            }}
          >
            <FOHView style={{ marginBottom: 8 }}>
              <FOHHighlightText
                labelCmp={FOHLabelBold}
                emphasizedWord={props.highlightedText}
                highlightColor={FOHColors.BLUE}
                boldLabelCmp={FOHLabelBold}
                style={{
                  fontSize: 14,
                  lineHeight: 24,
                  color: props.isDisabled
                    ? FOHColors.GREY
                    : FOHColors.CYBERSPACE_GREY
                }}
                emphasizedStyle={{ fontSize: 14, lineHeight: 24 }}
                highlightWrapper='"'
                caseInsensitive
              >
                {props.title}
              </FOHHighlightText>
            </FOHView>
            <FOHView>
              <FOHHighlightText
                labelCmp={FOHLabelSmall}
                emphasizedWord={props.highlightedText}
                highlightColor={FOHColors.BLUE}
                boldLabelCmp={FOHLabelBold}
                style={{
                  fontSize: 14,
                  lineHeight: 24,
                  color: props.isDisabled
                    ? FOHColors.GREY
                    : FOHColors.CYBERSPACE_GREY
                }}
                emphasizedStyle={{ fontSize: 14, lineHeight: 24 }}
                highlightWrapper='"'
                caseInsensitive
              >
                {props.description}
              </FOHHighlightText>
            </FOHView>
          </FOHView>
        ) : (
          <FOHView style={{ width: isMobile ? '100%' : '69%' }}>
            <FOHLabelBold
              style={{
                fontSize: 14,
                fontWeight: '700',
                lineHeight: 24,
                color: props.isDisabled
                  ? FOHColors.GREY
                  : FOHColors.CYBERSPACE_GREY
              }}
            >
              {props.title}
            </FOHLabelBold>
            <FOHLabel
              style={{
                fontSize: 14,
                fontWeight: '400',
                lineHeight: 24,
                color: props.isDisabled
                  ? FOHColors.GREY
                  : FOHColors.CYBERSPACE_GREY
              }}
            >
              {props.description}
            </FOHLabel>
          </FOHView>
        )}
        <FOHView
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: isMobile ? 'flex-end' : 'space-around',
            marginLeft: 'auto',
            width: isMobile ? '100%' : '31%'
          }}
        >
          <FOHView
            style={{
              marginRight: isMobile ? 'unset' : 'auto',
              opacity: props.isNotText ? 0 : 1,
              width: isMobile ? '32%' : '50%',
              alignItems: 'flex-end'
            }}
          >
            <FOHLabeledCheckbox
              selected={
                props.isDisabled || props.isNotText
                  ? false
                  : props.textSelected || false
              }
              checkboxLabel={props.checkboxTextLabel}
              setSelected={() =>
                !(props.isDisabled || props.isNotText) &&
                props.setTextSelected(!props.textSelected)
              }
              style={{
                fontSize: 14,
                color: props.isDisabled
                  ? FOHColors.GREY
                  : FOHColors.CYBERSPACE_GREY,
                opacity: props.isNotText ? 0.5 : 1
              }}
              disabled={props.isDisabled || props.isNotText}
              reverse
            />
          </FOHView>
          <FOHView
            style={{
              opacity: props.isNotEmail ? 0 : 1,
              width: isMobile ? '32%' : '50%',
              alignItems: 'flex-end'
            }}
          >
            <FOHLabeledCheckbox
              selected={
                props.isDisabled || props.isNotEmail
                  ? false
                  : props.emailSelected || false
              }
              checkboxLabel={props.checkboxEmailLabel}
              setSelected={() =>
                !(props.isDisabled || props.isNotEmail) &&
                props.setEmailSelected(!props.emailSelected)
              }
              style={{
                fontSize: 14,
                color: props.isDisabled
                  ? FOHColors.GREY
                  : FOHColors.CYBERSPACE_GREY,
                opacity: props.isNotEmail ? 0.5 : 1
              }}
              disabled={props.isDisabled || props.isNotEmail}
              reverse
            />
          </FOHView>
        </FOHView>
      </FOHView>
    </FOHView>
  );
};
