import React from 'react';

import styled from 'styled-components/native';
import {
  View,
  TouchableOpacity,
  Text,
  StyleProp,
  ViewStyle
} from 'react-native';

import { FOHSpaceSmall, FOHBlackAdd, FOHFonts } from '../../ingredients';

export interface FOHAddLinkProps {
  label: string;
  onPress: () => void;
  style?: StyleProp<ViewStyle>;
  minWidth?: string;
}

export const FOHAddLink = (props: FOHAddLinkProps) => (
  <Border
    style={{
      ...((props.style || {}) as ViewStyle),
      minWidth: props.minWidth || 90
    }}
  >
    <TouchableOpacity
      style={{
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%'
      }}
      testID={'select-cell'}
      onPress={props.onPress}
    >
      <FOHBlackAdd />
      <FOHSpaceSmall />
      <LinkText>{props.label}</LinkText>
    </TouchableOpacity>
  </Border>
);

const Border = styled(View)`
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  border: 1px dashed #dbdbdb;
  border-radius: 4px;
  justify-content: center;
  min-width: 0px;
  width: 100%;
  height: 50px;
  margin: 4px;
  padding-left: 20px;
  padding-right: 10px;
  background-color: #ffffff;
`;

const LinkText = styled(Text)`
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
  color: #171717;
  text-decoration: underline;
`;
