import React from 'react';

import settings from '../../../images/settings_icon.svg';

import { FOHImage } from '../../ingredients';

export const FOHSettingsIcon = (props: any) => (
  <FOHImage
    source={{ uri: settings }}
    style={{ width: 20, height: 22, ...props?.style }}
  />
);
