import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { defaultTo } from 'ramda';

import {
  FOHButton,
  FOHView,
  FOHSpaceLarger,
  FOHHeaderH2,
  FOHSmallDetailLabel,
  FOHFonts,
  FOHLabeledCheckbox,
  FOHDismissedWarningKeys
} from '../../../components';
import {
  removeDismissedWarningByKey,
  storeDismissedWarnings
} from '../../../utils';

import { withReactiveModal, WithReactiveModalProps } from '../../Navigation';

interface NotHiringConfirmationWarningModalProps
  extends WithReactiveModalProps {
  confirmIUnderstand: () => void;
}

const NotHiringConfirmationWarningModal: React.FC<
  NotHiringConfirmationWarningModalProps
> = ({ confirmIUnderstand }) => {
  const [doNotShowWarningIsChecked, setDoNotShowWarningIsChecked] =
    useState(false);

  const { t } = useTranslation('MyPositionsFeature');

  return (
    <FOHView
      style={{ alignItems: 'center', padding: 24 }}
      testID="NotHiringConfirmationWarningModal"
    >
      <FOHHeaderH2>Position billing not paused</FOHHeaderH2>
      <FOHSpaceLarger />
      <FOHView style={{ paddingHorizontal: 110 }}>
        <FOHSmallDetailLabel
          style={{
            textAlign: 'justify',
            fontFamily: FOHFonts.MEDIUM
          }}
        >
          {t('notPostedDescription')}
        </FOHSmallDetailLabel>
        <FOHSpaceLarger />
        <FOHSmallDetailLabel style={{ textAlign: 'justify', fontSize: 12 }}>
          {t('cancelSubscriptionDescription')}
        </FOHSmallDetailLabel>
      </FOHView>
      <FOHSpaceLarger />
      <FOHButton
        onPress={confirmIUnderstand}
        style={{ maxWidth: 176, width: '100%' }}
        title={t('iUnderstand')}
      />
      <FOHSpaceLarger />
      <FOHLabeledCheckbox
        checkboxLabel={t('doNotShowAgain')}
        selected={defaultTo(false, doNotShowWarningIsChecked)}
        setSelected={checked => {
          setDoNotShowWarningIsChecked(!doNotShowWarningIsChecked);
          if (checked) {
            storeDismissedWarnings(
              FOHDismissedWarningKeys.DO_NOT_SHOW_ME_AGAIN
            );
          } else {
            removeDismissedWarningByKey(
              FOHDismissedWarningKeys.DO_NOT_SHOW_ME_AGAIN
            );
          }
        }}
      />
    </FOHView>
  );
};

export default withReactiveModal(NotHiringConfirmationWarningModal);
