import { z } from 'zod';

export enum Fields {
  addressCity = 'addressCity',
  addressPostalCode = 'addressPostalCode',
  addressState = 'addressState',
  addressStreet1 = 'addressStreet1',
  addressStreet2 = 'addressStreet2',
  slug = 'slug',
  title = 'title'
}

export const schema = z.object({
  [Fields.addressCity]: z
    .string()
    .max(250, { message: 'Maximum length is 250.' }),
  [Fields.addressPostalCode]: z.string(),
  [Fields.addressState]: z.string(),
  [Fields.addressStreet1]: z
    .string()
    .max(250, { message: 'Maximum length is 250.' }),
  [Fields.addressStreet2]: z
    .string()
    .max(250, { message: 'Maximum length is 250.' }),
  [Fields.slug]: z
    .string()
    .min(1, { message: 'Minimum length is 3.' })
    .max(250, { message: 'Maximum length is 250.' }),
  [Fields.title]: z
    .string()
    .min(1, { message: 'Minimum length is 3.' })
    .max(250, { message: 'Maximum length is 250.' })
});

export type CareerPageLocationFormValues = z.infer<typeof schema>;
