import React from 'react';

import logo from '../../../images/logo_FB.svg';

import { FOHImage } from '../../ingredients';

export const FOHLogo = (props: any) => (
  <FOHImage
    source={{ uri: logo }}
    style={
      props && !!props.style
        ? { width: 20, height: 30, ...props.style }
        : { width: 20, height: 30 }
    }
  />
);
