import React from 'react';

import { ScrollView } from 'react-native';

import {
  FOHReactiveModal,
  FOHDivider,
  FOHHeaderH2,
  FOHCircleLoader,
  FOHSpace
} from '../../ingredients';
import { FOHChatCell } from '../../recipes';

import { SBMember, SBLastMessage } from './FOHChannelPreview';

export interface FOHChatModalProps {
  name?: string;
  headerFontSize?: number;
  open: boolean;
  close: () => void;
  dialog?: boolean;
  large?: boolean;
  users: Array<ChatUser>;
  onChannelPress: (user: ChatUser) => void;
  mobile?: boolean;
  messageLabel?: string;
  onPressMessage: (user: ChatUser) => void;
  onScroll?: () => void;
  loading?: boolean;
  leftContentShift?: number;
}

export interface ChatUser {
  members: Array<SBMember>;
  lastMessage: SBLastMessage;
  unreadMessageCount?: number;
  formattedLastReadMessageTime: string;
  restaurantName?: string;
  restaurantImage?: string;
  isActive: boolean;
  channelUrl: string;
  sendbirdUserId: string;
  stageLabel?: string;
  stage?: string;
}

export const FOHChatModal = (props: FOHChatModalProps) => {
  return (
    <FOHReactiveModal
      leftContentShift={props.leftContentShift}
      dialog={props.dialog}
      large={props.large}
      open={props.open}
      close={props.close}
      style={{ maxWidth: 825, width: '100%' }}
    >
      <FOHHeaderH2
        style={{
          width: '100%',
          fontSize: props.headerFontSize || 24,
          textAlign: 'left',
          justifyContent: 'left',
          padding: 22
        }}
      >
        {props.name}
      </FOHHeaderH2>
      <FOHDivider />
      <ScrollView
        style={{ height: 300, width: '100%' }}
        onScroll={props.onScroll}
      >
        {props.users.map((user, index) => (
          <FOHChatCell
            key={`chat-${index}`}
            mobile={props.mobile}
            members={user.members}
            lastMessage={user.lastMessage}
            unreadMessageCount={user.unreadMessageCount}
            formattedLastReadMessageTime={user.formattedLastReadMessageTime}
            restaurantName={user.restaurantName}
            restaurantImage={user.restaurantImage}
            isActive={user.isActive}
            channelUrl={user.channelUrl}
            sendbirdUserId={user.sendbirdUserId}
            stageLabel={user.stageLabel}
            stage={user.stage}
            messageLabel={props.messageLabel}
            onPressMessage={() => props.onPressMessage(user)}
            onChannelPress={() => props.onChannelPress(user)}
          />
        ))}
        {props.loading && (
          <>
            <FOHSpace />
            <FOHCircleLoader />
          </>
        )}
      </ScrollView>
    </FOHReactiveModal>
  );
};
