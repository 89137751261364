import React, { useState } from 'react';

import { withTranslation } from 'react-i18next';
import { length, compose, path } from 'ramda';
import { useLocalStorage } from 'use-hooks';

import { withIsMobile, handle } from '../../utils';
import { withUpdateUser, withUser } from '../../api/hocs';
import { requestEndorsement } from '../../api/endorsements';
import { CANDIDATE_VIDEO_PATH } from '../../constants';
import { useNavigation } from '../../utils/navigation';
import { FOHEmailListScreen } from '../../components';

import { useGetMeData } from '../SignIn';

const RequestEndorsementModal = props => {
  const { t } = props;

  const { navigateTo } = useNavigation();

  const meQuery = useGetMeData();

  const [text, setText] = useState('');
  const [emails, setEmails] = useState(text.split(/(\s*,?\s*)+/));
  const [error, setError] = useState();
  // Map Support dialog
  const [canPass, setCanPass] = useLocalStorage(
    'can-pass-reccomendations',
    false
  );

  return (
    <FOHEmailListScreen
      title={t('requestScreenEndorsements')}
      detailText={t('requestScreenEndorsementsDetail')}
      submitLabel={t('requestScreenEndorsementsSubmit')}
      largeHeader={!props.isMobile}
      cancel={() => navigateTo(CANDIDATE_VIDEO_PATH)}
      error={path(['email'], error)}
      handleSubmit={async () => {
        setError();

        if (canPass && length(emails) === 0) {
          return props.onSubmit && props.onSubmit();
        }

        if (length(emails) === 0 && text === '' && !canPass) {
          return setError({
            email: [t('emailRequired')]
          });
        }

        const emailsToRequest = length(emails) > 0 ? [...emails, text] : [text];
        const filteredEmails = emailsToRequest.filter(
          email => email.includes('@') && email !== ''
        );

        if (length(filteredEmails) < 2 && !canPass) {
          return setError({
            email: [t('emailRequired')]
          });
        }

        const requests = filteredEmails.map(async emailToRequest => {
          return requestEndorsement({
            email: emailToRequest
          });
        });

        const [, err] = await handle(Promise.all(requests));
        if (err && err.fieldErrors) {
          setError(err.fieldErrors);
          return;
        }

        setCanPass(true);

        meQuery && meQuery.refetch();

        props.onSubmit && props.onSubmit();
      }}
      emails={emails}
      setEmails={setEmails}
      input={text}
      setInput={value => setText(value.replace(',', ' '))}
    />
  );
};

export default compose(
  withTranslation('EndorseFeature'),
  withUpdateUser,
  withUser,
  withIsMobile
)(RequestEndorsementModal);
