import React from 'react';

import {
  FOHHeaderText,
  FOHDetailText,
  FOHButton,
  FOHErrorLabel,
  Screen,
  FOHTextField,
  Row,
  FOHView
} from '../../ingredients';
import { FOHGoBack } from '../../recipes';

export interface FOHPasswordResetScreenProps {
  handleSubmit: () => void;
  email: string;
  emailPlaceholder: string;
  setEmail: (value: string) => void;
  submitLabel: string;
  title: string;
  detailText: string;
  error?: any;
  disabledSubmit?: boolean;
  goBack?: () => void;
  largeHeader?: boolean;
}

export const FOHPasswordResetScreen = (props: FOHPasswordResetScreenProps) => (
  <Screen>
    {props.goBack && <FOHGoBack goBack={props.goBack} />}

    <FOHView
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 60
      }}
    >
      <FOHView
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: 300
        }}
      >
        <FOHHeaderText
          style={{ fontSize: props.largeHeader ? 30 : 20, width: '100%' }}
        >
          {props.title}
        </FOHHeaderText>
      </FOHView>
      <FOHView style={{ paddingTop: 8 }} />
      <Row>
        <FOHDetailText>{props.detailText}</FOHDetailText>
      </Row>
      <FOHView style={{ paddingTop: 18 }} />
      <Row>
        <FOHTextField
          autoCompleteType={'email'}
          placeholder={props.emailPlaceholder}
          value={props.email}
          onChangeText={(value: any) => props.setEmail(value)}
          error={props.error && props.error.email ? props.error.email : null}
        />
      </Row>
      <FOHErrorLabel style={{ paddingBottom: 8 }}>
        {props.error ? props.error.detail : null}
      </FOHErrorLabel>
      <FOHButton
        onPress={props.handleSubmit}
        title={props.submitLabel}
        disabled={props.disabledSubmit}
      />
    </FOHView>
  </Screen>
);
