import React, { useEffect } from 'react';

import { prop } from 'ramda';
import { useParams } from 'react-router-dom';

import { BILLING, EMPLOYER_SETTINGS_MENU } from '../../constants';
import { FOHPlanState } from '../../components';
import { useNavigation } from '../../utils/navigation';

import { useCurrentLocationContext } from '../Locations/useCurrentLocationContext';

import { UpgradePlanModal } from './UpgradePlan';
import { ChangePositionPlanModal } from './ChangePositionPlanModal';
import { useCustomerPortal } from './useCustomerPortal';
import { usePaymentPlan } from './usePaymentPlan';
import { SelectPositionsModal } from './SelectPositionsModal';
import { CancelReasonModal } from './CancelReasonModal';

export const PlanModals = props => {
  // positionId and position are both ids, some routes are using position some positionId
  const { modal, position, positionId } = useParams();

  const { navigateTo, location: navLocation } = useNavigation();
  const { redirectToCustomerPortal } = useCustomerPortal();

  const { locationFilter } = useCurrentLocationContext({
    skipLocationsQuery: true,
    props
  });

  const locationId = props.locationId || locationFilter;

  const { subscribed, planName, subscribedLocation } = usePaymentPlan({
    locationId,
    currentPlanQ: true
  });

  useEffect(() => {
    if (
      (planName === FOHPlanState.unlimited ||
        planName === FOHPlanState.enterprise) &&
      !subscribed &&
      modal === 'upgrade'
    ) {
      redirectToCustomerPortal({
        location: prop('rowId', subscribedLocation)
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planName, subscribed, subscribedLocation, modal]);

  const BILLING_MENU_URL = `${EMPLOYER_SETTINGS_MENU}${BILLING}`;

  return (
    <>
      <UpgradePlanModal
        {...props}
        open={
          modal === 'upgrade' &&
          (!position || position === 'all') &&
          (!positionId || positionId === 'all') &&
          !(
            (planName === FOHPlanState.unlimited ||
              planName === FOHPlanState.enterprise) &&
            !subscribed
          )
        }
        close={() => {
          props.onClose && props.onClose();
          navigateTo(
            {
              pathname: props.backPath || BILLING_MENU_URL,
              search: props.backSearch
            },
            {
              state: navLocation.state
            }
          );
        }}
      />
      <SelectPositionsModal
        {...props}
        open={modal === 'payasyougo'}
        close={() => {
          props.onClose && props.onClose();
          navigateTo(
            {
              pathname: props.backPath || BILLING_MENU_URL,
              search: props.backSearch
            },
            {
              state: navLocation.state
            }
          );
        }}
      />

      <ChangePositionPlanModal
        {...props}
        open={
          modal === 'upgrade' &&
          (position || positionId) &&
          position !== 'all' &&
          positionId !== 'all'
        }
        position={position || positionId}
        close={() => {
          props.onClose && props.onClose();
          navigateTo({
            pathname: props.backPath || BILLING_MENU_URL,
            search: navLocation.search
          });
        }}
      />
      <CancelReasonModal
        {...props}
        positionId={position || positionId}
        locationId={locationId}
        open={modal === 'reason'}
      />
      <ChangePositionPlanModal
        {...props}
        pause={true}
        open={
          modal === 'pause' &&
          (position || positionId) &&
          position !== 'all' &&
          positionId !== 'all'
        }
        position={position || positionId}
        backPath={props.backPath}
        close={() => {
          props.onClose && props.onClose();
          navigateTo({
            pathname: props.backPath || BILLING_MENU_URL,
            search: navLocation.search
          });
        }}
      />
    </>
  );
};
