import React from 'react';

import notificationRejection from '../../../images/notification-rejection.svg';

import { FOHImage } from '../../ingredients';

export interface FOHNotificationRejectionProps {}

export const FOHNotificationRejection = (
  props: FOHNotificationRejectionProps
) => (
  <FOHImage
    {...props}
    source={{ uri: notificationRejection }}
    style={{ width: 24, height: 24 }}
  />
);
