import React, { useMemo, useState } from 'react';

import { defaultTo, length, path, prop } from 'ramda';
import { DateTime } from 'luxon';

import { toDate, useIsMobile } from '../../../utils';

import {
  FOHLabel,
  FOHLabelBold,
  FOHNotificationIcon,
  FOHSmallDetailLabel,
  FOHSpaceSmall,
  FullWidthCard,
  FOHColors,
  FOHView,
  FOHTouchableOpacity
} from '../../ingredients';
import { FOHPositionBillingStatusType } from '../../typings';

import {
  FOHPositionBillingStatusDropdownProps,
  FOHPositionBillingStatusDropdown
} from '../select/FOHPositionBillingStatusDropdown';

export interface FOHPositionPlanCellProps
  extends FOHPositionBillingStatusDropdownProps {
  positions: Array<LightPositionType>;
  onPressPositionName: (slug: string) => void;
  priceLabel: string;
  price: string;
  stripeSubscription: string;
  billingLabel: string;
  billingDate: string;
  statusLabel: string;
  paygPrice?: string;
}

interface LightPositionType {
  slug: string;
  positionName: string;
  positionTypes: string;
}

const FOHPositionPaymentInfoRow = (props: any) => {
  const { position, keyIndex } = props;
  const { isMobile } = useIsMobile();

  const [openStatusDropdown, setOpenStatusDropdown] = useState(false);

  const billingDate = useMemo(() => {
    return path(['payment', 'subscriptionWillEndOn'], position)
      ? toDate(path(['payment', 'subscriptionWillEndOn'], position), 'M/dd/yy')
      : path(['payment', 'subscription', 'currentPeriodEnd'], position)
      ? toDate(
          DateTime.fromSeconds(
            defaultTo(
              0,
              path(['payment', 'subscription', 'currentPeriodEnd'], position)
            )
          ),
          'M/dd/yy'
        )
      : '-';
  }, [position]);

  return (
    <FOHView style={{ flexDirection: 'row' }}>
      <FOHView
        style={{
          flex: isMobile ? 1 : 3,
          minWidth: isMobile ? 128 : 236,
          paddingRight: isMobile ? 0 : 12,
          paddingBottom: 10
        }}
      >
        <FOHView
          key={position.slug}
          style={{
            paddingBottom: keyIndex !== length(props.positions) - 1 ? 24 : 0,
            flexWrap: 'wrap'
          }}
        >
          <FOHView
            style={{
              flexDirection: 'row',
              alignItems: 'flex-start'
            }}
          >
            {props.statusType === FOHPositionBillingStatusType.SUBSCRIBED ? (
              <FOHNotificationIcon style={{ top: 5 }} />
            ) : (
              <FOHNotificationIcon
                style={{ top: 5, background: FOHColors.GOLD }}
              />
            )}
            <FOHSpaceSmall />
            <FOHView style={{ flex: 1, minWidth: isMobile ? 125 : 250 }}>
              <FOHTouchableOpacity
                onPress={() => props.onPressPositionName(position.slug)}
              >
                <FOHLabelBold
                  style={{
                    textAlign: 'left',
                    textDecorationLine: 'underline',
                    flex: 1,
                    lineHeight: 24,
                    marginBottom: 4
                  }}
                >
                  {position.positionName}
                </FOHLabelBold>
              </FOHTouchableOpacity>
              <FOHSmallDetailLabel style={{ flex: 1 }}>
                {position.positionTypes}
              </FOHSmallDetailLabel>
            </FOHView>
          </FOHView>
          {isMobile ? (
            <>
              <FOHView
                style={{
                  marginLeft: 16
                }}
              >
                <FOHSmallDetailLabel>
                  {`${props.billingLabel}:  `}
                  <FOHLabel
                    heavy
                    style={{
                      fontSize: 14,
                      lineHeight: 20,
                      color: FOHColors.GRAYSCALE_900
                    }}
                  >
                    {billingDate}
                  </FOHLabel>
                </FOHSmallDetailLabel>
              </FOHView>
              <FOHView
                style={{
                  marginLeft: 16
                }}
              >
                <FOHSmallDetailLabel>
                  {`${props.priceLabel}:  `}
                  <FOHLabel
                    heavy
                    style={{
                      fontSize: 14,
                      lineHeight: 20,
                      color: FOHColors.GRAYSCALE_900
                    }}
                  >
                    {props.paygPrice || '-'}
                  </FOHLabel>
                </FOHSmallDetailLabel>
              </FOHView>
            </>
          ) : null}
        </FOHView>
      </FOHView>
      {!isMobile ? (
        <>
          <FOHView style={{ flex: 1, minWidth: 100 }}>
            <FOHLabel>{billingDate}</FOHLabel>
          </FOHView>
          <FOHView style={{ flex: 1, minWidth: 65 }}>
            <FOHLabel>{props.paygPrice || '-'}</FOHLabel>
          </FOHView>
        </>
      ) : null}
      <FOHView
        style={{ flex: isMobile ? 'unset' : 1, minWidth: isMobile ? 138 : 210 }}
      >
        <FOHView
          style={{
            flex: isMobile ? 'unset' : 1,
            paddingRight: isMobile ? 10 : 0
          }}
        >
          <FOHPositionBillingStatusDropdown
            {...props}
            statusText={prop('statusText', position)}
            statusType={prop('statusType', position)}
            openStatus={openStatusDropdown}
            setOpenStatus={setOpenStatusDropdown}
            positionId={prop('id', position)}
          />
        </FOHView>
      </FOHView>
    </FOHView>
  );
};

export const FOHPositionPlanCell = (props: FOHPositionPlanCellProps) => {
  const { isMobile } = useIsMobile();
  const isMultiplePositions = useMemo(
    () => (props.positions && length(props.positions) > 1) || false,
    [props.positions]
  );

  return (
    <FullWidthCard style={{ padding: isMobile ? 12 : 24, flexWrap: 'wrap' }}>
      {isMultiplePositions ? (
        <FOHView
          style={{ flexDirection: 'row', marginBottom: isMobile ? 10 : 0 }}
        >
          <FOHView
            style={{ flex: isMobile ? 1 : 3, minWidth: isMobile ? 128 : 236 }}
          ></FOHView>
          {!isMobile ? (
            <>
              <FOHView style={{ flex: 1, minWidth: 100 }}>
                <FOHSmallDetailLabel>{props.billingLabel}</FOHSmallDetailLabel>
              </FOHView>
              <FOHView style={{ flex: 1, minWidth: 65 }}>
                <FOHSmallDetailLabel>{props.priceLabel}</FOHSmallDetailLabel>
              </FOHView>
            </>
          ) : null}
          <FOHView
            style={{
              flex: isMobile ? 'unset' : 1,
              minWidth: isMobile ? 138 : 210
            }}
          >
            <FOHSmallDetailLabel
              style={{
                alignItems: 'flex-start',
                textAlign: isMobile ? 'center' : 'left',
                width: '100%'
              }}
            >
              {props.statusLabel}
            </FOHSmallDetailLabel>
          </FOHView>
        </FOHView>
      ) : (
        <></>
      )}
      {isMultiplePositions ? (
        <>
          {props.positions.map((position, index) => (
            <FOHView
              key={index}
              style={{ zIndex: length(props.positions) - index }}
              testID={`FOHPositionPlanCell-${position.positionName}`}
            >
              <FOHPositionPaymentInfoRow
                {...props}
                keyIndex={index}
                position={position}
              />
            </FOHView>
          ))}
        </>
      ) : (
        <FOHView style={{ flexDirection: 'row' }}>
          <FOHView
            style={{
              flex: isMobile ? 1 : 3,
              minWidth: isMobile ? 128 : 236,
              paddingRight: isMobile ? 0 : 12,
              paddingBottom: 10
            }}
          >
            {props.positions.map((position, i) => (
              <FOHView
                key={position.slug}
                style={{
                  paddingBottom: i !== length(props.positions) - 1 ? 24 : 0,
                  flexWrap: 'wrap'
                }}
                testID={`FOHPositionPlanCell-${position.positionName}`}
              >
                <FOHView
                  style={{
                    flexDirection: 'row',
                    alignItems: 'flex-start'
                  }}
                >
                  {props.statusType ===
                  FOHPositionBillingStatusType.SUBSCRIBED ? (
                    <FOHNotificationIcon style={{ top: 5 }} />
                  ) : (
                    <FOHNotificationIcon
                      style={{ top: 5, background: FOHColors.GOLD }}
                    />
                  )}
                  <FOHSpaceSmall />
                  <FOHView style={{ flex: 1, minWidth: isMobile ? 125 : 250 }}>
                    <FOHTouchableOpacity
                      onPress={() => props.onPressPositionName(position.slug)}
                    >
                      <FOHLabelBold
                        style={{
                          textAlign: 'left',
                          textDecorationLine: 'underline',
                          lineHeight: 24,
                          marginBottom: 4
                        }}
                      >
                        {position.positionName}
                      </FOHLabelBold>
                    </FOHTouchableOpacity>
                    <FOHSmallDetailLabel>
                      {position.positionTypes}
                    </FOHSmallDetailLabel>
                  </FOHView>
                </FOHView>
              </FOHView>
            ))}
          </FOHView>
          {!isMobile ? (
            <>
              <FOHView style={{ flex: 1, minWidth: 100 }}>
                <FOHSmallDetailLabel>{props.billingLabel}</FOHSmallDetailLabel>
                <FOHSpaceSmall />
                <FOHLabel>{props.billingDate}</FOHLabel>
              </FOHView>
              <FOHView style={{ flex: 1, minWidth: 65 }}>
                <FOHSmallDetailLabel>{props.priceLabel}</FOHSmallDetailLabel>
                <FOHSpaceSmall />
                <FOHLabel>{props.paygPrice || '-'}</FOHLabel>
              </FOHView>
            </>
          ) : null}
          <FOHView
            style={{
              flex: isMobile ? 'unset' : 1,
              minWidth: isMobile ? 138 : 210
            }}
            testID="positionBillingStatus"
          >
            <FOHView
              style={{
                flex: isMobile ? 'unset' : 1,
                paddingRight: isMobile ? 10 : 0
              }}
            >
              <FOHSmallDetailLabel
                style={{
                  alignItems: 'flex-start',
                  textAlign: 'left',
                  width: '100%'
                }}
              >
                {props.statusLabel}
              </FOHSmallDetailLabel>
              <FOHSpaceSmall />
              <FOHPositionBillingStatusDropdown
                {...props}
                positionId={path(['positions', '0', 'id'], props)}
              />
            </FOHView>
          </FOHView>
        </FOHView>
      )}
    </FullWidthCard>
  );
};
