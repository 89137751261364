import React, { useState, useContext } from 'react';

const ChatWindowContext = React.createContext([{}, () => {}]);

// Location context in the top nav
export const useChatWindow = () => {
  const [state, setState] = useContext(ChatWindowContext);
  const setChannelUrl = channelUrl => {
    setState({
      ...state,
      channelUrl
    });
  };
  const setTemplateMessage = templateMessage => {
    setState({
      ...state,
      templateMessage
    });
  };
  const setChannelUrlWithMessage = (channelUrl, templateMessage) => {
    setState({
      channelUrl,
      templateMessage
    });
  };

  return {
    setChannelUrl,
    setTemplateMessage,
    setChannelUrlWithMessage,
    ...state
  };
};

export const ChatWindowProvider = props => {
  const [state, setState] = useState({
    channelUrl: '',
    templateMessage: ''
  });
  return (
    <ChatWindowContext.Provider value={[state, setState]}>
      {props.children}
    </ChatWindowContext.Provider>
  );
};
