import { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { findById } from '../../utils';
import { FOHPositionBillingStatusType } from '../../components';

export const usePositionStatusState = initialStatus => {
  const { t } = useTranslation('PricingScreenFeature');
  const [openStatusDropdown, setOpenStatusDropdown] = useState(false);

  const billingStatusOptions = [
    {
      label: t('subscribedLabel'),
      description: t('subscribedDetail'),
      value: FOHPositionBillingStatusType.SUBSCRIBED,
      selected: true
    },
    {
      label: t('unsubscribedLabel'),
      description: t('unsubscribedDetail'),
      value: FOHPositionBillingStatusType.PAUSED,
      selected: false
    }
  ];

  const [status, setStatus] = useState(billingStatusOptions[1]);

  useEffect(() => {
    initialStatus
      ? setStatus(findById(initialStatus, billingStatusOptions, 'value'))
      : setStatus(billingStatusOptions[1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialStatus]);

  return {
    status,
    setStatus,
    openStatusDropdown,
    setOpenStatusDropdown,
    billingStatusOptions
  };
};
