import React from 'react';

import { defaultTo, prop } from 'ramda';

import status from '../../../images/active_looking_status.svg';

import { FOHImage } from '../../ingredients';

export const FOHLookingStatusIcon = (props: any) => (
  <FOHImage
    source={{ uri: status }}
    style={{ width: 14, height: 14, ...defaultTo({}, prop('style', props)) }}
  />
);

export const FOHLargeLookingStatusIcon = (props: any) => (
  <FOHImage
    source={{ uri: status }}
    style={{ width: 20, height: 20, ...defaultTo({}, prop('style', props)) }}
  />
);
