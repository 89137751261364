import styled from 'styled-components/native';
import { Text } from 'react-native';

import { FOHColors } from '../colors/FOHColors';

import { FOHFonts } from './FOHFonts';

export const FOHSmallDetailLabel = styled(Text)<{ color?: string }>`
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  color: ${({ color }: { color?: string }) =>
    color ? color : FOHColors.GRAYSCALE_600};
`;
