import { POSITIONS_URL } from '../constants/urls';

import { post, get, destroy, patch } from './index';

export const createPosition = data => {
  return post(POSITIONS_URL, data);
};

export const getPositions = () => {
  return get(POSITIONS_URL);
};

export const getPosition = id => {
  return get(`${POSITIONS_URL}/${id}`);
};

export const updatePosition = data => {
  return patch(`${POSITIONS_URL}/${data.id}`, data);
};

export const createOrUpdatePosition = async ({ props, updatedPosition }) => {
  return updatedPosition.id
    ? await updatePosition(updatedPosition).then(res => {
        props.updatePosition(res);
        return res;
      })
    : await createPosition(updatedPosition).then(res => {
        props.addPosition(res);
        return res;
      });
};

export const deletePosition = id => {
  return destroy(`${POSITIONS_URL}/${id}`, {});
};
