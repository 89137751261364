import React from 'react';

import styled from 'styled-components/native';
import { length } from 'ramda';

import {
  FullWidthCard,
  FOHButton,
  FOHSectionLabel,
  FOHDivider,
  FOHView,
  FOHSmallDetailLabel,
  FOHColors,
  FOHFonts
} from '../../ingredients';

import { FOHEndorseFormProps, FOHEndorseForm } from '..';
import {
  FOHRecommendationCellProps,
  FOHRecommendationCell
} from '../cells/FOHRecommendationCell';
import { FOHNoRecommendationCell } from '../cells/FOHNoRecommendationCell';

export interface FOHEndorseTableProps extends FOHEndorseFormProps {
  endorseTableTitle: string;
  recommendations?: Array<FOHRecommendationCellProps>;
  endorsementFormTitle: string;
  endorseHeaderText: string;
  noEndorsementImage: string;
  isEndorsing?: boolean;
  recommend: () => void;
  recommendCTA: string;
  emptyWorkHistory?: string;
  canViewOnly?: boolean;
}

export const FOHEndorseTable = (props: FOHEndorseTableProps) => (
  <FOHView style={{ width: '100%' }}>
    {props.recommend && (
      <>
        <FOHSectionLabel style={{ paddingBottom: 6, paddingTop: 6 }}>
          {props.endorseTableTitle}
        </FOHSectionLabel>
        <FullWidthCard
          style={{
            padding: length(props.recommendations || []) > 0 ? 18 : 24
          }}
        >
          {(!props.recommendations || length(props.recommendations) === 0) &&
            !props.isEndorsing && (
              <FOHNoRecommendationCell
                headerText={props.endorseHeaderText}
                detailText={props.endorsementFormTitle}
                submitBtnLabel={props.recommendCTA}
                onPress={props.recommend}
                image={props.noEndorsementImage}
              />
            )}
          {props.isEndorsing && (
            <FOHEndorseForm
              submitLabel={props.submitLabel}
              endorsement={props.endorsement}
              endorsementPlaceholder={props.endorsementPlaceholder}
              setEndorsement={props.setEndorsement}
              relation={props.relation}
              relationPlaceholder={props.relationPlaceholder}
              setRelation={props.setRelation}
              handleSubmit={props.handleSubmit}
              error={props.error}
              cancelLabel={props.cancelLabel}
              cancel={props.cancel}
            />
          )}
          <FOHView>
            <FOHView
              style={{
                paddingTop:
                  length(props.recommendations || []) > 0 && props.isEndorsing
                    ? 10
                    : 0
              }}
            >
              {props.recommendations &&
                props.recommendations.map((rec, index) => (
                  <FullWidthCard key={`endorse-${index}`} noBorder={true}>
                    <FOHRecommendationCell
                      endorser={rec.endorser}
                      relation={rec.relation}
                      recommendation={rec.recommendation}
                      date={rec.date}
                    />
                  </FullWidthCard>
                ))}
              {props.recommendations &&
                length(props.recommendations || []) > 0 && (
                  <>
                    <FOHView style={{ paddingVertical: 5 }}>
                      <FOHDivider />
                    </FOHView>
                    <FOHView
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      <FOHDetail>{props.endorsementFormTitle}</FOHDetail>
                      <FOHButton
                        style={{
                          width: 218
                        }}
                        fullWidth={true}
                        title={props.recommendCTA}
                        onPress={props.recommend}
                        textColor={FOHColors.WHITE}
                      />
                    </FOHView>
                  </>
                )}
            </FOHView>
          </FOHView>
        </FullWidthCard>
      </>
    )}
    {!!props.canViewOnly && length(props.recommendations || []) > 0 ? (
      <>
        <FOHSectionLabel style={{ paddingBottom: 6, paddingTop: 6 }}>
          {props.endorseTableTitle}
        </FOHSectionLabel>
        <FullWidthCard
          style={{
            padding: length(props.recommendations || []) > 0 ? 18 : 24
          }}
        >
          <FOHView
            style={{
              paddingTop:
                length(props.recommendations || []) > 0 && props.isEndorsing
                  ? 10
                  : 0
            }}
          >
            {props.recommendations &&
              props.recommendations.map((rec, index) => (
                <FullWidthCard key={`endorse-${index}`} noBorder={true}>
                  <FOHRecommendationCell
                    endorser={rec.endorser}
                    relation={rec.relation}
                    recommendation={rec.recommendation}
                    date={rec.date}
                  />
                </FullWidthCard>
              ))}
          </FOHView>
        </FullWidthCard>
      </>
    ) : null}
  </FOHView>
);

const FOHDetail = styled(FOHSmallDetailLabel)`
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  max-width: 348px;
  text-align: left;
  color: ${FOHColors.NARO_GREY};
`;
