import React from 'react';

import styled from 'styled-components/native';

import {
  FOHButton,
  FOHColors,
  FOHGrayX,
  FOHModalProps,
  FOHSupportIcon,
  FOHTouchableOpacity,
  FOHView
} from '../../ingredients';

interface FOHSupportDialogProps extends FOHModalProps {
  titleCmp?: any;
}

export const FOHSupportDialog = (props: FOHSupportDialogProps) => {
  const z = 9999;
  return (
    <>
      {props.open && (
        <DialogView
          style={{
            zIndex: z
          }}
        >
          <CloseContainer>
            <FOHTouchableOpacity onPress={props.close}>
              <FOHGrayX />
            </FOHTouchableOpacity>
          </CloseContainer>
          <IconContainer>
            <FOHSupportIcon />
          </IconContainer>
          <ContainerView style={{ height: props.open ? '100%' : 0 }}>
            <FOHView style={{ width: '75%', paddingTop: 6 }}>
              {props.titleCmp()}
              {props.children}
            </FOHView>
            <FOHView
              style={{
                flexDirection: 'row',
                justifyContent: 'flex-end',
                margin: 24
              }}
            >
              {props.doneText && props.close ? (
                <FOHButton
                  title={props.doneText}
                  onPress={props.close}
                  style={{ width: 87 }}
                />
              ) : (
                <></>
              )}
            </FOHView>
          </ContainerView>
        </DialogView>
      )}
    </>
  );
};

const ContainerView = styled(FOHView)`
  top: 8px;
  left: 36px;
`;

const DialogView = styled(FOHView)`
  min-height: 100px;
  z-index: 200;
  padding-left: 24px;
  background-color: ${FOHColors.WHITE};
  padding-right: 24px;
  shadow-opacity: 0.75;
  shadow-radius: 18px;
  shadow-color: ${FOHColors.GRAYSCALE_1};
  shadow-offset: { width: 5px, height: 10px };
  bottom: 20px;
  margin: 24px;
`;

const IconContainer = styled(FOHView)`
  width: 32px;
  z-index: 99999;
  position: absolute
  left: 20px;
  top: 16px;
  justify-content: center;
  align-items: center;
`;

const CloseContainer = styled(FOHView)`
  width: 32px;
  z-index: 99999;
  position: absolute
  right: 20px;
  top: 16px;
  justify-content: center;
  align-items: center;
`;
