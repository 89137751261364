import React, { useState } from 'react';

import { compose, length, path, defaultTo, prop } from 'ramda';
import { withTranslation } from 'react-i18next';

import { findById } from '../../utils';
import { useSearchLocationsQuery } from '../../graphql/generated';
import {
  FOHEmployerLocationSearch,
  FOHSpace,
  FOHTextButton
} from '../../components';

import { JoinTeamModal } from '../EmployerTeam/JoinTeamModal';

const SearchLocationToClaim = props => {
  const { t, search, setSearch, searchText, setSearchText } = props;

  const [openJoinTeam, setOpenJoinTeam] = useState(
    !!path(['invitedBy'], props)
  );

  const locationQuery = useSearchLocationsQuery({
    skip: length(search) === 0,
    variables: {
      first: 10,
      search: search
    }
  });

  const locations = defaultTo(
    [],
    path(['data', 'locations', 'edges'], locationQuery)
  ).map(node => ({ ...prop('node', node) }));

  const count = defaultTo(
    0,
    path(['data', 'locations', 'edgeCount'], locationQuery)
  );

  return (
    <>
      <FOHEmployerLocationSearch
        searchButtonTitle={t('searchButtonTitle')}
        searchPlaceholder={t('searchPlaceholder')}
        selectLabel={t('selectLabel')}
        followerCountLabel={t('followerCountLabel')}
        singleFollowerCountLabel={t('singleFollowerCountLabel')}
        disableNext={!prop('name', props.location)}
        restaurantCount={
          count === 1
            ? `${count} ${t('restaurant')}`
            : `${count} ${t('restaurants')}`
        }
        restaurantCountLabel={t('restaurantsCountLabel')}
        cantFindLabel={t('cantFindLabel')}
        createLocationLabel={t('createLocationLabel')}
        title={t('title')}
        description={t('description')}
        nextLabel={
          props.location
            ? t('claimRestaurant', {
                location: prop('name', props.location)
              })
            : t('claimRestaurant')
        }
        onPressSearch={() => {
          setSearch(searchText);
        }}
        locations={locations}
        loading={prop('loading', locationQuery)}
        onChangeSearch={val => setSearchText(val)}
        searchValue={searchText}
        largeHeader={true}
        currentSearchTerm={search}
        next={async () => {
          if (props.location) {
            props.setIsClaimingLocation(true);
          }

          return await props.nextStep();
        }}
        select={id => {
          const location = findById(id, locations);
          props.setLocation(location);
        }}
        selected={prop('id', props.location)}
        createLocation={async () => {
          props.setLocation();
          return await props.nextStep();
        }}
      />
      <FOHSpace />
      <FOHTextButton
        onPress={props.previousStep}
        title={t('common:previous')}
      />
      <FOHSpace />
      <JoinTeamModal
        {...props}
        close={() => {
          setOpenJoinTeam(false);
        }}
        open={
          !!path(['location', 'slug'], props) &&
          !!path(['invitedBy'], props) &&
          openJoinTeam
        }
      />
    </>
  );
};

export default compose(withTranslation('SearchLocationsFeature'))(
  SearchLocationToClaim
);
