import React, { FunctionComponent } from 'react';

import { ImageURISource } from 'react-native';
import styled from 'styled-components/native';

export interface PreviewImageProps {}

export const PreviewImage: FunctionComponent<{
  source: ImageURISource;
  type?: string;
}> = ({ source, type }) => {
  if (type === 'video/mp4') {
    return (
      <video //eslint-disable-line
        src={source.uri}
        style={{
          borderRadius: 4,
          height: 40,
          objectFit: 'cover',
          width: 40
        }}
      />
    );
  }

  return <PreviewImageFile source={source} />;
};

const PreviewImageFile = styled.Image`
  width: 40px;
  height: 40px;
  border-radius: 4px;
`;
