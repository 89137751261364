import React from 'react';

import interviewIcon from '../../../images/interviews_icon.svg';
import inactiveInterviewIcon from '../../../images/gray_interviews_icon.svg';

import { FOHImage } from '../../ingredients';

export interface FOHInterviewIconProps {
  active?: boolean;
  style?: any;
}

export const FOHInterviewIcon = (props: FOHInterviewIconProps) => (
  <FOHImage
    source={{
      uri: props.active ? interviewIcon : inactiveInterviewIcon
    }}
    style={{ width: 16, height: 16, ...props.style }}
  />
);
