import React from 'react';

import {
  FullWidthCard,
  FOHSectionLabel,
  FOHEditMarker,
  FOHView,
  FOHTouchableOpacity
} from '../../ingredients';

import {
  FOHAvailabilityReadTableProps,
  FOHAvailabilityReadTable
} from './FOHAvailabilityReadTable';

export interface FOHAvailabilityProfileTableProps
  extends FOHAvailabilityReadTableProps {
  availabilityLabel: string;
  maxWidth?: string;
  onOpenEditAvailability?: () => void;
}

export const FOHAvailabilityProfileTable = (
  props: FOHAvailabilityProfileTableProps
) => (
  <FOHView style={{ maxWidth: props.maxWidth || 600, width: '100%' }}>
    <FOHSectionLabel style={{ paddingBottom: 6 }}>
      {props.availabilityLabel}
    </FOHSectionLabel>
    <FullWidthCard style={{ marginBottom: 24 }}>
      {props.onOpenEditAvailability && (
        <FOHTouchableOpacity
          testID={`edit-availability`}
          onPress={props.onOpenEditAvailability}
          style={{
            position: 'absolute',
            right: 24,
            top: 12,
            zIndex: 20
          }}
        >
          <FOHEditMarker />
        </FOHTouchableOpacity>
      )}
      <FOHView style={{ padding: 12, width: '100%' }}>
        <FOHAvailabilityReadTable availability={props.availability} />
        <FOHView style={{ paddingLeft: 6 }} />
      </FOHView>
    </FullWidthCard>
  </FOHView>
);
