import React from 'react';

import styled from 'styled-components/native';

import { FOHView } from '../../ingredients';

export interface FOHExpiredChatBubbleProps {
  numbnerOfLines?: number;
  leftSpeach?: boolean;
}

const Lines = ({
  numberOfLines = 1,
  style = {}
}: {
  numberOfLines: number;
  style?: any;
}) => (
  <>
    {new Array(numberOfLines).fill({}).map((_n, i) => (
      <Line key={`line-${i}`} style={{ ...style }} />
    ))}
  </>
);

export const FOHExpiredChatBubble = (props: FOHExpiredChatBubbleProps) =>
  props.leftSpeach ? (
    <LeftBubble>
      <Lines
        numberOfLines={props.numbnerOfLines || 1}
        style={{
          background: '#DBDBDB'
        }}
      />
    </LeftBubble>
  ) : (
    <RightBubble>
      <Lines numberOfLines={props.numbnerOfLines || 1} />
    </RightBubble>
  );

const LeftBubble = styled(FOHView)`
  padding: 18px;
  border-radius: 24px;
  border-bottom-left-radius: 0px;
  background: #f5f5f5;
`;

const RightBubble = styled(FOHView)`
  padding: 18px;
  border-radius: 24px;
  border-bottom-right-radius: 0px;
  background: #4f4f4f;
`;

const Line = styled(FOHView)`
  border-radius: 4px;
  background: #878787;
  margin-bottom: 4px;
  height: 4px;
  width: 100%;
  padding: 8px;
`;
