import { Text } from 'react-native';
import styled from 'styled-components/native';
import { prop } from 'ramda';

import { FOHColors } from '../colors/FOHColors';

import { FOHFonts } from './FOHFonts';

export const FOHLabel = styled(Text)`
  font-family: ${(props: any) =>
    prop('bold', props)
      ? FOHFonts.BOLD
      : prop('heavy', props)
      ? FOHFonts.MEDIUM
      : FOHFonts.REGULAR};
  font-style: normal;
  font-weight: ${(props: any) =>
    prop('bold', props) ? '700' : prop('heavy', props) ? '600' : '400'};
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  color: ${FOHColors.GRAYSCALE_G1};
`;

export const FOHLabelLarge = styled(FOHLabel)`
  font-size: 32px;
`;

export const FOHLabelLargeWithBold = styled(FOHLabel)`
  font-size: 32px;
  font-weight: 700;
  font-family: ${FOHFonts.BOLD};
`;

export const FOHLabelMedium = styled(FOHLabel)`
  font-size: 20px;
`;

export const FOHLabelSmall = styled(FOHLabel)`
  font-size: 14px;
`;

export const FOHLabelBold = styled(FOHLabel)`
  font-family: ${FOHFonts.BOLD};
  font-weight: 700;
`;

export const FOHLabelSecondary = styled(FOHLabel)`
  color: ${FOHColors.WHITE};
  font-size: 14px;
`;

export const FOHLabelSmallest = styled(FOHLabel)`
  font-size: 14px;
  line-height: 18px;
`;
