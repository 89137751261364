import React from 'react';

import styled from 'styled-components/native';
import { View } from 'react-native';

import {
  FOHLabel,
  FOHSpaceSmall,
  FOHTouchableOpacity
} from '../../ingredients';

export interface FOHMenuCellProps {
  label: string;
  icon?: any;
  onPress: () => any;
  testID?: string;
}

export const FOHMenuItemCell = (props: FOHMenuCellProps) => (
  <FOHTouchableOpacity onPress={props.onPress} testID={props.testID}>
    <Container>
      {!!props.icon && (
        <>
          <props.icon />
          <FOHSpaceSmall />
        </>
      )}
      <CellText>{props.label}</CellText>
    </Container>
  </FOHTouchableOpacity>
);

const Container = styled(View)`
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  height: 60px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: -1px;
`;

const CellText = styled(FOHLabel)`
  font-size: 15px;
  margin-right: 10px;
`;
