import React from 'react';

import {
  FullWidthCard,
  FOHDetailText,
  FOHHeaderH3,
  FOHDivider,
  FOHSpace,
  FOHInverseButton,
  FOHButton,
  FOHSpaceLarge,
  FOHErrorLabel,
  FOHView,
  FOHMultiLineTextField,
  FOHLabelSmall,
  FOHColors,
  FOHSpaceSmall
} from '../../ingredients';
import { FOH_INPUT_HEIGHT } from '../../constants';

import {
  FOHPaginatedSchedulePickerProps,
  FOHPaginatedSchedulePicker
} from '../schedule/FOHPaginatedSchedulePicker';
import { FOHSelectItemProps } from '../select/FOHSelect';
import { FOHLabeledCheckbox } from '../checkbox/FOHLabeledCheckbox';
import {
  FOHInterviewDetailsBlock,
  FOHInterviewDetailsBlockProps
} from '../blocks/FOHInterviewDetailsBlock';
import { FOHMultiSelectButtons } from '../select/FOHMultiSelectButtons';

import { FOHForm } from './FOHForm';

export interface FOHCandidateInterviewScheduleFormProps
  extends FOHPaginatedSchedulePickerProps,
    FOHInterviewDetailsBlockProps {
  timeSlots: Array<FOHSelectItemProps>;
  stepLabel: string;
  selectDayLabel: string;
  selectTimeLabel: string;
  noneOfTheseTimesWorkLabel: string;
  noneOfTheseTimesWork: boolean;
  setNoneOfTheseTimesWork: (nottw: boolean) => any;
  selectedTime: string;
  setSelectedTime: (time: string) => any;
  goBackLabel: string;
  goBack: () => any;
  submitScheduleLabel: string;
  submitSchedule: () => any;
  vertical?: boolean;
  disabledSubmitSchedule?: boolean;
  error?: string;

  candidateCustomMessage?: string;
  setCandidateCustomMessage?: (customMessage: string) => void;
  candidateCustomMessagePlaceholder?: string;
}

export const FOHCandidateInterviewScheduleForm = (
  props: FOHCandidateInterviewScheduleFormProps
) => (
  <FullWidthCard style={{ padding: props.vertical ? 12 : 24 }}>
    <FOHForm
      handleSubmit={props.submitSchedule}
      disabledSubmit={props.disabledSubmitSchedule}
    >
      <FOHInterviewDetailsBlock {...props} />
      <FOHSpace />
      <FOHDivider />
      <FOHDetailText>{`${props.stepLabel} 1`}</FOHDetailText>
      <FOHHeaderH3 style={{ textAlign: 'left' }}>
        {props.selectDayLabel}
      </FOHHeaderH3>
      <FOHPaginatedSchedulePicker {...props} mobile={props.vertical} />
      <FOHSpace />
      <FOHDetailText>{`${props.stepLabel} 2`}</FOHDetailText>
      <FOHHeaderH3 style={{ textAlign: 'left' }}>
        {props.selectTimeLabel}
      </FOHHeaderH3>
      <FOHMultiSelectButtons
        options={props.timeSlots}
        selected={[props.selectedTime]}
        selectItem={props.setSelectedTime}
      />
      <FOHSpace />
      <FOHLabeledCheckbox
        checkboxLabel={props.noneOfTheseTimesWorkLabel}
        selected={props.noneOfTheseTimesWork}
        setSelected={() =>
          props.setNoneOfTheseTimesWork(!props.noneOfTheseTimesWork)
        }
      />
      <>
        {props.noneOfTheseTimesWork ? (
          <>
            <FOHSpace />
            <FOHLabelSmall
              style={{
                color: FOHColors.GRAYSCALE_500,
                lineHeight: 18
              }}
            >
              {props.candidateCustomMessagePlaceholder}
            </FOHLabelSmall>
            <FOHSpaceSmall />
            <FOHMultiLineTextField
              max={500}
              value={props.candidateCustomMessage}
              onChangeText={props.setCandidateCustomMessage}
            />
          </>
        ) : (
          <></>
        )}
      </>
      <FOHSpaceLarge />

      {props.error && (
        <FOHView
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            paddingBottom: 6
          }}
        >
          <FOHErrorLabel>{props.error}</FOHErrorLabel>
        </FOHView>
      )}
      <FOHView
        style={{
          flexDirection: props.vertical ? 'column-reverse' : 'row',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <FOHView style={{ width: 300 }}>
          <FOHInverseButton
            onPress={props.goBack}
            title={props.goBackLabel}
            style={{ height: FOH_INPUT_HEIGHT }}
          />
        </FOHView>
        <FOHSpace />
        <FOHView style={{ width: 300 }}>
          <FOHButton
            onPress={props.submitSchedule}
            title={props.submitScheduleLabel}
            disabled={props.disabledSubmitSchedule}
            style={{ height: FOH_INPUT_HEIGHT }}
          />
        </FOHView>
      </FOHView>
    </FOHForm>
  </FullWidthCard>
);
