import React, { useState, useEffect } from 'react';

import { useMatch, useParams } from 'react-router-dom';
import { compose, path, length } from 'ramda';
import { withTranslation } from 'react-i18next';
import Loadable from '@loadable/component';

import { useIsMobile } from '../../utils';
import { EMPLOYER_SETTINGS_MENU } from '../../constants';
import { useNavigation } from '../../utils/navigation';
import {
  FOHAccountMenuScreen,
  FOHWideAccountMenuScreen
} from '../../components';

import EditLocation from '../Locations/EditLocation';
import { MyPositions } from '../EmployerPositions/MyPositions';
import { EmployerRejectionReasons } from '../EmployerRejection';
import { useSidebarNav } from '../Navigation/useSidebar';
import { useFeatures } from '../SignIn/useFeatures';
import NotificationSettings from '../NotificationSettings/NotificationSettings';
import Integrations from '../Integrations/IntegrationsScreen';
import { EmployerInterviewAvailability } from '../EmployerInterviewSchedule';
import { OrganizeYourTeam } from '../EmployerTeam';
import BillingSettings from '../Billing/BillingSettings';
import { useGetMeData } from '../SignIn';

import EmployerAccount from './EmployerAccount';
// React Quill does not SSR, use loadable
const LoadableSettingsPositionScreen = Loadable(() =>
  import('./SettingsPositionScreen')
);

const getRoutes = t => [
  {
    label: t('accountSideBar'),
    route: `account`, // activeScreen value
    Component: EmployerAccount
  },
  {
    label: t('locationSideBar'),
    route: `location`, // activeScreen value
    Component: EditLocation
  },
  {
    label: t('notificationSideBar'),
    route: `notifications`, // activeScreen value
    Component: NotificationSettings
  },
  {
    label: t('teamSideBar'),
    route: 'team',
    Component: OrganizeYourTeam
  },
  {
    label: t('positionsSideBar'),
    route: 'positions',
    Component: MyPositions
  },
  {
    label: t('scheduleSideBar'),
    route: `schedule`,
    Component: EmployerInterviewAvailability
  },
  {
    label: t('PricingScreenFeature:bannerTitle'),
    route: 'billing',
    Component: BillingSettings
  },
  {
    label: '', // hide for now
    route: 'rejections',
    Component: EmployerRejectionReasons
  },
  {
    label: t('Integrations:integrationsHeaderTitle'),
    route: 'integrations',
    Component: Integrations
  }
];

export const employerSettingsRoutes = [
  {
    label: 'positions',
    route: 'positions/status/:modal/:position',
    Component: MyPositions
  },
  {
    label: 'positions',
    route: 'positions/status/:modal',
    Component: MyPositions
  },

  {
    label: 'positions',
    route: 'positions/status/:modal',
    Component: MyPositions
  },
  {
    label: 'positions',
    route: 'positions/:positionId',
    Component: LoadableSettingsPositionScreen
  },
  {
    label: 'billing',
    route: 'billing/:tab/:modal',
    Component: BillingSettings
  },
  {
    label: 'billing',
    route: 'billing/:tab',
    Component: BillingSettings
  }
];

const AccountMenuScreen = props => {
  const { t } = props;
  const { navigateTo, location } = useNavigation();

  const match = useMatch(`${EMPLOYER_SETTINGS_MENU}/*`);

  const params = useParams();

  const { data: me } = useGetMeData();
  const { isMobile } = useIsMobile();
  const features = useFeatures();
  const [routes, setRoutes] = useState(getRoutes(t, features));
  const [activeScreen, setActiveScreen] = useState(
    props.activeScreen ? props.activeScreen : 'settings'
  );

  useEffect(() => {
    setRoutes(getRoutes(t, features));
  }, [me]); //eslint-disable-line

  useEffect(() => {
    if (location.pathname) {
      const { activeScreen: currentScreen } = params;
      // If we have the route, update the sidebar
      // mobile always update because there is no visible sidebar
      if (
        length(routes.filter(route => route.route === currentScreen)) === 1 ||
        isMobile
      ) {
        setActiveScreen(currentScreen);
      }
    }
  }, [location.pathname]); //eslint-disable-line

  const DefaultComponent = routes[0].Component;

  const CurrentComponent = routes.find(
    route => route.route === activeScreen
  )?.Component;

  const { hasSideNav } = useSidebarNav(props);
  return (
    <>
      {isMobile || hasSideNav ? (
        <>
          {activeScreen === 'settings' && !hasSideNav && (
            <FOHAccountMenuScreen
              navigateTo={route => {
                navigateTo(`${match.path}/${route}`);
              }}
              navigation={routes}
              key={path(['candidateProfile', 'id'], me)}
            />
          )}
          {CurrentComponent ? <CurrentComponent /> : <DefaultComponent />}
        </>
      ) : (
        <FOHWideAccountMenuScreen
          activeScreen={
            activeScreen === 'settings'
              ? routes[0].route
              : activeScreen || 'account'
          }
          setActiveScreen={setActiveScreen}
          key={path(['candidateProfile', 'id'], me)}
          navigateTo={route => {
            navigateTo(`${match.pathnameBase}/${route}`);
          }}
          navigation={routes}
        >
          {CurrentComponent ? <CurrentComponent /> : <DefaultComponent />}
        </FOHWideAccountMenuScreen>
      )}
    </>
  );
};

export default compose(withTranslation('EmployerSettingsFeature'))(
  AccountMenuScreen
);
