import React from 'react';

import styled from 'styled-components/native';
import { Image } from 'react-native';

import {
  FullWidthCard,
  FOHView,
  FOHSpace,
  FOHSpaceLarger,
  FOHSpaceSmall,
  FOHHeaderH2,
  FOHSmallDetailLabel,
  FOHButton,
  FOHColors,
  FOHFonts
} from '../../ingredients';

export interface FOHNoResumeCellProps {
  isMobile?: boolean;
  image: string;
  headerText: string;
  detailText: string;
  submitBtnLabel: string;
  onPress: () => void;
}

export const FOHNoResumeCell = (props: FOHNoResumeCellProps) => (
  <FullWidthCard
    noBorder={true}
    style={{
      padding: 16,
      marginBottom: 0,
      backgroundColor: FOHColors.LIGHT_GRAY
    }}
  >
    <Container>
      <ImageContainer mobile={props.isMobile}>
        <FOHImage
          source={{
            uri: props?.image
          }}
        />
      </ImageContainer>
      {!props.isMobile ? <FOHSpaceLarger /> : <FOHSpaceSmall />}
      <Block mobile={props.isMobile}>
        <FOHHeaderH2 style={{ textAlign: 'left' }}>
          {props.headerText}
        </FOHHeaderH2>
        <FOHSpace />
        <FOHDetail>{props.detailText}</FOHDetail>
        <FOHSpace />
        <FOHButton
          style={{
            maxWidth: 248,
            width: '100%'
          }}
          title={props.submitBtnLabel}
          onPress={props.onPress}
          color={FOHColors.WHITE}
          textColor={FOHColors.BLACK}
          borderColor={FOHColors.BACKGROUND_DARK_GRAY}
        />
      </Block>
    </Container>
  </FullWidthCard>
);

const Container = styled(FOHView)`
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  display: flex;
`;

const ImageContainer = styled(FOHView)<{ mobile?: boolean }>`
  width: ${(p: any) => (p.mobile ? '100%' : '192px')};
  height: ${(p: any) => (p.mobile ? '100%' : '144px')};
  align-items: center;
  justify-content: center;
`;

const FOHImage = styled(Image)`
  max-width: 192px;
  width: 100%;
  height: 144px;
  object-fit: cover;
`;

const Block = styled(FOHView)<{ mobile?: boolean }>`
  padding: ${(p: any) => (p.mobile ? '10px' : '5px')};
  align-items: ${(p: any) => (p.mobile ? 'center' : 'flex-start')};
  justify-content: ${(p: any) => (p.mobile ? 'center' : 'space-between')};
  max-width: ${(p: any) => (p.mobile ? '100%' : '663px')};
  flex: 1;
`;

const FOHDetail = styled(FOHSmallDetailLabel)`
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: ${FOHColors.NARO_GREY};
`;
