import React from 'react';

import { FOHView } from '../../ingredients';

import { FOHSelect, FOHSelectItemProps, FOHSelectItem } from './FOHSelect';

interface FOHSelectProps {
  year: string;
  yearPlaceholder: string;
  setYear: (value: string) => void;
  yearError?: any;
  month: string;
  monthPlaceholder: string;
  setMonth: (value: string) => void;
  monthError?: any;
  months: Array<FOHSelectItemProps>;
  years: Array<FOHSelectItemProps>;
}

export const FOHDateSelect = (props: FOHSelectProps) => {
  return (
    <FOHView
      style={{
        flexDirection: 'row',
        maxWidth: 158
      }}
    >
      <FOHSelect
        selectedValue={props.month || ''}
        onValueChange={(value: string) => props.setMonth(value)}
        placeholder={props.monthPlaceholder}
        small={true}
        error={props.monthError}
      >
        {props.months.map(month => (
          <FOHSelectItem
            key={month.label}
            label={month.label}
            value={month.value}
          />
        ))}
      </FOHSelect>
      <FOHView style={{ paddingLeft: 12 }} />
      <FOHSelect
        selectedValue={props.year || ''}
        onValueChange={(value: string) => props.setYear(value)}
        placeholder={props.yearPlaceholder}
        small={true}
        error={props.yearError}
      >
        {props.years.map(year => (
          <FOHSelectItem
            key={year.label}
            label={year.label}
            value={year.value}
          />
        ))}
      </FOHSelect>
    </FOHView>
  );
};
