import { useState, useEffect } from 'react';

export const useAsyncFn = (asyncFn, deps = []) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    setLoading(true);
    setError(null);
    setData(null);

    asyncFn()
      .then(response => {
        setData(response);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        setError(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return { loading, error, data };
};
