import React, { FunctionComponent } from 'react';

import share from '../../../images/shareicon.svg';

import { FOHImage } from '../../ingredients';
import { FOHIconProps } from '../../typings';

export const FOHShareIcon: FunctionComponent<FOHIconProps> = ({ style }) => (
  <FOHImage
    source={{ uri: share }}
    style={[{ width: 24, height: 24, tintColor: '#000000' }, style]}
  />
);
