import React from 'react';

import { FOHView, FOHSectionLabel } from '../../ingredients';

import { FOHSelectButtonCell } from '../cells/FOHSelectButtonCell';

export interface FOHAvailabilitySectionProps {
  shifts: Array<Array<FOHSectionItems>>;
  selected: Array<string>;
  section: string;
  label: string;
  singleRow?: boolean;
  selectItem: (value: string, section: string) => void;
  allSelected?: boolean;
}

interface FOHSectionItems {
  label: string;
  value: string;
}

export const FOHAvailabilitySection = (props: FOHAvailabilitySectionProps) => (
  <FOHView>
    <FOHSectionLabel style={{ paddingLeft: 6 }}>{props.label}</FOHSectionLabel>
    <FOHView style={{ flexDirection: props.singleRow ? 'row' : 'column' }}>
      {props.shifts.map((shift, i) => (
        <FOHView style={{ flexDirection: 'row' }} key={`${i}shift`}>
          {shift.map(item => (
            <FOHSelectButtonCell
              key={item.value}
              value={item.value}
              small={props.singleRow}
              onPress={props.selectItem}
              label={item.label}
              section={props.section}
              selected={
                props.allSelected ||
                (props.selected &&
                  props.selected.includes(`${props.section}-${item.value}`))
              }
            />
          ))}
        </FOHView>
      ))}
    </FOHView>
  </FOHView>
);
