import React from 'react';

import {
  FOHColors,
  FOHFonts,
  FOHLabel,
  FOHSpace,
  FOHTextField,
  FOHView
} from '../../ingredients';

export interface FOHCandidateDetailFormProps {
  titleLabel: string;

  firstName: string;
  firstNamePlaceHolder: string;

  lastName: string;
  lastNamePlaceHolder: string;

  email: string;
  emailPlaceHolder: string;

  mobileNumber: string;
  mobileNumberPlaceHolder: string;

  error?: any;
}

export const FOHCandidateDetailForm = (props: FOHCandidateDetailFormProps) => (
  <FOHView
    style={{
      width: '100%',
      paddingVertical: 10
    }}
  >
    <FOHView>
      <FOHLabel
        style={{
          fontSize: 18,
          lineHeight: 24,
          fontWeight: 600,
          fontFamily: FOHFonts.REGULAR,
          color: FOHColors.GRAYSCALE_900
        }}
      >
        {props.titleLabel}
      </FOHLabel>
    </FOHView>
    <FOHSpace />
    <FOHView
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap'
      }}
    >
      <FOHView style={{ width: '49%' }}>
        <FOHTextField
          placeholder={props?.firstNamePlaceHolder}
          value={props?.firstName}
          disabled
          error={props?.error?.firstName || null}
        />
      </FOHView>
      <FOHView style={{ width: '49%' }}>
        <FOHTextField
          placeholder={props?.lastNamePlaceHolder}
          value={props?.lastName}
          disabled
          error={props?.error?.lastName || null}
        />
      </FOHView>
    </FOHView>
    <FOHSpace />
    <FOHView
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap'
      }}
    >
      <FOHView style={{ width: '49%' }}>
        <FOHTextField
          placeholder={props?.emailPlaceHolder}
          value={props?.email}
          disabled
          error={props?.error?.email || null}
        />
      </FOHView>
      <FOHView style={{ width: '49%' }}>
        <FOHTextField
          placeholder={props?.mobileNumberPlaceHolder}
          value={props?.mobileNumber}
          disabled
          error={props?.error?.mobileNumber || null}
        />
      </FOHView>
    </FOHView>
  </FOHView>
);
