import React from 'react';

import {
  FOHHeaderText,
  Screen,
  FOHDetailText,
  FOHView
} from '../../ingredients';
import { FOHRegisterFormProps, FOHRegisterForm } from '../../recipes';

export interface FOHRegistercreenProps extends FOHRegisterFormProps {
  largeHeader?: boolean;
  title?: string;
  subTitle?: string;
}

export const FOHRegisterScreen = (props: FOHRegistercreenProps) => (
  <Screen>
    <FOHView
      style={{
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {!!props.title && (
        <FOHHeaderText style={{ fontSize: props.largeHeader ? 42 : 30 }}>
          {props.title}
        </FOHHeaderText>
      )}
      {!!props.subTitle && <FOHDetailText>{props.subTitle}</FOHDetailText>}
      <FOHView style={{ paddingTop: 18 }} />
      <FOHRegisterForm {...props} />
    </FOHView>
  </Screen>
);
