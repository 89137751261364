import React from 'react';

import styled from 'styled-components/native';
import { View, TouchableWithoutFeedback } from 'react-native';

import { GrayBlur, FOHXIcon, FOHTouchableOpacity } from '../../ingredients';
import { FOHProfileImage } from '../../recipes';

export interface FOHVideoStoryPlayerProps {
  profilePhoto: string;
  video?: string;
  togglePlaying: () => void;
  open?: boolean;
  close: () => void;
  VideoComponent: any;
  z?: number;
}

export const FOHVideoStoryPlayer = (props: FOHVideoStoryPlayerProps) => (
  <>
    {props.open && props.video ? (
      <View
        style={{
          position: 'absolute',
          width: '100vw',
          height: '100vh',
          zIndex: 9999,
          top: 0,
          left: 0
        }}
      >
        <TouchableWithoutFeedback
          onPress={props.close}
          style={{ height: '100%' }}
        >
          <GrayBlur
            style={{
              backgroundColor: '#000000',
              opacity: 0.95,
              zIndex: props.z ? props.z : 199
            }}
          />
        </TouchableWithoutFeedback>
        <TouchableWithoutFeedback
          onPress={props.togglePlaying}
          style={{ height: '100%' }}
        >
          <PlayContainer style={{ zIndex: props.z ? props.z : 200 }}>
            <FOHTouchableOpacity
              onPress={props.close}
              style={{
                height: '100%',
                zIndex: props.z ? props.z + 2 : 201
              }}
            >
              <FOHProfileImage
                photo={props.profilePhoto}
                style={{
                  width: 60,
                  height: 60,
                  borderRadius: '50%',
                  borderColor: '#51C1DB',
                  position: 'absolute',
                  borderWidth: 2,
                  top: 60,
                  left: '48%',
                  marginLeft: -160
                }}
              />
            </FOHTouchableOpacity>
            <FOHTouchableOpacity
              onPress={props.close}
              style={{ height: '100%', zIndex: props.z ? props.z + 2 : 201 }}
            >
              <View
                // @ts-ignore borderRadius expecting number value but got string as per our requirement
                style={{
                  width: 30,
                  height: 30,
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '50%',
                  backgroundColor: '#ffffff',
                  position: 'absolute',
                  top: 10,
                  left: '44%',
                  marginLeft: 140
                }}
              >
                <FOHXIcon />
              </View>
            </FOHTouchableOpacity>
            <props.VideoComponent
              style={{
                objectFit: 'scale-down',
                position: 'absolute',
                width: '100%',
                maxWidth: '400',
                height: 700,
                maxHeight: '100vh',
                right: '0',
                left: '0'
              }}
            />
          </PlayContainer>
        </TouchableWithoutFeedback>
      </View>
    ) : (
      <></>
    )}
  </>
);

const PlayContainer = styled(View)`
  width: 100vw;
  align-items: center;
  position: fixed;
  top: 20;
`;
