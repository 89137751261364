import React, { useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { path, length, prop } from 'ramda';
import { useOnClickOutside } from 'use-hooks';

import {
  removeAuth,
  AmplitudeTrackingEvents,
  useTrackAmplitudeEvent
} from '../../utils';
import {
  ALLOWED_ROLES_GROUPS,
  CAREER_PAGE_DASHBOARD,
  DEMO,
  REGISTER_URL,
  ROLES,
  EMPLOYER_SETTINGS_MENU,
  TEMPLATES,
  PERSONAL_ACCOUNT,
  FEED,
  EMPLOYER_DASHBOARD,
  UPCOMING_INTERVIEWS_SCHEDULE,
  NETWORK,
  CAMPAIGN_HOME,
  PENDING_EMPLOYER_PROFILE,
  SUBSCRIBE_LOCATIONS_POSITIONS,
  HELP_REQUEST_FORM
} from '../../constants';
import {
  useIsRestricted,
  FOHSpace,
  FOHView,
  FOHButton,
  FOHCandidateNetworkIcon,
  FOHDashboardIcon,
  FOHFavorite,
  FOHColors,
  FOHGrayCalendarScheduledIcon,
  FOHGrayCampaignIcon,
  FOHGrayCareerIcon,
  FOHGrayFeedIcon,
  FOHGrayHelpIcon,
  FOHHiringIcon,
  FOHSettingsIcon,
  FOHInverseButton,
  FOHNavigationMenu,
  FOHMessageTemplateIcon,
  FOHSettingsGrayIcon
} from '../../components';
import { useNavigation } from '../../utils/navigation';

import useLogoutRedirectContext from '../SignIn/useLogoutRedirectContext';
import { useGetMeData } from '../SignIn';

import { getEmployerId, getCandidateProfile, getIsHome } from './TopNav';

const getHomeRoutes = ({ t }) => [
  {
    icon: () => <></>,
    label: t('TopNavFeature:home'),
    route: '/'
  },
  {
    icon: () => <></>,
    label: t('TopNavFeature:demo'),
    route: '/demo'
  },
  {
    icon: () => <></>,
    label: t('TopNavFeature:pricing'),
    route: '/pricing'
  }
];

const getCandidateMenu = ({ t, hasInterviewRequest }) => [
  {
    label: t('TopNavFeature:jobs'),
    icon: () => (
      <FOHHiringIcon
        style={{ width: 16, height: 14, tintColor: FOHColors.BLACK }}
      />
    ),
    routes: [
      {
        label: t('discover'),
        route: '/home/discover'
      },
      {
        label: t('applications'),
        route: '/home/applications'
      },
      {
        label: t('requests'),
        route: '/home/requests',
        // TODO: QUERY FOR NEW INTERVIEW REQUESTS
        notification: hasInterviewRequest,
        notificationLabel: t('newInterviewRequest')
      },
      {
        label: t('interviews'),
        route: '/home/interviews'
      },
      {
        label: t('following'),
        route: '/home/following'
      }
    ]
  },
  {
    icon: () => <FOHFavorite style={{ tintColor: FOHColors.BLACK }} />,
    label: t('TopNavFeature:exploreEmployers'),
    route: '/restaurant-jobs'
  },
  {
    icon: () => <FOHSettingsIcon />,
    label: t('TopNavFeature:settings'),
    route: '/candidate/account'
  }
];

export const NavigationMenu = props => {
  const { t } = useTranslation('CandidateHomeTabs');
  const { navigateTo, location: navLocation } = useNavigation();

  const navigationMenuRef = useRef(null);

  const handleClose = () => {
    if (props.burgerOpen) {
      props.setBurgerOpen(false);
    }
  };

  useOnClickOutside(navigationMenuRef, handleClose);

  const { data: me } = useGetMeData();
  const userRole = path(['employerProfileUser', 'role'], me);

  const { logEventMetrics } = useTrackAmplitudeEvent();

  const hasEmployerProfile = !!getEmployerId(me);
  const hasCandidateProfile = !!getCandidateProfile(me);
  const isHome = getIsHome(props);

  const onClickHelp = () => {
    handleClose();
    navigateTo(HELP_REQUEST_FORM);
  };

  const hasPendingEmployerProfileRoute =
    navLocation?.pathname === PENDING_EMPLOYER_PROFILE;

  const hasInterviewRequest = length(prop('notifications', me)) > 0;

  const { setLogoutWipeCacheClicked } = useLogoutRedirectContext();

  const { isRestricted } = useIsRestricted({
    allowedRoles: ALLOWED_ROLES_GROUPS.ROLES.ADMIN_USER,
    allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.EMPLOYER]
  });

  const campaignHome =
    userRole === ROLES.ADMIN && !isRestricted
      ? [
          {
            labelKey: 'CampaignsFeature:campaigns',
            IconComponent: () => <FOHGrayCampaignIcon />,
            route: CAMPAIGN_HOME
          }
        ]
      : [];

  const careerPageDashboard = !isRestricted
    ? [
        {
          labelKey: 'CareerPage:careers',
          IconComponent: () => <FOHGrayCareerIcon />,
          route: CAREER_PAGE_DASHBOARD
        }
      ]
    : [];

  const mobileNavItems = [
    {
      labelKey: 'TopNavFeature:dashboard',
      IconComponent: () => (
        <FOHDashboardIcon
          style={{ tintColor: FOHColors.GRAYSCALE_300, width: 24, height: 24 }}
        />
      ),
      route: EMPLOYER_DASHBOARD
    },
    {
      labelKey: 'TopNavFeature:candidateFeed',
      IconComponent: () => (
        <FOHGrayFeedIcon
          style={{ tintColor: FOHColors.GRAYSCALE_300, width: 24, height: 24 }}
        />
      ),
      route: FEED
    },
    {
      labelKey: 'TopNavFeature:smsTemplates',
      IconComponent: () => (
        <FOHMessageTemplateIcon
          style={{ tintColor: FOHColors.GRAYSCALE_300, width: 24, height: 24 }}
        />
      ),
      route: TEMPLATES
    },
    {
      labelKey: 'TopNavFeature:upcomingInterviews',
      IconComponent: () => (
        <FOHGrayCalendarScheduledIcon
          style={{ tintColor: FOHColors.GRAYSCALE_300, width: 24, height: 24 }}
        />
      ),
      route: UPCOMING_INTERVIEWS_SCHEDULE
    },
    {
      labelKey: 'TopNavFeature:candidateNetwork',
      IconComponent: () => (
        <FOHCandidateNetworkIcon
          style={{ tintColor: FOHColors.GRAYSCALE_300, width: 24, height: 24 }}
        />
      ),
      route: NETWORK
    },
    ...campaignHome,
    ...careerPageDashboard,
    {
      labelKey: 'TopNavFeature:settings',
      IconComponent: () => (
        <FOHSettingsGrayIcon
          style={{ tintColor: FOHColors.GRAYSCALE_300, width: 24, height: 24 }}
        />
      ),
      route: `${EMPLOYER_SETTINGS_MENU}${PERSONAL_ACCOUNT}`
    },
    {
      labelKey: 'TopNavFeature:help',
      IconComponent: () => (
        <FOHGrayHelpIcon
          style={{ tintColor: FOHColors.GRAYSCALE_300, width: 24, height: 24 }}
        />
      ),
      route: '',
      additionalProps: { onPress: onClickHelp },
      disabled: false
    }
  ];

  const createMobileNavItem = (
    labelKey,
    IconComponent,
    route,
    additionalProps = {},
    disabled = null
  ) => ({
    icon: IconComponent,
    label: t(labelKey),
    route,
    disabled:
      disabled !== null
        ? disabled
        : navLocation?.pathname === SUBSCRIBE_LOCATIONS_POSITIONS,
    ...additionalProps
  });

  const getEmployerMenu = () => [
    ...mobileNavItems.map(item =>
      createMobileNavItem(
        item.labelKey,
        item.IconComponent,
        item.route,
        item.additionalProps,
        item?.disabled
      )
    )
  ];

  const onPressNavigationItem = (route, title, groupName = '') => {
    props.setBurgerOpen(false);

    const subNavItemClickedDetail = groupName
      ? { sub_navigation_item_clicked: groupName }
      : undefined;

    const navItemClickedEventDetail = {
      event_type: AmplitudeTrackingEvents.NAVIGATION_ITEM_CLICKED.EVENT_TYPE,
      navigation_item_clicked: {
        nav_item_title: title,
        nav_item_route: route,
        ...subNavItemClickedDetail
      }
    };

    logEventMetrics(
      AmplitudeTrackingEvents.NAVIGATION_ITEM_CLICKED.EVENT_NAME,
      navItemClickedEventDetail
    );

    navigateTo(`${route}`);
  };

  return (
    <FOHView
      ref={navigationMenuRef}
      style={{
        position: 'fixed',
        top: 50,
        left: 0,
        zIndex: 9999,
        width: '100vw',
        height: '100vh',
        backgroundColor: '#ffffff',
        padding: 24
      }}
    >
      <FOHNavigationMenu
        logoutLabel={
          hasCandidateProfile || hasEmployerProfile
            ? t('TopNavFeature:logout')
            : ''
        }
        logout={async () => {
          removeAuth();
          props.setBurgerOpen(false);
          setLogoutWipeCacheClicked(true);
        }}
        sections={
          isHome
            ? getHomeRoutes({ t })
            : hasEmployerProfile && !hasPendingEmployerProfileRoute
            ? getEmployerMenu()
            : hasCandidateProfile
            ? getCandidateMenu({ t, hasInterviewRequest })
            : []
        }
        onPressRoute={onPressNavigationItem}
      >
        {isHome && (
          <>
            <FOHSpace />
            <FOHButton
              title={t('HomePageFeature:startHiringForFree')}
              onPress={() => {
                props.setBurgerOpen(false);
                navigateTo(REGISTER_URL);
              }}
              fullWidth={true}
            />
            <FOHSpace />
            <FOHInverseButton
              title={t('DemoScreenFeature:viewDemo')}
              color={FOHColors.BLACK}
              onPress={() => {
                props.setBurgerOpen(false);
                navigateTo(DEMO);
              }}
              fullWidth={true}
            />
          </>
        )}
      </FOHNavigationMenu>
    </FOHView>
  );
};
