import React, { useState, useEffect } from 'react';

import { path } from 'ramda';
import { useTranslation } from 'react-i18next';

import { EXTERNAL_APPLICATION_HEIGHTTODECREASE } from '../../constants';
import {
  FOHFixedHeightScrollView,
  FOHView,
  FOHSpace,
  FOHExternalApplicationSummaryForm,
  FOHFormFlowButtons,
  FOHExternalApplicationHeader
} from '../../components';
import {
  selectExternalApplicationTagItem,
  useIsMobile,
  shuffleArray,
  handle
} from '../../utils';
import { MAX_SCREEN_WIDTH } from '../../components/constants';

import { useCandidateProfileMutation } from '../CandidateProfile/useCandidateProfileMutation';

const ExternalApplicationSummary = props => {
  const { isMobile } = useIsMobile();
  const { t } = useTranslation('ExternalApplicationFeature');
  const { me, updateCandidate } = useCandidateProfileMutation(props);

  const [message, setMessage] = useState('');
  const [selected, setSelected] = useState(['']);
  const [error, setError] = useState();
  const [suggestionOptions, setSuggestionOptions] = useState(
    [
      t('summaryOption1'),
      t('summaryOption2'),
      t('summaryOption3'),
      t('summaryOption4'),
      t('summaryOption5'),
      t('summaryOption6'),
      t('summaryOption7'),
      t('summaryOption8'),
      t('summaryOption9'),
      t('summaryOption10')
    ].map(option => ({ label: option, value: option }))
  );

  useEffect(() => {
    if (me) {
      setMessage(path(['candidateProfile', 'personalSummary'], me));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path(['candidateProfile', 'personalSummary'], me)]);

  useEffect(() => {
    setSuggestionOptions(shuffleArray(suggestionOptions));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeExternalApplicationMessage = text => {
    setMessage(text);
    const newSelection = suggestionOptions
      .filter(item => text.toLowerCase().includes(item.value.toLowerCase()))
      .map(item => item.value);
    setSelected(newSelection);
  };

  const handleSubmit = async () => {
    const [resp] = await handle(
      updateCandidate({
        variables: {
          personalSummary: message
        }
      })
    );

    const err = path(['data', 'mutateCandidate', 'errors'], resp);

    if (resp || !err) props.onSubmit();
    else setError(err);
  };

  return (
    <>
      <FOHView style={{ height: '100%' }}>
        <FOHFixedHeightScrollView
          isMobile={isMobile}
          heightToDecrease={EXTERNAL_APPLICATION_HEIGHTTODECREASE}
        >
          <FOHView
            style={{
              width: '100%',
              maxWidth: isMobile ? '100%' : MAX_SCREEN_WIDTH,
              paddingHorizontal: 20
            }}
          >
            <FOHExternalApplicationHeader
              applicationTitle={t('applicationTitle')}
              applicationDetail={t('applicationDetail')}
              applicationTotalNumber={props.totalStepNumber}
              applicationNumber={props.stepIndex}
            />
          </FOHView>
          <FOHSpace />
          <FOHExternalApplicationSummaryForm
            message={message}
            messagePlaceholder={t(
              'PersonalSummaryFeature:personalSummaryPlaceholder'
            )}
            handleMessage={value => changeExternalApplicationMessage(value)}
            applicationMessageTitle={t('summaryFormTitle')}
            applicationMessageDetail={t('summaryFormDetail')}
            suggestionText={t('summaryFormSuggestionText')}
            selectedSuggessions={selected}
            suggestionOptions={suggestionOptions}
            selectItem={val =>
              selectExternalApplicationTagItem(
                val,
                setSelected,
                selected,
                message,
                setMessage
              )
            }
            submitLabel="Submit"
            handleSubmit={handleSubmit}
            // props.error.message
            error={error}
            mobile={isMobile}
          />
        </FOHFixedHeightScrollView>
      </FOHView>
      <FOHFormFlowButtons
        isMobile={isMobile}
        nextButtonlabel={t('common:next')}
        previousButtonLabel={t('common:previous')}
        onPressPreviousButton={props.previousStep}
        onPressNextButton={handleSubmit}
        isNextDisabled={!message}
      />
      {!isMobile && (
        <>
          <FOHSpace />
          <FOHSpace />
        </>
      )}
    </>
  );
};

export default ExternalApplicationSummary;
