import React from 'react';

import { length } from 'ramda';

import {
  FOHAccordian,
  FOHAccordianProps,
  FOHSpace,
  FOHView
} from '../../ingredients';

import { FOHSelectionTableProps } from './FOHSelectionTable';
import { FOHSelectCheckboxTable } from './FOHSelectCheckboxTable';

export interface FOHAccordianSelectTableProps
  extends FOHSelectionTableProps,
    FOHAccordianProps {
  children?: any;
}

export const FOHAccordianSelectTable = (
  props: FOHAccordianSelectTableProps
) => {
  const itemLength = length(props.selected.filter(item => !!item));
  return (
    <FOHAccordian
      {...props}
      width={props.width}
      title={`${props.title} ${itemLength > 0 ? `(${itemLength})` : ''}`}
    >
      <FOHView style={{ padding: 12, flex: 1 }}>
        <FOHSpace />
        <FOHSelectCheckboxTable
          {...props}
          style={{ flexWrap: 'wrap', flexDirection: 'row' }}
          cellStyle={{ minWidth: 220 }}
        />
      </FOHView>
    </FOHAccordian>
  );
};
