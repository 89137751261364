import React from 'react';

import quote from '../../../images/quote_ic.svg';

import { FOHImage } from '../../ingredients';

export const FOHQuoteIcon = (props: any) => (
  <FOHImage
    source={{ uri: quote }}
    style={
      props && !!props.style
        ? { width: 28, height: 28, ...props.style }
        : { width: 28, height: 28 }
    }
  />
);
