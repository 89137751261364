import React from 'react';

import { View } from 'react-native';
import { length } from 'ramda';

import {
  FOHAltSectionLabel,
  FOHLabel,
  FOHDivider,
  FOHButton,
  FOHAnswerBadgeProps
} from '../../ingredients';
import { FOHOtherJobsCard } from '../../templates';

import { FOHHourlyRates, FOHHourlyRatesProps } from '../tables/FOHHourlyRates';
import { FOHSmallJobPost } from '../cells/FOHJobCell';
import { FOHAnswersTable } from '../tables/FOHAnswersTable';

import { FOHLocationHeaderProps, FOHLocationHeader } from './FOHLocationHeader';

export interface FOHLocationDetailsProps
  extends FOHHourlyRatesProps,
    FOHLocationHeaderProps {
  answersLabel?: string;
  answers?: Array<FOHAnswerBadgeProps>;
  summaryTitle: string;
  summary: string;

  jobs: Array<FOHSmallJobPost>;
  jobSectionTitle: string;
  onJobPress: () => void;
  a: any;
  jobCTAText: string;

  claimBusinessLabel?: string;
  claimBusiness?: () => void;
  vertical?: boolean;
}

export const FOHLocationDetails = (props: FOHLocationDetailsProps) => {
  return (
    <View>
      <FOHLocationHeader
        id={props.id}
        name={props.name}
        tags={props.tags}
        formattedAddress={props.formattedAddress}
        vertical={props.vertical}
        favoriteLabel={props.favoriteLabel}
        hideLabel={props.hideLabel}
        isFavorited={props.isFavorited}
        isHidden={props.isHidden}
        pressFavorite={props.pressFavorite}
        pressHide={props.pressHide}
        headerFontSize={32}
        followerCountLabel={props.followerCountLabel}
      />
      {props.vertical && props.claimBusiness && !!props.claimBusinessLabel ? (
        <>
          <View style={{ paddingTop: 8 }} />
          <FOHButton
            style={{ minWidth: '100%' }}
            onPress={props.claimBusiness}
            title={props.claimBusinessLabel}
          />
        </>
      ) : (
        <></>
      )}
      {length(props.positionRates) > 0 ? (
        <>
          <View style={{ padding: 12 }}></View>
          <FOHDivider />
          <View style={{ padding: 12 }}></View>
          <FOHHourlyRates
            hourlyRatesTitle={props.hourlyRatesTitle}
            positionRates={props.positionRates}
          />
          <FOHDivider />
        </>
      ) : (
        <></>
      )}
      <View style={{ padding: 12 }}></View>
      {props.summary !== '' && (
        <>
          <FOHDivider />
          <View style={{ padding: 12 }}></View>
          <FOHAltSectionLabel>{props.summaryTitle}</FOHAltSectionLabel>
          <View style={{ padding: 4 }}></View>
          <FOHLabel>{props.summary}</FOHLabel>
          <View style={{ padding: 24 }}></View>
        </>
      )}
      <FOHAnswersTable
        vertical={props.vertical}
        answers={props.answers || []}
        answersLabel={props.answersLabel || ''}
      />
      {length(props.jobs) > 0 ? (
        <FOHOtherJobsCard
          noBorder={true}
          title={props.jobSectionTitle}
          jobs={props.jobs}
          a={props.a}
          onPress={props.onJobPress}
          ctaText={props.jobCTAText}
        />
      ) : (
        <></>
      )}
    </View>
  );
};
