import React, { useState } from 'react';

import { prop, defaultTo, path, toLower, groupBy, length } from 'ramda';
import { useTranslation } from 'react-i18next';

import {
  FOHColors,
  FOHPlanState,
  FOHCurrentPlan,
  FOHInverseButton,
  FOHView,
  FOHPriceBlock,
  FOHSpace,
  FOHLabel,
  FOHSpaceSmall,
  FOHDivider
} from '../../components';
import { handleMutation, toDate, useIsMobile } from '../../utils';
import {
  BILLING,
  EMPLOYER_SETTINGS_MENU,
  PAYASYOUGO,
  PLAN,
  UPGRADE,
  getAppBaseUrl
} from '../../constants';
import {
  CalculatePriceDocument,
  useReactivateSubscriptionMutationMutation
} from '../../graphql/generated';
import { useNavigation } from '../../utils/navigation';

import { useCurrentLocationContext } from '../Locations/useCurrentLocationContext';

import { usePaymentPlan, formatPricing } from './usePaymentPlan';
import { PositionPlanCell } from './PositionPlanCell';
import { useCheckout } from './useCheckout';
import { withStripe } from './withStripe';
import { getPlanMeta } from './PricingBox';

const LocationPlanSettings = props => {
  const { setOpenCancellationReason, setSelectedPositionSubscription } = props;

  const { navigateTo } = useNavigation();
  const [monthly] = useState(true);

  const { isMobile } = useIsMobile();
  const { t } = useTranslation('PricingScreenFeature');
  const { locationFilter, myLocations, currentLocation } =
    useCurrentLocationContext({
      props
    });

  // const { redirectToCustomerPortal } = useCustomerPortal();
  const { redirectToStripeCheckout } = useCheckout(props);
  const [reactivateSubscription] = useReactivateSubscriptionMutationMutation();

  const {
    locationChargeAmount,
    startedOn,
    notSubscribedPrice,
    subscribedPrice,
    planName,
    subscribed,
    singlePrice,
    subscribedPositions,
    unpaidPositions,
    currentPlanQuery
  } = usePaymentPlan({
    locationFilter,
    currentPlanQ: true,
    positionsQ: true,
    notSubscribedQ: true,
    subscribedQ: true,
    singleQ: true
  });

  const currentPlan = defaultTo(
    {},
    path(['data', 'locationById'], currentPlanQuery)
  );

  const usersCanCancel = defaultTo(
    true,
    path(
      ['data', 'locationById', 'employerProfile', 'usersCanCancel'],
      currentPlanQuery
    )
  );

  const subscriptionWillEndOn = defaultTo(
    null,
    path(['payment', 'subscriptionWillEndOn'], currentPlan)
  );
  const hasUnlimitedSubscription =
    defaultTo(false, path(['payment', 'subscription'], currentPlan)) !== null;

  const chosenCycleIsMonthly = true;

  const prices = formatPricing({
    notSubscribedPrice,
    subscribedPrice,
    // Show subscribed even if all positions are expiring
    subscribed,
    monthly: chosenCycleIsMonthly
  });

  const planMeta = getPlanMeta({ t, monthly: chosenCycleIsMonthly });

  const currentCost = !subscribed
    ? '$0'
    : path([toLower(planName || ''), 'total'], prices);

  const subscriptions = groupBy(
    path(['payment', 'stripeSubscription']),
    defaultTo([], subscribedPositions)
  );

  // subscriptions you can reactivate
  const pausedSubscriptions = groupBy(
    path(['id']),
    defaultTo([], unpaidPositions)
  );

  const priceDetail = `${
    chosenCycleIsMonthly
      ? defaultTo(
          '',
          path(['monthlyPayAsYouGo', 'displayPerMonth'], singlePrice)
        )
      : defaultTo('', path(['annualPayAsYouGo', 'total'], singlePrice))
  } per active position `.replace('.00', '');

  const totalCost =
    locationChargeAmount &&
    (planName === FOHPlanState.unlimited ||
      planName === FOHPlanState.enterprise)
      ? !usersCanCancel
        ? planMeta[2].customPrice
        : locationChargeAmount
      : currentCost;

  const paygPrice = monthly
    ? defaultTo(
        '',
        path(['monthlyPayAsYouGo', 'displayPerMonth'], singlePrice)
      ).replace('.00', '')
    : path(['annualPayAsYouGo', 'displayPerMonth'], singlePrice);

  return (
    <>
      {!!subscribed && (
        <FOHView style={{ paddingBottom: 10, zIndex: 2 }}>
          <FOHCurrentPlan
            plan={
              planName === FOHPlanState.enterprise
                ? FOHPlanState.unlimited
                : planName
            }
            usersCanCancel={usersCanCancel}
            // location charge amount || payasyougo
            total={totalCost}
            totalLabel={totalCost ? t('total') : ''}
            cycle={
              totalCost && typeof totalCost === 'number'
                ? chosenCycleIsMonthly === true
                  ? t('per-month')
                  : t('per-year')
                : ''
            }
            cycleLongText={
              planName === FOHPlanState.payAsYouGo
                ? chosenCycleIsMonthly
                  ? t('cycleLongTextPayAsYouGoMonthly')
                  : t('cycleLongTextPayAsYouGoAnnual')
                : chosenCycleIsMonthly
                ? t('cycleLongTextUnlimitedMonthly')
                : t('cycleLongTextUnlimitedAnnual')
            } //billed monthly || per position billed monthly
            // unlimited
            unlimited={t('unlimited')}
            billingStartedLabel={t('billingStarted')}
            billingStarted={toDate(startedOn, 'M/dd/yy')}
            payAsYouGo={t('payAsYouGo')}
            costPerPosition={`*${priceDetail}`}
            activePositionsLabel={t('activePositions')}
            estimatedCostLabel={t('estimatedCost')}
            estimatedPayAsYouGo={defaultTo(
              '',
              path(['monthlyPayAsYouGo', 'displayPerMonth'], notSubscribedPrice)
            ).replace('.00', '')}
            estimatedUnlimited={defaultTo(
              '',
              path(['monthlyUnlimited', 'displayPerMonth'], notSubscribedPrice)
            ).replace('.00', '')}
            billedMonthlyIn={t('billedMonthlyIn')}
            pricePerPositionDetail={t('pricePerPositionDetail')}
            unlimitedPriceDetail={t('unlimitedPriceDetail')}
            orLabel={t('or')}
            upgradeLabel={t('upgrade')}
            onPressUpgrade={() => {
              navigateTo(
                `${EMPLOYER_SETTINGS_MENU}${BILLING}${PLAN}${UPGRADE}`,
                {
                  state: { plan: toLower(FOHPlanState.unlimited), monthly }
                }
              );
            }}
            // Custom Plan
            enterprise={t('enterprise')}
            aboutEnterprise={t('aboutEnterprise')}
            chatWithManager={t('chatWithManager')}
            onPressChatWithManage={() => {
              props.setOpenSales(true);
            }}
            chatWithManagerButton={() => (
              <FOHInverseButton
                style={{
                  width: 155
                }}
                color={FOHColors.BLACK}
                onPress={() =>
                  window?.open('https://help.fohandboh.com', '_blank')
                }
                title={t('chatWithManager')}
              />
            )}
            showCancelSubscriptionBtn={
              !!hasUnlimitedSubscription && usersCanCancel
            }
            cancelUnlimitedSubscriptionLabel={
              subscriptionWillEndOn
                ? t('PricingScreenFeature:reactivateSubscription')
                : t('common:cancel')
            }
            onPressUnlimitedSubscriptionCancel={async () => {
              if (subscriptionWillEndOn) {
                const refetchQueries = [
                  {
                    query: CalculatePriceDocument,
                    variables: {
                      location: locationFilter,
                      paymentStatus: 'notsubscribed'
                    }
                  },
                  {
                    query: CalculatePriceDocument,
                    variables: {
                      location: locationFilter,
                      paymentStatus: 'subscribed'
                    }
                  }
                ];

                await handleMutation(
                  reactivateSubscription({
                    variables: {
                      stripeSubscription: path(
                        ['payment', 'subscription', 'id'],
                        currentPlan
                      )
                    },
                    refetchQueries
                  })
                );
              } else {
                setOpenCancellationReason(true);
              }
            }}
            allPositionsLabel={t('allPositions')}
            allPositions={length(unpaidPositions) + length(subscribedPositions)}
            allLocationsLabel={t('allLocations')}
            allLocations={length(
              myLocations.filter(
                location => location.paymentStatus === 'SUBSCRIBED'
              )
            )}
          >
            {planName === FOHPlanState.payasyougo &&
              Object.keys(subscriptions).map((stripeSubscription, i, array) => {
                const positions = prop(stripeSubscription, subscriptions);
                return (
                  <>
                    <FOHSpace />
                    <FOHDivider />
                    <FOHSpace />
                    <FOHLabel
                      heavy
                      style={{
                        textTransform: 'uppercase',
                        color: FOHColors.GRAYSCALE_900
                      }}
                    >
                      {t('activePositions')}
                    </FOHLabel>
                    <FOHSpace />
                    <FOHView
                      style={{ zIndex: 50 + length(array) - i }}
                      key={`hiring-${stripeSubscription}`}
                    >
                      <PositionPlanCell
                        paygPrice={paygPrice}
                        positions={positions}
                        location={locationFilter}
                        onCancel={positionId => {
                          setSelectedPositionSubscription({
                            positionId:
                              positionId ?? path([0, 'id'], positions),
                            stripeSubscription
                          });
                          setOpenCancellationReason(true);
                        }}
                        payment={path([0, 'payment'], positions)}
                        stripeSubscription={stripeSubscription}
                      />
                    </FOHView>
                  </>
                );
              })}
            {planName === FOHPlanState.payasyougo &&
            Object.keys(pausedSubscriptions).length > 0 ? (
              <>
                <FOHSpaceSmall />
                <FOHLabel
                  heavy
                  style={{
                    textTransform: 'uppercase',
                    color: FOHColors.GRAYSCALE_900
                  }}
                >
                  {t('inactivePositions')}
                </FOHLabel>
                <FOHSpace />
                {Object.keys(pausedSubscriptions).map(
                  (stripeSubscription, i, array) => {
                    const positions = defaultTo(
                      [],
                      prop(stripeSubscription, pausedSubscriptions)
                    );

                    const paymentExists = positions[i]?.payment ? true : false;
                    const stripeSubscriptionExists = positions[i]?.payment
                      ?.stripeSubscription
                      ? true
                      : false;

                    return (
                      <FOHView
                        // Decrementing zIndex so menus won't overlap
                        style={{ zIndex: 25 + length(array) - i }}
                        key={`paused-${stripeSubscription}`}
                      >
                        <PositionPlanCell
                          paygPrice={paygPrice}
                          positions={positions}
                          location={locationFilter}
                          payment={
                            paymentExists
                              ? path([0, 'payment'], positions)
                              : null
                          }
                          stripeSubscription={
                            stripeSubscriptionExists
                              ? stripeSubscription
                              : undefined
                          }
                        />
                      </FOHView>
                    );
                  }
                )}
              </>
            ) : (
              <></>
            )}
          </FOHCurrentPlan>
        </FOHView>
      )}
      <FOHView
        style={{
          flexDirection: isMobile ? 'column' : 'row',
          width: '100%',
          flexWrap: 'wrap'
        }}
      >
        <FOHPriceBlock
          {...planMeta[0]}
          detail=""
          asterisk={true}
          price={paygPrice}
          planText={t('payPerActivePosition', { price: paygPrice })}
          discountLabel={
            chosenCycleIsMonthly ? undefined : t('annualPAYGDiscount')
          }
          upgradeNow={t('upgradeNow')}
          selectedColor="#000000"
          selected={subscribed && planName === FOHPlanState.payasyougo}
          yourCurrentPlan={t('yourCurrentPlan')}
          onPressUpgradeNow={
            subscribed && planName === FOHPlanState.payasyougo
              ? undefined
              : () => {
                  subscribed
                    ? props.setOpenSales(true)
                    : navigateTo(
                        `${EMPLOYER_SETTINGS_MENU}${BILLING}${PLAN}${PAYASYOUGO}`,
                        {
                          state: {
                            plan: toLower(FOHPlanState.payasyougo),
                            monthly
                          }
                        }
                      );
                }
          }
        />
        {isMobile ? <FOHSpaceSmall /> : <FOHSpace />}
        <FOHPriceBlock
          {...planMeta[1]}
          detail=""
          price={path(['unlimited', 'displayPerMonth'], prices)}
          discountLabel={
            chosenCycleIsMonthly ? undefined : t('annualUnlimitedDiscount')
          }
          upgradeNow={t('upgradeNow')}
          selected={
            subscribed &&
            (planName === FOHPlanState.unlimited ||
              planName === FOHPlanState.enterprise) &&
            usersCanCancel
          }
          selectedColor={'#000000'}
          yourCurrentPlan={t('yourCurrentPlan')}
          onPressUpgradeNow={
            subscribed &&
            (planName === FOHPlanState.unlimited ||
              planName === FOHPlanState.enterprise) &&
            usersCanCancel
              ? undefined // no downgrading yet
              : () => {
                  // allow upgrading to unlimited always
                  redirectToStripeCheckout({
                    url: `${getAppBaseUrl()}${EMPLOYER_SETTINGS_MENU}${BILLING}${PLAN}`,
                    locations: [prop('rowId', currentLocation)],
                    cycle: monthly ? 'monthly' : 'annual',
                    plan: 'unlimited'
                  });
                }
          }
        />
        {isMobile ? <FOHSpaceSmall /> : <FOHSpace />}
        <FOHPriceBlock
          {...planMeta[2]}
          detail=""
          chatWithSales={t('chatWithSales')}
          selected={
            subscribed &&
            (planName === FOHPlanState.unlimited ||
              planName === FOHPlanState.enterprise) &&
            !usersCanCancel
          }
          selectedColor={'#000000'}
          yourCurrentPlan={t('yourCurrentPlan')}
          onPressChatWithSales={() =>
            (window.location.href = 'mailto:sales@fohandboh.com')
          }
        />
      </FOHView>
    </>
  );
};

export const LocationPlan = withStripe(LocationPlanSettings);
