import React from 'react';

import {
  FOHHeaderText,
  Screen,
  FOHDetailText,
  FOHButton,
  FOHInverseButton,
  FOHLink,
  FOHView,
  FOHRightArrow,
  FOHSpaceSmall,
  FOHColors
} from '../../ingredients';
import { FOHAddTeamMate, FOHForm } from '../../recipes';

export interface FOHEmployerInviteTeamProps {
  title: string;
  description: string;
  skipText: string;
  skipLabel: string;
  skip?: () => void;
  largeHeader?: boolean;
  addTeamMate: () => void;
  team: Array<FOHTeamMember>;
  updateTeam: (memeber: FOHTeamMember) => void;
  addTeamMateLabel: string;
  next?: () => void;
  nextLabel: string;
  emailPlaceholder: string;
  positionNamePlaceholder: string;
  disableNext?: boolean;
}

export interface FOHTeamMember {
  id: string;
  email: string;
  positionName: string;
}

export const FOHEmployerInviteTeam = (props: FOHEmployerInviteTeamProps) => (
  <Screen>
    <FOHForm disabledSubmit={props.disableNext} handleSubmit={props.next}>
      <FOHView
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: 60,
          width: '100%',
          padding: 16
        }}
      >
        {!!props.skip && (
          <FOHView
            style={{
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <FOHLink
              text={props.skipText}
              linkText={props.skipLabel}
              onPress={props.skip}
            />
            <FOHSpaceSmall />
            <FOHRightArrow style={{ tintColor: FOHColors.BLUE }} />
          </FOHView>
        )}
        <FOHView style={{ paddingTop: 16 }} />
        <FOHHeaderText style={{ fontSize: props.largeHeader ? 30 : 20 }}>
          {props.title}
        </FOHHeaderText>
        <FOHView
          style={{
            paddingTop: 8,
            justifyContent: 'center',
            width: '100%',
            maxWidth: 400
          }}
        >
          <FOHDetailText>{props.description}</FOHDetailText>
          <FOHView style={{ paddingTop: 24 }} />
          {props.team.map(member => (
            <React.Fragment key={`add-teammate-${member.id}`}>
              <FOHAddTeamMate
                email={member.email}
                key={member.id}
                setEmail={(email: string) => {
                  props.updateTeam({
                    ...member,
                    email
                  });
                }}
                emailPlaceholder={props.emailPlaceholder}
                positionName={member.positionName}
                setPositionName={(positionName: string) => {
                  props.updateTeam({
                    ...member,
                    positionName
                  });
                }}
                positionNamePlaceholder={props.positionNamePlaceholder}
              />
              <FOHView style={{ paddingTop: 24 }} />
            </React.Fragment>
          ))}

          <FOHInverseButton
            onPress={props.addTeamMate}
            title={props.addTeamMateLabel}
            style={{
              maxWidth: '100%'
            }}
          />
          <FOHView style={{ paddingTop: 16 }} />
          {props.next && !!props.nextLabel && (
            <FOHButton
              onPress={props.next}
              disabled={props.disableNext}
              title={
                !props.team.some(teamMate => teamMate.email === '')
                  ? props.nextLabel
                  : props.skipLabel
              }
              style={{ maxWidth: '100%' }}
            />
          )}
        </FOHView>
      </FOHView>
    </FOHForm>
  </Screen>
);
