import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { path, prop, defaultTo } from 'ramda';
import { useParams } from 'react-router-dom';

import {
  unwrapEdgesAt,
  useIsMobile,
  toDateTime,
  isScrollCloseToBottom
} from '../../utils';
import {
  DISCOVER,
  HOME_PAGE,
  JOBS,
  POSITIONS_SORT_BY_OPTIONS,
  RESTAURANT_JOBS
} from '../../constants';
import { useGetMyApplicationsQuery } from '../../graphql/generated';
import {
  FOHApplicationListCell,
  FOHAppliedStatusType,
  FOHCandidateResultFilter,
  FOHFilterIcon,
  FOHInverseButton,
  FOHScrollView,
  FOHView,
  FullWidthCard
} from '../../components';
import { useNavigation } from '../../utils/navigation';
import { FOH_INPUT_HEIGHT } from '../../components/constants';

import { fetchMorePositions } from '../EmployerPositions/fetchMorePositions';
import { useCandidateProfileMutation } from '../CandidateProfile/useCandidateProfileMutation';
import { useDirectMessage } from '../Chat/useDirectMessage';

import { HomeScrollFooter } from './HomeScrollFooter';
import { PositionTypeAheadSearchBar } from './PositionTypeAheadSearchBar';
import { PositionFilterDrawer } from './PositionFilterDrawer';

const FilterIcon = () => <FOHFilterIcon active={true} />;
const applicationStatusPath = prop('status');

const getApplicationStatus = application =>
  defaultTo(
    undefined,
    prop(applicationStatusPath(application), {
      [FOHAppliedStatusType.PENDING]: FOHAppliedStatusType.PENDING,
      [FOHAppliedStatusType.UNDER_REVIEW]: FOHAppliedStatusType.UNDER_REVIEW
    })
  );

const getApplicationStatusLabel = (t, application) =>
  defaultTo(
    undefined,
    prop(applicationStatusPath(application), {
      [FOHAppliedStatusType.PENDING]: t('common:application.PENDING'),
      [FOHAppliedStatusType.UNDER_REVIEW]: t('common:application.UNDER_REVIEW')
    })
  );

export const CandidateApplications = props => {
  const { tab } = useParams();
  const { t } = useTranslation('CandidateHomeFeature');
  const { navigateTo } = useNavigation();

  const { isMobile } = useIsMobile();
  const { me } = useCandidateProfileMutation();

  const { goToDirectMessageWith } = useDirectMessage({
    ...props,
    sendbirdAccessToken: prop('sendbirdAccessToken', me)
  });

  const [openFilters, setOpenFilters] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [workingPreferences, setWorkingPreferences] = useState([]);
  const [sortBy, setSortBy] = useState('latest');

  const [loadingMore, setLoadingMore] = useState(false);

  const searchTerms = selectedItems
    .map(item => prop('value', item))
    .filter(item => !!item);

  const getMyApplications = useGetMyApplicationsQuery({
    variables: {
      searchTerms: searchTerms
    }
  });

  const applications = unwrapEdgesAt(
    ['data', 'myApplications', 'edges'],
    getMyApplications
  );

  useEffect(() => {
    if (
      props.scrolledToBottom &&
      !getMyApplications.loading &&
      isMobile &&
      !loadingMore
    ) {
      setLoadingMore(true);
      fetchMorePositions(getMyApplications, { searchTerms }).then(() => {
        setLoadingMore(false);
        props.setScrolledToBottom(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.scrolledToBottom]);

  return (
    <>
      <FOHView
        style={{
          flexDirection: isMobile ? 'column-reverse' : 'column'
        }}
      >
        <FullWidthCard
          style={{
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          <FOHView
            style={{
              padding: isMobile ? 10 : 16,
              flexDirection: 'row',
              flexWrap: 'wrap'
            }}
          >
            <FOHView
              style={{
                flex: 3,
                marginRight: 10,
                minWidth: 300,
                paddingBottom: isMobile ? 10 : 0,
                minHeight: 52,
                zIndex: 99
              }}
            >
              <PositionTypeAheadSearchBar
                {...props}
                tab="applications"
                setSelectedItems={setSelectedItems}
                selectedItems={selectedItems}
                isMobile={isMobile}
              />
            </FOHView>
            {/* TODO: Employer logic for seen application */}
            {/* <FOHView
            style={{ flex: 1, marginRight: 24, minWidth: 180, minHeight: 52 }}
          >
            <FOHSelect
              selectedValue={applicationStatusFilter}
              placeholder={t('applicationStatus')}
              onValueChange={setApplicationStatusFilter}
              neverEmpty={true}
            >
              <FOHSelectItem label={t('showAll')} value={'showAll'} />
            </FOHSelect>
          </FOHView> */}
            <FOHView style={{ flex: 1, minWidth: 250 }}>
              <FOHInverseButton
                color="#000000"
                title={t('moreFilters')}
                icon={FilterIcon}
                onPress={() => setOpenFilters(true)}
                centerIcon={true}
                style={{
                  width: isMobile ? '100%' : 250,
                  height: FOH_INPUT_HEIGHT
                }}
              />
            </FOHView>
          </FOHView>
        </FullWidthCard>
        <FOHCandidateResultFilter
          totalCount={
            getMyApplications.loading
              ? '...'
              : path(
                  ['data', 'myApplications', 'totalCount'],
                  getMyApplications
                )
          }
          countHeader={t('applicationCountHeader')}
          showBestMatch={false}
          sortByLabel={t('sortByLabel')}
          sortBy={sortBy}
          setSortBy={setSortBy}
          sortByOptions={POSITIONS_SORT_BY_OPTIONS(t)}
        />
      </FOHView>
      <FOHScrollView
        scrollEventThrottle={200}
        onScroll={async ({ nativeEvent }) => {
          if (isScrollCloseToBottom(nativeEvent) && !loadingMore && !isMobile) {
            setLoadingMore(true);
            await fetchMorePositions(getMyApplications, { searchTerms });
            setLoadingMore(false);
          }
        }}
        style={{
          // no nested scrolling on mobile
          height: openFilters ? '40vh' : isMobile ? '100%' : '60vh',
          zIndex: -1
        }}
      >
        <>
          {getMyApplications.loading ? (
            <></>
          ) : (
            applications.map(application => (
              <FOHApplicationListCell
                mobile={isMobile}
                key={path(['position', 'id'], application)}
                positionName={path(['position', 'positionName'], application)}
                positionSlug={path(['position', 'slug'], application)}
                positionId={path(['position', 'id'], application)}
                hiringManagerName={path(
                  ['position', 'createdBy', 'firstName'],
                  application
                )}
                hiringManagerImage={path(
                  ['position', 'createdBy', 'profileImage'],
                  application
                )}
                hiringManagerPosition={t('positionOwner')} // Position Owner
                hiringManagerDetailText="" // It usually takes 1-2 weeks to hear back.
                // job details
                onPressJobDetails={slug => navigateTo(`${JOBS}/${slug}`)}
                // Apply
                appliedOnDate={
                  path(['position', 'appliedAt'], application)
                    ? toDateTime(
                        new Date(
                          // replace because safari does not like this field with a T
                          path(['position', 'appliedAt'], application).replace(
                            / /g,
                            'T'
                          )
                        ).toISOString()
                      )
                    : ''
                }
                appliedLabel={t('appliedOn')}
                buttonCTALabel={t('jobDetailsLabel')}
                onPressCTA={slug => {
                  navigateTo(`${JOBS}/${slug}`);
                }}
                buttonCTADisabled={true}
                // TODO: logic for employers that have seen an application to determine if under review, pending, or expired
                applicationStatusType={getApplicationStatus(application)}
                applicationStatus={getApplicationStatusLabel(t, application)}
                applicationStatusLabel={t('applicationStatusLabel')}
                // location
                locationId={path(['position', 'location', 'id'], application)}
                locationName={path(
                  ['position', 'location', 'name'],
                  application
                )}
                locationSlug={path(
                  ['position', 'location', 'slug'],
                  application
                )}
                onPressLocation={slug =>
                  isMobile
                    ? navigateTo(`${RESTAURANT_JOBS}/${slug}`)
                    : props.openLocationDetailsModal(slug)
                }
                locationImage={path(
                  ['position', 'location', 'image'],
                  application
                )}
                onPressMessage={async () => {
                  await goToDirectMessageWith({
                    uuids: [
                      path(['position', 'createdBy', 'uuid'], application)
                    ],
                    location: path(['position', 'location', 'id'], application),
                    position: path(['position', 'id'], application),
                    application: path(['id'], application)
                  });
                }}
                messageLabel={t('chat')}
              />
            ))
          )}
        </>
        <HomeScrollFooter
          loading={getMyApplications.loading || loadingMore}
          hasNextPage={path(
            ['data', 'myApplications', 'pageInfo', 'hasNextPage'],
            getMyApplications
          )}
          noMorePositionsLabel={t('noMoreApplications')}
          noMorePositionsDetailLabel={t('applyByYourOwn')}
          noMoreSubmitBtnLabel={t('browseMoreJobs')}
          onSubmitPress={() => {
            if (tab !== 'discover') {
              navigateTo(`${HOME_PAGE}${DISCOVER}`);
            }
          }}
        />
      </FOHScrollView>
      <PositionFilterDrawer
        workingPreferences={workingPreferences}
        setWorkingPreferences={setWorkingPreferences}
        openFilters={openFilters}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        setOpenFilters={setOpenFilters}
      />
    </>
  );
};
