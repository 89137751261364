import React from 'react';

import styled from 'styled-components/native';
import { View } from 'react-native';

import {
  FOHSmallDetailLabel,
  FOHLabel,
  FOHSpaceSmallest,
  FOHBullet
} from '../../ingredients';

export interface FOHRecommendationCellProps {
  relation?: string;
  endorser: string;
  recommendation: string;
  date: string;
}

export const FOHRecommendationCell = (props: FOHRecommendationCellProps) => (
  <View style={{ width: '100%' }}>
    <Row>
      <Field>
        <FOHBullet>
          <FOHLabel heavy>{props.endorser}</FOHLabel>
        </FOHBullet>
        <View style={{ paddingLeft: 14 }}>
          <FOHSpaceSmallest />
          <FOHSmallDetailLabel>{props.relation}</FOHSmallDetailLabel>
          <FOHSpaceSmallest />
          <FOHLabel style={{ width: '100%' }}>{props.recommendation}</FOHLabel>
        </View>
      </Field>
      <RightBox>
        <FOHSmallDetailLabel>{props.date}</FOHSmallDetailLabel>
      </RightBox>
    </Row>
  </View>
);

const Row = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const RightBox = styled(View)`
  word-break: break-word;
  justify-content: left;
  top: 0px;
  right: 0px;
  position: absolute;
`;

const Field = styled(View)`
  justify-content: left;
  width: 75%;
`;
