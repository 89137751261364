import React, { useRef } from 'react';

import { includes, path, defaultTo, prop, compose } from 'ramda';
import { useTranslation } from 'react-i18next';

import {
  FOHView,
  FOHBannerType,
  FileType,
  FOHUploadResumeScreen
} from '../../components';
import { parseFileNameFromS3Url } from '../../utils';

import { useBanner } from '../Navigation/useBanner';
import { withReactiveModal } from '../Navigation';

import { useResumeMutations } from './useResumeMutations';
import { useCandidateProfileMutation } from './useCandidateProfileMutation';

export const ResumeUploadScreen = props => {
  const reader = new FileReader();
  const { setBanner } = useBanner();

  const { me } = useCandidateProfileMutation();
  const { t } = useTranslation('ResumeFeature');
  const {
    resumePreview,
    setResumePreview,
    deleteResume,
    fileToUpload,
    setFileToUpload
  } = useResumeMutations(
    () => {
      setBanner({
        bannerType: FOHBannerType.SUCCESS,
        bannerMessage: t('common:success')
      });
      props.isModal && props.close();
    },
    () => {
      setBanner({
        bannerType: FOHBannerType.DANGER,
        bannerMessage: `${t('common:failedToUpload')}`
      });
    }
  );

  const inputFile = useRef(null);

  reader.onload = e => {
    const blob = e.target.result;
    setResumePreview(blob);
  };

  return (
    <>
      <FOHUploadResumeScreen
        title={t('title')}
        fileTypeText={t('fileTypeText')}
        detailText={t('detailText')}
        submitLabel={t('submitLabel')}
        removeResume={async () => {
          await deleteResume({});
          setResumePreview('');
        }}
        fileType={
          includes('application/pdf', resumePreview) ||
          includes(
            '.pdf',
            defaultTo('', path(['candidateProfile', 'resume'], me))
          )
            ? FileType.pdf
            : FileType.image
        }
        // Dependency inject a PDF component since they are different across android / ios and web
        Pdf={PDFProps => (
          <FOHView style={{ ...PDFProps.style }}>
            <iframe
              title="resume-iframe"
              src={resumePreview || path(['candidateProfile', 'resume'], me)}
              style={PDFProps.style}
            />
          </FOHView>
        )}
        onPressResume={() => {
          const link =
            resumePreview || path(['candidateProfile', 'resume'], me);
          window && window.open(link);
        }}
        isModal={props.isModal}
        uploading={false}
        uploadResume={() => {
          inputFile && inputFile.current.click();
        }}
        resume={resumePreview || path(['candidateProfile', 'resume'], me)}
        fileName={
          prop('name', fileToUpload) ||
          parseFileNameFromS3Url(path(['candidateProfile', 'resume'], me))
        }
      />
      <input
        type="file"
        id="file"
        accept="application/pdf,application/msword,.doc,.docx,.txt,.rtf,.html,.pages"
        onChange={e => {
          const file = e.target.files[0];
          file && setFileToUpload(file);
          file && reader.readAsDataURL(file);
        }}
        ref={inputFile}
        style={{ display: 'none' }}
      />
    </>
  );
};

export const ResumeUploadModal = compose(withReactiveModal)(ResumeUploadScreen);
