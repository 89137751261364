import { useMemo } from 'react';

import { prop, path, defaultTo } from 'ramda';

import { unwrapEdgesAt } from '../../utils';
import {
  GetMyProfileCompletenessDocument,
  GetMyWorkHistoryDetailDocument,
  useDeleteWorkHistoryMutation,
  useGetMyWorkHistoryDetailQuery,
  useMutateWorkHistoryMutation
} from '../../graphql/generated';

export const useWorkHistoryMutation = () => {
  const [mutateWorkHistory] = useMutateWorkHistoryMutation();
  const [deleteWorkHistory] = useDeleteWorkHistoryMutation();
  const workHistoryQuery = useGetMyWorkHistoryDetailQuery({});
  const myWorkHistory = useMemo(() => {
    return unwrapEdgesAt(
      ['data', 'me', 'candidateProfile', 'workHistory', 'edges'],
      workHistoryQuery
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prop('data', workHistoryQuery)]);

  const mutateCandidateWorkHistory = async ({ variables }) => {
    return await mutateWorkHistory({
      variables,
      refetchQueries: [
        { query: GetMyProfileCompletenessDocument, variables: {} },
        { query: GetMyWorkHistoryDetailDocument, variables: {} }
      ]
    });
  };

  const deleteCandidateWorkHistory = async ({ id }) => {
    return await deleteWorkHistory({
      variables: {
        id
      },
      update: (store, { data }) => {
        // Read the data from our cache for this query if it exists
        try {
          const myWorkHistoryQuery = store.readQuery({
            query: GetMyWorkHistoryDetailDocument,
            variables: {}
          });

          const remainingHistory = defaultTo(
            [],
            path(
              ['me', 'candidateProfile', 'workHistory', 'edges'],
              myWorkHistoryQuery
            )
          )
            .filter(
              wh =>
                path(['node', 'id'], wh) !==
                path(['deleteWorkHistory', 'id'], data)
            )
            .map(wh => ({
              ...wh,
              __typename: 'CandidateWorkHistoryTypeEdge'
            }));

          const updatedWorkHistory = {
            me: {
              ...path(['me'], myWorkHistoryQuery),
              candidateProfile: {
                ...path(['me', 'candidateProfile'], myWorkHistoryQuery),
                workHistory: {
                  ...path(
                    ['me', 'candidateProfile', 'workHistory'],
                    myWorkHistoryQuery
                  ),
                  edges: remainingHistory,
                  __typename: 'CandidateWorkHistoryTypeConnection'
                },
                __typename: 'CandidateProfileType'
              },
              __typename: 'UserType'
            }
          };
          // Write our data back to the cache.
          store.writeQuery({
            query: GetMyWorkHistoryDetailDocument,
            variables: {},
            data: updatedWorkHistory
          });
        } catch (err) {
          // query doesn't exist yet we should refetch
          workHistoryQuery.refetch();
        }
      }
    });
  };

  return {
    deleteCandidateWorkHistory,
    mutateCandidateWorkHistory,
    myWorkHistory,
    myWorkHistoryQuery: workHistoryQuery
  };
};
