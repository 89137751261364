import React from 'react';

import notificationUnscheduledInterview from '../../../images/notification-unscheduled-interview.svg';

import { FOHImage } from '../../ingredients';

export interface FOHNotificationUnscheduledInterviewProps {}

export const FOHNotificationUnscheduledInterview = (
  props: FOHNotificationUnscheduledInterviewProps
) => (
  <FOHImage
    {...props}
    source={{ uri: notificationUnscheduledInterview }}
    style={{ width: 24, height: 24 }}
    testID={'FOHNotificationUnscheduledInterview'}
  />
);
