import React from 'react';

import { FOHMultiSelect, FOHSelectItemProps } from '../../recipes';
import {
  FOHButton,
  FOHTextButton,
  FOHSpace,
  FOHAltSectionLabel,
  FOHView,
  FOHRightDrawer,
  FOHColors
} from '../../ingredients';

interface FOHLocationFilterSidebarProps {
  filters: Array<FOHFilterType>;
  sortBy: Array<FOHFilterType>;
  filterTitle: string;
  filterDrawerOpen?: boolean;
  clearFiltersLabel: string;
  clearFilters: () => void;
  toggleFilterDrawerOpen?: () => void;
  applyFilters?: () => void;
  applyFiltersTitle?: string;
  sortBySectionTitle: string;
}

interface FOHFilterType {
  filters: Array<FOHSelectItemProps>;
  selectedFilters: Array<string>;
  selectFilter: (value: string) => void;
  onFilterSelectPress: () => void;
  filterPlaceholder: string;
  filterOpen: boolean;
}

export const FOHLocationFilterSidebar = (
  props: FOHLocationFilterSidebarProps
) => (
  <FOHRightDrawer
    title={props.filterTitle}
    open={props.filterDrawerOpen}
    close={props.toggleFilterDrawerOpen}
  >
    <FOHView
      style={{
        justifyContent: 'center',
        padding: 12
      }}
    >
      <FOHView style={{ flex: 1, zIndex: 999 }}>
        <FOHAltSectionLabel>{props.sortBySectionTitle}</FOHAltSectionLabel>
        {props.sortBy.map((filter, index) => (
          <FOHView
            key={`${index}filter`}
            style={{
              padding: 6,
              zIndex: 9999 - index * 10
            }}
          >
            <FOHMultiSelect
              style={{ zIndex: 9999 - index * 10 }}
              width={287}
              items={filter.filters}
              key={index}
              selectedItems={filter.selectedFilters}
              selectItem={filter.selectFilter}
              onPress={filter.onFilterSelectPress}
              placeholder={filter.filterPlaceholder}
              dropDown={true}
              close={filter.onFilterSelectPress}
              open={filter.filterOpen}
            />
          </FOHView>
        ))}
        <FOHAltSectionLabel>{props.filterTitle}</FOHAltSectionLabel>

        {props.filters.map((filter, index) => (
          <FOHView
            key={`${index}filter`}
            style={{
              padding: 6,
              zIndex: 999 - index * 10
            }}
          >
            <FOHMultiSelect
              style={{ zIndex: 999 - index * 10 }}
              width={287}
              items={filter.filters}
              key={index}
              selectedItems={filter.selectedFilters}
              selectItem={filter.selectFilter}
              onPress={filter.onFilterSelectPress}
              placeholder={filter.filterPlaceholder}
              dropDown={true}
              close={filter.onFilterSelectPress}
              open={filter.filterOpen}
            />
          </FOHView>
        ))}
      </FOHView>
    </FOHView>
    <FOHView
      style={{
        flex: 1,
        zIndex: -99,
        justifyContent: 'flex-end'
      }}
    >
      <FOHView
        style={{
          flexDirection: 'row',
          justifyContent: 'flex-end',
          padding: 24,
          width: 320,
          alignItems: 'center'
        }}
      >
        <FOHTextButton
          onPress={props.clearFilters}
          title={props.clearFiltersLabel}
          color={FOHColors.PRIMARY_TEAL_400}
        />
        <FOHSpace />
        <FOHButton
          style={{
            zIndex: 1,
            width: 100
          }}
          onPress={() => props.applyFilters && props.applyFilters()}
          title={props.applyFiltersTitle || ''}
        />
      </FOHView>
    </FOHView>
  </FOHRightDrawer>
);
