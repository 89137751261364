import React from 'react';

import { useTranslation } from 'react-i18next';
import { compose, path, prop, toLower, defaultTo } from 'ramda';
import { DateTime } from 'luxon';

import {
  FOHBannerType,
  FOHButton,
  FOHLabel,
  FOHLabelBold,
  FOHView,
  FullWidthCard,
  FOHSpaceSmallest,
  FOHSpace,
  FOHSpaceSmall,
  FOHButtonBordered,
  FOHDetailText,
  FOHHeaderH3,
  FOHSmallDetailLabel,
  FOHHeaderH2,
  FOHCrownIcon
} from '../../components';
import {
  CalculatePriceDocument,
  useDeactivateSubscriptionMutateMutation
} from '../../graphql/generated';
import { handleMutation, toDate, useIsMobile } from '../../utils';
import { BILLING, EMPLOYER_SETTINGS_MENU } from '../../constants';
import { useNavigation } from '../../utils/navigation';

import { useCurrentLocationContext } from '../Locations/useCurrentLocationContext';
import { useBanner, withReactiveModal } from '../Navigation';

import { formatPricing, usePaymentPlan } from './usePaymentPlan';

const CancelUnlimitedSubscriptionPlan = props => {
  const { t } = useTranslation('PricingScreenFeature');

  const { navigateTo, location: navLocation } = useNavigation();

  const { isMobile } = useIsMobile();
  const { setBanner } = useBanner();

  const { locationFilter, currentLocation } = useCurrentLocationContext({
    props
  });

  const { planName, currentPlanQuery, subscribedPrice, cycle, singlePrice } =
    usePaymentPlan({
      locationFilter
    });

  const currentPlan = defaultTo(
    {},
    path(['data', 'locationById'], currentPlanQuery)
  );

  const prices = formatPricing({
    notSubscribedPrice: undefined,
    subscribedPrice,
    subscribed: true,
    monthly: cycle === 'monthly'
  });

  const monthly = cycle === 'monthly';
  const currentCost = !planName
    ? '$0'
    : monthly
    ? path([toLower(planName), 'displayPerMonth'], prices)
    : path([toLower(planName), 'total'], prices);

  const now = currentPlan
    ? DateTime.fromSeconds(
        defaultTo(
          0,
          path(['payment', 'subscription', 'currentPeriodEnd'], currentPlan)
        )
      )
    : DateTime.local().toISO();

  const billingDate = !currentPlan ? '' : toDate(now);

  const price = path(['monthlyUnlimited', 'total'], singlePrice)
    ? `-${path(['monthlyUnlimited', 'total'], singlePrice)}`
    : '...';

  const [deactivateSubscription] = useDeactivateSubscriptionMutateMutation();

  return (
    <FOHView
      style={{ width: '100%', paddingHorizontal: 24, paddingVertical: 12 }}
      testID="CancelUnlimitedSubscriptionPlanModal"
    >
      <FOHHeaderH2 style={{ textAlign: 'left' }}>
        {t('manageYourBillingModalTitle')}
      </FOHHeaderH2>
      {!!planName && (
        <FOHDetailText style={{ alignItems: 'center', flexWrap: 'wrap' }}>
          {t('currentBillingTotal')} <FOHLabelBold>{currentCost} </FOHLabelBold>
          <FOHLabel>{t('billedMonthly')}</FOHLabel>
          <FOHSpaceSmall />
          <FOHCrownIcon style={{ width: 20, height: 20 }} />
          <FOHLabelBold style={{ alignItems: 'center' }}>
            <FOHSpaceSmall />
            {t('unlimited')}
          </FOHLabelBold>
        </FOHDetailText>
      )}
      <FOHSpace />
      <FOHHeaderH3 style={{ textAlign: 'left' }}>
        {t('subscriptionSubject')}
      </FOHHeaderH3>
      <FOHSpaceSmall />
      <FullWidthCard
        style={{
          padding: 24,
          paddingTop: 0,
          flexDirection: 'row',
          flexWrap: 'wrap'
        }}
      >
        <FOHView style={{ flex: 2, minWidth: 200, paddingTop: 24 }}>
          <FOHLabelBold>{prop('name', currentLocation)}</FOHLabelBold>
          <FOHSmallDetailLabel>
            {prop('formattedAddress', currentLocation)}
          </FOHSmallDetailLabel>
        </FOHView>
        <FOHView
          style={{
            flex: 0.8,
            alignItems: 'flex-end',
            minWidth: 100,
            paddingTop: 24
          }}
        >
          <FOHSmallDetailLabel>{t('nextBillingDate')}</FOHSmallDetailLabel>
          <FOHSpaceSmallest />
          <FOHLabel>{billingDate}</FOHLabel>
        </FOHView>
        <FOHView
          style={{
            flex: 0.8,
            alignItems: 'flex-end',
            minWidth: 100,
            paddingTop: 24
          }}
        >
          <FOHSmallDetailLabel>{t('price')}</FOHSmallDetailLabel>
          <FOHSpaceSmallest />
          <FOHLabelBold>{price}</FOHLabelBold>
        </FOHView>
      </FullWidthCard>
      <FOHSpace />
      <FOHSmallDetailLabel>{t('aboutPositionUpgrade')}</FOHSmallDetailLabel>
      <FOHSpace />
      <FOHHeaderH3 style={{ textAlign: 'left' }}>
        {t('areYouSureYouWantToCancel')}
      </FOHHeaderH3>
      <FOHDetailText>{t('youWontBeAbleToRequestInterviews')}</FOHDetailText>
      <FOHSpace />
      <FOHView
        style={{
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: isMobile ? 'center' : 'space-between'
        }}
      >
        <FOHButton
          onPress={async () => {
            const refetchQueries = [
              {
                query: CalculatePriceDocument,
                variables: {
                  location: locationFilter,
                  paymentStatus: 'notsubscribed'
                }
              },
              {
                query: CalculatePriceDocument,
                variables: {
                  location: locationFilter,
                  paymentStatus: 'subscribed'
                }
              }
            ];

            // pausing unlimited plan
            await handleMutation(
              deactivateSubscription({
                variables: {
                  stripeSubscription: path(
                    ['payment', 'subscription', 'id'],
                    currentPlan
                  )
                },
                refetchQueries
              })
            );

            setBanner({
              bannerType: FOHBannerType.SUCCESS,
              bannerMessage: t('subscriptionUpdated')
            });

            props.close && props.close();

            navigateTo({
              pathname: props.backPath || `${EMPLOYER_SETTINGS_MENU}${BILLING}`,
              search: navLocation.search
            });
          }}
          style={{ width: isMobile ? '100%' : '50%' }}
          title={t('sureCancel')}
        />
        <FOHButtonBordered
          onPress={props.close}
          style={{ width: isMobile ? '100%' : '50%' }}
          title={t('dismissForNow')}
        />
      </FOHView>
    </FOHView>
  );
};

export const CancelUnlimitedSubscriptionPlanModal = compose(withReactiveModal)(
  CancelUnlimitedSubscriptionPlan
);
