import React, { FunctionComponent } from 'react';

import eye from '../../../images/black_eye_icon.svg';

import { FOHImage } from '../../ingredients';
import { FOHIconProps } from '../../typings';

export const FOHBlackEyeIcon: FunctionComponent<FOHIconProps> = ({
  style
} = {}) => (
  <FOHImage source={{ uri: eye }} style={[{ width: 24, height: 15 }, style]} />
);
