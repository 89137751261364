import React from 'react';
import styled from 'styled-components/native';

import {
  FOHBlackEyeIcon,
  FOHColors,
  FOHCopyIcon,
  FOHEditMarkerBlack,
  FOHFonts,
  FOHLabel,
  FOHNewUpdateBadge,
  FOHSmallDetailLabel,
  FOHSpace,
  FOHSpaceHuge,
  FOHTextButton,
  FOHToolTipType,
  FOHView,
  FullWidthCard
} from '../../../components';
import { useIsMobile } from '../../../utils';

type VoidHandler = () => void;

interface CareerListCellProps {
  pageTitle: string;
  careerPageLink: string;
  onPressCopyLink: VoidHandler;

  locationName: string;
  locationAddress: string;

  noOfPositions: number;
  createdBy: string;

  previewActionLabel: string;
  onPressPreviewAction: VoidHandler;

  editActionLabel: string;
  onPressEditAction: VoidHandler;
  isEditDisabled: boolean;

  tooltipText: string;

  noOfLocations?: number;
}

export const CareerListCell: React.FC<CareerListCellProps> = ({
  careerPageLink,
  createdBy,
  editActionLabel,
  isEditDisabled,
  locationAddress,
  locationName,
  noOfLocations,
  noOfPositions,
  onPressCopyLink,
  onPressEditAction,
  onPressPreviewAction,
  pageTitle,
  previewActionLabel,
  tooltipText
}) => {
  const { isMobile } = useIsMobile();

  const setTooltipProps:
    | {
        enableHover: boolean;
        helpText: string;
      }
    | undefined = isEditDisabled
    ? {
        enableHover: true,
        helpText: tooltipText
      }
    : undefined;

  return (
    <CareerListCellContainer testID={`CareerListCell-${pageTitle}`}>
      <CareerListCellWrapper>
        <FOHView style={{ width: isMobile ? '100%' : '80%' }}>
          <FOHView
            style={{
              alignItems: 'center',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'flex-start',
              width: '100%'
            }}
          >
            <TitleAndLinkWrapper mobile={isMobile}>
              <Label>{pageTitle}</Label>
              <FOHTextButton
                disabled={false}
                icon={() => (
                  <FOHCopyIcon
                    style={{
                      height: 18,
                      marginRight: 4,
                      tintColor: FOHColors.PRIMARY_TEAL_P1,
                      width: 16
                    }}
                  />
                )}
                onPress={onPressCopyLink}
                style={{
                  color: FOHColors.PRIMARY_TEAL_P1,
                  fontFamily: FOHFonts.REGULAR,
                  fontSize: 16,
                  fontWeight: 500,
                  lineHeight: 24
                }}
                title={`${careerPageLink.slice(0, 33)}...`}
                underline
              />
            </TitleAndLinkWrapper>
            {!isMobile && <FOHSpaceHuge />}
            <LocationPositionWrapper mobile={isMobile}>
              <FOHView style={{ alignItems: 'center', flexDirection: 'row' }}>
                <Label>
                  {locationName?.length >= 33
                    ? `${locationName.slice(0, 33)}...`
                    : locationName}
                </Label>
                <FOHSpace />
                <FOHView style={{ maxWidth: 40 }}>
                  {noOfLocations && noOfLocations > 1 && (
                    <FOHNewUpdateBadge label={`+${noOfLocations - 1}`} />
                  )}
                </FOHView>
              </FOHView>
              <FOHSmallDetailLabel
                numberOfLines={1}
                style={{
                  color: FOHColors.GRAYSCALE_600
                }}
              >
                {locationAddress}
              </FOHSmallDetailLabel>
            </LocationPositionWrapper>
            {!isMobile && <FOHSpaceHuge />}
            <NoOfPositionsWrapper mobile={isMobile}>
              <Label>{noOfPositions}</Label>
            </NoOfPositionsWrapper>
            {!isMobile && <FOHSpaceHuge />}
            <CreatedByWrapper mobile={isMobile}>
              <Label>{createdBy}</Label>
            </CreatedByWrapper>
          </FOHView>
        </FOHView>
        <FOHView
          style={{
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: isMobile ? '100%' : '20%'
          }}
        >
          <QuickActionsWrapper>
            <FOHTextButton
              disabled={false}
              icon={FOHBlackEyeIcon}
              onPress={onPressPreviewAction}
              style={{
                color: FOHColors.GRAYSCALE_700,
                fontFamily: FOHFonts.BOLD,
                fontSize: 16,
                fontWeight: 700,
                lineHeight: 24
              }}
              testID="FOHTextButton-preview"
              title={previewActionLabel}
              underline
            />
            <FOHTextButton
              disabled={isEditDisabled}
              icon={() => (
                <FOHEditMarkerBlack
                  style={{
                    tintColor: isEditDisabled
                      ? FOHColors.GRAYSCALE_400
                      : FOHColors.GRAYSCALE_700
                  }}
                />
              )}
              onPress={onPressEditAction}
              style={{
                color: isEditDisabled
                  ? FOHColors.GRAYSCALE_400
                  : FOHColors.GRAYSCALE_700,
                fontFamily: FOHFonts.BOLD,
                fontSize: 16,
                fontWeight: 700,
                lineHeight: 24
              }}
              testID="FOHTextButton-edit"
              title={editActionLabel}
              toolTipType={FOHToolTipType.LEFT}
              tooltipBGColor={FOHColors.RED_300}
              underline
              {...setTooltipProps}
            />
          </QuickActionsWrapper>
        </FOHView>
      </CareerListCellWrapper>
    </CareerListCellContainer>
  );
};

interface StyleProps {
  mobile: boolean;
}

const CareerListCellContainer = styled(FullWidthCard)`
  align-items: center;
  flex-direction: row;
  padding: 18px 12px;
  box-shadow: 0px 4px 8px rgba(23, 23, 23, 0.04);
  border-radius: 4px;
  border: none;
  margin-bottom: 16px;
`;

const CareerListCellWrapper = styled(FOHView)`
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
`;

const TitleAndLinkWrapper = styled(FOHView)<StyleProps>`
  align-items: flex-start;
  justify-content: center;
  margin-bottom: ${(props: StyleProps) => (props.mobile ? '10px' : '0')};
  max-width: ${(props: StyleProps) => (props.mobile ? '100%' : '278px')};
  flex: 3;
`;

const LocationPositionWrapper = styled(FOHView)<StyleProps>`
  align-items: flex-start;
  align-self: flex-start;
  justify-content: center;
  margin-bottom: ${(props: StyleProps) => (props.mobile ? '10px' : '0')};
  max-width: ${(props: StyleProps) => (props.mobile ? '100%' : '364px')};
  flex: 3;
`;

const NoOfPositionsWrapper = styled(FOHView)<StyleProps>`
  align-items: flex-start;
  justify-content: center;
  margin-bottom: ${(props: StyleProps) => (props.mobile ? '10px' : '0')};
  max-width: ${(props: StyleProps) => (props.mobile ? '100%' : '118px')};
  flex: 1;
`;

const CreatedByWrapper = styled(FOHView)<StyleProps>`
  align-items: flex-start;
  justify-content: center;
  margin-bottom: ${(props: StyleProps) => (props.mobile ? '10px' : '0')};
  max-width: ${(props: StyleProps) => (props.mobile ? '100%' : '198px')};
  flex: 1;
`;

const Label = styled(FOHLabel)`
  font-size: 18px;
  line-height: 28px;
  font-family: ${FOHFonts.MEDIUM};
  color: ${FOHColors.GRAYSCALE_900};
`;

const QuickActionsWrapper = styled(FOHView)`
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;
