import React from 'react';

import styled from 'styled-components/native';
import { View } from 'react-native';

import { FOHColors } from '../../ingredients/colors/FOHColors';

import { FOHLabeledCheckbox } from '../checkbox/FOHLabeledCheckbox';

interface FOHSelectionTableProps {
  items: Array<FOHSelectionItem>;
  selected: Array<string>;
  selectItem: (value: string) => void;
  style?: any;
  cellStyle?: any;
  borders?: boolean;
}

interface FOHSelectionItem {
  label: string;
  value: string;
}

export const FOHSelectCheckboxTable = (props: FOHSelectionTableProps) => {
  const Cell = props.borders ? BorderView : View;
  return (
    <TableContainer style={{ ...props.style }}>
      {props.items.map(item => (
        <Cell
          key={item.value}
          style={{
            padding: 8,
            marginBottom: 8,
            backgroundColor:
              props.selected && props.selected.includes(item.value)
                ? FOHColors.GRAYSCALE_10
                : '',
            borderRadius: 4,
            ...props.cellStyle
          }}
        >
          <FOHLabeledCheckbox
            style={{
              fontSize: 16,
              paddingLeft: 2,
              color: FOHColors.GRAYSCALE_900,
              fontWeight:
                props.selected && props.selected.includes(item.value)
                  ? 600
                  : 400
            }}
            setSelected={() => props.selectItem(item.value)}
            checkboxLabel={item.label}
            selected={props.selected && props.selected.includes(item.value)}
          />
        </Cell>
      ))}
    </TableContainer>
  );
};

const BorderView = styled(View)`
  border-width: 1px;
  border-color: #dbdbdb;
  padding: 14px;
  flex-direction: row;
  border-radius: 6px;
  margin-bottom: 5px;
  height: 44px;
  background-color: ${FOHColors.WHITE};
`;

const TableContainer = styled(View)`
  width: 100%;
  top: 0;
`;
