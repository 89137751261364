export const FORM_TYPES = {
  newTemplate: 'new_template',
  selectTemplate: 'select_template'
};

export const INITIAL_SLATE_VALUE = [
  {
    type: 'paragraph',
    children: [{ text: '' }]
  }
];
