import React from 'react';

import { useTranslation } from 'react-i18next';

import {
  FOHHighlightText,
  FOHColors,
  FOHView,
  FOHSpace,
  FOHReactiveModal,
  FOHPageModal,
  FOHDetailText,
  FOHHeaderH2
} from '../../components';
import { useIsMobile } from '../../utils';

import { useSidebarNav } from '../Navigation/useSidebar';

export const demoVideoUrl =
  'https://player.vimeo.com/video/520155483?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479';

export const MediumVideo = () => {
  return (
    <iframe
      src={demoVideoUrl}
      height="500"
      width="800"
      frameBorder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowFullScreen
      title="product-demo"
    ></iframe>
  );
};

export const SmallVideo = () => {
  return (
    <iframe
      src={demoVideoUrl}
      height="200"
      width="300"
      frameBorder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowFullScreen
      title="product-demo"
    ></iframe>
  );
};

export const DemoVideoModal = props => {
  const { isMobile } = useIsMobile();
  const Modal = isMobile ? FOHReactiveModal : FOHPageModal;
  const Video = isMobile ? SmallVideo : MediumVideo;
  const { t } = useTranslation('DemoScreenFeature');
  const { contentShift } = useSidebarNav(props);
  return (
    <Modal
      leftContentShift={contentShift}
      open={props.open}
      dialog={!isMobile}
      close={props.close}
    >
      <FOHView style={{ alignItems: 'center' }}>
        <FOHHighlightText
          labelCmp={FOHHeaderH2}
          emphasizedWord={t('modalTitleHighlight')}
          highlightColor={FOHColors.GOLD}
          boldLabelCmp={FOHHeaderH2}
        >
          {t('modalTitle')}
        </FOHHighlightText>
        <FOHSpace />
        <FOHDetailText>{t('modalDetailLabel')}</FOHDetailText>
        <Video />
      </FOHView>
    </Modal>
  );
};
