import { useEffect, useState, useMemo } from 'react';

import {
  sendBirdSelectors,
  useSendbirdStateContext
} from '@sendbird/uikit-react';
import '@sendbird/uikit-react/dist/index.css';
import { path, prop, length } from 'ramda';

import { useGetSbUsersQuery } from '../../graphql/generated';

import { useMyLocationsData } from '../Locations';

// positionMatches with nested location
const getIsPayingForMatch = (matches = []) =>
  matches.reduce(
    ([isPayingForMatch, matchLocations, matchPositions], curr) => {
      const position = path(['node', 'position'], curr);
      const positionPayment = path(['node', 'position', 'paymentStatus'], curr);
      const location = path(['node', 'position', 'location'], curr);
      const locationPayment = path(
        ['node', 'position', 'location', 'paymentStatus'],
        curr
      );
      return [
        positionPayment === 'SUBSCRIBED' ||
          locationPayment === 'SUBSCRIBED' ||
          !!isPayingForMatch,
        [...matchLocations, location],
        [...matchPositions, position]
      ];
    },
    [undefined, [], []]
  );

// sendbird userId of candidate (uuid)
export const useChannelMatches = ({
  channelUrl, // provide this prop if you do not have the candidates uuid or you need channel info
  sendbirdUserId, // employer
  isCandidate
}) => {
  const context = useSendbirdStateContext();
  const sdkInstance = sendBirdSelectors.getSdk(context);
  const [candidateUuid, setCandidateUuid] = useState('');
  const [employerUuid, setEmployerUuid] = useState('');

  const [currentChannel, setCurrentChannel] = useState('');

  const matchesQuery = useGetSbUsersQuery({
    skip: !candidateUuid || !sendbirdUserId || !employerUuid,
    variables: { uuid: candidateUuid, employerUuid: employerUuid }
  });

  const { data: myLocations } = useMyLocationsData({
    skipLocationsQuery: isCandidate
  });

  useEffect(() => {
    if (
      channelUrl &&
      sdkInstance &&
      sdkInstance.GroupChannel &&
      sendbirdUserId
    ) {
      // TODO: mock this for a unit test on useChannelMatches
      sdkInstance.GroupChannel.getChannel(channelUrl, (groupChannel, error) => {
        if (error) {
          return setCandidateUuid('');
        }
        const candidate = prop('members', groupChannel).filter(member =>
          isCandidate
            ? prop('userId', member) === sendbirdUserId
            : prop('userId', member) !== sendbirdUserId &&
              path(['metaData', 'is_candidate'], member) === 'True'
        );
        const employer = prop('members', groupChannel).filter(member =>
          isCandidate
            ? prop('userId', member) !== sendbirdUserId
            : prop('userId', member) === sendbirdUserId
        );
        const userId = path([0, 'userId'], candidate);
        const employerUserId = path([0, 'userId'], employer);
        setCandidateUuid(userId);
        setEmployerUuid(employerUserId);
        setCurrentChannel(groupChannel);
      });
    }
  }, [
    channelUrl,
    sdkInstance,
    sdkInstance.GroupChannel,
    isCandidate,
    sendbirdUserId
  ]);

  const candidate = path(
    ['data', 'sendbirdUsers', 'edges', 0, 'node'],
    matchesQuery
  );
  const matches = path(
    ['data', 'sendbirdUsers', 'edges', 0, 'node', 'positionMatches', 'edges'],
    matchesQuery
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const [payingForMatch, matchLocations, matchPositions] = useMemo(() =>
    getIsPayingForMatch(matches)
  );

  // if there is an unlimited location and no position matches let them talk
  const unlimited =
    (length(
      myLocations.filter(loc => prop('paymentStatus', loc) === 'SUBSCRIBED')
    ) > 0 &&
      length(matches) === 0) ||
    // possible state where candidate has left or been removed from a chat
    !candidateUuid;

  return {
    loadingPaying: matchesQuery.loading,
    payingForMatch: matchesQuery.loading
      ? undefined
      : payingForMatch || unlimited,
    currentChannel,
    matchLocations,
    matchPositions,
    candidate
  };
};
