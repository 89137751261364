import React from 'react';

import { defaultTo, prop } from 'ramda';

import feedicon from '../../../images/grayfeedicon.svg';

import { FOHImage } from '../../ingredients';

export const FOHGrayFeedIcon = (props: any) => (
  <FOHImage
    source={{ uri: feedicon }}
    style={{
      width: 24,
      height: 24,
      ...defaultTo({}, prop('style', props))
    }}
  />
);
