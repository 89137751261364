import React from 'react';

import red from '../../../images/red_starship.svg';
import gold from '../../../images/gold_starship.svg';
import green from '../../../images/green_starship.svg';

import { FOHImage } from '../../ingredients';

const getUri = (color: string) => {
  return {
    red,
    gold,
    green
  }[color];
};

export const FOHProgressRocketIcon = ({ color }: { color: string }) => (
  <FOHImage
    source={{
      uri: getUri(color)
    }}
    style={{
      width: color === 'red' ? 12 : 13,
      height: color === 'red' ? 16 : 14
    }}
  />
);
