import React from 'react';

import { View, Image } from 'react-native';

import {
  FOHReactiveModal,
  FOHHeaderText,
  FOHDetailText,
  FOHButton,
  FOHView,
  FOHColors,
  FOHSpaceLarger,
  FOHSpaceSmall
} from '../../ingredients';

export interface FOHBulkInviteConfirmationModalProps {
  open: boolean;
  close: () => void;
  headerLabel: string;
  detailLabel?: string;
  buttonLabel: string;
  image: string;
  imageWidth: number;
  imageHeight: number;
  confirm: () => void;
  cancel: () => void;
  cancelText: string;
  dialog?: boolean;
  leftContentShift?: number;
  disabled?: boolean;
}

export const FOHBulkInviteConfirmationModal = (
  props: FOHBulkInviteConfirmationModalProps
) => {
  return (
    <FOHReactiveModal
      leftContentShift={props.leftContentShift}
      dialog={props.dialog}
      style={{ width: 800 }}
      open={props.open}
      close={props.close}
    >
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: props.dialog ? 24 : 14,
          paddingHorizontal: 86
        }}
      >
        <FOHView style={{ paddingHorizontal: 50 }}>
          <FOHHeaderText
            style={{ fontSize: 24, lineHeight: 32, marginBottom: 8 }}
          >
            {props.headerLabel}
          </FOHHeaderText>
          <FOHDetailText style={{ lineHeight: 24, justifyContent: 'center' }}>
            {props.detailLabel}
          </FOHDetailText>
        </FOHView>
        <FOHSpaceLarger />
        <FOHSpaceSmall />
        <View style={{ alignItems: 'center', justifyContent: 'center' }}>
          <Image
            source={{ uri: props.image }}
            resizeMode={'contain'}
            style={{
              width: props.imageWidth,
              height: props.imageHeight
            }}
          />
        </View>
        <FOHSpaceLarger />
        <FOHSpaceSmall />
        <FOHView
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            width: '100%'
          }}
        >
          <FOHButton
            onPress={props.cancel}
            title={props.cancelText}
            disabled={props?.disabled}
            color={FOHColors.WHITE}
            borderColor={FOHColors.GRAYSCALE_700}
            textColor={FOHColors.GRAYSCALE_700}
            style={{ maxWidth: 200, height: 40, marginHorizontal: 8 }}
          />
          <FOHButton
            onPress={props.confirm}
            title={props.buttonLabel}
            disabled={props?.disabled}
            style={{ maxWidth: 200, height: 40, marginHorizontal: 8 }}
          />
        </FOHView>
      </View>
    </FOHReactiveModal>
  );
};
