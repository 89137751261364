import React, { Dispatch, FunctionComponent, useEffect } from 'react';

import { formatFileSize } from '../../../features/CareerPage/utils';

import { GalleryItemsCategories } from '../../constants';
import { SPACING_SCALE } from '../../ingredients';
import { FileFields } from '../../typings';
import {
  PickMedia,
  FilePreview
} from '../../recipes/buttons/FOHEditCoverPhotoButton';

import {
  EmptyUploadStateView,
  EmptyUploadWrapper,
  FileLimitationText
} from './styled/FOHCareerPageGalleryItemsInput.styled';

export { FileFields };

interface GalleryItem extends FilePreview {
  category?: string;
  uploadFile?: string;
  id?: string;
  file?: File;
  fileSize?: string;
  type?: string;
}

type TranslationKeys =
  | 'browse'
  | 'dropFilesHere'
  | 'supportedFileFormatsAndSize'
  | 'uploadPhotoOrVideo';

export interface FOHCareerPageGalleryItemsInputProps {
  galleryItems: GalleryItem[] | null;
  handleInvalidFileType: () => void;
  setIsSubmitDisabled: (state: boolean) => void;
  mediaInput: JSX.IntrinsicElements['input'];
  setGalleryItems: Dispatch<React.SetStateAction<GalleryItem[] | null>>;
  translations: Record<TranslationKeys, string>;
}
const MAX_ALLOWED_GALLERY_FILES = 15;
const ALLOWED_FILE_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'video/mp4'
];

export const FOHCareerPageGalleryItemsInput: FunctionComponent<
  FOHCareerPageGalleryItemsInputProps
> = ({
  galleryItems,
  handleInvalidFileType,
  mediaInput,
  setGalleryItems,
  setIsSubmitDisabled,
  translations
}) => {
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    if (event.target.files) {
      const { files } = event.target;

      if (files.length < 1) return;

      const mediaFiles: GalleryItem[] = Array.from(files).map(file => ({
        category: GalleryItemsCategories.CAREER_PAGE_GALLERY,
        file: file,
        [FileFields.FileName]: file.name,
        fileSize: formatFileSize(file.size),
        [FileFields.FileType]: file.type,
        url: URL.createObjectURL(file)
      }));

      setGalleryItems(prevFiles => {
        const result = prevFiles ? [...prevFiles, ...mediaFiles] : mediaFiles;

        return result.slice(0, MAX_ALLOWED_GALLERY_FILES);
      });
    }
  };

  const handleDelete = (url?: string) => {
    setGalleryItems(
      prevFiles => prevFiles?.filter(file => file.url !== url) || null
    );
  };

  useEffect(() => {
    const hasInvalidFileTypes = galleryItems?.some(
      file => !ALLOWED_FILE_TYPES.includes(file[FileFields.FileType] as string)
    );

    if (hasInvalidFileTypes) {
      setIsSubmitDisabled(true);
      handleInvalidFileType();
    } else {
      setIsSubmitDisabled(false);
    }
  }, [galleryItems, handleInvalidFileType, setIsSubmitDisabled]);

  if (!galleryItems?.length)
    return (
      <EmptyUploadWrapper>
        <EmptyUploadStateView
          translations={{
            browse: translations.browse,
            dropFilesHere: translations.dropFilesHere
          }}
        />

        {/* make invisible input to trigger uploads */}
        <input
          multiple
          onChange={handleChange}
          style={{
            height: '100%',
            marginLeft: -SPACING_SCALE.larger,
            marginTop: -SPACING_SCALE.larger,
            opacity: 0,
            position: 'absolute',
            width: '100%'
          }}
          type="file"
        />

        <FileLimitationText>
          {translations.supportedFileFormatsAndSize}
        </FileLimitationText>
      </EmptyUploadWrapper>
    );

  return (
    <PickMedia
      filePreview={galleryItems}
      handleChange={handleChange}
      handleDelete={handleDelete}
      mediaInput={mediaInput}
      translations={{
        changePhotoButton: translations.uploadPhotoOrVideo,
        uploadPhotoButton: translations.uploadPhotoOrVideo
      }}
    />
  );
};
