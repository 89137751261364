import React, { Fragment } from 'react';

import { FOHLinkOut } from '../links/FOHLinkOut';

import { FOHLabel } from './FOHLabel';

export interface LinkConfig {
  word: string;
  href: string;
  noIcon?: boolean;
}

export interface FOHHighlightLinksWithTextsProps {
  labelCmp?: any;
  labelStyle?: any;
  links: Array<LinkConfig>;
  highlightColor: string;
  linkCmp?: any;
  linkStyle?: any;
  children: string;
  iconStyle?: any;
  caseInsensitive?: boolean;
  boldLabelCmp?: any;
  style?: any;
  emphasizedWord?: string;
  emphasizedWords?: Array<string>;
  emphasizedStyle?: any;
  highlightWrapper?: string;
  textColor?: string;
}

export const FOHHighlightLinksWithTexts = (
  props: FOHHighlightLinksWithTextsProps
) => {
  const Label = props.labelCmp || FOHLabel;
  const Link = props.linkCmp || FOHLinkOut;
  const EmphasizedLabel = props.boldLabelCmp || Label;

  const splitText = (text: string, delimiters: string[]) => {
    const regex = new RegExp(`(${delimiters.join('|')})`, 'gi');
    return text.split(regex);
  };

  const getHighlightedText = (text: string) => {
    const allWords = [
      ...new Set([
        ...props.links.map(link => link.word),
        ...(props.emphasizedWords || [])
      ])
    ];
    const unstyledWords = splitText(text, allWords);

    return unstyledWords.map((word, index) => {
      const link = props.links.find(
        l => l.word.toLowerCase() === word.toLowerCase()
      );

      const emphasized = props.caseInsensitive
        ? props.emphasizedWords?.some(
            emphasizedWord =>
              emphasizedWord.toLowerCase() === word.toLowerCase()
          )
        : props.emphasizedWords?.includes(word);

      return (
        <Fragment key={index + 1}>
          {link ? (
            <Link
              iconStyle={props?.iconStyle}
              noIcon={link.noIcon}
              href={link.href}
              labelStyle={{
                ...props.linkStyle,
                color: props.highlightColor
              }}
            >
              {link.word}
            </Link>
          ) : emphasized ? (
            <EmphasizedLabel
              style={{
                ...props.emphasizedStyle,
                color: props.highlightColor
              }}
            >
              {props.highlightWrapper}
              {word}
              {props.highlightWrapper}
            </EmphasizedLabel>
          ) : (
            word
          )}
        </Fragment>
      );
    });
  };

  return (
    <Label style={{ ...props.labelStyle, color: props.textColor }}>
      {getHighlightedText(props.children)}
    </Label>
  );
};
