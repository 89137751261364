import React from 'react';

import {
  FOHHeaderText,
  FOHDetailText,
  Screen,
  FOHButton,
  FOHTextButton,
  FOHListTextField,
  FOHErrorLabel,
  FOHView
} from '../../ingredients';
import { FOHEmailListProps } from '../../recipes';

export interface FOHEmailListScreenProps extends FOHEmailListProps {
  largeHeader?: boolean;
}

export const FOHEmailListScreen = (props: FOHEmailListScreenProps) => (
  <Screen>
    <FOHView
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 60
      }}
    >
      <FOHView style={{ maxWidth: 400, width: '100%' }}>
        <FOHHeaderText style={{ fontSize: 30 }}>{props.title}</FOHHeaderText>
      </FOHView>
      <FOHView style={{ padding: 6 }} />
      <FOHView style={{ width: 325 }}>
        <FOHDetailText>{props.detailText}</FOHDetailText>
      </FOHView>
      <FOHView
        style={{
          paddingTop: 24
        }}
      />
      <FOHView style={{ width: 325 }}>
        <FOHListTextField
          values={props.emails}
          setValues={props.setEmails}
          onChangeText={props.setInput}
          value={props.input}
          setValue={props.setInput}
        />
      </FOHView>
      <FOHView style={{ padding: 6 }} />
      {!!props.error && (
        <>
          <FOHErrorLabel>{props.error}</FOHErrorLabel>{' '}
          <FOHView style={{ padding: 6 }} />
        </>
      )}
      <FOHButton title={props.submitLabel} onPress={props.handleSubmit} />
      <FOHView style={{ padding: 6 }} />
      {!!props.cancelText && (
        <FOHTextButton title={props.cancelText} onPress={props.cancel} />
      )}
    </FOHView>
  </Screen>
);
