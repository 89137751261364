import React, { FunctionComponent } from 'react';

import styled from 'styled-components/native';

import {
  FOHChatCreateNewInvertedIcon,
  FOHColors,
  // FOHFollowBadge,
  FOHInverseButton,
  FOHJobPositionIcon,
  FOHTag,
  FOHView,
  SPACING_SCALE,
  FOHShareIconAlt,
  FOHSmallDetailLabel,
  FOHHeaderH1
} from '../../ingredients';
import { useIsMobile } from '../../spices';

import { FontProperty } from '../';

import { DetailsRow } from './styled';

export interface FOHCareerPageDetailsProps {
  address: string;
  followerCount: number;
  followerCountLabel: string;
  fontProperty?: FontProperty;
  onJobsClick: () => void;
  onMessageClick: () => void;
  onShareClick: () => void;
  pageTitle: string;
  tags: string[];
  translations: Record<string, string>;
}

export const FOHCareerPageDetails: FunctionComponent<
  FOHCareerPageDetailsProps
> = ({
  address,
  // followerCount,
  // followerCountLabel,
  fontProperty,
  onJobsClick,
  onMessageClick,
  onShareClick,
  pageTitle,
  tags = [],
  translations
}) => {
  // const isFollowBadgeShown = followerCount > 0;
  const isMobile = useIsMobile();

  return (
    <>
      <DetailsRow style={{ marginTop: 22 }} testID="FOHCareerPageDetails-title">
        <FOHHeaderH1 style={{ textAlign: 'left', ...fontProperty }}>
          {pageTitle}
        </FOHHeaderH1>
        <FOHSmallDetailLabel
          color="#707070"
          style={{ ...fontProperty }}
          testID="FOHCareerPageDetails-address"
        >
          {address}
        </FOHSmallDetailLabel>
      </DetailsRow>

      {/* Hide follow badge for now until the feature is refined and implemented fully */}
      {/* {isFollowBadgeShown && (
        <DetailsRow style={{ marginBottom: SPACING_SCALE.regular }}>
          <FOHFollowBadge inverse label={followerCountLabel} />
        </DetailsRow>
      )} */}

      {tags.length > 0 && (
        <FOHView
          style={{
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginBottom: SPACING_SCALE.larger
          }}
        >
          {tags?.map((tag, i) => (
            <FOHTag
              key={`${tag}-${i}`}
              label={tag}
              style={{
                margin: 0,
                marginBottom: SPACING_SCALE.small,
                marginRight: SPACING_SCALE.small
              }}
            />
          ))}
        </FOHView>
      )}

      <DetailsRow
        style={{
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
          zIndex: 1
        }}
      >
        <DetailsButton
          buttonHorPadding={SPACING_SCALE.huge}
          icon={FOHShareIconAlt}
          isMobile={isMobile}
          onPress={onShareClick}
          style={{
            maxWidth: 'auto',
            ...(isMobile && { marginRight: SPACING_SCALE.large / 2 })
          }}
          title={
            isMobile
              ? translations.shareButtonLabelMobile
              : translations.shareButtonLabel
          }
        />

        <DetailsButton
          icon={FOHJobPositionIcon}
          isMobile={isMobile}
          onPress={onJobsClick}
          style={{
            ...(isMobile && { marginLeft: SPACING_SCALE.large / 2 })
          }}
          title={translations.jobsButtonLabel}
        />

        <DetailsButton
          color={FOHColors.GRAYSCALE_700}
          icon={() =>
            FOHChatCreateNewInvertedIcon({
              style: { height: 21, width: 21 }
            })
          }
          isMobile={isMobile}
          onPress={onMessageClick}
          opaque
          selected
          style={{
            ...(isMobile && { marginTop: SPACING_SCALE.large })
          }}
          textColor={FOHColors.WHITE}
          title={translations.messageButtonLabel}
        />
      </DetailsRow>
    </>
  );
};

// @ts-ignore Obsolete once we bump styled-conmponents
const DetailsButton = styled(FOHInverseButton)<{ isMobile: boolean }>`
  height: 50px;
  min-width: 160px;
  width: auto;

  ${({ isMobile }: { isMobile: boolean }) =>
    !isMobile && `marginRight: ${SPACING_SCALE.large}px`};
  ${({ isMobile }: { isMobile: boolean }) => isMobile && 'flex: 1'};
`;
