import { defaultTo, path } from 'ramda';

import { GROUPS, ROLES } from '../../constants';
import { useGetMeQuery } from '../../graphql/generated';

export interface UseIsRestrictedArgs {
  allowedRoles: Array<keyof typeof ROLES>;
  allowedGroups: Array<keyof typeof GROUPS>;
}

export const useToGetUserRoleAndGroup = () => {
  const { data, loading } = useGetMeQuery({});
  const me = defaultTo({}, path(['me'], data));

  const currentUserRole = defaultTo(
    null,
    path(['employerProfileUser', 'role'], me)
  ) as keyof typeof ROLES;

  const isCandidate = defaultTo(false, path(['candidateProfile'], me));

  const userGroup = defaultTo(
    null,
    isCandidate ? GROUPS.candidates : GROUPS.employers
  ) as keyof typeof GROUPS;

  return {
    group: userGroup,
    role: currentUserRole,
    loading
  };
};

const useIsRestricted = (args: UseIsRestrictedArgs) => {
  const { allowedRoles, allowedGroups } = args;
  const { group, role, loading } = useToGetUserRoleAndGroup();

  const isRestricted = defaultTo(
    false,
    group === GROUPS.employers
      ? !allowedRoles.includes(role) || !allowedGroups.includes(group)
      : !allowedGroups.includes(group)
  ) as boolean;

  return { isRestricted, loading };
};

export default useIsRestricted;
