import React from 'react';

import {
  FOHRestaurantLocationTableProps,
  FOHRestaurantLocationTable
} from '../../recipes';
import { FOHView, Screen } from '../../ingredients';

export interface FOHLocationFeedScreenProps
  extends FOHRestaurantLocationTableProps {
  filterTitle: string;
  toggleFilterDrawerOpen?: () => any;
}

export const FOHLocationFeedScreen = (props: FOHLocationFeedScreenProps) => (
  <>
    <Screen>
      <FOHView
        style={{
          flexDirection: 'row',
          paddingTop: props.mobile ? 60 : 10,
          zIndex: 999,
          alignItems: 'center',
          justifyContent: 'center'
        }}
      />
      {!props.mobile && <FOHView style={{ height: 26 }} />}
      <FOHRestaurantLocationTable
        mapHidden={props.mapHidden}
        toggleMapHiddenLabel={props.toggleMapHiddenLabel}
        setMapHidden={props.setMapHidden}
        hiringLabel={props.hiringLabel}
        hiringBtnHelpText={props.hiringBtnHelpText}
        jobMatchLabel={props.jobMatchLabel}
        svg={props.svg}
        path={props.path}
        noResultsHeader={props.noResultsHeader}
        noResultsBody={props.noResultsBody}
        noResultsButtonTitle={props.noResultsButtonTitle}
        noResultsButtonPress={props.noResultsButtonPress}
        loading={props.loading}
        tableHeader={props.tableHeader}
        tableDescription={props.tableDescription}
        restaurantCountLabel={props.restaurantCountLabel}
        seeMoreLabel={props.seeMoreLabel}
        hideLabel={props.hideLabel}
        restaurantCount={props.restaurantCount}
        favoriteLabel={props.favoriteLabel}
        seeMore={props.seeMore}
        mobile={props.mobile}
        mapOnly={props.mapOnly}
        listOnly={props.listOnly}
        toggleMapList={props.toggleMapList}
        mapListButtonTitle={props.mapListButtonTitle}
        locations={props.locations}
        pressFavorite={props.pressFavorite}
        pressHide={props.pressHide}
        openHiringModal={props.openHiringModal}
        onChangeSearch={props.onChangeSearch}
        searchValue={props.searchValue}
        searchPlaceholder={props.searchPlaceholder}
        zipCode={props.zipCode}
        zipCodePlaceholder={props.zipCodePlaceholder}
        setZipCode={props.setZipCode}
        onPressFilters={props.toggleFilterDrawerOpen}
        hasFiltersOn={props.hasFiltersOn}
        filterButtonTitle={props.filterTitle}
        loadMoreTitle={props.loadMoreTitle}
        loadMore={props.loadMore}
        hasMoreLocations={props.hasMoreLocations}
        supportTitleCmp={props.supportTitleCmp}
        supportBodyCmp={props.supportBodyCmp}
        closeSupport={props.closeSupport}
        supportOpen={props.supportOpen}
        supportCloseText={props.supportCloseText}
        favoritedHelpText={props.favoritedHelpText}
        hideMeHelpText={props.hideMeHelpText}
        clearSelectionLabel={props.clearSelectionLabel}
        clearSelection={props.clearSelection}
      >
        {props.children}
      </FOHRestaurantLocationTable>
    </Screen>
  </>
);
