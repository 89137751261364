import React from 'react';

import boost_position from '../../../images/boost_position.svg';

import { FOHImage } from '../../ingredients';

export interface FOHBoostIconProps {
  style?: any;
}

export const FOHBoostIcon = (props: FOHBoostIconProps) => (
  <FOHImage
    source={{ uri: boost_position }}
    style={
      props && !!props.style
        ? { width: 20, height: 20, ...props.style }
        : { width: 20, height: 20 }
    }
  />
);
