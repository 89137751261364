import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { length, prop, path, compose } from 'ramda';

import {
  AVAILABILITY_WEEKDAYS,
  AVAILABILITY_SHIFTS_MAP,
  CANDIDATE_ACCOUNT_MENU_PATH,
  CANDIDATE_AVAILABILITY_PATH
} from '../../constants';
import {
  transformAvailabilityFromProfile,
  transformAvailabilityToVariables
} from '../../api/transformers/candidates';
import { useIsMobile, snakeToCamel, handle } from '../../utils';
import { FOHAvailabilityScreen, FOHView } from '../../components';
import { useNavigation } from '../../utils/navigation';

import { useAvailability } from '../SharedHooks/useAvailability';
import { withReactiveModal } from '../Navigation';

import { useCandidateProfileMutation } from './useCandidateProfileMutation';

const AvailabilityScreen = props => {
  const { navigateTo, location: navLocation } = useNavigation();

  const { me, updateCandidate } = useCandidateProfileMutation();

  const availabilityMap = prop('candidateProfile', me)
    ? transformAvailabilityFromProfile({
        profile: prop('candidateProfile', me)
      })
    : [''];

  const {
    availability,
    setAvailability,
    selectAvailability,
    allAvailabilitySelected,
    toggleAllAvailability
  } = useAvailability();

  const hasBack =
    navLocation.pathname === CANDIDATE_AVAILABILITY_PATH &&
    me &&
    !!path(['candidateProfile', 'onboardingCompletedAt'], me);

  useEffect(() => {
    availabilityMap && setAvailability(availabilityMap);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prop('candidateProfile', me)]);

  useEffect(() => {
    try {
      !props.isModal &&
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
    } catch (e) {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Dirty state based on any information changed
  const [dirty, setDirty] = useState(false);
  const [error, setError] = useState();
  const { t } = useTranslation('AvailabilityFeature');
  const { isMobile } = useIsMobile();

  return (
    <>
      {!props.onboarding && props.isMobile && !props.isModal && (
        <FOHView style={{ paddingTop: 50 }} />
      )}
      <FOHAvailabilityScreen
        sections={AVAILABILITY_WEEKDAYS.map(_availability => ({
          value: snakeToCamel(_availability.value),
          label: t(_availability.label)
        }))}
        shifts={AVAILABILITY_SHIFTS_MAP(t)}
        selected={availability}
        selectItem={(value, section) => {
          selectAvailability({ value, section });
          setDirty(true);
        }}
        selectAllLabel={t('selectAll')}
        allSelected={allAvailabilitySelected}
        onSelectAll={() => {
          toggleAllAvailability();
          setDirty(true);
        }}
        title={t('availabilityTitle')}
        submitLabel={
          path(['candidateProfile', 'onboardingCompletedAt'], me)
            ? t('common:save')
            : t('nextLabel')
        }
        error={error}
        isModal={props.isModal}
        mobile={isMobile}
        largeHeader={!isMobile}
        disabledSubmit={
          length(availability) === 0 || (!dirty && !props.onboarding)
        }
        previousTitle={
          props.onboarding ? t('CandidateOnboardingSteps:previous') : ''
        }
        goBack={
          hasBack
            ? () => navigateTo(CANDIDATE_ACCOUNT_MENU_PATH)
            : props.onboarding
            ? () => props.previousStep()
            : null
        }
        handleSubmit={async () => {
          const updatedAvailability = transformAvailabilityToVariables({
            availability
          });

          const [resp] = await handle(
            updateCandidate({
              variables: {
                ...updatedAvailability
              }
            })
          );
          const err = path(['data', 'mutateCandidate', 'errors'], resp);
          if (resp && !err) {
            setDirty(false);

            if (props.close) return props.close();

            return navigateTo(
              path(['candidateProfile', 'onboardingCompletedAt'], me)
                ? CANDIDATE_ACCOUNT_MENU_PATH
                : props.onSubmit && props.onSubmit(resp)
            );
          } else if (err) {
            setError(err);
          }
        }}
      />
    </>
  );
};

export const AvailabilityModal = compose(withReactiveModal)(AvailabilityScreen);

export default AvailabilityScreen;
