import React from 'react';

import styled from 'styled-components/native';
import { View, Text, TouchableOpacity } from 'react-native';

import {
  FOHTextField,
  FOHHeaderH3,
  FullWidthCard,
  FOHSpace,
  FOHPhoneInput,
  FOHPasswordField
} from '../../ingredients';

import { FOHTerms, FOHTermsProps } from '../checkbox/FOHTerms';

import { FOHForm } from './FOHForm';

type VoidHandler = () => void;

interface FOHAccountFormProps extends FOHTermsProps {
  handleSubmit?: () => void;
  submitLabel?: string;
  firstName: string;
  firstNamePlaceholder: string;
  setFirstName: (value: string) => void;
  lastName: string;
  lastNamePlaceholder: string;
  setLastName: (value: string) => void;
  phoneNumber: string;
  phoneNumberPlaceholder: string;
  setPhoneNumber: (value: string) => void;
  email: string;
  emailPlaceholder: string;
  setEmail: (value: string) => void;
  passwordLabel?: string;
  password?: string;
  passwordPlaceholder?: string;
  setPassword?: (value: string) => void;
  confirmPassword?: string;
  confirmPasswordPlaceholder?: string;
  setConfirmPassword?: (value: string) => void;
  error?: any;
  loginBannerText?: string;
  loginText?: string;
  disabledSubmit?: boolean;
  isMobile?: boolean;
  loginPress?: VoidHandler;
  showPassText?: string;
  hidePassText?: string;
}

export const FOHExternalRegisterForm = (props: FOHAccountFormProps) => {
  const { isMobile } = props;

  return (
    <FOHForm
      handleSubmit={props.handleSubmit}
      disabledSubmit={props.disabledSubmit}
    >
      <ContainerView>
        <FullWidthCard
          style={{
            backgroundColor: '#fcfcfc',
            padding: isMobile ? 16 : 32,
            borderRadius: 24,
            maxWidth: 570,
            width: '100%'
          }}
        >
          <LoginBannerView>
            <FlexRow>
              <Text style={{ maxWidth: '80%', color: '#171717' }}>
                {props.loginBannerText}
              </Text>
              <TouchableOpacity
                onPress={props.loginPress}
                style={{ marginLeft: 'auto' }}
              >
                <Text
                  style={{
                    color: '#229557',
                    textDecorationLine: 'underline'
                  }}
                >
                  {props.loginText}
                </Text>
              </TouchableOpacity>
            </FlexRow>
          </LoginBannerView>
          <FOHSpace />
          <View style={{ alignItems: 'center', width: '100%' }}>
            <FlexRow>
              <View style={{ flex: 1 }}>
                <FOHTextField
                  placeholder={props.firstNamePlaceholder}
                  value={props.firstName}
                  onChangeText={(value: string) => props.setFirstName(value)}
                  error={
                    props.error && props.error.firstName
                      ? props.error.firstName
                      : null
                  }
                />
              </View>
              <View style={{ flex: 1, marginLeft: 10 }}>
                <FOHTextField
                  placeholder={props.lastNamePlaceholder}
                  value={props.lastName}
                  onChangeText={(value: string) => props.setLastName(value)}
                  error={
                    props.error && props.error.lastName
                      ? props.error.lastName
                      : null
                  }
                />
              </View>
            </FlexRow>
            <FlexRow>
              <FOHTextField
                placeholder={props.emailPlaceholder}
                value={props.email}
                autoCompleteType={'email'}
                onChangeText={(value: string) => props.setEmail(value)}
                error={
                  props.error && props.error.email ? props.error.email : null
                }
              />
            </FlexRow>
            <FlexRow>
              <FOHPhoneInput
                placeholder={props.phoneNumberPlaceholder}
                value={props.phoneNumber}
                autoCompleteType={'tel'}
                onChangeText={(value: string) => props.setPhoneNumber(value)}
                error={
                  props.error && (props.error.phoneNumber || props.error.phone)
                    ? props.error.phoneNumber || props.error.phone
                    : null
                }
              />
            </FlexRow>

            {props.setPassword && (
              <>
                <FOHHeaderH3 style={{ textAlign: 'left' }}>
                  {props.passwordLabel}
                </FOHHeaderH3>
                <FlexRow style={{ flexDirection: isMobile ? 'column' : 'row' }}>
                  <FlexRow
                    style={{
                      width: isMobile ? '100%' : '50%'
                    }}
                  >
                    <FOHPasswordField
                      passwordPlaceholder={props.passwordPlaceholder}
                      password={props.password}
                      setPassword={(value: string) =>
                        props.setPassword && props.setPassword(value)
                      }
                      error={
                        props.error && props.error.password
                          ? props.error.password
                          : null
                      }
                      showPassText={props.showPassText}
                      hidePassText={props.hidePassText}
                    />
                  </FlexRow>
                  <FlexRow style={{ width: isMobile ? '100%' : '50%' }}>
                    <FOHTextField
                      secureTextEntry={true}
                      autoCompleteType={'password'}
                      placeholder={props.confirmPasswordPlaceholder}
                      value={props.confirmPassword}
                      onChangeText={(value: string) =>
                        props.setConfirmPassword &&
                        props.setConfirmPassword(value)
                      }
                      error={
                        props.error && props.error.confirmPassword
                          ? props.error.confirmPassword
                          : null
                      }
                    />
                  </FlexRow>
                </FlexRow>
                <FlexRow>
                  <FOHTerms
                    //@ts-ignore as we wrapped with withError prop where onPressTermsLink not exist
                    onPressTermsLink={props.onPressTermsLink}
                    terms={props.terms}
                    setTerms={props.setTerms}
                    termsLinkText={props.termsLinkText}
                    termsText={props.termsText}
                    error={
                      props.error && props.error.terms
                        ? props.error.terms
                        : null
                    }
                  />
                </FlexRow>
              </>
            )}
          </View>
        </FullWidthCard>
      </ContainerView>
    </FOHForm>
  );
};

const FlexRow = styled(View)`
  flex-direction: row;
  padding: 4px;
  width: 100%;
`;

const LoginBannerView = styled(View)`
  background-color: #dff1e7;
  border-radius: 3px;
  padding: 8px;
`;

const ContainerView = styled(View)`
  width: 100%;
  align-items: center;
`;
