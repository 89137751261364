import React from 'react';

import styled from 'styled-components/native';
import { Image } from 'react-native';

import {
  FullWidthCard,
  FOHView,
  FOHSpaceLarger,
  FOHSpace,
  FOHSpaceSmall,
  FOHHeaderH2,
  FOHSmallDetailLabel,
  FOHButton,
  FOHColors,
  FOHFonts
} from '../../ingredients';

export interface FOHEmptyStageCellProps {
  isMobile?: boolean;
  image: string;
  onCta: (ctaUrl: string) => void;
  onLearnMore: (learnMoreUrl: string) => void;
  translations: {
    headerText: string;
    detailText: string;
    ctaButtonLabel: string;
    ctaUrl: string;
    learnMoreButtonLabel: string;
    learnMoreUrl: string;
    learnMoreGif: string;
  };
}

export const FOHEmptyStageCell = (props: FOHEmptyStageCellProps) => (
  <FullWidthCard
    noBorder={true}
    style={{
      padding: 16,
      marginBottom: 0,
      backgroundColor: FOHColors.LIGHT_GRAY,
      height: 300
    }}
  >
    <Container>
      <Block mobile={props.isMobile}>
        <FOHHeaderH2 style={{ textAlign: 'left' }}>
          {props.translations.headerText}
        </FOHHeaderH2>
        <FOHSpace />
        <FOHDetail>{props.translations.detailText}</FOHDetail>
        <FOHSpace />
        <ActionSection>
          <FOHButton
            style={{
              maxWidth: 240,
              width: '100%'
            }}
            title={props.translations.ctaButtonLabel}
            onPress={() => props.onCta(props.translations.ctaUrl)}
            color={FOHColors.BLACK}
            textColor={FOHColors.WHITE}
            borderColor={FOHColors.SILVER_POLISH}
          />
          {!props.isMobile ? <FOHSpaceLarger /> : <FOHSpaceSmall />}
          <FOHButton
            style={{
              maxWidth: 240,
              width: '100%'
            }}
            title={props.translations.learnMoreButtonLabel}
            onPress={() => props.onLearnMore(props.translations.learnMoreUrl)}
            color={FOHColors.WHITE}
            textColor={FOHColors.BLACK}
            borderColor={FOHColors.SILVER_POLISH}
          />
        </ActionSection>
      </Block>
      <GifContainer mobile={props.isMobile}>
        <FOHGif
          source={{
            uri: props?.translations.learnMoreGif
          }}
        />
      </GifContainer>
      <ImageContainer mobile={props.isMobile}>
        <FOHImage
          source={{
            uri: props?.image
          }}
        />
      </ImageContainer>
    </Container>
  </FullWidthCard>
);

const Container = styled(FOHView)`
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
`;

const ImageContainer = styled(FOHView)<{ mobile?: boolean }>`
  width: ${(p: any) => (p.mobile ? '100%' : '192px')};
  height: ${(p: any) => (p.mobile ? '100%' : '144px')};
  align-items: center;
  justify-content: center;
`;

const FOHImage = styled(Image)`
  max-width: 192px;
  width: 100%;
  height: 144px;
  object-fit: cover;
`;

const GifContainer = styled(FOHView)<{ mobile?: boolean }>`
  width: ${(p: any) => (p.mobile ? '100%' : '444px')};
  height: ${(p: any) => (p.mobile ? '100%' : '250px')};
  align-items: center;
  display: flex;
  justify-content: flex-start;
`;

const FOHGif = styled(Image)`
  max-width: 444px;
  width: 100%;
  height: 250px;
  object-fit: cover;
`;

const Block = styled(FOHView)<{ mobile?: boolean }>`
  padding: ${(p: any) => (p.mobile ? '10px' : '5px')};
  align-items: ${(p: any) => (p.mobile ? 'center' : 'center')};
  justify-content: ${(p: any) => (p.mobile ? 'center' : 'space-between')};
  max-width: ${(p: any) => (p.mobile ? '100%' : '663px')};
  height: 250px;
  flex: 1;
`;

const FOHDetail = styled(FOHSmallDetailLabel)`
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: ${FOHColors.NARO_GREY};
  align-self: flex-start;
  padding-left: 30px;
`;

const ActionSection = styled(FOHView)<{ mobile?: boolean }>`
  flex-direction: ${(p: any) => (p.mobile ? 'column' : 'row')};
  align-items: center;
  flex-wrap: wrap;
  display: flex;
  width: 100%;
  justify-content: center;
`;
