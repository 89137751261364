import React, { FunctionComponent } from 'react';

import { FOHButton, FOHImage, SPACING_SCALE } from '../../ingredients';

import {
  Container,
  Heading,
  Message,
  Wrapper
} from './styled/FOHCareerPageMobileForm.styled';

interface FOHCareerPageMobileFormProps {
  image: string;
  href: string;
  translations: Record<string, string>;
}

export const FOHCareerPageMobileForm: FunctionComponent<
  FOHCareerPageMobileFormProps
> = ({ href, image, translations }) => (
  <Wrapper>
    <Container>
      <Heading>{translations.header}</Heading>
      <Message>{translations.message}</Message>

      <FOHButton
        onPress={() => (window.location.href = href)}
        style={{ marginBottom: SPACING_SCALE.massive }}
        title={translations.button}
      />

      <FOHImage
        resizeMode="contain"
        source={{ uri: image }}
        style={{ height: '348px', maxWidth: '70vw', width: '280px' }}
      />
    </Container>
  </Wrapper>
);
