import React, { FunctionComponent } from 'react';

import styled from 'styled-components/native';
import { useTheme } from 'styled-components';

import { toFullDate } from '../../../utils';

import {
  FOHColors,
  FOHImage,
  FOHInverseButton,
  FOHInverseButtonProps,
  FOHLabel,
  FOHRightArrow,
  FOHView,
  SPACING_SCALE,
  FOHMoneyIcon,
  FOHAltTextButton,
  FOHSmallDetailLabel,
  FOHHeaderH2
} from '../../ingredients';
import { useIsMobile } from '../../spices';
import { CommonProps, PaymentPeriod, PaymentPeriodString } from '../../typings';
import { FOHCareerPagePositionsProps } from '../../templates';

export interface FOHOpenPositionProps {
  baseSalaryMax: number;
  baseSalaryMin: number;
  baseSalaryUnitText: PaymentPeriodString;
  createdAt: string;
  posted: string;
  id: number;
  locationAddress: string;
  locationLogo: string;
  locationName: string;
  positionName: string;
  slug: string;
  hiring: boolean;
  onPositionDetailsPress: FOHCareerPagePositionsProps['onPositionDetailsPress'];
  onApplyPress: FOHCareerPagePositionsProps['onApplyPress'];
  translations: Record<string, string>;
}

export const FOHOpenPosition: FunctionComponent<FOHOpenPositionProps> = ({
  baseSalaryMax,
  baseSalaryMin,
  baseSalaryUnitText,
  posted,
  locationAddress,
  locationLogo,
  locationName,
  hiring,
  onApplyPress,
  onPositionDetailsPress,
  positionName,
  slug,
  translations
}) => {
  const isMobile = useIsMobile();
  const theme = useTheme();

  const handleOnPositionDetailsPress = () => {
    onPositionDetailsPress(slug);
  };

  const handleOnApplyPress = () => {
    onApplyPress(slug);
  };

  return (
    <PositionBody>
      <Head isMobile={isMobile}>
        {!isMobile && <LocationImage source={{ uri: locationLogo }} />}

        <FOHView style={{ flexGrow: 1, flexShrink: 1 }}>
          <InfoAndCTAWrapper isMobile={isMobile}>
            <DateAndPositionWrapper isMobile={isMobile}>
              {posted ? (
                <Date
                  isMobile={isMobile}
                  style={{
                    color: theme?.fontColor,
                    fontFamily: theme?.fontFamily
                  }}
                >
                  {`${translations.postedAtLabel}${toFullDate(posted)}`}
                </Date>
              ) : (
                <></>
              )}
              <PositionName
                isMobile={isMobile}
                style={{
                  color: theme?.fontColor,
                  fontFamily: theme?.fontFamily
                }}
              >
                {positionName}
              </PositionName>
            </DateAndPositionWrapper>

            {!isMobile && (
              <ApplyCTA
                isMobile={isMobile}
                onPress={handleOnApplyPress}
                disabled={!hiring}
                title={translations.applyPositionButtonLabel}
              />
            )}
          </InfoAndCTAWrapper>

          <Location
            style={{
              color: theme?.fontColor,
              fontFamily: theme?.fontFamily
            }}
          >
            <LocationName
              style={{
                color: theme?.fontColor,
                fontFamily: theme?.fontFamily
              }}
            >{`${locationName}, `}</LocationName>
            {locationAddress}
          </Location>
        </FOHView>
      </Head>

      <Footer isMobile={isMobile}>
        {isMobile && (
          <ApplyCTA
            isMobile={isMobile}
            disabled={!hiring}
            onPress={handleOnApplyPress}
            title={translations.applyPositionButtonLabel}
          />
        )}
        <DetailsLinkWrapper isMobile={isMobile}>
          <PositionDetails
            icon={() => <FOHRightArrow />}
            onPress={handleOnPositionDetailsPress}
            style={{
              color: theme?.fontColor,
              fontFamily: theme?.fontFamily
            }}
            title={translations.positionDetailButtonLabel}
          />
        </DetailsLinkWrapper>
        {baseSalaryMin !== 0 && baseSalaryMax !== 0 && (
          <SalaryWrapper isMobile={isMobile}>
            <FOHMoneyIcon />
            <SalaryInfo
              style={{
                color: theme?.fontColor,
                fontFamily: theme?.fontFamily
              }}
            >
              {`$${baseSalaryMin} - $${baseSalaryMax} ${PaymentPeriod[baseSalaryUnitText]}`}
            </SalaryInfo>
          </SalaryWrapper>
        )}
      </Footer>
    </PositionBody>
  );
};

const PositionBody = styled(FOHView)`
  margin-bottom: ${SPACING_SCALE.larger}px;
  padding: ${SPACING_SCALE.huge}px;
  border-radius: 4px;
  border-width: 2px;
  border-style: 'solid';
  border-color: ${FOHColors.BORDER_GRAY};
  background-color: ${FOHColors.WHITE};
`;

const Head = styled(FOHView)<CommonProps>`
  ${({ isMobile }: { isMobile: boolean }) =>
    isMobile
      ? `margin-bottom: ${SPACING_SCALE.larger}px`
      : `
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: ${SPACING_SCALE.huge}px;
        column-gap: ${SPACING_SCALE.huge}px;
      `}
`;

const InfoAndCTAWrapper = styled(FOHView)<CommonProps>`
  ${({ isMobile }: { isMobile: boolean }) =>
    isMobile
      ? `flex-direction: column;`
      : `
        flex-direction: row;
        flex-shrink: 1;
        column-gap: ${SPACING_SCALE.huge}px;
      `}
`;

const DateAndPositionWrapper = styled(FOHView)<CommonProps>`
  ${({ isMobile }: { isMobile: boolean }) =>
    isMobile
      ? ``
      : `
      flex-shrink: 1;
        flex-grow: 1;
    `}
`;

const LocationImage = styled(FOHImage)`
  width: 72;
  height: 72;
`;

const Date = styled(FOHSmallDetailLabel)<CommonProps>`
  font-size: 12px;

  ${({ isMobile }: { isMobile: boolean }) =>
    isMobile
      ? `margin-bottom: ${SPACING_SCALE.regular}px`
      : `
        margin-top: ${SPACING_SCALE.small}px;
        margin-bottom: ${SPACING_SCALE.smallest}px;
    `}
`;

const PositionName = styled(FOHHeaderH2)<CommonProps>`
  text-align: left;

  ${({ isMobile }: { isMobile: boolean }) =>
    isMobile ? `margin-bottom: ${SPACING_SCALE.regular}px` : ``}
`;

const Location = styled(FOHSmallDetailLabel)`
  display: inline;
  line-height: 24px;
`;

const LocationName = styled(FOHSmallDetailLabel)`
  color: ${FOHColors.BLACK};
`;

const Footer = styled(FOHView)<CommonProps>`
  ${({ isMobile }: { isMobile: boolean }) =>
    isMobile
      ? ``
      : `
        flex-direction: row-reverse;
        justify-content: space-between;
      `}
`;

const SalaryWrapper = styled(FOHView)<CommonProps>`
  flex-direction: row;

  ${({ isMobile }: { isMobile: boolean }) =>
    isMobile ? `margin-bottom: ${SPACING_SCALE.larger}px` : ``}
`;

const SalaryInfo = styled(FOHLabel)`
  margin-left: ${SPACING_SCALE.regular}px;
  text-transform: capitalize;
`;

const ApplyCTA: FunctionComponent<
  FOHInverseButtonProps & {
    isMobile: boolean;
  }
> = props => {
  const theme = useTheme();

  return (
    <FOHInverseButton
      color={theme?.brandColor || FOHColors.PACIFIC_BLUE}
      opaque={true}
      selected={true}
      style={{
        height: props.isMobile ? 50 : 40,
        margin: props.isMobile ? 'auto' : 0,
        width: props.isMobile ? '100%' : 130
      }}
      textColor={FOHColors.WHITE}
      {...props}
    />
  );
};

const DetailsLinkWrapper = styled(FOHView)<CommonProps>`
  ${({ isMobile }: { isMobile: boolean }) =>
    isMobile ? `margin: ${SPACING_SCALE.huge}px auto 0;` : ``}
`;

const PositionDetails = styled(FOHAltTextButton)`
  font-size: 16;
  color: 'black';
  text-decoration: underline;
`;
