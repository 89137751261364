import React from 'react';

import { useTranslation } from 'react-i18next';

import { FOHOptionsDropdown, FOHOptionsType } from '../../components';

export const getDefaultStatusOptions = t => [
  {
    label: t('editProfilePhotoLabel'),
    value: FOHOptionsType.EDIT_PROFILE_PHOTO,
    route: 'edit/image?id='
  },
  // TODO: add a remove mutation
  // {
  //   label: t('removeProfilePhotoLabel'),
  //   value: FOHOptionsType.REMOVE_PROFILE_PHOTO,
  //   route: 'edit/image?id='
  // },
  {
    label: t('uploadPersonalVideoLabel'),
    value: FOHOptionsType.UPLOAD_PERSONAL_VIDEO,
    route: 'edit/video?id='
  }
];

export const UpdateOptionsDropdown = _props => {
  const { t } = useTranslation('ManageProfilePreferencesFeature');
  const options = getDefaultStatusOptions(t);

  return (
    <FOHOptionsDropdown
      openOptions={_props.open}
      selectedOption={_props.setSelectedOption}
      options={options}
    />
  );
};
