import React from 'react';

import { useParams } from 'react-router-dom';

import { useNavigation } from '../../utils/navigation';

import { RequestEndorsementModal } from '../Endorse';

import { EditContactInformationModal } from './EditContactInformationModal';
import { PositionTypeModal } from './PositionTypeScreen';
import { YearsExpModal } from './YearsExpScreen';
import { SKillsModal } from './SkillsScreen';
import { CommuteEditModal } from './LocationScreen';
import { WorkHistoryModal } from './WorkHistoryScreen';
import { TopThreeModal } from './TopThreeScreen';
import { AvailabilityModal } from './AvailabilityScreen';
import { UploadVideoModal } from './UploadVideoScreen';
import { WorkPreferencesModal } from './WorkPreferencesScreen';
import { UploadPhotoModal } from './UploadPhotoScreen';
import { PersonalSummaryModal } from './PersonalSummaryScreen';
import { RestaurantStyleModal } from './RestaurantStyleScreen';
import { ResumeUploadModal } from './ResumeUploadScreen';

export const EditProfileModals = props => {
  const { modal } = useParams();
  const { goBack } = useNavigation();

  const close = () => {
    goBack();
  };

  return (
    <>
      <EditContactInformationModal
        {...props}
        open={modal === 'contact'}
        close={close}
      />
      <PositionTypeModal
        {...props}
        open={modal === 'positions'}
        close={close}
      />
      <YearsExpModal {...props} open={modal === 'experience'} close={close} />
      <SKillsModal {...props} open={modal === 'skills'} close={close} />
      <CommuteEditModal {...props} open={modal === 'commute'} close={close} />
      <WorkHistoryModal
        {...props}
        open={modal === 'workhistory'}
        close={close}
      />
      <TopThreeModal {...props} open={modal === 'top-three'} close={close} />
      <AvailabilityModal
        {...props}
        open={modal === 'availability'}
        close={close}
      />
      <UploadVideoModal {...props} open={modal === 'video'} close={close} />
      <WorkPreferencesModal
        {...props}
        withoutStatus={true}
        withoutBenefits={true}
        open={modal === 'dresscode'}
        close={close}
      />
      <WorkPreferencesModal
        {...props}
        withoutStatus={true}
        withoutDresscode={true}
        open={modal === 'benefits'}
        close={close}
      />
      <UploadPhotoModal
        {...props}
        withoutStatus={true}
        withoutDresscode={true}
        open={modal === 'image'}
        close={close}
      />
      <PersonalSummaryModal
        {...props}
        withoutStatus={true}
        withoutDresscode={true}
        open={modal === 'summary'}
        close={close}
      />
      <RestaurantStyleModal
        {...props}
        withoutStatus={true}
        withoutDresscode={true}
        open={modal === 'style'}
        close={close}
      />
      <RequestEndorsementModal open={modal === 'references'} close={close} />
      <ResumeUploadModal open={modal === 'resume'} close={close} />
    </>
  );
};
