import { SalaryUnitChoices } from '../components';

// Categories for message template
export const getTemplateCategoriesLabels = t => [
  {
    label: t('CandidateCardFeature:chat'),
    value: 'OTHER'
  },
  {
    label: t('screening'),
    value: 'SCREENING'
  },
  {
    label: t('interview'),
    value: 'INTERVIEW'
  },
  {
    label: t('offers'),
    value: 'OFFER'
  },
  {
    label: t('common:interview.hired'),
    value: 'HIRED'
  }
];

export const VIDEO_QUESTIONNAIRE = 'video';
export const TEXT_QUESTIONNAIRE = 'questionnaire';
export const INTERVIEW_REQUEST_SEND_SCHEDULE =
  'interview-request-send-schedule';
export const INTERVIEW_REQUEST_SEND_DATE_TIME =
  'interview-request-send-date-time';
export const OFFER_LETTER = 'offer-letter';
export const ONBOARDING_LETTER = 'onboarding-letter';
export const NONE = 'none';

// Template actions while selecting from dropdown
export const getTemplateActionLabels = (t, filterBy = 'none') =>
  [
    {
      value: INTERVIEW_REQUEST_SEND_SCHEDULE,
      label: t('interviewRequestSendSchedule'),
      category: 'INTERVIEW'
    },
    {
      value: INTERVIEW_REQUEST_SEND_DATE_TIME,
      label: t('interviewRequestSendDateTime'),
      category: 'INTERVIEW'
    },
    {
      value: 'reject',
      label: t('rejectCandidate'),
      category: 'OTHER'
    },
    {
      value: OFFER_LETTER,
      label: t('CandidateFeed:offerLetterLabel'),
      category: 'OFFER'
    },
    {
      value: ONBOARDING_LETTER,
      label: t('onboardingLetter'),
      category: 'HIRED'
    }
  ].filter(
    placeHolder =>
      placeHolder.category === filterBy || placeHolder.category === NONE
  );

// Template types
export const getTemplateTypesLabels = t => [
  {
    label: t('EmployerSettingsFeature:global'),
    value: 'global'
  },
  {
    label: t('EmployerSettingsFeature:personal'),
    value: 'personal'
  }
];

// Compensation types
export const getUnitCompensationLabels = t => [
  { value: SalaryUnitChoices.HOUR, label: t('PositionsFeature:hourly') },
  {
    value: SalaryUnitChoices.HOURLY_TIPS,
    label: t('PositionsFeature:hourlyTipsWage')
  },
  { value: SalaryUnitChoices.YEAR, label: t('PositionsFeature:annual') }
];

// Stages selection options
export const getStagesFilterOptions = t => [
  {
    title: t('CandidateFeed:showAll'),
    route: 'SHOW_ALL',
    key: 'ShowAll'
  },
  {
    title: t('CandidateCardFeature:chat'),
    route: 'OTHER',
    key: 'other'
  },
  {
    title: t('screening'),
    route: 'SCREENING',
    key: 'screening'
  },
  {
    title: t('interview'),
    route: 'INTERVIEW',
    key: 'interview'
  },
  {
    title: t('offers'),
    route: 'OFFER',
    key: 'offer'
  },
  {
    title: t('CandidateFeed:hiredLabel'),
    route: 'HIRED',
    key: 'hired'
  }
];

export const getScreeningTemplateActionLabels = t => [
  {
    label: t('common:questionnaire'),
    value: 'questionnaire'
  },
  {
    label: t('ProfileFeature:videoLabel'),
    value: 'video'
  }
  // {
  //   label: t('CandidateFeed::phoneScreeningLabel'),
  //   value: 'phoneScreen'
  // }
];

export const getTemplatePlaceHolders = (
  t,
  filterBy = 'none',
  returnObject = false
) =>
  [
    {
      title: t('MessageTemplatesFeature:screeningLink'),
      value: t('MessageTemplatesFeature:screeningLink'),
      action: VIDEO_QUESTIONNAIRE
    },
    {
      title: t('MessageTemplatesFeature:screeningLink'),
      value: t('MessageTemplatesFeature:screeningLink'),
      action: TEXT_QUESTIONNAIRE
    },
    {
      title: t('MessageTemplatesFeature:schedulingLink'),
      value: t('MessageTemplatesFeature:schedulingLink'),
      action: INTERVIEW_REQUEST_SEND_SCHEDULE
    },
    {
      title: t('MessageTemplatesFeature:interviewTime'),
      value: t('MessageTemplatesFeature:interviewTime'),
      action: INTERVIEW_REQUEST_SEND_DATE_TIME
    },
    {
      title: t('MessageTemplatesFeature:letterLink'),
      value: t('MessageTemplatesFeature:letterLink'),
      action: OFFER_LETTER
    },
    {
      title: t('MessageTemplatesFeature:letterLink'),
      value: t('MessageTemplatesFeature:letterLink'),
      action: ONBOARDING_LETTER
    },
    ...(returnObject
      ? [
          {
            title: t('MessageTemplatesFeature:candidateName'),
            value: t('MessageTemplatesFeature:candidateName'),
            action: NONE
          },
          {
            title: t('MessageTemplatesFeature:locationName'),
            value: t('MessageTemplatesFeature:locationName'),
            action: NONE
          },
          {
            title: t('MessageTemplatesFeature:positionName'),
            value: t('MessageTemplatesFeature:positionName'),
            action: NONE
          },
          {
            title: t('MessageTemplatesFeature:positionLink'),
            value: t('MessageTemplatesFeature:positionLink'),
            action: NONE
          }
        ]
      : [])
  ]
    .filter(
      placeHolder =>
        placeHolder.action === filterBy || placeHolder.action === NONE
    )
    .map(placeHolder => (returnObject ? placeHolder : placeHolder.value));

export const getPlaceHolderByAction = action => {
  switch (action) {
    case VIDEO_QUESTIONNAIRE:
      return '{{Screening Link}}';
    case TEXT_QUESTIONNAIRE:
      return '{{Screening Link}}';
    case INTERVIEW_REQUEST_SEND_SCHEDULE:
      return '{{Scheduling Link}}';
    case INTERVIEW_REQUEST_SEND_DATE_TIME:
      return '{{Interview Time}}';
    case OFFER_LETTER:
      return '{{Letter Link}}';
    case ONBOARDING_LETTER:
      return '{{Letter Link}}';
    default:
      return '';
  }
};
