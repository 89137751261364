import { useState, useEffect } from 'react';

import { propEq, find, prop, path, defaultTo, length } from 'ramda';
import { useLocalStorage } from 'use-hooks';

import {
  scrollToTop,
  unwrapEdgesAt,
  getOnboardingState,
  handleMutation
} from '../../utils';
import { transformAvailabilityToGraphQL } from '../../api/transformers/candidates';
import { generateKeysForPositionAvailability } from '../../api/transformers/positions';
import {
  useGetFeedLocationAnswersQuery,
  GetMyPositionsPlanDocument,
  PositionMatchQueryDocument,
  useTypeAheadSearchQuery,
  useJobDescriptionQuery,
  MyPositionsDocument,
  usePositionByIdQuery,
  useMutatePositionMutation,
  GetMyDashboardPositionsDocument
} from '../../graphql/generated';
import { SalaryUnitChoices } from '../../components';

import { useAvailability } from '../SharedHooks/useAvailability';
import { useCandidateFeedContext } from '../CandidateFeed/useCandidateFeedContext';

import {
  validatePaymentInput,
  formatToCurrency,
  currecyToFloat
} from './positionPayments';

export const usePositionMutation = props => {
  const {
    positionGlobalId,
    initialLocation,
    initialLocationSlug,
    selectLocationFilter,
    myLocations
  } = props;
  const [positionId, setPositionId] = useLocalStorage(
    'onboarding-position',
    positionGlobalId
  ); // Hook for Persist Position
  const isOnboarding = getOnboardingState() !== null;

  const isNewPosition = !positionId;
  const positionByIdQuery = usePositionByIdQuery({
    skip: isNewPosition,
    variables: isNewPosition ? {} : { id: positionId }
  });
  const position = path(['data', 'positionById'], positionByIdQuery);

  const [positionMutation] = useMutatePositionMutation();

  // TODO: SWAP ALL THIS STATE WITH A GRAPHQL QUERY
  const [name, setName] = useState('');
  const [error, setError] = useState();
  const [training, setTraining] = useState([]);
  const [selectedPositionTypes, setSelectedPositionTypes] = useState([]);
  const [positionTypeText, setPositionTypeText] = useState('');
  const [yearsExp, setYearsExp] = useState('');
  const [notifications, setSendNotifications] = useState(true);
  const [hiring, setHiring] = useState(true);
  const [benefits, setBenefits] = useState(false);
  const [dressCode, setDressCode] = useState(false);
  const [includeSalary, setIncludeSalary] = useState(true);

  const {
    availability,
    selectAvailability,
    allAvailabilitySelected,
    setAvailability,
    toggleAllAvailability,
    setAllAvailabilitySelected
  } = useAvailability();

  const { locationFilters, positionSearch } = useCandidateFeedContext({
    skipPositionsQuery: true,
    skipLocationQuery: true
  });

  const [selectedLocation, setSelectedLocation] = useState();
  const [inProgress, setInProgress] = useState(false);
  const [jobDescription, setJobDescription] = useState('');

  const [minPay, setMinPay] = useState('$0.00');
  const [maxPay, setMaxPay] = useState('$0.00');
  const [paymentType, setPaymentType] = useState(SalaryUnitChoices.HOUR);

  const [indeedEasyapplyOptout, setIndeedEasyapplyOptout] = useState(false);

  const typeAheadQuery = useTypeAheadSearchQuery({
    variables: { search: positionTypeText }
  });

  // Position Types

  const positionTypesFromSearchAhead = defaultTo(
    [],
    path(['data', 'searchAhead', 'groups'], typeAheadQuery)
  ).filter(group => prop('group', group) === 'Position Types');

  const positionTypeOptions = defaultTo(
    [{ value: '', label: '' }],
    path([0, 'values'], positionTypesFromSearchAhead)
  );

  useEffect(() => {
    if (
      (initialLocation || length(myLocations) > 0) &&
      isNewPosition &&
      !selectedLocation
    ) {
      const loc = initialLocation
        ? initialLocation
        : path(['0', 'id'], myLocations);
      setSelectedLocation(loc);
    }
    if (!isNewPosition && length(myLocations) > 0 && !selectedLocation) {
      setSelectedLocation(path(['location', 'id'], position));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLocation, myLocations, positionByIdQuery.loading]);

  const getLocationAnswersQuery = useGetFeedLocationAnswersQuery({
    variables: {
      // myLocations may not have anything in it if you are claiming your
      // first location and position so pass in the slug
      slug: initialLocationSlug
        ? initialLocationSlug
        : prop('slug', find(propEq('id', selectedLocation), myLocations))
    }
  });

  const answers = unwrapEdgesAt(
    ['data', 'location', 'answers', 'edges'],
    getLocationAnswersQuery
  );

  // TODO find a better way to tie these questions together
  const locationHealthInsuranceQuestion = prop(
    0,
    answers.filter(ans =>
      path(['question', 'title'], ans).includes('insurance')
    )
  );

  const specifiedYesOrOther = value => value === 'other' || value === 'yes';

  const locationOffersHealthInsurance = specifiedYesOrOther(
    defaultTo('', path(['option', 'value'], locationHealthInsuranceQuestion))
  );

  // TODO find a better way to tie these questions together
  const locationDressCodeQuestion = prop(
    0,
    answers.filter(ans => path(['question', 'title'], ans).includes('dress'))
  );

  const locationHasStrictDressCode = specifiedYesOrOther(
    defaultTo('', path(['option', 'value'], locationDressCodeQuestion))
  );

  useEffect(() => {
    if (position && !isNewPosition && !positionByIdQuery.loading) {
      const availabilityMap = generateKeysForPositionAvailability(position);
      setName(prop('positionName', position));
      setTraining(prop('preferredTraining', position) || []);
      setJobDescription(prop('jobDescription', position) || '');
      // prop('positionType', position)
      if (selectedPositionTypes.length === 0) {
        setSelectedPositionTypes(
          positionTypeOptions.filter(option =>
            prop('positionType', position).includes(prop('value', option))
          )
        );
      }
      setYearsExp(prop('preferredExperience', position));
      setHiring(prop('hiring', position));
      setSendNotifications(prop('sendNotifications', position));
      setMinPay(formatToCurrency(prop('baseSalaryMin', position)));
      setMaxPay(formatToCurrency(prop('baseSalaryMax', position)));
      setPaymentType(prop('baseSalaryUnitText', position));
      setAvailability(availabilityMap);
      setSelectedLocation(
        selectedLocation || path(['location', 'id'], position)
      );
      setBenefits(prop('isBenefitsRequired', position));
      setDressCode(prop('canHaveDressCode', position));
      setIndeedEasyapplyOptout(prop('indeedEasyapplyOptout', position));
    }
    if (isNewPosition) {
      setBenefits(locationOffersHealthInsurance);
      setDressCode(locationHasStrictDressCode);
      setIndeedEasyapplyOptout(
        prop(
          'indeedEasyapplyOptoutDefault',
          find(propEq('id', selectedLocation), myLocations)
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    positionByIdQuery.loading,
    isNewPosition,
    selectedLocation,
    getLocationAnswersQuery.loading,
    typeAheadQuery.loading
  ]);

  const getJobDescQuery = useJobDescriptionQuery({
    skip: selectedPositionTypes.length === 0,
    variables: {
      positionType:
        selectedPositionTypes.length > 0 && selectedPositionTypes[0].value
    }
  });

  useEffect(() => {
    const strippedJD = defaultTo('', jobDescription).replace(
      /(<([^>]+)>)/gi,
      ''
    );
    if (
      !!path(['data', 'jobDescriptionForPositionType'], getJobDescQuery) &&
      (!prop('jobDescription', position) || strippedJD === '')
    ) {
      setJobDescription(getJobDescQuery.data.jobDescriptionForPositionType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    path(['data', 'jobDescriptionForPositionType'], getJobDescQuery),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    path([0, 'value'], selectedPositionTypes)
  ]);

  const validateJobDescription = desc => {
    const plainText = new DOMParser().parseFromString(desc, 'text/html')
      .documentElement.textContent;

    if (!plainText || plainText.length <= 0) {
      return 'This is a required field';
    } else {
      return undefined;
    }
  };

  const handleSave = async () => {
    setError();
    setInProgress(true);
    const { payError } = validatePaymentInput(minPay, maxPay);
    if (payError) {
      scrollToTop({});
      setInProgress(false);

      return setError(payError);
    }

    const descError = validateJobDescription(jobDescription);

    if (descError) {
      scrollToTop({});
      setInProgress(false);

      return setError({ jobDescription: descError });
    }

    const updatedAvailability = transformAvailabilityToGraphQL({
      availability
    });

    const variables = {
      positionName: name,
      positionType: selectedPositionTypes
        .map(item => prop('value', item))
        .filter(item => !!item),
      hiring: hiring,
      sendNotifications: notifications,
      location: selectedLocation,
      canHaveDressCode: dressCode,
      isBenefitsRequired: benefits,
      baseSalaryMax: currecyToFloat(maxPay),
      baseSalaryMin: currecyToFloat(minPay),
      baseSalaryUnitText: paymentType,
      preferredExperience: yearsExp === 'show_all' ? '' : yearsExp || '',
      preferredTraining: defaultTo([], training).filter(item => item !== ''),
      jobDescription,
      indeedEasyapplyOptout,
      ...updatedAvailability
    };

    const positionVariables = {
      variables: positionId
        ? {
            id: positionId,
            ...variables
          }
        : {
            ...variables
          }
    };

    const refetchQueries = [
      // refetch new list
      {
        query: MyPositionsDocument,
        variables: {
          location: selectedLocation,
          search: ''
        }
      },
      {
        query: GetMyPositionsPlanDocument,
        variables: {
          location: selectedLocation
        }
      },
      {
        query: MyPositionsDocument,
        variables: {
          locations: defaultTo([], locationFilters).filter(a => a),
          search: positionSearch
        }
      },
      {
        query: GetMyDashboardPositionsDocument,
        variables: {
          location: selectedLocation,
          first: 10,
          after: '',
          search: ''
        }
      }
    ];

    // refetch old list and feed
    const myPositionsQuery = !isNewPosition
      ? [
          {
            query: PositionMatchQueryDocument,
            variables: {
              first: 20,
              interviewStatus: undefined,
              page: 1,
              position: prop('id', position),
              search: ''
            }
          },
          {
            query: MyPositionsDocument,
            variables: {
              location: path(['location', 'id'], position),
              search: ''
            }
          }
        ]
      : [];

    const [resp, err] = await handleMutation(
      positionMutation({
        awaitRefetchQueries: true,
        ...positionVariables,
        refetchQueries: [...refetchQueries, ...myPositionsQuery]
      })
    );

    if (err) {
      setInProgress(false);
      setError(err);
      return false;
    }

    const locationGlobalId = selectedLocation;
    const globalPositionID = path(['mutatePosition', 'position', 'id'], resp);
    isOnboarding && setPositionId(globalPositionID);
    selectLocationFilter(locationGlobalId);
    setInProgress(false);
    return {
      locationGlobalId,
      positionGlobalId: globalPositionID,
      position: { ...path(['mutatePosition', 'position'], resp) }
    };
  };

  return {
    position,
    name,
    setName,
    error,
    setError,
    training,
    setTraining,
    selectedPositionTypes,
    setSelectedPositionTypes,
    positionTypeText,
    setPositionTypeText,
    positionTypeOptions,
    yearsExp,
    setYearsExp,
    notifications,
    setSendNotifications,
    hiring,
    setHiring,
    benefits,
    setBenefits,
    dressCode,
    setDressCode,
    availability,
    setAvailability,
    selectAvailability,
    allAvailabilitySelected,
    setAllAvailabilitySelected,
    toggleAllAvailability,
    selectedLocation,
    setSelectedLocation,
    inProgress,
    minPay,
    setMinPay,
    maxPay,
    setMaxPay,
    paymentType,
    setPaymentType,
    isNewPosition,
    handleSave,
    includeSalary,
    setIncludeSalary,
    jobDescription,
    setJobDescription,
    indeedEasyapplyOptout,
    setIndeedEasyapplyOptout
  };
};
