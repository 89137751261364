import React from 'react';

import {
  Card,
  FOHAltTextButton,
  FOHColors,
  FOHGrayEyeIcon,
  FOHInverseButton,
  FOHLabel,
  FOHResumeIcon,
  FOHSmallDetailLabel,
  FOHSpace,
  FOHSpaceSmall,
  FOHTouchableOpacity,
  FOHUploadIcon,
  FOHView
} from '../../ingredients';

export interface FOHResumeBlockProps {
  resumeCtaText: string; // update || preview resume
  onPressResumeCTA: () => void; // upload || open tab
  resumeUpdatedAt: string;
  resumeName: string;
  resume?: string;
  resumeLabel: string; // my resume
  resumeViewer: ResumeViewer;
}

export enum ResumeViewer {
  candidate = 'candidate',
  employer = 'employer' // default to employer
}

export const FOHResumeBlock = (props: FOHResumeBlockProps) =>
  props.resume ? (
    <Card
      style={{
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
        padding: 16,
        margin: 0,
        display: 'flex',
        flexWrap: 'wrap',
        minWidth: '100%',
        maxWidth: '100%',
        left: 0
      }}
    >
      <FOHView style={{ padding: 5 }}>
        <FOHView style={{ flexDirection: 'row' }}>
          <FOHResumeIcon style={{ tintColor: FOHColors.GRAYSCALE_700 }} />
          <FOHSpaceSmall />
          <FOHLabel>{props.resumeLabel}</FOHLabel>
        </FOHView>
        <FOHSpace />
        <FOHView style={{ flexDirection: 'row' }}>
          <FOHLabel
            style={{
              maxWidth: 200,
              paddingLeft: 23
            }}
          >
            {props.resumeName}
          </FOHLabel>
        </FOHView>
      </FOHView>

      <FOHView style={{ padding: 5 }}>
        <FOHTouchableOpacity
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
          onPress={props.onPressResumeCTA}
        >
          {props.resumeViewer === ResumeViewer.candidate ? (
            <FOHUploadIcon />
          ) : (
            <FOHGrayEyeIcon
              style={{
                height: 12,
                width: 17,
                tintColor: FOHColors.PRIMARY_TEAL_P1
              }}
            />
          )}
          <FOHSpaceSmall />
          <FOHAltTextButton
            title={props.resumeCtaText}
            onPress={props.onPressResumeCTA}
          ></FOHAltTextButton>
        </FOHTouchableOpacity>
        <FOHSpace />
        <FOHSmallDetailLabel style={{ textAlign: 'right' }}>
          {props.resumeUpdatedAt}
        </FOHSmallDetailLabel>
      </FOHView>
    </Card>
  ) : (
    <FOHInverseButton
      onPress={props.onPressResumeCTA}
      title={props.resumeCtaText}
      color={FOHColors.GRAYSCALE_700}
      icon={() =>
        FOHResumeIcon({ style: { tintColor: FOHColors.GRAYSCALE_700 } })
      }
      style={{
        minWidth: '100%'
      }}
    />
  );
