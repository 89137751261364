import React from 'react';

import unreject from '../../../images/unreject_ic.svg';

import { FOHImage } from '../../ingredients';

export const FOHUnrejectIcon = (props: any) => (
  <FOHImage
    source={{ uri: unreject }}
    style={
      props && !!props.style
        ? { width: 18, height: 18, ...props.style }
        : { width: 18, height: 18 }
    }
  />
);
