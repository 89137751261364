import { DateTime } from 'luxon';
import { defaultTo, toUpper } from 'ramda';

const time = DateTime.local();
export const browserTimezone = time.zoneName;

export const toLongDateTime = isoString => {
  return DateTime.fromISO(isoString, {
    zone: 'local'
  }).toLocaleString({
    weekday: 'short',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    timeZoneName: 'short'
  });
};

export const toDate = (isoString, format = 'M/dd') => {
  return DateTime.fromISO(isoString, {
    zone: 'local'
  }).toFormat(format);
};

export const toFullDate = (isoString, format = 'M/dd/yyyy') => {
  return DateTime.fromISO(isoString, {
    zone: 'local'
  }).toFormat(format);
};

export const toDateTime = isoString => {
  return DateTime.fromISO(isoString, {
    zone: 'local',
    locale: 'en-US'
  }).toFormat('EEE, MMM d, h:mm a ZZZZ'); //Tue, Mar 22, 2:00 PM EST
};

export const toShortDay = isoString => {
  return toUpper(
    DateTime.fromISO(isoString, {
      zone: 'local'
    }).toLocaleString({ weekday: 'short' })
  );
};

export const toTime = isoString => {
  return toUpper(
    DateTime.fromISO(isoString, {
      zone: 'local'
    }).toFormat('h:mm a ZZZZ')
  );
};

export const toShortTime = (isoString, format = 'hh:mm a') =>
  toUpper(
    DateTime.fromISO(isoString, {
      zone: 'local'
    }).toFormat(format)
  );

export const to24HoursTime = isoString => {
  let dateTime = DateTime.fromISO(isoString, {
    zone: 'local'
  });
  return dateTime.toLocaleString(DateTime.TIME_24_SIMPLE);
};

export const toRelativeTime = isoString => {
  return DateTime.fromISO(isoString, {
    zone: 'local'
  }).toRelative();
};

export const toLocaleDateTime = isoString =>
  DateTime.fromISO(isoString, {
    zone: 'local'
  }).toFormat("LL/dd/yyyy 'at' hh:mm a");

export const formatRangeToTime = (hour, minute) => {
  return DateTime.fromObject({
    hour: defaultTo(1, hour),
    minute: defaultTo(0, minute),
    zone: 'local'
  }).toFormat('h:mm a ZZZZ');
};

// no timezones for time select
export const formatRangeToTimeSelect = (hour, minute) => {
  return hour !== undefined && minute !== undefined
    ? DateTime.fromObject({
        hour: defaultTo(1, hour),
        minute: defaultTo(0, minute),
        zone: 'local'
      }).toFormat('h:mm a')
    : undefined;
};
