import React from 'react';

import styled from 'styled-components/native';
import { Text } from 'react-native';
import { defaultTo, prop } from 'ramda';

import { FOHFonts } from '../text/FOHFonts';
import { FOHTouchableOpacity, FOHView } from '../containers/Containers';

export interface FOHMultiPositionTagProps {
  onPress: () => any;
  value: number;
  style?: any;
}

export const FOHMultiPositionTag = (props: FOHMultiPositionTagProps) => (
  <Border>
    <FOHTouchableOpacity
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        ...defaultTo({}, prop('style', props))
      }}
      onPress={props.onPress}
    >
      <LinkText>+{props.value} more</LinkText>
    </FOHTouchableOpacity>
  </Border>
);

const Border = styled(FOHView)`
  align-items: center;
  border-radius: 100px;
  justify-content: center;
  width: 68px;
  height: 24px;
  border: 1.5px solid #17abcc;
  padding: 4px 8px;
`;

const LinkText = styled(Text)`
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  align-items: center;
  color: #17abcc;
  text-decoration: underline;
  text-decoration-color: #17abcc;
`;
