import React from 'react';

import styled from 'styled-components/native';
import { prop } from 'ramda';

import {
  FOHView,
  FOHFonts,
  FOHSpaceSmall,
  FOHAvatar,
  FOHGrayCirclePlayIcon,
  FOHGrayCircleTrashCanIcon,
  FOHHeaderH4
} from '../../ingredients';

export enum FOHOptionsType {
  EDIT_PROFILE_PHOTO = 'EDIT_PROFILE_PHOTO',
  REMOVE_PROFILE_PHOTO = 'REMOVE_PROFILE_PHOTO',
  UPLOAD_PERSONAL_VIDEO = 'UPLOAD_PERSONAL_VIDEO'
}

export interface FOHOptionsProps {
  optionType: FOHOptionsType;
  optionText: string;
}

export const FOHProfileOptions = [
  FOHOptionsType.EDIT_PROFILE_PHOTO,
  FOHOptionsType.REMOVE_PROFILE_PHOTO,
  FOHOptionsType.UPLOAD_PERSONAL_VIDEO
];

export const FOHOptions = (props: FOHOptionsProps) => {
  const optionType = prop('optionType', props);
  const validStatus = FOHProfileOptions.includes(optionType);
  const StatusIcon = validStatus
    ? {
        [FOHOptionsType.EDIT_PROFILE_PHOTO]: () => (
          <FOHAvatar style={{ width: 30, height: 30 }} />
        ),
        [FOHOptionsType.REMOVE_PROFILE_PHOTO]: FOHGrayCircleTrashCanIcon,
        [FOHOptionsType.UPLOAD_PERSONAL_VIDEO]: FOHGrayCirclePlayIcon
      }[optionType]
    : FOHAvatar;

  return (
    <FOHView style={{ flexDirection: 'row', alignItems: 'center' }}>
      <StatusIcon />
      <FOHSpaceSmall />
      {props.optionText ? <OptionText>{props.optionText}</OptionText> : <></>}
    </FOHView>
  );
};

const OptionText = styled(FOHHeaderH4)`
  padding-top: 4px;
  padding-left: 10px;
  font-weight: 400;
  font-style: normal;
  font-family: ${FOHFonts.REGULAR};
  color: #171717;
`;
