import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { path, length } from 'ramda';

import {
  unwrapEdgesAt,
  toDate,
  useIsMobile,
  positionToSalaryText
} from '../../utils';
import { useGetMyMatchesQuery } from '../../graphql/generated';
import { DISCOVER, HOME_PAGE, JOBS, RESTAURANT_JOBS } from '../../constants';
import {
  FOHColors,
  FOHGreenDecoration,
  FOHHeaderH3,
  FOHInverseButton,
  FOHPositionListCell,
  FOHSpace,
  FOHView
} from '../../components';
import { useNavigation } from '../../utils/navigation';

export const TopJobMatches = props => {
  const jobFeatureLocalization = useTranslation('JobApplicationsFeature');
  const T = jobFeatureLocalization.t;
  const { t } = useTranslation('CandidateHomeFeature');

  const { navigateTo } = useNavigation();

  const { isMobile, width } = useIsMobile();

  const variables = {
    first: 3,
    after: '',
    hasApplied: false
  };

  const myMatchesQuery = useGetMyMatchesQuery({
    variables: {
      ...variables
    }
  });

  const matches = useMemo(
    () => unwrapEdgesAt(['data', 'myMatches', 'edges'], myMatchesQuery) ?? [],
    [myMatchesQuery]
  );

  return (
    <FOHView style={{ padding: isMobile ? 10 : 0 }}>
      <FOHView
        style={{
          justifyContent: isMobile ? 'center' : 'flex-start',
          alignItems: isMobile ? 'center' : 'flex-start'
        }}
      >
        <FOHGreenDecoration />
        <FOHSpace />
        <FOHHeaderH3 style={{ textAlign: isMobile ? 'center' : 'left' }}>
          {T('jobsSuggestedForYou')}
        </FOHHeaderH3>
        <FOHSpace />
      </FOHView>
      {length(matches) > 0 &&
        matches.map((match, keyIndex) => (
          <FOHPositionListCell
            mobile={width <= 2000}
            key={`${path(['position', 'id'], match)}-${keyIndex}`}
            positionName={path(['position', 'positionName'], match)}
            positionSlug={path(['position', 'slug'], match)}
            positionId={path(['position', 'id'], match)}
            hiringManagerName={path(
              ['position', 'createdBy', 'firstName'],
              match
            )}
            hiringManagerImage={path(
              ['position', 'createdBy', 'profileImage'],
              match
            )}
            hiringManagerPosition={t('positionOwner')} // Position Owner
            hiringManagerDetailText="" // It usually takes 1-2 weeks to hear back.
            salaryText={positionToSalaryText(path(['position'], match), { t })}
            postedDateText={`${t('posted')}: ${toDate(
              path(['position', 'createdAt'], match)
            )}`}
            // job details
            onPressJobDetails={slug => navigateTo(`${JOBS}/${slug}`)}
            jobDetailsLabel={t('jobDetailsLabel')}
            // Apply
            buttonCTALabel={
              path(['position', 'hasApplied'], match)
                ? t('applied')
                : t('apply')
            }
            onPressCTA={slug => {
              const position = path(['position'], match);
              if (position && position.externalApplyUrl) {
                return (
                  window &&
                  window.open &&
                  window.open(position.externalApplyUrl, '_blank').focus()
                );
              }
              props.openJobApplicationModalForSlug(slug);
            }}
            buttonCTADisabled={path(['position', 'hasApplied'], match)}
            // location
            locationId={path(['position', 'location', 'id'], match)}
            locationName={path(['position', 'location', 'name'], match)}
            locationSlug={path(['position', 'location', 'slug'], match)}
            onPressLocation={slug => navigateTo(`${RESTAURANT_JOBS}/${slug}`)}
            locationImage={path(['position', 'location', 'image'], match)}
            locationHref=""
          />
        ))}
      <FOHView
        style={{
          justifyContent: 'center',
          width: '100%',
          alignItems: 'center',
          paddingTop: 24
        }}
      >
        <FOHInverseButton
          title={T('exploreMoreJobs')}
          onPress={() => {
            navigateTo(`${HOME_PAGE}${DISCOVER}`);
          }}
          color={FOHColors.BLACK}
        />
      </FOHView>
    </FOHView>
  );
};
