import React, { useState } from 'react';

import { View } from 'react-native';
import { animated, useSpring } from 'react-spring';

import {
  FOHLabel,
  FOHLabelBold,
  FOHDropDownArrow,
  FOHColors,
  FOHView,
  FOHTouchableOpacity
} from '../../ingredients';

export interface FOHAccordianProps {
  title: string;
  clearLabel?: string;
  clear?: () => void;
  children?: React.ReactNode;
  width?: number;
}

const AnimatedArrowView = animated(View) as any;

export const FOHAccordian = (props: FOHAccordianProps) => {
  const [open, setOpen] = useState(false);

  const animatedProps = useSpring({
    from: { xyz: [0, 0, 0] },
    to: async (next: any) => {
      await next({ xyz: open ? [0, 180, 0] : [0, 0, 0] });
    },
    config: { duration: 200 }
  }) as any;

  return (
    <FOHView style={{ width: props.width || '100%' }}>
      <FOHView
        style={{
          flexDirection: 'row',
          width: props.width || '100%',
          backgroundColor: open ? FOHColors.BACKGROUND_GRAY : FOHColors.WHITE,
          alignItems: 'center'
        }}
      >
        <FOHTouchableOpacity
          onPress={() => setOpen(!open)}
          style={{
            flexDirection: 'row',
            height: 48,
            alignItems: 'center',
            flex: 3
          }}
        >
          <AnimatedArrowView
            style={{
              transform: animatedProps?.xyz?.to(
                (_x: number, y: number, _z: number) => `rotate(${y}deg)`
              )
            }}
          >
            <FOHDropDownArrow />
          </AnimatedArrowView>
          <FOHLabelBold>{`${props.title}`}</FOHLabelBold>
        </FOHTouchableOpacity>
        <FOHView
          style={{
            justifyContent: 'flex-end',
            flexDirection: 'row',
            flex: 1,
            width: '100%',
            alignItems: 'center',
            paddingRight: 16
          }}
        >
          <FOHTouchableOpacity onPress={props.clear}>
            <FOHLabel
              style={{
                color: FOHColors.BLUE
              }}
            >
              {props.clearLabel}
            </FOHLabel>
          </FOHTouchableOpacity>
        </FOHView>
      </FOHView>
      {open && props.children}
    </FOHView>
  );
};
