import React from 'react';

import badge from '../../../images/applicant_badge.svg';
import inactiveBadge from '../../../images/gray_apply_icon.svg';

import { FOHImage } from '../../ingredients';

export const FOHApplicantIcon = (props: any) => (
  <FOHImage
    source={{ uri: props.active ? badge : inactiveBadge }}
    style={
      props && !!props.style
        ? { width: 28, height: 28, ...props.style }
        : { width: 28, height: 28 }
    }
  />
);
