import React from 'react';

import { prop, defaultTo } from 'ramda';

import {
  FOHHeaderH4,
  FOHStatus,
  FOHStatusProps,
  FOHLargeLookingStatusIcon,
  FOHLargeNewShiftStatusIcon,
  FOHLargeNotLookingStatusIcon,
  FOHLargeOpenToOffersStatusIcon,
  FOHSpaceSmall,
  FOHDropDownArrow,
  FOHView,
  FOHTouchableOpacity,
  FOHColors
} from '../../ingredients';

import {
  FOHStatusSelectProps,
  StatusDescription,
  StatusLabel
} from '../cells/FOHStatusSelectCell';

export interface FOHStatusDropdownProps extends FOHStatusProps {
  openStatus: boolean;
  statusLabel: string;
  setOpenStatus: (open: boolean) => void;
  selectStatus: (status: any) => void;
  statusOptions: Array<FOHStatusSelectProps>;
}

const getStatusIcon = (value: any) =>
  defaultTo(
    FOHLargeLookingStatusIcon,
    prop(value, {
      ACTIVELY_LOOKING: FOHLargeLookingStatusIcon,
      OPEN_TO_OFFERS: FOHLargeOpenToOffersStatusIcon,
      LOOKING_FOR_SHIFT: FOHLargeNewShiftStatusIcon,
      CLOSED_TO_OFFERS: FOHLargeNotLookingStatusIcon
    })
  );

export const FOHStatusDropdown = (props: FOHStatusDropdownProps) => {
  return (
    <FOHView>
      <FOHTouchableOpacity
        onPress={() => props.setOpenStatus(!props.openStatus)}
        style={{
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <FOHHeaderH4
          style={{
            paddingTop: 2
          }}
        >
          {props.statusLabel}
        </FOHHeaderH4>
        <FOHSpaceSmall />
        <FOHStatus {...props} />
        <FOHDropDownArrow />
      </FOHTouchableOpacity>
      {props.openStatus && (
        <FOHView
          style={{
            position: 'absolute',
            top: 32,
            borderRadius: 6,
            padding: 12,
            paddingTop: 0,
            paddingBottom: 16,
            minWidth: 260,
            backgroundColor: FOHColors.WHITE
          }}
        >
          {props.statusOptions.map((status: any, _index: number) => (
            <FOHTouchableOpacity
              key={_index}
              style={{
                flexDirection: 'row'
              }}
              onPress={() => props.selectStatus(status)}
            >
              <FOHView style={{ paddingTop: 16, paddingRight: 10 }}>
                {getStatusIcon(status.value)()}
              </FOHView>
              <FOHView style={{ paddingTop: 18, flex: 1 }}>
                <StatusLabel>{status.label}</StatusLabel>
                <StatusDescription>{status.description}</StatusDescription>
              </FOHView>
            </FOHTouchableOpacity>
          ))}
        </FOHView>
      )}
    </FOHView>
  );
};
