import React from 'react';

import { ImageStyle } from 'react-native';

import candidatenetworkselected from '../../../images/candidatenetworkselected.svg';
import candidatenetworkunselected from '../../../images/candidatenetworkunselected.svg';

import { FOHImage } from '../../ingredients';

export interface FOHCandidateNetworkIconProps {
  selected?: boolean;
  style?: ImageStyle;
}

export const FOHCandidateNetworkIcon = (
  props: FOHCandidateNetworkIconProps
) => (
  <FOHImage
    source={{
      uri: props.selected
        ? candidatenetworkselected
        : candidatenetworkunselected
    }}
    style={[{ width: 24, height: 24 }, props.style]}
  />
);
