import React from 'react';

import { defaultTo, prop } from 'ramda';

import icon from '../../../images/screening_video_questionnaire.png';

import { FOHImage } from '..';

export const FOHVideoQuestionnaireIcon = (props: any) => (
  <FOHImage
    source={{ uri: icon }}
    style={{ width: 80, height: 82, ...defaultTo({}, prop('style', props)) }}
  />
);
