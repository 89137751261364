import React, { useState } from 'react';

import { withTranslation } from 'react-i18next';
import { compose, insert, length, prop } from 'ramda';

import {
  EXTERNAL_APPLICATION_HEIGHTTODECREASE,
  EXTERNAL_APPLICATION_WIDTH
} from '../../constants';
import { useInviteTeamToLocationMutation } from '../../graphql/generated';
import { handleMutation } from '../../utils';
import {
  FOHEmployerInviteTeam,
  FOHFixedHeightScrollView,
  FOHFormFlowButtons,
  FOHView
} from '../../components';

const LocationInvite = props => {
  const { t, location } = props;
  const [inviting, setInviting] = useState(false);

  const [inviteTeamToLocation] = useInviteTeamToLocationMutation();

  const [team, setTeam] = useState([{ id: '1', email: '', positionName: '' }]);

  const updateTeam = updatedMember => {
    const memberIndex = team.findIndex(mem => mem.id === updatedMember.id);
    const rest = team.filter(mem => mem.id !== updatedMember.id);
    const newTeam = insert(memberIndex, updatedMember, rest);
    if (length(newTeam) > 0 && updatedMember.id) {
      setTeam(newTeam);
    }
  };

  const addTeamMate = () =>
    setTeam([
      ...team,
      {
        id: String(length(team) + 1),
        email: '',
        positionName: ''
      }
    ]);

  const handleSubmit = async () => {
    const teamMembers = team.filter(member => member.email !== '');
    if (length(teamMembers) > 0 && !inviting) {
      setInviting(true);
      // TODO replace with mutation in team settings
      await handleMutation(
        inviteTeamToLocation({
          variables: {
            location: prop('id', location),
            name: prop('name', location),
            users: teamMembers.map(member => member.email)
          }
        })
      );
    }
    setInviting(false);

    return await props.nextStep();
  };

  return (
    <FOHView
      style={{
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <FOHView
        style={{
          width: '100%',
          maxWidth: props.isMobile ? '100%' : EXTERNAL_APPLICATION_WIDTH
        }}
      >
        <FOHFixedHeightScrollView
          isMobile={props.isMobile}
          heightToDecrease={EXTERNAL_APPLICATION_HEIGHTTODECREASE}
        >
          <FOHView
            style={{
              width: '100%',
              maxWidth: props.isMobile ? '100%' : EXTERNAL_APPLICATION_WIDTH
            }}
          >
            <FOHEmployerInviteTeam
              title={t('title')}
              emailPlaceholder={t('emailPlaceholder')}
              positionNamePlaceholder={t('positionNamePlaceholder')}
              addTeamMateLabel={t('addTeamMateLabel')}
              skipText={t('skipText')}
              skipLabel={t('skipLabel')}
              description=""
              disableNext={inviting}
              key={String(team)}
              next={handleSubmit}
              skip={handleSubmit}
              team={team}
              updateTeam={updateTeam}
              addTeamMate={addTeamMate}
            />
          </FOHView>
        </FOHFixedHeightScrollView>
      </FOHView>
      <FOHView
        style={{
          width: '100%',
          marginBottom: props.isMobile ? 0 : 100
        }}
      >
        <FOHFormFlowButtons
          isMobile={true}
          nextButtonlabel={
            inviting
              ? `${t('common:loading')}...`
              : team.some(item => length(item.email) > 0)
              ? t('nextLabel')
              : t('EmployerInterviewScheduleFeature:closeLable')
          }
          onPressNextButton={handleSubmit}
          isNextDisabled={inviting}
          previousButtonLabel={t('common:previous')}
          onPressPreviousButton={props.previousStep}
        />
      </FOHView>
    </FOHView>
  );
};

export default compose(withTranslation('InviteTeamMates'))(LocationInvite);
