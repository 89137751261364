import React from 'react';

import expand_icon from '../../../images/expand_icon.svg';

import { FOHImage } from '../../ingredients';

export const FOHExpandedIcon = (props: any) => (
  <FOHImage
    source={{ uri: expand_icon }}
    style={
      props && !!props.style
        ? { width: 24, height: 24, ...props.style }
        : { width: 24, height: 24 }
    }
  />
);
