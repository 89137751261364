import React from 'react';

import rightarrow from '../../../images/rightarrow.svg';

import { FOHImage } from '../../ingredients';

export const FOHRightArrow = (props: any) => (
  <FOHImage
    source={{ uri: rightarrow }}
    style={{ width: 14, height: 10, ...props.style }}
  />
);
