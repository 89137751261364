import React from 'react';

import complete from '../../../images/currentstep.svg';

import { FOHImage } from '../../ingredients';

export const FOHCurrentStep = (props: any) => (
  <FOHImage
    source={{ uri: complete }}
    style={{ width: 25, height: 25, ...props.style }}
  />
);
