import React from 'react';

import { FOHTextButton, FOHColors } from '../../../ingredients';

export interface DropDownTextProps {
  text: string;
}

export const DropDownText = (props: DropDownTextProps) => {
  return (
    <FOHTextButton
      title={props.text}
      disabled={true}
      reverseIcon={true}
      onPress={() => {}}
      style={{
        color: FOHColors.GRAYSCALE_500,
        fontSize: 12,
        margin: 0
      }}
      touchableStyle={{
        width: 'fit-content',
        margin: 0
      }}
    />
  );
};
