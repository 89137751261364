import React, { useRef } from 'react';

import { View, TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';
import { useHover } from 'react-native-web-hooks';
import { defaultTo, length } from 'ramda';

import {
  FOHLargeAvatar,
  FOHSmallDetailLabel,
  FOHHeaderH3,
  FOHCheckbox,
  FOHSpace,
  FOHView,
  FOHFollowBadge,
  FOHToolTip,
  FOHNotificationIcon,
  FOHNotificationAlertChat,
  FOHNotificationDefaultChat,
  FOHCircleLoader,
  FOHMessageTemplateIcon,
  FOHSpaceSmall,
  FOHRejectionTag,
  FOHToolTipType,
  FOHColors
} from '../../ingredients';

import { FOHProfileImage } from '../navigation/FOHProfileImage';

export interface FOHCandidateSelectCellProps {
  name: string;
  photo?: string;
  positionName: string;
  style?: any;
  id?: string;
  selected?: boolean;
  onSelectPress?: (id: string) => void;
  onPressCell?: (id: string) => void;
  isFollowing?: boolean;
  hidden?: boolean;
  followingBadgeLabel?: string;
  followingHelpText?: string;
  isActive?: boolean;
  notificationIcon?: any;
  notificationIconHelperText?: string;
  hasUnreadMessage?: boolean;
  unreadMessageHelperText?: string;
  hasRedDot?: boolean;
  rejectedReasons?: Array<string>;
  loading?: boolean;
  testID?: string;
  hasNote?: boolean;
}

export const FOHCandidateSelectCell = (props: FOHCandidateSelectCellProps) => {
  const NotificationIconToRender = props.notificationIcon;
  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);
  const firstHoverRef = useRef(null);
  const isFirstHovered = useHover(firstHoverRef);
  const redDot = useRef(null);
  const isRedDotHovered = useHover(redDot);

  return (
    <>
      <TouchableOpacity
        onPress={() => {
          if (props.onPressCell) {
            props.onPressCell(props.id!);
          }
        }}
        style={{
          backgroundColor: props.isActive
            ? FOHColors.GRAYSCALE_100
            : FOHColors.WHITE
        }}
        testID={'FOHCandidateSelectCell'}
      >
        {!props.loading ? (
          <Container>
            <Wrapper
              style={{
                ...props.style,
                backgroundColor: props.isActive
                  ? FOHColors.GRAYSCALE_100
                  : FOHColors.WHITE,
                paddingLeft: 16,
                paddingRight: 16
              }}
            >
              {props.onSelectPress && props.id ? (
                <TouchableOpacity
                  onPress={e => {
                    e.preventDefault();
                    if (props.onSelectPress) {
                      props.onSelectPress(props.id!);
                    }
                  }}
                >
                  <FOHCheckbox selected={props.selected!} />
                </TouchableOpacity>
              ) : (
                <></>
              )}
              <FOHSpace />
              {props.photo ? (
                <FOHProfileImage
                  photo={props.photo}
                  style={{
                    height: 48,
                    width: 48,
                    opacity: props.hidden ? 0.3 : undefined
                  }}
                />
              ) : (
                <FOHLargeAvatar style={{ height: 48, width: 48 }} />
              )}
              <CandidateDetailContainer
                testID={'candidate-list-cell-detail-container'}
              >
                <FOHView style={{ alignItems: 'flex-start' }}>
                  <FOHView style={{ flexDirection: 'row' }}>
                    <FOHHeaderH3
                      numberOfLines={1}
                      style={{
                        maxWidth: 200
                      }}
                    >
                      {props.name}{' '}
                    </FOHHeaderH3>
                    <FOHSpaceSmall />
                    {props.hasNote ? (
                      <FOHMessageTemplateIcon
                        style={{
                          width: 16,
                          height: 16,
                          tintColor: '#878787'
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </FOHView>
                  {length(defaultTo([], props.rejectedReasons)) === 0 ? (
                    <View style={{ flexDirection: 'row' }}>
                      <FOHSmallDetailLabel
                        numberOfLines={1}
                        style={{
                          lineHeight: 24,
                          marginBottom: 4,
                          Maxwidth: 150,
                          marginRight: 5
                        }}
                      >
                        {props.positionName}
                      </FOHSmallDetailLabel>
                    </View>
                  ) : (
                    <></>
                  )}

                  {length(defaultTo([], props.rejectedReasons)) > 0 ? (
                    <View
                      style={{
                        maxWidth: 228,
                        flexDirection: 'row',
                        flexWrap: 'wrap'
                      }}
                    >
                      {props.rejectedReasons ? (
                        props.rejectedReasons.slice(0, 3).map((reason, i) => {
                          return <FOHRejectionTag key={i} label={reason} />;
                        })
                      ) : (
                        <></>
                      )}
                      {length(defaultTo([], props.rejectedReasons)) > 3 && (
                        <FOHRejectionTag
                          label={`+${
                            length(defaultTo([], props.rejectedReasons)) - 3
                          }`}
                        />
                      )}
                    </View>
                  ) : (
                    <></>
                  )}
                </FOHView>

                {props.isFollowing && !props.hidden ? (
                  <FOHFollowBadge
                    small={true}
                    label={props.followingBadgeLabel!}
                    helpText={props.followingHelpText}
                  />
                ) : (
                  <></>
                )}
              </CandidateDetailContainer>
              <NotificationRightSide>
                {props.hasRedDot ? (
                  <View ref={redDot} style={{ alignItems: 'center' }}>
                    <FOHNotificationIcon backgroundColor={FOHColors.RED} />
                    <FOHToolTip
                      helpText={`You haven't reviewed this candidate yet!`}
                      open={isRedDotHovered}
                      type={FOHToolTipType.LEFT}
                      style={{ width: 200, right: 40, top: 10 }}
                      width={150}
                    />
                  </View>
                ) : (
                  <View ref={redDot}></View>
                )}
                <NotificationListContainer>
                  {props.notificationIcon ? (
                    <View
                      ref={firstHoverRef}
                      style={{ alignItems: 'center', zIndex: 97 }}
                    >
                      <NotificationIconToRender />
                      <FOHToolTip
                        helpText={props.notificationIconHelperText}
                        open={isFirstHovered}
                        type={FOHToolTipType.LEFT}
                        style={{ width: 275, right: 40, top: 8, opacity: 0.85 }}
                        width={275}
                      />
                    </View>
                  ) : (
                    <View ref={firstHoverRef}></View>
                  )}
                  {props.hasUnreadMessage ? (
                    <View
                      ref={hoverRef}
                      style={{ alignItems: 'center', zIndex: 98 }}
                    >
                      <FOHNotificationAlertChat />
                      <FOHToolTip
                        helpText={props.unreadMessageHelperText}
                        open={isHovered}
                        type={FOHToolTipType.LEFT}
                        style={{ width: 275, right: 40, top: 8, opacity: 0.85 }}
                        width={275}
                      />
                    </View>
                  ) : (
                    <View ref={hoverRef}>
                      <FOHNotificationDefaultChat />
                    </View>
                  )}
                </NotificationListContainer>
              </NotificationRightSide>
            </Wrapper>
          </Container>
        ) : (
          <Container>
            <Wrapper
              style={{
                ...props.style,
                backgroundColor: props.isActive
                  ? FOHColors.BACKGROUND_GRAY
                  : FOHColors.WHITE,
                justifyContent: 'center'
              }}
            >
              <FOHCircleLoader />
            </Wrapper>
          </Container>
        )}
      </TouchableOpacity>
    </>
  );
};

const Container = styled(View)`
  border-width: 1px;
  border-color: #e8e8e8;
  width: 100%;
  flex-direction: row;
`;

const Wrapper = styled(FOHView)`
  padding-vertical: 16px;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

const CandidateDetailContainer = styled(View)`
  align-items: flex-start;
  flex-direction: row;
  margin-left: 16px;
  margin-right: 8px;
`;

const NotificationRightSide = styled(View)`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  flex: 1 0 auto;
  align-items: flex-end;
  justify-content: center;
`;

const NotificationListContainer = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1 0 auto;
  column-gap: 4px;
`;
