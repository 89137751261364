import React from 'react';

import notificationPendingOffers from '../../../images/notification-pending-offers.svg';

import { FOHImage } from '../../ingredients';

export interface FOHNotificationPendingOffersProps {}

export const FOHNotificationPendingOffers = (
  props: FOHNotificationPendingOffersProps
) => (
  <FOHImage
    {...props}
    source={{ uri: notificationPendingOffers }}
    style={{ width: 24, height: 24 }}
    testID={'offer-notification-badge'}
  />
);
