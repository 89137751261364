import React from 'react';

import styled from 'styled-components/native';
import { View } from 'react-native';

import { FOHLabelSmall, FOHLabelBold } from '../../ingredients';

export interface FOHMoneyCellProps {
  hourlyrate: string;
  position: string;
}

export const FOHMoneyCell = (props: FOHMoneyCellProps) => (
  <Box>
    <BoldGreenLabel>{props.hourlyrate}</BoldGreenLabel>
    <SmallLabel>{props.position}</SmallLabel>
  </Box>
);

const BoldGreenLabel = styled(FOHLabelBold)`
  padding-top: 8px;
  color: #38a56b;
  opacity: 1;
  line-height: 20px;
`;

const SmallLabel = styled(FOHLabelSmall)`
  font-size: 12px;
  opacity: 1;
`;

const Box = styled(View)`
  background-color: #6fcf9714;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 148px;
  height: 58px;
  padding: 36px;
  margin-bottom: 6px;
`;
