import React from 'react';

import { TouchableOpacity, View } from 'react-native';
import { prop, defaultTo } from 'ramda';

import { useClickOutside } from '../../spices';
import { FOHPositionBillingStatusType } from '../../typings';
import {
  FOHColors,
  FOHCompletedStep,
  FOHDropDownArrow,
  FOHLabelBold,
  FOHSpaceSmall,
  FOHView,
  FOHWhiteX,
  FullWidthCard
} from '../../ingredients';

import { StatusDescription, StatusLabel } from '../cells/FOHStatusSelectCell';

export interface FOHPositionBillingStatusSelectProps {
  label: string;
  description: string;
  value: FOHPositionBillingStatusType;
}

export interface FOHPositionBillingStatusDropdownProps {
  statusType: FOHPositionBillingStatusType;
  statusText: string;
  openStatus: boolean;
  setOpenStatus: (open: boolean) => void;
  selectStatus: (status: any, positionId: string | undefined) => void;
  statusOptions: Array<FOHPositionBillingStatusSelectProps>;
  positionId: string | undefined;
}

const SubscribedStatusIcon = () => (
  <FOHCompletedStep style={{ height: 20, width: 20 }} />
);

const PausedJobIcon = () => (
  <FOHView style={{ backgroundColor: FOHColors.RED_200, borderRadius: 50 }}>
    <FOHWhiteX style={{ height: 20, width: 20 }} />
  </FOHView>
);

const getStatusIcon = (value: any) =>
  defaultTo(
    SubscribedStatusIcon,
    prop(value, {
      SUBSCRIBED: SubscribedStatusIcon,
      PAUSED: PausedJobIcon
    })
  );

export const FOHPositionBillingStatusDropdown = (
  props: FOHPositionBillingStatusDropdownProps
) => {
  const [ref] = useClickOutside({
    close: () =>
      !!props.openStatus && props.setOpenStatus && props.setOpenStatus(false)
  });

  return (
    <>
      <View ref={ref} style={{ alignItems: 'center', flexDirection: 'row' }}>
        <TouchableOpacity
          onPress={() => props.setOpenStatus(!props.openStatus)}
          testID="positionBillingStatusDropdown"
          style={{
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          {getStatusIcon(props.statusType)()}
          <FOHSpaceSmall />
          <FOHLabelBold
            style={{
              fontSize: 14,
              lineHeight: 20,
              color: FOHColors.GRAYSCALE_700
            }}
          >
            {' '}
            {props.statusText}
          </FOHLabelBold>
          <FOHDropDownArrow />
        </TouchableOpacity>
        {props.openStatus && (
          <FullWidthCard
            shadow={true}
            style={{
              position: 'absolute',
              right: 0,
              top: 32,
              borderRadius: 4,
              padding: 12,
              paddingTop: 0,
              paddingBottom: 16,
              minWidth: 260,
              backgroundColor: '#ffffff'
            }}
          >
            {props.statusOptions.map((status, index) => (
              <TouchableOpacity
                style={{
                  flexDirection: 'row'
                }}
                key={index}
                onPress={() => props.selectStatus(status, props.positionId)}
                testID={`positionBillingStatusDropdown-${status.value}`}
              >
                <View style={{ paddingTop: 16, paddingRight: 10 }}>
                  {getStatusIcon(status.value)()}
                </View>
                <View style={{ paddingTop: 18, flex: 1 }}>
                  <StatusLabel>{status.label}</StatusLabel>
                  <StatusDescription>{status.description}</StatusDescription>
                </View>
              </TouchableOpacity>
            ))}
          </FullWidthCard>
        )}
      </View>
    </>
  );
};
