import React from 'react';

import styled from 'styled-components/native';
import { View, TouchableOpacity } from 'react-native';

import {
  FOHLabel,
  FOHMessageIcon,
  FOHSpaceSmall,
  FOHView,
  FOHColors,
  FOHSpaceSmallest
} from '../../ingredients';

export interface FOHInboxProps {
  hasMessage?: boolean;
  inboxLabel: string;
  mobile?: boolean;
  onPressInbox: () => void;
  fullWidth?: boolean; // sidenav
  countText?: string;
  disabled?: boolean;
}

export const FOHInbox = (props: FOHInboxProps) => (
  <Container
    testID={'on-press-inbox'}
    onPress={props.onPressInbox}
    style={{
      borderRadius: props.mobile ? '50%' : 6,
      width: props.mobile ? 44 : props.fullWidth ? '100%' : 88,
      backgroundColor: props.hasMessage
        ? FOHColors.LIGHT_ORANGE
        : props?.disabled
        ? ''
        : FOHColors.BACKGROUND_GRAY,
      justifyContent: props.fullWidth ? 'space-between' : 'center',
      paddingLeft: props.fullWidth ? 14 : 0,
      paddingRight: props.fullWidth ? 14 : 0
    }}
    disabled={props?.disabled}
  >
    <FOHView
      style={{
        flexDirection: 'row',
        justifyContent: props.fullWidth ? 'flex-start' : 'center',
        alignItems: 'center'
      }}
    >
      <FOHView>
        <FOHMessageIcon
          style={{
            width: 19,
            height: 18,
            tintColor: props?.disabled
              ? FOHColors.GRAYSCALE_300
              : FOHColors.BLACK
          }}
        />
        {props.hasMessage ? <BlueCircle /> : <></>}
      </FOHView>
      {!props.mobile ? (
        <>
          <FOHSpaceSmall />
          <FOHSpaceSmallest />
          <FOHLabel
            style={{
              fontSize: 14,
              lineHeight: 24,
              color: props?.disabled
                ? FOHColors.GRAYSCALE_400
                : FOHColors.CYBERSPACE_GREY
            }}
          >
            {props.inboxLabel}
          </FOHLabel>
        </>
      ) : null}
    </FOHView>
    {!!props.countText && props.fullWidth ? (
      <FOHLabel
        style={{ color: props.hasMessage ? FOHColors.RED : FOHColors.BLACK }}
      >
        {props.countText}
      </FOHLabel>
    ) : null}
  </Container>
);

const Container = styled(TouchableOpacity)`
  flex-direction: row;
  height: 44px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
`;

const BlueCircle = styled(View)`
  width: 10px;
  position: absolute;
  height: 10px;
  border-radius: 50%;
  background: ${FOHColors.RED};
  border-width: 1px;
  border-color: ${FOHColors.LIGHT_ORANGE};
  justify-content: center;
  align-items: center;
  top: -4px;
  right: -4px;
`;
