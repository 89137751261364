import React from 'react';

import { path, defaultTo, prop } from 'ramda';
import { useTranslation } from 'react-i18next';

import { unwrapEdgesAt } from '../../utils';
import { CANDIDATE_PROFILE, getAppBaseUrl } from '../../constants';
import { useSelectedEmployerCandidatesQuery } from '../../graphql/generated';
import {
  FOHCircleLoader,
  FOHMultipleCandidatesSelected
} from '../../components';

import { usePositionTypes } from '../CandidateProfile/usePositionTypes';

import { useCandidateFeedContext } from './useCandidateFeedContext';
import { useExportCsv } from './useExportCsv';

export const MultiCandidateSelectionDetail = props => {
  const { t } = useTranslation('CandidateFeed');
  const { selectedCandidateCount } = props;
  const {
    selectedEmployerCandidates,
    positionFilters,
    locationFilters,
    allCandidates,
    clearEmployerCandidates
  } = useCandidateFeedContext(props);
  const candidatesQuery = useSelectedEmployerCandidatesQuery({
    skip: !props.activeStageId,
    variables: {
      employerCandidateIds: allCandidates
        ? undefined
        : selectedEmployerCandidates,
      positions: positionFilters,
      locations: locationFilters,
      stageId: props.activeStageId,
      first: allCandidates ? 200 : undefined // no pagination for csv export ?
    }
  });

  const { getStringForPositionType } = usePositionTypes();

  const { generateAndDownloadCsv } = useExportCsv({
    configs: {
      filename: `candidates`,
      title: `Candidates\n\n`
    }
  });

  const candidates = unwrapEdgesAt(
    ['data', 'employerCandidates', 'edges'],
    candidatesQuery
  );

  if (candidatesQuery.loading) {
    return <FOHCircleLoader />;
  }

  const handleExportCsv = () => {
    // only add these fields if they have applied
    const data = candidates.map(empCand => {
      return {
        // export snake case for csv
        name: `${path(
          ['candidateProfile', 'user', 'firstName'],
          empCand
        )} ${path(['candidateProfile', 'user', 'lastName'], empCand)}`,
        email: path(['candidateProfile', 'user', 'email'], empCand),
        phone: path(['candidateProfile', 'user', 'phone'], empCand),
        profile_url: `${getAppBaseUrl()}${CANDIDATE_PROFILE}/${path(
          ['candidateProfile', 'handle'],
          empCand
        )}`,
        zip_code: path(['candidateProfile', 'addressPostalCode'], empCand),
        candidate_position_types: defaultTo(
          [],
          path(['candidateProfile', 'positions'], empCand)
        )
          .map(position => getStringForPositionType(position))
          .join(', '),
        summary: path(['candidateProfile', 'personalSummary'], empCand)
      };
    });
    generateAndDownloadCsv(data);
  };

  return (
    <FOHMultipleCandidatesSelected
      thumbnails={candidates.slice(0, 3).map(candidate => ({
        uri: path(['candidateProfile', 'user', 'profileImage'], candidate)
      }))}
      totalCount={
        candidatesQuery.loading || selectedCandidateCount <= 3
          ? 0
          : selectedCandidateCount - 3
      }
      selectionText={
        // `You’ve selected ${selectedCount} Candidates in Applicants Stage`
        t('selectedCandidateCountText', {
          count: selectedCandidateCount,
          stage: prop('name', props.currentStage)
        })
      }
      selectionHighlightText={
        // `${selectedCount} Candidates`
        // words to highlight, also highlight stage name, space delimited
        `${t('highlightCandidateCount', {
          count: selectedCandidateCount
        })}  `
      }
      detailLabel={
        // 'Reject, Qualify, Chat or Manage them all at once to save your precious time...'
        t('rejectQualifyChatManage')
      }
      deselectLabel={t('deselectLabel')}
      exportToCSVLabel={t('exportToCSVLabel')}
      deselectAll={() => {
        clearEmployerCandidates();
      }}
      exportToCSV={handleExportCsv}
      youCanAlsoLabel={t('youCanAlsoLabel')}
      orLabel={t('orLabel')}
    />
  );
};
