import React from 'react';

import {
  Row,
  FOHTextButton,
  FOHReactiveModal,
  FOHReactiveModalProps,
  FOHSpace,
  FOHHeaderText,
  FOHView,
  FOHScrollView
} from '../../ingredients';

import {
  FOHInterviewRequestFormProps,
  FOHInterviewRequestForm
} from '../forms/FOHInterviewRequestForm';

interface FOHInterviewRequestModalProps
  extends FOHInterviewRequestFormProps,
    FOHReactiveModalProps {
  title: string;
  cancelText?: string;
  CandidateStateBanner?: any;
}

export const FOHInterviewRequestModal = (
  props: FOHInterviewRequestModalProps
) => {
  return (
    <FOHReactiveModal
      leftContentShift={props.leftContentShift}
      dialog={props.dialog}
      open={props.open}
      close={props.close}
      heightOpen={props.heightOpen}
    >
      <FOHView
        style={{
          justifyContent: 'center',
          padding: props.dialog ? 20 : 0,
          paddingTop: props.dialog ? 0 : 30
        }}
      >
        {!!props.CandidateStateBanner && (
          <>
            {props.CandidateStateBanner()}
            <FOHSpace />
          </>
        )}
        <FOHHeaderText>{props.title}</FOHHeaderText>
        <FOHScrollView>
          <FOHView style={{ alignItems: 'center' }}>
            <FOHInterviewRequestForm
              {...props}
              positionPlaceholder={props.positionPlaceholder}
              myPositions={props.myPositions}
              selectPosition={props.selectPosition}
              selectedPosition={props.selectedPosition}
              submit={props.submit}
              submitText={props.submitText}
              submitDisabled={props.sendingIR || !props.selectedPosition}
            />
            {!!props.cancelText && props.close && (
              <Row style={{ justifyContent: 'center' }}>
                <FOHTextButton title={props.cancelText} onPress={props.close} />
              </Row>
            )}
          </FOHView>
        </FOHScrollView>
      </FOHView>
    </FOHReactiveModal>
  );
};
