import React, { useContext } from 'react';

import { path, defaultTo } from 'ramda';
import { useLocalStorage } from 'use-hooks';

import { useSearchFilterGroupsQuery } from '../../graphql/generated';

const CandidateFeedFiltersContext = React.createContext<
  [any, (state: any) => void]
>([{}, () => {}]);

export const useCandidateFeedFiltersContext = () => {
  const [state, setState] = useContext<[any, (state: any) => void]>(
    CandidateFeedFiltersContext
  );

  const searchFilterGroupsQuery = useSearchFilterGroupsQuery();

  const setSearchTerms = (terms: any) => {
    setState({ ...state, searchTerms: terms });
  };

  const setZip = (zip: any) => {
    setState({ ...state, zip });
  };

  const setDistance = (distance: any) => {
    setState({ ...state, distance });
  };

  const addFilterItem = (item: any) => {
    setState({
      ...state,
      profileDetailFilters: [...state.profileDetailFilters, item]
    });
  };

  const removeFilterItem = (itemToRemove: any) => {
    const profileDetailFilters = state.profileDetailFilters.filter(
      (item: any) => item !== itemToRemove
    );
    setState({ ...state, profileDetailFilters });
  };

  const setIsFilterDrawerOpen = (isFilterDrawerOpen: any) => {
    setState({ ...state, isFilterDrawerOpen });
  };

  const clearFiltersByGroup = (groupName: string) => {
    const groupToClear = defaultTo(
      [],
      path(
        ['data', 'candidateSearchFilterGroups'],
        searchFilterGroupsQuery
      ) as any
    ).find((group: { name: string }) => group.name === groupName);

    if (groupToClear) {
      const itemsToRemove = groupToClear.filterItems;

      const currentProfileDetailFilters = state.profileDetailFilters;

      const newProfileDetailFilters = currentProfileDetailFilters.filter(
        (item: string) => !itemsToRemove.includes(item)
      );

      setState({
        ...Object.assign(state, {
          profileDetailFilters: newProfileDetailFilters
        })
      });
    }
  };

  const clearAllFilters = () => {
    setState(Object.assign(state, { profileDetailFilters: [] }));
  };

  const setPartialState = (partialState: any) => {
    setState(Object.assign(state, partialState));
  };

  return {
    ...state,
    setSearchTerms,
    setZip,
    setDistance,
    addFilterItem,
    removeFilterItem,
    setIsFilterDrawerOpen,
    clearFiltersByGroup,
    clearAllFilters,
    setPartialState
  };
};

export const CandidateFeedFiltersContextProvider = (props: any) => {
  // persist search vars across refetch
  const [state, setState] = useLocalStorage('candidate-feed-filters-storage', {
    searchTerms: [],
    zip: '',
    distance: '',
    isFilterDrawerOpen: false,
    profileDetailFilters: [],
    activeMoveToStageId: null
  });

  return (
    <CandidateFeedFiltersContext.Provider value={[state, setState]}>
      {props.children}
    </CandidateFeedFiltersContext.Provider>
  );
};
