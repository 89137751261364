import React, { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { defaultTo, path, prop } from 'ramda';
import { Navigate, useParams } from 'react-router-dom';

import {
  FOHColors,
  FOHFonts,
  FOHLabel,
  FOHLinkOut,
  FOHSmallDetailLabel,
  FOHSpace,
  FOHSpaceLarge,
  FOHSpaceSmallest,
  FOHView,
  Screen
} from '../../components';
import { useDisableBackgroundScroll, useIsMobile } from '../../utils';
import { FEED } from '../../constants';

import { useGetMeData } from '../SignIn';
import { SetPasswordModal } from '../ClaimAccount/SetPasswordModal';

const PendingEmployerProfile = () => {
  const { isMobile } = useIsMobile();
  const { t } = useTranslation('PendingEmployerProfile');
  const { modal } = useParams();

  const { data: me } = useGetMeData();

  const isInvited = defaultTo(false, prop('unclaimed', me));

  const [isOpenInviteUserOrPasswordModal, setIsOpenInviteUserOrPasswordModal] =
    useState(!!isInvited || modal === 'password');

  const employerProfileStatus = defaultTo(
    null,
    path(['employerProfile', 'status'], me)
  );

  const hasCompletedAccountSetup = useMemo(
    () =>
      employerProfileStatus &&
      !['PENDING', 'FRAUD'].includes(employerProfileStatus),
    [employerProfileStatus]
  );

  const blurStyle = useMemo(() => {
    return isOpenInviteUserOrPasswordModal
      ? {
          filter: 'blur(4px)',
          position: 'absolute',
          width: '100vw',
          height: '100vh',
          zIndex: 9999,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0
        }
      : {};
  }, [isOpenInviteUserOrPasswordModal]);

  useDisableBackgroundScroll([isOpenInviteUserOrPasswordModal]);

  return (
    <>
      {hasCompletedAccountSetup && <Navigate to={FEED} />}
      <FOHView
        style={{
          width: '100%',
          alignItems: 'center',
          paddingTop: 40,
          height: '100vh',
          paddingHorizontal: isMobile ? 15 : 0
        }}
      >
        <Screen
          style={{
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingTop: isMobile ? 60 : 120,
            ...blurStyle
          }}
        >
          <FOHView
            style={{
              maxWidth: 821,
              width: '100%',
              alignItems: 'flex-start',
              paddingHorizontal: 62,
              paddingVertical: 49
            }}
          >
            <FOHView>
              <FOHLabel bold={true} style={{ fontSize: 44, lineHeight: 56 }}>
                {t('accountSetupInProgressHeader')}
              </FOHLabel>
              <FOHSpaceLarge />
              <FOHLabel style={{ fontSize: 34, lineHeight: 46 }}>
                {t('accountSetupBodyText')}
              </FOHLabel>
            </FOHView>
            <FOHSpace />
            <FOHView
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
                flexWrap: 'wrap'
              }}
            >
              <FOHSmallDetailLabel style={{ fontSize: 16, lineHeight: 24 }}>
                {t('accountSetupFooterText')}
              </FOHSmallDetailLabel>
              <FOHSpaceSmallest />
              <FOHLinkOut
                noIcon={true}
                href={'mailto:support@fohandboh.com'}
                labelStyle={{
                  color: FOHColors.PRIMARY_TEAL_400,
                  fontSize: 16,
                  lineHeight: 24,
                  fontWeight: 400,
                  fontFamily: FOHFonts.REGULAR
                }}
              >
                {t('contactSupportBtnText')}
              </FOHLinkOut>
            </FOHView>
          </FOHView>
        </Screen>
        {isOpenInviteUserOrPasswordModal && (
          <SetPasswordModal
            open={isOpenInviteUserOrPasswordModal}
            close={() => setIsOpenInviteUserOrPasswordModal(false)}
          />
        )}
      </FOHView>
    </>
  );
};

export default PendingEmployerProfile;
