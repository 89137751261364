import React from 'react';

import styled from 'styled-components/native';
import { View } from 'react-native';

import {
  FOHProfilePhotoIcon,
  FOHInverseButton,
  FOHLabel,
  withError,
  FOHTouchableOpacity,
  FOHView,
  FOHSpaceSmall,
  FOHColors,
  FOHEditMarkerBlack,
  FOHSpace
} from '../../ingredients';
import { useIsMobile } from '../../spices';

import { FOHProfileImage } from '../navigation/FOHProfileImage';

import { FOHPhotoInputProps } from './FOHPhotoInput';

const AccountPhotoInput = (props: FOHPhotoInputProps) => {
  const isMobile = useIsMobile();

  return (
    <PhotoContainer
      style={{
        width: isMobile ? '100%' : props?.isEmployer ? 420 : 327,
        borderWidth: props?.isEmployer ? 0 : 1,
        borderColor: props?.isEmployer ? undefined : FOHColors.BORDER_GRAY
      }}
    >
      <PhotoRow
        style={{
          width: isMobile ? '100%' : props?.isEmployer ? 420 : 327,
          flexDirection: !!props?.isEmployer && isMobile ? 'column' : 'row',
          justifyContent:
            !!props?.isEmployer && isMobile ? 'center' : 'flex-start',
          flexWrap: props?.isEmployer ? 'wrap' : undefined,
          paddingVertical: props?.isEmployer ? (isMobile ? 18 : 16) : undefined,
          position: 'relative'
        }}
      >
        <FOHView
          style={{
            width: props?.isEmployer ? 140 : 72,
            height: props?.isEmployer ? 140 : 72
          }}
        >
          {props.photo ? (
            <FOHProfileImage
              photo={props.photo}
              style={{
                width: props?.isEmployer ? 140 : 72,
                height: props?.isEmployer ? 140 : 72
              }}
            />
          ) : (
            <FOHProfilePhotoIcon
              style={{
                width: props?.isEmployer ? 140 : 72,
                height: props?.isEmployer ? 140 : 72
              }}
            />
          )}
          {props?.isEmployer ? (
            <FOHView style={{ position: 'absolute', bottom: 0, right: 0 }}>
              <FOHTouchableOpacity onPress={props.pickPhoto}>
                <FOHView
                  style={{
                    padding: 8,
                    backgroundColor: FOHColors.GRAYSCALE_200,
                    borderRadius: '50%'
                  }}
                >
                  <FOHEditMarkerBlack
                    style={{
                      tintColor: FOHColors.GRAYSCALE_600,
                      width: 24,
                      height: 24
                    }}
                  />
                </FOHView>
              </FOHTouchableOpacity>
            </FOHView>
          ) : null}
        </FOHView>
        <FOHSpace />
        <FOHLabel
          numberOfLines={1}
          style={{
            maxWidth: 189,
            color: FOHColors.GRAYSCALE_900,
            fontSize: props?.isEmployer ? 26 : 16,
            lineHeight: props?.isEmployer ? 32 : 24
          }}
        >
          {props.fullName}
        </FOHLabel>
      </PhotoRow>
      {!props?.isEmployer ? (
        <View style={{ paddingBottom: 24 }}>
          <FOHSpaceSmall />
          <FOHTouchableOpacity onPress={props.pickPhoto}>
            <FOHInverseButton
              style={{ maxWidth: 279, minWidth: 200 }}
              onPress={props.pickPhoto}
              title={props.uploadLabel}
            />
          </FOHTouchableOpacity>
        </View>
      ) : null}
    </PhotoContainer>
  );
};

const PhotoRow = styled(View)`
  border-radius: 4px;
  align-items: center;
  padding-horizontal: 18px;
  flex-direction: row;
`;

const PhotoContainer = styled(View)`
  background: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const FOHAccountPhotoInput = withError(AccountPhotoInput);
