import React from 'react';

import { FOHPageModal, FOHScrollView } from '../../ingredients';
import { FOHLocationBlockProps, FOHLocationBlock } from '../../recipes';

export interface FOHLocationDetailsModalProps extends FOHLocationBlockProps {
  leftContentShift?: number;
  open?: boolean;
  close?: () => void;
}

export const FOHLocationDetailsModal = (
  props: FOHLocationDetailsModalProps
) => {
  return (
    <FOHPageModal
      leftContentShift={props.leftContentShift}
      open={props.open}
      close={props.close}
    >
      <FOHScrollView style={{ width: '100%', paddingLeft: 40 }}>
        <FOHLocationBlock
          modal={true}
          hourlyRatesTitle={props.hourlyRatesTitle}
          positionRates={props.positionRates}
          id={props.id}
          locationLabel={props.locationLabel}
          name={props.name}
          formattedAddress={props.formattedAddress}
          tags={props.tags}
          favoriteLabel={props.favoriteLabel}
          hideLabel={props.hideLabel}
          isFavorited={props.isFavorited}
          followerCountLabel={props.followerCountLabel}
          isHidden={props.isHidden}
          pressHide={props.pressHide}
          pressFavorite={props.pressFavorite}
          answersLabel={props.answersLabel}
          answers={props.answers}
          summaryTitle={props.summaryTitle}
          image={props.image}
          summary={props.summary}
          claimBusinessLabel={props.claimBusinessLabel}
          claimBusiness={props.claimBusiness}
          jobs={props.jobs}
          jobSectionTitle={props.jobSectionTitle}
          onJobPress={props.onJobPress}
          a={props.a}
          jobCTAText={props.jobCTAText}
        >
          {props.children}
        </FOHLocationBlock>
      </FOHScrollView>
    </FOHPageModal>
  );
};
