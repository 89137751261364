import { useCallback, useState, useRef } from 'react';

import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { path } from 'ramda';

import { envVariables } from '../../constants';
import { GetCompaniesDocument, GetMeDocument } from '../../graphql/generated';
import { removeSessionStorageItem, setSessionStorageItem } from '../../utils';

import { OAUTH_PROVIDERS } from './constants';

const OAUTH_STATE_KEY = 'react-use-oauth2-state-key';
const POPUP_HEIGHT = 700;
const POPUP_WIDTH = 600;
const OAUTH_RESPONSE = 'react-use-oauth2-response';

const MUTATE_7SHIFTS_GUID = gql`
  mutation Create7ShiftsGuid($guid: String!, $companyId: String!) {
    sevenshiftsCreateGuid(guid: $guid, companyId: $companyId) {
      guid {
        id
        companyName
        companyId
        guid
      }
    }
  }
`;

// https://medium.com/@dazcyril/generating-cryptographic-random-state-in-javascript-in-the-browser-c538b3daae50
export const generateState = () => {
  const validChars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let array = new Uint8Array(40);
  window.crypto.getRandomValues(array);
  array = array.map(x => validChars.codePointAt(x % validChars.length)!);
  const randomState = String.fromCharCode.apply(
    null,
    array as unknown as number[]
  );
  return randomState;
};

const removeState = () => removeSessionStorageItem(OAUTH_STATE_KEY);

const openPopup = (url: string) => {
  // To fix issues with window.screen in multi-monitor setups, the easier option is to
  // center the pop-up over the parent window.
  const top = window.outerHeight / 2 + window.screenY - POPUP_HEIGHT / 2;
  const left = window.outerWidth / 2 + window.screenX - POPUP_WIDTH / 2;
  // eslint-disable-next-line
  return window.open(
    url,
    'OAuth2 Popup',
    `height=${POPUP_HEIGHT},width=${POPUP_WIDTH},top=${top},left=${left}`
  );
};

const closePopup = (popupRef: any) => {
  return popupRef.current?.close();
};

const cleanup = (
  intervalRef: any,
  popupRef: any,
  handleMessageListener: any
) => {
  clearInterval(intervalRef.current);
  closePopup(popupRef);
  removeState();
  window.removeEventListener('message', handleMessageListener);
};

const getIndeedOAuthUrl = (state: string) => {
  const authorizeUrl = 'https://secure.indeed.com/oauth/v2/authorize';
  const host = path(['window', 'location', 'host'], global);
  const protocol = host === 'localhost:3000' ? 'http://' : 'https://';
  const redirectUri = encodeURIComponent(protocol + host + '/indeed/oauth');
  const scope =
    'email+offline_access+employer_access+employer.advertising.account.read+employer.advertising.subaccount.read+employer.advertising.campaign+employer.advertising.campaign_report.read';
  return `${authorizeUrl}?response_type=code&client_id=${envVariables.INDEED_OAUTH_CLIENT_ID}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}&prompt=select_employer`;
};

const get7ShiftsOAuthUrl = (state: string) =>
  `https://app.7shifts.com/generate_token?client_id=${envVariables.SEVENSHIFTS_CLIENT_ID}&state=${state}`;

type useOAuth2SuccessPayload = {
  provider: keyof typeof OAUTH_PROVIDERS;
};

type useOAuth2ErrorPayload = {
  provider: keyof typeof OAUTH_PROVIDERS;
};

interface OAuth2Args {
  onSuccess: (payload: useOAuth2SuccessPayload) => void;
  onError: (payload: useOAuth2ErrorPayload) => void;
}

const useOAuth2 = (args: OAuth2Args) => {
  const popupRef = useRef<Window | null>();
  const intervalRef = useRef<NodeJS.Timer>();
  const [{ loading, error }, setUI] = useState({ loading: false, error: null });
  const [mutate7ShiftsGuid] = useMutation(MUTATE_7SHIFTS_GUID);

  const getAuth = useCallback(
    (provider: keyof typeof OAUTH_PROVIDERS) => {
      // 1. Init
      setUI({
        loading: true,
        error: null
      });

      // 2. Generate and save state
      const state = generateState();
      setSessionStorageItem(OAUTH_STATE_KEY, state);

      // 3. Open popup
      if (provider === OAUTH_PROVIDERS.Indeed) {
        const url = getIndeedOAuthUrl(state);
        return (window.location.href = url);
      } else if (provider === OAUTH_PROVIDERS.SevenShifts) {
        popupRef.current = openPopup(get7ShiftsOAuthUrl(state));
      }

      // 4. Register message listener
      async function handleMessageListener(message: MessageEvent) {
        const type = message && message.data && message.data.type;
        try {
          if (type === OAUTH_RESPONSE) {
            const errorMaybe = message && message.data && message.data.error;
            if (errorMaybe) {
              setUI({
                loading: false,
                error: errorMaybe || 'Unknown Error'
              });
            } else {
              if (provider === OAUTH_PROVIDERS.SevenShifts) {
                const guid = path(['data', 'payload', 'guid'], message);
                const companyId = path(
                  ['data', 'payload', 'company_id'],
                  message
                );
                await mutate7ShiftsGuid({
                  variables: {
                    guid: guid,
                    companyId: companyId
                  },
                  refetchQueries: [
                    {
                      query: GetCompaniesDocument,
                      variables: {}
                    },
                    {
                      query: GetMeDocument,
                      variables: {}
                    }
                  ]
                });
                args.onSuccess({ provider: OAUTH_PROVIDERS.SevenShifts });
              }
            }
          }
        } catch (genericError) {
          console.error(genericError);
          setUI({
            loading: false,
            error: (genericError as any).toString()
          });
        } finally {
          // Clear stuff ...
          cleanup(intervalRef, popupRef, handleMessageListener);
        }
      }
      window.addEventListener('message', handleMessageListener);

      // 5. Begin interval to check if popup was closed forcefully by the user
      intervalRef.current = setInterval(() => {
        const popupClosed =
          !popupRef.current ||
          !popupRef.current.window ||
          popupRef.current.window.closed;
        if (popupClosed) {
          // Popup was closed before completing auth...
          setUI(ui => ({
            ...ui,
            loading: false
          }));
          console.warn(
            'Warning: Popup was closed before completing authentication.'
          );
          clearInterval(intervalRef.current);
          removeState();
          window.removeEventListener('message', handleMessageListener);
        }
      }, 250);

      // Remove listener(s) on unmount
      return () => {
        window.removeEventListener('message', handleMessageListener);
        if (intervalRef.current) clearInterval(intervalRef.current);
      };
    },
    [mutate7ShiftsGuid] //eslint-disable-line
  );

  return { loading, error, getAuth };
};

export default useOAuth2;
