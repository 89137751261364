import React from 'react';

import { prop } from 'ramda';
import { Dimensions } from 'react-native';

import {
  FOHPageModal,
  FOHDialog,
  FOHModalProps,
  FOHModal
} from './../../ingredients';

export interface FOHReactiveModalProps extends FOHModalProps {
  dialog?: boolean;
  large?: boolean;
  testID?: string;
}

export const FOHReactiveModal = (props: FOHReactiveModalProps) =>
  props.dialog ? (
    props.large ? (
      <FOHPageModal
        close={props.close}
        children={props.children} //eslint-disable-line
        open={props.open}
        leftContentShift={prop('leftContentShift', props)}
      />
    ) : (
      <FOHDialog
        leftContentShift={prop('leftContentShift', props)}
        style={props.style}
        doneText={props.doneText}
        close={props.close}
        children={props.children} //eslint-disable-line
        open={props.open}
        testID={props.testID}
      />
    )
  ) : (
    <FOHModal
      style={props.style}
      doneText={props.doneText}
      close={props.close}
      children={props.children} //eslint-disable-line
      open={props.open}
      innerStyle={props.innerStyle}
      heightOpen={
        props.large
          ? prop('height', Dimensions.get('window')) - 100
          : props.heightOpen
      }
    />
  );
