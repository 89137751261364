import React, { useRef, useEffect } from 'react';

import styled from 'styled-components/native';
import { Platform } from 'react-native';
import { useHover } from 'react-native-web-hooks';

import {
  FOHColors,
  FOHFavorite,
  FOHLabel,
  FOHTagText,
  FOHToolTip,
  FOHToolTipType,
  FOHView
} from '../../ingredients';

interface FOHFollowBadgeProps {
  helpText?: string;
  inverse?: boolean;
  label?: string;
  onHovering?: (hovering: boolean) => any;
  small?: boolean;
  style?: any;
}

export const FOHFollowBadge = ({
  helpText,
  label,
  onHovering,
  inverse,
  small,
  style
}: FOHFollowBadgeProps) => {
  const isTooltipShown = helpText;
  const isSmallBadgeShown = small || inverse;
  const isLabelShown = !inverse && !small && label;
  const isInverseLabelShown = inverse && label;

  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);

  useEffect(() => {
    onHovering && onHovering(isHovered);
  }, [isHovered, onHovering]);

  return (
    <BadgeContainer ref={hoverRef} testID={'FOHFollowBadge'}>
      {isTooltipShown ? (
        <FOHToolTip
          open={isHovered}
          type={FOHToolTipType.LEFT}
          style={{ width: 200, right: '100%', bottom: 'auto', top: -27 }}
        >
          <FOHLabel style={{ color: FOHColors.WHITE }}>{helpText}</FOHLabel>
        </FOHToolTip>
      ) : (
        <></>
      )}

      <BadgeText style={{ ...style }} small={isSmallBadgeShown}>
        <FOHView
          style={{ alignItems: 'center', height: '100%', flexDirection: 'row' }}
        >
          <FOHFavorite white={true} />

          {isLabelShown ? <BadgeTextLabel>{label}</BadgeTextLabel> : <></>}
        </FOHView>
      </BadgeText>

      {isInverseLabelShown ? (
        <BadgeTextLabel inverse={true}>{label}</BadgeTextLabel>
      ) : (
        <></>
      )}
    </BadgeContainer>
  );
};

export const BadgeContainer = styled(FOHView)`
  height: 24px;
  align-items: center;
  flex-direction: row;
`;

interface BadgeTextLabelProps {
  inverse?: FOHFollowBadgeProps['inverse'];
}

// FIXME: Remove inline function once we bump styled-components
const BadgeTextLabel = styled((props: any) => (
  <FOHTagText {...props} />
))<BadgeTextLabelProps>`
  padding-top: 2;
  margin-left: 6;
  color: ${(props: BadgeTextLabelProps) =>
    props?.inverse ? FOHColors.BLACK : FOHColors.WHITE};
`;

const computeBadgeStyles = (small: FOHFollowBadgeProps['small']) => ({
  width: small ? 28 : '',
  paddingLeft: small ? 0 : 12,
  paddingRight: small ? 0 : 12,
  borderRadius: small && Platform.OS === 'web' ? '50%' : '100px',
  backgroundColor: FOHColors.GOLD
});

interface BadgeTextProps {
  small?: boolean;
}

// @ts-ignore Necessary until we bump styled-componets and types
export const BadgeText = styled(FOHLabel)<BadgeTextProps>`
  font-size: 12px;
  color: #333333;
  text-align: center;
  height: 24px;
  justify-content: center;
  text-align: center;
  line-height: 100%;
  padding-top: 2px;
  padding-bottom: 2px;
  word-break: break-word;
  align-items: center;

  ${(props: BadgeTextProps) => computeBadgeStyles(props?.small)};
`;
