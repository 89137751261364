import React from 'react';

import {
  FOHButton,
  FOHMultiLineTextField,
  FOHSpaceSmall,
  FOHView
} from '../../ingredients';

import { FOHForm } from './FOHForm';

export interface FOHCandidateNotesProps {
  message: string;
  messagePlaceholder: string;
  setMessage: (message: string) => void;
  submitLabel?: string;
  onPressSubmit?: () => void;
  error?: any;
  mobile?: boolean;
  disableSubmit?: boolean;
}

export const FOHCandidateNotes = (props: FOHCandidateNotesProps) => (
  <FOHForm handleSubmit={props.onPressSubmit}>
    <>
      <FOHMultiLineTextField
        style={{
          height: 400
        }}
        max={500}
        value={props.message}
        onChangeText={props.setMessage}
        placeholder={props.messagePlaceholder}
        error={props.error && props.error.message ? props.error.message : null}
      />
      <FOHSpaceSmall />
    </>
    {props.onPressSubmit && props.submitLabel ? (
      <>
        <FOHSpaceSmall />
        <FOHView
          style={{
            flexDirection: 'row',
            justifyContent: props.mobile ? 'center' : 'flex-end'
          }}
        >
          <FOHButton
            onPress={props.onPressSubmit}
            title={props.submitLabel}
            fullWidth={props.mobile}
            disabled={props.disableSubmit}
            style={{
              width: props.mobile ? '100%' : 150,
              height: 65
            }}
          />
        </FOHView>
      </>
    ) : (
      <></>
    )}
  </FOHForm>
);
