import React from 'react';

import { defaultTo, prop } from 'ramda';

import profilePhoto from '../../../images/profile_photo.svg';

import { FOHImage } from '../../ingredients';

export const FOHProfilePhotoIcon = (props: any) => (
  <FOHImage
    source={{ uri: profilePhoto }}
    style={{ width: 72, height: 72, ...defaultTo({}, prop('style', props)) }}
  />
);
