import { useState, useEffect } from 'react';

import { create } from 'zustand';
import { equals } from 'ramda';

import {
  EmployerStageType,
  EmployersEmployerStageStageTypeChoices
} from '../../graphql/generated';

type ignoredStagesStore = {
  ignoredStages: Array<string>;
  clearAll: () => void;
  add: (stageTypes: Array<string>) => void;
};

export const useIgnoredStagesStore = create<ignoredStagesStore>(set => ({
  ignoredStages: [],
  clearAll: () => {
    set(state => ({ ...state, ignoredStages: [] }), true);
  },
  add: stageTypes => {
    set(state => ({
      ...state,
      ignoredStages: [...state.ignoredStages, ...stageTypes]
    }));
  }
}));

interface Stage extends EmployerStageType {}

const refreshAvailableForStages = [
  EmployersEmployerStageStageTypeChoices.Applicants,
  EmployersEmployerStageStageTypeChoices.Offered,
  EmployersEmployerStageStageTypeChoices.Screening,
  EmployersEmployerStageStageTypeChoices.Interviews,
  EmployersEmployerStageStageTypeChoices.Rejected
];

const shouldRefreshStage = (
  ignoredStages: Array<string>,
  oldStage?: Stage,
  currentStage?: Stage
): boolean => {
  if (!oldStage || !currentStage) return false;

  if (ignoredStages.includes(oldStage!.stageType)) {
    return false;
  }

  if (oldStage.employerCandidateCount === 0) {
    if (
      currentStage.employerCandidateCount! > oldStage.employerCandidateCount
    ) {
      return true;
    }
  }

  const stageType = currentStage.stageType;

  //check if stage type is refreshable
  if (!refreshAvailableForStages.includes(stageType)) return false;

  //check for employerCandidateCount if its applicants else check refreshNotificationCount
  if (stageType === EmployersEmployerStageStageTypeChoices.Applicants) {
    if (
      oldStage.employerCandidateCount !== currentStage.employerCandidateCount
    ) {
      return true;
    }
  } else {
    return (
      currentStage.refreshNotificationCount !==
      oldStage.refreshNotificationCount
    );
  }
  return false;
};

export const useCandidateFeedRefresh = ({
  currentStages,
  isLoadingStages
}: {
  currentStages: Array<Stage>;
  isLoadingStages: boolean;
}) => {
  const [oldStages, setOldStages] = useState<Array<Stage>>([]);

  const [refreshableStageTypes, setRefreshableStageTypes] = useState<
    Array<EmployersEmployerStageStageTypeChoices>
  >([]);

  const ignoredStagesStore = useIgnoredStagesStore();

  const checkRefreshableStagesList = () => {
    const newRefreshableStages = currentStages.filter(currStage =>
      shouldRefreshStage(
        ignoredStagesStore.ignoredStages,
        oldStages.find(oldStage => oldStage.id === currStage.id),
        currStage
      )
    );
    const newRefreshableStageTypes = newRefreshableStages
      .map(stage => stage.stageType)
      .filter(stageType => !refreshableStageTypes.includes(stageType));

    return newRefreshableStageTypes;
  };

  const removeRefreshableStageType = (stageTypeToRemove: string) => {
    const newRefreshableStages = refreshableStageTypes.filter(
      stageType => stageType !== stageTypeToRemove
    );
    setRefreshableStageTypes(newRefreshableStages);
  };

  const stagesAreEqual = equals(currentStages, oldStages);

  useEffect(() => {
    if (isLoadingStages || !currentStages || stagesAreEqual) {
      return;
    }
    setOldStages(currentStages);

    if (oldStages.length > 0) {
      const newRefreshableStageTypes = checkRefreshableStagesList();
      if (newRefreshableStageTypes.length > 0) {
        // setOldStages(currentStages);
        setRefreshableStageTypes([
          ...refreshableStageTypes,
          ...newRefreshableStageTypes
        ]);
      }

      if (ignoredStagesStore.ignoredStages.length > 0) {
        ignoredStagesStore.clearAll();
      }
    }
  }, [isLoadingStages, stagesAreEqual]); //eslint-disable-line

  return {
    refreshableStageTypes,
    removeRefreshableStageType
  };
};

export default useCandidateFeedRefresh;
