import React from 'react';

import {
  FOHReactiveModal,
  FOHDetailText,
  FOHButton,
  FOHSpace,
  FOHView,
  FOHHeaderH2,
  FOHPhoneInput,
  FOHSpaceSmall,
  FOHImage
} from '../../ingredients';

export interface FOHCapturePhoneModalProps {
  open: boolean;
  close: () => void;
  headerLabel: string;
  detailLabel?: string;
  buttonLabel: string;
  image: string;
  imageWidth: number;
  imageHeight: number;
  confirm: () => void;
  cancel: () => void;
  cancelText?: string;
  dialog?: boolean;
  leftContentShift?: number;
  phone: string;
  setPhone: (text: string) => void;
}

export const FOHCapturePhoneModal = (props: FOHCapturePhoneModalProps) => {
  return (
    <FOHReactiveModal
      leftContentShift={props.leftContentShift}
      dialog={props.dialog}
      open={props.open}
      close={props.close}
      style={{ minWidth: 723 }}
      testID={'FOHCapturePhoneModal'}
    >
      <FOHView
        style={{
          flexDirection: 'row',
          paddingHorizontal: props.dialog ? 40 : 14,
          paddingVertical: props.dialog ? 26 : 14,
          maxWidth: '100%'
        }}
      >
        <FOHView style={{ alignItems: 'center', justifyContent: 'center' }}>
          <FOHImage
            source={{ uri: props.image }}
            resizeMode={'contain'}
            style={{
              width: props.imageWidth,
              height: props.imageHeight
            }}
          />
        </FOHView>
        <FOHView
          style={{
            marginLeft: 32,
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            maxWidth: 380
          }}
        >
          <FOHHeaderH2>{props.headerLabel}</FOHHeaderH2>
          <FOHSpaceSmall />
          <FOHDetailText
            style={{
              textAlign: 'left',
              fontSize: 14,
              lineHeight: 24,
              fontWeight: '400'
            }}
          >
            {props.detailLabel}
          </FOHDetailText>
          <FOHSpace />
          <FOHView style={{ flexDirection: 'row', width: '100%' }}>
            <FOHView style={{ maxWidth: '50%', width: '100%', marginRight: 8 }}>
              <FOHPhoneInput
                onChangeText={(text, _rawText) => {
                  props.setPhone(text);
                }}
                value={props.phone}
                placeholder={'Phone Number'}
              ></FOHPhoneInput>
            </FOHView>
            <FOHView style={{ maxWidth: '50%', width: '100%' }}>
              <FOHButton
                onPress={props.confirm}
                title={props.buttonLabel}
                textStyle={{ display: 'flex', alignItems: 'center' }}
              />
            </FOHView>
          </FOHView>
        </FOHView>
      </FOHView>
    </FOHReactiveModal>
  );
};
