export const fetchMorePositions = async (query, variables, first = 10) => {
  return query?.data?.positions?.pageInfo?.hasNextPage && !query?.loading
    ? await query.fetchMore({
        variables: {
          ...variables,
          after: query?.data?.positions?.pageInfo?.endCursor,
          first
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const newEdges = fetchMoreResult?.positions?.edges;
          const pageInfo = fetchMoreResult?.positions?.pageInfo;
          const totalCount =
            fetchMoreResult?.positions?.totalCount || undefined;

          return newEdges.length
            ? {
                positions: {
                  __typename: previousResult?.positions?.__typename,
                  edges: [...previousResult?.positions?.edges, ...newEdges],
                  pageInfo,
                  totalCount
                }
              }
            : previousResult;
        }
      })
    : undefined;
};
