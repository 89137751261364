import React from 'react';

import { prop, defaultTo } from 'ramda';

import menudots from '../../../images/menudots.svg';

import { FOHImage } from '../../ingredients';

export const FOHDotsIcon = (props: any) => (
  <FOHImage
    source={{ uri: menudots }}
    style={{ width: 28, height: 6, ...defaultTo({}, prop('style', props)) }}
  />
);
