import { defaultTo, prop } from 'ramda';

import { availabilityDayKeys } from '../../constants/candidates';

export const formatPostionsForTable = positions => {
  return positions.map(position => ({
    ...position,
    matches: 0,
    status: 'active'
  }));
};

export const generateKeysForPositionAvailability = position => {
  const availabilities = defaultTo([''], Object.keys(position))
    .filter(key => key.includes('availability'))
    .map(day =>
      defaultTo([''], prop(day, position)).map(
        available => `${day}-${available}`
      )
    );
  return [].concat.apply([], availabilities);
};

const getShift = dayAndShift => dayAndShift.split('-')[1];

export const defaultPosition = {
  id: 'new',
  position_name: '',
  position_type: [''],
  preferred_training: [''],
  availability_monday: [],
  availability_tuesday: [],
  availability_wednesday: [],
  availability_thursday: [],
  availability_friday: [],
  availability_saturday: [],
  availability_sunday: [],
  preferred_experience: '',
  send_notifications: false,
  location: ''
};

export const formatPositionForPost = ({
  id,
  employerProfile,
  positionName,
  positionType,
  availability,
  additionalTraining,
  yearsExp,
  sendNotifications,
  dressCode,
  benefits,
  location
}) => {
  const availabilityMap = availabilityDayKeys.reduce((acc, day) => {
    return {
      ...acc,
      [day]: availability
        .filter(dayAndShift => dayAndShift.includes(day))
        .map(getShift)
        .filter(shift => !!shift)
    };
  }, {});

  return {
    id,
    employer_profile: employerProfile,
    position_name: positionName,
    position_type: positionType.filter(item => item !== ''),
    send_notifications: sendNotifications,
    location,
    can_have_dress_code: dressCode,
    is_benefits_required: benefits,
    preferred_experience: yearsExp || '',
    preferred_training: additionalTraining.filter(item => item !== ''),
    ...availabilityMap
  };
};
