import queryString from 'query-string';
import i18n from 'i18next';

import { getAuth } from '../utils/localStorage';
import { BAD_REQUEST_STATUS } from '../constants/http';

const auth = () => getAuth() || {};

const getAuthorizationHeaderValue = token => (token ? `Bearer ${token}` : null);
const buildQueryString = query => {
  if (!query) {
    return '';
  }

  let formattedQuery = {};

  for (let key in query) {
    formattedQuery[key] = Array.isArray(query[key])
      ? query[key].join(',')
      : query[key];
  }

  return '?' + queryString.stringify(formattedQuery, { encode: false });
};

// Replace double slash so routes with /route or route work.
const getFullUrl = (path, query) => {
  const queryStr = query ? buildQueryString(query) : '';
  const url = `/api/v1/${path}`.replace('//', '/') + queryStr;
  return url;
};

export class ApiError extends Error {
  constructor(response, detail = null, fieldErrors = null) {
    super('ApiError');
    this.response = response;
    this.detail = detail;
    this.fieldErrors = fieldErrors;
  }
}

/**
 * Make API requests with Fetch
 *
 * @param {string} url
 * @param {object} options
 *  @param {string} options.method
 *  @param {boolean} [options.json=True]
 *  @param {object} [options.headers]
 *  @param {object|FormData} [options.body]
 *  @param {object} [options.query]
 */

export const api = (url, options) => {
  options = {
    json: true,
    ...options
  };
  let headers = {
    Authorization: getAuthorizationHeaderValue(auth().token),
    Accept: 'application/json',
    'Accept-Language': i18n.language
  };

  // Exclude automatic Content-Type if not json
  if (options?.json) {
    headers = {
      ...headers,
      'Content-Type': 'application/json'
    };

    if (['post', 'patch', 'put'].includes(options?.method?.toLowerCase())) {
      options = {
        ...options,
        body: JSON.stringify(options?.body)
      };
    }

    delete options?.json;
  }

  // Merge default and passed in headers
  if (options?.headers) {
    headers = {
      ...headers,
      ...options?.headers
    };

    delete options?.headers;
  }
  return fetch(getFullUrl(url, options?.query), {
    headers,
    ...options
  }).then(response => {
    if (response.ok) {
      return response.status === 204 || response.status === 302
        ? response
        : response.json();
    } else {
      const copiedResponse = { ...response };
      let detail = null;
      let fieldErrors = null;

      try {
        return response.json().then(body => {
          // Error messages pertaining to user input
          if (response.status === BAD_REQUEST_STATUS) {
            if (body.non_field_errors) {
              detail = body.non_field_errors;
              delete body.non_field_errors;
            }

            if (Object.keys(body).length) {
              fieldErrors = body;
            }
          }

          // Generic error messages from API
          if (body.detail) {
            detail = body.detail;
          }

          throw new ApiError(copiedResponse, detail, fieldErrors);
        });
      } catch (error) {
        throw new ApiError(copiedResponse, detail, fieldErrors);
      }
    }
  });
};

export const get = (url, options = {}) => {
  return api(url, {
    method: 'GET',
    ...options
  });
};

export const post = (url, body = {}, options = {}) => {
  return api(url, {
    method: 'POST',
    body: body,
    ...options
  });
};

export const destroy = (url, options = {}) => {
  return api(url, {
    method: 'DELETE',
    ...options
  });
};

export const patch = (url, body = {}, options = {}) => {
  return api(url, {
    method: 'PATCH',
    body: body,
    ...options
  });
};

api.post = post;

export default api;
