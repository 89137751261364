import React from 'react';

import { Platform } from 'react-native';

import {
  FOHButton,
  FOHButtonBordered,
  FOHColors,
  FOHView
} from '../../ingredients';

type VoidHandler = () => void;

interface FOHFormFlowButtonsProps {
  isMobile?: boolean;

  nextButtonlabel: string;
  onPressNextButton: VoidHandler;
  isNextDisabled?: boolean;
  helpTextOnNext?: string;

  previousButtonLabel?: string;
  onPressPreviousButton?: VoidHandler;
  isPreviousDisabled?: boolean;
  helpTextOnPrevious?: string;

  nextButtonStyle?: any;
  prevButtonStyle?: any;
}

export const FOHFormFlowButtons = (props: FOHFormFlowButtonsProps) => {
  const MobileButtons = (
    <FOHView
      style={{
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        bottom: 0,
        backgroundColor: FOHColors.WHITE,
        shadowColor: FOHColors.BLACK,
        shadowOpacity: 0.3,
        shadowOffset: { width: 0, height: -4 },
        shadowRadius: 6,
        width: '100%',
        position: Platform.OS === 'web' ? 'fixed' : 'relative',
        borderColor: FOHColors.WHITE,
        borderWidth: 1,
        padding: 10
      }}
    >
      <FOHView
        style={{
          flexDirection: 'row',
          maxWidth: 570,
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {props.previousButtonLabel && props.onPressPreviousButton ? (
          <FOHButtonBordered
            color={FOHColors.WHITE}
            style={{ width: 151 }}
            disabled={props.isPreviousDisabled}
            title={props.previousButtonLabel}
            onPress={props.onPressPreviousButton}
            enableHover={!!props.helpTextOnPrevious}
            helpText={props.helpTextOnPrevious}
          />
        ) : (
          <></>
        )}

        <FOHButton
          style={{
            width:
              props.isMobile &&
              props.previousButtonLabel &&
              props.onPressPreviousButton
                ? 151
                : '100%',
            marginLeft:
              props.isMobile &&
              props.previousButtonLabel &&
              props.onPressPreviousButton
                ? 'auto'
                : ''
          }}
          enableHover={!!props.helpTextOnNext}
          helpText={props.helpTextOnNext}
          disabled={props.isNextDisabled}
          title={props.nextButtonlabel}
          onPress={props.onPressNextButton}
        />
      </FOHView>
    </FOHView>
  );

  const WebButtons = (
    <FOHView
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%'
      }}
    >
      {props.previousButtonLabel && props.onPressPreviousButton ? (
        <FOHButtonBordered
          color={FOHColors.WHITE}
          style={{ maxWidth: 151, ...props.prevButtonStyle }}
          disabled={props.isPreviousDisabled}
          title={props.previousButtonLabel}
          onPress={props.onPressPreviousButton}
          enableHover={!!props.helpTextOnPrevious}
          helpText={props.helpTextOnPrevious}
        />
      ) : (
        <></>
      )}

      <FOHButton
        style={{ maxWidth: 151, marginLeft: 'auto', ...props.nextButtonStyle }}
        disabled={props.isNextDisabled}
        title={props.nextButtonlabel}
        onPress={props.onPressNextButton}
        enableHover={!!props.helpTextOnNext}
        helpText={props.helpTextOnNext}
      />
    </FOHView>
  );

  return props.isMobile ? MobileButtons : WebButtons;
};
