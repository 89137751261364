import React from 'react';

import { prop, defaultTo } from 'ramda';
import { ImageStyle, StyleProp } from 'react-native';

import hideInactive from '../../../images/hide_inactive.svg';
import hideActive from '../../../images/hide_active.svg';

import { FOHImage } from '../../ingredients';

export const FOHHide = (props: {
  active?: boolean;
  style?: StyleProp<ImageStyle>;
}) => (
  <FOHImage
    source={{
      uri: props.active ? hideActive : hideInactive
    }}
    style={{
      width: 16,
      height: 16,
      ...defaultTo({}, prop('style', props) as ImageStyle)
    }}
  />
);
