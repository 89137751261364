import React from 'react';

import { defaultTo, prop } from 'ramda';

import icon from '../../../images/blue_department.svg';

import { FOHImage } from '../../ingredients';

export const FOHBlueDepartmentIcon = (props: any) => (
  <FOHImage
    source={{ uri: icon }}
    style={{ width: 24, height: 24, ...defaultTo({}, prop('style', props)) }}
  />
);
