import React from 'react';

import styled from 'styled-components/native';
import styledVanilla from 'styled-components';
import { StyleProp, ViewStyle } from 'react-native';

import { FOHErrorLabel, FOHTextField } from '../../ingredients';

export interface FOHColorPickerProps {
  colorStyle?: StyleProp<ViewStyle>;
  error?: string;
  setValue: (val: string) => void;
  style: StyleProp<ViewStyle>;
  value: string;
}

const DEFAULT_COLOR = '';

export const FOHColorPicker = ({
  colorStyle,
  error,
  setValue,
  style,
  value = ''
}: FOHColorPickerProps) => {
  const handleChangeText = (text: string): void => {
    const newText = text.replace(/[^aA-fF^0-9]/g, '').toUpperCase();
    setValue(newText);
  };

  return (
    <Wrapper style={style}>
      <ColorSquare color={`#${value}`} style={colorStyle} />
      <HashDecoration>#</HashDecoration>

      <TextWrapper
        maxLength={6}
        onChangeText={handleChangeText}
        value={value || DEFAULT_COLOR}
      />

      {error ? <FOHErrorLabel>{error}</FOHErrorLabel> : <></>}
    </Wrapper>
  );
};

const Wrapper = styled.View`
  align-items: center;
  flex-direction: row;
`;

const ColorSquare = styled.View<{ color: string }>`
  position: absolute;
  left: 4px;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background-color: ${(props: any) => `${props.color}`};
`;

const HashDecoration = styledVanilla.span`
  position: absolute;
  left: 54px; // make room for color square
`;

const TextWrapper = styled(FOHTextField)`
  background: transparent;
  font-size: 16px;
  padding-left: 67px; // make room for color square and hash
`;
