import React from 'react';

import { defaultTo, prop } from 'ramda';

import expand from '../../../images/expand_more.svg';

import { FOHImage } from '../../ingredients';

export const FOHExpandIcon = (props: any) => (
  <FOHImage
    source={{ uri: expand }}
    style={{ width: 24, height: 24, ...defaultTo({}, prop('style', props)) }}
  />
);
