import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { defaultTo, path, prop, length } from 'ramda';

import {
  CANDIDATE_EDIT_AVAILABILITY,
  CANDIDATE_EDIT_BENEFITS,
  CANDIDATE_EDIT_COMMUTE,
  CANDIDATE_EDIT_CONTACT_INFO,
  CANDIDATE_EDIT_DRESS_CODE,
  CANDIDATE_EDIT_EXPERIENCE,
  CANDIDATE_EDIT_IMAGE,
  CANDIDATE_EDIT_POSITIONS,
  CANDIDATE_EDIT_REFERENCES,
  CANDIDATE_EDIT_RESTAURANT_TYPES,
  CANDIDATE_EDIT_RESUME,
  CANDIDATE_EDIT_SKILLS,
  CANDIDATE_EDIT_SUMMARY,
  CANDIDATE_EDIT_TOP_THREE,
  CANDIDATE_EDIT_VIDEO,
  CANDIDATE_EDIT_WORK_HISTORY,
  CANDIDATE_PROFILE
} from '../../constants';
import { useGetMyProfileCompletenessQuery } from '../../graphql/generated';
import { useNavigation } from '../../utils/navigation';
import { FOHProfileCompletionBlock } from '../../components';

export const ProfileCompletionBlock = props => {
  const { navigateTo } = useNavigation();
  const [stepIndex, setStepIndex] = useState(0);

  const profileCompletenessQuery = useGetMyProfileCompletenessQuery({});
  const handle = path(
    ['data', 'me', 'candidateProfile', 'handle'],
    profileCompletenessQuery
  );

  const parentRoute = defaultTo(
    `${CANDIDATE_PROFILE}/${handle}`,
    prop('parentRoute', props)
  );

  const percentage = defaultTo(
    50,
    path(
      ['data', 'me', 'candidateProfile', 'profileCompleteness'],
      profileCompletenessQuery
    )
  );

  const incompleteSections = defaultTo(
    [],
    path(
      ['data', 'me', 'candidateProfile', 'incompleteSections'],
      profileCompletenessQuery
    )
  );

  const handleOnPress = to => {
    navigateTo(`${parentRoute}${to}`);
  };

  const { t } = useTranslation('ProfileCompletenessSteps');

  const allSteps = {
    work_history: {
      title: t('workHistoryTitle'),
      detail: t('workHistoryDetail'),
      onPress: () => handleOnPress(`${CANDIDATE_EDIT_WORK_HISTORY}?id=`),
      buttonLabel: t('workHistoryButton')
    },
    personal_summary: {
      title: t('personalSummaryTitle'),
      detail: t('personalSummaryDetail'),
      onPress: () => handleOnPress(CANDIDATE_EDIT_SUMMARY),
      buttonLabel: t('personalSummaryButton')
    },
    availability: {
      title: t('availabilityTitle'),
      detail: t('availabilityDetail'),
      onPress: () => handleOnPress(CANDIDATE_EDIT_AVAILABILITY),
      buttonLabel: t('availabilityButton')
    },
    preferred_categories: {
      title: t('preferredCategoriesTitle'),
      detail: t('preferredCategoriesDetail'),
      onPress: () => handleOnPress(CANDIDATE_EDIT_RESTAURANT_TYPES),
      buttonLabel: t('preferredCategoriesButton')
    },
    positions: {
      title: t('positionsTitle'),
      detail: t('positionsDetail'),
      onPress: () => handleOnPress(CANDIDATE_EDIT_POSITIONS),
      buttonLabel: t('positionsButton')
    },
    profile_image: {
      title: t('profileImageTitle'),
      detail: t('profileImageDetail'),
      onPress: () => handleOnPress(CANDIDATE_EDIT_IMAGE),
      buttonLabel: t('profileImageButton')
    },
    profile_video: {
      title: t('profileVideoTitle'),
      detail: t('profileVideoDetail'),
      onPress: () => handleOnPress(CANDIDATE_EDIT_VIDEO),
      buttonLabel: t('profileVideoButton')
    },
    additional_training: {
      title: t('additionalTrainingTitle'),
      detail: t('additionalTrainingDetail'),
      onPress: () => handleOnPress(CANDIDATE_EDIT_SKILLS),
      buttonLabel: t('additionalTrainingButton')
    },
    years_experience: {
      title: t('yearsExperienceTitle'),
      detail: t('yearsExperienceDetail'),
      onPress: () => handleOnPress(CANDIDATE_EDIT_EXPERIENCE),
      buttonLabel: t('yearsExperienceButton')
    },
    employment_preferences: {
      title: t('employmentPreferencesTitle'),
      detail: t('employmentPreferencesDetail'),
      onPress: () => handleOnPress(CANDIDATE_EDIT_TOP_THREE),
      buttonLabel: t('employmentPreferencesButton')
    },
    commute_preference: {
      title: t('commutePreferenceTitle'),
      detail: t('commutePreferenceDetail'),
      onPress: () => handleOnPress(CANDIDATE_EDIT_COMMUTE),
      buttonLabel: t('commutePreferenceButton')
    },
    is_benefits_required: {
      title: t('benefitsTitle'),
      detail: t('benefitsDetail'),
      onPress: () => handleOnPress(CANDIDATE_EDIT_BENEFITS),
      buttonLabel: t('benefitsButton')
    },
    can_have_dress_code: {
      title: t('dressCodeTitle'),
      detail: t('dressCodeDetail'),
      onPress: () => handleOnPress(CANDIDATE_EDIT_DRESS_CODE),
      buttonLabel: t('dressCodeButton')
    },
    references: {
      title: t('referencesTitle'),
      detail: t('referencesDetail'),
      onPress: () => handleOnPress(CANDIDATE_EDIT_REFERENCES),
      buttonLabel: t('referencesButton')
    },
    resume: {
      title: t('resumeTitle'),
      detail: t('resumeDetail'),
      onPress: () => handleOnPress(CANDIDATE_EDIT_RESUME),
      buttonLabel: t('resumeButton')
    },
    contact_info: {
      title: t('contactInfoTitle'),
      detail: t('contactInfoDetail'),
      onPress: () => handleOnPress(CANDIDATE_EDIT_CONTACT_INFO),
      buttonLabel: t('contactInfoButton')
    }
  };

  const steps = incompleteSections.map(step => prop(step, allSteps));

  useEffect(() => {
    if (stepIndex > length(steps) - 1) {
      setStepIndex(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepIndex, length(steps)]);

  return (
    <FOHProfileCompletionBlock
      readOnly={!!props.readOnly}
      completeProfileTitle={
        percentage === 100
          ? t('profileCompletenessTitleComplete')
          : t('profileCompletenessTitle')
      }
      completeProfileDetailText={
        percentage === 100
          ? t('profileCompletenessDetailComplete')
          : t('profileCompletenessDetail')
      }
      small={props.small}
      percentage={percentage}
      yourScoreLabel={t('profileCompletenessScoreLabel')}
      score={
        percentage <= 20 ? t('low') : percentage <= 80 ? t('medium') : t('high')
      }
      previousLabel={t('CandidateOnboardingSteps:previous')}
      nextLabel={t('common:next')}
      next={() => {
        setStepIndex(stepIndex + 1);
      }}
      previous={() => {
        setStepIndex(stepIndex - 1);
      }}
      stepIndex={stepIndex}
      steps={steps}
    ></FOHProfileCompletionBlock>
  );
};
