import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
  compose,
  defaultTo,
  path,
  prop,
  groupBy,
  toLower,
  length
} from 'ramda';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';

import {
  FOHBannerType,
  FOHBlueAdd,
  FOHColors,
  FOHEditMarkerBlack,
  FOHErrorLabel,
  FOHFonts,
  FOHIconButton,
  FOHInterviewMeetingType,
  FOHLabel,
  FOHScrollView,
  FOHSelect,
  FOHSelectItem,
  FOHSmallDetailLabel,
  FOHSpace,
  FOHSpaceLarger,
  FOHSpaceSmall,
  FOHSpaceSmallest,
  FOHSwitch,
  FOHToolTipType,
  FOHTouchableOpacity,
  FOHTrashCanBlk,
  FOHView
} from '../../components';
import {
  batchMutations,
  formatRangeToTimeSelect,
  handle,
  scrollToTop,
  unwrapEdgesAt,
  useIsMobile
} from '../../utils';
import {
  InterviewScheduleDocument,
  useCreateInterviewScheduleMutation,
  useInterviewScheduleQuery,
  useMutateInterviewScheduleMutation
} from '../../graphql/generated';
import {
  INTERVIEW_LENGTH_OPTIONS,
  INTERVIEW_WINDOW_OPTIONS,
  getStringKeyForScheduleDay
} from '../../constants';

import { withEmployerSettings } from '../EmployerSettings/withEmployerSettings';
import { useBanner } from '../Navigation';

import { FOHAddOrEditInterviewSchedules } from './components';
import { generateScheduleRangeMutations } from './generateScheduleRangeMutations';

const byTimeSlot = groupBy(range => {
  return `${range.startHour}.${range.startMinute}.${range.endHour}.${range.endMinute}`;
});

const dedupeArray = arr => Array.from(new Set(arr));

const processRangesToString = (ranges, rangeKey, t) => {
  const dedupedDays = dedupeArray(
    defaultTo([''], path([rangeKey], ranges))
      .sort(
        (a, b) =>
          prop('day', a).match(/(\d+)$/)[0] - prop('day', b).match(/(\d+)$/)[0]
      )
      .map(_range => t(getStringKeyForScheduleDay(prop('day', _range))))
  ).join(', ');

  const from = formatRangeToTimeSelect(
    path([rangeKey, 0, 'startHour'], ranges),
    path([rangeKey, 0, 'startMinute'], ranges)
  );

  const to = formatRangeToTimeSelect(
    path([rangeKey, 0, 'endHour'], ranges),
    path([rangeKey, 0, 'endMinute'], ranges)
  );

  return `${dedupedDays},  ${from} - ${to}`;
};

const EmployerInterviewAvailability = () => {
  const { t } = useTranslation('EmployerInterviewScheduleFeature');

  const { isMobile } = useIsMobile();
  const scrollRef = useRef();
  const client = useApolloClient();

  const { setBanner } = useBanner();

  const [selectedLength, setSelectedLength] = useState(30);
  const [where, setWhere] = useState(FOHInterviewMeetingType.INPERSON);
  const [interviewWindow, setInterviewWindow] = useState(4);
  const [allowOverBooking, setAllowOverBooking] = useState(false);
  const [addOrEditScheduleBlockIsDisplay, setAddOrEditScheduleBlockIsDisplay] =
    useState(false);
  const [editScheduleRanges, setEditScheduleRanges] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [error, setError] = useState();

  const interviewLocation = toLower(where);

  const [mutateInterviewSchedule] = useMutateInterviewScheduleMutation();
  const [createInterviewSchedule] = useCreateInterviewScheduleMutation();

  const interviewScheduleQuery = useInterviewScheduleQuery({});

  const interviewSchedule = useMemo(() => {
    return path(['data', 'me', 'interviewschedule'], interviewScheduleQuery);
  }, [interviewScheduleQuery]);

  const ranges = useMemo(
    () => byTimeSlot(unwrapEdgesAt(['ranges', 'edges'], interviewSchedule)),
    [interviewSchedule]
  );

  const handleScrollToTop = () => {
    scrollToTop({});
    scrollRef.current &&
      scrollRef.current.scrollTo({
        y: 0,
        animated: true
      });
  };

  const savePreferences = async () => {
    const [response, errorOnCreateOrUpdate] = await handle(
      interviewSchedule
        ? mutateInterviewSchedule({
            variables: {
              id: prop('id', interviewSchedule),
              allowOverbooking: allowOverBooking,
              location:
                interviewLocation === 'inperson'
                  ? 'inPerson'
                  : interviewLocation,
              durationMinutes: parseInt(selectedLength),
              maxWeeksOut: parseInt(interviewWindow)
            },
            refetchQueries: [
              {
                query: InterviewScheduleDocument
              }
            ]
          })
        : createInterviewSchedule({
            variables: {
              locationPhone: phone,
              allowOverbooking: allowOverBooking,
              location:
                interviewLocation === 'inperson'
                  ? 'inPerson'
                  : interviewLocation,
              durationMinutes: parseInt(selectedLength),
              maxWeeksOut: parseInt(interviewWindow)
            },
            refetchQueries: [
              {
                query: InterviewScheduleDocument
              }
            ]
          })
    );

    setIsUpdated(false);
    handleScrollToTop();

    const err = path(['data', 'mutateInterviewSchedule', 'errors'], response);

    if (err || errorOnCreateOrUpdate) {
      return setError(err || errorOnCreateOrUpdate);
    }

    setBanner({
      bannerType: FOHBannerType.SUCCESS,
      bannerMessage: t('common:success')
    });
  };

  useEffect(() => {
    if (interviewSchedule) {
      setWhere(prop('location', interviewSchedule));
      setSelectedLength(
        prop('durationMinutes', interviewSchedule).match(/(\d+)$/)[0]
      );
      setInterviewWindow(prop('maxWeeksOut', interviewSchedule));
      setAllowOverBooking(prop('allowOverbooking', interviewSchedule));
    }
  }, [interviewScheduleQuery?.data]);

  useEffect(() => {
    if (error) setError();
  }, [where, selectedLength, interviewWindow, allowOverBooking]);

  // Save preferences on update
  useEffect(() => {
    if (isUpdated) {
      savePreferences();
    }
  }, [isUpdated]);

  return (
    <FOHView
      style={{
        alignItems: isMobile ? 'center' : 'flex-start'
      }}
    >
      <FOHScrollView
        style={{
          height: isMobile ? undefined : '80vh',
          width: '100%'
        }}
        ref={scrollRef}
      >
        <FOHView
          style={{
            paddingHorizontal: isMobile ? 14 : 28,
            paddingTop: isMobile ? 14 : 20,
            paddingBottom: isMobile ? 14 : 50,
            width: '100%'
          }}
        >
          <FOHView
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              flexWrap: 'wrap'
            }}
          >
            <FOHView
              style={{
                width: isMobile ? '100%' : '45%',
                justifyContent: 'flex-start'
              }}
            >
              <FOHView
                style={{
                  flexDirection: isMobile ? 'column' : 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                  display: 'flex'
                }}
              >
                <FOHView
                  style={{
                    width: isMobile ? '100%' : '48%',
                    marginBottom: isMobile ? 8 : 16
                  }}
                >
                  <FOHLabel heavy style={{ color: FOHColors.GRAYSCALE_900 }}>
                    {t('interviewDurationLabel')}
                  </FOHLabel>
                  <FOHSpaceSmallest />
                  <FOHSpaceSmall />
                  <FOHSelect
                    selectedValue={selectedLength}
                    onValueChange={value => {
                      setSelectedLength(value);
                      setIsUpdated(true);
                    }}
                    style={{ width: '100%' }}
                    neverEmpty
                  >
                    {INTERVIEW_LENGTH_OPTIONS.map(_duration => (
                      <FOHSelectItem
                        key={_duration.label}
                        label={_duration.label}
                        value={_duration.value}
                      />
                    ))}
                  </FOHSelect>
                </FOHView>
                {isMobile ? <FOHSpaceSmall /> : null}
                <FOHView
                  style={{
                    width: isMobile ? '100%' : '48%',
                    marginBottom: isMobile ? 8 : 16
                  }}
                >
                  <FOHLabel heavy style={{ color: FOHColors.GRAYSCALE_900 }}>
                    {t('interviewLocationLabel')}
                  </FOHLabel>
                  <FOHSpaceSmallest />
                  <FOHSpaceSmall />
                  <FOHSelect
                    selectedValue={where}
                    onValueChange={value => {
                      setWhere(value);
                      setIsUpdated(true);
                    }}
                    neverEmpty
                    style={{ width: '100%' }}
                  >
                    <FOHSelectItem
                      label={t('inPersonLabel')}
                      value={FOHInterviewMeetingType.INPERSON}
                    />
                    <FOHSelectItem
                      label={t('remoteMeetingLabel')}
                      value={FOHInterviewMeetingType.REMOTE}
                    />
                    <FOHSelectItem
                      label={t('isPhoneLabel')}
                      value={FOHInterviewMeetingType.PHONE}
                    />
                  </FOHSelect>
                </FOHView>
              </FOHView>
              {isMobile ? <FOHSpace /> : null}
              <FOHView style={{ flex: 1 }}>
                <FOHLabel heavy style={{ color: FOHColors.GRAYSCALE_900 }}>
                  {t('bookingOptionsLabel')}
                </FOHLabel>
                {isMobile ? <FOHSpaceSmall /> : <FOHSpace />}
                <FOHView
                  style={{
                    flexDirection: isMobile ? 'column' : 'row',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    display: 'flex'
                  }}
                >
                  <FOHView
                    style={{
                      width: isMobile ? '100%' : '48%',
                      marginBottom: isMobile ? 8 : 16,
                      alignItems: 'flex-start'
                    }}
                  >
                    <FOHLabel heavy style={{ color: FOHColors.GRAYSCALE_900 }}>
                      {t('howFarOutBookLabel')}
                    </FOHLabel>
                    <FOHSpaceSmallest />
                    <FOHSpaceSmall />
                    <FOHSelect
                      selectedValue={interviewWindow}
                      onValueChange={value => {
                        setInterviewWindow(value);
                        setIsUpdated(true);
                      }}
                      style={{ width: '100%' }}
                      neverEmpty
                    >
                      {INTERVIEW_WINDOW_OPTIONS.map(window => (
                        <FOHSelectItem
                          key={window.label}
                          label={window.label}
                          value={window.value}
                        />
                      ))}
                    </FOHSelect>
                  </FOHView>
                  {isMobile ? <FOHSpaceSmall /> : null}
                  <FOHView
                    style={{
                      width: isMobile ? '100%' : '48%',
                      marginBottom: isMobile ? 8 : 16,
                      alignItems: 'flex-start'
                    }}
                  >
                    <FOHSwitch
                      value={allowOverBooking}
                      onValueChange={val => {
                        setAllowOverBooking(val);
                        setIsUpdated(true);
                      }}
                      width={'100%'}
                      label={t('allowOverbookingLabel')}
                      onColor={FOHColors.PACIFIC_BLUE}
                      offColor={FOHColors.GRAY}
                      onLabel={t('common:onLabel')}
                      offLabel={t('common:offLabel')}
                    />
                  </FOHView>
                </FOHView>
              </FOHView>
              {length(error) > 0 ? (
                <>
                  <FOHSpace />
                  <FOHErrorLabel>
                    {`• ${error.map(_e => prop('messages', _e)).join('\n\n')}`}
                  </FOHErrorLabel>
                </>
              ) : (
                <></>
              )}
              {isMobile ? <FOHSpace /> : null}
              <FOHView style={{ flex: 1 }}>
                <FOHView
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start'
                  }}
                >
                  <FOHLabel heavy style={{ color: FOHColors.GRAYSCALE_900 }}>
                    {t('yourScheduleLabel')}
                  </FOHLabel>
                  <FOHSpaceSmall />
                  <FOHTouchableOpacity
                    onPress={() => {
                      setEditScheduleRanges([]);
                      setAddOrEditScheduleBlockIsDisplay(true);
                    }}
                    disabled={addOrEditScheduleBlockIsDisplay}
                  >
                    <FOHBlueAdd
                      style={{
                        tintColor: addOrEditScheduleBlockIsDisplay
                          ? FOHColors.GRAYSCALE_200
                          : FOHColors.PRIMARY_TEAL_P1
                      }}
                    />
                  </FOHTouchableOpacity>
                </FOHView>
                <FOHSpace />
                <FOHView
                  style={{
                    paddingHorizontal: isMobile ? 14 : 16,
                    paddingVertical: 14,
                    backgroundColor: FOHColors.WHITE,
                    borderWidth: 1,
                    borderColor: FOHColors.GRAYSCALE_200,
                    borderRadius: 4
                  }}
                >
                  {Object.keys(ranges).length > 0 ? (
                    <>
                      {Object.keys(ranges).map(rangeKey => (
                        <FOHView
                          key={rangeKey}
                          style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            marginBottom: 6
                          }}
                        >
                          <FOHView style={{ flex: 3 }}>
                            <FOHSmallDetailLabel
                              style={{
                                textTransform: 'capitalize',
                                textWrap: 'wrap',
                                fontFamily: FOHFonts.REGULAR,
                                lineHeight: 24,
                                fontSize: 16
                              }}
                            >
                              {processRangesToString(ranges, rangeKey, t)}
                            </FOHSmallDetailLabel>
                          </FOHView>
                          <FOHView
                            style={{
                              flex: 1,
                              flexDirection: 'row',
                              justifyContent: 'flex-end',
                              alignItems: 'center'
                            }}
                          >
                            <FOHIconButton
                              enableHover={true}
                              helpText={t('MyTeamFeature:edit')}
                              toolTipType={FOHToolTipType.LEFT}
                              onPress={() => {
                                setEditScheduleRanges(prop(rangeKey, ranges));
                                setAddOrEditScheduleBlockIsDisplay(true);
                              }}
                              icon={() => <FOHEditMarkerBlack />}
                            />
                            <FOHSpaceLarger />
                            <FOHIconButton
                              enableHover={true}
                              helpText="Delete schedule"
                              toolTipType={FOHToolTipType.CENTER}
                              disabled={Object.keys(ranges).length === 1}
                              onPress={async () => {
                                const deletes = path([rangeKey], ranges).map(
                                  range => ({
                                    id: prop('id', range)
                                  })
                                );
                                const { deleteRangeMutations } =
                                  generateScheduleRangeMutations({
                                    deletes
                                  });
                                const batchedDelete = batchMutations(
                                  'DeleteScheduleRange',
                                  deleteRangeMutations
                                );
                                await client.mutate({
                                  mutation: batchedDelete,
                                  refetchQueries: [
                                    {
                                      query: InterviewScheduleDocument,
                                      variables: {}
                                    }
                                  ]
                                });

                                handleScrollToTop();

                                setBanner({
                                  bannerType: FOHBannerType.SUCCESS,
                                  bannerMessage: t('common:success')
                                });
                              }}
                              icon={() => (
                                <FOHTrashCanBlk
                                  style={{
                                    tintColor:
                                      Object.keys(ranges).length === 1
                                        ? FOHColors.GRAYSCALE_200
                                        : FOHColors.BLACK
                                  }}
                                />
                              )}
                            />
                          </FOHView>
                        </FOHView>
                      ))}
                    </>
                  ) : null}
                </FOHView>
                <FOHSpace />
                {addOrEditScheduleBlockIsDisplay ? (
                  <>
                    <FOHAddOrEditInterviewSchedules
                      onCancel={() => {
                        setAddOrEditScheduleBlockIsDisplay(false);
                        setEditScheduleRanges([]);
                      }}
                      interviewSchedule={interviewSchedule}
                      ranges={editScheduleRanges}
                      scrollToTop={handleScrollToTop}
                    />
                    <FOHSpace />
                  </>
                ) : null}
              </FOHView>
            </FOHView>
          </FOHView>
        </FOHView>
      </FOHScrollView>
    </FOHView>
  );
};

export default compose(withEmployerSettings)(EmployerInterviewAvailability);
