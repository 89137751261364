export const isBrowser = typeof window !== 'undefined';

export const setSessionStorageItem = (
  key: string,
  data: any,
  isObject = true
) => {
  if (!isBrowser || !sessionStorage) {
    console.log('No session storage setItem');
    return;
  }
  if (isObject) {
    data = JSON.stringify(data);
  }
  sessionStorage.setItem(key, data);
  return data;
};

export const removeSessionStorageItem = (key: string) => {
  if (!isBrowser || !sessionStorage) {
    console.log('No session storage removeItem');
    return;
  }
  sessionStorage.removeItem(key);
};

export const getSessionStorageItem = (key: string, isObject = true) => {
  if (!isBrowser || !sessionStorage) {
    console.log('No session storage getItem');
    return;
  }
  const data = sessionStorage.getItem(key);

  if (data && isObject && data !== 'undefined') {
    return JSON.parse(data);
  }
  return data;
};
