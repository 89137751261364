import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { path, prop } from 'ramda';
import Loadable from '@loadable/component';

import { useGetMyPhotoQuery } from '../../graphql/generated';
import {
  ALLOWED_ROLES_GROUPS,
  CAREER_PAGE_DASHBOARD,
  ROLES,
  EMPLOYER_SETTINGS_MENU,
  TEMPLATES,
  PERSONAL_ACCOUNT,
  EMPLOYER_DASHBOARD,
  FEED,
  UPCOMING_INTERVIEWS_SCHEDULE,
  NETWORK,
  PENDING_EMPLOYER_PROFILE,
  CAMPAIGN_HOME,
  SUBSCRIBE_LOCATIONS_POSITIONS,
  HELP_REQUEST_FORM
} from '../../constants';
import {
  FOHSidebarNav,
  useIsRestricted,
  FOHColors,
  FOHView,
  FOHBlueCareerIcon,
  FOHBlueCalendarScheduledIcon,
  FOHBlueDashboardIcon,
  FOHCampaignIcon,
  FOHCandidateNetworkIcon,
  FOHDashboardIcon,
  FOHFeedIcon,
  FOHGrayCalendarScheduledIcon,
  FOHGrayCampaignIcon,
  FOHGrayCareerIcon,
  FOHGrayFeedIcon,
  FOHGrayHelpIcon,
  FOHInverseButton,
  FOHMessageTemplateBlue,
  FOHSettingsGrayIcon
} from '../../components';
import { useNavigation } from '../../utils/navigation';
import { AmplitudeTrackingEvents, useTrackAmplitudeEvent } from '../../utils';

import useLogoutRedirectContext from '../SignIn/useLogoutRedirectContext';
import { useGetMeData } from '../SignIn';

import { getProfilePhoto } from './TopNav';
import { useSidebarNav } from './useSidebar';

const LoadableSendbirdInbox = Loadable(() => import('../Chat/SendbirdInbox'));

const SelectedCandidateNetworkIcon = props => (
  <FOHCandidateNetworkIcon {...props} selected={true} />
);

export const SidebarMenu = props => {
  const { navigateTo, location: navLocation } = useNavigation();

  const {
    expandedNav,
    setExpandedNav,
    setProfileDropdownOpen,
    profileDropdownOpen,
    setSearch,
    search,
    alwaysOpenNav
  } = useSidebarNav(props);

  const { setLogoutWipeCacheClicked } = useLogoutRedirectContext();

  const { isRestricted } = useIsRestricted({
    allowedRoles: ALLOWED_ROLES_GROUPS.ROLES.ADMIN_USER,
    allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.EMPLOYER]
  });

  const { data: me } = useGetMeData();
  const userRole = path(['employerProfileUser', 'role'], me);

  const myPhotoQuery = useGetMyPhotoQuery({});
  const profileImage = getProfilePhoto(myPhotoQuery);

  const { t } = useTranslation('TopNavFeature');

  const { logEventMetrics } = useTrackAmplitudeEvent();

  const hasSubscribeRoute =
    navLocation?.pathname === SUBSCRIBE_LOCATIONS_POSITIONS;

  const createSidebarNavItem = (
    titleKey,
    icon,
    selectedIcon,
    route,
    additionalProps = {},
    disabled = null
  ) => ({
    title: t(titleKey),
    icon,
    selectedIcon,
    route,
    disabled: disabled !== null ? disabled : hasSubscribeRoute,
    ...additionalProps
  });

  const campaignHome =
    userRole === ROLES.ADMIN && !isRestricted
      ? createSidebarNavItem(
          'CampaignsFeature:campaigns',
          FOHGrayCampaignIcon,
          FOHCampaignIcon,
          CAMPAIGN_HOME
        )
      : {};

  const careerPageDashboard = !isRestricted
    ? createSidebarNavItem(
        'CareerPage:careers',
        FOHGrayCareerIcon,
        FOHBlueCareerIcon,
        CAREER_PAGE_DASHBOARD
      )
    : {};

  const onClickHelp = () => {
    setExpandedNav(false);
    navigateTo(HELP_REQUEST_FORM);
  };

  const hasPendingEmployerProfileRoute =
    navLocation?.pathname === PENDING_EMPLOYER_PROFILE;

  const sideNavItems = useMemo(
    () =>
      hasPendingEmployerProfileRoute
        ? []
        : [
            createSidebarNavItem(
              'dashboard',
              FOHDashboardIcon,
              FOHBlueDashboardIcon,
              EMPLOYER_DASHBOARD
            ),
            createSidebarNavItem('feed', FOHGrayFeedIcon, FOHFeedIcon, FEED),
            createSidebarNavItem(
              'smsTemplates',
              FOHMessageTemplateBlue,
              FOHMessageTemplateBlue,
              TEMPLATES
            ),
            createSidebarNavItem(
              'upcomingInterviews',
              FOHGrayCalendarScheduledIcon,
              FOHBlueCalendarScheduledIcon,
              UPCOMING_INTERVIEWS_SCHEDULE
            ),
            createSidebarNavItem(
              'candidateNetwork',
              FOHCandidateNetworkIcon,
              SelectedCandidateNetworkIcon,
              NETWORK
            ),
            campaignHome,
            careerPageDashboard
          ].filter(item => item.title),
    [hasPendingEmployerProfileRoute, hasSubscribeRoute]
  );

  const bottomNavItems = useMemo(
    () =>
      hasPendingEmployerProfileRoute
        ? []
        : [
            createSidebarNavItem(
              'settings',
              FOHSettingsGrayIcon,
              FOHSettingsGrayIcon,
              `${EMPLOYER_SETTINGS_MENU}${PERSONAL_ACCOUNT}`
            ),
            createSidebarNavItem(
              'help',
              FOHGrayHelpIcon,
              FOHGrayHelpIcon,
              '',
              { onPress: onClickHelp },
              false
            )
          ],
    [hasPendingEmployerProfileRoute, hasSubscribeRoute]
  );

  const onClickSideNavItem = (route, title, groupName = '') => {
    const subNavItemClickedDetail = groupName
      ? { sub_navigation_item_clicked: groupName }
      : undefined;

    const navItemClickedEventDetail = {
      event_type: AmplitudeTrackingEvents.NAVIGATION_ITEM_CLICKED.EVENT_TYPE,
      navigation_item_clicked: {
        nav_item_title: title,
        nav_item_route: route,
        ...subNavItemClickedDetail
      }
    };

    logEventMetrics(
      AmplitudeTrackingEvents.NAVIGATION_ITEM_CLICKED.EVENT_NAME,
      navItemClickedEventDetail
    );

    navigateTo(`${route}`);
  };

  return (
    <FOHView
      style={{
        position: 'fixed',
        zIndex: 9999,
        backgroundColor: '#ffffff'
      }}
    >
      <FOHSidebarNav
        hideSearch={true}
        isExpanded={expandedNav}
        profileOpen={profileDropdownOpen}
        setIsExpanded={alwaysOpenNav ? () => {} : setExpandedNav}
        sideNavItems={sideNavItems}
        bottomNavItems={bottomNavItems}
        currentRoute={navLocation.pathname}
        setCurrentRoute={(route, title, groupName) => {
          setExpandedNav(false);
          onClickSideNavItem(route, title, groupName);
        }}
        onChangeSearch={setSearch}
        searchValue={search}
        searchPlaceholder={t('common:search')}
        settingsLabel={t('settings')}
        name={prop('firstName', me)}
        email={prop('email', me)}
        photo={profileImage}
        inbox={() =>
          !hasPendingEmployerProfileRoute && (
            <LoadableSendbirdInbox
              {...props}
              isExpanded={expandedNav}
              key={prop('sendbirdAccessToken', me)}
              me={me}
              isCandidate={false} // only employers have side nav
              sendbirdUserId={prop('uuid', me)}
              sendbirdAccessToken={prop('sendbirdAccessToken', me)}
              onPress={() => {}}
              inboxLabel={t('Inbox')}
              fullWidth={expandedNav}
              disabled={hasSubscribeRoute}
            />
          )
        }
        onPressProfilePhoto={() => {
          setProfileDropdownOpen(!profileDropdownOpen);
        }}
        onPressSettings={() => {
          setExpandedNav(false);
        }}
        addLabel={t('newDropdown')}
      >
        <FOHView
          style={{ paddingVertical: 15, paddingHorizontal: 15, width: '100%' }}
        >
          <FOHInverseButton
            onPress={async () => {
              setExpandedNav(false);
              setLogoutWipeCacheClicked(true);
            }}
            title={'Logout'}
            textColor={FOHColors.BLACK}
          />
        </FOHView>
      </FOHSidebarNav>
    </FOHView>
  );
};
