import { length } from 'ramda';

import * as groups from '../constants/groups';

export const userIsEmployer = user => {
  return (
    user.groups.find(({ name }) => groups.EMPLOYER_GROUP === name) !== undefined
  );
};

export const userIsCandidate = user => {
  return (
    user.groups.find(({ name }) => groups.CANDIDATE_GROUP === name) !==
    undefined
  );
};

const userHasSetCompanyName = user => {
  return user.employer_profile.company_name !== '';
};

export const userHasEmployerProfile = user => {
  return user.employer_profile !== null && userHasSetCompanyName(user);
};

const userHasBeenThroughOnboarding = user =>
  length(user.candidate_profile.positions) > 0;

export const userHasCandidateProfile = user => {
  return user.candidate_profile !== null && userHasBeenThroughOnboarding(user);
};

const CANDIDATE_REQUIRED_FIELDS = [
  'positions',
  'availability',
  'address_street_1',
  'address_state',
  'address_city',
  'address_postal_code'
];

const DAYS = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday'
];

const candidateAvailabilityIsSet = user => {
  let availabilityIsSet = false;
  let candidateProfile = user.candidate_profile || {};

  DAYS.forEach(day => {
    let availabilityDayField = `availability_${day}`;

    if (
      candidateProfile[availabilityDayField] &&
      candidateProfile[availabilityDayField] &&
      candidateProfile[availabilityDayField].length > 0
    ) {
      availabilityIsSet = true;
    }
  });

  return availabilityIsSet;
};

export const candidateProfileIsComplete = user => {
  let candidateProfile = user.candidate_profile || {};
  let isComplete = true;

  CANDIDATE_REQUIRED_FIELDS.forEach(field => {
    if (field === 'availability' && !candidateAvailabilityIsSet(user)) {
      isComplete = false;
    }

    if (field !== 'availability' && candidateProfile[field] === undefined) {
      isComplete = false;
    }

    if (field !== 'availability' && candidateProfile[field] === null) {
      isComplete = false;
    }

    if (
      field !== 'availability' &&
      candidateProfile[field] &&
      candidateProfile[field].length === 0
    ) {
      isComplete = false;
    }
  });

  return isComplete;
};

export const formatPhoneNumber = phoneNumberString => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return ['(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
};
