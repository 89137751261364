import { defaultTo, path } from 'ramda';

// Soft navigate is a util to update the URL without trigerring a rerender
// We often use this for modals so users can easily share detail pages from a list
export const softNavigate = (props, toPath, slug) => {
  if (window) {
    window.history.replaceState(
      toPath,
      toPath,
      `${toPath}/${slug}?${defaultTo('', path(['location', 'search'], props))}`
    );
  }
};
