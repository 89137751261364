import { SalaryUnitChoices } from '../../components';

export const paymentTypeOptions = t => [
  {
    label: t('hourly'),
    value: SalaryUnitChoices.HOUR
  },
  {
    label: t('PositionsFeature:hourlyTipsWage'),
    value: SalaryUnitChoices.HOURLY_TIPS
  },
  {
    label: t('annual'),
    value: SalaryUnitChoices.YEAR
  }
];

export const currecyToFloat = currency =>
  currency ? Number(currency.replace(/[^0-9.-]+/g, '')) : undefined;

export const formatToCurrency = value =>
  Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(
    `${value}`
  );

export const validatePaymentInput = (min, max) => {
  if (!min || !max) {
    return {
      payError: {
        minPay: 'Required',
        maxPay: 'Required'
      }
    };
  }

  if (currecyToFloat(max) < currecyToFloat(min)) {
    return {
      payError: {
        maxPay: 'Less than Min',
        minPay: 'Greater than Max'
      }
    };
  }
  return {
    payError: undefined
  };
};
