import React, { createContext, useContext, ReactNode, useState } from 'react';

import { useLogout } from './useLogout';

interface LogoutRedirectContextProps {}

const LogoutRedirectContext = createContext<
  LogoutRedirectContextProps | undefined
>(undefined);

const useLogoutRedirectContext = () => {
  return useContext(LogoutRedirectContext);
};

export default useLogoutRedirectContext;

export const LogoutRedirectContextProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [logoutWipeCacheClicked, setLogoutWipeCacheClicked] =
    useState<boolean>(false);
  const [isRedirectToLoginCalled, setIsRedirectToLoginCalled] = useState(false);

  const { logoutWipeCache } = useLogout();

  const redirectToLogin = async (cb: () => void | undefined) => {
    await logoutWipeCache(() => cb && cb());
  };

  return (
    <LogoutRedirectContext.Provider
      value={{
        logoutWipeCacheClicked,
        setLogoutWipeCacheClicked,
        isRedirectToLoginCalled,
        setIsRedirectToLoginCalled,
        redirectToLogin
      }}
    >
      {children}
    </LogoutRedirectContext.Provider>
  );
};
