import React from 'react';

import clock from '../../../images/clock.svg';

import { FOHImage } from '../../ingredients';

export const FOHClockIcon = (props: any) => (
  <FOHImage
    source={{ uri: clock }}
    style={
      props && !!props.style
        ? { width: 20, height: 20, ...props.style }
        : { width: 20, height: 20 }
    }
  />
);
