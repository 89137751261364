import React from 'react';

import styled from 'styled-components/native';
import { View } from 'react-native';

import {
  FOHHeaderH2,
  FOHMultiLineTextField,
  FOHSmallDetailLabel,
  FOHSuggestionText,
  FOHSpaceSmall,
  FOHSpace
} from '../../ingredients';

import { FOHSelectItemProps } from '../select/FOHSelect';
import { FOHMultiSelectTextTags } from '../select/FOHMultiSelectTextTags';

import { FOHForm } from './FOHForm';

export interface FOHExternalApplicationSummaryFormProps {
  applicationMessageTitle?: string;
  applicationMessageDetail?: string;
  message: string;
  messagePlaceholder: string;
  handleMessage: (message: string) => void;
  submitLabel?: string;
  handleSubmit?: () => void;
  error?: any;
  suggestionText?: string;
  suggestionOptions?: Array<FOHSelectItemProps>;
  selectedSuggessions?: Array<string>;
  selectItem: (val: string) => void;
  mobile?: boolean;
  disableSubmit?: boolean;
}

export const FOHExternalApplicationSummaryForm = (
  props: FOHExternalApplicationSummaryFormProps
) => (
  <FOHForm handleSubmit={props.handleSubmit}>
    <FormContainer mobile={props.mobile ? props.mobile : false}>
      <FOHHeaderH2 style={{ textAlign: 'left' }}>
        {props.applicationMessageTitle}
      </FOHHeaderH2>
      <FOHSpaceSmall />
      <FOHSmallDetailLabel>
        {props.applicationMessageDetail}
      </FOHSmallDetailLabel>
      <FOHSpace />
      <FOHMultiLineTextField
        max={600}
        value={props.message}
        onChangeText={props.handleMessage}
        placeholder={props.messagePlaceholder}
        error={props.error && props.error.message ? props.error.message : null}
      />
      <FOHSpace />
      {props.suggestionOptions && props.suggestionText && (
        <>
          <FOHSuggestionText>{props.suggestionText}</FOHSuggestionText>
          <FOHSpaceSmall />
          <FOHMultiSelectTextTags
            selected={props.selectedSuggessions}
            options={props.suggestionOptions}
            selectItem={props.selectItem}
          />
        </>
      )}
    </FormContainer>
  </FOHForm>
);

const FormContainer = styled(View)<{ mobile: boolean }>`
  padding: ${(p: any) => (p.mobile ? '10px' : '30px')};
  width: 100%;
`;
