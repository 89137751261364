import React, { useEffect, useMemo, useState } from 'react';

import { DateTime } from 'luxon';
import { path, defaultTo, compose, prop, toLower, toUpper } from 'ramda';
import { withTranslation } from 'react-i18next';

import {
  withIsMobile,
  formatFollowerCountLabel,
  storeAuth,
  handleMutation
} from '../../utils';
import {
  HOME_PAGE,
  INTERVIEW_STATUS_ACCEPTED,
  INTERVIEW_STATUS_DECLINED,
  INTERVIEW_STATUS_EXPIRED,
  INTERVIEW_STATUS_SCHEDULED,
  REQUESTS,
  CANDIDATE_ONBOARDING_1
} from '../../constants';
import { logout } from '../../api/auth';
import {
  GetMeDocument,
  useGetInterviewDetailLazyQuery,
  useGetInterviewMessagingQuery,
  useMutateInterviewMutation,
  useMutateSignInInterviewRequestMutation
} from '../../graphql/generated';
import {
  FOHInverseButton,
  FOHView,
  FullWidthCard,
  Screen,
  FOHButton,
  FOHSpace,
  FOHPlateIcon,
  FOHAltTextButton,
  FOHHeaderText,
  FOHInterviewResponse
} from '../../components';
import { useNavigation } from '../../utils/navigation';
import { FOH_INPUT_HEIGHT } from '../../components/constants';

import { JobDetails } from '../JobApplications/JobDetails';
import { QTOKEN, getSearchQuery } from '../Search';
import { SignInModal, useGetMeData } from '../SignIn';
import { LogoLoader } from '../Loading/LogoLoader';

import { useRespondToInterviewRequest } from './useRespondToInterviewRequest';

const daysSinceISODate = isoString =>
  -DateTime.fromISO(isoString).diffNow('days').toFormat('d');

const useSetInterviewViewedEffect = (id, status) => {
  const [setViewedAt] = useMutateInterviewMutation();
  useEffect(() => {
    if (id && status !== 'EXPIRED') {
      handleMutation(
        setViewedAt({
          variables: { id, setViewedAt: true },
          refetchQueries: [
            {
              query: GetMeDocument
            }
          ]
        })
      );
    }
  }, [id, setViewedAt, status]);
};

const InterviewResponseScreen = props => {
  const { t } = props;
  const { navigateTo, location: navLocation } = useNavigation();

  const meQuery = useGetMeData();
  const paths = defaultTo('', path(['pathname'], navLocation)).split('/');
  const uuid = prop(2, paths);
  const token = prop(4, paths) || getSearchQuery(navLocation, QTOKEN);

  const [openSignIn, setOpenSignIn] = useState(false);

  const [
    interviewRequestQuery,
    { data: interviewData, error: interviewError, loading }
  ] = useGetInterviewDetailLazyQuery({
    skip: !token,
    variables: {
      uuid,
      token
    }
  });

  const [signInWithInterviewToken] = useMutateSignInInterviewRequestMutation();

  const interviewDetail = useMemo(() => {
    return path(['interview'], interviewData);
  }, [interviewData]);

  const interviewRequestQueryError = interviewError;

  const expired =
    prop('status', interviewDetail) === toUpper(INTERVIEW_STATUS_EXPIRED);
  const position = path(['position'], interviewDetail);

  const hasInterviewSchedule = useMemo(() => {
    return path(['createdBy', 'interviewschedule'], interviewDetail) !== null;
  }, [interviewDetail]);

  const schedulingEnabled = hasInterviewSchedule;

  const location = prop(['location'], interviewDetail);

  const interviewMessaging = useGetInterviewMessagingQuery({
    variables: {
      location: prop('id', location),
      slug: prop('slug', location)
    },
    skip: !prop('id', location)
  });

  const requestedDaysAgo = prop('createdAt', interviewDetail)
    ? daysSinceISODate(prop('createdAt', interviewDetail))
    : '';
  const requestedDaysLabel =
    requestedDaysAgo <= 1 ? t('today') : `${requestedDaysAgo} ${t('daysAgo')}`;

  const {
    accepting,
    declining,
    error,
    acceptInterviewRequest,
    declineInterviewRequest
  } = useRespondToInterviewRequest();

  useSetInterviewViewedEffect(
    prop('id', interviewDetail),
    prop('status', interviewDetail)
  );

  const signIn = async (interviewToken, _uuid) => {
    const [signInInterviewResp, errorOnSignInWithInterviewToken] =
      await handleMutation(
        signInWithInterviewToken({
          variables: {
            interviewToken,
            uuid: _uuid
          }
        })
      );
    if (errorOnSignInWithInterviewToken || !signInInterviewResp) {
      return setOpenSignIn(true);
    }

    const auth = path(
      ['signInInterviewMutation', 'authorization'],
      signInInterviewResp
    );

    if (auth) {
      // In case they are logged in as a different account, log them out and refetch.
      await logout();
      await storeAuth({ token: auth });
      meQuery && meQuery.refetch();
      interviewRequestQuery();
    }
  };

  useEffect(() => {
    if (token && uuid && !meQuery?.loading && !meQuery?.data) {
      signIn(token, uuid);
    } else if (!meQuery?.loading && meQuery?.data) {
      interviewRequestQuery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, uuid, meQuery.loading]);

  // TODO: Looks like this tries to get the details of the IR (with a token) before is calls
  // signInWithInterviewToken. If we switch that (sign them in before anything), we could stop
  // passing the token in the Interview Details query.
  //
  // We probably ought to genericize ClaimAccountScreen to handle this for us: authenticate with
  // token and redirect generically
  useEffect(() => {
    if (interviewRequestQueryError) {
      setOpenSignIn(true);
    }
  }, [interviewRequestQueryError]);

  const status = toLower(defaultTo('', path(['status'], interviewDetail)));

  const responded =
    (status === INTERVIEW_STATUS_ACCEPTED && !schedulingEnabled) ||
    status === INTERVIEW_STATUS_DECLINED ||
    status === INTERVIEW_STATUS_SCHEDULED;

  const isValidEmail = () => {
    if (
      defaultTo('', path(['data', 'email'], meQuery)).includes('indeedemail')
    ) {
      props.openSetPasswordAndEmail();
      return false;
    } else {
      return true;
    }
  };

  return (
    <Screen
      style={{
        alignItems: 'center',
        paddingTop: '60px',
        backgroundColor: '#F5F5F5',
        minHeight: '100vh',
        paddingLeft: 8,
        paddingRight: 8
      }}
    >
      <FOHHeaderText>
        {responded
          ? t('interviewRespondedTitle')
          : expired
          ? t('interviewExpiredTitle')
          : t('titleLabel')}
      </FOHHeaderText>

      {(expired || responded) && (
        <FOHView style={{ padding: 12, width: '100%', maxWidth: 600 }}>
          <FOHSpace />
          <FullWidthCard
            shadow={true}
            style={{
              padding: 12,
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <FOHPlateIcon />
            <FOHSpace />
            <FOHAltTextButton
              title={t('viewRecentInterviewRequests')}
              onPress={() => navigateTo(`${HOME_PAGE}${REQUESTS}`)}
              style={{ textDecoration: 'underline' }}
            />
            <FOHSpace />
          </FullWidthCard>
        </FOHView>
      )}

      <FOHSpace />
      {!interviewDetail && <LogoLoader />}
      {!expired && interviewDetail && (
        <FOHInterviewResponse
          error={error ? t('expiredInterview') : undefined}
          acceptInterview={async () => {
            if (isValidEmail()) {
              await acceptInterviewRequest({
                interviewId: prop('id', interviewDetail),
                uuid,
                schedulingEnabled
              });
            }
          }}
          declineInterview={async () => {
            if (isValidEmail()) {
              await declineInterviewRequest({
                interviewId: prop('id', interviewDetail)
              });
            }
          }}
          id={prop('id', position)}
          name={prop('name', location)}
          image={prop('image', location)}
          formattedAddress={prop('formattedAddress', location)}
          followerCountLabel={formatFollowerCountLabel({
            followerCount: prop('followerCount', location),
            t
          })}
          tags={prop('tags', location)}
          summary={prop('summary', location)}
          acceptLabel={
            schedulingEnabled ? t('scheduleInterview') : t('acceptLabel')
          }
          declineLabel={t('declineLabel')}
          positionLabel={position ? t('positionLabel') : ''}
          positionName={position ? prop('positionName', position) : ''}
          // TODO: implement Google Drive time API on BE and get commuteTime off of location GQL resolver
          commuteLabel=""
          commuteTime=""
          whereLabel={t('whereLabel')}
          requestedAtLabel={`${t('requested')} ${requestedDaysLabel}`}
          responded={responded || accepting || declining || expired}
          responseLabel={
            declining
              ? t('declining')
              : accepting
              ? t('accepting')
              : status === INTERVIEW_STATUS_ACCEPTED ||
                status === INTERVIEW_STATUS_SCHEDULED
              ? t('InterviewResponsePage:accepted.title')
              : status === INTERVIEW_STATUS_DECLINED
              ? t('InterviewResponsePage:declined.title')
              : null
          }
          vertical={props.isMobile}
          interviewRequestMessaging={defaultTo(
            '',
            path(
              ['data', 'interviewMessaging', 'interviewRequestMessaging'],
              interviewMessaging
            )
          )}
        />
      )}
      {!expired && position && (
        <>
          <FOHSpace />
          <FOHView
            style={{
              maxWidth: '600px'
            }}
          >
            <JobDetails
              position={position}
              loading={loading}
              location={location}
            >
              {!responded && !expired && (
                <FOHView
                  style={{
                    flexDirection: props.isMobile ? 'column' : 'row-reverse',
                    paddingLeft: '24px',
                    paddingRight: '24px',
                    paddingBottom: '24px'
                  }}
                >
                  <FOHButton
                    onPress={async () => {
                      if (isValidEmail()) {
                        await acceptInterviewRequest({
                          interviewId: prop('id', interviewDetail),
                          uuid,
                          schedulingEnabled
                        });
                      }
                    }}
                    disabled={accepting || declining}
                    title={
                      accepting
                        ? t('accepting')
                        : schedulingEnabled
                        ? t('scheduleInterview')
                        : t('acceptLabel')
                    }
                    style={{
                      flex: 1,
                      height: FOH_INPUT_HEIGHT
                    }}
                  />
                  <FOHSpace />
                  <FOHInverseButton
                    disabled={accepting || declining}
                    onPress={async () => {
                      if (isValidEmail()) {
                        await declineInterviewRequest({
                          interviewId: prop('id', interviewDetail)
                        });
                      }
                    }}
                    title={declining ? t('declining') : t('declineLabel')}
                    style={{
                      flex: 1,
                      height: FOH_INPUT_HEIGHT
                    }}
                  />
                </FOHView>
              )}
            </JobDetails>
          </FOHView>
        </>
      )}
      <SignInModal
        {...props}
        openSignIn={openSignIn}
        setOpenSignIn={val => setOpenSignIn(val)}
        onSignIn={async () => {
          setOpenSignIn(false);
          meQuery && meQuery.refetch();
          await interviewRequestQuery();
          {
            interviewError && setOpenSignIn(true);
          }
        }}
        handleSignUp={() => navigateTo(CANDIDATE_ONBOARDING_1)}
      />
    </Screen>
  );
};

export default compose(
  withIsMobile,
  withTranslation('InterviewFeature')
)(InterviewResponseScreen);
