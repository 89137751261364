import React, { useMemo } from 'react';

import { compose, path, length } from 'ramda';
import { withTranslation } from 'react-i18next';

import {
  withIsMobile,
  getAuthFlowControlRedirectURI,
  removeAuthFlowControlRedirectURI
} from '../../utils';
import {
  FOHNavigationProgress,
  FOHSpace,
  FOHSpaceLarger,
  FOHTextButton
} from '../../components';

import { useOnboardingState } from '../Navigation/useOnboardingState';
import {
  PersonalSummaryScreen,
  UploadPhotoScreen,
  PositionTypeScreen,
  AddEmploymentScreen,
  LocationScreen
} from '../CandidateProfile';
import { RegisterScreen } from '../Register';

// Configure onboarding order
export const steps = [
  { title: 'signup', Component: RegisterScreen },
  { title: 'positions', Component: PositionTypeScreen },
  { title: 'location', Component: LocationScreen },
  {
    title: 'workhistory',
    Component: AddEmploymentScreen
  },

  { title: 'photo', Component: UploadPhotoScreen },

  {
    title: 'personalSummary',
    Component: PersonalSummaryScreen
  }
];

const OnboardingRoutes = props => {
  const { stepIndex } = props;
  const Component = steps[stepIndex - 1].Component;

  return <Component {...props} />;
};

const CandidateOnboardingRouter = props => {
  const {
    signUpFlow,
    stepIndex,
    setStepIndex,
    nextStep,
    previousStep,
    finishOnboarding
  } = useOnboardingState(props, '/candidates', `/home`);

  const storeRedirectUri = getAuthFlowControlRedirectURI();

  const { t } = props;
  const stepTitles = steps.map(step => t(step.title));

  const getPreviousStep = useMemo(
    () => () => {
      if (stepIndex === 2) {
        return undefined;
      }
      return previousStep;
    },
    [stepIndex]
  );

  return (
    <div>
      <div style={{ paddingTop: props.isMobile ? '50px' : '60px' }} />
      <FOHNavigationProgress
        stepIndex={stepIndex - 1}
        steps={stepTitles}
        small={props.isMobile}
      />
      <OnboardingRoutes
        {...props}
        key={'routes'}
        stepIndex={stepIndex}
        nextStep={nextStep}
        previousStep={getPreviousStep()}
        signUpFlow={signUpFlow}
        onSubmit={() => {
          if (stepIndex === length(steps)) {
            if (storeRedirectUri) removeAuthFlowControlRedirectURI();
            finishOnboarding(storeRedirectUri);
          } else {
            nextStep();
          }
        }}
      />
      <FOHSpace />
      {!!path([stepIndex - 1, 'previous'], steps) && (
        <>
          <FOHTextButton
            onPress={() => {
              setStepIndex(stepIndex - 1);
              previousStep();
            }}
            title={t('previous')}
            style={{ flex: 1 }}
          />
          <FOHSpaceLarger />
          <FOHSpaceLarger />
          <FOHSpaceLarger />
        </>
      )}
    </div>
  );
};

export default compose(
  withIsMobile,
  withTranslation('CandidateOnboardingSteps')
)(CandidateOnboardingRouter);
