import React, { FunctionComponent, useState } from 'react';

import { Text } from 'react-native';
import styled from 'styled-components/native';
import { useTheme } from 'styled-components';

import { FontProperty } from '../../templates';
import { useIsMobile } from '../../spices';
import {
  FOHColors,
  FOHFonts,
  FOHLabel,
  FOHLink,
  FOHTouchableOpacity
} from '../../ingredients';

export interface FOHExpandableTextProps {
  children: React.ReactNode;
  collapseLabel: string;
  expandLabel: string;
  label?: string;
  maxRows: number;
  fontProperty?: FontProperty;
  wordCount: number;
}

export const FOHExpandableText: FunctionComponent<FOHExpandableTextProps> = ({
  children,
  collapseLabel,
  expandLabel,
  fontProperty,
  maxRows = 5,
  wordCount
}) => {
  const isMobile = useIsMobile();
  const theme = useTheme();

  const [visibleRows, setVisibleRows] =
    useState<FOHExpandableTextProps['maxRows']>(maxRows);

  const isTextCollapsed = visibleRows === maxRows;
  // We don't have access to onTextLayout so have to rely on word count
  const arbitraryWordLimit = isMobile ? 27 : 68;

  const toggleRows = () =>
    setVisibleRows(prev => (prev === maxRows ? 0 : maxRows));

  return (
    <>
      <Text
        numberOfLines={visibleRows}
        style={{ minHeight: 60, position: 'relative' }}
      >
        <FOHLabel style={{ lineHeight: 28 }}>
          <CareerPageHTMLContainer
            style={{ ...fontProperty }}
            testID="FOHExpandableText-cp-description"
          >
            {children}
          </CareerPageHTMLContainer>
        </FOHLabel>
      </Text>
      {wordCount > arbitraryWordLimit && (
        <ToggleText isTextCollapsed={isTextCollapsed}>
          <FOHLink
            linkStyle={{
              color: theme?.brandColor || FOHColors.PACIFIC_BLUE,
              ...linkStyles
            }}
            linkText={isTextCollapsed ? `...${expandLabel}` : collapseLabel}
            onPress={toggleRows}
          />
        </ToggleText>
      )}
    </>
  );
};

const linkStyles = {
  fontSize: 14,
  fontWeight: 'bold',
  textDecoration: 'underline'
};

const ToggleText = styled((props: any) => <FOHTouchableOpacity {...props} />)<{
  isTextCollapsed: boolean;
}>`
  ${({ isTextCollapsed }: { isTextCollapsed: boolean }) =>
    isTextCollapsed
      ? `
          position: absolute;
          right: 0;
          bottom: -25;
          padding-left: 2;
        `
      : `
          position: relative;
    `};

  flex-direction: row;
  align-items: baseline;
  background-color: transparent;
`;

const CareerPageHTMLContainer = styled(Text)`
  margin-top: 0px;
  margin-bottom: 24px;
  font-family: ${FOHFonts.REGULAR};
  font-size: 16px;
  line-height: 24px;
`;
