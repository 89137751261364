import React from 'react';

import lock from '../../../images/lock.svg';

import { FOHImage } from '../../ingredients';

export const FOHLockIcon = (props: any) => (
  <FOHImage
    source={{ uri: lock }}
    style={
      props && !!props.style
        ? { width: 18, height: 21, ...props.style }
        : { width: 18, height: 21 }
    }
  />
);
