import styled from 'styled-components/native';

import { FOHView, SPACING_SCALE } from '../../../ingredients';

// @ts-ignore Necessary until we bump styled-components and styles
export const DetailsRow = styled(FOHView)`
  align-items: flex-start;
  margin-bottom: ${SPACING_SCALE.larger}px;
  width: 100%;
`;
