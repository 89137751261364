import React, { useState, useEffect, useMemo } from 'react';

import { prop, length, defaultTo, path } from 'ramda';
import { useTranslation } from 'react-i18next';
import { createEditor, Node } from 'slate';
import { withReact } from 'slate-react';

import { useIsMobile, setTimeOnDate, generateTimeIntervals } from '../../utils';
import {
  useEmployerMessageTemplatesQuery,
  useGetCandidateQuery
} from '../../graphql/generated';
import {
  getPlaceHolderByAction,
  getTemplatePlaceHolders,
  OFFER_LETTER
} from '../../constants/templates';
import { FEED, TEMPLATES, UPGRADE } from '../../constants';
import {
  FOHSpaceSmall,
  FOHView,
  FOHErrorLabel,
  FOHCalendarPicker,
  FOHOfferLetterForm,
  SalaryUnitChoices,
  FOHOfferIcon
} from '../../components';
import { useNavigation } from '../../utils/navigation';

import { useSelectPositionForSelectedLocationState } from '../EmployerPositions/useSelectPositionForSelectedLocationState';
import { useLetter } from '../Welcome/useLetter';
import { WebDropZone } from '../Welcome/webDropZone';
import { useCandidateFeedContext } from '../CandidateFeed/useCandidateFeedContext';
import ActionModal from '../CandidateFeed/ActionModal';
import {
  withCharacterLimit,
  withCustomDelete,
  stripBracesFromPlaceholder
} from '../EmployerMessageTemplate/utils';
import { FOHMessageInputSection } from '../EmployerMessageTemplate/FOHMessageInputSection';

const times = generateTimeIntervals({ numberOfIntervals: 28 });

const MAX_COUNT = 500;

const editor = withCharacterLimit(MAX_COUNT)(
  withReact(withCustomDelete(createEditor()))
);

export const OfferLetterModal = props => {
  const { isInSettings } = props;
  const { t } = useTranslation('Welcome');
  const { isMobile } = useIsMobile();

  const { navigateTo, goBack } = useNavigation();

  const { candidateHandle, employerCandidate } = props;

  const employerCandidatePreferredMatch = path(
    ['preferredMatch'],
    employerCandidate
  );

  const { setSelectedPosition, selectedPosition, paidPositions } =
    useSelectPositionForSelectedLocationState({
      doNotDefault: true,
      positionId: path(['position', 'id'], employerCandidatePreferredMatch),
      locationId: path(
        ['position', 'location', 'id'],
        employerCandidatePreferredMatch
      )
    });

  const {
    customNote,
    setCustomNote,
    files,
    setFiles,
    createEmploymentDocument,
    includeCustomNote,
    setIncludeCustomNote,
    error
  } = useLetter(props);

  const [time, setTime] = useState('12:00 AM');
  const [date, setDate] = useState(new Date());
  const [compensation, setCompensation] = useState(0);

  const [selectedUnit, setSelectedUnit] = useState(SalaryUnitChoices.HOUR);
  const [sending, setSending] = useState(false);
  const [isCalendarOpen, setCalendarOpen] = useState(false);

  const [inputMessageContent, setInputMessageContent] = useState([
    {
      type: 'paragraph',
      children: [{ text: '' }]
    }
  ]);

  const [label, setLabel] = useState('');
  const [template, setTemplate] = useState();
  const [open, setOpen] = useState(false);
  const [templateSearch, setSearch] = useState('');
  const [placeHolderError, setPlaceHolderError] = useState('');

  const messageTemplatesQuery = useEmployerMessageTemplatesQuery({
    variables: {
      templateSearch,
      category: 'OFFER'
    }
  });

  const filteredTemplates = useMemo(() => {
    const templates = defaultTo(
      [],
      path(['data', 'employerMessageTemplates', 'edges'], messageTemplatesQuery)
    );

    return templates
      .map(edge => ({
        id: edge?.node.id,
        name: edge?.node.name,
        messageContent: edge.node.messageContent,
        templateType: edge.node.templateType,
        isDefault: edge.node.isDefault,
        category: edge.node.category,
        action: edge.node.action,
        defaultCompensation: edge.node.defaultCompensation
          ? edge.node.defaultCompensation
          : 0,
        defaultCompensationUnits: edge.node.defaultCompensationUnits,
        employerMessageTemplateFile:
          edge.node.employerMessageTemplateFile?.edges?.map(fileEdge => {
            return {
              name: fileEdge.node.name,
              id: fileEdge.node.id,
              type: fileEdge.node.fileType,
              deleteFile: false
            };
          })
      }))
      .filter(_template =>
        _template?.name?.toLowerCase()?.includes(templateSearch.toLowerCase())
      );
  }, [messageTemplatesQuery, templateSearch]);

  useEffect(() => {
    if (length(filteredTemplates) > 0 && !template) {
      const defaultTemplate = filteredTemplates.find(
        _template => !!_template.isDefault
      );
      setTemplate(defaultTemplate);
      handleOnUseTemplate(defaultTemplate);
    }
  }, [filteredTemplates, template]);

  const candidateQuery = useGetCandidateQuery({
    skip: !candidateHandle,
    variables: { handle: candidateHandle }
  });

  const candidateId = path(['data', 'profile', 'id'], candidateQuery);

  useEffect(() => {
    !includeCustomNote && setCustomNote('');
  }, [includeCustomNote]); //eslint-disable-line react-hooks/exhaustive-deps

  const {
    activeStageId,
    positionFilters,
    locationFilters,
    search,
    setNotificationMessage
  } = useCandidateFeedContext();

  const resetForm = () => {
    setTemplate(undefined);
    setLabel('');
    setSelectedUnit(SalaryUnitChoices.HOUR);
    setCompensation(0);
    setFiles([]);
    setPlaceHolderError('');
  };

  const handleSendPress = async () => {
    const messageContent = inputMessageContent
      .map(n => Node.string(n))
      .join('\n');

    const placeholder = getPlaceHolderByAction(OFFER_LETTER);

    if (!messageContent.includes(placeholder)) {
      const errorPlaceholder = stripBracesFromPlaceholder(placeholder);

      setPlaceHolderError(
        t('MessageTemplatesFeature:requiredPlaceHolder', {
          placeholder: errorPlaceholder
        })
      );
      return;
    }

    setPlaceHolderError('');
    setSending(true);
    const letterData =
      parseInt(compensation) === 0
        ? {
            documentType: 'offer',
            status: 'sent',
            position: selectedPosition,
            candidate: candidateId,
            startingAt: setTimeOnDate(date, time),
            note: customNote,
            messageContent: messageContent
          }
        : {
            documentType: 'offer',
            status: 'sent',
            position: selectedPosition,
            candidate: candidateId,
            startingAt: setTimeOnDate(date, time),
            note: customNote,
            baseSalaryValue: parseFloat(compensation),
            baseSalaryUnitText: selectedUnit,
            messageContent: messageContent
          };
    await createEmploymentDocument(letterData);

    await props.moveEmployerCandidates({
      employerCandidateIds: [prop('id', employerCandidate)],
      newStage: prop('id', props.offeredStage),
      oldStage: activeStageId,
      positions: positionFilters,
      locations: locationFilters,
      search,
      notificationStatusToUpdate: 'pending'
    });

    props.stagesQuery.refetch();
    props.candidateFeedQuery.refetch();

    navigateTo({
      pathname: FEED
    });

    setNotificationMessage(t('NotificationBanner:sentOfferSuccess'));
    setSending(false);
    resetForm();
  };

  const handleOnUseTemplate = (selectedTemplate = null) => {
    const temp = selectedTemplate ?? template;

    const newValue = [
      {
        type: 'paragraph',
        children: [{ text: temp?.messageContent || '' }]
      }
    ];

    setInputMessageContent(newValue);
    editor.children = newValue;
    setLabel(temp?.name);
    setSelectedUnit(temp?.defaultCompensationUnits);
    setCompensation(temp?.defaultCompensation);
    setFiles(defaultTo([], prop('employerMessageTemplateFile', temp)));
    setOpen(false);
  };

  return (
    <ActionModal
      {...props}
      title={t('offerLetterTitle')}
      subtitle={t('offerLetterDetail')}
      containerTestID="OfferLetterModal"
      HeaderImage={FOHOfferIcon}
      defaultLocation={path(
        ['position', 'location', 'id'],
        employerCandidatePreferredMatch
      )}
      defaultPosition={path(
        ['position', 'id'],
        employerCandidatePreferredMatch
      )}
      showLabel={t('common:show')}
      onPressUpgrade={() => navigateTo(`${FEED}${UPGRADE}`)}
      searchPlaceholder={t('common:search')}
      positionFieldLabel={t('common:position')}
      upgradeLabel={t('TopNavFeature:upgrade')}
      backButtonText={t('common:previous')}
      nextButtonText={sending ? t('actions:sendingLetter') : t('common:send')}
      onBackPress={() => {
        goBack();
        resetForm();
      }}
      isNextDisabled={sending || length(paidPositions) === 0}
      onNextPress={handleSendPress}
      setPosition={setSelectedPosition}
      containerStyle={{
        alignItems: 'flex-start',
        backgroundColor: isInSettings
          ? '#f5f5f5'
          : isMobile
          ? '#ffffff'
          : undefined,
        width: '100%'
      }}
      positionContainerTestId="FOHOfferLetterForm-position-dropdown"
    >
      <FOHView
        style={{
          width: '100%'
        }}
      >
        <FOHOfferLetterForm
          isMobile={isMobile}
          canlendarCmp={
            <FOHCalendarPicker
              open={isCalendarOpen}
              setOpen={setCalendarOpen}
              value={date}
              setValue={setDate}
              placeholder="Date"
              width="100%"
              btnBackground="#FFF"
            />
          }
          candidateSectionLabel={t('candidateSectionLabel')}
          time={time}
          setTime={setTime}
          timeChoices={times}
          timePlaceholder={t('OffersFeature:startTimeLabel')}
          compensationSectionLabel={t('OffersFeature:compensationSectionLabel')}
          compensation={parseFloat(compensation)?.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
          })}
          setCompensation={setCompensation}
          compensationPlaceholder={t('OffersFeature:compensationLabel')}
          typeLabel={t('OffersFeature:typeLabel')}
          selectedUnit={selectedUnit}
          setSelectedUnit={setSelectedUnit}
          unitOptions={[
            {
              value: SalaryUnitChoices.HOUR,
              label: t('PositionsFeature:hourly')
            },
            {
              value: SalaryUnitChoices.HOURLY_TIPS,
              label: t('PositionsFeature:hourlyTipsWage')
            },
            {
              value: SalaryUnitChoices.YEAR,
              label: t('MessageTemplatesFeature:annually')
            }
          ]}
          documentSectionLabel={t('OffersFeature:documentSectionLabel')}
          customNoteLabel={t('OffersFeature:customNoteLabel')}
          includeCustomNote={includeCustomNote}
          setIncludeCustomNote={setIncludeCustomNote}
          customNote={customNote}
          setCustomNote={setCustomNote}
          customNotePlaceholder=""
          hideLocationSelection={true}
          hideHeaderBlock={true}
          locationOptions={[]}
          positionOptions={[]}
          documentsUpload={<WebDropZone files={files} setFiles={setFiles} />}
        >
          <FOHMessageInputSection
            testID={'message-templates-section'}
            t={t}
            open={open}
            setOpen={setOpen}
            label={label}
            editor={editor}
            template={template}
            setTemplate={setTemplate}
            onEditTemplate={() => {
              setOpen(false);
              resetForm();
              navigateTo(TEMPLATES);
            }}
            search={templateSearch}
            setSearch={setSearch}
            filteredTemplates={filteredTemplates}
            resetForm={resetForm}
            handleOnUseTemplate={handleOnUseTemplate}
            placeholder={getTemplatePlaceHolders(t, OFFER_LETTER)}
            placeHolderError={placeHolderError}
            inputMessageContent={inputMessageContent}
            setInputMessageContent={setInputMessageContent}
            onClose={() => {
              setOpen(false);
              resetForm();
            }}
          />
        </FOHOfferLetterForm>
      </FOHView>
      {length(error) > 0 ? (
        <FOHView
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            paddingLeft: 40
          }}
        >
          <>
            <FOHSpaceSmall />
            <FOHErrorLabel>
              {`• ${error
                .map(_error => prop('messages', _error))
                .join('\n\n')}`}
            </FOHErrorLabel>
          </>
        </FOHView>
      ) : (
        <></>
      )}
    </ActionModal>
  );
};

export default OfferLetterModal;
