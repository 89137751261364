import React from 'react';

import { Picker } from 'react-native';
import styled from 'styled-components/native';
import { path } from 'ramda';

import {
  FOHColors,
  FOHExpandIcon,
  FOHFonts,
  FOHSpaceSmall,
  FOHView
} from '../../ingredients';
import { getTextWidthFromFont } from '../../utils';

interface FOHBorderlessSelectProps {
  children: Array<any>;
  selectedValue: any;
  onValueChange: (value: string) => void;
  small?: boolean;
  style?: any;
  testID?: string;
  disabled?: boolean;
}

export const FOHBorderlessSelect = (props: FOHBorderlessSelectProps) => {
  const selectedLabel = props.selectedValue
    ? path(
        ['props', 'label'],
        props.children.find(child => child.props.value === props.selectedValue)
      ) || props.children[0].props.label
    : props.children[0].props.label;

  const viewlength = getTextWidthFromFont(
    selectedLabel,
    `16px ${FOHFonts.REGULAR}`
  );
  const overflow = viewlength > 320;

  return (
    <FOHView style={{ width: '100%' }}>
      <SelectContainer>
        <Select
          small={props.small ? 1 : 0}
          style={{
            // calculate, based on pixels of each letter
            width: viewlength + 10,
            minWidth: 50,
            maxWidth: 320,
            backgroundColor: 'transparent',
            ...props.style
          }}
          truncate={overflow ? 1 : 0}
          selectedValue={props.selectedValue}
          onValueChange={props.onValueChange}
          testID={props.testID}
          disabled={props.disabled}
        >
          {props.children}
        </Select>
        <FOHSpaceSmall />
        <FOHView
          style={{
            left: overflow ? -20 : -10,
            paddingLeft: overflow ? 10 : 0,
            height: 24,
            alignItems: 'center',
            justifyContent: 'center'
          }}
          pointerEvents="none"
        >
          <FOHExpandIcon
            style={{
              tintColor: props.disabled
                ? FOHColors.GRAYSCALE_400
                : props?.style?.color || FOHColors.GRAYSCALE_500
            }}
          />
        </FOHView>
      </SelectContainer>
    </FOHView>
  );
};

const SelectContainer = styled(FOHView)`
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const Select = styled(Picker)`
  border: none;
  font-family: ${(props: any) =>
    props.small ? FOHFonts.REGULAR : FOHFonts.MEDIUM};
  font-style: normal;
  font-weight: ${(props: any) => (props.small ? 400 : 600)};
  font-size: ${(props: any) => (props.small ? 16 : 24)};
  line-height: 30px;
  align-items: center;
  height: 44;
  -webkit-appearance: none;
`;
