import React from 'react';

import { FOHEditLocationForm, FOHEditLocationFormProps } from '../../recipes';
import {
  FOHView,
  Screen,
  FOHCircleLoader,
  FOHDetailText,
  FOHHeaderText
} from '../../ingredients';

export interface FOHEmployerLocationVerifyProps
  extends FOHEditLocationFormProps {
  title: string;
  description: string;
  largeHeader?: boolean;
  loading?: boolean;
}

export const FOHEmployerLocationVerify = (
  props: FOHEmployerLocationVerifyProps
) => (
  <Screen>
    <FOHView
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 60,
        width: '100%',
        padding: 16
      }}
    >
      <FOHHeaderText style={{ fontSize: props.largeHeader ? 30 : 20 }}>
        {props.title}
      </FOHHeaderText>
      <FOHView style={{ maxWidth: 396 }}>
        <FOHDetailText>{props.description}</FOHDetailText>
      </FOHView>
      <FOHView style={{ paddingTop: 24 }} />
      {props.loading ? (
        <FOHCircleLoader />
      ) : (
        <FOHEditLocationForm
          image={props.image}
          uploadImageLabel={props.uploadImageLabel}
          pickPhoto={props.pickPhoto}
          submitLabel={props.submitLabel}
          companyName={props.companyName}
          companyNamePlaceholder={props.companyNamePlaceholder}
          setCompanyName={props.setCompanyName}
          street1={props.street1}
          street1Placeholder={props.street1Placeholder}
          setStreet1={props.setStreet1}
          street2={props.street2}
          street2Placeholder={props.street2Placeholder}
          setStreet2={props.setStreet2}
          city={props.city}
          cityPlaceholder={props.cityPlaceholder}
          setCity={props.setCity}
          state={props.state}
          disabledSubmit={props.disabledSubmit}
          statePlaceholder={props.statePlaceholder}
          setState={props.setState}
          postalCode={props.postalCode}
          postalCodePlaceholder={props.postalCodePlaceholder}
          setPostalCode={props.setPostalCode}
          summary={props.summary}
          setSummary={props.setSummary}
          summaryPlaceholder={props.summaryPlaceholder}
          vertical={props.vertical}
          indeedEasyapplyOptoutLabel={props.indeedEasyapplyOptoutLabel}
          indeedEasyapplyOptout={props.indeedEasyapplyOptout}
          setIndeedEasyapplyOptout={props.setIndeedEasyapplyOptout}
          handleSubmit={props.handleSubmit}
          autoCompleteInput={props.autoCompleteInput}
        />
      )}
    </FOHView>
  </Screen>
);
