import React from 'react';

import {
  FullWidthCard,
  FOHHeaderH1,
  FOHDetailText,
  FOHLabel,
  FOHFollowBadge,
  FOHSpace,
  FOHSpaceSmall,
  FOHAltSectionLabel,
  FOHTextButton,
  FOHEditMarker,
  FOHColors,
  FOHView,
  FOHImage
} from '../../ingredients';
import { FOHRestaurantLocation, FOHTagList } from '../../recipes';

export interface FOHJobPositionLocationCardProps extends FOHRestaurantLocation {
  aboutEmployerLabel: string;
  followersLabel?: string;
  summaryLabel?: string;
  editLabel?: string;
  onEditPress?: () => void;
}

export const FOHJobPositionLocationCard = (
  props: FOHJobPositionLocationCardProps
) => (
  <FullWidthCard>
    <FOHView style={{ padding: 24 }}>
      <FOHView
        style={{ flexDirection: 'row', justifyContent: 'space-between' }}
      >
        <FOHDetailText>{props.aboutEmployerLabel}</FOHDetailText>
        {!!props.editLabel && !!props.onEditPress && (
          <FOHTextButton
            icon={() => FOHEditMarker({ style: { width: 18, height: 18 } })}
            title={props.editLabel}
            color={FOHColors.BLUE}
            style={{
              textDecorationLine: 'underline',
              textDecorationColor: FOHColors.BLUE
            }}
            onPress={() => props.onEditPress && props.onEditPress()}
          />
        )}
      </FOHView>
      <FOHSpace />
      <FOHHeaderH1 style={{ textAlign: 'left' }}>{props.name}</FOHHeaderH1>
      <FOHLabel>{props.formattedAddress}</FOHLabel>
      <FOHSpace />
      {!!props.followersLabel && (
        <FOHFollowBadge label={props.followersLabel} />
      )}
      <FOHSpaceSmall />
      {!!props.tags && <FOHTagList tags={props.tags} />}
      <FOHSpace />
    </FOHView>
    <FOHImage
      source={{
        uri: props.image
      }}
      resizeMode="contain"
      style={{ width: '100%', minHeight: 242 }}
    />
    <FOHSpace />
    <FOHView style={{ padding: 24 }}>
      <FOHAltSectionLabel style={{ textAlign: 'left' }}>
        {props.summaryLabel}
      </FOHAltSectionLabel>
      <FOHSpaceSmall />
      <FOHLabel>{props.summary}</FOHLabel>
    </FOHView>
  </FullWidthCard>
);
