import React from 'react';

import {
  FOHLabel,
  FOHDetailText,
  FOHSpaceSmall,
  FOHSmallDetailLabel,
  FOHApplicantIcon,
  FOHCalendarIcon,
  FOHThumbDownIcon,
  FOHTouchableOpacity,
  FOHView,
  FOHRestaurantIcon,
  FOHRejectionTag,
  FOHMultiPositionTag,
  FOHSpace
} from '../../ingredients';

export interface FOHCandidatePositionDetailsProps {
  type: FOHCandidatePositionDetailsType;

  appliedForLabel: string;
  positionName?: string; // from application
  onPressPosition: () => void;

  locationLabel: string;
  locationName?: string;
  formattedAddress: string;

  applicationDateLabel: string;
  applicationDate: string;

  rejectedForLabel: string;
  rejectedByLabel?: string;
  rejectedByName?: string;
  rejectionReasons: Array<string>;

  appliedPositionCount?: number;
  onPressMorePositions: () => void;
}

const appliedForPositionTrimLength = 35;
const appliedForLocationTrimLength = 38;
const appliedForAddressTrimLength = 46;

export enum FOHCandidatePositionDetailsType {
  REJECTED = 'rejected',
  APPLIED = 'applied'
}

export const FOHCandidatePositionDetails = (
  props: FOHCandidatePositionDetailsProps
) => (
  <FOHView
    style={{
      backgroundColor: '#FCFCFC',
      borderRadius: 6,
      padding: 16
    }}
  >
    {props.type === FOHCandidatePositionDetailsType.REJECTED ? (
      <FOHView
        style={{
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          width: '100%',
          paddingBottom: 20
        }}
      >
        <FOHThumbDownIcon
          style={{ width: 16, height: 16, tintColor: '#171717' }}
        />
        <FOHSpaceSmall />
        <FOHView
          style={{
            justifyContent: 'flex-start',
            top: -8,
            width: '100%'
          }}
        >
          <FOHDetailText style={{ color: '#4F4F4F' }}>
            {props.rejectedForLabel}
          </FOHDetailText>
          <FOHTouchableOpacity onPress={props.onPressPosition}>
            <FOHLabel
              style={{
                textDecorationStyle: 'solid',
                textDecorationLine: 'underline'
              }}
            >
              {props.positionName}
            </FOHLabel>
          </FOHTouchableOpacity>
          <FOHView
            style={{
              flexDirection: 'row',
              flexWrap: 'wrap',
              paddingBottom: 8
            }}
          >
            {props.rejectionReasons ? (
              props.rejectionReasons.map(reason => (
                <FOHRejectionTag
                  label={reason}
                  key={reason}
                  style={{ paddingRight: 8 }}
                />
              ))
            ) : (
              <></>
            )}
          </FOHView>
          {props.rejectedByName ? (
            <FOHSmallDetailLabel>
              {props.rejectedByLabel} {props.rejectedByName}
            </FOHSmallDetailLabel>
          ) : (
            <></>
          )}
        </FOHView>
      </FOHView>
    ) : (
      <></>
    )}
    {props.type === FOHCandidatePositionDetailsType.APPLIED ? (
      <FOHView
        style={{
          justifyContent: 'space-between',
          flexDirection: 'row'
        }}
      >
        <FOHView
          style={{
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            paddingBottom: 20
          }}
        >
          <FOHApplicantIcon style={{ width: 20, height: 20 }} active={true} />
          <FOHSpaceSmall />
          <FOHView
            style={{
              justifyContent: 'flex-start',
              top: -6
            }}
          >
            <FOHDetailText style={{ color: '#4F4F4F' }}>
              {props.appliedForLabel}
            </FOHDetailText>
            <FOHTouchableOpacity onPress={props.onPressPosition}>
              <FOHLabel
                style={{
                  textDecorationStyle: 'solid',
                  textDecorationLine: 'underline'
                }}
              >
                {typeof props.positionName === 'string' &&
                props.positionName.length > appliedForPositionTrimLength
                  ? `${props.positionName.slice(
                      0,
                      appliedForPositionTrimLength
                    )}...`
                  : props.positionName}
              </FOHLabel>
            </FOHTouchableOpacity>
          </FOHView>
        </FOHView>
        {!!props.appliedPositionCount && props.appliedPositionCount > 0 ? (
          <FOHView
            style={{
              alignItems: 'flex-end'
            }}
          >
            <FOHSpace />
            <FOHMultiPositionTag
              value={props.appliedPositionCount}
              onPress={props.onPressMorePositions}
            />
          </FOHView>
        ) : (
          <></>
        )}
      </FOHView>
    ) : (
      <></>
    )}
    {props.locationName ? (
      <FOHView
        style={{
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          width: '100%',
          paddingBottom:
            props.type === FOHCandidatePositionDetailsType.APPLIED ? 20 : 0
        }}
      >
        <FOHRestaurantIcon
          style={{ width: 16, height: 16, tintColor: '#171717' }}
        />
        <FOHSpaceSmall />
        <FOHView
          style={{
            justifyContent: 'flex-start',
            top: -8,
            width: '100%'
          }}
        >
          <FOHDetailText style={{ color: '#4F4F4F' }}>
            {props.locationLabel}
          </FOHDetailText>
          <FOHLabel>
            {typeof props.locationName === 'string' &&
            props.locationName.length > appliedForLocationTrimLength
              ? `${props.locationName.slice(
                  0,
                  appliedForLocationTrimLength
                )}...`
              : props.locationName}
          </FOHLabel>
          <FOHSmallDetailLabel>
            {props.formattedAddress.length > appliedForAddressTrimLength
              ? `${props.formattedAddress.slice(
                  0,
                  appliedForAddressTrimLength
                )}...`
              : props.formattedAddress}
          </FOHSmallDetailLabel>
        </FOHView>
      </FOHView>
    ) : (
      <></>
    )}
    {props.type === FOHCandidatePositionDetailsType.APPLIED ? (
      <FOHView
        style={{
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          width: '100%'
        }}
      >
        <FOHCalendarIcon
          style={{ width: 16, height: 16, tintColor: '#171717' }}
        />
        <FOHSpaceSmall />
        <FOHView
          style={{
            justifyContent: 'flex-start',
            top: -8,
            width: '100%'
          }}
        >
          <FOHDetailText style={{ color: '#4F4F4F' }}>
            {props.applicationDateLabel}
          </FOHDetailText>
          <FOHTouchableOpacity onPress={props.onPressPosition}>
            <FOHLabel>{props.applicationDate}</FOHLabel>
          </FOHTouchableOpacity>
        </FOHView>
      </FOHView>
    ) : (
      <></>
    )}
  </FOHView>
);
