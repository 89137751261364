import styled from 'styled-components/native';
import { Text } from 'react-native';

import { FOHColors } from '../../ingredients';

export const ImageNameHeader = styled(Text)`
  font-size: 16px;
  max-width: 186px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: underline;
  color: ${FOHColors.NARO_GREY};
`;

export const ImageSize = styled(Text)`
  font-size: 12px;
  margin-top: 4px;
  color: ${FOHColors.GREY};
`;

export const NameSizeWrapper = styled.View`
  margin-left: 8px;
`;

export const SectionWrapper = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
`;
