import React from 'react';

import { FOHButton, FOHTextField, Row, FOHPhoneInput } from '../../ingredients';

import { FOHForm } from './FOHForm';

export interface FOHContactFormProps {
  phoneNumber: string;
  phoneNumberPlaceholder: string;
  setPhoneNumber: (value: string) => void;
  email: string;
  emailPlaceholder: string;
  setEmail: (value: string) => void;
  handleSubmit: () => void;
  submitLabel: string;
  disabledSubmit?: boolean;
  error?: any;
}

export const FOHContactForm = (props: FOHContactFormProps) => (
  <FOHForm>
    <Row>
      <FOHTextField
        placeholder={props.emailPlaceholder}
        value={props.email}
        autoCompleteType={'email'}
        onChangeText={(value: string) => props.setEmail(value)}
        error={props.error && props.error.email ? props.error.email : null}
      />
    </Row>
    <Row>
      <FOHPhoneInput
        placeholder={props.phoneNumberPlaceholder}
        value={props.phoneNumber}
        autoCompleteType={'tel'}
        onChangeText={(value: string) => props.setPhoneNumber(value)}
        error={
          props.error && (props.error.phoneNumber || props.error.phone)
            ? props.error.phoneNumber || props.error.phone
            : null
        }
      />
    </Row>

    <FOHButton
      onPress={props.handleSubmit}
      title={props.submitLabel}
      disabled={props.disabledSubmit}
    />
  </FOHForm>
);
