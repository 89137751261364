import { useState, useEffect } from 'react';

import { flatten, length, includes } from 'ramda';

import { AVAILABILITY_WEEKDAYS_CAMEL, shifts } from '../../constants';

export const useAvailability = () => {
  const [availability, setAvailability] = useState([]);
  const [allAvailabilitySelected, setAllAvailabilitySelected] = useState(false);

  const toggleAllAvailability = () => {
    const allSections = flatten(
      AVAILABILITY_WEEKDAYS_CAMEL.map(section =>
        shifts.map(shift => `${section.value}-${shift.value}`)
      )
    );
    if (!allAvailabilitySelected) {
      setAvailability(allSections);
    } else {
      setAvailability([]);
    }
    setAllAvailabilitySelected(!allAvailabilitySelected);
    return allSections;
  };

  const allOptionsChecked = options => length(options) === 28;

  const selectAvailability = ({ value, section }) => {
    const selectedValue = `${section}-${value}`;
    if (availability && includes(selectedValue, availability)) {
      const newSelection = availability.filter(item => item !== selectedValue);
      setAvailability(newSelection);
      setAllAvailabilitySelected &&
        setAllAvailabilitySelected(allOptionsChecked(newSelection));
    } else {
      const newSelection = [selectedValue, ...availability];
      setAvailability(newSelection);
      setAllAvailabilitySelected &&
        setAllAvailabilitySelected(allOptionsChecked(newSelection));
    }
    return selectedValue;
  };

  useEffect(() => {
    availability &&
      allOptionsChecked(availability) &&
      setAllAvailabilitySelected(true);
  }, [availability]);

  return {
    availability,
    setAvailability,
    allAvailabilitySelected,
    selectAvailability,
    toggleAllAvailability
  };
};
