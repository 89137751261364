import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { path, prop, defaultTo } from 'ramda';

import { getTermsUrl, useIsMobile } from '../../utils';
import { APPLICATIONS, HOME_PAGE } from '../../constants';
import {
  FOHFixedHeightScrollView,
  FOHView,
  FOHSpace,
  FOHExternalRegisterForm,
  FOHFormFlowButtons,
  FOHExternalApplicationHeader
} from '../../components';
import { MAX_SCREEN_WIDTH } from '../../components/constants';
import { useNavigation } from '../../utils/navigation';

import { useSetValidEmail } from '../ClaimAccount/useSetValidEmail';
import { SetValidEmailModal } from '../ClaimAccount/SetValidEmailModal';
import { useCandidateProfileMutation } from '../CandidateProfile/useCandidateProfileMutation';

const ExternalApplicationSignUp = props => {
  const { me } = useCandidateProfileMutation();
  const { navigateTo } = useNavigation();

  const { isMobile } = useIsMobile();
  const { t, i18n } = useTranslation('ExternalApplicationFeature');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [openLogin, setOpenLogin] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState('');
  const [terms, setTerms] = useState(false);
  const { error, setValidEmail } = useSetValidEmail();

  useEffect(() => {
    if (path(['candidateProfile', 'positions'], me)) {
      setFirstName(path(['firstName'], me));
      setLastName(path(['lastName'], me));
      const isIndeedApplicant = defaultTo('', prop('email', me)).includes(
        'indeedemail'
      );
      if (path(['email'], me) && !isIndeedApplicant) {
        navigateTo({
          pathname: `${HOME_PAGE}${APPLICATIONS}`,
          search: navLocation.search
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path(['firstName'], me)]);

  return (
    <>
      <FOHView
        style={{
          width: '100%',
          alignItems: 'center'
        }}
      >
        <FOHView style={{ maxWidth: isMobile ? '100%' : 540, width: '100%' }}>
          <FOHFixedHeightScrollView isMobile={isMobile} heightToDecrease={150}>
            <FOHView
              style={{
                width: '100%',
                maxWidth: isMobile ? '100%' : MAX_SCREEN_WIDTH,
                paddingHorizontal: 20
              }}
            >
              <FOHExternalApplicationHeader
                applicationTitle={t('applicationTitle')}
                applicationDetail={t('applicationDetail')}
                // comes from router
                applicationTotalNumber={props.totalStepNumber}
                applicationNumber={props.stepIndex}
              />
            </FOHView>
            <FOHSpace />
            <FOHSpace />
            <FOHExternalRegisterForm
              error={
                password !== confirmPassword && confirmPassword
                  ? {
                      confirmPassword: t('RegisterFeature:passwordsMustMatch'),
                      ...error
                    }
                  : error
              }
              firstName={firstName}
              firstNamePlaceholder={t('RegisterFeature:firstName')}
              setFirstName={setFirstName}
              lastName={lastName}
              lastNamePlaceholder={t('RegisterFeature:lastName')}
              setLastName={setLastName}
              phoneNumber={phone}
              phoneNumberPlaceholder={t('RegisterFeature:phoneNumber')}
              setPhoneNumber={setPhone}
              email={email}
              emailPlaceholder={t('RegisterFeature:email')}
              setEmail={setEmail}
              passwordPlaceholder={t('RegisterFeature:password')}
              password={password}
              setPassword={setPassword}
              confirmPasswordPlaceholder={t('RegisterFeature:confirmPassword')}
              confirmPassword={confirmPassword}
              setConfirmPassword={setConfirmPassword}
              isMobile={isMobile}
              // setOpenLogin
              loginPress={() => {
                setOpenLogin(true);
              }}
              disabledSubmit={true}
              loginBannerText={t('RegisterFeature:loginQuestion')}
              loginText={t('RegisterFeature:loginHere')}
              terms={terms}
              termsText={t('RegisterFeature:acceptTerms')}
              termsLinkText={t('RegisterFeature:termsAgreement')}
              onPressTermsLink={() => window.open(getTermsUrl(i18n.language))}
              setTerms={() => setTerms(!terms)}
              hidePassText={t('common:hidePassText')}
              showPassText={t('common:showPassText')}
            />
            <FOHSpace />
          </FOHFixedHeightScrollView>
          <FOHView style={{ width: '100%', maxWidth: isMobile ? '100%' : 485 }}>
            <FOHFormFlowButtons
              isMobile={isMobile}
              nextButtonlabel={t('common:next')}
              onPressNextButton={async () => {
                const oldEmail = prop('email', me);
                const success = await setValidEmail({
                  id: prop('id', me),
                  firstName,
                  lastName,
                  terms,
                  phone,
                  password,
                  email,
                  oldEmail
                });
                if (success) {
                  // if they already have these things finish early
                  if (
                    path(['candidateProfile', 'positions'], me) &&
                    prop('profileImage', me) &&
                    path(['candidateProfile', 'personalSummary'], me)
                  ) {
                    return props.finishOnboarding();
                  }
                  props.nextStep();
                }
              }}
              isNextDisabled={
                !firstName ||
                !lastName ||
                !terms ||
                !phone ||
                !password ||
                !email ||
                password !== confirmPassword
              }
            />
          </FOHView>
        </FOHView>
      </FOHView>
      <SetValidEmailModal
        onlySignIn={true}
        setOpen={setOpenLogin}
        open={openLogin}
        onSubmit={() => {
          // if they already have these things finish early
          if (
            path(['candidateProfile', 'positions'], me) &&
            prop('profileImage', me) &&
            path(['candidateProfile', 'personalSummary'], me)
          ) {
            return props.finishOnboarding();
          }
          props.nextStep();
        }}
        close={() => {
          setOpenLogin(false);
        }}
      />
    </>
  );
};

export default ExternalApplicationSignUp;
