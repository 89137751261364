import React from 'react';

import success from '../../../images/success.svg';
import success_black from '../../../images/black_check.svg';

import { FOHImage } from '../../ingredients';

export const FOHSuccessIcon = (props: any) => (
  <FOHImage
    source={{ uri: success }}
    style={{ width: 24, height: 24, ...props.style }}
  />
);

export const FOHSuccessIconBlack = (props: any) => (
  <FOHImage
    source={{ uri: success_black }}
    style={{ width: 16, height: 11, ...props.style }}
  />
);
