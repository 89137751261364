import React from 'react';

import {
  FOHButton,
  FOHTextField,
  FOHMultiLineTextField,
  FullWidthRow,
  FOHErrorLabel,
  FOHColors,
  FOHView
} from '../../ingredients';

import { FOHForm } from './FOHForm';

export interface FOHEndorseFormProps {
  handleSubmit: () => void;
  relation: string;
  relationPlaceholder: string;
  setRelation: (value: string) => void;

  endorsement: string;
  endorsementPlaceholder: string;
  setEndorsement: (value: string) => void;

  submitLabel: string;
  error?: any;
  disabledSubmit?: boolean;
  cancelLabel: string;
  cancel: () => void;
}

export const FOHEndorseForm = (props: FOHEndorseFormProps) => (
  <>
    <FOHForm
      handleSubmit={props.handleSubmit}
      disabledSubmit={props.disabledSubmit}
    >
      <FullWidthRow>
        <FOHTextField
          placeholder={props.relationPlaceholder}
          value={props.relation}
          onChangeText={(value: string) => props.setRelation(value)}
          error={
            props.error && props.error.relation ? props.error.relation : null
          }
        />
      </FullWidthRow>

      <FullWidthRow>
        <FOHMultiLineTextField
          placeholder={props.endorsementPlaceholder}
          value={props.endorsement}
          style={{ height: 96 }}
          onChangeText={(value: string) => props.setEndorsement(value)}
          error={
            props.error && props.error.endorsement
              ? props.error.endorsement
              : null
          }
        />
      </FullWidthRow>
      {props.error && props.error.candidate && (
        <FullWidthRow>
          <FOHErrorLabel>{props.error.candidate[0]}</FOHErrorLabel>
        </FullWidthRow>
      )}
      <FullWidthRow style={{ alignItems: 'center' }}>
        <FOHButton
          onPress={props.handleSubmit}
          title={props.submitLabel}
          disabled={props.disabledSubmit}
          style={{ width: '47%' }}
        />
        <FOHView style={{ padding: 12 }} />
        <FOHButton
          style={{
            width: '47%'
          }}
          title={props.cancelLabel}
          onPress={props.cancel}
          color={FOHColors.WHITE}
          textColor={FOHColors.BLACK}
          borderColor={FOHColors.BACKGROUND_DARK_GRAY}
        />
      </FullWidthRow>
    </FOHForm>
  </>
);
