import React from 'react';

import { ImageStyle, StyleProp } from 'react-native';

import iconSelected from '../../../images/Selected.svg';
import iconUnselected from '../../../images/Unselected.svg';
import iconSelectedDisabled from '../../../images/selected_disabled.svg';
import iconUnselectedDisabled from '../../../images/unselected_disabled.svg';

import { FOHImage } from '../../ingredients';

interface FOHRadioSelectProps {
  selected: boolean;
  style?: StyleProp<ImageStyle>;
  isDisabled?: boolean;
}

export const FOHRadioSelect = ({
  selected,
  isDisabled,
  style
}: FOHRadioSelectProps) => (
  <FOHImage
    source={{
      uri: selected
        ? isDisabled
          ? iconSelectedDisabled
          : iconSelected
        : isDisabled
        ? iconUnselectedDisabled
        : iconUnselected
    }}
    style={[{ width: 20, height: 20 }, style]}
  />
);

export const FOHLargeRadioSelect = ({
  selected,
  isDisabled,
  style
}: FOHRadioSelectProps) => (
  <FOHImage
    source={{
      uri: selected
        ? isDisabled
          ? iconSelectedDisabled
          : iconSelected
        : isDisabled
        ? iconUnselectedDisabled
        : iconUnselected
    }}
    style={[{ width: 24, height: 24 }, style]}
  />
);
