import React from 'react';

import chevron_right from '../../../images/chevron_right.svg';

import { FOHImage } from '../../ingredients';

export const FOHNextIcon = (props: any) => (
  <FOHImage
    source={{ uri: chevron_right }}
    style={{
      width: props?.style?.width || 24,
      height: props?.style?.height || 24,
      ...props.style
    }}
    testID={props?.testID}
  />
);

export const FOHPrevIcon = (props: any) => (
  <FOHImage
    source={{ uri: chevron_right }}
    style={{
      width: 24,
      height: 24,
      transform: `rotate(180deg)`,
      ...props.style
    }}
  />
);
