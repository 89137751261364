import React, { ReactNode } from 'react';

import { useDimensions } from 'react-native-web-hooks';

import {
  FOHCheckbox,
  FOHLabel,
  FullWidthCard,
  FOHToolBoxIcon,
  FOHSpaceSmall,
  FOHLocationPin,
  FOHSpace,
  FOHSuccessIcon,
  FOHLabelSmall,
  FOHColors,
  FOHGrayTag,
  FOHVerticalDivider,
  FOHSpaceSmallest,
  FOHLabelBold,
  FOHStatus,
  FOHStatusProps,
  FOHView,
  FOHTouchableOpacity
} from '../../ingredients';
import { FOHProfileImage } from '../../recipes';

export interface FOHCandidateNetworkCellProps extends FOHStatusProps {
  name: string;
  onPressName: (handle: string) => void;
  handle: string;
  selected: boolean;
  onCheckBoxPress: (handle: string) => void;
  profileImage?: string;
  onPressProfileImage: (handle: string) => void;
  positions?: string;
  workHistoryPositionName?: string;
  workHistoryEmployerName?: string;
  onPressWorkHistoryEmployerName?: (slug: string) => void;
  workHistoryEmployerSlug?: string;
  atLabel?: string;
  cityZipString?: string;
  experience?: string;
  experienceLabel: string;
  profileCompletenessLabel: string;
  profileCompleteness: Array<ProfileCompletenessInterface>;
  children?: Array<ReactNode>;
  hasStatusBackground?: boolean;
}

interface ProfileCompletenessInterface {
  label?: string;
  value?: boolean;
}

export const FOHCandidateNetworkCell = (
  props: FOHCandidateNetworkCellProps
) => {
  const { window } = useDimensions();
  const isMobile = window.width < 768;

  return (
    <FullWidthCard
      style={{
        height: isMobile ? 80 : 120,
        padding: 16,
        paddingTop: 12,
        maxWidth: 1321
      }}
    >
      <FOHView
        style={{ flexDirection: 'row', alignItems: 'center', flex: 1 }}
        testID={`FOHCandidateNetworkCell-${props.name}`}
      >
        <FOHView
          style={{ flexDirection: 'row', alignItems: 'center', flex: 1 }}
        >
          <FOHTouchableOpacity
            testID="candidate-checkbox"
            onPress={() => props.onCheckBoxPress(props.handle)}
          >
            <FOHCheckbox selected={props.selected} />
          </FOHTouchableOpacity>
          <FOHSpace />

          <FOHTouchableOpacity
            onPress={() => props.onPressProfileImage(props.handle)}
          >
            <FOHProfileImage
              photo={props.profileImage}
              style={{
                width: isMobile ? 48 : 68,
                height: isMobile ? 48 : 68
              }}
            />
          </FOHTouchableOpacity>
          <FOHSpace />
          <FOHView style={{ flex: 1 }}>
            <FOHView style={{ flexDirection: 'row', alignItems: 'center' }}>
              <FOHTouchableOpacity
                onPress={() => props.onPressName(props.handle)}
                style={{ maxWidth: 200 }}
              >
                <FOHLabelBold
                  numberOfLines={1}
                  style={{
                    textDecorationLine: 'underline',
                    fontSize: 18,
                    fontWeight: '700'
                  }}
                >
                  {props.name}
                </FOHLabelBold>
              </FOHTouchableOpacity>
              <FOHSpaceSmall />
              <FOHStatus
                small={isMobile}
                statusText={props.statusText}
                statusType={props.statusType}
                hasBackground={true}
                labelStyle={{
                  fontSize: 12,
                  lineHeight: 16,
                  fontWeight: '600',
                  padding: 0
                }}
              ></FOHStatus>
            </FOHView>
            <FOHView
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                paddingTop: 8,
                flexWrap: 'wrap',
                flex: 1
              }}
            >
              <FOHToolBoxIcon
                style={{
                  width: 13,
                  height: 13,
                  tintColor: FOHColors.GRAYSCALE_700
                }}
              />
              <FOHSpaceSmall />
              {props.workHistoryPositionName ? (
                <>
                  <FOHLabel
                    style={{
                      fontSize: 14,
                      fontWeight: '600',
                      color: FOHColors.GRAYSCALE_700
                    }}
                  >
                    {props.workHistoryPositionName}{' '}
                    {props.workHistoryEmployerName && props.atLabel}{' '}
                  </FOHLabel>
                  <FOHTouchableOpacity
                    disabled={
                      !props.onPressWorkHistoryEmployerName ||
                      !props.workHistoryEmployerSlug
                    }
                    onPress={() =>
                      props.onPressWorkHistoryEmployerName &&
                      props.onPressWorkHistoryEmployerName(
                        props.workHistoryEmployerSlug || ''
                      )
                    }
                  >
                    <FOHLabel
                      style={{
                        textDecorationLine: 'underline',
                        fontSize: 14,
                        fontWeight: '700'
                      }}
                    >
                      {props.workHistoryEmployerName}
                    </FOHLabel>
                  </FOHTouchableOpacity>
                </>
              ) : (
                <FOHLabel style={{ fontSize: 14 }}>
                  {props.positions || '-'}
                </FOHLabel>
              )}
              {!!props.experience && !isMobile && (
                <>
                  <FOHSpaceSmall />
                  <FOHVerticalDivider
                    style={{
                      height: 18,
                      width: 1,
                      backgroundColor: FOHColors.BACKGROUND_DARK_GRAY
                    }}
                  />
                  <FOHSpaceSmall />
                  <FOHLabel
                    style={{ fontSize: 14, color: FOHColors.GRAYSCALE_500 }}
                  >
                    {props.experienceLabel}
                  </FOHLabel>
                  <FOHSpaceSmallest />
                  <FOHGrayTag
                    label={props.experience || ''}
                    style={{
                      fontSize: 14,
                      color: FOHColors.GRAYSCALE_700,
                      margin: 0
                    }}
                  ></FOHGrayTag>
                </>
              )}
            </FOHView>
            {!isMobile && (
              <>
                <FOHView
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingTop: 8,
                    flexWrap: 'wrap',
                    flex: 1
                  }}
                >
                  <FOHLocationPin
                    style={{
                      width: 13,
                      height: 13,
                      tintColor: FOHColors.GRAYSCALE_400
                    }}
                  />
                  <FOHSpaceSmall />
                  <FOHLabel
                    style={{ color: FOHColors.GRAYSCALE_500, fontSize: 14 }}
                  >
                    {props.cityZipString}
                  </FOHLabel>
                </FOHView>
              </>
            )}
          </FOHView>
        </FOHView>

        {!isMobile && (
          <FOHView
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              flex: 1,
              justifyContent: 'flex-end'
            }}
          >
            <FOHView style={{ flex: 1, justifyContent: 'flex-start' }}>
              <FOHLabel
                style={{
                  color: FOHColors.GRAYSCALE_400,
                  fontSize: 12,
                  lineHeight: 16,
                  fontWeight: '600'
                }}
              >
                {props.profileCompletenessLabel}
              </FOHLabel>
              <FOHView
                style={{
                  maxWidth: 600,
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                  flex: 2,
                  marginBottom: 8
                }}
              >
                {props.profileCompleteness.map(
                  (item: ProfileCompletenessInterface) => (
                    <FOHProfileCompletenessCheck
                      {...item}
                      key={`${item.label}${props.handle}`}
                    />
                  )
                )}
              </FOHView>
            </FOHView>
            <FOHView style={{ flex: 1, justifyContent: 'flex-end' }}>
              {!!props.children && (
                <FOHView
                  style={{ flexDirection: 'row', justifyContent: 'flex-end' }}
                >
                  {props.children}
                </FOHView>
              )}
            </FOHView>
          </FOHView>
        )}
      </FOHView>
    </FullWidthCard>
  );
};

const FOHProfileCompletenessCheck = (props: ProfileCompletenessInterface) => (
  <FOHView
    style={{
      flexDirection: 'row',
      alignItems: 'center',
      paddingRight: 16,
      paddingTop: 8
    }}
  >
    <FOHSuccessIcon
      style={{
        width: 12,
        height: 9,
        tintColor: props.value ? FOHColors.GREEN_200 : FOHColors.GRAYSCALE_400
      }}
    />
    <FOHLabelSmall
      style={{
        color: props.value ? FOHColors.GRAYSCALE_700 : FOHColors.GRAYSCALE_400,
        fontSize: 12,
        lineHeight: 16,
        fontWeight: '400'
      }}
    >
      {props.label}
    </FOHLabelSmall>
  </FOHView>
);
