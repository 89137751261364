import React, { useContext, useCallback, useState, useEffect } from 'react';

import { FOHBannerType } from '../../components';

type defaultBannerStateType = {
  bannerType: keyof typeof FOHBannerType;
  bannerMessage: string | undefined;
};

const BannerContext = React.createContext<
  [defaultBannerStateType, (state: defaultBannerStateType) => void]
>([{} as defaultBannerStateType, () => {}]);

const defaultBannerState = {
  bannerType: '' as keyof typeof FOHBannerType,
  bannerMessage: ''
};

export const useBanner = () => {
  const [state, setState] =
    useContext<
      [defaultBannerStateType, (state: defaultBannerStateType) => void]
    >(BannerContext);

  const setBanner = useCallback(
    (banner?: typeof defaultBannerState) => {
      if (!banner) {
        setState(defaultBannerState);
      } else {
        const { bannerType, bannerMessage } = banner;
        setState({
          bannerType,
          bannerMessage
        });
      }
    },
    [setState]
  );

  useEffect(() => {
    if (state.bannerType) {
      setTimeout(() => {
        setBanner(defaultBannerState);
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.bannerType]);

  return { banner: state, setBanner };
};

export const BannerContextProvider = (props: any) => {
  const [state, setState] =
    useState<defaultBannerStateType>(defaultBannerState);

  return (
    <BannerContext.Provider value={[state, setState]}>
      {props.children}
    </BannerContext.Provider>
  );
};
