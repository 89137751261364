import React, { useMemo } from 'react';

import { path, prop, defaultTo, toLower } from 'ramda';
import { useTranslation } from 'react-i18next';
import { useChannelContext } from '@sendbird/uikit-react/Channel/context';
import { HiddenState } from '@sendbird/chat/groupChannel';
import { useSendbirdStateContext } from '@sendbird/uikit-react';

import { useIsMobile } from '../../utils';
import restore_icon from '../../images/restore_icon.svg';
import { useGetChannelAndFullDetailsQuery } from '../../graphql/generated';
import {
  CANDIDATE_PROFILE,
  FEED,
  INTERVIEW_ADD_TO_CALENDAR_PATH,
  INTERVIEW_DETAIL_PATH,
  JOBS,
  RESTAURANT_JOBS
} from '../../constants';
import {
  FOHChannelHeader,
  FOHColors,
  FOHImage,
  FOHInterviewStatus,
  FOHMessageBoxIcon
} from '../../components';
import { useNavigation } from '../../utils/navigation';

import { useCurrentLocationContext } from '../Locations/useCurrentLocationContext';

const RestoreIcon = () => {
  return <FOHImage source={restore_icon} style={{ width: 20, height: 20 }} />;
};

export const SendbirdChannelHeader = props => {
  const { isCandidate } = props;

  const { navigateTo } = useNavigation();

  const { isMobile } = useIsMobile();
  const { t } = useTranslation('ChatFeature');
  const sb = useSendbirdStateContext();

  const { currentGroupChannel } = useChannelContext();

  const talkingTo = prop(
    0,
    defaultTo([], path(['members'], currentGroupChannel)).filter(
      member => member.metaData.is_candidate === 'True'
    )
  );

  const channelDetailsQuery = useGetChannelAndFullDetailsQuery({
    skip: !prop('url', currentGroupChannel),
    variables: { sendbirdChannelId: prop('url', currentGroupChannel) }
  });

  const restaurantName =
    path(['data', 'channel', 'location', 'name'], channelDetailsQuery) ||
    path(['data', 'channel', 'lastLocation', 'name'], channelDetailsQuery);
  const restaurantImage =
    path(['data', 'channel', 'location', 'image'], channelDetailsQuery) ||
    path(['data', 'channel', 'lastLocation', 'image'], channelDetailsQuery);

  const isMatch = path(
    ['data', 'channel', 'lastPosition', 'id'],
    channelDetailsQuery
  );

  const isApplicant = path(
    ['data', 'channel', 'lastApplication', 'id'],
    channelDetailsQuery
  );

  const interviewStatus = toLower(
    defaultTo(
      '',
      path(['data', 'channel', 'lastInterview', 'status'], channelDetailsQuery)
    )
  );

  const interviewUuid = toLower(
    defaultTo(
      '',
      path(['data', 'channel', 'lastInterview', 'uuid'], channelDetailsQuery)
    )
  );

  const interviewRequested = interviewStatus === FOHInterviewStatus.PENDING;
  const interviewAccepted = interviewStatus === FOHInterviewStatus.ACCEPTED;
  const interviewScheduled = interviewStatus === FOHInterviewStatus.SCHEDULED;

  // const stage = interviewScheduled
  //   ? t('interviewScheduled')
  //   : interviewAccepted
  //   ? t('interviewAccepted')
  //   : interviewRequested
  //   ? t('interviewRequested')
  //   : isApplicant
  //   ? t('applicant')
  //   : isMatch
  //   ? t('candidateMatch')
  //   : t('candidate');

  const stageAction = interviewScheduled
    ? t('scheduledFor')
    : interviewAccepted
    ? t('acceptedFor')
    : interviewRequested
    ? t('requestedFor')
    : isApplicant
    ? t('appliedTo')
    : isMatch
    ? t('matchFor')
    : t('');

  const { selectLocationFilter } = useCurrentLocationContext();
  const locationId =
    path(['data', 'channel', 'location', 'id'], channelDetailsQuery) ||
    path(['data', 'channel', 'lastLocation', 'id'], channelDetailsQuery);

  const isChannelHidden = useMemo(
    () => currentGroupChannel?.hiddenState !== HiddenState.UNHIDDEN || false,
    [currentGroupChannel]
  );

  return (
    // candidates see restaurant details
    <FOHChannelHeader
      mobile={isMobile}
      restaurantName={isCandidate ? restaurantName : ''}
      image={isCandidate ? restaurantImage : path(['profileUrl'], talkingTo)}
      candidateName={
        isCandidate ? restaurantName : path(['nickname'], talkingTo)
      }
      onPressName={() => {
        if (isCandidate) {
          return navigateTo(
            path(['data', 'channel', 'location', 'slug'], channelDetailsQuery)
              ? `${RESTAURANT_JOBS}/${path(
                  ['data', 'channel', 'location', 'slug'],
                  channelDetailsQuery
                )}`
              : `${RESTAURANT_JOBS}/${path(
                  ['data', 'channel', 'lastLocation', 'slug'],
                  channelDetailsQuery
                )}`
          );
        }
        if (!isCandidate) {
          selectLocationFilter(locationId);
          navigateTo(
            `${CANDIDATE_PROFILE}/${path(
              [
                'data',
                'channel',
                'candidateUser',
                'candidateProfile',
                'handle'
              ],
              channelDetailsQuery
            )}?location=${locationId}`
          );
        }
      }}
      positionText={stageAction}
      linkText={path(
        ['data', 'channel', 'lastPosition', 'positionName'],
        channelDetailsQuery
      )}
      onLinkPress={() => {
        if (isCandidate) {
          interviewScheduled || interviewAccepted
            ? navigateTo(`${INTERVIEW_ADD_TO_CALENDAR_PATH}/${interviewUuid}`)
            : interviewRequested
            ? navigateTo(`${INTERVIEW_DETAIL_PATH}/${interviewUuid}`)
            : navigateTo(
                `${JOBS}/${path(
                  ['data', 'channel', 'lastPosition', 'slug'],
                  channelDetailsQuery
                )}`
              );
        }
        if (!isCandidate) {
          selectLocationFilter(locationId);
          navigateTo(
            `${FEED}?location=${path(
              ['data', 'channel', 'location', 'id'],
              channelDetailsQuery
            )}&position=${path(
              ['data', 'channel', 'lastPosition', 'id'],
              channelDetailsQuery
            )}`
          );
        }
      }}
      // TBD remove stage because of redundant info
      // stageLabel={isCandidate ? '' : t('stageLabel')}
      // stage={isCandidate ? '' : stage}
      detailsLabel={t('details')}
      // onDetailsPressed={() => {}}
      onBackPress={() => {}}
      // hiringManagerLabel={t('hiringManagerLabel')}
      // hiringManagerName={isCandidate ? hiringManagerName : ''}
      onPressArchiveOrUnarchive={
        sb && !isCandidate
          ? () => props.hideChannel(sb.stores.sdkStore.sdk)
          : undefined
      }
      archiveButtonLabel={
        !isCandidate
          ? isChannelHidden
            ? 'Back to inbox'
            : 'Archive'
          : undefined
      }
      archiveButtonProps={{
        style: {
          color: isChannelHidden ? FOHColors.BLUE : FOHColors.BLACK,
          textDecoration: 'underline'
        }
      }}
      archiveButtonIcon={isChannelHidden ? RestoreIcon : FOHMessageBoxIcon}
    />
  );
};
