import React, { FunctionComponent } from 'react';

import styled from 'styled-components/native';
import { Text } from 'react-native';

import {
  FOHColors,
  FOHDetailText,
  FOHUploadIcon,
  FOHView,
  SPACING_SCALE
} from '../../../ingredients';

export const EmptyUploadWrapper = styled.View`
  border-radius: 8px;
  padding: ${SPACING_SCALE.larger}px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='grey' stroke-width='1' stroke-dasharray='9' stroke-dashoffset='35' stroke-linecap='square'/%3e%3c/svg%3e");
  flex-direction: 'row';
  /* Last few pixels were truncated when not specified */
  padding-bottom: 0px;
`;

const FileUploadDescriptionText = styled(FOHDetailText)`
  color: ${FOHColors.BLACK};
  display: 'static';
  text-align: 'start';
  line-height: 22px;
`;

export const FileLimitationText = styled.Text`
  font-size: 12px;
  color: ${FOHColors.GRAY};
  margin-top: ${SPACING_SCALE.regular}px;
  margin-bottom: ${SPACING_SCALE.larger}px;
  margin-left: ${SPACING_SCALE.massive}px;
`;

export const EmptyUploadStateView: FunctionComponent<{
  translations: Record<string, string>;
}> = ({ translations }) => (
  <FOHView style={{ flexDirection: 'row' }}>
    <FOHUploadIcon
      style={{
        marginRight: SPACING_SCALE.large,
        marginTop: SPACING_SCALE.small
      }}
    />

    <FileUploadDescriptionText>
      {translations.dropFilesHere}

      <Text
        style={{
          color: FOHColors.PACIFIC_BLUE,
          marginLeft: SPACING_SCALE.small,
          textDecorationLine: 'underline'
        }}
      >
        {translations.browse}
      </Text>
    </FileUploadDescriptionText>
  </FOHView>
);
