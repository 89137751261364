import React from 'react';

import { useTranslation } from 'react-i18next';
import { prop, path, defaultTo, length } from 'ramda';

import {
  FOHCandidateSelectCell,
  FOHNotificationAlertInterview,
  FOHNotificationAlertOffers,
  FOHNotificationAlertScreening,
  FOHNotificationCompletedInterviewCompleted,
  FOHNotificationCompletedOffers,
  FOHNotificationCompletedScreening,
  FOHNotificationPendingInterview,
  FOHNotificationUnscheduledInterview,
  FOHNotificationPendingOffers,
  FOHNotificationPendingScreening,
  FOHNotificationRejectedOffers,
  FOHView
} from '../../components';

import { useCandidateFeedContext } from './useCandidateFeedContext';
import { useStages } from './useStages';

export const getNotificationIcon = (stageType, notificationStatus) => {
  switch (stageType) {
    case `SCREENING`:
      switch (notificationStatus) {
        case `TODO`:
          return FOHNotificationAlertScreening;
        case `PENDING`:
          return FOHNotificationPendingScreening;
        case `COMPLETE`:
        case `DONE`:
          return FOHNotificationCompletedScreening;
        default:
          return undefined;
      }
    case `INTERVIEWS`:
      switch (notificationStatus) {
        case `TODO`:
          return FOHNotificationAlertInterview;
        case `PENDING`:
          return FOHNotificationPendingInterview;
        case `UNSCHEDULED`:
          return FOHNotificationUnscheduledInterview;
        case `COMPLETE`:
        case `DONE`:
          return FOHNotificationCompletedInterviewCompleted;
        default:
          return undefined;
      }
    case `OFFERS`:
    case `OFFERED`:
      switch (notificationStatus) {
        case `TODO`:
          return FOHNotificationAlertOffers;
        case `PENDING`:
          return FOHNotificationPendingOffers;
        case `COMPLETE`:
        case `DONE`:
          return FOHNotificationCompletedOffers;
        case `DECLINED`:
          return FOHNotificationRejectedOffers;
        default:
          return undefined;
      }
    case `HIRED`:
      switch (notificationStatus) {
        case `TODO`:
          return FOHNotificationAlertOffers;
        case `DONE`:
          return FOHNotificationCompletedOffers;
        default:
          return undefined;
      }
    default:
      return undefined;
  }
};

export const CandidateInboxCell = ({
  employerCandidate,
  setCandidateHandle,
  setEmployerCandidate,
  candidateHandle,
  multiSelectEmployerCandidate,
  canBulkEdit,
  selected,
  onDirectMessagePress,
  zIndex,
  loading,
  candidates // all pulled down candidates in the list
}) => {
  const candidate = defaultTo({}, prop('candidateProfile', employerCandidate));
  const fullName = `${path(['user', 'firstName'], candidate)} ${path(
    ['user', 'lastName'],
    candidate
  )}`;
  const preferredMatch = defaultTo(
    {},
    prop('preferredMatch', employerCandidate)
  );
  const {
    activeStageId,
    selectedEmployerCandidates,
    setSelectedEmployerCandidates,
    allCandidates
  } = useCandidateFeedContext();

  const { t } = useTranslation('NotificationBanner');

  const { currentStage } = useStages({ activeStageId });
  const stageType = prop('stageType', currentStage);

  const chatNotification = path(['chatNotification'], employerCandidate);
  const hasNote = path(['hasNote'], employerCandidate);

  const otherNotification = prop(
    'node',
    path(['employercandidateNotifications', 'edges'], employerCandidate).find(
      notification =>
        path(['node', 'notificationType'], notification) !== 'CHAT'
    )
  );

  const rejectedReasons = defaultTo(
    [],
    path(['rejectionReasons', 'edges'], employerCandidate)
  ).map(reason => defaultTo('', path(['node', 'name'], reason)));

  const getIconByStageTypeAndNotificationStatus = (
    _stageType,
    _notificationStatus
  ) => {
    switch (_stageType) {
      case `SCREENING`:
        switch (_notificationStatus) {
          case `TODO`:
            return FOHNotificationAlertScreening;
          case `PENDING`:
            return FOHNotificationPendingScreening;
          case `DONE`:
            return FOHNotificationCompletedScreening;
          default:
            return undefined;
        }
      case `INTERVIEWS`:
        switch (_notificationStatus) {
          case `TODO`:
            return FOHNotificationAlertInterview;
          case `PENDING`:
            return FOHNotificationPendingInterview;
          case `UNSCHEDULED`:
            return FOHNotificationUnscheduledInterview;
          case `DONE`:
            return FOHNotificationCompletedInterviewCompleted;
          default:
            return undefined;
        }
      case `OFFERED`:
        switch (_notificationStatus) {
          case `TODO`:
            return FOHNotificationAlertOffers;
          case `PENDING`:
            return FOHNotificationPendingOffers;
          case `DONE`:
            return FOHNotificationCompletedOffers;
          default:
            return undefined;
        }
      case `HIRED`:
        switch (_notificationStatus) {
          case `TODO`:
            return FOHNotificationAlertOffers;
          case `DONE`:
            return FOHNotificationCompletedOffers;
          default:
            return undefined;
        }
      default:
        return undefined;
    }
  };

  const notificationIcon = getIconByStageTypeAndNotificationStatus(
    stageType,
    prop('status', otherNotification)
  );
  const notificationHelperText = t(prop('message', otherNotification), {
    candidate: fullName
  });

  return (
    <FOHView style={{ zIndex: zIndex }}>
      <FOHCandidateSelectCell
        loading={loading}
        key={path(['handle'], candidate)}
        name={fullName}
        positionName={`${path(
          ['position', 'positionName'],
          preferredMatch
        )} - ${path(['position', 'location', 'name'], preferredMatch)}`}
        rejectedReasons={stageType === 'REJECTED' ? rejectedReasons : []}
        isActive={candidateHandle === path(['handle'], candidate) || selected}
        photo={path(['user', 'profileImage'], candidate)}
        //here the id prop is handle
        id={path(['handle'], candidate)}
        onPressCell={handle => {
          if (canBulkEdit && length(selectedEmployerCandidates) > 0) {
            setEmployerCandidate(employerCandidate);
            setCandidateHandle(handle);
            multiSelectEmployerCandidate(prop('id', employerCandidate));
          }
          setEmployerCandidate(employerCandidate);
          setCandidateHandle(handle);
          if (chatNotification) {
            onDirectMessagePress(
              employerCandidate,
              path(['user', 'uuid'], candidate)
            );
          }
        }}
        selected={selected}
        onSelectPress={
          canBulkEdit
            ? () => {
                if (allCandidates) {
                  // all pulled down candidates but this one
                  setSelectedEmployerCandidates(
                    candidates
                      .filter(
                        cand =>
                          prop('id', cand) !== prop('id', employerCandidate)
                      )
                      .map(cand => prop('id', cand))
                  );
                } else {
                  multiSelectEmployerCandidate(prop('id', employerCandidate));
                }
              }
            : undefined
        }
        hasUnreadMessage={
          !!chatNotification && !path(['dismissedAt'], chatNotification)
        }
        unreadMessageHelperText={prop('message', chatNotification)}
        notificationIcon={notificationIcon}
        notificationIconHelperText={notificationHelperText}
        hasNote={hasNote}
      />
    </FOHView>
  );
};
