import React from 'react';
import { useTranslation } from 'react-i18next';
import FontPicker from 'font-picker-react';

import {
  FOHLabelSmall,
  FOHColorPicker,
  FOHColors,
  FOHSpaceSmall,
  FOHView,
  SPACING_SCALE,
  FOHSectionLabel,
  FOHSmallDetailLabel
} from '../../components';
import { envVariables } from '../../constants';

export const FormColorBrandingSection = ({
  activeFontFamily,
  color,
  fontColor,
  initialColor,
  pageColor,
  setColor,
  setFont,
  setFontColor,
  setPageColor,
  setTableColor,
  tableColor
}) => {
  const { t } = useTranslation('CareerPage');

  return (
    <FOHView style={{ marginTop: SPACING_SCALE.massive }}>
      <FOHSectionLabel style={{ fontSize: 18 }}>
        {t('yourBrandingHeader')}
      </FOHSectionLabel>
      <FOHSmallDetailLabel
        style={{
          marginBottom: SPACING_SCALE.larger
        }}
      >
        {t('yourBrandingSubHeader')}
      </FOHSmallDetailLabel>

      <FOHView
        style={{
          border: `1px solid ${FOHColors.BACKGROUND_DARK_GRAY}`,
          borderRadius: '4px',
          padding: SPACING_SCALE.larger,
          paddingTop: SPACING_SCALE.regular
        }}
      >
        <FOHView style={{ marginBottom: 30, zIndex: 2 }}>
          <FOHView>
            <FOHSectionLabel
              style={{
                fontSize: 16,
                fontWeight: 'normal'
              }}
            >
              {t('customFontLabel')}
            </FOHSectionLabel>

            <FOHSmallDetailLabel
              style={{
                fontSize: 12,
                marginBottom: SPACING_SCALE.larger
              }}
            >
              {t('googleFontTitleLabel')} {/* All Google Fonts available */}
            </FOHSmallDetailLabel>
          </FOHView>
          <FOHView
            style={{ flexDirection: 'row' }}
            testID="FormColorBrandingSection-font"
          >
            <FOHView style={{ marginRight: 40, width: '50%' }}>
              <FontPicker
                activeFontFamily={activeFontFamily}
                apiKey={envVariables.GOOGLE_API_KEY}
                onChange={nextFont => setFont(nextFont.family)}
              />
            </FOHView>
            <FOHColorPicker
              colorStyle={{ borderRadius: 4, height: 40, width: 40 }}
              initialValue={initialColor}
              setValue={setFontColor}
              style={{ margin: 0, width: 163 }}
              value={fontColor}
            />
          </FOHView>
        </FOHView>
        <FOHView style={{ marginBottom: 10, zIndex: 1 }}>
          <FOHView>
            <FOHSectionLabel
              style={{
                fontSize: 16,
                fontWeight: 'normal'
              }}
            >
              {t('brandColorHeader')}
            </FOHSectionLabel>

            <FOHSmallDetailLabel
              style={{
                fontSize: 12,
                marginBottom: SPACING_SCALE.larger
              }}
            >
              {t('brandColorSubHeader')}
            </FOHSmallDetailLabel>
          </FOHView>

          <FOHView style={{ flexDirection: 'row', marginTop: 20 }}>
            <FOHView
              style={{ flex: 1 }}
              testID="FormColorBrandingSection-buttons"
            >
              <FOHLabelSmall>BUTTONS</FOHLabelSmall>
              <FOHSpaceSmall />
              <FOHColorPicker
                initialValue={initialColor}
                setValue={setColor}
                style={{ margin: 0, width: 214 }}
                value={color}
              />
            </FOHView>
            <FOHView
              style={{ flex: 1 }}
              testID="FormColorBrandingSection-page-bg"
            >
              <FOHLabelSmall>PAGE BACKGROUND</FOHLabelSmall>
              <FOHSpaceSmall />
              <FOHColorPicker
                initialValue={initialColor}
                setValue={setPageColor}
                style={{ margin: 0, width: 214 }}
                value={pageColor}
              />
            </FOHView>
            <FOHView
              style={{ flex: 1 }}
              testID="FormColorBrandingSection-table-bg"
            >
              <FOHLabelSmall>TABLE BACKGROUND</FOHLabelSmall>
              <FOHSpaceSmall />
              <FOHColorPicker
                initialValue={initialColor}
                setValue={setTableColor}
                style={{ margin: 0, width: 214 }}
                value={tableColor}
              />
            </FOHView>
          </FOHView>
        </FOHView>
      </FOHView>
    </FOHView>
  );
};
