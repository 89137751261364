import React from 'react';

import { View, Image } from 'react-native';
import styled from 'styled-components/native';
import { defaultTo, prop } from 'ramda';

import {
  FOHHeaderH2,
  FOHSmallDetailLabel,
  FOHSpace,
  FullWidthCard,
  FOHFonts,
  FOHLinkOut
} from '../../ingredients';

export interface FOHPositionOverviewType {
  locationImage: string;
  postedDateText?: string;
  positionName: string;
  locationHref: string;
  locationName: string;
  locationSlug: string;
  onPressLocation: (slug: string) => void;
}

export interface FOHPositionOverviewProps extends FOHPositionOverviewType {
  noBorder?: boolean;
  shadow?: boolean;
  style?: any;
  padding?: number;
  headerStyle?: any;
  memoImageStyle?: any;
  locationDetailText?: string;
}

const MemoImage = React.memo((props: any) => (
  <Image source={props.source} {...props} />
));

export const FOHPositionOverview = (props: FOHPositionOverviewProps) => (
  <FullWidthCard
    shadow={props.shadow}
    noBorder={props.noBorder}
    style={{
      flexDirection: 'row',
      flexWrap: 'wrap',
      padding: props.padding || 24,
      ...defaultTo({}, prop('style', props))
    }}
  >
    <MemoImage
      source={{ uri: props.locationImage }}
      style={{
        width: 72,
        height: 72,
        borderRadius: 3,
        ...props.memoImageStyle
      }}
    />
    <FOHSpace />
    <View style={{ flex: 2, minWidth: 140 }}>
      <FOHSmallDetailLabel>{props.postedDateText}</FOHSmallDetailLabel>
      <FOHHeaderH2 style={{ textAlign: 'left', ...props.headerStyle }}>
        {props.positionName}
      </FOHHeaderH2>
      {props.locationDetailText ? (
        <FOHLocationDetailText>
          {props.locationDetailText}
        </FOHLocationDetailText>
      ) : (
        <FOHLinkOut
          noIcon={true}
          // not sure if this is a modal or link out yet
          href={props.locationHref}
          onPress={() =>
            props.onPressLocation && props.onPressLocation(props.locationSlug)
          }
        >
          {props.locationName}
        </FOHLinkOut>
      )}
    </View>
  </FullWidthCard>
);

const FOHLocationDetailText = styled(FOHSmallDetailLabel)`
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #878787;
`;
