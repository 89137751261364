import React, { useState, useEffect } from 'react';

import { path, prop, defaultTo } from 'ramda';
import { useTranslation } from 'react-i18next';

import {
  FOHFixedHeightScrollView,
  FOHView,
  FOHSpace,
  FOHInverseButton,
  FOHColors,
  FOHPickAvatarForm,
  FOHAreYouSureModal,
  FOHFormFlowButtons,
  FOHExternalApplicationHeader
} from '../../components';
import { useIsMobile } from '../../utils';
import { envVariables } from '../../constants';
import search_charachter from '../../images/search_charachter.svg';

import { useUploadCandidateAvatar } from '../UploadFiles/useUploadCandidateAvatar';
import { usePositionTypes } from '../CandidateProfile/usePositionTypes';

// TODO: put photos onto s3
const getAvatarForHtml = index =>
  `https://${envVariables.AWS_S3_BUCKET_NAME}.s3.amazonaws.com/avatars/ava${index}.png`;

const MAX_AVATAR_COUNT = 60;
const MAX_SCREEN_WIDTH = 570;
const ExternalApplicationAvatarSelector = props => {
  const { isMobile } = useIsMobile();
  const { t } = useTranslation('ExternalApplicationFeature');
  const [avatarCount, setAvatarCount] = useState(10);
  const [defaultAvatars, setDefaultAvatars] = useState([]);
  const [areYouSureOpen, setAreYouSureOpen] = useState();
  const [areYouSurePhoto, setAreYouSurePhoto] = useState('');

  const loadMore = () => {
    if (avatarCount < MAX_AVATAR_COUNT) {
      setAvatarCount(avatarCount + 10);
    }
  };

  const {
    me,
    submitPhoto,
    photo,
    setPhoto,
    pickPhoto,
    UploadInput,
    loading
    // error
  } = useUploadCandidateAvatar();

  const { getStringForPositionType } = usePositionTypes();

  useEffect(() => {
    // Load more avatars when the list count changes
    const avatars = new Array(avatarCount).fill({}).map((_, index) => {
      const avatar = getAvatarForHtml(index + 1);
      return avatar;
    });
    setDefaultAvatars(avatars);
  }, [avatarCount]);

  return (
    <>
      <FOHView
        style={{
          width: '100%',
          alignItems: 'center'
        }}
      >
        <FOHView style={{ maxWidth: isMobile ? '100%' : 540, width: '100%' }}>
          <FOHFixedHeightScrollView isMobile={isMobile} heightToDecrease={150}>
            <FOHView
              style={{
                width: '100%',
                maxWidth: isMobile ? '100%' : MAX_SCREEN_WIDTH,
                paddingHorizontal: 20,
                paddingBottom: 20
              }}
            >
              <FOHExternalApplicationHeader
                applicationTitle={t('applicationTitle')}
                applicationDetail={t('applicationDetail')}
                // comes from router
                applicationTotalNumber={props.totalStepNumber}
                applicationNumber={props.stepIndex}
              />
            </FOHView>
            <UploadInput />
            <FOHPickAvatarForm
              pickPhoto={pickPhoto}
              photo={photo}
              defaultPhotos={defaultAvatars}
              setPhoto={newPhoto => {
                if (photo && !photo.includes('https')) {
                  setAreYouSureOpen(true);
                  setAreYouSurePhoto(newPhoto);
                } else {
                  setPhoto(newPhoto);
                }
              }}
              isMobile={isMobile}
              user={{
                name: prop('firstName', me),
                position: defaultTo(
                  [],
                  path(['candidateProfile', 'positions'], me)
                )
                  .map(pos => getStringForPositionType(pos))
                  .join(', ')
              }}
              localization={{
                title: t('profilePhotoTitle'),
                subTitle: t('profilePhotoSubTitle'),
                avatarSelectTitle: t('avatarSelectTitle'),
                loadMoreTitle: t('loadMoreTitle'),
                uploadTitle: loading ? t('common:loading') : t('common:upload')
              }}
            />
            <FOHSpace />
            {avatarCount < MAX_AVATAR_COUNT && (
              <FOHInverseButton
                textColor={FOHColors.BLACK}
                title={t('common:loadMore')}
                onPress={loadMore}
              />
            )}
            <FOHSpace />
            <FOHSpace />
          </FOHFixedHeightScrollView>
          <FOHView style={{ width: '100%', maxWidth: '100%' }}>
            <FOHFormFlowButtons
              isMobile={isMobile}
              nextButtonlabel={loading ? t('common:loading') : t('common:next')}
              onPressNextButton={async () => {
                await submitPhoto();
                props.onSubmit();
              }}
              isNextDisabled={loading || !photo}
              previousButtonLabel={t('common:previous')}
              onPressPreviousButton={props.previousStep}
            />
          </FOHView>
          {!isMobile && (
            <>
              <FOHSpace />
              <FOHSpace />
            </>
          )}
        </FOHView>
      </FOHView>
      <FOHAreYouSureModal
        open={areYouSureOpen}
        close={() => {
          setAreYouSureOpen(false);
        }}
        image={search_charachter}
        headerLabel={t('areYouSure')}
        detailLabel={t('areYouSureYouWantToRemoveYourPhoto')}
        buttonLabel={t('yesReplacePhoto')}
        confirm={() => {
          setPhoto(areYouSurePhoto);
          setAreYouSureOpen(false);
        }}
        cancel={() => {
          setAreYouSureOpen(false);
        }}
        cancelText={t('noKeepPhoto')}
        dialog={!isMobile}
        imageHeight={155}
        imageWidth={105}
      />
    </>
  );
};

export default ExternalApplicationAvatarSelector;
