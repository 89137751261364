// Only added this for more control over sendbird, exhaust other options before using.
export const removeElementsByClass = className => {
  const elements = document.getElementsByClassName(className);
  Array.from(elements).forEach(el => el.parentNode.removeChild(el));
};

export const replaceClassWith = (className, newClassName) => {
  const elements = document.getElementsByClassName(className);
  Array.from(elements).forEach(el => (el.className = newClassName));
};
