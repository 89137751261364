import React from 'react';

import { defaultTo } from 'ramda';

import blue_frame from '../../../images/blue_frame.svg';

import { FOHImage } from '../../ingredients';

export const FOHBlueFrameIcon = (props: any) => (
  <FOHImage
    source={{ uri: blue_frame }}
    style={{ width: 24, height: 27, ...defaultTo({}, props.style) }}
  />
);
