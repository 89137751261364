import React, { useState } from 'react';

import { compose } from 'ramda';
import { withTranslation } from 'react-i18next';

import { withUser } from '../../api/hocs';
import { handle, parseAndCastQueryString, withIsMobile } from '../../utils';
import { passwordResetConfirmation } from '../../api/auth';
import { passwordResetReturnScreenErrorTransform } from '../../api/transformers/login';
import { LOGIN_URL } from '../../constants';
import { FOHBannerType, FOHPasswordResetReturnScreen } from '../../components';
import { useNavigation } from '../../utils/navigation';

import { useBanner } from '../Navigation/useBanner';

const PasswordResetReturnScreen = props => {
  const { navigateTo, location: navLocation } = useNavigation();

  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [error, setError] = useState();
  const { setBanner } = useBanner();

  const { t } = props;

  return (
    <FOHPasswordResetReturnScreen
      title={t('title')}
      detailText={t('detailText')}
      submitLabel={t('nextLabel')}
      password={password}
      passwordPlaceholder={t('password')}
      setPassword={setPassword}
      passwordConfirm={passwordConfirm}
      passwordConfirmPlaceholder={t('passwordConfirm')}
      setPasswordConfirm={setPasswordConfirm}
      largeHeader={!props.isMobile}
      error={error}
      handleSubmit={async () => {
        const queryString = parseAndCastQueryString(navLocation.search);
        const data = {
          new_password1: password,
          new_password2: passwordConfirm,
          uid: queryString['uid'],
          token: queryString['token']
        };
        const [response, respError] = await handle(
          passwordResetConfirmation(data)
        );
        if (respError) {
          const err = passwordResetReturnScreenErrorTransform(respError);
          return setError(err);
        } else {
          setPassword('');
          setPasswordConfirm('');
          setBanner({
            bannerType: FOHBannerType.SUCCESS,
            bannerMessage: response.detail
          });
          setTimeout(() => navigateTo(LOGIN_URL), 3000);
          // TODO: hide the password fields and submit button
        }
      }}
      disabledSubmit={!password || !passwordConfirm}
      hidePassText={t('common:hidePassText')}
      showPassText={t('common:showPassText')}
    />
  );
};

export default compose(
  withTranslation('PasswordResetReturnFeature'),
  withUser,
  withIsMobile
)(PasswordResetReturnScreen);
