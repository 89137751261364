import React, { FunctionComponent } from 'react';

import stars from '../../../images/stars.svg';

import { FOHImage } from '../../ingredients';
import { FOHIconProps } from '../../typings';

export const FOHStarIcon: FunctionComponent<FOHIconProps> = ({ style }) => (
  <FOHImage
    source={{ uri: stars }}
    style={[{ width: 24, height: 24 }, style]}
  />
);
