import React from 'react';

import {
  FOHBlueAdd,
  FOHButton,
  FOHDivider,
  FOHEditMarker,
  FOHSpace,
  FOHSpaceSmall,
  FOHSpaceSmallest,
  FOHTextButton,
  FOHVerticalDivider,
  FOHView,
  FOHColors,
  FOHScrollView,
  FOHCompoundDropdownButtonBordered,
  FOHSmallDetailLabel
} from '..';

type voidHandler = () => void;

interface FOHRejectDropdownButtonProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  disabled?: boolean;
  tipHelper?: string;
  children?: any;
  buttonTitle: string;
  cardTitle: string;
  onButtonPress: voidHandler;
  buttonIcon: any;
  onAddNewPress?: voidHandler;
  onEditPress?: voidHandler;
  onApplyPress?: voidHandler;
  addNewLabel?: string;
  editLabel?: string;
  applyLabel?: string;
  maxHeight?: number;
}

export const FOHRejectDropdownButton = (
  props: FOHRejectDropdownButtonProps
) => {
  return (
    <FOHCompoundDropdownButtonBordered
      disabled={props.disabled}
      isToolTip={true}
      tipHelper={props.tipHelper}
      icon={props.buttonIcon}
      testID="reject-dropdown-button"
      title={props.buttonTitle}
      open={props.open}
      onPress={props.onButtonPress}
      style={{
        maxWidth: 220
      }}
      cardStyle={{
        minWidth: 366,
        maxHeight: 500,
        height: props?.maxHeight || 500,
        overflowY: 'scroll'
      }}
      toggleOpen={() => {
        props.setOpen(!props.open);
      }}
    >
      <FOHView>
        <FOHSpaceSmall />
        <FOHSmallDetailLabel style={{ paddingHorizontal: 24 }}>
          {props.cardTitle}
        </FOHSmallDetailLabel>
        <FOHSpace />
        <FOHScrollView style={{ paddingHorizontal: 8 }}>
          {props.children}
        </FOHScrollView>
        <FOHDivider />
        <FOHSpace />
        <FOHView
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingHorizontal: 24
          }}
        >
          {props.onAddNewPress && props.addNewLabel && (
            <FOHTextButton
              onPress={props.onAddNewPress}
              title={props.addNewLabel}
              style={{
                padding: 10,
                color: FOHColors.PRIMARY_TEAL_P1,
                textDecoration: 'underline',
                paddingLeft: 0,
                paddingRight: 4
              }}
              icon={() => (
                <FOHBlueAdd
                  style={{
                    height: 14,
                    width: 14,
                    tintColor: FOHColors.PRIMARY_TEAL_P1
                  }}
                />
              )}
              testID={'new-rejection-label-button'}
            />
          )}
          <FOHVerticalDivider height={32} />
          {props.onEditPress && props.editLabel && (
            <FOHTextButton
              title={props.editLabel}
              disabled={false}
              onPress={props.onEditPress}
              style={{
                padding: 10,
                color: FOHColors.GRAYSCALE_700,
                textDecoration: 'underline',
                paddingLeft: 4
              }}
              icon={() => (
                <FOHEditMarker
                  style={{
                    height: 14,
                    width: 14,
                    tintColor: FOHColors.GRAYSCALE_700
                  }}
                />
              )}
              testID={'edit-rejection-label-button'}
            />
          )}

          <FOHSpaceSmallest />

          {props.onApplyPress && props.applyLabel && (
            <FOHButton
              style={{ height: 32, width: 'auto' }}
              buttonStyle={{ paddingHorizontal: 24 }}
              title={props.applyLabel}
              color={FOHColors.GRAYSCALE_700}
              onPress={props.onApplyPress}
              testID={'reject-dropdown-reject-button'}
            />
          )}
        </FOHView>
        <FOHSpaceSmall />
      </FOHView>
    </FOHCompoundDropdownButtonBordered>
  );
};
