import React from 'react';

import { defaultTo, prop } from 'ramda';

import suggestion from '../../../images/blue_suggestion.svg';

import { FOHImage } from '../../ingredients';

export const FOHSuggestionIcon = (props: any) => (
  <FOHImage
    source={{ uri: suggestion }}
    style={{ width: 11, height: 16, ...defaultTo({}, prop('style', props)) }}
  />
);
