import { useState, useEffect } from 'react';

import { path, prop } from 'ramda';

import { handleMutation } from '../../utils';
import { useSendInterviewMutationMutation } from '../../graphql/generated';
import { FOHInterviewMeetingType } from '../../components';

import { useCandidateFeedContext } from '../CandidateFeed/useCandidateFeedContext';
import { useGetMeData } from '../SignIn';

export const useCandidateRequestInterview = () => {
  const [sendInterviewMutation] = useSendInterviewMutationMutation();

  const [positionId, selectPositionId] = useState('');

  const { data: me } = useGetMeData();

  const { myPositions } = useCandidateFeedContext();

  const createdBy = prop('id', me);

  const [sendingIR, setSendingIR] = useState(false);

  const sendInterviewRequest = async (
    {
      userId,
      onRequestInterviewSent,
      position,
      location,
      messageContent,
      interviewAttendees,
      interviewType,
      phoneNumber,
      meetingUrl
    } = {
      onRequestInterviewSent: null,
      position: null,
      location: null,
      messageContent: null
    }
  ) => {
    const positionToInterviewFor = position || positionId;
    setSendingIR(true);

    const variables = {
      candidate: userId,
      createdBy,
      employerProfile: path(['employerProfile', 'id'], me),
      position: positionToInterviewFor,
      messageContent: messageContent,
      interviewAttendees: interviewAttendees,
      interviewType:
        interviewType === FOHInterviewMeetingType.INPERSON
          ? 'inPerson'
          : interviewType === FOHInterviewMeetingType.PHONE
          ? 'phone'
          : 'remote',
      phoneNumber: phoneNumber,
      meetingUrl: meetingUrl
    };

    await handleMutation(
      sendInterviewMutation({
        variables
      })
    );

    onRequestInterviewSent && onRequestInterviewSent();
    setSendingIR(false);
  };

  useEffect(() => {
    if (!positionId) {
      selectPositionId(path([0, 'id'], myPositions));
    }
  }, [myPositions, positionId]);

  return {
    sendingIR,
    sendInterviewRequest,
    positionId,
    selectPositionId,
    myPositions
  };
};
