import React from 'react';

import { View } from 'react-native';
import styled from 'styled-components/native';
import { length } from 'ramda';

import {
  FOHInverseButton,
  FOHFavorite,
  FOHHide,
  FOHLocationTagProps,
  FOHFollowBadge,
  FOHSpaceSmall,
  FOHSpace,
  FOHBestMatchBadge,
  FOHHiringIcon,
  FOHSpaceLarger,
  FOHColors,
  FOHSmallDetailLabel,
  FOHHeaderH2,
  FOHSectionLabel
} from '../../ingredients';

import { FOHTagList } from '../tables/FOHTagList';

export interface FOHLocationHeaderProps extends FOHRestaurantLocation {
  pressFavorite?: () => any;
  pressHide?: () => any;
  favoriteLabel: string;
  hideLabel: string;
  favoritedHelpText?: string;
  hideMeHelpText?: string;
  headerFontSize?: any;
  hiringLabel?: string;
  jobMatchLabel?: string;
  vertical?: boolean;
  tiny?: boolean;
  openHiringModal?: () => any;
  disabledHiringButton?: boolean;
  hiringBtnHelpText?: string;
}

export interface FOHRestaurantLocation {
  followerCountLabel?: string;
  formattedAddress: string;
  hasJobMatch?: boolean;
  id: string;
  image?: string;
  isFavorited?: boolean;
  isHidden?: boolean;
  isHiring?: boolean;
  latitude?: number;
  longitude?: number;
  name: string;
  slug?: string;
  summary?: string;
  tags?: Array<FOHLocationTagProps>;
  hiringPositionCount?: number;
}

export const FOHLocationHeader = (props: FOHLocationHeaderProps) => {
  const tags = props.tags || [];

  return (
    <View
      style={{
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: props.vertical ? '100%' : '60%',
        paddingRight: props.vertical ? 0 : 24,
        minWidth: 300
      }}
    >
      <FOHHeaderH2
        style={{
          fontSize: props.headerFontSize || 24,
          textAlign: 'left',
          justifyContent: 'left'
        }}
      >
        {props.name}
      </FOHHeaderH2>
      <View style={{ flexDirection: 'row' }}>
        <FOHSmallDetailLabel>{props.formattedAddress}</FOHSmallDetailLabel>
      </View>
      {props.hasJobMatch && !!props.jobMatchLabel && props.vertical ? (
        <>
          <FOHSpaceSmall />
          <FOHBestMatchBadge label={props.jobMatchLabel} />
        </>
      ) : (
        <></>
      )}
      <>
        {props.followerCountLabel ? (
          <>
            <FOHSpaceSmall />
            <FOHFollowBadge label={props.followerCountLabel} />
          </>
        ) : (
          !props.vertical && <FOHSpaceLarger />
        )}
      </>
      <>
        {length(tags) > 0 ? (
          <>
            <FOHSpaceSmall />
            <FOHTagList tags={tags} />
          </>
        ) : (
          !props.vertical && <FOHSpaceLarger />
        )}
      </>
      <FOHSpace />
      <View
        style={{
          flexDirection: props.vertical ? 'column' : 'row'
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            justifyContent: props.vertical ? 'space-between' : 'flex-start'
          }}
        >
          {props.pressFavorite && (
            <FOHInverseButton
              onPress={props.pressFavorite}
              title={props.favoriteLabel}
              style={{
                maxWidth: undefined,
                width: props.vertical || !props.openHiringModal ? '49%' : 144,
                minWidth: 148,
                zIndex: 999
              }}
              icon={() => FOHFavorite({ active: true })}
              color={'#F5AD22'}
              textColor={'#000000'}
              canSelect={true}
              selected={props.isFavorited}
              enableHover={true}
              helpText={props.favoritedHelpText}
            />
          )}
          <View style={{ width: 8 }} />
          {props.pressHide && (
            <FOHInverseButton
              onPress={props.pressHide}
              title={props.hideLabel}
              textColor={props.isHidden ? '#E5253C' : '#000000'}
              color={'#E5253C'}
              selected={props.isHidden}
              style={{
                maxWidth: undefined,
                width: props.vertical || !props.openHiringModal ? '49%' : 144,
                minWidth: 148,
                zIndex: 999
              }}
              canSelect={true}
              icon={() => FOHHide({ active: props.isHidden })}
              enableHover={true}
              helpText={props.hideMeHelpText}
              testID={
                props.isHidden
                  ? 'button-hideFromMe-active'
                  : 'button-hideFromMe'
              }
            />
          )}
        </View>
        {props.openHiringModal && props.hiringLabel && (
          <>
            <View
              style={{
                width: props.vertical ? 0 : 8,
                height: props.vertical ? 8 : 0
              }}
            />
            <FOHInverseButton
              onPress={props.openHiringModal}
              title={props.hiringLabel}
              opaque={true}
              textColor={FOHColors.WHITE}
              selected={true}
              helpText={props.hiringBtnHelpText}
              disabled={props.disabledHiringButton}
              enableHover={props.disabledHiringButton}
              color={FOHColors.RED}
              style={{
                maxWidth: undefined,
                width: props.vertical ? '100%' : 144,
                minWidth: 148,
                zIndex: 999
              }}
              icon={() => (
                <FOHHiringIcon
                  style={{
                    width: 16,
                    height: 16
                  }}
                />
              )}
            />
          </>
        )}
      </View>
    </View>
  );
};

const HeaderLabel = styled(FOHSectionLabel)`
  line-height: 120%;
  padding-bottom: 12px;
  padding-top: 12px;
`;

export const FOHLocationHeaderSmall = (props: FOHLocationHeaderProps) => (
  <View
    style={{
      flexDirection: 'column',
      justifyContent: 'flex-start',
      width: props.vertical ? '100%' : '40%'
    }}
  >
    <HeaderLabel
      style={{
        fontSize: props.headerFontSize || 14,
        lineHeight: '120%',
        paddingBottom: 0
      }}
    >
      {props.name}
    </HeaderLabel>
    <View style={{ paddingTop: 4 }} />
    <View style={{ flexDirection: 'row' }}>
      <FOHSmallDetailLabel style={{ fontSize: 12 }}>
        {props.formattedAddress}
      </FOHSmallDetailLabel>
    </View>
    <View style={{ paddingTop: 4 }} />
    <View style={{ flexDirection: 'row' }}>
      {props.pressFavorite && (
        <FOHInverseButton
          onPress={props.pressFavorite}
          title={props.favoriteLabel}
          style={{
            width: 40,
            height: 40,
            zIndex: 999,
            padding: 0,
            margin: 0,
            maxHeight: 40
          }}
          icon={() => FOHFavorite({ active: true })}
          color={'#F5AD22'}
          centerIcon={true}
          textColor={'#000000'}
          canSelect={true}
          selected={props.isFavorited}
          enableHover={true}
          helpText={props.favoritedHelpText}
        />
      )}
      <View style={{ paddingLeft: 6 }} />
      {props.pressHide && (
        <FOHInverseButton
          onPress={props.pressHide}
          title={props.hideLabel}
          textColor={props.isHidden ? '#E5253C' : '#000000'}
          color={'#E5253C'}
          selected={props.isHidden}
          centerIcon={true}
          style={{
            width: 40,
            height: 40,
            maxHeight: 40,
            zIndex: 999,
            padding: 0
          }}
          canSelect={true}
          icon={() => FOHHide({ active: props.isHidden })}
          enableHover={true}
          helpText={props.hideMeHelpText}
        />
      )}
    </View>
  </View>
);
