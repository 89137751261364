import React, { Component, ErrorInfo, ReactElement } from 'react';

import * as Sentry from '@sentry/react';
import { withTranslation } from 'react-i18next';
import { compose } from 'ramda';
import { WithT } from 'i18next';

import { FOHDefaultErrorBoundary } from '../../components';

type ErrorBoundaryState =
  | {
      didCatch: true;
      error: any;
    }
  | {
      didCatch: false;
      error: null;
    };

const initialState: ErrorBoundaryState = {
  didCatch: false,
  error: null
};

interface ErrorBoundaryProps extends WithT {
  children: ReactElement<unknown, string | React.FC | typeof Component> | null;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.resetErrorBoundary = this.resetErrorBoundary.bind(this);
    this.state = initialState;
  }

  static getDerivedStateFromError(error: Error) {
    return { didCatch: true, error };
  }

  resetErrorBoundary() {
    const { error } = this.state;

    if (error !== null) {
      this.setState(initialState);
      window.location.reload();
    }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry.captureException(error, { extra: { errorInfo } });
  }

  render() {
    const { t, children } = this.props;
    const { didCatch } = this.state;

    if (didCatch) {
      return (
        <FOHDefaultErrorBoundary
          title={t('ErrorBoundary:errorLabel')}
          description={t('ErrorBoundary:errorDescription')}
          btnLabel={t('ErrorBoundary:reloadBtnLabel')}
          onPressBtn={this.resetErrorBoundary}
        />
      );
    }

    return children;
  }
}

export default compose(withTranslation('ErrorBoundary'))(ErrorBoundary);
