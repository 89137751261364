import React from 'react';

import selectedBox from '../../../images/select_box.svg';
import unselectedBox from '../../../images/unselect_box.svg';
import selectedBoxDisabled from '../../../images/select_box_disabled.svg';
import unselectedBoxDisabled from '../../../images/unselect_box_disabled.svg';

import { FOHImage } from '../containers/Containers';

interface FOHCheckboxProps {
  selected: boolean;
  style?: any;
  isDisabled?: boolean;
}

export const FOH_CHECKBOX_SIZE = 20;

export const FOHCheckbox = ({
  selected,
  isDisabled,
  style
}: FOHCheckboxProps) => {
  return (
    <FOHImage
      source={{
        uri: selected
          ? isDisabled
            ? selectedBoxDisabled
            : selectedBox
          : isDisabled
          ? unselectedBoxDisabled
          : unselectedBox
      }}
      style={[{ width: FOH_CHECKBOX_SIZE, height: FOH_CHECKBOX_SIZE }, style]}
      testID={'FOHCheckbox'}
    />
  );
};
