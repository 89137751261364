import { useEffect } from 'react';

import { prop, defaultTo, path } from 'ramda';
import { useTranslation } from 'react-i18next';

import { useCandidateFeedContext } from './useCandidateFeedContext';

export const NOTIFICATION_FILTER_TYPES = {
  INTERVIEWS: 'SCHEDULE'
};

export const useNotificationFilters = stage => {
  const { notificationStatusFilter, setNotificationStatusFilter } =
    useCandidateFeedContext();
  const stageType = prop('stageType', stage);

  const { t } = useTranslation('CandidateFeed');

  const STAGE_FILTERS_LIST = {
    // {stageType:filters[]}
    // filter{ title:string, route:notificationStatus }
    INTERVIEWS: [
      { title: t('allInterviews'), route: 'ALL', style: { flex: 0.4 } },
      { title: t('notScheduledInterviews'), route: 'TODO' },
      { title: t('pendingInterviews'), route: 'PENDING' },
      { title: t('acceptedInterviews'), route: 'DONE' }
    ]
  };

  const notificationStatuses = defaultTo(
    [],
    prop(stageType, STAGE_FILTERS_LIST)
  );

  const initialRoute = path(['0', 'route'], notificationStatuses);

  useEffect(() => {
    !notificationStatusFilter && setNotificationStatusFilter(initialRoute);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialRoute, notificationStatusFilter]);

  return {
    notificationStatuses,
    NOTIFICATION_FILTER_TYPES,
    notificationType: NOTIFICATION_FILTER_TYPES[stageType]
  };
};
