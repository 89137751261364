import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-hooks';
import { defaultTo, path, length } from 'ramda';

import {
  FOHLabelBold,
  FOHColors,
  FOHView,
  FOHScrollView,
  FOHSpace,
  FOHFilterIcon,
  FOHInverseButton,
  FOHLookingStatusIcon,
  FOHResumeIcon,
  FOHTextField,
  FOHDivider,
  FOHLabeledCheckbox,
  FOHDropDownSearch,
  FOHFormFlowButtons,
  FOHZipInput
} from '../../../components';
import { multiSelectItem } from '../../../utils';
import {
  useCandidateSearchFilterGroupsQuery,
  useSearchAheadNetworkQuery
} from '../../../graphql/generated';

import { useCandidateNetworkSearchContext } from '../useCandidateNetworkSearchHook';

const MAXWIDTH = 1340;

const ResumeIcon = () => (
  <FOHResumeIcon style={{ tintColor: FOHColors.BLUE, top: 3 }} />
);

const FilterGroupIcons = {
  candidatesThatHave: <ResumeIcon />,
  candidatesWhoAre: <FOHLookingStatusIcon style={{ top: 3 }} />
};

const Filters = () => {
  const { profileDetailFilters, addFilterItem, removeFilterItem } =
    useCandidateNetworkSearchContext();

  const filtersQuery = useCandidateSearchFilterGroupsQuery();

  const groups = defaultTo(
    [],
    path(['data', 'candidateSearchFilterGroups'], filtersQuery)
  );

  const { t } = useTranslation('CandidateNetworkSearch');

  return (
    <FOHView
      style={{ width: '100%', background: '#ffffff', alignItems: 'flex-start' }}
    >
      <FOHView
        style={{
          maxWidth: MAXWIDTH,
          width: '100%'
        }}
      >
        <FOHScrollView style={{ height: '62vh', width: '100%' }}>
          {groups.map((group, index) => {
            return (
              <FOHView
                key={`${group.name}-${index}`}
                style={{ padding: 16, justifyContent: 'flex-start' }}
              >
                <FOHLabelBold style={{ justifyContent: 'flex-start' }}>
                  {FilterGroupIcons[group.name]} {t(group.name)}
                </FOHLabelBold>
                <FOHSpace />

                <FOHView style={{ justifyContent: 'flex-start' }}>
                  {group.filterItems.map(item => {
                    return (
                      <FOHView key={item}>
                        <FOHLabeledCheckbox
                          selected={
                            profileDetailFilters
                              ? profileDetailFilters.includes(item)
                              : false
                          }
                          checkboxLabel={t(item)}
                          setSelected={() => {
                            profileDetailFilters.includes(item)
                              ? removeFilterItem(item)
                              : addFilterItem(item);
                          }}
                          style={{
                            fontSize: 14
                          }}
                        />
                        <FOHSpace />
                      </FOHView>
                    );
                  })}
                </FOHView>
              </FOHView>
            );
          })}
        </FOHScrollView>
        <FOHFormFlowButtons
          isMobile={true}
          nextButtonlabel="View Results"
          onPressNextButton={() => {}}
        />
      </FOHView>
    </FOHView>
  );
};

const BoxOverLay = props => {
  const { isFilterDrawerOpen } = props;

  return (
    <FOHView
      style={{
        backgroundColor: isFilterDrawerOpen ? 'rgba(0, 0, 0, 0.64)' : '#ffffff',
        width: '100%',
        height: isFilterDrawerOpen ? '90vh' : '100%',
        position: isFilterDrawerOpen ? 'absolute' : 'relative',
        top: 0,
        zIndex: 100,
        alignItems: 'center'
      }}
    >
      <FOHView style={{ width: '100%', backgroundColor: '#ffffff' }}>
        {props.children}
        <FOHSpace height={8} />
        <FOHDivider />
        {isFilterDrawerOpen && (
          <FOHView>
            <Filters />
          </FOHView>
        )}
      </FOHView>
    </FOHView>
  );
};

export const MobileSearchAndFilters = () => {
  const {
    searchTerms,
    zip,
    distance,
    isFilterDrawerOpen,
    setSearchTerms,
    setZip,
    setDistance,
    setIsFilterDrawerOpen
  } = useCandidateNetworkSearchContext();

  const [searchText, setSearchText] = useState();

  const { t } = useTranslation('CandidateNetworkSearch');

  const debouncedSearchText = useDebounce(searchText, 500);

  const searchAheadNetworkQuery = useSearchAheadNetworkQuery({
    variables: {
      search: debouncedSearchText
    }
  });

  const searchSections = defaultTo(
    [],
    path(['data', 'searchAheadNetwork', 'groups'], searchAheadNetworkQuery)
  ).map(section => ({
    title: section.group,
    items: section.values.map(item => ({
      label: item.label,
      value: item.value
    }))
  }));

  return (
    <BoxOverLay isFilterDrawerOpen={isFilterDrawerOpen}>
      <FOHView
        style={{
          zIndex: 1,

          padding: 10
        }}
      >
        <FOHView
          style={{
            minWidth: 300,
            width: '100%',
            marginTop: 8,
            marginRight: 8,
            zIndex: 2
          }}
        >
          <FOHDropDownSearch
            onChangeSearch={text => {
              setSearchText(text);
            }}
            closeLabel="Close"
            searchDetailLabel="Click here to confirm the keyword."
            searchValue={searchText}
            searchPlaceholder="Search"
            selectFreeTextLabel="Search for"
            truncationLimit={1}
            clearSearch={() => {
              setSearchText('');
              setSearchTerms([]);
            }}
            sections={searchSections}
            selectedItems={searchTerms || []}
            selectItem={item => {
              multiSelectItem(item, setSearchTerms, searchTerms);
              setSearchText('');
            }}
          ></FOHDropDownSearch>
        </FOHView>
        <FOHView
          style={{
            flexDirection: 'row',
            flexWrap: 'wrap',
            opacity: 1,
            maxWidth: MAXWIDTH,
            width: '100%',
            zIndex: 1
          }}
        >
          <FOHView style={{ flex: 1.2, marginTop: 8, marginRight: 8 }}>
            <FOHZipInput
              placeholder={t('common:zipCodePlaceholder')}
              onChangeText={zipCode =>
                length(zipCode) > 5 || isNaN(zipCode)
                  ? () => {}
                  : setZip(zipCode)
              }
              value={zip}
              keyboardType="numeric"
              autoComplete="postal-code"
              containerStyle={{ height: 48 }}
              style={{ height: 42, paddingTop: zip ? 18 : 5, width: '85%' }}
            />
          </FOHView>
          <FOHView style={{ flex: 1.2, marginTop: 8, marginRight: 8 }}>
            <FOHTextField
              placeholder={t('common:distance')}
              value={distance}
              onChangeText={zipCode =>
                length(distance) > 5 || isNaN(distance)
                  ? () => {}
                  : setDistance(zipCode)
              }
              keyboardType="numeric"
            />
          </FOHView>
          <FOHView
            style={{
              flex: 0.5,
              alignItems: 'center',
              marginTop: 8,
              maxWidth: 50
            }}
          >
            <FOHInverseButton
              icon={() => <FOHFilterIcon active={true} />}
              textColor={FOHColors.BLACK}
              onPress={() => {
                setIsFilterDrawerOpen(!isFilterDrawerOpen);
              }}
              style={{
                width: '100%'
              }}
              centerIcon={true}
            />
          </FOHView>
        </FOHView>
      </FOHView>
    </BoxOverLay>
  );
};
