import React, { useContext, useState } from 'react';

import { prop, path, indexOf, includes, length } from 'ramda';
import { useLocalStorage } from 'use-hooks';

import { unwrapEdgesAt } from '../../utils';
import { useMyPositionsQuery } from '../../graphql/generated';
import { FOHCandidateListSortBy } from '../../components';

import { useMyLocationsData } from '../Locations';

const CandidateFeedContext = React.createContext([{}, () => {}]);
// Location context in the top nav
export const useCandidateFeedContext = (
  { skipLocationsQuery, skipPositionsQuery } = {
    skipLocationsQuery: false,
    skipPositionsQuery: false
  }
) => {
  const {
    state,
    setState,
    candidateHandle,
    setCandidateHandle,
    movingEmployerCandidateId,
    setMovingEmployerCandidateId,
    employerCandidate,
    setEmployerCandidate,
    notificationMessage,
    setNotificationMessage
  } = useContext(CandidateFeedContext);

  const { data: myLocations } = useMyLocationsData({
    skipLocationsQuery,
    locationSearch: state.locationSearch
  });

  const myPositionsQuery = useMyPositionsQuery({
    skip: skipPositionsQuery,
    variables: {
      locations: state.locationFilters,
      search: state.positionSearch
    }
  });

  const myPositions = unwrapEdgesAt(
    ['data', 'myPositions', 'edges'],
    myPositionsQuery
  ).sort((posA, posB) => {
    //sort by position name alphabetically
    let textA = prop('positionName', posA).toUpperCase();
    let textB = prop('positionName', posB).toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });

  // actions to update state
  const multiSelectLocation = locationId => {
    const indexToRemove = indexOf(locationId, state.locationFilters);
    const selected = state.locationFilters
      ? state.locationFilters.filter(i => !!i)
      : [];

    if (!locationId) {
      return;
    }

    if (indexToRemove !== -1) {
      const newSelection = selected.filter(
        (_, index) => index !== indexToRemove
      );
      setState({
        ...state, // reset position filters
        positionFilters: [],
        locationFilters: newSelection
      });
    } else if (selected && includes(locationId, selected)) {
      const newSelection = selected.filter(item => item !== locationId);
      setState({
        ...state, // reset position filters
        positionFilters: [],
        locationFilters: newSelection
      });
    } else if (selected) {
      setState({
        ...state, // reset position filters
        positionFilters: [],
        locationFilters: [...selected, locationId]
      });
    } else {
      setState({
        ...state, // reset position filters
        positionFilters: [],
        locationFilters: [locationId]
      });
    }
  };

  const multiSelectPosition = positionId => {
    const indexToRemove = indexOf(positionId, state.positionFilters);
    const selected = state.positionFilters || [];
    if (indexToRemove !== -1) {
      const newSelection = selected.filter(
        (_, index) => index !== indexToRemove
      );
      setState({ ...state, positionFilters: newSelection });
    } else if (selected && includes(positionId, selected)) {
      const newSelection = selected.filter(item => item !== positionId);
      setState({ ...state, positionFilters: newSelection });
    } else if (selected) {
      setState({ ...state, positionFilters: [...selected, positionId] });
    } else {
      setState({ ...state, positionFilters: [positionId] });
    }
  };

  const addLocationAndPositionFilters = (locationId, positionId) => {
    setState({
      ...state,
      locationFilters: [locationId],
      positionFilters: [positionId]
    });
  };

  // select all the positions
  const selectAllPositions = () => {
    const subscribedPositions = myPositions
      .filter(
        pos =>
          path(['paymentStatus'], pos) === 'SUBSCRIBED' ||
          path(['location', 'paymentStatus'], pos) === 'SUBSCRIBED'
      )
      .map(pos => pos.id);

    if (state.positionFilters.length === subscribedPositions.length) {
      setState({
        ...state,
        positionFilters: []
      });
    } else {
      setState({
        ...state,
        positionFilters: subscribedPositions
      });
    }
  };

  const setLocationSearch = search => {
    setState({
      ...state,
      locationSearch: search
    });
  };

  const selectAllLocations = () => {
    if (state.locationFilters.length === myLocations.length) {
      setState({
        ...state, // reset position filters
        positionFilters: [],
        locationFilters: []
      });
    } else {
      setState({
        ...state, // reset position filters
        positionFilters: [],
        locationFilters: myLocations.map(loc => loc.id)
      });
    }
  };

  const setPositionSearch = search => {
    setState({ ...state, positionSearch: search });
  };

  const setActiveStageId = (stage, setHandleFlag = true) => {
    setState({
      ...state,
      notificationStatusFilter: '',
      activeStageId: stage,
      selectedEmployerCandidates: [],
      allCandidates: false,
      sortBy: FOHCandidateListSortBy.LAST_UPDATED
    });
    if (setHandleFlag) {
      setCandidateHandle(undefined);
      setEmployerCandidate(undefined);
    }
  };

  const setAllCandidates = all => {
    setState({
      ...state,
      allCandidates: all,
      selectedEmployerCandidates: []
    });
  };

  const multiSelectEmployerCandidate = _employerCandidate => {
    const indexToRemove = indexOf(
      _employerCandidate,
      state.selectedEmployerCandidates
    );
    const selected = state.selectedEmployerCandidates || [];

    if (indexToRemove !== -1) {
      const newSelection = selected.filter(
        (_, index) => index !== indexToRemove
      );
      if (length(newSelection) > 1) {
        setCandidateHandle(undefined);
        setMovingEmployerCandidateId(undefined);
        setEmployerCandidate(undefined);
      }
      setState({ ...state, selectedEmployerCandidates: newSelection });
    } else if (selected && includes(_employerCandidate, selected)) {
      const newSelection = selected.filter(item => item !== _employerCandidate);
      if (length(newSelection) > 1) {
        setCandidateHandle(undefined);
        setMovingEmployerCandidateId(undefined);
        setEmployerCandidate(undefined);
      }
      setState({ ...state, selectedEmployerCandidates: newSelection });
    } else if (selected) {
      const newSelection = [...selected, _employerCandidate];
      if (length(newSelection) > 1) {
        setCandidateHandle(undefined);
        setMovingEmployerCandidateId(undefined);
        setEmployerCandidate(undefined);
      }
      setState({
        ...state,
        selectedEmployerCandidates: newSelection
      });
    } else {
      setState({
        ...state,
        selectedEmployerCandidates: [_employerCandidate]
      });
    }
  };

  const setSelectedEmployerCandidates = selectedEmployerCandidates => {
    if (length(selectedEmployerCandidates) > 1) {
      setEmployerCandidate(undefined);
      setCandidateHandle(undefined);
      setMovingEmployerCandidateId(undefined);
    }
    setState({
      ...state,
      // just the selected candidates
      allCandidates: false,
      selectedEmployerCandidates: selectedEmployerCandidates
    });
  };

  const clearEmployerCandidates = () => {
    setState({
      ...state,
      selectedEmployerCandidates: [],
      allCandidates: false
    });
  };

  const clearLocationFilters = () => {
    setState({
      ...state,
      locationFilters: []
    });
  };

  const clearPositionFilters = () => {
    setState({
      ...state,
      positionFilters: []
    });
  };

  const setNotificationStatusFilter = status => {
    setState({
      ...state,
      notificationStatusFilter: status
    });
  };

  const setSelectedLocations = locationIds => {
    setState({
      ...state,
      locationFilters: locationIds
    });
  };

  const setSortBy = sortBy => {
    setState({
      ...state,
      sortBy
    });
  };
  // set location and position filters if not already set
  // do not use ?location=, it was making the state for this hook confusing.
  // whatever the user has last selected during their session will be the location they see from local storage
  // persists off of reload
  // useEffect(() => {
  //   if (
  //     length(state.locationFilters) === 0 &&
  //     !myLocationsQuery.loading &&
  //     path([0, 'id'], myLocations)
  //   ) {
  //     setState({
  //       ...state,
  //       locationFilters: [path([0, 'id'], myLocations)]
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [
  //   myLocations,
  //   myPositions,
  //   myPositionsQuery.loading,
  //   myLocationsQuery.loading
  // ]);

  return {
    ...state,
    candidateHandle,
    setCandidateHandle,
    movingEmployerCandidateId,
    setMovingEmployerCandidateId,
    employerCandidate,
    setEmployerCandidate,
    notificationMessage,
    setNotificationMessage,
    setLocationSearch,
    setPositionSearch,
    multiSelectLocation,
    selectAllLocations,
    multiSelectPosition,
    selectAllPositions,
    setActiveStageId,
    setAllCandidates,
    multiSelectEmployerCandidate,
    setSelectedEmployerCandidates,
    clearEmployerCandidates,
    clearPositionFilters,
    clearLocationFilters,
    setNotificationStatusFilter,
    myLocations,
    myPositions,
    myPositionsQuery,
    setSelectedLocations,
    setSortBy,
    addLocationAndPositionFilters
  };
};

// todo write a FE test for this once functionality is settled
export const CandidateFeedContextProvider = props => {
  // default state for store
  // this context is for IDs that are important to the Candidate Inbox Feed filtering
  // If there is an important ID for filtering, add it to this state so we can avoid confusing prop drilling
  // do not add graphql queries to this hook or context, graphql queries have their own context via Apollo Provider
  const [state, setState] = useLocalStorage('candidate-feed-state', {
    locationFilters: [],
    positionFilters: [],
    positionSearch: '',
    locationSearch: '',
    activeStageId: '',
    allCandidates: false,
    selectedEmployerCandidates: [],
    notificationStatusFilter: '',
    sortBy: FOHCandidateListSortBy.LAST_UPDATED
  });

  // context state outside of local storage
  const [candidateHandle, setCandidateHandle] = useState(undefined);
  const [employerCandidate, setEmployerCandidate] = useState(undefined);
  const [movingEmployerCandidateId, setMovingEmployerCandidateId] =
    useState(undefined);
  const [notificationMessage, setNotificationMessage] = useState('');

  return (
    <CandidateFeedContext.Provider
      value={{
        state,
        setState,
        candidateHandle,
        setCandidateHandle,
        movingEmployerCandidateId,
        setMovingEmployerCandidateId,
        employerCandidate,
        setEmployerCandidate,
        notificationMessage,
        setNotificationMessage
      }}
    >
      {props.children}
    </CandidateFeedContext.Provider>
  );
};
