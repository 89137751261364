import React from 'react';

import { FOHLocationBlockProps, FOHLocationBlock } from '../../recipes';
import {
  Screen,
  FOHLabelBold,
  FOHXIcon,
  FOHView,
  FOHTouchableOpacity,
  FOHColors
} from '../../ingredients';

export interface FOHLocationDetailScreenProps extends FOHLocationBlockProps {}

export const FOHLocationDetailScreen = (
  props: FOHLocationDetailScreenProps
) => (
  <Screen
    style={{
      backgroundColor: FOHColors.BACKGROUND_GRAY,
      height: '100%',
      minHeight: '100vh'
    }}
  >
    <FOHView
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        paddingTop: props.vertical ? 50 : 60,
        backgroundColor:
          props.modal || props.vertical
            ? FOHColors.WHITE
            : FOHColors.BACKGROUND_GRAY
      }}
    >
      {props.vertical && (
        <FOHView
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            height: 56,
            flexDirection: 'row',
            backgroundColor: FOHColors.WHITE,
            width: '100%'
          }}
        >
          <FOHView style={{ position: 'absolute', right: 16 }}>
            <FOHTouchableOpacity onPress={props.navigateBack}>
              <FOHXIcon large={true} />
            </FOHTouchableOpacity>
          </FOHView>
          <FOHLabelBold>{props.name}</FOHLabelBold>
        </FOHView>
      )}
      <FOHLocationBlock
        navigateBack={props.navigateBack}
        locationLabel={props.locationLabel}
        backText={props.backText}
        hourlyRatesTitle={props.hourlyRatesTitle}
        positionRates={props.positionRates}
        id={props.id}
        name={props.name}
        formattedAddress={props.formattedAddress}
        tags={props.tags}
        favoriteLabel={props.favoriteLabel}
        hideLabel={props.hideLabel}
        isFavorited={props.isFavorited}
        isHidden={props.isHidden}
        pressHide={props.pressHide}
        pressFavorite={props.pressFavorite}
        answersLabel={props.answersLabel}
        answers={props.answers}
        summaryTitle={props.summaryTitle}
        image={props.image}
        summary={props.summary}
        claimBusinessLabel={props.claimBusinessLabel}
        claimBusiness={props.claimBusiness}
        vertical={props.vertical}
        followerCountLabel={props.followerCountLabel}
        jobs={props.jobs}
        jobSectionTitle={props.jobSectionTitle}
        onJobPress={props.onJobPress}
        a={props.a}
        jobCTAText={props.jobCTAText}
      >
        {props.children}
      </FOHLocationBlock>
    </FOHView>
  </Screen>
);
