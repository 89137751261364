import React from 'react';

import { useTranslation } from 'react-i18next';

import { useIsMobile } from '../../../utils';

import {
  FOHButton,
  FOHColors,
  FOHLabel,
  FOHSmallDetailLabel,
  FOHSpace,
  FOHSpaceLarge,
  FOHView
} from '../../ingredients';

import { FOHForm } from './FOHForm';

export interface FOHResetPasswordFormProps {
  headerText: string;
  descriptionText: string;
  handleSubmit: () => void;
  submitLabel: string;
  disabledSubmit?: boolean;
}

export const FOHResetPasswordForm = (props: FOHResetPasswordFormProps) => {
  const { isMobile } = useIsMobile();
  const { t } = useTranslation('PasswordResetFeature');

  return (
    <FOHForm
      handleSubmit={props.handleSubmit}
      disabledSubmit={props.disabledSubmit}
    >
      <FOHView
        style={{
          paddingHorizontal: isMobile ? 18 : 32,
          paddingVertical: 18,
          backgroundColor: FOHColors.WHITE,
          borderWidth: 1,
          borderColor: FOHColors.GRAYSCALE_200,
          borderRadius: 4,
          width: isMobile ? '100%' : 478
        }}
      >
        <FOHLabel
          style={{
            color: FOHColors.GRAYSCALE_900,
            fontSize: 18,
            lineHeight: 28
          }}
          heavy
        >
          {t('title')}
        </FOHLabel>
        <FOHSpace />
        <FOHSmallDetailLabel>
          {t('resetPasswordDetailText')}
        </FOHSmallDetailLabel>
        <FOHSpaceLarge />
        <FOHView style={{ alignItems: 'center' }}>
          <FOHButton
            onPress={props.handleSubmit}
            title={props.submitLabel}
            disabled={props.disabledSubmit}
            style={{
              maxWidth: 168,
              width: '100%'
            }}
          />
        </FOHView>
      </FOHView>
    </FOHForm>
  );
};
