import React, { FunctionComponent, Dispatch, SetStateAction } from 'react';

import {
  ScrollView,
  StyleProp,
  TouchableOpacity,
  ViewStyle
} from 'react-native';
import styled from 'styled-components/native';
import { useTheme } from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { compose, defaultTo } from 'ramda';

import { FOHSearchBar } from '../../recipes';
import {
  FOHCheckbox,
  FOHCircleLoader,
  FOHColors,
  FOHCrownIcon,
  FOHDivider,
  FOHExpandIcon,
  FOHFonts,
  FOHLabel,
  FOHLabelMedium,
  FOHLabelSmall,
  FOHSmallDetailLabel,
  FOHSpaceSmallest,
  FOHTag,
  FOHView,
  FullWidthCard,
  SPACING_SCALE,
  withError
} from '../../ingredients';
import { useClickOutside, useIsMobile } from '../../spices';
import {
  FOH_INPUT_HEIGHT,
  OPTION_LIST_PADDING,
  OPTION_MARGIN_BOT,
  OPTION_MIN_HEIGHT
} from '../../constants';
import { FOHIconProps } from '../../typings';

import { FOHDropdownSelectOption } from './FOHBorderlessSelectDropdown';
export interface UpgradeDropdownSelectOptions extends FOHDropdownSelectOption {
  upgrade?: boolean;
  isPaused?: boolean;
  isUserRestricted?: boolean;
}

export interface FOHSearchableSelectDropdownProps {
  backgroundColor?: string;
  fieldLabel?: string;
  icon?: FunctionComponent<FOHIconProps>;
  isSelectAllSelected?: boolean;
  multiSelect?: boolean;
  onPressSelectAll?: () => void;
  onPressUpgrade?: (option: UpgradeDropdownSelectOptions) => void;
  onSearchChange?: (text: string) => void;
  onValueChange: (value: any) => void;
  open: boolean;
  options: UpgradeDropdownSelectOptions[];
  optionsContainerStyle?: any;
  searchPlaceholder?: string;
  searchValue?: string;
  selectAllLabel?: string;
  selectedLabel?: string;
  selectedValue: string | string[];
  setOpen: Dispatch<SetStateAction<boolean>>;
  showLabel?: string;
  style?: StyleProp<ViewStyle>;
  testID?: string;
  upgradeLabel?: string;
  disabled?: boolean;
  error?: any;
  isLoading?: boolean;
  pausedLabel?: string;
  children?: React.ReactNode;
  maxHeight?: number | string;
  showSecondaryText?: boolean;
}

interface OptionsContainerProps {
  isMobile: boolean;
}

const SelectContainerStyle = styled(TouchableOpacity)`
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: ${FOHColors.WHITE};
  border-radius: 4px;
`;

const OptionsContainer = styled(FullWidthCard)<OptionsContainerProps>`
  position: absolute;
  left: 2;
  width: 100%;
  background-color: ${FOHColors.WHITE};
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.15);
  width: 100%;
  min-width: ${(props: OptionsContainerProps) =>
    props.isMobile ? '294px' : '400px'};
  max-width: 500px;
`;
const SelectContainer = compose(withError)(SelectContainerStyle);

const AnimatedArrowView = animated(FOHView);

const computeLabel = (
  selectedValue: FOHSearchableSelectDropdownProps['selectedValue'],
  options: FOHSearchableSelectDropdownProps['options'],
  returnKeyName: string = 'label'
): string | undefined => {
  const selectedValueLabel = Array.isArray(selectedValue)
    ? selectedValue[0]
    : selectedValue;

  const option = options.find(_option => _option.value === selectedValueLabel);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return option?.[returnKeyName] || option?.label;
};

const DropdownArrow: FunctionComponent<{
  open: boolean;
  error: any;
  disabled: boolean;
  style?: any;
}> = ({ disabled, error, open, style }) => {
  const animatedProps = useSpring({
    from: { xyz: [0, 0, 0] },
    to: async (next: any) => {
      await next({ xyz: open ? [0, 180, 0] : [0, 0, 0] });
    },
    config: { duration: 100 }
  }) as any;

  return (
    <FOHView
      pointerEvents="none"
      style={{
        left: -2,
        ...defaultTo({}, style)
      }}
    >
      <AnimatedArrowView
        style={{
          transform: animatedProps?.xyz?.to(
            (_x: number, y: number, _z: number) => `rotate(${y}deg)`
          )
        }}
      >
        <FOHExpandIcon
          style={{
            tintColor: error
              ? FOHColors.RED_300
              : disabled
              ? FOHColors.GRAYSCALE_400
              : FOHColors.GRAYSCALE_500
          }}
        />
      </AnimatedArrowView>
    </FOHView>
  );
};

const LocationBadge: FunctionComponent<{
  icon: FOHSearchableSelectDropdownProps['icon'];
  fieldLabel: FOHSearchableSelectDropdownProps['fieldLabel'];
  disabled: FOHSearchableSelectDropdownProps['disabled'];
  error: FOHSearchableSelectDropdownProps['error'];
}> = ({ disabled, error, fieldLabel, icon: Icon }) => {
  const theme = useTheme();

  if (!Icon) return null;

  return (
    <FOHView
      style={{
        alignItems: 'center',
        flexDirection: 'row'
      }}
    >
      <Icon
        style={{
          tintColor: error
            ? FOHColors.RED_300
            : disabled
            ? FOHColors.GRAYSCALE_400
            : theme?.brandColor || FOHColors.PRIMARY_TEAL_P1
        }}
      />
      <FOHLabelSmall
        style={{
          color: error
            ? FOHColors.RED_300
            : disabled
            ? FOHColors.GRAYSCALE_400
            : theme?.brandColor || FOHColors.PRIMARY_TEAL_P1,
          fontFamily: FOHFonts.REGULAR,
          fontSize: 12,
          fontWeight: 600,
          lineHeight: 12,
          paddingLeft: 3
        }}
      >
        {fieldLabel}
      </FOHLabelSmall>
    </FOHView>
  );
};

export const UpgradeBadge: FunctionComponent<{
  option: UpgradeDropdownSelectOptions;
  onPressUpgrade: FOHSearchableSelectDropdownProps['onPressUpgrade'];
  upgradeLabel: FOHSearchableSelectDropdownProps['upgradeLabel'];
}> = ({ onPressUpgrade = () => null, option, upgradeLabel }) => (
  <TouchableOpacity
    onPress={() => onPressUpgrade({ ...option })}
    style={{
      alignItems: 'center',
      alignSelf: 'center',
      backgroundColor: FOHColors.GRAYSCALE_700,
      borderRadius: 4,
      flexDirection: 'row',
      height: 'fit-content',
      justifyContent: 'center',
      margin: 5,
      paddingHorizontal: 16,
      paddingVertical: 8
    }}
    testID={`upgrade-${option.label}`}
  >
    <FOHCrownIcon
      style={{ height: 14, tintColor: FOHColors.WHITE, width: 16 }}
    />
    <FOHLabel
      style={{
        color: FOHColors.WHITE,
        fontSize: 12,
        paddingLeft: 4
      }}
    >
      {upgradeLabel}
    </FOHLabel>
  </TouchableOpacity>
);

export const FOHSearchableSelectDropdown: FunctionComponent<
  FOHSearchableSelectDropdownProps
> = ({
  backgroundColor,
  children,
  fieldLabel,
  icon,
  isSelectAllSelected = false,
  multiSelect,
  onPressSelectAll,
  onPressUpgrade,
  onSearchChange,
  onValueChange,
  open,
  options,
  optionsContainerStyle = {},
  searchPlaceholder,
  searchValue,
  selectAllLabel,
  selectedLabel = '',
  selectedValue,
  setOpen,
  showLabel,
  style,
  testID,
  upgradeLabel,
  disabled = false,
  isLoading,
  error,
  pausedLabel,
  maxHeight,
  showSecondaryText = false
}) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const computedLabel = defaultTo(
    selectedLabel,
    computeLabel(selectedValue, options)
  );

  const selectedSubLabel = defaultTo(
    '',
    computeLabel(selectedValue, options, 'sublabel')
  );

  const hasPlaceholder = !!(icon && fieldLabel) || false;

  const [ref] = useClickOutside({
    close: () => {
      if (open) {
        setOpen(false);
      }
    }
  });

  return (
    <FOHView
      ref={ref}
      style={[
        { width: 294 },
        isLoading && { alignItems: 'center', justifyContent: 'center' },
        style
      ]}
    >
      <SelectContainer
        //@ts-ignore disabled
        disabled={disabled}
        error={error}
        onPress={(e: any) => {
          e.preventDefault();
          if (!disabled) setOpen(!open);
        }}
        style={{
          backgroundColor: isLoading ? FOHColors.GRAYSCALE_100 : 'transparent',
          borderColor: open ? FOHColors.LIGHT_BLUE : 'transparent',
          borderRadius: 4,
          borderWidth: open ? 2 : 1,
          height: hasPlaceholder ? FOH_INPUT_HEIGHT : maxHeight ? maxHeight : 40
        }}
        testID={testID}
      >
        <FOHView
          style={{
            backgroundColor: disabled
              ? FOHColors.GRAYSCALE_100
              : backgroundColor || 'transparent',
            borderColor: error
              ? FOHColors.RED_300
              : disabled
              ? FOHColors.GRAYSCALE_200
              : open
              ? FOHColors.PRIMARY_TEAL_P1
              : FOHColors.GRAYSCALE_300,
            borderRadius: 4,
            borderWidth: 1,
            flexDirection: 'row',
            height: hasPlaceholder
              ? FOH_INPUT_HEIGHT
              : maxHeight
              ? maxHeight
              : 40,
            justifyContent: 'space-between',
            padding: 6,
            alignItems: 'center',
            width: '100%'
          }}
        >
          <FOHView style={{ flex: 1 }}>
            {icon ? (
              <>
                <LocationBadge
                  disabled={disabled}
                  error={error}
                  fieldLabel={fieldLabel}
                  icon={icon}
                />
                <FOHSpaceSmallest />
              </>
            ) : (
              <></>
            )}
            {multiSelect && selectedValue && selectedValue.length > 1 ? (
              <FOHView style={{ flexDirection: 'row', top: -2 }}>
                <FOHLabelMedium
                  style={{
                    color: disabled
                      ? FOHColors.GRAYSCALE_400
                      : FOHColors.GRAYSCALE_900,
                    fontSize: 16,
                    fontWeight: '400',
                    lineHeight: 18
                  }}
                >
                  {showLabel}
                  <FOHTag
                    label={selectedLabel}
                    style={{
                      backgroundColor: disabled
                        ? FOHColors.GRAYSCALE_400
                        : theme?.brandColor || FOHColors.PRIMARY_TEAL_10,
                      color: disabled
                        ? FOHColors.GRAYSCALE_600
                        : FOHColors.PRIMARY_TEAL_300,
                      height: 12,
                      marginLeft: 2,
                      marginTop: SPACING_SCALE.smallest
                    }}
                  />
                </FOHLabelMedium>
              </FOHView>
            ) : (
              <FOHLabel
                style={{
                  color: disabled ? FOHColors.GRAYSCALE_400 : '',
                  fontSize: 16,
                  lineHeight: 18,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '98%',
                  marginLeft: 3
                }}
              >
                {computedLabel}
                {!!showSecondaryText && selectedSubLabel ? (
                  <FOHSmallDetailLabel
                    style={{
                      color: FOHColors.GRAYSCALE_500,
                      fontSize: 12,
                      lineHeight: 18
                    }}
                  >
                    {` ${selectedSubLabel}`}
                  </FOHSmallDetailLabel>
                ) : (
                  <></>
                )}
              </FOHLabel>
            )}
          </FOHView>

          <DropdownArrow
            disabled={disabled || false}
            error={null}
            open={open}
            style={{
              top: hasPlaceholder ? 5 : 0
            }}
          />
        </FOHView>
      </SelectContainer>
      {isLoading ? (
        <FOHView
          style={[
            {
              backgroundColor: isLoading
                ? FOHColors.GRAYSCALE_100
                : 'transparent',
              position: 'absolute',
              top: 8
            }
          ]}
        >
          <FOHView>
            <FOHCircleLoader />
          </FOHView>
        </FOHView>
      ) : (
        <></>
      )}

      {open ? (
        <OptionsContainer
          isMobile={isMobile}
          shadow={true}
          style={{
            top: hasPlaceholder ? FOH_INPUT_HEIGHT + 4 : FOH_INPUT_HEIGHT - 15,
            ...defaultTo({}, optionsContainerStyle)
          }}
        >
          {onSearchChange ? (
            <FOHView style={{ padding: 10 }}>
              <FOHSearchBar
                inputStyle={{
                  height: 'fit-content',
                  lineHeight: 1,
                  paddingHorizontal: 10,
                  paddingVertical: 0
                }}
                onChangeSearch={(text: string) => onSearchChange!(text)}
                searchPlaceholder={searchPlaceholder!}
                searchValue={searchValue!}
                style={{
                  height: 'auto'
                }}
              />
            </FOHView>
          ) : (
            <></>
          )}

          {onPressSelectAll ? (
            <>
              <FOHView
                style={{
                  alignItems: 'center',
                  flexDirection: 'row',
                  paddingBottom: OPTION_LIST_PADDING - 6,
                  paddingHorizontal: OPTION_LIST_PADDING,
                  paddingTop: 6
                }}
              >
                <TouchableOpacity
                  onPress={e => {
                    e.preventDefault();
                    onPressSelectAll();
                  }}
                  style={{ alignItems: 'center', flexDirection: 'row' }}
                >
                  <FOHCheckbox selected={isSelectAllSelected} />
                  <FOHSmallDetailLabel
                    style={{
                      color: FOHColors.GRAYSCALE_700,
                      fontSize: 16,
                      lineHeight: 24,
                      marginLeft: SPACING_SCALE.regular
                    }}
                  >
                    {selectAllLabel}
                  </FOHSmallDetailLabel>
                </TouchableOpacity>
              </FOHView>

              <FOHView style={{ paddingHorizontal: 20 }}>
                <FOHDivider />
              </FOHView>
            </>
          ) : (
            <></>
          )}

          <ScrollView
            style={{
              maxHeight:
                OPTION_LIST_PADDING * 2 +
                OPTION_MIN_HEIGHT * 3 +
                OPTION_MARGIN_BOT * 3,
              paddingHorizontal: 12,
              paddingTop: OPTION_LIST_PADDING - 8,
              paddingBottom: 12
            }}
          >
            {options.map((option, index) => (
              <TouchableOpacity
                disabled={
                  option?.isUserRestricted &&
                  (option?.upgrade || option?.isPaused)
                }
                key={`option-${option.value}_${index + 1}`}
                onPress={() => {
                  option?.upgrade && onPressUpgrade
                    ? onPressUpgrade({ ...option })
                    : onValueChange(option.value);

                  if (!multiSelect) setOpen(false);
                }}
                style={{
                  backgroundColor:
                    selectedValue === option.value && !option?.upgrade
                      ? FOHColors.GRAYSCALE_10
                      : '',
                  borderRadius: 6,
                  justifyContent: 'center',
                  // marginBottom: OPTION_MARGIN_BOT,
                  minHeight: OPTION_MIN_HEIGHT,
                  minWidth: 100,
                  padding: 12
                }}
                testID={`list-option-${option.label}`}
              >
                <FOHView
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                  }}
                >
                  <FOHView
                    style={{
                      alignItems: 'center',
                      flexDirection: 'row',
                      width: option?.upgrade || option?.isPaused ? '65%' : '90%'
                    }}
                  >
                    {multiSelect ? (
                      <FOHCheckbox
                        isDisabled={
                          option?.upgrade ||
                          (option?.isUserRestricted && option?.isPaused)
                        }
                        selected={
                          selectedValue?.includes(option.value) &&
                          !option?.upgrade
                        }
                        style={{ marginRight: SPACING_SCALE.regular }}
                      />
                    ) : (
                      <></>
                    )}

                    {!multiSelect && selectedValue === option.value ? (
                      <FOHCheckbox
                        isDisabled={
                          option?.upgrade ||
                          (option?.isUserRestricted && option?.isPaused)
                        }
                        selected={true}
                        style={{ marginRight: SPACING_SCALE.regular }}
                      />
                    ) : (
                      <></>
                    )}
                    <FOHView
                      style={{
                        justifyContent: 'center',
                        maxWidth:
                          option?.upgrade || option?.isPaused ? '85%' : '100%'
                      }}
                    >
                      <FOHLabel
                        style={{
                          color:
                            option?.upgrade ||
                            (option?.isUserRestricted && option?.isPaused)
                              ? FOHColors.GRAYSCALE_400
                              : FOHColors.GRAYSCALE_900,
                          lineHeight: 22,
                          maxWidth:
                            option?.upgrade || option?.isPaused ? 240 : 300,
                          minHeight: 24
                        }}
                      >
                        {option.label}
                      </FOHLabel>
                      {option?.sublabel ? (
                        <FOHSmallDetailLabel
                          numberOfLines={1}
                          style={{
                            color:
                              option?.upgrade ||
                              (option?.isUserRestricted && option?.isPaused)
                                ? FOHColors.GRAYSCALE_400
                                : FOHColors.GRAYSCALE_600
                          }}
                        >
                          {option?.sublabel}
                        </FOHSmallDetailLabel>
                      ) : (
                        <></>
                      )}
                    </FOHView>
                  </FOHView>

                  {option?.upgrade ? (
                    <UpgradeBadge
                      onPressUpgrade={onPressUpgrade}
                      option={option}
                      upgradeLabel={upgradeLabel}
                    />
                  ) : (
                    <></>
                  )}

                  {option?.isPaused ? (
                    <FOHView
                      style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: 5,
                        paddingHorizontal: 16,
                        paddingVertical: 8
                      }}
                    >
                      <FOHLabel
                        style={{
                          color: FOHColors.YELLOW_500,
                          fontSize: 16,
                          fontWeight: '600',
                          textTransform: 'uppercase'
                        }}
                      >
                        {pausedLabel}
                      </FOHLabel>
                    </FOHView>
                  ) : (
                    <></>
                  )}
                </FOHView>
              </TouchableOpacity>
            ))}
          </ScrollView>
          {children ? (
            <>
              <FOHDivider />
              <FOHView style={{ padding: 24 }}>{children}</FOHView>
            </>
          ) : (
            <></>
          )}
        </OptionsContainer>
      ) : (
        <></>
      )}
    </FOHView>
  );
};
