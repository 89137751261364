import React from 'react';

import red_x from '../../../images/red_x.svg';

import { FOHImage } from '../../ingredients';

export const FOHRedX = (props: any) => (
  <FOHImage
    source={{ uri: red_x }}
    style={{ width: 14, height: 14 }}
    {...props}
  />
);
