import { CURRENT_USER_URL } from '../constants/urls';

import { get, patch } from './index';

export const getCurrentUser = () => {
  return get(CURRENT_USER_URL);
};

export const updateCurrentUser = data => {
  return patch(CURRENT_USER_URL, data);
};

export const setUserPreferredLanguage = lang => {
  return patch(CURRENT_USER_URL, { preferred_language: lang });
};
