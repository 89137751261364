import { useState, useEffect, useMemo } from 'react';

const defaultSettings = {
  enableHighAccuracy: false,
  timeout: Infinity,
  maximumAge: 0
};

export const useLatLng = (watch = false, userSettings = {}) => {
  const settings = useMemo(
    () => ({
      ...defaultSettings,
      ...userSettings
    }),
    []
  );

  const [position, setPosition] = useState({
    latitude: null,
    longitude: null,
    accuracy: null,
    speed: null,
    heading: null,
    timestamp: null
  });
  const [error, setError] = useState(null);

  const onChange = ({ coords, timestamp }) => {
    setPosition({
      latitude: coords.latitude,
      longitude: coords.longitude,
      accuracy: coords.accuracy,
      speed: coords.speed,
      heading: coords.heading,
      timestamp
    });
  };

  const onError = err => {
    setError(err?.message);
    console.error(err?.message);
  };

  useEffect(() => {
    if (!navigator || !navigator.geolocation) {
      setError('Geolocation is not supported by your browser.');
      return;
    }

    if (watch) {
      const watcher = navigator.geolocation.watchPosition(
        onChange,
        onError,
        settings
      );

      return () => navigator.geolocation.clearWatch(watcher);
    }

    navigator.geolocation.getCurrentPosition(onChange, onError, settings);
  }, [settings.enableHighAccuracy, settings.timeout, settings.maximumAge]);

  return { ...position, error };
};
