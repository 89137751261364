import React, { useState, useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { path, defaultTo, prop } from 'ramda';
import { useParams } from 'react-router-dom';

import {
  useIsMobile,
  unwrapEdgesAt,
  toDateTime,
  isScrollCloseToBottom,
  useDisableBackgroundScroll,
  handleMutation
} from '../../utils';
import { CANDIDATE_PROFILE, FEED, getAppBaseUrl } from '../../constants';
import { fetchMore } from '../../utils/fetchMore';
import {
  useGetDashboardMatchesQuery,
  useRequestInterviewsMutation
} from '../../graphql/generated';
import { useNavigation } from '../../utils/navigation';
import { FOHApplicantsModal } from '../../components';

import { useRequestInterview } from '../InterviewRequest/useRequestInterview';
import { usePaymentPlan } from '../Billing/usePaymentPlan';
import { useCurrentLocationContext } from '../Locations/useCurrentLocationContext';
import { useDirectMessage } from '../Chat/useDirectMessage';
import { useSidebarNav } from '../Navigation/useSidebar';
import { usePositionTypes } from '../CandidateProfile/usePositionTypes';
import { useExportCsv } from '../CandidateFeed/useExportCsv';
import { useGetMeData } from '../SignIn';

const formatNewApplicants = (matches, t, getStringForPositionType) =>
  matches.map(match => {
    return {
      profile: {
        image: path(['candidateProfile', 'user', 'profileImage'], match),
        name: path(['candidateProfile', 'user', 'firstName'], match),
        position: defaultTo([], path(['candidateProfile', 'positions'], match))
          .map(position => getStringForPositionType(position))
          .join(', '),
        summary: path(['candidateProfile', 'personalSummary'], match),
        slug: path(['candidateProfile', 'id'], match),
        handle: path(['candidateProfile', 'handle'], match)
      },
      status: {
        status: prop('candidateStatus', match),
        statusText: t(
          `common:candidateStatusType.${prop('candidateStatus', match)}`
        )
      },
      isRequestSent:
        !!prop(['lastInterview', 'status'], match) ||
        prop('stage', match) === 'pending'
    };
  });

export const ApplicantsModal = props => {
  const { t } = useTranslation('EmployerDashboardFeature');
  const { navigateTo } = useNavigation();
  const params = useParams();
  const { positionId, modal } = params;
  const initialVariables = {
    position: positionId,
    // ID is required type for positionById
    id: positionId,
    first: 50,
    after: '',
    // show latest if new
    sortBy:
      modal === 'new_matches' || modal === 'new_applicants' ? 'latest' : 'best'
  };

  const extraParams =
    modal === 'new_matches' || modal === 'matches'
      ? {
          newMatches: modal === 'new_matches'
        }
      : {
          applied: true,
          new_applications: modal === 'new_applicants'
        };

  const { getStringForPositionType } = usePositionTypes();

  const variables = {
    ...initialVariables,
    ...extraParams
  };

  const dashboardMatchesQuery = useGetDashboardMatchesQuery({
    skip: !positionId || positionId === 'all',
    variables
  });
  const { data } = dashboardMatchesQuery;

  const matches = unwrapEdgesAt(['matches', 'edges'], data);

  const { isMobile } = useIsMobile();

  const [candidateId, setCandidateId] = useState(undefined);
  const [loadingMore, setLoadingMore] = useState(false);
  const [requestingInterviews, setRequestingInterviews] = useState(false);
  const applicants = formatNewApplicants(matches, t, getStringForPositionType);

  const { data: me } = useGetMeData();

  const hasInterviewSchedule = useMemo(() => prop('hasSchedule', me), [me]);

  const profile = prop(
    'candidateProfile',
    matches.find(
      match => path(['candidateProfile', 'id'], match) === candidateId
    )
  );

  const { locationFilter, currentLocation } = useCurrentLocationContext();

  const { canChangeHiringFreely } = usePaymentPlan({
    locationFilter,
    currentPlanQ: true
  });

  const { sendInterviewRequest, selectPosition } = useRequestInterview(
    props,
    undefined,
    profile,
    canChangeHiringFreely
  );

  useEffect(() => {
    selectPosition(positionId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionId]);

  const { goToDirectMessageWith } = useDirectMessage({
    ...props,
    sendbirdAccessToken: prop('sendbirdAccessToken', me)
  });

  const getLabelByModal = _modal => {
    switch (_modal) {
      case 'new_matches':
        return 'newMatches';
      case 'matches':
        return 'matches';
      case 'new_applicants':
        return 'newApplicants';
      case 'applicants':
        return 'applicants';
      default:
        return '';
    }
  };

  useEffect(() => {
    // This is to be async and send the interviewRequest only after setting candidateId
    if (candidateId && profile) {
      sendInterviewRequest({
        onComplete: () => {
          setCandidateId(undefined);

          props.onRequestInterview();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateId, profile]);

  const goToDirectMessage = async id => {
    const uuid = path(
      ['candidateProfile', 'user', 'uuid'],
      matches.find(match => path(['candidateProfile', 'id'], match) === id)
    );
    await goToDirectMessageWith({
      uuids: [uuid],
      location: locationFilter,
      position: positionId
    });
  };

  const handleRequestInterview = id => {
    setCandidateId(id);
  };

  const { generateAndDownloadCsv } = useExportCsv({
    configs: {
      filename: `all_${defaultTo(
        '',
        path([0, 'application', 'position', 'positionName'], matches)
      )}_${getLabelByModal(modal)}`,
      title: `${
        getLabelByModal(modal).charAt(0).toUpperCase() +
        getLabelByModal(modal).slice(1)
      }\n\n`
    }
  });

  const handleExportCsv = () => {
    // only add these fields if they have applied

    const _data = matches.map(match => {
      const appliedObj = path(['application', 'createdAt'], match)
        ? {
            applied_to: path(
              ['application', 'position', 'positionName'],
              match
            ),
            applied_on: toDateTime(
              defaultTo('', path(['application', 'createdAt'], match))
            )
          }
        : {};
      return {
        // export snake case for csv
        name: `${path(['candidateProfile', 'user', 'firstName'], match)} ${path(
          ['candidateProfile', 'user', 'lastName'],
          match
        )}`,
        ...appliedObj,
        location: path(['name'], currentLocation),
        location_address: path(['formattedAddress'], currentLocation),
        email: path(['candidateProfile', 'user', 'email'], match),
        phone: path(['candidateProfile', 'user', 'phone'], match),
        profile_url: `${getAppBaseUrl()}${CANDIDATE_PROFILE}/${path(
          ['candidateProfile', 'handle'],
          match
        )}`,
        candidate_position_types: defaultTo(
          [],
          path(['candidateProfile', 'positions'], match)
        )
          .map(position => getStringForPositionType(position))
          .join(', '),
        summary: path(['candidateProfile', 'personalSummary'], match)
      };
    });
    generateAndDownloadCsv(_data);
  };

  const [requestInterviewsMutation] = useRequestInterviewsMutation();
  const handleRequestAll = async () => {
    if (!requestingInterviews) {
      setRequestingInterviews(true);
      const [, reqError] = await handleMutation(
        requestInterviewsMutation({
          variables: {
            filter:
              modal === 'new_applicants'
                ? 'NEW_APPLICANTS'
                : modal === 'applicants'
                ? 'ALL_APPLICANTS'
                : 'ALL_MATCHES',
            position: positionId
          }
        })
      );
      setRequestingInterviews(false);
      props.onRequestAllInterviews(reqError ? false : true);
      props.close();
    }
  };

  const showAllApplicants = () => {
    navigateTo(
      `${FEED}/?tab=applied&position=${positionId}&location=${locationFilter}`
    );
  };

  useDisableBackgroundScroll([props.open]);
  const { contentShift } = useSidebarNav(props);

  return (
    <FOHApplicantsModal
      {...props}
      leftContentShift={contentShift}
      open={props.open}
      applicants={
        dashboardMatchesQuery.loading || requestingInterviews
          ? []
          : applicants || []
      }
      isMobile={isMobile}
      onScroll={async ({ nativeEvent }) => {
        if (isScrollCloseToBottom(nativeEvent) && !loadingMore && !isMobile) {
          setLoadingMore(true);
          await fetchMore(dashboardMatchesQuery, variables, 'matches');
          setLoadingMore(false);
        }
      }}
      loading={
        dashboardMatchesQuery.loading || loadingMore || requestingInterviews
      }
      labels={{
        applicantLabels: {
          personalSummary: t(`personalSummary`),
          requestInterview: t(`common:interview.request`),
          startChat: t('CandidateCardFeature:chat'),
          requestSent: t(`requestSent`),
          requestSentLongText: t(`requestSentLongText`)
        },
        modalLabels: {
          exportToCsv: t(`exportToCsvModalLabel`),
          requestAll: requestingInterviews
            ? t('InterviewRequestFeature:requestingInterview')
            : t(`requestAllModalLabel`),
          newApplicants: t(`${getLabelByModal(modal)}ModalLabel`),
          showAllApplicants: t(
            `${getLabelByModal(modal)}ShowAllApplicantsModalLabel`
          )
        }
      }}
      onPressProfile={handle => navigateTo(`${CANDIDATE_PROFILE}/${handle}`)}
      onApplicantRequestInterview={handleRequestInterview}
      onApplicantStartChat={goToDirectMessage}
      onRequestAll={
        requestingInterviews || modal === 'matches' || modal === 'new_matches'
          ? null
          : handleRequestAll
      }
      onExportToCsv={handleExportCsv}
      onShowAllApplicants={showAllApplicants}
      positionName={
        dashboardMatchesQuery.loading
          ? '...'
          : path(
              ['data', 'positionById', 'positionName'],
              dashboardMatchesQuery
            )
      }
      schedulerHasInterviewSchedule={hasInterviewSchedule}
      noInterviewScheduleForSchedulerText={t(
        'common:noInterviewScheduleForSchedulerText'
      )}
    />
  );
};
