import React from 'react';

import styled from 'styled-components/native';
import { View } from 'react-native';

import { FOHLabel } from '../../ingredients';

import { FOHPill, FOHPillProps } from '../cells/FOHPill';

export interface FOHAvailableShiftsProps {
  shifts: Array<FOHPillProps>;
  day: string;
}

export const FOHAvailabilityReadSection = (props: FOHAvailableShiftsProps) => {
  return (
    <SectionContainer>
      <FOHLabel style={{ paddingLeft: 6, paddingBottom: 6 }}>
        {props.day}
      </FOHLabel>
      <RowContainer>
        {props.shifts.map((shift, index) => (
          <FOHPill
            key={`shift${index}${props.day}`}
            label={shift.label}
            active={shift.active}
          />
        ))}
      </RowContainer>
    </SectionContainer>
  );
};

const SectionContainer = styled(View)`
  padding-bottom: 12px;
  width: 100%;
`;

const RowContainer = styled(View)`
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
`;
