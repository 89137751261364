import { generateCsv, download, ConfigOptions, mkConfig } from 'export-to-csv';

interface UseExportCsvArgs {
  configs: ConfigOptions;
}

export const useExportCsv = ({ configs }: UseExportCsvArgs) => {
  const defaultCsvConfigs: ConfigOptions = {
    fieldSeparator: ',',
    quoteStrings: true,
    decimalSeparator: '.',
    showColumnHeaders: true,
    showTitle: true,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true
  };

  const csvConfigs = mkConfig({ ...defaultCsvConfigs, ...configs });

  const generateAndDownloadCsv = (csvData: any[]) => {
    const generatedCsv = generateCsv(csvConfigs)(csvData);
    download(csvConfigs)(generatedCsv);
  };

  return { generateAndDownloadCsv };
};
