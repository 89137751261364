import React, { useState } from 'react';

import { defaultTo, path, prop, length } from 'ramda';
import { useTranslation } from 'react-i18next';

import {
  FOHLabel,
  FOHLabelBold,
  FOHColors,
  FOHSpaceSmall,
  FOHSpace,
  FOHView,
  FOHScrollView,
  FOHTouchableOpacity,
  FOHSelectItem,
  FOHCheckbox,
  FOHBorderlessSelect,
  FOHCandidateNetworkCell
} from '../../components';
import { isScrollCloseToBottom, unwrapEdgesAt } from '../../utils';
import {
  INVITE_TO_APPLY,
  MOVE_CANDIDATE,
  NETWORK,
  PROFILE
} from '../../constants';
import { useNavigation } from '../../utils/navigation';

import { LogoLoader } from '../Loading/LogoLoader';
import { usePositionTypes } from '../CandidateProfile/usePositionTypes';
import { useStages } from '../CandidateFeed/useStages';

import { InviteToApplyButton } from './InviteToApplyButton';
import { useCandidateNetworkSearchContext } from './useCandidateNetworkSearchHook';

export const CandidatesList = props => {
  const {
    selectedPosition,
    selectedLocation,
    candidates,
    fetchMoreCandidates,
    isDrawerOpen
  } = props;
  const { navigateTo } = useNavigation();
  const { t } = useTranslation('CandidateNetworkSearch');

  const [loadingMore, setLoadingMore] = useState(false);
  const [errorOnFetchMore, setErrorOnFetchMore] = useState(null);
  const {
    sortBy,
    isFilterDrawerOpen,
    setSortBy,
    sortByOptions,

    setPartialState,
    setActiveCandidateHandles,
    activeCandidateHandles
  } = useCandidateNetworkSearchContext();

  const { getStringForPositionType } = usePositionTypes();

  const { stages, moveEmployerCandidates, passiveStage } = useStages({
    polling: false
  });

  const handleSelectAll = () => {
    if (activeCandidateHandles.length === candidates.length) {
      setActiveCandidateHandles([]);
    } else {
      const handles = candidates.map(candidate => candidate.handle);
      setActiveCandidateHandles(handles);
    }
  };

  const multipleSelected = activeCandidateHandles.length > 1;

  return (
    <FOHView
      style={{
        maxWidth: 1350,
        width: '100%',
        height: '80vh',
        alignItems: 'center'
      }}
    >
      {!isFilterDrawerOpen && (
        <FOHView
          style={{
            background: FOHColors.WHITE,
            flexDirection: 'row',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            alignItems: 'center',
            paddingHorizontal: 16,
            paddingVertical: 6,
            borderRadius: 4,
            maxWidth: 1321,
            width: '100%'
          }}
          testID="CandidatesList-header"
        >
          <FOHTouchableOpacity
            onPress={handleSelectAll}
            style={{ flexDirection: 'row', alignItems: 'center' }}
          >
            <FOHCheckbox
              selected={activeCandidateHandles.length === candidates.length}
            />
            <FOHSpaceSmall />
            <FOHLabel>
              {t('selectAllResults', {
                selected: activeCandidateHandles.length || 0,
                total: candidates.length || 0
              })}
            </FOHLabel>
          </FOHTouchableOpacity>
          <FOHView
            style={{
              flexDirection: 'row',
              minWidth: 200,
              alignItems: 'center',
              justifyContent: 'flex-end'
            }}
          >
            <FOHLabelBold
              style={{ fontSize: 14, color: FOHColors.GRAYSCALE_700 }}
            >
              {t('CandidateFeed:sortByLabel')}
            </FOHLabelBold>
            <FOHSpace />
            <FOHView style={{ maxWidth: 112 }}>
              {length(sortByOptions) > 0 && (
                <FOHBorderlessSelect
                  small={true}
                  selectedValue={sortBy || ''}
                  onValueChange={setSortBy}
                  testID="candidateDB-sort-select"
                  style={{
                    fontSize: 14,
                    lineHeight: 20,
                    color: FOHColors.GRAYSCALE_700
                  }}
                >
                  {sortByOptions.map(option => (
                    <FOHSelectItem
                      value={option}
                      label={t(option)}
                      key={option}
                    />
                  ))}
                </FOHBorderlessSelect>
              )}
            </FOHView>
            {length(activeCandidateHandles) > 1 && (
              <>
                <InviteToApplyButton
                  onPress={() => {
                    navigateTo(`${NETWORK}${INVITE_TO_APPLY}`);
                  }}
                  onStagePress={() => navigateTo(`${NETWORK}${MOVE_CANDIDATE}`)}
                  selectedPosition={selectedPosition}
                  selectedLocation={selectedLocation}
                  style={{ height: 42, paddingHorizontal: 0 }}
                  // dont render expensive or polling
                  //hooks inside of maps
                  stages={stages.filter(state => state.slug !== 'applicants')}
                  moveEmployerCandidates={moveEmployerCandidates}
                  passiveStage={passiveStage}
                  title={`${t(
                    'CandidateNetworkSearch:inviteToApplyHeader'
                  )} (${length(activeCandidateHandles)})`}
                />
                <FOHSpaceSmall />
              </>
            )}
          </FOHView>
        </FOHView>
      )}
      <FOHSpace />
      <FOHScrollView
        style={{
          height: isDrawerOpen ? '90vh' : '62vh',
          width: '100%',
          zIndex: -1
        }}
        scrollEventThrottle={200}
        onScroll={async ({ nativeEvent }) => {
          if (
            isScrollCloseToBottom(nativeEvent) &&
            !loadingMore &&
            !errorOnFetchMore
          ) {
            setLoadingMore(true);
            const [response, error] = await fetchMoreCandidates();
            setLoadingMore(false);

            if (error || !response) {
              setErrorOnFetchMore(error?.message);
              setTimeout(() => {
                setErrorOnFetchMore(null);
              }, 1000);
              return;
            }
          }
        }}
      >
        {/* sorting bar should go here  */}
        <FOHView style={{ alignItems: 'center' }}>
          {candidates.map((candidate, index, all) => {
            const user = prop('user', candidate);
            const workHistory = unwrapEdgesAt(
              ['workHistory', 'edges'],
              candidate
            );
            const firstWorkHistory = prop(0, workHistory);

            const isSelected = activeCandidateHandles.includes(
              candidate.handle
            );

            return (
              <FOHView
                key={candidate.handle}
                style={{
                  // should keep one card above the other, so that the  move to stage card is not covered by the cadidate card
                  zIndex: all.length - index,
                  width: '100%',
                  alignItems: 'center'
                }}
              >
                <FOHCandidateNetworkCell
                  key={candidate.handle}
                  name={`${prop('firstName', user)} ${prop('lastName', user)}`}
                  onPressName={() => {
                    setActiveCandidateHandles([candidate.handle]);
                    navigateTo(`${NETWORK}${PROFILE}`);
                  }}
                  onPressProfileImage={() => {
                    setActiveCandidateHandles([candidate.handle]);
                    navigateTo(`${NETWORK}${PROFILE}`);
                  }}
                  handle={candidate.handle}
                  selected={isSelected}
                  onCheckBoxPress={() => {
                    if (isSelected) {
                      setActiveCandidateHandles(
                        activeCandidateHandles.filter(
                          handle => handle !== candidate.handle
                        )
                      );
                    } else {
                      setActiveCandidateHandles([
                        ...activeCandidateHandles,
                        candidate.handle
                      ]);
                    }
                  }}
                  profileImage={prop('profileImage', user)}
                  statusType={prop('status', candidate)}
                  hasStatusBackground={true}
                  statusText={t(
                    `common:candidateStatusType.${prop('status', candidate)}`
                  )}
                  positions={defaultTo([], prop('positions', candidate))
                    .map(position => getStringForPositionType(position))
                    .join(', ')}
                  workHistoryPositionName={defaultTo(
                    '',
                    prop('position', firstWorkHistory)
                  )}
                  workHistoryEmployerName={defaultTo(
                    '',
                    path(['location', 'name'], firstWorkHistory)
                  )}
                  atLabel={
                    path(['location', 'name'], firstWorkHistory) ? t('at') : ''
                  }
                  cityZipString={`${defaultTo(
                    '-',
                    path(['addressPostalCode'], candidate)
                  )}`}
                  profileCompletenessLabel={t('profileCompletenessLabel')}
                  profileCompleteness={[
                    {
                      label: 'Profile Photo',
                      value: !!prop('profileImage', user)
                    },
                    {
                      label: 'Work History',
                      value: workHistory.length > 0
                    },
                    {
                      label: 'Profile Video',
                      value: !!candidate.profileVideo
                    },
                    {
                      label: 'Has Resume',
                      value: candidate.hasResume
                    },
                    {
                      label: 'Has Endorsements',
                      value: candidate.hasEndorsements
                    },
                    {
                      label: 'Has Contact Information',
                      value: candidate.hasContactInformation
                    }
                  ]}
                  experience={
                    candidate.yearsExperience
                      ? t(
                          `common:yearsExperience:${candidate.yearsExperience}Years`
                        )
                      : null
                  }
                  experienceLabel={t('Experience')}
                >
                  {!multipleSelected && (
                    <>
                      <InviteToApplyButton
                        onPress={() => {
                          setActiveCandidateHandles([candidate.handle]);
                          navigateTo(`${NETWORK}${INVITE_TO_APPLY}`);
                        }}
                        onStagePress={stage => {
                          setPartialState({
                            activeCandidateHandles: [candidate.handle],
                            activeMoveToStageId: stage.id
                          });
                          navigateTo(`${NETWORK}${MOVE_CANDIDATE}`);
                        }}
                        handle={candidate.handle}
                        selectedPosition={selectedPosition}
                        selectedLocation={selectedLocation}
                        style={{ height: 42 }}
                        // dont render expensive or polling
                        //hooks inside of maps
                        stages={stages.filter(
                          state => state.slug !== 'applicants'
                        )}
                        moveEmployerCandidates={moveEmployerCandidates}
                        passiveStage={passiveStage}
                      />
                    </>
                  )}
                </FOHCandidateNetworkCell>
              </FOHView>
            );
          })}
          {loadingMore && <LogoLoader />}
        </FOHView>
      </FOHScrollView>
    </FOHView>
  );
};
