import React, { useMemo, useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-hooks';
import { length, path } from 'ramda';
import Loadable from '@loadable/component';
import { useParams } from 'react-router-dom';

import {
  FOHColors,
  FOHView,
  FOHSpaceSmall,
  FOHHeaderH2,
  FOHImageCell,
  FOHBulkInviteConfirmationModal
} from '../../components';
import {
  AmplitudeTrackingEvents,
  handle,
  handleMutation,
  unwrapEdgesAt,
  useIsMobile,
  useTrackAmplitudeEvent
} from '../../utils';
import network_illustration from '../../images/network_illustration.svg';
import bulk_invite_confirmation from '../../images/bulk_invite_confirmation.svg';
import { fetchMore } from '../../utils/fetchMore';
import { useLatLng } from '../../utils/useLatLng';
import {
  useGetZipcodeForLatLngQuery,
  useMutateInviteToApplyMutation,
  useSearchProfilesQuery
} from '../../graphql/generated';
import { NETWORK } from '../../constants';
import { useNavigation } from '../../utils/navigation';

import { useSelectPositionForSelectedLocationState } from '../EmployerPositions/useSelectPositionForSelectedLocationState';
import { PlanModals } from '../Billing/PlanModals';

import { CandidateNetworkProfileModal } from './CandidateNetworkProfileModal';
import { SearchAndFilters } from './SearchAndFilters';
import { CandidatesList } from './CandidatesList';
import { useCandidateNetworkSearchContext } from './useCandidateNetworkSearchHook';
import { MoveCandidatesModal } from './MoveCandidatesModal';
import { CandidateNetworkMobileScreen } from './Mobile/CandidateNetworkMobileScreen';

const InviteToApplyModal = Loadable(() => import('./InviteToApplyModal'));

export const CandidateNetworkSearchScreen = (props: any) => {
  const { navigateTo, location } = useNavigation();
  const { modal } = useParams();

  const {
    isFilterDrawerOpen,
    activeMoveToStageId,
    setActiveMoveToStageId,
    setActiveCandidateHandles,
    setZip,
    zip,
    profileDetailFilters,
    sortBy,
    searchTerms,
    distance,
    activeCandidateHandles
  } = useCandidateNetworkSearchContext();

  const { t } = useTranslation('CandidateNetworkSearch');

  const [bulkMessages, setBulkMessages] = useState(null);
  const [bulkPosition, setBulkPosition] = useState(null);
  const [bulkLocation, setBulkLocation] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [locationIdToUpgrade, setLocationIdToUpgrade] = useState();
  const { isMobile } = useIsMobile();

  const {
    setSelectedPosition,
    selectedPosition,
    paidPositions,
    setSelectedLocation,
    myLocations,
    selectedLocation
  } = useSelectPositionForSelectedLocationState();

  const { latitude, longitude }: any = useLatLng(true);

  const zipcodeForCoordsQuery = useGetZipcodeForLatLngQuery({
    skip: !latitude || !longitude,
    variables: {
      latitude,
      longitude
    }
  });

  const [inviteToApplyMutation] = useMutateInviteToApplyMutation();

  const { logEventMetrics } = useTrackAmplitudeEvent();

  const zipcodeForCoords = path(
    ['data', 'zipcodeForCoordinates', 'zipcode'],
    zipcodeForCoordsQuery
  );

  useEffect(() => {
    if (!zip && zipcodeForCoords) {
      setZip(zipcodeForCoords);
    }
  }, [zipcodeForCoords]); // eslint-disable-line react-hooks/exhaustive-deps

  // calculating search terms for the query is causing multiple re renders so useMemo
  const searchTermsValues = useMemo(
    () => searchTerms.map((term: any) => term.value),
    [searchTerms]
  );

  const debouncedSearchTerms = useDebounce(searchTermsValues, 1000);
  const debouncedZip = useDebounce(zip, 1000);
  const debouncedDistance = useDebounce(distance, 300);

  const variables = {
    first: 10,
    searchTerms: debouncedSearchTerms,
    profileDetailFilters,
    addressPostalCode:
      debouncedZip && length(debouncedZip) === 5 ? debouncedZip : undefined,
    milesFromCenterZip: debouncedDistance
      ? parseInt(debouncedDistance)
      : undefined,
    sortBy
  };

  const searchProfilesQuery = useSearchProfilesQuery({ variables });

  const candidates = unwrapEdgesAt(
    ['data', 'searchProfiles', 'edges'],
    searchProfilesQuery
  );

  if (isMobile) {
    return (
      <CandidateNetworkMobileScreen
        candidates={candidates}
      ></CandidateNetworkMobileScreen>
    );
  }

  const endCursor = path(
    ['data', 'searchProfiles', 'pageInfo'],
    searchProfilesQuery
  );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fetchMoreCandidates = useCallback(async () => {
    return await handle(
      fetchMore(searchProfilesQuery, variables, 'searchProfiles')
    ); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endCursor]);

  return (
    <FOHView
      style={{
        maxHeight: isFilterDrawerOpen ? '100vh' : undefined,
        alignItems: 'center',
        opacity: 1,
        paddingVertical: 24,
        width: '100%',
        height: '100vh',
        alignSelf: 'center',
        position: 'relative',
        overflow: 'hidden'
      }}
    >
      <FOHView
        style={{
          width: '100%',
          height: '100%'
          //position: 'initial'
        }}
      >
        <FOHView
          style={{
            position: 'absolute',
            right: 0,
            top: -19,
            zIndex: isFilterDrawerOpen ? 1 : 0
          }}
        >
          <FOHImageCell
            image={network_illustration}
            style={{ height: 144, width: 144 }}
          />
        </FOHView>
        <FOHView
          style={{
            paddingHorizontal: 24,
            width: '100%',
            alignItems: 'flex-start'
          }}
        >
          <FOHHeaderH2
            style={{
              textAlign: 'left',
              color: FOHColors.GRAYSCALE_G1,
              fontWeight: '700'
            }}
          >
            {t('TopNavFeature:candidateNetwork') as string}
          </FOHHeaderH2>
        </FOHView>
        <FOHView
          style={{
            maxHeight: isFilterDrawerOpen ? '100vh' : undefined,
            alignItems: 'center',
            opacity: 1,
            height: '100%'
          }}
        >
          <FOHView
            style={{
              zIndex: 2,
              maxWidth: '100%',
              width: '100%'
            }}
          >
            <FOHSpaceSmall />
            <SearchAndFilters candidates={candidates} />
          </FOHView>

          {/* sort and search results should be populated here */}
          <FOHView
            style={{
              width: '100%',
              height: '100%',
              padding: 24,
              backgroundColor: FOHColors.GRAYSCALE_10
            }}
          >
            <CandidatesList
              isDrawerOpen={isFilterDrawerOpen}
              candidates={candidates}
              fetchMoreCandidates={fetchMoreCandidates}
              setSelectedPosition={setSelectedPosition}
              selectedPosition={selectedPosition}
              paidPositions={paidPositions}
              setSelectedLocation={setSelectedLocation}
              myLocations={myLocations}
              selectedLocation={selectedLocation}
              setActiveMoveToStage={setActiveMoveToStageId}
            />
          </FOHView>
        </FOHView>
        <MoveCandidatesModal
          {...props}
          open={modal === 'move-candidate'}
          close={() => {
            setActiveCandidateHandles([]);
            navigateTo(NETWORK);
          }}
          activeMoveToStage={activeMoveToStageId}
          setActiveMoveToStage={setActiveMoveToStageId}
          refetchSearchProfiles={searchProfilesQuery.refetch}
          setLocationIdToUpgrade={setLocationIdToUpgrade}
        />

        <CandidateNetworkProfileModal
          {...props}
          style={{ minWidth: 1128 }}
          open={modal === 'profile'}
          close={() => {
            setActiveCandidateHandles([]);

            navigateTo(NETWORK);
          }}
          refetchSearchProfiles={searchProfilesQuery.refetch}
        />

        {modal === 'invite-to-apply' && (
          <InviteToApplyModal
            {...props}
            open={modal === 'invite-to-apply'}
            close={() => {
              setActiveCandidateHandles([]);

              navigateTo(NETWORK);
            }}
            activeMoveToStage={activeMoveToStageId}
            setActiveMoveToStage={setActiveMoveToStageId}
            style={{ top: 39, width: 800, height: '90%' }}
            refetchSearchProfiles={searchProfilesQuery.refetch}
            setLocationIdToUpgrade={setLocationIdToUpgrade}
            setBulkMessages={setBulkMessages}
            setBulkPosition={setBulkPosition}
            setBulkLocation={setBulkLocation}
          />
        )}
        <PlanModals
          {...props}
          // initialPosition={currentPosition}
          onClose={() => {
            navigateTo(NETWORK);
          }}
          onCancel={() => {
            navigateTo(NETWORK);
          }}
          history={history}
          backPath={NETWORK}
          backSearch={location.search}
          locationId={locationIdToUpgrade}
        />
        <FOHBulkInviteConfirmationModal
          open={modal === 'bulk-invite-confirmation'}
          close={() => {
            navigateTo(NETWORK);
            setActiveCandidateHandles([]);
          }}
          image={bulk_invite_confirmation}
          headerLabel={t('bulkInviteConfirmationTitle', {
            count: activeCandidateHandles?.length
          })}
          detailLabel={t('bulkInviteConfirmationDescription')}
          buttonLabel={t('bulkInviteConfirmationOKlabel', {
            count: activeCandidateHandles?.length
          })}
          disabled={isLoading}
          confirm={async () => {
            setIsLoading(true);
            const candidateInvitedEventDetail = {
              event_type: AmplitudeTrackingEvents.CANDIDATE_INVITED.EVENT_TYPE,
              invite_method: 'bulk'
            };

            await handleMutation(
              inviteToApplyMutation({
                variables: {
                  candidateHandles: activeCandidateHandles,
                  locationId: bulkLocation,
                  positionId: bulkPosition,
                  message: bulkMessages
                }
              })
            );
            searchProfilesQuery.refetch();

            setIsLoading(false);
            setActiveCandidateHandles([]);

            await logEventMetrics(
              AmplitudeTrackingEvents.CANDIDATE_INVITED.EVENT_NAME,
              candidateInvitedEventDetail
            );

            navigateTo(NETWORK);
          }}
          cancel={() => {
            navigateTo(NETWORK);
          }}
          cancelText={t('bulkInviteConfirmationCancellabel')}
          dialog={true}
          imageHeight={200}
          imageWidth={200}
        />
      </FOHView>
    </FOHView>
  );
};
