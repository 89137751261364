import React, { useState } from 'react';

import { compose, length, path, prop } from 'ramda';
import { useTranslation } from 'react-i18next';

import {
  FOHColors,
  FOHLabel,
  FOHHighlightWordsLabel,
  useIsRestricted,
  FOHSpaceLarge,
  FOHSpace,
  FOHView,
  FOHSearchableSelectDropdown,
  FOHPlanState,
  FOHButton,
  FOHPositionBillingStatusType,
  FOHLocationPin,
  FOHToolBoxIcon,
  FOHProfileImage,
  FOHHeaderH2
} from '../../components';
import {
  EmployerStagesDocument,
  useGetCandidateQuery,
  useMutateCreateEmployerCandidatesMutation
} from '../../graphql/generated';
import {
  AmplitudeTrackingEvents,
  handleMutation,
  useTrackAmplitudeEvent
} from '../../utils';
import { ALLOWED_ROLES_GROUPS, NETWORK, UPGRADE } from '../../constants';
import { useNavigation } from '../../utils/navigation';

import { withReactiveModal } from '../Navigation';
import { useSelectPositionForSelectedLocationState } from '../EmployerPositions/useSelectPositionForSelectedLocationState';
import { useCurrentLocationContext } from '../Locations/useCurrentLocationContext';
import { usePaymentPlan } from '../Billing/usePaymentPlan';
import { useCandidateFeedContext } from '../CandidateFeed/useCandidateFeedContext';

import { useCandidateNetworkSearchContext } from './useCandidateNetworkSearchHook';

const MoveCandidatesScreen = props => {
  const { refetchSearchProfiles, setLocationIdToUpgrade } = props;
  const { t } = useTranslation('CandidateNetworkSearch');

  const { navigateTo } = useNavigation();

  const { selectLocationFilter } = useCurrentLocationContext();

  const {
    activeCandidateHandles,
    activeMoveToStageId,
    setActiveCandidateHandles,
    profileDetailFilters
  } = useCandidateNetworkSearchContext();

  const [locationOpen, setLocationOpen] = useState(false);
  const [positionOpen, setPositionOpen] = useState(false);

  const handle = activeCandidateHandles[0];

  const candidateQuery = useGetCandidateQuery({
    variables: { handle: handle }
  });

  const profile = path(['data', 'profile'], candidateQuery);

  const {
    setSelectedPosition,
    selectedPosition,
    myPositions,
    setSelectedLocation,
    myLocations,
    selectedLocation,
    locationSearch,
    setLocationSearch,
    positionSearch,
    setPositionSearch
  } = useSelectPositionForSelectedLocationState({ profile });

  const { positionFilters, locationFilters } = useCandidateFeedContext();

  const { isRestricted } = useIsRestricted({
    allowedRoles: ALLOWED_ROLES_GROUPS.ROLES.ADMIN_USER,
    allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.EMPLOYER]
  });

  // control location selection of payment modal via context
  const { getCurrentPlanName } = usePaymentPlan({
    locationFilter: selectedLocation
  });

  const { logEventMetrics } = useTrackAmplitudeEvent();

  const [createEmployerCandidatesMutation] =
    useMutateCreateEmployerCandidatesMutation();

  const handleMoveCandidates = async () => {
    const moveCandidateEventDetail = {
      event_type: AmplitudeTrackingEvents.MOVE_CANDIDATE_CLICKED.EVENT_TYPE,
      page: 'database',
      method: length(activeCandidateHandles) > 1 ? 'bulk' : 'individual'
    };

    await handleMutation(
      createEmployerCandidatesMutation({
        variables: {
          candidateHandles: activeCandidateHandles,
          positionId: selectedPosition,
          locationId: selectedLocation,
          moveToStageId: activeMoveToStageId
        },
        refetchQueries: [
          {
            query: EmployerStagesDocument,
            variables: {
              positions: positionFilters,
              locations: locationFilters,
              profileDetailFilters
            }
          }
        ]
      })
    );

    await logEventMetrics(
      AmplitudeTrackingEvents.MOVE_CANDIDATE_CLICKED.EVENT_NAME,
      moveCandidateEventDetail
    );

    refetchSearchProfiles();
    setActiveCandidateHandles([]);
    navigateTo(NETWORK);
  };

  const locationOptions = myLocations.map(loc => ({
    label: prop('displayName', loc),
    value: prop('id', loc),
    sublabel: prop('formattedAddress', loc)
  }));

  const positionOptions = myPositions.map(pos => {
    const isHiring = path(['hiring'], pos) || false;
    const planName = getCurrentPlanName(pos);
    const isNotSubscribed =
      path(['paymentStatus'], pos) !==
        FOHPositionBillingStatusType.SUBSCRIBED &&
      path(['location', 'paymentStatus'], pos) !==
        FOHPositionBillingStatusType.SUBSCRIBED;

    return {
      label: prop('positionName', pos),
      value: prop('id', pos),
      sublabel: '',

      // prompt for upgrade label
      upgrade:
        (planName === FOHPlanState.payasyougo || planName === null) &&
        !isRestricted &&
        isNotSubscribed,
      // paused when role is interviewer and position/location
      isPaused:
        ((planName === FOHPlanState.unlimited ||
          planName === FOHPlanState.enterprise) &&
          !isHiring) ||
        ((planName === FOHPlanState.payasyougo || planName === null) &&
          ((isRestricted && isNotSubscribed) ||
            (!isHiring && !isNotSubscribed))),
      // restrict the user on action clickable
      isUserRestricted:
        (planName === FOHPlanState.payasyougo || planName === null) &&
        isNotSubscribed &&
        isRestricted,

      locationIdToUpgrade: path(['location', 'id'], pos)
    };
  });

  return (
    <FOHView
      style={{ alignItems: 'center', paddingTop: 24, paddingBottom: 4 }}
      testID="MoveCandidatesModal"
    >
      <FOHView style={{ alignItems: 'center', flexDirection: 'row' }}>
        <FOHProfileImage
          photo={path(['user', 'profileImage'], profile)}
          key="photo"
          style={{
            height: 64,
            width: 64
          }}
        />
        <FOHView
          style={{
            height: 64,
            width: 64,
            backgroundColor: FOHColors.BLUE,
            borderRadius: '50%',
            padding: 10,
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: -10,
            zIndex: 1,
            boxshadow: '0px 0px 10px rgba(0,0,0,0.5)'
          }}
        >
          <FOHToolBoxIcon
            style={{
              width: 32,
              height: 32,
              tintColor: FOHColors.WHITE
            }}
          />
        </FOHView>
      </FOHView>
      <FOHSpaceLarge />
      <FOHView style={{ alignItems: 'center' }}>
        <FOHHeaderH2 style={{ marginBottom: 8 }}>
          {activeCandidateHandles.length === 1 ? (
            t('moveCandidateModalHeader')
          ) : (
            <>
              <FOHHighlightWordsLabel
                labelCmp={FOHHeaderH2}
                emphasizedWords={[
                  `${activeCandidateHandles.length}`,
                  `${t('common:candidates')}`
                ]}
                highlightColor={FOHColors.PACIFIC_BLUE}
              >
                {t('moveCandidatesModalHeader', {
                  candidateCount: activeCandidateHandles.length
                })}
              </FOHHighlightWordsLabel>
            </>
          )}
        </FOHHeaderH2>
        <FOHLabel style={{ fontSize: 14, color: FOHColors.GREY }}>
          {t('moveCandidateModalHeaderSubtext')}
        </FOHLabel>
        <FOHSpace />

        <FOHView
          style={{
            backgroundColor: '#FCFCFC',
            padding: 32,
            borderRadius: 24,
            zIndex: 2
          }}
        >
          <FOHView style={{ zIndex: 3, width: '100%', minWidth: 400 }}>
            <FOHSearchableSelectDropdown
              open={locationOpen}
              setOpen={open => {
                setPositionOpen(false);
                setLocationOpen(open);
              }}
              selectedValue={selectedLocation}
              selectedLabel={undefined}
              showLabel={t('Show')}
              onSearchChange={setLocationSearch}
              searchValue={locationSearch}
              multiSelect={false}
              searchPlaceholder={t('common:search')}
              onValueChange={value => {
                setSelectedLocation(value);
              }}
              options={locationOptions}
              fieldLabel={t('locations').toUpperCase()}
              icon={() => <FOHLocationPin />}
              testID="candidate-network-location-filter"
              style={{ width: '100%' }}
            ></FOHSearchableSelectDropdown>
          </FOHView>

          <FOHSpace />
          <FOHView style={{ zIndex: 2, width: '100%', minWidth: 400 }}>
            <FOHSearchableSelectDropdown
              open={positionOpen}
              style={{ width: '100%' }}
              setOpen={open => {
                setPositionOpen(open);
                setLocationOpen(false);
              }}
              selectedValue={selectedPosition}
              multiSelect={false}
              showLabel={t('Show')}
              onSearchChange={setPositionSearch}
              searchValue={positionSearch}
              searchPlaceholder={t('common:search')}
              onValueChange={value => {
                setSelectedPosition(value);
              }}
              options={positionOptions}
              fieldLabel={t('positions').toUpperCase()}
              upgradeLabel={t('upgrade')}
              pausedLabel={t('PricingScreenFeature:notPostedLabel')}
              onPressUpgrade={({ locationIdToUpgrade }) => {
                setActiveCandidateHandles([]);
                navigateTo({
                  pathname: `${NETWORK}${UPGRADE}`,
                  search: `?location=${locationIdToUpgrade}`
                });
                selectLocationFilter(locationIdToUpgrade);
                setLocationIdToUpgrade(locationIdToUpgrade);
              }}
              icon={() => (
                <FOHToolBoxIcon
                  style={{
                    width: 14,
                    height: 14,
                    tintColor: FOHColors.PACIFIC_BLUE
                  }}
                />
              )}
              testID="candidate-network-position-filter"
            ></FOHSearchableSelectDropdown>
          </FOHView>
        </FOHView>
        <FOHSpaceLarge />
        <FOHButton
          title={t('moveCandidateModalButton')}
          disabled={!selectedLocation || !selectedPosition}
          onPress={async () => {
            await handleMoveCandidates(selectedPosition, selectedLocation);
          }}
          style={{ width: 204 }}
        />
      </FOHView>
    </FOHView>
  );
};

export const MoveCandidatesModal =
  compose(withReactiveModal)(MoveCandidatesScreen);
