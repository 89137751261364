import React from 'react';

import { Text } from 'react-native';
import styled from 'styled-components/native';

import { FOHFonts, FOHView } from '../../ingredients';

export interface FOHMultiPositionCountProps {
  value: number;
  style?: any;
}

export const FOHMultiPositionCount = (props: FOHMultiPositionCountProps) => (
  <Border>
    <LinkText>{`+${props.value}`}</LinkText>
  </Border>
);

const Border = styled(FOHView)`
  align-items: center;
  border-radius: 100px;
  justify-content: center;
  width: 29px;
  height: 24px;
  border: 1.5px solid #878787;
  padding: 4px 8px;
`;

const LinkText = styled(Text)`
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  align-items: center;
  color: #878787;
`;
