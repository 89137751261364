import React, { useRef } from 'react';

import { useHover } from 'react-native-web-hooks';

import {
  FOHColors,
  FullWidthCard,
  FOHVerticalDivider,
  FOHDropDownArrow,
  ButtonText,
  FOHView,
  FOHTouchableOpacity
} from '../../ingredients';

export interface FOHDropdownButtonProps {
  title: string;
  testID?: string;
  open?: boolean;
  toggleOpen: () => any;
  children: any;
  style?: any;
  menuTop?: number;
  disabled?: boolean;
}

export const FOHDropdownButton = (props: FOHDropdownButtonProps) => {
  const btnRef = useRef(null);
  const isBtnHover = useHover(btnRef);
  const dropdownRef = useRef(null);
  const isDropdownHover = useHover(dropdownRef);

  return (
    <FOHView
      style={{
        width: '100%'
      }}
    >
      <FOHTouchableOpacity
        onPress={props.toggleOpen}
        disabled={props.disabled}
        style={{
          backgroundColor: props.disabled
            ? FOHColors.GRAYSCALE_100
            : FOHColors.PRIMARY_TEAL_P1,
          height: 50,
          borderRadius: 4,
          width: '100%',
          flexDirection: 'row',
          ...(props.style ? props.style : {})
        }}
        testID={props.testID}
      >
        <FOHView
          ref={btnRef}
          style={{
            flex: 3,
            justifyContent: 'center',
            alignItems: 'center',
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
            backgroundColor:
              !props.disabled && isBtnHover ? FOHColors.PRIMARY_TEAL_300 : ''
          }}
        >
          <ButtonText
            style={{
              color: props.disabled ? FOHColors.GRAYSCALE_400 : FOHColors.WHITE
            }}
          >
            {props.title}
          </ButtonText>
        </FOHView>
        <FOHVerticalDivider color={FOHColors.WHITE} />
        <FOHView
          ref={dropdownRef}
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
            backgroundColor:
              !props.disabled && isDropdownHover
                ? FOHColors.PRIMARY_TEAL_300
                : ''
          }}
        >
          <FOHDropDownArrow
            style={{
              tintColor: props.disabled
                ? FOHColors.GRAYSCALE_400
                : FOHColors.WHITE,
              width: 32,
              height: 32,
              transform: props.open ? [{ rotate: '180deg' }] : []
            }}
          />
        </FOHView>
      </FOHTouchableOpacity>
      {props.open && (
        <FullWidthCard
          style={{
            padding: 12,
            position: 'absolute',
            top: props.menuTop || -150,
            maxWidth: 293,
            width: '100%'
          }}
          shadow
        >
          {props.children}
        </FullWidthCard>
      )}
    </FOHView>
  );
};
