import React from 'react';

import { compose, length, path, defaultTo, prop } from 'ramda';
import { useTranslation } from 'react-i18next';

import { scrollToTop } from '../../utils';
import { JOBS, getAppBaseUrl } from '../../constants';
import {
  useGetOtherJobPositionsByLocationQuery,
  useGetOtherJobPositionsQuery
} from '../../graphql/generated';
import {
  FOHOtherJobsCard,
  FOHPositionBillingStatusType,
  FOHView
} from '../../components';
import { useNavigation } from '../../utils/navigation';

import { withReactiveModal } from '../Navigation/withReactiveModal';
import { LogoLoader } from '../Loading/LogoLoader';

export const formatOtherJobs = jobs =>
  jobs.map(node => ({
    // Use an <a> tag for seo purposes
    url: prop('externalApplyUrl', node)
      ? prop('externalApplyUrl', node)
      : `${getAppBaseUrl()}/jobs/${path(['node', 'slug'], node)}`,
    name: path(['node', 'positionName'], node),
    formattedAddress: path(['node', 'location', 'formattedAddress'], node),
    ...prop('node', node)
  }));

const LocationJobsModal = props => {
  const { slug } = props;
  const { t } = useTranslation('JobApplicationsFeature');
  const { navigateTo } = useNavigation();

  const otherJobsQuery = useGetOtherJobPositionsByLocationQuery({
    variables: {
      slug
    }
  });

  const jobs = formatOtherJobs(
    defaultTo(
      [],
      path(['data', 'location', 'positions', 'edges'], otherJobsQuery)
    )
  )
    .map(job => ({
      ...job,
      formattedAddress: path(
        ['data', 'location', 'formattedAddress'],
        otherJobsQuery
      )
    }))
    .filter(
      position =>
        prop('hiring', position) === true &&
        (prop('paymentStatus', position) ===
          FOHPositionBillingStatusType.SUBSCRIBED ||
          path(['location', 'paymentStatus'], position) ===
            FOHPositionBillingStatusType.SUBSCRIBED)
    );

  return (
    <>
      {otherJobsQuery.loading ? (
        <FOHView style={{ justifyContent: 'center', alignItems: 'center' }}>
          <LogoLoader />
        </FOHView>
      ) : (
        length(jobs) > 0 && (
          <FOHOtherJobsCard
            ctaText={t('ctaTextOtherJob')}
            style={{ padding: props.dialog ? 24 : 0 }}
            noBorder={true}
            a={anchorProps => <>{anchorProps.children}</>}
            onPress={(_slug, _externalApplyUrl) => {
              scrollToTop({ smooth: false });
              if (_externalApplyUrl && window && window.open) {
                return window.open(_externalApplyUrl, '_blank').focus();
              }
              navigateTo({
                pathname: `${JOBS}/${_slug}`,
                search: path(['location', 'search'], props)
              });
            }}
            title={`JOBS AT ${path(
              ['data', 'location', 'name'],
              otherJobsQuery
            )}`}
            jobs={jobs}
          />
        )
      )}
    </>
  );
};

const OtherJobs = props => {
  const { slug } = props;
  const { t } = useTranslation('JobApplicationsFeature');
  const { navigateTo } = useNavigation();

  // diff query
  const otherJobsQuery = useGetOtherJobPositionsQuery({
    skip: !slug,
    variables: {
      slug
    }
  });

  const jobs = formatOtherJobs(
    defaultTo(
      [],
      path(
        ['data', 'position', 'location', 'positions', 'edges'],
        otherJobsQuery
      )
    )
  )
    .map(job => ({
      ...job,
      formattedAddress: path(
        ['data', 'position', 'location', 'formattedAddress'],
        otherJobsQuery
      )
    }))
    .filter(
      position =>
        prop('slug', position) !== slug &&
        prop('hiring', position) === true &&
        (prop('paymentStatus', position) ===
          FOHPositionBillingStatusType.SUBSCRIBED ||
          path(['location', 'paymentStatus'], position) ===
            FOHPositionBillingStatusType.SUBSCRIBED)
    );

  return (
    <>
      {length(jobs) > 0 && (
        <FOHOtherJobsCard
          ctaText={t('ctaTextOtherJob')}
          // Use an <a> tag for seo purposes
          a={anchorProps => <a {...anchorProps}>{anchorProps.children}</a>}
          onPress={(_slug, _externalApplyUrl) => {
            scrollToTop({ smooth: false });
            if (_externalApplyUrl && window && window.open) {
              return window.open(_externalApplyUrl, '_blank').focus();
            }
            scrollToTop({ smooth: false });
            navigateTo(`${JOBS}/${_slug}`);
          }}
          title={`JOBS AT ${path(
            ['data', 'position', 'location', 'name'],
            otherJobsQuery
          )}`}
          jobs={jobs}
        />
      )}
    </>
  );
};

export const JobsModal = compose(withReactiveModal)(LocationJobsModal);

export default OtherJobs;
