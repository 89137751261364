import React from 'react';

import styled from 'styled-components/native';
import { Text } from 'react-native';

import { FOHColors, FOHFonts } from '../../ingredients';

interface FOHSquareTagProps {
  label: string;
  style?: any;
}

export const FOHSquareTag = (props: FOHSquareTagProps) => (
  <BlackTagText style={{ ...props.style }}>{props.label}</BlackTagText>
);

const BlackTagText = styled(Text)`
  font-family: ${FOHFonts.MEDIUM}
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  display: inline-flex;
  color: #ffffff;
  background-color: ${FOHColors.GRAYSCALE_G1};
  justify-content: center;
  text-align: center;
  line-height: 22px;
  font-size: 12px;
  word-break: break-word;
  border-radius: 4px;
  padding-top: 2px;
  padding-right: 9px;
  padding-left: 9px;
`;
