import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';

import styled from 'styled-components/native';
import { path, length, defaultTo, prop } from 'ramda';
import { useTranslation } from 'react-i18next';
import { useHover } from 'react-native-web-hooks';
import { createEditor, Node } from 'slate';
import { withReact } from 'slate-react';

import { handleMutation } from '../../utils';
import {
  useEmployerMessageTemplatesQuery,
  useMutateSendQuestionnaireMutation
} from '../../graphql/generated';
import {
  VIDEO_QUESTIONNAIRE,
  getPlaceHolderByAction,
  getTemplatePlaceHolders
} from '../../constants/templates';
import { FEED, TEMPLATES, UPGRADE } from '../../constants';
import {
  FOHLabel,
  FOHColors,
  FOHSpace,
  FOHSpaceSmallest,
  FOHSpaceSmall,
  FOHView,
  FOHToolTip,
  FOHToolTipType,
  FOHBlackAdd,
  FOHBlackHelpIcon,
  FOHVideoQuestionnaireIcon,
  FOHFonts
} from '../../components';
import { useNavigation } from '../../utils/navigation';

import { useSelectPositionForSelectedLocationState } from '../EmployerPositions/useSelectPositionForSelectedLocationState';
import { useCandidateFeedContext } from '../CandidateFeed/useCandidateFeedContext';
import ActionModal from '../CandidateFeed/ActionModal';
import {
  withCharacterLimit,
  withCustomDelete,
  stripBracesFromPlaceholder
} from '../EmployerMessageTemplate/utils';
import { FOHMessageInputSection } from '../EmployerMessageTemplate/FOHMessageInputSection';

import { INITIAL_SLATE_VALUE } from './CONSTANTS';

const MAX_COUNT = 500;

const editor = withCharacterLimit(MAX_COUNT)(
  withReact(withCustomDelete(createEditor()))
);

export const SuggestionLabelComp = ({ children }) => (
  <FOHView
    style={{
      borderRadius: 20,
      borderWidth: 1,
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'row',
      paddingHorizontal: 8,
      paddingVertical: 7
    }}
    testID={`SuggestionLabelComp-${children}`}
  >
    <FOHLabel>{children}</FOHLabel>
    <FOHSpaceSmallest />
    <FOHBlackAdd style={{ paddingTop: 6 }} />
  </FOHView>
);

export const VideoScreeningQuestionnaireModal = props => {
  const { t } = useTranslation('EmployerQuestionnaireModal');
  const { employerCandidate } = props;

  const { navigateTo } = useNavigation();

  const [inputMessageContent, setInputMessageContent] =
    useState(INITIAL_SLATE_VALUE);

  const [label, setLabel] = useState('');
  const [template, setTemplate] = useState();
  const [activeQuestionnaire, setActiveQuestionnaire] = useState([]);

  const [open, setOpen] = useState(false);
  const [sending, setSending] = useState(false);
  const [search, setSearch] = useState('');
  const [placeHolderError, setPlaceHolderError] = useState('');

  const employerCandidatePreferredMatch = path(
    ['preferredMatch'],
    employerCandidate
  );

  const {
    selectedLocation,
    selectedPosition,
    setSelectedPosition,
    setSelectedLocation,
    paidPositions
  } = useSelectPositionForSelectedLocationState({
    positionId: path(['position', 'id'], employerCandidatePreferredMatch),
    locationId: path(
      ['position', 'location', 'id'],
      employerCandidatePreferredMatch
    )
  });

  const messageTemplatesQuery = useEmployerMessageTemplatesQuery({
    variables: {
      search,
      category: 'SCREENING'
    }
  });

  const videoPromptHoverRef = useRef(null);
  const isVideoPromptHovered = useHover(videoPromptHoverRef);

  const filteredTemplates = useMemo(() => {
    const templates = defaultTo(
      [],
      path(['data', 'employerMessageTemplates', 'edges'], messageTemplatesQuery)
    );

    return templates
      .map(edge => ({
        id: edge?.node.id,
        name: edge?.node.name,
        messageContent: edge.node.messageContent,
        templateType: edge.node.templateType,
        isDefault: edge.node.isDefault,
        category: edge.node.category,
        action: edge.node.action,
        screeningQuestionnaireId:
          edge.node.screeningQuestionnaire?.edges?.[0]?.node?.id,
        screeningQuestion:
          edge.node.screeningQuestionnaire?.edges?.[0]?.node?.screeningQuestion?.edges
            ?.slice(0, 1)
            ?.map(questionEdge => {
              return {
                question: questionEdge.node.question,
                questionId: questionEdge.node.id,
                deleteQuestion: false
              };
            })
      }))
      .filter(
        _template =>
          _template?.name?.toLowerCase()?.includes(search.toLowerCase()) &&
          _template?.action === 'video'
      );
  }, [messageTemplatesQuery, search]);

  useEffect(() => {
    if (length(filteredTemplates) > 0 && !template) {
      const defaultTemplate = filteredTemplates.find(
        _template => !!_template.isDefault
      );
      if (defaultTemplate) {
        console.log('defaultTemplate', defaultTemplate);
        setTemplate(defaultTemplate);
        handleOnUseTemplate(defaultTemplate);
      }
    }
  }, [filteredTemplates, template]);

  const [sendQuestionnaireMutation] = useMutateSendQuestionnaireMutation();

  const { activeStageId } = useCandidateFeedContext();

  const resetForm = () => {
    setOpen(false);
    setTemplate(undefined);
    setActiveQuestionnaire([]);
    setPlaceHolderError('');
    setLabel('');
    setInputMessageContent(INITIAL_SLATE_VALUE);
  };

  const handleOnClose = () => {
    resetForm();
    setSelectedPosition(undefined);
    setSending(false);
    navigateTo(FEED);
  };

  const handleOnUseTemplate = (selectedTemplate = null) => {
    const temp = selectedTemplate ?? template;

    const newValue = [
      {
        type: 'paragraph',
        children: [{ text: temp?.messageContent || '' }]
      }
    ];

    setInputMessageContent(newValue);
    editor.children = newValue;
    setLabel(temp?.name);
    setActiveQuestionnaire(defaultTo([], prop('screeningQuestion', temp)));
    setOpen(false);
  };

  const sendQuestionnaire = async () => {
    const questionnaireId = prop('screeningQuestionnaireId', template);

    const messageContent = inputMessageContent
      .map(n => Node.string(n))
      .join('\n');

    const screeningStage = props.stages.find(
      stage => stage.stageType === 'SCREENING'
    );

    const placeholder = getPlaceHolderByAction(VIDEO_QUESTIONNAIRE);

    if (!messageContent.includes(placeholder)) {
      const errorPlaceholder = stripBracesFromPlaceholder(placeholder);

      setPlaceHolderError(
        t('MessageTemplatesFeature:requiredPlaceHolder', {
          placeholder: errorPlaceholder
        })
      );
      return;
    }

    setPlaceHolderError('');

    setSending(true);
    if (activeStageId !== screeningStage.id) {
      await props.moveEmployerCandidates({
        employerCandidateIds: [props.employerCandidateId],
        newStage: screeningStage.id,
        all: false,
        oldStage: activeStageId,
        positions: [selectedPosition],
        locations: [selectedLocation],
        search: ''
      });
    }
    props.stagesQuery.refetch();
    props.candidateFeedQuery.refetch();

    await handleMutation(
      sendQuestionnaireMutation({
        variables: {
          employerCandidateId: props.employerCandidateId,
          positionId: selectedPosition,
          questionnaireId: questionnaireId,
          messageContent: messageContent
        }
      })
    );

    setTimeout(() => {
      props.setEmployerCandidate(props.employerCandidate);
      props.setCandidateHandle(props.candidateHandle);
    }, 1000);

    handleOnClose();
  };

  return (
    <ActionModal
      {...props}
      title={t('videoHeader')}
      subtitle={t('headerSubTitle')}
      HeaderImage={FOHVideoQuestionnaireIcon}
      defaultLocation={path(
        ['position', 'location', 'id'],
        employerCandidatePreferredMatch
      )}
      defaultPosition={path(
        ['position', 'id'],
        employerCandidatePreferredMatch
      )}
      showLabel={t('common:show')}
      onPressUpgrade={() => navigateTo(`${FEED}${UPGRADE}`)}
      searchPlaceholder={t('common:search')}
      positionFieldLabel={t('common:position')}
      upgradeLabel={t('TopNavFeature:upgrade')}
      backButtonText={t('common:previous')}
      nextButtonText={sending ? t('actions:sendingLetter') : t('sendLabel')}
      onNextPress={sendQuestionnaire}
      onBackPress={handleOnClose}
      isNextDisabled={
        length(paidPositions) === 0 || !template?.id || sending === true
      }
      setPosition={setSelectedPosition}
      setLocation={setSelectedLocation}
      containerTestID="FOHSendVideoScreeningForm"
      containerStyle={{
        alignItems: 'flex-start',
        width: '100%',
        paddingTop: 0
      }}
    >
      <FOHView style={{ minHeight: open ? 540 : undefined }}>
        <FOHMessageInputSection
          testID={'message-templates-section'}
          t={t}
          open={open}
          setOpen={setOpen}
          label={label}
          editor={editor}
          template={template}
          setTemplate={setTemplate}
          onEditTemplate={() => {
            resetForm();
            navigateTo(TEMPLATES);
          }}
          search={search}
          setSearch={setSearch}
          filteredTemplates={filteredTemplates}
          resetForm={resetForm}
          handleOnUseTemplate={handleOnUseTemplate}
          placeholder={getTemplatePlaceHolders(t, VIDEO_QUESTIONNAIRE)}
          placeHolderError={placeHolderError}
          inputMessageContent={inputMessageContent}
          setInputMessageContent={setInputMessageContent}
        >
          <FOHView style={{ zIndex: 3 }}>
            {activeQuestionnaire && length(activeQuestionnaire) > 0 ? (
              <FOHView style={{ zIndex: 2 }}>
                <FOHSpace />
                <FOHSpaceSmallest />
                <FOHView
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'left',
                    zIndex: 2
                  }}
                >
                  <FieldLabel>
                    {t('MessageTemplatesFeature:promptLabel')}
                  </FieldLabel>
                  <FOHSpaceSmall />
                  <FOHView ref={videoPromptHoverRef}>
                    <FOHBlackHelpIcon
                      style={{
                        width: 16,
                        height: 16,
                        tintColor: FOHColors.GRAYSCALE_400
                      }}
                    />
                  </FOHView>
                  <FOHToolTip
                    width={300}
                    type={FOHToolTipType.BOTTOM_CENTER}
                    helpText={t('MessageTemplatesFeature:videoPromptInfo')}
                    open={isVideoPromptHovered}
                    style={{ bottom: -118, left: -31 }}
                  />
                </FOHView>
                <FOHView>
                  {activeQuestionnaire.map((question, keyIndex) => (
                    <Fragment key={keyIndex}>
                      <FOHSpaceSmall />
                      <FOHView
                        style={{
                          borderWidth: 1,
                          borderColor: FOHColors.GRAYSCALE_300,
                          borderRadius: 4,
                          paddingHorizontal: 16,
                          paddingVertical: 12,
                          justifyContent: 'space-between',
                          flexDirection: 'row',
                          backgroundColor: FOHColors.WHITE
                        }}
                        testID={`VideoQuestionnaireContainer-question-${
                          keyIndex + 1
                        }`}
                      >
                        <FOHLabel style={{ color: FOHColors.GRAYSCALE_900 }}>
                          <>Q.</>
                          <FOHSpace />
                          {question.question}
                        </FOHLabel>
                      </FOHView>
                    </Fragment>
                  ))}
                </FOHView>
                <FOHSpaceSmall />
              </FOHView>
            ) : (
              <></>
            )}
          </FOHView>
        </FOHMessageInputSection>
      </FOHView>
    </ActionModal>
  );
};

export default VideoScreeningQuestionnaireModal;

const FieldLabel = styled(FOHLabel)`
  font-family: ${FOHFonts.MEDIUM};
  line-height: 16px;
  text-transform: uppercase;
  color: ${FOHColors.PRIMARY_TEAL_P1};
`;
