import React from 'react';

import { View } from 'react-native';

import { useIsMobile } from '../../../utils';

import { FOHProfileImage } from '../../recipes';
import { FOHPlanState } from '../../typings';
import {
  FOHAltTextButton,
  FOHColors,
  FOHDivider,
  FOHFonts,
  FOHHeaderH1,
  FOHLabel,
  FOHLabelSmall,
  FOHPremiumPlanIcon,
  FOHSmallDetailLabel,
  FOHSpace,
  FOHSpaceLarge,
  FOHSpaceSmall,
  FOHSpaceSmallest,
  FOHStopWatchIcon,
  FOHView,
  FOHVisaIcon,
  FOHWhiteCrownIcon,
  FullWidthCard
} from '../../ingredients';

export interface FOHCurrentPlanProps {
  plan: FOHPlanState;
  usersCanCancel: boolean;

  total: string;
  totalLabel: string;
  cycle: string; // /month or /year
  cycleLongText: string; //billed monthly || per position billed monthly
  cardType?: string;
  cardNumber?: string;

  // unlimited
  unlimited: string;
  billingStartedLabel: string;
  billingStarted: string;

  payAsYouGo: string;
  costPerPosition: string;
  activePositionsLabel: string; // ACTIVE POSITIONS

  // Custom Pricing
  enterprise: string;
  aboutEnterprise: string;
  chatWithManager: string;
  onPressChatWithManage: () => void;

  chatWithManagerButton: () => React.ReactNode;

  showCancelSubscriptionBtn?: boolean;
  cancelUnlimitedSubscriptionLabel: string;
  onPressUnlimitedSubscriptionCancel: () => void;

  allPositionsLabel: string;
  allPositions: string;
  allLocationsLabel: string;
  allLocations: string;
  accountManagerLabel?: string;
  accountManager?: string;
  accountManagerPhoto?: string;
  charachterPhoto?: string;
  // position plan cells
  children?: any;
  testID?: string;
}

const PlanTotal = (props: FOHCurrentPlanProps) => {
  return (
    <View testID="plan-total">
      <FOHLabel style={{ fontSize: 32, lineHeight: 40, marginBottom: 4 }}>
        {props.totalLabel}{' '}
        <FOHLabel style={{ fontSize: 32, fontWeight: '600' }}>
          {props.total}
        </FOHLabel>
        <FOHLabel style={{ fontSize: 24, color: '#bdbdbd' }}>
          {props.cycle}
        </FOHLabel>
      </FOHLabel>
      {props.cardType && (
        <FOHLabelSmall
          style={{
            display: 'flex',
            alignSelf: 'flex-end',
            alignItems: 'center'
          }}
        >
          <FOHVisaIcon />
          <FOHView style={{ paddingLeft: 8 }}>
            {props.cardType}, {props.cardNumber}
          </FOHView>
        </FOHLabelSmall>
      )}
    </View>
  );
};

const PayAsYouGoHeader = (props: FOHCurrentPlanProps) => {
  const { isMobile } = useIsMobile();
  return (
    <View testID="PayAsYouGoHeader">
      <View
        style={{
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: isMobile ? 'flex-start' : 'space-between',
          flexWrap: 'wrap'
        }}
      >
        <View style={{ flexDirection: 'row' }}>
          <FOHStopWatchIcon style={{ width: 32, height: 32, top: 6 }} />
          <FOHSpace />
          <View>
            <FOHHeaderH1 style={{ textAlign: 'left' }}>
              {props.payAsYouGo}
            </FOHHeaderH1>
            <FOHSpaceSmall />
            <FOHLabel>
              {props.costPerPosition}
              <FOHLabel style={{ color: '#bdbdbd' }}>
                {props.cycleLongText}
              </FOHLabel>
            </FOHLabel>
          </View>
        </View>
        <View
          style={{
            alignItems: isMobile ? 'flex-end' : undefined,
            alignSelf: !isMobile ? 'flex-start' : undefined,
            paddingTop: isMobile ? 12 : undefined
          }}
        >
          <PlanTotal {...props} />
        </View>
      </View>
    </View>
  );
};

const Unlimited = (props: FOHCurrentPlanProps) => {
  const { isMobile } = useIsMobile();

  return (
    <View testID="UnlimitedHeader">
      <View
        style={{
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: isMobile ? 'flex-start' : 'space-between'
        }}
      >
        <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
          <FOHView style={{ flexDirection: 'row' }}>
            <FOHWhiteCrownIcon style={{ width: 32, height: 32, top: 6 }} />
            <FOHSpace />
            <View>
              <FOHHeaderH1 style={{ textAlign: 'left' }}>
                {props.unlimited}
              </FOHHeaderH1>
              <FOHSpaceSmall />
              <FOHLabel style={{ color: '#bdbdbd' }}>
                {props.cycleLongText}
              </FOHLabel>
            </View>
          </FOHView>
          <View
            style={{
              flexDirection: 'row',
              flexWrap: 'wrap',
              paddingLeft: isMobile ? 48 : 36,
              paddingTop: isMobile ? 12 : 0
            }}
          >
            <View>
              <FOHSmallDetailLabel>
                {props.allPositionsLabel}
              </FOHSmallDetailLabel>
              <FOHSpaceSmallest />
              <FOHLabel heavy>{props.allPositions}</FOHLabel>
            </View>
            <FOHSpaceLarge />
            <View>
              <FOHSmallDetailLabel>
                {props.billingStartedLabel}
              </FOHSmallDetailLabel>
              <FOHSpaceSmallest />
              <FOHLabel heavy>{props.billingStarted}</FOHLabel>
            </View>
            <FOHSpace />
            {props.accountManagerLabel && (
              <View>
                <FOHSmallDetailLabel>
                  {props.accountManagerLabel}
                </FOHSmallDetailLabel>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <FOHProfileImage
                    highlight={true}
                    photo={props.accountManagerPhoto}
                    style={{ width: 24, height: 24 }}
                  />
                  <FOHSpaceSmallest />
                  <FOHLabel heavy>{props.accountManager}</FOHLabel>
                </View>
              </View>
            )}
          </View>
        </View>
        <View
          style={{
            alignItems: isMobile ? 'flex-end' : undefined,
            alignSelf: !isMobile ? 'flex-start' : undefined,
            paddingTop: isMobile ? 12 : undefined
          }}
        >
          <PlanTotal {...props} />
        </View>
      </View>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          flexWrap: 'wrap'
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            flex: 1,
            justifyContent: 'flex-end',
            alignItems: 'center',
            minWidth: 200,
            paddingTop: isMobile ? 12 : 0
          }}
        >
          {props.chatWithManagerButton && props.chatWithManagerButton()}
          <FOHSpace />
          {props?.showCancelSubscriptionBtn ? (
            <View>
              <FOHAltTextButton
                style={{
                  fontFamily: FOHFonts.MEDIUM,
                  fontSize: 16,
                  fontWeight: '600',
                  textDecorationLine: 'underline',
                  color: FOHColors.BLACK
                }}
                title={props.cancelUnlimitedSubscriptionLabel}
                onPress={props.onPressUnlimitedSubscriptionCancel}
              />
            </View>
          ) : (
            <></>
          )}
        </View>
      </View>
    </View>
  );
};

const CustomPlan = (props: FOHCurrentPlanProps) => {
  return (
    <View>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          flexWrap: 'wrap'
        }}
      >
        <View
          style={{
            width: '100%',
            flexDirection: 'row',
            flex: 1,
            minWidth: 200
          }}
        >
          <FOHPremiumPlanIcon style={{ width: 42, height: 42 }} />
          <FOHSpace />
          <FOHView>
            <FOHHeaderH1 style={{ textAlign: 'left' }}>
              {props.enterprise}
            </FOHHeaderH1>
            <FOHSpaceSmall />
            <FOHLabel style={{ color: '#bdbdbd' }}>
              {props.cycleLongText}
            </FOHLabel>
          </FOHView>
          <View
            style={{
              flexDirection: 'row',
              flex: 1,
              justifyContent: 'flex-end',
              minWidth: 150,
              paddingTop: 16
            }}
          >
            <PlanTotal {...props} />
          </View>
        </View>
      </View>
      <FOHSpace />
      <FOHDivider />
      <FOHSpace />
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          paddingBottom: 16
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            flex: 1,
            minWidth: 300,
            paddingTop: 16
          }}
        >
          <View style={{ minWidth: 80 }}>
            <FOHSmallDetailLabel>{props.allLocationsLabel}</FOHSmallDetailLabel>
            <FOHSpaceSmallest />
            <FOHLabel>{props.allLocations}</FOHLabel>
          </View>
          <FOHSpace />
          <View style={{ minWidth: 80 }}>
            <FOHSmallDetailLabel>
              {props.billingStartedLabel}
            </FOHSmallDetailLabel>
            <FOHSpaceSmallest />
            <FOHLabel>{props.billingStarted}</FOHLabel>
          </View>
          <FOHSpace />
          {props.accountManagerLabel && (
            <View style={{ minWidth: 100 }}>
              <FOHSmallDetailLabel>
                {props.accountManagerLabel}
              </FOHSmallDetailLabel>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                {props.accountManagerPhoto && (
                  <FOHProfileImage
                    highlight={true}
                    photo={props.accountManagerPhoto}
                    style={{ width: 24, height: 24 }}
                  />
                )}
                <FOHSpaceSmallest />
                <FOHLabel>{props.accountManager}</FOHLabel>
              </View>
            </View>
          )}
          <FOHSmallDetailLabel>{props.aboutEnterprise}</FOHSmallDetailLabel>
          <FOHSpace />
          {props.chatWithManagerButton && props.chatWithManagerButton()}
        </View>
      </View>
    </View>
  );
};

export const FOHCurrentPlan = (props: FOHCurrentPlanProps) => {
  const { isMobile } = useIsMobile();
  return (
    <FullWidthCard style={{ padding: isMobile ? 14 : 24, maxWidth: 1108 }}>
      {props.plan === FOHPlanState.payasyougo && (
        <PayAsYouGoHeader {...props} />
      )}
      {(props.plan === FOHPlanState.unlimited ||
        props.plan === FOHPlanState.enterprise) &&
        props.usersCanCancel && <Unlimited {...props} />}
      {(props.plan === FOHPlanState.unlimited ||
        props.plan === FOHPlanState.enterprise) &&
        !props.usersCanCancel && <CustomPlan {...props} />}
      {props.children}
    </FullWidthCard>
  );
};
