import { getShareURLsForHandle } from '../api/shareUrls';
import { FOHBannerType } from '../components';

export const share = (platform, profile) => {
  getShareURLsForHandle(profile.handle).then(res => {
    window.open(res[platform]);
  });
};

export const copyProfileToClipboard = (props, profile) => {
  getShareURLsForHandle(profile.handle).then(res => {
    const hasClipboard =
      navigator && navigator.clipboard && navigator.clipboard.writeText;
    hasClipboard && navigator.clipboard.writeText(res['generic']);
    hasClipboard &&
      props.setBanner({
        bannerType: FOHBannerType.SUCCESS,
        bannerMessage: props.t('copiedToClipboard')
      });
  });
};

export const copyContactInfoToClipboard = (props, value = '') => {
  navigator.clipboard.writeText(value);
  props.setBanner({
    bannerType: FOHBannerType.SUCCESS,
    bannerMessage: props.t('copiedToClipboard')
  });
};

export const copyToClipboard = ({ onSuccess, content }) => {
  // eslint-disable-next-line no-unused-expressions
  navigator?.clipboard.writeText(content).then(() => onSuccess());
};

export const shareOnMobile = ({ title, url }) => {
  if (navigator.share) {
    navigator
      .share({ title, url })
      .then(() => {})
      .catch(error => {
        throw new Error(error);
      });
  }
};
