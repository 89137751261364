import React from 'react';

import { FOHView } from '../../ingredients';

import { FOHDayCircle } from '../cells/FOHDayCircle';

export interface FOHDayProps {
  day: string;
  availabilities: Array<string>;
}

export interface FOHAvailabilityOverviewProps {
  days: Array<string>;
  availabilities: Array<FOHDayProps>;
  disabled?: boolean;
}

export const FOHAvailabilityOverview = (
  props: FOHAvailabilityOverviewProps
) => (
  <FOHView style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
    {props.days.map((day, i) => (
      <FOHDayCircle
        day={day[0]}
        key={`day-${day[0]}${i}`}
        disabled={props.disabled}
        active={props.availabilities
          .map(availability => availability.day)
          .includes(day)}
      />
    ))}
  </FOHView>
);
