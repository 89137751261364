import React, { useMemo, useRef } from 'react';

import {
  TouchableOpacity,
  ScrollView,
  StyleProp,
  ViewStyle
} from 'react-native';
import { propEq, find, prop, defaultTo } from 'ramda';
import styled from 'styled-components/native';

import { useClickOutside } from '../../spices';
import {
  FOHCheckbox,
  FOHColors,
  FOHDivider,
  FOHExpandIcon,
  FOHFonts,
  FOHLabel,
  FOHSmallDetailLabel,
  FOHView,
  FullWidthCard,
  SPACING_SCALE
} from '../../ingredients';

import { FOHSearchBar } from '../search/FOHSearchBar';

import { FOHSelectItemProps } from './FOHSelect';
import { UpgradeBadge } from './FOHSearchableSelectDropdown';

export interface FOHDropdownSelectOption extends FOHSelectItemProps {
  sublabel: string;
  upgrade?: boolean;
}

interface FOHBorderlessSelectDropdownProps {
  children?: React.ReactNode;
  selectedValue: string;
  onValueChange: (value: string) => void;
  labelStyle?: StyleProp<ViewStyle>;
  options: Array<FOHDropdownSelectOption>;
  open: boolean;
  setOpen: (open: boolean) => void;
  onSearch?: (text: string) => void;
  searchValue?: string;
  searchPlaceholder?: string;
  large?: boolean;
  selectedLabel?: string;
  backgroundColor?: string;
  disabled?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  optionsContainerStyle?: StyleProp<ViewStyle>;
  testID?: string;
  onPressUpgrade?: (option: FOHDropdownSelectOption) => void;
  upgradeLabel?: string;
}

const SelectContainer = styled(TouchableOpacity)`
  flex-direction: row;
  align-items: center;
  minwidth: 50;
  height: 33;
  justify-content: flex-start;
  background-color: ${FOHColors.WHITE};
`;

export const FOHBorderlessSelectDropdown = (
  props: FOHBorderlessSelectDropdownProps
) => {
  const { open, setOpen } = props;
  const selectedLabel =
    defaultTo('', props.selectedLabel) ||
    defaultTo(
      '',
      prop('label', find(propEq('value', props.selectedValue))(props.options))
    );

  const selectedSubLabel = defaultTo(
    '',
    prop('sublabel', find(propEq('value', props.selectedValue))(props.options))
  );

  const dropdownRef = useRef<HTMLDivElement>(null);

  const [ref] = useClickOutside({
    close: () => !!open && props.setOpen && props.setOpen(false)
  });

  const labelMaxWidth = useMemo(() => {
    if (dropdownRef.current) {
      const width = dropdownRef.current.offsetWidth;
      return width - 27;
    }
    return 0;
  }, [dropdownRef, dropdownRef.current]);

  return (
    <FOHView ref={ref}>
      <FOHView ref={dropdownRef}>
        <SelectContainer
          onPress={(e: any) => {
            e.preventDefault();
            if (!props.disabled) setOpen(!open);
          }}
          style={{
            backgroundColor: props.backgroundColor || FOHColors.WHITE,
            ...((props.containerStyle || {}) as ViewStyle)
          }}
          testID={props.testID}
          disabled={props.disabled}
        >
          <FOHLabel
            style={{
              fontSize: props.large ? 24 : 16,
              lineHeight: props.large ? 32 : 20,
              ...((props.labelStyle || {}) as ViewStyle),
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              color: props.disabled
                ? FOHColors.GRAYSCALE_400
                : FOHColors.GRAYSCALE_700,
              fontFamily: FOHFonts.MEDIUM,
              maxWidth: labelMaxWidth
            }}
          >
            {selectedLabel}

            {!!selectedSubLabel && (
              <FOHSmallDetailLabel style={{ color: FOHColors.GRAYSCALE_500 }}>
                {` ${selectedSubLabel}`}
              </FOHSmallDetailLabel>
            )}
          </FOHLabel>
          <FOHView
            style={{
              left: 2,
              backgroundColor: props.backgroundColor || FOHColors.WHITE
            }}
            pointerEvents="none"
          >
            <FOHExpandIcon
              style={{
                tintColor: props.disabled
                  ? FOHColors.GRAYSCALE_400
                  : FOHColors.GRAYSCALE_500
              }}
            />
          </FOHView>
        </SelectContainer>
      </FOHView>
      {open && (
        <FullWidthCard
          shadow={true}
          style={{
            position: 'absolute',
            backgroundColor: FOHColors.WHITE,
            zIndex: 1,
            top: 30,
            minWidth: 270,
            left: props?.large ? 0 : -150,
            maxHeight: 350,
            ...((props.optionsContainerStyle || {}) as ViewStyle)
          }}
        >
          {props.onSearch && (
            <FOHView style={{ padding: 10 }}>
              <FOHSearchBar
                onChangeSearch={(text: string) => props.onSearch!(text)}
                searchValue={props.searchValue!}
                searchPlaceholder={props.searchPlaceholder!}
              />
            </FOHView>
          )}
          <ScrollView
            style={{
              paddingHorizontal: 12,
              maxHeight: 198,
              paddingTop: 17,
              paddingBottom: 24
            }}
          >
            {props.options.map(option => (
              <TouchableOpacity
                key={`option-${option.value}`}
                style={{
                  paddingBottom: 12,
                  paddingHorizontal: 12,
                  paddingVertical: 12,
                  borderRadius: 6,
                  minHeight: 40,
                  minWidth: 100,
                  backgroundColor:
                    props.selectedValue === option.value && !option?.upgrade
                      ? FOHColors.GRAYSCALE_10
                      : undefined,
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }}
                disabled={option?.upgrade}
                onPress={() => {
                  option?.upgrade && props.onPressUpgrade
                    ? props.onPressUpgrade({ ...option })
                    : props.onValueChange(option.value);
                }}
                testID={`list-option-${option.label}`}
              >
                <FOHView
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: option?.upgrade ? '70%' : '90%'
                  }}
                >
                  {props.selectedValue === option.value && (
                    <FOHCheckbox
                      isDisabled={option?.upgrade}
                      selected={true}
                      style={{ marginRight: SPACING_SCALE.regular }}
                    />
                  )}
                  <FOHView
                    style={{
                      justifyContent: 'center',
                      maxWidth: option?.upgrade ? '85%' : '100%'
                    }}
                  >
                    <FOHLabel
                      style={{
                        color: option?.upgrade
                          ? FOHColors.GRAYSCALE_400
                          : FOHColors.GRAYSCALE_900,
                        maxWidth: option?.upgrade ? 240 : 300
                      }}
                    >
                      {option.label}
                    </FOHLabel>
                    <FOHSmallDetailLabel
                      numberOfLines={1}
                      style={{
                        color: option?.upgrade
                          ? FOHColors.GRAYSCALE_400
                          : FOHColors.GRAYSCALE_600
                      }}
                    >
                      {option.sublabel}
                    </FOHSmallDetailLabel>
                  </FOHView>
                </FOHView>

                {option?.upgrade && (
                  <UpgradeBadge
                    onPressUpgrade={props.onPressUpgrade}
                    option={option}
                    upgradeLabel={props.upgradeLabel}
                  />
                )}
              </TouchableOpacity>
            ))}
          </ScrollView>
          {!!props.children && (
            <>
              <FOHDivider />
              <FOHView style={{ padding: 24 }}>{props.children}</FOHView>
            </>
          )}
        </FullWidthCard>
      )}
    </FOHView>
  );
};
