import React, { useState, useEffect, useMemo } from 'react';

import { compose, path, defaultTo, prop } from 'ramda';
import { Navigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  FOHFixedHeightScrollView,
  FOHView,
  FOHSpace,
  FOHButton,
  FOHAuthControlButtons,
  FOHColors,
  Screen,
  FOHFullLogo,
  FOHFormFlowButtons,
  FOHRegisterScreen
} from '../../components';
import {
  LOGIN_URL,
  CANDIDATE_ONBOARDING_2,
  EMPLOYER_ONBOARDING_2,
  EMPLOYER_ONBOARDING_1,
  GROUPS,
  CANDIDATE_SPECIFIC_REGISTER,
  EMPLOYER_SPECIFIC_REGISTER,
  EXTERNAL_APPLICATION_WIDTH,
  EXTERNAL_APPLICATION_HEIGHTTODECREASE,
  FEED
} from '../../constants';
import {
  registerScreenPostTransform,
  registerScreenErrorTransform
} from '../../api/transformers/register';
import { register } from '../../api/auth';
import { handle, storeAuth, useIsMobile } from '../../utils';
import { useNavigation } from '../../utils/navigation';
import { isEmailValid } from '../../components/utils';

import { useGetMeData, useGoogleSSOAuth } from '../SignIn';
import { useEmployerOnboardingContext } from '../EmployerOnboarding/useEmployerOnboardingContext';
import { withReactiveModal } from '../Navigation/withReactiveModal';

const RegisterScreen = props => {
  const { navigateTo } = useNavigation();

  const isModal = props.isModal;

  const meQuery = useGetMeData();

  const employerProfileStatus = useMemo(
    () => defaultTo(null, path(['employerProfile', 'status'], meQuery.data)),
    [meQuery.data]
  );

  const isEmployerRegistered = useMemo(
    () =>
      (employerProfileStatus &&
        (employerProfileStatus === 'REGISTERED' ||
          employerProfileStatus === 'SUBSCRIBED')) ||
      false,
    [employerProfileStatus]
  );

  const { state } = useLocation();

  const { isMobile } = useIsMobile();
  const { t } = useTranslation('RegisterFeature');

  const { t: errorsTFunction } = useTranslation('errors');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState();
  const [terms] = useState(false);
  const [loading, setLoading] = useState(false);

  const [authControlButtonsDisplay] = useState(!state?.group && !isModal);

  // EMPLOYER_GROUP
  const initialGroup = props.group
    ? props.group
    : state && state?.group
    ? state?.group
    : defaultTo(
        '',
        prop(
          1,
          defaultTo('', path(['history', 'location', 'search'], props)).split(
            '?group='
          )
        )
      );
  const [registerType] = useState(initialGroup);

  const { setLocation, setEmployerOnboardingState } =
    useEmployerOnboardingContext();

  const { clearSessionStates } = useGoogleSSOAuth();

  const handleSubmit = async () => {
    clearSessionStates();
    if (loading) {
      // if we are registering, don't let them through
      return;
    }
    setLoading(true);
    const registerData = registerScreenPostTransform({
      firstName,
      lastName,
      phone,
      email,
      password,
      registerType,
      terms
    });
    const [response, respError] = await handle(register({ ...registerData }));

    if (respError) {
      const err = registerScreenErrorTransform(respError.fieldErrors);
      setLoading(false);
      return setError(err);
    }

    await storeAuth(response);

    if (meQuery && meQuery.refetch) meQuery.refetch();

    const invitationDetails = prop('invitation_details', response);

    if (prop('invitedLocation', invitationDetails)) {
      setEmployerOnboardingState({
        location: {
          slug: prop('invitedLocation', invitationDetails)
        },
        invitedBy: prop('invitedBy', invitationDetails)
      });
      return navigateTo(EMPLOYER_ONBOARDING_2);
    }

    const existingLocation = path(
      ['location_details', 'existingLocation'],
      response
    );

    if (existingLocation) {
      setLocation({
        slug: existingLocation
      });
      return navigateTo(EMPLOYER_ONBOARDING_2);
    }

    if (props.onRegister) {
      setLoading(false);
      return props.onRegister(registerType);
    } else {
      const nextStep =
        registerType === 'employers'
          ? EMPLOYER_ONBOARDING_1
          : CANDIDATE_ONBOARDING_2;
      return (window.location.href = nextStep);
    }
  };

  useEffect(() => {
    try {
      !isModal &&
        window &&
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
    } catch (e) {
      return;
    }
  }, [isModal]);

  const disabledSubmit =
    !firstName ||
    !lastName ||
    !phone ||
    !email ||
    !password ||
    !confirmPassword ||
    confirmPassword !== password ||
    loading;

  useEffect(() => {
    setError(undefined);
  }, [firstName, lastName, password, confirmPassword, phone]);

  useEffect(() => {
    if (email) {
      const emailError = isEmailValid(email, errorsTFunction);
      if (emailError) {
        setError({ email: emailError });
      } else {
        setError(undefined);
      }
    } else {
      setError({ ...error, email: undefined });
    }
  }, [email]);

  return (
    <>
      {isEmployerRegistered && <Navigate to={FEED} />}
      <FOHView
        style={{
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: props.isModal ? 0 : 40
        }}
      >
        {authControlButtonsDisplay ? (
          <Screen
            style={{
              justifyContent: 'flex-start',
              alignItems: 'center',
              paddingTop: isMobile ? 60 : 80
            }}
          >
            <FOHAuthControlButtons
              icon={() => <FOHFullLogo style={{ width: 167, height: 42 }} />}
              title={t('GoogleSSOFeature:signupLabel')}
              buttons={[
                {
                  label: t('GoogleSSOFeature:asEmployerBtnLabel'),
                  onClick: () =>
                    navigateTo(
                      {
                        pathname: EMPLOYER_SPECIFIC_REGISTER
                      },
                      {
                        state: { group: GROUPS.employers }
                      }
                    )
                },
                {
                  label: t('GoogleSSOFeature:asCandidateBtnLabel'),
                  onClick: () =>
                    navigateTo(
                      {
                        pathname: CANDIDATE_SPECIFIC_REGISTER
                      },
                      {
                        state: { group: GROUPS.candidates }
                      }
                    )
                },
                {
                  label: t('GoogleSSOFeature:loginWithAccountBtnLabel'),
                  onClick: () => navigateTo(LOGIN_URL),
                  color: FOHColors.BLACK,
                  textColor: FOHColors.WHITE,
                  opaque: true,
                  selected: true
                }
              ]}
            />
          </Screen>
        ) : (
          <FOHView
            style={{
              maxWidth: isMobile ? '100%' : EXTERNAL_APPLICATION_WIDTH,
              width: '100%'
            }}
          >
            <FOHFixedHeightScrollView
              isMobile={isMobile}
              heightToDecrease={EXTERNAL_APPLICATION_HEIGHTTODECREASE}
            >
              <FOHView
                style={{
                  width: '100%',
                  maxWidth: isMobile ? '100%' : EXTERNAL_APPLICATION_WIDTH,
                  paddingTop: props.onboarding || props.isModal ? 0 : 60
                }}
              >
                <FOHRegisterScreen
                  title={t('registerTitle')}
                  subTitle={t('registerSubTitle')}
                  // submitLabel={t('nextLabel')}
                  firstName={firstName}
                  firstNamePlaceholder={t('firstName')}
                  setFirstName={setFirstName}
                  lastName={lastName}
                  lastNamePlaceholder={t('lastName')}
                  setLastName={setLastName}
                  phoneNumber={phone}
                  phoneNumberPlaceholder={t('phoneNumber')}
                  setPhoneNumber={setPhone}
                  email={email}
                  emailPlaceholder={t('email')}
                  setEmail={setEmail}
                  password={password}
                  signInInfoLabel={t('loginQuestion')}
                  signInLabel={t('loginHere')}
                  handleSignIn={() => navigateTo(LOGIN_URL)}
                  passwordPlaceholder={t('password')}
                  setPassword={setPassword}
                  confirmPassword={confirmPassword}
                  confirmPasswordPlaceholder={t('confirmPassword')}
                  setConfirmPassword={value => setConfirmPassword(value)}
                  error={
                    password && confirmPassword !== password
                      ? { confirmPassword: t('passwordsMustMatch'), ...error }
                      : error
                  }
                  handleSubmit={handleSubmit}
                  disabledSubmit={
                    !firstName ||
                    !lastName ||
                    !phone ||
                    !email ||
                    !password ||
                    !confirmPassword
                  }
                  largeHeader={!isMobile}
                  hidePassText={t('common:hidePassText')}
                  showPassText={t('common:showPassText')}
                />
              </FOHView>
              {props.isModal && (
                <>
                  <FOHSpace />
                  <FOHButton
                    title={loading ? t('common:loading') : t('nextLabel')}
                    onPress={handleSubmit}
                    disabled={disabledSubmit}
                    enableHover={disabledSubmit}
                    helpText={
                      disabledSubmit && !loading
                        ? `${t('common:requiredAllFields')}`
                        : ''
                    }
                  />
                </>
              )}
            </FOHFixedHeightScrollView>
            <FOHView
              style={{
                width: '100%',
                maxWidth: isMobile ? '100%' : EXTERNAL_APPLICATION_WIDTH,
                marginBottom: isMobile ? 0 : 10
              }}
            >
              {!props.isModal && (
                <>
                  <FOHSpace />
                  <FOHFormFlowButtons
                    isMobile={isMobile}
                    nextButtonlabel={
                      loading ? t('common:loading') : t('nextLabel')
                    }
                    onPressNextButton={handleSubmit}
                    helpTextOnNext={
                      disabledSubmit && !loading
                        ? `${t('common:requiredAllFields')}`
                        : ''
                    }
                    isNextDisabled={disabledSubmit}
                  />
                  <FOHSpace />
                </>
              )}
            </FOHView>
          </FOHView>
        )}
      </FOHView>
    </>
  );
};

const RegisterInModal = compose(withReactiveModal)(RegisterScreen);

export const RegisterModal = props => (
  <RegisterInModal
    {...props}
    innerStyle={{ paddingLeft: 0, paddingRight: 0 }}
  />
);
export default RegisterScreen;
