import React, { useState, useEffect } from 'react';

import { prop } from 'ramda';

export const useIsMobile = () => {
  const defaultWidth =
    prop('innerWidth', window) && window ? prop('innerWidth', window) : 1080;
  const defaultHeight =
    prop('innerHeight', window) && window ? prop('innerHeight', window) : 900;
  const [width, setWidth] = useState(defaultWidth);
  const [height, setHeight] = useState(defaultHeight);

  useEffect(() => {
    const handleResize = () => {
      prop('innerHeight', window) && setHeight(prop('innerHeight', window));
      prop('innerWidth', window) && setWidth(prop('innerWidth', window));
      return;
    };
    prop('addEventListener', window) &&
      window.addEventListener('resize', handleResize);

    return () => {
      prop('removeEventListener', window) &&
        window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {
    width,
    height,
    isMobile: width <= 960
  };
};

export const withIsMobile = Cmp => props => {
  const { width, isMobile } = useIsMobile();
  return (
    <Cmp {...props} width={width} isMobile={isMobile}>
      {props.children}
    </Cmp>
  );
};
