import React from 'react';

import notificationAlertChat from '../../../images/notification-alert-chat.svg';

import { FOHImage } from '../../ingredients';

export interface FOHNotificationAlertChatProps {}

export const FOHNotificationAlertChat = (
  props: FOHNotificationAlertChatProps
) => (
  <FOHImage
    {...props}
    source={{ uri: notificationAlertChat }}
    style={{ width: 24, height: 24 }}
  />
);
