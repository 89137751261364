import React from 'react';

import { View, TouchableOpacity } from 'react-native';

import {
  FOHRightArrow,
  FOHHeaderH3,
  FOHDetailText,
  FOHLabelBold,
  FOHSpaceSmall,
  FOHDivider,
  FOHSpace
} from '../../ingredients';

export interface FOHJobCellProps {
  // Pass in web link component so bots can crawl from page to page
  a: any;
  onPress: (slug: string, externalApplyUrl?: string) => any;
  ctaText: string;
  hasBottomDivider?: boolean;
}

export interface FOHSmallJobPost {
  slug: string;
  name: string;
  formattedAddress: string;
  url: string;
  externalApplyUrl?: string;
}

interface JobCellProps extends FOHJobCellProps, FOHSmallJobPost {}

export const FOHJobCell = (props: JobCellProps) => (
  <>
    <TouchableOpacity
      onPress={() => props.onPress(props.slug, props.externalApplyUrl)}
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          flex: 1
        }}
      >
        <View style={{ flexDirection: 'row', flex: 1 }}>
          <View style={{ top: 4 }}>
            <FOHRightArrow />
          </View>
          <FOHSpaceSmall />
          <View
            style={{
              width: '90%'
            }}
          >
            <FOHHeaderH3 style={{ textAlign: 'left' }}>
              {props.name}
            </FOHHeaderH3>
            <FOHDetailText
              style={{
                fontSize: 12,
                textAlign: 'left'
              }}
            >
              {props.formattedAddress}
            </FOHDetailText>
          </View>
        </View>
        <props.a
          style={{
            textDecoration: 'none'
          }}
          href={props.url}
        >
          <FOHLabelBold
            style={{
              color: '#51C1DB',
              top: 8
            }}
          >
            {props.ctaText}
          </FOHLabelBold>
        </props.a>
      </View>
    </TouchableOpacity>
    {props.hasBottomDivider && (
      <>
        <FOHSpace /> <FOHDivider />
      </>
    )}
  </>
);
