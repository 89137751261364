import React, { useState } from 'react';

import { defaultTo, path, prop } from 'ramda';
import { useTranslation } from 'react-i18next';

import { findById, handleMutation, unwrapEdgesAt } from '../../utils';
import {
  EmployerCandidatesDocument,
  EmployerStagesDocument,
  useMyPositionsQuery,
  useSetPreferredMatchMutation
} from '../../graphql/generated';
import { FOHPreferredMatchBlock, FOHView } from '../../components';

import { useCandidateFeedContext } from '../CandidateFeed/useCandidateFeedContext';

export const PreferredMatchSelect = props => {
  const { employerCandidateId, preferredMatchQuery } = props;
  const { t } = useTranslation('PreferredMatchFeature');
  const { positionFilters, locationFilters, activeStageId } =
    useCandidateFeedContext();

  const [search, setSearch] = useState('');

  const positionsQuery = useMyPositionsQuery({
    variables: { search, locations: locationFilters }
  });

  const [setPreferredPositionMatch] = useSetPreferredMatchMutation();

  const positions = unwrapEdgesAt(
    ['data', 'myPositions', 'edges'],
    positionsQuery
  )
    .filter(
      position =>
        position.paymentStatus === 'SUBSCRIBED' ||
        position.location.paymentStatus === 'SUBSCRIBED'
    )
    .map(position => ({
      label: `${prop('positionName', position)} - `,
      value: prop('id', position),
      sublabel: `${path(['location', 'name'], position)} - ${path(
        ['location', 'formattedAddress'],
        position
      )}`
    }));

  const selectedPosition = defaultTo(
    '',
    path(
      ['data', 'employerCandidate', 'preferredMatch', 'position', 'id'],
      preferredMatchQuery
    )
  );

  return (
    <FOHView style={{ zIndex: 2, width: '100%' }}>
      <FOHPreferredMatchBlock
        positionSelectLabel={t('positionAssociated')}
        positions={positions}
        positionPlaceholder={t('positionPlaceholder')}
        positionSearchText={search}
        searchPlaceholder={t('common:search')}
        setPositionSearchText={setSearch}
        selectedPosition={selectedPosition}
        setSelectedPosition={async pos => {
          // no need for response or error yet
          // WYSIWYG with the dropdown
          await handleMutation(
            setPreferredPositionMatch({
              variables: {
                positionId: pos,
                employerCandidateId: employerCandidateId
              },
              // prevents race condition for moving candidates right after setting their position
              awaitRefetchQueries: true,
              refetchQueries: [
                // refetch common related queries
                {
                  query: EmployerStagesDocument,
                  variables: {
                    positions: positionFilters,
                    locations: locationFilters
                  }
                },
                {
                  query: EmployerStagesDocument,
                  variables: {
                    positions: [pos],
                    locations: locationFilters
                  }
                },
                {
                  query: EmployerCandidatesDocument,
                  variables: {
                    first: 20,
                    after: '',
                    stageId: activeStageId,
                    positions: [pos],
                    locations: locationFilters,
                    search: '',
                    notificationStatuses: [],
                    notificationType: ''
                  }
                }
              ]
            })
          );
          // may need more refetching or manual cache updates potentially
          props.onChangePosition && (await props.onChangePosition());
        }}
        selectedPositionLabel={
          prop('label', findById(selectedPosition, positions, 'value')) ||
          t('noPositionSelected')
        }
        isMobile={true}
        optionsContainerStyle={{
          minWidth: 260
        }}
      />
    </FOHView>
  );
};
