import React, { useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { path } from 'ramda';
import { useHover } from 'react-native-web-hooks';

import headerdecoration from '../../images/sendscheduleorsettime.svg';
import sharedecoration from '../../images/share_schedule_form_decoration.svg';
import settimedecoration from '../../images/illustration-set-date-time.svg';
import {
  FOHLabel,
  FOHLabelBold,
  FOHHighlightWordsLabel,
  FOHColors,
  FOHSpaceSmall,
  FOHSpace,
  FullWidthCard,
  FOHView,
  FOHTouchableOpacity,
  FOHDetailText,
  FOHHeaderH2,
  FOHHeaderH3,
  FOHToolTip,
  FOHToolTipType
} from '../../components';
import { toDateTime } from '../../utils';
import { useGetCandidateQuery } from '../../graphql/generated';
import { FEED, INTERVIEW_SCHEDULE, SEND_SCHEDULE } from '../../constants';
import { useNavigation } from '../../utils/navigation';

import { withReactiveModal } from '../Navigation/withReactiveModal';

export const SendScheduleOrSchedule = props => {
  const { handle, hasInterviewSchedule } = props;
  const { t } = useTranslation('SendScheduleOrSchedule');
  const { navigateTo } = useNavigation();

  const candidateQuery = useGetCandidateQuery({
    skip: !handle,
    variables: { handle }
  });
  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);

  const profile = path(['data', 'profile'], candidateQuery);

  return (
    <FOHView
      style={{ width: '100%', maxWidth: 600 }}
      testID="schedule-interview-modal"
    >
      <img
        src={headerdecoration}
        style={{ height: 64 }}
        alt="decoration-send-or-set-schedule"
      />
      <FOHHighlightWordsLabel
        labelCmp={FOHHeaderH2}
        emphasizedWords={[
          t('automateRemindersHighlight', t('textMessagesHighlight'))
        ]}
        highlightColor={FOHColors.RED}
        boldLabelCmp={FOHHeaderH2}
      >
        {t('headerLabel')}
      </FOHHighlightWordsLabel>
      <FOHDetailText>{t('schedulingWillAutomateReminders')}</FOHDetailText>
      {path(['lastInterview', 'scheduledFor'], profile) ? (
        <FOHView
          style={{
            backgroundColor: FOHColors.LIGHT_ORANGE,
            height: 88,
            borderRadius: 16,
            padding: 24,
            paddingTop: 16,
            paddingBottom: 16
          }}
        >
          <FOHLabel style={{ color: FOHColors.ORANGE }}>
            {t('currentDateAndTime')}
          </FOHLabel>
          <FOHSpaceSmall />
          <FOHLabelBold>
            {toDateTime(path(['lastInterview', 'scheduledFor'], profile))}
          </FOHLabelBold>
        </FOHView>
      ) : (
        <></>
      )}
      <FOHSpace />
      <FOHView style={{ flexDirection: 'row', alignItems: 'space-between' }}>
        <FullWidthCard
          ref={hoverRef}
          style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            padding: 16,
            backgroundColor: !hasInterviewSchedule && FOHColors.GRAYSCALE_100
          }}
        >
          {!hasInterviewSchedule ? (
            <FOHToolTip
              helpText={t('common:noInterviewScheduleForSchedulerText')}
              open={isHovered}
              type={FOHToolTipType.CENTER}
              style={{ width: 250, top: -250 }}
            />
          ) : (
            <></>
          )}
          <FOHTouchableOpacity
            testID="sendSchedule"
            onPress={() => {
              props.pathname
                ? navigateTo(`${props.pathname}${SEND_SCHEDULE}`)
                : navigateTo(`${FEED}${SEND_SCHEDULE}`);
            }}
            style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
            disabled={!hasInterviewSchedule}
          >
            <img
              src={sharedecoration}
              style={{
                height: 100,
                width: 100,
                opacity: !hasInterviewSchedule && 0.5
              }}
              alt="decoration-send-schedule"
            />
            <FOHHeaderH3
              style={{
                color: !hasInterviewSchedule && FOHColors.GRAYSCALE_400
              }}
            >
              {t('shareSchedule')}
            </FOHHeaderH3>
            <FOHDetailText>{t('shareScheduleDetailText')}</FOHDetailText>
            <FOHSpaceSmall />
          </FOHTouchableOpacity>
        </FullWidthCard>
        <FOHSpace />
        <FullWidthCard
          style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            padding: 16
          }}
        >
          <FOHTouchableOpacity
            testID="setInterview"
            onPress={() => {
              const interviewId = path(['lastInterview', 'id'], profile);
              const navigatePath = props.pathname || FEED;

              navigateTo(
                { pathname: `${navigatePath}${INTERVIEW_SCHEDULE}` },
                {
                  state: interviewId ? { interviewId } : undefined
                }
              );
            }}
            style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
          >
            <img
              src={settimedecoration}
              style={{ height: 100, width: 100 }}
              alt="decoration-set-schedule"
            />
            <FOHHeaderH3>{t('setInterview')}</FOHHeaderH3>
            <FOHDetailText>{t('setInterviewDetailText')}</FOHDetailText>
            <FOHSpaceSmall />
          </FOHTouchableOpacity>
        </FullWidthCard>
      </FOHView>
    </FOHView>
  );
};

export const SendScheduleOrScheduleModal = withReactiveModal(
  SendScheduleOrSchedule
);
