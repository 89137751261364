import React from 'react';

import { useTranslation } from 'react-i18next';
import { path, prop } from 'ramda';

import { useIsMobile } from '../../utils';
import {
  FOHChatCreateNewIcon,
  FOHColors,
  FOHGetFreePassiveCandidatesBanner,
  FOHLookingStatusIcon,
  FOHReminderIcon,
  FOHTeamIcon
} from '../../components';
import { FEED, UPGRADE } from '../../constants';
import { useNavigation } from '../../utils/navigation';

export const FreePassiveCandidatesBanner = props => {
  const { t } = useTranslation('CandidateFeed');
  const { isMobile } = useIsMobile();

  const { navigateTo } = useNavigation();
  return (
    <FOHGetFreePassiveCandidatesBanner
      isMobile={isMobile}
      thumbnails={
        prop('candidates', props).length > 3
          ? path(['candidates'], props)
              .map(candidate => ({
                uri: path(
                  ['candidateProfile', 'user', 'profileImage'],
                  candidate
                )
              }))
              .slice(0, 3)
          : [1, 2, 3].map(() => ({ uri: '' }))
      }
      totalCount={
        prop('candidates', props).length > 3
          ? prop('candidates', props).length - 3
          : ''
      } // put counts only when having
      labelLHS={t('accessOurNetworkLabel')}
      titleLHS={t('accessNetworklInMarketPlaceLabel')}
      descLHS={t('passiveCandidatesHireDesc')}
      submitBtnLabel={t('freePassiveCandidatesBtnLabel')}
      onPressSubmit={() => navigateTo(`${FEED}${UPGRADE}`)}
      labelRHS={t('howPassiveWorksLabel')}
      stepsRHS={[
        {
          icon: () => (
            <FOHLookingStatusIcon
              style={{
                width: 26,
                height: 26,
                tintColor: FOHColors.PACIFIC_BLUE
              }}
            />
          ),
          headerText: t('step1Title'),
          detailText: t('step1DetailText')
        },
        {
          icon: () => (
            <FOHTeamIcon
              style={{
                width: 26,
                height: 26,
                tintColor: FOHColors.PACIFIC_BLUE
              }}
            />
          ),
          headerText: t('step2Title'),
          detailText: t('step2DetailText')
        },
        {
          icon: () => (
            <FOHChatCreateNewIcon
              style={{
                width: 26,
                height: 26,
                tintColor: FOHColors.PACIFIC_BLUE
              }}
            />
          ),
          headerText: t('step3Title'),
          detailText: t('step3DetailText')
        },
        {
          icon: () => <FOHReminderIcon />,
          headerText: t('step4Title'),
          detailText: t('step4DetailText')
        }
      ]}
    />
  );
};
