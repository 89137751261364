import React from 'react';

import { compose, prop, path, defaultTo, toLower } from 'ramda';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

import {
  FOHLabel,
  FOHLabelBold,
  FOHPlanState,
  FOHButton,
  FOHBannerType,
  FOHView,
  FOHSpaceSmallest,
  FOHSpace,
  FullWidthCard,
  FOHGreenCircleDecoration,
  FOHStopWatchIcon,
  FOHButtonBordered,
  FOHDetailText,
  FOHHeaderH2,
  FOHHeaderH3,
  FOHSmallDetailLabel
} from '../../components';
import { FEED, getAppBaseUrl } from '../../constants';
import {
  CalculatePriceDocument,
  useDeactivateSubscriptionMutateMutation,
  useGetPositionToPayForQuery,
  useReactivateSubscriptionMutationMutation
} from '../../graphql/generated';
import { handleMutation, toDate, useIsMobile } from '../../utils';
import { useNavigation } from '../../utils/navigation';

import { useCurrentLocationContext } from '../Locations/useCurrentLocationContext';
import { useBanner } from '../Navigation/useBanner';
import { usePositionTypes } from '../CandidateProfile/usePositionTypes';
import { withReactiveModal } from '../Navigation';

import { usePaymentPlan, formatPricing } from './usePaymentPlan';
import { useCheckout } from './useCheckout';
import { withStripe } from './withStripe';

const ChangePositionPlan = props => {
  const { position, pause } = props;
  const { navigateTo, location: navLocation } = useNavigation();
  const { isMobile } = useIsMobile();
  const { setBanner } = useBanner();
  const positionQuery = useGetPositionToPayForQuery({
    variables: { id: position }
  });

  const { locationFilter } = useCurrentLocationContext({ props });

  const { singlePrice, planName, cycle, subscribedPrice } = usePaymentPlan({
    locationFilter,
    currentPlanQ: true,
    subscribedQ: true,
    singleQ: true
  });

  const [reactivateSubscription] = useReactivateSubscriptionMutationMutation();
  const [deactivateSubscription] = useDeactivateSubscriptionMutateMutation();

  const { getStringForPositionType } = usePositionTypes();

  const positionToChange = path(['data', 'positionById'], positionQuery);
  const prices = formatPricing({
    notSubscribedPrice: undefined,
    subscribedPrice,
    subscribed: true,
    monthly: cycle === 'monthly'
  });

  const monthly = cycle === 'monthly';
  const currentCost = !planName
    ? '$0'
    : monthly
    ? path([toLower(planName), 'displayPerMonth'], prices)
    : path([toLower(planName), 'total'], prices);

  const sign = pause ? '-' : '+';

  const payAsYouGoCycle = 'monthlyPayAsYouGo';

  const price = path([payAsYouGoCycle, 'total'], singlePrice)
    ? `${sign}${path([payAsYouGoCycle, 'total'], singlePrice)}`
    : '...';
  const { t } = useTranslation('PricingScreenFeature');
  const { redirectToStripeCheckout } = useCheckout(props);

  const now =
    pause && !positionQuery.loading
      ? DateTime.fromSeconds(
          defaultTo(
            0,
            path(
              ['payment', 'subscription', 'currentPeriodEnd'],
              positionToChange
            )
          )
        )
      : DateTime.local().toISO();

  const billingDate = positionQuery.loading ? '' : toDate(now);

  return (
    <FOHView
      style={{ width: '100%', padding: 24 }}
      testID="ChangePositionPlanModal"
    >
      <FOHHeaderH2 style={{ textAlign: 'left' }}>
        {t('manageYourBillingModalTitle')}
      </FOHHeaderH2>
      {planName ? (
        <FOHDetailText style={{ alignItems: 'center', flexWrap: 'wrap' }}>
          {t('currentBillingTotal')} <FOHLabelBold>{currentCost} </FOHLabelBold>
          <FOHLabel>{t('billedMonthly')}</FOHLabel>
          {planName === FOHPlanState.payasyougo ? (
            <FOHStopWatchIcon style={{ width: 20, height: 20 }} />
          ) : (
            <FOHGreenCircleDecoration style={{ width: 20, height: 20 }} />
          )}
          <FOHLabelBold style={{ alignItems: 'center' }}>
            {' '}
            {planName === FOHPlanState.payasyougo
              ? t('payAsYouGo')
              : t('unlimited')}
          </FOHLabelBold>
        </FOHDetailText>
      ) : (
        <></>
      )}
      <FOHSpace />
      <FOHHeaderH3 style={{ textAlign: 'left' }}>
        {t('subscriptionSubject')}
      </FOHHeaderH3>
      <FOHSpace />
      <FullWidthCard
        style={{
          padding: 24,
          paddingTop: 0,
          flexDirection: 'row',
          flexWrap: 'wrap'
        }}
      >
        <FOHView style={{ flex: 2, minWidth: 200, paddingTop: 24 }}>
          <FOHLabelBold>{prop('positionName', positionToChange)}</FOHLabelBold>
          <FOHSmallDetailLabel>
            {defaultTo([], prop('positionType', positionToChange))
              .map(pos => getStringForPositionType(pos))
              .join(', ')}
          </FOHSmallDetailLabel>
        </FOHView>
        <FOHView
          style={{
            flex: 0.8,
            alignItems: 'flex-end',
            minWidth: 100,
            paddingTop: 24
          }}
        >
          <FOHSmallDetailLabel>
            {pause ? t('nextBillingDate') : t('estBillingDate')}
          </FOHSmallDetailLabel>
          <FOHSpaceSmallest />
          <FOHLabel>{billingDate}</FOHLabel>
        </FOHView>
        <FOHView
          style={{
            flex: 0.8,
            alignItems: 'flex-end',
            minWidth: 100,
            paddingTop: 24
          }}
        >
          <FOHSmallDetailLabel>{t('price')}</FOHSmallDetailLabel>
          <FOHSpaceSmallest />
          <FOHLabelBold>{price}</FOHLabelBold>
        </FOHView>
      </FullWidthCard>
      <FOHSpace />
      <FOHSmallDetailLabel>{t('aboutPositionUpgrade')}</FOHSmallDetailLabel>
      <FOHSpace />
      <FOHHeaderH3 style={{ textAlign: 'left' }}>
        {pause
          ? t('areYouSureYouWantToCancel')
          : t('doYouWantToAddThisPositionToYourPlan')}
      </FOHHeaderH3>
      <FOHDetailText>
        {pause
          ? t('youWontBeAbleToRequestInterviews')
          : t('youWillBeAbleToRequestInterviews')}
      </FOHDetailText>
      <FOHSpace />
      <FOHView
        style={{
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'center'
        }}
      >
        <FOHButton
          onPress={async () => {
            const refetchQueries = [
              {
                query: CalculatePriceDocument,
                variables: {
                  location: locationFilter,
                  paymentStatus: 'notsubscribed'
                }
              },
              {
                query: CalculatePriceDocument,
                variables: {
                  location: locationFilter,
                  paymentStatus: 'subscribed'
                }
              }
            ];
            if (
              !pause &&
              (!path(['payment', 'subscriptionWillEndOn'], positionToChange) ||
                path(['payment', 'subscriptionEndedOn'], positionToChange))
            ) {
              // starting a new subscription
              return redirectToStripeCheckout({
                url: `${getAppBaseUrl()}/feed?position=${prop(
                  'id',
                  positionToChange
                )}&location=${path(['location', 'id'], positionToChange)}`,
                positions: [prop('rowId', positionToChange)],
                locations: [path(['location', 'rowId'], positionToChange)],
                plan: 'payasyougo',
                cycle
              });
            }

            if (
              !pause &&
              path(['payment', 'subscriptionWillEndOn'], positionToChange) &&
              !path(['payment', 'subscriptionEndedOn'], positionToChange)
            ) {
              // subscription exists and is ending
              await handleMutation(
                reactivateSubscription({
                  variables: {
                    stripeSubscription: path(
                      ['payment', 'subscription', 'id'],
                      positionToChange
                    ),
                    // just reactivating 1 position
                    positions: [prop('id', positionToChange)]
                  },
                  refetchQueries
                })
              );
            } else if (pause) {
              // pausing a position
              await handleMutation(
                deactivateSubscription({
                  variables: {
                    position: prop('id', positionToChange),
                    stripeSubscription: path(
                      ['payment', 'subscription', 'id'],
                      positionToChange
                    ),
                    cancellationReason: prop('cancellationReason', props)
                  },
                  refetchQueries
                })
              );
            }

            setBanner({
              bannerType: FOHBannerType.SUCCESS,
              bannerMessage: t('subscriptionUpdated')
            });
            props.onCancel && props.onCancel();
            navigateTo({
              pathname: props.backPath || FEED,
              search: navLocation.search
            });
          }}
          style={{ width: isMobile ? '100%' : '50%' }}
          title={
            pause
              ? t('sureCancel')
              : path(['payment', 'subscriptionWillEndOn'], positionToChange) &&
                !path(['payment', 'subscriptionEndedOn'], positionToChange)
              ? t('reactivateSubscription')
              : t('yesProcessThePayment')
          }
        />
        <FOHSpace />
        <FOHButtonBordered
          onPress={props.close}
          style={{ width: isMobile ? '100%' : '50%' }}
          title={t('dismissForNow')}
        />
      </FOHView>
    </FOHView>
  );
};

export const ChangePositionPlanModal = compose(
  withStripe,
  withReactiveModal
)(ChangePositionPlan);
