import React from 'react';

import notificationAlertInterview from '../../../images/notification-alert-interview.svg';

import { FOHImage } from '../../ingredients';

export interface FOHNotificationAlertInterviewProps {}

export const FOHNotificationAlertInterview = (
  props: FOHNotificationAlertInterviewProps
) => (
  <FOHImage
    {...props}
    source={{ uri: notificationAlertInterview }}
    style={{ width: 24, height: 24 }}
    testID={'FOHNotificationAlertInterview'}
  />
);
