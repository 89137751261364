import React, { useRef } from 'react';

import styled from 'styled-components/native';
import {
  StyleProp,
  ViewStyle,
  TextInput,
  TouchableOpacity
} from 'react-native';
import { useFocus } from 'react-native-web-hooks';

import {
  FOHFonts,
  FOHColors,
  FOHView,
  FOHGrayX,
  FOHSearch
} from '../../ingredients';
import { FOH_INPUT_HEIGHT } from '../../constants';

export interface FOHSearchBarProps {
  hideSearch?: boolean;
  onChangeSearch: (val: string) => void;
  onKeyPress?: (e: any) => void;
  clearSearch?: () => any;
  searchPlaceholder: string;
  searchValue: string;
  style?: StyleProp<ViewStyle>;
  inputStyle?: any;
  disabled?: boolean;
}

const Container = styled(FOHView)`
  background: ${(props: any) =>
    props.disabled ? FOHColors.GRAYSCALE_100 : FOHColors.WHITE};
  border: ${(props: any) =>
    props.disabled
      ? `1px solid ${FOHColors.GRAYSCALE_200}`
      : `1px solid ${FOHColors.GRAYSCALE_300}`};
  border-radius: 4px;
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 200%;
  display: flex;
  align-items: center;
  width: 100%;
  height: ${FOH_INPUT_HEIGHT}px;
  flex-direction: row;
  padding: 10px 20px;
`;

const Input = styled(TextInput)`
  background: ${(props: any) =>
    props.disabled ? FOHColors.GRAYSCALE_100 : FOHColors.WHITE};
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 46;
  flex-direction: row;
  padding: 10px 12px;
  outline-color: transparent;
`;

export const FOHSearchBar = ({
  hideSearch,
  onChangeSearch,
  onKeyPress,
  clearSearch,
  searchPlaceholder,
  searchValue,
  style,
  inputStyle,
  disabled
}: FOHSearchBarProps) => {
  const ref = useRef(null);
  const isInputActive = useFocus(ref);
  if (hideSearch) return null;

  return (
    <Container
      disabled={disabled}
      style={[
        {
          borderColor: disabled
            ? FOHColors.GRAYSCALE_200
            : isInputActive
            ? FOHColors.PRIMARY_TEAL_P1
            : FOHColors.GRAYSCALE_300
        },
        style
      ]}
    >
      <>
        <FOHSearch
          style={{
            tintColor: disabled
              ? FOHColors.GRAYSCALE_400
              : isInputActive
              ? FOHColors.PRIMARY_TEAL_P1
              : FOHColors.GRAYSCALE_500
          }}
        />

        <Input
          ref={ref}
          onChangeText={onChangeSearch}
          value={searchValue}
          placeholder={searchPlaceholder}
          onKeyPress={onKeyPress}
          disabled={disabled}
          style={{ ...inputStyle }}
        />

        {searchValue && clearSearch ? (
          <TouchableOpacity
            onPress={() => {
              if (!disabled) {
                clearSearch();
              }
            }}
            style={{
              position: 'absolute',
              right: 10,
              zIndex: 99
            }}
          >
            <FOHGrayX
              style={{
                width: 20,
                height: 20,
                tintColor: FOHColors.GRAYSCALE_500
              }}
            />
          </TouchableOpacity>
        ) : null}
      </>
    </Container>
  );
};
