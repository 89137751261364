import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-hooks';
import { defaultTo, path, prop } from 'ramda';

import { unwrapEdgesAt } from '../../utils/unwrapEdges.js';
import { useIsMobile } from '../../utils/withIsMobile.js';
import { useGetMyLocationsDashboardQuery } from '../../graphql/generated';
import {
  ADD_NEW_LOCATION,
  EMPLOYER_DASHBOARD,
  EMPLOYER_SETTINGS_MENU,
  LOCATION,
  RESTAURANT_JOBS
} from '../../constants/urls.js';
import { ALLOWED_ROLES_GROUPS } from '../../constants';
import {
  FOHLocationCard,
  RestrictedView,
  FOHColors,
  FOHSearchBar,
  Screen,
  FOHCircleLoader,
  FOHView,
  FOHStopWatchIcon,
  FOHWhiteCrownIcon,
  FOHButtonBordered,
  FOHHeaderH2,
  FOHInverseButton,
  FOHWhiteAddIcon,
  FOHSpace,
  FOHPremiumPlanIcon
} from '../../components';
import { useNavigation } from '../../utils/navigation';

import { useSidebarNav } from '../Navigation/useSidebar.js';
import { useCurrentLocationContext } from '../Locations/useCurrentLocationContext.js';
import { useFeatures } from '../SignIn/useFeatures';

import { ManageLocationPositionModals } from './ManageLocationPositionModals';

const EmployerLocationsDashboard = props => {
  const { t } = useTranslation('EmployerDashboardFeature');
  const { navigateTo } = useNavigation();
  const { isMobile } = useIsMobile();

  const [search, setSearch] = useState('');
  const [loadingMore, setLoadingMore] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [initalLoad, setInitialLoad] = useState(false);
  const { loadingFeatureFlags } = useFeatures();

  const debouncedSearch = useDebounce(search, 1000);

  const variables = {
    first: 5,
    after: '',
    search: debouncedSearch,
    sortBy: 'alpha'
  };

  const myLocationsDashboardQuery = useGetMyLocationsDashboardQuery({
    variables: {
      ...variables
    }
  });

  const locations = defaultTo(
    [],
    unwrapEdgesAt(
      ['data', 'me', 'myLocations', 'edges'],
      myLocationsDashboardQuery
    )
  );

  const loadMore = async () => {
    if (!loadingMore && isMounted) {
      setLoadingMore(true);
      await myLocationsDashboardQuery.fetchMore({
        variables: {
          ...variables,
          after:
            myLocationsDashboardQuery.data.me.myLocations.pageInfo.endCursor
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const newEdges = fetchMoreResult.me.myLocations.edges;
          const pageInfo = fetchMoreResult.me.myLocations.pageInfo;
          return newEdges.length
            ? {
                me: {
                  __typename: previousResult.me.__typename,
                  id: previousResult.me.id,
                  myLocations: {
                    __typename: previousResult.me.myLocations.__typename,
                    edges: [
                      ...previousResult.me.myLocations.edges,
                      ...newEdges
                    ],
                    pageInfo
                  }
                }
              }
            : previousResult;
        }
      });
      setLoadingMore(false);
    }
  };

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    if (
      !initalLoad &&
      path(
        ['data', 'me', 'myLocations', 'pageInfo', 'hasNextPage'],
        myLocationsDashboardQuery
      )
    ) {
      loadMore();
      setInitialLoad(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myLocationsDashboardQuery.loading]);

  const { selectLocationFilter } = useCurrentLocationContext({
    props,
    skipLocationsQuery: true
  });
  const { hasSideNav } = useSidebarNav(props);

  if (!isMounted) {
    return <></>;
  }

  if (loadingFeatureFlags) {
    return <FOHCircleLoader />;
  }

  return (
    <Screen
      style={{
        minWidth: 1280,
        alignItems: 'flex-start'
      }}
    >
      <FOHView
        style={{
          alignItems: 'flex-start',
          width: '100%',
          minHeight: '100vh',
          paddingTop: hasSideNav ? 0 : 50,
          maxWidth: 1920
        }}
      >
        <FOHView
          style={{
            width: '100%',
            height: '100%',
            alignItems: 'flex-start',
            paddingHorizontal: 24,
            paddingVertical: 16,
            backgroundColor: FOHColors.WHITE
          }}
        >
          <FOHHeaderH2
            style={{
              fontWeight: 700,
              fontStyle: 'normal',
              color: FOHColors.GRAYSCALE_G1,
              marginLeft: 5
            }}
          >
            {`${t('TopNavFeature:dashboard')}`}
          </FOHHeaderH2>
          <FOHSpace />
          <FOHView
            style={{
              width: '100%',
              flexDirection: 'row',
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <FOHView style={{ padding: 5, maxWidth: 328, width: '100%' }}>
              <FOHSearchBar
                onChangeSearch={text => setSearch(text)}
                searchValue={search}
                searchPlaceholder={t('searchPlaceholder')}
                style={{ height: 48, maxWidth: 550, width: '100%' }}
                inputStyle={{ height: 42 }}
              />
            </FOHView>
            <RestrictedView
              allowedRoles={ALLOWED_ROLES_GROUPS.ROLES.ADMIN}
              allowedGroups={[ALLOWED_ROLES_GROUPS.GROUPS.EMPLOYER]}
            >
              <FOHView
                style={{ maxWidth: 157, width: '100%', marginRight: 16 }}
              >
                <FOHInverseButton
                  title={t('addNewLocationButton')}
                  icon={() => (
                    <FOHWhiteAddIcon style={{ width: 16, height: 16 }} />
                  )}
                  onPress={() => {
                    navigateTo(`${ADD_NEW_LOCATION}`, {
                      state: { originFrom: EMPLOYER_DASHBOARD }
                    });
                  }}
                  style={{ height: 48, flex: 1, alignItems: 'center' }}
                  opaque
                  selected
                  textColor={FOHColors.WHITE}
                  color={FOHColors.PRIMARY_TEAL_P1}
                />
              </FOHView>
            </RestrictedView>
          </FOHView>
        </FOHView>

        <FOHView
          style={{
            width: '100%',
            height: '100%',
            paddingHorizontal: 24,
            paddingVertical: 24,
            backgroundColor: FOHColors.GRAYSCALE_10,
            flex: 1
          }}
        >
          <FOHView>
            {locations &&
              locations.map((location, index) => (
                <FOHLocationCard
                  mobile={isMobile}
                  key={`my-locations-dashboard-${index}`}
                  restaurantProfileURI={path(['image'], location) || ''}
                  restaurantName={path(['name'], location)}
                  onPressRestaurantName={() => {
                    navigateTo(`${EMPLOYER_DASHBOARD}/${prop('id', location)}`);
                  }}
                  restaurantLocationAt={path(['formattedAddress'], location)}
                  allPositionsLabel={`${t('allPositionLabel')}`}
                  allPositionsCount={path(['allPositionsCount'], location)}
                  activePositionsLabel={`${t('activePositionLabel')}`}
                  activePositionsCount={path(['hiringPositionCount'], location)}
                  locationPlanLabel={t('locationPlanLabel')}
                  locationPlanType={t('locationPlanType')}
                  hiringIcon={() =>
                    path(['payment', 'planName'], location) === 'ENTERPRISE' ? (
                      <FOHPremiumPlanIcon
                        style={{ width: 18, height: 18, top: 2 }}
                      />
                    ) : path(['payment', 'planName'], location) ===
                      'UNLIMITED' ? (
                      <FOHWhiteCrownIcon
                        style={{ width: 16, height: 16, top: 1 }}
                      />
                    ) : (
                      <FOHStopWatchIcon
                        style={{
                          width: 16,
                          height: 16,
                          top: 1
                        }}
                      />
                    )
                  }
                  hiringTypeLable={
                    path(['payment', 'planName'], location) === 'ENTERPRISE'
                      ? t('PricingScreenFeature:enterprise')
                      : path(['payment', 'planName'], location) === 'UNLIMITED'
                      ? `${t('PricingScreenFeature:unlimited')}`
                      : `${t('PricingScreenFeature:payAsYouGo')}`
                  }
                  editLocationLabel={t('PositionsFeature:editLocationLabel')}
                  onPressEditLocation={() => {
                    selectLocationFilter(prop('id', location));
                    navigateTo(`${EMPLOYER_SETTINGS_MENU}${LOCATION}`);
                  }}
                  viewLocationLabel={t('locationPage')}
                  onPressViewLocation={() => {
                    selectLocationFilter(prop('id', location));
                    navigateTo(`${RESTAURANT_JOBS}/${prop('slug', location)}`);
                  }}
                  locationNumber={prop('locationPhoneNumber', location)}
                  locationNumberLabel={t('locationPhoneNumberLabel')}
                />
              ))}
            {(myLocationsDashboardQuery.loading || loadingMore) && (
              <FOHCircleLoader />
            )}
            <FOHView
              style={{
                width: '100%',
                alignItems: 'center',
                marginTop: 20,
                marginBottom: 30
              }}
            >
              {path(
                ['data', 'me', 'myLocations', 'pageInfo', 'hasNextPage'],
                myLocationsDashboardQuery
              ) && (
                <FOHButtonBordered
                  style={{
                    minWidth: 168,
                    width: 168,
                    height: 48
                  }}
                  textColor={FOHColors.GRAYSCALE_700}
                  title={t('loadMore')}
                  onPress={loadMore}
                />
              )}
            </FOHView>
          </FOHView>
        </FOHView>
        <ManageLocationPositionModals from={EMPLOYER_DASHBOARD} />
      </FOHView>
    </Screen>
  );
};

export default EmployerLocationsDashboard;
