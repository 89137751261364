import { length, path } from 'ramda';

export const buildSearchQuery = ({ searchParams, key, value, values = [] }) => {
  const stringifiedValues = values.filter(val => val !== undefined).join(',');
  const stringifiedValue =
    value === false ? 'false' : value === true ? 'true' : value;
  if (length(values) > 0) {
    searchParams.set(key, stringifiedValues);
  } else if (stringifiedValue) {
    searchParams.set(key, stringifiedValue);
  } else {
    searchParams.delete(key);
  }
  return searchParams;
};

export const addSearchQuery = (
  searchParams,
  { key, value, values = [] },
  setSearchParams
) => {
  const newSearchParams = buildSearchQuery({
    searchParams,
    key,
    value,
    values
  });
  const search = newSearchParams.toString();

  setSearchParams(search);
  return search;
};

export const createSearchString = (searchParams, queries = []) => {
  const newSearchParams = queries.reduce((params, search) => {
    return buildSearchQuery({ searchParams: params, ...search });
  }, searchParams);
  return newSearchParams.toString();
};

export const addSearchQueries = (
  searchParams,
  queries = [],
  setSearchParams
) => {
  const search = createSearchString(searchParams, queries);
  return setSearchParams(search);
};

export const removeSearchQuery = (searchParams, key, setSearchParams) => {
  searchParams.delete(key);
  const search = searchParams.toString();
  setSearchParams(search);
  return search;
};

export const removeSearchQueries = (
  searchParams,
  keys = [],
  setSearchParams
) => {
  const newSearchParams = keys.reduce((params, key) => {
    params.delete(key);
    return params;
  }, searchParams);
  setSearchParams(newSearchParams);
};

export const getSearchQuery = (location, key, asArray = false) => {
  const searchString =
    new URLSearchParams(path(['search'], location)).get(key) || '';
  const searchArray = searchString
    .split(',')
    .filter(search => search !== '' && search !== 'undefined');
  if (searchString === 'false') {
    return false;
  } else if (searchString === 'true') {
    return true;
  }
  return asArray ? searchArray : searchString;
};

export const candidateProfileToSearchParams = (props, user) => {
  if (!path(['me', 'candidateProfile', 'id'], user)) {
    return '';
  }
  const positionTypes = path(['me', 'candidateProfile', 'positions'], user);
  const restaurantTypes = path(
    ['me', 'candidateProfile', 'restaurantTypes'],
    user
  );
  const dressCode = path(['me', 'candidateProfile', 'canHaveDressCode'], user);
  const healthInsurance = path(
    ['me', 'candidateProfile', 'isBenefitsRequired'],
    user
  );
  return createSearchString(props, [
    { key: QDRESSCODE, value: dressCode },
    { key: QHEALTHINSURANCE, value: healthInsurance },
    { key: QRESTAURANTTYPES, values: restaurantTypes },
    { key: QPOSITIONTYPES, values: positionTypes }
  ]);
};

// constants for query params
export const QPZIP = 'zip';
export const QPSEARCH = 'search';
export const QPMAP = 'map';
export const QLOCATIONSLUG = 'location';
export const QLOCATIONID = 'location';
export const QPOSITIONTYPES = 'positions';
export const QRESTAURANTTYPES = 'restauranttypes';
export const QHEALTHINSURANCE = 'healthinsurance';
export const QDRESSCODE = 'dresscode';
export const QTOKEN = 'token';
export const QPOSITION = 'position';
