import React from 'react';

import { defaultTo, prop } from 'ramda';

import x_icon from '../../../images/navy_x.svg';

import { FOHImage } from '../../ingredients';

export const FOHNavyX = (props: any) => (
  <FOHImage
    source={{ uri: x_icon }}
    style={{ width: 10, height: 10, ...defaultTo({}, prop('style', props)) }}
  />
);
