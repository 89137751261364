import React, { useState } from 'react';

import { compose, path } from 'ramda';
import { withTranslation } from 'react-i18next';

import { withUser, withUpdateUser } from '../../api/hocs';
import { handle, withIsMobile } from '../../utils';
import { passwordResetRequest } from '../../api/auth';
import { registerScreenErrorTransform } from '../../api/transformers/register';
import {
  PASSWORD_RESET_PATH,
  CANDIDATE_ACCOUNT_MENU_PATH,
  LOGIN_URL
} from '../../constants';
import { FOHBannerType, FOHPasswordResetScreen } from '../../components';
import { useNavigation } from '../../utils/navigation';

import { useBanner } from '../Navigation/useBanner';

const PasswordResetScreen = props => {
  const { location: navLocation, navigateTo } = useNavigation();

  const [email, setEmail] = useState('');
  const [error, setError] = useState();
  const { setBanner } = useBanner();

  const { t } = props;

  return (
    <FOHPasswordResetScreen
      title={t('title')}
      detailText={t('detailText')}
      submitLabel={t('nextLabel')}
      email={email}
      emailPlaceholder={t('email')}
      setEmail={setEmail}
      error={error}
      largeHeader={!props.isMobile}
      goBack={
        navLocation.pathname === PASSWORD_RESET_PATH &&
        props.user &&
        !!path(['user', 'candidate_profile', 'has_completed_onboarding'], props)
          ? () => navigateTo(CANDIDATE_ACCOUNT_MENU_PATH)
          : null
      }
      handleSubmit={async () => {
        const data = { email };
        const [response, respError] = await handle(passwordResetRequest(data));
        if (respError) {
          // TODO: genericize error transformer
          const err = registerScreenErrorTransform(respError.fieldErrors);
          return setError(err);
        } else {
          setEmail('');
          setBanner({
            bannerType: FOHBannerType.SUCCESS,
            bannerMessage: response.detail
          });
          setTimeout(() => {
            navigateTo(LOGIN_URL);
          }, 3000);
        }
      }}
      disabledSubmit={!email}
    />
  );
};

export default compose(
  withUpdateUser,
  withUser,
  withIsMobile,
  withTranslation('PasswordResetFeature')
)(PasswordResetScreen);
