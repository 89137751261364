import React from 'react';

import icon from '../../../images/notification-rejected-offers-chat.svg';

import { FOHImage } from '../../ingredients';

export interface FOHNotificationRejectedOffersChatProps {}

export const FOHNotificationRejectedOffersChat = (
  props: FOHNotificationRejectedOffersChatProps
) => (
  <FOHImage
    {...props}
    source={{ uri: icon }}
    style={{ width: 24, height: 24 }}
    {...props}
  />
);
