import React from 'react';

import { View } from 'react-native';
import styled from 'styled-components/native';
import { length } from 'ramda';

import {
  FOHSectionLabel,
  FOHSmallDetailLabel,
  FullWidthCard,
  FOHToolBoxIcon,
  FOHBullet,
  FOHLabel,
  FOHSpaceSmall,
  FOHProfileLocationIcon,
  FOHProfileCommuteIcon,
  FOHSpace,
  FOHProfileCertificationsIcon,
  FOHProfileSkillsIcon,
  FOHEditMarker,
  FOHSpaceSmallest,
  FOHColors,
  FOHTouchableOpacity
} from '../../ingredients';

export interface FOHUserStatsCardProps {
  locationLabel: string;
  locationValue?: string;
  commuteTimeLabel: string;
  commuteTimeValue?: string;
  positionsLabel: string;
  positions: Array<string>;
  skillsLabel: string;
  skills?: Array<string>;
  certificationsLabel: string;
  certifications?: Array<string>;
  statsLabel: string;
  maxWidth?: string;
  openLocationEdit?: () => void;
  openCommuteTimeEdit?: () => void;
  openSkillsEdit?: () => void;
  openPositionsEdit?: () => void;
}

export const FOHStatsCard = (props: FOHUserStatsCardProps) => (
  <View style={{ maxWidth: 600, width: '100%', marginBottom: 12 }}>
    <FOHSectionLabel
      style={{
        lineHeight: 24,
        marginBottom: 8,
        color: FOHColors.GRAYSCALE_700
      }}
    >
      {props.statsLabel}
    </FOHSectionLabel>
    <FullWidthCard>
      <View
        style={{
          padding: 16,
          paddingBottom: 12
        }}
      >
        {props.locationValue ? (
          <View>
            <StatLabelContainer>
              <IconDataContainer>
                <FOHProfileLocationIcon
                  style={{
                    tintColor: FOHColors.GRAYSCALE_400,
                    height: 20,
                    width: 14,
                    marginHorizontal: 3
                  }}
                />
                <FOHSmallDetailLabel
                  style={{
                    paddingLeft: 8,
                    fontSize: 16,
                    lineHeight: 24,
                    color: FOHColors.GRAYSCALE_400
                  }}
                >
                  {props.locationLabel}
                </FOHSmallDetailLabel>
              </IconDataContainer>
              {props.openLocationEdit ? (
                <FOHTouchableOpacity
                  testID={`edit-location-info`}
                  onPress={props.openLocationEdit}
                >
                  <FOHEditMarker />
                </FOHTouchableOpacity>
              ) : (
                <></>
              )}
            </StatLabelContainer>
            <FOHLabel
              style={{
                paddingLeft: 28,
                lineHeight: 24,
                fontWeight: '600',
                color: FOHColors.GRAYSCALE_700
              }}
            >
              {props.locationValue}
            </FOHLabel>
            <FOHSpaceSmallest />
            <FOHSpace />
          </View>
        ) : (
          <></>
        )}
        {props.commuteTimeValue ? (
          <>
            <StatLabelContainer>
              <IconDataContainer>
                <FOHProfileCommuteIcon
                  style={{
                    tintColor: FOHColors.GRAYSCALE_400,
                    marginHorizontal: 2
                  }}
                />
                <FOHSmallDetailLabel
                  style={{
                    paddingLeft: 8,
                    fontSize: 16,
                    lineHeight: 24,
                    color: FOHColors.GRAYSCALE_400
                  }}
                >
                  {props.commuteTimeLabel}
                </FOHSmallDetailLabel>
              </IconDataContainer>
              {props.openCommuteTimeEdit ? (
                <FOHTouchableOpacity
                  testID={`edit-commute-time`}
                  onPress={props.openCommuteTimeEdit}
                >
                  <FOHEditMarker />
                </FOHTouchableOpacity>
              ) : (
                <></>
              )}
            </StatLabelContainer>
            <FOHLabel
              style={{
                paddingLeft: 28,
                lineHeight: 24,
                fontWeight: '600',
                color: FOHColors.GRAYSCALE_700
              }}
            >
              {props.commuteTimeValue}
            </FOHLabel>
            <FOHSpaceSmallest />
            <FOHSpace />
          </>
        ) : (
          <></>
        )}
        {props?.openPositionsEdit ||
        (props?.positions && length(props?.positions) > 0) ? (
          <>
            <StatLabelContainer>
              <IconDataContainer>
                <FOHToolBoxIcon
                  style={{ tintColor: FOHColors.GRAYSCALE_400 }}
                />
                <FOHSmallDetailLabel
                  style={{
                    paddingLeft: 8,
                    fontSize: 16,
                    lineHeight: 24,
                    color: FOHColors.GRAYSCALE_400
                  }}
                >
                  {props.positionsLabel}
                </FOHSmallDetailLabel>
              </IconDataContainer>
              {props?.openPositionsEdit ? (
                <FOHTouchableOpacity
                  testID={`edit-position-types`}
                  onPress={props.openPositionsEdit}
                >
                  <FOHEditMarker />
                </FOHTouchableOpacity>
              ) : (
                <></>
              )}
            </StatLabelContainer>
            <View style={{ paddingLeft: 28 }}>
              {props?.positions?.map(position => (
                <View key={position}>
                  <View style={{ flexDirection: 'row' }}>
                    <FOHBullet
                      color={FOHColors.PRIMARY_TEAL_100}
                      bulletStyle={{ top: 8 }}
                    >
                      <FOHLabel
                        style={{
                          lineHeight: 24,
                          fontWeight: '600',
                          color: FOHColors.GRAYSCALE_700
                        }}
                      >
                        {position}
                      </FOHLabel>
                    </FOHBullet>
                  </View>
                  <FOHSpaceSmall />
                </View>
              ))}
            </View>
          </>
        ) : (
          <></>
        )}
      </View>
      {props.openSkillsEdit || (props.skills && length(props.skills) > 0) ? (
        <View
          style={{
            paddingHorizontal: 16,
            paddingBottom: 16
          }}
        >
          <StatLabelContainer>
            <IconDataContainer>
              <FOHProfileSkillsIcon
                style={{
                  width: 20,
                  height: 20,
                  tintColor: FOHColors.GRAYSCALE_400
                }}
              />
              <FOHSmallDetailLabel
                style={{
                  paddingLeft: 8,
                  fontSize: 16,
                  lineHeight: 24,
                  color: FOHColors.GRAYSCALE_400
                }}
              >
                {props.skillsLabel}
              </FOHSmallDetailLabel>
            </IconDataContainer>
            {props.openSkillsEdit ? (
              <FOHTouchableOpacity
                testID={`edit-skills`}
                onPress={props.openSkillsEdit}
              >
                <FOHEditMarker />
              </FOHTouchableOpacity>
            ) : (
              <></>
            )}
          </StatLabelContainer>
          {props.skills ? (
            <FOHLabel
              style={{
                paddingLeft: 28,
                lineHeight: 24,
                fontWeight: '600',
                color: FOHColors.GRAYSCALE_700
              }}
            >
              {props.skills.join(', ')}
            </FOHLabel>
          ) : (
            <></>
          )}
        </View>
      ) : (
        <></>
      )}
      {props.openSkillsEdit ||
      (props.certifications && length(props.certifications) > 0) ? (
        <View
          style={{
            paddingHorizontal: 16,
            paddingBottom: 16
          }}
        >
          <StatLabelContainer>
            <IconDataContainer>
              <FOHProfileCertificationsIcon
                style={{
                  width: 20,
                  height: 20,
                  tintColor: FOHColors.GRAYSCALE_400
                }}
              />
              <FOHSmallDetailLabel
                style={{
                  paddingLeft: 8,
                  fontSize: 16,
                  lineHeight: 24,
                  color: FOHColors.GRAYSCALE_400
                }}
              >
                {props.certificationsLabel}
              </FOHSmallDetailLabel>
            </IconDataContainer>
          </StatLabelContainer>
          {props.certifications ? (
            <FOHLabel
              style={{
                paddingLeft: 28,
                lineHeight: 24,
                fontWeight: '600',
                color: FOHColors.GRAYSCALE_700
              }}
            >
              {props.certifications.join(', ')}
            </FOHLabel>
          ) : (
            <></>
          )}
        </View>
      ) : (
        <></>
      )}
    </FullWidthCard>
  </View>
);

const StatLabelContainer = styled(View)`
  padding-bottom: 4px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const IconDataContainer = styled(View)`
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
