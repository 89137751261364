import React, { Fragment } from 'react';

import { length } from 'ramda';

import { FOHLabel } from './FOHLabel';
export interface FOHHighlightTextProps {
  labelCmp: any;
  emphasizedWord: string;
  highlightColor: string;
  boldLabelCmp?: any;
  children: string;
  caseInsensitive?: boolean;
  style?: any;
  emphasizedStyle?: any;
  highlightWrapper?: string;
}

export interface FOHHighlightWordsLabelProps {
  labelCmp: any;
  emphasizedWords: Array<string>;
  highlightColor: string;
  textColor?: string;
  boldLabelCmp?: any;
  children: string;
}

export const FOHHighlightText = (props: FOHHighlightTextProps) => {
  const Label = props.labelCmp || FOHLabel;
  const EmphasizedLabel = props.boldLabelCmp || Label;
  const unstyledWords = props.children
    ? props.caseInsensitive
      ? props.children.split(new RegExp(props.emphasizedWord, 'i'))
      : props.children.split(props.emphasizedWord)
    : [];

  const getHighlightedText = (text: string, highlight: string) => {
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return parts.map((part, i) => {
      return (
        <Fragment key={i}>
          {part.toLowerCase() === highlight.toLowerCase() ? (
            <EmphasizedLabel
              key={i}
              style={{
                ...props.emphasizedStyle,
                color: props.highlightColor
              }}
            >
              {props.highlightWrapper}
              {part}
              {props.highlightWrapper}
            </EmphasizedLabel>
          ) : (
            part
          )}
        </Fragment>
      );
    });
  };

  // react-native labels are nestable
  return (
    <Label numberOfLines={4} style={props.style}>
      {props.caseInsensitive ? (
        <>{getHighlightedText(props.children, props.emphasizedWord)}</>
      ) : (
        unstyledWords.map((word, index) => (
          <React.Fragment key={index + 1}>
            <>
              {word}
              {index !== length(unstyledWords) - 1 ? (
                <EmphasizedLabel
                  style={{
                    ...props.emphasizedStyle,
                    color: props.highlightColor
                  }}
                >
                  {props.highlightWrapper}
                  {props.emphasizedWord}
                  {props.highlightWrapper}
                </EmphasizedLabel>
              ) : (
                <></>
              )}
            </>
          </React.Fragment>
        ))
      )}
    </Label>
  );
};

export const FOHHighlightWordsLabel = (props: FOHHighlightWordsLabelProps) => {
  const Label = props.labelCmp || FOHLabel;
  const EmphasizedLabel = props.boldLabelCmp || Label;
  const words = props.children.split(/\s+/).reduce((acc: any, word: string) => {
    const isHighlighted = props.emphasizedWords.includes(word);
    const wordWithoutDot = word.endsWith('.') ? word.slice(0, -1) : word;

    return [
      ...acc,
      {
        word: wordWithoutDot,
        hasDot: word.endsWith('.'),
        highlighted: isHighlighted
      }
    ];
  }, []) as any;

  // react-native labels are nestable
  return (
    <Label style={{ color: props.textColor }}>
      {words.map((word: any, keyIndex: number) => (
        <React.Fragment key={keyIndex + 1}>
          {word.highlighted ? (
            <EmphasizedLabel
              style={{
                color: props.highlightColor
              }}
            >
              {word.word}
            </EmphasizedLabel>
          ) : (
            word.word
          )}
          {word.hasDot && '.'}{' '}
        </React.Fragment>
      ))}
    </Label>
  );
};
