import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { path, prop, length, defaultTo } from 'ramda';
import { useParams } from 'react-router-dom';

import {
  unwrapEdgesAt,
  useIsMobile,
  toDate,
  isScrollCloseToBottom,
  scrollToTop
} from '../../utils';
import { positionToSalaryText } from '../../utils/currency';
import {
  JOBS,
  POSITIONS_SORT_BY_OPTIONS,
  RESTAURANT_JOBS
} from '../../constants';
import { useSearchPositionsLazyQuery } from '../../graphql/generated';
import {
  FOHColors,
  FOHInverseButton,
  FOHFilterIcon,
  FOHRedBestMatchIcon,
  FOHScrollView,
  FOHView,
  FullWidthCard,
  FOHHeaderH4,
  FOHPositionListCell,
  FOHLabeledCheckbox,
  FOHLocationSearchBar,
  FOHCandidateResultFilter
} from '../../components';
import { useNavigation } from '../../utils/navigation';
import { FOH_INPUT_HEIGHT } from '../../components/constants';

import { useCandidateProfileMutation } from '../CandidateProfile/useCandidateProfileMutation';

import { HomeScrollFooter } from './HomeScrollFooter';
import { PositionTypeAheadSearchBar } from './PositionTypeAheadSearchBar';
import { PositionFilterDrawer } from './PositionFilterDrawer';

const FilterIcon = () => <FOHFilterIcon active={true} />;

export const CandidateDiscover = props => {
  const { tab } = useParams();

  const { t } = useTranslation('CandidateHomeFeature');
  const { navigateTo } = useNavigation();

  const [openFilters, setOpenFilters] = useState(false);
  const [zip, setZip] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [workingPreferences, setWorkingPreferences] = useState([]);
  const [loadingMore, setLoadingMore] = useState(false);
  const [selectedMatch, setSelectedMatch] = useState(false);
  const [sortBy, setSortBy] = useState('best');

  const { me, meQuery } = useCandidateProfileMutation();

  const { isMobile } = useIsMobile();

  const benefits = workingPreferences.includes('isBenefitsRequired')
    ? {
        isBenefitsRequired: true
      }
    : {};

  const dressCode = workingPreferences.includes('canHaveDressCode')
    ? {
        canHaveDressCode: true
      }
    : {};

  const variables = {
    first: 10,
    after: '',
    hasApplied: false,
    hasInterview: false,
    hiring: true,
    searchTerms: selectedItems
      .map(item => prop('value', item))
      .filter(item => !!item),
    zipCode: length(zip) === 5 ? zip : '',
    showBestMatch: selectedMatch,
    sortBy: sortBy,
    ...benefits,
    ...dressCode
  };
  const [
    positionSearchQuery,
    {
      loading: positionQueryloading,
      data: positionData,
      fetchMore: fetchMorePositions
    }
  ] = useSearchPositionsLazyQuery({
    variables: {
      ...variables
    }
  });

  const positions = unwrapEdgesAt(['positions', 'edges'], positionData);

  const hasMorePositionsToLoad = path(
    ['positions', 'pageInfo', 'hasNextPage'],
    positionData
  );

  const getMorePositions = async () => {
    setLoadingMore(true);
    await fetchMorePositions({
      variables: {
        ...variables,
        after: positionData?.positions?.pageInfo?.endCursor,
        first: 10
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult?.positions?.edges;
        const pageInfo = fetchMoreResult?.positions?.pageInfo;
        const totalCount = fetchMoreResult?.positions?.totalCount;
        return newEdges.length
          ? {
              positions: {
                __typename: previousResult?.positions?.__typename,
                edges: [...previousResult?.positions?.edges, ...newEdges],
                pageInfo,
                totalCount
              }
            }
          : previousResult;
      }
    });
    setLoadingMore(false);
  };

  useEffect(() => {
    if (
      props.scrolledToBottom &&
      hasMorePositionsToLoad &&
      !positionQueryloading &&
      isMobile
    ) {
      getMorePositions(variables);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.scrolledToBottom]);

  useEffect(() => {
    if (!zip && path(['candidateProfile', 'addressPostalCode'], me)) {
      setZip(path(['candidateProfile', 'addressPostalCode'], me));
    }
    positionSearchQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meQuery?.loading]);

  return (
    <>
      <FOHView
        style={{
          flexDirection: isMobile ? 'column-reverse' : 'column'
        }}
      >
        {isMobile && (
          <FOHView
            style={{
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              marginRight: 25,
              paddingBottom: 10
            }}
          >
            <FOHLabeledCheckbox
              selected={selectedMatch}
              checkboxLabel={t('showLabel')}
              setSelected={() => setSelectedMatch(!selectedMatch)}
              style={{
                fontSize: 14
              }}
            />
            <FOHRedBestMatchIcon />
            <FOHHeaderH4
              style={{
                fontWeight: 700,
                paddingTop: 2
              }}
            >
              {t('matchLabel')}
            </FOHHeaderH4>
          </FOHView>
        )}
        <FullWidthCard
          style={{
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          <FOHView
            style={{
              padding: isMobile ? 10 : 16,
              flexDirection: 'row',
              flexWrap: 'wrap'
            }}
          >
            <FOHView
              style={{
                flex: 3,
                marginRight: isMobile ? 10 : 8,
                minWidth: 300,
                paddingBottom: isMobile ? 10 : 0,
                minHeight: 52,
                zIndex: 99
              }}
            >
              <PositionTypeAheadSearchBar
                {...props}
                tab="discover"
                setSelectedItems={setSelectedItems}
                selectedItems={selectedItems}
                isMobile={isMobile}
              />
            </FOHView>
            <FOHView
              style={{
                flex: 1,
                marginRight: 8,
                minWidth: 180,
                minHeight: 52,
                height: FOH_INPUT_HEIGHT
              }}
            >
              <FOHLocationSearchBar
                onChangeLocation={text => setZip(text)}
                locationSearchValue={zip}
                locationSearchPlaceholder={t('locationSearchPlaceholder')}
              />
            </FOHView>
            <FOHView style={{ flex: 1 }}>
              <FOHInverseButton
                color={FOHColors.BLACK}
                title={t('moreFilters')}
                icon={FilterIcon}
                onPress={() => setOpenFilters(true)}
                centerIcon={true}
                style={{
                  width: isMobile ? '100%' : 200,
                  minWidth: 122,
                  height: FOH_INPUT_HEIGHT
                }}
              />
            </FOHView>
          </FOHView>
        </FullWidthCard>
        <FOHCandidateResultFilter
          totalCount={
            positionQueryloading
              ? '...'
              : path(['positions', 'totalCount'], positionData)
          }
          countHeader={t('Results')}
          showBestMatch={true && !isMobile}
          selected={selectedMatch}
          setSelected={() => setSelectedMatch(!selectedMatch)}
          showLabel={t('showLabel')}
          matchLabel={t('matchLabel')}
          sortByLabel={t('sortByLabel')}
          sortBy={sortBy}
          setSortBy={setSortBy}
          sortByOptions={POSITIONS_SORT_BY_OPTIONS(t)}
        />
      </FOHView>

      <FOHScrollView
        scrollEventThrottle={200}
        onScroll={async ({ nativeEvent }) => {
          if (
            isScrollCloseToBottom(nativeEvent) &&
            hasMorePositionsToLoad &&
            !isMobile &&
            !loadingMore
          ) {
            await getMorePositions(variables);
          }
        }}
        style={{
          // no nested scrolling on mobile
          height: openFilters ? '40vh' : isMobile ? '100%' : '60vh',
          zIndex: -1
        }}
      >
        <>
          {positionQueryloading ? (
            <></>
          ) : (
            positions.map(position => (
              <FOHPositionListCell
                mobile={isMobile}
                key={path(['id'], position)}
                positionName={path(['positionName'], position)}
                positionSlug={path(['slug'], position)}
                positionId={path(['id'], position)}
                hiringManagerName={path(['createdBy', 'firstName'], position)}
                hiringManagerImage={path(
                  ['createdBy', 'profileImage'],
                  position
                )}
                hiringManagerPosition={t('positionOwner')} // Position Owner
                hiringManagerDetailText="" // It usually takes 1-2 weeks to hear back.
                salaryText={positionToSalaryText(position, { t })}
                isBestMatch={path(['isMatch'], position)}
                postedDateText={`${t('posted')}: ${toDate(
                  path(['posted'], position)
                )}`}
                // job details
                onPressJobDetails={slug => navigateTo(`${JOBS}/${slug}`)}
                jobDetailsLabel={t('jobDetailsLabel')}
                // Apply
                buttonCTALabel={
                  prop('hasApplied', position) ? t('applied') : t('apply')
                }
                onPressCTA={slug => {
                  if (position.externalApplyUrl) {
                    return (
                      window &&
                      window.open &&
                      window.open(position.externalApplyUrl, '_blank').focus()
                    );
                  }
                  if (
                    defaultTo('', path(['email'], me)).includes('indeedemail')
                  ) {
                    props.openSetPasswordAndEmail();
                    return navigateTo(`${JOBS}/${slug}`);
                  }
                  props.openApplicationModal(slug);
                }}
                buttonCTADisabled={prop('hasApplied', position)}
                // location
                locationId={path(['location', 'id'], position)}
                locationName={path(['location', 'name'], position)}
                locationSlug={path(['location', 'slug'], position)}
                onPressLocation={slug =>
                  isMobile
                    ? navigateTo(`${RESTAURANT_JOBS}/${slug}`)
                    : props.openLocationDetailsModal(slug)
                }
                locationImage={path(['location', 'image'], position)}
              />
            ))
          )}
        </>
        <HomeScrollFooter
          positionQueryloading={positionQueryloading || loadingMore}
          hasNextPage={hasMorePositionsToLoad}
          noMorePositionsLabel={t('noMoreDiscovers')}
          noMorePositionsDetailLabel={t('applyByYourOwn')}
          noMoreSubmitBtnLabel={t('clearSearch')}
          onSubmitPress={() => {
            if (tab === 'discover') {
              setSelectedItems([]);
              setZip('');
              scrollToTop({ smooth: true });
              setSelectedMatch(false);
            }
          }}
        />
      </FOHScrollView>
      <PositionFilterDrawer
        workingPreferences={workingPreferences}
        setWorkingPreferences={setWorkingPreferences}
        openFilters={openFilters}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        setOpenFilters={setOpenFilters}
      />
    </>
  );
};
