import React from 'react';

import { FOHView } from '../containers/Containers';

import { FOHErrorLabel } from './FOHErrorLabel';

export const withRequired = (Cmp: any) => (props: any) => {
  return props.required ? (
    <FOHView style={{ width: '100%' }}>
      {!props.value && (
        <FOHView
          style={{
            width: '100%',
            alignItems: 'flex-end',
            position: 'absolute',
            top: -14,
            right: 0
          }}
        >
          <FOHErrorLabel>*</FOHErrorLabel>
        </FOHView>
      )}
      <Cmp {...props}>{props.children}</Cmp>
    </FOHView>
  ) : (
    <Cmp {...props}>{props.children}</Cmp>
  );
};

export const FOHRequired = (props: any) => {
  return props.required ? (
    <FOHView style={{ width: '100%' }}>
      {!props.value && (
        <FOHView
          style={{
            width: '100%',
            alignItems: 'flex-end',
            position: 'absolute',
            top: -14,
            right: 0
          }}
        >
          <FOHErrorLabel>*</FOHErrorLabel>
        </FOHView>
      )}
      {props.children}
    </FOHView>
  ) : (
    props.children
  );
};
