import React, { useState, useEffect } from 'react';

import { compose, path, length } from 'ramda';
import { withTranslation } from 'react-i18next';

import { multiSelectItem } from '../../utils/fieldHelpers';
import {
  CANDIDATE_ACCOUNT_MENU_PATH,
  CANDIDATE_NOTIFICATION_PREFERENCE_PATH,
  CANDIDATE_PROFILE
} from '../../constants';
import { updateCurrentUser } from '../../api/users';
import { withUpdateUser, withUser } from '../../api/hocs';
import { handle, withIsMobile } from '../../utils';
import { useNavigation } from '../../utils/navigation';
import { FOHMultiSelectScreen } from '../../components';

const NotificationPreferenceScreen = props => {
  const { navigateTo, location: navLocation } = useNavigation();

  const [notificationPreferences, setNotificationPreferences] = useState();
  // WAITING ON USER OBJECT
  useEffect(() => {
    if (!props.user) {
      return;
    }

    const notificationPref = (({ notify_via_sms, notify_via_email }) => ({
      notify_via_sms,
      notify_via_email
    }))(props.user);

    const selected = Object.keys(notificationPref).filter(
      key => notificationPref[key] === true
    );

    setNotificationPreferences(selected);
  }, [props.user]);

  const [error, setError] = useState();
  const { t } = props;

  const hasBack =
    navLocation.pathname === CANDIDATE_NOTIFICATION_PREFERENCE_PATH &&
    props.user &&
    !!path(['user', 'candidate_profile', 'has_completed_onboarding'], props);

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    } catch (e) {
      return;
    }
  }, []);

  // TODO: should I have changed this?
  // const phone = props.user ? props.user.candidate_profile.phone : '';
  const phone = props.user ? props.user.phone : '';
  const email = props.user ? props.user.email : '';
  const notificationItems = [
    { key: 'sms', value: 'notify_via_sms', label: `${phone} 📱` },
    { key: 'email', value: 'notify_via_email', label: `${email} 📬` }
  ];

  return (
    <FOHMultiSelectScreen
      onboarding={props.onboarding}
      items={notificationItems}
      selected={notificationPreferences}
      selectItem={value => {
        return multiSelectItem(
          value,
          setNotificationPreferences,
          notificationPreferences
        );
      }}
      title={t('title')}
      detailText={t('detailText')}
      submitLabel={
        props.user &&
        !!path(['user', 'candidate_profile', 'has_completed_onboarding'], props)
          ? t('common:save')
          : t('common:next')
      }
      error={error}
      disabledSubmit={length(notificationPreferences) === 0}
      goBack={hasBack ? () => navigateTo(CANDIDATE_ACCOUNT_MENU_PATH) : null}
      largeHeader={!props.isMobile}
      handleSubmit={async () => {
        const data = {
          notify_via_sms: notificationPreferences.includes('notify_via_sms'),
          notify_via_email: notificationPreferences.includes('notify_via_email')
        };
        const [resp, err] = await handle(
          updateCurrentUser({
            ...data
          })
        );

        if (resp) {
          props.updateUser({
            notify_via_sms: resp['notify_via_sms'],
            notify_via_email: resp['notify_via_email']
          });
          return navigateTo(
            props.user &&
              !!path(
                ['user', 'candidate_profile', 'has_completed_onboarding'],
                props
              )
              ? CANDIDATE_ACCOUNT_MENU_PATH
              : `${CANDIDATE_PROFILE}/${path(
                  ['user', 'candidate_profile', 'handle'],
                  props
                )}`
          );
        } else if (err) {
          setError(err);
        }
      }}
    />
  );
};

export default compose(
  withUpdateUser,
  withUser,
  withIsMobile,
  withTranslation('NotificationPreferenceFeature')
)(NotificationPreferenceScreen);
