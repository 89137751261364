import React from 'react';

import circle from '../../../images/gold_quarter_circle.svg';

import { FOHImage } from '../../ingredients';

export const FOHGoldQuarterDecoration = (props: any) => (
  <FOHImage
    source={{ uri: circle }}
    style={{
      width: 32,
      height: 32
    }}
    {...props}
  />
);
