import React from 'react';

import { GoogleMap } from '@react-google-maps/api';

export const WebMap = props => {
  const mapContainerStyle = {
    height: '96vh',
    width: '100%'
  };

  return (
    <GoogleMap {...props} zoom={12} mapContainerStyle={mapContainerStyle} />
  );
};

export const MobileWebMap = props => {
  const mapContainerStyle = {
    height: '65vh',
    width: '100%'
  };

  return (
    <GoogleMap {...props} zoom={12} mapContainerStyle={mapContainerStyle} />
  );
};

export const SmallMobileWebMap = props => {
  const mapContainerStyle = {
    height: '250px',
    width: '100%'
  };

  return (
    <GoogleMap {...props} zoom={12} mapContainerStyle={mapContainerStyle} />
  );
};

export const SmallWebMap = props => {
  const mapContainerStyle = {
    height: '250px',
    width: '424px'
  };

  return (
    <GoogleMap {...props} zoom={12} mapContainerStyle={mapContainerStyle} />
  );
};
