import React from 'react';

import { last, length, prop } from 'ramda';
import { useTranslation } from 'react-i18next';

import { HOME_PAGE } from '../../constants';
import {
  FOHApplicantIcon,
  FOHFavorite,
  FOHHiringIcon,
  FOHInterviewIcon,
  FOHTabList
} from '../../components';
import { useNavigation } from '../../utils/navigation';

import { useGetMeData } from '../SignIn';

const HiringIcon = () => (
  <FOHHiringIcon
    key="hiringIcon"
    style={{
      width: 16,
      height: 16,
      tintColor: '#dbdbdb'
    }}
  />
);

const HiringSelectedIcon = () => (
  <FOHHiringIcon
    key="selectedHiringIcon"
    style={{
      width: 16,
      height: 16,
      tintColor: '#000000'
    }}
  />
);

const ApplicationIcon = () => (
  <FOHApplicantIcon
    key="applicationIcon"
    style={{
      width: 16,
      height: 16
    }}
  />
);

const ApplicationSelectedIcon = () => (
  <FOHApplicantIcon
    key="selectedapplicationIcon"
    style={{
      width: 16,
      height: 16
    }}
    active={true}
  />
);

const InterviewIcon = () => <FOHInterviewIcon key="interviewIcon" />;
const InterviewSelectIcon = () => (
  <FOHInterviewIcon key="selectedINterviewIcon" active={true} />
);

const FollowingIcon = () => (
  <FOHFavorite
    key="followingIcon"
    style={{
      width: 16,
      height: 16,
      tintColor: '#dbdbdb'
    }}
  />
);

const FollowingSelectedIcon = () => (
  <FOHFavorite
    key="selectedfollowingIcon"
    style={{
      width: 16,
      height: 16,
      tintColor: '#000000'
    }}
  />
);
export const candidateHomeTabs = [
  {
    route: 'discover',
    icon: HiringIcon,
    selectedIcon: HiringSelectedIcon
  },
  {
    route: 'applications',
    icon: ApplicationIcon,
    selectedIcon: ApplicationSelectedIcon
  },
  // TODO: get correct icons from Pola
  {
    route: 'requests',
    icon: InterviewIcon,
    selectedIcon: InterviewSelectIcon
  },
  {
    route: 'interviews',
    icon: InterviewIcon,
    selectedIcon: InterviewSelectIcon
  },
  {
    route: 'following',
    divider: true,
    icon: FollowingIcon,
    selectedIcon: FollowingSelectedIcon
  }
];

export const CandidateHomeTabs = () => {
  const { t } = useTranslation('CandidateHomeTabs');
  const { navigateTo, location: navLocation } = useNavigation();

  const { data: me } = useGetMeData();

  const hasUnseenInterviewRequest = length(prop('notifications', me)) > 0;

  return (
    <FOHTabList
      selectedTab={
        navLocation.pathname === HOME_PAGE
          ? candidateHomeTabs[0].route
          : last(navLocation.pathname.split('/'))
      }
      key="hometabs"
      onPressTab={route => navigateTo(`${HOME_PAGE}/${route}`)}
      tabs={candidateHomeTabs.map(tab => ({
        ...tab,
        notification:
          tab.route === 'requests' && hasUnseenInterviewRequest
            ? t('newInterviewRequest')
            : '',
        title: t(`${tab.route}`)
      }))}
    />
  );
};
