import { useState } from 'react';

export const useLocalPagination = numberOfPages => {
  const [currentPage, setCurrentPage] = useState(1);

  const handleNextPage = () => {
    if (currentPage + 1 <= numberOfPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage - 1 >= 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return {
    currentPage,
    numberOfPages,
    handleNextPage,
    handlePreviousPage
  };
};
