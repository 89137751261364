import React from 'react';

import { View } from 'react-native';

import avatar from '../../../images/avatar.svg';

import {
  FOHHeaderH2,
  FullWidthCard,
  FOHDetailText,
  FOHInverseButton,
  FOHSpace
} from '../../ingredients';

import { FOHProfileImage } from '../navigation/FOHProfileImage';

export interface FOHSmallProfileCardProps {
  photo?: string;

  name: string;
  positions: string;
  viewProfile: () => void;
  viewProfileLabel: string;
  children?: any;
}

export const FOHSmallProfileCard = (props: FOHSmallProfileCardProps) => (
  <FullWidthCard
    shadow={true}
    style={{
      padding: 24,
      justifyContent: 'center',
      alignItems: 'center'
    }}
  >
    <FOHProfileImage
      photo={props.photo || avatar}
      style={{
        height: 152,
        width: 152
      }}
    />
    <FOHSpace />
    <FOHHeaderH2
      numberOfLines={2}
      style={{
        maxWidth: 248
      }}
    >
      {props.name}
    </FOHHeaderH2>
    <FOHSpace />
    <FOHDetailText>{props.positions}</FOHDetailText>
    <FOHSpace />
    <View style={{ zIndex: 99 }}>{props.children}</View>
    <FOHSpace />
    <FOHInverseButton
      onPress={props.viewProfile}
      title={props.viewProfileLabel}
      color={'#000000'}
    />
  </FullWidthCard>
);
