import { defaultTo, path, prop, length, findIndex, propEq, slice } from 'ramda';

import { findById, handle } from '../../utils';
import {
  EmployerCandidatesDocument,
  EmployerStagesDocument,
  GetEmployerCandidateForHandleDocument,
  useEmployerStagesQuery,
  useMutateBulkMoveEmployerCandidateMutation
} from '../../graphql/generated';
import { FOHNotificationType } from '../../components';

import { useCandidateFeedContext } from './useCandidateFeedContext';
import { useNotificationFilters } from './useNotificationFilters';
import { useCandidateFeedFiltersContext } from './useCandidateFeedFiltersContext';

export const useStages = ({
  activeStageId = '',
  polling = false,
  addIgnoredStages
}) => {
  const {
    positionFilters,
    locationFilters,
    employerCandidate,
    candidateHandle,
    setMovingEmployerCandidateId,
    notificationStatusFilter
  } = useCandidateFeedContext();

  const { profileDetailFilters } = useCandidateFeedFiltersContext();

  const pollingInterval = !polling ? 0 : 60000;

  const stagesQuery = useEmployerStagesQuery({
    // skip:
    //   !locationFilters ||
    //   !prop(0, locationFilters) ||
    //   length(locationFilters.filter(loc => !!loc)) === 0,
    pollInterval: pollingInterval,
    variables: {
      positions: positionFilters,
      locations: locationFilters,
      profileDetailFilters: profileDetailFilters
    }
  });
  const [moveEmployerCandidatesMutation] =
    useMutateBulkMoveEmployerCandidateMutation();

  const stages = defaultTo(
    [],
    path(['data', 'employerStages', 'edges'], stagesQuery)
  ).map(stageEdge => stageEdge.node);

  const activeStageIndex = findIndex(propEq('id', activeStageId))(stages);
  const newStageIndex =
    // passive candidates stage skip
    activeStageId && length(stages) > 1
      ? activeStageIndex === 0
        ? activeStageIndex + 2
        : activeStageIndex + 1
      : 0;

  const skipToStages = slice(newStageIndex, length(stages), stages);

  const currentStage = prop(activeStageIndex, stages);
  const interviewStage = findById('INTERVIEWS', stages, 'stageType');
  const offeredStage = findById('OFFERED', stages, 'stageType');
  const hiredStage = findById('HIRED', stages, 'stageType');

  const { notificationStatuses, notificationType, NOTIFICATION_FILTER_TYPES } =
    useNotificationFilters(currentStage);

  const filteredNotificationStatusValues = {
    notificationStatuses:
      notificationType === 'SCHEDULE' &&
      notificationStatusFilter === FOHNotificationType.PENDING
        ? [FOHNotificationType.PENDING, FOHNotificationType.UNSCHEDULED]
        : !['ALL', FOHNotificationType.PENDING].includes(
            notificationStatusFilter
          )
        ? [notificationStatusFilter]
        : ''
  };

  const backToStages = slice(0, activeStageIndex, stages).filter(
    stage =>
      prop('stageType', stage) !== 'APPLICANTS' &&
      prop('stageType', currentStage) !== 'PASSIVE_CANDIDATES'
  );

  const moveEmployerCandidates = async ({
    positions,
    locations,
    employerCandidateIds,
    all,
    oldStage,
    newStage,
    search,
    notificationStatusToUpdate
  }) => {
    if (addIgnoredStages) {
      const oldStageObj = stages.find(stage => stage.id === oldStage);
      const newStageObj = stages.find(stage => stage.id === newStage);
      addIgnoredStages([oldStageObj.stageType, newStageObj.stageType]);
    }

    if (!oldStage && !newStage) {
      return false;
    }
    const employerCandidateIdsToMove = prop('id', employerCandidate)
      ? [...new Set([...employerCandidateIds, prop('id', employerCandidate)])]
      : [...new Set([...employerCandidateIds])];

    if (length(employerCandidateIds) === 1) {
      setMovingEmployerCandidateId(prop(0, employerCandidateIds));
    }

    const variables = {
      positions,
      locations,
      // remove any null values
      employerCandidateIds: employerCandidateIdsToMove.filter(item => item),
      all,
      oldStage,
      newStage,
      notificationStatusToUpdate,
      notificationType:
        notificationStatusFilter !== 'ALL' ? notificationType : '',
      ...filteredNotificationStatusValues
    };

    const newStageRefetch =
      newStage === prop('id', interviewStage)
        ? [
            {
              query: EmployerCandidatesDocument,
              variables: {
                first: 20,
                after: '',
                stageId: newStage,
                positions: positions,
                locations: locations,
                search: '',
                notificationStatuses: [],
                notificationType: ''
              }
            },
            {
              query: EmployerCandidatesDocument,
              variables: {
                first: 20,
                after: '',
                stageId: newStage,
                positions: positions,
                locations: locations,
                search: '',
                notificationType: NOTIFICATION_FILTER_TYPES.INTERVIEWS,
                notificationStatuses: ['TODO']
              }
            },
            {
              query: EmployerCandidatesDocument,
              variables: {
                first: 20,
                after: '',
                stageId: newStage,
                positions: positions,
                locations: locations,
                search: '',
                notificationType: NOTIFICATION_FILTER_TYPES.INTERVIEWS,
                notificationStatuses: ['PENDING']
              }
            },
            {
              query: EmployerCandidatesDocument,
              variables: {
                first: 20,
                after: '',
                stageId: newStage,
                positions: positions,
                locations: locations,
                search: '',
                notificationType: NOTIFICATION_FILTER_TYPES.INTERVIEWS,
                notificationStatuses: ['DONE']
              }
            }
          ]
        : [
            {
              query: EmployerCandidatesDocument,
              variables: {
                first: 20,
                after: '',
                stageId: newStage,
                positions: positions,
                locations: locations,
                search: ''
              }
            }
          ];
    // refetch current view of interview stage if active stage is interview stage
    const interviewStageRefetches =
      oldStage === prop('id', interviewStage)
        ? [
            {
              query: EmployerCandidatesDocument,
              variables: {
                first: 20,
                after: '',
                stageId: oldStage,
                positions: positions,
                locations: locations,
                search: '',
                notificationType:
                  notificationStatusFilter !== 'ALL' ? notificationType : '',
                ...filteredNotificationStatusValues
              }
            }
          ]
        : [];

    const [response] = await handle(
      moveEmployerCandidatesMutation({
        variables,
        refetchQueries: [
          ...newStageRefetch,
          ...interviewStageRefetches,
          {
            query: EmployerCandidatesDocument,
            variables: {
              first: 20,
              after: '',
              stageId: oldStage,
              positions: positions,
              locations: locations,
              search: ''
            }
          },
          // refetch old stage if you have a search
          {
            query: EmployerCandidatesDocument,
            variables: {
              first: 20,
              after: '',
              stageId: oldStage,
              positions: positions,
              locations: locations,
              search
            }
          },
          {
            query: EmployerStagesDocument,
            variables: {
              positions: positions,
              locations: locations,
              profileDetailFilters: profileDetailFilters
            }
          },
          {
            query: GetEmployerCandidateForHandleDocument,
            variables: { handle: candidateHandle }
          }
        ],
        // await to show candidate loading spinner
        awaitRefetchQueries: true
      })
    );

    setMovingEmployerCandidateId(undefined);
    return response;
  };

  return {
    stages,
    stagesQuery,
    loadingStages: stagesQuery.loading,
    currentStage,
    skipToStages,
    backToStages,
    interviewStage,
    offeredStage,
    hiredStage,
    rejectedStage: findById('REJECTED', stages, 'stageType'),
    passiveStage: findById('PASSIVE_CANDIDATES', stages, 'stageType'),
    appliedStage: findById('APPLIED', stages, 'stageType'),
    screeningStage: findById('SCREENING', stages, 'stageType'),
    moveEmployerCandidates,
    notificationStatuses,
    notificationType,
    filteredNotificationStatusValues
  };
};
