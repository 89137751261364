import React from 'react';

import { useIsMobile } from '../../utils';
import no_more_positions from '../../images/no_more_positions.svg';
import { FOHNoMorePositions, FOHView } from '../../components';

import { LogoLoader } from '../Loading/LogoLoader';

const NoMorePositions = React.memo(props => {
  return (
    <FOHView
      key="noMorePositionsParent"
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: 200
      }}
    >
      <FOHNoMorePositions
        key="noMorePositions"
        mobile={props.isMobile}
        title={props.noMorePositionsLabel}
        subTitle={props.noMorePositionsDetailLabel}
        submitBtnLabel={props.noMoreSubmitBtnLabel}
        onButtonPress={props.onSubmitPress}
        // onRightImgURI={"https://picsum.photos/400/200"}
        onRightImgURI={no_more_positions}
      />
    </FOHView>
  );
});

export const HomeScrollFooter = props => {
  const { isMobile } = useIsMobile();

  // TODO: no results state
  return (
    <>
      {props.loading && (
        <FOHView
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            paddingBottom: 150
          }}
        >
          <LogoLoader />
        </FOHView>
      )}
      <FOHView
        style={{
          display: !props.hasNextPage && !props.loading ? undefined : 'none'
        }}
      >
        <NoMorePositions {...props} isMobile={isMobile} />
      </FOHView>
    </>
  );
};
