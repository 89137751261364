import { GROUPS } from './groups';
import { ROLES } from './roles';

// length options and future options for sharing schedule
export const INTERVIEW_LENGTH_OPTIONS = [
  {
    label: '15 minutes',
    value: '15'
  },
  {
    label: '30 minutes',
    value: '30'
  },
  {
    label: '45 minutes',
    value: '45'
  },
  {
    label: '60 minutes',
    value: '60'
  }
];

export const INTERVIEW_WINDOW_OPTIONS = [
  {
    label: '1 week',
    value: 1
  },
  {
    label: '2 weeks',
    value: 2
  },
  {
    label: '3 weeks',
    value: 3
  },
  {
    label: '4 weeks',
    value: 4
  }
];

export const ALLOWED_ROLES_GROUPS = {
  GROUPS: {
    EMPLOYER: GROUPS.employers,
    CANDIDATE: GROUPS.candidates
  },
  ROLES: {
    ADMIN: [ROLES.ADMIN],
    ADMIN_USER: [ROLES.ADMIN, ROLES.USER],
    ADMIN_USER_INTERVIEWER: [ROLES.ADMIN, ROLES.USER, ROLES.INTERVIEWER]
  }
};

export const NASHVILLE_COORDS = {
  lat: 36.15906782868013,
  lng: -86.77915721429295
};
