import React from 'react';

import { useTranslation } from 'react-i18next';

import { useIsMobile } from '../../../utils';
import {
  FOHColors,
  FOHSpaceSmall,
  FOHSpace,
  FullWidthCard,
  FOHView,
  FOHTouchableOpacity,
  FOHDetailText,
  FOHHeaderH3,
  FOHBlackHelpIcon,
  FOHSuggestionIcon,
  FOHReactiveModal,
  FOHHeaderH1
} from '../../../components';

interface FOHHelpRequestChoiceModalProps {
  close: () => void;
  onPressOpenHelpForm: () => void;
  leftContentShift: number;
}

export const FOHHelpRequestChoiceModal = (
  props: FOHHelpRequestChoiceModalProps
) => {
  const { t } = useTranslation('HelpRequestScreen');
  const { onPressOpenHelpForm, close, leftContentShift } = props;
  const { isMobile } = useIsMobile();

  return (
    <FOHReactiveModal
      leftContentShift={leftContentShift}
      open={true}
      dialog={!isMobile}
      close={close}
    >
      <FOHView
        style={{ width: '100%', maxWidth: 600 }}
        testID={'help-choice-modal'}
      >
        <FOHHeaderH1>{t('howCanWeHelp')}</FOHHeaderH1>
        <FOHDetailText style={{ alignSelf: 'center', paddingTop: 10 }}>
          {t('helpRequestChoiceHeaderSubLabel')}
        </FOHDetailText>
        <FOHSpace />
        <FOHView
          style={{
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: 'space-between'
          }}
        >
          <FullWidthCard
            style={{
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              padding: 16
            }}
          >
            <FOHTouchableOpacity
              testID={'open-help-site'}
              onPress={() =>
                window?.open('https://help.fohandboh.com', '_blank')
              }
              style={{
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <FOHHeaderH3>{t('supportSiteButtonLabel')}</FOHHeaderH3>
              <FOHSpaceSmall />
              <FOHSuggestionIcon
                style={{ width: 50, height: 72, tintColor: FOHColors.BLUE }}
              />
              <FOHSpaceSmall />
              <FOHDetailText>{t('supportSiteButtonSubLabel')}</FOHDetailText>
              <FOHSpaceSmall />
            </FOHTouchableOpacity>
          </FullWidthCard>
          <FOHSpace />
          <FullWidthCard
            style={{
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              padding: 16
            }}
          >
            <FOHTouchableOpacity
              testID={'open-help-form'}
              onPress={onPressOpenHelpForm}
              style={{
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <FOHHeaderH3>{t('openSupportFormButtonLabel')}</FOHHeaderH3>
              <FOHSpaceSmall />
              <FOHBlackHelpIcon
                style={{ width: 72, height: 72, tintColor: FOHColors.BLUE }}
              />
              <FOHSpaceSmall />
              <FOHDetailText>
                {t('openSupportFormButtonSubLabel')}
              </FOHDetailText>
              <FOHSpaceSmall />
            </FOHTouchableOpacity>
          </FullWidthCard>
        </FOHView>
      </FOHView>
    </FOHReactiveModal>
  );
};

export default FOHHelpRequestChoiceModal;
