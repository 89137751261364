import React from 'react';

import { useTranslation } from 'react-i18next';

import {
  FOHImage,
  FOHView,
  FOHSpace,
  FOHSpaceMassive,
  FOHDetailText,
  FOHHeaderH2
} from '../../components';
import StopWatch from '../../images/stop_watch.svg';

const ArchiveLoadingScreen = () => {
  const { t } = useTranslation('SendBirdFeature');

  return (
    <FOHView style={{ alignItems: 'center' }}>
      <FOHSpaceMassive />
      <FOHHeaderH2>{t('archiveLoadingHeader')}</FOHHeaderH2>

      <FOHSpace />
      <FOHDetailText>{t('archiveDetails')}</FOHDetailText>
      <FOHSpace />
      <FOHImage
        source={{ uri: StopWatch }}
        style={{ width: 224, height: 200 }}
      />
    </FOHView>
  );
};

export default ArchiveLoadingScreen;
