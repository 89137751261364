import React, { useCallback } from 'react';

import { FlatList, View } from 'react-native';
import { length } from 'ramda';

import { FOHSpace, FOHLabel } from '../../ingredients';

import {
  FOHTableColumn,
  FOHTableColumnData,
  FOHTableColumnType,
  FOHTableRow,
  TableCol,
  TableRow
} from '../cells/FOHTableRow';

export interface FOHTableProps {
  hideTableHeader?: boolean;
  columns: Array<FOHTableColumn>;
  data: Array<FOHTableCols>;
  minHeight?: any;
  borders?: boolean;
}

interface FOHTableCols {
  columnData: Array<FOHTableColumnData>;
  id: string;
}

export const FOHTable = (props: FOHTableProps) => {
  const dataColumns = props.columns.filter(
    column => column.columnType !== FOHTableColumnType.action
  );
  const actionColumns = props.columns.filter(
    column => column.columnType === FOHTableColumnType.action
  );
  const { data } = props;
  return (
    <View>
      {!props.hideTableHeader ? (
        <TableRow
          style={{
            borderTopWidth: props.borders ? 1 : 0,
            borderColor: '#eeeeee'
          }}
        >
          {dataColumns.map((column, i) => (
            <TableCol {...column} key={`${column.columnName}_${i}`}>
              <FOHLabel style={{ color: '#878787' }}>
                {column.columnName}
              </FOHLabel>
            </TableCol>
          ))}
          <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
            {actionColumns.map((column, i) => (
              <TableCol
                {...column}
                key={`${column.columnName}_${i}`}
                style={{ flex: 1, minWidth: 100, marginRight: 4 }}
              />
            ))}
          </View>
        </TableRow>
      ) : (
        <></>
      )}
      <FlatList
        // @ts-ignore make a pr to rn definitely typed for better types on flatlist
        data={props.data}
        style={{ flex: 1, minHeight: props.minHeight || '100vh' }}
        initialNumToRender={length(props.data)}
        CellRendererComponent={useCallback(
          (CellRendererComponentProps: any) => (
            <View
              {...CellRendererComponentProps}
              style={{
                zIndex: length(data) - CellRendererComponentProps.index
              }}
            />
          ),
          []
        )}
        renderItem={({
          item,
          index
        }: {
          item: FOHTableCols;
          index: number;
        }) => {
          return (
            <View
              style={{
                zIndex: 99 + length(data) - index,
                borderWidth: props.borders ? 1 : 0,
                borderColor: '#eeeeee',
                marginBottom: 8
              }}
            >
              <FOHSpace />
              <FOHTableRow
                id={item.id}
                columns={props.columns}
                data={item.columnData}
              />
            </View>
          );
        }}
        // @ts-ignore as we're passing custom props for TableCols
        keyExtractor={(item: Array<FOHTableCols>) => item.id}
      />
    </View>
  );
};
