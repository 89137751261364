import React from 'react';

import { prop, path, defaultTo } from 'ramda';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

import { handleMutation, toDate } from '../../utils';
import {
  CalculatePriceDocument,
  useReactivateSubscriptionMutationMutation
} from '../../graphql/generated';
import {
  BILLING,
  EMPLOYER_SETTINGS_MENU,
  PLAN,
  UPGRADE
} from '../../constants';
import { useNavigation } from '../../utils/navigation';
import {
  FOHPositionPlanCell,
  FOHPositionBillingStatusType
} from '../../components';

import { usePositionStatusState } from '../EmployerPositions/usePositionStatusState';
import { usePositionTypes } from '../CandidateProfile/usePositionTypes';

export const PositionPlanCell = props => {
  const { payment, paygPrice } = props;
  const { navigateTo } = useNavigation();

  const { t } = useTranslation('PricingScreenFeature');

  const [reactivateSubscription] = useReactivateSubscriptionMutationMutation();

  const {
    status,
    openStatusDropdown,
    setOpenStatusDropdown,
    billingStatusOptions
  } = usePositionStatusState(path(['positions', 0, 'paymentStatus'], props));

  const { getStringForPositionType } = usePositionTypes();

  return (
    <FOHPositionPlanCell
      positions={props.positions.map(position => ({
        ...position,
        statusType: status.value,
        statusText: status.label,
        positionTypes: prop('positionType', position)
          .map(pos => getStringForPositionType(pos))
          .join(', ')
      }))}
      onPressPositionName={_slug => {}}
      priceLabel={t('price')}
      paygPrice={payment ? paygPrice : '-'}
      price={
        status.value === FOHPositionBillingStatusType.SUBSCRIBED
          ? path(['subscription', 'displayChargeAmount'], payment)
          : '-'
      }
      stripeSubscription={props.stripeSubscription}
      billingLabel={
        prop('subscriptionWillEndOn', payment)
          ? t('subscriptionWillEndOn')
          : t('nextSubscriptionDate')
      }
      billingDate={
        prop('subscriptionWillEndOn', payment)
          ? toDate(prop('subscriptionWillEndOn', payment), 'M/dd/yy')
          : path(['subscription', 'currentPeriodEnd'], payment)
          ? toDate(
              DateTime.fromSeconds(
                defaultTo(
                  0,
                  path(['subscription', 'currentPeriodEnd'], payment)
                )
              ),
              'M/dd/yy'
            )
          : '-'
      }
      statusLabel={t('status')}
      openStatus={openStatusDropdown}
      statusText={status.label}
      statusType={status.value}
      setOpenStatus={setOpenStatusDropdown}
      selectStatus={async (selectedStatus, positionId) => {
        if (prop('value', status) === prop('value', selectedStatus)) {
          setOpenStatusDropdown(false);
          return;
        }

        if (props.stripeSubscription && !props.payment.subscriptionEndedOn) {
          if (
            prop('value', selectedStatus) ===
            FOHPositionBillingStatusType.SUBSCRIBED
          ) {
            await handleMutation(
              reactivateSubscription({
                variables: {
                  stripeSubscription: props.stripeSubscription,
                  positions:
                    positionId ??
                    defaultTo(
                      [],
                      path(['positions'], props).map(position =>
                        prop('id', position)
                      )
                    )
                },
                refetchQueries: [
                  {
                    query: CalculatePriceDocument,
                    variables: {
                      location: props.location,
                      paymentStatus: 'notsubscribed'
                    }
                  },
                  {
                    query: CalculatePriceDocument,
                    variables: {
                      location: props.location,
                      paymentStatus: 'subscribed'
                    }
                  }
                ]
              })
            );
          } else {
            props.onCancel && props.onCancel(positionId);
          }
          setOpenStatusDropdown(false);
        } else {
          return navigateTo(
            `${EMPLOYER_SETTINGS_MENU}${BILLING}${PLAN}${UPGRADE}`,
            {
              search: `?location=${props.location}`
            }
          );
        }
      }}
      statusOptions={billingStatusOptions}
    />
  );
};
