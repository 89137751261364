import React from 'react';

import status from '../../../images/open_to_offers_status.svg';

import { FOHImage } from '../../ingredients';

export const FOHOpenToOffersStatusIcon = () => (
  <FOHImage source={{ uri: status }} style={{ width: 16, height: 14 }} />
);

export const FOHLargeOpenToOffersStatusIcon = () => (
  <FOHImage source={{ uri: status }} style={{ width: 23, height: 20 }} />
);
