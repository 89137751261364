import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { prop, defaultTo } from 'ramda';
import { setContext } from '@apollo/client/link/context';
import fetch from 'cross-fetch';

import { getAPIEndpoint, ENVIRONMENT } from './constants';

export const getTokenFromLocalStorage = () => {
  const token = localStorage
    ? prop('token', JSON.parse(localStorage.getItem('auth') as string))
    : '';
  return token;
};

const getAuthFromUrl = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return defaultTo('', urlParams.get('authorization'));
};

const httpLink = createHttpLink({
  fetch,
  uri: `${getAPIEndpoint()}/api/v1/graphql`
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: getTokenFromLocalStorage()
        ? `Bearer ${getTokenFromLocalStorage()}`
        : getAuthFromUrl()
        ? `Bearer ${getAuthFromUrl()}`
        : ''
    }
  };
});

export const apolloClient = new ApolloClient({
  connectToDevTools: ENVIRONMENT === 'local',
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

if (window && window.Cypress) {
  // this is only for cypress testing
  // only import from the app once, or else it will be a new instance
  // in Cypress, get the client from window.apolloClient, not via import
  window.apolloClient = apolloClient;
}
