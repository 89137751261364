import React, { FunctionComponent } from 'react';

import edit_marker from '../../../images/edit_marker.svg';

import { FOHImage, FOHColors } from '../../ingredients';
import { FOHIconProps } from '../../typings';

export const FOHEditMarker: FunctionComponent<FOHIconProps> = ({
  style
} = {}) => (
  <FOHImage
    source={{ uri: edit_marker }}
    style={[
      { width: 22, height: 22, tintColor: FOHColors.PRIMARY_TEAL_P1 },
      style
    ]}
  />
);
