import React from 'react';

import { useTranslation } from 'react-i18next';
import { animated } from 'react-spring';
import styled from 'styled-components/native';
import { length } from 'ramda';

import restaurant from '../../../images/restaurant.svg';
import { useClickOutside } from '../../../components/spices';
import {
  FOHColors,
  FOHDivider,
  FOHImage,
  FOHLabel,
  FOHLabelBold,
  FOHLocationPin,
  FOHReactiveModal,
  FOHSmallDetailLabel,
  FOHSpace,
  FOHSpaceSmall,
  FOHView
} from '../../../components';
import { useIsMobile } from '../../../utils';

export const FOHShowMyLocationsModal = props => {
  const { t } = useTranslation('MyTeamFeature');
  const { isMobile } = useIsMobile();
  const [ref] = useClickOutside({
    close: () => !!props.open && props.close()
  });

  return (
    <FOHReactiveModal
      ref={ref}
      open={props.open}
      close={props.close}
      dialog={props.dialog}
      style={{ maxWidth: isMobile ? 'unset' : 650 }}
    >
      <FOHView
        style={{
          alignItems: !isMobile ? 'flex-start' : 'center',
          justifyContent: 'flex-start',
          paddingHorizontal: isMobile ? 0 : 14,
          paddingVertical: isMobile ? 0 : 12,
          width: '100%'
        }}
      >
        <FOHLabelBold>
          {t('EmployerDashboardFeature:yourAllLocations')}
        </FOHLabelBold>
        <FOHSpaceSmall />
        <FOHDivider />
        <FOHSpace />
        <FOHView
          style={{
            width: '100%',
            backgroundColor: FOHColors.GRAYSCALE_10,
            paddingVertical: 12,
            paddingHorizontal: 8
          }}
        >
          {props.viewLocations && length(props.viewLocations) > 0
            ? props.viewLocations.map((location, keyIndex) => {
                return (
                  <>
                    <FOHView
                      key={keyIndex}
                      style={{
                        width: '100%',
                        backgroundColor: FOHColors.WHITE,
                        borderRadius: 4,
                        borderWidth: 1,
                        borderColor: FOHColors.GRAYSCALE_300,
                        flexDirection: 'row',
                        alignItems: 'center',
                        height: isMobile ? 82 : 75,
                        display: 'flex'
                      }}
                    >
                      <FOHView
                        style={{
                          flex: 1,
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-start'
                        }}
                      >
                        <FOHSpace />
                        <AnimatedDonut
                          style={{
                            borderWidth: 1,
                            borderColor: FOHColors.PRIMARY_TEAL_400,
                            borderRadius: '50%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: 47,
                            width: 47
                          }}
                        >
                          <FOHView
                            style={{
                              borderRadius: '50%',
                              justifyContent: 'center',
                              alignItems: 'center',
                              backgroundColor: FOHColors.GRAYSCALE_100,
                              height: 45,
                              width: 45
                            }}
                          >
                            <FOHLocationImage
                              source={{ uri: restaurant }}
                              style={{
                                height: 24,
                                width: 24
                              }}
                            />
                          </FOHView>
                        </AnimatedDonut>
                        <FOHSpace />
                        <FOHView style={{ alignItems: 'flex-start', flex: 1 }}>
                          <FOHLabel
                            style={{
                              color: FOHColors.GRAYSCALE_900,
                              fontWeight: 600
                            }}
                          >
                            {location.label}
                          </FOHLabel>
                          <FOHView
                            style={{
                              flexDirection: 'row',
                              alignItems: 'center'
                            }}
                          >
                            {!isMobile ? (
                              <>
                                <FOHLocationPin
                                  style={{
                                    width: 16,
                                    height: 16,
                                    tintColor: FOHColors.GRAYSCALE_400
                                  }}
                                />
                                <FOHSpaceSmall />
                              </>
                            ) : null}
                            <FOHSmallDetailLabel style={{ paddingRight: 12 }}>
                              {location.sublabel}
                            </FOHSmallDetailLabel>
                          </FOHView>
                        </FOHView>
                      </FOHView>
                    </FOHView>
                    {length(props.viewLocations) !== keyIndex + 1 ? (
                      <FOHSpaceSmall />
                    ) : null}
                  </>
                );
              })
            : null}
        </FOHView>
      </FOHView>
    </FOHReactiveModal>
  );
};

const AnimatedDonut = animated(FOHView);

const FOHLocationImage = styled(FOHImage)`
  border-radius: 50%;
`;

export default FOHShowMyLocationsModal;
