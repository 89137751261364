import React from 'react';

import { FOHView, Screen } from '../../ingredients';
import {
  FOHCandidateProfileBlockProps,
  FOHCandidateProfileBlock
} from '../../recipes';

interface FOHProfileScreenProps extends FOHCandidateProfileBlockProps {
  endorse?: () => void;
  endorseCTA: string;
  children?: any;
  statsCTA?: string;
  statsCTAIcon?: any;
  clickedStatsCTA?: () => void;
  ProfileCompleteComponent?: any;
  ProfileStateBannerComponent?: any;
}

export const FOHProfileScreen = (props: FOHProfileScreenProps) => (
  <Screen>
    <FOHView
      style={{
        paddingTop: 60,
        justifyContent: 'center'
      }}
    />
    <FOHView style={{ alignItems: 'center' }}>
      <FOHView
        style={{
          width: props.ProfileStateBannerComponent ? '100%' : 0,
          maxWidth: props.ProfileStateBannerComponent ? 1200 : 0,
          padding: props.ProfileStateBannerComponent ? 32 : 0,
          paddingTop: 0,
          paddingBottom: 0
        }}
      >
        {!!props.ProfileStateBannerComponent &&
          props.ProfileStateBannerComponent}
      </FOHView>
    </FOHView>
    <FOHView
      style={{
        flexDirection: props.vertical ? 'column' : 'row',
        backgroundColor: '#FFFFFF',
        borderBottomWidth: 1,
        borderColor: '#E5E5E5',
        zIndex: 1
      }}
    >
      <FOHView style={{ width: '100%', alignItems: 'center' }}>
        <FOHView style={{ width: '100%', maxWidth: 1200 }}>
          <FOHCandidateProfileBlock
            {...props}
            name={props.name}
            vertical={props.vertical}
            disabledMainProfileButton={props.disabledMainProfileButton}
            mainProfileButtonPressed={props.mainProfileButtonPressed}
            mainProfileButtonTitle={props.mainProfileButtonTitle}
            personalSummary={props.personalSummary}
            videoHasPlayed={props.videoHasPlayed}
            videoOpen={props.videoOpen}
            toggleVideoPlayback={props.toggleVideoPlayback}
            closeVideo={props.closeVideo}
            openVideo={props.openVideo}
            storyPhoto={props.storyPhoto}
            video={props.video}
            status={props.status}
            VideoComponent={props.VideoComponent}
            facebookPressed={props.facebookPressed}
            linkedInPressed={props.linkedInPressed}
            twitterPressed={props.twitterPressed}
            emailPressed={props.emailPressed}
            linkPressed={props.linkPressed}
            sharePressed={props.sharePressed}
            shareCTA={props.shareCTA}
            showShare={props.showShare}
            statusType={props.statusType}
            openEditPersonalSummary={props.openEditPersonalSummary}
          >
            {props.ProfileCompleteComponent}
          </FOHCandidateProfileBlock>
        </FOHView>
      </FOHView>
    </FOHView>
    <FOHView
      style={{
        justifyContent: 'center',
        paddingTop: 24,
        backgroundColor: '#fafafa',
        paddingBottom: 36,
        paddingLeft: 6,
        paddingRight: 6,
        flexDirection: props.vertical ? 'column' : 'row',
        alignItems: 'center'
      }}
    >
      <FOHView style={{ flex: 0.1 }}></FOHView>
      {props.children}
      <FOHView style={{ flex: 0.1 }}></FOHView>
    </FOHView>
  </Screen>
);
