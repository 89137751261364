import React from 'react';

import leftarrow from '../../../images/leftarrow.svg';

import { FOHImage } from '../../ingredients';

export const FOHLeftArrow = (props: any) => (
  <FOHImage
    source={{ uri: leftarrow }}
    style={
      props && !!props.style
        ? { width: 14, height: 10, ...props.style }
        : { width: 14, height: 10 }
    }
  />
);
