import React, { useState } from 'react';

import { length, path, prop } from 'ramda';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  isScrollCloseToBottom,
  toRelativeTime,
  unwrapEdgesAt,
  useDisableBackgroundScroll,
  useIsMobile
} from '../../utils';
import { fetchMore } from '../../utils/fetchMore';
import {
  useGetAllChatCandidateQuery,
  useGetNewChatCandidateQuery
} from '../../graphql/generated';
import { useNavigation } from '../../utils/navigation';
import { FOHChatModal } from '../../components';

import { useDirectMessage } from '../Chat/useDirectMessage';
import { useSidebarNav } from '../Navigation/useSidebar';
import { useGetMeData } from '../SignIn';

const formatToSendbirdType = match => ({
  nickname: path(['candidateProfile', 'user', 'firstName'], match),
  plainProfileUrl: path(['candidateProfile', 'user', 'profileImage'], match),
  userId: path(['candidateProfile', 'user', 'uuid'], match)
});

const formatCandidates = (matches, unread, t) =>
  matches.map(match => {
    const talkingTo = formatToSendbirdType(match);

    const formattedLastReadMessageTime = path(
      ['candidateProfile', 'user', 'lastMessage', 'createdAt'],
      match
    )
      ? toRelativeTime(
          new Date(
            parseInt(
              path(
                ['candidateProfile', 'user', 'lastMessage', 'createdAt'],
                match
              )
            )
          ).toISOString()
        )
      : '';
    return {
      members: [talkingTo],
      lastMessage: {
        message: path(
          ['candidateProfile', 'user', 'lastMessage', 'message'],
          match
        ),
        sender: talkingTo
      },
      // we dont show unread count so set it to 1 if unread
      unreadMessageCount: unread ? 1 : 0,
      formattedLastReadMessageTime,
      stage: `${prop('stage', match).charAt(0).toUpperCase()}${prop(
        'stage',
        match
      )
        .substr(1)
        .toLowerCase()}`,
      stageLabel: t('stageLabel'),
      // for navigation
      handle: path(['candidateProfile', 'handle'], match),
      uuid: path(['candidateProfile', 'user', 'uuid'], match)
    };
  });

export const NewChatsModal = props => {
  const { open, locationId } = props;
  const { goBack } = useNavigation();
  const params = useParams();
  const { positionId } = params;
  const [loadingMore, setLoadingMore] = useState(false);
  const variables = {
    position: positionId,
    locationId: locationId,
    first: 10,
    after: ''
  };
  const { t } = useTranslation('ChatFeature');

  const { data: me } = useGetMeData();

  const { goToDirectMessageWith } = useDirectMessage({
    ...props,
    sendbirdAccessToken: prop('sendbirdAccessToken', me)
  });

  const newChatsQuery = useGetNewChatCandidateQuery({
    skip: !positionId || positionId === 'all',
    variables
  });

  const newChats = unwrapEdgesAt(['data', 'matches', 'edges'], newChatsQuery);

  const allChatsQuery = useGetAllChatCandidateQuery({
    skip: !positionId || positionId === 'all',
    variables
  });

  const allChats = unwrapEdgesAt(['data', 'matches', 'edges'], allChatsQuery);
  const hasNewChats = length(newChats) > 0;

  const users = hasNewChats
    ? formatCandidates(newChats, true, t)
    : formatCandidates(allChats, false, t);

  const { isMobile } = useIsMobile();

  useDisableBackgroundScroll([props.open]);
  const { contentShift } = useSidebarNav(props);

  return (
    <FOHChatModal
      leftContentShift={contentShift}
      name={hasNewChats ? t('newChatsTitle') : t('allChatsTitle')}
      dialog={!isMobile}
      loading={
        hasNewChats
          ? newChatsQuery.loading
          : allChatsQuery.loading || loadingMore
      }
      headerFontSize={32}
      open={open}
      onScroll={async ({ nativeEvent }) => {
        if (isScrollCloseToBottom(nativeEvent) && !loadingMore) {
          setLoadingMore(true);
          hasNewChats
            ? await fetchMore(newChatsQuery, variables, 'matches')
            : await fetchMore(allChatsQuery, variables, 'matches');
          setLoadingMore(false);
        }
      }}
      close={() => {
        goBack();
      }}
      users={users}
      onChannelPress={async row => {
        await newChatsQuery.refetch();
        await goToDirectMessageWith({
          uuids: [prop('uuid', row)],
          location: locationId,
          position: positionId
        });
      }}
      onPressMessage={async row => {
        await goToDirectMessageWith({
          uuids: [prop('uuid', row)],
          location: locationId,
          position: positionId
        });
      }}
      messageLabel={t('CandidateCardFeature:chat')}
    />
  );
};
