import React from 'react';

import {
  FOHButton,
  FOHHeaderH2,
  FOHMultiLineTextField,
  FOHSmallDetailLabel,
  FOHSpaceSmall,
  FOHSpace,
  FOHView
} from '../../ingredients';

import { FOHForm } from './FOHForm';

export interface FOHApplicationMessageFormProps {
  message: string;
  messagePlaceholder: string;
  setMessage: (message: string) => void;
  applicationMessageTitle?: string;
  applicationMessageDetail?: string;
  submitLabel?: string;
  onPressSubmit?: () => void;
  error?: any;
  mobile?: boolean;

  disableSubmit?: boolean;
}

export const FOHApplicationMessageForm = (
  props: FOHApplicationMessageFormProps
) => (
  <FOHForm handleSubmit={props.onPressSubmit}>
    <>
      <FOHHeaderH2 style={{ textAlign: 'left' }}>
        {props.applicationMessageTitle}
      </FOHHeaderH2>
      <FOHSpaceSmall />
      <FOHSmallDetailLabel>
        {props.applicationMessageDetail}
      </FOHSmallDetailLabel>
      <FOHSpace />

      <FOHMultiLineTextField
        max={500}
        value={props.message}
        onChangeText={props.setMessage}
        placeholder={props.messagePlaceholder}
        error={props.error && props.error.message ? props.error.message : null}
      />
      <FOHSpaceSmall />
    </>

    {props.onPressSubmit && props.submitLabel && (
      <>
        <FOHSpaceSmall />
        <FOHView
          style={{
            flexDirection: 'row',
            justifyContent: props.mobile ? 'center' : 'flex-end'
          }}
        >
          <FOHButton
            onPress={props.onPressSubmit}
            title={props.submitLabel}
            fullWidth={props.mobile}
            disabled={props.disableSubmit}
          />
        </FOHView>
      </>
    )}
  </FOHForm>
);
