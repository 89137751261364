import React, { useState, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { path, prop, length, compose } from 'ramda';
import { useParams } from 'react-router-dom';
import { useDebounce } from 'use-hooks';
import { Channel, withSendBird } from '@sendbird/uikit-react';
import '@sendbird/uikit-react/dist/index.css';

import { useIsMobile } from '../../utils';
import {
  PAYASYOUGO,
  SENDBIRD_CHAT,
  TEMPLATES,
  getAppBaseUrl
} from '../../constants';
import {
  useGetChannelAndFullDetailsQuery,
  useGetEmployerCandidateForHandleQuery
} from '../../graphql/generated';
import {
  FOHInverseButton,
  FOHMessageTemplate,
  FOHView,
  FullWidthCard,
  FOHExpiredBanner,
  FOHGreenCircleDecoration,
  FOHColors,
  FOHExpiredChatBubble
} from '../../components';
import { useNavigation } from '../../utils/navigation';

import { SelectPositionsModal } from '../Billing/SelectPositionsModal';
import { useCheckout, withStripe } from '../Billing';
import { useCandidateFeedContext } from '../CandidateFeed/useCandidateFeedContext';
import ErrorBoundary from '../ErrorBoundary';
import { useGetMeData } from '../SignIn';
import { useFilteredEmployerMessageTemplates } from '../EmployerMessageTemplate/useFilteredEmployerMessageTemplates';

import { SBLocationCard, SBInterviewCard } from './SendBirdCustomConversations';
import { useChannelMatches } from './useChannelMatches';
import { SendbirdChannelHeader } from './SendbirdChannelHeader';
import { EmployerMagicLinkMessage } from './EmployerMagicLinkMessage';
import { ChatInput } from './ChatInput';
import { populateMessage } from './ChatWindow';
import { needsLinksHidden } from './utils';

const CustomChannel = props => {
  const { modal } = useParams();
  const { navigateTo, location: navLocation } = useNavigation();

  const { sendbirdUserId, channelUrl } = props;
  const { isMobile } = useIsMobile();
  const { t } = useTranslation('ChatFeature');
  const { data: me } = useGetMeData();
  const isCandidate = !!path(['candidateProfile', 'id'], me);
  const channelDetailsQuery = useGetChannelAndFullDetailsQuery({
    skip: !channelUrl,
    variables: { sendbirdChannelId: channelUrl }
  });

  const handle = path(
    ['data', 'channel', 'candidateUser', 'candidateProfile', 'handle'],
    channelDetailsQuery
  );

  const { setCandidateHandle, setEmployerCandidate, setActiveStageId } =
    useCandidateFeedContext();

  const employerCandidateForHandle = useGetEmployerCandidateForHandleQuery({
    skip: isCandidate,
    variables: { handle }
  });

  const employerCandidate = path(
    ['data', 'profile', 'employerCandidate'],
    employerCandidateForHandle
  );

  const [message, setMessage] = useState('');
  const [openMessageTemplate, setOpenMessageTemplate] = useState(false);
  const [search, setSearch] = useState('');
  const [template, setTemplate] = useState();

  const [height, setHeight] = useState(52);
  const inputFile = useRef(null);
  const [, setFileToUpload] = useState();

  const { payingForMatch, matchPositions, candidate, loadingPaying } =
    useChannelMatches({
      channelUrl,
      sendbirdUserId,
      isCandidate
    });

  const { redirectToStripeCheckout } = useCheckout(props);
  const successUrl = `${getAppBaseUrl()}/chat/${channelUrl}`;
  const locationToUpgrade = path(
    [0, 'location'],
    matchPositions.filter(pos => prop('paymentStatus', pos) !== 'SUBSCRIBED')
  );

  const payingForPositionOrLocation =
    path(
      ['data', 'channel', 'lastPosition', 'paymentStatus'],
      channelDetailsQuery
    ) === 'SUBSCRIBED' ||
    path(
      ['data', 'channel', 'location', 'paymentStatus'],
      channelDetailsQuery
    ) === 'SUBSCRIBED' ||
    path(
      // deprecated lastLocation for old chats
      ['data', 'channel', 'lastLocation', 'paymentStatus'],
      channelDetailsQuery
    ) === 'SUBSCRIBED';

  const debouncedSearchText = useDebounce(search, 1000);

  const { data: templates } = useFilteredEmployerMessageTemplates({
    isSkipped: isCandidate,
    searchText: debouncedSearchText
  });

  const candidateName = `${prop('firstName', candidate)} ${prop(
    'lastName',
    candidate
  )}`;

  const positionName = path(
    ['data', 'channel', 'lastPosition', 'positionName'],
    channelDetailsQuery
  );
  const locationName = path(
    ['data', 'channel', 'location', 'name'],
    channelDetailsQuery
  );

  const positionLink = path(
    ['data', 'channel', 'lastPosition', 'shortUrl'],
    channelDetailsQuery
  );

  return (
    <>
      <Channel
        onChatHeaderActionClick={() => {
          // setShowSettings(true);
        }}
        sendbirdUserId={sendbirdUserId}
        channelUrl={channelUrl}
        reconnectOnIdle
        renderMessageInput={
          isCandidate
            ? // default message input
              undefined
            : // no input
            !payingForMatch && !payingForPositionOrLocation && !loadingPaying
            ? () => {
                return (
                  <FOHExpiredBanner
                    title={t('expiredChatTitle')}
                    body={t('expiredChatDetail')}
                    buttonLabel={t('activatePosition')}
                    onButtonPress={() => {
                      navigateTo(`${SENDBIRD_CHAT}/${channelUrl}${PAYASYOUGO}`);
                    }}
                    mobile={isMobile}
                  >
                    <FOHInverseButton
                      title={t('upgradeToUnlimited', {
                        locationName: prop('name', locationToUpgrade)
                      })}
                      icon={FOHGreenCircleDecoration}
                      textColor={FOHColors.BLACK}
                      centerIcon={true}
                      onPress={() => {
                        redirectToStripeCheckout({
                          url: successUrl,
                          locations: [prop('rowId', locationToUpgrade)],
                          plan: 'unlimited',
                          cycle: 'monthly'
                        });
                      }}
                    />
                  </FOHExpiredBanner>
                );
              }
            : (!payingForMatch && !payingForPositionOrLocation) || loadingPaying
            ? () => {}
            : () => (
                <ErrorBoundary>
                  <ChatInput
                    message={message}
                    openMessageTemplate={openMessageTemplate}
                    setOpenMessageTemplate={setOpenMessageTemplate}
                    height={height}
                    setHeight={setHeight}
                    setMessage={setMessage}
                    employerCandidate={employerCandidate}
                    setActiveStageId={setActiveStageId}
                    setCandidateHandle={setCandidateHandle}
                    setEmployerCandidate={setEmployerCandidate}
                    handle={handle}
                    channelDetailsQuery={channelDetailsQuery}
                    channelUrl={channelUrl}
                    inputFile={inputFile}
                    setFileToUpload={setFileToUpload}
                  />
                </ErrorBoundary>
              )
        }
        renderChannelHeader={() => {
          return (
            <SendbirdChannelHeader
              {...props}
              isCandidate={isCandidate}
              hideChannel={props.hideChannel}
            />
          );
        }}
        // https://codesandbox.io/s/custommessage-ivi9e?file=/src/CustomizedApp.js:1096-1469
        // eslint-disable-next-line @typescript-eslint/no-shadow
        renderMessage={({ message }) => {
          // RENDER INTERVIEW CARDS HERE
          const text = message.message;
          if (!text) {
            return;
          }

          const isMyMessage =
            path(['sender', 'userId'], message) === sendbirdUserId;
          const hideLink = needsLinksHidden(text, isCandidate);

          if (
            hideLink ||
            text.includes('/location-card') ||
            text.includes('/interview-card') ||
            (((!payingForMatch && !payingForPositionOrLocation) ||
              loadingPaying) &&
              !isCandidate)
          ) {
            const slug = prop(1, text.split('/location-card'));
            const interviewUUID = prop(1, text.split('/interview-card'));

            return (
              <FOHView
                style={{
                  alignItems: isMyMessage ? 'flex-end' : 'flex-start',
                  flex: 1
                }}
              >
                {hideLink ? (
                  <EmployerMagicLinkMessage message={message} />
                ) : (!payingForMatch && !payingForPositionOrLocation) ||
                  loadingPaying ? (
                  <FOHView style={{ width: isMobile ? 200 : 400, padding: 12 }}>
                    <FOHExpiredChatBubble
                      numbnerOfLines={parseInt((length(text) * 5) % 3) + 1}
                      leftSpeach={!isMyMessage}
                    />
                  </FOHView>
                ) : slug ? (
                  <SBLocationCard slug={slug} message={message} />
                ) : interviewUUID ? (
                  <SBInterviewCard
                    interviewUUID={interviewUUID}
                    message={message}
                  />
                ) : (
                  <></>
                )}
              </FOHView>
            );
          }
        }}
      />
      <SelectPositionsModal
        {...props}
        open={modal === 'payasyougo'}
        candidateName={candidateName}
        successUrl={successUrl}
        matchingPositions={matchPositions}
        close={() => {
          navigateTo({
            pathname: `${SENDBIRD_CHAT}/${channelUrl}`,
            search: props.backSearch,
            state: navLocation.state
          });
        }}
      />
      {openMessageTemplate ? (
        <FOHView
          style={{
            position: 'fixed',
            right: isMobile ? 0 : 170,
            width: 673,
            height: 397,
            bottom: 97,
            zIndex: 99
          }}
        >
          <FullWidthCard
            shadow={true}
            style={{
              maxWidth: 673,
              padding: 6
            }}
          >
            <FOHMessageTemplate
              templateTitle={t('templateLabel')}
              onChangeSearch={value => setSearch(value)}
              searchValue={search}
              searchPlaceholder={t('searchTemplatePlaceholder')}
              scrollHeight={240}
              messageTemplates={templates}
              selectedTemplate={template}
              editTemplateLabel={t('editTemplates')}
              useLabel={t('useItLabel')}
              setTemplate={setTemplate}
              editTemplate={() => navigateTo(TEMPLATES)}
              onUseTemplate={() => {
                if (template) {
                  setMessage(
                    populateMessage(
                      template.messageContent,
                      locationName,
                      positionName,
                      candidateName,
                      positionLink
                    )
                  );
                }

                setOpenMessageTemplate(!openMessageTemplate);
              }}
              close={() => {
                setOpenMessageTemplate(false);
              }}
            />
          </FullWidthCard>
        </FOHView>
      ) : (
        <></>
      )}
    </>
  );
};

export const SendbirdChannel = compose(withStripe, withSendBird)(CustomChannel);
