import React, { useState } from 'react';

import { useGoogleMap } from '@react-google-maps/api';
import { length, prop } from 'ramda';

// Center map based on lat and long from server
export const MapControls = props => {
  const map = useGoogleMap();
  const [locations] = useState(props.locations);
  const { centerLat, centerLong } = props;
  // on location update pan to center lat and long from the server
  React.useEffect(() => {
    if (map && length(props.openLocations) === 0) {
      map.panTo({
        lat: parseFloat(centerLat || 36.162663),
        lng: parseFloat(centerLong || -86.781601)
      });

      if (window.google && window.google.maps) {
        const bounds = new window.google.maps.LatLngBounds();

        if (
          length(locations) > 0 &&
          locations[0].latitude &&
          locations[0].longitude &&
          bounds
        ) {
          locations.forEach(loc => {
            if (prop('latitude', loc) && prop('longitude', loc)) {
              bounds.extend({
                lat: parseFloat(loc.latitude),
                lng: parseFloat(loc.longitude)
              });
            }
          });
          map.fitBounds(bounds);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations, props.key]);

  return null;
};
