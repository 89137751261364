import React, { useEffect, useMemo, useState } from 'react';

import { defaultTo, indexOf, length, path, prop } from 'ramda';
import { useApolloClient } from '@apollo/client';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import {
  FOHBannerType,
  FOHButton,
  FOHButtonBordered,
  FOHColors,
  FOHErrorLabel,
  FOHFromToSelect,
  FOHLabel,
  FOHMultiSelectCheckbox,
  FOHSpace,
  FOHSpaceSmall,
  FOHView
} from '../../../components';
import {
  batchMutations,
  formatRangeToTimeSelect,
  generateTimeIntervals,
  multiSelectItem,
  useIsMobile
} from '../../../utils';
import {
  AVAILABILITY_WEEKDAYS,
  availabilityDayKeys,
  getDayForScheduleDayKey
} from '../../../constants';
import { InterviewScheduleDocument } from '../../../graphql/generated';

import { useBanner } from '../../Navigation';

import { generateScheduleRangeMutations } from '../generateScheduleRangeMutations';

const FOHAddOrEditInterviewSchedules = props => {
  const { t } = useTranslation('EmployerInterviewScheduleFeature');
  const { isMobile, width } = useIsMobile();
  const { setBanner } = useBanner();

  const client = useApolloClient();

  const [toTime, setToTime] = useState('');
  const [fromTime, setFromTime] = useState('');
  const [error, setError] = useState(undefined);

  const ranges = useMemo(
    () => defaultTo([], prop('ranges', props)),
    [props.ranges]
  );

  const defaultDays = useMemo(
    () => ranges.map(range => getDayForScheduleDayKey(prop('day', range))),
    [ranges]
  );

  const [selectedDays, setSelectedDays] = useState(
    length(defaultDays) === 0
      ? [
          'availability_sunday',
          'availability_saturday',
          'availability_friday',
          'availability_thursday',
          'availability_wednesday',
          'availability_tuesday',
          'availability_monday'
        ]
      : defaultDays
  );

  const handleAddOrUpdateSchedule = async () => {
    const deletes = ranges
      .filter(
        range =>
          !selectedDays.includes(getDayForScheduleDayKey(prop('day', range)))
      )
      .map(range => ({ id: prop('id', range) }));

    const from = DateTime.fromFormat(fromTime, 'h:mm a', {
      zone: 'local'
    });

    const to = DateTime.fromFormat(toTime, 'h:mm a', {
      zone: 'local'
    });

    const creates = selectedDays
      .filter(day => !defaultDays.includes(day))
      .map(day => ({
        day: indexOf(day, availabilityDayKeys),
        schedule: path(['interviewSchedule', 'id'], props),
        startHour: from.hour,
        startMinute: from.minute,
        endHour: to.hour,
        endMinute: to.minute
      }));

    const updates = ranges
      .filter(range =>
        selectedDays.includes(getDayForScheduleDayKey(prop('day', range)))
      )
      .map(range => ({
        id: prop('id', range),
        day: indexOf(
          getDayForScheduleDayKey(prop('day', range)),
          availabilityDayKeys
        ),
        startHour: from.hour,
        startMinute: from.minute,
        endHour: to.hour,
        endMinute: to.minute
      }));

    const { deleteRangeMutations, updateRangeMutations, createRangeMutations } =
      generateScheduleRangeMutations({
        deletes,
        creates,
        updates
      });
    const batchedDelete = batchMutations('ScheduleRangeMutations', [
      ...deleteRangeMutations,
      ...updateRangeMutations,
      ...createRangeMutations
    ]);

    await client.mutate({
      mutation: batchedDelete,
      refetchQueries: [
        {
          query: InterviewScheduleDocument,
          variables: {}
        }
      ]
    });

    props.scrollToTop();
    props.onCancel();

    setBanner({
      bannerType: FOHBannerType.SUCCESS,
      bannerMessage: t('common:success')
    });
  };

  useEffect(() => {
    setSelectedDays(defaultDays);
  }, [defaultDays]);

  useEffect(() => {
    const defaultToTime = defaultTo(
      '4:00 PM',
      formatRangeToTimeSelect(
        path([0, 'endHour'], ranges),
        path([0, 'endMinute'], ranges)
      )
    );

    const defaultFromTime = defaultTo(
      '2:00 PM',
      formatRangeToTimeSelect(
        path([0, 'startHour'], ranges),
        path([0, 'startMinute'], ranges)
      )
    );
    setFromTime(defaultFromTime);
    setToTime(defaultToTime);
  }, [
    length(ranges),
    path([0, 'startHour'], ranges),
    path([0, 'endHour'], ranges)
  ]);

  useEffect(() => {
    const from = DateTime.fromFormat(fromTime, 'h:mm a');
    const to = DateTime.fromFormat(toTime, 'h:mm a');
    const invalidTime = from >= to;
    if (invalidTime) {
      setError(t('validRangeError'));
    } else {
      setError('');
    }
  }, [fromTime, toTime]);

  const isNewSchedule = useMemo(() => ranges && length(ranges) === 0, [ranges]);

  return (
    <FOHView
      style={{
        paddingHorizontal: isMobile ? 14 : 16,
        paddingVertical: 14,
        backgroundColor: FOHColors.WHITE,
        borderWidth: 1,
        borderColor: FOHColors.GRAYSCALE_200,
        borderRadius: 4
      }}
    >
      <FOHLabel
        style={{
          color: FOHColors.GRAYSCALE_900,
          fontSize: 18,
          lineHeight: 20
        }}
        heavy
      >
        {isNewSchedule ? t('addNewTimeSlotLabel') : t('updateScheduleLabel')}
      </FOHLabel>
      <FOHSpaceSmall />
      <FOHView style={{ flex: 1 }}>
        <FOHFromToSelect
          timeChoices={generateTimeIntervals({ numberOfIntervals: 28 })}
          toTime={toTime}
          fromTime={fromTime}
          setToTime={setToTime}
          setFromTime={setFromTime}
          placeholderTo={t('placeholderTo')}
          placeholderFrom={t('placeholderFrom')}
        />
      </FOHView>
      <FOHSpaceSmall />
      <FOHLabel
        style={{
          color: FOHColors.GRAYSCALE_900,
          fontSize: 16,
          lineHeight: 24
        }}
        heavy
      >
        {t('daysLabel')}
      </FOHLabel>
      <FOHMultiSelectCheckbox
        options={AVAILABILITY_WEEKDAYS.map(day => ({
          ...day,
          label: t(day.label)
        }))}
        selectedValues={selectedDays}
        setSelectedValue={day =>
          multiSelectItem(day, setSelectedDays, selectedDays)
        }
      />
      <FOHSpace />
      {error ? (
        <>
          <FOHErrorLabel>{error}</FOHErrorLabel>
          <FOHSpace />
        </>
      ) : null}
      <FOHView
        style={{
          flex: 1,
          alignItems: 'center',
          flexDirection: width > 449 ? 'row' : 'column',
          justifyContent: 'center',
          flexWrap: 'wrap'
        }}
      >
        <FOHButtonBordered
          color={FOHColors.WHITE}
          style={{ width: 168 }}
          disabled={props.isPreviousDisabled}
          title={t('common:cancel')}
          onPress={props.onCancel}
        />
        {width <= 449 ? <FOHSpaceSmall /> : null}
        <FOHButton
          style={{
            maxWidth: 168,
            width: '100%',
            marginLeft: width > 449 ? 8 : 0
          }}
          onPress={handleAddOrUpdateSchedule}
          title={
            isNewSchedule
              ? t('WorkHistoryFeature:addButton')
              : t('WorkHistoryFeature:updateButton')
          }
          disabled={length(selectedDays) === 0 || error || !fromTime || !toTime}
        />
      </FOHView>
      <FOHSpaceSmall />
    </FOHView>
  );
};

export default FOHAddOrEditInterviewSchedules;
