import React from 'react';

import { FOHDetailText, FOHHeaderH3, FOHView } from '../../ingredients';

export interface FOHInterviewDetailsBlockProps {
  positionLabel: string;
  positionName: string;
  interviewLengthLabel?: string;
  interviewLength?: string;
  locationLabel?: string; // zoom, location, or phone
  locationName?: string;
  interviewMeetingType: FOHInterviewMeetingType;
  interviewMeetingTypeLabel: string;
  whereLabel?: string; //zoom, or phone
  where?: string;
  alwaysShowWhere?: boolean;
}

export enum FOHInterviewMeetingType {
  PHONE = 'PHONE',
  INPERSON = 'INPERSON',
  REMOTE = 'REMOTE'
}

export const FOHInterviewDetailsBlock = (
  props: FOHInterviewDetailsBlockProps
) => (
  <FOHView style={{ flex: 1 }}>
    <FOHView
      style={{
        flexDirection: 'row',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        flex: 1
      }}
    >
      <FOHView style={{ flex: 1, minWidth: 140 }}>
        <FOHDetailText>{props.positionLabel}</FOHDetailText>
        <FOHHeaderH3 style={{ textAlign: 'left' }}>
          {props.positionName}
        </FOHHeaderH3>
      </FOHView>
      <FOHView style={{ flex: 1, minWidth: 140 }}>
        <FOHDetailText>
          {props.interviewMeetingType === FOHInterviewMeetingType.PHONE ||
          props.interviewMeetingType === FOHInterviewMeetingType.REMOTE
            ? props.whereLabel
            : props.interviewLengthLabel}
        </FOHDetailText>
        <FOHHeaderH3 style={{ textAlign: 'left' }}>
          {props.interviewMeetingType === FOHInterviewMeetingType.PHONE ||
          props.interviewMeetingType === FOHInterviewMeetingType.REMOTE
            ? props.interviewMeetingTypeLabel
            : props.interviewLength}
        </FOHHeaderH3>
      </FOHView>
    </FOHView>
    <FOHView
      style={{
        flexDirection: 'row',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        flex: 2
      }}
    >
      {props.interviewMeetingType === FOHInterviewMeetingType.INPERSON && (
        <FOHView style={{ flex: 1, minWidth: 140 }}>
          <FOHDetailText>{props.whereLabel}</FOHDetailText>
          <FOHHeaderH3 style={{ textAlign: 'left' }}>
            {props.interviewMeetingTypeLabel}
          </FOHHeaderH3>
        </FOHView>
      )}
      <FOHView style={{ flex: 1, minWidth: 140 }}>
        <FOHDetailText>{props.locationLabel}</FOHDetailText>
        <FOHHeaderH3 style={{ textAlign: 'left' }}>
          {props.interviewMeetingType === FOHInterviewMeetingType.PHONE ||
          props.interviewMeetingType === FOHInterviewMeetingType.REMOTE
            ? props.where
            : props.locationName}
        </FOHHeaderH3>
      </FOHView>
    </FOHView>
  </FOHView>
);
