import React from 'react';

import { length } from 'ramda';

import {
  FOHHeaderH1,
  FOHSmallDetailLabel,
  FOHSpaceSmall,
  FOHFollowBadge,
  FOHSpace,
  FOHLabel,
  FOHView,
  FOHImage
} from '../../ingredients';
import { FOHTagList } from '../../recipes/tables/FOHTagList';

import { FOHRestaurantLocation } from './FOHLocationHeader';

export interface FOHLocationOverviewProps extends FOHRestaurantLocation {
  followerCountOnBottom?: boolean;
  largePhoto?: boolean;
  photoWidth?: number;
  photoHeight?: number;
}

export const FOHLocationOverview = (props: FOHLocationOverviewProps) => {
  const tags = props.tags || [];

  return (
    <FOHView
      style={{
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '100%',
        paddingRight: 0,
        minWidth: 280
      }}
    >
      {props.largePhoto ? (
        <>
          <FOHImage
            source={{ uri: props.image }}
            style={{
              width: props.photoWidth || 283,
              height: props.photoHeight || 179,
              borderRadius: 3,
              borderWidth: 1,
              borderColor: '#eeeeee'
            }}
          />
          <FOHSpace />
        </>
      ) : null}
      <FOHView
        style={{ flexDirection: 'row', flex: 1, justifyContent: 'flex-start' }}
      >
        {!props.largePhoto ? (
          <>
            <FOHImage
              source={{ uri: props.image }}
              style={{
                width: props.photoWidth || 80,
                height: props.photoHeight || 80,
                borderRadius: 3,
                borderWidth: 1,
                borderColor: '#eeeeee'
              }}
            />

            <FOHSpace />
          </>
        ) : null}
        <FOHView style={{ flex: 1, justifyContent: 'flex-start' }}>
          <FOHHeaderH1
            style={{
              fontSize: 24,
              textAlign: 'left',
              justifyContent: 'left',
              lineHeight: 26
            }}
          >
            {props.name}
          </FOHHeaderH1>
          <FOHView style={{ flexDirection: 'row' }}>
            <FOHSmallDetailLabel>{props.formattedAddress}</FOHSmallDetailLabel>
          </FOHView>
          {!!props.followerCountLabel && !props.followerCountOnBottom ? (
            <FOHView style={{ minWidth: 240 }}>
              <FOHSpaceSmall />
              <FOHFollowBadge label={props.followerCountLabel} />
            </FOHView>
          ) : (
            <></>
          )}
        </FOHView>
      </FOHView>
      {!!props.followerCountLabel && props.followerCountOnBottom ? (
        <FOHView style={{ minWidth: 240 }}>
          <FOHSpaceSmall />
          <FOHFollowBadge label={props.followerCountLabel} />
        </FOHView>
      ) : (
        <></>
      )}
      {length(tags) > 0 || props.isHiring ? (
        <>
          <FOHSpaceSmall />
          <FOHTagList tags={tags} />
        </>
      ) : (
        <></>
      )}
      <FOHSpace />
      <FOHLabel>{props.summary}</FOHLabel>
    </FOHView>
  );
};
