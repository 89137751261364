import React from 'react';

import styled from 'styled-components/native';
import { length } from 'ramda';

import {
  FOHHeaderText,
  FOHButton,
  FOHView,
  FOHSpaceLarger,
  FOHSpaceSmall,
  FOHTextButton,
  FOHColors,
  FOHSpace,
  FOHAltSectionLabel,
  FOHVerticalDivider,
  FOHDivider,
  FOHHighlightText,
  FOHLabelBold,
  FOHSmallDetailLabel,
  FOHFonts,
  FOHReactiveModal
} from '../../../components';
import { useIsMobile } from '../../../utils';

import { WithReactiveModalProps } from '../../Navigation';

export interface FOHLocationPositionCreationSuccessModalProps
  extends WithReactiveModalProps {
  dialog?: boolean;
  leftContentShift?: number;
  headerLabel: string;
  detailLabel: string;
  highlightLocationOrPositionName: string;
  buttonLabel: string;
  onButtonPress: () => void;
  actionsLabel?: string;
  actions?: {
    testID?: string | undefined;
    actionLabel: string;
    onActionClick: () => void;
  }[];
}

const FOHLocationPositionCreationSuccessModal: React.FC<
  FOHLocationPositionCreationSuccessModalProps
> = props => {
  const { isMobile } = useIsMobile();

  return (
    <FOHReactiveModal open={props.open} close={props.close} dialog={!isMobile}>
      <FOHView
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: props.dialog ? 24 : 14,
          paddingHorizontal: 60
        }}
        testID={'FOHLocationPositionCreationSuccessModal'}
      >
        <FOHView style={{ paddingHorizontal: 50, textAlign: 'center' }}>
          <FOHHeaderText
            style={{ fontSize: 24, lineHeight: 32, marginBottom: 8 }}
          >
            {props.headerLabel}
          </FOHHeaderText>
          <FOHHighlightText
            labelCmp={DetailText}
            emphasizedWord={props.highlightLocationOrPositionName}
            highlightColor={FOHColors.PRIMARY_TEAL_P1}
            boldLabelCmp={FOHLabelBold}
          >
            {props.detailLabel}
          </FOHHighlightText>
        </FOHView>
        <FOHSpaceLarger />
        <FOHView
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            width: '100%'
          }}
        >
          <FOHButton
            onPress={props.onButtonPress}
            title={props.buttonLabel}
            style={{
              minWidth: 142,
              maxWidth: 200,
              width: '100%',
              height: 52,
              marginHorizontal: 8
            }}
          />
        </FOHView>
        <FOHSpace />
        <FOHView style={{ paddingHorizontal: 100, width: '100%' }}>
          <FOHDivider
            style={{
              backgroundColor: FOHColors.GRAYSCALE_200
            }}
          />
        </FOHView>
        <FOHSpace />
        <FOHView>
          {props.actionsLabel && (
            <>
              <FOHAltSectionLabel style={{ textAlign: 'center' }}>
                {props.actionsLabel}
              </FOHAltSectionLabel>
              <FOHSpaceSmall />
            </>
          )}
          {props.actions && length(props.actions) > 0 ? (
            <FOHView
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'row',
                justifyContent: 'center',
                width: '100%'
              }}
            >
              {props.actions.map((action, index) => (
                <FOHView
                  key={index}
                  style={{
                    paddingHorizontal: 5,
                    height: 36,
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                >
                  <FOHTextButton
                    onPress={action.onActionClick}
                    title={action.actionLabel}
                    underline
                    style={{
                      paddingRight: 10,
                      color: FOHColors.PRIMARY_TEAL_P1
                    }}
                    testID={action.testID}
                  />
                  {props.actions?.length !== index + 1 && (
                    <FOHVerticalDivider
                      style={{
                        width: 2,
                        height: 24,
                        backgroundColor: FOHColors.VERY_LIGHT_GRAY
                      }}
                    />
                  )}
                </FOHView>
              ))}
            </FOHView>
          ) : null}
        </FOHView>
      </FOHView>
    </FOHReactiveModal>
  );
};

const DetailText = styled(FOHSmallDetailLabel)`
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: ${FOHColors.GRAYSCALE_500};
  text-align: center;
`;

export default FOHLocationPositionCreationSuccessModal;
