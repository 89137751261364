import React from 'react';

import styled from 'styled-components/native';

import { CommonProps } from '../../../typings';
import {
  FOHView,
  SPACING_SCALE,
  FOHButton,
  FOHColors,
  FOHDetailText
} from '../../../ingredients';
import {
  FOHSearchableSelectDropdown,
  FOHSearchableSelectDropdownProps,
  FOHSearchBar
} from '../../../recipes';

const MOBILE_SUBMIT = 80;
const DESKTOP_SUBMIT = 130;

export const PositionsWrapper = styled(FOHView)<CommonProps>`
  width: 100%;

  ${({ isMobile }: { isMobile: boolean }) =>
    isMobile
      ? ``
      : `
        padding: ${SPACING_SCALE.huge}px;
        border-radius: 8;
        background-color: ${FOHColors.LIGHT_GRAY};
  `}
`;

export const SearchCluster = styled(FOHView)<CommonProps>`
  z-index: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: ${({ isMobile }: { isMobile: boolean }) =>
    isMobile ? 'wrap' : 'no-wrap'};
  margin-top: ${({ isMobile }: { isMobile: boolean }) =>
    isMobile ? SPACING_SCALE.huge : 0};

  margin-bottom: ${SPACING_SCALE.larger}px;
  width: 100%;
`;

export const SearchField = styled(FOHSearchBar)<CommonProps>`
  margin-bottom: ${({ isMobile }: { isMobile: boolean }) =>
    isMobile ? SPACING_SCALE.regular : ''};
  flex-basis: ${({ isMobile }: { isMobile: boolean }) =>
    isMobile ? '100%' : `50% `};
  margin-right: ${SPACING_SCALE.regular}px;
`;

export const LocationDropdown = styled(
  (props: FOHSearchableSelectDropdownProps) => (
    <FOHSearchableSelectDropdown {...props} />
  )
)<{
  isMobile: boolean;
}>`
  width: auto;
  flex: 1;
`;

export const InformationText = styled(FOHDetailText)`
  justify-content: center;
  min-height: 300px;
  padding: ${SPACING_SCALE.massive}px;
`;

export const SubmitButton = styled(FOHButton)<{
  isMobile: boolean;
}>`
  flex-basis: ${({ isMobile }: { isMobile: boolean }) =>
    isMobile ? MOBILE_SUBMIT : DESKTOP_SUBMIT};
`;
