import React from 'react';

import notificationCompletedScreening from '../../../images/notification-completed-screening.svg';

import { FOHImage } from '../../ingredients';

export interface FOHNotificationCompletedScreeningProps {}

export const FOHNotificationCompletedScreening = (
  props: FOHNotificationCompletedScreeningProps
) => (
  <FOHImage
    {...props}
    source={{ uri: notificationCompletedScreening }}
    style={{ width: 24, height: 24 }}
    testID={'FOHNotificationCompletedScreening'}
  />
);
