import React from 'react';

import { View, Text, TouchableOpacity } from 'react-native';
import styled from 'styled-components';

import {
  FOHButton,
  FOHDivider,
  FOHInverseButton,
  FOHLabelBold,
  FOHMessageIcon,
  FOHSpace,
  FOHSpaceSmall,
  FOHSuccessIcon,
  FOHStatus,
  FOHStatusProps,
  FOHFonts,
  FOHToolTipType,
  FOHView
} from '../../ingredients';

import { BannerView } from '../banners/FOHBanner';
import { FOHProfileImage } from '../navigation/FOHProfileImage';

const CTAButtons = (props: FOHApplicantBlockProps) => {
  return (
    <View
      style={{
        flexDirection: props.isMobile ? 'row' : 'column',
        width: props.isMobile ? '98%' : 180
      }}
    >
      <FOHView style={{ zIndex: 22 }}>
        {props.isRequestSent ? (
          <FOHInverseButton
            title={`${props.labels.requestSent}`}
            onPress={() =>
              props.onRequestInterview(props.applicantProfile.slug)
            }
            opaque={true}
            color={'#F5F5F5'}
            textColor={'#171717'}
            canSelect={true}
            selected={true}
            style={{ height: 40 }}
          />
        ) : (
          <FOHButton
            style={{ height: 40 }}
            title={props.labels.requestInterview}
            onPress={() =>
              props.onRequestInterview(props.applicantProfile.slug)
            }
            disabled={!props?.schedulerHasInterviewSchedule}
            enableHover={!props?.schedulerHasInterviewSchedule && true}
            helpText={props?.noInterviewScheduleForSchedulerText}
            toolTipType={FOHToolTipType.LEFT}
          />
        )}
      </FOHView>
      <FOHSpace />
      <FOHView style={{ zIndex: 21 }}>
        <FOHInverseButton
          title={props.labels.startChat}
          onPress={() => props.onStartChat(props.applicantProfile.slug)}
          opaque={true}
          color={'#000000'}
          textColor={'#ffffff'}
          canSelect={true}
          selected={true}
          style={{ height: 40 }}
          icon={() => <FOHMessageIcon style={{ tintColor: '#ffffff' }} />}
        />
      </FOHView>
    </View>
  );
};

const PicAndInfo = (props: any) => {
  return (
    <View
      style={{
        flexDirection: 'row',
        width: '100%',
        maxWidth: 300
      }}
    >
      <TouchableOpacity
        onPress={() => props.onPressProfile(props.applicantProfile.handle)}
      >
        <FOHProfileImage
          photo={props.applicantProfile.image}
          style={{ width: 88, height: 88 }}
        />
      </TouchableOpacity>
      <FOHSpace />
      <View style={{ maxWidth: 222 }}>
        <TouchableOpacity
          onPress={() => props.onPressProfile(props.applicantProfile.handle)}
        >
          <FOHLabelBold
            numberOfLines={2}
            style={{
              fontSize: 18,
              textDecorationLine: 'underline'
            }}
          >
            {props.applicantProfile.name}
          </FOHLabelBold>
        </TouchableOpacity>
        <Text numberOfLines={2}>{props.applicantProfile.position}</Text>
        <FOHStatus
          statusType={props.statusType}
          statusText={props.statusText}
        />
      </View>
    </View>
  );
};

const SummaryInfo = (props: any) => {
  return (
    <View
      style={{
        alignItems: 'flex-start',
        maxWidth: props.isMobile ? 300 : 500,
        paddingLeft: 40
      }}
    >
      {!props.isMobile && (
        <Text style={{ color: '#878787', fontSize: 12 }}>
          {props.labels.personalSummary}
        </Text>
      )}

      <Text numberOfLines={4} style={{ fontSize: 16, color: '#171717' }}>
        {props.applicantProfile.summary}
      </Text>
    </View>
  );
};

const RequestSentBanner = (props: any) => {
  return (
    <BannerView
      style={{
        width: '100%',
        backgroundColor: '#DFF1E7',
        borderRadius: 6,
        marginLeft: 'auto'
      }}
      testID={'RequestSentBanner'}
    >
      <Circle
        style={{ backgroundColor: 'green', borderRadius: 20, padding: 2 }}
      >
        <FOHSuccessIcon />
      </Circle>
      <FOHSpaceSmall />
      <Text
        style={{
          color: '#229557',
          fontFamily: FOHFonts.REGULAR,
          fontWeight: '600'
        }}
      >
        {props.labels.requestSentLongText}
      </Text>
    </BannerView>
  );
};

export interface FOHApplicantBlockProps extends FOHStatusProps {
  applicantProfile: {
    image: string;
    name: string;
    position: string;
    summary: string;
    slug: string;
    handle?: string;
  };
  labels: {
    personalSummary: string;
    requestInterview: string;
    startChat: string;
    requestSent: string;
    requestSentLongText: string;
  };
  onRequestInterview: (slug: string) => void;
  onStartChat: (slug: string) => void;
  onPressProfile: (handle?: string) => void;
  isMobile: boolean;
  isRequestSent: boolean;
  schedulerHasInterviewSchedule?: boolean;
  noInterviewScheduleForSchedulerText?: string;
}

export const FOHApplicantBlock = (props: FOHApplicantBlockProps) => {
  return (
    <View
      style={{ width: '100%' }}
      testID={`FOHApplicantBlock-${props.applicantProfile.name}`}
    >
      <FOHDivider />
      <View>
        <View
          style={{
            flexDirection: props.isMobile ? 'column' : 'row',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            width: '100%',
            marginTop: 30
          }}
        >
          <PicAndInfo {...props} />
          <FOHSpaceSmall />
          <SummaryInfo {...props} />
          <FOHSpace />
          <View style={{ flex: 1, alignItems: 'flex-end' }}>
            <CTAButtons {...props} />
          </View>
          <FOHSpace />
        </View>
        <FOHSpaceSmall />
        {!props.isMobile && props.isRequestSent && (
          <View style={{ flexDirection: 'row' }}>
            <RequestSentBanner {...props} /> <FOHSpace />
          </View>
        )}
      </View>
    </View>
  );
};

const Circle = styled(View)`
  border-radius: 50%;
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
  margin-top: 6px;
  background-color: #229557;
`;
