import React, { useContext } from 'react';

import { useLocalStorage } from 'use-hooks';

const EmployerOnboardingContext = React.createContext([{}, () => {}]);

export const initialEmployerOnboardingState = {
  answers: [],
  search: '',
  searchText: ''
};

export const useEmployerOnboardingContext = () => {
  const [state, setState] = useContext(EmployerOnboardingContext);

  // Location model
  const setLocation = location => {
    setState({
      ...state,
      location
    });
  };

  const setAnswers = answers => {
    setState({ ...state, answers });
  };

  const setSearch = search => {
    setState({ ...state, search });
  };

  const setSearchText = searchText => {
    setState({ ...state, searchText });
  };

  const setEmployerOnboardingState = newState => {
    setState({ ...state, ...newState });
  };

  const setInvitedBy = invitedBy => {
    setState({ ...state, invitedBy });
  };

  const resetEmployerOnboarding = () => {
    setState({ ...initialEmployerOnboardingState });
  };

  return {
    ...state,
    // actions
    setLocation,
    setAnswers,
    setSearch,
    setSearchText,
    setEmployerOnboardingState,
    setInvitedBy,
    resetEmployerOnboarding
  };
};

export const EmployerOnboardingProvider = props => {
  const [state, setState] = useLocalStorage('employer-onboarding-state', {
    ...initialEmployerOnboardingState
  });
  return (
    <EmployerOnboardingContext.Provider value={[state, setState]}>
      {props.children}
    </EmployerOnboardingContext.Provider>
  );
};
