import React from 'react';

import {
  FOHReactiveModal,
  FOHHeaderText,
  FOHDetailText,
  FOHButton,
  FOHTextButton,
  FOHSpace,
  FOHView,
  FOHImage
} from '../../ingredients';

export interface FOHAreYouSureModalProps {
  open: boolean;
  close: () => void;
  headerLabel: string;
  detailLabel?: string;
  buttonLabel: string;
  image: string;
  imageWidth: number;
  imageHeight: number;
  confirm: () => void;
  cancel: () => void;
  cancelText?: string;
  dialog?: boolean;
  leftContentShift?: number;
}

export const FOHAreYouSureModal = (props: FOHAreYouSureModalProps) => {
  return (
    <FOHReactiveModal
      leftContentShift={props.leftContentShift}
      dialog={props.dialog}
      open={props.open}
      close={props.close}
    >
      <FOHView
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          padding: props.dialog ? 20 : 14
        }}
      >
        <FOHHeaderText>{props.headerLabel}</FOHHeaderText>
        <FOHDetailText>{props.detailLabel}</FOHDetailText>
        <FOHSpace />
        <FOHView style={{ alignItems: 'center', justifyContent: 'center' }}>
          <FOHImage
            source={{ uri: props.image }}
            resizeMode={'contain'}
            style={{
              width: props.imageWidth,
              height: props.imageHeight
            }}
          />
        </FOHView>
        <FOHSpace />
        <FOHButton onPress={props.confirm} title={props.buttonLabel} />
        <FOHSpace />
        {!!props.cancelText && (
          <FOHView style={{ padding: 16 }}>
            <FOHTextButton title={props.cancelText} onPress={props.cancel} />
          </FOHView>
        )}
      </FOHView>
    </FOHReactiveModal>
  );
};
