import { useState, useEffect } from 'react';

import { path, prop, length } from 'ramda';

import { handleMutation } from '../../utils';
import {
  EmployerCandidatesDocument,
  GetCandidateDocument,
  GetCandidateNameDocument,
  MatchCountQueryDocument,
  PositionMatchQueryDocument,
  useSendInterviewMutationMutation
} from '../../graphql/generated';
import { FOHPositionBillingStatusType } from '../../components';

import { useCurrentLocationContext } from '../Locations/useCurrentLocationContext';
import { useCurrentPosition } from '../EmployerPositions/useCurrentPosition';
import { useCandidateFeedContext } from '../CandidateFeed/useCandidateFeedContext';
import { useStages } from '../CandidateFeed/useStages';
import { useGetMeData } from '../SignIn';

export const useRequestInterview = (
  props,
  initialPosition,
  candidate,
  unlimitedPositions
) => {
  const [sendInterviewMutation] = useSendInterviewMutationMutation();
  const { data: me } = useGetMeData();

  const { locationFilter } = useCurrentLocationContext({ props });

  const { locationFilters, positionFilters, activeStageId } =
    useCandidateFeedContext();

  const { interviewStage } = useStages({});

  const { positionId, positions } = useCurrentPosition(props, locationFilter);

  const myPositions = unlimitedPositions
    ? positions
    : positions.filter(
        position =>
          prop('paymentStatus', position) ===
          FOHPositionBillingStatusType.SUBSCRIBED
      );

  const createdBy = prop('id', me);

  // Do not change the global position only the position in the modal
  const [position, selectPosition] = useState(positionId);
  const [sendingIR, setSendingIR] = useState(false);

  const queryForInboxFeed =
    // using the inbox feed they will have location filters and we should refetch these views
    length(positionFilters) > 0 &&
    length(locationFilters) > 0 &&
    prop('id', interviewStage)
      ? [
          {
            query: EmployerCandidatesDocument,
            variables: {
              first: 20,
              after: '',
              stageId: prop('id', interviewStage),
              positions: positionFilters,
              locations: locationFilters,
              search: '',
              notificationType: '',
              notificationStatuses: []
            }
          },
          {
            query: EmployerCandidatesDocument,
            variables: {
              first: 20,
              after: '',
              stageId: activeStageId,
              positions: positionFilters,
              locations: locationFilters,
              search: '',
              notificationType: '',
              notificationStatuses: []
            }
          },
          {
            query: EmployerCandidatesDocument,
            variables: {
              first: 20,
              after: '',
              stageId: prop('id', interviewStage),
              positions: [position],
              locations: [locationFilter],
              search: '',
              notificationType: '',
              notificationStatuses: []
            }
          }
        ]
      : [];

  const sendInterviewRequest = async (
    { search, onComplete, incrementCount } = {
      search: '',
      onComplete: null,
      incrementCount: false
    }
  ) => {
    setSendingIR(true);
    const interviewAttendees = [
      {
        userId: createdBy,
        role: 'interviewer'
      },
      {
        userId: path(['user', 'id'], candidate),
        role: 'candidate'
      }
    ];
    const variables = {
      candidate: path(['user', 'id'], candidate),
      createdBy,
      employerProfile: path(['employerProfile', 'id'], me),
      position,
      interviewAttendees: interviewAttendees
    };

    const queryForSearch = search
      ? [
          {
            query: PositionMatchQueryDocument,
            variables: {
              first: 20,
              interviewStatus: 'PENDING',
              page: 1,
              position,
              search,
              sort: 'best',
              applied: false
            }
          }
        ]
      : [];

    await handleMutation(
      sendInterviewMutation({
        variables,
        refetchQueries: [
          ...queryForSearch,
          ...queryForInboxFeed,
          {
            query: PositionMatchQueryDocument,
            variables: {
              first: 20,
              interviewStatus: 'PENDING',
              page: 1,
              position,
              search: '',
              sort: 'best',
              applied: false
            }
          },
          {
            query: GetCandidateNameDocument,
            variables: { handle: path(['handle'], candidate) }
          }
        ],

        update: (store, { data }) => {
          const lastInterview = {
            id: path(['mutateInterview', 'interview', 'id'], data),
            status: path(['mutateInterview', 'interview', 'status'], data),
            uuid: path(['mutateInterview', 'interview', 'uuid'], data),
            __typename: 'InterviewTypeEdge'
          };

          try {
            // GET_CANDIDATE_QUERY
            // Update candidate profile query w/ handle
            const handle = path(
              [
                'mutateInterview',
                'interview',
                'candidate',
                'candidateProfile',
                'handle'
              ],
              data
            );

            const candidateResp = store.readQuery({
              query: GetCandidateDocument,
              variables: { handle }
            });

            const requestedCandidate = {
              ...candidateResp,
              profile: {
                ...path(['profile'], candidateResp),
                user: {
                  ...path(['profile', 'user'], candidateResp)
                },
                lastInterview: {
                  ...path(['profile', 'lastInterview'], candidateResp),
                  ...lastInterview
                }
              }
            };

            // write query if we can
            store.writeQuery({
              query: GetCandidateDocument,
              variables: { handle },
              data: requestedCandidate
            });
          } catch {
            return;
          }

          if (incrementCount) {
            // Read the data from our cache for this query if it exists
            try {
              const counts = store.readQuery({
                query: MatchCountQueryDocument,
                variables: { position, search: '' }
              });

              const updatedCounts = {
                ...counts,
                matchFilterCounts: {
                  ...path(['matchFilterCounts'], counts),
                  pendingCount:
                    path(['matchFilterCounts', 'pendingCount'], counts) + 1
                }
              };

              // Write our data back to the cache.
              store.writeQuery({
                query: MatchCountQueryDocument,
                variables: { position, search: '' },
                data: updatedCounts
              });

              if (search) {
                const countsWithSearch = store.readQuery({
                  query: MatchCountQueryDocument,
                  variables: { position, search }
                });

                const updatedCountsWithSearch = {
                  ...countsWithSearch,
                  matchFilterCounts: {
                    ...path(['matchFilterCounts'], countsWithSearch),
                    pendingCount:
                      path(
                        ['matchFilterCounts', 'pendingCount'],
                        countsWithSearch
                      ) + 1
                  }
                };
                // Write our data back to the cache.
                store.writeQuery({
                  query: MatchCountQueryDocument,
                  variables: { position, search },
                  data: updatedCountsWithSearch
                });
              }
            } catch {
              return;
            }
          }

          onComplete &&
            onComplete(store, {
              data,
              lastInterview
            });
        }
      })
    );
    setSendingIR(false);
  };

  // set initial position
  useEffect(() => {
    if (positionId && !position) {
      selectPosition(positionId);
    } else if (!prop('id', initialPosition) && !position) {
      selectPosition(path([0, 'id'], myPositions));
    } else if (!position) {
      selectPosition(prop('id', initialPosition));
    }
  }, [myPositions, initialPosition, positionId, position]);

  useEffect(() => {
    selectPosition(positionId);
  }, [positionId]);

  return {
    sendingIR,
    sendInterviewRequest,
    position,
    myPositions,
    selectPosition
  };
};
