import React, { Fragment } from 'react';

import { useTranslation } from 'react-i18next';

import {
  FOHImage,
  FOHLabel,
  FOHView,
  FOHSpace,
  FOHInverseButton,
  FOHColors,
  FOHHeaderH3,
  FOHHeaderH2
} from '../../components';
import Feature1 from '../../images/network-feature-1.svg';
import Feature2 from '../../images/network-feature-2.svg';
import { NETWORK } from '../../constants';
import { useNavigation } from '../../utils/navigation';

const getFeatures = t => [
  {
    icon: Feature1,

    desc: t('useFilters')
  },
  {
    icon: Feature2,
    desc: t('createOpportunities')
  }
];

export const NeedMoreCandidateCard = () => {
  const { navigateTo } = useNavigation();

  const { t } = useTranslation('CandidateNetworkCardInFeed');

  return (
    <FOHView style={{ padding: 16 }} className="candidate-card">
      <FOHView className="candidate-card__header">
        <FOHView className="candidate-card__header__title">
          <FOHHeaderH3
            style={{
              color: '#17ABCC',
              textAlign: 'left',
              fontWeight: 600
            }}
          >
            {t('needCandidates')}
          </FOHHeaderH3>
        </FOHView>
        <FOHSpace />
        <FOHView className="candidate-card__header__subtitle">
          <FOHHeaderH2 style={{ textAlign: 'left' }}>
            {t('exploreCandidates')}
          </FOHHeaderH2>
        </FOHView>
      </FOHView>

      <FOHSpace />
      <FOHView style={{ maxWidth: '100%' }}>
        {getFeatures(t).map((feature, index) => (
          <Fragment key={index}>
            <FOHView
              style={{ flexDirection: 'row', alignItems: 'center' }}
              key={index}
              className="candidate-card__feature"
            >
              <FOHView className="candidate-card__feature__icon">
                <FOHImage
                  source={feature.icon}
                  style={{ width: 20, height: 20 }}
                />
              </FOHView>
              <FOHSpace />
              <FOHView
                className="candidate-card__feature__desc"
                style={{ wordWrap: 'break-word', flex: 1 }}
              >
                <FOHLabel style={{ textAlign: 'left' }}>
                  {feature.desc}
                </FOHLabel>
              </FOHView>
            </FOHView>
            <FOHSpace />
          </Fragment>
        ))}
      </FOHView>
      <FOHInverseButton
        disabled={false}
        textColor={FOHColors.BLACK}
        opaque={true}
        selected={true}
        onPress={async () => {
          navigateTo(NETWORK);
        }}
        title={t('goToNetworkLabel')}
        style={{
          height: 50
        }}
      />
    </FOHView>
  );
};
