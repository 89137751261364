import { useEffect } from 'react';

import { useGoogleLogin } from '@react-oauth/google';

import { GOOGLE_OAUTH_CALLBACK, getAppBaseUrl } from '../../constants';
import {
  getSessionStorageItem,
  removeSessionStorageItem,
  setSessionStorageItem
} from '../../utils';

import { generateState } from '../Campaigns/useOAuth2';

export const GOOGLE_OAUTH_STATE = 'react-oauth-google-state';
export const AUTH_DETAIL_STATE = 'auth_detail';

const useGoogleSSOAuth = () => {
  const oauthState = getSessionStorageItem(GOOGLE_OAUTH_STATE) || undefined;

  const clearOauthState = () => {
    removeSessionStorageItem(GOOGLE_OAUTH_STATE);
  };

  const clearSessionStates = () => {
    removeSessionStorageItem(AUTH_DETAIL_STATE);
    clearOauthState();
  };

  const initiateGoogleAuth = useGoogleLogin({
    flow: 'auth-code',
    ux_mode: 'redirect',
    redirect_uri: `${getAppBaseUrl()}${GOOGLE_OAUTH_CALLBACK}`,
    state: oauthState,
    onError: error => console.log('error', error)
  });

  useEffect(() => {
    if (oauthState === null || oauthState === undefined) {
      // Generate & save state in session storage if not exist
      const state = generateState();
      setSessionStorageItem(GOOGLE_OAUTH_STATE, state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    initiateGoogleAuth,
    clearSessionStates,
    clearOauthState
  };
};

export default useGoogleSSOAuth;
