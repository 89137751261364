import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { path, prop } from 'ramda';

import {
  unwrapEdgesAt,
  useIsMobile,
  toDateTime,
  isScrollCloseToBottom
} from '../../utils';
import {
  DISCOVER,
  HOME_PAGE,
  INTERVIEW_ADD_TO_CALENDAR_PATH,
  RESTAURANT_JOBS,
  POSITIONS_SORT_BY_OPTIONS
} from '../../constants';
import { useSearchInterviewPositionsQuery } from '../../graphql/generated';
import {
  FOHCandidateResultFilter,
  FOHColors,
  FOHFilterIcon,
  FOHInterviewListCell,
  FOHInterviewMeetingType,
  FOHInverseButton,
  FOHScrollView,
  FOHView,
  FullWidthCard
} from '../../components';
import { useNavigation } from '../../utils/navigation';
import { FOH_INPUT_HEIGHT } from '../../components/constants';

import { fetchMorePositions } from '../EmployerPositions/fetchMorePositions';
import { useCandidateProfileMutation } from '../CandidateProfile/useCandidateProfileMutation';
import { useDirectMessage } from '../Chat/useDirectMessage';

import { HomeScrollFooter } from './HomeScrollFooter';
import { PositionTypeAheadSearchBar } from './PositionTypeAheadSearchBar';
import { PositionFilterDrawer } from './PositionFilterDrawer';

const FilterIcon = () => <FOHFilterIcon active={true} />;

export const CandidateInterviews = props => {
  const { navigateTo } = useNavigation();

  const { t } = useTranslation('CandidateHomeFeature');
  const { me } = useCandidateProfileMutation();

  const { goToDirectMessageWith } = useDirectMessage({
    ...props,
    sendbirdAccessToken: path(['sendbirdAccessToken'], me)
  });

  const [openFilters, setOpenFilters] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [workingPreferences, setWorkingPreferences] = useState([]);
  const [sortBy, setSortBy] = useState('latest');

  const [loadingMore, setLoadingMore] = useState(false);

  const { isMobile } = useIsMobile();

  const benefits = workingPreferences.includes('isBenefitsRequired')
    ? {
        isBenefitsRequired: true
      }
    : {};

  const dressCode = workingPreferences.includes('canHaveDressCode')
    ? {
        canHaveDressCode: true
      }
    : {};

  const variables = {
    first: 10,
    after: '',
    hasInterview: true,
    // TODO: just accepted / scheduled?
    interviewStatus: 'ACCEPTED',
    sortBy: sortBy,
    searchTerms: selectedItems
      .map(item => prop('value', item))
      .filter(item => !!item),
    ...benefits,
    ...dressCode
  };

  const positionSearchQuery = useSearchInterviewPositionsQuery({
    variables: {
      ...variables
    }
  });

  const positions = unwrapEdgesAt(
    ['data', 'positions', 'edges'],
    positionSearchQuery
  );

  useEffect(() => {
    if (
      props.scrolledToBottom &&
      !positionSearchQuery.loading &&
      isMobile &&
      !loadingMore
    ) {
      setLoadingMore(true);
      fetchMorePositions(positionSearchQuery, variables).then(() => {
        setLoadingMore(false);
        props.setScrolledToBottom(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.scrolledToBottom]);

  return (
    <>
      <FOHView
        style={{
          flexDirection: isMobile ? 'column-reverse' : 'column'
        }}
      >
        <FullWidthCard
          style={{
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          <FOHView
            style={{
              padding: isMobile ? 10 : 16,
              flexDirection: 'row',
              flexWrap: 'wrap'
            }}
          >
            <FOHView
              style={{
                flex: 3,
                marginRight: 10,
                minWidth: 300,
                paddingBottom: isMobile ? 10 : 0,
                minHeight: 52,
                zIndex: 99
              }}
            >
              <PositionTypeAheadSearchBar
                {...props}
                tab="interviews"
                setSelectedItems={setSelectedItems}
                selectedItems={selectedItems}
                isMobile={isMobile}
              />
            </FOHView>
            <FOHView style={{ flex: 1, minWidth: 250 }}>
              <FOHInverseButton
                color={FOHColors.BLACK}
                title={'More Filters'}
                icon={FilterIcon}
                onPress={() => setOpenFilters(true)}
                centerIcon={true}
                style={{
                  width: isMobile ? '100%' : 250,
                  height: FOH_INPUT_HEIGHT
                }}
              />
            </FOHView>
          </FOHView>
        </FullWidthCard>
        <FOHCandidateResultFilter
          totalCount={
            positionSearchQuery.loading
              ? '...'
              : path(['data', 'positions', 'totalCount'], positionSearchQuery)
          }
          countHeader={t('interviewsCountHeader')}
          showBestMatch={false}
          sortByLabel={t('sortByLabel')}
          sortBy={sortBy}
          setSortBy={setSortBy}
          sortByOptions={POSITIONS_SORT_BY_OPTIONS(t)}
        />
      </FOHView>
      <FOHScrollView
        scrollEventThrottle={200}
        onScroll={async ({ nativeEvent }) => {
          if (isScrollCloseToBottom(nativeEvent) && !loadingMore && !isMobile) {
            setLoadingMore(true);
            await fetchMorePositions(positionSearchQuery, variables);
            setLoadingMore(false);
          }
        }}
        style={{
          // no nested scrolling on mobile
          height: openFilters ? '40vh' : isMobile ? '100%' : '60vh',
          zIndex: -1
        }}
      >
        <>
          {positionSearchQuery.loading ? (
            <></>
          ) : (
            positions.map(position => (
              <FOHInterviewListCell
                mobile={isMobile}
                key={path(['id'], position)}
                positionName={path(['positionName'], position)}
                positionSlug={path(['slug'], position)}
                positionId={path(['id'], position)}
                hiringManagerName={path(
                  ['myInterview', 'createdBy', 'firstName'],
                  position
                )}
                hiringManagerImage={path(
                  ['myInterview', 'createdBy', 'profileImage'],
                  position
                )}
                hiringManagerPosition={t('interviewOwner')} // Position Owner
                hiringManagerDetailText="" // It usually takes 1-2 weeks to hear back.
                // job details
                onPressJobDetails={() =>
                  navigateTo(
                    `${INTERVIEW_ADD_TO_CALENDAR_PATH}/${path(
                      ['myInterview', 'uuid'],
                      position
                    )}`
                  )
                }
                onPressMessage={async () =>
                  await goToDirectMessageWith({
                    uuids: [
                      path(['myInterview', 'createdBy', 'uuid'], position)
                    ],
                    location: path(['location', 'id'], position),
                    position: prop('id', position),
                    interview: path(['myInterview', 'id'], position)
                  })
                }
                messageLabel={t('chat')}
                scheduledLabel={t('scheduledLabel')}
                interviewTypeLabel={t('interviewTypeLabel')}
                interviewType={
                  !path(['myInterview', 'scheduledFor'], position)
                    ? ''
                    : path(['myInterview', 'interviewType'], position) ===
                      FOHInterviewMeetingType.INPERSON
                    ? t('EmployerInterviewScheduleFeature:inPersonLabel')
                    : path(['myInterview', 'interviewType'], position) ===
                      FOHInterviewMeetingType.PHONE
                    ? t('EmployerInterviewScheduleFeature:phonePlaceholder')
                    : t('EmployerInterviewScheduleFeature:remoteMeetingLabel')
                }
                interviewLocationLabel={t('locationLabel')}
                interviewLocation={
                  !path(['myInterview', 'scheduledFor'], position)
                    ? ''
                    : path(['myInterview', 'interviewType'], position) ===
                      FOHInterviewMeetingType.INPERSON
                    ? path(['location', 'formattedAddress'], position)
                    : path(['myInterview', 'interviewType'], position) ===
                      FOHInterviewMeetingType.PHONE
                    ? path(['myInterview', 'phoneNumber'], position)
                    : path(['myInterview', 'meetingUrl'], position)
                }
                scheduledFor={
                  path(['myInterview', 'scheduledFor'], position)
                    ? toDateTime(
                        new Date(
                          path(['myInterview', 'scheduledFor'], position)
                        ).toISOString()
                      )
                    : t('thisEmployerHasNoSchedule')
                }
                buttonCTALabel={t('interviewDetails')}
                onPressCTA={_slug =>
                  navigateTo(
                    `${INTERVIEW_ADD_TO_CALENDAR_PATH}/${path(
                      ['myInterview', 'uuid'],
                      position
                    )}`
                  )
                }
                buttonCTADisabled={false}
                // location
                locationId={path(['location', 'id'], position)}
                locationName={path(['location', 'name'], position)}
                locationSlug={path(['location', 'slug'], position)}
                onPressLocation={slug =>
                  isMobile
                    ? navigateTo(`${RESTAURANT_JOBS}/${slug}`)
                    : props.openLocationDetailsModal(slug)
                }
                locationImage={path(['location', 'image'], position)}
                locationHref=""
              />
            ))
          )}
        </>
        <HomeScrollFooter
          loading={positionSearchQuery.loading || loadingMore}
          hasNextPage={path(
            ['data', 'positions', 'pageInfo', 'hasNextPage'],
            positionSearchQuery
          )}
          noMorePositionsLabel={t('noMoreInterviews')}
          noMorePositionsDetailLabel={t('applyByYourOwn')}
          noMoreSubmitBtnLabel={t('browseMoreJobs')}
          onSubmitPress={() => {
            if (path(['params', 'tab'], props.match) !== 'discover') {
              navigateTo(`${HOME_PAGE}${DISCOVER}`);
            }
          }}
        />
      </FOHScrollView>
      <PositionFilterDrawer
        workingPreferences={workingPreferences}
        setWorkingPreferences={setWorkingPreferences}
        openFilters={openFilters}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        setOpenFilters={setOpenFilters}
      />
    </>
  );
};
