import React from 'react';

import {
  FOHButton,
  Row,
  FOHSpace,
  FOHLabel,
  FOHView,
  FOHFonts,
  FOHSpaceLarge,
  FOHColors,
  FOHTextButton,
  FOHPasswordField,
  FOHErrorLabel,
  FOHInverseButton,
  FOHTextField,
  FOHAlert
} from '../../ingredients';

import { FOHForm, onEnterKeyPresed } from './FOHForm';

export interface FOHSignInFormProps {
  handleSubmit: () => any;
  email: string;
  emailPlaceholder: string;
  setEmail: (value: string) => any;
  password: string;
  passwordPlaceholder: string;
  setPassword: (value: string) => any;
  submitLabel: string;
  error?: any;
  disabledSubmit?: boolean;
  handleForgotPassword?: () => any;
  forgotPasswordLabel: string;
  handleSignUp: () => any;
  noAccountLabel?: string;
  signUpLabel: string;
  showPassText?: string;
  hidePassText?: string;
}

export const FOHSignInForm = (props: FOHSignInFormProps) => (
  <FOHForm
    handleSubmit={props.handleSubmit}
    disabledSubmit={props.disabledSubmit}
  >
    {props.error && props.error.alert && (
      <FOHAlert message={props.error.alert} />
    )}
    <FOHView style={{ justifyContent: 'center', alignItems: 'center' }}>
      <Row>
        <FOHTextField
          autoCompleteType={'email'}
          placeholder={props.emailPlaceholder}
          value={props.email}
          onChangeText={(value: any) => props.setEmail(value)}
          onKeyPress={(e: any) => {
            onEnterKeyPresed(e, props);
          }}
          error={props.error && props.error.email ? props.error.email : null}
        />
      </Row>
      <Row style={{ position: 'relative' }}>
        <FOHPasswordField
          passwordPlaceholder={props.passwordPlaceholder}
          password={props.password}
          setPassword={(value: string) =>
            props.setPassword && props.setPassword(value)
          }
          error={
            props.error && props.error.password ? props.error.password : null
          }
          onKeyPress={(e: any) => {
            onEnterKeyPresed(e, props);
          }}
          showPassText={props.showPassText}
          hidePassText={props.hidePassText}
        />
        {props.handleForgotPassword && (
          <FOHView
            style={{
              position: 'absolute',
              bottom: props.showPassText || props.hidePassText ? 12 : -12,
              right: 6
            }}
          >
            <FOHTextButton
              onPress={props.handleForgotPassword}
              title={props.forgotPasswordLabel}
              style={{
                flex: 1,
                fontWeight: 500,
                fontSize: 12,
                lineHeight: 16,
                fontFamily: FOHFonts.MEDIUM
              }}
            />
          </FOHView>
        )}
      </Row>
      <FOHSpaceLarge />
      <FOHErrorLabel style={{ paddingBottom: 8 }}>
        {props.error ? props.error.detail : null}
      </FOHErrorLabel>
      <FOHButton
        onPress={props.handleSubmit}
        title={props.submitLabel}
        disabled={props.disabledSubmit}
      />
      <>
        {props.handleSignUp ? (
          <>
            <FOHView style={{ paddingTop: 24 }}></FOHView>
            <FOHView
              style={{
                height: 1,
                backgroundColor: FOHColors.GRAYSCALE_1,
                width: '80%'
              }}
            />
            <FOHView style={{ paddingTop: 24 }}></FOHView>
            <>
              {props.noAccountLabel ? (
                <>
                  <FOHLabel>{props.noAccountLabel}</FOHLabel>
                  <FOHSpace />
                </>
              ) : (
                <></>
              )}
            </>
            {props.signUpLabel && props.handleSignUp && (
              <FOHInverseButton
                selected={true}
                opaque={true}
                color={FOHColors.BLACK}
                textColor={FOHColors.WHITE}
                onPress={props.handleSignUp}
                title={props.signUpLabel}
                style={{ flex: 1 }}
              />
            )}
          </>
        ) : (
          <></>
        )}
      </>
    </FOHView>
  </FOHForm>
);
