import React from 'react';

import styled from 'styled-components/native';

import { FOHColors, FOHRestaurantIcon } from '../../../ingredients';
import { CommonProps } from '../../../typings';

export const MainImageWrapper = styled.View<CommonProps>`
  border-radius: 8px;
  overflow: hidden;
  align-items: center;
  display: flex;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: ${({ isMobile }: { isMobile: boolean }) =>
    isMobile ? '327px' : '350px'};
  height: ${({ isMobile }: { isMobile: boolean }) =>
    isMobile ? '327px' : '350px'};
  justify-content: center;
`;

export const DotPaginationWrapper = styled.View`
  align-items: center;
  flex: 1;
  flex-direction: row;
  justify-content: center;
`;

export const NavigationWrapper = styled.View`
  bottom: 12;
  flex-direction: row;
  margin-left: 12;
  margin-right: 12;
  position: absolute;
  width: calc(100% - 24px);
`;

export const Dot = styled.View<{ selected: boolean }>`
  background: ${({ selected }: { selected: boolean }) =>
    selected ? FOHColors.WHITE : FOHColors.BACKGROUND_GRAY};
  opacity: ${({ selected }: { selected: boolean }) => (selected ? 1 : 0.5)};
  border: 1px solid ${FOHColors.BACKGROUND_GRAY};
  border-radius: 50%;
  color: white;
  display: inline-block;
  height: 8;
  margin-left: 8;
  width: 8;
`;

export const Circle = styled.View`
  border-radius: 50%;
  border: 1px solid ${FOHColors.BACKGROUND_GRAY};
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 32px;
  height: 32px;
`;

export interface MainImageProps {
  setIsLightBoxOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const DefaultBackground = styled.View`
  border-radius: 8px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='grey' stroke-width='1' stroke-dasharray='9' stroke-dashoffset='35' stroke-linecap='square'/%3e%3c/svg%3e");
  height: 100%;
  width: 100%;
  align-items: center;
  background-color: ${FOHColors.BACKGROUND_GRAY};
  justify-content: center;
`;

export const DefaultImage = () => (
  <DefaultBackground>
    <FOHRestaurantIcon
      style={{
        height: 104,
        width: 104
      }}
    />
  </DefaultBackground>
);
