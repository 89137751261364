import React, { useEffect, useMemo } from 'react';

import { GoogleOAuthProvider } from '@react-oauth/google';
import * as Sentry from '@sentry/browser';

import Routes from './Routes';
import { ENVIRONMENT, envVariables } from './constants';
import { EmployerOnboardingProvider } from './features/EmployerOnboarding';
import { OnboardingProvider } from './features/Navigation/useOnboardingState';
import { CurrentLocationContextProvider } from './features/Locations/useCurrentLocationContext';
import { BannerContextProvider } from './features/Navigation/useBanner';
import { SidebarNavContextProvider } from './features/Navigation/useSidebar';
import { BreadCrumbsContextProvider } from './features/EmployerDashbaord/useBreadCrumbsContext';
import { ChatWindowProvider } from './features/Chat/useChatWindow';
import { CandidateFeedContextProvider } from './features/CandidateFeed/useCandidateFeedContext';
import { CandidateNetworkSearchContextProvider } from './features/CandidateNetworkSearch/useCandidateNetworkSearchHook';
import { CandidateFeedFiltersContextProvider } from './features/CandidateFeed/useCandidateFeedFiltersContext';
import { AuthFlowSelectionContextProvider } from './features/GoogleSSOAuth/components/useAuthFlowSelectionContext';
import { LogoutRedirectContextProvider } from './features/SignIn/useLogoutRedirectContext';
import { useTrackAmplitudeEvent } from './utils';

if (['production', 'stage'].includes(ENVIRONMENT)) {
  Sentry.init({
    dsn: envVariables.SENTRY_DSN,
    environment: ENVIRONMENT,
    release: envVariables.GITHUB_SHA,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 0.25,
    ignoreErrors: [
      'Command received no ack.',
      // another sometimes noisy sendbird error
      'WebSocket connection must be made first.',
      'Loading chunk',
      '@webkit-masked-url:(//hidden/)',
      'Non-Error promise rejection captured',
      'UnknownError: Database deleted by request of the user'
    ],
    replaysSessionSampleRate: ENVIRONMENT == 'stage' ? 5.0 : 0.1,
    replaysOnErrorSampleRate: 1.0
  });
}

export const App = _props => {
  const { initializeAmplitude } = useTrackAmplitudeEvent();

  useEffect(() => {
    if (
      document &&
      document.body &&
      document.body.style &&
      document.body.style.zoom
    ) {
      // in case anyone is extra zommed in on the site
      document.body.style.zoom = '100%';
    }

    initializeAmplitude();
  }, []);

  // Providers
  return (
    <LogoutRedirectContextProvider>
      <GoogleOAuthProvider clientId={envVariables.GOOGLE_SSO_CLIENT_ID}>
        <AuthFlowSelectionContextProvider>
          <OnboardingProvider>
            <EmployerOnboardingProvider>
              <CurrentLocationContextProvider>
                <BannerContextProvider>
                  <SidebarNavContextProvider>
                    <BreadCrumbsContextProvider>
                      <ChatWindowProvider>
                        <CandidateNetworkSearchContextProvider>
                          <CandidateFeedFiltersContextProvider>
                            <CandidateFeedContextProvider>
                              <Routes />
                            </CandidateFeedContextProvider>
                          </CandidateFeedFiltersContextProvider>
                        </CandidateNetworkSearchContextProvider>
                      </ChatWindowProvider>
                    </BreadCrumbsContextProvider>
                  </SidebarNavContextProvider>
                </BannerContextProvider>
              </CurrentLocationContextProvider>
            </EmployerOnboardingProvider>
          </OnboardingProvider>
        </AuthFlowSelectionContextProvider>
      </GoogleOAuthProvider>
    </LogoutRedirectContextProvider>
  );
};

export default App;
