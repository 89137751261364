import React, { useState } from 'react';

import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { path, defaultTo, prop } from 'ramda';

import {
  FOHScrollView,
  FOHView,
  FOHSettingsBanner,
  FOHAddButton,
  FOHRejectionCell,
  FOHRejectionEditCell
} from '../../components';
import {
  GetEmployerRejectionReasonDocument,
  useEmployerRejectionReasonMutation,
  useGetEmployerRejectionReasonQuery,
  useMutateEmployerRejectionReasonMutation
} from '../../graphql/generated';
import { handleMutation, useIsMobile } from '../../utils';
import { useNavigation } from '../../utils/navigation';

import { useSidebarNav } from '../Navigation/useSidebar';
import { useGetMeData } from '../SignIn';

export const EmployerRejectionReasons = props => {
  const { goBack } = useNavigation();
  const { isMobile } = useIsMobile();
  const { t } = useTranslation('RejectReasonFeature');

  const [selectedLabel, setSelectedLabel] = useState('');
  const [text, setText] = useState('');
  const [isAddNew, setAddNew] = useState(false);

  const { data: me } = useGetMeData();
  const employerProfileId = path(['employerProfile', 'id'], me);
  const rejectionReasonQuery = useGetEmployerRejectionReasonQuery({});
  const [mutateRejectionReason] = useMutateEmployerRejectionReasonMutation();

  const [mutateEmployerRejectionReason] = useEmployerRejectionReasonMutation();

  const rejectionReasons = defaultTo(
    [],
    path(['data', 'employerRejectionReasons', 'edges'], rejectionReasonQuery)
  ).map(node => ({ ...prop('node', node) }));

  const onSavePress = async () => {
    await handleMutation(
      mutateRejectionReason({
        variables: {
          id: selectedLabel,
          name: text
        },
        refetchQueries: [
          {
            query: GetEmployerRejectionReasonDocument
          }
        ]
      })
    );
    setSelectedLabel('');
    setText('');
  };

  const deleteLabel = async (selectedId, labelName) => {
    await handleMutation(
      mutateRejectionReason({
        variables: {
          id: selectedId,
          name: labelName,
          archivedAt: DateTime.local().toISO()
        },
        refetchQueries: [
          {
            query: GetEmployerRejectionReasonDocument
          }
        ]
      })
    );
    setSelectedLabel('');
    setText('');
  };

  const { hasSideNav } = useSidebarNav(props);

  return (
    <FOHView>
      <FOHView
        style={{
          paddingTop: hasSideNav ? 0 : 60,
          alignItems: isMobile ? 'center' : 'flex-start'
        }}
      >
        <FOHView
          style={{
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'row'
          }}
        >
          <FOHSettingsBanner
            title={t('bannerTitle')}
            subTitle={t('subTitle')}
            goBack={() => {
              goBack();
            }}
            style={{ paddingBottom: 20 }}
          />
          <FOHAddButton
            title={t('addNewLabel')}
            style={{ position: 'absolute', right: 57, top: 31 }}
            iconSize={14}
            onPress={() => {
              setAddNew(true);
              setText('');
              setSelectedLabel('');
            }}
          />
        </FOHView>
        <FOHScrollView
          style={{
            height: isMobile ? '72vh' : '80vh',
            width: isMobile ? undefined : '100%',
            maxWidth: isMobile ? undefined : 900
          }}
        >
          <FOHView
            style={{
              padding: 48,
              paddingBottom: 100,
              paddingTop: 0
            }}
          >
            {!!isAddNew && (
              <FOHRejectionEditCell
                onChangeLabel={_text => setText(_text)}
                labelValue={text}
                labelPlaceholder={t('newPlaceholder')}
                label=""
                saveButtonLabel={t('common:save')}
                onSavePress={async () => {
                  if (text && employerProfileId) {
                    await handleMutation(
                      mutateEmployerRejectionReason({
                        variables: {
                          employerProfile: employerProfileId,
                          name: text
                        },
                        refetchQueries: [
                          {
                            query: GetEmployerRejectionReasonDocument,
                            variables: {}
                          }
                        ]
                      })
                    );
                    setText('');
                    setAddNew(false);
                    setSelectedLabel('');
                  }
                }}
                cancelButtonLabel={t('common:cancel')}
                onCancelPress={() => {
                  setSelectedLabel('');
                  setText('');
                  setAddNew(false);
                }}
              />
            )}
            {rejectionReasons.map(reason => {
              const currentId = prop('id', reason);
              const labelName = prop('name', reason);
              return (
                <>
                  {currentId !== selectedLabel && (
                    <FOHRejectionCell
                      label={labelName}
                      total_candidates={path(
                        ['employercandidateSet', 'totalCount'],
                        reason
                      )}
                      delete={() => {
                        deleteLabel(currentId, labelName);
                      }}
                      edit={() => {
                        setSelectedLabel(currentId);
                        setAddNew(false);
                        setText(labelName);
                      }}
                    />
                  )}
                  {currentId === selectedLabel && (
                    <FOHRejectionEditCell
                      onChangeLabel={_text => setText(_text)}
                      labelValue={text}
                      labelPlaceholder={t('newPlaceholder')}
                      label={labelName}
                      saveButtonLabel={t('common:save')}
                      onSavePress={() => {
                        onSavePress();
                      }}
                      cancelButtonLabel={t('common:cancel')}
                      onCancelPress={() => {
                        setSelectedLabel('');
                      }}
                    />
                  )}
                </>
              );
            })}
          </FOHView>
        </FOHScrollView>
      </FOHView>
    </FOHView>
  );
};
