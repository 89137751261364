import React from 'react';

import { defaultTo, prop } from 'ramda';

import google_icon from '../../../images/google_logo.svg';

import { FOHImage } from '../../ingredients';

export const FOHGoogleLogoIcon = (props: any) => (
  <FOHImage
    source={{ uri: google_icon }}
    style={{ width: 40, height: 40, ...defaultTo({}, prop('style', props)) }}
  />
);
