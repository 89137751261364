import React from 'react';

import styled from 'styled-components/native';

import { FOHLabel } from '../../ingredients';

interface FOHRejectionTagProps {
  label: string | number;
  style?: any;
}

export const FOHRejectionTag = (props: FOHRejectionTagProps) => (
  <RejectedText style={{ ...props.style }} numberOfLines={1}>
    {props.label}
  </RejectedText>
);

const RejectedText = styled(FOHLabel)`
  color: #e5253c;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  border-radius: 100px;
  border: 1.5px solid #e5253c;
  height: 24px;
  justify-content: center;
  background-color: #ffffff;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 2px;
  padding-bottom: 2px;
  word-break: break-word;
  margin-top: 4px;
  margin-right: 2px;
`;
