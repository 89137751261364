import React from 'react';

import { Text } from 'react-native';
import styled from 'styled-components/native';

import {
  FOHFonts,
  FOHCheckbox,
  withError,
  FOHView,
  FOHTouchableOpacity
} from '../../ingredients';

import { CheckboxLabel } from './FOHLabeledCheckbox';

export interface FOHTermsProps {
  terms: boolean;
  termsText: string;
  onPressTermsLink: () => void;
  termsLinkText: string;
  setTerms: () => void;
  style?: any;
}

export const Terms = (props: FOHTermsProps) => (
  <FOHView
    style={{
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      ...props.style
    }}
  >
    <FOHTouchableOpacity onPress={() => props.setTerms()} testID={'terms'}>
      <FOHCheckbox selected={props.terms} />
    </FOHTouchableOpacity>
    <FOHView style={{ paddingLeft: 5 }} />
    <CheckboxLabel>{`${props.termsText}`} </CheckboxLabel>
    <FOHTouchableOpacity onPress={props.onPressTermsLink}>
      <TermsLinkText>{props.termsLinkText}</TermsLinkText>
    </FOHTouchableOpacity>
  </FOHView>
);

export const FOHTerms = withError(Terms);

const TermsLinkText = styled(Text)`
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 200%;
  color: #51c1db;
  text-decoration: underline;
  text-decoration-color: #51c1db;
`;
