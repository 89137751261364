import React from 'react';

import feedIcon from '../../../images/feed.svg';

import { FOHImage } from '../../ingredients';

export const FOHFeedIcon = ({ style = {} }: { style?: any }) => (
  <FOHImage
    source={{ uri: feedIcon }}
    style={{ width: 24, height: 24, ...style }}
    resizeMode={'contain'}
  />
);
