import React from 'react';

import { TouchableWithoutFeedback, Dimensions } from 'react-native';
import styled from 'styled-components/native';
import { animated, SpringConfig, useSprings } from 'react-spring';
import { defaultTo, prop } from 'ramda';

import {
  FOHScrollView,
  FOHTouchableOpacity,
  FOHView,
  FOHTextButton,
  FOHGrayX
} from '../../ingredients';

export interface FOHModalProps {
  style?: any;
  innerStyle?: any;
  doneText?: string;
  close?: () => any;
  children?: any;
  open?: boolean;
  heightOpen?: number;
  leftContentShift?: number;
  testID?: string;
  containerStyle?: any;
}

export const FOHModal = (props: FOHModalProps) => {
  const height = prop('height', Dimensions.get('window'));

  const items = [
    {
      height: props.open ? props.heightOpen || height - 150 : 0
    }
  ];

  const config: SpringConfig = { tension: 400, friction: 30 };

  const springs = useSprings(
    items.length,
    items.map(item => ({
      height: item.height,
      config
    }))
  );

  const z = 9999;

  return (
    <>
      {props.open && (
        <TouchableWithoutFeedback onPress={props.close}>
          <GrayBlur style={{ zIndex: z - 1 }} />
        </TouchableWithoutFeedback>
      )}
      <AnimatedMobileView
        style={{
          ...props.style,
          height: springs?.[0]?.height,
          maxHeight: '90vh',
          zIndex: z
        }}
      >
        {props.open && (
          <>
            <FOHTouchableOpacity
              onPress={props.close}
              testID={'close-modal'}
              style={{
                position: 'absolute',
                top: 16,
                right: 20,
                zIndex: z
              }}
            >
              <FOHGrayX />
            </FOHTouchableOpacity>
            <FOHScrollView>
              <FOHView
                style={{
                  paddingLeft: 24,
                  paddingRight: 24,
                  ...defaultTo({}, prop('innerStyle', props))
                }}
              >
                <FOHView
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingTop: 18,
                    zIndex: z
                  }}
                />
                {props.children}

                {props.close && props.doneText ? (
                  <>
                    <FOHView
                      style={{
                        paddingTop: 36
                      }}
                    />
                    <FOHView
                      style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingBottom: 12,
                        bottom: 10,
                        width: '100%'
                      }}
                    >
                      <FOHTextButton
                        onPress={() => props.close && props.close()}
                        title={props.doneText}
                      />
                    </FOHView>
                  </>
                ) : (
                  <></>
                )}
              </FOHView>
            </FOHScrollView>
          </>
        )}
      </AnimatedMobileView>
    </>
  );
};

export const GrayBlur = styled(FOHView)`
  background-color: #000000;
  width: 100vw;
  height: 100vh;
  opacity: 0.64;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
`;

const ModalView = styled(FOHView)`
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 200;
  width: 100%;
  background-color: #FFFFFF;
  border-color: #FFFFFF;
  border-top-width: 3px
  border-top-left-radius: 6px; 
  border-top-right-radius: 6px;
`;

const AnimatedMobileView = animated(ModalView);
