import React from 'react';

import { StyleProp, ViewStyle } from 'react-native';
import { prop } from 'ramda';

import { useIsMobile } from '../../utils';

import {
  FOHColors,
  FOHDetailText,
  FOHFonts,
  FOHHighlightLinkWithText,
  FOHInverseButton,
  FOHSpace,
  FOHSpaceSmall,
  FOHView
} from '../ingredients';

const BUTTON_HEIGHT = 52;

interface FOHAuthControlButtonsProps {
  icon: () => React.ReactElement;
  title: string;

  buttons: {
    label: string;
    onClick: () => void;
    styles?: React.CSSProperties;
    icon?: () => React.ReactElement;
    opaque?: boolean;
    selected?: boolean;
    color?: string;
    textColor?: string;
  }[];

  termsText?: string;
  termsLinkText?: string;
  termsLinkToNavigate?: string;
}

const FOHAuthControlButtons = (props: FOHAuthControlButtonsProps) => {
  const isMobile = useIsMobile();

  return (
    <FOHView
      style={{
        maxWidth: 432,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: isMobile ? 15 : 0
      }}
    >
      <FOHView
        style={{
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {props.icon ? props.icon() : <></>}
        <FOHSpaceSmall />
        <FOHDetailText>{props.title}</FOHDetailText>
      </FOHView>
      <FOHView
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          paddingBottom: 22
        }}
      >
        {props.buttons.map((button, index) => {
          return (
            <React.Fragment key={index}>
              <FOHSpace />
              <FOHInverseButton
                style={
                  {
                    //@eslint
                    flex: 1,
                    maxWidth: 358,
                    height: BUTTON_HEIGHT,
                    ...prop('styles', button)
                  } as StyleProp<ViewStyle>
                }
                title={button.label}
                onPress={button.onClick}
                {...button}
              />
            </React.Fragment>
          );
        })}
      </FOHView>
      {props.termsText && props.termsLinkText && props.termsLinkToNavigate ? (
        <FOHView
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%'
          }}
        >
          <FOHHighlightLinkWithText
            labelStyle={{
              fontSize: 14,
              fontFamily: FOHFonts.REGULAR,
              lineHeight: '200%'
            }}
            linkStyle={{
              fontSize: 14,
              fontFamily: FOHFonts.REGULAR,
              lineHeight: '200%'
            }}
            linkWord={props.termsLinkText}
            highlightColor={FOHColors.PACIFIC_BLUE}
            linkHref={props.termsLinkToNavigate}
            noIcon={true}
          >
            {props.termsText}
          </FOHHighlightLinkWithText>
        </FOHView>
      ) : (
        <></>
      )}
    </FOHView>
  );
};

export default FOHAuthControlButtons;
