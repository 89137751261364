import React, { useMemo, useState, useEffect } from 'react';

import { prop, length, defaultTo, path } from 'ramda';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { createEditor, Node } from 'slate';
import { withReact } from 'slate-react';

import { useIsMobile, generateTimeIntervals } from '../../utils';
import {
  useEmployerMessageTemplatesQuery,
  useGetCandidateQuery
} from '../../graphql/generated';
import {
  getPlaceHolderByAction,
  getTemplatePlaceHolders,
  ONBOARDING_LETTER
} from '../../constants/templates';
import { FEED, TEMPLATES, UPGRADE } from '../../constants';
import {
  FOHView,
  FOHErrorLabel,
  FOHCalendarPicker,
  FOHWelcomeLetterForm,
  FOHHiredLetterIcon,
  FOHColors,
  FOHSpaceSmall
} from '../../components';
import { useNavigation } from '../../utils/navigation';

import {
  withCharacterLimit,
  withCustomDelete,
  stripBracesFromPlaceholder
} from '../EmployerMessageTemplate/utils';
import ActionModal from '../CandidateFeed/ActionModal';
import { useSelectPositionForSelectedLocationState } from '../EmployerPositions/useSelectPositionForSelectedLocationState';
import { FOHMessageInputSection } from '../EmployerMessageTemplate/FOHMessageInputSection';

import { WebDropZone } from './webDropZone';

const times = generateTimeIntervals({ numberOfIntervals: 28 });

const MAX_COUNT = 500;

const editor = withCharacterLimit(MAX_COUNT)(
  withReact(withCustomDelete(createEditor()))
);

const setTimeOnDate = (date, timeString) => {
  const [hours] = timeString.split(':');
  const minutes = timeString.split(':')[1].split(' ')[0];
  const isAm = timeString.split(' ')[1] === 'AM';
  const newDateTime = DateTime.fromObject({
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
    hour: isAm ? parseInt(hours) : parseInt(hours) + 12,
    minute: parseInt(minutes)
  });
  return newDateTime.toISO();
};

export const WelcomeLetterFormModal = props => {
  const { isInSettings, setFiles, files } = props;
  const { t } = useTranslation('Welcome');
  const { isMobile } = useIsMobile();

  const { candidateHandle, error, employerCandidate } = props;
  const employerCandidatePreferredMatch = path(
    ['preferredMatch'],
    employerCandidate
  );

  const [inputMessageContent, setInputMessageContent] = useState([
    {
      type: 'paragraph',
      children: [{ text: '' }]
    }
  ]);
  const [label, setLabel] = useState('');
  const [template, setTemplate] = useState();
  const [open, setOpen] = useState(false);
  const [templateSearch, setSearch] = useState('');

  const messageTemplatesQuery = useEmployerMessageTemplatesQuery({
    variables: {
      templateSearch,
      category: 'HIRED'
    }
  });

  const filteredTemplates = useMemo(() => {
    const templates = defaultTo(
      [],
      path(['data', 'employerMessageTemplates', 'edges'], messageTemplatesQuery)
    );

    return templates
      .map(edge => ({
        id: edge?.node.id,
        name: edge?.node.name,
        messageContent: edge.node.messageContent,
        templateType: edge.node.templateType,
        isDefault: edge.node.isDefault,
        category: edge.node.category,
        action: edge.node.action,
        defaultCompensation: edge.node.defaultCompensation,
        defaultCompensationUnits: edge.node.defaultCompensationUnits,
        employerMessageTemplateFile:
          edge.node.employerMessageTemplateFile?.edges?.map(fileEdge => {
            return {
              name: fileEdge.node.name,
              id: fileEdge.node.id,
              type: fileEdge.node.fileType,
              deleteFile: false
            };
          })
      }))
      .filter(_template =>
        _template?.name?.toLowerCase()?.includes(templateSearch.toLowerCase())
      );
  }, [messageTemplatesQuery, templateSearch]);

  useEffect(() => {
    if (length(filteredTemplates) > 0 && !template) {
      const defaultTemplate = filteredTemplates.find(
        _template => !!_template.isDefault
      );
      setTemplate(defaultTemplate);
      handleOnUseTemplate(defaultTemplate);
    }
  }, [filteredTemplates, template]);

  const { selectedPosition, paidPositions, setSelectedPosition } =
    useSelectPositionForSelectedLocationState({
      doNotDefault: true,
      positionId: path(['position', 'id'], employerCandidatePreferredMatch),
      locationId: path(
        ['position', 'location', 'id'],
        employerCandidatePreferredMatch
      )
    });

  const [time, setTime] = useState('12:00 AM');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [date, setDate] = useState(new Date());
  const [placeHolderError, setPlaceHolderError] = useState('');
  const [isCalendarOpen, setCalendarOpen] = useState(false);

  const candidateQuery = useGetCandidateQuery({
    skip: !candidateHandle,
    variables: { handle: candidateHandle }
  });

  const candidateId = path(['data', 'profile', 'id'], candidateQuery);

  const { navigateTo, goBack } = useNavigation();

  const handleOnUseTemplate = (selectedTemplate = null) => {
    const temp = selectedTemplate ?? template;

    const newValue = [
      {
        type: 'paragraph',
        children: [{ text: temp?.messageContent || '' }]
      }
    ];

    setInputMessageContent(newValue);
    editor.children = newValue;
    setLabel(temp?.name);
    setFiles(defaultTo([], prop('employerMessageTemplateFile', temp)));
    setOpen(false);
  };

  const resetForm = () => {
    setTemplate(undefined);
    setLabel('');
    setFiles([]);
    setOpen(false);
    setPlaceHolderError('');
  };

  const onSendPress = () => {
    const messageContent = inputMessageContent
      .map(n => Node.string(n))
      .join('\n');

    const placeholder = getPlaceHolderByAction(ONBOARDING_LETTER);

    if (!messageContent.includes(placeholder)) {
      const errorPlaceholder = stripBracesFromPlaceholder(placeholder);

      setPlaceHolderError(
        t('MessageTemplatesFeature:requiredPlaceHolder', {
          placeholder: errorPlaceholder
        })
      );
      return;
    }

    setPlaceHolderError('');
    props.onSendWelcomeLetter({
      documentType: 'hired',
      status: 'sent',
      position: selectedPosition,
      candidate: candidateId,
      startingAt: setTimeOnDate(date, time),
      contactName: name,
      contactPhone: phone,
      messageContent: messageContent
    });
  };

  return (
    <ActionModal
      {...props}
      title={t('welcomeLetterTitle')}
      subtitle={t('welcomeLetterDetail')}
      HeaderImage={FOHHiredLetterIcon}
      defaultLocation={path(
        ['position', 'location', 'id'],
        employerCandidatePreferredMatch
      )}
      defaultPosition={path(
        ['position', 'id'],
        employerCandidatePreferredMatch
      )}
      showLabel={t('common:show')}
      onPressUpgrade={() => navigateTo(`${FEED}${UPGRADE}`)}
      searchPlaceholder={t('common:search')}
      positionFieldLabel={t('common:position')}
      upgradeLabel={t('TopNavFeature:upgrade')}
      backButtonText={t('common:previous')}
      nextButtonText={t('common:next')}
      onBackPress={() => {
        goBack();
        resetForm();
      }}
      isNextDisabled={length(paidPositions) === 0}
      onNextPress={onSendPress}
      containerStyle={{
        alignItems: 'flex-start',
        backgroundColor: isInSettings
          ? '#f5f5f5'
          : isMobile
          ? '#ffffff'
          : undefined,
        width: '100%'
      }}
      setPosition={setSelectedPosition}
      childrenContainerStyle={{
        zIndex: isCalendarOpen ? 4 : 1
      }}
    >
      <FOHWelcomeLetterForm
        isMobile={isMobile}
        candidateSectionLabel={t('candidateSectionLabel')}
        time={time}
        setTime={setTime}
        timePlaceholder={t('OffersFeature:startTimeLabel')}
        timeChoices={times}
        contactSectionLabel={t('contactSectionLabel')}
        name={name}
        setName={setName}
        namePlaceholder={t('contactNameLabel')}
        phone={phone}
        setPhone={setPhone}
        phonePlaceholder={t('ProfileFeature:phone')}
        hideLocationSelection={true}
        hideHeaderBlock={true}
        calendarCmp={
          <FOHCalendarPicker
            open={isCalendarOpen}
            setOpen={setCalendarOpen}
            value={date}
            setValue={setDate}
            placeholder="Date"
            width="100%"
            btnBackground={FOHColors.WHITE}
          />
        }
        documentSectionLabel={t('OffersFeature:documentSectionLabel')}
        documentsUpload={<WebDropZone files={files} setFiles={setFiles} />}
      >
        <FOHMessageInputSection
          testID={'message-templates-section'}
          t={t}
          open={open}
          setOpen={setOpen}
          label={label}
          editor={editor}
          template={template}
          setTemplate={setTemplate}
          onEditTemplate={() => {
            resetForm();
            navigateTo(TEMPLATES);
          }}
          search={templateSearch}
          setSearch={setSearch}
          filteredTemplates={filteredTemplates}
          resetForm={resetForm}
          handleOnUseTemplate={handleOnUseTemplate}
          placeholder={getTemplatePlaceHolders(t, ONBOARDING_LETTER)}
          placeHolderError={placeHolderError}
          inputMessageContent={inputMessageContent}
          setInputMessageContent={setInputMessageContent}
        />
      </FOHWelcomeLetterForm>
      {length(error) > 0 ? (
        <FOHView
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            paddingLeft: 40
          }}
        >
          <>
            <FOHSpaceSmall />
            <FOHErrorLabel>
              {`• ${error.map(e => prop('messages', e)).join('\n\n• ')}`}
            </FOHErrorLabel>
          </>
        </FOHView>
      ) : null}
    </ActionModal>
  );
};
