import { path } from 'ramda';

import { unwrapEdges, toFullDate } from '../../utils';
import mockIcon from '../../images/healthCare.svg';
import { FileFields } from '../../components';

import { dataURLtoBlob } from './utils';

const MAIN_IMAGE_CATEGORY = 'career_page_main';
export const FOHOther = 'other';
const FOHYes = 'yes';
const edgesPath = ['careerPageBySlug', 'edges', 0, 'node'];

export const adaptPageData = rawData => {
  const parsed = path(edgesPath, rawData);
  const { galleries, ...pageData } = parsed;

  return pageData;
};

export const adaptAnswersDisplayData = rawData => {
  const parsedData = path([...edgesPath, 'answers', 'edges'], rawData);

  if (!parsedData) {
    return [];
  }
  // leave 'yes' and 'other' answers
  const filteredData = parsedData
    ?.filter(({ node }) => node?.response !== 'no')
    ?.map(({ node }) => ({ ...node }));

  return filteredData.map(({ option, question, response }) => ({
    displayIcon: question.icon || mockIcon,
    displayLabel:
      response === FOHYes
        ? option.displayLabel
        : option.displayLabel || response // order important here as some 'other' type questions might have defined answers with labels (like "By check/Nightly")
  }));
};

export const adaptAnswersData = rawData => {
  const parsedData = path([...edgesPath, 'answers', 'edges'], rawData).map(
    ({ node }) => ({
      ...node
    })
  );

  return parsedData.reduce((acc, answer) => {
    const {
      option: { id: optionId },
      question: { id, options }
    } = answer;

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const selectedOption = options.find(({ id }) => id === optionId);
    const isOtherType = selectedOption.value === FOHOther;

    const enchancedAnswer = {
      ...answer,
      answer: {
        optionId: selectedOption.id,
        questionId: id,
        value: isOtherType ? FOHOther : answer.response,
        ...(isOtherType && {
          freeText: answer.response
        })
      }
    };

    return [...acc, enchancedAnswer];
  }, []);
};

export const adaptMainImageData = rawData => {
  const parsed = path(edgesPath, rawData);
  const { galleries } = parsed || {};
  const unwrapped = unwrapEdges(galleries?.edges);
  // empty state main image
  const mainImage = unwrapped.find(
    ({ category }) => category === MAIN_IMAGE_CATEGORY
  );
  if (!mainImage)
    unwrapped.push({ category: MAIN_IMAGE_CATEGORY, isEmpty: true });
  // cover image must be first in array
  return unwrapped.reduce((acc, image) => {
    return image.category === MAIN_IMAGE_CATEGORY
      ? [image, ...acc]
      : [...acc, image];
  }, []);
};

export const adaptPositionsData = rawData => {
  const unwrapped = unwrapEdges(rawData?.positions?.edges);

  return unwrapped.map(position => {
    const { location, ...restOfPosition } = position;

    return {
      ...restOfPosition,
      createdAt: toFullDate(position.createdAt),
      location,
      locationAddress: position.location.formattedAddress,
      locationLogo: position.location.image,
      locationName: position.location.name
    };
  });
};

export const adaptLocationsOptions = locations =>
  locations.map(location => ({
    label: location.name,
    sublabel: location.formattedAddress,
    value: location.rowId
  }));

export const adaptLocations = (rawData, publicPage = false) => {
  const parsed = path(edgesPath, rawData);
  let unwrapped = unwrapEdges(parsed?.locations?.edges);
  if (publicPage) {
    unwrapped = unwrapped.filter(item =>
      parsed.selectedLocations.includes(item.id)
    );
  }

  const tagNamesArray = unwrapped
    .map(location => location.tags.map(tag => tag.name))
    .flat();
  const uniqueTagNames = Array.from(new Set(tagNamesArray));

  return {
    dropdownOptions: unwrapped.map(location => ({
      label: location.name,
      sublabel: location.formattedAddress,
      value: location.id
    })),
    locationArray: unwrapped,
    locationRowIds: unwrapped.map(({ rowId }) => rowId),
    tags: uniqueTagNames
  };
};

export const adaptMainImageGalleryDataAsync = async rawData => {
  const { galleries } = rawData;

  const unwrapped = unwrapEdges(galleries?.edges, galleries);

  const gallery = unwrapped
    .filter(({ category }) => category !== MAIN_IMAGE_CATEGORY)
    .filter(item => item.uploadFile !== null)
    .map(async galleryItem => ({
      ...galleryItem,
      ...(await dataURLtoBlob(galleryItem.uploadFile))
    }));
  try {
    const result = await Promise.all(gallery);
    return result;
  } catch (e) {
    throw new Error(e);
  }
};

export const adaptMainImageMutationPayload = (
  galleriesState,
  initialGalleries
) => {
  const updatedFilesArray = galleriesState?.map(
    ({ category, fileName, fileType }) => ({
      category,
      fileName,
      fileType
    })
  );

  const itemsToDelete = initialGalleries
    ?.filter(
      ({ fileName }) =>
        !updatedFilesArray?.find(
          ({ fileName: innerFileName }) => innerFileName === fileName
        )
    )
    .map(({ category, id }) => ({
      // Getting in response all upper case but mutation is lower case
      category: category?.toLowerCase(),
      [FileFields.FileName]: 'null',
      [FileFields.FileType]: 'null',
      id
    }));

  const itemsToAdd = updatedFilesArray?.filter(
    ({ fileName }) =>
      !initialGalleries?.find(
        ({ fileName: innerFileName }) => innerFileName === fileName
      )
  );

  return { itemsToAdd, itemsToDelete };
};
