import React from 'react';

import { View } from 'react-native';
import styled from 'styled-components/native';
import { length } from 'ramda';

import { Row, FOHButton } from '../../ingredients';

import { FOHSelect, FOHSelectItem } from '../select/FOHSelect';

export interface FOHInterviewRequestFormProps extends InterviewRequestProps {
  positionPlaceholder?: string | undefined;
  myPositions?: Array<FOHPositionProps>;
  selectedPosition: string;
  selectPosition: (value: string) => void;
  locationPlaceHolder?: string | undefined;
  myLocations?: Array<FOHLocationProps>;
  location?: string;
  setLocation?: (value: string) => void;
  submit: () => void;
  submitText: string;
  style?: any;
  error?: string;
  submitDisabled?: boolean;
  sendingIR?: boolean;
}

interface FOHPositionProps {
  id: string;
  positionName: string;
}
interface FOHLocationProps {
  id: string;
  locationName: string;
}

interface InterviewRequestProps {
  error?: any;
  style?: any;
}

export const FOHInterviewRequestForm = (
  props: FOHInterviewRequestFormProps
) => (
  <ContainerView>
    <Row>
      {props.myPositions && length(props.myPositions) > 0 ? (
        <FOHSelect
          selectedValue={props.selectedPosition}
          onValueChange={(value: string) => props.selectPosition(value)}
          placeholder={props.positionPlaceholder || ''}
          small={true}
          neverEmpty={true}
          error={props.error}
          testID={'FOHSelect'}
        >
          {props.myPositions.map(position => (
            <FOHSelectItem
              key={`${position.positionName}-interview-form-option`}
              label={position.positionName}
              value={String(position.id)}
            />
          ))}
        </FOHSelect>
      ) : (
        <></>
      )}
    </Row>
    <Row>
      {props.myLocations && length(props.myLocations) > 0 ? (
        <FOHSelect
          selectedValue={props.location}
          onValueChange={(value: string) =>
            props.setLocation && props.setLocation(value)
          }
          placeholder={props.locationPlaceHolder || ''}
          small={true}
          neverEmpty={true}
          error={props.error}
        >
          {props.myLocations &&
            props.myLocations.map(position => (
              <FOHSelectItem
                key={`${position.locationName}-interview-form-option`}
                label={position.locationName}
                value={String(position.id)}
              />
            ))}
        </FOHSelect>
      ) : (
        <></>
      )}
    </Row>
    <Row>
      <FOHButton
        testID={props.submitText}
        title={props.submitText}
        onPress={props.submit}
        disabled={props.submitDisabled}
      />
    </Row>
  </ContainerView>
);

const ContainerView = styled(View)`
  padding-top: 20px;
  align-items: center;
  justify-content: center;
`;
