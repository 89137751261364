import React from 'react';

import { useTranslation } from 'react-i18next';
import { defaultTo, path, prop } from 'ramda';
import { useParams } from 'react-router-dom';

import {
  FOHHighlightText,
  FOHColors,
  Screen,
  FOHSpace,
  FOHTextButton,
  FOHView,
  FOHFixedHeightScrollView,
  FOHBannerType,
  FOHRedX,
  FOHToolBoxIcon,
  FOHInverseButton,
  FOHHeaderH1,
  FOHCandidateOffer,
  FileType,
  FOHFormFlowButtons,
  SalaryUnitChoices,
  PaymentPeriod
} from '../../components';
import candidate_offer from '../../images/candidate_offer.svg';
import candidate_offer_rejected from '../../images/candidate_offer_rejected.svg';
import {
  GetEmployerDocumentDocument,
  useGetEmployerDocumentQuery,
  useMutateEmploymentDocumentMutation
} from '../../graphql/generated';
import { DISCOVER, HOME_PAGE } from '../../constants';
import { handleMutation, toUSD, useIsMobile, toDate } from '../../utils';
import { useNavigation } from '../../utils/navigation';

import { useBanner } from '../Navigation/useBanner';

const CandidateOffer = () => {
  const { t } = useTranslation('OffersFeature');
  const { navigateTo } = useNavigation();
  const { id } = useParams();

  const { isMobile } = useIsMobile();

  const empDocumentQuery = useGetEmployerDocumentQuery({
    variables: { id: id }
  });

  const refetchQueries = [
    {
      query: GetEmployerDocumentDocument,
      variables: { id: id }
    }
  ];

  const empDocument = defaultTo(
    {},
    path(['data', 'getEmployerDocument'], empDocumentQuery)
  );

  const { setBanner } = useBanner();

  const [mutateEmploymentDocument] = useMutateEmploymentDocumentMutation();

  const locationAddress = [
    path(['position', 'location', 'addressStreet1'], empDocument),
    path(['position', 'location', 'addressCity'], empDocument),
    path(['position', 'location', 'addressState'], empDocument) +
      ' ' +
      path(['position', 'location', 'addressPostalCode'], empDocument)
  ].join(', ');

  const files = defaultTo(
    [],
    path(['employmentdocumentfileSet', 'edges'], empDocument)
  ).map(edge => path(['node', 'attachment'], edge));

  const startDate = toDate(prop('startingAt', empDocument), 'LLL dd, yyyy');

  const startTime = toDate(prop('startingAt', empDocument), 'h:mm a');

  const candidateId = defaultTo('', path(['candidate', 'id'], empDocument));
  const status = defaultTo('', path(['status'], empDocument));

  const acceptOffer = async () => {
    if (candidateId) {
      const [response, error] = await handleMutation(
        mutateEmploymentDocument({
          variables: {
            status: 'accepted',
            id: id
          }
        }),
        refetchQueries
      );

      if (error && !response) {
        setBanner({
          bannerType: FOHBannerType.DANGER,
          bannerMessage: `${t('candidateOfferFailed')}`
        });
        return;
      }

      setBanner({
        bannerType: FOHBannerType.SUCCESS,
        bannerMessage: `${t('candidateOfferSuccess')}`
      });
      navigateTo(HOME_PAGE);
    }
  };

  const declineOffer = async () => {
    if (candidateId) {
      const [response, error] = await handleMutation(
        mutateEmploymentDocument({
          variables: {
            status: 'declined',
            id: id
          }
        }),
        refetchQueries
      );

      if (error && !response) {
        setBanner({
          bannerType: FOHBannerType.DANGER,
          bannerMessage: `${t('candidateOfferFailed')}`
        });
        return;
      }

      setBanner({
        bannerType: FOHBannerType.SUCCESS,
        bannerMessage: `${t('candidateOfferSuccess')}`
      });
    }
  };

  return (
    <Screen
      style={{
        minHeight: '100vh',
        backgroundColor: '#E5E5E5'
      }}
    >
      <FOHView
        style={{
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: 60
        }}
      >
        <FOHView
          style={{
            width: '100%'
          }}
        >
          <FOHFixedHeightScrollView isMobile={isMobile} heightToDecrease={100}>
            <FOHView
              style={{
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <FOHView
                style={{
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingHorizontal: 15,
                  paddingVertical: 20
                }}
              >
                <FOHHighlightText
                  labelCmp={FOHHeaderH1}
                  emphasizedWord={t('newOfferHighlight')}
                  highlightColor={FOHColors.PACIFIC_BLUE}
                  boldLabelCmp={FOHHeaderH1}
                >
                  {t('congratsYouHaveGotANewOffer')}
                </FOHHighlightText>
              </FOHView>
              <FOHCandidateOffer
                isMobile={isMobile}
                image={
                  status === 'DECLINED'
                    ? candidate_offer_rejected
                    : candidate_offer
                }
                positionLabel={t('common:position')}
                position={path(['position', 'positionName'], empDocument)}
                locationLabel={t('common:location')}
                locationImage={path(
                  ['position', 'location', 'image'],
                  empDocument
                )}
                locationName={path(
                  ['position', 'location', 'name'],
                  empDocument
                )}
                locationAddress={locationAddress}
                startDateLabel={t('startDateLabel')}
                startDate={startDate}
                startTimeLabel={t('startTimeLabel')}
                startTime={startTime}
                compensationLabel={t('compensation')}
                compensation={`${toUSD(
                  defaultTo('', prop('baseSalaryValue', empDocument))
                ).replace('US', '')} USD`}
                typeLabel={t('type')}
                type={`${t(
                  `Offers:${
                    prop('baseSalaryUnitText', empDocument) ===
                    SalaryUnitChoices.YEAR
                      ? PaymentPeriod.YEAR
                      : prop('baseSalaryUnitText', empDocument) ===
                        SalaryUnitChoices.HOURLY_TIPS
                      ? PaymentPeriod['HOURLY_TIPS']
                      : PaymentPeriod.HOUR
                  }`
                )}`}
                customNoteLabel={t('hiringManagerMsg')}
                customNote={prop('note', empDocument)}
                hiringManagerProfile={path(
                  ['position', 'createdBy', 'profileImage'],
                  empDocument
                )}
                hiringManagerLabel={t('hiringManagerLabel')}
                hiringManagerName={path(
                  ['position', 'createdBy', 'firstName'],
                  empDocument
                )}
                OfferLetterCmp={OfferLetterCmpProps => (
                  <FOHView style={{ ...OfferLetterCmpProps.style }}>
                    <iframe
                      title="offer-iframe"
                      src={defaultTo('', files)[0]}
                      allowFullScreen={true}
                      style={OfferLetterCmpProps.style}
                      frameBorder="0"
                    />
                  </FOHView>
                )}
                offerLetter={defaultTo('', files)[0]}
                fileType={FileType.pdf}
              >
                {!isMobile && status !== 'DECLINED' && (
                  <FOHView
                    style={{
                      width: '100%'
                    }}
                  >
                    <FOHFormFlowButtons
                      nextButtonStyle={{ maxWidth: 270 }}
                      prevButtonStyle={{ maxWidth: 270 }}
                      nextButtonlabel={t('acceptOfferBtn')}
                      onPressNextButton={acceptOffer}
                      previousButtonLabel={t('declineOfferBtn')}
                      onPressPreviousButton={declineOffer}
                    />
                  </FOHView>
                )}
                {status === 'DECLINED' && (
                  <FOHView
                    style={{
                      width: '100%'
                    }}
                  >
                    <FOHInverseButton
                      title={t('offerRejectLabel')}
                      icon={FOHRedX}
                      color="#F2F2F2"
                      opaque={true}
                      textColor={FOHColors.BLACK}
                      canSelect={true}
                      selected={true}
                      onPress={() => {
                        navigateTo(HOME_PAGE);
                      }}
                      style={{
                        maxWidth: '100%',
                        backgroundColor: '#F2F2F2'
                      }}
                    />
                    <FOHSpace />
                    <FOHTextButton
                      title={t('exporeMoreJobsLabel')}
                      icon={() => (
                        <FOHToolBoxIcon
                          style={{ tintColor: FOHColors.BLACK }}
                        />
                      )}
                      onPress={() => {
                        navigateTo(`${HOME_PAGE}${DISCOVER}`);
                      }}
                    />
                  </FOHView>
                )}
              </FOHCandidateOffer>
            </FOHView>
          </FOHFixedHeightScrollView>
        </FOHView>
        {!!isMobile && status !== 'DECLINED' && (
          <FOHView
            style={{
              width: '100%'
            }}
          >
            {/* TODO REFACTOR HANDLE SUBMIT: passing strings like this is brittle */}
            <FOHFormFlowButtons
              isMobile={true}
              nextButtonlabel={t('acceptOfferBtn')}
              onPressNextButton={acceptOffer}
              previousButtonLabel={t('declineOfferBtn')}
              onPressPreviousButton={declineOffer}
              isNextDisabled={!empDocument.id}
              isPreviousDisabled={!empDocument.id}
            />
          </FOHView>
        )}
      </FOHView>
    </Screen>
  );
};

export default CandidateOffer;
