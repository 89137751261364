import React from 'react';

import {
  FOHDivider,
  FOHInformationIcon,
  FOHLabel,
  FOHLabelSmall,
  FOHSmallDetailLabel,
  FOHSpaceSmall,
  FOHSpaceSmallest,
  FOHVerticalDivider,
  FOHTextButton,
  FOHSpace,
  FOHColors,
  FOHTouchableOpacity,
  FOHView
} from '../../ingredients';
import { FOHProfileImage } from '../../recipes';

export interface FOHChannelHeaderProps {
  image: string;
  candidateName: string;
  positionText: string;
  linkText?: string;
  onLinkPress?: () => void;
  stageLabel?: string;
  stage?: string;
  detailsLabel: string;
  onDetailsPressed?: () => void;
  mobile?: boolean;
  restuarantName?: string;
  onPressName?: () => void;
  hiringManagerPhoto?: string;
  hiringManagerName?: string;
  hiringManagerLabel?: string;
  onBackPress?: () => void;
  archiveButtonLabel?: string;
  archiveButtonIcon?: () => Element;
  onPressArchiveOrUnarchive?: () => void;
  archiveButtonProps?: any;
}

export const FOHChannelHeader = (props: FOHChannelHeaderProps) => (
  <FOHView>
    <FOHView style={{ flexDirection: 'row', paddingRight: 8 }}>
      <FOHView
        style={{
          padding: 8,
          flexDirection: 'row',
          flex: 1,
          justifyContent: 'flex-start'
        }}
      >
        <FOHProfileImage photo={props.image} />
        <FOHSpaceSmall />
        <FOHView>
          <FOHLabel
            style={{
              textDecorationLine: 'underline'
            }}
            accessibilityRole={'link'}
            onPress={props.onPressName}
          >
            {props.restuarantName ? props.restuarantName : props.candidateName}
          </FOHLabel>
          <FOHView style={{ top: props.hiringManagerName ? -6 : 0 }}>
            <FOHSmallDetailLabel
              style={{ textAlignVertical: 'center' }}
              numberOfLines={1}
            >
              {props.hiringManagerName ? (
                <FOHSmallDetailLabel numberOfLines={1}>
                  <>{props.mobile ? '' : props.hiringManagerLabel}</>
                  <FOHSpaceSmallest />
                  <FOHProfileImage
                    style={{ height: 24, width: 24, top: 6 }}
                    photo={props.hiringManagerPhoto}
                  />
                  <FOHSpaceSmallest />
                  <FOHLabel numberOfLines={1}>
                    {props.hiringManagerName}
                  </FOHLabel>
                  <FOHSpaceSmallest />
                  <FOHVerticalDivider height={24} style={{ top: 6 }} />
                  <FOHSpaceSmallest />
                </FOHSmallDetailLabel>
              ) : (
                <></>
              )}
              <>
                {props.mobile && !!props.restuarantName
                  ? ''
                  : props.positionText}
              </>{' '}
              <FOHTouchableOpacity onPress={props.onLinkPress}>
                <FOHLabel
                  style={{
                    color: FOHColors.PRIMARY_TEAL_P1,
                    textDecorationLine: 'underline'
                  }}
                  accessibilityRole={'link'}
                >
                  {props.linkText}
                </FOHLabel>
              </FOHTouchableOpacity>
            </FOHSmallDetailLabel>
          </FOHView>
        </FOHView>
      </FOHView>
      <FOHView
        style={{
          flexDirection: 'row',
          flex: 1,
          justifyContent: 'flex-end',
          alignItems: 'center',
          top: -6
        }}
      >
        {props.stage && !props.mobile ? (
          <FOHView
            style={{
              backgroundColor: '#f5f5f5',
              height: 40,
              borderRadius: 6,
              marginTop: 8,
              marginRight: 8,
              padding: 8,
              paddingLeft: 16,
              paddingRight: 16,
              minWidth: 80,
              maxWidth: 186
            }}
          >
            <FOHSmallDetailLabel style={{ fontSize: 10, lineHeight: 10 }}>
              {props.stageLabel}
            </FOHSmallDetailLabel>
            <FOHLabelSmall>{props.stage}</FOHLabelSmall>
          </FOHView>
        ) : (
          <></>
        )}
        <FOHSpaceSmallest />
        {props.onDetailsPressed ? (
          <FOHTouchableOpacity
            onPress={props.onDetailsPressed}
            style={{ flexDirection: 'row', alignItems: 'center' }}
          >
            <FOHInformationIcon />
            <FOHSpaceSmallest />
            <FOHLabel
              style={{
                fontWeight: 600,
                color: FOHColors.BLUE
              }}
            >
              {props.detailsLabel}
            </FOHLabel>
          </FOHTouchableOpacity>
        ) : (
          <></>
        )}
        {props.onPressArchiveOrUnarchive && props.archiveButtonLabel ? (
          <>
            <FOHSpace />
            <FOHTextButton
              title={props.archiveButtonLabel!}
              icon={props.archiveButtonIcon}
              onPress={props.onPressArchiveOrUnarchive}
              {...props.archiveButtonProps}
            />
          </>
        ) : (
          <></>
        )}
      </FOHView>
    </FOHView>
    <FOHDivider />
  </FOHView>
);
