import React from 'react';

import { View, TouchableOpacity, ViewStyle, StyleProp } from 'react-native';
import styled from 'styled-components/native';

import {
  FOHLabel,
  FOHButton,
  FOHLabelBold,
  FOHColors,
  FOHGoldStar,
  FOHWhiteX
} from '../../ingredients';

const XContainer = styled(View)`
  width: 32px;
  height: 32px;
  z-index: 99999;
  right: 16px;
  position: absolute;
  justify-content: center;
  align-items: center;
`;

export interface FOHGuideBannerProps {
  iconText: string;
  title: string;
  body: string;
  open?: boolean;
  close?: () => void;
  buttonLabel?: string;
  onButtonPress?: () => void;
  mobile?: boolean;
  style?: StyleProp<ViewStyle>;
  children?: React.ReactNode;
}

export const FOHGuideBanner = (props: FOHGuideBannerProps) =>
  props.open ? (
    <View
      style={{
        borderBottomColor: '#f2f2f2',
        borderBottomWidth: 1,
        padding: 16,
        borderRadius: 6,
        backgroundColor: FOHColors.BLACK,
        justifyContent: 'flex-start',
        marginTop: 16,
        marginLeft: '4%',
        marginRight: '4%',
        zIndex: -1,
        minHeight: props.buttonLabel ? 148 : 129,
        ...((props.style || {}) as ViewStyle)
      }}
    >
      <XContainer>
        <TouchableOpacity style={{ flex: 1 }} onPress={props.close}>
          <FOHWhiteX />
        </TouchableOpacity>
      </XContainer>
      {!props.mobile && (
        <View
          style={{
            left: props.mobile ? 8 : 16,
            position: 'absolute'
          }}
        >
          <FOHGoldStar />
        </View>
      )}
      <View
        style={{
          justifyContent: 'flex-start',
          paddingLeft: props.mobile ? 0 : 40
        }}
      >
        <View
          style={{
            width: '100%',
            justifyContent: 'flex-start',
            paddingBottom: 16,
            flexDirection: 'row',
            alignItems: 'center',
            top: 4
          }}
        >
          {props.mobile && <FOHGoldStar />}
          {props.mobile && <View style={{ paddingLeft: 8 }} />}
          <FOHLabel style={{ color: '#F5AD22' }}>{props.iconText}</FOHLabel>
        </View>
        <View style={{ flexDirection: props.mobile ? 'column' : 'row' }}>
          <View style={{ flex: 1 }}>
            <FOHLabelBold
              style={{
                color: '#ffffff',
                textAlign: 'left',
                fontSize: 20
              }}
            >
              {props.title}
            </FOHLabelBold>
            <View style={{ paddingTop: 8 }}></View>
            <FOHLabel style={{ textAlign: 'left', color: '#d1d1d1' }}>
              {props.body}
            </FOHLabel>
          </View>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'flex-end',
              flex: 1,
              alignItems: 'flex-end',
              paddingTop: 8
            }}
          >
            {props.children && props.children}
            {!!props.buttonLabel && props.onButtonPress ? (
              <FOHButton
                style={{
                  maxWidth: props.mobile ? '100%' : 325
                }}
                title={props.buttonLabel}
                onPress={props.onButtonPress}
              />
            ) : (
              <></>
            )}
          </View>
        </View>
      </View>
    </View>
  ) : (
    <></>
  );
