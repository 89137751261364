import React, { useRef, useState } from 'react';

import { compose, defaultTo, path, prop } from 'ramda';
import { useTranslation } from 'react-i18next';

import {
  FOHView,
  FOHSpace,
  FOHBanner,
  FOHBannerType,
  FOHStatusType,
  FOHShareIcon,
  FOHInverseButton,
  FOHProfileInterviewBlock,
  FOHVideoStoryPlayer
} from '../../components';
import {
  CANDIDATE_PROFILE,
  INVITE_TO_APPLY,
  MOVE_CANDIDATE,
  NETWORK,
  getAppBaseUrl
} from '../../constants';
import { useGetCandidateQuery } from '../../graphql/generated';
import { useNavigation } from '../../utils/navigation';
import { FOH_INPUT_HEIGHT } from '../../components/constants';

import { CandidateProfileOverview } from '../CandidateProfile/CandidateProfileOverview';
import { withReactiveModal } from '../Navigation';
import { useStages } from '../CandidateFeed/useStages';
import { useBanner } from '../Navigation/useBanner';

import { useCandidateNetworkSearchContext } from './useCandidateNetworkSearchHook';
import { InviteToApplyButton } from './InviteToApplyButton';

export const CandidateNetworkProfile = props => {
  const { activeCandidateHandles, setPartialState } =
    useCandidateNetworkSearchContext();

  const handle = activeCandidateHandles[0];

  const { navigateTo } = useNavigation();

  const { t } = useTranslation('CandidateNetworkSearch');

  const { banner, setBanner } = useBanner();

  const candidateQuery = useGetCandidateQuery({
    variables: { handle: handle }
  });

  const profile = path(['data', 'profile'], candidateQuery);

  const status = defaultTo('ACTIVELY_LOOKING', prop('status', profile));

  const handleInviteToApplyPress = async () => {
    navigateTo(`${NETWORK}${INVITE_TO_APPLY}/`);
  };

  const handleSharePress = () => {
    navigator.clipboard.writeText(
      `${getAppBaseUrl()}${CANDIDATE_PROFILE}/${handle}`
    );
    setBanner({
      bannerMessage: t('successfullyCopied'),
      bannerType: FOHBannerType.SUCCESS
    });
  };

  const { stages, moveEmployerCandidates, passiveStage } = useStages({
    polling: false
  });

  const [videoOpen, setVideoOpen] = useState(false);
  const vidRef = useRef(null);

  const toggleVideoPlayback = () => {
    vidRef && vidRef.current && !vidRef.current.paused
      ? vidRef.current.pause()
      : vidRef.current.play();
  };

  const VideoComponent = VideoCompProps => (
    <video
      controls
      ref={vidRef}
      playsInline
      autoPlay
      style={{ ...VideoCompProps.style }}
    >
      <track kind="captions" />
      <source src={path(['profileVideo'], profile)} type="video/mp4" />
    </video>
  );

  return (
    <FOHView style={{ maxWidth: '100%' }}>
      {banner.bannerMessage ? (
        <FOHBanner {...banner} message={banner.bannerMessage} />
      ) : (
        <></>
      )}
      <FOHProfileInterviewBlock
        key={handle}
        personalSummary={prop('personalSummary', profile)}
        name={`${defaultTo(
          '',
          path(['user', 'firstName'], profile)
        )} ${defaultTo('', path(['user', 'lastName'], profile))}`}
        status={t(`common:candidateStatusType.${status}`)}
        statusType={
          status === 'APPLIED_TO_YOU' ? FOHStatusType.ACTIVELY_LOOKING : status
        }
        storyPhoto={path(['user', 'profileImage'], profile)}
        openVideo={() => {
          // props.close();
          setVideoOpen(true);
        }}
        video={path(['profileVideo'], profile)}
        VideoComponent={VideoComponent}
      >
        <FOHView style={{ flexDirection: 'row', minWidth: 300 }}>
          <FOHView style={{ flex: 2 }}>
            <InviteToApplyButton
              onPress={handleInviteToApplyPress}
              onStagePress={stage => {
                setPartialState({
                  activeCandidateHandles: [handle],
                  activeMoveToStageId: stage.id
                });
                navigateTo({
                  pathname: `${NETWORK}${MOVE_CANDIDATE}`
                });
              }}
              handle={handle}
              stages={stages.filter(state => state.slug !== 'applicants')}
              moveEmployerCandidates={moveEmployerCandidates}
              passiveStage={passiveStage}
            />
          </FOHView>

          <FOHSpace />
          <FOHView style={{ flex: 0.75, maxWidth: 55 }}>
            <FOHInverseButton
              fullWidth={true}
              selected={true}
              centerIcon={true}
              style={{
                height: FOH_INPUT_HEIGHT
              }}
              onPress={handleSharePress}
              icon={() => FOHShareIcon({ style: { width: 20, height: 20 } })}
            />
          </FOHView>
        </FOHView>
      </FOHProfileInterviewBlock>
      <CandidateProfileOverview
        {...props}
        backgroundColor="#ffffff"
        handle={handle}
        fullWidth={true}
        setVideoOpen={setVideoOpen}
      />
      <FOHVideoStoryPlayer
        open={videoOpen}
        toggleVideoPlayback={toggleVideoPlayback}
        close={() => setVideoOpen(false)}
        profilePhoto={path(['user', 'profileImage'], profile)}
        video={prop('profileVideo', profile)}
        VideoComponent={VideoComponent}
      />
    </FOHView>
  );
};

export const CandidateNetworkProfileModal = compose(withReactiveModal)(
  CandidateNetworkProfile
);
