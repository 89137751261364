import { path } from 'ramda';

import { getStripeCustomerPortal } from '../../api/stripePayment';
// rowid
export const useCustomerPortal = () => {
  const redirectToCustomerPortal = async ({ location, customer }) => {
    const sessionResponse = await getStripeCustomerPortal({
      location: String(location),
      customer
    });
    // const sessionId = path(['stripe_session', 'id'], sessionResponse);
    window.location.href = path(['stripe_session', 'url'], sessionResponse);
  };
  return {
    redirectToCustomerPortal
  };
};
