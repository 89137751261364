import React, { useState } from 'react';

import { LoadScript, LoadScriptProps } from '@react-google-maps/api';

import { envVariables } from '../../constants';

import { FOHCircleLoader } from '../ingredients';

interface GoogleMapsLoaderProps {
  children: React.ReactNode;
  options: LoadScriptProps;
}

const FOHLoadGoogleScript: React.FC<GoogleMapsLoaderProps> = ({
  options,
  children
}) => {
  const [loadError, setLoadError] = useState<Error | undefined>();

  const loadScriptProps: LoadScriptProps = {
    ...options,
    loadingElement: <FOHCircleLoader />,
    googleMapsApiKey: envVariables.GOOGLE_MAPS_PLACES_API_KEY as string,
    onError: (error: Error) => setLoadError(error)
  };

  if (loadError) return null;

  return <LoadScript {...loadScriptProps}>{children}</LoadScript>;
};

export default FOHLoadGoogleScript;
