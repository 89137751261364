import React from 'react';

import {
  FOHButton,
  FOHHeaderText,
  Screen,
  FOHErrorLabel,
  FOHTextButton,
  FOHColors,
  FOHView
} from '../../ingredients';
import {
  FOHGoBack,
  FOHWorkHistoryFormProps,
  FOHWorkHistoryForm
} from '../../recipes';

export interface FOHWorkHistoryScreenProps extends FOHWorkHistoryFormProps {
  handleSubmit: () => void;
  removeLabel?: string;
  remove?: () => void;
  goBack: () => void;
  cancel?: () => void;
  cancelLabel: string;
  error?: string;
  submitLabel: string;
  title?: string;
  disabledSubmit?: boolean;
  largeHeader?: boolean;
  paddingTop?: string;
}

export const FOHWorkHistoryScreen = (props: FOHWorkHistoryScreenProps) => (
  <Screen>
    {props.goBack && <FOHGoBack goBack={props.goBack} />}
    <FOHView
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: props.paddingTop || 60,
        width: '375'
      }}
    >
      <FOHView
        style={{
          justifyContent: 'center',
          flexDirection: 'row',
          width: 375
        }}
      >
        {!!props.title && (
          <FOHHeaderText style={{ fontSize: 30 }}>{props.title}</FOHHeaderText>
        )}
        <FOHView />
      </FOHView>
      <FOHView style={{ paddingTop: 18 }} />
      <FOHWorkHistoryForm {...props} />
      <FOHErrorLabel>{props.error}</FOHErrorLabel>
      <FOHButton
        onPress={props.handleSubmit}
        title={props.submitLabel}
        disabled={props.disabledSubmit}
      />
      {!!props.remove && !!props.removeLabel && (
        <>
          <FOHView style={{ paddingTop: 16 }} />
          <FOHTextButton
            title={props.removeLabel}
            onPress={props.remove}
            color={FOHColors.RED}
          />
        </>
      )}
      {!!props.cancel && (
        <>
          <FOHView style={{ paddingTop: 16 }} />
          <FOHTextButton title={props.cancelLabel} onPress={props.cancel} />
        </>
      )}
      <FOHView style={{ paddingTop: 16 }} />
    </FOHView>
  </Screen>
);
