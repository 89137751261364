import React from 'react';

import {
  FOHButton,
  FOHView,
  FOHTextField,
  FOHMultiLineTextField,
  FOHSwitch
} from '../../ingredients';
import { FOHForm } from '../../recipes';

import { FOHLargeImageInput } from '../fileinputs/FOHLargeImageInput';

export interface FOHEditLocationFormProps {
  vertical?: boolean;

  pickPhoto?: () => void;
  image?: string;
  uploadImageLabel?: string;

  companyName: string;
  companyNamePlaceholder: string;
  setCompanyName: (value: string) => void;
  street1?: string;
  street1Placeholder?: string;
  setStreet1?: (value: string) => void;
  street2: string;
  street2Placeholder: string;
  setStreet2: (value: string) => void;
  city: string;
  cityPlaceholder: string;
  setCity: (value: string) => void;
  state: string;
  statePlaceholder: string;
  setState: (value: string) => void;
  postalCode: string;
  postalCodePlaceholder: string;
  setPostalCode: (value: string) => void;

  summary: string;
  summaryPlaceholder: string;
  setSummary: (value: string) => void;

  indeedEasyapplyOptout?: boolean;
  indeedEasyapplyOptoutLabel?: string;
  setIndeedEasyapplyOptout?: (value: boolean) => void;

  submitLabel: string;
  error?: any;

  handleSubmit?: () => void;
  disabledSubmit?: boolean;
  largeHeader?: boolean;

  autoCompleteInput?: React.ReactNode;
}

export const FOHEditLocationForm = (props: FOHEditLocationFormProps) => (
  <FOHForm
    handleSubmit={props.handleSubmit}
    disabledSubmit={props.disabledSubmit}
  >
    <FOHView style={{ justifyContent: 'center', alignItems: 'center' }}>
      <FOHView
        style={{
          flexDirection: !props.vertical ? 'row' : 'column',
          justifyContent: 'center',
          width: !props?.pickPhoto ? '100%' : undefined
        }}
      >
        {props?.pickPhoto && (
          <>
            <FOHView
              style={{
                flex: 8,
                minWidth: 257
              }}
            >
              <FOHLargeImageInput
                //@ts-ignore photo
                photo={props.image}
                center={props.vertical}
                uploadLabel={props.uploadImageLabel}
                pickPhoto={props.pickPhoto}
              />
            </FOHView>
            <FOHView style={{ padding: 12 }} />
          </>
        )}
        <FOHView
          style={{
            flex: props?.pickPhoto ? 8 : undefined,
            width: !props?.pickPhoto ? '100%' : undefined,
            minWidth: 257
          }}
        >
          <FOHTextField
            placeholder={props.companyNamePlaceholder}
            value={props.companyName || ''}
            required={true}
            onChangeText={(value: string) => props.setCompanyName(value)}
            error={props.error && props.error.name ? props.error.name : null}
          />
          <FOHView style={{ paddingTop: 16 }} />

          {props.autoCompleteInput ? (
            props.autoCompleteInput
          ) : (
            <FOHTextField
              placeholder={props.street1Placeholder}
              value={props.street1 || ''}
              required={true}
              autoCompleteType="street-address"
              onChangeText={(value: string) =>
                props.setStreet1 && props.setStreet1(value)
              }
              error={
                props.error && props.error.address_street_1
                  ? props.error.address_street_1
                  : null
              }
            />
          )}
          <FOHView style={{ paddingTop: 16 }} />

          <FOHTextField
            placeholder={props.street2Placeholder}
            value={props.street2 || ''}
            onChangeText={(value: string) => props.setStreet2(value)}
            error={
              props.error && props.error.address_street_1
                ? props.error.address_street_2
                : null
            }
          />
          <FOHView style={{ paddingTop: 16 }} />
          <FOHView style={{ flexDirection: 'row' }}>
            <FOHView style={{ flex: 3 }}>
              <FOHTextField
                placeholder={props.cityPlaceholder}
                value={props.city || ''}
                required={true}
                onChangeText={(value: string) =>
                  props.setCity && props.setCity(value)
                }
                error={
                  props.error && props.error.address_city
                    ? props.error.address_city
                    : null
                }
              />
            </FOHView>
            <FOHView style={{ padding: 2 }} />
            <FOHView style={{ flex: 1 }}>
              <FOHTextField
                placeholder={props.statePlaceholder}
                value={props.state || ''}
                required={true}
                onChangeText={(value: string) =>
                  props.setState && props.setState(value)
                }
                error={
                  props.error && props.error.address_state
                    ? props.error.address_state
                    : null
                }
              />
            </FOHView>
          </FOHView>
          <FOHView style={{ paddingTop: 16 }} />
          <FOHTextField
            placeholder={props.postalCodePlaceholder}
            required={true}
            value={props.postalCode || ''}
            autoCompleteType="postal-code"
            onChangeText={(value: string) =>
              value.length <= 5
                ? props.setPostalCode && props.setPostalCode(value)
                : undefined
            }
            error={
              props.error && props.error.address_postal_code
                ? props.error.address_postal_code
                : null
            }
          />
          <FOHView style={{ paddingTop: 16 }} />
          <FOHMultiLineTextField
            placeholder={props.summaryPlaceholder}
            required={false}
            value={props.summary || ''}
            onChangeText={(value: string) => props.setSummary(value)}
            error={
              props.error && props.error.summary ? props.error.summary : null
            }
          />
        </FOHView>
      </FOHView>
      <FOHView style={{ paddingTop: 32 }} />
      {props.setIndeedEasyapplyOptout && (
        <FOHView
          style={{
            width: '100%',
            flexDirection: !props.vertical ? 'row' : 'column',
            justifyContent: 'center'
          }}
        >
          <FOHView
            style={{
              flex: 16,
              justifyContent: 'center',
              alignItems: 'flex-start'
            }}
          >
            <FOHSwitch
              inverseLabel={true}
              value={props?.indeedEasyapplyOptout || false}
              label={props.indeedEasyapplyOptoutLabel}
              onValueChange={props.setIndeedEasyapplyOptout}
              width="100%"
            />
          </FOHView>
        </FOHView>
      )}
      <FOHView style={{ paddingTop: 32 }} />
      {props.handleSubmit && !!props.submitLabel && (
        <FOHButton
          onPress={props.handleSubmit}
          title={props.submitLabel}
          disabled={props.disabledSubmit}
        />
      )}
    </FOHView>
  </FOHForm>
);
