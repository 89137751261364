import React from 'react';

import { View, TouchableOpacity, ScrollView } from 'react-native';
import styled from 'styled-components/native';
import { length, path, defaultTo, compose } from 'ramda';
import { useSpring, animated } from 'react-spring';

import {
  FOHExpandIcon,
  withError,
  FOHDetailText,
  FOHModalProps,
  FOHModal,
  FOHHeaderText,
  FOHSmallDetailLabel,
  FOHLabel,
  FOHFonts,
  FOHColors
} from '../../ingredients';
import { FOHSelectionTable } from '../../recipes';
import { useClickOutside } from '../../spices';
import { FOH_INPUT_HEIGHT } from '../../constants';

import { FOHSelectCheckboxTable } from '../tables/FOHSelectCheckboxTable';

import { FOHSelectItemProps } from './FOHSelect';

interface FOHMultiSelectProps extends MultiSelectProps, FOHModalProps {
  items: Array<FOHSelectItemProps>;
  selectedItems: Array<string>;
  selectItem: (value: string) => void;
  onPress: () => void;
  placeholder: string;
  style?: any;
  width?: number;
  dropDown?: boolean;
  disabled?: boolean;
}

interface MultiSelectProps {
  error?: any;
  style?: any;
  disabled?: boolean;
}

export const FOHMultiSelect = (props: FOHMultiSelectProps) => {
  const animatedProps = useSpring({
    from: { xyz: [0, 0, 0] },
    to: async (next: any) => {
      const isOpen = props.open;
      await next({ xyz: isOpen ? [0, 180, 0] : [0, 0, 0] });
    },
    config: { duration: 200 }
  }) as any;

  const [ref] = useClickOutside({
    close: () => !!props.open && props.close && props.close()
  });

  return (
    <>
      <View
        ref={ref}
        style={{
          width: props.width || '100%',
          zIndex: path(['style', 'zIndex'], props)
        }}
      >
        {/* {props.open && <FOHFullScreenClickable onPress={props.close} />} */}
        <TouchableOpacity
          onPress={props.onPress}
          testID={`multi-select-${props.placeholder}`}
          disabled={props.disabled}
        >
          <SelectContainer>
            <SelectButton
              //@ts-ignore showing style prop not supported
              style={{
                ...props.style,
                width: props.width || '100%'
              }}
              error={props.error}
              disabled={props.disabled}
            >
              {props.selectedItems && length(props.selectedItems) > 0 && (
                <View style={{ display: 'flex', top: -6 }}>
                  <FOHSmallDetailLabel style={{ fontSize: 12 }}>
                    {props.placeholder}
                  </FOHSmallDetailLabel>
                  <TruncatedLabel
                    style={{ width: props.width ? props.width - 50 : '95%' }}
                  >
                    {props.selectedItems
                      .map(
                        selected =>
                          props.items.filter(item => item.value === selected)[0]
                            ?.label
                      )
                      .join(', ')}
                  </TruncatedLabel>
                </View>
              )}

              {length(props.selectedItems) === 0 && (
                <FOHDetailText
                  style={{
                    color: props.disabled
                      ? FOHColors.GRAYSCALE_400
                      : FOHColors.GRAYSCALE_500
                  }}
                >
                  {props.placeholder}
                </FOHDetailText>
              )}
            </SelectButton>
            <View
              pointerEvents="none"
              style={{
                position: 'absolute',
                right: 8,
                top: '25%',
                zIndex:
                  (defaultTo(0, path(['style', 'zIndex'], props)) as number) + 8
              }}
            >
              <AnimatedArrowView
                style={{
                  transform: animatedProps?.xyz?.to(
                    (_x: number, y: number, _z: number) => `rotate(${y}deg)`
                  )
                }}
              >
                <FOHExpandIcon
                  style={{
                    tintColor: props.error
                      ? FOHColors.RED_300
                      : props.disabled
                      ? FOHColors.GRAYSCALE_400
                      : FOHColors.GRAYSCALE_500
                  }}
                />
              </AnimatedArrowView>
            </View>
          </SelectContainer>
        </TouchableOpacity>
        {props.dropDown && props.open && (
          <DropDown
            style={{
              height: props.open
                ? length(props.items) > 5
                  ? 300
                  : 60 * length(props.items)
                : 0,
              width: props.width || '100%',
              zIndex:
                (defaultTo(0, path(['style', 'zIndex'], props)) as number) + 11
            }}
          >
            <ScrollView>
              <FOHSelectCheckboxTable
                items={props.items}
                selected={props.selectedItems}
                selectItem={props.selectItem}
              />
            </ScrollView>
          </DropDown>
        )}
      </View>
      {!props.dropDown && (
        <FOHModal
          open={props.open}
          close={props.close}
          doneText={props.doneText}
        >
          <FOHHeaderText>{props.placeholder}</FOHHeaderText>
          <ScrollView>
            <ContainerView>
              <FOHSelectionTable
                items={props.items}
                selected={props.selectedItems}
                selectItem={props.selectItem}
              />
            </ContainerView>
          </ScrollView>
        </FOHModal>
      )}
    </>
  );
};

const EmptySelect = styled(View)`
  background: ${(props: MultiSelectProps) =>
    props.disabled ? FOHColors.GRAYSCALE_100 : FOHColors.WHITE};
  border: ${(props: MultiSelectProps) =>
    props.error
      ? `1px solid ${FOHColors.RED_300}`
      : props.disabled
      ? `${FOHColors.GRAYSCALE_200}`
      : `1px solid ${FOHColors.GRAYSCALE_300}`};
  border-radius: 4px;
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  display: flex;
  align-items: center;
  width: 327px;
  height: ${FOH_INPUT_HEIGHT}px;
  padding: 10px;
  text-align: left;
  align-items: left;
`;

const TruncatedLabel = styled(FOHLabel)`
  width: 275px;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${FOHColors.BLACK};
`;

const SelectButton = compose(withError)(EmptySelect);

const ArrowContainer = styled(View)``;
const AnimatedArrowView = animated(ArrowContainer);

const DropDown = styled(View)`
  border: 1px solid ${FOHColors.GRAYSCALE_300};
  height: 350px;
  background-color: ${FOHColors.WHITE};
  width: 327px;
  justify-content: left;
  padding-left: 18px;
  padding-top: 24px;
  top: 45px;
  border-radius: 4px;
  position: absolute;
  z-index: 11;
`;

const SelectContainer = styled(View)`
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

const ContainerView = styled(View)`
  width: 100%;
  padding-bottom: 100px;
  padding-top: 20px;
  align-items: center;
`;
