import React from 'react';

import {
  FOHOptionsType,
  FOHOptions,
  FOHView,
  FOHTouchableOpacity
} from '../../ingredients';

export interface FOHOptionsSelectProps {
  label: string;
  value: FOHOptionsType;
  route?: string;
}

export interface FOHOptionsDropdownProps {
  openOptions: boolean;
  selectedOption: (option: any) => void;
  options: Array<FOHOptionsSelectProps>;
}

export const FOHOptionsDropdown = (props: FOHOptionsDropdownProps) => {
  return (
    <React.Fragment>
      {props.openOptions && (
        <FOHView
          style={{
            justifyContent: 'center',
            top: 5,
            borderRadius: 4,
            paddingTop: 0,
            paddingLeft: 12,
            paddingRight: 12,
            minWidth: 260,
            backgroundColor: '#ffffff',
            shadowColor: '#000000',
            shadowOpacity: 0.06,
            shadowOffset: { width: 0, height: -1 },
            shadowRadius: 6,
            position: 'absolute',
            borderColor: '#ffffff',
            borderWidth: 1
          }}
        >
          {props.options.map((option, idx) => (
            <FOHTouchableOpacity
              key={`manage-profile-options-${idx}`}
              style={{
                flexDirection: 'row',
                paddingTop: 10,
                paddingBottom: 10
              }}
              onPress={() => props.selectedOption(option)}
            >
              <FOHOptions optionType={option.value} optionText={option.label} />
            </FOHTouchableOpacity>
          ))}
        </FOHView>
      )}
    </React.Fragment>
  );
};
