import indeed_logo from '../../images/indeed_logo.svg';

export enum HOME_TABS {
  ActiveCampaigns = 'ActiveCampaigns',
  DraftCampaigns = 'DraftCampaigns',
  CompletedCampaigns = 'CompletedCampaigns'
}

export const DEFAULT_TAB =
  HOME_TABS.ActiveCampaigns as unknown as keyof typeof HOME_TABS;

export enum PLATFORM_TYPES {
  Indeed = 'Indeed',
  ZipRecruiter = 'ZipRecruiter'
}

export interface Platform {
  title: string;
  value: keyof typeof PLATFORM_TYPES;
  logo: string;
  disabled: boolean;
}

export const PLATFORMS_LIST: Array<Platform> = [
  {
    title: 'Indeed',
    value: PLATFORM_TYPES.Indeed,
    logo: indeed_logo,
    disabled: false
  }
];

export enum OAUTH_PROVIDERS {
  SevenShifts = 'SevenShifts',
  Indeed = 'Indeed'
}
