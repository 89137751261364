import React from 'react';

import geo_search_ic from '../../../images/geo_search_ic.svg';

import { FOHImage } from '../../ingredients';

export const FOHGeoSearchIcon = (props: any) => (
  <FOHImage
    source={{ uri: geo_search_ic }}
    style={{
      width: 21,
      height: 21,
      ...props?.style
    }}
  />
);
