import React from 'react';

import styled from 'styled-components/native';
import { View } from 'react-native';

import {
  FOHButton,
  Row,
  FOHTextField,
  FOHHeaderH3,
  FOHSpace,
  FOHPhoneInput,
  FOHPasswordField,
  FOHTextButton,
  FOHTrashCan,
  FOHColors
} from '../../ingredients';
import {
  FOHSelect,
  FOHSelectItem,
  FOHPhotoInputProps,
  FOHAccountPhotoInput,
  FOHCommuteOption
} from '../../recipes';

import { FOHForm } from './FOHForm';

export interface FOHAccountFormProps extends FOHPhotoInputProps {
  handleSubmit?: () => void;
  submitLabel?: string;
  firstName: string;
  firstNamePlaceholder: string;
  setFirstName: (value: string) => void;
  lastName: string;
  lastNamePlaceholder: string;
  setLastName: (value: string) => void;
  phoneNumber: string;
  phoneNumberPlaceholder: string;
  setPhoneNumber: (value: string) => void;
  email: string;
  emailPlaceholder: string;
  setEmail: (value: string) => void;
  passwordLabel?: string;
  password?: string;
  passwordPlaceholder?: string;
  setPassword?: (value: string) => void;
  confirmPassword?: string;
  confirmPasswordPlaceholder?: string;
  setConfirmPassword?: (value: string) => void;
  zip?: string;
  zipPlaceholder?: string;
  setZip?: (value: string) => void;
  commuteTimeOptions?: Array<FOHCommuteOption>;
  commuteTime?: string;
  setCommuteTime?: (value: string) => void;
  commuteTimePlaceholder?: string;
  error?: any;
  disabledSubmit?: boolean;
  showPassText?: string;
  hidePassText?: string;
  onDeleteAccountPress?: () => void;
  deleteAccountLabel?: string;
  deleteButtonContainerProps?: any;
  deleteButtonProps?: any;
}

export const FOHAccountForm = (props: FOHAccountFormProps) => (
  <FOHForm
    handleSubmit={props.handleSubmit}
    disabledSubmit={props.disabledSubmit}
  >
    <ContainerView>
      <Row>
        <FOHAccountPhotoInput
          //@ts-ignore photo prop is not available in withErrors
          photo={props.photo}
          fullName={props.fullName}
          uploadLabel={props.uploadLabel}
          pickPhoto={props.pickPhoto}
          error={props.error && props.error.photo ? props.error.photo : null}
        />
      </Row>
      <Row>
        <View style={{ flex: 1 }}>
          <FOHTextField
            placeholder={props.firstNamePlaceholder}
            value={props.firstName}
            onChangeText={(value: string) => props.setFirstName(value)}
            error={
              props.error && props.error.firstName
                ? props.error.firstName
                : null
            }
          />
        </View>
        <FOHSpace />
        <View style={{ flex: 1 }}>
          <FOHTextField
            placeholder={props.lastNamePlaceholder}
            value={props.lastName}
            onChangeText={(value: string) => props.setLastName(value)}
            error={
              props.error && props.error.lastName ? props.error.lastName : null
            }
          />
        </View>
      </Row>

      <Row>
        <FOHPhoneInput
          placeholder={props.phoneNumberPlaceholder}
          value={props.phoneNumber}
          autoCompleteType={'tel'}
          onChangeText={(value: string) => props.setPhoneNumber(value)}
          error={
            props.error && (props.error.phoneNumber || props.error.phone)
              ? props.error.phoneNumber || props.error.phone
              : null
          }
        />
      </Row>
      <Row>
        <FOHTextField
          placeholder={props.emailPlaceholder}
          value={props.email}
          autoCompleteType={'email'}
          onChangeText={(value: string) => props.setEmail(value)}
          error={props.error && props.error.email ? props.error.email : null}
        />
      </Row>

      {props.setPassword && (
        <>
          <FOHSpace />
          <FOHHeaderH3 style={{ textAlign: 'left' }}>
            {props.passwordLabel}
          </FOHHeaderH3>
          <FOHSpace />

          <Row>
            <FOHPasswordField
              passwordPlaceholder={props.passwordPlaceholder}
              password={props.password}
              setPassword={(value: string) =>
                props.setPassword && props.setPassword(value)
              }
              error={
                props.error && props.error.password
                  ? props.error.password
                  : null
              }
              showPassText={props.showPassText}
              hidePassText={props.hidePassText}
            />
          </Row>
          <Row>
            <FOHTextField
              secureTextEntry={true}
              autoCompleteType={'password'}
              placeholder={props.confirmPasswordPlaceholder}
              value={props.confirmPassword}
              onChangeText={(value: string) =>
                props.setConfirmPassword && props.setConfirmPassword(value)
              }
              error={
                props.error && props.error.confirmPassword
                  ? props.error.confirmPassword
                  : null
              }
            />
          </Row>
        </>
      )}

      {props.setZip && (
        <Row>
          <FOHTextField
            placeholder={props.zipPlaceholder}
            value={props.zip}
            autoCompleteType={'postal-code'}
            keyboardType={'numeric'}
            onChangeText={(value: any) =>
              value.length <= 5 && props.setZip
                ? props.setZip(value)
                : undefined
            }
            error={props.error && props.error.zip ? props.error.zip : null}
          />
        </Row>
      )}
      {props.commuteTimeOptions && props.setCommuteTime && (
        <Row>
          <FOHSelect
            placeholder={props.commuteTimePlaceholder || ''}
            selectedValue={props.commuteTime}
            onValueChange={(value: string) =>
              props.setCommuteTime && props.setCommuteTime(value)
            }
            error={
              props.error && props.error.commuteTime
                ? props.error.commuteTime
                : null
            }
          >
            {props.commuteTimeOptions.map(option => (
              <FOHSelectItem
                key={option.value}
                value={option.value}
                label={option.label}
              />
            ))}
          </FOHSelect>
        </Row>
      )}

      {props.onDeleteAccountPress && !!props.deleteAccountLabel && (
        <Row
          style={{ alignContent: 'flex-end' }}
          {...props.deleteButtonContainerProps}
        >
          <FOHTextButton
            icon={FOHTrashCan}
            color={FOHColors.BLACK}
            style={{ textDecoration: 'underline', marginLeft: 'auto' }}
            onPress={props.onDeleteAccountPress}
            title={props.deleteAccountLabel}
            {...props.deleteButtonProps}
          />
        </Row>
      )}
      <FOHSpace />
      {props.handleSubmit && !!props.submitLabel && (
        <FOHButton
          onPress={props.handleSubmit}
          title={props.submitLabel}
          disabled={props.disabledSubmit}
        />
      )}
    </ContainerView>
  </FOHForm>
);

const ContainerView = styled(View)`
  width: 100%;
  padding-bottom: 100px;
  padding-top: 20px;
`;
