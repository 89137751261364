import React from 'react';

import { StyleProp, ViewStyle } from 'react-native';

import {
  FOHLabel,
  FOHButton,
  FOHLabelBold,
  FOHColors,
  FOHView,
  FOHSpace
} from '../../ingredients';

export interface FOHExpiredBannerProps {
  title: string;
  body: string;
  buttonLabel: string;
  onButtonPress?: () => void;
  mobile?: boolean;
  style?: StyleProp<ViewStyle>;
  children?: React.ReactNode;
}

export const FOHExpiredBanner = (props: FOHExpiredBannerProps) => (
  <FOHView
    style={{
      borderBottomColor: '#f2f2f2',
      borderBottomWidth: 1,
      padding: 16,
      borderRadius: 6,
      backgroundColor: FOHColors.BLACK,
      justifyContent: 'flex-start',
      marginTop: 16,
      marginLeft: '4%',
      marginRight: '4%',
      minHeight: props.buttonLabel ? 148 : 129,
      ...((props.style || {}) as ViewStyle)
    }}
  >
    <FOHView
      style={{
        justifyContent: 'flex-start',
        paddingLeft: props.mobile ? 0 : 40
      }}
    >
      <FOHView
        style={{
          width: '100%',
          justifyContent: 'flex-start',
          paddingBottom: 16,
          flexDirection: 'row',
          alignItems: 'center',
          top: 4
        }}
      ></FOHView>
      <FOHView style={{ flexDirection: props.mobile ? 'column' : 'row' }}>
        <FOHView style={{ flex: 1 }}>
          <FOHLabelBold
            style={{
              color: '#ffffff',
              textAlign: 'left',
              fontSize: 20
            }}
          >
            {props.title}
          </FOHLabelBold>
          <FOHView style={{ paddingTop: 8 }}></FOHView>
          <FOHLabel style={{ textAlign: 'left', color: '#d1d1d1' }}>
            {props.body}
          </FOHLabel>
        </FOHView>
        <FOHView
          style={{
            padding: 12,
            paddingTop: 8,
            justifyContent: 'flex-start',
            flex: 1,
            alignItems: 'flex-end',
            maxWidth: 375
          }}
        >
          {props.buttonLabel && props.onButtonPress ? (
            <FOHButton
              style={{
                maxWidth: '100%'
              }}
              color={FOHColors.GOLD}
              title={props.buttonLabel}
              onPress={props.onButtonPress}
            />
          ) : (
            <></>
          )}
          {props.children ? <FOHSpace /> : <></>}
          {props.children ? props.children : <></>}
        </FOHView>
      </FOHView>
    </FOHView>
  </FOHView>
);
