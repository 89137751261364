import React from 'react';

import { StyleProp, ViewStyle } from 'react-native';
import styled from 'styled-components/native';

import { FOHView } from '../containers/Containers';

export interface FOHVerticalDividerProps {
  height?: number;
  color?: string;
  style?: StyleProp<ViewStyle>;
}

export const FOHVerticalDivider = (props: FOHVerticalDividerProps) => (
  <Divider
    style={{
      height: props.height || '100%',
      backgroundColor: props.color || '#E8E8E8',
      ...((props.style || {}) as ViewStyle)
    }}
  />
);

const Divider = styled(FOHView)`
  width: 1px;
`;
