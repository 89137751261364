import React from 'react';

import { useTranslation } from 'react-i18next';
import { path } from 'ramda';

import { FOHNoResults, FOHView } from '../../components';
import { LOGIN_URL } from '../../constants';
import { useNavigation } from '../../utils/navigation';

import { useGetMeData } from './useGetMeData';

const NotFound404 = () => {
  const { t } = useTranslation('NotFoundFeature');

  const { navigateTo, location: navLocation } = useNavigation();
  const type = path(['state', 'type'], navLocation);

  const { data: me } = useGetMeData();

  const isCandidate = !!path(['candidateProfile', 'id'], me);
  const isEmployer = !!path(['employerProfile', 'id'], me);

  return (
    <>
      <FOHView
        style={{
          paddingTop: '60px'
        }}
      >
        <FOHNoResults
          noResultsHeader={
            type ? t(`${type}.noResultsHeader`) : t(`noResultsHeader`)
          }
          noResultsBody={t('noResultsBody')}
          noResultsButtonTitle={
            isCandidate
              ? t('candidate.noResultsButtonTitle')
              : isEmployer
              ? t('employer.noResultsButtonTitle')
              : t('noResultsButtonTitle')
          }
          noResultsButtonPress={() => {
            navigateTo(LOGIN_URL);
          }}
        />
      </FOHView>
    </>
  );
};

export default NotFound404;
