import React from 'react';

import { FOHLocationOverviewProps, FOHLocationOverview } from '../../recipes';
import {
  FullWidthCard,
  FOHButton,
  FOHHeaderH4,
  FOHSpace,
  FOHTouchableOpacity
} from '../../ingredients';

export interface FOHTopThreeLocationsListProps {
  locations: Array<FOHLocationOverviewProps>;
  tableTitle: string;
  onPressLocation: (id: string) => void;
  ctaText: string;
  onPressCTR: () => void;
  mobile?: boolean;
}

export const FOHTopThreeLocationsList = (
  props: FOHTopThreeLocationsListProps
) => (
  <FullWidthCard
    style={{
      padding: props.mobile ? 12 : 40,
      paddingTop: props.mobile ? 20 : 40,
      justifyContent: 'center'
    }}
  >
    <FOHHeaderH4 style={{ textAlign: 'left' }}>{props.tableTitle}</FOHHeaderH4>
    <FOHSpace />
    {props?.locations?.map((location: any, i: number) => (
      <FOHTouchableOpacity
        key={`location${i}`}
        onPress={() => location.slug && props.onPressLocation(location.slug)}
      >
        <FullWidthCard
          style={{
            paddingLeft: props.mobile ? 8 : 16,
            paddingRight: props.mobile ? 8 : 16,
            paddingTop: props.mobile ? 8 : 16
          }}
        >
          <FOHLocationOverview {...location} summary={''} />
        </FullWidthCard>
      </FOHTouchableOpacity>
    ))}
    <FOHSpace />
    <FOHButton
      onPress={props.onPressCTR}
      title={props.ctaText}
      fullWidth={true}
    />
  </FullWidthCard>
);
