import React from 'react';

import { View, ViewStyle, StyleProp } from 'react-native';
import styled from 'styled-components/native';

import {
  FOHSectionLabel,
  FOHAltSectionLabel,
  FOHAnswerBadgeProps,
  FOHAnswerBadge,
  SPACING_SCALE
} from '../../ingredients';
import { getShortId } from '../../utils';
import { FontProperty } from '../../templates';

export interface FOHAnswersTableProps {
  answersLabel: string;
  answers: FOHAnswerBadgeProps[];
  fontProperty?: FontProperty;
  useRegularLabel?: boolean;
  vertical?: boolean;
  style?: StyleProp<ViewStyle>;
}

export const FOHAnswersTable = ({
  answers,
  answersLabel,
  fontProperty,
  vertical,
  useRegularLabel,
  style
}: FOHAnswersTableProps) => {
  const LabelComp = useRegularLabel ? FOHSectionLabel : FOHAltSectionLabel;
  const validAnswers = answers?.filter((ans: FOHAnswerBadgeProps) =>
    Boolean(ans.displayLabel)
  );

  if (!validAnswers.length) return null;

  return (
    <View style={[{ width: '100%' }, style]}>
      <LabelComp
        style={{ marginBottom: SPACING_SCALE.larger, ...fontProperty }}
      >
        {answersLabel}
      </LabelComp>

      <AnswersContainer vertical={vertical}>
        {validAnswers.map(({ displayLabel, displayIcon }) => (
          <FOHAnswerBadge
            key={getShortId()}
            displayIcon={displayIcon}
            displayLabel={displayLabel}
            fontProperty={fontProperty}
            style={{
              marginBottom: SPACING_SCALE.larger * 1.5,
              flexBasis: vertical ? 'auto' : '50%',
              paddingRight: '2rem'
            }}
          />
        ))}
      </AnswersContainer>
    </View>
  );
};

interface AnswersContainerProp {
  vertical?: boolean;
}

const AnswersContainer = styled(View)<AnswersContainerProp>`
  flex-wrap: ${(props: AnswersContainerProp) =>
    props?.vertical ? '' : 'wrap'};
  flex-direction: ${(props: AnswersContainerProp) =>
    props?.vertical ? 'column' : 'row'};
`;
