import React from 'react';

import { View } from 'react-native';

import {
  FOHPlateIcon,
  FOHLabel,
  FOHTextButton,
  FOHHeaderText
} from '../../ingredients';

export interface FOHNoResultsProps {
  noResultsHeader: string;
  noResultsBody: string;
  noResultsButtonTitle: string;
  noResultsButtonPress: () => any;
}

export const FOHNoResults = (props: FOHNoResultsProps) => (
  <View
    style={{
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center'
    }}
  >
    <View
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: 400
      }}
    >
      <FOHPlateIcon />
      <View style={{ paddingTop: 24 }} />
      <FOHHeaderText
        style={{
          textAlign: 'center'
        }}
      >
        {props.noResultsHeader}
      </FOHHeaderText>
      <View style={{ paddingTop: 16 }} />
      <FOHLabel
        style={{
          textAlign: 'center'
        }}
      >
        {props.noResultsBody}
      </FOHLabel>
      <View style={{ paddingTop: 16 }} />
      <FOHTextButton
        style={{
          color: '#51C1DB'
        }}
        title={props.noResultsButtonTitle}
        onPress={props.noResultsButtonPress}
      />
    </View>
  </View>
);
