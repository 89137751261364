import { useState } from 'react';

import { useApolloClient } from '@apollo/client';
import { path, prop, defaultTo } from 'ramda';

import {
  handleMutation,
  browserTimezone,
  storeAuth,
  removeAuth
} from '../../utils';
import { useUpdateUserMutation } from '../../graphql/generated';

export const useSetValidEmail = () => {
  const [error, setError] = useState();
  const [userMutation] = useUpdateUserMutation();
  const client = useApolloClient();

  const setValidEmail = async ({
    id,
    firstName,
    lastName,
    phone,
    terms,
    password,
    email
  }) => {
    // this is not really a login or register, just a form to get indeed users to have real emails and phones
    // these users are already logged in with an indeedemail
    const [response, errors] = await handleMutation(
      userMutation({
        variables: {
          id,
          firstName,
          lastName,
          phone,
          terms,
          password,
          confirmPassword: password,
          email,
          timezone: browserTimezone
        }
      })
    );

    if (errors) {
      setError({
        password: prop('password', errors) || prop('non_field_errors', errors),
        // Global error on email
        email: defaultTo('', prop('message', errors)).includes(
          'Duplicate entry'
        )
          ? 'You already have an account. Please logout and sign in.'
          : prop('email', errors),
        ...errors
      });
      return false;
    }
    const authToken = path(['mutateUser', 'me', 'authorization'], response);
    if (authToken) {
      const auth = { token: authToken };
      await removeAuth();
      await storeAuth(auth);
      // refetch all queries
      await client.reFetchObservableQueries();
    }

    return true;
  };
  return { error, setValidEmail };
};
