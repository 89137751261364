export const EMPLOYER_GROUP = 'employers';
export const CANDIDATE_GROUP = 'candidates';

export enum GROUPS {
  candidates = 'candidates',
  employers = 'employers'
}

export const REGISTER_OPTIONS = ({ t }: any) => [
  {
    label: t('RegisterFeature:candidate'),
    value: GROUPS.candidates
  },
  {
    label: t('RegisterFeature:employer'),
    value: GROUPS.employers
  }
];
