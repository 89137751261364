import React from 'react';

import { BrowserRouter as Route, Routes } from 'react-router-dom';
import { length } from 'ramda';

import { useIsMobile } from '../../utils';
import {
  EXTERNAL_APPLICATION_WIDTH,
  EXTERNAL_APPLICATION_PADDINGTOP
} from '../../constants';
import { Screen } from '../../components';

import { useOnboardingState } from '../Navigation/useOnboardingState';

import ExternalApplicationSignUp from './ExternalApplicationSignUp';
import ExternalApplicationRole from './ExternalApplicationRole';
import ExternalApplicationSummary from './ExternalApplicationSummary';
import ExternalApplicationAvatarSelector from './ExternalApplicationAvatarSelector';

export const steps = [
  {
    title: 'signup',
    Component: ExternalApplicationSignUp,
    previous: false
  },
  {
    title: 'role',
    Component: ExternalApplicationRole,
    previous: false
  },
  {
    title: 'personalSummary',
    Component: ExternalApplicationSummary,
    previous: true
  },
  {
    title: 'avatarSeletor',
    Component: ExternalApplicationAvatarSelector,
    previous: true
  }
];

const CompleteApplicationRoutes = props => {
  return (
    <Routes>
      {steps.map((step, i) => (
        <Route path={`${props.match.path}/${i + 1}`} key={`${i}`}>
          <step.Component {...props} />
        </Route>
      ))}
    </Routes>
  );
};

const CompleteApplicationRouter = props => {
  const { isMobile } = useIsMobile();
  const { signUpFlow, stepIndex, nextStep, previousStep, finishOnboarding } =
    useOnboardingState(props, '/complete-application', `/home/applications`);

  return (
    <>
      <Screen
        style={{
          paddingTop: EXTERNAL_APPLICATION_PADDINGTOP,
          minHeight: '100vh',
          width: isMobile ? '100%' : EXTERNAL_APPLICATION_WIDTH,
          alignItems: 'center',
          margin: 'auto'
        }}
      >
        <CompleteApplicationRoutes
          {...props}
          key="routes"
          stepIndex={stepIndex}
          nextStep={nextStep}
          previousStep={previousStep}
          finishOnboarding={finishOnboarding}
          signUpFlow={signUpFlow}
          totalStepNumber={length(steps)}
          onSubmit={() => {
            stepIndex === length(steps) ? finishOnboarding() : nextStep();
          }}
        />
      </Screen>
    </>
  );
};

export default CompleteApplicationRouter;
