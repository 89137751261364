import React, { forwardRef } from 'react';

import { FOHColors, FOHInverseButton } from '../../components';

export const FOHPhotoInputButton = forwardRef(
  (
    { color, handleChange, icon, multiple = false, style, textColor, title },
    ref
  ) => (
    <>
      <input
        accept="image/jpeg, image/png, image/jpg, video/mp4"
        hidden
        multiple={multiple}
        onChange={handleChange}
        ref={ref}
        testID={`FOHPhotoInputButton-${title}`} //eslint-disable-line
        type="file"
      />

      <FOHInverseButton
        color={color || FOHColors.GRAYSCALE_700}
        icon={icon}
        onPress={() => {
          // eslint-disable-next-line no-unused-expressions
          ref.current?.click();
        }}
        opaque
        selected
        style={style}
        textColor={textColor || FOHColors.WHITE}
        title={title}
      />
    </>
  )
);
