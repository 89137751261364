import React from 'react';

import { getEndorsementsForHandle } from '../endorsements';
const { useReducer, useEffect } = React;

const endorsementsReducer = (state, action) => {
  switch (action.type) {
    case 'setEndorsements':
      return [...action.endorsements];
    case 'updateEndorsement':
      return [
        action.endorsement,
        ...state.filter(endorsement => endorsement.id !== action.endorsement.id)
      ];
    case 'removeEndorsement':
      return [
        ...state.filter(endorsement => endorsement.id !== action.endorsementId)
      ];
    case 'addEndorsement':
      return [action.endorsement, ...state];
    default:
      return [];
  }
};

const endorsementActions = endorsementsDispatch => ({
  setEndorsements: endorsements =>
    endorsementsDispatch({
      type: 'setEndorsements',
      endorsements: endorsements
    }),
  updateEndorsement: endorsement =>
    endorsementsDispatch({ type: 'updateEndorsement', endorsement }),
  removeEndorsement: endorsementId =>
    endorsementsDispatch({ type: 'removeEndorsement', endorsementId }),
  addEndorsement: endorsement =>
    endorsementsDispatch({ type: 'addEndorsement', endorsement })
});

export const withEndorsements = Cmp => props => {
  const [endorsements, endorsementsDispatch] = useReducer(
    endorsementsReducer,
    []
  );
  const actions = endorsementActions(endorsementsDispatch);
  useEffect(() => {
    props.handle &&
      getEndorsementsForHandle(props.handle).then(res => {
        actions.setEndorsements(res);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Cmp {...props} endorsements={endorsements} {...actions} />;
};
