import { Text, TextProps } from 'react-native';
import styled from 'styled-components/native';

import { FOHColors } from '../colors/FOHColors';

import { FOHFonts } from './FOHFonts';

// https://github.com/necolas/react-native-web/blob/master/packages/docs/src/guides/accessibility.stories.mdx
// accessibilityRole: 'heading' Aria level prop will render comp as h1 on web etc..
export const FOHHeaderText = styled(Text).attrs((props: any) => ({
  ...props,
  accessibilityRole: 'heading'
}))<TextProps>`
  font-family: ${FOHFonts.MEDIUM};
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 130%;
  text-align: center;
  color: ${FOHColors.GRAYSCALE_900};
`;

export const FOHHeaderH1 = styled(Text).attrs((props: any) => ({
  ...props,
  accessibilityRole: 'heading'
}))<TextProps>`
  font-family: ${FOHFonts.MEDIUM};
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: ${FOHColors.GRAYSCALE_900};
`;

export const FOHHeaderH2 = styled(FOHHeaderH1).attrs((props: any) => ({
  ...props,
  accessibilityRole: 'heading',
  'aria-level': '2'
}))<TextProps>`
  font-family: ${FOHFonts.MEDIUM};
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: ${FOHColors.GRAYSCALE_900};
`;

export const FOHHeaderH3 = styled(FOHHeaderH1).attrs((props: any) => ({
  ...props,
  accessibilityRole: 'heading',
  'aria-level': '3'
}))<TextProps>`
  font-size: 18px;
  line-height: 24px;
  color: ${FOHColors.GRAYSCALE_900};
`;

export const FOHHeaderH4 = styled(FOHHeaderH1).attrs((props: any) => ({
  ...props,
  accessibilityRole: 'heading',
  'aria-level': '4'
}))<TextProps>`
  font-size: 14px;
  line-height: 16px;
  color: ${FOHColors.GRAYSCALE_900};
`;
