import React from 'react';

import { Helmet } from 'react-helmet';

import { FOHScrollView, FOHView } from '../../../components';

import { CandidatesMobileList } from './CandidatesMobileList';
import { MobileSearchAndFilters } from './MobileSearchAndFilters';

export const CandidateNetworkMobileScreen = props => {
  const { candidates } = props;
  //this is related to only for the style that are above this component level.
  const MobileStyle = () => (
    <Helmet>
      <style type="text/css">
        {`
        body {
        min-height:90% !important
        }
      `}
      </style>
    </Helmet>
  );

  return (
    <FOHScrollView>
      <MobileStyle />
      <FOHView style={{ marginTop: 50 }}>
        <MobileSearchAndFilters />
        <CandidatesMobileList candidates={candidates} />
      </FOHView>
    </FOHScrollView>
  );
};
