import React, { useState, useRef, useEffect } from 'react';

import { TouchableOpacity } from 'react-native';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components/native';
import { useHover } from 'react-native-web-hooks';
import { length } from 'ramda';

import { EMPLOYER_SETTINGS_MENU } from '../../../constants';

import {
  FOHView,
  FOHColors,
  FOHLabel,
  FOHLabelBold,
  FOHSpaceSmallest,
  FOHSpace,
  FOHSpaceSmall,
  FOHExpandedIcon,
  FOHFullLogo,
  FOHLogo,
  FOHSearch,
  FOHDivider,
  FOHSmallDetailLabel
} from '../../ingredients';
import { FOHProfileImage } from '../../recipes';

import { FOHSearchBarProps, FOHSearchBar } from '../search/FOHSearchBar';

import { FOHAddNewButtonProps } from './FOHAddNewButton';

interface FOHSideNavItemProps {
  title: string;
  icon: any;
  selectedIcon?: any;
  route: string;
  testID?: string;
  disabled?: boolean;
}

interface FOHSideNavItemProperties extends FOHSideNavItemProps {
  selected: boolean;
  onPress?: (route: string, title: string) => void;
}

export interface FOHSidebarNavProps
  extends FOHSearchBarProps,
    FOHAddNewButtonProps {
  children?: any;
  isExpanded: boolean;
  setIsExpanded: (val: boolean) => any;
  sideNavItems?: Array<FOHSideNavItemProps>;
  currentRoute: string;
  setCurrentRoute: (route: string, title: string, groupName?: string) => void;
  settingsLabel: string;
  name: string;
  email: string;
  photo?: string;
  onPressProfilePhoto: () => any;
  profileOpen?: boolean;
  onPressSettings: () => any;
  inbox: any;
  testID?: string;
  bottomNavItems?: Array<FOHSideNavItemProperties>;
}

const WrapperView = styled(FOHView)`
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: space-between;
`;

const FOHLogoContainer = styled(FOHView)``;

const ItemContainer = styled(FOHView)`
  justify-content: center;
  align-items: center;
`;

const ExpandItemWrapper = styled(FOHView)`
  padding-horizontal: 12px;
  padding-vertical: 10px;
  flex-direction: row;
  width: 100%;
  align-items: center;
  border-radius: 4px;
  justify-content: space-between;
`;

const ItemWrapper = styled(ExpandItemWrapper)``;

const SearchContainer = styled(FOHView)`
  background: ${FOHColors.WHITE};
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 12px;
  justify-content: center;
  align-items: center;
`;

const ExpandContainerView = animated(styled(FOHView)`
  width: 100%;
  max-width: 240px;
  height: 100vh;
  background-color: ${FOHColors.WHITE};
`);

const ExpandItemContainer = styled(FOHView)`
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Label = styled(FOHSmallDetailLabel)`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: ${FOHColors.CYBERSPACE_GREY};
`;

const AnimatedDonut = animated(FOHView);

const FOHSideNavItem = (props: FOHSideNavItemProperties) => {
  const { title, icon, selected, route, onPress } = props;
  const itemRef = useRef(null);
  const isItemHover = useHover(itemRef);
  const IconToRender =
    (isItemHover || selected) && props.selectedIcon && !props?.disabled
      ? props.selectedIcon
      : icon;
  const ItemLabel = selected ? FOHLabelBold : Label;

  return (
    <TouchableOpacity
      ref={itemRef}
      onPress={() => {
        onPress && onPress(route, title);
      }}
      testID={props.testID}
      disabled={props?.disabled}
    >
      <ExpandItemContainer
        style={{ paddingHorizontal: 12, width: 240, paddingVertical: 4 }}
      >
        <ExpandItemWrapper
          style={{
            justifyContent: 'flex-start',
            backgroundColor: props?.disabled
              ? ''
              : isItemHover || selected
              ? FOHColors.PRIMARY_TEAL_10
              : ''
          }}
        >
          <IconToRender
            style={{
              tintColor: props?.disabled
                ? FOHColors.GRAYSCALE_300
                : isItemHover || selected
                ? FOHColors.PRIMARY_TEAL_P1
                : FOHColors.GRAYSCALE_300,
              height: 24,
              width: 24
            }}
          />
          <FOHSpaceSmall />
          <ItemLabel
            style={{
              fontSize: 14,
              lineHeight: 24,
              color: props?.disabled
                ? FOHColors.GRAYSCALE_400
                : FOHColors.CYBERSPACE_GREY
            }}
          >
            {title}
          </ItemLabel>
        </ExpandItemWrapper>
      </ExpandItemContainer>
    </TouchableOpacity>
  );
};

interface FOHSideNavItemCollapsedProps extends FOHSideNavItemProperties {
  isExpanded: boolean;
  setIsExpanded: (val: boolean) => any;
}

const FOHSideNavItemCollapsed = (props: FOHSideNavItemCollapsedProps) => {
  const { icon, selected, route, onPress, title } = props;
  const itemRef = useRef(null);
  const isItemHover = useHover(itemRef);
  const IconToRender =
    (isItemHover || selected) && props.selectedIcon && !props?.disabled
      ? props.selectedIcon
      : icon;

  return (
    <TouchableOpacity
      ref={itemRef}
      onPress={() => {
        onPress && onPress(route, title);
      }}
      disabled={props?.disabled}
    >
      <ItemContainer>
        <ItemWrapper
          style={{
            backgroundColor: props?.disabled
              ? ''
              : isItemHover || selected
              ? FOHColors.PRIMARY_TEAL_10
              : '',
            borderRadius: 6
          }}
        >
          <IconToRender
            style={{
              tintColor: props?.disabled
                ? FOHColors.GRAYSCALE_200
                : isItemHover || selected
                ? FOHColors.PRIMARY_TEAL_P1
                : FOHColors.GRAYSCALE_300,
              height: 24,
              width: 24
            }}
          />
        </ItemWrapper>
      </ItemContainer>
      <FOHSpaceSmall />
    </TouchableOpacity>
  );
};

export const FOHSidebarNav = (props: FOHSidebarNavProps) => {
  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);
  // settings expanded
  const [openSettings, setOpenSettings] = useState(false);

  useEffect(() => {
    if (isHovered) {
      props.setIsExpanded(true);
      return;
    } else {
      props.isExpanded
        ? setTimeout(() => props.setIsExpanded(false), 1000)
        : undefined;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHovered]);

  useEffect(() => {
    if (!props.isExpanded) {
      // close settigns when nav closes
      setOpenSettings(false);
    }
  }, [props.isExpanded]);

  const [_, setIsAnimated] = useState(false);

  const spring = useSpring({
    from: { width: 240 },
    to: { width: !props.isExpanded ? 74 : 240 },
    config: { duration: 100 },
    onStart: () => {
      setIsAnimated(true);
    },
    onRest: () => {
      setIsAnimated(false);
    }
  });

  const finishedAnimating =
    spring?.width?.get() === (spring?.width?.goal || 240);

  return (
    <ExpandContainerView
      style={{
        ...spring,
        maxWidth: 240,
        borderWidth: 1,
        borderColor: FOHColors.BORDER_GRAY,
        overflowY: 'auto',
        overflowX: 'hidden',
        zIndex: 180
      }}
      ref={hoverRef}
      testID={props.isExpanded ? 'nav-expanded' : 'nav-collapsed'}
    >
      <FOHView>
        <WrapperView>
          {props.isExpanded ? (
            <>
              <FOHView>
                <FOHView
                  style={{
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    width: '100%',
                    position: 'sticky',
                    top: 0,
                    zIndex: 9,
                    backgroundColor: FOHColors.WHITE
                  }}
                  testID="nav-expanded"
                >
                  <ExpandItemContainer>
                    <ExpandItemWrapper
                      style={{
                        paddingBottom: 14,
                        paddingHorizontal: 20,
                        paddingTop: 20
                      }}
                    >
                      <FOHFullLogo />
                      <TouchableOpacity
                        onPress={() =>
                          props.isExpanded && props.setIsExpanded
                            ? props.setIsExpanded(false)
                            : undefined
                        }
                      >
                        <FOHExpandedIcon />
                      </TouchableOpacity>
                    </ExpandItemWrapper>
                    {props.hideSearch ? (
                      <></>
                    ) : (
                      <FOHView style={{ paddingLeft: 8, paddingRight: 8 }}>
                        <FOHSearchBar {...props} />
                        <FOHSpaceSmall />
                      </FOHView>
                    )}
                    <FOHDivider style={{ maxWidth: 208 }} />
                    <FOHSpaceSmallest />
                  </ExpandItemContainer>
                </FOHView>
                <FOHView
                  style={{
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    width: '100%'
                  }}
                >
                  {props.sideNavItems && length(props.sideNavItems) > 0 ? (
                    props.sideNavItems.map(item => (
                      <FOHSideNavItem
                        {...item}
                        key={item.title}
                        title={finishedAnimating ? item.title : ''}
                        onPress={route =>
                          props.setCurrentRoute(route, item?.title)
                        }
                        selected={props.currentRoute === item.route}
                        testID={`sideNav-${item.title}`}
                      />
                    ))
                  ) : (
                    <></>
                  )}
                </FOHView>
                {props?.sideNavItems && length(props.sideNavItems) !== 0 ? (
                  <FOHView
                    style={{
                      alignItems: 'center',
                      width: '100%'
                    }}
                  >
                    <FOHSpaceSmall />
                    <FOHDivider style={{ maxWidth: 208 }} />
                    <FOHSpaceSmall />
                  </FOHView>
                ) : null}
                <FOHView
                  style={{
                    alignItems: 'flex-start',
                    width: '100%',
                    justifyContent: 'flex-start'
                  }}
                >
                  <ExpandItemContainer
                    style={{ paddingHorizontal: 12, width: 240 }}
                  >
                    {props.inbox ? (
                      <ExpandItemWrapper
                        style={{
                          minHeight: 44,
                          paddingHorizontal: 0,
                          paddingVertical: 4
                        }}
                      >
                        {props.inbox()}
                      </ExpandItemWrapper>
                    ) : (
                      <></>
                    )}
                    <>
                      {props.bottomNavItems &&
                      length(props.bottomNavItems) > 0 ? (
                        props.bottomNavItems.map(item => {
                          const isEmployerSettings =
                            props.currentRoute.startsWith(
                              EMPLOYER_SETTINGS_MENU
                            );

                          const isSelected = isEmployerSettings
                            ? item.route.startsWith(EMPLOYER_SETTINGS_MENU)
                            : props.currentRoute === item.route;

                          return (
                            <FOHSideNavItem
                              {...item}
                              key={item.title}
                              title={finishedAnimating ? item.title : ''}
                              onPress={route => {
                                if (item.onPress)
                                  item.onPress &&
                                    item.onPress(route, item?.title);
                                else props.setCurrentRoute(route, item?.title);
                              }}
                              selected={isSelected}
                              testID={`sideNav-${item.title}`}
                            />
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </>
                  </ExpandItemContainer>
                </FOHView>
              </FOHView>
              <FOHView
                style={{
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  width: '100%',
                  zIndex: 77,
                  backgroundColor: FOHColors.WHITE,
                  position: openSettings ? 'absolute' : 'relative',
                  bottom: 0,
                  shadowColor: openSettings ? FOHColors.BLACK : 'transparent',
                  shadowOpacity: openSettings ? 0.2 : 0,
                  shadowOffset: { width: 0, height: -4 },
                  shadowRadius: 6
                }}
              >
                <ExpandItemContainer
                  style={{
                    backgroundColor: FOHColors.GRAYSCALE_10,
                    justifyContent: 'flex-start'
                  }}
                >
                  <ExpandItemWrapper
                    style={{
                      borderRadius: 0,
                      backgroundColor: FOHColors.GRAYSCALE_10,
                      paddingTop: 12,
                      paddingBottom: 4,
                      paddingHorizontal: 15,
                      borderTopColor: FOHColors.GRAYSCALE_200,
                      borderTopWidth: 1
                    }}
                  >
                    <ExpandItemWrapper
                      style={{
                        justifyContent: 'flex-start',
                        padding: 0,
                        width: '100%',
                        maxWidth: 200
                      }}
                    >
                      <AnimatedDonut
                        style={{
                          borderWidth: 1,
                          borderColor: FOHColors.PRIMARY_TEAL_400,
                          borderRadius: '50%',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: 38,
                          height: 38
                        }}
                      >
                        <FOHProfileImage photo={props.photo} key="photo" />
                      </AnimatedDonut>
                      <FOHView
                        style={{ flex: 1, marginLeft: 8, flexWrap: 'wrap' }}
                      >
                        <FOHLabel
                          numberOfLines={1}
                          style={{
                            fontSize: 14,
                            lineHeight: 20,
                            maxWidth: 127,
                            color: FOHColors.GRAYSCALE_900
                          }}
                          bold
                        >
                          {props.name}
                        </FOHLabel>
                        <FOHSmallDetailLabel
                          style={{
                            fontSize: 12,
                            lineHeight: 16,
                            textOverflow: 'ellipsis',
                            maxWidth: '100%',
                            overflowWrap: 'normal',
                            overflow: 'hidden',
                            color: FOHColors.GRAYSCALE_700
                          }}
                        >
                          {props.email}
                        </FOHSmallDetailLabel>
                      </FOHView>
                    </ExpandItemWrapper>
                  </ExpandItemWrapper>
                  <>{props.children}</>
                </ExpandItemContainer>
              </FOHView>
            </>
          ) : (
            <>
              <FOHView style={{ alignItems: 'center' }}>
                <ItemContainer style={{ paddingTop: 20, paddingBottom: 14 }}>
                  <FOHLogoContainer>
                    <FOHLogo />
                  </FOHLogoContainer>
                </ItemContainer>
                <>
                  {props.hideSearch ? (
                    <></>
                  ) : (
                    <>
                      <ItemContainer style={{ padding: 0 }}>
                        <SearchContainer>
                          <FOHSearch />
                        </SearchContainer>
                      </ItemContainer>
                      <FOHSpace />
                    </>
                  )}
                </>
                <FOHDivider style={{ maxWidth: 42 }} />
                <FOHSpaceSmall />
                <FOHView>
                  {props.sideNavItems && length(props.sideNavItems) > 0 ? (
                    props.sideNavItems.map(item => (
                      <FOHSideNavItemCollapsed
                        {...item}
                        key={item.title}
                        title={item.title}
                        onPress={props.setCurrentRoute}
                        selected={props.currentRoute === item.route}
                        isExpanded={props.isExpanded && props.isExpanded}
                        setIsExpanded={
                          props.setIsExpanded && props.setIsExpanded
                        }
                      />
                    ))
                  ) : (
                    <></>
                  )}
                </FOHView>
                <FOHView style={{ alignItems: 'center' }}>
                  {props?.sideNavItems && length(props.sideNavItems) !== 0 ? (
                    <>
                      <FOHDivider style={{ maxWidth: 42 }} />
                      <FOHSpaceSmall />
                    </>
                  ) : null}
                  <FOHView style={{ alignItems: 'center' }}>
                    {props.inbox ? (
                      <ItemContainer>
                        <ItemWrapper
                          style={{
                            paddingHorizontal: 0,
                            paddingVertical: 4
                          }}
                        >
                          {props.inbox()}
                        </ItemWrapper>
                        <FOHSpaceSmall />
                      </ItemContainer>
                    ) : (
                      <></>
                    )}
                    {props.bottomNavItems &&
                    length(props.bottomNavItems) > 0 ? (
                      props.bottomNavItems.map(item => {
                        const isEmployerSettings =
                          props.currentRoute.startsWith(EMPLOYER_SETTINGS_MENU);

                        const isSelected = isEmployerSettings
                          ? item.route.startsWith(EMPLOYER_SETTINGS_MENU)
                          : props.currentRoute === item.route;

                        return (
                          <FOHSideNavItemCollapsed
                            {...item}
                            key={item.title}
                            title={item.title}
                            onPress={route => {
                              if (item.onPress)
                                item.onPress &&
                                  item.onPress(route, item?.title);
                              else props.setCurrentRoute(route, item?.title);
                            }}
                            selected={isSelected}
                            isExpanded={props.isExpanded && props.isExpanded}
                            setIsExpanded={
                              props.setIsExpanded && props.setIsExpanded
                            }
                          />
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </FOHView>
                </FOHView>
              </FOHView>
              <FOHView style={{ alignItems: 'center' }}>
                <TouchableOpacity onPress={props.onPressProfilePhoto}>
                  <ItemContainer
                    style={{
                      backgroundColor: '#FCFCFC',
                      padding: 15,
                      borderTopColor: '#E8E8E8',
                      borderTopWidth: 1
                    }}
                  >
                    <AnimatedDonut
                      style={{
                        borderWidth: 1,
                        borderColor: FOHColors.PRIMARY_TEAL_400,
                        borderRadius: '50%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: 38,
                        height: 38
                      }}
                    >
                      <FOHProfileImage photo={props.photo} key="photo" />
                    </AnimatedDonut>
                  </ItemContainer>
                </TouchableOpacity>
                <FOHSpace />
              </FOHView>
            </>
          )}
        </WrapperView>
      </FOHView>
    </ExpandContainerView>
  );
};
