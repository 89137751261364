import React, { FunctionComponent } from 'react';

import { StyleProp, ViewStyle } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import styled from 'styled-components/native';
import { compose } from 'ramda';

import {
  FOHColors,
  FOHExpandIcon,
  FOHFonts,
  FOHView,
  withError,
  withPlaceholderText
} from '../../ingredients';
import { FOH_INPUT_HEIGHT } from '../../constants';

interface SelectProps {
  error?: any;
  small?: any;
  disabled?: boolean;
}

export const SelectContainer = styled(FOHView)`
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

const Select = styled(Picker)`
  background: ${(props: SelectProps) =>
    props.disabled ? FOHColors.GRAYSCALE_100 : FOHColors.WHITE};
  border: ${(props: SelectProps) =>
    props.error
      ? `1px solid ${FOHColors.RED_300}`
      : props.disabled
      ? `${FOHColors.GRAYSCALE_200}`
      : `1px solid ${FOHColors.GRAYSCALE_300}`};
  border-radius: 4px;
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 200%;
  display: flex;
  align-items: center;
  width: 100%;
  height: ${FOH_INPUT_HEIGHT}px;
  padding: 10px;
  -webkit-appearance: none;
`;

export interface FOHSelectItemProps {
  label: string;
  value: string;
  description?: string;
  testID?: string;
}

export const FOHSelectItem = styled(Picker.Item)``;
interface FOHSelectProps extends SelectProps {
  children: Array<any>;
  selectedValue: any;
  onValueChange: (value: any) => void;
  placeholder: string;
  style?: StyleProp<ViewStyle>;
  neverEmpty?: boolean;
  testID?: string;
  disabled?: boolean;
}

const FOHSelectInput = (props: FOHSelectProps) => {
  return (
    <FOHView style={{ width: '100%' }}>
      <SelectContainer>
        <Select
          style={{ ...(props.style as ViewStyle) }}
          selectedValue={props.selectedValue}
          onValueChange={props.onValueChange}
          error={props.error}
          disabled={props.disabled}
          small={props.small ? 1 : 0}
          testID={props.testID}
        >
          {!props.neverEmpty ? (
            <FOHSelectItem label={props.placeholder} value="" />
          ) : (
            <></>
          )}
          {props.children}
        </Select>
        <FOHView
          pointerEvents="none"
          style={{
            right: 8,
            position: 'absolute'
          }}
        >
          <FOHExpandIcon
            style={{
              tintColor: props.error
                ? FOHColors.RED_300
                : props.disabled
                ? FOHColors.GRAYSCALE_400
                : FOHColors.GRAYSCALE_500
            }}
          />
        </FOHView>
      </SelectContainer>
    </FOHView>
  );
};

export const FOHSelect: FunctionComponent<FOHSelectProps> = compose(
  withError,
  withPlaceholderText
)(FOHSelectInput);
