import { useEffect } from 'react';

import { any } from 'ramda';

const containsTrue = any(i => !!i);

export const useDisableBackgroundScroll = (show = []) => {
  const disableBackgroundScroll = containsTrue(show);
  useEffect(() => {
    if (disableBackgroundScroll) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [disableBackgroundScroll]);
  return undefined;
};
