import React from 'react';

import video_play_grey from '../../../images/video_play.svg';

import { FOHImage } from '../../ingredients';

export interface FOHGrayCirclePlayIconProps {
  style?: any;
}

export const FOHGrayCirclePlayIcon = (props: FOHGrayCirclePlayIconProps) => (
  <FOHImage
    source={{ uri: video_play_grey }}
    style={{ width: 30, height: 30, ...props.style }}
  />
);
