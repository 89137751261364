import React from 'react';

import ic_print from '../../../images/ic_print.svg';

import { FOHImage } from '../../ingredients';

export const FOHPrintIcon = (props: any) => (
  <FOHImage
    source={{ uri: ic_print }}
    style={
      props && !!props.style
        ? { width: 20, height: 20, ...props.style }
        : { width: 20, height: 20 }
    }
  />
);
