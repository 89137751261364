import React from 'react';

import styled from 'styled-components/native';

import { FOHProfileImage } from '../../recipes';
import {
  FOHColors,
  FOHView,
  FOHProfilePhotoIcon,
  FOHInverseButton,
  withError,
  FOHTouchableOpacity,
  FOHHeaderH3
} from '../../ingredients';

export interface FOHPhotoInputProps {
  pickPhoto: () => void;
  fullName?: string;
  photo: string | undefined;
  uploadLabel: string;
  isEmployer: boolean | undefined;
}

const PhotoContainer = styled(FOHView)`
  background: ${FOHColors.WHITE};
  border: 1px solid ${FOHColors.BORDER_GRAY};
  border-radius: 4px;
  align-items: center;
  justify-content: left;
  padding-left: 24px;
  width: 327px;
  height: 108px;
  flex-direction: row;
`;

const PhotoInput = (props: FOHPhotoInputProps) => (
  <>
    <FOHTouchableOpacity onPress={props.pickPhoto}>
      <PhotoContainer>
        {props.photo ? (
          <FOHProfileImage
            photo={props.photo}
            style={{ width: 72, height: 72 }}
          />
        ) : (
          <FOHProfilePhotoIcon />
        )}
        <FOHView style={{ paddingLeft: 13 }} />
        <FOHHeaderH3 numberOfLines={1}>{props.fullName}</FOHHeaderH3>
      </PhotoContainer>
    </FOHTouchableOpacity>
    <FOHView style={{ paddingTop: 18 }} />
    <FOHTouchableOpacity onPress={props.pickPhoto}>
      <FOHInverseButton
        onPress={props.pickPhoto}
        title={props.uploadLabel}
        color={FOHColors.BLACK}
      />
    </FOHTouchableOpacity>
  </>
);

export const FOHPhotoInput = withError(PhotoInput);
