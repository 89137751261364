import React, { useState } from 'react';

import { prop, toLower } from 'ramda';

import {
  FOHView,
  FOHSectionLabel,
  FOHLocationPin,
  FOHTextField,
  FOHPhoneInput,
  FOHTimeSelect,
  FOHSpace,
  FOHColors,
  FOHSpaceSmall,
  FOHSpaceSmallest
} from '../../ingredients';

import { FOHSelectItemProps } from '../select/FOHSelect';
import { FOHScheduleFormTitleBlock } from '../blocks/FOHScheduleFormTitleBlock';
import { FOHSearchableSelectDropdown } from '../select/FOHSearchableSelectDropdown';
import { FOHDropdownSelectOption } from '../select/FOHBorderlessSelectDropdown';

export interface FOHWelcomeLetterFormProps {
  children?: React.ReactNode;
  titleLabel: string;
  candidateName: string;
  detailLabel: string;
  decorationImage: string;
  locationSubtitleLabel: string;

  style?: any;
  searchPlaceholder?: string;
  locationSelectedLabel?: string;
  locationSecondarySelectedText?: string;

  locationIcon?: any;
  locationOptions: Array<FOHDropdownSelectOption>;
  selectedLocation: string;
  selectedLocationLabel?: string;
  onLocationValueChange: (value: string) => void;
  locationLabel?: string;

  positionOptions: Array<FOHDropdownSelectOption>;
  selectedPosition: string;
  selectedPositionLabel?: string;
  onPositionValueChange: (value: string) => void;
  positionLabel?: string;

  candidateSectionLabel: string;
  time: string;
  setTime: (time?: any) => void;

  calendarCmp?: any;

  timePlaceholder: string;
  timeChoices: Array<FOHSelectItemProps>;

  contactSectionLabel: string;
  name: string;
  setName: (value: string) => void;
  namePlaceholder: string;

  phone: string;
  setPhone: (value: string) => void;
  phonePlaceholder: string;

  isMobile?: boolean;
  error?: any;
  hideLocationSelection?: boolean;
  hideHeaderBlock?: boolean;

  documentSectionLabel?: string;
  documentsUpload?: React.ReactNode;
}

export const FOHWelcomeLetterForm = ({
  locationOptions = [],
  positionOptions = [],
  ...props
}: FOHWelcomeLetterFormProps) => {
  const [locationOpen, setLocationOpen] = useState(false);
  const [positionOpen, setPositionOpen] = useState(false);
  const [locationText, setLocationText] = useState('');
  const [positionText, setPositionText] = useState('');

  locationOptions = locationOptions.filter(
    loc =>
      !locationText ||
      toLower(prop('label', loc)).includes(toLower(locationText)) ||
      toLower(prop('sublabel', loc)).includes(toLower(locationText))
  );

  positionOptions = positionOptions.filter(
    pos =>
      !positionText ||
      toLower(prop('label', pos)).includes(toLower(positionText)) ||
      toLower(prop('sublabel', pos)).includes(toLower(positionText))
  );

  return (
    <FOHView style={{ maxWidth: 716 }}>
      {!props.hideHeaderBlock ? (
        <FOHScheduleFormTitleBlock
          titleLabel={props.titleLabel}
          candidateName={props.candidateName}
          detailString={props.detailLabel}
          decorationImage={props.decorationImage}
        />
      ) : (
        <></>
      )}

      <FOHView>
        {!props.hideLocationSelection ? (
          <FOHView style={{ zIndex: 3 }}>
            <FOHSectionLabel>{props.locationSubtitleLabel}</FOHSectionLabel>
            <FOHView style={{ marginVertical: 5, zIndex: 4 }}>
              <FOHSearchableSelectDropdown
                open={locationOpen}
                setOpen={setLocationOpen}
                selectedValue={props.selectedLocation}
                selectedLabel={props.selectedLocationLabel}
                onSearchChange={setLocationText}
                searchValue={locationText}
                searchPlaceholder={props.searchPlaceholder}
                onValueChange={(value: string) =>
                  props.onLocationValueChange(value)
                }
                options={locationOptions}
                fieldLabel={props.locationLabel}
                icon={FOHLocationPin}
                style={{ width: '100%' }}
              ></FOHSearchableSelectDropdown>
            </FOHView>
            <FOHView style={{ marginTop: 5, marginBottom: 16, zIndex: 3 }}>
              <FOHSearchableSelectDropdown
                open={positionOpen}
                setOpen={setPositionOpen}
                selectedValue={props.selectedPosition}
                selectedLabel={props.selectedPositionLabel}
                onSearchChange={setPositionText}
                searchValue={positionText}
                searchPlaceholder={props.searchPlaceholder}
                onValueChange={(value: string) => {
                  props.onPositionValueChange(value);
                }}
                options={positionOptions}
                fieldLabel={props.positionLabel}
                icon={FOHLocationPin}
                style={{ width: '100%' }}
              ></FOHSearchableSelectDropdown>
            </FOHView>
          </FOHView>
        ) : (
          <></>
        )}
        <FOHSectionLabel
          style={{
            lineHeight: 16,
            color: FOHColors.PRIMARY_TEAL_P1,
            textTransform: 'uppercase'
          }}
        >
          {props.candidateSectionLabel}
        </FOHSectionLabel>
        <FOHView
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            marginVertical: 5,
            zIndex: 2
          }}
        >
          <FOHView style={{ width: '48%' }}>{props.calendarCmp}</FOHView>
          <FOHSpace />
          <FOHView style={{ width: '49%' }}>
            <FOHTimeSelect
              setTime={props.setTime}
              time={props.time}
              placeholder={props.timePlaceholder}
              timeChoices={props.timeChoices}
              width={'50%'}
            />
          </FOHView>
        </FOHView>
        <FOHSpace />
        <FOHSectionLabel
          style={{
            lineHeight: 16,
            color: FOHColors.PRIMARY_TEAL_P1,
            textTransform: 'uppercase'
          }}
        >
          {props.contactSectionLabel}
        </FOHSectionLabel>
        <FOHView
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            marginVertical: 5,
            zIndex: 1
          }}
        >
          <FOHView style={{ width: '48%' }}>
            <FOHTextField
              onChangeText={props.setName}
              value={props.name}
              placeholder={props.namePlaceholder}
            />
          </FOHView>
          <FOHSpace />
          <FOHView style={{ width: '30%' }}>
            <FOHPhoneInput
              onChangeText={(text, _rawText) => {
                props.setPhone(text);
              }}
              value={props.phone}
              placeholder={props.phonePlaceholder}
              error={
                props.error && (props.error.phoneNumber || props.error.phone)
                  ? props.error.phoneNumber || props.error.phone
                  : null
              }
            />
          </FOHView>
        </FOHView>
        <FOHSpace />
        <FOHView>{props.children}</FOHView>
        <FOHSpace />
        <FOHSpaceSmallest />
        <>
          {props.documentSectionLabel && props.documentsUpload ? (
            <>
              <FOHSectionLabel
                style={{
                  lineHeight: 16,
                  color: FOHColors.PRIMARY_TEAL_P1,
                  textTransform: 'uppercase'
                }}
              >
                {props.documentSectionLabel}
              </FOHSectionLabel>
              <FOHSpaceSmall />
              <FOHView
                style={{
                  marginBottom: 5
                }}
                testID={'FOHWelcomeLetterForm-dropzone'}
              >
                {props.documentsUpload}
              </FOHView>
            </>
          ) : null}
        </>
      </FOHView>
    </FOHView>
  );
};
