import { isBrowser } from './sessionStorage';

export const get = (key, isObject = true) => {
  if (!isBrowser || !localStorage) {
    console.log('No local storage getItem');
    return;
  }
  const data = localStorage.getItem(key);

  if (data && isObject && data !== 'undefined') {
    console.log('Auth is retrieved.');
    return JSON.parse(data);
  }
  return data;
};

export const store = (key, data, isObject = true) => {
  if (!isBrowser || !localStorage) {
    console.log('No local storage setItem');
    return;
  }
  if (isObject) {
    data = JSON.stringify(data);
  }
  localStorage.setItem(key, data);
  return data;
};

export const remove = key => {
  if (!isBrowser || !localStorage) {
    console.log('No local storage removeItem');
    return;
  }
  localStorage.removeItem(key);
};

// Authentication
export const getAuth = () => get('auth');
export const storeAuth = auth => store('auth', auth);
export const removeAuth = () => remove('auth');

// only call getUser and Store user from the HOC for current user
export const getUser = () => get('user');
export const storeUser = user => store('user', user);
export const removeOnboardingKeys = () => {
  remove('onboarding-position');
  remove('employer-onboarding-state');
};
export const getOnboardingState = () => get('employer-onboarding-state');

// Warnings
const DISMISSED_WARNINGS_KEY = 'dismissedWarnings';

export const getDismissedWarnings = () => {
  const dismissedWarnings =
    JSON.parse(localStorage.getItem(DISMISSED_WARNINGS_KEY)) || [];
  return dismissedWarnings;
};

export const checkIfWarningIsDismissedByKey = warningKey => {
  const dismissedWarnings = getDismissedWarnings();
  return dismissedWarnings.includes(warningKey);
};

export const storeDismissedWarnings = warningKey => {
  let dismissedWarnings = getDismissedWarnings();

  if (!dismissedWarnings.includes(warningKey)) {
    dismissedWarnings.push(warningKey);
    localStorage.setItem(
      DISMISSED_WARNINGS_KEY,
      JSON.stringify(dismissedWarnings)
    );
  }
};

export const removeDismissedWarningByKey = warningKey => {
  let dismissedWarnings = getDismissedWarnings();

  const index = dismissedWarnings.indexOf(warningKey);
  if (index !== -1) {
    dismissedWarnings.splice(index, 1);
    localStorage.setItem(
      DISMISSED_WARNINGS_KEY,
      JSON.stringify(dismissedWarnings)
    );
  }
};

export const removeAllDismissedWarnings = () => remove(DISMISSED_WARNINGS_KEY);
