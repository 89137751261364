import React from 'react';

import messageFormSurvey from '../../../images/message_form_survey.svg';

import { FOHImage } from '../../ingredients';

export const FOHMessageFormSurveyIcon = (props: any) => (
  <FOHImage
    source={{ uri: messageFormSurvey }}
    style={
      props && !!props.style
        ? { width: 24, height: 24, ...props.style }
        : { width: 24, height: 24 }
    }
  />
);
