import { path } from 'ramda';

export const scrollToTop = ({ smooth = true }) => {
  if (window && window.scroll) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: smooth ? 'smooth' : 'auto'
    });
  }
};

export const scrollToRef = ref => {
  if (window) {
    window.scroll({
      top: path(['current', 'offsetTop'], ref),
      left: 0,
      behavior: 'smooth'
    });
  }
};
