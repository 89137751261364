import React from 'react';

import chevron_up from '../../../images/back_to_top.svg';

import { FOHImage } from '../../ingredients';

export const FOHBackToTopIcon = (props: any) => (
  <FOHImage
    source={{ uri: chevron_up }}
    style={{
      width: props?.style?.width || 24,
      height: props?.style?.height || 24,
      ...props?.style
    }}
  />
);
