import React from 'react';

import styled from 'styled-components/native';
import { path } from 'ramda';

import { FOHHeaderH1, FOHSmallDetailLabel, FOHView } from '../../ingredients';

import { FOHSelectItemProps } from '../select/FOHSelect';
import { FOHSelectionCell } from '../cells/FOHSelectionCell';

export interface FOHRadioQuestionOptionProps {
  id: string;
  options: Array<FOHSelectItemProps>;
}

export interface FOHSetRadioAnswer {
  questionId?: string;
  optionId?: string;
  value: string;
}

export interface FOHRadioQuestionFieldProps {
  answer: FOHSetRadioAnswer;
  updateAnswer?: (answer: any) => void;
}

export interface FOHHeaderRadioQuestionCellProps
  extends FOHRadioQuestionOptionProps,
    FOHRadioQuestionFieldProps {
  header?: string;
  description?: string;
}

export const FOHHeaderRadioQuestionCell = (
  props: FOHHeaderRadioQuestionCellProps
) => (
  <FOHView
    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
  >
    <Header>{props.header}</Header>
    <Description>{props.description}</Description>
    <FOHView
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        maxWidth: 396,
        width: '100%'
      }}
      testID={`${props.id}-radio-question`}
    >
      {props.options.map((option: any, i: number, _arr: Array<any>) => (
        <FOHSelectionCell
          key={i}
          minWidth={'48%'}
          label={option.label}
          value={option.value}
          onPress={() => {
            props.updateAnswer &&
              props.updateAnswer({
                questionId: option.questionId,
                optionId: option.id,
                value: option.value
              });
          }}
          selected={option.value === path(['answer', 'value'], props)}
        />
      ))}
    </FOHView>
  </FOHView>
);

const Header = styled(FOHHeaderH1)`
  margin-bottom: 8px;
`;

const Description = styled(FOHSmallDetailLabel)`
  text-align: center;
  margin-bottom: 8px;
  line-height: 24px;
  max-width: 396px;
`;
