import React, { useState } from 'react';

import { defaultTo, length, path, prop } from 'ramda';
import { useTranslation } from 'react-i18next';

import shifts_export from '../../images/7shifts_export.svg';
import {
  useGetLocationsQuery,
  useGetDepartmentsQuery,
  useGetRolesQuery,
  useCreate7ShiftsUserMutation
} from '../../graphql/generated';
import {
  handleMutation,
  openNewTab,
  formatPhoneNumber,
  toDate
} from '../../utils';
import { FEED } from '../../constants';
import {
  FOHLabel,
  FOHFonts,
  FOHColors,
  FOHSpaceSmallest,
  FOHSpace,
  FOHSpaceSmall,
  FOHButton,
  FOHView,
  FOHSearchableSelectDropdown,
  FOHBlueCrownIcon,
  FOHBlueDepartmentIcon,
  FOHBlueRestaurantIcon,
  FOHLocationPin,
  FOHTextButton,
  FOHErrorLabel,
  FOHSmallDetailLabel,
  FOHCalendarPositionType,
  FOHCalendarPicker,
  FOHLabeledCheckbox,
  FOHCandidateDetailForm
} from '../../components';
import { useNavigation } from '../../utils/navigation';

import { withReactiveModal } from '../Navigation';
import { FOHAvatarsOverlayOrImageWithHeader } from '../CandidateFeed/components';

import { useGetCompanies } from './useGetCompanies';

const Export7ShiftsModal = props => {
  const { t } = useTranslation('Integrations');

  const { employerCandidate } = props;

  const { navigateTo } = useNavigation();
  // for Company
  const [companyOpen, setCompanyOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState('');

  // for Location
  const [locationOpen, setLocationOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState('');

  // for Department
  const [departmentOpen, setDepartmentOpen] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState('');

  // for Roles
  const [roleOpen, setRoleOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState(undefined);

  const [hireDate, setHireDate] = useState('');

  const [error, setError] = useState('');
  const [inviteUser, setInviteUser] = useState(false);
  const [isCalendarOpen, setCalendarOpen] = useState(false);

  const candidateName = `${path(
    ['candidateProfile', 'user', 'firstName'],
    employerCandidate
  )} ${path(['candidateProfile', 'user', 'lastName'], employerCandidate)}`;

  const { data } = useGetCompanies();

  const companies = defaultTo([], path(['sevenshiftsCompanies'], data)).map(
    company => {
      return {
        label: prop('companyName', company),
        value: prop('companyId', company)
      };
    }
  );

  const getLocationsQuery = useGetLocationsQuery({
    skip: !selectedCompany,
    variables: {
      companyId: selectedCompany || undefined
    }
  });

  const locations = defaultTo(
    [],
    path(['data', 'sevenshiftsLocations'], getLocationsQuery)
  ).map(location => {
    return {
      label: prop('name', location),
      value: prop('id', location),
      sublabel: prop('address', location)
    };
  });

  const getDepartmentsQuery = useGetDepartmentsQuery({
    skip: !selectedCompany || !selectedLocation,
    variables: {
      companyId: selectedCompany,
      locationId: selectedLocation
    }
  });

  const departments = defaultTo(
    [],
    path(['data', 'sevenshiftsDepartments'], getDepartmentsQuery)
  ).map(department => {
    return {
      label: prop('name', department),
      value: prop('id', department)
    };
  });

  const getRolesQuery = useGetRolesQuery({
    skip: !selectedCompany || !selectedLocation || !selectedDepartment,
    variables: {
      companyId: selectedCompany,
      locationId: selectedLocation,
      departmentId: selectedDepartment
    }
  });

  const roles = defaultTo(
    [],
    path(['data', 'sevenshiftsRoles'], getRolesQuery)
  ).map(role => {
    return {
      label: prop('name', role),
      value: prop('id', role)
    };
  });

  const [create7ShiftsUserMutation, { loading }] =
    useCreate7ShiftsUserMutation();

  const resetForm = () => {
    setSelectedCompany('');
    setSelectedLocation('');
    setSelectedDepartment('');
    setSelectedRole(undefined);
    setInviteUser(false);
    setHireDate('');
    setError('');
  };

  const handle7ShiftCreateUser = async () => {
    const [create7ShiftsUserResponse, errorOnCreate7ShiftsUser] =
      await handleMutation(
        create7ShiftsUserMutation({
          variables: {
            employerCandidate: prop('id', employerCandidate),
            companyId: selectedCompany,
            locationId: selectedLocation,
            departmentId: selectedDepartment,
            roleId: selectedRole || undefined,
            hireDate:
              hireDate !== ''
                ? toDate(hireDate.toISOString(), 'yyyy-MM-dd')
                : undefined,
            inviteUser: inviteUser || false
          }
        })
      );

    return {
      create7ShiftsUserResponse,
      errorOnCreate7ShiftsUser
    };
  };

  const onPressAddAndGoToProfile = async () => {
    const { create7ShiftsUserResponse, errorOnCreate7ShiftsUser } =
      await handle7ShiftCreateUser();

    if (errorOnCreate7ShiftsUser) {
      setError(errorOnCreate7ShiftsUser.message);
      return;
    }

    if (!create7ShiftsUserResponse) {
      setError(t('errorOn7ShiftCreateUser'));
      return;
    }

    const URL = path(
      ['sevenshiftsCreateUser', 'sevenshiftsUrl'],
      create7ShiftsUserResponse
    );

    openNewTab(URL);

    resetForm();

    props.candidateFeedQuery.refetch();

    navigateTo({
      pathname: FEED
    });
  };

  const onPressAddEmployee = async () => {
    const { create7ShiftsUserResponse, errorOnCreate7ShiftsUser } =
      await handle7ShiftCreateUser();

    if (errorOnCreate7ShiftsUser) {
      setError(errorOnCreate7ShiftsUser.message);
      return;
    }

    if (!create7ShiftsUserResponse) {
      setError(t('errorOn7ShiftCreateUser'));
      return;
    }

    resetForm();

    props.candidateFeedQuery.refetch();

    navigateTo({
      pathname: FEED
    });
  };

  return (
    <FOHView
      style={{ width: '100%', maxWidth: 722 }}
      testID="Export7ShiftsModal"
    >
      <FOHAvatarsOverlayOrImageWithHeader
        title={t('integrationHeaderLabel')}
        HeaderImage={() => <img src={shifts_export} alt="" />}
        candidateName={candidateName}
      />
      <FOHView style={{ paddingHorizontal: 40, width: '100%' }}>
        <FOHView
          style={{
            backgroundColor: FOHColors.GRAYSCALE_10,
            padding: 32,
            marginTop: 33,
            borderRadius: 24,
            zIndex: 2
          }}
        >
          <FOHLabel style={{ fontSize: 18, color: FOHColors.GRAYSCALE_900 }}>
            {t('candidateContext')}
          </FOHLabel>
          <FOHSpace />
          <FOHView style={{ zIndex: 6, width: '100%' }}>
            <FOHSearchableSelectDropdown
              open={companyOpen}
              setOpen={open => {
                setCompanyOpen(open);
                setLocationOpen(false);
                setDepartmentOpen(false);
                setRoleOpen(false);
              }}
              selectedValue={selectedCompany}
              selectedLabel={!selectedCompany ? t('selectCompany') : undefined}
              showLabel={t('show')}
              multiSelect={false}
              onValueChange={value => {
                setSelectedCompany(value);
                setSelectedLocation('');
                setSelectedDepartment('');
                setSelectedRole(undefined);
              }}
              disabled={length(companies) === 0}
              /** TEMPORARY COMMENTED CODES AS WE DON'T HAVE SEARCH IN QUERY */
              // onSearchChange={setCompanySearch}
              // searchValue={companySearch}
              // searchPlaceholder={t('common:search')}
              options={companies}
              icon={() => (
                <FOHBlueRestaurantIcon style={{ width: 11, height: 11 }} />
              )}
              fieldLabel={t('company').toUpperCase()}
              style={{ width: '100%', backgroundColor: FOHColors.WHITE }}
              testID="Export7ShiftsModal-company-dropdown"
              isLoading={getCompaniesQuery.loading}
            />
          </FOHView>
          <FOHSpace />
          <FOHView style={{ zIndex: 5, width: '100%' }}>
            <FOHSearchableSelectDropdown
              open={locationOpen}
              setOpen={open => {
                setLocationOpen(open);
                setCompanyOpen(false);
                setDepartmentOpen(false);
                setRoleOpen(false);
              }}
              selectedValue={selectedLocation}
              selectedLabel={
                !selectedLocation ? t('selectLocation') : undefined
              }
              showLabel={t('show')}
              multiSelect={false}
              onValueChange={value => {
                setSelectedLocation(value);
                setSelectedDepartment('');
                setSelectedRole(undefined);
              }}
              disabled={!selectedCompany || length(locations) === 0}
              /** TEMPORARY COMMENTED CODES AS WE DON'T HAVE SEARCH IN QUERY */
              // onSearchChange={setLocationSearch}
              // searchValue={locationSearch}
              // searchPlaceholder={t('common:search')}
              options={locations}
              icon={() => <FOHLocationPin style={{ width: 8, height: 11 }} />}
              fieldLabel={t('location').toUpperCase()}
              style={{ width: '100%', backgroundColor: FOHColors.WHITE }}
              testID="Export7ShiftsModal-location-dropdown"
              isLoading={getLocationsQuery.loading}
            />
          </FOHView>
          <FOHSpace />
          <FOHView style={{ zIndex: 4, width: '100%' }}>
            <FOHSearchableSelectDropdown
              open={departmentOpen}
              setOpen={open => {
                setDepartmentOpen(open);
                setCompanyOpen(false);
                setLocationOpen(false);
                setRoleOpen(false);
              }}
              selectedValue={selectedDepartment}
              selectedLabel={
                !selectedDepartment ? t('selectDepartment') : undefined
              }
              showLabel={t('show')}
              multiSelect={false}
              onValueChange={value => {
                setSelectedDepartment(value);
                setSelectedRole(undefined);
              }}
              disabled={
                length(departments) === 0 ||
                !selectedCompany ||
                !selectedLocation
              }
              /** TEMPORARY COMMENTED CODES AS WE DON'T HAVE SEARCH IN QUERY */
              // onSearchChange={setDepartmentSearch}
              // searchValue={departmentSearch}
              // searchPlaceholder={t('common:search')}
              options={departments}
              icon={() => (
                <FOHBlueDepartmentIcon style={{ width: 10, height: 10 }} />
              )}
              fieldLabel={t('department').toUpperCase()}
              style={{ width: '100%', backgroundColor: FOHColors.WHITE }}
              testID="Export7ShiftsModal-department-dropdown"
              isLoading={getDepartmentsQuery.loading}
            />
          </FOHView>
          <FOHSpace />
          <FOHView style={{ zIndex: 3, width: '100%' }}>
            <FOHSearchableSelectDropdown
              open={roleOpen}
              setOpen={open => {
                setRoleOpen(open);
                setCompanyOpen(false);
                setLocationOpen(false);
                setDepartmentOpen(false);
              }}
              selectedValue={selectedRole}
              showLabel={t('show')}
              multiSelect={false}
              onValueChange={value => {
                setSelectedRole(value);
              }}
              disabled={
                length(roles) === 0 ||
                !selectedCompany ||
                !selectedLocation ||
                !selectedDepartment
              }
              /** TEMPORARY COMMENTED CODES AS WE DON'T HAVE SEARCH IN QUERY */
              // onSearchChange={setRoleSearch}
              // searchValue={roleSearch}
              // searchPlaceholder={t('common:search')}
              options={[
                { label: t('roleDoesNotExist'), value: undefined },
                ...roles
              ]}
              icon={() => (
                <FOHBlueCrownIcon style={{ width: 10, height: 10 }} />
              )}
              fieldLabel={t('role').toUpperCase()}
              style={{ width: '100%', backgroundColor: FOHColors.WHITE }}
              testID="Export7ShiftsModal-role-dropdown"
              isLoading={getRolesQuery.loading}
            />
          </FOHView>
          <FOHSpace />
          <FOHCandidateDetailForm
            titleLabel={t('candidateDetails')}
            firstName={path(
              ['candidateProfile', 'user', 'firstName'],
              employerCandidate
            )}
            firstNamePlaceHolder={t('RegisterFeature:firstName')}
            lastName={path(
              ['candidateProfile', 'user', 'lastName'],
              employerCandidate
            )}
            lastNamePlaceHolder={t('RegisterFeature:lastName')}
            email={path(
              ['candidateProfile', 'user', 'email'],
              employerCandidate
            )}
            emailPlaceHolder={t('RegisterFeature:email')}
            mobileNumber={defaultTo(
              '',
              formatPhoneNumber(
                path(['candidateProfile', 'user', 'phone'], employerCandidate)
              )
            )}
            mobileNumberPlaceHolder={t('RegisterFeature:phoneNumber')}
          />
          <FOHSpaceSmallest />
          <FOHView
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexWrap: 'wrap',
              zIndex: isCalendarOpen ? 7 : 2
            }}
          >
            <FOHView style={{ width: '49%' }}>
              <FOHCalendarPicker
                open={isCalendarOpen}
                setOpen={setCalendarOpen}
                value={hireDate}
                setValue={setHireDate}
                placeholder={t('hireDatePlaceHolder')}
                defaultPlaceHolderText={t('selectDate')}
                btnBackground={FOHColors.WHITE}
                width="100%"
                calendarPositionType={FOHCalendarPositionType.TOP}
              />
            </FOHView>
          </FOHView>
          <FOHSpace />
          <FOHView>
            <FOHLabeledCheckbox
              checkboxLabel={t('inviteTo7ShiftsHeaderText')}
              selected={inviteUser}
              setSelected={() => setInviteUser(!inviteUser)}
            />
            <FOHSmallDetailLabel style={{ paddingLeft: 30 }}>
              {t('inviteTo7ShiftsSubHeaderText')}
            </FOHSmallDetailLabel>
          </FOHView>
        </FOHView>
      </FOHView>
      <FOHView
        style={{
          marginTop: 24,
          paddingHorizontal: 40,
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
          zIndex: -1
        }}
      >
        <FOHView style={{ width: 196 }}>
          <FOHTextButton
            title={t('addAndGoToProfile')}
            underline
            disabled={
              loading ||
              !selectedCompany ||
              !selectedLocation ||
              !selectedDepartment
            }
            onPress={onPressAddAndGoToProfile}
            style={{
              color: FOHColors.GRAYSCALE_700,
              fontWeight: 700,
              fontFamily: FOHFonts.BOLD,
              fontSize: 16,
              lineHeight: 24
            }}
          />
        </FOHView>
        <FOHSpaceSmall />
        <FOHView style={{ width: 196 }}>
          <FOHButton
            title={t('addEmployee')}
            disabled={
              loading ||
              !selectedCompany ||
              !selectedLocation ||
              !selectedDepartment
            }
            onPress={onPressAddEmployee}
            selected={true}
            style={{ height: 40 }}
            textStyle={{ color: FOHColors.WHITE, fontWeight: 700 }}
          />
        </FOHView>
      </FOHView>
      <FOHSpaceSmall />
      <FOHView
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          paddingHorizontal: 40,
          width: '100%',
          zIndex: -1
        }}
      >
        {error ? <FOHErrorLabel>{`• ${error}`}</FOHErrorLabel> : null}
      </FOHView>
    </FOHView>
  );
};

export default withReactiveModal(Export7ShiftsModal);
