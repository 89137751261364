import React from 'react';

import styled from 'styled-components/native';

import { FOHColors, FOHView, FOHHeaderH1 } from '../../ingredients';
import { FOHProfileImage } from '../../recipes';

export interface FOHMultipleProfilePhotosProps {
  thumbnails: Array<{ uri: string }>;
  totalCount?: string | number;
}

export const FOHMultipleProfilePhotos = (
  props: FOHMultipleProfilePhotosProps
) => (
  <Row>
    <ImagesContainer>
      {props.thumbnails ? (
        props.thumbnails.map((thumb, keyIndex) => (
          <Circle
            style={
              keyIndex !== 0
                ? {
                    position: 'absolute',
                    left: 50 * keyIndex
                  }
                : {}
            }
            key={keyIndex + 1}
          >
            <FOHProfileImage
              photo={thumb.uri}
              style={{
                height: 88,
                width: 88,
                borderColor: FOHColors.WHITE,
                borderWidth: 4
              }}
            />
          </Circle>
        ))
      ) : (
        <></>
      )}
      {props.totalCount ? (
        <Circle
          style={{
            position: 'absolute',
            left: 150,
            borderColor: FOHColors.WHITE,
            borderWidth: 4
          }}
        >
          <FOHHeaderH1 style={{ textAlign: 'center', lineHeight: 0 }}>
            {`+${props.totalCount}`}
          </FOHHeaderH1>
        </Circle>
      ) : (
        <></>
      )}
    </ImagesContainer>
  </Row>
);

const ImagesContainer = styled(FOHView)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Circle = styled(FOHView)`
  border-radius: 50%;
  width: 88px;
  justify-content: center;
  align-items: center;
  height: 88px;
  background: #f5f5f5;
`;

const Row = styled(FOHView)`
  padding: 12px;
  flex-direction: row;
`;
