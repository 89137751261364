import { path, prop } from 'ramda';

import { logout } from '../../api/auth';
import {
  useGetMyPhotoQuery,
  useUpdateCandidateMutation,
  useUpdateCandidateProfileImageMutation
} from '../../graphql/generated';
import { LOGIN_URL } from '../../constants';
import { useNavigation } from '../../utils/navigation';

import { getProfilePhoto } from '../Navigation/TopNav';
import { useGetMeData } from '../SignIn';

export const useCandidateProfileMutation = props => {
  const { navigateTo } = useNavigation();

  const meQuery = useGetMeData();
  const me = prop('data', meQuery);
  const myPhotoQuery = useGetMyPhotoQuery({});

  const [mutateCandidate] = useUpdateCandidateMutation();
  const [mutateCandidatePhoto] = useUpdateCandidateProfileImageMutation();

  const updateCandidate = async ({ variables }) => {
    // optimistic responses etc...
    return await mutateCandidate({
      variables: {
        id: path(['candidateProfile', 'id'], me),
        ...variables
      }
    }).catch(async err => {
      if (
        err.message ===
        "GraphQL error: Django doesn't provide a DB representation for AnonymousUser."
      ) {
        console.log(err, 'Error updating candidate');
        if (props) {
          await logout();
          return navigateTo(LOGIN_URL);
        }
      }
      return err;
    });
  };

  const updateCandidateWithPhoto = async ({ variables }) => {
    // optimistic responses etc...
    return await mutateCandidatePhoto({
      variables: {
        id: path(['candidateProfile', 'id'], me),
        ...variables
      }
    });
  };

  return {
    me: {
      ...me,
      profileImage: getProfilePhoto(myPhotoQuery)
    },
    meQuery,
    updateCandidate,
    updateCandidateWithPhoto
  };
};
