import React from 'react';

import styled from 'styled-components/native';

import { FOHLabel } from '../../ingredients';

interface FOHTagProps {
  label: string;
  style?: any;
}

export interface FOHLocationTagProps {
  name: string;
}

export const FOHTag = (props: FOHTagProps) => (
  <TagText style={{ ...props.style }}>{props.label}</TagText>
);

const TagText = styled(FOHLabel)`
  font-size: 12px;
  color: #035081;
  text-align: center;
  border-radius: 100px;
  height: 24px;
  padding-left: 12px;
  padding-right: 12px;
  justify-content: center;
  background-color: #ddf9ff;
  text-align: center;
  line-height: 20px;
  padding-top: 2px;
  padding-bottom: 2px;
  word-break: break-word;
  margin-top: 4px;
`;
