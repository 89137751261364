import React from 'react';

import { FOHDotsIcon, FOHTouchableOpacity, FOHView } from '../../ingredients';

export interface FOHDotMenuProps {
  openDotMenu?: boolean;
  setOpenDotMenu: (open: boolean) => void;
  children?: any;
  openLeft?: boolean;
  tintColor?: string;
  menuTestId?: string;
  fullChatTestId?: string;
}

export const FOHDotMenu = (props: FOHDotMenuProps) => (
  <FOHView>
    <FOHTouchableOpacity
      onPress={() => {
        props.openDotMenu
          ? props.setOpenDotMenu(false)
          : props.setOpenDotMenu(true);
      }}
      testID={props.menuTestId}
    >
      <FOHDotsIcon
        key={props.tintColor || 'dotsmenu'}
        style={{ tintColor: props.tintColor }}
      />
    </FOHTouchableOpacity>
    {props.openDotMenu && (
      <FOHView
        style={{
          zIndex: 99,
          top: 20,
          position: 'absolute',
          width: 200,
          left: props.openLeft ? -170 : 0
        }}
        testID={props.fullChatTestId}
      >
        {props.children}
      </FOHView>
    )}
  </FOHView>
);
