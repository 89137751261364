import { path } from 'ramda';

import { handleMutation } from '../../utils';
import {
  SearchLocationsFeedDocument,
  useToggleHideLocationMutation,
  useToggleFavoriteLocationMutation
} from '../../graphql/generated';

export const useLocationMutation = () => {
  const [toggleFavorite] = useToggleFavoriteLocationMutation();
  const [toggleHide] = useToggleHideLocationMutation();
  // Location search variables
  const variables = {
    following: true,
    first: 10,
    featured: 'any'
  };

  const optimisticToggleFavorite = location => {
    if (!location || !location?.id) return;

    return handleMutation(
      toggleFavorite({
        variables: {
          id: location.id
        },
        optimisticResponse: {
          __typename: 'Mutation',
          toggleFavoriteLocation: {
            id: location.id,
            __typename: location.__typename,
            content: {},
            location: {
              ...location,
              isFavorited: location.isHidden ? false : !location.isFavorited,
              followerCount: location.isHidden
                ? location.followerCount
                : location.isFavorited
                ? location.followerCount - 1
                : location.followerCount + 1,
              id: location.id,
              __typename: location.__typename
            }
          }
        },
        update: store => {
          // Update query if it exists
          try {
            const data = store.readQuery({
              query: SearchLocationsFeedDocument,
              variables
            });

            store.writeQuery({
              query: SearchLocationsFeedDocument,
              variables,
              data: {
                ...data,
                locations: {
                  ...path(['locations'], data),
                  totalCount:
                    path(['locations', 'totalCount'], data) +
                    (location.isFavorited ? -1 : 1)
                }
              }
            });
          } catch {
            return;
          }
        }
      })
    );
  };

  const optimisticToggleHidden = location => {
    if (!location || !location?.id) return;

    return handleMutation(
      toggleHide({
        variables: {
          id: location.id
        },
        optimisticResponse: {
          __typename: 'Mutation',
          toggleHideLocation: {
            id: location.id,
            __typename: location.__typename,
            content: {},
            location: {
              ...location,
              isFavorited: false,
              followerCount: location.isFavorited
                ? location.followerCount - 1
                : location.followerCount,
              isHidden: !location.isHidden,
              id: location.id,
              __typename: location.__typename
            }
          }
        },
        update: store => {
          // Update query if it exists
          try {
            const data = store.readQuery({
              query: SearchLocationsFeedDocument,
              variables
            });

            store.writeQuery({
              query: SearchLocationsFeedDocument,
              variables,
              data: {
                ...data,
                locations: {
                  ...path(['locations'], data),
                  totalCount:
                    path(['locations', 'totalCount'], data) +
                    (location.isFavorited ? -1 : 0)
                }
              }
            });
          } catch {
            return;
          }
        }
      })
    );
  };

  return {
    optimisticToggleFavorite,
    optimisticToggleHidden
  };
};
