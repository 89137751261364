import styled from 'styled-components/native';

import { FOH_INPUT_HEIGHT } from '../../constants';
import {
  FOHColors,
  FOHHeaderH4,
  FullWidthCard,
  SPACING_SCALE
} from '../../ingredients';
import { CAREER_PAGE_SCREEN_TOP_PADDING } from '../../templates/CareerPage/styled/FOHCareerPageLayout.styled';

export const SectionHeader = styled(FOHHeaderH4)`
  margin-top: ${SPACING_SCALE.huge}px;
  color: ${FOHColors.SILVER};
  font-size: 16px;
  text-align: start;
  text-transform: uppercase;
`;

// position button in the middle of the space available above main content
export const Wrapper = styled.View`
  position: absolute;
  right: 68;
  top: ${CAREER_PAGE_SCREEN_TOP_PADDING / 2 - FOH_INPUT_HEIGHT / 2}px;
`;

export const MainContent = styled(FullWidthCard)`
  margin-top: ${SPACING_SCALE.regular}px;
  margin-bottom: 100px;
  padding: ${SPACING_SCALE.huge}px;
  max-width: 361px;
  max-height: '100%';
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.15);
`;

export const ColorBox = styled.View<{ color: string }>`
  width: 51px;
  display: inline;
  height: 32px;
  background-color: ${(props: any) => props.color};
  border-radius: 3px;
`;

export const ColorsWrapper = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -16;
`;
