import React from 'react';

import styled from 'styled-components/native';
import { length } from 'ramda';

import {
  FOHCurrentStep,
  FOHCompletedStep,
  FOHNextStep,
  FOHView,
  FOHColors,
  FOHCircleSuccessIcon,
  FOHBlueCurrentStepIcon,
  FOHBlueNextStepIcon
} from '../../ingredients';

export interface FOHNavigationProgressProps {
  steps: Array<string>;
  stepIndex: number;
  small?: boolean;
  styleBlue?: boolean;
}

export const FOHNavigationProgress = (props: FOHNavigationProgressProps) => {
  const stepCount = length(props.steps);
  const stepIndex = props.small ? props.stepIndex + 1 : props.stepIndex;

  return (
    <FOHView
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        position: props.small ? 'fixed' : 'relative',
        zIndex: 999
      }}
    >
      <FOHView
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%'
        }}
      >
        {!props.small && <FOHView style={{ flex: 1 }} />}
        {props.steps.map((_, i) => {
          const Completebar = props.small
            ? props?.styleBlue
              ? SmallBlueBar
              : SmallGreenBar
            : props?.styleBlue
            ? BlueBar
            : GreenBar;
          const Incompletebar = props.small ? SmallGrayBar : GrayBar;

          const StepContainer =
            i < stepIndex
              ? props?.styleBlue
                ? FOHCircleSuccessIcon
                : FOHCompletedStep
              : i === stepIndex
              ? props?.styleBlue
                ? FOHBlueCurrentStepIcon
                : FOHCurrentStep
              : props?.styleBlue
              ? FOHBlueNextStepIcon
              : FOHNextStep;

          const RightBar =
            stepCount - 1 === i && !props.small
              ? () => <></>
              : i < stepIndex
              ? Completebar
              : Incompletebar;

          return (
            <FOHView style={{ flex: 1 }} key={`${i}step`}>
              <FOHView
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  flex: 1
                }}
              >
                {!props.small && (
                  <FOHView>
                    <StepContainer style={{ width: 25, height: 25 }} />
                  </FOHView>
                )}
                <RightBar />
                {props.small && stepCount - 1 !== i && (
                  <FOHView style={{ paddingLeft: 4 }} />
                )}
              </FOHView>
            </FOHView>
          );
        })}
      </FOHView>
    </FOHView>
  );
};

const Bar = styled(FOHView)`
  height: 4px;
  flex: 1;
`;

const GreenBar = styled(Bar)`
  background-color: ${FOHColors.GREEN};
`;

const BlueBar = styled(Bar)`
  background-color: ${FOHColors.PRIMARY_TEAL_P1};
`;

const GrayBar = styled(Bar)`
  background-color: #dbdbdb;
`;

const SmallBar = styled(Bar)`
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
`;

const SmallGreenBar = styled(SmallBar)`
  background-color: ${FOHColors.GREEN};
`;

const SmallBlueBar = styled(SmallBar)`
  background-color: ${FOHColors.PRIMARY_TEAL_P1};
`;

const SmallGrayBar = styled(SmallBar)`
  background-color: #dbdbdb;
`;
