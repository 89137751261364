import React from 'react';

import circle from '../../../images/green_circle.svg';

import { FOHImage } from '../../ingredients';

export const FOHGreenCircleDecoration = (props: any) => (
  <FOHImage
    source={{ uri: circle }}
    style={{ width: 16, height: 16 }}
    {...props}
  />
);
