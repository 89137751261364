import React from 'react';

import { prop, defaultTo } from 'ramda';

import clear from '../../../images/white_clear.svg';

import { FOHImage } from '../../ingredients';

export const FOHWhiteX = (props: any) => (
  <FOHImage
    source={{ uri: clear }}
    style={{ width: 32, height: 32, ...defaultTo({}, prop('style', props)) }}
  />
);
