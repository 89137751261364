import React from 'react';

import eye from '../../../images/question_gray.svg';

import { FOHImage } from '../../ingredients';

export const FOHGrayHelpIcon = (props: any) => (
  <FOHImage
    source={{ uri: eye }}
    style={{
      width: 24,
      height: 24
    }}
    {...props}
  />
);
