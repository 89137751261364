import React, { FunctionComponent, ElementType } from 'react';

import { ScrollViewProps } from 'react-native';

import { useIsMobile } from '../../../spices';
import { FOHView, FOHScrollView, FOHLoader } from '../../../ingredients';
import {
  FOHOpenPosition,
  FOHOpenPositionProps,
  TOP_NAV_HEIGHT
} from '../../../recipes';

import { FOHCareerPagePositionsProps } from '../FOHCareerPagePositions';
import { FontProperty } from '../FOHCareerPageLocations';

interface PositionListProps {
  fontProperty?: FontProperty;
  isLoading: boolean;
  maxHeight: number;
  onScrollHandler: ScrollViewProps['onScroll'];
  positions: FOHOpenPositionProps[];
  onPositionDetailsPress: FOHCareerPagePositionsProps['onPositionDetailsPress'];
  onApplyPress: FOHCareerPagePositionsProps['onApplyPress'];
  translations: Record<string, string>;
}

export const PositionList: FunctionComponent<PositionListProps> = ({
  fontProperty,
  isLoading,
  maxHeight = 400,
  onApplyPress,
  onPositionDetailsPress,
  onScrollHandler,
  positions,
  translations,
  ...props
}) => {
  const isMobile = useIsMobile();
  const Container = (isMobile ? FOHView : FOHScrollView) as ElementType; // work around ts error

  return (
    <Container
      style={{
        maxHeight: isMobile ? 'auto' : maxHeight,
        position: 'relative'
      }}
      {...(!isMobile && {
        onScroll: onScrollHandler,
        scrollEventThrottle: 200
      })}
      {...props}
    >
      {isLoading && (
        <FOHView
          style={{
            alignItems: 'center',
            backgroundColor: '#ffffffcc',
            bottom: 0,
            height: isMobile ? `calc(100vh - ${TOP_NAV_HEIGHT}px)` : maxHeight,
            justifyContent: 'center',
            left: 0,
            position: 'absolute',
            width: '100%',
            zIndex: 1
          }}
        >
          <FOHLoader />
        </FOHView>
      )}

      {positions.map(position => (
        <FOHOpenPosition
          key={position.id}
          {...position}
          onApplyPress={onApplyPress}
          onPositionDetailsPress={onPositionDetailsPress}
          translations={translations}
        />
      ))}
    </Container>
  );
};
