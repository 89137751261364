import React from 'react';

import styled from 'styled-components/native';
import { View } from 'react-native';

import {
  FOHView,
  FOHColors,
  FOHSmallDetailLabel,
  FOHFonts
} from '../../ingredients';

export interface FOHTextCellProps {
  icon?: any;
  reverse?: boolean;
  label: string;
  value: string;
}

export const FOHTextCell = (props: FOHTextCellProps) => (
  <Cell reverse={props.reverse}>
    {props.icon && props.icon()}
    <FOHView style={{ paddingLeft: 6 }} />
    <FOHView style={{ flex: 1 }}>
      <SmallGreyLabel style={{ textTransform: 'none' }}>
        {props.label}
      </SmallGreyLabel>
      <FOHName>{props.value}</FOHName>
    </FOHView>
  </Cell>
);

const Cell = styled(View)`
  flex-direction: ${(props: any) => (props.reverse ? 'row-reverse' : 'row')};
  align-items: center;
  justify-content: flex-start;
`;

const SmallGreyLabel = styled(FOHSmallDetailLabel)`
  text-transform: uppercase;
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${FOHColors.GREY};
`;

const FOHName = styled(FOHSmallDetailLabel)`
  line-height: 24px;
  font-weight: 600;
  color: ${FOHColors.NARO_GREY};
`;
