import React, { useState, useEffect } from 'react';

import styled from 'styled-components/native';
import { View, TextInput, ScrollView, TouchableOpacity } from 'react-native';
import {
  defaultTo,
  path,
  length,
  prop,
  indexOf,
  flatten,
  last,
  propEq,
  find,
  isEmpty
} from 'ramda';

import { getTextWidthFromFont } from '../../utils';
import {
  FOHCircleLoader,
  FOHGrayX,
  FOHHeaderH4,
  FOHLabel,
  FOHLabelBold,
  FOHRequired,
  FOHSearch,
  FOHSmallDetailLabel,
  FOHSpace,
  FOHSpaceSmall,
  FOHTextListCell,
  FOHTextListTruncated,
  FOHView,
  FOHColors,
  FOHFonts
} from '../../ingredients';
import { useClickOutside } from '../../spices/useClickOutside';
import { FOH_INPUT_HEIGHT } from '../../constants';

import { FOHSelectItemProps } from '../select/FOHSelect';
import { FOHSelectCheckboxTable } from '../tables/FOHSelectCheckboxTable';

export interface FOHDropdownSearchProps {
  onChangeSearch: (val: string) => any;
  searchValue: string;
  searchPlaceholder: string;
  onKeyPressSearchBar?: (e: any) => any;
  sections: Array<Section>;
  selectedItems: Array<FOHSelectItemProps>;
  selectItem: (item: FOHSelectItemProps) => any;
  freeTextLabel: string;
  selectFreeTextLabel: string;
  loading?: boolean;
  truncationLimit: number;
  clearSearch: () => any;
  closeLabel: string;
  searchDetailLabel: string;
  testID?: string;

  noIcon?: boolean;
  noFreeText?: boolean;
  required?: boolean;
  disabled?: boolean;
  height?: number;
}

interface Section {
  title: string;
  items: Array<FOHSelectItemProps>;
}

export const DropDownSearch = (props: FOHDropdownSearchProps) => {
  const [open, setOpen] = useState(false);
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const [showItem, setShowItem] = useState({ width: 0, num: 0 });
  const allItems = flatten(props.sections.map(section => section.items));
  const allItemsByValue = allItems.reduce(
    (acc, item) => ({
      ...acc,
      [item.value]: { ...item }
    }),
    {}
  );
  const close = () => {
    setOpen(false);
    if (!props.noFreeText && props.searchValue) {
      props.selectItem({
        value: props.searchValue,
        label: `"${props.searchValue}"`
      });
      props.onChangeSearch('');
    }
  };
  const openDropdown = () => setOpen(true);

  const [ref] = useClickOutside({
    close: () => !!open && close()
  });

  useEffect(() => {
    if (props.selectedItems.length === 1 && isEmpty(props.selectedItems[0])) {
      setShowItem({ width: 10, num: 1 });
    } else if (!open && props.selectedItems.length > 0) {
      const selectedShowItem = props.selectedItems.reduce(
        (prev: any, curr: FOHSelectItemProps) => {
          const nextWidth =
            prev.width +
            getTextWidthFromFont(curr.value, `16px ${FOHFonts.REGULAR}`) +
            50;
          if (nextWidth < width - 100) {
            return { width: nextWidth, num: prev.num + 1 };
          }
          return prev;
        },
        { width: 0, num: 0 }
      );
      setShowItem(selectedShowItem);
    }
  }, [open, width]);

  return (
    <View ref={ref}>
      <FOHRequired
        required={props.required && length(props.selectedItems) === 0}
      >
        <View
          style={{
            position: open ? 'absolute' : 'relative',
            width: open ? '100%' : undefined,
            borderWidth: 1,
            borderRadius: 4,
            padding: 0,
            paddingRight: 34,
            justifyContent:
              props.selectedItems.length > 0 ? 'flex-start' : 'center',
            minHeight: props?.height || FOH_INPUT_HEIGHT,
            height: props?.height || FOH_INPUT_HEIGHT,
            backgroundColor: props.disabled
              ? FOHColors.GRAYSCALE_100
              : FOHColors.WHITE,
            borderColor: props.disabled
              ? FOHColors.GRAYSCALE_200
              : open
              ? FOHColors.BLUE
              : FOHColors.GRAYSCALE_300
          }}
        >
          <FOHView
            style={{
              height: '100%',
              alignItems: 'center',
              position: 'absolute',
              right: 10,
              zIndex: 99
            }}
          >
            <TouchableOpacity
              onPress={() => {
                open ? close() : props.clearSearch();
              }}
              style={{ marginVertical: 'auto' }}
              testID={`${props.testID}-close`}
            >
              {open ? (
                <FOHLabel
                  style={{
                    color: FOHColors.BLUE
                  }}
                >
                  {props.closeLabel}
                </FOHLabel>
              ) : (
                <FOHGrayX />
              )}
            </TouchableOpacity>
          </FOHView>
          <Container
            onLayout={(event: any) => {
              const { width: viewWidth, height: viewHeight } =
                event.nativeEvent.layout;
              setWidth(viewWidth);
              setHeight(viewHeight);
            }}
            style={{
              flexWrap: open ? 'wrap' : undefined,
              minHeight: props.height ? props.height - 2 : 46,
              paddingHorizontal: 10,
              paddingVertical: open ? 5 : 2,
              backgroundColor: props.disabled
                ? FOHColors.GRAYSCALE_100
                : FOHColors.WHITE
            }}
            testID={props.testID}
          >
            {!props.noIcon ? (
              <View>
                <FOHSearch
                  style={{
                    tintColor: props.disabled
                      ? FOHColors.GRAYSCALE_400
                      : open
                      ? FOHColors.PRIMARY_TEAL_P1
                      : FOHColors.GRAYSCALE_500
                  }}
                />
              </View>
            ) : (
              <></>
            )}
            <FOHSpaceSmall />
            {props.selectedItems
              .filter(
                (item, index) => (!open ? index < showItem.num : true) && !!item
              )
              .map((item: FOHSelectItemProps, index: number) => (
                <FOHTextListCell
                  key={`${index}val`}
                  value={defaultTo(
                    prop('label', item),
                    path([prop('value', item), 'label'], allItemsByValue)
                  )}
                  removeValueFromInput={() => {
                    props.selectItem(
                      prop(
                        indexOf(item, props.selectedItems),
                        props.selectedItems
                      )
                    );
                  }}
                />
              ))}
            {!open && length(props.selectedItems) > showItem.num ? (
              <FOHTextListTruncated
                onPress={openDropdown}
                value={`+${length(props.selectedItems) - showItem.num}`}
              />
            ) : (
              <></>
            )}
            <Input
              onChangeText={props.onChangeSearch}
              value={props.searchValue}
              style={{
                // so people can see the input
                borderLeftWidth:
                  props.searchValue || props.selectedItems.length === 0 ? 0 : 1,
                borderLeftColor:
                  props.searchValue || props.selectedItems.length === 0
                    ? FOHColors.WHITE
                    : FOHColors.SILVER
              }}
              placeholder={
                length(props.selectedItems) > 0 ? '' : props.searchPlaceholder
              }
              onKeyPress={(e: any) => {
                const key = path(['nativeEvent', 'key'], e);
                if (
                  key === 'Backspace' &&
                  !props.searchValue &&
                  length(props.selectedItems) > 0
                ) {
                  const lastValue = last(props.selectedItems);
                  return lastValue && props.selectItem(lastValue);
                } else if (
                  key === 'Enter' &&
                  props.searchValue &&
                  !props.noFreeText
                ) {
                  props.selectItem({
                    value: props.searchValue,
                    label: `"${props.searchValue}"`
                  });
                  props.onChangeSearch('');
                }
                props.onKeyPressSearchBar && props.onKeyPressSearchBar(e);
              }}
              // multiline={open}
              onFocus={openDropdown}
              disabled={props.disabled}
            />
          </Container>
          {open ? (
            <DropDown
              style={{
                height: open ? 300 : 0,
                width: '100%',
                zIndex:
                  (defaultTo(0, path(['style', 'zIndex'], props)) as number) +
                  11,
                position: 'absolute',
                top: height + 6,
                borderColor: FOHColors.BACKGROUND_GRAY,
                borderWidth: 1,
                borderStyle: 'solid',
                borderRadius: 4
              }}
            >
              <ScrollView>
                <FOHSpace />
                {!props.noFreeText && props.searchValue ? (
                  <TouchableOpacity
                    onPress={() => {
                      props.selectItem({
                        value: props.searchValue,
                        label: `"${props.searchValue}"`
                      });
                      props.onChangeSearch('');
                    }}
                  >
                    {props.searchValue ? (
                      <FOHLabel
                        style={{
                          color: FOHColors.GRAYSCALE_900
                        }}
                      >
                        {props.selectFreeTextLabel}
                        <FOHSpaceSmall />
                        <FOHLabelBold
                          style={{
                            color: FOHColors.PRIMARY_TEAL_P1,
                            textDecorationLine: 'underline'
                          }}
                        >
                          {`"${props.searchValue}"`}
                        </FOHLabelBold>
                        <FOHSpaceSmall />
                        <FOHSmallDetailLabel
                          style={{
                            color: FOHColors.GRAYSCALE_400
                          }}
                        >
                          {props.searchDetailLabel}
                        </FOHSmallDetailLabel>
                      </FOHLabel>
                    ) : (
                      <></>
                    )}
                    <FOHSpace />
                  </TouchableOpacity>
                ) : (
                  <></>
                )}
                {props.loading ? (
                  <View
                    style={{
                      paddingRight: 24
                    }}
                  >
                    <FOHCircleLoader />
                  </View>
                ) : (
                  props.sections.map(section => (
                    <View key={prop('title', section)}>
                      {length(prop('items', section)) > 0 ? (
                        <>
                          <FOHHeaderH4
                            style={{
                              color: FOHColors.SILVER,
                              textAlign: 'left'
                            }}
                          >
                            {prop('title', section)}
                          </FOHHeaderH4>
                          <FOHSpaceSmall />
                          <FOHSelectCheckboxTable
                            items={section.items}
                            selected={props.selectedItems.map(
                              item => item.value
                            )}
                            selectItem={value => {
                              const item = find(
                                propEq('value', value),
                                section.items
                              );
                              item &&
                                props.selectItem({
                                  value: prop('value', item),
                                  label: prop('label', item)
                                });
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </View>
                  ))
                )}
              </ScrollView>
            </DropDown>
          ) : (
            <></>
          )}
        </View>
      </FOHRequired>
    </View>
  );
};

export const FOHDropDownSearch = DropDownSearch;

const Input = styled(TextInput)`
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  display: flex;
  align-items: center;
  height: 14px;
  flex-direction: row;
  padding: 10px;
  padding-left: 0px;
  outline-color: transparent;
  flex: 1;
  outline-color: transparent;
`;

const Container = styled(View)`
  background: ${FOHColors.WHITE};
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: row;
  padding: 10px;
  border-radius: 4px;
`;

const DropDown = styled(View)`
  height: 350px;
  background-color: ${FOHColors.WHITE};
  width: 327px;
  justify-content: left;
  padding-left: 18px;
  border-radius: 4px;
  z-index: 11;
  border-radius: 4px;
`;
