import React, { useState } from 'react';

import { prop, length, path } from 'ramda';
import { useTranslation } from 'react-i18next';

import { useIsMobile } from '../../utils';
import {
  FOHButton,
  FOHInverseButton,
  FOHView,
  FOHErrorLabel,
  FOHWelcomeLetterSecondForm
} from '../../components';
import decoration from '../../images/welcome_letter.svg';
import { useGetCandidateQuery } from '../../graphql/generated';
import { FEED } from '../../constants';
import { useNavigation } from '../../utils/navigation';

import { withReactiveModal } from '../Navigation/withReactiveModal';
import { useCandidateFeedContext } from '../CandidateFeed/useCandidateFeedContext';

import { useLetter } from './useLetter';
import { WelcomeLetterFormModal } from './WelcomeLetterFormModal';

const WelcomeLetterSecond = props => {
  const { isInSettings } = props;
  const { t } = useTranslation('Welcome');
  const { isMobile } = useIsMobile();

  const {
    candidateHandle,
    links,
    setLinks,
    customNote,
    setCustomNote,
    includeCustomNote,
    setIncludeCustomNote,
    includeLink,
    setIncludeLink,
    error
  } = props;

  const [link, setLink] = useState('');

  const addLink = () => {
    setLinks([...links, link]);
    setLink('');
  };

  const candidateQuery = useGetCandidateQuery({
    skip: !candidateHandle,
    variables: { handle: candidateHandle }
  });

  const candidateName = path(
    ['data', 'profile', 'user', 'firstName'],
    candidateQuery
  )
    ? `${path(['data', 'profile', 'user', 'firstName'], candidateQuery)} ${path(
        ['data', 'profile', 'user', 'lastName'],
        candidateQuery
      )}`
    : '';

  return (
    <FOHView
      style={{
        alignItems: 'flex-start',
        backgroundColor: isInSettings
          ? '#f5f5f5'
          : isMobile
          ? '#ffffff'
          : undefined,
        width: isInSettings || isMobile ? '100%' : 716,
        paddingLeft: 24,
        padddingRight: 24,
        paddingTop: 24
      }}
    >
      <FOHView
        style={{
          paddingLeft: 24,
          paddingRight: 48,
          width: '100%'
        }}
      >
        <FOHWelcomeLetterSecondForm
          isMobile={isMobile}
          titleLabel={t('welcomeLetterTitle')}
          detailLabel={t('welcomeLetterDetail')}
          candidateName={candidateName}
          decorationImage={decoration}
          customNoteLabel={t('customNoteLabel')}
          includeCustomNote={includeCustomNote}
          setIncludeCustomNote={setIncludeCustomNote}
          customNote={customNote}
          setCustomNote={setCustomNote}
          customNotePlaceholder={t('customNotePlaceholder')}
          customNoteHelper={t('customNoteHelper')}
          // documentLabel={t('documentLabel')}
          // includeDocument={includeDocument}
          // setIncludeDocument={setIncludeDocument}
          linkLabel={t('linkLabel')}
          includeLink={includeLink}
          setIncludeLink={setIncludeLink}
          links={links}
          setLinks={setLinks}
          addLinkLabel={t('addLinkLabel')}
          addLink={addLink}
          linkPlaceholder={t('linkplaceholder')}
          link={link}
          setLink={setLink}
        />
      </FOHView>
      <FOHView
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          paddingLeft: 40
        }}
      >
        {length(error) > 0 && (
          <FOHErrorLabel style={{ paddingBottom: '20px' }}>
            • {error.map(e => prop('messages', e)).join('\n\n')}
          </FOHErrorLabel>
        )}
      </FOHView>
      <FOHView
        style={{
          flexDirection: 'row',
          alignItems: 'space-between',
          width: '100%',
          flex: 1,
          paddingLeft: 24,
          paddingRight: 48,
          paddingTop: 24
        }}
      >
        <FOHView style={{ flex: 1, alignItems: 'flex-start' }}>
          <FOHInverseButton
            title={t('common:previous')}
            onPress={() => props.close()}
            style={{ width: 200 }}
          />
        </FOHView>
        <FOHView style={{ flex: 1, alignItems: 'flex-end' }}>
          <FOHButton
            title={
              props.isSubmitDisabled
                ? t('actions:sendingLetter')
                : t('common:send')
            }
            onPress={props.sendDocument}
            style={{ width: 200 }}
            disabled={props.isSubmitDisabled}
          />
        </FOHView>
      </FOHView>
    </FOHView>
  );
};

export const WelcomeLetterSecondFormModal =
  withReactiveModal(WelcomeLetterSecond);

export const WelcomeModals = props => {
  const { open, employerCandidate } = props;
  const { navigateTo } = useNavigation();

  const [openWelcomeLetter, setOpenWelcomeLetter] = useState(true);
  const [openWelcomeLetterSecond, setOpenWelcomeLetterSecond] = useState(false);
  const [sending, setSending] = useState(false);
  const {
    customNote,
    setCustomNote,
    letter,
    setLetter,
    files,
    setFiles,
    links,
    setLinks,
    createEmploymentDocument,
    includeCustomNote,
    setIncludeCustomNote,
    includeLink,
    setIncludeLink,
    validateData,
    error,
    setError
  } = useLetter(props);

  const { activeStageId, positionFilters, locationFilters, search } =
    useCandidateFeedContext();

  return (
    <>
      <WelcomeLetterFormModal
        {...props}
        open={openWelcomeLetter && open}
        close={() => {
          navigateTo({
            pathname: FEED
          });
        }}
        files={files}
        setFiles={setFiles}
        onSendWelcomeLetter={async variables => {
          const err = await validateData(variables, 'letter-1');
          setError(err);
          if (length(err) > 0) {
            return;
          }
          setLetter(variables);
          setOpenWelcomeLetterSecond(true);
          setOpenWelcomeLetter(false);
        }}
        error={error}
      />

      <WelcomeLetterSecondFormModal
        {...props}
        open={openWelcomeLetterSecond && open}
        close={() => {
          setOpenWelcomeLetter(true);
          setOpenWelcomeLetterSecond(false);
        }}
        includeCustomNote={includeCustomNote}
        setIncludeCustomNote={setIncludeCustomNote}
        customNote={customNote}
        setCustomNote={setCustomNote}
        files={files}
        setFiles={setFiles}
        includeLink={includeLink}
        setIncludeLink={setIncludeLink}
        links={links}
        setLinks={setLinks}
        isSubmitDisabled={sending}
        sendDocument={async () => {
          setSending(true);
          const note = includeCustomNote ? customNote : '';
          setLetter({ note: note, ...letter });
          const err = await validateData({ note: note, ...letter }, 'letter-2');
          setError(err);
          if (length(err) > 0) {
            setSending(false);
            return;
          }
          const urlErr = await createEmploymentDocument({
            note: note,
            ...letter
          });
          if (urlErr) {
            setError(urlErr);
            return;
          }

          if (activeStageId !== props.hiredStage.id) {
            await props.moveEmployerCandidates({
              employerCandidateIds: [prop('id', employerCandidate)],
              newStage: prop('id', props.hiredStage),
              oldStage: activeStageId,
              positions: positionFilters,
              locations: locationFilters,
              search,
              notificationStatusToUpdate: 'done'
            });

            props.stagesQuery.refetch();
          }
          setSending(false);
          setOpenWelcomeLetter(true);
          setOpenWelcomeLetterSecond(false);
          props.setNextCandidate(prop('id', props.hiredStage));
          navigateTo({
            pathname: FEED
          });
        }}
        error={error}
      />
    </>
  );
};
