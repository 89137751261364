import React, { useEffect, useRef, useState } from 'react';

import { defaultTo, path, prop } from 'ramda';
import { useTranslation } from 'react-i18next';
import {
  Channel,
  sendBirdSelectors,
  useSendbirdStateContext
} from '@sendbird/uikit-react';
import {
  ChannelProvider,
  useChannelContext
} from '@sendbird/uikit-react/Channel/context';
import { ConnectionState } from '@sendbird/chat';
import { useDebounce } from 'use-hooks';

import {
  FOHMessageTemplate,
  FOHSmallChannelHeader,
  FOHView,
  FullWidthCard
} from '../../components';
import {
  useGetChannelAndFullDetailsQuery,
  useGetEmployerCandidateForHandleQuery,
  useDismissNotificationMutation
} from '../../graphql/generated';
import {
  CANDIDATE_PROFILE,
  JOBS,
  SENDBIRD_CHAT,
  TEMPLATES
} from '../../constants';
import { handleMutation, useIsMobile } from '../../utils';
import { useNavigation } from '../../utils/navigation';

import { useCandidateFeedContext } from '../CandidateFeed/useCandidateFeedContext';
import { useGetMeData } from '../SignIn';
import { useFilteredEmployerMessageTemplates } from '../EmployerMessageTemplate/useFilteredEmployerMessageTemplates';

import { EmployerMagicLinkMessage } from './EmployerMagicLinkMessage';
import { CustomMessage } from './CustomMessage';
import { sendBirdAppId } from './SendBirdChat';
import { useChatWindow } from './useChatWindow';
import { ChatInput } from './ChatInput';
import { needsLinksHidden } from './utils';

export const populateMessage = (
  message,
  locationName,
  positionName,
  candidateName,
  positionLink
) => {
  // locationName and positionName can be undefined
  message = message.replaceAll('{{Candidate First Name}}', candidateName);
  if (locationName) {
    message = message.replaceAll('{{Location Name}}', locationName);
  }
  if (positionName) {
    message = message.replaceAll('{{Position Name}}', positionName);
  }
  if (positionLink) {
    message = message.replaceAll('{{Position Link}}', positionLink);
  }
  return message;
};

const ChatHeader = ({
  channel,
  channelDetailsQuery,
  handle,
  setChannelUrl,
  setExpanded,
  width,
  expanded,
  setOpenOptions,
  openOptions,
  channelUrl
}) => {
  const { navigateTo, location } = useNavigation();
  const sbContext = useSendbirdStateContext();
  const sdkInstance = sendBirdSelectors.getSdk(sbContext);

  const talkingTo = prop(
    0,
    defaultTo([], path(['members'], channel)).filter(
      member => member.metaData.is_candidate === 'True'
    )
  );
  const { t } = useTranslation('ChatFeature');

  return (
    <FOHView
      style={{
        zIndex: 99,
        // cover up sendbirds rough edges since channel header has a border radius of 6
        top: -5,
        left: -1,
        width: width + 1
      }}
    >
      <FOHSmallChannelHeader
        isActionsDisabled={
          !sbContext?.config?.isOnline ||
          sdkInstance?.connectionState !== ConnectionState.OPEN
        }
        actionsMenuStyle={{
          cursor: 'not-allowed',
          pointerEvents: 'none',
          opacity: 0.5
        }}
        channelName={path(['nickname'], talkingTo)}
        channelDetail={path(
          ['data', 'channel', 'lastPosition', 'positionName'],
          channelDetailsQuery
        )}
        hasUnread={prop('unreadMessageCount', channel) > 0}
        onPressChannelDetail={() => {
          navigateTo({
            pathname: `${JOBS}/${path(
              ['data', 'channel', 'lastPosition', 'slug'],
              channelDetailsQuery
            )}`,
            search: location.search
          });
        }}
        avatar={path(['profileUrl'], talkingTo)}
        onPressAvatar={() => {
          navigateTo({
            pathname: `${CANDIDATE_PROFILE}/${handle}`,
            search: location.search
          });
        }}
        toggleExpanded={() => {
          setExpanded(!expanded);
        }}
        expanded={expanded}
        close={() => {
          setChannelUrl('');
        }}
        onPressOptions={() => {
          setOpenOptions(!openOptions);
        }}
        openOptions={openOptions}
        options={[
          {
            title: t('goToFullChat'),
            onPress: () => {
              setChannelUrl('');
              navigateTo(`${SENDBIRD_CHAT}/${channelUrl}`);
            }
          }
        ]}
      />
    </FOHView>
  );
};

const ChatWindow = () => {
  const { channelUrl, setChannelUrl, templateMessage, setTemplateMessage } =
    useChatWindow();
  const { t } = useTranslation('ChatFeature');

  const { data: me } = useGetMeData();

  const sendbirdUserId = prop('uuid', me);

  const channelContext = useChannelContext();

  const [dismissNotification] = useDismissNotificationMutation();
  const { navigateTo } = useNavigation();

  const channelDetailsQuery = useGetChannelAndFullDetailsQuery({
    skip: !channelUrl,
    variables: { sendbirdChannelId: channelUrl }
  });
  const [expanded, setExpanded] = useState(false);
  const [openOptions, setOpenOptions] = useState(false);
  const [message, setMessage] = useState('');
  const [height, setHeight] = useState(52);
  const [candidateFirstName, setCandidateFirstName] = useState('');

  const handle = path(
    ['data', 'channel', 'candidateUser', 'candidateProfile', 'handle'],
    channelDetailsQuery
  );

  // candidate feed context
  // so we can set the candidate if we need to navigate
  const { setCandidateHandle, setEmployerCandidate, setActiveStageId } =
    useCandidateFeedContext();

  const isCandidate = false; // employer only view

  const employerCandidateForHandle = useGetEmployerCandidateForHandleQuery({
    skip: isCandidate,
    variables: { handle }
  });

  const employerCandidate = path(
    ['data', 'profile', 'employerCandidate'],
    employerCandidateForHandle
  );

  const inputFile = useRef(null);
  const [, setFileToUpload] = useState();

  const { isMobile } = useIsMobile();

  const width = isMobile ? 290 : expanded ? 650 : 550;

  const [search, setSearch] = useState('');
  const [template, setTemplate] = useState();
  const [openMessageTemplate, setOpenMessageTemplate] = useState(false);
  const [, setShowChannel] = useState(false);

  const debouncedSearchText = useDebounce(search, 1000);

  const { data: templates } = useFilteredEmployerMessageTemplates({
    isSkipped: isCandidate,
    searchText: debouncedSearchText
  });

  const positionName = path(
    ['data', 'channel', 'lastPosition', 'positionName'],
    channelDetailsQuery
  );

  const positionLink = path(
    ['data', 'channel', 'lastPosition', 'shortUrl'],
    channelDetailsQuery
  );

  const locationName = path(
    ['data', 'channel', 'location', 'name'],
    channelDetailsQuery
  );
  useEffect(() => {
    if (templateMessage && candidateFirstName) {
      if (templateMessage === '***openMessageTemplate***') {
        setOpenMessageTemplate(true);
        setTemplateMessage('');
      } else {
        setMessage(
          populateMessage(
            templateMessage,
            locationName,
            positionName,
            candidateFirstName,
            positionLink
          )
        );
        setTemplateMessage('');
      }
    }
  }, [
    templateMessage,
    positionName,
    locationName,
    candidateFirstName,
    setTemplateMessage,
    positionLink
  ]);

  useEffect(() => {
    if (!channelDetailsQuery.loading) {
      const candidateFName = path(
        ['data', 'channel', 'candidateUser', 'firstName'],
        channelDetailsQuery
      );
      setCandidateFirstName(candidateFName);
    }
  }, [channelDetailsQuery]);

  useEffect(() => {
    (async () => {
      if (path(['chatNotification', 'id'], employerCandidate)) {
        await handleMutation(
          dismissNotification({
            variables: {
              id: path(['chatNotification', 'id'], employerCandidate)
            }
          })
        );
      }
    })();
  }, [dismissNotification, employerCandidate]);

  return channelUrl && sendBirdAppId ? (
    <>
      <FOHView
        style={{
          position: 'fixed',
          right: isMobile ? 0 : 30,
          bottom: 10,
          width: width,
          height: isMobile ? 500 : expanded ? 600 : 550,
          zIndex: 98
        }}
      >
        <Channel
          onChatHeaderActionClick={() => {
            // setShowSettings(true);
          }}
          sendbirdUserId={sendbirdUserId}
          channelUrl={channelUrl}
          reconnectOnIdle
          // queries={queries}
          renderChannelHeader={() => {
            const url = prop('channelUrl', channelContext);
            if (url) {
              setShowChannel(true);
            }
            return (
              <>
                {channelUrl ? (
                  <ChatHeader
                    channel={channelContext?.currentGroupChannel}
                    channelDetailsQuery={channelDetailsQuery}
                    handle={handle}
                    setChannelUrl={setChannelUrl}
                    setExpanded={setExpanded}
                    width={width}
                    expanded={expanded}
                    setOpenOptions={setOpenOptions}
                    openOptions={openOptions}
                    channelUrl={channelUrl}
                  />
                ) : null}
              </>
            );
          }}
          renderMessageInput={() => (
            <ChatInput
              message={message}
              openMessageTemplate={openMessageTemplate}
              setOpenMessageTemplate={setOpenMessageTemplate}
              height={height}
              setHeight={setHeight}
              setMessage={setMessage}
              employerCandidate={employerCandidate}
              setActiveStageId={setActiveStageId}
              setCandidateHandle={setCandidateHandle}
              setEmployerCandidate={setEmployerCandidate}
              handle={handle}
              channelDetailsQuery={channelDetailsQuery}
              inputFile={inputFile}
              setFileToUpload={setFileToUpload}
            />
          )}
          // https://codesandbox.io/s/custommessage-ivi9e?file=/src/CustomizedApp.js:1096-1469
          renderMessage={({ message: _message }) => {
            // RENDER INTERVIEW CARDS HERE
            const text = _message.message;

            if (!text) {
              return;
            }

            const isMyMessage =
              path(['sender', 'userId'], _message) == sendbirdUserId;

            const hideLink = needsLinksHidden(text, isCandidate);

            if (hideLink) {
              return (
                <FOHView
                  style={{
                    alignItems: isMyMessage ? 'flex-end' : 'flex-start',
                    flex: 1
                  }}
                >
                  <EmployerMagicLinkMessage message={_message} />
                </FOHView>
              );
            }
            if (
              ['offer-decline', 'offer-accept'].includes(_message.customType)
            ) {
              return (
                <FOHView
                  style={{
                    alignItems: isMyMessage ? 'flex-end' : 'flex-start',
                    flex: 1
                  }}
                >
                  <CustomMessage
                    message={_message}
                    messageType={_message.customType}
                  />
                </FOHView>
              );
            }
          }}
        />
      </FOHView>
      {openMessageTemplate ? (
        <FOHView
          style={{
            position: 'fixed',
            right: isMobile ? 0 : 200,
            width: 673,
            height: 397,
            bottom: 0,
            zIndex: 99
          }}
        >
          <FullWidthCard
            shadow={true}
            style={{
              maxWidth: 673,
              padding: 6
            }}
          >
            <FOHMessageTemplate
              templateTitle={t('templateLabel')}
              onChangeSearch={value => setSearch(value)}
              searchValue={search}
              searchPlaceholder={t('searchTemplatePlaceholder')}
              scrollHeight={240}
              messageTemplates={templates}
              selectedTemplate={template}
              editTemplateLabel={t('editTemplates')}
              useLabel={t('useItLabel')}
              setTemplate={setTemplate}
              editTemplate={() => {
                setOpenMessageTemplate(false);
                setChannelUrl('');
                navigateTo(TEMPLATES);
              }}
              onUseTemplate={() => {
                if (template) {
                  setMessage(
                    populateMessage(
                      template.messageContent,
                      locationName,
                      positionName,
                      candidateFirstName,
                      positionLink
                    )
                  );
                }
                setOpenMessageTemplate(!openMessageTemplate);
              }}
              close={() => {
                setOpenMessageTemplate(false);
              }}
            />
          </FullWidthCard>
        </FOHView>
      ) : (
        <></>
      )}
    </>
  ) : null;
};

const ChatWindowContainer = () => {
  const { channelUrl } = useChatWindow();

  return (
    <ChannelProvider channelUrl={channelUrl}>
      <ChatWindow />
    </ChannelProvider>
  );
};
export default ChatWindowContainer;
