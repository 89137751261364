import React, { useRef } from 'react';

import styled from 'styled-components/native';
import { useHover } from 'react-native-web-hooks';
import { Text } from 'react-native';

import {
  FOHColors,
  FOHFonts,
  FOHView,
  FOHEditMarker,
  FOHLabel,
  FOHTouchableOpacity
} from '../../ingredients';

export interface FOHLinkProps {
  text?: string;
  textStyle?: any;
  linkText: string;
  linkStyle?: any;
  isDisabled?: boolean;
  onPress: () => void;
  Icon?: JSX.Element;
  iconSize?: any;
}

// This is for new tabs
export const FOHLink = ({
  linkStyle,
  linkText,
  onPress,
  text,
  textStyle,
  isDisabled,
  Icon,
  iconSize
}: FOHLinkProps) => {
  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);

  return (
    <FOHLabel>
      {text ? (
        <FOHLabel
          style={{
            fontSize: 12,
            marginRight: '.5ch',
            ...textStyle
          }}
        >
          {text}
        </FOHLabel>
      ) : (
        <></>
      )}

      <FOHTouchableOpacity
        onPress={!isDisabled ? onPress : () => {}}
        ref={hoverRef}
        style={{
          //@ts-ignore The `cursor` property expects a value of type `StyleProp<ViewStyle>`, which is a type that represents a CSS style property
          cursor: !isDisabled ? 'pointer' : 'normal'
        }}
      >
        <FOHView
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor:
              !isDisabled && isHovered ? FOHColors.GRAYSCALE_100 : '',
            padding: 4
          }}
        >
          {Icon ? (
            // @ts-ignore as we're using JSX element as component
            <Icon
              style={{
                width: iconSize ? iconSize : 12,
                height: iconSize ? iconSize : 12,
                marginRight: 4,
                tintColor: isDisabled
                  ? FOHColors.GRAYSCALE_400
                  : linkStyle?.color
                  ? linkStyle.color
                  : isHovered
                  ? FOHColors.PRIMARY_TEAL_300
                  : FOHColors.PRIMARY_TEAL_P1
              }}
            />
          ) : (
            <FOHEditMarker
              style={{
                width: linkStyle?.width ? linkStyle.width : 12,
                height: linkStyle?.height ? linkStyle.height : 12,
                marginRight: 4,
                tintColor: isDisabled
                  ? FOHColors.GRAYSCALE_400
                  : linkStyle?.color
                  ? linkStyle.color
                  : isHovered
                  ? FOHColors.PRIMARY_TEAL_300
                  : FOHColors.PRIMARY_TEAL_P1
              }}
            />
          )}
          <LinkText
            style={{
              ...linkStyle,
              color: isDisabled
                ? FOHColors.GRAYSCALE_400
                : linkStyle?.color
                ? linkStyle.color
                : isHovered
                ? FOHColors.PRIMARY_TEAL_300
                : FOHColors.PRIMARY_TEAL_P1,
              textDecorationColor: isDisabled
                ? FOHColors.GRAYSCALE_400
                : linkStyle?.color
                ? linkStyle.color
                : isHovered
                ? FOHColors.PRIMARY_TEAL_300
                : FOHColors.PRIMARY_TEAL_P1
            }}
          >
            {linkText}
          </LinkText>
        </FOHView>
      </FOHTouchableOpacity>
    </FOHLabel>
  );
};

const LinkText = styled(Text)`
  font-family: ${FOHFonts.BOLD};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  text-decoration: underline;
`;
