import React from 'react';

import styled from 'styled-components/native';
import { Image, View } from 'react-native';
import { path, defaultTo } from 'ramda';

import avatar from '../../../assets/avatar.svg';

import { FOHColors } from '../../ingredients/colors/FOHColors';

interface NavImageProps {
  photo?: string;
  highlight?: boolean;
  style?: any;
}

export const FOHProfileImage = React.memo((props: NavImageProps) => {
  return (
    <LightGoldCircle
      style={{
        borderWidth: props.highlight ? 4 : 0,

        width: defaultTo(36, path(['style', 'width'], props)) + 6,

        height: defaultTo(36, path(['style', 'height'], props)) + 6
      }}
    >
      <GoldCircle
        style={{
          borderWidth: props.highlight ? 2 : 0,

          width: defaultTo(36, path(['style', 'width'], props)) + 4,

          height: defaultTo(36, path(['style', 'height'], props)) + 4
        }}
      >
        <NavImage
          source={{ uri: props.photo || avatar }}
          style={{
            width: 36,
            height: 36,
            backgroundColor: '#ffffff',
            ...props.style
          }}
          testID="profile-image"
        />
      </GoldCircle>
    </LightGoldCircle>
  );
});

const GoldCircle = styled(View)`
  border-radius: 50%;
  border-color: ${FOHColors.GOLD};
  width: 36px;
  border-width: 2px;
  justify-content: center;
  align-items: center;
  height: 36px;
`;

const LightGoldCircle = styled(View)`
  border-radius: 50%;
  border-color: ${FOHColors.LIGHT_GOLD};
  width: 40px;
  border-width: 4px;
  justify-content: center;
  align-items: center;
  height: 40px;
`;

const NavImage = styled(Image)`
  border-radius: 50%;
`;
