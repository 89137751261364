import React, { Fragment, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-hooks';
import { defaultTo, path, length } from 'ramda';

import {
  FOHLabel,
  FOHLabelBold,
  FOHColors,
  FOHButton,
  FOHView,
  FOHSpace,
  FOHFilterIcon,
  FOHLookingStatusIcon,
  FOHResumeIcon,
  FOHDivider,
  FOHButtonBordered,
  FOHTextField,
  FOHLink,
  FOHLabeledRadio,
  FOHLabeledCheckbox,
  FOHDropDownSearch,
  FOHZipInput
} from '../../components';
import { multiSelectItem } from '../../utils';
import {
  useCandidateSearchFilterGroupsQuery,
  useSearchAheadNetworkQuery,
  useSearchProfilesCountQuery
} from '../../graphql/generated';

import { useCandidateFeedFiltersContext } from '../CandidateFeed/useCandidateFeedFiltersContext';

import { useCandidateNetworkSearchContext } from './useCandidateNetworkSearchHook';

const MAXWIDTH = 1340;

const ResumeIcon = () => (
  <FOHResumeIcon style={{ tintColor: FOHColors.NARO_GREY, top: 3 }} />
);

const ClockBackIcon = () => (
  <FOHView style={{ marginTop: 5 }}>
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 0.333374C10.682 0.333374 13.6667 3.31804 13.6667 7.00004C13.6667 10.682 10.682 13.6667 7 13.6667C3.318 13.6667 0.333328 10.682 0.333328 7.00004H1.66666C1.66666 9.94537 4.05466 12.3334 7 12.3334C9.94533 12.3334 12.3333 9.94537 12.3333 7.00004C12.3333 4.05471 9.94533 1.66671 7 1.66671C5.16666 1.66671 3.54933 2.59137 2.59 4.00004H4.33333V5.33337H0.333328V1.33337H1.66666V3.00004C2.88266 1.38004 4.81933 0.333374 7 0.333374ZM7.66666 3.66671V6.72337L9.82866 8.88537L8.88533 9.82871L6.33333 7.27537V3.66671H7.66666Z"
        fill="#171717"
      />
    </svg>
  </FOHView>
);

const FilterGroupIcons = {
  candidatesThatHave: <ResumeIcon />,
  candidatesWhoAre: <FOHLookingStatusIcon style={{ top: 3 }} />,
  candidatesLastActiveAt: <ClockBackIcon />
};

export const Filters = props => {
  const filtersContext = props.isCandidateFeed
    ? useCandidateFeedFiltersContext
    : useCandidateNetworkSearchContext;
  const {
    profileDetailFilters,
    addFilterItem,
    removeFilterItem,
    clearFiltersByGroup,
    setIsFilterDrawerOpen,
    searchTermsValues,
    zip,
    distance,
    sortBy
  } = filtersContext();

  const debouncedSearchTerms = useDebounce(searchTermsValues, 1000);
  const debouncedZip = useDebounce(zip, 1000);
  const debouncedDistance = useDebounce(distance, 300);

  const variables = {
    searchTerms: debouncedSearchTerms,
    profileDetailFilters,
    addressPostalCode:
      debouncedZip && length(debouncedZip) === 5 ? debouncedZip : undefined,
    milesFromCenterZip: debouncedDistance
      ? parseInt(debouncedDistance)
      : undefined,
    sortBy
  };

  const searchProfileFilterCountQuery = useSearchProfilesCountQuery({
    variables
  });

  const profilesCount = path(
    ['data', 'searchProfilesCount', 'count'],
    searchProfileFilterCountQuery
  );

  const filtersQuery = useCandidateSearchFilterGroupsQuery();

  const groups = defaultTo(
    [],
    path(['data', 'candidateSearchFilterGroups'], filtersQuery)
  );

  const { t } = useTranslation('CandidateNetworkSearch');

  return (
    <FOHView
      style={{ width: '100%', background: '#ffffff', alignItems: 'flex-start' }}
      key={profileDetailFilters.length}
    >
      <FOHView
        style={{
          paddingHorizontal: 48,
          paddingTop: 24,
          paddingBottom: 80,
          maxWidth: MAXWIDTH,
          width: '100%'
        }}
      >
        {groups.map((group, index) => {
          return (
            <FOHView
              style={{
                padding: 16,
                paddingBottom: 0,
                backgroundColor: '#FCFCFC',
                borderRadius: 6,
                marginTop: index !== 0 ? 16 : 0
              }}
              key={group}
            >
              <FOHView style={{ flexDirection: 'row', alignItems: 'center' }}>
                <FOHLabelBold style={{ marginRight: 8 }}>
                  {FilterGroupIcons[group.name]}
                </FOHLabelBold>
                <FOHLabelBold>{t(group.name)}</FOHLabelBold>
              </FOHView>
              <FOHView
                style={{
                  position: 'absolute',
                  right: 16,
                  top: 12
                }}
              >
                <FOHLink
                  linkStyle={{
                    fontSize: 14
                  }}
                  isDisabled={
                    group.filterItems.filter(item =>
                      profileDetailFilters.includes(item)
                    ).length === 0
                  }
                  onPress={() => {
                    clearFiltersByGroup(group.name);
                  }}
                  linkText={
                    group.filterItems.filter(item =>
                      profileDetailFilters.includes(item)
                    ).length === 0
                      ? t('LocationFeedFeature:clearSelectionLabelShort')
                      : `${t('LocationFilterFeature:clearFiltersLabel')}(${
                          group.filterItems.filter(item =>
                            profileDetailFilters.includes(item)
                          ).length
                        })`
                  }
                ></FOHLink>
              </FOHView>
              <FOHSpace />
              <FOHView style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                {group.filterItems.map(item => {
                  return (
                    <Fragment key={item}>
                      {group.name === 'candidatesLastActiveAt' ? (
                        <FOHView
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: '30%',
                            paddingBottom: 16
                          }}
                        >
                          <FOHLabeledRadio
                            selected={profileDetailFilters.includes(item)}
                            label={t(item)}
                            setSelected={() => {
                              clearFiltersByGroup(group.name);
                              addFilterItem(item);
                            }}
                          />
                        </FOHView>
                      ) : (
                        <FOHView
                          style={{
                            width:
                              group.name === 'candidatesWhoAre' ? '24%' : '30%',
                            paddingBottom: 16
                          }}
                        >
                          <FOHLabeledCheckbox
                            selected={
                              profileDetailFilters
                                ? profileDetailFilters.includes(item)
                                : false
                            }
                            checkboxLabel={t(item)}
                            checkboxIcon={
                              group.name === 'candidatesWhoAre'
                                ? [item.toUpperCase()]
                                : null
                            }
                            setSelected={() => {
                              profileDetailFilters.includes(item)
                                ? removeFilterItem(item)
                                : addFilterItem(item);
                            }}
                            style={{
                              fontSize: 14,
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          />
                        </FOHView>
                      )}
                      <FOHSpace />
                    </Fragment>
                  );
                })}
              </FOHView>
            </FOHView>
          );
        })}
      </FOHView>
      <FOHView
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
          background: '#FFFFFF',
          boxShadow: '4px 0px 16px rgba(23, 23, 23, 0.06)',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          flexDirection: 'row',
          paddingVertical: 8,
          paddingHorizontal: 48
        }}
      >
        {!props.hideCandidateCount && (
          <FOHLabel
            style={{ color: '#878787' }}
            testID="CandidateDatabase-filters-candidate-matches"
          >
            <i>
              {profilesCount} {t('candidateMatches')}
            </i>
          </FOHLabel>
        )}

        <FOHButton
          title="View Results"
          selected={true}
          style={{ width: 128, height: 40, marginStart: 13 }}
          onPress={() => {
            setIsFilterDrawerOpen(false);
          }}
        />
      </FOHView>
    </FOHView>
  );
};

export const BoxOverLay = props => {
  const {
    isFilterDrawerOpen,
    style,
    hideDivider = false,
    filterContainerStyle,
    isCandidateFeed
  } = props;

  return (
    <FOHView
      style={{
        backgroundColor: isFilterDrawerOpen ? 'rgba(0, 0, 0, 0.64)' : '',
        width: '100%',
        height: isFilterDrawerOpen ? '100vh' : 'auto',
        position: isFilterDrawerOpen ? 'absolute' : 'relative',
        top: isFilterDrawerOpen ? (isCandidateFeed ? 50 : 8) : 0,
        left: 0,
        right: 0,
        zIndex: 100,
        alignItems: 'center',
        ...style
      }}
    >
      <FOHView
        style={{
          width: '100%',
          backgroundColor: isFilterDrawerOpen ? '#FFFFFF' : ''
        }}
      >
        <FOHView style={{ paddingHorizontal: 24 }}>{props.children}</FOHView>
        <FOHSpace height={8} />
        {!hideDivider ? <FOHDivider style={{ zIndex: '-1' }} /> : <></>}

        {isFilterDrawerOpen ? (
          <FOHView style={{ zIndex: '-1', ...filterContainerStyle }}>
            <Filters {...props} />
          </FOHView>
        ) : (
          <></>
        )}
      </FOHView>
    </FOHView>
  );
};

export const SearchAndFilters = props => {
  const filtersContext = props.isCandidateFeed
    ? useCandidateFeedFiltersContext
    : useCandidateNetworkSearchContext;
  const {
    searchTerms,
    zip,
    distance,
    isFilterDrawerOpen,
    setSearchTerms,
    setZip,
    setDistance,
    setIsFilterDrawerOpen,
    profileDetailFilters,
    isLoading
  } = filtersContext();

  const [searchText, setSearchText] = useState();
  const [isDistanceFocused, setIsDistanceFocused] = useState(false);

  const { t } = useTranslation('CandidateNetworkSearch');

  const debouncedSearchText = useDebounce(searchText, 500);

  const searchAheadNetworkQuery = useSearchAheadNetworkQuery({
    variables: {
      search: debouncedSearchText
    }
  });

  const searchSections = defaultTo(
    [],
    path(['data', 'searchAheadNetwork', 'groups'], searchAheadNetworkQuery)
  ).map(section => ({
    title: section.group,
    items: section.values.map(item => ({
      label: item.label,
      value: item.value
    }))
  }));

  return (
    <BoxOverLay isFilterDrawerOpen={isFilterDrawerOpen} {...props}>
      <FOHView
        style={{
          zIndex: 1,
          alignItems: 'center'
        }}
      >
        <FOHView
          style={{
            flexDirection: 'row',
            flexWrap: 'wrap',
            opacity: 1,
            width: '100%'
          }}
        >
          <FOHView
            style={{
              minWidth: 300,
              maxWidth: 360,
              width: '100%',
              marginTop: 8,
              marginRight: 8,
              height: 48
            }}
          >
            <FOHDropDownSearch
              onChangeSearch={text => {
                setSearchText(text);
              }}
              closeLabel={t('WorkHistoryFeature:close')}
              searchDetailLabel={t('searchDetailLabel')}
              searchValue={searchText}
              searchPlaceholder={t('searchDetailPlaceholder')}
              selectFreeTextLabel={t('common:searchFor1')}
              truncationLimit={1}
              clearSearch={() => {
                setSearchText('');
                setSearchTerms([]);
              }}
              sections={searchSections}
              selectedItems={searchTerms || []}
              selectItem={item => {
                multiSelectItem(item, setSearchTerms, searchTerms);
                setSearchText('');
              }}
              testID="CandidateDatabase-search-field"
              height={48}
            ></FOHDropDownSearch>
          </FOHView>
          <FOHView
            style={{ width: 150, marginTop: 8, marginRight: 8 }}
            testID="CandidateDatabase-zip-code-field"
          >
            <FOHZipInput
              placeholder={t('common:zipCodePlaceholder')}
              onChangeText={zipCode => {
                return length(zipCode) > 5 || isNaN(zipCode) || isLoading
                  ? () => {}
                  : setZip(zipCode);
              }}
              defaultValue={zip}
              value={zip}
              keyboardType="numeric"
              autoComplete="postal-code"
              containerStyle={{ height: 48 }}
              style={{ height: 42, paddingTop: zip ? 18 : 5, width: '85%' }}
            />
          </FOHView>
          <FOHView
            style={{ width: 150, marginTop: 8, marginRight: 8, height: 48 }}
            testID="CandidateDatabase-distance-field"
          >
            <FOHTextField
              style={{ height: 48 }}
              placeholder={t('distancePlaceholder')}
              value={distance}
              onChangeText={zipCode =>
                length(distance) > 5 || isNaN(distance)
                  ? setDistance('')
                  : setDistance(zipCode)
              }
              keyboardType="numeric"
              onFocus={() => {
                setIsDistanceFocused(true);
              }}
              onBlur={() => {
                setIsDistanceFocused(false);
              }}
              focused={isDistanceFocused}
            />
          </FOHView>
          <FOHView>
            <FOHButtonBordered
              icon={() => (
                <FOHFilterIcon
                  active={isFilterDrawerOpen}
                  style={{ tintColor: FOHColors.GRAYSCALE_700 }}
                />
              )}
              title={`${t('advancedFilters')}${
                profileDetailFilters.length
                  ? ` (${profileDetailFilters.length})`
                  : ''
              }`}
              onPress={() => {
                setIsFilterDrawerOpen(!isFilterDrawerOpen);
              }}
              selected={isFilterDrawerOpen ? true : false}
              opaque={true}
              style={{ width: 200, marginTop: 8, marginRight: 8, height: 48 }}
              testID="CandidateDatabase-filters-button"
            />
          </FOHView>
        </FOHView>
      </FOHView>
    </BoxOverLay>
  );
};
