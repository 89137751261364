import React, { FunctionComponent } from 'react';

import eye from '../../../images/question_gray.svg';

import { FOHImage, FOHColors } from '../../ingredients';
import { FOHIconProps } from '../../typings';

export const FOHBlackHelpIcon: FunctionComponent<FOHIconProps> = ({
  style
} = {}) => (
  <FOHImage
    source={{ uri: eye }}
    style={[
      {
        width: 24,
        height: 24,
        tintColor: FOHColors.BLACK
      },
      style
    ]}
  />
);
