import { useState, useEffect, useMemo } from 'react';

import { useDebounce } from 'use-hooks';
import { defaultTo, path, length } from 'ramda';

import { FOHInterviewAttendeesRole } from '../../components';
import { useGetEmployerUsersQuery } from '../../graphql/generated';
import { unwrapEdgesAt } from '../../utils';

import { findUserById } from './ShareScheduleFormModal';

const useInterviewAttendees = props => {
  const {
    loggedInUserId,
    employerCandidate,
    selectedLocation,
    isActionModalOpen
  } = props;

  const [selectedInterviewer, setSelectedInterviewer] =
    useState(loggedInUserId);
  const [interviewerSearch, setInterviewerSearch] = useState('');
  const [keepMeUpdated, setKeepMeUpdated] = useState(false);
  const [interviewAttendees, setInterviewAttendees] = useState([]);

  const debouncedSearchInterviewee = useDebounce(interviewerSearch, 1000);

  const createInterviewParticipants = (interviewerUser, candidateUser) => {
    return [
      {
        firstName: interviewerUser?.user?.firstName,
        lastName: interviewerUser?.user?.lastName,
        id: interviewerUser?.user?.id,
        uri: interviewerUser?.user?.profileImage,
        role: 'interviewer'
      },
      {
        firstName: candidateUser?.candidateProfile?.user?.firstName,
        lastName: candidateUser?.candidateProfile?.user?.lastName,
        uri: candidateUser?.candidateProfile?.user?.profileImage,
        id: candidateUser?.candidateProfile?.user?.id,
        role: 'candidate'
      }
    ];
  };

  const employerUsersQ = useGetEmployerUsersQuery({
    skip: !selectedLocation,
    variables: {
      location: selectedLocation,
      search: debouncedSearchInterviewee
    }
  });

  const interviewers = useMemo(() => {
    const employerUsers = unwrapEdgesAt(
      ['data', 'employerUsers', 'edges'],
      employerUsersQ
    );
    return (employerUsers || []).filter(team => !team.user.unclaimed);
  }, [employerUsersQ]);

  const interviewersOptions = useMemo(() => {
    return interviewers.map(interviewer => {
      const fullName = `${path(['user', 'firstName'], interviewer)} ${path(
        ['user', 'lastName'],
        interviewer
      )}`;
      const email = path(['user', 'email'], interviewer);
      return {
        label: `${fullName} - ${email}`,
        value: path(['user', 'id'], interviewer),
        sublabel: ''
      };
    });
  }, [interviewers]);

  const selectedInterviewerUser = useMemo(() => {
    if (length(interviewers) > 0 && selectedInterviewer) {
      return findUserById(interviewers, selectedInterviewer);
    }
    return null;
  }, [interviewers, selectedInterviewer]);

  const schedulerIsInterviewer = useMemo(
    () => defaultTo(false, selectedInterviewer === loggedInUserId),
    [selectedInterviewer, loggedInUserId]
  );

  const clearInterviewAttendeeStates = () => {
    setSelectedInterviewer(loggedInUserId);
    setInterviewerSearch('');
    setKeepMeUpdated(false);
    setInterviewAttendees([]);
  };

  const manageInterviewAttendeesList = (userId, role) => {
    setInterviewAttendees(prevAttendees => {
      const updatedAttendees = [...prevAttendees];

      const removeAttendee = attendeeIndex => {
        if (attendeeIndex !== -1) {
          updatedAttendees.splice(attendeeIndex, 1);
        }
      };

      const isInterviewerAttendeeExist = updatedAttendees.some(
        attendee =>
          attendee.userId === userId && FOHInterviewAttendeesRole.INTERVIEWER
      );
      const interviewerAttendeeIndex = updatedAttendees.findIndex(
        attendee => attendee.role === FOHInterviewAttendeesRole.INTERVIEWER
      );
      const isSchedulerAttendeeExist = updatedAttendees.some(
        attendee => attendee.userId === loggedInUserId
      );
      const schedulerAttendeeIndex = updatedAttendees.findIndex(
        attendee => attendee.userId === loggedInUserId
      );

      if (
        isSchedulerAttendeeExist &&
        !keepMeUpdated &&
        selectedInterviewer !== loggedInUserId
      ) {
        removeAttendee(schedulerAttendeeIndex);
      }

      if (isSchedulerAttendeeExist && keepMeUpdated) {
        if (schedulerAttendeeIndex !== -1) {
          updatedAttendees[schedulerAttendeeIndex].role =
            FOHInterviewAttendeesRole.NOTIFY_ONLY;
        }
      }

      if (
        isInterviewerAttendeeExist &&
        selectedInterviewer === loggedInUserId
      ) {
        const notifyAttendeeIndex = updatedAttendees.findIndex(
          attendee => attendee.role === FOHInterviewAttendeesRole.NOTIFY_ONLY
        );

        if (notifyAttendeeIndex !== -1)
          updatedAttendees[notifyAttendeeIndex].role =
            FOHInterviewAttendeesRole.INTERVIEWER;

        removeAttendee(interviewerAttendeeIndex);
      }

      if (!isInterviewerAttendeeExist) {
        removeAttendee(interviewerAttendeeIndex);

        updatedAttendees.push({ userId, role });
      }

      return updatedAttendees;
    });
  };

  useEffect(() => {
    if (employerCandidate) {
      const candidateUserId = path(
        ['candidateProfile', 'user', 'id'],
        employerCandidate
      );
      manageInterviewAttendeesList(
        candidateUserId,
        FOHInterviewAttendeesRole.CANDIDATE
      );
    }
  }, [isActionModalOpen, employerCandidate]);

  useEffect(() => {
    manageInterviewAttendeesList(
      selectedInterviewer,
      FOHInterviewAttendeesRole.INTERVIEWER
    );
  }, [isActionModalOpen, selectedInterviewer, keepMeUpdated]);

  return {
    interviewersOptions,
    selectedInterviewerUser,
    schedulerIsInterviewer,
    selectedInterviewer,
    setSelectedInterviewer,
    interviewAttendees,
    interviewerSearch,
    setInterviewerSearch,
    keepMeUpdated,
    setKeepMeUpdated,
    manageInterviewAttendeesList,
    clearInterviewAttendeeStates,
    createInterviewParticipants
  };
};

export default useInterviewAttendees;
