import React from 'react';

import { FOHLoader, FOHView } from '../../components';

export const LogoLoader = () => {
  return (
    <FOHLoader
      svg={props => <svg {...props} />}
      path={props => <path {...props} />}
    />
  );
};

export const FullScreenLogoLoader = () => (
  <FOHView
    style={{
      height: '100vh',
      width: '100vw',
      alignItems: 'center',
      justifyContent: 'center'
    }}
  >
    <LogoLoader />
  </FOHView>
);

export const CustomSizeLogoLoader = ({ width, height, style }) => (
  <FOHView
    style={[
      {
        height,
        width,
        alignItems: 'center',
        justifyContent: 'center'
      },
      style
    ]}
  >
    <LogoLoader />
  </FOHView>
);
