import React, { useEffect, useState } from 'react';

import { path, compose } from 'ramda';

import { withStripe } from '../../features/Billing/withStripe';

import {
  getStripeSession,
  reactivatePlan,
  deactivatePlan
} from '../stripePayment';
import { getPayments } from '../payments';
// Deprecated Notice, write any new payment logic in Billing/useCheckout, and use graphql to get payments
const withPayment = Cmp => props => {
  const [payments, setPayments] = useState();

  // Deprecated, use useCheckout and redirectToStripeCheckout
  const redirectToCheckout = async () => {
    // no url defaults to feed
    const sessionResponse = await getStripeSession({ url: '' });
    const sessionId = path(['stripe_session', 'id'], sessionResponse);
    await props.stripe.redirectToCheckout({
      sessionId
    });
  };

  const refreshPayments = res => {
    getPayments().then(response => {
      return setPayments(response.results);
    });
    return res;
  };

  useEffect(() => {
    refreshPayments();
  }, []);

  return (
    <Cmp
      {...props}
      payments={payments}
      checkoutStripe={() => redirectToCheckout()}
      reactivatePlan={() =>
        reactivatePlan().then(res => {
          return refreshPayments(res);
        })
      }
      deactivatePlan={() =>
        deactivatePlan().then(res => {
          return refreshPayments(res);
        })
      }
    >
      {props.children}
    </Cmp>
  );
};

export default compose(withStripe, withPayment);
