import React from 'react';

import { View } from 'react-native';

import {
  FOHButton,
  FOHInverseButton,
  FullWidthCard,
  FOHSpace,
  FOHSmallDetailLabel,
  FOHHeaderH2,
  FOHErrorLabel,
  FOHQuoteIcon,
  FOHLabel
} from '../../ingredients';
import { FOHLocationOverviewProps, FOHLocationOverview } from '../../recipes';
import { FOH_INPUT_HEIGHT } from '../../constants';

export interface FOHInterviewResponseProps extends FOHLocationOverviewProps {
  acceptLabel: string;
  acceptInterview: () => void;
  declineLabel: string;
  declineInterview: () => void;
  responded?: boolean;
  responseLabel?: string;
  positionLabel: string;
  positionName: string;
  commuteLabel: string;
  commuteTime: string;
  whereLabel: string;
  requestedAtLabel: string;
  interviewRequestMessaging?: string;
  vertical?: boolean;
  error?: string;
}

export const FOHInterviewResponse = (props: FOHInterviewResponseProps) => (
  <FullWidthCard
    style={{
      padding: 24,
      maxWidth: 600
    }}
    testID={'FOHInterviewResponse-interview-card'}
  >
    <FOHSpace />
    <View
      style={{
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'space-between'
      }}
    >
      <View style={{ flex: 1 }}>
        <FOHSmallDetailLabel>{props.positionLabel}</FOHSmallDetailLabel>
        <FOHHeaderH2
          style={{
            textAlign: 'left'
          }}
        >
          {props.positionName}
        </FOHHeaderH2>
      </View>
      <View
        // @ts-ignore textAlign
        style={{ flex: 1, textAlign: 'right' }}
      >
        <FOHSmallDetailLabel>{props.requestedAtLabel}</FOHSmallDetailLabel>
      </View>
    </View>
    <FOHSpace />

    <View style={{ flex: 1 }}>
      <FOHSmallDetailLabel>{props.whereLabel}</FOHSmallDetailLabel>
    </View>
    <FOHSpace />
    <FOHLocationOverview {...props} />
    <FOHSpace />
    <View
      style={{
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'flex-start'
      }}
    >
      <View style={{ flex: 1 }}>
        <FOHSmallDetailLabel>{props.commuteLabel}</FOHSmallDetailLabel>
        <FOHHeaderH2
          style={{
            textAlign: 'left'
          }}
        >
          {props.commuteTime}
        </FOHHeaderH2>
      </View>
    </View>
    {!!props.interviewRequestMessaging && (
      <>
        <FOHSpace />
        <View>
          <FOHQuoteIcon />
          <FOHSpace />
          <FOHLabel>{props.interviewRequestMessaging}</FOHLabel>
          <FOHSpace />
          <FOHSpace />
        </View>
      </>
    )}
    <FOHSpace />
    {/* has responded, show a disabled button */}
    {props.responded && !!props.responseLabel && (
      <View
        style={{
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'center'
        }}
      >
        <FOHButton
          onPress={() => {}}
          disabled={true}
          title={props.responseLabel}
        />
      </View>
    )}

    {props.error && (
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          paddingBottom: 6
        }}
      >
        <FOHErrorLabel>{props.error}</FOHErrorLabel>
      </View>
    )}

    {!props.responded && (
      <View
        style={{
          width: '100%',
          flexDirection: props.vertical ? 'column' : 'row-reverse'
        }}
      >
        <FOHButton
          onPress={props.acceptInterview}
          title={props.acceptLabel}
          style={{ flex: 1, height: FOH_INPUT_HEIGHT }}
        />
        <FOHSpace />
        <FOHInverseButton
          onPress={props.declineInterview}
          title={props.declineLabel}
          style={{ flex: 1, height: FOH_INPUT_HEIGHT }}
        />
      </View>
    )}
  </FullWidthCard>
);
