import React from 'react';

import { View } from 'react-native';
import styled from 'styled-components/native';

import {
  FOHSmallDetailLabel,
  FOHLabelSmall,
  FOHDirectMessageIcon,
  FOHDivider,
  FOHVerticalDivider,
  FOHView,
  FOHButtonSecondary,
  FOHColors
} from '../../ingredients';
import { FOHChannelPreview, SBMember, SBLastMessage } from '../../templates';

export interface FOHChatCellProps {
  mobile?: boolean;
  members: Array<SBMember>;
  lastMessage: SBLastMessage;
  unreadMessageCount?: number;
  formattedLastReadMessageTime: string;
  restaurantName?: string;
  restaurantImage?: string;
  isActive: boolean;
  channelUrl: string;
  sendbirdUserId: string;
  stageLabel?: string;
  stage?: string;
  messageLabel?: string;
  onPressMessage: () => void;
  onChannelPress: () => void;
}

export const FOHChatCell = (props: FOHChatCellProps) => {
  return (
    <>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap'
        }}
      >
        <View style={{ paddingTop: 10 }}>
          <FOHChannelPreview
            onChannelPress={props.onChannelPress}
            isActive={props.isActive}
            members={props.members}
            channelUrl={props.channelUrl}
            sendbirdUserId={props.sendbirdUserId}
            restaurantName={props.restaurantName}
            unreadMessageCount={props.unreadMessageCount}
            lastMessage={props.lastMessage}
            formattedLastReadMessageTime={props.formattedLastReadMessageTime}
            noDivider={true}
          />
        </View>
        <FOHView
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            flexWrap: 'wrap'
          }}
        >
          <FOHVerticalDivider height={40} />
          <View
            style={{
              width: 177,
              backgroundColor: FOHColors.BACKGROUND_GRAY,
              opacity: 0.8,
              borderRadius: 6,
              marginLeft: 10,
              paddingBottom: 10,
              height: 46
            }}
          >
            {props.stage && !props.mobile && (
              <StageView>
                <FOHSmallDetailLabel style={{ fontSize: 10, lineHeight: 10 }}>
                  {props.stageLabel}
                </FOHSmallDetailLabel>
                <FOHLabelSmall>{props.stage}</FOHLabelSmall>
              </StageView>
            )}
          </View>
          <View style={{ paddingTop: 10 }}>
            {props.onPressMessage && !!props.messageLabel && !props.mobile && (
              <View
                style={{
                  paddingLeft: 24,
                  minWidth: 177,
                  paddingRight: 24,
                  alignItems: 'center'
                }}
              >
                <FOHButtonSecondary
                  icon={FOHDirectMessageIcon}
                  testID={'on-press-message'}
                  onPress={props.onPressMessage}
                  title={props.messageLabel}
                  style={{
                    width: 177,
                    marginBottom: 10,
                    height: 46
                  }}
                />
              </View>
            )}
          </View>
        </FOHView>
      </View>
      <FOHDivider />
    </>
  );
};

const StageView = styled(View)`
  background-color: ${FOHColors.BACKGROUND_GRAY};
  height: 40px;
  border-radius: 6px;
  margin-top: 8px;
  margin-right: 8px;
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 0px;
  min-width: 80px;
  max-width: 186px;
`;
