import React, { useState, useEffect } from 'react';

import { compose, path, prop, length } from 'ramda';
import { useTranslation } from 'react-i18next';

import {
  YEARS_EXPERIENCE_DROPDOWN,
  CANDIDATE_ACCOUNT_MENU_PATH,
  CANDIDATE_YEARS_EXPERIENCE_PATH
} from '../../constants';
import { handle, useIsMobile } from '../../utils';
import { useNavigation } from '../../utils/navigation';
import { FOHMultiSelectScreen } from '../../components';

import { withReactiveModal } from '../Navigation/withReactiveModal';

import { useCandidateProfileMutation } from './useCandidateProfileMutation';
const YearsExpScreen = props => {
  const { navigateTo, location: navLocation } = useNavigation();

  const { me, updateCandidate } = useCandidateProfileMutation();
  const [yearsExp, setYearsExp] = useState(['']);
  const [error, setError] = useState();
  const { t } = useTranslation('YearsExpFeature');
  const { isMobile } = useIsMobile();

  const hasBack =
    navLocation.pathname === CANDIDATE_YEARS_EXPERIENCE_PATH &&
    !!path(['candidateProfile', 'onboardingCompletedAt'], me);

  const [dirty, setDirty] = useState(false);

  // WAITING ON USER OBJECT
  useEffect(() => {
    setYearsExp([path(['candidateProfile', 'yearsExperience'], me)]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prop('candidateProfile', me)]);

  return (
    <FOHMultiSelectScreen
      onboarding={props.onboarding}
      items={YEARS_EXPERIENCE_DROPDOWN.map(position => ({
        value: position.value,
        label: t(position.label)
      }))}
      // max five
      selected={yearsExp}
      selectItem={value => {
        setDirty(true);
        return setYearsExp([value]);
      }}
      title={t('yearsExpTitle')}
      submitLabel={
        path(['candidateProfile', 'onboardingCompletedAt'], me)
          ? t('common:save')
          : t('nextLabel')
      }
      maxCount={1}
      previousTitle={
        props.onboarding ? t('CandidateOnboardingSteps:previous') : ''
      }
      goBack={
        hasBack
          ? () => navigateTo(CANDIDATE_ACCOUNT_MENU_PATH)
          : props.onboarding
          ? () => props.previousStep()
          : null
      }
      disabledSubmit={
        length(yearsExp.filter(years => years !== '')) !== 1 ||
        (!dirty && !props.onboarding)
      }
      error={error}
      isModal={props.isModal}
      largeHeader={!isMobile}
      handleSubmit={async () => {
        const yearsExperience = yearsExp.filter(years => years !== '')[0];
        const [resp] = await handle(
          updateCandidate({
            variables: {
              yearsExperience
            }
          })
        );
        const err = path(['data', 'mutateCandidate', 'errors'], resp);

        if (resp && !err) {
          setDirty(false);
          if (props.close) return props.close();
          return path(['candidateProfile', 'onboardingCompletedAt'], me)
            ? navigateTo(CANDIDATE_ACCOUNT_MENU_PATH)
            : props.onSubmit && props.onSubmit();
        } else if (err) {
          setError(err);
        }
      }}
    />
  );
};

export const YearsExpModal = compose(withReactiveModal)(YearsExpScreen);

export default YearsExpScreen;
