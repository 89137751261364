import React from 'react';

import styled from 'styled-components/native';

import {
  FOHColors,
  FOHLabel,
  FOHLabelBold,
  FOHTouchableOpacity,
  FOHView
} from '../../ingredients';

export interface FOHDayScheduleCellProps extends FOHDayScheduleProps {
  selected?: boolean;
  onPress: (id: string) => void;
}

export interface FOHDayScheduleProps {
  id: string;
  disabled?: boolean;
  date: string;
  shortDateName: string;
}

export const FOHDayScheduleCell = (props: FOHDayScheduleCellProps) => (
  <FOHTouchableOpacity
    style={{ justifyContent: 'center', alignItems: 'center' }}
    onPress={() => props.onPress(props.id)}
    disabled={props.disabled}
    testID={`FOHDayScheduleCell-${props.shortDateName}`}
  >
    {props.selected ? (
      <FOHLabelBold>{props.shortDateName}</FOHLabelBold>
    ) : (
      <FOHLabel>{props.shortDateName}</FOHLabel>
    )}
    <BigCircle {...props}>
      <FOHLabel
        style={{
          color: props.disabled
            ? FOHColors.SILVER
            : props.selected
            ? FOHColors.WHITE
            : FOHColors.BLACK
        }}
      >
        {props.date}
      </FOHLabel>
    </BigCircle>
  </FOHTouchableOpacity>
);

const BigCircle = styled(FOHView)`
  border-radius: 50%;
  background-color: ${(props: FOHDayScheduleCellProps) =>
    props.disabled
      ? FOHColors.BACKGROUND_GRAY
      : props.selected
      ? '#51C1DB'
      : FOHColors.WHITE};
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-color: ${(props: FOHDayScheduleCellProps) =>
    props.selected ? '#51C1DB' : FOHColors.BORDER_GRAY};
`;
