import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { defaultTo, path, prop } from 'ramda';

import {
  FOHColors,
  FOHView,
  Screen,
  FOHBannerType,
  FOHBanner,
  FOHCircleLoader,
  FOHScrollView,
  FOHSpace,
  RestrictedView,
  useIsRestricted,
  FOHButtonBordered,
  FOHHeaderH2
} from '../../components';
import { useSidebarNav } from '../Navigation/useSidebar';
import { copyToClipboard, handle, isScrollCloseToBottom } from '../../utils';
import { useBanner } from '../Navigation';
import {
  ALLOWED_ROLES_GROUPS,
  CAREER_PAGE_DASHBOARD,
  CREATE_NEW_CAREER_PAGE,
  getAppBaseUrl
} from '../../constants';
import { useGetCareerPagesQuery } from '../../graphql/generated';
import { fetchMore } from '../../utils/fetchMore';
import { LogoLoader } from '../Loading/LogoLoader';
import { useNavigation } from '../../utils/navigation';
import { useGetMeData } from '../SignIn';

import {
  CareerListCell,
  CareerListHeaderCell,
  CreateNewCareerPageModal
} from './components';

interface CareerPageDashboardProps {}

export const CareerPageDashboard: React.FC<
  CareerPageDashboardProps
> = props => {
  const { t } = useTranslation('CareerPage');

  const { hasSideNav } = useSidebarNav(props);
  const { banner, setBanner } = useBanner();

  const { navigateTo } = useNavigation();
  const { modal }: { modal?: string } = useParams();

  const [loadingMore, setLoadingMore] = useState<boolean>(false);

  const ALLOWED_ROLES_GROUPS_PERMISSION = {
    allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.EMPLOYER],
    allowedRoles: ALLOWED_ROLES_GROUPS.ROLES.ADMIN
  };

  const { isRestricted: isRestrictedExceptAdmin } = useIsRestricted({
    ...ALLOWED_ROLES_GROUPS_PERMISSION
  });

  const { data: me } = useGetMeData();
  const employerProfileId = path(['employerProfile', 'id'], me) as string;

  const variables = {
    employerProfile: employerProfileId,
    first: 10
  };

  const careerPagesQuery = useGetCareerPagesQuery({
    skip: !employerProfileId,
    variables
  });

  const endCursor = path(
    ['data', 'employerCareerPages', 'pageInfo'],
    careerPagesQuery
  );

  const careerPages = defaultTo(
    [],
    path(['employerCareerPages', 'edges'], careerPagesQuery?.data)
  )?.map((node: any) => ({ ...prop('node', node) }));

  const fetchMoreCareerPages = useCallback(async () => {
    return await handle(
      fetchMore(careerPagesQuery, variables, 'employerCareerPages')
    ); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endCursor]);

  const handleCopyURL = (URL: string) => {
    copyToClipboard({
      content: URL,
      onSuccess: () => {
        setBanner({
          bannerMessage: t('careerURLCopied'),
          bannerType:
            FOHBannerType.SUCCESS as unknown as keyof typeof FOHBannerType
        });
      }
    });
  };

  // Implement the component's logic and rendering
  return (
    <Screen
      style={{
        position: 'relative',
        width: '100%'
      }}
    >
      {banner?.bannerMessage ? (
        <FOHBanner
          style={{ position: 'absolute', zIndex: 1 }}
          {...banner}
          message={banner?.bannerMessage}
        />
      ) : (
        <></>
      )}
      <FOHView
        style={{
          alignItems: 'flex-start',
          minHeight: '100vh',
          paddingTop: hasSideNav ? 0 : 50,
          width: '100%'
        }}
      >
        <FOHView
          style={{
            alignItems: 'flex-start',
            backgroundColor: FOHColors.WHITE,
            height: '100%',
            paddingHorizontal: 24,
            paddingVertical: 12,
            width: '100%'
          }}
        >
          <FOHView
            style={{
              alignItems: 'center',
              flexDirection: 'row',
              height: 78,
              justifyContent: 'space-between',
              width: '100%'
            }}
          >
            <FOHHeaderH2
              style={{
                color: FOHColors.GRAYSCALE_G1,
                fontStyle: 'normal',
                fontWeight: 700,
                marginBottom: 16
              }}
            >
              {`${t('careerPages')}`}
            </FOHHeaderH2>
            <RestrictedView {...ALLOWED_ROLES_GROUPS_PERMISSION}>
              <FOHButtonBordered
                color={FOHColors.PRIMARY_TEAL_P1}
                onPress={() => {
                  navigateTo(CREATE_NEW_CAREER_PAGE);
                }}
                style={{
                  height: 40,
                  margin: 4,
                  width: 187
                }}
                textColor={FOHColors.WHITE}
                title={`+ ${t('newCareerPage')}`}
              />
            </RestrictedView>
          </FOHView>
        </FOHView>
        <FOHView
          style={{
            backgroundColor: FOHColors.GRAYSCALE_10,
            width: '100%'
          }}
        >
          <FOHScrollView
            onScroll={async ({ nativeEvent }: any) => {
              if (isScrollCloseToBottom(nativeEvent) && !loadingMore) {
                setLoadingMore(true);
                await fetchMoreCareerPages();
                setLoadingMore(false);
              }
            }}
            scrollEventThrottle={200}
            style={{
              height: '88vh',
              width: '100%'
            }}
          >
            <FOHView
              style={{
                flex: 1,
                height: '100%',
                paddingHorizontal: 24,
                paddingVertical: 24,
                width: '100%'
              }}
            >
              {careerPagesQuery.loading ? (
                <FOHView>
                  <FOHCircleLoader />
                </FOHView>
              ) : (
                <FOHView>
                  <CareerListHeaderCell
                    translationKeys={{
                      createdByLabel: t('createdByLabel'),
                      locationLabel: t('locationLabel'),
                      noOfPositionsLabel: t('noOfPositionsLabel'),
                      titlePageLabel: t('titlePageLabel')
                    }}
                  />
                  <FOHView
                    style={{ alignItems: 'center', justifyContent: 'center' }}
                  >
                    <>
                      {careerPages?.map((careerPage: any, index: number) => {
                        const slug = prop('slug', careerPage);

                        const careerPageLink = slug
                          ? `${getAppBaseUrl()}/restaurant/${slug}`
                          : '';
                        const editCareerPageRoute = slug
                          ? `/restaurant/edit/${slug}`
                          : '';
                        const previewCareerPageRoute = slug
                          ? `/restaurant/${slug}/preview`
                          : '';

                        const noOfLocations = careerPage?.pageLocations?.length;
                        const locationObj =
                          noOfLocations > 0
                            ? (defaultTo(
                                {},
                                path(['pageLocations', 0], careerPage)
                              ) as {
                                formattedAddress: string;
                                name: string;
                              })
                            : null;
                        return (
                          <CareerListCell
                            careerPageLink={careerPageLink}
                            createdBy={
                              path(['createdBy', 'firstName'], careerPage) ||
                              path(['createdBy', 'lastName'], careerPage)
                                ? `${defaultTo(
                                    '',
                                    path(['createdBy', 'firstName'], careerPage)
                                  )} ${defaultTo(
                                    '',
                                    path(['createdBy', 'lastName'], careerPage)
                                  )}`
                                : '--'
                            }
                            editActionLabel={t('PositionsFeature:editLabel')}
                            isEditDisabled={
                              !prop('canManage', careerPage) as boolean
                            }
                            key={index}
                            locationAddress={
                              noOfLocations === 1
                                ? (locationObj?.formattedAddress as string)
                                : ''
                            }
                            locationName={locationObj?.name as string}
                            noOfLocations={noOfLocations}
                            noOfPositions={
                              prop('totalPositions', careerPage) as number
                            }
                            onPressCopyLink={() =>
                              handleCopyURL(careerPageLink)
                            }
                            onPressEditAction={() =>
                              navigateTo(editCareerPageRoute)
                            }
                            onPressPreviewAction={() =>
                              navigateTo(previewCareerPageRoute)
                            }
                            pageTitle={prop('title', careerPage) as string}
                            previewActionLabel={t('PositionsFeature:preview')}
                            tooltipText={t('restrictedLabelForEdit')}
                          />
                        );
                      })}
                    </>
                    <FOHSpace />
                    {!!loadingMore && <LogoLoader />}
                  </FOHView>
                </FOHView>
              )}
            </FOHView>
          </FOHScrollView>
        </FOHView>
      </FOHView>
      <CreateNewCareerPageModal
        close={() => {
          navigateTo(CAREER_PAGE_DASHBOARD, { replace: true });

          //REFETCH QUERY ONCE CREATED NEW CAREER PAGE
          careerPagesQuery.refetch();
        }}
        isRestrictedExceptAdmin={isRestrictedExceptAdmin}
        open={modal === 'create'}
        testID="CreateNewCareerPageModal"
      />
    </Screen>
  );
};
