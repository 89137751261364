import React from 'react';

import {
  FOHLabelSmallest,
  FOHColors,
  FOHView,
  FOHCustomChatBubble
} from '../../components';

import { obfuscateLinks } from './utils';

export const EmployerMagicLinkMessage = ({ message }) => {
  return (
    <FOHView
      style={{
        maxWidth: 400,
        justifyContent: 'flex-end',
        paddingTop: 4,
        paddingBottom: 10
      }}
    >
      <FOHCustomChatBubble backgroundColor={FOHColors.GRAYSCALE_700}>
        <FOHLabelSmallest
          style={{
            color: '#ffffff',
            fontSize: 14,
            fontWeight: 400,
            lineHeight: 20.02,
            fontFamily: 'Roboto, sans-serif'
          }}
        >
          {obfuscateLinks(message.message)}
        </FOHLabelSmallest>
      </FOHCustomChatBubble>
    </FOHView>
  );
};
