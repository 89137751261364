import React, { useRef, useState } from 'react';

import { compose, prop } from 'ramda';
import { useTranslation } from 'react-i18next';

import {
  FOHColors,
  FOHPlanState,
  FOHScrollView,
  FOHSpaceSmall,
  FOHView
} from '../../components';
import { handleMutation, useIsMobile } from '../../utils';
import {
  CalculatePriceDocument,
  useDeactivateSubscriptionMutateMutation
} from '../../graphql/generated';
import { BILLING, EMPLOYER_SETTINGS_MENU } from '../../constants';

import { withEmployerSettings } from '../EmployerSettings/withEmployerSettings';
import { useCurrentLocationContext } from '../Locations/useCurrentLocationContext';
import { LocationContextSelector } from '../Navigation/LocationContextSelector';

import { LocationPlan } from './LocationPlan';
import { PlanModals } from './PlanModals';
import { CancelUnlimitedSubscriptionPlanModal } from './CancelUnlimitedSubscriptionPlanModal';
import { CancelReasonModal } from './CancelReasonModal';
import { usePaymentPlan } from './usePaymentPlan';
import { useCustomerPortal } from './useCustomerPortal';

const BillingSettings = props => {
  const { isMobile } = useIsMobile();
  const scrollRef = useRef();

  const { locationFilter } = useCurrentLocationContext({ props });
  const [openCancellationReason, setOpenCancellationReason] = useState(false);
  const [openCancelSubscriptionModal, setOpenCancelSubscriptionModal] =
    useState(false);

  const INITIAL_PAYASYOUGO_SELECTED_POSITION_SUBSCRIPTION = {
    positionId: null,
    stripeSubscription: null
  };

  const [selectedPositionSubscription, setSelectedPositionSubscription] =
    useState(INITIAL_PAYASYOUGO_SELECTED_POSITION_SUBSCRIPTION);

  const [, setOpenSales] = useState(false);

  const { planName, subscribedLocation } = usePaymentPlan({
    locationFilter,
    currentPlanQ: true
  });

  const { redirectToCustomerPortal } = useCustomerPortal();
  const [deactivateSubscription] = useDeactivateSubscriptionMutateMutation();

  return (
    <>
      <FOHView
        style={{
          alignItems: isMobile ? 'center' : 'flex-start',
          backgroundColor: FOHColors.BACKGROUND_GRAY
        }}
      >
        <FOHScrollView
          style={{
            height: isMobile ? undefined : '80vh',
            width: '100%'
          }}
          ref={scrollRef}
        >
          <FOHView
            style={{
              paddingHorizontal: isMobile ? 14 : 28,
              paddingTop: isMobile ? 14 : 20,
              paddingBottom: isMobile ? 14 : 50,
              width: '100%'
            }}
          >
            <FOHView style={{ zIndex: 19 }}>
              <LocationContextSelector
                containerStyle={{
                  backgroundColor: 'transparent',
                  position: 'relative',
                  paddingTop: 0,
                  paddingLeft: 0,
                  paddingBottom: 0
                }}
                shouldShow={true}
                {...props}
              />
            </FOHView>
            <FOHSpaceSmall />
            <LocationPlan
              {...props}
              setOpenCancellationReason={setOpenCancellationReason}
              setSelectedPositionSubscription={setSelectedPositionSubscription}
              setOpenSales={setOpenSales}
            />
          </FOHView>
        </FOHScrollView>
      </FOHView>
      <PlanModals {...props} />
      <CancelUnlimitedSubscriptionPlanModal
        open={openCancelSubscriptionModal}
        close={() => setOpenCancelSubscriptionModal(false)}
        backPath={`${EMPLOYER_SETTINGS_MENU}${BILLING}`}
        {...props}
      />
      <CancelReasonModal
        {...props}
        positionId={
          props.positionId || selectedPositionSubscription?.positionId
        }
        open={openCancellationReason}
        setOpenCancellationReason={setOpenCancellationReason}
        locationId={locationFilter}
        onCancelReasonSubmit={async () => {
          setOpenCancellationReason(false);
          if (
            planName === FOHPlanState.enterprise
            // || planName === FOHPlanState.unlimited
          ) {
            redirectToCustomerPortal({
              location: prop('rowId', subscribedLocation)
            });
          } else if (planName === FOHPlanState.unlimited) {
            setOpenCancelSubscriptionModal(true);
          } else {
            await handleMutation(
              deactivateSubscription({
                variables: {
                  stripeSubscription:
                    selectedPositionSubscription?.stripeSubscription,
                  position: selectedPositionSubscription?.positionId
                },
                refetchQueries: [
                  {
                    query: CalculatePriceDocument,
                    variables: {
                      location: locationFilter,
                      paymentStatus: 'notsubscribed'
                    }
                  },
                  {
                    query: CalculatePriceDocument,
                    variables: {
                      location: locationFilter,
                      paymentStatus: 'subscribed'
                    }
                  }
                ]
              })
            );
            setSelectedPositionSubscription(
              INITIAL_PAYASYOUGO_SELECTED_POSITION_SUBSCRIPTION
            );
          }
        }}
      />
    </>
  );
};

export default compose(withEmployerSettings)(BillingSettings);
