import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { defaultTo, path, prop, length } from 'ramda';

import { multiSelectItem } from '../../utils';
import { useTypeAheadSearchQuery } from '../../graphql/generated';
import { FOHDropDownSearch } from '../../components';

export const PositionTypeAheadSearchBar = props => {
  // pass in search text and selected items
  const { t } = useTranslation('PositionSearchAheadFeature');
  const { selectedItems, setSelectedItems, isMobile, tab } = props;
  const [search, setSearch] = useState('');

  const typeAheadQuery = useTypeAheadSearchQuery({
    variables: { search }
  });
  // how many charachters are in our search
  const searchBarLength = selectedItems.reduce(
    (acc, item) => (acc += length(defaultTo('', prop('value', item)))),
    0
  );

  return (
    <FOHDropDownSearch
      onChangeSearch={setSearch}
      freeTextLabel={t('freeText')}
      searchDetailLabel={t('searchDetailLabel')}
      closeLabel={
        length(selectedItems) > 0 ? t('applySearchLabel') : t('closeLabel')
      }
      searchValue={search}
      searchPlaceholder={
        tab === 'applications'
          ? t('searchApplicationsPlaceholder')
          : t('searchPlaceholder')
      }
      selectFreeTextLabel={t('selectFreeTextLabel')}
      loading={typeAheadQuery.loading}
      truncationLimit={isMobile || searchBarLength >= 35 ? 1 : 3}
      clearSearch={() => setSelectedItems([])}
      sections={defaultTo(
        [],
        path(['data', 'searchAhead', 'groups'], typeAheadQuery)
      ).map(group => ({
        title: t(prop('group', group)),
        items: defaultTo([], prop('values', group))
      }))}
      selectedItems={selectedItems}
      selectItem={item => {
        setSearch('');
        multiSelectItem(item, setSelectedItems, selectedItems);
      }}
    />
  );
};
