import React from 'react';

import {
  FOHLabelSmallest,
  FOHLabelBold,
  FOHHighlightWordsLabel,
  FOHColors,
  FOHSpace,
  FOHView,
  FOHNotificationCompletedOffers,
  FOHNotificationRejectedOffersChat,
  FOHCustomChatBubble
} from '../../components';

export const getMessageTypeIcon = messageType => {
  switch (messageType) {
    case `offer-decline`:
      return (
        <FOHNotificationRejectedOffersChat
          style={{
            width: 40,
            height: 40
          }}
        />
      );
    case `offer-accept`:
      return (
        <FOHNotificationCompletedOffers
          style={{
            width: 40,
            height: 40
          }}
        />
      );
    default:
      return undefined;
  }
};

export const CustomMessage = ({ message, messageType }) => {
  return (
    <FOHView style={{ width: 400, justifyContent: 'flex-end', paddingTop: 4 }}>
      <FOHCustomChatBubble backgroundColor={FOHColors.GRAYSCALE_700}>
        <FOHView
          style={{
            flexDirection: 'row'
          }}
        >
          {getMessageTypeIcon(messageType)}
          <FOHSpace />
          <FOHLabelSmallest
            style={{ color: FOHColors.BLACK, fontSize: 12, width: 320 }}
          >
            <FOHHighlightWordsLabel
              labelCmp={FOHLabelSmallest}
              emphasizedWords={[]}
              highlightColor="#000000"
              textColor={FOHColors.WHITE}
              boldLabelCmp={FOHLabelBold}
            >
              {message.message}
            </FOHHighlightWordsLabel>
            {/* {` (Magic Link sent to Candidate)`} */}
          </FOHLabelSmallest>
        </FOHView>
      </FOHCustomChatBubble>
    </FOHView>
  );
};
