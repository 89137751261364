import React, { useEffect, useState } from 'react';

import { path, prop, defaultTo, length } from 'ramda';
import { useTranslation } from 'react-i18next';

import {
  useCancellationReasonMutation,
  useGetCancelReasonsQuery,
  usePositionByIdQuery
} from '../../graphql/generated';
import {
  useDisableBackgroundScroll,
  useIsMobile,
  multiSelectItem,
  handleMutation
} from '../../utils';
import { BILLING, EMPLOYER_SETTINGS_MENU } from '../../constants';
import { useNavigation } from '../../utils/navigation';
import { FOHCancelReasonModal } from '../../components';

import { useSidebarNav } from '../Navigation/useSidebar';

export const CancelReasonModal = props => {
  const { positionId, locationId } = props;
  const { navigateTo, location: navLocation } = useNavigation();

  const positionToCancelQuery = usePositionByIdQuery({
    skip: !positionId || positionId === 'all',
    variables: {
      id: positionId
    }
  });

  const cancelReasonsQuery = useGetCancelReasonsQuery({
    variables: {}
  });

  const positionToCancel = path(
    ['data', 'positionById'],
    positionToCancelQuery
  );
  const { isMobile } = useIsMobile();

  const [mutateCancelReason] = useCancellationReasonMutation();
  const [cancellationReason, setCancellationReason] = useState([]);
  const [cancellationReasonOther, setCancellationReasonOther] = useState('');
  const [error, setError] = useState('');
  const { t } = useTranslation('PricingScreenFeature');

  useDisableBackgroundScroll([props.open]);

  useEffect(() => {
    setCancellationReason([]);
    setCancellationReasonOther('');
  }, [positionId, locationId, props.open]);

  useEffect(() => {
    if (
      length(cancellationReason) > 0 &&
      cancellationReason.includes('other')
    ) {
      if (length(cancellationReasonOther) < 10) {
        setError(t('minimumCharLengthError'));
      } else {
        setError('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancellationReason, cancellationReasonOther]);

  const { contentShift } = useSidebarNav(props);

  return (
    <FOHCancelReasonModal
      {...props}
      leftContentShift={contentShift}
      open={props.open}
      close={() => {
        props.setOpenCancellationReason &&
          props.setOpenCancellationReason(false);
        navigateTo({
          pathname: props.backPath || `${EMPLOYER_SETTINGS_MENU}${BILLING}`,
          search: navLocation.search
        });
      }}
      items={defaultTo(
        [],
        path(['data', 'cancellationChoices'], cancelReasonsQuery)
      ).map(choice => ({ value: choice, label: t(choice) }))}
      selectItem={value =>
        multiSelectItem(value, setCancellationReason, cancellationReason)
      }
      selected={cancellationReason}
      dialog={!isMobile}
      hideTextField={!cancellationReason.includes('other')}
      headerLabel={
        positionId
          ? t('pausePositionHeader', {
              positionName: prop('positionName', positionToCancel)
                ? prop('positionName', positionToCancel)
                : path(['initialPosition', 'positionName'], props)
            })
          : t('CancelReasonModalHeader')
      }
      subHeaderLabel={
        positionId
          ? t('pausePositionSubheader')
          : t('CancelReasonModalSubHeader')
      }
      positionName={
        prop('positionName', positionToCancel) ||
        path(['initialPosition', 'positionName'], props)
      }
      locationImage={
        path(['location', 'image'], positionToCancel) ||
        defaultTo('', path(['initialPosition', 'location', 'image'], props))
      }
      location={
        path(['location', 'formattedAddress'], positionToCancel) ||
        defaultTo(
          '',
          path(['initialPosition', 'location', 'formattedAddress'], props)
        )
      }
      error={error}
      feedback={cancellationReasonOther}
      setFeedback={setCancellationReasonOther}
      inputPlaceholder={t('CancelReasonModalInputPlaceholder')}
      submitBtnLabel={t('common:next')}
      disablSubmit={
        length(cancellationReason) === 0 ||
        (cancellationReason.includes('other') &&
          length(cancellationReasonOther) < 10)
      }
      onPressSubmitBtn={async () => {
        if (
          cancellationReason ||
          (cancellationReasonOther && length(cancellationReasonOther) > 10)
        ) {
          await handleMutation(
            mutateCancelReason({
              variables: {
                position: positionId || undefined,
                location: locationId || undefined,
                hiredFohandboh: cancellationReason.includes('hired-fohandboh'),
                hiredOther: cancellationReason.includes('hired-other'),
                tooFewApplicants:
                  cancellationReason.includes('too-few-applicants'),
                candidatesUnresponsive: cancellationReason.includes(
                  'candidates-unresponsive'
                ),
                needsChange: cancellationReason.includes('needs-change'),
                other: !!cancellationReasonOther,
                cancellationReasonOther
              }
            })
          );
        }
        props.onCancelReasonSubmit
          ? props.onCancelReasonSubmit()
          : navigateTo({
              pathname: `${prop(
                0,
                navLocation.pathname.split('/reason')
              )}/pause`,
              search: navLocation.search
            });
      }}
    />
  );
};
