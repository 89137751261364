import React, { useContext, useState, useEffect } from 'react';

import { path, defaultTo } from 'ramda';
import { useLocalStorage } from 'use-hooks';

import {
  useSearchFilterGroupsQuery,
  useSortByOptionsQuery
} from '../../graphql/generated';

const CandidateNetworkSearchContext = React.createContext([{}, () => {}]);

export const useCandidateNetworkSearchContext = () => {
  const { state, setState } = useContext(CandidateNetworkSearchContext);
  const [isLoading, setIsLoading] = useState(true);

  const searchFilterGroupsQuery = useSearchFilterGroupsQuery();
  const sortByOptionsQuery = useSortByOptionsQuery();
  const sortByOptions = defaultTo(
    [],
    path(['data', 'candidateSearchSortByOptions'], sortByOptionsQuery)
  );

  const setSearchTerms = terms => {
    return setState({ ...state, searchTerms: terms });
  };

  const setZip = zip => {
    return setState({ ...state, zip });
  };

  const setDistance = distance => {
    return setState({ ...state, distance });
  };

  const addFilterItem = item => {
    return setState({
      ...state,
      profileDetailFilters: [...state.profileDetailFilters, item]
    });
  };

  const removeFilterItem = itemToRemove => {
    const profileDetailFilters = state.profileDetailFilters.filter(
      item => item !== itemToRemove
    );
    return setState({ ...state, profileDetailFilters });
  };

  const setIsFilterDrawerOpen = isFilterDrawerOpen => {
    return setState({ ...state, isFilterDrawerOpen });
  };

  const clearFiltersByGroup = groupName => {
    const groupToClear = defaultTo(
      [],
      path(['data', 'candidateSearchFilterGroups'], searchFilterGroupsQuery)
    ).find(group => group.name === groupName);

    if (groupToClear) {
      const itemsToRemove = groupToClear.filterItems;

      const currentProfileDetailFilters = state.profileDetailFilters;

      const newProfileDetailFilters = currentProfileDetailFilters.filter(
        item => !itemsToRemove.includes(item)
      );

      return setState({
        ...state,
        profileDetailFilters: newProfileDetailFilters
      });
    }
  };

  const clearAllFilters = () => {
    return setState({ ...state, profileDetailFilters: [] });
  };

  const setActiveCandidateHandles = async activeCandidateHandles => {
    return setState({ ...state, activeCandidateHandles });
  };

  const setActiveMoveToStageId = activeMoveToStageId => {
    return setState({ ...state, activeMoveToStageId });
  };

  const setSortBy = sortBy => setState({ ...state, sortBy });

  const setPartialState = partialState => {
    return setState({ ...state, ...partialState });
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return {
    ...state,
    setSortBy,
    sortByOptions,
    setSearchTerms,
    setZip,
    setDistance,
    addFilterItem,
    removeFilterItem,
    setIsFilterDrawerOpen,
    clearFiltersByGroup,
    clearAllFilters,
    setActiveCandidateHandles,
    setActiveMoveToStageId,
    setPartialState,
    isLoading
  };
};

export const CandidateNetworkSearchContextProvider = props => {
  // persist search vars across refetch
  const [state, setState] = useLocalStorage(
    'candidate-network-search-storage',
    {
      searchTerms: [],
      zip: '',
      distance: '',
      isFilterDrawerOpen: false,
      profileDetailFilters: [],
      activeCandidateHandles: [],
      sortBy: 'LAST_ACTIVE',
      activeMoveToStageId: null
    }
  );

  return (
    <CandidateNetworkSearchContext.Provider
      value={{
        state,
        setState
      }}
    >
      {props.children}
    </CandidateNetworkSearchContext.Provider>
  );
};
