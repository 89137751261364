import React, { FunctionComponent, useState, useRef, useEffect } from 'react';

import { TouchableWithoutFeedback } from 'react-native';
import { useTheme } from 'styled-components';

import {
  Card,
  FOHImage,
  FOHTouchableOpacity,
  FOHView,
  SPACING_SCALE
} from '../../ingredients';
import {
  FOHLocationHeaderSmall,
  FOHTagList,
  FOHRestaurantLocation
} from '../../recipes';

type PinStyle = 'default' | 'careerPage';

const PIN_STYLE_MAP: Record<
  PinStyle,
  { iconActive: string; iconDefault: string; iconHover: string }
> = {
  careerPage: {
    iconActive: require('../../../images/pin_career_page__default.svg'),
    iconDefault: require('../../../images/pin_career_page__default.svg'),
    iconHover: require('../../../images/pin_career_page__default.svg')
  },
  default: {
    iconActive: require('../../../images/pin_active.svg'),
    iconDefault: require('../../../images/pin_default.svg'),
    iconHover: require('../../../images/pin_hover.svg')
  }
};

export interface FOHMapMarkerProps extends FOHRestaurantLocation {
  animation?: number;
  Callout?: any;
  clusterer?: any;
  iconStyle?: PinStyle;
  isActive?: boolean;
  isHoverable?: boolean;
  isOpen?: boolean;
  Marker: any;
  onImagePress: (id: string) => void;
  onMarkerPress?: (id: string) => void;
  pressFavorite?: () => void;
  pressHide?: () => void;
  setOpenPin: (id: string) => void;
  visible?: boolean;
}

export const FOHMapMarker: FunctionComponent<FOHMapMarkerProps> = ({
  animation,
  Callout,
  clusterer,
  formattedAddress,
  iconStyle = 'default',
  id: locationId,
  image,
  isActive,
  isFavorited,
  isHidden,
  isHoverable = true,
  isOpen,
  latitude,
  longitude,
  Marker,
  name,
  onImagePress,
  onMarkerPress = () => null,
  pressFavorite,
  pressHide,
  setOpenPin,
  tags: locationTags = [],
  visible
}) => {
  const hoverRef = useRef(null);
  const theme = useTheme();
  const [hover, setHover] = useState(false);
  const { iconActive, iconDefault, iconHover } = PIN_STYLE_MAP[iconStyle];

  const [coloredSVG, setColoredSVG] = useState(iconDefault);

  const handleClick = () => {
    setHover(false);
    setOpenPin(locationId);
    onMarkerPress(locationId);
  };

  useEffect(() => {
    if (theme?.brandColor)
      fetch(iconActive)
        .then(res => res.text())
        .then(blob => {
          setColoredSVG(
            `data:image/svg+xml;charset=UTF-8;base64,${btoa(
              blob?.replace(/#17ABCC/g, theme?.brandColor)
            )}`
          );
        });
  }, [iconActive, theme?.brandColor]);

  return (
    <Marker
      animation={animation}
      clusterer={clusterer}
      coordinate={{ latitude, longitude }}
      description={formattedAddress}
      key={locationId}
      onClick={handleClick}
      onMouseOut={() => !isOpen && setHover(false)}
      onMouseOver={isHoverable ? () => !isOpen && setHover(true) : undefined}
      options={{
        icon:
          isActive || isOpen
            ? iconActive
            : isHoverable && hover
            ? iconHover
            : coloredSVG
      }}
      position={{ lat: Number(latitude), lng: Number(longitude) }}
      ref={hoverRef}
      title={name}
      visible={visible}
    >
      {isHoverable && (isOpen || hover) && (
        <Callout
          options={{ closeBoxURL: ``, enableEventPropagation: true }}
          position={{ lat: Number(latitude), lng: Number(longitude) }}
        >
          <Card
            style={{
              flexDirection: 'column',
              padding: 8,
              paddingBottom: 20,
              width: 327
            }}
          >
            <FOHTagList tags={locationTags} />

            <FOHView
              style={{
                flexDirection: 'row',
                marginTop: 6,
                zIndex: 9999
              }}
            >
              {image && (
                <FOHTouchableOpacity onPress={() => onImagePress(locationId)}>
                  <FOHImage
                    source={{ uri: image }}
                    style={{
                      backgroundColor: '#f2f2f2',
                      borderRadius: 6,
                      height: 118,
                      marginRight: SPACING_SCALE.larger,
                      width: 118
                    }}
                  />
                </FOHTouchableOpacity>
              )}

              <TouchableWithoutFeedback
                onPress={() => onImagePress(locationId)}
              >
                <FOHLocationHeaderSmall
                  favoriteLabel=""
                  formattedAddress={formattedAddress}
                  headerFontSize={14}
                  hideLabel=""
                  id={locationId}
                  isFavorited={isFavorited}
                  isHidden={isHidden}
                  key={`mappin${locationId}header`}
                  name={name}
                  pressFavorite={pressFavorite}
                  pressHide={pressHide}
                  tags={locationTags}
                />
              </TouchableWithoutFeedback>
            </FOHView>
          </Card>
        </Callout>
      )}
    </Marker>
  );
};
