import React, { useState } from 'react';

import { FOHTagText, FOHTouchableOpacity, FOHView } from '../../ingredients';

import { FOHTextField } from './FOHTextField';

export interface FOHPasswordFieldProps {
  passwordPlaceholder?: string;
  setPassword: (value: string) => any;
  password?: string;
  error?: any;
  showPassText?: string;
  hidePassText?: string;
  onKeyPress?: (value: any) => any;
}

export const FOHPasswordField = (props: FOHPasswordFieldProps) => {
  const [showPass, setShowPass] = useState(false);
  return (
    <>
      <FOHView style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <FOHTextField
          secureTextEntry={!showPass}
          required={true}
          autoCompleteType={'password'}
          placeholder={props.passwordPlaceholder}
          value={props.password}
          onChangeText={(value: string) => props.setPassword(value)}
          error={
            props.error && props.error.password ? props.error.password : null
          }
          onKeyPress={props.onKeyPress}
        />
        {!!props.hidePassText && !!props.showPassText ? (
          <FOHTouchableOpacity
            style={{ marginTop: 4, marginLeft: 8 }}
            onPress={() => setShowPass(!showPass)}
          >
            <FOHTagText>
              {showPass ? props.hidePassText : props.showPassText}
            </FOHTagText>
          </FOHTouchableOpacity>
        ) : null}
      </FOHView>
    </>
  );
};
