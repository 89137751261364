import React, { useMemo } from 'react';

import { compose } from 'ramda';
import { withTranslation } from 'react-i18next';

import { withIsMobile } from '../../utils';
import { FOHNavigationProgress, FOHSpace } from '../../components';

import { VerifyOrEditLocation } from '../Locations';
import { LocationInvite } from '../EmployerTeam';
import { FirstPosition } from '../EmployerPositions';
import { useOnboardingState } from '../Navigation/useOnboardingState';

import { EmployerOnboardingQualifyQuestions } from './EmployerOnboardingQualifyQuestions';
import { useEmployerOnboardingContext } from './useEmployerOnboardingContext';

// Configure onboarding order
export const steps = [
  {
    title: 'answerQualifyQuestionsStep',
    Component: EmployerOnboardingQualifyQuestions
  },
  { title: 'verifyLocationStep', Component: VerifyOrEditLocation },
  { title: 'inviteStep', Component: LocationInvite },
  { title: 'createPositionStep', Component: FirstPosition }
];

const OnboardingRoutes = props => {
  const { stepIndex } = props;

  const Component = steps[stepIndex - 1].Component;

  return <Component {...props} />;
};

const EmployerOnboardingRouter = props => {
  const { stepIndex, nextStep, previousStep, finishOnboarding } =
    useOnboardingState(props);

  const employerOnboardingActionsAndState = useEmployerOnboardingContext();

  const { t } = props;
  const stepTitles = steps.map(step => t(step.title));

  const getPreviousStep = useMemo(
    () => () => {
      if (stepIndex === 2) {
        return undefined;
      }
      return previousStep;
    },
    [stepIndex]
  );

  return (
    <div>
      <div style={{ paddingTop: props.isMobile ? '50px' : '60px' }} />
      <FOHNavigationProgress
        stepIndex={stepIndex - 1}
        steps={stepTitles}
        small={props.isMobile}
      />
      <OnboardingRoutes
        {...props}
        key="routes"
        {...employerOnboardingActionsAndState}
        stepIndex={stepIndex}
        nextStep={nextStep}
        previousStep={getPreviousStep()}
        finishOnboarding={finishOnboarding}
      />
      <FOHSpace />
    </div>
  );
};

export default compose(
  withIsMobile,
  withTranslation('EmployerOnboardingSteps')
)(EmployerOnboardingRouter);
