import React from 'react';

import { TouchableWithoutFeedback, Platform, Dimensions } from 'react-native';
import { defaultTo, prop } from 'ramda';
import styled from 'styled-components/native';

import {
  FOHScrollView,
  FOHTouchableOpacity,
  FOHView,
  FOHTextButton,
  FOHXIcon
} from '../../ingredients';

import { GrayBlur, FOHModalProps } from './FOHModal';

export const FOHDialog = (props: FOHModalProps) => {
  const z = 9999;
  const width = prop('width', Dimensions.get('window'));
  const shift = width / 2 + defaultTo(0, prop('leftContentShift', props));
  return (
    <>
      {props.open && (
        <TouchableWithoutFeedback onPress={props.close}>
          <GrayBlur style={{ zIndex: z - 1 }} />
        </TouchableWithoutFeedback>
      )}

      {props.open && (
        <DialogView
          style={{
            zIndex: z,
            alignSelf: 'center',
            left: shift,
            right: -defaultTo(0, prop('leftContentShift', props)),
            transform: [{ translateX: '-50%' }],
            top: props.open ? 100 : 0,
            height: props.open ? '85%' : 0,
            maxWidth: 800,
            ...props.style
          }}
        >
          {props.close && (
            <CloseCircle>
              <FOHTouchableOpacity onPress={props.close} testID={'close-modal'}>
                <FOHXIcon />
              </FOHTouchableOpacity>
            </CloseCircle>
          )}

          <FOHScrollView>
            <ContainerView
              style={{
                ...props.containerStyle,
                height: props.open ? '100%' : 0
              }}
              testID={props.testID}
            >
              {props.children}
              <FOHView style={{ paddingTop: 24 }} />
              {props.close && props.doneText ? (
                <FOHView
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingBottom: 12,
                    bottom: 10,
                    width: '100%'
                  }}
                >
                  <FOHTextButton
                    onPress={() => props.close && props.close()}
                    title={props.doneText}
                  />
                </FOHView>
              ) : (
                <></>
              )}
            </ContainerView>
          </FOHScrollView>
        </DialogView>
      )}
    </>
  );
};

const ContainerView = styled(FOHView)`
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  padding-top: 24px;
  z-index: 999;
  background-color: #ffffff;
`;

const DialogView = styled(FOHView)`
  bottom: 0;
  left: 0;
  right: 0;
  height: 90px;
  position: ${Platform.OS === 'web' ? 'fixed' : 'absolute'};
  z-index: 200;
  padding-left: 24px;
  padding-right: 24px;
`;

const CloseCircle = styled(FOHView)`
  width: 32px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 50%;
  z-index: 99999;
  top: -16;
  right: 8px;
  position: absolute;
  justify-content: center;
  align-items: center;
`;
