import React, { useState, useEffect } from 'react';

import { compose, path } from 'ramda';
import { withTranslation } from 'react-i18next';

import { withUpdateUser, withUser } from '../../api/hocs';
import { sendVerificationMessage } from '../../api/sendVerificationMessage';
import { CANDIDATE_PROFILE } from '../../constants';
import { useNavigation } from '../../utils/navigation';
import { FOHEmailVerificationScreen } from '../../components';

const ConfirmEmailScreen = props => {
  const { navigateTo } = useNavigation();

  const [error] = useState(null);
  const [messageSent, setMessageSent] = useState(false);
  const [channelMessage, setChannelMessage] = useState();
  const { t } = props;

  useEffect(() => {
    if (!messageSent) {
      sendVerificationMessage();
      setMessageSent(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user]);

  useEffect(() => {
    if (!props.user) {
      return;
    }

    switch (true) {
      case props.user.notify_via_sms && props.user.notify_via_email:
        setChannelMessage('both');
        break;
      case props.user.notify_via_sms:
        setChannelMessage('sms');
        break;
      case props.user.notify_via_email:
        setChannelMessage('email');
        break;
      default:
        break;
    }
  }, [props.user]);

  const title = {
    sms: t('titlePhone'),
    email: t('titleEmail'),
    both: t('titleBoth')
  }[channelMessage];

  const detailText = {
    sms: t('detailTextPhone'),
    email: t('detailTextEmail'),
    both: t('detailTextBoth')
  }[channelMessage];

  return (
    <FOHEmailVerificationScreen
      error={error}
      title={title}
      detailText={detailText}
      submitLabel={t('submitLabel')}
      handleSubmit={() => sendVerificationMessage()}
      skipForNowLabel={t('skipForNowLabel')}
      skip={() => {
        return navigateTo(
          `${CANDIDATE_PROFILE}/${path(
            ['user', 'candidate_profile', 'handle'],
            props
          )}`
        );
      }}
    />
  );
};

export default compose(
  withUpdateUser,
  withUser,
  withTranslation('ConfirmEmailFeature')
)(ConfirmEmailScreen);
