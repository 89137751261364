import React from 'react';

import { defaultTo, prop, path, find, propEq } from 'ramda';
import { useTranslation } from 'react-i18next';

import { multiSelectItem } from '../../utils/fieldHelpers';
import { useDisableBackgroundScroll, useIsMobile } from '../../utils';
import { useTypeAheadSearchQuery } from '../../graphql/generated';
import {
  FOHAccordianSelectTable,
  FOHRightDrawer,
  FOHScrollView
} from '../../components';

export const PositionFilterDrawer = props => {
  const {
    selectedItems,
    openFilters,
    setOpenFilters,
    setSelectedItems,
    workingPreferences,
    setWorkingPreferences
  } = props;
  // Get default filters
  const defaultSearchQuery = useTypeAheadSearchQuery({
    variables: { search: '' }
  });
  const { t } = useTranslation('PositionSearchAheadFeature');
  const { isMobile } = useIsMobile();
  const defaultSearchItems = defaultTo(
    [],
    path(['data', 'searchAhead', 'groups'], defaultSearchQuery)
  ).map(group => ({
    title: t(prop('group', group)),
    items: defaultTo([], prop('values', group))
  }));

  const drawerWidth = isMobile ? 290 : 500;

  useDisableBackgroundScroll([openFilters]);

  return (
    <FOHRightDrawer
      top={50}
      open={openFilters}
      openWidth={drawerWidth}
      close={() => setOpenFilters(false)}
    >
      <FOHScrollView>
        {defaultSearchItems.map((group, index) => (
          <FOHAccordianSelectTable
            width={drawerWidth}
            title={prop('title', group)}
            items={prop('items', group)}
            key={index}
            selectItem={value => {
              const item = find(propEq('value', value), group.items);
              multiSelectItem(
                {
                  value: prop('value', item),
                  label: prop('label', item)
                },
                setSelectedItems,
                selectedItems
              );
            }}
            clear={() => {
              const valuesInGroup = group.items.map(item => item.value);
              const remaining = selectedItems.filter(
                item => !valuesInGroup.includes(item.value)
              );
              setSelectedItems(remaining);
            }}
            clearLabel={t('clear')}
            selected={selectedItems
              .map(item => item.value)
              .filter(value => find(propEq('value', value), group.items))}
          />
        ))}
        <FOHAccordianSelectTable
          width={drawerWidth}
          title={t('ProfileFeature:workPreferencesTableTitle')}
          items={[
            {
              label: t('dressCode'),
              value: 'canHaveDressCode'
            },
            {
              label: t('benefits'),
              value: 'isBenefitsRequired'
            }
          ]}
          selectItem={value => {
            multiSelectItem(value, setWorkingPreferences, workingPreferences);
          }}
          clear={() => {
            setWorkingPreferences([]);
          }}
          clearLabel={t('clear')}
          selected={workingPreferences}
        />
      </FOHScrollView>
    </FOHRightDrawer>
  );
};
