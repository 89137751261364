import React, { FunctionComponent } from 'react';

import { produce } from 'immer';

import { FOHOther } from '../../../features/CareerPage/adapters';

import { FOHSectionLabel, FOHSmallDetailLabel } from '../../ingredients';
import { FOHQuestionCell, FOHQuestionProps, FOHSetAnswer } from '../../recipes';

import {
  QuestionsWrapper,
  Wrapper
} from './styled/FOHCareerPageQuestionnaire.styled';

export interface FOHCareerPageQuestionnaireAnswer {
  id: string;
  answer: FOHSetAnswer;
  question: FOHQuestionProps;
}

interface FOHCareerPageQuestionnaireProps {
  answers: FOHCareerPageQuestionnaireAnswer[];
  setAnswers: (updatedAnswers: FOHCareerPageQuestionnaireAnswer[]) => void;
  translations: Record<string, string>;
}

export const FOHCareerPageQuestionnaire: FunctionComponent<
  FOHCareerPageQuestionnaireProps
> = ({ answers, setAnswers, translations }) => {
  const updateAnswer = (update: FOHSetAnswer) =>
    setAnswers(
      produce(answers, draft => {
        for (const answer of draft) {
          if (answer.question.id === update.questionId) {
            answer.answer.optionId = update.optionId;
            answer.answer.value = update.value;

            if (update.value === FOHOther)
              answer.answer.freeText = update.freeText;
          }
        }
      })
    );

  return (
    <Wrapper>
      <FOHSectionLabel style={{ fontSize: 18 }}>
        {translations.heading}
      </FOHSectionLabel>
      <FOHSmallDetailLabel>{translations.message}</FOHSmallDetailLabel>

      <QuestionsWrapper>
        {answers.map(({ answer, question }, i) => (
          <FOHQuestionCell
            answer={answer}
            careerPage
            cellWidth="25%"
            id={question.id}
            key={question.id}
            options={question.options}
            questionType={question.questionType}
            title={`${i + 1}. ${question.title}`}
            updateAnswer={updateAnswer}
          />
        ))}
      </QuestionsWrapper>
    </Wrapper>
  );
};
