import styled from 'styled-components/native';

import {
  Screen,
  FOHView,
  FOHColors,
  SPACING_SCALE
} from '../../../ingredients';
import { CommonProps } from '../../../typings';
import { TOP_NAV_HEIGHT } from '../../../recipes';

const COL_GAP = 56;

// TODO: Bumping @types/styled-components to 5.x and deps fixes this

export const ScrollViewWrapper = styled(FOHView)`
  // ScrollView parent height must be constrained for scrollTo() to work
  max-height: 100vh;
  min-height: 100vh;
  background-color: ${FOHColors.BACKGROUND_GRAY};
`;

interface CareerPageScreenProps extends CommonProps {
  isEmployer: boolean;
}

export const CAREER_PAGE_SCREEN_TOP_PADDING = 230;

export const CareerPageScreen = styled(Screen)<CareerPageScreenProps>`
  display: flex;
  align-items: center;
  margin-top: ${({
    isEmployer,
    isMobile
  }: {
    isEmployer: boolean;
    isMobile: boolean;
  }) => (isEmployer && !isMobile ? 0 : TOP_NAV_HEIGHT)};
  padding-top: ${({ isMobile }: { isMobile: boolean }) =>
    isMobile ? '' : CAREER_PAGE_SCREEN_TOP_PADDING};
  padding-right: ${({ isMobile }: { isMobile: boolean }) =>
    isMobile ? '' : COL_GAP / 2};
  padding-bottom: ${({ isMobile }: { isMobile: boolean }) =>
    isMobile ? '' : CAREER_PAGE_SCREEN_TOP_PADDING};
  padding-left: ${({ isMobile }: { isMobile: boolean }) =>
    isMobile ? '' : COL_GAP / 2};
`;

export const CareerPageScreenBackground = styled(FOHView)<{
  image?: any;
  color?: string;
}>`
  z-index: -1;
  position: absolute;
  top: 0;
  width: 100%;
  height: 640px;
  background-color: ${({ color }: { color: string }) => color};

  ${({ image }: { image: string }) =>
    image
      ? `
          background-image: url(${image ? image : 'none'});
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center top;
        `
      : ``};
`;

export const CareerPageContainer = styled(FOHView)<CommonProps>`
  background-color: white;
  padding: ${SPACING_SCALE.huge}px;
  padding-bottom: ${({ isMobile }: { isMobile: boolean }) =>
    isMobile ? 0 : SPACING_SCALE.huge};
  width: 100%;
  max-width: 1230px;

  ${({ isMobile }: { isMobile: boolean }) =>
    isMobile
      ? `
        flex-direction: column;
        row-gap: 32px
      `
      : `
        flex-direction: row;
        border-radius: 16px;
        column-gap: ${COL_GAP};
  `}
`;

export const CareerPageSidebar = styled(FOHView)<CommonProps>`
  ${({ isMobile }: { isMobile: boolean }) =>
    isMobile ? `width: 100%` : `width: 350px`};
`;

export const CareerPageMain = styled(FOHView)<CommonProps>`
  align-items: flex-start;

  ${({ isMobile }: { isMobile: boolean }) =>
    !isMobile &&
    `
      flex-grow: 1;
      flex-shrink: 1;
    `};
`;
