import { path, prop } from 'ramda';

export const fetchMore = async (
  query,
  variables,
  variableName = 'postitions'
) => {
  const pathTo = ['data', variableName];

  const queryResult = path(pathTo, query);

  if (!queryResult) return undefined;

  return queryResult?.pageInfo?.hasNextPage && !query?.loading
    ? await query.fetchMore({
        variables: {
          ...variables,
          after: queryResult?.pageInfo?.endCursor,
          first: 10
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const newEdges = prop(variableName, fetchMoreResult)?.edges;
          const pageInfo = prop(variableName, fetchMoreResult)?.pageInfo;
          const totalCount =
            prop(variableName, fetchMoreResult)?.totalCount || 0;

          return newEdges.length
            ? {
                [variableName]: {
                  __typename: prop(variableName, previousResult)?.__typename,
                  edges: [
                    ...prop(variableName, previousResult)?.edges,
                    ...newEdges
                  ],
                  pageInfo,
                  totalCount
                }
              }
            : previousResult;
        }
      })
    : undefined;
};
