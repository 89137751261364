import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { find, prop, propEq, path } from 'ramda';

import { handle } from '../../utils';
import { FOHStatusDropdown, FOHStatusType } from '../../components';

import { useCandidateProfileMutation } from './useCandidateProfileMutation';

export const getDefaultStatusOptions = t => [
  {
    label: t('activelyLookingLabel'),
    description: t('activelyLookingDescription'),
    value: FOHStatusType.ACTIVELY_LOOKING,
    selected: true
  },
  {
    label: t('lookingForShiftLabel'),
    description: t('lookingForShiftDescription'),
    value: FOHStatusType.LOOKING_FOR_SHIFT,
    selected: false
  },
  {
    label: t('openToOffersLabel'),
    description: t('openToOffersDescription'),
    value: FOHStatusType.OPEN_TO_OFFERS,
    selected: false
  },
  {
    label: t('closedToOffersLabel'),
    description: t('closedToOffersDescription'),
    value: FOHStatusType.CLOSED_TO_OFFERS,
    selected: false
  }
];

export const UpdateStatusDropdwon = _props => {
  const { me, updateCandidate } = useCandidateProfileMutation();
  const { t } = useTranslation('WorkPreferencesFeature');
  const options = getDefaultStatusOptions(t);
  const [open, setOpen] = useState(false);
  const statusType = path(['candidateProfile', 'status'], me);
  const status = find(propEq('value', statusType), options);

  return (
    <FOHStatusDropdown
      openStatus={open}
      statusLabel={t('statusLabel')}
      statusText={prop('label', status)}
      statusType={prop('value', status)}
      setOpenStatus={setOpen}
      selectStatus={async _status => {
        await handle(
          updateCandidate({ variables: { status: prop('value', _status) } })
        );
        setOpen(false);
      }}
      statusOptions={options}
    />
  );
};
