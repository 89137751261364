import React, { useRef } from 'react';

import styled from 'styled-components/native';
import { useHover } from 'react-native-web-hooks';

import {
  FOHButton,
  FOHSpace,
  FOHBestMatchBadge,
  FOHView,
  FOHTouchableOpacity,
  FOHImage
} from '../../ingredients';

import {
  FOHLocationHeaderProps,
  FOHLocationHeader
} from '../blocks/FOHLocationHeader';

export interface FOHRestaurantLocationCellProps extends FOHLocationHeaderProps {
  image: string;
  seeMoreLabel: string;
  seeMore: () => any;
  isCard?: boolean;
}

const LocationImage = styled(FOHImage)`
  border-radius: 6px;
  background-color: #f2f2f2;
`;

export const FOHRestaurantLocationCell = (
  props: FOHRestaurantLocationCellProps
) => {
  const ref = useRef(null);
  const isHovered = useHover(ref);
  const IMAGE_SIZE = props.vertical ? 80 : 180;
  const IMAGE_WIDTH = props.vertical && !props.isCard ? '100%' : IMAGE_SIZE;

  return (
    <FOHView
      style={{
        width: props.isCard ? undefined : '100%',
        justifyContent: 'center'
      }}
    >
      <CardView {...props}>
        <FOHView
          style={{
            flexDirection: props.vertical ? 'column' : 'row',
            flex: 1,
            left: 0,
            width: '100%'
          }}
        >
          {props.hasJobMatch &&
            !!props.jobMatchLabel &&
            !props.isCard &&
            !props.vertical && (
              <FOHView style={{ position: 'absolute', right: '0', top: '0' }}>
                <FOHBestMatchBadge label={props.jobMatchLabel} />
              </FOHView>
            )}
          <FOHView>
            <FOHView
              ref={ref}
              style={{
                marginRight: props.vertical ? 0 : 26,
                width: IMAGE_SIZE
              }}
            >
              <FOHTouchableOpacity onPress={props.seeMore}>
                <LocationImage
                  source={{ uri: props.image }}
                  resizeMode="contain"
                  style={{
                    width: IMAGE_WIDTH,
                    height: IMAGE_SIZE
                  }}
                />
                {props.isHidden && (
                  <FOHView
                    style={{
                      position: 'absolute',
                      backgroundColor: '#BBBBBBB0',
                      zIndex: 999,
                      width: IMAGE_WIDTH,
                      height: IMAGE_SIZE,
                      borderRadius: 6
                    }}
                  />
                )}
                {isHovered && !props.vertical && (
                  <FOHView
                    style={{
                      position: 'absolute',
                      width: IMAGE_WIDTH,
                      height: IMAGE_SIZE,
                      borderRadius: 6,
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#BBBBBBB0'
                    }}
                  >
                    <FOHButton
                      style={{
                        width: props.isCard ? IMAGE_WIDTH : 150,
                        zIndex: 999
                      }}
                      onPress={props.seeMore}
                      title={props.seeMoreLabel}
                    />
                  </FOHView>
                )}
              </FOHTouchableOpacity>
            </FOHView>
          </FOHView>
          <FOHLocationHeader
            id={props.id}
            name={props.name}
            tags={props.tags}
            isHiring={props.isHiring}
            hiringLabel={props.hiringLabel}
            jobMatchLabel={props.jobMatchLabel}
            hasJobMatch={props.hasJobMatch}
            formattedAddress={props.formattedAddress}
            vertical={props.vertical}
            favoriteLabel={props.favoriteLabel}
            hideLabel={props.hideLabel}
            isFavorited={props.isFavorited}
            isHidden={props.isHidden}
            pressFavorite={props.pressFavorite}
            pressHide={props.pressHide}
            favoritedHelpText={props.favoritedHelpText}
            hideMeHelpText={props.hideMeHelpText}
            followerCountLabel={props.followerCountLabel}
            openHiringModal={props.openHiringModal}
            disabledHiringButton={props.disabledHiringButton}
            hiringBtnHelpText={props.hiringBtnHelpText}
          />
        </FOHView>
      </CardView>
      {props.isCard && <FOHSpace />}
    </FOHView>
  );
};

const CardView = styled(FOHView)`
  max-width: ${(props: any) => (props.isCard ? 343 : '100%')};
  background-color: #ffffff;
  padding: ${(props: any) => (props.isCard ? 24 : 0)}px;
  margin-right: ${(props: any) => (props.isCard ? 24 : 0)}px;
  border-radius: 6px;
  border: ${(props: any) => (props.isCard ? '1px solid #E8E8E8' : 'none')};
  min-height: ${(props: any) => (props.isCard ? 400 : 'none')};
`;
