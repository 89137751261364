import React from 'react';

import { defaultTo, length } from 'ramda';

import {
  FOHNextIcon,
  FOHLabel,
  FOHInverseButton,
  FOHDivider,
  FOHGreenTag,
  FOHHeaderH3,
  FOHColors,
  FOHView,
  FOHTouchableOpacity,
  FOHSpaceSmall,
  FOHSpaceSmallest
} from '../../ingredients';

export interface FOHNavigationMenuProps {
  logoutLabel?: string;
  logout?: () => void;
  sections: Array<FOHNavSection>;
  onPressRoute: (route: string, title: string) => void;
  children?: React.ReactNode;
}

// One or many routes
export interface FOHNavSection {
  routes?: Array<FOHRoute>;
  route?: string;
  onPress?: () => void;
  label?: string;
  icon?: any;
  disabled?: boolean;
}
export interface FOHRoute {
  label: string;
  route: string;
  notification?: boolean;
  notificationLabel?: string;
}

export const FOHNavigationMenu = (props: FOHNavigationMenuProps) => (
  <FOHView
    style={{
      backgroundColor: FOHColors.WHITE,
      maxWidth: 400,
      justifyContent: 'center'
    }}
  >
    {props.sections.map((section, index) => (
      <FOHView key={`${section.label}-${index}`}>
        <FOHTouchableOpacity
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            flex: 1
          }}
          onPress={() => {
            console.log('section', section);
            if (section.onPress) {
              section.onPress();
              return;
            }

            section.route &&
              section.label &&
              props.onPressRoute(section.route, section.label);
          }}
          testID={`FOHNavigationMenu-${section.label}`}
          disabled={section?.disabled}
        >
          <section.icon />
          <FOHSpaceSmall />
          <FOHHeaderH3
            style={{
              paddingTop: 4,
              color: section?.disabled
                ? FOHColors.GRAYSCALE_400
                : FOHColors.GRAYSCALE_900
            }}
          >
            {section.label}
          </FOHHeaderH3>
          <FOHView
            style={{
              flex: 1,
              alignItems: 'flex-end',
              justifyContent: 'center',
              paddingTop: 4
            }}
          >
            {length(defaultTo([], section.routes)) === 0 && (
              <FOHNextIcon
                style={{
                  tintColor: section?.disabled
                    ? FOHColors.GRAYSCALE_400
                    : FOHColors.GRAYSCALE_900
                }}
              />
            )}
          </FOHView>
        </FOHTouchableOpacity>
        {section.routes && length(section.routes) > 0 ? (
          <FOHView style={{ paddingLeft: 24 }}>
            {section.routes &&
              section.routes.map((route: any, _index: number) => (
                <FOHView key={`${route.label}-${_index}`} style={{ flex: 1 }}>
                  <FOHTouchableOpacity
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      flex: 1
                    }}
                    disabled={!route.route}
                    onPress={() =>
                      route.route &&
                      route.label &&
                      props.onPressRoute(route.route, route.label)
                    }
                    testID={`FOHNavigationMenu-${route.label}`}
                  >
                    <FOHView
                      style={{
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        flex: 5,
                        height: 40,
                        alignItems: 'center'
                      }}
                    >
                      <FOHLabel style={{ paddingTop: 4 }}>
                        {route.label}
                      </FOHLabel>
                      {route.notification && !!route.notificationLabel && (
                        <FOHGreenTag
                          style={{
                            marginLeft: 8
                          }}
                          label={route.notificationLabel}
                        />
                      )}
                    </FOHView>
                    <FOHView
                      style={{
                        flex: 1,
                        alignItems: 'flex-end',
                        justifyContent: 'center',
                        paddingTop: 4
                      }}
                    >
                      <FOHNextIcon />
                    </FOHView>
                  </FOHTouchableOpacity>
                </FOHView>
              ))}
          </FOHView>
        ) : (
          <></>
        )}
        <FOHSpaceSmall />
        <FOHSpaceSmallest />
        {index !== length(props.sections) - 1 && <FOHDivider />}
        <FOHSpaceSmall />
        <FOHSpaceSmallest />
      </FOHView>
    ))}
    <>{props.children}</>
    <FOHSpaceSmall />
    {!!props.logoutLabel && !!props.logout && (
      <FOHInverseButton
        style={{ maxWidth: 400 }}
        color={FOHColors.BLACK}
        onPress={props.logout}
        title={props.logoutLabel}
      />
    )}
  </FOHView>
);
