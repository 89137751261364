import React from 'react';

import { path, length } from 'ramda';
import { useTranslation } from 'react-i18next';

import { unwrapEdgesAt } from '../../utils';
import { useSelectedEmployerCandidatesQuery } from '../../graphql/generated';
import { FOHMultipleCandidatesSelected } from '../../components';

import { useCandidateFeedContext } from './useCandidateFeedContext';

export const NoPositionsMultiCandidateDetail = props => {
  const { t } = useTranslation('CandidateFeed');
  const { forLocation } = props;
  const { selectedEmployerCandidates, locationFilters, allCandidates } =
    useCandidateFeedContext(props);

  const candidatesQuery = useSelectedEmployerCandidatesQuery({
    skip: !props.activeStageId,
    variables: {
      employerCandidateIds: allCandidates
        ? undefined
        : selectedEmployerCandidates,
      positions: [],
      locations: locationFilters,
      stageId: props.activeStageId,
      first: allCandidates ? 200 : undefined
    }
  });

  const candidates = unwrapEdgesAt(
    ['data', 'employerCandidates', 'edges'],
    candidatesQuery
  );

  return (
    <FOHMultipleCandidatesSelected
      thumbnails={candidates.slice(0, 3).map(candidate => ({
        uri: path(['candidateProfile', 'user', 'profileImage'], candidate)
      }))}
      // extra count past the three photos displayed
      totalCount={length(candidates) < 3 ? 0 : length(candidates) - 3}
      selectionText={
        forLocation
          ? t('NoLocationsSelectedLabel')
          : t('NoPositionsSelectedLabel')
      }
      selectionHighlightText={
        forLocation
          ? t('NoLocationsSelectedHighlightText')
          : t('NoPositionsSelectedHighlightText')
      }
      detailLabel={
        forLocation
          ? t('NoLocationsSelectedDetailLabel')
          : t('NoPositionsSelectedDetailLabel')
      }
      deselectLabel={t('deselectLabel')}
    />
  );
};
