import React from 'react';

import { View } from 'react-native';

import {
  FullWidthCard,
  FOHSpace,
  FOHSpaceSmall,
  FOHAppliedStatusType,
  FOHApplicationStatusBadge,
  FOHDirectMessageIcon,
  FOHLabel,
  FOHDivider,
  FOHInverseButton,
  FOHSmallDetailLabel
} from '../../ingredients';

import {
  FOHPositionOverview,
  FOHPositionOverviewType
} from '../blocks/FOHPositionOverview';
import {
  FOHHiringManagerOverview,
  FOHHiringManagerProps
} from '../blocks/FOHHiringManagerOverview';

export interface FOHApplicationListCellProps
  extends FOHHiringManagerProps,
    FOHPositionOverviewType {
  mobile?: boolean;
  positionId: string;
  positionSlug: string;
  // Application Status
  applicationStatusType?: FOHAppliedStatusType;
  applicationStatus?: string;
  applicationStatusLabel?: string;
  // Job Details
  onPressJobDetails: (slug: string) => any;
  // CTA
  buttonCTALabel: string;
  appliedLabel: string;
  appliedOnDate: string;

  messageLabel?: string;
  onPressMessage?: () => any;
}

export const FOHApplicationListCell = (props: FOHApplicationListCellProps) => (
  <FullWidthCard>
    <View
      style={{
        flexDirection: props.mobile ? 'column' : 'row',
        flexWrap: 'wrap'
      }}
    >
      <View style={{ flex: 1, paddingLeft: 8 }}>
        <FOHPositionOverview
          {...props}
          noBorder={true}
          onPressLocation={props.onPressLocation}
          locationImage={props.locationImage || ''}
          postedDateText={''}
          padding={16}
        />
      </View>
      {props.hiringManagerName && !props.mobile && (
        <View
          style={{
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: props.mobile ? 'flex-start' : 'flex-end',
            paddingTop: props.mobile ? 0 : 10,
            paddingBottom: 0,
            flexWrap: 'wrap'
          }}
        >
          {props.hiringManagerPosition && props.hiringManagerName && (
            <FOHHiringManagerOverview {...props} />
          )}
          {props.onPressMessage &&
            !!props.messageLabel &&
            !props.mobile &&
            props.hiringManagerName && (
              <View
                style={{
                  paddingLeft: 24,
                  minWidth: 250,
                  paddingRight: 24
                }}
              >
                <FOHInverseButton
                  icon={FOHDirectMessageIcon}
                  centerIcon={true}
                  color={'#000000'}
                  textColor={'#ffffff'}
                  opaque={true}
                  testID={'on-press-message'}
                  selected={true}
                  onPress={props.onPressMessage}
                  title={props.messageLabel}
                  style={{
                    width: 250,
                    marginBottom: 10,
                    height: 46
                  }}
                />
              </View>
            )}
        </View>
      )}
    </View>
    {!props.mobile && <FOHDivider />}
    <View
      style={{
        paddingBottom: 16,
        flexDirection: props.mobile ? 'column' : 'row'
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          flexWrap: 'wrap',
          paddingLeft: 24,
          paddingTop: props.mobile ? 0 : 12
        }}
      >
        <View
          style={{
            alignItems: 'flex-start',
            paddingTop: 4,
            minWidth: 100
          }}
        >
          <FOHSmallDetailLabel
            style={{ textAlign: 'left', alignItems: 'flex-start' }}
          >
            {props.appliedLabel}
          </FOHSmallDetailLabel>
          <FOHSpaceSmall />
          <FOHLabel>{props.appliedOnDate}</FOHLabel>
        </View>
        <FOHSpace />
        {!!props.applicationStatusType && !!props.applicationStatus && (
          <View
            style={{
              alignItems: 'flex-start',
              paddingTop: 4,
              minWidth: 100
            }}
          >
            <FOHSmallDetailLabel
              style={{ textAlign: 'left', alignItems: 'flex-start' }}
            >
              {props.applicationStatusLabel}
            </FOHSmallDetailLabel>
            <FOHSpaceSmall />
            <FOHApplicationStatusBadge
              type={props.applicationStatusType}
              label={props.applicationStatus}
            />
          </View>
        )}
      </View>

      {props.mobile &&
        props.hiringManagerPosition &&
        props.hiringManagerName && (
          <View style={{ paddingLeft: 24, paddingTop: props.mobile ? 10 : 0 }}>
            <FOHHiringManagerOverview {...props} flexStart={true} />
          </View>
        )}

      <View
        style={{
          flex: 1,
          minWidth: 270,
          paddingBottom: 10,
          alignItems: props.mobile ? 'center' : 'flex-end',
          justifyContent: props.mobile ? 'center' : 'flex-end',
          paddingRight: props.mobile ? 0 : 24,
          paddingTop: 16,
          flexDirection: props.mobile ? 'row' : 'column'
        }}
      >
        {props.onPressMessage &&
          !!props.messageLabel &&
          props.mobile &&
          props.hiringManagerName && (
            <FOHInverseButton
              icon={FOHDirectMessageIcon}
              centerIcon={true}
              color={'#000000'}
              textColor={'#ffffff'}
              opaque={true}
              testID={'on-press-message'}
              selected={true}
              onPress={props.onPressMessage}
              title={props.messageLabel}
              style={{
                width: props.mobile ? '45%' : 250,
                height: 46
              }}
            />
          )}
        {props.mobile && <FOHSpaceSmall />}
        <FOHInverseButton
          onPress={() => props.onPressJobDetails(props.positionSlug)}
          title={props.buttonCTALabel}
          color={'#ffffff'}
          textColor={'#000000'}
          style={{
            width: props.mobile ? '45%' : 250
          }}
        />
      </View>
    </View>
  </FullWidthCard>
);
