import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Platform } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useSpring, animated } from 'react-spring';
import { prop, length, path } from 'ramda';
import Loadable from '@loadable/component';

import {
  CareerPageScreenBackground,
  FileFields,
  FOHBanner,
  FOHBannerType,
  FOHColors,
  FOHSearchableSelectDropdown,
  FOHCircleLoader,
  FOHView,
  SPACING_SCALE,
  FOHBlueAdd,
  FOHEditMarker,
  FOHLocationPin,
  FOHTrashCanBlk,
  FOHInverseButton,
  FOHCareerPageGalleryItemsInput,
  FOHCareerPageLayout,
  FOHCareerPageLocationForm,
  FOHCareerPageMainImage,
  FOHCareerPageMobileForm,
  FOHEditCoverPhotoButton,
  FOHCareerPageQuestionnaire,
  FOHSectionLabel,
  FOHSmallDetailLabel,
  TOP_NAV_HEIGHT
} from '../../components';
import MOBILE_FORM_PLACEHOLDER from '../../images/career-page-mobile-form.svg';
import DEFAULT_COVER from '../../images/career-page-default-cover.svg';
import { useBanner } from '../Navigation/useBanner';
import { useCandidateFeedContext } from '../CandidateFeed/useCandidateFeedContext';
import { handleMutation, useIsMobile } from '../../utils';
import {
  useEditCareerPageGalleryMutation,
  useEditCareerPageMutation,
  useEditCareerPageWithCoverMutation,
  useUpdateCareerPageAnswersMutation
} from '../../../src/graphql/generated';
import { useGetMeData } from '../SignIn';

import { FormColorBrandingSection } from './FormColorBrandingSection';
import { FOHPhotoInputButton } from './FOHPhotoInputButton';
import { FormActionBar } from './FormActionBar';
// import { FormFooter } from './FormFooter';
import { useGetStockPhotos } from './hooks/useGetStockPhotos';
import { blobToFile, getStockPhoto, formatFileSize } from './utils';
import { FOHOther, adaptMainImageMutationPayload } from './adapters';
import { FORM_ID, MAX_DESCRIPTION_LENGTH } from './consts';
import DeleteConfirmationModal from './DeleteConfirmationModal';

const JobDescriptionEditor = Loadable(() =>
  import('../EmployerPositions/JobDescriptionEditor')
);

const AnimatedBanner = animated(FOHBanner);

export const CareerPageForm = ({
  answers,
  data: {
    addressCity,
    addressPostalCode,
    addressState,
    addressStreet1,
    addressStreet2,
    brandColor,
    brandPageColor,
    brandTableColor,
    cover,
    coverColor,
    description,
    fontColor: currentFontColor,
    fontFamily: currentFontFamily,
    id: careerPageID,
    title
  },
  galleriesArray: initialGalleries,
  mainImageArray,
  selectedLocations,
  slug
}) => {
  const { data: me } = useGetMeData();
  const employerProfileId = path(['employerProfile', 'id'], me);

  const {
    locationFilters,
    locationSearch,
    multiSelectLocation,
    myLocations,
    selectAllLocations,
    setLocationSearch,
    setSelectedLocations
  } = useCandidateFeedContext();

  const locationOptions = myLocations.map(loc => ({
    label: prop('displayName', loc),
    sublabel: prop('formattedAddress', loc),
    value: prop('id', loc)
  }));

  const { t } = useTranslation('CareerPage');
  const { banner, setBanner } = useBanner();
  const { isMobile } = useIsMobile();

  const galleryInputRef = useRef(null);
  const coverImageInputRef = useRef(null);
  const mainImageInputRef = useRef(null);

  const { defaultStockPhotos, loadMore } = useGetStockPhotos(getStockPhoto);

  //#region state
  const [answersState, setAnswersState] = useState(answers);
  const [brandColorState, setBrandColorState] = useState(brandColor);
  const [brandPageColorState, setBrandPageColorState] =
    useState(brandPageColor);
  const [brandTableColorState, setBrandTableColorState] =
    useState(brandTableColor);
  const [coverAssetState, setCoverAssetState] = useState(); // important to init as undefined
  const [descriptionState, setDescriptionState] = useState(description);
  const [formError, setFormError] = useState();
  const [galleryItemsState, setGalleryItemsState] = useState(initialGalleries);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [isUploadingGalleries, setIsUploadingGalleries] = useState(false);
  const [mainImageState, setMainImageState] = useState(mainImageArray?.[0]);
  const [activeFontFamily, setFont] = useState(currentFontFamily);
  const [fontColor, setFontColor] = useState(currentFontColor);
  const [locationOpen, setLocationOpen] = useState(false);

  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);

  //#endregion state

  const defaultValues = {
    addressCity,
    addressPostalCode,
    addressState,
    addressStreet1,
    addressStreet2,
    slug,
    title
  };

  const placeholders = {
    addressCity: (t('inputAddressCityLabel') || '').toUpperCase(),
    addressPostalCode: (t('inputZipCodeLabel') || '').toUpperCase(),
    addressState: (t('inputStateLabel') || '').toUpperCase(),
    addressStreet1: (t('inputAddress1Label') || '').toUpperCase(),
    addressStreet2: (t('inputAddress2Label') || '').toUpperCase(),
    location: (t('inputLocationNameLabel') || '').toUpperCase(),
    slug: (t('inputSlugLabel') || '').toUpperCase(),
    title: (t('inputTitleLabel') || '').toUpperCase()
  };

  const showSuccBanner = useCallback(
    () =>
      setBanner({
        bannerMessage: t('formSubmission', { returnObjects: true }).success,
        bannerType: FOHBannerType.SUCCESS
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const showInvalidFileTypeBanner = useCallback(
    () =>
      setBanner({
        bannerMessage: t('invalidFileType'),
        bannerType: FOHBannerType.DANGER
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  //#region mutations
  const [mutateCareerPageAnswers] = useUpdateCareerPageAnswersMutation({});
  const [mutateCareerPageWithCover] = useEditCareerPageWithCoverMutation({
    onCompleted: data => {
      if (
        coverAssetState?.image !== null &&
        data?.mutateCareerPage?.coverImageUploadUrl
      ) {
        // push cover image to AWS
        fetch(data?.mutateCareerPage?.coverImageUploadUrl, {
          body: coverAssetState.image.file,
          method: 'PUT'
        });
      }

      showSuccBanner();
    }
  });

  const [mutateCareerPage] = useEditCareerPageMutation({
    onCompleted: () => showSuccBanner()
  });

  const [mutateCareerPageGalleriesMainImage] = useEditCareerPageGalleryMutation(
    {
      onCompleted: data => {
        if (
          mainImageState !== null &&
          data?.mutateCreateCareerPageGallery?.urls?.[0]?.signedS3Url
        ) {
          // push main image to AWS
          fetch(data?.mutateCreateCareerPageGallery?.urls?.[0]?.signedS3Url, {
            body: mainImageState.file,
            method: 'PUT'
          });
        }

        showSuccBanner();
      }
    }
  );

  const [mutateCareerPageGalleries] = useEditCareerPageGalleryMutation({
    onCompleted: data => {
      setIsUploadingGalleries(true);
      const { itemsToAdd } = adaptMainImageMutationPayload(
        galleryItemsState,
        initialGalleries
      );

      const promises = data?.mutateCreateCareerPageGallery?.urls?.map(
        (url, id) => {
          const uploadedGalleries = galleryItemsState?.filter(galleryItem =>
            itemsToAdd.some(
              item =>
                item?.[FileFields.FileName] ===
                galleryItem?.[FileFields.FileName]
            )
          );

          if (!url?.signedS3Url) return null;

          return fetch(url?.signedS3Url, {
            body: uploadedGalleries?.[id]?.file,
            method: 'PUT'
          });
        }
      );

      Promise.all(promises).then(() => {
        setIsUploadingGalleries(false);
        showSuccBanner();
      });
    }
  });
  //#endregion mutations

  //#region handlers
  const handleFormSubmit = async ({
    addressCity: _addressCity,
    addressPostalCode: _addressPostalCode,
    addressState: _addressState,
    addressStreet1: _addressStreet1,
    addressStreet2: _addressStreet2,
    title: _title
  }) => {
    const { color, image, mode } = coverAssetState || {};

    const variables = {
      addressCity: _addressCity,
      addressPostalCode: _addressPostalCode,
      addressState: _addressState,
      addressStreet1: _addressStreet1,
      addressStreet2: _addressStreet2,
      brandColor: brandColorState,
      brandPageColor: brandPageColorState,
      brandTableColor: brandTableColorState,
      description: descriptionState === '<p><br></p>' ? null : descriptionState,
      fontColor,
      fontFamily: activeFontFamily,
      selectedLocations: locationFilters,
      slug,
      title: _title
    };

    // There's room for improvement here if I can get "partialUpdate to work
    if (mode === 'color') {
      await handleMutation(
        mutateCareerPageWithCover({
          variables: {
            ...variables,
            coverColor: color,
            [FileFields.FileName]: 'null',
            [FileFields.FileType]: 'null'
          }
        })
      );
    } else if (mode === 'image' || mode === 'blank') {
      await handleMutation(
        mutateCareerPageWithCover({
          variables: {
            ...variables,
            coverColor: '', // image data will exist if it was added, otherwise - reset
            [FileFields.FileName]: image?.[FileFields.FileName] || 'null',
            [FileFields.FileType]: image?.[FileFields.FileType] || 'null'
          }
        })
      );
    } else {
      await handleMutation(mutateCareerPage({ variables }));
    }
    await handleMutation(
      mutateCareerPageAnswers({
        variables: {
          careerPage: careerPageID,
          responses: answersState.map(({ answer }) => ({
            option: answer.optionId,
            question: answer.questionId,
            response: answer.value === FOHOther ? answer.freeText : answer.value
          }))
        }
      })
    );

    const clearImagePayload = [
      {
        category: 'career_page_main',
        [FileFields.FileName]: 'null',
        [FileFields.FileType]: 'null',
        id: mainImageArray[0]?.id
      }
    ];

    const addImagePayload = [
      {
        category: 'career_page_main',
        [FileFields.FileName]: mainImageState?.[FileFields.FileName],
        [FileFields.FileType]: mainImageState?.[FileFields.FileType]
      }
    ];

    const updateImagePayload = [
      {
        category: 'career_page_main',
        [FileFields.FileName]: 'null',
        [FileFields.FileType]: 'null',
        id: mainImageArray[0]?.id
      },
      {
        category: 'career_page_main',
        [FileFields.FileName]: mainImageState?.[FileFields.FileName],
        [FileFields.FileType]: mainImageState?.[FileFields.FileType]
      }
    ];

    // ensures not to upload empty image and not changed image
    if (mainImageState?.isEmpty !== true && !mainImageState?.id)
      await handleMutation(
        mutateCareerPageGalleriesMainImage({
          variables: {
            careerPage: careerPageID,
            ...(mainImageState === null && { galleries: clearImagePayload }),
            ...(!mainImageArray[0]?.uploadFile &&
              mainImageState && { galleries: addImagePayload }),
            ...(mainImageArray[0]?.uploadFile &&
              mainImageState && { galleries: updateImagePayload })
          }
        })
      );

    const { itemsToAdd, itemsToDelete } = adaptMainImageMutationPayload(
      galleryItemsState,
      initialGalleries
    );

    await handleMutation(
      mutateCareerPageGalleries({
        variables: {
          careerPage: careerPageID,
          galleries: itemsToAdd.concat(itemsToDelete)
        }
      })
    );
  };

  const handleMainImageChange = event => {
    if (event.target.files) {
      const { files } = event.target;
      const [file] = files;

      setMainImageState({
        file,
        [FileFields.FileName]: file.name,
        [FileFields.FileType]: file.type,
        size: formatFileSize(file.size),
        uploadFile: URL.createObjectURL(file)
      });
    }
  };

  useEffect(
    () =>
      setFormError(
        descriptionState?.length >= MAX_DESCRIPTION_LENGTH
          ? `Employer summary must be less than ${MAX_DESCRIPTION_LENGTH} characters`
          : null
      ),
    [descriptionState]
  );

  useEffect(() => {
    setSelectedLocations(selectedLocations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocations]);

  const isBannerVisible = !!banner?.bannerMessage;
  const springProps = useSpring({
    config: { duration: 200 },
    from: { top: -TOP_NAV_HEIGHT },
    to: { top: isBannerVisible ? 0 : -TOP_NAV_HEIGHT }
  });

  if (isMobile)
    return (
      <FOHCareerPageMobileForm
        href={`/restaurant/${slug}`}
        image={MOBILE_FORM_PLACEHOLDER}
        translations={{
          button: t('mobileFormPageButton'),
          header: t('mobileFormPageHeading'),
          message: t('mobileFormPageMessage')
        }}
      />
    );

  const wasCoverImageReset = coverAssetState?.image === null;
  const wasCoverColorReset = coverAssetState?.color === null;
  const wasMainImageSet =
    mainImageState !== null && mainImageState?.isEmpty !== true;

  const isColorMode =
    (coverAssetState?.mode === 'color' || !!coverColor) && !wasCoverColorReset;
  const isImageMode =
    (coverAssetState?.mode === 'image' || !!cover) && !wasCoverImageReset;
  const isBlankMode = !isColorMode && !isImageMode;
  const isMainImgVideo =
    mainImageState?.getUploadFileExtension === '.mp4' ||
    mainImageState?.[FileFields.FileType] === 'video/mp4'; // check saved and updated field

  return (
    <FOHView>
      <FOHCareerPageLayout
        aside={
          <>
            <FOHSectionLabel style={{ fontSize: 18 }}>
              {t('mainImageHeader')}
            </FOHSectionLabel>
            <FOHSmallDetailLabel>
              {t('mainImageDescription')}
            </FOHSmallDetailLabel>

            <FOHCareerPageMainImage
              isVideo={isMainImgVideo}
              src={mainImageState?.uploadFile || null}
              style={{ marginTop: SPACING_SCALE.huge }}
            />

            <FOHPhotoInputButton
              color={FOHColors.GRAYSCALE_700}
              handleChange={handleMainImageChange}
              icon={
                wasMainImageSet
                  ? () => (
                      <FOHEditMarker
                        style={{
                          height: 18,
                          tintColor: FOHColors.WHITE,
                          width: 18
                        }}
                      />
                    )
                  : () => (
                      <FOHBlueAdd
                        style={{
                          height: 18,
                          tintColor: FOHColors.WHITE,
                          width: 18
                        }}
                      />
                    )
              }
              opaque={true}
              ref={mainImageInputRef}
              selected={true}
              style={{ marginTop: SPACING_SCALE.larger }}
              textColor={FOHColors.WHITE}
              title={
                wasMainImageSet ? t('mainImageChange') : t('mainImageUpload')
              }
            />

            {wasMainImageSet && (
              <FOHInverseButton
                enableUnderline
                icon={FOHTrashCanBlk}
                onPress={() => setMainImageState(null)}
                style={{ marginTop: SPACING_SCALE.large }}
                title={t('removeImage')}
              />
            )}

            <FOHView
              style={{ marginTop: SPACING_SCALE.huge }}
              testID="CareerPAge-gallery-upload"
            >
              <FOHSectionLabel style={{ fontSize: 18 }}>
                {t('formGalleryHeader')}
              </FOHSectionLabel>
              <FOHSmallDetailLabel
                style={{ marginBottom: SPACING_SCALE.larger }}
              >
                {t('formGalleryDescription')}
              </FOHSmallDetailLabel>

              <FOHCareerPageGalleryItemsInput
                galleryItems={galleryItemsState}
                handleInvalidFileType={showInvalidFileTypeBanner}
                mediaInput={props =>
                  isUploadingGalleries ? (
                    <FOHCircleLoader />
                  ) : (
                    <FOHPhotoInputButton
                      {...props}
                      icon={() => (
                        <FOHBlueAdd
                          style={{
                            height: 18,
                            tintColor: FOHColors.WHITE,
                            width: 18
                          }}
                        />
                      )}
                      multiple
                      ref={galleryInputRef}
                    />
                  )
                }
                setGalleryItems={setGalleryItemsState}
                setIsSubmitDisabled={setIsSubmitDisabled}
                translations={{
                  browse: t('browse'),
                  dropFilesHere: t('dropFilesHere'),
                  supportedFileFormatsAndSize: t('supportedFileFormatsAndSize'),
                  uploadPhotoOrVideo: t('uploadPhotoOrVideo')
                }}
              />
            </FOHView>
          </>
        }
        background={
          <>
            {isBannerVisible && (
              <FOHView
                style={{
                  position: Platform.OS === 'web' ? 'fixed' : 'absolute',
                  top: 0,
                  width: '100%',
                  zIndex: 200
                }}
              >
                <AnimatedBanner
                  bannerType={banner.bannerType}
                  message={banner.bannerMessage}
                  style={springProps}
                />
              </FOHView>
            )}

            <CareerPageScreenBackground
              // color/image is either set or saved
              // Default cover should be shown for blank and reset states
              color={
                isColorMode ? `#${coverAssetState?.color || coverColor}` : null
              }
              image={
                isBlankMode
                  ? DEFAULT_COVER
                  : isImageMode
                  ? wasCoverImageReset
                    ? DEFAULT_COVER
                    : coverAssetState?.image?.url || cover?.url
                  : null
              }
            />

            <FOHEditCoverPhotoButton
              blobToFile={blobToFile}
              // pass either modified or saved data
              coverAsset={{
                color: coverAssetState?.color || coverColor,
                image: coverAssetState?.image || cover
              }}
              loadMore={loadMore}
              mediaInput={props => (
                <FOHPhotoInputButton
                  ref={coverImageInputRef}
                  {...props}
                  icon={() => (
                    <FOHEditMarker
                      style={{
                        height: 18,
                        tintColor: FOHColors.WHITE,
                        width: 18
                      }}
                    />
                  )}
                />
              )}
              setCoverAsset={setCoverAssetState}
              stockPhotos={defaultStockPhotos}
              translations={{
                changePhotoButton: t('changePhotoButton'),
                dimensionLabel: t('dimensionLabel'),
                editCoverPhotoButton: t('editCoverPhotoButton'),
                loadMoreButton: t('loadMoreButton'),
                mainHeader: t('mainHeader'),
                pickColorHeader: t('pickColorHeader'),
                pickStockPhotoHeader: t('pickStockPhotoHeader'),
                setCustomColorHeader: t('setCustomColorHeader'),
                uploadPhotoButton: t('uploadPhotoButton')
              }}
            />
          </>
        }
        isEmployer={true}
        isMobile={isMobile}
      >
        <FOHView style={{ paddingRight: !isMobile && '66px' }}>
          <FOHSectionLabel style={{ fontSize: 18 }}>
            {t('editFormHeader')}
          </FOHSectionLabel>
          <FOHSmallDetailLabel>{t('editFormSubHeader')}</FOHSmallDetailLabel>

          <FOHCareerPageLocationForm
            defaultValues={defaultValues}
            formId={FORM_ID}
            onSubmit={handleFormSubmit}
            placeholders={placeholders}
          >
            <FOHSearchableSelectDropdown
              fieldLabel={t('locations').toUpperCase()}
              icon={() => <FOHLocationPin />}
              isSelectAllSelected={
                locationOptions.length === locationFilters.length
              }
              multiSelect={true}
              onPressSelectAll={selectAllLocations}
              onSearchChange={setLocationSearch}
              onValueChange={value => {
                multiSelectLocation(value);
              }}
              open={locationOpen}
              options={locationOptions}
              optionsContainerStyle={{ maxWidth: '100%' }}
              searchPlaceholder={t('common:search')}
              searchValue={locationSearch}
              selectAllLabel={t('selectAllLocations')}
              selectedLabel={
                !prop(0, locationFilters)
                  ? 'No location selected'
                  : length(locationFilters) === 1
                  ? undefined
                  : `${length(locationFilters)} Locations`
              }
              selectedValue={locationFilters}
              setOpen={open => {
                setLocationOpen(open);
              }}
              showLabel={t('Show')}
              style={{ width: '100%' }}
              testID="candidate-feed-location-filter"
            />
          </FOHCareerPageLocationForm>

          <JobDescriptionEditor
            description={descriptionState}
            error={formError}
            header={
              <FOHSectionLabel
                style={{
                  fontSize: 18,
                  height: 40,
                  lineHeight: 44,
                  marginLeft: 16
                }}
              >
                {t('employerSummaryEditorLabel')}
              </FOHSectionLabel>
            }
            maxLength={MAX_DESCRIPTION_LENGTH}
            setDescription={setDescriptionState}
          />

          <FormColorBrandingSection
            activeFontFamily={activeFontFamily}
            color={brandColorState}
            fontColor={fontColor}
            pageColor={brandPageColorState}
            setColor={setBrandColorState}
            setFont={setFont}
            setFontColor={setFontColor}
            setPageColor={setBrandPageColorState}
            setTableColor={setBrandTableColorState}
            tableColor={brandTableColorState}
          />

          <FOHCareerPageQuestionnaire
            answers={answersState}
            setAnswers={setAnswersState}
            translations={{
              heading: t('questionnaireHeading'),
              message: t('questionnaireMessage')
            }}
          />
        </FOHView>
      </FOHCareerPageLayout>

      {/* <FormFooter FORM_ID={FORM_ID} t={t} /> */}

      <DeleteConfirmationModal
        careerPageId={careerPageID}
        careerPageTitle={title}
        close={() => setIsDeleteConfirmationModalOpen(false)}
        employerProfileId={employerProfileId}
        open={isDeleteConfirmationModalOpen}
      />

      <FormActionBar
        handleOpenDeleteConfirmationModal={() =>
          setIsDeleteConfirmationModalOpen(true)
        }
        isDeleteDisabled={!careerPageID}
        isSubmitDisabled={isSubmitDisabled}
        slug={slug}
      />
    </FOHView>
  );
};
