import React from 'react';

import { useParams } from 'react-router-dom';

import { CandidateDiscover } from './CandidateDiscover';
import { CandidateFollowing } from './CandidateFollowing';
import { CandidateInterviews } from './CandidateInterviews';
import { CandidateApplications } from './CandidateApplications';
import { CandidateInterviewRequests } from './CandidateInterviewRequests';

export const candidateHomeRouteConfig = [
  { route: 'discover', Component: CandidateDiscover },
  { route: 'applications', Component: CandidateApplications },
  { route: 'requests', Component: CandidateInterviewRequests },
  { route: 'interviews', Component: CandidateInterviews },
  { route: 'following', Component: CandidateFollowing }
];

export const CandidateHomeRoutes = props => {
  const params = useParams();

  if (params.tab) {
    const route = candidateHomeRouteConfig.find(
      tab => tab.route === params.tab
    );

    return <route.Component {...props} />;
  }

  return <CandidateDiscover {...props} />;
};
