import { defaultTo, prop } from 'ramda';
import { QueryHookOptions } from '@apollo/client';

import {
  useGetMeQuery,
  GetMeQuery,
  GetMeQueryVariables
} from '../../graphql/generated';

interface UseGetMeDataOptions
  extends QueryHookOptions<GetMeQuery, GetMeQueryVariables> {}

export const useGetMeData = (
  options?: UseGetMeDataOptions
): {
  data: GetMeQuery['me'] | undefined;
  loading: boolean;
  error?: Error;
  refetch: () => void;
} => {
  const { data, loading, error, refetch } = useGetMeQuery(options);

  const me = defaultTo(undefined, prop('me', data)) as GetMeQuery['me'];

  return { data: me, loading, error, refetch };
};
