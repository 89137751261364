import React from 'react';

import styled from 'styled-components/native';

import { FOHBackIcon, FOHTouchableOpacity, FOHView } from '../../ingredients';

export const FOHGoBack = (props: any) => {
  return (
    <ContainerView
      style={{
        position: props.fixed ? 'fixed' : 'absolute',
        ...props.style
      }}
    >
      <FOHTouchableOpacity onPress={props.goBack}>
        <FOHBackIcon />
      </FOHTouchableOpacity>
    </ContainerView>
  );
};

const ContainerView = styled(FOHView)`
  position: absolute;
  left: 6px;
  top: 65px;
  z-index: 10;
`;
