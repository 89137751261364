import React from 'react';

import required from '../../../images/required.svg';

import { FOHImage } from '../../ingredients';

export const FOHRequiredMark = (props?: any) => (
  <FOHImage
    source={{ uri: required }}
    style={{ width: 7, height: 7, ...props?.style }}
  />
);
