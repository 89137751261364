import React from 'react';

import { View } from 'react-native';
import styled from 'styled-components/native';
import { path } from 'ramda';

import { FOHHeaderH1, FOHSmallDetailLabel } from '../../ingredients';
import { FOHRadioQuestionFieldProps } from '../../recipes';

import {
  FOHStatusSelectCell,
  FOHStatusSelectProps
} from './FOHStatusSelectCell';

export interface FOHStatusCellProps extends FOHRadioQuestionFieldProps {
  header: string;
  description: string;
  options: Array<FOHStatusSelectProps>;
}

export const FOHStatusCell = (props: FOHStatusCellProps) => {
  return (
    <View
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Header>{props.header}</Header>
      <Description>{props.description}</Description>
      {props.options.map((option: any, i: number, arr: Array<any>) => (
        <FOHStatusSelectCell
          key={i}
          testID={option.value}
          style={i === arr.length - 1 ? { marginBottom: 0 } : {}}
          label={option.label}
          description={option.description}
          value={option.value}
          onPress={() => {
            props.updateAnswer &&
              props.updateAnswer({
                value: option.value
              });
          }}
          selected={option.value === path(['answer', 'value'], props)}
        />
      ))}
    </View>
  );
};

const Header = styled(FOHHeaderH1)`
  margin-bottom: 8px;
`;

const Description = styled(FOHSmallDetailLabel)`
  text-align: center;
  margin-bottom: 8px;
  line-height: 24px;
  max-width: 442px;
`;
