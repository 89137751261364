import React from 'react';

import styled from 'styled-components/native';
import { compose } from 'ramda';

import {
  FOHErrorLabel,
  FOHCompensationIcon,
  FOHSpaceSmallest,
  FOHView,
  withPlaceholderText,
  FOHFonts,
  FOHColors
} from '../../ingredients';
import { FOH_INPUT_HEIGHT } from '../../constants';

import { FOHMaskedTextInputProps } from './FOHMaskedTextInput';

//eslint-disable-next-line
const mask = require('react-native-mask-text/lib/commonjs/index.js');

export const FOHCurrencyInput = (props: FOHMaskedTextInputProps) => (
  <>
    <Container error={props.error}>
      <FOHView>
        <FOHCompensationIcon
          style={{
            width: 18,
            height: 18,
            tintColor: FOHColors.BLUE
          }}
        />
      </FOHView>
      <FOHSpaceSmallest />
      <CurrencyInput
        {...props}
        //@ts-expect-error type issue
        type="currency"
        testID="currency-input"
        options={{
          prefix: '$',
          decimalSeparator: '.',
          groupSeparator: ',',
          precision: 2
        }}
        keyboardType="numeric"
        placeholder={props.placeholder}
      />
    </Container>
    {props.error ? <FOHErrorLabel>{props.error}</FOHErrorLabel> : <></>}
  </>
);

const Input = styled(mask.MaskedTextInput)`
  background: #ffffff;
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  display: flex;
  align-items: center;
  width: 90%;
  height: 46px;
  flex-direction: row;
  padding: 5px 10px;
  outline-color: transparent;
`;

const CurrencyInput = compose(withPlaceholderText)(Input);

const Container = styled(FOHView)`
  background: #ffffff;
  border: ${(props: any) =>
    props.error ? `1px solid ${FOHColors.RED}` : '1px solid #dbdbdb'};
  border-radius: 4px;
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 200%;
  align-items: center;
  height: ${FOH_INPUT_HEIGHT}px;
  flex-direction: row;
  padding: 8px;
  width: 100%;
`;
