import React, { useRef } from 'react';

import styled from 'styled-components/native';
import {
  View,
  Text,
  TouchableOpacity,
  StyleProp,
  ViewStyle,
  TextStyle,
  GestureResponderEvent
} from 'react-native';
import { path } from 'ramda';
import { useHover } from 'react-native-web-hooks';

import { FOH_INPUT_HEIGHT } from '../../constants';

import { FOHColors } from '../colors/FOHColors';
import { FOHFonts } from '../text/FOHFonts';
import { FOHToolTip, FOHToolTipType } from '../tooltips/FOHToolTip';

export const ButtonText = styled(Text)`
  font-family: ${FOHFonts.MEDIUM};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`;

const ContrastButtonText = styled(ButtonText)`
  color: ${FOHColors.WHITE};
`;

const StyledButton = styled(View)`
  align-items: center;
  border-radius: 4px;
  flex-direction: row;
  justify-content: center;
  height: ${FOH_INPUT_HEIGHT}px;
`;

export interface FOHButtonProps {
  borderColor?: string;
  color?: string;
  disabled?: any;
  enableHover?: boolean;
  fullWidth?: boolean;
  helpText?: string;
  onPress: (e: GestureResponderEvent) => void;
  style?: StyleProp<ViewStyle>;
  testID?: string;
  icon?: React.ElementType;
  textColor?: string;
  title: string;
  toolTipType?: FOHToolTipType;
  textStyle?: StyleProp<TextStyle>;
  buttonStyle?: StyleProp<ViewStyle>;
}

export const FOHButton = (props: FOHButtonProps) => {
  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);

  const Icon = props?.icon;

  return (
    <TouchableOpacity
      disabled={props.disabled}
      testID={props.testID || `button-${props.title}`}
      onPress={props.onPress}
      style={[
        {
          width: '100%',
          maxWidth: props.fullWidth ? '100%' : 325
        },
        props.style
      ]}
    >
      <StyledButton
        ref={hoverRef}
        style={{
          backgroundColor: props.disabled
            ? FOHColors.GRAYSCALE_100
            : isHovered
            ? props.color || FOHColors.PRIMARY_TEAL_300
            : props.color || FOHColors.PRIMARY_TEAL_P1,
          height: path(['style', 'height'], props) || FOH_INPUT_HEIGHT,
          padding: 8,
          borderWidth: 1,
          borderColor:
            props.borderColor ?? props.disabled
              ? props.borderColor || FOHColors.GRAYSCALE_100
              : props.color || FOHColors.PRIMARY_TEAL_400
        }}
      >
        {props.enableHover ? (
          <FOHToolTip
            helpText={props.helpText}
            open={isHovered}
            type={props.toolTipType}
            style={
              props.toolTipType === FOHToolTipType.LEFT
                ? { width: 200, right: 60, top: 10 }
                : { width: 200 }
            }
          />
        ) : (
          <></>
        )}
        {Icon ? <Icon /> : <></>}
        <ContrastButtonText
          style={[
            {
              color: props.disabled
                ? FOHColors.GRAYSCALE_400
                : props.textColor || FOHColors.WHITE,
              paddingLeft: Icon ? 6 : 0
            },
            props?.textStyle
          ]}
        >
          {props.title}
        </ContrastButtonText>
      </StyledButton>
    </TouchableOpacity>
  );
};
