import React from 'react';

import success from '../../../images/circle_success.svg';

import { FOHImage } from '../../ingredients';

export const FOHCircleSuccessIcon = (props: any) => (
  <FOHImage
    source={{ uri: success }}
    style={
      props && !!props.style
        ? { width: 25, height: 25, ...props.style }
        : { width: 16, height: 16 }
    }
  />
);
