import React from 'react';

import { View, Image } from 'react-native';

import { FOHDetailText, FOHHeaderH2, FOHColors } from '../../ingredients';

export interface FOHScheduleFormTitleBlockProps {
  titleLabel: string;
  candidateName: string;
  detailString: string;
  decorationImage: string;
}

export const FOHScheduleFormTitleBlock = (
  props: FOHScheduleFormTitleBlockProps
) => (
  <View style={{ flexDirection: 'row', width: '100%' }}>
    <View style={{ paddingRight: 28 }}>
      <Image source={{ uri: props.decorationImage, height: 96, width: 100 }} />
    </View>
    <View style={{ flex: 1 }}>
      <View style={{ flexDirection: 'row', marginBottom: 8 }}>
        <FOHHeaderH2
          numberOfLines={1}
          style={{
            alignItems: 'flex-start',
            textAlign: 'left',
            fontWeight: 400
          }}
        >
          {props.titleLabel}
        </FOHHeaderH2>
        {props.candidateName && (
          <FOHHeaderH2
            numberOfLines={1}
            style={{
              color: FOHColors.GRAYSCALE_500,
              alignItems: 'flex-start',
              textAlign: 'left',
              paddingLeft: 8,
              fontWeight: 400,
              maxWidth: 218
            }}
          >
            · {props.candidateName} ·
          </FOHHeaderH2>
        )}
      </View>
      <FOHDetailText
        style={{
          maxWidth: 522,
          alignItems: 'flex-start',
          textAlign: 'left',
          color: 'rgba(102, 112, 133, 0.8)',
          lineHeight: 21
        }}
      >
        {props.detailString}
      </FOHDetailText>
    </View>
  </View>
);
