import React from 'react';

import styled from 'styled-components/native';

import { FOHView } from '../../ingredients';

export interface FOHCustomChatBubbleProps {
  children?: any;
  leftSpeach?: boolean;
  backgroundColor?: string;
}

export const FOHCustomChatBubble = (props: FOHCustomChatBubbleProps) =>
  props.leftSpeach ? (
    <LeftBubble style={{ backgroundColor: props.backgroundColor || '#f5f5f5' }}>
      {props.children}
    </LeftBubble>
  ) : (
    <RightBubble
      style={{ backgroundColor: props.backgroundColor || '#4f4f4f' }}
    >
      {props.children}
    </RightBubble>
  );

const LeftBubble = styled(FOHView)`
  padding: 18px;
  border-radius: 24px;
  border-bottom-left-radius: 0px;
`;

const RightBubble = styled(FOHView)`
  padding: 18px;
  border-radius: 24px;
  border-bottom-right-radius: 0px;
`;
