import React, { useEffect, useMemo, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { defaultTo, isEmpty, length, path, prop } from 'ramda';
import { DateTime, Settings } from 'luxon';
import {
  Calendar,
  Views,
  DateLocalizer,
  luxonLocalizer
} from 'react-big-calendar';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import { to24HoursTime } from '../../utils/toDateTime';
import { FEED, UPCOMING_INTERVIEWS_SCHEDULE, UPGRADE } from '../../constants';
import {
  FOHColors,
  FOHSpace,
  FOHSearchableSelectDropdown,
  FOHView,
  FOHBlueCalendarScheduledIcon,
  FOHLocationPin,
  FOHToolBoxIcon,
  FOHThumbDownIcon,
  FOHHeaderH2,
  FOHHiredIcon
} from '../../components';
import {
  AgendaEventsDocument,
  useAgendaEventsQuery,
  useGetInterviewDetailLazyQuery,
  useMutateBulkMoveEmployerCandidateMutation,
  useMutateInterviewMutation,
  useRejectCandidatesMutation
} from '../../graphql/generated';
import { useNavigation } from '../../utils/navigation';

import { useCandidateFeedContext } from '../CandidateFeed/useCandidateFeedContext';
import { useCurrentLocationContext } from '../Locations/useCurrentLocationContext';
import { SetInterviewModal } from '../EmployerInterviewSchedule/SetInterviewModal';
import { SendScheduleOrScheduleModal } from '../EmployerInterviewSchedule/SendScheduleOrSchedule';
import { ShareScheduleModals } from '../EmployerInterviewSchedule/ShareScheduleFormModal';
import { useStages } from '../CandidateFeed/useStages';

import './styles.css';
import ViewEventModal from './ViewEventModal';

const mLocalizer = luxonLocalizer(DateTime, { firstDayOfWeek: 7 });

export const UpcomingAgenda = ({ localizer = mLocalizer, ...props }) => {
  const { modal } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [calendarDate, setCalendarDate] = useState(DateTime.local());

  const interviewId = searchParams.get('interviewId');

  const { navigateTo, goBack } = useNavigation();

  const { t } = useTranslation('UpcomingInterviews');
  const eventRef = useRef([]);
  const defaultTZ = calendarDate.zoneName;
  const [currentEvent, setCurrentEvent] = useState(null);
  const [openDetailsPopup, setOpenDetailsPopup] = useState(false);
  const [locationOpen, setLocationOpen] = useState(false);
  const [positionOpen, setPositionOpen] = useState(false);

  const {
    myPositions,
    myLocations,
    positionFilters,
    locationFilters,
    multiSelectLocation,
    multiSelectPosition,
    setPositionSearch,
    setLocationSearch,
    positionSearch,
    locationSearch,
    selectAllLocations,
    selectAllPositions,
    addLocationAndPositionFilters
  } = useCandidateFeedContext();

  const { selectLocationFilter } = useCurrentLocationContext();
  const [
    getInterviewDetails,
    { loading: loadingInterview, data: interviewData }
  ] = useGetInterviewDetailLazyQuery();

  const employerCandidate = defaultTo(
    undefined,
    path(['employerCandidate'], currentEvent)
  );

  const locationOptions = myLocations.map(loc => ({
    label: prop('displayName', loc),
    value: prop('id', loc),
    sublabel: prop('formattedAddress', loc)
  }));

  const positionOptions = myPositions.map(pos => ({
    label: prop('positionName', pos),
    value: prop('id', pos),
    sublabel: '',
    // prompt for upgrade label
    upgrade:
      path(['paymentStatus'], pos) !== 'SUBSCRIBED' &&
      path(['location', 'paymentStatus'], pos) !== 'SUBSCRIBED',
    locationIdToUpgrade: path(['location', 'id'], pos)
  }));

  const { formats } = useMemo(
    () => ({
      formats: {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        timeGutterFormat: (date, culture, localizer) =>
          localizer.format(date, 'hh:mm a', culture)
      },
      views: Object.keys(Views).map(k => Views[k])
    }),
    []
  );

  const weekStart = calendarDate.startOf('week').minus({ days: 1 });
  const weekEnd = calendarDate.endOf('week').minus({ days: 1 });

  const scrollToTime = new Date();
  if (scrollToTime.getHours() >= 2) {
    scrollToTime.setHours(scrollToTime.getHours() - 2);
  } else {
    scrollToTime.setHours(0);
  }
  scrollToTime.setMinutes(0);
  scrollToTime.setSeconds(0);

  const variables = {
    startTime: weekStart.toISO(),
    endTime: weekEnd.toISO(),
    locations: locationFilters,
    positions: positionFilters
  };

  const agendaQuery = useAgendaEventsQuery({
    pollInterval: 60000, // poll every minute
    skip: locationFilters.length === 0 || positionFilters.length === 0,
    variables
  });

  const eventsBE = !agendaQuery.error
    ? defaultTo([], path(['data', 'agendaEvents'], agendaQuery))
    : [];
  const events = useMemo(
    () =>
      eventsBE.map(event => ({
        ...event,
        start: new Date(event.start),
        end: new Date(event.end)
      })),
    [eventsBE]
  );

  const { hiredStage } = useStages({});
  const [moveEmployerCandidatesMutation] =
    useMutateBulkMoveEmployerCandidateMutation();
  const [rejectCandidate] = useRejectCandidatesMutation();
  const [interviewMutation] = useMutateInterviewMutation();

  const refetchQueries = [
    {
      query: AgendaEventsDocument,
      variables: {
        startTime: weekStart.toISO(),
        endTime: weekEnd.toISO(),
        locations: locationFilters,
        positions: positionFilters
      }
    }
  ];
  const handleSelectEvent = event => {
    if (event.interviewUuid !== interviewId) {
      setSearchParams({ interviewId: event.interviewUuid });
    }
    const agendaEvent = events.find(
      item => item.interviewUuid === event.interviewUuid
    );

    setCurrentEvent(agendaEvent);
    setOpenDetailsPopup(true);
  };

  useEffect(() => {
    if (!isEmpty(positionFilters) && !isEmpty(locationFilters)) {
      agendaQuery.refetch();
    }
    return () => {
      try {
        Settings.defaultTimeZone = defaultTZ;
      } catch (e) {
        console.log(e);
      }
    };
  }, [defaultTZ]); //eslint-disable-line react-hooks/exhaustive-deps

  //Filter the Events by location and position
  useEffect(() => {
    agendaQuery.refetch();
  }, [locationFilters.length, positionFilters.length]); //eslint-disable-line react-hooks/exhaustive-deps

  // Creating array of refs to get position of respective event for popup positioning
  useEffect(() => {
    eventRef.current = eventRef?.current?.slice(0, events?.length);
  }, [events]);

  useEffect(() => {
    if (interviewId && !agendaQuery.loading && !currentEvent) {
      const agendaEvent = events?.find(
        event => event?.interviewUuid === interviewId
      );
      if (agendaEvent) {
        const agendaEventRef = eventRef?.current?.find(
          item => item?.interviewId === interviewId
        )?.el;

        if (agendaEventRef && agendaEventRef.getBoundingClientRect) {
          agendaEventRef.scrollIntoView();

          setCurrentEvent(agendaEvent);
          setTimeout(() => {
            setOpenDetailsPopup(true);
          }, 500);
        }
      } else {
        getInterviewDetails({ variables: { uuid: interviewId } });
      }
    }
  }, [interviewId, currentEvent, agendaQuery.loading]);

  useEffect(() => {
    if (!loadingInterview && interviewData) {
      const interview = interviewData.interview;
      const interviewTime = DateTime.fromJSDate(
        new Date(interview.scheduledFor)
      );

      const isBetween = interviewTime >= weekStart && interviewTime <= weekEnd;

      if (!isBetween) {
        setCalendarDate(interviewTime.toLocal());
      }
      addLocationAndPositionFilters(
        interview.location.id,
        interview.position.id
      );
    }
  }, [loadingInterview]);

  // Custom Event Component
  const EventComponent = event => {
    const start = to24HoursTime(event?.event?.start?.toISOString());
    const end = to24HoursTime(event?.event?.end?.toISOString());
    const duration =
      start && end
        ? (event?.event?.end - event?.event?.start) / (1000 * 60)
        : 0;

    return (
      <div
        className={`event ${duration < 60 ? 'short' : ''}`}
        ref={el =>
          el
            ? (eventRef.current[
                events?.indexOf(event?.event) === -1
                  ? 0
                  : events?.indexOf(event?.event)
              ] = {
                el,
                interviewId: event?.event?.interviewUuid
              })
            : null
        }
        data-testid={`calendar-event-${event.title}`}
      >
        {duration >= 60 && (
          <p className="rbc-event-timestamp">
            {start} - {end}
          </p>
        )}
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <h5>{event?.title}</h5>
          {event?.event?.employerCandidate?.stage?.name.toLowerCase() ===
          'rejected' ? (
            <FOHThumbDownIcon
              style={{
                tintColor: FOHColors.GRAYSCALE_500,
                width: 18,
                height: 18,
                marginLeft: 15
              }}
            />
          ) : event?.event?.employerCandidate?.stage?.name.toLowerCase() ===
            'hired' ? (
            <FOHHiredIcon
              style={{
                tintColor: FOHColors.GRAYSCALE_500,
                width: 21,
                height: 18,
                marginLeft: 15
              }}
            />
          ) : (
            <></>
          )}
        </div>
        {duration >= 90 && (
          <p className="rbc-event-desc">{event?.event?.desc}</p>
        )}
      </div>
    );
  };

  return (
    <FOHView style={{ paddingVertical: 20, height: '100vh' }}>
      <FOHView
        style={{
          textAlign: 'left',
          paddingLeft: 25,
          paddingTop: 5,
          paddingBottom: 16,
          flexDirection: 'row',
          justifyContent: 'space-between',
          zIndex: 1
        }}
      >
        <FOHView
          style={{
            flexDirection: 'row',
            alignItems: 'baseline'
          }}
        >
          <FOHView style={{ marginRight: 10 }}>
            <FOHBlueCalendarScheduledIcon />
          </FOHView>
          <FOHHeaderH2>{t('interviewCalendarTitle')}</FOHHeaderH2>
        </FOHView>
        <FOHView
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-end',
            zIndex: 1
          }}
        >
          <FOHSearchableSelectDropdown
            open={locationOpen}
            setOpen={open => {
              setPositionOpen(false);
              setLocationOpen(open);
            }}
            selectedValue={locationFilters}
            selectedLabel={
              !prop(0, locationFilters)
                ? 'No location selected'
                : length(locationFilters) === 1
                ? undefined
                : `${length(locationFilters)} Locations`
            }
            showLabel={t('Show')}
            onSearchChange={setLocationSearch}
            searchValue={locationSearch}
            multiSelect={true}
            searchPlaceholder={t('common:search')}
            onValueChange={value => {
              multiSelectLocation(value);
            }}
            options={locationOptions}
            fieldLabel={t('locations').toUpperCase()}
            icon={() => <FOHLocationPin />}
            testID="candidate-feed-location-filter"
            selectAllLabel={t('CandidateFeed:selectAllLocations')}
            isSelectAllSelected={
              locationOptions.length === locationFilters.length
            }
            onPressSelectAll={selectAllLocations}
          />
          <FOHSpace />
          <FOHSearchableSelectDropdown
            open={positionOpen}
            setOpen={open => {
              setPositionOpen(open);
              setLocationOpen(false);
            }}
            selectedValue={positionFilters || []}
            multiSelect={true}
            selectedLabel={
              !prop(0, positionFilters)
                ? 'No position selected'
                : length(positionFilters) === 1
                ? undefined
                : `${length(positionFilters)} Positions`
            }
            showLabel={t('Show')}
            onSearchChange={setPositionSearch}
            searchValue={positionSearch}
            searchPlaceholder={t('common:search')}
            onValueChange={value => {
              multiSelectPosition(value);
            }}
            options={positionOptions}
            fieldLabel={t('positions').toUpperCase()}
            upgradeLabel={t('upgrade')}
            onPressUpgrade={({ locationIdToUpgrade }) => {
              selectLocationFilter(locationIdToUpgrade);
              navigateTo(`${FEED}${UPGRADE}`);
            }}
            icon={() => (
              <FOHToolBoxIcon
                style={{
                  width: 14,
                  height: 14,
                  tintColor: FOHColors.PACIFIC_BLUE
                }}
              />
            )}
            testID="candidate-feed-position-filter"
            optionsContainerStyle={{
              left: -107
            }}
            selectAllLabel={t('CandidateFeed:selectAllPositions')}
            isSelectAllSelected={
              positionOptions.filter(position => !position.upgrade).length ===
              positionFilters.length
            }
            onPressSelectAll={selectAllPositions}
          />
          <FOHSpace />
        </FOHView>
      </FOHView>
      <FOHView
        className="height600"
        {...props}
        style={{ flex: 'auto', overflow: 'scroll' }}
      >
        <Calendar
          date={calendarDate.toJSDate()}
          onNavigate={date => {
            setOpenDetailsPopup(false);
            setCurrentEvent(null);
            setSearchParams({});
            setCalendarDate(DateTime.fromJSDate(date));
          }}
          defaultView={Views.WEEK}
          events={events}
          localizer={localizer}
          formats={formats}
          showMultiDayTimes
          step={30}
          views={['week']}
          selectable={true}
          onSelectEvent={handleSelectEvent}
          scrollToTime={scrollToTime}
          components={{
            event: EventComponent
          }}
        />
      </FOHView>
      {openDetailsPopup && currentEvent ? (
        <ViewEventModal
          {...props}
          open={openDetailsPopup}
          event={currentEvent}
          position={eventRef?.current
            ?.find(item => item?.interviewId === currentEvent?.interviewUuid)
            ?.el?.getBoundingClientRect()}
          close={() => {
            setCurrentEvent(null);
            setSearchParams({});
            setOpenDetailsPopup(false);
          }}
          agendaQuery={agendaQuery}
          moveEmployerCandidatesMutation={moveEmployerCandidatesMutation}
          hiredStageId={hiredStage?.id}
          rejectCandidateMutation={rejectCandidate}
          interviewMutation={interviewMutation}
          refetchQueries={refetchQueries}
        />
      ) : null}
      {modal === 'send-or-set' ? (
        <SendScheduleOrScheduleModal
          close={() => {
            agendaQuery.refetch();
            goBack();
          }}
          key={`${currentEvent?.employerCandidate.candidateProfile.handle}-send-or-set`}
          open={modal === 'send-or-set'}
          handle={currentEvent?.employerCandidate.candidateProfile.handle}
          employerCandidate={employerCandidate}
          pathname={UPCOMING_INTERVIEWS_SCHEDULE}
        />
      ) : null}
      {modal === 'send-schedule' ? (
        <ShareScheduleModals
          key={`${currentEvent?.employerCandidate.candidateProfile.handle}-send`}
          open={modal === 'send-schedule'}
          handle={currentEvent?.employerCandidate.candidateProfile.handle}
          employerCandidate={employerCandidate}
          pathname={UPCOMING_INTERVIEWS_SCHEDULE}
          close={() => {
            agendaQuery.refetch();
            navigateTo(UPCOMING_INTERVIEWS_SCHEDULE);
            setOpenDetailsPopup(false);
          }}
          onPrevPress={() => {
            goBack();
          }}
        />
      ) : null}
      {modal === 'interview' ? (
        <SetInterviewModal
          candidateHandle={
            currentEvent?.employerCandidate.candidateProfile.handle
          }
          key={`${currentEvent?.employerCandidate.candidateProfile.handle}-set`}
          open={modal === 'interview'}
          onPrevPress={() => {
            goBack();
          }}
          employerCandidate={employerCandidate}
          close={() => {
            agendaQuery.refetch();
            navigateTo(UPCOMING_INTERVIEWS_SCHEDULE);
            setOpenDetailsPopup(false);
          }}
          pathname={UPCOMING_INTERVIEWS_SCHEDULE}
          edit={true}
        />
      ) : null}
    </FOHView>
  );
};
UpcomingAgenda.propTypes = {
  localizer: PropTypes.instanceOf(DateLocalizer)
};
