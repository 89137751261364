import React, { useState, useEffect, useRef, useCallback } from 'react';

import { includes, path, defaultTo, prop, compose, length } from 'ramda';
import { withTranslation } from 'react-i18next';

import {
  FOHFixedHeightScrollView,
  FOHView,
  FOHButton,
  FOHSpace,
  FileType,
  FOHEmploymentCard,
  FOHFormFlowButtons,
  FOHWorkHistoryOrUploadResumeScreen
} from '../../components';
import {
  CANDIDATE_WORK_HISTORY_PATH,
  CANDIDATE_ACCOUNT_MENU_PATH,
  CANDIDATE_ADD_EMPLOYMENT_PATH,
  EXTERNAL_APPLICATION_HEIGHTTODECREASE,
  EXTERNAL_APPLICATION_WIDTH,
  YEARS_EXPERIENCE_DROPDOWN
} from '../../constants';
import {
  handle,
  withIsMobile,
  parseFileNameFromS3Url,
  handleMutation
} from '../../utils';
import { getStartEndMonthYear } from '../../api/transformers/candidates';
import { useNavigation } from '../../utils/navigation';

import { usePositionTypes } from '../CandidateProfile/usePositionTypes';

import { useWorkHistoryMutation } from './useWorkHistoryMutation';
import { WorkHistoryModal } from './WorkHistoryScreen';
import { useResumeMutations } from './useResumeMutations';
import { useCandidateProfileMutation } from './useCandidateProfileMutation';

const formatDate = date => (date ? date.substring(0, date.length - 3) : '');

const AddEmploymentScreen = props => {
  const { t } = props;

  const { navigateTo, location: navLocation } = useNavigation();

  const reader = new FileReader();

  const { deleteCandidateWorkHistory, myWorkHistory } =
    useWorkHistoryMutation();

  const { updateCandidate, me } = useCandidateProfileMutation();

  const {
    resumePreview,
    setResumePreview,
    deleteResume,
    fileToUpload,
    setFileToUpload
  } = useResumeMutations();

  const inputFile = useRef(null);

  reader.onload = e => {
    const blob = e.target.result;
    setResumePreview(blob);
  };

  const [workHistory, setWorkHistory] = useState([]);
  const [yearsExp, setYearsExp] = useState('2-5');

  const [whToEdit, setWhToEdit] = useState('');
  const [openEditWorkHistory, setOpenEditWorkHistory] = useState(false);

  const { getStringForPositionType } = usePositionTypes();

  const getDisplayHistory = useCallback(
    (_myWorkHistory = []) =>
      _myWorkHistory.map(history => ({
        ...getStartEndMonthYear(history),
        ...history,
        end: history.present ? t('currentlyEmployed') : formatDate(history.end),
        start: formatDate(history.start),
        current: prop('present', history),
        // Don't translate old positions that were custom from the text field
        position: getStringForPositionType(history.position)
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getStringForPositionType]
  );

  useEffect(() => {
    if (myWorkHistory) {
      const wh = getDisplayHistory(myWorkHistory, t);
      setWorkHistory(wh);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myWorkHistory]);

  // Dirty state based on any information changed
  const [dirty, setDirty] = useState(false);
  useEffect(() => {
    setDirty(workHistory !== getDisplayHistory(myWorkHistory, t));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workHistory]);

  const hasBack =
    navLocation.pathname === CANDIDATE_ADD_EMPLOYMENT_PATH &&
    !!path(['candidateProfile', 'onboardingCompletedAt'], me);

  const handleSubmit = async () => {
    handle(
      updateCandidate({
        variables: {
          yearsExperience: yearsExp
        }
      })
    );

    path(['candidateProfile', 'onboardingCompletedAt'], me)
      ? navigateTo(CANDIDATE_ACCOUNT_MENU_PATH)
      : props.onSubmit && props.onSubmit();
  };

  useEffect(() => {
    !!path(['candidateProfile', 'yearsExperience'], me) &&
      setYearsExp(path(['candidateProfile', 'yearsExperience'], me));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path(['candidateProfile'], me)]);

  return (
    <>
      <FOHView
        style={{
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <FOHView
          style={{
            maxWidth: props.isMobile ? '100%' : EXTERNAL_APPLICATION_WIDTH,
            width: '100%'
          }}
        >
          <FOHFixedHeightScrollView
            isMobile={props.isMobile}
            heightToDecrease={EXTERNAL_APPLICATION_HEIGHTTODECREASE}
          >
            <FOHView
              style={{
                width: '100%',
                maxWidth: props.isMobile ? '100%' : EXTERNAL_APPLICATION_WIDTH
              }}
            >
              <FOHWorkHistoryOrUploadResumeScreen
                headerText={t('tellUsYours')} // Awesome! Tell Us Your
                subHeaderText={t('workDetailLabel')} //  Work History OR Upload a Resume
                highlightTextInSubHeader="OR"
                detailText={t('detailText')} // Employers like to see 2-3 previous jobs.
                errorText={t('completeAtLeastOne')}
                cardHeaderText={t('ProfileFeature:uploadResume')}
                cardFooterText={t('workHistoryManual')} // Fill in Your Work History Manually
                uploadBtnLabel={t('ProfileFeature:uploadResume')}
                fileTypeText={t('ResumeFeature:fileTypeText')}
                fileType={
                  includes('application/pdf', resumePreview) ||
                  includes(
                    '.pdf',
                    defaultTo('', path(['candidateProfile', 'resume'], me))
                  )
                    ? FileType.pdf
                    : FileType.image
                }
                fileName={
                  prop('name', fileToUpload) ||
                  parseFileNameFromS3Url(
                    path(['candidateProfile', 'resume'], me)
                  )
                }
                resume={
                  resumePreview || path(['candidateProfile', 'resume'], me)
                }
                uploading={false}
                Pdf={PDFProps => (
                  <FOHView style={{ ...PDFProps.style }}>
                    <iframe
                      scrolling="no"
                      title="resume-iframe"
                      src={
                        resumePreview ||
                        path(['candidateProfile', 'resume'], me)
                      }
                      style={{
                        width: '100%',
                        border: 'none',
                        overflow: 'hidden',
                        ...defaultTo({}, PDFProps.style)
                      }}
                    />
                  </FOHView>
                )}
                removeResume={async () => {
                  await deleteResume({});
                  setResumePreview('');
                }}
                onClickUploadResume={() => {
                  inputFile && inputFile.current.click();
                }}
                yearsExpPlaceholder={t(
                  'PositionsFeature:experiencePlaceholder'
                )}
                yearsExpChoices={YEARS_EXPERIENCE_DROPDOWN.map(position => ({
                  value: position.value,
                  label: t(position.label)
                }))}
                yearsOfExp={yearsExp}
                setYearsOfExp={value => setYearsExp(value)}
                employmentBtnLabel={t('addEmploymentButton')} // button for add employment
                onAddEmployment={() => {
                  setWhToEdit();
                  props.onboarding
                    ? setOpenEditWorkHistory(true)
                    : navigateTo(CANDIDATE_WORK_HISTORY_PATH);
                }}
              >
                {workHistory &&
                  workHistory.map(history => (
                    <FOHEmploymentCard
                      key={history.id}
                      id={history.id}
                      positionLabel={t('positionLabel')}
                      position={history.position}
                      employerNameLabel={t('employerName')}
                      employerName={history.employerName}
                      employerImg={path(['location', 'image'], history)}
                      employerAddr={path(
                        ['location', 'formattedAddress'],
                        history
                      )}
                      startLabel={t('startDate')}
                      start={history.start}
                      endLabel={t('endDate')}
                      end={history.end}
                      roleLabel={t(
                        'WorkHistoryFeature:responsibilitiesPlaceholder'
                      )}
                      role={history.responsibilities}
                      editLabel={t('editButton')}
                      onEditPress={id => {
                        setWhToEdit(id);
                        props.onboarding
                          ? setOpenEditWorkHistory(true)
                          : navigateTo(
                              `${CANDIDATE_WORK_HISTORY_PATH}/?id=${id}`
                            );
                      }}
                      removeLabel={t('removeButton')}
                      onRemovePress={async id => {
                        const [response, error] = await handleMutation(
                          deleteCandidateWorkHistory({
                            id
                          })
                        );

                        if (error || !response) {
                          return;
                        }
                      }}
                      testID={`FOHEmploymentCard-${history.employerName}`}
                    />
                  ))}
              </FOHWorkHistoryOrUploadResumeScreen>
            </FOHView>
            {props.isModal && (
              <>
                <FOHSpace />
                <FOHButton
                  title={
                    path(['candidateProfile', 'onboardingCompletedAt'], me)
                      ? t('common:save')
                      : t('nextLabel')
                  }
                  disabled={!dirty}
                  onPress={handleSubmit}
                />
              </>
            )}
          </FOHFixedHeightScrollView>
          <FOHSpace />
          <FOHView
            style={{
              width: '100%',
              maxWidth: props.isMobile ? '100%' : EXTERNAL_APPLICATION_WIDTH,
              marginBottom: props.isMobile ? 0 : 10,
              alignItems: 'center'
            }}
          >
            {!props.isModal && (
              <FOHFormFlowButtons
                isMobile={props.isMobile}
                nextButtonlabel={
                  path(['candidateProfile', 'onboardingCompletedAt'], me)
                    ? t('common:save')
                    : t('nextLabel')
                }
                onPressNextButton={handleSubmit}
                isNextDisabled={
                  length(workHistory) === 0 &&
                  !(resumePreview || path(['candidateProfile', 'resume'], me))
                }
                previousButtonLabel={t('common:previous')}
                onPressPreviousButton={
                  hasBack
                    ? () => navigateTo(CANDIDATE_ACCOUNT_MENU_PATH)
                    : props.onboarding
                    ? () => props.previousStep()
                    : props.previousStep
                }
              />
            )}
          </FOHView>
        </FOHView>
      </FOHView>
      <WorkHistoryModal
        id={whToEdit}
        open={openEditWorkHistory}
        close={() => setOpenEditWorkHistory(false)}
      />
      <input
        type="file"
        id="file"
        accept="application/pdf,image/jpeg,image/png,application/msword,.doc,.docx, image/jpg"
        onChange={e => {
          const file = e.target.files[0];
          file && setFileToUpload(file);
          file && reader.readAsDataURL(file);
        }}
        ref={inputFile}
        style={{ display: 'none' }}
      />
    </>
  );
};

export default compose(
  withIsMobile,
  withTranslation('AddEmploymentFeature')
)(AddEmploymentScreen);
