import React from 'react';

import { length } from 'ramda';
import styled from 'styled-components/native';

import {
  FOHAnswerBadgeProps,
  FullWidthCard,
  FlexCardTopContainer,
  FOHHeaderH1,
  FOHDetailText,
  FOHLabel,
  FOHButton,
  FOHSpaceLarge,
  FOHSpaceSmall,
  FOHSpace,
  FOHAltSectionLabel,
  FOHLabelBold,
  FOHAltTextButton,
  FOHDirectMessageIcon,
  FOHInverseButton,
  FOHTextButton,
  FOHEditMarker,
  FOHColors,
  FOHView
} from '../../ingredients';
import {
  FOHAvailabilityOverview,
  FOHAnswersTable,
  FOHHiringManagerOverviewProps,
  FOHHiringManagerOverview,
  FOHDayProps
} from '../../recipes';

export interface FOHJobPositionCardProps extends FOHHiringManagerOverviewProps {
  locationName: string;
  onPressLocationName?: () => void;
  positionLabel: string;
  positionName: string;
  salaryRangeLabel: string;
  postedAtLabel: string;
  jobDescriptionLabel: string;
  jobDescription: string;
  employmentTypeLabel: string;
  employmentType: string;
  availabilityLabel?: string;
  availabilities?: Array<FOHDayProps>;
  days: Array<string>;
  experienceLabel: string;
  preferredExperience?: string;
  responsibilitiesLabel?: string;
  responsibilities?: string;
  requirementsLabel?: string;
  requirements?: string;
  answersLabel: string;
  answers: Array<FOHAnswerBadgeProps>;
  applyCTALabel: string;
  apply?: () => void;
  applyDisabled?: boolean;
  vertical?: boolean;
  onPressMessage?: () => void;
  messageLabel?: string;
  children?: React.ReactNode;
  editDetailsLabel?: string;
  onEditDetailsPress?: () => void;
  editDescriptionLabel?: string;
  onEditDescriptionPress?: () => void;
}

export const FOHJobPositionCard = (props: FOHJobPositionCardProps) => (
  <FullWidthCard>
    <FlexCardTopContainer
      style={{
        padding: 24
      }}
    >
      <FOHView
        style={{
          flexDirection: props.vertical ? 'column' : 'row',
          justifyContent: 'space-between',
          flexWrap: 'wrap'
        }}
      >
        <FOHView
          style={{
            flexWrap: 'wrap',
            display: 'flex',
            flex: 1
          }}
        >
          <FOHDetailText>{props.positionLabel}</FOHDetailText>
          <FOHView style={{ maxWidth: 300, minWidth: 270, width: '100%' }}>
            <FOHHeaderH1
              style={{
                textAlign: 'left'
              }}
            >
              {props.positionName}
            </FOHHeaderH1>
          </FOHView>
          <FOHLabelBold
            numberOfLines={2}
            style={{ alignItems: 'flex-start', textAlign: 'left' }}
          >
            {props.salaryRangeLabel}
          </FOHLabelBold>
          <FOHSpaceSmall />
          {props.vertical ? (
            <FOHAltTextButton
              onPress={props.onPressLocationName}
              title={props.locationName}
            />
          ) : (
            <FOHLabelBold
              style={{
                fontSize: 18
              }}
            >
              {props.locationName}
            </FOHLabelBold>
          )}
        </FOHView>
        {props.vertical && <FOHSpace />}
        <FOHView>
          <FOHDetailText
            style={{ alignSelf: props.vertical ? 'flex-start' : 'flex-end' }}
          >
            {props.postedAtLabel}
          </FOHDetailText>
          {!!props.editDetailsLabel && !!props.onEditDetailsPress && (
            <FOHView
              style={{
                minWidth: 200,
                width: props.vertical ? '100%' : 200,
                alignItems: props.vertical ? 'flex-start' : 'flex-end'
              }}
            >
              <FOHTextButton
                icon={() => FOHEditMarker({ style: { width: 18, height: 18 } })}
                title={props.editDetailsLabel}
                color={FOHColors.BLUE}
                style={{
                  textDecorationLine: 'underline',
                  textDecorationColor: FOHColors.BLUE
                }}
                onPress={() =>
                  props.onEditDetailsPress && props.onEditDetailsPress()
                }
              />
            </FOHView>
          )}

          {props.apply && (
            <FOHView
              style={{
                minWidth: 200,
                width: props.vertical ? '100%' : 200
              }}
            >
              <FOHSpace />
              <FOHButton
                title={props.applyCTALabel}
                onPress={props.apply}
                disabled={props.applyDisabled}
                style={{
                  minWidth: 200,
                  width: props.vertical ? '100%' : 200
                }}
              />
            </FOHView>
          )}
        </FOHView>
      </FOHView>
      {!!props.hiringManagerName && (
        <FOHView
          style={{
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: !props.vertical ? 'space-between' : 'flex-start',
            paddingBottom: 0,
            flexWrap: 'wrap',
            paddingTop: 24
          }}
        >
          {!!props.hiringManagerPosition && !!props.hiringManagerName && (
            <FOHView style={{ width: 250 }}>
              <FOHHiringManagerOverview {...props} flexStart={true} />
            </FOHView>
          )}
          {!!props.onPressMessage &&
            !!props.messageLabel &&
            !!props.hiringManagerName && (
              <FOHInverseButton
                icon={FOHDirectMessageIcon}
                centerIcon={true}
                color={FOHColors.BLACK}
                textColor={FOHColors.WHITE}
                opaque={true}
                testID={'on-press-message'}
                selected={true}
                onPress={props.onPressMessage}
                title={props.messageLabel}
                style={{
                  maxWidth: 327,
                  width: props.vertical ? '100%' : 200,
                  marginBottom: 10
                }}
              />
            )}
        </FOHView>
      )}
    </FlexCardTopContainer>
    <FOHView style={{ padding: 24 }}>
      <FOHView>
        <FOHSpaceLarge />
        <FOHView
          style={{
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between'
          }}
        >
          <FOHAltSectionLabel style={{ textAlign: 'left' }}>
            {props.jobDescriptionLabel}
          </FOHAltSectionLabel>
          {!!props.editDescriptionLabel && !!props.onEditDescriptionPress && (
            <FOHTextButton
              icon={() => FOHEditMarker({ style: { width: 18, height: 18 } })}
              title={props.editDescriptionLabel}
              color={FOHColors.BLUE}
              style={{
                textDecorationLine: 'underline',
                textDecorationColor: FOHColors.BLUE
              }}
              onPress={() =>
                props.onEditDescriptionPress && props.onEditDescriptionPress()
              }
            />
          )}
        </FOHView>
        <FOHSpaceSmall />
        <FOHLabel style={{ textAlign: 'left' }}>
          <div
            dangerouslySetInnerHTML={{
              __html: props?.jobDescription
            }}
          />
        </FOHLabel>
      </FOHView>
      <FOHView style={{ flexDirection: props.vertical ? 'column' : 'row' }}>
        {!!props.availabilityLabel &&
          !!props.availabilities &&
          length(props.availabilities || []) > 0 && (
            <FOHView style={{ flex: 1 }}>
              <FOHSpaceLarge />
              <FOHAltSectionLabel style={{ textAlign: 'left' }}>
                {props.availabilityLabel}
              </FOHAltSectionLabel>
              <FOHAvailabilityOverview
                days={props.days}
                availabilities={props.availabilities}
              />
            </FOHView>
          )}
        {!!props.preferredExperience && (
          <FOHView style={{ flex: 1 }}>
            <FOHSpaceLarge />
            <FOHAltSectionLabel style={{ textAlign: 'left' }}>
              {props.experienceLabel}
            </FOHAltSectionLabel>
            <FOHSpaceSmall />
            <FOHLabel>{props.preferredExperience}</FOHLabel>
          </FOHView>
        )}
      </FOHView>
      {!!props.responsibilities && !!props.responsibilitiesLabel && (
        <FOHView>
          <FOHSpaceLarge />
          <FOHAltSectionLabel style={{ textAlign: 'left' }}>
            {props.responsibilitiesLabel}
          </FOHAltSectionLabel>
          <FOHSpaceSmall />
          <LabelWithNewLines>{props.responsibilities}</LabelWithNewLines>
        </FOHView>
      )}
      {!!props.requirements && !!props.requirementsLabel && (
        <FOHView>
          <FOHSpaceLarge />
          <FOHAltSectionLabel style={{ textAlign: 'left' }}>
            {props.requirementsLabel}
          </FOHAltSectionLabel>
          <FOHSpaceSmall />
          <LabelWithNewLines>{props.requirements}</LabelWithNewLines>
        </FOHView>
      )}

      <FOHSpaceLarge />
      {length(props.answers) > 0 && (
        <FOHAnswersTable
          answersLabel={props.answersLabel}
          answers={props.answers}
          vertical={props.vertical}
        />
      )}
    </FOHView>
    {props.children}
  </FullWidthCard>
);

const LabelWithNewLines = styled(FOHLabel)`
  white-space: pre-line;
  text-align: left;
  padding-left: 10px;
`;
