import React, { useEffect, useState, useLayoutEffect } from 'react';

import { useLocalStorage } from 'use-hooks';
import { useTranslation } from 'react-i18next';
import { defaultTo, path, prop } from 'ramda';
import { useParams } from 'react-router-dom';
import { ChannelSettings, ChannelList } from '@sendbird/uikit-react';
import withSendbird from '@sendbird/uikit-react/withSendbird';
import '@sendbird/uikit-react/dist/index.css';
import { ChannelProvider } from '@sendbird/uikit-react/Channel/context';

import {
  FOHSearchBar,
  FOHLabel,
  FOHColors,
  FOHSearchableSelectDropdown,
  FOHCandidateMoveSuccessBanner,
  FOHSpace,
  FOHView,
  FOHTouchableOpacity,
  FOHCheckbox,
  FOHLocationPin,
  FOHToolBoxIcon,
  FOHDivider,
  FOHHeaderH2,
  FOHGoBack,
  FOHMultipleCandidatesSelected
} from '../../components';
import {
  useIsMobile,
  removeElementsByClass,
  handleMutation
} from '../../utils';
import {
  useGenerateSbTokenMutation,
  useGetChannelsQuery,
  useHideOrUnhideChannelMutation
} from '../../graphql/generated';
import { SENDBIRD_CHAT, envVariables } from '../../constants';
import { useNavigation } from '../../utils/navigation';

import { useSidebarNav } from '../Navigation/useSidebar';
import { useBanner } from '../Navigation';
import ErrorBoundary from '../ErrorBoundary';
import { useGetMeData } from '../SignIn';

import ChannelPreview from './SendbirdChannelPreview';
import { SendbirdChannel } from './SendbirdChannel';
import {
  useChatFiltersState,
  ARCHIVE_FILTER,
  INBOX_FILTER
} from './useChatFiltersState';
import ChatFilterTabs from './ChatFilterTabs';
import ArchiveLoadingScreen from './ArchiveLoadingScreen';

export const colorSet = {
  '--sendbird-light-primary-500': FOHColors.NAVY,
  '--sendbird-light-primary-400': FOHColors.LIGHT_BLUE,
  '--sendbird-light-primary-300': FOHColors.BLUE,
  '--sendbird-light-primary-200': FOHColors.GREEN,
  '--sendbird-light-primary-100': FOHColors.LIGHT_BLUE
};

export const sendBirdAppId = envVariables.SENDBIRD_APP_ID;
//
// https://github.com/sendbird/sendbird-uikit-react-sources/blob/d0feff1a18ec526b92386328848e2ae7af6f66b3/src/smart-components/ChannelList/index.jsx
// source for channel list in case we need to copy or fork their UI
const SBChannelList = withSendbird(props => {
  const { isMobile } = useIsMobile();
  const { isCandidate, isArchiveButtonVisible } = props;

  return (
    <>
      <div
        className={isMobile ? undefined : 'sendbird-app__channellist-wrap'}
        style={{
          height: props.open ? (isMobile ? '100vh' : '80vh') : 0,
          maxWidth: '344px !important',
          backgroundColor: isMobile ? FOHColors.GRAYSCALE_200 : FOHColors.WHITE,
          display: props.open ? 'block' : 'none',
          borderRight: '1px solid var(--sendbird-light-onlight-04)'
        }}
      >
        <FOHSpace />
        {!isCandidate && isArchiveButtonVisible ? (
          <FOHView style={{ flexDirection: 'row', alignItems: 'center' }}>
            <FOHSpace />
            <FOHTouchableOpacity onPress={props.onPressSelectAllChannels}>
              <FOHCheckbox
                selected={
                  props.selectedChannels.length > 0 && props.isSelectAllActive
                }
              />
            </FOHTouchableOpacity>
            <FOHSpace />
            <FOHLabel>Select All</FOHLabel>
          </FOHView>
        ) : null}

        <FOHSpace />
        <FOHDivider />
        <ChannelList
          // render our own channel previews instead
          className="sendbird-app__channellist-custom"
          renderChannelPreview={channelProps => (
            <div
              className="custom-sendbird-channel"
              data-channel-id={channelProps.channel.url}
            >
              <ChannelPreview
                {...props}
                {...channelProps}
                sendbirdUserId={props.sendbirdUserId}
                currentChannelUrl={props.channel}
                selectChannel={props.selectChannel}
                selectedChannels={props.selectedChannels}
              />
            </div>
          )}
          queries={
            isCandidate
              ? {}
              : {
                  channelListQuery: {
                    hiddenChannelFilter:
                      props.chatListArchiveFilter === ARCHIVE_FILTER
                        ? 'hidden_only'
                        : 'unhidden_only',
                    metadataKey: props.selectedPosition
                      ? 'position_id'
                      : props.selectedLocation
                      ? 'location_id'
                      : '',
                    metadataValues: [
                      props.selectedPosition
                        ? props.selectedPosition
                        : props.selectedLocation ?? ''
                    ],
                    channelNameContainsFilter: props.searchText ?? '',
                    nicknameContainsFilter: props.searchText ?? ''
                  }
                }
          }
          // don't do anything with this prop, it is pretty buggy
          onChannelSelect={_selectedChannel => {}}
        />
      </div>
    </>
  );
});

const handleHideOrUnhideChannel = async (
  channelUrl,
  hideOrUnhideChannel,
  successMove,
  sb
) => {
  const channel = await sb.groupChannel.getChannel(channelUrl);

  const isChannelHidden =
    channel.hiddenState === 'hidden_allow_auto_unhide' ||
    channel.hiddenState === 'hidden_prevent_auto_unhide';

  isChannelHidden ? await channel.unhide() : await channel.hide(false, true);

  await hideOrUnhideChannel({
    variables: {
      channelUrl,
      archive: !isChannelHidden
    }
  });

  await sb.groupChannel.getChannel(channelUrl);

  if (successMove) {
    successMove(true);
  }
};

const hideAllChannels = async (
  channels,
  setIsArchivingAllChannels,
  hideOrUnhideChannel
) => {
  setIsArchivingAllChannels(true);
  let channelsToBeArchived = channels;

  const channelsDone = [];
  const channelsFailed = [];

  let hasErrorOccured = false;

  const promises = await Promise.all(
    channelsToBeArchived.map(async channelUrl => {
      if (!hasErrorOccured) {
        try {
          await handleHideOrUnhideChannel(channelUrl, hideOrUnhideChannel);
          channelsDone.push(channelUrl);
          return true;
        } catch (error) {
          channelsFailed.push(channelUrl);

          hasErrorOccured = true;
          return false;
        }
      }
    })
  );

  setTimeout(() => {
    if (
      channelsFailed.length > 0 ||
      promises.length !== channelsToBeArchived.length
    ) {
      hideAllChannels(
        channelsFailed,
        setIsArchivingAllChannels,
        hideOrUnhideChannel
      );
    } else {
      setTimeout(() => {
        setIsArchivingAllChannels(false);
      }, 1000);
    }
  }, 1000);
};

export const SendBirdChat = props => {
  const { isMobile } = useIsMobile();
  const { t } = useTranslation('SendBirdFeature');
  const { navigateTo } = useNavigation();

  const [isLoading, setIsLoading] = useState(true);

  const [isArchiveButtonVisible] = useLocalStorage(
    'is-chat-archive-button-visible-flag',
    false
  );
  const params = useParams();

  const { channel } = params;

  const [currentChannel, setCurrentChannel] = useState(channel);

  const [showSettings, setShowSettings] = useState(false);

  const [locationOpen, setLocationOpen] = useState(false);
  const [positionOpen, setPositionOpen] = useState(false);

  useLayoutEffect(() => {
    setIsLoading(false);
  }, []);

  const [hideOrUnhideChannel, { loading }] = useHideOrUnhideChannelMutation();
  const { banner, setBanner } = useBanner();

  const successMove = toInbox => {
    setBanner({
      bannerType: 'SUCCESS',
      bannerMessage: toInbox
        ? 'success move to inbox'
        : 'success move to archive'
    });
  };

  const [generateToken] = useGenerateSbTokenMutation({});

  const { data: me } = useGetMeData();
  const sendbirdAccessToken = prop('sendbirdAccessToken', me);
  const sendbirdUserId = prop('uuid', me);
  const isCandidate = !!path(['candidateProfile', 'id'], me);
  const { hasSideNav } = useSidebarNav(props);

  const {
    selectedLocation,
    locationOptions,
    setSelectedLocation,
    selectedPosition,
    positionOptions,
    setSelectedPosition,
    locationSearch,
    positionSearch,
    setLocationSearch,
    setPositionSearch,
    searchText,
    setFiltersState,
    chatListArchiveFilter,
    selectChannel,
    selectedChannels,
    selectAllChannels,
    isSelectAllActive,
    isArchivingAllChannels,
    setIsArchivingAllChannels,
    setChatListArchiveFilter
  } = useChatFiltersState({
    setCurrentChannel
  });

  const channelsQuery = useGetChannelsQuery({
    skip: !selectedLocation,
    variables: {
      locationId: selectedLocation,
      positionId: selectedPosition,
      filters: [
        chatListArchiveFilter === INBOX_FILTER ? 'unhidden_only' : 'hidden_only'
      ]
    }
  });

  const channels = defaultTo(
    [],
    path(['data', 'channels', 'edges'], channelsQuery)
  ).map(_channel => path(['node'], _channel));

  const channelIds = channels.map(_channel =>
    path(['sendbirdChannelId'], _channel)
  );

  useEffect(() => {
    if (!sendbirdAccessToken && sendbirdUserId) {
      handleMutation(generateToken({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendbirdAccessToken, sendbirdUserId]);

  useEffect(() => {
    // TODO: Customize with our own components
    // Cannot start a group message
    removeElementsByClass('sendbird-channel-header__right-icon');
    // Cannot perform channel actions (yet)
    removeElementsByClass('sendbird-chat-header__right');
    // remove uuid
    removeElementsByClass(
      'sendbird-label sendbird-channel-header__title--user-id sendbird-label--body-2 sendbird-label--color-onbackground-2'
    );
    // remove last message time from channel header
    removeElementsByClass(
      'sendbird-label sendbird-chat-header__subtitle sendbird-label--body-1 sendbird-label--color-onbackground-2'
    );
    removeElementsByClass('sendbird-channel-list__header');
  }, [me, currentChannel, isArchivingAllChannels, isLoading]);

  useEffect(() => {
    if (currentChannel === '') {
      window.history.replaceState('', '', '/chat');
    }
  }, [currentChannel]);

  useEffect(() => {
    if (channel !== currentChannel) {
      setCurrentChannel(channel);
    }
  }, [channel, currentChannel]);

  if (!sendbirdAccessToken || !sendbirdUserId) {
    return <></>;
  }

  const mobileChannel = isMobile && currentChannel;

  const onPressSelectAllChannels = () => {
    const allVisibleChannelDivs = Array.from(
      document.getElementsByClassName('custom-sendbird-channel')
    );
    const domChannelIds = allVisibleChannelDivs.map(div =>
      div.getAttribute('data-channel-id')
    );
    return selectAllChannels(domChannelIds);
  };

  const handleHeaderArchiveUnArchivePress = async sb => {
    await handleHideOrUnhideChannel(
      currentChannel,
      hideOrUnhideChannel,
      successMove,
      sb
    );
    navigateTo(SENDBIRD_CHAT);
  };

  if (isLoading) {
    return <></>;
  }

  return (
    <ErrorBoundary>
      {!isCandidate && isArchivingAllChannels ? (
        <ArchiveLoadingScreen />
      ) : (
        <FOHView
          style={{ paddingTop: hasSideNav ? 0 : mobileChannel ? 100 : 50 }}
        >
          {!isCandidate ? (
            <>
              <FOHSpace />
              <FOHView
                style={{ width: '100%', paddingHorizontal: 24, zIndex: 100 }}
              >
                <FOHView style={{ flexDirection: 'row' }}>
                  <FOHHeaderH2>
                    {chatListArchiveFilter === INBOX_FILTER
                      ? 'Inbox'
                      : 'Archives'}
                  </FOHHeaderH2>
                  <ChatFilterTabs
                    setChatFilter={filter => {
                      setChatListArchiveFilter(filter);
                    }}
                    activeChatFilter={chatListArchiveFilter}
                    selectedLocation={selectedLocation}
                    selectedPosition={selectedPosition}
                    hideChannelLoading={loading}
                  />
                </FOHView>

                <FOHSpace />

                <FOHView
                  style={{
                    zIndex: 100,
                    flexDirection: 'row',
                    justifyContent: 'flex-start'
                  }}
                >
                  <FOHView style={{ flex: 1 }}>
                    <FOHSearchBar
                      onChangeSearch={text =>
                        setFiltersState({ searchText: text })
                      }
                      searchValue={searchText}
                      searchPlaceholder="Search"
                    />
                  </FOHView>
                  <FOHSpace />
                  <FOHView style={{ zIndex: 3, flex: 1 }}>
                    <FOHSearchableSelectDropdown
                      open={locationOpen}
                      setOpen={open => {
                        setPositionOpen(false);
                        setLocationOpen(open);
                      }}
                      selectedValue={selectedLocation}
                      selectedLabel={undefined}
                      showLabel={t('common:show')}
                      onSearchChange={setLocationSearch}
                      searchValue={locationSearch}
                      multiSelect={false}
                      searchPlaceholder={t('common:search')}
                      onValueChange={value => {
                        setSelectedLocation(value);
                      }}
                      options={locationOptions}
                      fieldLabel={t('common:location')}
                      icon={() => <FOHLocationPin />}
                      style={{ width: '100%' }}
                      testID={'FOHSearchableSelectDropdown-location'}
                    ></FOHSearchableSelectDropdown>
                  </FOHView>{' '}
                  <FOHSpace />
                  <FOHView style={{ zIndex: 2, flex: 1 }}>
                    <FOHSearchableSelectDropdown
                      open={positionOpen}
                      style={{ width: '100%' }}
                      setOpen={open => {
                        setPositionOpen(open);
                        setLocationOpen(false);
                      }}
                      selectedValue={selectedPosition}
                      multiSelect={false}
                      showLabel={t('common:show')}
                      onSearchChange={setPositionSearch}
                      searchValue={positionSearch}
                      onValueChange={value => {
                        setSelectedPosition(value);
                      }}
                      options={positionOptions}
                      searchPlaceholder={t('common:search')}
                      fieldLabel={t('common:position')}
                      upgradeLabel={t('TopNavFeature:upgrade')}
                      locationFieldLabel={t('common:location')}
                      icon={() => (
                        <FOHToolBoxIcon
                          style={{
                            width: 14,
                            height: 14,
                            tintColor: FOHColors.PACIFIC_BLUE
                          }}
                        />
                      )}
                      testID={'FOHSearchableSelectDropdown-position'}
                    ></FOHSearchableSelectDropdown>
                  </FOHView>
                </FOHView>
              </FOHView>

              <FOHSpace />
            </>
          ) : null}

          <ChannelProvider channelUrl={currentChannel}>
            <div className={isMobile ? undefined : 'customized-app'}>
              <div
                className={isMobile ? undefined : 'sendbird-app__wrap'}
                style={{
                  flexDirection: isMobile ? 'column-reverse' : 'row'
                }}
              >
                <SBChannelList
                  {...props}
                  isCandidate={isCandidate}
                  channel={currentChannel}
                  sendbirdUserId={sendbirdUserId}
                  open={(isMobile && !currentChannel) || !isMobile}
                  selectedLocation={selectedLocation}
                  selectedPosition={selectedPosition}
                  channelIds={channelIds}
                  searchText={searchText}
                  chatListArchiveFilter={chatListArchiveFilter}
                  selectChannel={selectChannel}
                  selectedChannels={selectedChannels}
                  onPressSelectAllChannels={onPressSelectAllChannels}
                  isSelectAllActive={isSelectAllActive}
                  isArchiveButtonVisible={isArchiveButtonVisible}
                />
                {selectedChannels.length > 1 ? (
                  <FOHView style={{ width: '60%', justifyContent: 'center' }}>
                    <FOHMultipleCandidatesSelected
                      thumbnails={
                        channels &&
                        channels.length > 0 &&
                        channels
                          .filter(_channel =>
                            selectedChannels.includes(
                              _channel.sendbirdChannelId
                            )
                          )
                          .slice(0, 3)
                          .reverse()
                          .map(_channel => ({
                            uri: path(
                              ['candidateUser', 'profileImage'],
                              _channel
                            )
                          }))
                      }
                      totalCount={
                        channelsQuery.loading || selectedChannels.length <= 3
                          ? 0
                          : selectedChannels.length - 3
                      }
                      selectionText={t('archiveSelectCountLabel', {
                        count: selectedChannels.length
                      })}
                      selectionHighlightText={`${selectedChannels.length} Channels`}
                      detailLabel={t('archiveDetailLabel')}
                      deselectLabel={t('deselectLabel')}
                      exportToCSVLabel={
                        chatListArchiveFilter === INBOX_FILTER
                          ? t('archiveAllLabel')
                          : t('unarchiveAllLabel')
                      }
                      deselectAll={() => {}}
                      //change the prop name to onMainAction
                      exportToCSV={() =>
                        hideAllChannels(
                          selectedChannels,
                          setIsArchivingAllChannels,
                          hideOrUnhideChannel
                        )
                      }
                      youCanAlsoLabel={t('youCanAlsoLabel')}
                      orLabel={t('orLabel')}
                    />
                  </FOHView>
                ) : (
                  <>
                    {mobileChannel ? (
                      <>
                        <FOHGoBack
                          style={{ left: 18 }}
                          goBack={() => navigateTo(SENDBIRD_CHAT)}
                        />
                      </>
                    ) : null}
                    {mobileChannel || !isMobile ? (
                      <div
                        className={
                          mobileChannel
                            ? undefined
                            : 'sendbird-app__conversation-wrap'
                        }
                        style={{ height: isMobile ? '75vh' : '80vh' }}
                      >
                        <SendbirdChannel
                          {...props}
                          channelUrl={currentChannel}
                          sendbirdUserId={sendbirdUserId}
                          hideChannel={handleHeaderArchiveUnArchivePress}
                        />
                      </div>
                    ) : null}
                    {showSettings ? (
                      <div className="sendbird-app__settingspanel-wrap">
                        <ChannelSettings
                          channelUrl={currentChannel}
                          onCloseClick={() => {
                            setShowSettings(false);
                          }}
                        />
                      </div>
                    ) : null}
                  </>
                )}
              </div>
            </div>
          </ChannelProvider>
          {banner.bannerType && banner.bannerMessage ? (
            <FOHView
              style={{
                position: 'absolute',
                zIndex: 9999,
                width: '100%',
                bottom: 0,
                paddingLeft: 20,
                paddingRight: 20
              }}
            >
              <FOHCandidateMoveSuccessBanner message={banner.bannerMessage} />
            </FOHView>
          ) : (
            <></>
          )}
        </FOHView>
      )}
    </ErrorBoundary>
  );
};

// sendbird user list of available chats // in func component
// const [after, setAfter] = useState('');
// const querySBUsers = useQuery(GET_SENDBIRD_USERS_QUERY, {
//   variables: {
//     first: 10
//   }
// });

// const sbUsers = unwrapEdgesAt(
//   ['data', 'sendBirdUsers', 'edges'],
//   querySBUsers
// );
// const lastUser = prop('id', last(sbUsers));
// const hasNext = path(
//   ['data', 'sendBirdUsers', 'pageInfo', 'hasNextPage'],
//   querySBUsers
// );
// const nextUsers = callback => {
//   const pagedUsers = sbUsers.map(user => prop('sendbirdUser', user));
//   querySBUsers.fetchMore({
//     variables: {
//       first: 10,
//       after
//     },
//     updateQuery: (previousResult, { fetchMoreResult }) => {
//       const newEdges = fetchMoreResult.sendBirdUsers.edges;
//       const pageInfo = fetchMoreResult.sendBirdUsers.pageInfo;
//       return newEdges.length
//         ? {
//             sendBirdUsers: {
//               __typename: previousResult.sendBirdUsers.__typename,
//               edges: [...previousResult.sendBirdUsers.edges, ...newEdges],
//               pageInfo
//             }
//           }
//         : previousResult;
//     }
//   });
//   setAfter(lastUser);
//   callback(pagedUsers, undefined);
// };
