import React from 'react';

import { prop } from 'ramda';

import { useIsMobile } from '../../../utils';

import { FOHSelectionCell } from '../../recipes';
import {
  FOHBlackTag,
  FOHBlueCrownIcon,
  FOHButton,
  FOHColors,
  FOHHeaderH2,
  FOHHighlightText,
  FOHInverseButton,
  FOHLabel,
  FOHLabelBold,
  FOHSmallDetailLabel,
  FOHSpace,
  FOHSpaceSmallest,
  FOHTouchableOpacity,
  FOHView,
  FullWidthCard
} from '../../ingredients';

export interface FOHPriceBlockProps {
  title: string;
  detail?: string;
  decoration: any;
  inverseColor?: boolean;
  customPrice?: string;
  price?: string;
  timePeriod?: string;
  priceDetail?: string;
  points: Array<Point>;
  start2WeeksFree?: string;
  chatWithSales?: string;
  selected?: boolean;
  selectedColor?: string;
  onPressSelect?: () => any;
  selectText?: string;
  onPressChatWithSales?: () => any;
  upgradeNow?: string;
  onPressUpgradeNow?: () => any;
  yourCurrentPlan?: string;
  discountLabel?: string;
  asterisk?: boolean;
  asteriskText?: string;
  planText?: string;
  smallDecoration?: any;
  new?: boolean;
}

interface Point {
  Icon: any;
  text: string;
}

export const FOHPriceBlock = (props: FOHPriceBlockProps) => {
  const {
    title,
    decoration,
    inverseColor,
    customPrice,
    price,
    timePeriod,
    priceDetail,
    points
  } = props;

  const { isMobile } = useIsMobile();

  const clarifiedPrice = priceDetail
    ? prop(
        0,
        priceDetail.split(' ').filter(word => word.includes('$'))
      )
    : '';

  return (
    <FullWidthCard
      shadow={true}
      selectedColor={props.selectedColor}
      selected={props.selected}
      style={{
        padding: isMobile ? 16 : 24,
        width: '100%',
        minWidth: isMobile ? 298 : 320,
        maxWidth: 359,
        flex: 1,
        backgroundColor: inverseColor ? '#000000' : '#ffffff'
      }}
      testID={`FOHPriceBlock-${props.title}`}
    >
      <FOHTouchableOpacity
        onPress={props.onPressSelect}
        disabled={!props.onPressSelect}
      >
        <FOHView>
          <FOHHeaderH2
            style={{
              textAlign: 'left',
              color: inverseColor ? '#ffffff' : '#000000'
            }}
          >
            {title}
          </FOHHeaderH2>
          <FOHSpace />
          {props.detail ? (
            <>
              <FOHSmallDetailLabel style={{ color: '#BDBDBD' }}>
                {props.detail}
              </FOHSmallDetailLabel>
              <FOHSpace />
              <FOHSpace />
            </>
          ) : (
            <></>
          )}
          <FOHView style={{ position: 'absolute', top: 0, right: 0 }}>
            {decoration}
          </FOHView>
          {price ? (
            <FOHView>
              <FOHLabel
                style={{
                  fontSize: 48,
                  lineHeight: 56,
                  weight: 400,
                  color: inverseColor ? '#ffffff' : '#000000'
                }}
              >
                {price}
                {!props.new ? (
                  <>
                    <FOHLabel style={{ color: FOHColors.RED, fontSize: 54 }}>
                      {props.asterisk ? '*' : ''}
                    </FOHLabel>
                    <FOHLabel
                      style={{
                        fontSize: 24,
                        color: inverseColor ? '#ffffff' : '#BDBDBD'
                      }}
                    >
                      {timePeriod}
                    </FOHLabel>
                  </>
                ) : (
                  <></>
                )}
              </FOHLabel>
              {props.new ? (
                <FOHSmallDetailLabel
                  style={{
                    fontSize: 12,
                    color: inverseColor ? '#ffffff' : '#4F4F4F'
                  }}
                >
                  {timePeriod}
                </FOHSmallDetailLabel>
              ) : (
                <></>
              )}
              <FOHSpace />
              <FOHSmallDetailLabel style={{ color: FOHColors.RED }}>
                <>{props.asterisk ? '*' : ''}</>
                <>{props.asteriskText ? `${props.asteriskText} ` : ''}</>
                <FOHHighlightText
                  labelCmp={FOHSmallDetailLabel}
                  emphasizedWord={clarifiedPrice}
                  highlightColor={'#000000'}
                  boldLabelCmp={FOHLabelBold}
                >
                  {priceDetail || ''}
                </FOHHighlightText>
              </FOHSmallDetailLabel>
              <FOHSpace />
            </FOHView>
          ) : (
            <FOHView>
              <FOHLabel
                style={{
                  fontSize: 32,
                  lineHeight: 56,
                  weight: 400,
                  color: inverseColor ? '#ffffff' : '#000000'
                }}
              >
                {customPrice}
              </FOHLabel>
              <FOHSpace />
              <FOHSpace />
            </FOHView>
          )}
          {!!props.planText && !!props.smallDecoration ? (
            <FOHView
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                paddingTop: 12
              }}
            >
              {props.smallDecoration}
              <FOHSpaceSmallest />
              <FOHLabelBold style={{ fontSize: 14 }}>
                {props.planText}
              </FOHLabelBold>
            </FOHView>
          ) : (
            <></>
          )}
          {props.discountLabel ? (
            <>
              <FOHSpace />
              <FOHView style={{ flexDirection: 'row' }}>
                <FOHBlackTag
                  label={props.discountLabel}
                  style={{
                    textAlign: 'left',
                    justifyContent: 'flex-start'
                  }}
                />
              </FOHView>
            </>
          ) : (
            <></>
          )}
          {!props.new ? <FOHSpace /> : <></>}
          {points.map((point, index) => (
            <FOHView
              key={`point-${index}`}
              style={{ flexDirection: 'row', paddingBottom: 16 }}
            >
              {point.Icon}
              <FOHSpaceSmallest />
              <FOHLabel
                style={{
                  color: inverseColor ? '#ffffff' : '#000000',
                  maxWidth: 200
                }}
              >
                {point.text}
              </FOHLabel>
            </FOHView>
          ))}
          <FOHSpace />
        </FOHView>
      </FOHTouchableOpacity>
      <FOHSpace />
      <FOHView
        style={{
          flex: 1,
          justifyContent: 'flex-end',
          alignItems: 'center',
          bottom: 0
        }}
      >
        {props.upgradeNow && props.new && props.onPressUpgradeNow ? (
          <FOHButton
            title={props.upgradeNow}
            onPress={props.onPressUpgradeNow}
          />
        ) : props.upgradeNow && props.onPressUpgradeNow ? (
          <FOHInverseButton
            textColor={'#ffffff'}
            title={props.upgradeNow}
            color={FOHColors.GOLD}
            opaque={true}
            selected={true}
            icon={() => (
              <FOHBlueCrownIcon
                style={{ tintColor: '#ffffff', width: 16, height: 16 }}
              />
            )}
            onPress={props.onPressUpgradeNow}
          />
        ) : props.chatWithSales &&
          props.new &&
          props.onPressChatWithSales &&
          !props.selected ? (
          <div style={{ minWidth: 290 }}>
            <FOHButton
              title={props.chatWithSales}
              onPress={props.onPressChatWithSales}
            />
          </div>
        ) : props.chatWithSales &&
          props.onPressChatWithSales &&
          !props.selected ? (
          <div style={{ minWidth: 180 }}>
            <FOHInverseButton
              textColor={'#000000'}
              title={props.chatWithSales}
              onPress={props.onPressChatWithSales}
            />
          </div>
        ) : !!props.yourCurrentPlan && props.new ? (
          <FOHButton
            title={props.yourCurrentPlan}
            disabled={true}
            onPress={() => {}}
          />
        ) : props.onPressSelect && props.selectText ? (
          <FOHSelectionCell
            value={'select-all'}
            onPress={props.onPressSelect}
            label={props.selectText}
            selected={!!props.selected}
            key={`select-${props.selectText}`}
            style={{
              borderColor: '#ffffff',
              backgroundColor: undefined
            }}
          />
        ) : (
          <></>
        )}
      </FOHView>
    </FullWidthCard>
  );
};
