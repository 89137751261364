import React, { useState, useEffect } from 'react';

import { compose, length, path, prop } from 'ramda';
import { useTranslation } from 'react-i18next';

import { multiSelectItem } from '../../utils/fieldHelpers';
import {
  RESTAURANT_TYPES_DROPDOWN,
  CANDIDATE_ACCOUNT_MENU_PATH,
  CANDIDATE_RESTAURANT_STYLE_PATH
} from '../../constants';
import { useIsMobile, handle } from '../../utils';
import { useNavigation } from '../../utils/navigation';
import { FOHMultiSelectScreen } from '../../components';

import { withReactiveModal } from '../Navigation';

import { useCandidateProfileMutation } from './useCandidateProfileMutation';

const RestaurantStyleScreen = props => {
  const { navigateTo, location: navLocation } = useNavigation();
  const { me, updateCandidate } = useCandidateProfileMutation();

  const [style, setStyle] = useState();

  const [error, setError] = useState();
  const { t } = useTranslation('RestaurantStyleFeature');
  const { isMobile } = useIsMobile();

  const hasBack =
    navLocation.pathname === CANDIDATE_RESTAURANT_STYLE_PATH &&
    !!path(['candidateProfile', 'onboardingCompletedAt'], me);

  const [dirty, setDirty] = useState(false);

  useEffect(() => {
    setStyle(path(['candidateProfile', 'restaurantTypes'], me));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prop('candidateProfile', me)]);

  const items = RESTAURANT_TYPES_DROPDOWN.map(option => ({
    value: option.value,
    label: t(option.label)
  }));

  return (
    <FOHMultiSelectScreen
      onboarding={props.onboarding}
      items={items}
      selected={style}
      selectItem={value => {
        setDirty(true);
        return multiSelectItem(value, setStyle, style);
      }}
      selectAll={() => {
        setDirty(true);
        if (length(items) === length(style)) {
          setStyle([]);
        } else {
          setStyle(items.map(item => item.value));
        }
      }}
      previousTitle={
        props.onboarding ? t('CandidateOnboardingSteps:previous') : ''
      }
      goBack={
        hasBack
          ? () => navigateTo(CANDIDATE_ACCOUNT_MENU_PATH)
          : props.onboarding
          ? () => props.previousStep()
          : null
      }
      allSelected={length(items) === length(style)}
      selectAllLabel={t('common:selectAll')}
      title={t('styleTitle')}
      submitLabel={
        path(['candidateProfile', 'onboardingCompletedAt'], me)
          ? t('common:save')
          : t('nextLabel')
      }
      error={error}
      isModal={props.isModal}
      disabledSubmit={length(style) > 0 && !dirty && !props.onboarding}
      largeHeader={!isMobile}
      handleSubmit={async () => {
        const [resp] = await handle(
          updateCandidate({
            variables: {
              restaurantTypes: style
            }
          })
        );
        const err = path(['data', 'mutateCandidate', 'errors'], resp);
        if (resp && !err) {
          setDirty(false);
          if (props.close) return props.close();
          return navigateTo(
            path(['candidateProfile', 'onboardingCompletedAt'], me)
              ? CANDIDATE_ACCOUNT_MENU_PATH
              : props.onSubmit && props.onSubmit(resp)
          );
        } else if (err) {
          setError(err);
        }
      }}
    />
  );
};

export const RestaurantStyleModal = compose(withReactiveModal)(
  RestaurantStyleScreen
);

export default RestaurantStyleScreen;
