/* eslint-disable */
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { compose, path, prop, length } from 'ramda';

import {
  FOHFixedHeightScrollView,
  FOHView,
  FOHBannerType,
  FOHFormFlowButtons,
  FOHEmployerOnboardingQuestions
} from '../../components';

import {
  useGetQuestionsQuery,
  useMutateAnswerQuestionsMutation
} from '../../graphql/generated';

import { findById, handleMutation } from '../../utils';
import { EXTERNAL_APPLICATION_HEIGHTTODECREASE } from '../../constants';
import { useNavigation } from '../../utils/navigation';

const hasAnsweredRequiredQuestion = (
  questions,
  answers = [{ questionId: '' }]
) => {
  return (
    length(
      questions.filter(question => {
        const answer = findById(prop('id', question), answers, 'questionId');
        return (
          (prop('required', question) &&
            (prop('value', answer) || prop('freeText', answer))) ||
          !prop('required', question)
        );
      })
    ) !== length(questions)
  );
};

const Questions = props => {
  const { location: navLocation } = useNavigation();
  const variables = {
    category: 'location'
  };
  const questionsQuery = useGetQuestionsQuery({
    variables: {
      ...variables
    }
  });
  const [answerQuestions] = useMutateAnswerQuestionsMutation();

  const { t, answers, setAnswers, triggerSave, setTriggerSave } = props;

  const questions = (
    path(['data', 'questions', 'edges'], questionsQuery) || []
  ).map(node => ({ ...prop('node', node) }));

  const answerLocationQuestions = async () => {
    if (length(answers) > 0) {
      const question_Ids = questions.map(question => question.id);
      const responses = answers
        .filter(answer => question_Ids.includes(answer.questionId))
        .map(ans => ({
          question: ans.questionId,
          option: ans.optionId,
          answer:
            length(prop('freeText', ans)) > 0
              ? prop('freeText', ans)
              : ans.value
        }));
      await handleMutation(
        answerQuestions({
          variables: {
            location: navLocation.id,
            employerProfile: '',
            responses
          }
        })
      );
    }
    props.setSettingsBanner &&
      props.setSettingsBanner({
        bannerType: FOHBannerType.SUCCESS,
        bannerMessage: t('common:success')
      });

    props.scrollUp && props.scrollUp();

    return props.nextStep();
  };

  useEffect(() => {
    if (triggerSave) {
      answerLocationQuestions();
      setTriggerSave(false);
    }
  }, [answerLocationQuestions, setTriggerSave, triggerSave]);

  return (
    <FOHView
      style={{
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <FOHView
        style={{
          width: '100%'
        }}
      >
        <FOHFixedHeightScrollView
          isMobile={props.isMobile}
          heightToDecrease={EXTERNAL_APPLICATION_HEIGHTTODECREASE}
        >
          <FOHView
            style={{
              width: '100%'
            }}
          >
            <FOHEmployerOnboardingQuestions
              title={props.hideTitles ? '' : t('title')}
              tableTitle={props.hideTitles ? '' : t('tableTitle')}
              description={props.hideTitles ? '' : t('description')}
              // nextLabel={t('nextLabel')}
              answers={answers}
              hideTitles={props.hideTitles || false}
              largeHeader={props.isMobile}
              loading={prop('loading', questionsQuery)}
              questions={questions}
              disabledSubmit={hasAnsweredRequiredQuestion(questions, answers)}
              updateAnswer={ans => {
                const restAnswers = answers.filter(
                  answer => prop('questionId', answer) !== ans.questionId
                );
                setAnswers([...restAnswers, ans]);
              }}
              next={async () => await answerLocationQuestions()}
            />
          </FOHView>
        </FOHFixedHeightScrollView>
      </FOHView>
      {!props.hideTitles && (
        <FOHView
          style={{
            width: '100%',
            marginBottom: props.isMobile ? 0 : 100
          }}
        >
          <FOHFormFlowButtons
            isMobile={true}
            nextButtonlabel={
              prop('loading', questionsQuery)
                ? `${t('common:loading')}...`
                : t('nextLabel')
            }
            onPressNextButton={
              props.hideTitles
                ? undefined
                : async () => await answerLocationQuestions()
            }
            isNextDisabled={hasAnsweredRequiredQuestion(questions, answers)}
            previousButtonLabel={t('common:previous')}
            onPressPreviousButton={props.previousStep}
          />
        </FOHView>
      )}
    </FOHView>
  );
};

export const EmployerOnboardingQuestions = compose(
  withTranslation('EmployerQuestions')
)(Questions);
