import React from 'react';

import styled from 'styled-components/native';

import {
  FOHBluePlayIcon,
  FOHTouchableOpacity,
  FOHView,
  withError
} from '../../ingredients';

export interface FOHVideoPlayerProps {
  pickVideo?: (() => any) | undefined;
  video?: string;
  VideoComponent: any;
  height?: string;
}

const VideoPlayer = (props: FOHVideoPlayerProps) => (
  <FOHView>
    {props.video ? (
      <FOHPlayContainer style={{ height: props.height ? props.height : 262 }}>
        <props.VideoComponent style={{ height: 262 }} />
      </FOHPlayContainer>
    ) : (
      <FOHTouchableOpacity
        onPress={props.pickVideo}
        disabled={!props.pickVideo}
        style={{ height: props.height ? props.height : 262 }}
      >
        <FOHPlayContainer>
          <FOHBluePlayIcon />
        </FOHPlayContainer>
      </FOHTouchableOpacity>
    )}
  </FOHView>
);

export const FOHPlayContainer = styled(FOHView)`
  background: #ffffff;
  border: 1px solid #dbdbdb;

  border-radius: 6px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const FOHVideoPlayer = withError(VideoPlayer);
