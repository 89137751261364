import React from 'react';

import black_add from '../../../images/black_add.svg';

import { FOHImage } from '../../ingredients';

export const FOHBlackAdd = ({ style = {} }: { style?: any }) => (
  <FOHImage
    source={{ uri: black_add }}
    style={{ width: 16, height: 16, ...style }}
  />
);
