import React, { FunctionComponent } from 'react';

import { StyleProp, ViewStyle } from 'react-native';
import styled from 'styled-components/native';

import {
  FOHView,
  SPACING_SCALE,
  FOHColors,
  FOHSquareTag,
  FOHDetailText,
  FOHSectionLabel,
  FOHSmallDetailLabel
} from '../../ingredients';
import { FOHLocationProps } from '../../typings';
import { useIsMobile } from '../../spices';

interface FOHCareerPageLocationsProps {
  fontProperty?: FontProperty;
  locations: FOHLocationProps[];
  translations: Record<string, string>;
  Map: React.ReactElement;
  style?: StyleProp<ViewStyle>;
}

export type FontProperty = { fontFamily: string; color: string };

const LOCATION_LIST_TILE_HEIGHT = 84;
const LOCATION_LIST_ITEM_LIMIT = 5;

export const FOHCareerPageLocations: FunctionComponent<
  FOHCareerPageLocationsProps
> = ({ Map, fontProperty, locations, style, translations }) => {
  const isMobile = useIsMobile();

  return (
    <FOHView style={style}>
      {!isMobile && (
        <FOHSectionLabel
          style={{
            marginBottom: SPACING_SCALE.regular,
            ...fontProperty
          }}
        >
          {translations.header}

          <FOHSquareTag
            label={`${locations.length}`}
            style={{ marginLeft: SPACING_SCALE.regular }}
          />
        </FOHSectionLabel>
      )}
      {Map}

      <ListWrapper noOfLocations={locations.length}>
        {locations.length > 0 ? (
          locations.map(location => (
            <FOHView
              key={location.id}
              style={{
                marginBottom: SPACING_SCALE.large,
                marginTop: SPACING_SCALE.large,
                width: '100%'
              }}
            >
              <LocationName style={{ ...fontProperty }}>
                {location.name}
              </LocationName>
              <FOHSmallDetailLabel style={{ fontSize: 14, ...fontProperty }}>
                {location.formattedAddress}
              </FOHSmallDetailLabel>
            </FOHView>
          ))
        ) : (
          <EmptyLocationsList style={{ ...fontProperty }}>
            {translations.noLocations}
          </EmptyLocationsList>
        )}
      </ListWrapper>
    </FOHView>
  );
};

interface ListWrapperProps {
  noOfLocations: number;
}

const ListWrapper = styled(FOHView)<ListWrapperProps>`
  background-color: ${FOHColors.LIGHT_GRAY};
  padding: ${SPACING_SCALE.large}px ${SPACING_SCALE.huge}px;
  max-height: ${LOCATION_LIST_ITEM_LIMIT * LOCATION_LIST_TILE_HEIGHT}px;

  ${(props: ListWrapperProps) =>
    props?.noOfLocations > LOCATION_LIST_ITEM_LIMIT
      ? `overflow-y: scroll`
      : `auto`}
`;

const LocationName = styled(FOHSmallDetailLabel)`
  color: ${FOHColors.BLACK};
  font-size: 15px;
  font-weight: 600;
  margin-bottom: ${SPACING_SCALE.regular}px;
`;
const EmptyLocationsList = styled((props: JSX.IntrinsicAttributes) => (
  <FOHDetailText {...props} />
))`
  margin-top: ${SPACING_SCALE.massive}px;
  margin-bottom: ${SPACING_SCALE.massive}px;
  padding: ${SPACING_SCALE.massive}px;
  text-align: center;
  line-height: 24px;
`;
