import React, { useRef } from 'react';

import { useHover } from 'react-native-web-hooks';
import { View, TouchableOpacity } from 'react-native';
import { prop, defaultTo, remove } from 'ramda';

import {
  FOHView,
  FOHSectionLabel,
  FOHTextField,
  FOHSwitch,
  FOHMultiLineTextField,
  FOHLinkOut,
  FOHSpaceSmall,
  FOHToolTip,
  FOHToolTipType,
  FOHXIcon
} from '../../ingredients';

import { FOHScheduleFormTitleBlock } from '../blocks/FOHScheduleFormTitleBlock';
import { FOHAddLink } from '../cells/FOHAddLink';

import { FileBlock } from './FOHCandidateWelcomeLetter';

export interface FOHWelcomeLetterSecondFormProps {
  children?: React.ReactNode;
  titleLabel: string;
  candidateName: string;
  detailLabel: string;
  decorationImage: string;

  customNoteLabel: string;
  includeCustomNote: boolean;
  setIncludeCustomNote: (val: boolean) => void;
  customNote: string;
  setCustomNote: (val: string) => void;
  customNotePlaceholder: string;
  customNoteHelper: string;

  documentLabel?: string;
  includeDocument?: boolean;
  setIncludeDocument?: (val: boolean) => void;

  linkLabel: string;
  includeLink: boolean;
  setIncludeLink: (val: boolean) => void;
  links: Array<string>;
  setLinks: (val: any) => void;
  addLinkLabel: string;
  addLink: () => void;
  link: string;
  setLink: (val: string) => void;
  linkPlaceholder: string;
  error?: any;

  isMobile?: boolean;
}

export const FOHWelcomeLetterSecondForm = (
  props: FOHWelcomeLetterSecondFormProps
) => {
  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);

  return (
    <FOHView style={{ maxWidth: 716 }}>
      <FOHScheduleFormTitleBlock
        titleLabel={props.titleLabel}
        candidateName={props.candidateName}
        detailString={props.detailLabel}
        decorationImage={props.decorationImage}
      />

      <FOHView
        style={{
          margin: 5,
          padding: 32,
          borderRadius: 24,
          backgroundColor: '#FCFCFC'
        }}
      >
        <FOHView
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            zIndex: 1
          }}
        >
          <FOHSectionLabel>{props.customNoteLabel}</FOHSectionLabel>
          <FOHView>
            <View style={{ position: 'absolute' }}>
              <FOHToolTip
                type={FOHToolTipType.LEFT}
                helpText={props.customNoteHelper}
                open={isHovered}
                style={{ width: 200, right: 170, top: 6 }}
                width={500}
              />
            </View>
            <View ref={hoverRef}>
              <FOHSwitch
                value={defaultTo(true, props.includeCustomNote)}
                onValueChange={value => {
                  (props.includeDocument || props.includeLink) &&
                    props.setIncludeCustomNote(value);
                }}
              />
            </View>
          </FOHView>
        </FOHView>
        {props.includeCustomNote && (
          <FOHView style={{ marginTop: 16, marginBottom: 16 }}>
            <FOHMultiLineTextField
              onChangeText={props.setCustomNote}
              value={props.customNote}
              placeholder={props.customNotePlaceholder}
              max={500}
              style={{ paddingRight: 7 }}
            />
          </FOHView>
        )}
        {props.includeDocument && props.setIncludeDocument ? (
          <>
            <FOHView
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <FOHSectionLabel>{props.documentLabel}</FOHSectionLabel>
              <FOHView>
                <FOHSwitch
                  value={defaultTo(true, props.includeDocument)}
                  onValueChange={value => {
                    if (props.includeCustomNote || props.includeLink) {
                      props.setIncludeDocument &&
                        props.setIncludeDocument(value);
                    }
                  }}
                />
              </FOHView>
            </FOHView>
            {props.includeDocument && (
              <FOHView style={{ marginTop: 16, marginBottom: 16 }}>
                {props.children}
              </FOHView>
            )}
          </>
        ) : (
          <FOHView />
        )}
        <FOHView
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <FOHSectionLabel>{props.linkLabel}</FOHSectionLabel>
          <FOHView>
            <FOHSwitch
              value={defaultTo(true, props.includeLink)}
              onValueChange={value => {
                (props.includeCustomNote || props.includeDocument) &&
                  props.setIncludeLink(value);
              }}
            />
          </FOHView>
        </FOHView>
        {props.includeLink && (
          <FOHView style={{ marginTop: 16, marginBottom: 16 }}>
            {prop('links', props) && (
              <FileBlock>
                {props.links?.map((link: any, i: number) => {
                  return (
                    <View
                      key={i}
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        marginTop: 12,
                        marginBottom: 12
                      }}
                    >
                      <FOHLinkOut href={link} revertIcon={true}>
                        {link}
                      </FOHLinkOut>
                      <TouchableOpacity
                        onPress={() => {
                          const remainingLinks = remove(i, 1, props.links);
                          props.setLinks(remainingLinks);
                        }}
                        style={{
                          width: 32,
                          backgroundColor: '#F5F5F5',
                          borderRadius: 16,
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: 32
                        }}
                      >
                        <FOHXIcon />
                      </TouchableOpacity>
                    </View>
                  );
                })}
              </FileBlock>
            )}
            <FOHTextField
              placeholder={props.linkPlaceholder}
              value={props.link}
              onChangeText={props.setLink}
              error={props.error && props.error.link ? props.error.link : null}
            />
            <FOHSpaceSmall />
            <FOHAddLink label={props.addLinkLabel} onPress={props.addLink} />
          </FOHView>
        )}
      </FOHView>
    </FOHView>
  );
};
