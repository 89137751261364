import React, { useRef } from 'react';

import styled from 'styled-components/native';
import { useHover } from 'react-native-web-hooks';

import {
  FOHLabel,
  FOHColors,
  FOHView,
  FOHTouchableOpacity,
  FOHCheckbox,
  FOHRadioSelect,
  FOHSmallDetailLabel
} from '../../ingredients';

export interface FOHSelectionCellProps {
  cellStyle?: any;
  checkboxes?: boolean;
  icon?: any;
  label: string;
  description?: string;
  minWidth?: string | number;
  onPress: (value: string) => any;
  selected: boolean;
  style?: any;
  value: string;
  bordered?: boolean;
  isDisabled?: boolean;
}

export const FOHSelectionCell = ({
  cellStyle,
  checkboxes,
  icon,
  label,
  description,
  minWidth,
  onPress,
  selected,
  style,
  value,
  bordered,
  isDisabled
}: FOHSelectionCellProps) => {
  const UIComponent = checkboxes ? FOHCheckbox : FOHRadioSelect;
  const ref = useRef(null);
  const hoverRef = useHover(ref);

  return (
    <Border
      style={{
        minWidth: minWidth || 327,
        borderWidth: !bordered ? 0 : 1,
        backgroundColor: selected ? FOHColors.GRAYSCALE_10 : '',
        padding: 8,
        marginBottom: 8,
        ...style
      }}
    >
      <FOHTouchableOpacity
        style={{
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          padding: 4,
          paddingLeft: 20,
          minHeight: 48,
          backgroundColor: hoverRef ? '#F2F4F7' : '',
          ...cellStyle
        }}
        ref={ref}
        testID={`select-cell-${label}`}
        onPress={e => {
          e.preventDefault();
          if (!isDisabled) {
            onPress(value);
          }
        }}
      >
        {
          <UIComponent
            selected={selected}
            isDisabled={isDisabled}
            style={{ marginRight: 12 }}
          />
        }
        {icon ? <FOHView style={{ marginRight: 12 }}>{icon}</FOHView> : <></>}
        <FOHView style={{ flex: 1, justifyContent: 'center' }}>
          <FOHLabel style={{ color: isDisabled ? '#98A2B3' : '' }}>
            {label}
          </FOHLabel>
          {description ? (
            <FOHSmallDetailLabel style={{ color: isDisabled ? '#98A2B3' : '' }}>
              {description}
            </FOHSmallDetailLabel>
          ) : (
            <></>
          )}
        </FOHView>
      </FOHTouchableOpacity>
    </Border>
  );
};

const Border = styled(FOHView)`
  align-items: center;
  flex-direction: row;
  border-radius: 6px;
  justify-content: space-between;
  min-width: 327px;
  max-width: 500px;
  min-height: 50px;
  max-height: 150px;
  border: 1px solid #e8e8e8;
  background-color: ${FOHColors.WHITE};
  margin-bottom: 4px;
  margin-top: 4px;
`;
