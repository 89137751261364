import React, { useRef, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-hooks';
import { path } from 'ramda';
import { GoogleMap, Marker, MarkerClusterer } from '@react-google-maps/api';
import shallow from 'zustand/shallow';
import FontPicker from 'font-picker-react';

import {
  FOHColors,
  FOHView,
  FOHScrollView,
  FOHBannerType,
  FOHMap,
  FOHCareerPageLayout,
  FOHCareerPageLocations,
  FOHCareerPageMainImage,
  FOHCareerPagePositions,
  FOHBlackTag,
  FOHCareerPageDetails,
  FOHCareerPageTabs,
  FOHTabList,
  SPACING_SCALE,
  FOHPositionBillingStatusType,
  FOHBackToTopButton,
  FOHSectionLabel,
  FOHMapMarker,
  FOHMarkerCluster,
  gmapStyle,
  FOHAnswersTable,
  TOP_NAV_HEIGHT,
  CareerPageScreenBackground
} from '../../components';
import { useGetCareerPagePositionsQuery } from '../../../src/graphql/generated';
import {
  formatFollowerCountLabel,
  isScrollCloseToBottom,
  useIsMobile,
  copyToClipboard,
  shareOnMobile
} from '../../utils';
import DEFAULT_COVER from '../../images/career-page-default-cover.svg';
import { fetchMorePositions } from '../EmployerPositions/fetchMorePositions';
import { CustomSizeLogoLoader } from '../../features/Loading/LogoLoader';
import { useLocalPagination } from '../../utils/useLocalPagination';
import { useBanner } from '../Navigation';
import { envVariables, NASHVILLE_COORDS } from '../../constants';
import { MapControls } from '../Map';
import { useAuthFlowSelectionContext } from '../GoogleSSOAuth/components';

import { CareerPageScreenModals } from './components';
import { useModalStore, MODAL_TYPE } from './stores/modals';
import { adaptLocationsOptions, adaptPositionsData } from './adapters';

const DEFAULT_BACKGROUND_COLOR = 'F4F4F4';
const DEFAULT_BRAND_COLOR = '009DC1';
const DEFAULT_FONT_COLOR = '000000';
const DEFAULT_TABLE_COLOR = 'FCFCFC';

const POSITIONS_BATCH = 5;
const DESKTOP_LIST_LEN = 4;
const DESKTOP_POSITION_TILE_HEIGHT = 200;

const MOBILE_TAB_ROUTES = {
  locations: 'locations',
  positions: 'positions'
};

const MobileTabUI = ({
  locationsShownOnMap,
  onLayout,
  positions,
  selectedMobileTab,
  setSelectedMobileTab,
  t
}) => {
  const LocationsMobileTab = isSelected => (
    <FOHSectionLabel
      style={{ fontSize: 15, fontWeight: isSelected ? 'bold' : 'normal' }}
    >
      {t('ourLocationsHeader')}
      <FOHBlackTag
        label={`${locationsShownOnMap?.length}`}
        style={{ marginLeft: SPACING_SCALE.regular }}
      />
    </FOHSectionLabel>
  );

  const PositionsMobileTab = isSelected => (
    <FOHSectionLabel
      style={{ fontSize: 15, fontWeight: isSelected ? 'bold' : 'normal' }}
    >
      {t('openPositionsHeader')}
      <FOHBlackTag
        label={`${positions?.length}`}
        style={{ marginLeft: SPACING_SCALE.regular }}
      />
    </FOHSectionLabel>
  );

  const mobileTabs = [
    {
      icon: LocationsMobileTab,
      route: MOBILE_TAB_ROUTES.locations,
      selectedIcon: () => LocationsMobileTab(true)
    },
    {
      icon: PositionsMobileTab,
      route: MOBILE_TAB_ROUTES.positions,
      selectedIcon: () => PositionsMobileTab(true)
    }
  ];

  return (
    <FOHView onLayout={onLayout} style={{ width: '100%' }}>
      <FOHTabList
        onPressTab={setSelectedMobileTab}
        selectedTab={selectedMobileTab}
        tabOptions={{ fullWidth: true }}
        tabs={mobileTabs}
      />
    </FOHView>
  );
};

export const CareerPage = ({
  answers,
  data: {
    brandColor,
    brandPageColor,
    brandTableColor,
    cover,
    coverColor,
    description,
    fontColor,
    fontFamily,
    formattedAddress,
    title
  },
  locations: { dropdownOptions, locationArray, locationRowIds, tags },
  mainImageArray,
  meQuery: { refetchMe, userData }
}) => {
  //#region set up hooks
  const { t } = useTranslation('CareerPage');
  const { setBanner } = useBanner();
  const { isMobile } = useIsMobile();
  const wrapperRef = useRef(null);
  const fontProperty = {
    color: `#${fontColor}`,
    fontFamily: fontFamily
  };
  const { handleDisplayAuthControlButtons } = useAuthFlowSelectionContext();

  const { applyToPosition, openModal, toggleModal } = useModalStore(
    state => ({
      applyToPosition: state.applyToPosition,
      openModal: state.openModal,
      toggleModal: state.toggleModal
    }),
    shallow
  );
  //#endregion hooks
  const isCandidate = !!userData?.candidateProfile?.id;
  const isEmployer = !!userData?.employerProfileUser?.id;

  const desktopTabs = [
    {
      route: 'overview',
      title: t('tabLabelsOverview')
    }
  ];

  //#region state
  const [isFetchMoreLoading, setIsFetchMoreLoading] = useState(false);
  const [isPageAtBottom, setIsPageAtBottom] = useState(false);
  const [isPosListAtBottom, setIsPosListAtBottom] = useState(false);
  const [isScrolledPastPositions, setIsScrolledPastPositions] = useState(false);
  const [mainImageHeight, setMainImageHeight] = useState(0);
  const [mobileTabsY, setMobileTabsY] = useState(0);
  const [positionListY, setPositionListY] = useState(0);
  const [searchPhrase, setSearchPhrase] = useState('');
  const [selectedLocationsRowIds, setSelectedLocationsRowIds] =
    useState(locationRowIds);
  const [selectedMobileTab, setSelectedMobileTab] = useState(
    MOBILE_TAB_ROUTES.locations
  );
  const [selectedTab, setSelectedTab] = useState(desktopTabs[0].route);
  //#endregion

  const locationsShownOnMap = useMemo(
    () =>
      locationArray.filter(location =>
        selectedLocationsRowIds.some(rowId => rowId === location.rowId)
      ),
    [locationArray, selectedLocationsRowIds]
  );

  const debouncedSearchPhrase = useDebounce(searchPhrase, 1000);
  const queryVariables = {
    after: '',
    first: POSITIONS_BATCH,
    // To get no positions when no locations are selected
    location_In: selectedLocationsRowIds?.length
      ? selectedLocationsRowIds
      : [null],
    positionName_Icontains: debouncedSearchPhrase
  };

  const positionsQuery = useGetCareerPagePositionsQuery({
    variables: queryVariables
  });

  const { data: positionsData, loading: isPosQueryLoading } = positionsQuery;

  const activelyHiringSubscribedPositions = adaptPositionsData(positionsData);

  const positions = activelyHiringSubscribedPositions.filter(
    position =>
      path(['paymentStatus'], position) ===
        FOHPositionBillingStatusType.SUBSCRIBED ||
      path(['location', 'paymentStatus'], position) ===
        FOHPositionBillingStatusType.SUBSCRIBED
  );

  const hasFetchedMorePositions = positions?.length > POSITIONS_BATCH;
  const isBackToTopPositionsVisible =
    isMobile &&
    selectedMobileTab === MOBILE_TAB_ROUTES.positions &&
    isScrolledPastPositions &&
    hasFetchedMorePositions;

  const paginationLogic = useLocalPagination(mainImageArray.length);
  const currentMainImage = mainImageArray?.[paginationLogic.currentPage - 1];

  //#region handlers
  const handleJobsClick = () => {
    if (isMobile) setSelectedMobileTab(MOBILE_TAB_ROUTES.positions);

    return wrapperRef?.current?.scrollTo({
      animated: true,
      y: isMobile
        ? mainImageHeight + mobileTabsY + TOP_NAV_HEIGHT * 2
        : positionListY
    });
  };

  const handleShareClick = () => {
    const mobileShare = () => {
      shareOnMobile({
        title,
        url: document.location.href
      });
    };

    const desktopShare = () =>
      copyToClipboard({
        content: document.location.href,
        onSuccess: () =>
          setBanner({
            bannerMessage: t('urlCopied'),
            bannerType: FOHBannerType.SUCCESS
          })
      });

    isMobile ? mobileShare() : desktopShare();
  };

  const handleMessageClick = () => {
    if (isCandidate) {
      openModal(MODAL_TYPE.message);
    } else {
      handleDisplayAuthControlButtons(true);
    }
  };

  const handleImageLayout = ({ nativeEvent: { layout } }) =>
    setMainImageHeight(layout.height);
  const handleMobileTabsLayout = ({ nativeEvent: { layout } }) =>
    setMobileTabsY(layout.y);
  const handlePositionListLayout = ({ nativeEvent: { layout } }) =>
    setPositionListY(layout.y);

  const handlePositionListScroll = async ({ nativeEvent }) =>
    setIsPosListAtBottom(isScrollCloseToBottom(nativeEvent));

  const handlePageScroll = async ({ nativeEvent }) => {
    setIsScrolledPastPositions(
      nativeEvent.contentOffset.y >
        mainImageHeight + mobileTabsY + TOP_NAV_HEIGHT - 10
    ); // top nav height minus whitespace
    setIsPageAtBottom(isScrollCloseToBottom(nativeEvent));
  };
  //#endregion handlers

  const MemoisedMap = useMemo(() => {
    const center =
      locationsShownOnMap && locationsShownOnMap.length === 1
        ? {
            lat: parseFloat(locationsShownOnMap[0].latitude),
            lng: parseFloat(locationsShownOnMap[0].longitude)
          }
        : NASHVILLE_COORDS;

    return (
      <FOHMap
        Map={() => (
          <GoogleMap
            center={center}
            //eslint-disable-next-line
            children={
              <>
                {locationsShownOnMap.length > 1 && (
                  <MapControls
                    locations={locationsShownOnMap}
                    openLocations={[]}
                  />
                )}

                <FOHMarkerCluster Cluster={MarkerClusterer}>
                  {locationsShownOnMap.map(location => (
                    <FOHMapMarker
                      Marker={Marker}
                      formattedAddress={location.formattedAddress}
                      iconStyle="careerPage"
                      id={location.id}
                      isHoverable={false}
                      key={location.id}
                      latitude={location.latitude}
                      longitude={location.longitude}
                      name={location.name}
                      onImagePress={() => null}
                      setOpenPin={() => null}
                    />
                  ))}
                </FOHMarkerCluster>
              </>
            }
            mapContainerStyle={{
              aspectRatio: isMobile ? '4 / 3' : '1/1',
              height: 350
            }}
            options={{ disableDefaultUI: true, styles: gmapStyle }}
            zoom={14}
          />
        )}
        key="careerPageMap"
        style={{ position: 'relative' }}
      />
    );
  }, [isMobile, locationsShownOnMap]);

  // memoise to avoid flickering on state change
  const MemoisedAnswers = useMemo(
    () => (
      <FOHAnswersTable
        answers={answers}
        answersLabel={t('blockHeadersWorkingWithUs')}
        fontProperty={fontProperty}
        style={{ marginBottom: SPACING_SCALE.huge, ...fontProperty }}
        useRegularLabel
        vertical={isMobile}
      />
    ),
    [answers, isMobile, fontProperty, t]
  );

  useEffect(() => {
    const isLoadingFinished = !isPosQueryLoading && !isFetchMoreLoading;
    const fetchMore = async _isMobile => {
      _isMobile ? setIsPageAtBottom(false) : setIsPosListAtBottom(false);
      await fetchMorePositions(positionsQuery, queryVariables, POSITIONS_BATCH);
      setIsFetchMoreLoading(false);
    };

    if (
      isLoadingFinished &&
      ((!isMobile && isPosListAtBottom) || (isMobile && isPageAtBottom))
    ) {
      setIsFetchMoreLoading(true);
      fetchMore(isMobile);
    }
  }, [
    isFetchMoreLoading,
    isMobile,
    isPageAtBottom,
    isPosListAtBottom,
    isPosQueryLoading,
    positionsData,
    positionsQuery,
    queryVariables
  ]);

  return (
    <>
      <FOHScrollView onScroll={handlePageScroll}>
        <FOHCareerPageLayout
          aside={
            <>
              <FOHCareerPageMainImage
                isVideo={currentMainImage?.getUploadFileExtension === '.mp4'}
                navigation={mainImageArray.length > 1}
                onLayout={handleImageLayout}
                paginationLogic={paginationLogic}
                src={
                  currentMainImage?.uploadFile ||
                  mainImageArray?.[0]?.uploadFile ||
                  null
                }
                testID="FOHCareerPageMainImage"
                toggleLightBox={() => toggleModal(MODAL_TYPE.lightBox)}
              />

              {!isMobile && (
                <FOHCareerPageLocations
                  Map={MemoisedMap}
                  fontProperty={fontProperty}
                  locations={locationsShownOnMap}
                  style={{ marginTop: SPACING_SCALE.massive }}
                  translations={{
                    header: t('ourLocationsHeader'),
                    noLocations: t('noLocationSelected_alt')
                  }}
                />
              )}
            </>
          }
          background={
            <CareerPageScreenBackground
              color={`#${coverColor || DEFAULT_BACKGROUND_COLOR}`}
              image={cover || (!coverColor && DEFAULT_COVER)}
              testID="CareerPageScreenBackground"
            />
          }
          innerRef={wrapperRef}
          isEmployer={isEmployer}
          onScrollHandler={handlePageScroll}
          theme={{
            brandColor: `#${brandColor || DEFAULT_BRAND_COLOR}`,
            fontColor: `#${fontColor || DEFAULT_FONT_COLOR}`,
            fontFamily: fontFamily,
            pageBackground: `#${brandPageColor || DEFAULT_BACKGROUND_COLOR}`,
            tableBackground: `#${brandTableColor || DEFAULT_TABLE_COLOR}`
          }}
        >
          <FOHCareerPageDetails
            address={formattedAddress}
            followerCount={positions?.length}
            followerCountLabel={formatFollowerCountLabel({
              followerCount: positions?.length,
              t
            })}
            fontProperty={fontProperty}
            onJobsClick={handleJobsClick}
            onMessageClick={handleMessageClick}
            onShareClick={handleShareClick}
            pageTitle={title}
            tags={tags}
            translations={{
              jobsButtonLabel: t('showJobs'),
              messageButtonLabel: t('messageUs'),
              shareButtonLabel: t('shareProfile'),
              shareButtonLabelMobile: t('shareProfileMobile')
            }}
          />

          <FOHCareerPageTabs
            fontProperty={fontProperty}
            onPressTab={route => setSelectedTab(route)}
            overViewWordCount={description?.split(' ').length}
            overviewContent={
              description ? (
                <div dangerouslySetInnerHTML={{ __html: description }} />
              ) : null
            }
            selectedTab={selectedTab}
            style={{ marginBottom: SPACING_SCALE.huge }}
            tabs={desktopTabs}
            translations={{
              blockHeaderOverview: t('blockHeadersOverview'),
              blockHeaderTeam: t('blockHeadersTeam'),
              collapseDescriptionLabel: t('collapseDescription'),
              expandDescriptionLabel: t('expandDescription')
            }}
          />

          {MemoisedAnswers}

          {isMobile && (
            <MobileTabUI
              locationsShownOnMap={locationsShownOnMap}
              onLayout={handleMobileTabsLayout}
              positions={positions}
              selectedMobileTab={selectedMobileTab}
              setSelectedMobileTab={setSelectedMobileTab}
              t={t}
            />
          )}

          {isMobile && (
            <FOHView
              style={{
                display:
                  selectedMobileTab === MOBILE_TAB_ROUTES.locations
                    ? 'flex'
                    : 'none',
                marginTop: SPACING_SCALE.huge
              }}
            >
              <FOHCareerPageLocations
                Map={MemoisedMap}
                fontProperty={fontProperty}
                locations={locationsShownOnMap}
                style={{
                  marginBottom: SPACING_SCALE.huge,
                  marginLeft: isMobile ? -SPACING_SCALE.huge : 0,
                  marginRight: isMobile ? -SPACING_SCALE.huge : 0,
                  width: isMobile ? '100vw' : '100%'
                }}
                translations={{
                  header: t('ourLocationsHeader'),
                  noLocations: t('noLocationSelected_alt')
                }}
              />

              <FOHBackToTopButton
                label={t('common:backToTop')}
                refObject={wrapperRef}
                style={{ marginBottom: SPACING_SCALE.huge }}
              />
            </FOHView>
          )}

          <FOHView
            onLayout={handlePositionListLayout}
            style={{
              display: !isMobile
                ? 'flex'
                : selectedMobileTab === MOBILE_TAB_ROUTES.positions
                ? 'flex'
                : 'none',
              width: '100%'
            }}
          >
            {isPosQueryLoading ? (
              <CustomSizeLogoLoader
                height={`${DESKTOP_POSITION_TILE_HEIGHT * POSITIONS_BATCH}px`}
                style={{
                  backgroundColor: FOHColors.LIGHT_GRAY,
                  borderRadius: '8px'
                }}
                width="100%"
              />
            ) : (
              <FOHCareerPagePositions
                fontProperty={fontProperty}
                isLoading={isFetchMoreLoading}
                listOnScrollHandler={handlePositionListScroll}
                maxHeightOnDesktop={
                  DESKTOP_POSITION_TILE_HEIGHT * DESKTOP_LIST_LEN
                }
                onApplyPress={positionSlug =>
                  isEmployer || !isCandidate
                    ? handleDisplayAuthControlButtons(true)
                    : applyToPosition(positionSlug)
                }
                onPositionDetailsPress={positionSlug => {
                  window.open(
                    `${window.location.origin}/jobs/${positionSlug}`,
                    '_blank'
                  );
                }}
                options={adaptLocationsOptions(locationArray)}
                positions={positions}
                searchPhrase={searchPhrase}
                selectedLocations={selectedLocationsRowIds}
                setSearchPhrase={setSearchPhrase}
                setSelectedLocations={setSelectedLocationsRowIds}
                totalPositionsCount={positionsData?.positions?.totalCount}
                translations={{
                  applyPositionButtonLabel: t('applyPositionButtonLabel'),
                  header: t('openPositionsHeader'),
                  noLocationSelected: t('noLocationSelected'),
                  noOpenPositionsFound: t('noOpenPositionsFound'),
                  positionDetailButtonLabel: t('positionDetailButtonLabel'),
                  postedAtLabel: `${t('CandidateHomeFeature:posted')}: `
                }}
              />
            )}
          </FOHView>

          {isBackToTopPositionsVisible && (
            <FOHView
              style={{
                alignItems: 'center',
                backgroundColor: 'white',
                bottom: 0,
                paddingBottom: SPACING_SCALE.huge,
                paddingTop: SPACING_SCALE.regular,
                position: 'sticky',
                width: '100%',
                zIndex: 1
              }}
            >
              <FOHBackToTopButton
                label={t('common:backToTop')}
                refObject={wrapperRef}
              />
            </FOHView>
          )}
        </FOHCareerPageLayout>
      </FOHScrollView>

      {/* MODALS */}
      <CareerPageScreenModals
        currentMainImage={currentMainImage}
        dropdownOptions={dropdownOptions}
        mainImageSrc={currentMainImage?.uploadFile}
        paginationLogic={paginationLogic}
        refetchMe={refetchMe}
      />
      <FOHView style={{ display: 'none' }}>
        <FontPicker
          activeFontFamily={fontFamily}
          apiKey={envVariables.GOOGLE_API_KEY}
          onChange={() => {}}
        />
      </FOHView>
    </>
  );
};
