import React from 'react';

import { FOHHeaderText, FOHView } from '../../ingredients';

export interface FOHResultCountProps {
  countLabel: string;
  count: string;
}

export const FOHResultCount = (props: FOHResultCountProps) => (
  <FOHView style={{ flexDirection: 'row' }}>
    <FOHHeaderText
      style={{
        fontSize: 20,
        textAlign: 'left'
      }}
    >
      {props.countLabel}
    </FOHHeaderText>
    <FOHHeaderText
      style={{
        fontSize: 20,
        textAlign: 'left',
        color: '#878787'
      }}
    >
      {` ${props.count}`}
    </FOHHeaderText>
  </FOHView>
);
