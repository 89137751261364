import React from 'react';

import { defaultTo, prop } from 'ramda';

import arrow from '../../../images/arrow_drop_down.svg';
import dropdown from '../../../images/dropdown_triangle.svg';

import { FOHImage } from '../../ingredients';

interface FADropDownArrowProps {
  large?: boolean;
  style?: any;
}

export const FOHDropDownArrow = (props: FADropDownArrowProps) =>
  props.large ? (
    <FOHImage
      source={{ uri: dropdown }}
      style={{ width: 12, height: 8, ...defaultTo({}, prop('style', props)) }}
    />
  ) : (
    <FOHImage
      source={{ uri: arrow }}
      style={{ width: 24, height: 24, ...defaultTo({}, prop('style', props)) }}
    />
  );
