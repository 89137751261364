import React from 'react';

import { View, Image, Text } from 'react-native';
import styled from 'styled-components/native';

import {
  FOHFonts,
  FOHColors,
  FOHButton,
  FOHSpace,
  FOHSpaceSmall,
  FOHSmallDetailLabel,
  FOHHeaderH1,
  FullWidthCard,
  CardScreen
} from '../../ingredients';

export interface FOHNoMorePositionsProps {
  mobile?: boolean;
  title: string;
  subTitle: string;
  submitBtnLabel: string;
  onButtonPress: () => void;
  onRightImgURI: string;
}

export const FOHNoMorePositions = (props: FOHNoMorePositionsProps) => (
  <React.Fragment>
    {!props.mobile ? (
      <FOHNoMorePositionsWebCard>
        <FOHLeftPortion>
          <FOHSpaceSmall />
          <HeaderTitle>{props?.title}</HeaderTitle>
          <FOHSpace />
          <FOHDetailText>{props?.subTitle}</FOHDetailText>
          <FOHSpace />
          <FOHButton
            title={props?.submitBtnLabel}
            onPress={props.onButtonPress}
            color={FOHColors.WHITE}
            textColor={FOHColors.BLACK}
            borderColor={FOHColors.SILVER_POLISH}
          />
          <FOHSpaceSmall />
        </FOHLeftPortion>
        <FOHRightPortion>
          <FOHImage
            resizeMode={'contain'}
            source={{
              uri: props?.onRightImgURI
            }}
          />
        </FOHRightPortion>
      </FOHNoMorePositionsWebCard>
    ) : (
      <FOHNoMorePositionsMobileCard>
        <FOHNoMorePositionsMobileContainer>
          <FOHMobileViewLeftView>
            <HeaderTitle
              style={{
                fontSize: 16,
                lineHeight: 24,
                fontStyle: 'normal',
                fontWeight: 'normal'
              }}
            >
              {props?.title}
            </HeaderTitle>
            <FOHSpaceSmall />
            <FOHSmallDetailLabel
              style={{
                fontSize: 14,
                lineHeight: 20,
                paddingRight: 20
              }}
            >
              {props?.subTitle}
            </FOHSmallDetailLabel>
          </FOHMobileViewLeftView>
          <FOHMobileViewRightView>
            <FOHImage
              resizeMode={'contain'}
              source={{
                uri: props?.onRightImgURI
              }}
              style={{ width: 85, height: 115 }}
            />
          </FOHMobileViewRightView>
        </FOHNoMorePositionsMobileContainer>
        <FOHSpace />
        <FOHButton
          title={props?.submitBtnLabel}
          onPress={props.onButtonPress}
          color={FOHColors.WHITE}
          textColor={FOHColors.BLACK}
          borderColor={FOHColors.SILVER_POLISH}
        />
        <FOHSpaceSmall />
      </FOHNoMorePositionsMobileCard>
    )}
  </React.Fragment>
);

const FOHNoMorePositionsWebCard = styled(FullWidthCard)`
  padding: 12px;
  align-items: center;
  flex-direction: row;
`;

const FOHLeftPortion = styled(View)`
  width: 70%;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 20px;
  padding-right: 20px;
`;

const FOHRightPortion = styled(View)`
  width: 30%;
  align-items: flex-end;
`;

const HeaderTitle = styled(FOHHeaderH1)`
  text-align: left;
  font-style: normal;
  font-weight: normal;
  color: #000000;
`;

const FOHDetailText = styled(Text)`
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  color: #4f4f4f;
`;

const FOHImage = styled(Image)`
  width: 187px;
  height: 200px;
  object-fit: cover;
`;

const FOHNoMorePositionsMobileCard = styled(CardScreen)`
  padding: 12px;
  align-items: center;
  margin-top: 10px;
  border-radius: 6px;
  margin: 6px;
  min-width: 290px;
  border: 1px solid #e8e8e8;
  background-color: #ffffff;
`;

const FOHNoMorePositionsMobileContainer = styled(View)`
  align-items: center;
  flex-direction: row;
`;

const FOHMobileViewLeftView = styled(View)`
  width: 70%;
  align-items: flex-start;
  justify-content: flex-start;
`;

const FOHMobileViewRightView = styled(View)`
  width: 30%;
  align-items: flex-end;
`;
