import React from 'react';

import { Navigate } from 'react-router-dom';

import { FOHCircleLoader, useIsRestricted } from '../../components';

import { UseIsRestrictedArgs } from './useIsRestricted';

interface RestrictedViewProps extends UseIsRestrictedArgs {
  children: React.ReactNode;
  redirectUrl?: string;
}

const RestrictedView = (props: RestrictedViewProps) => {
  const { allowedGroups, allowedRoles, redirectUrl } = props;
  const { isRestricted, loading } = useIsRestricted({
    allowedRoles,
    allowedGroups
  });

  if (loading) {
    return <FOHCircleLoader />;
  }

  if (!loading && isRestricted && redirectUrl) {
    return <Navigate to={redirectUrl} />;
  }

  if (isRestricted) return null;

  return <>{props.children}</>;
};

export default RestrictedView;
