import React from 'react';

import { TouchableOpacity, View } from 'react-native';
import { defaultTo } from 'ramda';

import {
  FOHColors,
  FOHFonts,
  FOHGrayTag,
  FOHLabel,
  FOHSpaceSmall
} from '../../ingredients';

export interface InfoCellProps {
  title: string;
  total: number | string;
  new?: number;
  icon?: any;
  isDividerNext?: boolean;
  onPress?: () => void;
  textColor?: string;
  backgroundColor?: string;
  newLabel: string;
  testID?: string;
}

export const FOHInfoCell = (props: InfoCellProps) => {
  return (
    <TouchableOpacity
      style={{
        paddingVertical: 13,
        paddingHorizontal: 16,
        borderRadius: 4,
        backgroundColor: props.backgroundColor ?? '#F5F5F5',
        marginHorizontal: 8,
        minWidth: '124px',
        minHeight: '72px'
      }}
      onPress={props.onPress}
      testID={props.testID}
    >
      <FOHLabel
        style={{
          color: props.textColor ?? '#4F4F4F',
          fontFamily: FOHFonts.MEDIUM,
          fontSize: 12,
          lineHeight: 18
        }}
      >
        {props.title}
      </FOHLabel>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'flex-end',
          marginTop: 4
        }}
      >
        {!!props.icon && props.icon}
        {!!props.icon && <FOHSpaceSmall />}
        <FOHLabel
          style={{
            fontWeight: '600',
            fontSize: 16,
            lineHeight: 24,
            color: props.textColor ?? '#171717',
            fontFamily: FOHFonts.BOLD
          }}
        >
          {props.total}
        </FOHLabel>
        {!!props.new && defaultTo(0, props.new) > 0 && (
          <FOHGrayTag
            label={`${props.new} ${props.newLabel}`}
            style={{
              marginLeft: 8,
              shadowColor: props.textColor,
              shadowOpacity: 0.4,
              shadowOffset: { width: 1, height: 1 },
              shadowRadius: 4,
              backgroundColor: FOHColors.WHITE,
              color: props.textColor ?? '#171717',
              fontWeight: 600,
              fontFamily: FOHFonts.REGULAR
            }}
          />
        )}
      </View>
    </TouchableOpacity>
  );
};
