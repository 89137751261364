import React, { useState, useEffect } from 'react';

import { compose, path, defaultTo, prop, length } from 'ramda';
import { withTranslation } from 'react-i18next';
import { useDebounce } from 'use-hooks';

import {
  getStartEndMonthYear,
  transformWorkHistoryToVariables
} from '../../api/transformers/candidates';
import {
  getYears,
  unwrapEdgesAt,
  withIsMobile,
  handleMutation,
  formatFollowerCountLabel
} from '../../utils';
import { MONTHS_DROPDOWN } from '../../constants';
import { useSearchLocationsFeedQuery } from '../../graphql/generated';
import { useNavigation } from '../../utils/navigation';
import { FOHWorkHistoryScreen } from '../../components';

import { withReactiveModal } from '../Navigation';

import { useWorkHistoryMutation } from './useWorkHistoryMutation';
import { useCandidateProfileMutation } from './useCandidateProfileMutation';

const getWorkHistoryFromId = (workHistory, id) =>
  prop(
    0,
    workHistory.filter(work => id === String(work.id) || id === work.id)
  );

const WorkHistoryScreen = props => {
  const { t } = props;
  const { location: navLocation, goBack } = useNavigation();

  const {
    mutateCandidateWorkHistory,
    myWorkHistory,
    deleteCandidateWorkHistory
  } = useWorkHistoryMutation();

  const id =
    props.id ||
    prop(1, defaultTo('', path(['search'], navLocation)).split('id='));

  const currentWorkHistory = myWorkHistory || [];
  const [startMonth, setStartMonth] = useState();
  const [endMonth, setEndMonth] = useState();
  const [endYear, setEndYear] = useState();
  const [startYear, setStartYear] = useState();
  const [employerName, setEmployerName] = useState();
  const [present, setPresent] = useState();
  const [position, setPosition] = useState();
  const [responsibilities, setResponsibilities] = useState('');

  const [selectedLocation, setSelectedLocation] = useState(undefined);
  const [suggestedLocation, setSuggestedLocation] = useState(undefined);
  const debouncedSearch = useDebounce(employerName, 200);

  const { me } = useCandidateProfileMutation();

  const locationsQuery = useSearchLocationsFeedQuery({
    skip: !employerName,
    variables: {
      search: debouncedSearch,
      first: 5,
      zipCode: prop(['candidateProfile', 'addressPostalCode'], me)
    }
  });

  const locations = unwrapEdgesAt(
    ['data', 'locations', 'edges'],
    locationsQuery
  );

  useEffect(() => {
    if (myWorkHistory && id) {
      const workHistoryFromId = getWorkHistoryFromId(currentWorkHistory, id);
      const formattedWorkHistory = id
        ? {
            ...workHistoryFromId,
            ...getStartEndMonthYear({ ...workHistoryFromId })
          }
        : {};

      setStartMonth(prop('startMonth', formattedWorkHistory));
      setEndMonth(prop('endMonth', formattedWorkHistory));
      setEndYear(prop('endYear', formattedWorkHistory));
      setStartYear(prop('startYear', formattedWorkHistory));
      setEmployerName(prop('employerName', formattedWorkHistory));
      setPresent(prop('present', formattedWorkHistory));
      setPosition(prop('position', formattedWorkHistory));
      setResponsibilities(prop('responsibilities', formattedWorkHistory));

      if (path(['location', 'id'], workHistoryFromId)) {
        setSelectedLocation({
          value: path(['location', 'id'], workHistoryFromId),
          id: path(['location', 'id'], workHistoryFromId),
          label: path(['location', 'name'], workHistoryFromId),
          image: path(['location', 'image'], workHistoryFromId),
          sublabel: path(['location', 'formattedAddress'], workHistoryFromId)
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myWorkHistory, props.open, id]);

  useEffect(() => {
    const workHistoryFromId = getWorkHistoryFromId(currentWorkHistory, id);
    if (
      length(locations) > 0 &&
      !prop('id', selectedLocation) &&
      !path(['location', 'id'], workHistoryFromId) &&
      !locationsQuery.loading
    ) {
      setSuggestedLocation({
        ...prop(0, locations),
        summary: '',
        followerCountLabel: formatFollowerCountLabel({
          followerCount: prop('followerCount', prop(0, locations)),
          t
        })
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWorkHistory, id, locationsQuery.loading, debouncedSearch]);

  useEffect(() => {
    if (!id) {
      setStartMonth('');
      setEndMonth('');
      setEndYear('');
      setStartYear('');
      setEmployerName('');
      setPresent(false);
      setPosition('');
      setResponsibilities('');
    }
  }, [id, props.open]);

  return (
    <>
      <FOHWorkHistoryScreen
        key={`${props.open}${id}`}
        paddingTop={props.isModal ? '0px' : '60px'}
        yearPlaceholder={t('yearPlaceholder')}
        positionPlaceholder={t('positionPlaceholder')}
        submitLabel={id ? t('updateButton') : t('addButton')}
        title={id ? t('updateEmployment') : t('addEmployment')}
        cancelLabel={t('cancel')}
        currentLabel={t('currentlyWorkHere')}
        employerNamePlaceholder={t('employerNamePlaceholder')}
        monthPlaceholder={t('monthPlaceholder')}
        startLabel={t('startDate')}
        endLabel={t('endDate')}
        endMonth={present ? '' : endMonth}
        setEndMonth={setEndMonth}
        months={MONTHS_DROPDOWN.map(month => ({
          label: t(month.label),
          value: month.value
        }))}
        endYear={present ? '' : endYear}
        setEndYear={setEndYear}
        years={getYears()}
        startMonth={startMonth}
        startYear={startYear}
        setStartYear={setStartYear}
        setStartMonth={setStartMonth}
        setResponsibilities={setResponsibilities}
        responsibilities={responsibilities}
        responsibilitiesPlaceholder={t('responsibilitiesPlaceholder')}
        handleSubmit={async () => {
          // new
          const variables = transformWorkHistoryToVariables({
            employerName,
            startMonth,
            startYear,
            endMonth,
            endYear,
            present,
            position,
            responsibilities
          });

          const [, errs] = await handleMutation(
            mutateCandidateWorkHistory({
              variables: id
                ? {
                    ...variables,
                    employerName:
                      prop('label', selectedLocation) || employerName,
                    location: prop('id', selectedLocation),
                    id
                  }
                : {
                    ...variables,
                    employerName:
                      prop('label', selectedLocation) || employerName,
                    location: prop('id', selectedLocation)
                  }
            })
          );
          if (errs) return;
          return props.isModal ? props.close() : goBack();
        }}
        loading={locationsQuery.loading}
        employerName={employerName}
        setEmployerName={setEmployerName}
        current={present}
        setCurrent={setPresent}
        position={position}
        setPosition={setPosition}
        largeHeader={!props.isMobile && props.isModal}
        disabledSubmit={
          !employerName ||
          !position ||
          !startMonth ||
          !startYear ||
          (!endMonth && !present) ||
          (!endYear && !present) ||
          (Date.parse(new Date(endYear, endMonth, 1)) <
            Date.parse(new Date(startYear, startMonth, 1)) &&
            !present)
        }
        removeLabel={t('removeWorkHistory')}
        remove={
          id
            ? async () => {
                await handleMutation(deleteCandidateWorkHistory({ id }));
                props.isModal ? props.close() : goBack();
              }
            : undefined
        }
        goBack={
          props.isModal
            ? undefined
            : () => {
                goBack();
              }
        }
        onChangeSearch={setEmployerName}
        closeLabel={t('close')}
        searchDetailLabel={t('clickHereToSelect')}
        searchValue={employerName}
        searchPlaceholder={t('search')}
        selectFreeTextLabel={t('add')}
        clearSearch={() => {
          setEmployerName('');
          setSelectedLocation(undefined);
        }}
        selectedLocation={selectedLocation}
        selectLocation={item => {
          item && setEmployerName(item.label);
          setSelectedLocation(item);
          if (prop('id', item)) {
            return setSuggestedLocation(undefined);
          }
        }}
        locations={locations.map(loc => ({
          id: prop('id', loc),
          label: prop('name', loc),
          value: prop('id', loc),
          image: prop('image', loc),
          sublabel: prop('formattedAddress', loc)
        }))}
        suggestionLabel={t('didYouMean')}
        clearSuggestionLabel={t('noDismiss')}
        replaceLabel={t('yesReplace')}
        clearSuggestedLocation={() => setSuggestedLocation(undefined)}
        suggestedLocation={suggestedLocation}
      />
    </>
  );
};

export const WorkHistoryModal = compose(
  withReactiveModal,
  withIsMobile,
  withTranslation('WorkHistoryFeature')
)(WorkHistoryScreen);

export default compose(
  withIsMobile,
  withTranslation('WorkHistoryFeature')
)(WorkHistoryScreen);
