import React, { FunctionComponent } from 'react';

import goldstar from '../../../images/gold_star.svg';

import { FOHImage } from '../../ingredients';
import { FOHIconProps } from '../../typings';

export const FOHGoldStar: FunctionComponent<FOHIconProps> = ({ style }) => (
  <FOHImage
    source={{ uri: goldstar }}
    style={[{ width: 24, height: 24 }, style]}
  />
);
