const changeHeightWidth = (height, maxHeight, width, maxWidth) => {
  if (width > maxWidth) {
    height = Math.round((height * maxWidth) / width);
    width = maxWidth;
  }
  if (height > maxHeight) {
    width = Math.round((width * maxHeight) / height);
    height = maxHeight;
  }
  return { height, width };
};

const resizeImage = (image, maxWidth, maxHeight, compressFormat = 'jpeg') => {
  var canvas = document.createElement('canvas');

  var width = image.width;
  var height = image.height;

  var newHeightWidth = changeHeightWidth(height, maxHeight, width, maxWidth);

  canvas.width = newHeightWidth.width;
  canvas.height = newHeightWidth.height;
  width = newHeightWidth.width;
  height = newHeightWidth.height;

  var ctx = canvas.getContext('2d');
  ctx.fillStyle = 'rgba(0, 0, 0, 0)';
  ctx.fillRect(0, 0, width, height);

  if (ctx.imageSmoothingEnabled && ctx.imageSmoothingQuality) {
    ctx.imageSmoothingQuality = 'high';
  }

  ctx.drawImage(image, 0, 0, width, height);
  return canvas.toDataURL(`image/${compressFormat}`, 1);
};

const b64toByteArrays = b64Data => {
  var sliceSize = 512;

  var byteCharacters = atob(
    b64Data.toString().replace(/^data:image\/(png|jpeg|jpg|webp);base64,/, '')
  );
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }
  return byteArrays;
};

const b64toFile = (b64Data, fileName, contentType) => {
  const byteArrays = b64toByteArrays(b64Data, contentType);
  const file = new File(byteArrays, fileName, {
    type: contentType,
    lastModified: new Date()
  });
  return file;
};

export const imageFileResizer = (
  file,
  maxWidth,
  maxHeight,
  responseUriFunc
) => {
  const reader = new FileReader();
  if (file) {
    if (file.type && !file.type.includes('image')) {
      throw Error('File Is NOT Image!');
    } else {
      reader.readAsDataURL(file);
      reader.onload = () => {
        const compressFormat = file.type.split('/')[1];
        var image = new Image();
        image.src = reader.result;
        image.onload = function () {
          var resizedDataUrl = resizeImage(
            image,
            maxWidth,
            maxHeight,
            compressFormat
          );
          const contentType = `image/${compressFormat}`;
          let fileName = file.name;
          let fileNameWithoutFormat = fileName
            .toString()
            .replace(/(png|jpeg|jpg|webp)$/i, '');
          let newFileName = fileNameWithoutFormat.concat(
            compressFormat.toString()
          );
          const newFile = b64toFile(resizedDataUrl, newFileName, contentType);
          responseUriFunc(newFile);
        };
      };
    }
  }
};
