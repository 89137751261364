import React, { useState, useCallback } from 'react';

import { defaultTo, length, prop, remove } from 'ramda';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';

import {
  FOHLabel,
  FOHLabelBold,
  FOHSpace,
  FOHSpaceSmall,
  FOHDropZoneBox,
  FOHView,
  FOHUploadIcon,
  FOHErrorLabel,
  FOHLinkOut,
  FOHSmallDetailLabel,
  FOHFileCell
} from '../../components';

export const WebDropZone = props => {
  const { t } = useTranslation('InterviewMessagingFeature');

  const { files, setFiles } = props;

  const [fileProgress, setFileProgress] = useState({});
  const updateProgress = useCallback(
    (file, total) =>
      setFileProgress({
        ...fileProgress,
        [file.name]: total
      }),
    [setFileProgress, fileProgress]
  );

  const onDrop = useCallback(
    acceptedFiles => {
      // Do something with the files

      acceptedFiles.forEach(file => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onprogress = ({ total, loaded }) => {
          updateProgress(file, (total / loaded) * 100);
        };
        reader.onload = () => {
          // Do whatever you want with the file contents
        };
      });
      setFiles([...files, ...acceptedFiles]);
    },
    [files, setFiles, updateProgress]
  );

  const { getRootProps, getInputProps, isDragActive, open, fileRejections } =
    useDropzone({
      accept: [
        'image/png',
        'image/jpg',
        'image/jpeg',
        'application/msword',
        'application/pdf'
      ].join(','),
      onDrop
    });
  const dropZoneProps = getRootProps();

  const errors = defaultTo([], prop('errors', fileRejections[0]));

  return (
    <>
      <div {...dropZoneProps}>
        <FOHDropZoneBox>
          <input
            {...getInputProps()}
            accept=".pdf,.jpg,.doc,.docx,.png,.jpeg"
          />
          <FOHView
            style={{
              justifyContent: 'space-between',
              flexDirection: 'row',
              flexWrap: 'wrap'
            }}
          >
            <FOHView
              style={{ flexDirection: 'row', flexWrap: 'wrap', flex: 1 }}
            >
              {isDragActive ? (
                <FOHLabelBold>Drop Files Here</FOHLabelBold>
              ) : (
                <>
                  <FOHUploadIcon />
                  <FOHSpaceSmall />
                  <FOHLabel>{t('dropFilesHere')}</FOHLabel>
                  <FOHSpaceSmall />
                  <FOHLinkOut href="" onPress={open} noIcon={true}>
                    {t('browse')}
                  </FOHLinkOut>
                </>
              )}
            </FOHView>
            <FOHSpace />
            <FOHView>
              <FOHSmallDetailLabel>
                Supports .png, .jpg, .doc .pdf
              </FOHSmallDetailLabel>
            </FOHView>
          </FOHView>
        </FOHDropZoneBox>
        <FOHView
          style={{
            justifyContent: 'center',
            alignItems: 'flex-start',
            wordBreak: 'break-word'
          }}
        >
          {length(errors) > 0 && (
            <FOHErrorLabel style={{ paddingBottom: '20px' }}>
              • {errors.map(error => prop('message', error)).join('\n\n')}
            </FOHErrorLabel>
          )}
        </FOHView>
      </div>
      {files.map((file, i) => (
        <FOHFileCell
          key={i}
          fileName={file.name}
          size={`${file.size}kb`}
          progress={prop(prop('name', file), fileProgress) || 0}
          delete={() => {
            const remainingFiles = remove(i, 1, files);
            setFiles(remainingFiles);
          }}
        />
      ))}
    </>
  );
};
