import React from 'react';

import {
  FOHHeaderText,
  FOHScrollView,
  FOHView,
  FixedTopContainerUnderNavigation,
  Screen
} from '../../ingredients';
import {
  FOHGoBack,
  FOHAccountFormProps,
  FOHAccountForm,
  FOHPhotoInputProps
} from '../../recipes';

export interface FOHAccountInformationScreenProps
  extends FOHPhotoInputProps,
    FOHAccountFormProps {
  title?: string;
  goBack?: () => void;
  largeHeader?: boolean;
  error?: any;
}

export const FOHAccountInformationScreen = (
  props: FOHAccountInformationScreenProps
) => (
  <Screen>
    <FOHView
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 100
      }}
    >
      {!!props.title && (
        <FixedTopContainerUnderNavigation>
          <FOHView
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: 300
            }}
          >
            <FOHView style={{ zIndex: 10 }}>
              {props.goBack && <FOHGoBack goBack={props.goBack} fixed={true} />}
            </FOHView>

            <FOHHeaderText style={{ fontSize: props.largeHeader ? 30 : 20 }}>
              {props.title}
            </FOHHeaderText>

            <FOHView />
          </FOHView>
        </FixedTopContainerUnderNavigation>
      )}
      <FOHScrollView>
        <FOHAccountForm {...props} />
      </FOHScrollView>
    </FOHView>
  </Screen>
);
