import React from 'react';

import error from '../../../images/error.svg';

import { FOHImage, FOHColors } from '../../ingredients';

export const FOHErrorIcon = (props: any) => (
  <FOHImage
    source={{ uri: error }}
    style={
      props && !!props.style
        ? { width: 24, height: 24, tintColor: FOHColors.WHITE, ...props.style }
        : { width: 24, height: 24, tintColor: FOHColors.WHITE }
    }
  />
);
