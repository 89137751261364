import React from 'react';

import { useTranslation } from 'react-i18next';
import { defaultTo, path } from 'ramda';

import {
  FOHLabel,
  FOHColors,
  FOHBannerType,
  FOHSpaceSmall,
  FOHSpaceLarge,
  FOHSpace,
  FullWidthCard,
  FOHView,
  FOHImage,
  FOHButton,
  FOHBlueSuccessIcon,
  FOHErrorIcon,
  FOHXIcon,
  FOHDivider,
  FOHDetailText,
  FOHAccountConnected
} from '../../components';
import indeed_logo from '../../images/indeed_logo.svg';
import {
  GetCompaniesDocument,
  useDeleteIndeedTokenMutation,
  IndeedCompanyType,
  GetMeDocument
} from '../../graphql/generated';
import { handleMutation, toLocaleDateTime, useIsMobile } from '../../utils';

import { useGetMeData } from '../SignIn';

import { useGetCompanies } from './useGetCompanies';

interface IntegrationIndeedProps {
  onPressAddAccount: VoidFunction;
  setBanner: React.Dispatch<
    React.SetStateAction<{
      bannerType: keyof typeof FOHBannerType;
      bannerMessage: string;
    }>
  >;
  setGoToIndeedModalActive: (value: boolean) => void;
  isBillingConfirmed: boolean;
}

export const ConnectedButton = (props: { connectedLabel: string }) => (
  <FOHView
    style={{
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: 175,
      width: 175,
      height: 40,
      backgroundColor: FOHColors.PRIMARY_TEAL_10,
      borderRadius: 4
    }}
    testID="ConnectedButton"
  >
    <FOHBlueSuccessIcon style={{ tintColor: FOHColors.PRIMARY_TEAL_300 }} />
    <FOHSpaceSmall />
    <FOHLabel
      style={{
        color: FOHColors.PRIMARY_TEAL_300,
        fontWeight: 700
      }}
    >
      {props.connectedLabel}
    </FOHLabel>
  </FOHView>
);

const IntegrationIndeed = (props: IntegrationIndeedProps) => {
  const { setBanner, onPressAddAccount, isBillingConfirmed } = props;
  const { t } = useTranslation('Integrations');

  const { isMobile } = useIsMobile();

  const [deleteIndeedTokenMutation] = useDeleteIndeedTokenMutation();

  const { data: me } = useGetMeData();

  const integrations: string[] = defaultTo([], path(['integrations'], me));

  const isIndeedConnected = integrations.includes('indeed');

  const { data } = useGetCompanies();

  const companies = defaultTo(
    [],
    path(['indeedCompanies'], data)
  ) as Array<IndeedCompanyType>;

  const handleDisconnect = async (ID: string) => {
    const [response, error] = await handleMutation(
      deleteIndeedTokenMutation({
        variables: {
          id: ID
        },
        refetchQueries: [
          {
            query: GetCompaniesDocument,
            variables: {}
          },
          {
            query: GetMeDocument,
            variables: {}
          }
        ]
      })
    );

    if (error || !response) {
      setBanner({
        bannerType:
          FOHBannerType.DANGER as unknown as keyof typeof FOHBannerType,
        bannerMessage: error.message
      });
    } else {
      props.setGoToIndeedModalActive(true);
    }
  };

  return (
    <FOHView
      style={{
        width: '100%'
      }}
      testID="IntegrationIndeed"
    >
      <FOHView
        style={{
          paddingTop: isMobile ? 14 : 28,
          borderRadius: 16
        }}
      >
        <FullWidthCard
          shadow
          noBorder
          style={{
            paddingHorizontal: isMobile ? 14 : 28,
            paddingTop: 28
          }}
        >
          <FOHView
            style={{
              flexDirection: 'row',
              alignItems: isMobile ? 'flex-start' : 'center',
              justifyContent: 'space-between',
              flex: 1,
              flexWrap: 'wrap'
            }}
          >
            <FOHView
              style={{
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: 'flex-start',
                alignItems: isMobile ? 'flex-start' : 'center',
                flex: isMobile ? 'unset' : 3,
                maxWidth: isMobile ? '100%' : 'unset'
              }}
            >
              <FOHImage
                source={indeed_logo}
                style={{
                  height: 29,
                  width: 116
                }}
              />
              {isMobile ? <FOHSpace /> : <FOHSpaceLarge />}
              <FOHView
                style={{
                  wordWrap: 'break-word',
                  flex: 1
                }}
              >
                <FOHDetailText
                  style={{
                    fontSize: 14,
                    lineHeight: 20,
                    textAlign: isMobile ? 'justify' : 'left',
                    opacity: 1
                  }}
                >
                  {t('indeedValueProp')}
                </FOHDetailText>
              </FOHView>
            </FOHView>
            <FOHView
              style={{
                flex: isMobile ? 'unset' : 1.5,
                alignItems: 'flex-end',
                width: isMobile ? '100%' : 'unset',
                marginTop: isMobile ? 15 : 'unset'
              }}
            >
              <FOHView style={{ width: 175, maxWidth: 175 }}>
                {isIndeedConnected ? (
                  <ConnectedButton connectedLabel={t('connected')} />
                ) : (
                  <FOHButton
                    title={t('addNewAccount')}
                    icon={() =>
                      FOHXIcon({
                        style: {
                          transform: [{ rotate: '135deg' }],
                          width: 15,
                          height: 15,
                          tintColor: FOHColors.WHITE
                        }
                      })
                    }
                    onPress={onPressAddAccount}
                    selected={true}
                    style={{ height: 40, maxWidth: 175 }}
                    // @ts-ignore font-weight 700 as type number
                    textStyle={{ color: FOHColors.WHITE, fontWeight: 700 }}
                  />
                )}
              </FOHView>
            </FOHView>
          </FOHView>
          {isMobile ? <FOHSpace /> : <FOHSpaceLarge />}
          {isIndeedConnected ? (
            <>
              <FOHDivider color={FOHColors.BORDER_GRAY} />
              {isMobile ? <FOHSpaceSmall /> : <FOHSpace />}
              <FOHView
                style={{
                  width: '100%'
                }}
              >
                <FOHView
                  style={{
                    paddingVertical: 5
                  }}
                >
                  <FOHLabel
                    style={{
                      fontSize: 18,
                      lineHeight: 24,
                      fontWeight: '600',
                      color: FOHColors.GRAYSCALE_900
                    }}
                  >
                    {t('yourConnectedAccounts')}
                  </FOHLabel>
                </FOHView>

                <FOHView
                  style={{
                    paddingVertical: isMobile ? 11 : 22,
                    paddingHorizontal: isMobile ? 0 : 10
                  }}
                >
                  {companies!.map((company, index) => {
                    const createdAt = toLocaleDateTime(company.createdAt);

                    const isDisconnectButtonDisabled =
                      company.hasActiveCampaign || false;

                    return (
                      <React.Fragment key={index}>
                        <FOHAccountConnected
                          accountName={company.indeedCompany}
                          connectedBy={`${t('connected')}: ${createdAt}`}
                          disconnectLabel={t('disconnect')}
                          onPressDisconnect={() => handleDisconnect(company.id)}
                          disconnectButtonProps={{
                            disabled: isDisconnectButtonDisabled,
                            enableHover: isDisconnectButtonDisabled,
                            helpText: t('disabledDisConnectHelpText')
                          }}
                        />
                        <FOHSpace />
                      </React.Fragment>
                    );
                  })}
                  {!isBillingConfirmed ? (
                    <FOHView
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        backgroundColor: '#FBDCE0',
                        padding: 5,
                        borderRadius: 6
                      }}
                    >
                      <FOHErrorIcon style={{ tintColor: FOHColors.RED_200 }} />
                      <FOHSpaceSmall />
                      <FOHLabel
                        style={{ color: FOHColors.RED_300, fontSize: 14 }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: t('indeedBillingWarning')
                          }}
                        />
                      </FOHLabel>
                    </FOHView>
                  ) : null}
                </FOHView>
              </FOHView>
            </>
          ) : null}
        </FullWidthCard>
      </FOHView>
    </FOHView>
  );
};
export default IntegrationIndeed;
