import React from 'react';

import { prop } from 'ramda';
import styled from 'styled-components/native';

import {
  FOHView,
  FOHColors,
  FOHSpaceSmall,
  FOHLookingStatusIcon,
  FOHNewShiftStatusIcon,
  FOHNotLookingStatusIcon,
  FOHOpenToOffersStatusIcon,
  FOHHeaderH4
} from '../../ingredients';

export enum FOHStatusType {
  ACTIVELY_LOOKING = 'ACTIVELY_LOOKING',
  OPEN_TO_OFFERS = 'OPEN_TO_OFFERS',
  LOOKING_FOR_SHIFT = 'LOOKING_FOR_SHIFT',
  CLOSED_TO_OFFERS = 'CLOSED_TO_OFFERS'
}

export interface FOHStatusProps {
  statusType: FOHStatusType;
  statusText: string;
  small?: boolean;
  hasBackground?: boolean;
  labelStyle?: any;
}

export const FOHStatusTypes = [
  FOHStatusType.ACTIVELY_LOOKING,
  FOHStatusType.OPEN_TO_OFFERS,
  FOHStatusType.LOOKING_FOR_SHIFT,
  FOHStatusType.CLOSED_TO_OFFERS
];

export const FOHStatus = (props: FOHStatusProps) => {
  const statusType = prop('statusType', props);
  const validStatus = FOHStatusTypes.includes(statusType);
  const StatusIcon = validStatus
    ? {
        ACTIVELY_LOOKING: FOHLookingStatusIcon,
        OPEN_TO_OFFERS: FOHOpenToOffersStatusIcon,
        LOOKING_FOR_SHIFT: FOHNewShiftStatusIcon,
        CLOSED_TO_OFFERS: FOHNotLookingStatusIcon
      }[statusType]
    : FOHLookingStatusIcon;

  const color = validStatus
    ? {
        ACTIVELY_LOOKING: '#38A56B',
        OPEN_TO_OFFERS: '#F5AD22',
        LOOKING_FOR_SHIFT: '#38A56B',
        CLOSED_TO_OFFERS: '#E5253C'
      }[statusType]
    : '#38A56B';

  const backgroundColor = validStatus
    ? {
        ACTIVELY_LOOKING: FOHColors.LIGHT_GREEN,
        OPEN_TO_OFFERS: FOHColors.LIGHT_GOLD,
        LOOKING_FOR_SHIFT: FOHColors.LIGHT_GREEN,
        CLOSED_TO_OFFERS: FOHColors.LIGHT_RED
      }[statusType]
    : '#38A56B';

  return (
    <FOHView style={{ flexDirection: 'row', alignItems: 'center' }}>
      {props.hasBackground ? (
        <FOHView
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            borderRadius: 100,
            paddingHorizontal: 8,
            paddingVertical: 4,
            backgroundColor: backgroundColor
          }}
        >
          <StatusIcon />
          <FOHSpaceSmall />
          {!!props.statusText && !props.small ? (
            <StatusText color={color} style={props?.labelStyle}>
              {props.statusText}
            </StatusText>
          ) : (
            <></>
          )}
        </FOHView>
      ) : (
        <>
          <StatusIcon />
          <FOHSpaceSmall />
          {!!props.statusText && !props.small ? (
            <StatusText color={color} style={props?.labelStyle}>
              {props.statusText}
            </StatusText>
          ) : (
            <></>
          )}
        </>
      )}
    </FOHView>
  );
};

const StatusText: any = styled(FOHHeaderH4)`
  color: ${(props: any) => props.color};
  padding-top: 2px;
  line-height: 20px;
  font-style: normal;
  font-weight: normal;
`;
