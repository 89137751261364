import React from 'react';

import bluecurrentstep from '../../../images/bluecurrentstep.svg';

import { FOHImage } from '../../ingredients';

export const FOHBlueCurrentStepIcon = (props: any) => (
  <FOHImage
    source={{ uri: bluecurrentstep }}
    style={{ width: 25, height: 25, ...props.style }}
  />
);
