import React, { useRef } from 'react';

import { View, TouchableWithoutFeedback } from 'react-native';
import { useHover } from 'react-native-web-hooks';
import { animated, SpringConfig, useSprings } from 'react-spring';
import styled from 'styled-components/native';

import { FOHLabelBold, FOHLabel, FOHSpaceSmall } from '../../ingredients';

export interface FOHSwitchProps {
  onValueChange: (val: boolean) => void;
  value: boolean;
  label?: string;
  onColor?: string;
  offColor?: string;
  inverseLabel?: boolean;
  labelCmp?: any;
  width?: string;
  onLabel?: string;
  offLabel?: string;
  isDisabled?: boolean;
}

export const FOHSwitch = (props: FOHSwitchProps) => {
  const on = props.value;
  const items = [
    {
      left: on ? 27 : 3
    }
  ];
  const config: SpringConfig = { tension: 500, friction: 30 };

  const springs = useSprings(
    items.length,
    items.map(item => ({
      left: item.left,
      config
    }))
  );

  const Label = props.labelCmp || FOHLabelBold;
  const ref = useRef(null);
  const hoverRef = useHover(ref);

  return (
    <View
      style={{
        width: props?.width,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start'
      }}
    >
      {props.label && props.inverseLabel ? (
        <>
          <Label>{props.label}</Label>
          <FOHSpaceSmall />
        </>
      ) : (
        <></>
      )}
      <TouchableWithoutFeedback
        ref={ref}
        testID={`Switch-${props.label}`}
        onPress={() => {
          if (!props.isDisabled) props.onValueChange(!on);
        }}
      >
        <SwitchPill
          style={{
            borderRadius: 18,
            height: 24,
            width: 48,
            paddingLeft: 3,
            paddingRight: 3,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: props.isDisabled
              ? '#98A2B3'
              : on
              ? hoverRef
                ? props.onColor || '#14687A'
                : props.onColor || '#009DC1'
              : hoverRef
              ? props.offColor || '#98A2B3'
              : props.offColor || '#D0D5DD'
          }}
        >
          {on ? (
            <FOHLabel
              style={{
                fontSize: 12,
                color: 'white',
                paddingLeft: 3,
                marginRight: 'auto',
                textTransform: 'uppercase'
              }}
            >
              {props.onLabel || ''}
            </FOHLabel>
          ) : (
            <></>
          )}
          <AnimatedCircle
            style={{
              left: springs?.[0]?.left,
              width: 18,
              height: 18,
              backgroundColor: props.isDisabled ? '#D0D5DD' : '#FFFFFF',
              borderRadius: '50%',
              position: 'absolute'
            }}
          />
          {!on ? (
            <FOHLabel
              style={{
                fontSize: 12,
                color: 'white',
                marginLeft: 'auto',
                textTransform: 'uppercase'
              }}
            >
              {props.offLabel || ''}
            </FOHLabel>
          ) : (
            <></>
          )}
        </SwitchPill>
      </TouchableWithoutFeedback>
      {props.label && !props.inverseLabel ? (
        <>
          <FOHSpaceSmall />
          <Label>{props.label}</Label>
        </>
      ) : (
        <></>
      )}
    </View>
  );
};

const AnimatedCircle = animated(View);

const SwitchPill = styled(View)`
  flex-direction: row;
`;
