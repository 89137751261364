import React, { FunctionComponent } from 'react';

import eyeicon from '../../../images/eyeicon.svg';

import { FOHImage } from '../../ingredients';
import { FOHIconProps } from '../../typings';

export const FOHEyeIcon: FunctionComponent<FOHIconProps> = props => (
  <FOHImage
    source={{ uri: eyeicon }}
    style={[{ width: 16, height: 16 }, props?.style]}
  />
);
