import React from 'react';

import { FOHView } from '../../ingredients';

import { CampaignItem } from './styled';
import { DropDownText } from './parts';

export interface FOHCampaignHeaderCellProps {
  translations: Record<string, string>;
  selectedTab?: string;
}

export const FOHCampaignHeaderCell = (props: FOHCampaignHeaderCellProps) => {
  const { translations, selectedTab } = props;

  return (
    <FOHView
      style={{
        paddingLeft: 16,
        paddingRight: 16
      }}
    >
      <FOHView
        style={{
          flexDirection: 'row',
          justifyContent:
            selectedTab === 'DraftCampaigns' ? 'flex-start' : undefined,
          alignItems: 'center'
        }}
      >
        <CampaignItem
          style={{ flex: selectedTab === 'DraftCampaigns' ? 2 : 6, margin: 0 }}
        >
          <DropDownText text={translations.campaignLabel} />
        </CampaignItem>
        <CampaignItem>
          {selectedTab === 'DraftCampaigns' ? (
            <DropDownText text={translations.positionLabel} />
          ) : (
            <DropDownText text={translations.clickLabel} />
          )}
        </CampaignItem>
        <CampaignItem>
          {selectedTab === 'DraftCampaigns' ? (
            <DropDownText text={translations.scheduleLabel} />
          ) : (
            <DropDownText text={translations.applyLabel} />
          )}
        </CampaignItem>
        {selectedTab !== 'DraftCampaigns' && (
          <CampaignItem>
            <DropDownText text={translations.avgCPALabel} />
          </CampaignItem>
        )}
        {selectedTab !== 'DraftCampaigns' && (
          <CampaignItem style={{ flex: 4 }}>
            <DropDownText text={translations.progressLabel} />
          </CampaignItem>
        )}
        <CampaignItem>
          <DropDownText text={translations.budgetLabel} />
        </CampaignItem>
        <CampaignItem style={{ flex: 1 }}>
          <DropDownText text={translations.jobBoardLabel} />
        </CampaignItem>
        <CampaignItem style={{ flex: 1 }}></CampaignItem>
      </FOHView>
    </FOHView>
  );
};
