// react-native-web scroll view is close to the bottom.
import { prop } from 'ramda';
export const isScrollCloseToBottom = nativeElement => {
  if (
    !prop('layoutMeasurement', nativeElement) ||
    !prop('contentOffset', nativeElement) ||
    !prop('contentSize', nativeElement)
  ) {
    return false;
  }
  const { layoutMeasurement, contentOffset, contentSize } = nativeElement;
  const paddingToBottom = 50;

  return (
    layoutMeasurement.height + contentOffset.y >=
    contentSize.height - paddingToBottom
  );
};
