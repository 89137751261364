import styled from 'styled-components/native';
import { Text } from 'react-native';

import { FOHColors } from '../colors/FOHColors';

import { FOHFonts } from './FOHFonts';

export const FOHContrastText = styled(Text)`
  font-family: ${FOHFonts.MEDIUM};
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 140%;
  align-items: center;
  color: ${FOHColors.WHITE};
`;

export const FOHContrastDetailText = styled(Text)`
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  align-items: center;
  color: ${FOHColors.WHITE};
`;
