import {create} from 'zustand';
import { devtools } from 'zustand/middleware';

export const MODAL_TYPE = {
  apply: 'apply',
  lightBox: 'lightBox',
  message: 'message',
  register: 'register',
  signIn: 'signIn'
};

const modalStore = set => ({
  appliedPositionSlug: '',
  applyToPosition: slug =>
    set(state => ({
      appliedPositionSlug: slug,
      modalState: { ...state.modalState, [MODAL_TYPE.apply]: true }
    })),
  closeModal: modalType =>
    set(state => ({ modalState: { ...state.modalState, [modalType]: false } })),
  handleMessageSent: () =>
    set(state => ({
      messageContent: '',
      messageReceipientId: null,
      modalState: { ...state.modalState, [MODAL_TYPE.message]: true }
    })),
  handleSignup: () =>
    set(state => ({
      modalState: {
        ...state.modalState,
        [MODAL_TYPE.register]: true,
        [MODAL_TYPE.signIn]: false
      }
    })),
  messageContent: '',
  messageReceipientId: null,
  modalState: {
    [MODAL_TYPE.apply]: false,
    [MODAL_TYPE.lightBox]: false,
    [MODAL_TYPE.message]: false,
    [MODAL_TYPE.register]: false,
    [MODAL_TYPE.signIn]: false
  },
  openModal: type =>
    set(state => ({ modalState: { ...state.modalState, [type]: true } })),
  setMessageContent: content => set(_state => ({ messageContent: content })),
  setMessageReceipientId: id => set(_state => ({ messageReceipientId: id })),
  toggleModal: type =>
    set(state => ({
      modalState: { ...state.modalState, [type]: !state[type] }
    }))
});

export const useModalStore =
  process.env.NODE_ENV === 'development'
    ? create(devtools(modalStore))
    : create(modalStore);
