import React, { useRef } from 'react';

import { useHover } from 'react-native-web-hooks';

import {
  FOHMoreInfoIcon,
  FOHToolTip,
  FOHToolTipType,
  FOHView
} from '../../ingredients';

export interface FOHMoreInfoBadgeProps {
  toolTipText: string;
  offset?: number;
  rightOffset?: number;
  toolTipType?: FOHToolTipType;
}

export const FOHMoreInfoBadge = (props: FOHMoreInfoBadgeProps) => {
  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);
  return (
    <FOHView ref={hoverRef} style={{ alignItems: 'center', zIndex: 99999 }}>
      <FOHToolTip
        open={isHovered}
        rightOffset={props.rightOffset}
        type={props.toolTipType || FOHToolTipType.BOTTOM_CENTER}
        helpText={props.toolTipText}
        style={{
          width: 250,
          top: props.offset || 100
        }}
      />
      <FOHMoreInfoIcon />
    </FOHView>
  );
};
