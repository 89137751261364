import React from 'react';

import { currencyFormat } from '../../utils';
import { FOHLabel, FOHView } from '../../ingredients';

import { FOHProgressBar } from './FOHProgressBar';

export interface FOHProgressCampaignBarProps {
  totalBudget: number;
  spentBudget?: number;
  progressLabel?: string;
}

export const FOHProgressCampaignBar = (props: FOHProgressCampaignBarProps) => {
  const { totalBudget, spentBudget, progressLabel } = props;

  return (
    <FOHView>
      <FOHProgressBar
        completed={
          spentBudget && totalBudget !== 0
            ? (spentBudget / totalBudget) * 100
            : 0
        }
      />
      <FOHLabel style={{ marginTop: 12 }}>
        <>{`${progressLabel}: `}</>
        <b>
          {spentBudget && spentBudget !== 0 ? currencyFormat(spentBudget) : '_'}
        </b>
      </FOHLabel>
    </FOHView>
  );
};
