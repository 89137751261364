import React from 'react';

import phone_ic from '../../../images/phone_ic.svg';

import { FOHImage } from '../../ingredients';

export interface FOHPhoneIconProps {
  style?: any;
}

export const FOHPhoneIcon = (props: FOHPhoneIconProps) => (
  <FOHImage
    source={{ uri: phone_ic }}
    style={
      props && !!props.style
        ? { width: 24, height: 24, ...props.style }
        : { width: 24, height: 24 }
    }
  />
);
