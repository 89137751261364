import React from 'react';

import { path } from 'ramda';

import { FOHView, Screen } from '../../ingredients';
import { FOHSideMenuTable, FOHSideMenuTableProps } from '../../recipes';

export interface FOHWideAccountMenuScreenProps extends FOHSideMenuTableProps {
  components?: Array<FOHComponentsType>;
  children?: any;
}

interface FOHComponentsType {
  component: any;
  route: string;
}

export const FOHWideAccountMenuScreen = (
  props: FOHWideAccountMenuScreenProps
) => (
  <Screen>
    <FOHView
      style={{
        width: '100%'
      }}
    >
      <FOHView style={{ zIndex: 100 }}>
        <FOHSideMenuTable
          tableTitle={props.tableTitle}
          navigateTo={props.navigateTo}
          activeScreen={props.activeScreen}
          navigation={props.navigation}
        />
      </FOHView>
      {props?.components && (
        <FOHView style={{ marginLeft: 288, zIndex: 99 }}>
          {path(
            [0, 'component'],
            props.components.filter(
              cmpRoute => cmpRoute.route === props.activeScreen
            )
          )}
        </FOHView>
      )}
      {props?.children && (
        <FOHView style={{ marginLeft: 288, zIndex: 100 }}>
          {props.children}
        </FOHView>
      )}
    </FOHView>
  </Screen>
);
