import styled from 'styled-components/native';

import {
  FOHColors,
  FOHSectionLabel,
  FOHSmallDetailLabel,
  FOHView,
  SPACING_SCALE
} from '../../../ingredients';
import { TOP_NAV_HEIGHT } from '../../../recipes';

export const Wrapper = styled(FOHView)`
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding-top: ${TOP_NAV_HEIGHT};
`;

export const Container = styled(FOHView)`
  align-items: center;
  justify-content: center;
  max-width: calc(100% - 60px);
`;

export const Heading = styled(FOHSectionLabel)`
  font-size: 24;
  line-height: 28;
  max-width: 80%;
  text-align: center;
  margin-bottom: ${SPACING_SCALE.larger}px;
`;

export const Message = styled(FOHSmallDetailLabel)`
  font-size: 16;
  line-height: 24;
  text-align: center;
  color: ${FOHColors.BLACK};
  margin-bottom: ${SPACING_SCALE.huge}px;
`;
