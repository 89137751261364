import React from 'react';

import { View, TouchableWithoutFeedback, TouchableOpacity } from 'react-native';
import { animated, SpringConfig, useSprings } from 'react-spring';
import styled from 'styled-components/native';
import { defaultTo } from 'ramda';

import { FOHXIcon, FOHDivider, FOHSectionLabel } from '../../ingredients';

interface FOHRightDrawerProps {
  style?: any;
  close?: () => void;
  children?: React.ReactNode;
  open?: boolean;
  title?: string;
  top?: string;
  openWidth?: number;
}

export const FOHRightDrawer = (props: FOHRightDrawerProps) => {
  const z = 9999;
  const items = [
    {
      width: props.openWidth || 320,
      right: props.open ? 0 : -defaultTo(320, props.openWidth)
    }
  ];
  const config: SpringConfig = { tension: 400, friction: 30 };

  const springs = useSprings(
    items.length,
    items.map(item => ({
      width: item.width,
      right: item.right,
      config
    }))
  );

  return (
    <>
      {props.open && (
        <TouchableWithoutFeedback onPress={props.close}>
          <GrayBlur style={{ zIndex: z - 1 }} />
        </TouchableWithoutFeedback>
      )}
      <AnimatedMobileView
        style={{
          ...props.style,
          position: 'fixed',
          justifyContent: 'flex-end',
          height: '100%',
          top: props.top || 0,
          flexDirection: 'row',
          maxHeight: '100vh',
          backgroundColor: '#ffffff',
          borderColor: '#eeeeee',
          borderLeftWidth: 1,
          width: springs?.[0]?.width,
          right: springs?.[0]?.right,
          zIndex: z
        }}
      >
        <>
          <View
            style={{
              alignItems: 'flex-start',
              paddingTop: 18,
              zIndex: z,
              width: '100%'
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%'
              }}
            >
              <View style={{ flex: 1, paddingLeft: 12 }}>
                <FOHSectionLabel>{props.title}</FOHSectionLabel>
              </View>
              <View style={{ flex: 1 }} />
              <View style={{ justifyContent: 'flex-end', paddingBottom: 12 }}>
                <TouchableOpacity
                  style={{ flex: 1, paddingRight: 12 }}
                  onPress={props.close}
                >
                  <FOHXIcon large={true} />
                </TouchableOpacity>
              </View>
            </View>
            <FOHDivider />
            {props.children}
          </View>
        </>
      </AnimatedMobileView>
    </>
  );
};

const GrayBlur = styled(View)`
  background-color: #000000;
  width: 100vw;
  height: 100vh;
  opacity: 0.64;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
`;

const AnimatedMobileView = animated(View);
