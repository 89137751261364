import React from 'react';

import { useTranslation } from 'react-i18next';

import {
  FOHHighlightWordsLabel,
  FOHColors,
  FOHSpaceSmall,
  FOHSpace,
  FullWidthCard,
  FOHView,
  FOHTouchableOpacity,
  FOHDetailText,
  FOHHeaderH2,
  FOHHeaderH3
} from '../../components';
import videoScreeningQuestionIcon from '../../images/video_screening_question.svg';
import screeningQuestionIcon from '../../images/screening_ques.svg';
import screeningChoiceIcon from '../../images/screening_choice.svg';
import {
  FEED,
  SCREENING_QUESTIONS,
  VIDEO_SCREENING_QUESTIONS
} from '../../constants';
import { useNavigation } from '../../utils/navigation';

import { withReactiveModal } from '../Navigation/withReactiveModal';

interface EmployerScreeningChoiceModalProps {
  handle: string;
  pathname?: string;
}

export const EmployerScreeningChoiceModal = (
  props: EmployerScreeningChoiceModalProps
) => {
  const { t } = useTranslation('EmployerQuestionnaireModal');
  const { navigateTo } = useNavigation();

  return (
    <FOHView
      style={{ width: '100%', maxWidth: 600 }}
      testID={'screening-choice-modal'}
    >
      <img
        src={screeningChoiceIcon}
        style={{ height: 64 }}
        alt={'screening-choice-icon'}
      />
      <FOHHighlightWordsLabel
        labelCmp={FOHHeaderH2}
        emphasizedWords={[]}
        highlightColor={FOHColors.RED}
        boldLabelCmp={FOHHeaderH2}
      >
        {t('screeningChoiceHeaderLabel')}
      </FOHHighlightWordsLabel>
      <FOHDetailText style={{ alignSelf: 'center', paddingTop: 10 }}>
        {t('screeningChoiceHeaderSubLabel')}
      </FOHDetailText>
      <FOHSpace />
      <FOHView style={{ flexDirection: 'row', alignItems: 'space-between' }}>
        <FullWidthCard
          style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            padding: 16
          }}
        >
          <FOHTouchableOpacity
            testID={'sendScreeningQuestion'}
            onPress={() => {
              props.pathname
                ? navigateTo(`${props.pathname}${SCREENING_QUESTIONS}`)
                : navigateTo(`${FEED}${SCREENING_QUESTIONS}`);
            }}
            style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
          >
            <img
              src={screeningQuestionIcon}
              style={{ height: 100, width: 100 }}
              alt={'screening-question-icon'}
            />
            <FOHHeaderH3>
              {t('ChatFeature:sendScreeningQuestionsLabel')}
            </FOHHeaderH3>
            <FOHDetailText>{t('sendScreeningQuestionSubLabel')}</FOHDetailText>
            <FOHSpaceSmall />
          </FOHTouchableOpacity>
        </FullWidthCard>
        <FOHSpace />
        <FullWidthCard
          style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            padding: 16
          }}
        >
          <FOHTouchableOpacity
            testID={'sendVideoScreeningQuestion'}
            onPress={() => {
              props.pathname
                ? navigateTo(`${props.pathname}${VIDEO_SCREENING_QUESTIONS}`)
                : navigateTo(`${FEED}${VIDEO_SCREENING_QUESTIONS}`);
            }}
            style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
          >
            <img
              src={videoScreeningQuestionIcon}
              style={{ height: 100, width: 100 }}
              alt={'video-screening-question-icon'}
            />
            <FOHHeaderH3>
              {t('ChatFeature:sendVideoScreeningLabel')}
            </FOHHeaderH3>
            <FOHDetailText>{t('sendVideoScreeningSubLabel')}</FOHDetailText>
            <FOHSpaceSmall />
          </FOHTouchableOpacity>
        </FullWidthCard>
      </FOHView>
    </FOHView>
  );
};

const ScreeningChoiceModal = withReactiveModal(EmployerScreeningChoiceModal);

export default ScreeningChoiceModal;
