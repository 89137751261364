import React from 'react';

import more_info from '../../../images/more_info_icon.svg';

import { FOHImage } from '../../ingredients';

export const FOHMoreInfoIcon = (props: any) => (
  <FOHImage
    source={{ uri: more_info }}
    style={{ width: 16, height: 16, ...props.style }}
  />
);
