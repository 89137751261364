import { defaultTo, prop, path } from 'ramda';

export const unwrapEdgesWithoutNode = edges =>
  defaultTo([], edges).map(node => ({ ...node }));

export const unwrapEdges = edges =>
  defaultTo([], edges).map(node => ({ ...prop('node', node) }));

export const unwrapEdgesAt = (pathToEdges = [], query) =>
  defaultTo([], path(pathToEdges, query)).map(node => ({
    ...prop('node', node)
  }));
