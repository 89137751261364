import React, { useState, useMemo } from 'react';

import { defaultTo, path, prop } from 'ramda';
import { useTranslation } from 'react-i18next';

import { useIsMobile, toDate, handleMutation } from '../../utils';
import { RESTAURANT_JOBS, getAppBaseUrl } from '../../constants';
import {
  SearchPositionsDocument,
  useGetJobPositionQuery,
  useGetMyPhotoQuery,
  useMutatePositionApplicationMutation
} from '../../graphql/generated';
import { useNavigation } from '../../utils/navigation';
import { FOHApplicationMessageModal } from '../../components';

import { ProfileCompletionBlock } from '../CandidateProfile/ProfileCompletionBlock';
import { usePositionTypes } from '../CandidateProfile/usePositionTypes';
import { useGetMeData } from '../SignIn';

import { CompletedApplicationModal } from './CompletedApplicationModal';

export const JobApplicationModals = props => {
  const { isModalOpen, closeModal, slug, utmSource } = props;

  const { navigateTo } = useNavigation();

  const { isMobile } = useIsMobile();
  const { t } = useTranslation('JobApplicationsFeature');

  const [openCompleteModal, setOpenCompleteModal] = useState(false);
  const [sendingApplyRequest, setSendingApplyRequest] = useState(false);

  const [applyToPosition] = useMutatePositionApplicationMutation();

  const [message, setMessage] = useState('');

  const { data: me } = useGetMeData();
  const myPhotoQuery = useGetMyPhotoQuery({});

  const positionQuery = useGetJobPositionQuery({
    skip: !slug,
    variables: {
      slug
    }
  });

  const { getStringForPositionType } = usePositionTypes();

  const position = useMemo(
    () => path(['data', 'position'], positionQuery) ?? null,
    [positionQuery]
  );
  const location = useMemo(
    () => prop('location', position) ?? null,
    [position]
  );

  const candidateZip = useMemo(
    () => path(['candidateProfile', 'addressPostalCode'], me),
    [me]
  );

  const positionSearchZip =
    candidateZip ?? path(['location', 'addressPostalCode'], position);

  // TODO break out into hook like useRequestInterview to make code more testable
  const fulfillApplication = async (_position, _initialMessage) => {
    // users can only submit once
    if (!sendingApplyRequest) {
      setSendingApplyRequest(true);
      await handleMutation(
        applyToPosition({
          variables: {
            position: path(['id'], _position),
            platform: utmSource,
            initialMessage: _initialMessage
          },
          refetchQueries: [
            {
              query: SearchPositionsDocument,
              variables: {
                first: 10,
                after: '',
                hasApplied: true,
                hasInterview: false,
                searchTerms: [],
                sortBy: 'latest',
                zipCode: positionSearchZip
              }
            }
          ]
        })
      );
    }
    setOpenCompleteModal(true);
    setSendingApplyRequest(false);
  };

  const loadingDetails = positionQuery.loading;

  return (
    <>
      <FOHApplicationMessageModal
        yourProfileLabel={t('yourProfile')}
        yourProfileDetail={t('yourProfileDetail')}
        applyingToLabel={t('applyingTo')}
        profileCompletenessWidget={() => (
          <ProfileCompletionBlock {...props} readOnly={true} small={true} />
        )}
        profileCompletenessInfo={t('profileCompletenessInfo')}
        positionName={prop('positionName', position)}
        postedDateText={
          loadingDetails
            ? ''
            : `${t('posted')}: ${toDate(path(['posted'], position))}`
        }
        locationName={prop('name', location)}
        locationSlug={prop('slug', location)}
        onPressLocation={_slug =>
          navigateTo(`${RESTAURANT_JOBS}/${prop('slug', location)}`)
        }
        locationImage={prop('image', location)}
        locationHref={`${getAppBaseUrl}${RESTAURANT_JOBS}/${prop(
          'slug',
          location
        )}`}
        message={message}
        messagePlaceholder={t('messagePlaceholder')}
        setMessage={setMessage}
        applicationMessageTitle={t('yourIntroduction')}
        applicationMessageDetail={t('yourMessageDetail')}
        submitLabel={sendingApplyRequest ? t('applying') : t('proceedAndApply')}
        disableSubmit={sendingApplyRequest}
        onPressSubmit={async () => {
          await fulfillApplication(position, message);
          closeModal();
        }}
        // props.error.message
        error={null}
        mobile={isMobile}
        dialog={!isMobile}
        close={closeModal}
        open={isModalOpen && !loadingDetails}
        positions={defaultTo([], path(['candidateProfile', 'positions'], me))
          .map(pos => getStringForPositionType(pos))
          .join(', ')}
        personalSummary={path(['candidateProfile', 'personalSummary'], me)}
        name={`${prop('firstName', me)} ${prop('lastName', me)}`}
        status={t(
          `common:candidateStatusType.${path(
            ['candidateProfile', 'status'],
            me
          )}`
        )}
        statusType={path(['candidateProfile', 'status'], me)}
        storyPhoto={path(['data', 'me', 'profileImage'], myPhotoQuery)}
      />

      <CompletedApplicationModal
        {...props}
        position={position}
        open={openCompleteModal}
        close={() => setOpenCompleteModal(false)}
      />
    </>
  );
};
