import React from 'react';

import {
  FOHView,
  FOHHeaderH3,
  FOHHeaderH4,
  FOHBlackTag,
  FOHLabelBold,
  FOHSpace,
  FOHRedBestMatchIcon
} from '../../ingredients';
import {
  FOHLabeledCheckbox,
  FOHBorderlessSelect,
  FOHSelectItem,
  FOHSelectItemProps
} from '../../recipes';

export interface FOHCandidateResultFilterProps {
  totalCount: string;
  countHeader: string;
  showBestMatch: boolean;
  showLabel: string;
  selected: boolean;
  setSelected: () => void;
  matchLabel?: string;
  sortByLabel: string;
  sortBy: string;
  setSortBy: (val: string) => string;
  sortByOptions: Array<FOHSelectItemProps>;
}

export const FOHCandidateResultFilter = (
  props: FOHCandidateResultFilterProps
) => {
  return (
    <FOHView
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: 5,
        flexWrap: 'wrap',
        zIndex: -1
      }}
    >
      <FOHView
        style={{
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          zIndex: -1,
          maxWidth: 200,
          width: '100%'
        }}
      >
        <FOHHeaderH3
          style={{
            marginTop: 2,
            marginRight: 8
          }}
        >
          {props.countHeader}
        </FOHHeaderH3>
        <FOHBlackTag label={props.totalCount} />
      </FOHView>
      <FOHView
        style={{
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
          zIndex: -1
        }}
      >
        {props.showBestMatch && (
          <FOHView
            style={{
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              marginRight: 25
            }}
          >
            <FOHLabeledCheckbox
              selected={props.selected}
              checkboxLabel={props.showLabel}
              setSelected={props.setSelected}
              style={{
                fontSize: 14
              }}
            />
            <FOHRedBestMatchIcon />
            <FOHHeaderH4
              style={{
                fontWeight: '700',
                paddingTop: 2
              }}
            >
              {props.matchLabel}
            </FOHHeaderH4>
          </FOHView>
        )}
        <FOHView
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            maxWidth: 220
          }}
        >
          <FOHLabelBold>{props.sortByLabel}:</FOHLabelBold>
          <FOHSpace />
          <FOHBorderlessSelect
            small={true}
            selectedValue={props.sortBy}
            onValueChange={props.setSortBy}
          >
            {props.sortByOptions.map(sortBy => (
              <FOHSelectItem {...sortBy} key={sortBy.label} />
            ))}
          </FOHBorderlessSelect>
        </FOHView>
      </FOHView>
    </FOHView>
  );
};
