import React from 'react';

import grayx from '../../../images/gray_clear.svg';

import { FOHImage } from '../../ingredients';

export const FOHGrayX = (props: any) => (
  <FOHImage
    source={{ uri: grayx }}
    style={{ width: 24, height: 24, ...props?.style }}
  />
);
