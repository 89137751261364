import React from 'react';

import { prop, defaultTo } from 'ramda';

import icon_uri from '../../../images/settings_gray.svg';

import { FOHImage } from '../../ingredients';

export const FOHSettingsGrayIcon = (props: any) => (
  <FOHImage
    source={{ uri: icon_uri }}
    style={{ width: 24, height: 24, ...defaultTo({}, prop('style', props)) }}
  />
);
