import React, {
  FunctionComponent,
  MutableRefObject,
  ReactElement
} from 'react';

import { useForm, Controller, UseFormReturn } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { USSTATES } from '../../constants';
import { getShortId } from '../../utils';
import { FOHView, SPACING_SCALE } from '../../ingredients';

import { FOHSelect, FOHSelectItem } from '../select/FOHSelect';

import {
  CareerPageLocationFormValues,
  schema,
  Fields
} from './FOHCareerPageLocationFormTypes';
import { Row, InputWrapper, Input } from './FOHCareerPageLocationFormStyles';
import { FOHForm } from './FOHForm';

export interface FOHCareerPageLocationFormProps {
  children?: any;
  defaultValues?: CareerPageLocationFormValues;
  errors?: CareerPageLocationFormValues;
  formId: string;
  onSubmit: () => void;
  placeholders: CareerPageLocationFormValues;
  useFormRef: MutableRefObject<UseFormReturn<CareerPageLocationFormValues> | null>;
}

export const FOHCareerPageLocationForm: FunctionComponent<
  FOHCareerPageLocationFormProps
> = ({
  children,
  placeholders,
  defaultValues = {
    [Fields.addressCity]: '',
    [Fields.addressPostalCode]: '',
    [Fields.addressState]: '',
    [Fields.addressStreet1]: '',
    [Fields.addressStreet2]: '',
    [Fields.slug]: '',
    [Fields.title]: ''
  },
  formId,
  onSubmit,
  useFormRef
}) => {
  const useFormValues = useForm<CareerPageLocationFormValues>({
    defaultValues,
    mode: 'onSubmit',
    resolver: zodResolver(schema)
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue
  } = useFormValues;

  if (useFormRef) {
    useFormRef.current = useFormValues;
  }

  return (
    <FOHView style={{ marginTop: SPACING_SCALE.larger, zIndex: 2 }}>
      <FOHForm handleSubmit={handleSubmit(onSubmit)} id={formId}>
        <Row>
          <InputWrapper>
            <Controller
              control={control}
              name={Fields.title}
              render={({ field: { onBlur, value } }): ReactElement => (
                <Input
                  error={errors.title?.message}
                  onBlur={onBlur}
                  onChangeText={(text: string) => setValue(Fields.title, text)}
                  placeholder={placeholders.title}
                  required
                  style={{}}
                  testID="page-title-input"
                  value={value}
                />
              )}
            />
          </InputWrapper>
          <InputWrapper style={{ marginRight: 0 }}>
            <Controller
              control={control}
              name={Fields.slug}
              render={({ field: { onBlur, value } }): ReactElement => (
                <Input
                  disabled
                  error={errors.slug?.message}
                  onBlur={onBlur}
                  onChangeText={(text: string) => setValue(Fields.slug, text)}
                  placeholder={placeholders.slug}
                  required
                  style={{}}
                  testID="page-slug-input"
                  value={value}
                />
              )}
            />
          </InputWrapper>
        </Row>
        <Row style={{ zIndex: 2 }}>
          <InputWrapper style={{ marginRight: 0, zIndex: 2 }}>
            {children}
          </InputWrapper>
        </Row>

        <Row>
          <InputWrapper>
            <Controller
              control={control}
              name={Fields.addressStreet1}
              render={({ field: { onBlur, value } }): ReactElement => (
                <Input
                  error={errors.addressStreet1?.message}
                  onBlur={onBlur}
                  onChangeText={(text: string) =>
                    setValue(Fields.addressStreet1, text)
                  }
                  placeholder={placeholders.addressStreet1}
                  testID="address1-input"
                  value={value}
                />
              )}
            />
          </InputWrapper>

          <InputWrapper style={{ marginRight: 0 }}>
            <Controller
              control={control}
              name={Fields.addressStreet2}
              render={({ field: { onBlur, value } }): ReactElement => (
                <Input
                  error={errors.addressStreet2?.message}
                  onBlur={onBlur}
                  onChangeText={(text: string) =>
                    setValue(Fields.addressStreet2, text)
                  }
                  placeholder={placeholders.addressStreet2}
                  testID="address2-input"
                  value={value}
                />
              )}
            />
          </InputWrapper>
        </Row>

        <Row>
          <InputWrapper>
            <Controller
              control={control}
              name={Fields.addressCity}
              render={({ field: { onBlur, value } }): ReactElement => (
                <Input
                  error={errors.addressCity?.message}
                  onBlur={onBlur}
                  onChangeText={(text: string) =>
                    setValue(Fields.addressCity, text)
                  }
                  placeholder={placeholders.addressCity}
                  testID="city-input"
                  value={value}
                />
              )}
            />
          </InputWrapper>

          <InputWrapper style={{ marginRight: 0 }}>
            <InputWrapper>
              <Controller
                control={control}
                name={Fields.addressPostalCode}
                render={({ field: { onBlur, value } }): ReactElement => (
                  <Input
                    error={errors.addressPostalCode?.message}
                    maxLength={5}
                    onBlur={onBlur}
                    onChangeText={(text: string) =>
                      setValue(Fields.addressPostalCode, text)
                    }
                    placeholder={placeholders.addressPostalCode}
                    testID="zip-code-input"
                    value={value}
                  />
                )}
              />
            </InputWrapper>

            <InputWrapper
              style={{ marginRight: 0 }}
              testID="FOHCareerPageLocationForm-state-picker"
            >
              <Controller
                control={control}
                name={Fields.addressState}
                render={({ field: { value } }): ReactElement => (
                  <FOHSelect
                    error={errors.addressState?.message}
                    onValueChange={(text: string) =>
                      setValue(Fields.addressState, text)
                    }
                    placeholder={placeholders.addressState}
                    selectedValue={value || placeholders.addressState}
                    style={{ paddingBottom: 0, paddingTop: 16 }}
                  >
                    {USSTATES.map(option => (
                      <FOHSelectItem
                        key={getShortId()}
                        label={option.abbreviation}
                        value={option.abbreviation}
                      />
                    ))}
                  </FOHSelect>
                )}
              />
            </InputWrapper>
          </InputWrapper>
        </Row>
      </FOHForm>
    </FOHView>
  );
};
