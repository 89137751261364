import React from 'react';

import {
  View,
  Text,
  ImageSourcePropType,
  TouchableOpacity
} from 'react-native';
import styled from 'styled-components/native';

import {
  FOHInverseButton,
  FOHStaticLoaderIcon,
  FOHUploadIcon,
  FOHAvatar,
  FullWidthCard
} from '../../ingredients';

import { FOHProfileImage } from '../navigation/FOHProfileImage';

type VoidHandler = () => void;

interface User {
  name: string;
  position?: string;
}

interface Localization {
  title: string;
  subTitle: string;
  uploadTitle: string;
  loadMoreTitle: string;
  avatarSelectTitle: string;
}

interface FOHPickAvatarFormProps {
  photo: ImageSourcePropType;
  user: User;
  pickPhoto: VoidHandler;
  defaultPhotos?: ImageSourcePropType[];
  setPhoto: (source: ImageSourcePropType) => void;
  onLoadMorePress?: VoidHandler;
  localization: Localization;
  isMobile: boolean;
}

export const FOHPickAvatarForm = (props: FOHPickAvatarFormProps) => {
  const { isMobile, localization } = props;

  const { title, subTitle, avatarSelectTitle, uploadTitle, loadMoreTitle } =
    localization;

  return (
    <ContainerView>
      <FullWidthCard
        style={{
          backgroundColor: '#fcfcfc',
          padding: 32,
          borderRadius: 24,
          maxWidth: 570,
          width: '100%'
        }}
      >
        <Text style={{ fontSize: 18, color: '#171717', fontWeight: 'bold' }}>
          {title}
        </Text>
        <Text style={{ color: '#878787', marginTop: 4 }}>{subTitle}</Text>
        <FullWidthCard
          style={{
            padding: 16,
            justifyContent: 'space-between',
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: isMobile ? 'flex-start' : 'center',
            backgroundColor: '#ffffff',
            marginTop: 16
          }}
          testID={'FOHPickAvatarForm-upload-image'}
        >
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            {props.photo ? (
              <FOHProfileImage
                style={{ width: 74, height: 73 }}
                photo={props.photo as string}
                key={props.photo as string}
              />
            ) : (
              <FOHAvatar style={{ width: 74, height: 73 }} />
            )}
            <View style={{ marginLeft: 16, maxWidth: 150 }}>
              <Text
                style={{ fontSize: 18, color: '#171717', fontWeight: 'bold' }}
              >
                {props.user.name}
              </Text>
              <Text style={{ maxWidth: 150, paddingBottom: 10 }}>
                {props.user.position}
              </Text>
            </View>
          </View>

          <FOHInverseButton
            style={{ height: 40, width: 163, marginLeft: 'auto' }}
            title={uploadTitle}
            onPress={props.pickPhoto}
            opaque={true}
            color="#000000"
            textColor="#ffffff"
            selected={true}
            icon={() =>
              FOHUploadIcon({
                style: {
                  tintColor: '#fff'
                }
              })
            }
          />
        </FullWidthCard>
        <Text style={{ fontSize: 18, color: '#171717', fontWeight: 'bold' }}>
          {avatarSelectTitle}
        </Text>
        <View
          style={{
            flexDirection: 'row',
            flexWrap: 'wrap'
          }}
        >
          {props.defaultPhotos?.map(photo => (
            <TouchableOpacity
              onPress={() => props.setPhoto(photo)}
              style={{
                marginRight: 6,
                marginTop: 6
              }}
              key={photo as string}
            >
              <GrayCircle>
                <FOHProfileImage
                  style={{
                    width: 74,
                    height: 73,
                    margin: 0
                  }}
                  highlight={photo === props.photo}
                  photo={photo as string}
                  key={photo as string}
                />
              </GrayCircle>
            </TouchableOpacity>
          ))}
        </View>
        {props.onLoadMorePress && (
          <FOHInverseButton
            style={{
              width: 210,
              margin: 'auto',
              marginTop: 16,
              borderRadius: 6,
              paddingHorizontal: 16,
              height: 40
            }}
            title={loadMoreTitle}
            onPress={props.onLoadMorePress}
            opaque={true}
            textColor="#000000"
            icon={() =>
              FOHStaticLoaderIcon({
                style: {
                  tintColor: '#000'
                }
              })
            }
          />
        )}
      </FullWidthCard>
    </ContainerView>
  );
};

const GrayCircle = styled(View)`
  border-radius: 50%;
  border-radius: 50%;
  border-color: #ddd;
  border-width: 2px;
  justify-content: center;
  align-items: center;
  width: 78px;
  height: 77px;
`;

const ContainerView = styled(View)`
  width: 100%;
  align-items: center;
`;
