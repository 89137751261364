import React from 'react';

import styled from 'styled-components/native';
import { View } from 'react-native';

import {
  FOHAvailableShiftsProps,
  FOHAvailabilityReadSection
} from '../sections/FOHAvailabilityReadSection';

export interface FOHAvailabilityReadTableProps {
  availability: Array<FOHAvailableShiftsProps>;
}

export const FOHAvailabilityReadTable = (
  props: FOHAvailabilityReadTableProps
) => (
  <TableContainer>
    {props.availability.map((availability, index) => (
      <FOHAvailabilityReadSection
        key={`availability-${index}`}
        shifts={availability.shifts}
        day={availability.day}
      />
    ))}
  </TableContainer>
);

const TableContainer = styled(View)`
  height: 100%;
  width: 100%;
`;
