import React from 'react';

import messageTemplate from '../../../images/message_template.svg';

import { FOHImage } from '../../ingredients';

export const FOHMessageTemplateIcon = (props: any) => (
  <FOHImage
    source={{ uri: messageTemplate }}
    style={
      props && !!props.style
        ? { width: 24, height: 24, ...props.style }
        : { width: 24, height: 24 }
    }
  />
);
