import React, { useState, useEffect } from 'react';

import { compose, length, path, prop } from 'ramda';
import { withTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  FOHAccountMenuScreen,
  FOHStatusType,
  FOHWideAccountMenuScreen
} from '../../components';
import { unwrapEdgesAt, withIsMobile } from '../../utils';
import {
  PASSWORD_RESET_PATH,
  CANDIDATE_NOTIFICATION_PREFERENCE_PATH,
  CANDIDATE_ADD_EMPLOYMENT_PATH,
  CANDIDATE_VIDEO_PATH,
  CANDIDATE_PERSONAL_SUMMARY_PATH,
  CANDIDATE_POSITION_PATH,
  CANDIDATE_SKILLS_PATH,
  CANDIDATE_RESTAURANT_STYLE_PATH,
  CANDIDATE_TOP_THREE_PATH,
  CANDIDATE_YEARS_EXPERIENCE_PATH,
  CANDIDATE_WORK_PREFERENCES_PATH,
  CANDIDATE_RESUME,
  CANDIDATE_PROFILE
} from '../../constants';
import {
  useGetMyPhotoQuery,
  useGetMyWorkHistoryQuery
} from '../../graphql/generated';
import { useNavigation } from '../../utils/navigation';

import { PasswordResetScreen } from '../Password';
import { getProfilePhoto } from '../Navigation/TopNav';
import { useGetMeData } from '../SignIn';

import DeleteCandidateModal from './DeleteCandidateModal';

import * as Screens from './';

const createNavigation = (props, t, me) => [
  {
    label: '🎉 ' + t('myProfile'),
    route: `${CANDIDATE_PROFILE}/${path(['candidateProfile', 'handle'], me)}`
  },
  {
    label:
      (!prop('profileImage', me) ? '🚨 ' : '✅ ') +
      t('accountInformationLabel'),
    route: '/candidate/account/info'
  },
  {
    label:
      ((me &&
        path(['notifyViaEmail'], me) === true &&
        path(['emailVerifiedAt'], me) === null) ||
      (path(['notifyViaSms'], me) === true &&
        path(['smsVerifiedAt'], me) === null)
        ? '🚨 '
        : '✅ ') + t('notificationPreferencesLabel'),
    route: CANDIDATE_NOTIFICATION_PREFERENCE_PATH
  },
  {
    label:
      (me &&
      path(['candidateProfile', 'status'], me) ===
        FOHStatusType.CLOSED_TO_OFFERS
        ? '🚨 '
        : '✅ ') + t('workPreferences'),
    route: CANDIDATE_WORK_PREFERENCES_PATH
  },
  {
    label:
      (path(['candidateProfile', 'personalSummary'], me) === ''
        ? '🚨 '
        : '✅ ') + t('personalSummaryLabel'),
    route: CANDIDATE_PERSONAL_SUMMARY_PATH
  },
  {
    label:
      (length(path(['candidateProfile', 'positionTypes'], me) || []) > 0
        ? '🚨 '
        : '✅ ') + t('positionsLabel'),
    route: CANDIDATE_POSITION_PATH
  },
  {
    label:
      (length(path(['candidateProfile', 'availabilitySunday'], me) || []) ===
        0 &&
      length(path(['candidateProfile', 'availabilityMonday'], me) | []) === 0 &&
      length(path(['candidateProfile', 'availabilityTuesday'], me) || []) ===
        0 &&
      length(path(['candidateProfile', 'availabilityWednesday'], me) || []) ===
        0 &&
      length(path(['candidateProfile', 'availabilityThursday'], me) || []) ===
        0 &&
      length(path(['candidateProfile', 'availabilityFriday'], me) || []) ===
        0 &&
      length(path(['candidateProfile', 'availabilitySaturday'], me) || []) === 0
        ? '🚨 '
        : '✅ ') + t('availabilityLabel'),
    route: '/candidate/availability'
  },
  {
    label:
      (length(path(['candidateProfile', 'additionalTraining'], me) || []) === 0
        ? '🚨 '
        : '✅ ') + t('skillsLabel'),
    route: CANDIDATE_SKILLS_PATH
  },
  {
    label:
      (!path(['candidateProfile', 'profileVideo'], me) ? '🚨 ' : '✅ ') +
      t('updateVideoLabel'),
    route: CANDIDATE_VIDEO_PATH
  },
  {
    label:
      (path(['candidateProfile', 'yearsExperience'], me) === ''
        ? '🚨 '
        : '✅ ') + t('yearsExpLabel'),
    route: CANDIDATE_YEARS_EXPERIENCE_PATH
  },
  {
    label:
      (!prop('hasWorkHistory', props) ? '🚨 ' : '✅ ') + t('workProfileLabel'),
    route: CANDIDATE_ADD_EMPLOYMENT_PATH
  },

  {
    label:
      (length(path(['candidateProfile', 'restaurantTypes'], me) || []) === 0
        ? '🚨 '
        : '✅ ') + t('restaurantStyle'),
    route: CANDIDATE_RESTAURANT_STYLE_PATH
  },
  {
    label:
      (length(path(['candidateProfile', 'employmentPreferences'], me) || []) ===
      0
        ? '🚨 '
        : '✅ ') + t('topThreeLabel'),
    route: CANDIDATE_TOP_THREE_PATH
  },
  {
    label:
      (!path(['candidateProfile', 'resume'], me) ? '🚨 ' : '✅ ') +
      t('resumeLabel'),
    route: CANDIDATE_RESUME
  },
  {
    label: '🔑 ' + t('passwordLabel'),
    route: PASSWORD_RESET_PATH
  }
];

const AccountMenuScreen = props => {
  const { t } = props;
  const { modal } = useParams();

  const { navigateTo, goBack } = useNavigation();

  const [activeScreen, setActiveScreen] = useState();
  const { data } = useGetMeData();
  const myWorkHistoryQuery = useGetMyWorkHistoryQuery({});
  const myProfileImageQuery = useGetMyPhotoQuery({});

  const me = {
    ...data,
    profileImage: getProfilePhoto(myProfileImageQuery)
  };

  const hasWorkHistory =
    length(
      unwrapEdgesAt(
        ['data', 'me', 'candidateProfile', 'workHistory', 'edges'],
        myWorkHistoryQuery
      )
    ) > 0;

  const [navigation, setNavigation] = useState(
    createNavigation(
      {
        ...props,
        hasWorkHistory
      },
      t,
      me
    )
  );

  useEffect(() => {
    setNavigation(createNavigation({ ...props, hasWorkHistory }, t, me));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myWorkHistoryQuery.data, data, myProfileImageQuery.data]);

  const handle = path(['candidateProfile', 'handle'], me);

  return (
    <>
      {props.isMobile ? (
        <FOHAccountMenuScreen
          navigateTo={route => {
            navigateTo(route);
          }}
          navigation={navigation}
          key={path(['candidateProfile', 'id'], me)}
        />
      ) : (
        <>
          <FOHWideAccountMenuScreen
            activeScreen={activeScreen || navigation[1].route}
            setActiveScreen={setActiveScreen}
            key={path(['candidateProfile', 'id'], me)}
            navigateTo={route => {
              if (route.includes(`${CANDIDATE_PROFILE}/`)) {
                navigateTo(route);
              } else {
                setActiveScreen(route);
              }
            }}
            components={[
              {
                component: (
                  <Screens.ProfileScreen
                    handle={handle}
                    key={handle}
                    isInSettings={true}
                  />
                ),
                route: `${CANDIDATE_PROFILE}/${handle}`
              },
              {
                component: (
                  <Screens.AccountInformationScreen
                    hideTopNav={true}
                    isInSettings={true}
                  />
                ),
                route: '/candidate/account/info'
              },

              {
                component: (
                  <Screens.NotificationPreferenceScreen
                    hideTopNav={true}
                    isInSettings={true}
                  />
                ),
                route: CANDIDATE_NOTIFICATION_PREFERENCE_PATH
              },
              {
                component: (
                  <Screens.PositionTypeScreen
                    hideTopNav={true}
                    isInSettings={true}
                  />
                ),
                route: CANDIDATE_POSITION_PATH
              },
              {
                component: (
                  <Screens.AddEmploymentScreen
                    hideTopNav={true}
                    isInSettings={true}
                  />
                ),
                route: CANDIDATE_ADD_EMPLOYMENT_PATH
              },
              {
                component: (
                  <Screens.UploadVideoScreen
                    hideTopNav={true}
                    isInSettings={true}
                  />
                ),
                route: CANDIDATE_VIDEO_PATH
              },
              {
                component: (
                  <Screens.WorkPreferencesScreen
                    hideTopNav={true}
                    isInSettings={true}
                  />
                ),
                route: CANDIDATE_WORK_PREFERENCES_PATH
              },
              {
                component: (
                  <Screens.TopThreeScreen
                    hideTopNav={true}
                    isInSettings={true}
                  />
                ),
                route: CANDIDATE_TOP_THREE_PATH
              },
              {
                component: (
                  <Screens.SkillsScreen hideTopNav={true} isInSettings={true} />
                ),
                route: CANDIDATE_SKILLS_PATH
              },
              {
                component: (
                  <Screens.YearsExpScreen
                    hideTopNav={true}
                    isInSettings={true}
                  />
                ),
                route: CANDIDATE_YEARS_EXPERIENCE_PATH
              },
              {
                component: (
                  <Screens.RestaurantStyleScreen
                    hideTopNav={true}
                    isInSettings={true}
                  />
                ),
                route: CANDIDATE_RESTAURANT_STYLE_PATH
              },
              {
                component: (
                  <Screens.AvailabilityScreen
                    hideTopNav={true}
                    isInSettings={true}
                  />
                ),
                route: '/candidate/availability'
              },
              {
                component: (
                  <Screens.ResumeUploadScreen
                    hideTopNav={true}
                    isInSettings={true}
                  />
                ),
                route: CANDIDATE_RESUME
              },
              {
                component: (
                  <Screens.PersonalSummaryScreen isInSettings={true} />
                ),
                route: CANDIDATE_PERSONAL_SUMMARY_PATH
              },
              {
                component: (
                  <PasswordResetScreen hideTopNav={true} isInSettings={true} />
                ),
                route: PASSWORD_RESET_PATH
              }
            ]}
            navigation={navigation}
          />
        </>
      )}
      <DeleteCandidateModal
        open={modal === 'delete'}
        dialog={!props.isMobile}
        close={() => {
          goBack();
        }}
        t={t}
        me={me}
        style={{
          maxWidth: 664
        }}
      />
    </>
  );
};

export default compose(
  withTranslation('AccountMenuFeature'),
  withIsMobile
)(AccountMenuScreen);
