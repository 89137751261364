import {
  CANDIDATE_PROFILE_URL,
  CANDIDATE_WORK_HISTORY_URL,
  PROFILE_VIDEO_URL
} from '../constants/urls';

import { post, get } from './index';

export const deleteCandidateWorkHistory = work_id => {
  let CANDIDATE_WORK_HISTORY_DELETE_URL = `${CANDIDATE_WORK_HISTORY_URL}/${work_id}/delete`;

  return post(CANDIDATE_WORK_HISTORY_DELETE_URL);
};

export const uploadProfileVideo = async imageFile => {
  const data = {
    file_name: imageFile.name,
    file_size: imageFile.size,
    file_type: imageFile.type
  };

  const resp = await post(PROFILE_VIDEO_URL, data);
  await fetch(resp.upload_url, {
    method: 'PUT',
    body: imageFile
  });

  return resp;
};

export const getCandidateProfile = id => {
  return get(`${CANDIDATE_PROFILE_URL}/${id}/`);
};
