import React, { useRef } from 'react';

import { useHover } from 'react-native-web-hooks';
import { StyleProp, ViewStyle } from 'react-native';

import { FOHSpaceSmallest } from '../space/FOHSpace';
import { FOHTouchableOpacity } from '../containers/Containers';
import { FOHToolTip, FOHToolTipType } from '../tooltips/FOHToolTip';

import { FOHButtonProps, ButtonText } from './FOHButton';

export interface FOHTextButtonProps extends FOHButtonProps {
  color?: string;
  icon?: any;
  numberOfLines?: number;
  style?: any;
  testID?: string;
  touchableStyle?: StyleProp<ViewStyle>;
  underline?: boolean;
  reverseIcon?: boolean;
  tooltipBGColor?: string;
  enableHover?: boolean;
}

export const FOHTextButton = (props: FOHTextButtonProps) => {
  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);

  return (
    <FOHTouchableOpacity
      disabled={props.disabled}
      onPress={props.onPress}
      style={{
        justifyContent: 'center',
        flexDirection: props.reverseIcon ? 'row-reverse' : 'row',
        alignItems: 'center',
        ...((props.touchableStyle || {}) as ViewStyle)
      }}
      testID={props.testID}
      ref={hoverRef}
    >
      {props.enableHover ? (
        <FOHToolTip
          helpText={props.helpText}
          open={isHovered}
          type={props.toolTipType}
          style={
            props.toolTipType === FOHToolTipType.LEFT
              ? { width: 200, right: 52, top: 0 }
              : { width: 200 }
          }
          bgColor={props.tooltipBGColor || undefined}
        />
      ) : (
        <></>
      )}
      {props.icon ? (
        <>
          {props.icon()}
          <FOHSpaceSmallest />
        </>
      ) : (
        <></>
      )}
      <ButtonText
        style={{
          color: props.disabled ? '#C6C6C6' : props.color || '#171717',
          textDecorationLine: props.underline ? 'underline' : 'none',
          ...props.style
        }}
        numberOfLines={props.numberOfLines}
      >
        {props.title}
      </ButtonText>
    </FOHTouchableOpacity>
  );
};
