import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import {
  FOHColors,
  FOHView,
  FOHSpace,
  FOHCampaignCell,
  FOHCampaignHeaderCell
} from '../../components';
import { JobBoardCampaignType } from '../../graphql/generated';
import { toFullDate, unwrapEdgesAt } from '../../utils';
import { CAMPAIGN_HOME } from '../../constants';
import { useNavigation } from '../../utils/navigation';

import { HOME_TABS } from './constants';

interface CampaignListProps {
  campaigns: JobBoardCampaignType[];
  selectedTab: keyof typeof HOME_TABS;
}

export const CampaignList = (props: CampaignListProps) => {
  const { t } = useTranslation('CampaignsFeature');

  const { campaigns, selectedTab } = props;

  const { navigateTo } = useNavigation();

  const [activeCampaignHandles, setActiveCampaignHandles] = useState<string[]>(
    []
  );

  return (
    <>
      <FOHView
        style={{
          backgroundColor: FOHColors.BACKGROUND_GRAY,
          height: '100%',
          minHeight: '100vh',
          width: '98%',
          margin: 'auto'
        }}
      >
        <FOHCampaignHeaderCell
          translations={{
            campaignLabel: t('campaigns'),
            clickLabel: t('clicks'),
            applyLabel: t('applies'),
            avgCPALabel: t('avgCpa'),
            progressLabel: t('progess'),
            budgetLabel: t('totalBudget'),
            jobBoardLabel: t('jobBoard'),
            scheduleLabel: t('campaignSchedule'),
            positionLabel: t('positionName')
          }}
          selectedTab={selectedTab}
        />
        {campaigns.map(campaign => {
          const isSelected = (activeCampaignHandles as string[]).includes(
            campaign.id
          );
          const position = unwrapEdgesAt(['positions', 'edges'], campaign)[0];
          const navigateToCampaignDetail = `${CAMPAIGN_HOME}/${campaign.id}`;

          return (
            <FOHCampaignCell
              key={campaign.id}
              name={campaign?.name as string}
              onPressName={() => navigateTo(navigateToCampaignDetail)}
              handle={campaign.id}
              selected={isSelected}
              onCheckBoxPress={() => {
                if (isSelected) {
                  setActiveCampaignHandles(
                    activeCampaignHandles.filter(
                      handle => handle !== campaign.id
                    )
                  );
                } else {
                  setActiveCampaignHandles([
                    ...(activeCampaignHandles as string[]),
                    campaign.id
                  ]);
                }
              }}
              campaignType={t('sponsoring')}
              jobCount={campaign?.applicationsCount as number}
              clickCount={campaign.clicks}
              applyCount={campaign.numApplies}
              avgCPA={(campaign.avgCpa || 0) / 100}
              totalBudget={(campaign.amount || 0) / 100}
              spentBudget={
                campaign.status === 'SUCCESS'
                  ? (campaign.spent || 0) / 100
                  : undefined
              }
              onPressJobs={() => {}}
              hasStatusBackground={false}
              platform={campaign.platform.toLowerCase()}
              editTemplate={() => navigateTo(navigateToCampaignDetail)}
              editLabel={t('edit')}
              progressLabel={
                campaign.status === 'PROGRESS'
                  ? t('campaignProgressLabel')
                  : t('spent')
              }
              jobLabel={t('jobs')}
              selectedTab={selectedTab}
              schedule={`${toFullDate(campaign.willStartOn)} - ${toFullDate(
                campaign.campaignWillEndOn
              )}`}
              position={`${position?.positionName} - ${position?.location?.displayName}`}
            />
          );
        })}
        <FOHSpace />
      </FOHView>
    </>
  );
};
