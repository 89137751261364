import React, { useState } from 'react';

import styled from 'styled-components/native';
import { compose } from 'ramda';
import { StyleProp, ViewStyle } from 'react-native';

import {
  FOHErrorLabel,
  FOHGeoSearchIcon,
  FOHPhoneIcon,
  FOHSpaceSmallest,
  withPlaceholderText,
  FOHColors,
  FOHFonts,
  FOHView
} from '../../ingredients';
import { FOH_INPUT_HEIGHT } from '../../constants';

import { withError } from '../text/withError';

//eslint-disable-next-line
const mask = require('react-native-mask-text/lib/commonjs/index.js');

export interface FOHMaskedTextInputProps {
  onChangeText: (text: string, raw: string) => void;
  value: string;
  mask?: string;
  options?: any;
  error?: string;
  type?: string;
  testID?: string;
  placeholder?: string;
  autoCompleteType?: string;
  onKeyPress?: (event: any) => void;
  keyboardType?: string;
  style?: StyleProp<ViewStyle>;
  containerStyle?: any;
  small?: boolean;
  maxHeight?: string | number;
}

export const FOHMaskedTextInput = withError(
  (props: FOHMaskedTextInputProps) => (
    <mask.MaskedTextInput
      {...props}
      style={{
        borderColor: props.error ? `#E5253C` : `#dbdbdb`,
        height: props?.small
          ? props?.maxHeight !== undefined
            ? (props.maxHeight as number) - 6
            : undefined
          : FOH_INPUT_HEIGHT,
        borderWidth: 1,
        borderRadius: 4,
        padding: 10,
        fontFamily: FOHFonts.REGULAR,
        fontStyle: 'normal',
        fontSize: 14,
        width: '100%'
      }}
      keyboardType="default"
    />
  )
);

export const FOHPhoneInput = (props: FOHMaskedTextInputProps) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <>
      <Container
        error={props.error}
        style={{
          borderColor: isFocused ? FOHColors.BLUE : FOHColors.GRAYSCALE_300,
          ...props.containerStyle
        }}
      >
        <FOHPhoneIcon
          style={{
            width: props?.small ? 14 : 18,
            height: props?.small ? 14 : 18,
            tintColor: FOHColors.BLUE
          }}
        />
        <FOHSpaceSmallest />
        <PhoneInput
          {...props}
          style={{
            height: props?.small
              ? props?.maxHeight !== undefined
                ? (props.maxHeight as number) - 6
                : undefined
              : 46,
            paddingBottom: 0
          }}
          //@ts-expect-error mask error
          mask="(999) 999-9999"
          testID="phone-input"
          autoCompleteType={'tel'}
          keyboardType={'phone-pad'}
          // placeholder={props.placeholder}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
      </Container>
      {props.error ? <FOHErrorLabel>{props.error}</FOHErrorLabel> : <></>}
    </>
  );
};

export const FOHZipInput = (props: FOHMaskedTextInputProps) => {
  const [isFocused, setIsFocused] = useState(false);
  return (
    <>
      <Container
        error={props.error}
        style={{
          borderColor: isFocused ? FOHColors.BLUE : FOHColors.GRAYSCALE_300,
          ...props.containerStyle
        }}
      >
        <FOHView>
          <FOHGeoSearchIcon
            style={{
              width: props?.small ? 14 : 18,
              height: props?.small ? 14 : 18,
              tintColor: FOHColors.BLUE
            }}
          />
        </FOHView>
        <FOHSpaceSmallest />
        <ZipInput
          {...props}
          style={{
            height: props?.small
              ? props?.maxHeight !== undefined
                ? (props.maxHeight as number) - 6
                : undefined
              : 46
          }}
          //@ts-expect-error mask error
          mask="99999"
          testID="zip-input"
          autoCompleteType={'postal-code'}
          keyboardType={'numeric'}
          placeholder={props.placeholder}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
      </Container>
      {props.error ? <FOHErrorLabel>{props.error}</FOHErrorLabel> : <></>}
    </>
  );
};

const Input = styled(mask.MaskedTextInput)`
  background: #ffffff;
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  display: flex;
  align-items: center;
  width: 90%;
  height: 46px;
  flex-direction: row;
  padding: 5px 10px;
  outline-color: transparent;
`;

const PhoneInput = compose(withPlaceholderText)(Input);
const ZipInput = compose(withPlaceholderText)(Input);

const Container = styled(FOHView)`
  background: #ffffff;
  border: ${(props: any) =>
    props.error ? `1px solid ${FOHColors.RED}` : '1px solid #dbdbdb'};
  border-radius: 4px;
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 200%;
  align-items: center;
  height: ${FOH_INPUT_HEIGHT}px;
  flex-direction: row;
  padding: 8px;
  width: 100%;
`;
