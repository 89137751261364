import React from 'react';

import { View, ScrollView } from 'react-native';
import styled from 'styled-components/native';
import { prop } from 'ramda';

import {
  FOHLargeAvatar,
  FOHHeaderH3,
  FOHSpace,
  FOHView,
  FOHStatus,
  FOHStatusType,
  FOHLabel,
  FOHSectionLabel,
  FOHSpaceSmall,
  FOHColors,
  FOHFonts
} from '../../ingredients';

import {
  FOHCandidatePositionDetails,
  FOHCandidatePositionDetailsType
} from '../blocks/FOHCandidatePositionDetails';
import { FOHProfileImage } from '../navigation/FOHProfileImage';

export interface FOHMultiPositionFormProps {
  title: string;
  name: string;
  photo?: string;
  positions: Array<string>;
  style?: any;
  id?: string;
  statusText?: string;
  statusType?: FOHStatusType;
  onScroll?: () => void;
  applications: Array<any>;
  appliedForLabel: string;
  locationLabel: string;
  applicationDateLabel: string;
}

export const FOHMultiPositionForm = (props: FOHMultiPositionFormProps) => {
  const positionsText = props.positions.join(', ');

  return (
    <>
      <FOHSectionLabel
        numberOfLines={2}
        style={{
          fontSize: 24,
          lineHeight: 32,
          textAlign: 'center',
          fontFamily: FOHFonts.MEDIUM,
          fontWeight: '600',
          fontStyle: 'normal',
          width: '100%'
        }}
      >
        {props.title}
      </FOHSectionLabel>
      <FOHSpace />
      <ProfileContainer
        style={{
          ...props.style,
          backgroundColor: FOHColors.WHITE
        }}
      >
        {props.photo ? (
          <FOHProfileImage
            photo={props.photo}
            style={{
              height: 72,
              width: 72
            }}
          />
        ) : (
          <FOHLargeAvatar style={{ height: 72, width: 72 }} />
        )}
        <CandidateDetailContainer>
          <FOHView style={{ alignItems: 'flex-start' }}>
            <FOHHeaderH3
              numberOfLines={1}
              style={{
                maxWidth: 200
              }}
            >
              {props.name}{' '}
            </FOHHeaderH3>
            <View style={{ flexDirection: 'row' }}>
              <FOHLabel
                style={{
                  paddingBottom: 8,
                  fontSize: 14,
                  lineHeight: 24,
                  color: FOHColors.GREY
                }}
              >
                {positionsText}
              </FOHLabel>
            </View>
            {props.statusText && props.statusType ? (
              <>
                <FOHStatus
                  statusText={props.statusText}
                  statusType={props.statusType}
                />
              </>
            ) : (
              <></>
            )}
          </FOHView>
        </CandidateDetailContainer>
      </ProfileContainer>
      <FOHSpace />
      <ScrollView
        scrollEventThrottle={200}
        onScroll={prop('onScroll', props)}
        style={{
          width: '100%',
          height: 700
        }}
      >
        {props.applications.map(application => (
          <>
            <FOHCandidatePositionDetails
              type={FOHCandidatePositionDetailsType.APPLIED}
              appliedForLabel={prop('appliedForLabel', props)}
              positionName={prop('positionName', application)}
              locationLabel={prop('locationLabel', props)}
              locationName={prop('locationName', application)}
              formattedAddress={prop('formattedAddress', application)}
              applicationDateLabel={prop('applicationDateLabel', props)}
              applicationDate={prop('applicationDate', application)}
              rejectedForLabel={''}
              rejectedByLabel={''}
              rejectedByName={''}
              rejectionReasons={[]}
              onPressPosition={() => {}}
              onPressMorePositions={() => {}}
            />
            <FOHSpaceSmall />
          </>
        ))}
      </ScrollView>
    </>
  );
};

const ProfileContainer = styled(View)`
  border-width: 1px;
  border-color: #e8e8e8;
  padding: 16px;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

const CandidateDetailContainer = styled(View)`
  align-items: flex-start;
  flex-direction: row;
  margin-left: 16px;
  margin-right: 8px;
`;
