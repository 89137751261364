import React from 'react';

import styled from 'styled-components/native';

import {
  FOHButton,
  Row,
  FOHTextField,
  FOHSpace,
  FOHPhoneInput,
  FOHView,
  FOHColors,
  FOHSpaceSmall
} from '../../ingredients';
import {
  FOHPhotoInputProps,
  FOHAccountPhotoInput,
  FOHResetPasswordForm
} from '../../recipes';
import { useIsMobile } from '../../spices';

import { FOHForm } from './FOHForm';

export interface FOHEmployerAccountFormProps extends FOHPhotoInputProps {
  handleSubmit?: () => void;
  submitLabel?: string;
  firstName: string;
  firstNamePlaceholder: string;
  setFirstName: (value: string) => void;
  lastName: string;
  lastNamePlaceholder: string;
  setLastName: (value: string) => void;
  phoneNumber: string;
  phoneNumberPlaceholder: string;
  setPhoneNumber: (value: string) => void;
  email: string;
  emailPlaceholder: string;
  setEmail: (value: string) => void;
  error?: any;
  disabledSubmit?: boolean;
  resetPasswordHeader: string;
  resetPasswordDescrition: string;
  handleReset: () => void;
  resetLabel: string;
  disabledReset?: boolean;
}

export const FOHEmployerAccountForm = (props: FOHEmployerAccountFormProps) => {
  const isMobile = useIsMobile();

  return (
    <ContainerView
      style={{
        paddingBottom: isMobile ? 0 : 80,
        paddingTop: isMobile ? 0 : 20
      }}
    >
      <FOHView
        style={{
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: isMobile ? 'center' : 'space-between',
          alignItems: isMobile ? 'center' : 'flex-start',
          flexWrap: 'wrap'
        }}
      >
        <FOHView
          style={{
            paddingTop: isMobile ? 10 : 0,
            paddingBottom: isMobile ? 5 : 0,
            width: isMobile ? '100%' : undefined
          }}
        >
          <FOHForm
            handleSubmit={props.handleSubmit}
            disabledSubmit={props.disabledSubmit}
          >
            <FOHView
              style={{
                padding: isMobile ? 18 : 13,
                backgroundColor: FOHColors.WHITE,
                borderWidth: 1,
                borderColor: FOHColors.GRAYSCALE_200,
                borderRadius: 4
              }}
            >
              <Row style={{ width: isMobile ? '100%' : 450 }}>
                <FOHAccountPhotoInput
                  //@ts-ignore photo prop is not available in withErrors
                  photo={props.photo}
                  fullName={props.fullName}
                  uploadLabel={props.uploadLabel}
                  pickPhoto={props.pickPhoto}
                  error={
                    props.error && props.error.photo ? props.error.photo : null
                  }
                  isEmployer={true}
                />
              </Row>
              <Row style={{ width: isMobile ? '100%' : 450 }}>
                <FOHView style={{ flex: 1 }}>
                  <FOHTextField
                    placeholder={props.firstNamePlaceholder}
                    value={props.firstName}
                    onChangeText={(value: string) => props.setFirstName(value)}
                    error={
                      props.error && props.error.firstName
                        ? props.error.firstName
                        : null
                    }
                  />
                </FOHView>
                <FOHSpace />
                <FOHView style={{ flex: 1 }}>
                  <FOHTextField
                    placeholder={props.lastNamePlaceholder}
                    value={props.lastName}
                    onChangeText={(value: string) => props.setLastName(value)}
                    error={
                      props.error && props.error.lastName
                        ? props.error.lastName
                        : null
                    }
                  />
                </FOHView>
              </Row>
              <Row style={{ width: isMobile ? '100%' : 450 }}>
                <FOHTextField
                  placeholder={props.emailPlaceholder}
                  value={props.email}
                  autoCompleteType={'email'}
                  onChangeText={(value: string) => props.setEmail(value)}
                  error={
                    props.error && props.error.email ? props.error.email : null
                  }
                />
              </Row>
              <Row style={{ width: isMobile ? '100%' : 450 }}>
                <FOHPhoneInput
                  placeholder={props.phoneNumberPlaceholder}
                  value={props.phoneNumber}
                  autoCompleteType={'tel'}
                  onChangeText={(value: string) => props.setPhoneNumber(value)}
                  error={
                    props.error &&
                    (props.error.phoneNumber || props.error.phone)
                      ? props.error.phoneNumber || props.error.phone
                      : null
                  }
                />
              </Row>
              <FOHSpace />
              {props.handleSubmit && !!props.submitLabel && (
                <FOHView style={{ alignItems: 'center' }}>
                  <FOHButton
                    onPress={props.handleSubmit}
                    title={props.submitLabel}
                    disabled={props.disabledSubmit}
                    style={{
                      maxWidth: 168,
                      width: '100%'
                    }}
                  />
                </FOHView>
              )}
            </FOHView>
            {!isMobile ? <FOHSpace /> : null}
          </FOHForm>
        </FOHView>
        <FOHView
          style={{
            paddingTop: isMobile ? 10 : 0,
            paddingBottom: isMobile ? 5 : 0,
            width: isMobile ? '100%' : undefined
          }}
        >
          <FOHResetPasswordForm
            headerText={props.resetPasswordHeader}
            descriptionText={props.resetPasswordDescrition}
            handleSubmit={props.handleReset}
            submitLabel={props.resetLabel}
            disabledSubmit={props.disabledReset}
          />
          <FOHSpaceSmall />
        </FOHView>
      </FOHView>
    </ContainerView>
  );
};

const ContainerView = styled(FOHView)`
  width: 100%;
`;
