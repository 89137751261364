import React from 'react';

import { defaultTo, prop } from 'ramda';

import whiteadd from '../../../images/whiteaddicon.svg';

import { FOHImage } from '../../ingredients';

export const FOHWhiteAddIcon = (props: any) => (
  <FOHImage
    source={{ uri: whiteadd }}
    style={{ width: 22, height: 22, ...defaultTo({}, prop('style', props)) }}
  />
);
