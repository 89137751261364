import React from 'react';

import {
  FOHLink,
  FOHReactiveModal,
  FOHHeaderText,
  FOHDetailText,
  FOHButton,
  FOHSpace,
  FOHView
} from '../../ingredients';

export interface FOHGoToModalProps {
  open: boolean;
  close: () => void;
  headerLabel: string;
  buttonLabel: string;
  detailLabel?: string;
  preLinkText: string;
  linkText: string;
  onPressLink: () => void;
  onPressButton: () => void;
  dialog?: boolean;
  leftContentShift?: number;
}

export const FOHGoToModal = (props: FOHGoToModalProps) => {
  return (
    <FOHReactiveModal
      leftContentShift={props.leftContentShift}
      dialog={props.dialog}
      open={props.open}
      close={props.close}
    >
      <FOHView
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          padding: props.dialog ? 24 : 16
        }}
      >
        <FOHHeaderText>{props.headerLabel}</FOHHeaderText>
        <FOHDetailText>{props.detailLabel}</FOHDetailText>
        <FOHSpace />
        <FOHButton
          testID="button-matches"
          onPress={props.onPressButton}
          title={props.buttonLabel}
        />
        <FOHSpace />
        <FOHLink
          text={props.preLinkText}
          linkText={props.linkText}
          onPress={props.onPressLink}
        />
      </FOHView>
    </FOHReactiveModal>
  );
};
