import React from 'react';

import {
  FOHLabel,
  FOHSpaceSmall,
  FOHSpace,
  FOHXIcon,
  FOHRequiredMark,
  FOHEditMarker,
  FOHColors,
  FOHView,
  FOHTouchableOpacity
} from '../../ingredients';

export interface FOHRejectionCellProps {
  label: string;
  total_candidates: number;
  delete: () => void;
  edit: () => void;
}

export const FOHRejectionCell = (props: FOHRejectionCellProps) => {
  return (
    <FOHView
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: 6,
        flexWrap: 'wrap',
        padding: 24,
        paddingTop: 16,
        paddingBottom: 16,
        backgroundColor: FOHColors.GRAYSCALE_10
      }}
    >
      <FOHView
        style={{
          flexDirection: 'row',
          flex: 1,
          flexWrap: 'wrap',
          alignItems: 'center'
        }}
      >
        <FOHRequiredMark style={{ width: 8, height: 8 }} />
        <FOHSpace />
        <FOHView style={{ flexWrap: 'wrap', flex: 1, flexDirection: 'row' }}>
          <FOHLabel style={{ color: FOHColors.GRAYSCALE_900 }} heavy>
            {props.label}
          </FOHLabel>
          <FOHLabel style={{ color: FOHColors.GRAYSCALE_500 }}>
            &nbsp;· {props.total_candidates} Candidates Total
          </FOHLabel>
        </FOHView>
      </FOHView>
      <FOHView style={{ flexDirection: 'row', alignItems: 'center' }}>
        <FOHTouchableOpacity
          onPress={props.edit}
          style={{
            width: 32,
            justifyContent: 'center',
            alignItems: 'center',
            height: 24
          }}
        >
          <FOHEditMarker style={{ width: 18, height: 18 }} />
        </FOHTouchableOpacity>
        <FOHSpaceSmall />
        <FOHTouchableOpacity
          onPress={props.delete}
          style={{
            width: 32,
            justifyContent: 'center',
            alignItems: 'center',
            height: 24
          }}
        >
          <FOHXIcon />
        </FOHTouchableOpacity>
      </FOHView>
    </FOHView>
  );
};
