import React from 'react';

import { Image, View, TouchableOpacity } from 'react-native';

import {
  FOHButton,
  FOHLinkOut,
  FullWidthCard,
  FOHHeaderH2,
  FOHSmallDetailLabel,
  FOHSpace,
  FOHLabel,
  FOHVerticalDivider,
  FOHLabelBold,
  FOHSpaceSmall,
  FOHRightArrow,
  FOHMoneyIcon,
  FOHRedBestMatchIcon
} from '../../ingredients';

import { FOHProfileImage } from '../navigation/FOHProfileImage';

export interface FOHPositionListCellProps {
  mobile?: boolean;

  positionName: string;
  positionId: string;
  positionSlug: string;
  hiringManagerName: string;
  hiringManagerImage?: string;
  hiringManagerPosition: string; // Position Owner
  salaryText?: string;
  postedDateText: string;
  // Job Details
  onPressJobDetails?: (slug: string) => void;
  jobDetailsLabel?: string;

  // Apply
  buttonCTALabel: string;
  onPressCTA: (slug: string) => void;
  buttonCTADisabled?: boolean;
  // location
  locationId: string;
  locationName: string;
  locationSlug: string;
  locationImage: string;
  onPressLocation?: (slug: string) => void;
  locationHref: string;
  isBestMatch?: boolean;
}

const MemoImage = React.memo((props: any) => (
  <Image source={props.source} {...props} />
));

export const FOHPositionListCell = (props: FOHPositionListCellProps) => (
  <FullWidthCard style={{ padding: 24 }}>
    <View
      style={{
        flexDirection: 'row',
        flexWrap: 'wrap'
      }}
    >
      <MemoImage
        source={{ uri: props.locationImage }}
        style={{ width: 72, height: 72, borderRadius: 3 }}
      />
      {props.isBestMatch && (
        <FOHRedBestMatchIcon
          style={{
            position: 'absolute',
            top: 52,
            left: 52
          }}
        />
      )}
      <FOHSpace />
      <View style={{ flex: 2, minWidth: 270 }}>
        <FOHSmallDetailLabel>{props.postedDateText}</FOHSmallDetailLabel>
        <FOHHeaderH2 style={{ textAlign: 'left' }}>
          {props.positionName}
        </FOHHeaderH2>
        <FOHLinkOut
          noIcon={true}
          // not sure if this is a modal or link out yet
          href={props.locationHref}
          onPress={() =>
            props.onPressLocation && props.onPressLocation(props.locationSlug)
          }
        >
          {props.locationName}
        </FOHLinkOut>
      </View>
      {!props.mobile && (
        <View
          style={{
            flex: 1,
            minWidth: 270,
            paddingTop: 10,
            paddingBottom: 10,
            alignItems: 'flex-end',
            flexDirection: 'row'
          }}
        >
          <FOHButton
            onPress={() => props.onPressCTA(props.positionSlug)}
            title={props.buttonCTALabel}
            disabled={props.buttonCTADisabled}
            fullWidth={true}
          />
        </View>
      )}
    </View>
    <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
      {!!props.salaryText && (
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            minWidth: 100
          }}
        >
          <FOHMoneyIcon />
          <FOHSpaceSmall />
          <FOHLabel>{props.salaryText}</FOHLabel>
          <FOHSpace />
          <FOHVerticalDivider />
          <FOHSpace />
        </View>
      )}
      <View
        style={{
          flex: 4,
          flexDirection: 'row',
          alignItems: 'center',
          flexWrap: 'wrap',
          minWidth: 250,
          paddingTop: 10,
          paddingBottom: 10
        }}
      >
        <View style={{ marginBottom: 10 }}>
          <FOHProfileImage
            highlight={true}
            photo={props.hiringManagerImage}
            key={props.hiringManagerImage}
            style={{
              width: 34,
              height: 34
            }}
          />
        </View>
        <FOHSpaceSmall />
        <FOHLabelBold style={{ marginBottom: 4 }}>
          {props.hiringManagerName},{' '}
        </FOHLabelBold>
        <FOHLabel style={{ marginBottom: 4 }}>
          {props.hiringManagerPosition}{' '}
        </FOHLabel>
      </View>
      {!!props.onPressJobDetails && (
        <View
          style={{
            flex: 1,
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            paddingLeft: 40,
            minWidth: 270
          }}
        >
          <TouchableOpacity
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end'
            }}
            onPress={() =>
              props.onPressJobDetails &&
              props.onPressJobDetails(props.positionSlug)
            }
          >
            <FOHLabel>{props.jobDetailsLabel}</FOHLabel>
            <FOHSpaceSmall />
            <FOHRightArrow />
          </TouchableOpacity>
        </View>
      )}
    </View>
    {props.mobile && (
      <View
        style={{
          flex: 1,
          minWidth: 270,
          paddingTop: 10,
          paddingBottom: 10,
          alignItems: 'flex-end',
          flexDirection: 'row'
        }}
      >
        <FOHButton
          onPress={() => props.onPressCTA(props.positionSlug)}
          title={props.buttonCTALabel}
          disabled={props.buttonCTADisabled}
          fullWidth={true}
        />
      </View>
    )}
  </FullWidthCard>
);
