import React, { FunctionComponent, useState } from 'react';

import { Spring, SpringConfig } from 'react-spring';

export interface FOHLoaderProps {
  path?: any;
  svg?: any;
}

export const FOHLoader: FunctionComponent<FOHLoaderProps> = () => {
  const [reset, setReset] = useState<string>('');

  const springConfig: SpringConfig = {
    duration: 1200,
    easing: (t: number) =>
      ((t *= 2) <= 1 ? t * t * t : (t -= 2) * t * t + 2) / 2
  };

  return (
    <Spring
      from={{ x: 0 }}
      to={{ x: 2000 }}
      config={springConfig}
      reverse={true}
      reset={!!reset}
      onRest={() => setReset(`${new Date().getSeconds()}`)}
    >
      {(spring: any) => {
        const strokeDashoffsetValue: number = spring.x.get();
        const strokeDashoffsetString: string = strokeDashoffsetValue.toFixed(2);

        return (
          <svg
            width="30"
            height="44"
            viewBox="0 0 262 382"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            strokeDashoffset={strokeDashoffsetString}
          >
            <path
              strokeDasharray="2000"
              d="M16 364.449V126.243C16 99.7329 37.4903 78.2426 64 78.2426H141.566C164.779 78.2426 183.596 97.0602 183.596 120.273V120.273C183.596 143.486 164.779 162.303 141.566 162.303H110.271C100.33 162.303 92.2713 170.362 92.2713 180.303V202.345C92.2713 212.286 100.33 220.345 110.271 220.345H141.065C164.555 220.345 183.596 239.387 183.596 262.876V262.876C183.596 286.365 164.555 305.407 141.065 305.407H110.271C100.33 305.407 92.2713 313.466 92.2713 323.407V348.225C92.2713 357.445 99.235 365.246 108.444 365.692C163.097 368.341 225.981 354.956 241.302 284.392C245.65 261.375 249.33 227.4 214.707 191.874C249.33 156.349 248.159 123.276 243.81 100.259C235.207 54.7261 200.522 27.9687 163.525 19.7008C141.359 14.7473 117.087 16.1979 92.2713 16.1979L16 16.1979"
              stroke="#51C1DB"
              strokeWidth="32"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }}
    </Spring>
  );
};
