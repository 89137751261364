import React from 'react';

import { Image } from 'react-native';
import { length, prop } from 'ramda';
import styled from 'styled-components/native';

import {
  FOHHighlightWordsLabel,
  CardScreen,
  FOHView,
  FOHFonts,
  FOHDivider,
  FOHHighlightLinkWithText,
  FOHLink,
  FOHColors,
  FOHSpaceSmall,
  FOHSpace,
  FOHSmallDetailLabel,
  FOHHeaderH3,
  FOHRightArrow
} from '../../ingredients';

interface FOHNoApplicantsBannerProps {
  headerText: string;
  headerHightlightText: string;

  detailText1: string;

  detailText2WithLink: string;
  highlightLinkText: string;
  onPressExplore: () => void;

  detailText3WithLink: string;
  linkWord: string;
  linkHref: string;
  noIcon: boolean;
  onPressLink3: () => void;

  smallDetailText?: string;
  otherJobBoards?: Array<FOHJobBoardsPostProps>;
}

interface FOHJobBoardsPostProps {
  image: string;
}

export const FOHNoApplicantsBanner = (props: FOHNoApplicantsBannerProps) => (
  <FOHNoApplicantsBannerCard>
    <FOHNoApplicantsBannerCardContainer>
      <FOHHeaderWrapper>
        <FOHHighlightWordsLabel
          labelCmp={FOHHeaderH3}
          emphasizedWords={props.headerHightlightText.split(' ')}
          highlightColor={FOHColors.BLUE}
          boldLabelCmp={FOHHeaderH3}
        >
          {props.headerText}
        </FOHHighlightWordsLabel>
      </FOHHeaderWrapper>
      <FOHSpace />
      <FOHSpace />
      <FOHPointsWrapper>
        <FOHPointBlock>
          <FOHRightArrow style={{ tintColor: FOHColors.BLUE, top: 7 }} />
          <FOHSpace />
          <FOHSmallDetailLabel
            style={{
              fontSize: 16,
              lineHeight: 24,
              color: FOHColors.NARO_GREY
            }}
          >
            {props.detailText1}
          </FOHSmallDetailLabel>
        </FOHPointBlock>
        <FOHSpace />
        <FOHSpaceSmall />
        <FOHPointBlock>
          <FOHRightArrow style={{ tintColor: FOHColors.BLUE, top: 7 }} />
          <FOHSpace />
          <FOHLink
            textStyle={{
              fontFamily: FOHFonts.REGULAR,
              fontStyle: 'normal',
              fontSize: 16,
              lineHeight: 24,
              color: FOHColors.NARO_GREY
            }}
            linkStyle={{
              fontFamily: FOHFonts.REGULAR,
              fontStyle: 'normal',
              fontSize: 16,
              fontWeight: 600,
              lineHeight: 24,
              textDecorationLine: 'underline',
              color: FOHColors.PACIFIC_BLUE
            }}
            text={props.detailText2WithLink}
            linkText={props.highlightLinkText}
            onPress={props.onPressExplore}
          />
        </FOHPointBlock>
        <FOHSpace />
        <FOHSpaceSmall />
        {props.detailText3WithLink ? (
          <FOHPointBlock>
            <FOHRightArrow style={{ tintColor: FOHColors.BLUE, top: 7 }} />
            <FOHSpace />
            <FOHHighlightLinkWithText
              labelStyle={{
                fontFamily: FOHFonts.REGULAR,
                fontStyle: 'normal',
                fontSize: 16,
                lineHeight: 24,
                color: FOHColors.NARO_GREY
              }}
              linkStyle={{
                fontFamily: FOHFonts.REGULAR,
                fontStyle: 'normal',
                fontSize: 16,
                fontWeight: 600,
                lineHeight: 24,
                textDecorationLine: 'underline',
                color: FOHColors.PACIFIC_BLUE
              }}
              linkWord={props.linkWord}
              highlightColor={FOHColors.PACIFIC_BLUE}
              linkHref={props.linkHref}
              noIcon={props.noIcon}
              onPress={props.onPressLink3}
            >
              {props.detailText3WithLink}
            </FOHHighlightLinkWithText>
          </FOHPointBlock>
        ) : (
          <></>
        )}
      </FOHPointsWrapper>
      {prop('otherJobBoards', props) &&
      length(prop('otherJobBoards', props)!) > 0 ? (
        <>
          <FOHSpace />
          <FOHSpace />
          <FOHView>
            <FOHDivider />
          </FOHView>
          <FOHSpace />
          <FOHSpace />
          <FOHSmallDetailLabel
            style={{
              fontSize: 16,
              lineHeight: 24
            }}
          >
            {props.smallDetailText}
          </FOHSmallDetailLabel>
          <FOHSpace />
          <FOHSpaceSmall />
        </>
      ) : (
        <></>
      )}
      <FOHImagesWrapper>
        {prop('otherJobBoards', props) &&
        length(prop('otherJobBoards', props)!) > 0 ? (
          props.otherJobBoards!.map((board, index) => (
            <FOHImageWrapper key={`different-job-boards-${index}`}>
              <FOHImage
                resizeMode={'contain'}
                style={{ maxWidth: 109 }}
                source={{
                  uri: board.image
                }}
              />
            </FOHImageWrapper>
          ))
        ) : (
          <></>
        )}
      </FOHImagesWrapper>
    </FOHNoApplicantsBannerCardContainer>
  </FOHNoApplicantsBannerCard>
);

const FOHNoApplicantsBannerCard = styled(CardScreen)`
  padding: 20px;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #e8e8e8;
  background-color: #ffffff;
  width: 100%;
`;

const FOHNoApplicantsBannerCardContainer = styled(FOHView)`
  width: 100%;
  height: 100%;
  padding: 24px;
`;

const FOHHeaderWrapper = styled(FOHView)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const FOHPointsWrapper = styled(FOHView)`
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;

const FOHPointBlock = styled(FOHView)`
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
`;

const FOHImagesWrapper = styled(FOHView)`
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const FOHImageWrapper = styled(FOHView)`
  width: 100%;
  max-width: 109px;
  height: 87px;
  margin-bottom: 20px;
  margin-right: 20px;
`;

const FOHImage = styled(Image)`
  width: 100%;
  max-width: 167px;
  height: 87px;
  object-fit: cover;
`;
