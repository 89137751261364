import React, { useRef } from 'react';

import { Text } from 'react-native';
import styled from 'styled-components/native';
import { useHover } from 'react-native-web-hooks';

import {
  FOHCheckbox,
  FOHColors,
  FOHFonts,
  FOHTouchableOpacity,
  FOHView
} from '../../ingredients';

export interface FOHMultiSelectCheckboxProps {
  options: { label: string; value: string }[];
  selectedValues: string[];
  setSelectedValue: (selectedValue: string) => void;
  disabled?: boolean;
}

export const FOHMultiSelectCheckbox = ({
  options,
  selectedValues,
  setSelectedValue,
  disabled = false
}: FOHMultiSelectCheckboxProps) => {
  const ref = useRef(null);
  const hoverRef = useHover(ref);

  return (
    <Container>
      {options.map((option, index) => (
        <>
          <FOHTouchableOpacity
            key={index}
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              backgroundColor: disabled
                ? ''
                : hoverRef
                ? FOHColors.GRAYSCALE_100
                : '',
              borderRadius: 4,
              width: 130,
              paddingVertical: 6,
              margin: 5,
              //@ts-ignore cursor
              cursor: disabled ? 'initial' : 'pointer',
              pointerEvents: disabled ? 'none' : 'unset'
            }}
            ref={ref}
            onPress={() => !disabled && setSelectedValue(option.value)}
            testID={`multi-select-checkbox-${option.label}`}
          >
            <FOHCheckbox
              selected={selectedValues.includes(option.value)}
              isDisabled={disabled}
            />
            <FOHView style={{ paddingLeft: 6 }}>
              <CheckboxLabel
                style={{
                  color: disabled
                    ? FOHColors.GRAYSCALE_400
                    : FOHColors.GRAYSCALE_700
                }}
              >
                {option.label}
              </CheckboxLabel>
            </FOHView>
          </FOHTouchableOpacity>
        </>
      ))}
    </Container>
  );
};

const Container = styled(FOHView)`
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
`;

const CheckboxLabel = styled(Text)`
  font-family: ${FOHFonts.MEDIUM};
  font-size: 14px;
  line-height: 20px;
`;
