import React from 'react';

import { prop, path, defaultTo } from 'ramda';

import {
  FOHCheckbox,
  FOHDivider,
  FOHLabel,
  FOHLabelBold,
  FOHNotificationIcon,
  FOHSmallDetailLabel,
  FOHSpaceSmallest,
  FOHTouchableOpacity,
  FOHView,
  FOHColors
} from '../../ingredients';
import { FOHProfileImage } from '../../recipes';

export interface FOHChannelPreviewProps {
  members: Array<SBMember>;
  lastMessage: SBLastMessage;
  unreadMessageCount?: number;
  formattedLastReadMessageTime: string;
  restaurantName?: string;
  restaurantImage?: string;
  isActive: boolean;
  channelUrl: string;
  onChannelPress: () => void;
  sendbirdUserId: string;
  noDivider?: boolean;
  onChannelCheckboxPress?: () => void;
  isChannelSelected?: boolean;
}

export interface SBMember {
  nickname: string;
  plainProfileUrl: string;
  userId: string;
}

export interface SBSender extends SBMember {}

export interface SBLastMessage {
  sender: SBSender;
  message: string;
}

export const FOHChannelPreview = (props: FOHChannelPreviewProps) => {
  const talkingTo = prop(
    0,
    defaultTo([], prop('members', props)).filter(
      (member: SBMember) => prop('userId', member) !== props.sendbirdUserId
    )
  );
  const hasUnread = !!props.unreadMessageCount && props.unreadMessageCount > 0;
  const NameLabel = hasUnread ? FOHLabelBold : FOHLabel;
  return (
    <>
      <FOHTouchableOpacity
        onPress={props.onChannelPress}
        style={{
          padding: 12,
          backgroundColor: props.isActive ? FOHColors.LIGHT_BLUE : '#ffffff'
        }}
        testID={'FOHChannelPreview'}
      >
        <FOHView style={{ flexDirection: 'row', alignItems: 'center' }}>
          {props.onChannelCheckboxPress ? (
            <FOHTouchableOpacity onPress={props.onChannelCheckboxPress}>
              <FOHCheckbox selected={props.isChannelSelected || false} />
            </FOHTouchableOpacity>
          ) : null}
          <FOHView
            style={{
              height: 50,
              flexDirection: 'row'
            }}
          >
            <FOHProfileImage
              photo={
                props.restaurantImage || prop('plainProfileUrl', talkingTo)
              }
            />
            <FOHView style={{ paddingLeft: 8 }}>
              <FOHView
                style={{
                  flexDirection: 'row'
                }}
              >
                <FOHView style={{ flex: 1, width: 120 }}>
                  <NameLabel
                    numberOfLines={1}
                    style={{
                      fontWeight: props.isActive ? '600' : '400'
                    }}
                  >
                    {props.restaurantName
                      ? `${props.restaurantName}`
                      : prop('nickname', talkingTo)}
                  </NameLabel>
                </FOHView>
                <FOHView></FOHView>
                {props.formattedLastReadMessageTime ? (
                  <FOHView
                    style={{
                      top: 0,
                      left: -4,
                      flex: 1
                    }}
                  >
                    <FOHView
                      style={{
                        justifyContent: 'flex-end',
                        flex: 1,
                        width: 120
                      }}
                    >
                      <FOHSmallDetailLabel
                        numberOfLines={1}
                        style={{ textAlign: 'right', fontSize: 12 }}
                      >
                        {`${props.formattedLastReadMessageTime}`}
                      </FOHSmallDetailLabel>
                    </FOHView>
                  </FOHView>
                ) : null}
              </FOHView>
              <FOHView style={{ maxWidth: 40 }}></FOHView>
              <FOHView
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  paddingTop: 4
                }}
              >
                {hasUnread ? (
                  <>
                    <FOHNotificationIcon
                      style={{ background: FOHColors.RED }}
                    />
                    <FOHSpaceSmallest />
                  </>
                ) : null}
                <FOHSmallDetailLabel
                  numberOfLines={1}
                  style={{
                    fontWeight: hasUnread ? '800' : '400',
                    color: hasUnread ? '#000000' : '#878787',
                    width: 200,
                    textAlign: 'left'
                  }}
                >
                  {path(['lastMessage', 'message'], props)}
                </FOHSmallDetailLabel>
              </FOHView>
            </FOHView>
          </FOHView>
        </FOHView>
      </FOHTouchableOpacity>
      {props.noDivider ? <></> : <FOHDivider />}
    </>
  );
};
