import React, { useState, useContext, useEffect } from 'react';

import { last, path, defaultTo } from 'ramda';
import { useMatch } from 'react-router-dom';

import {
  EMPLOYER_GROUP,
  CANDIDATE_GROUP,
  CANDIDATE_ONBOARDING,
  EMPLOYER_ONBOARDING
} from '../../constants';
import { useNavigation } from '../../utils/navigation';

const OnboardingContext = React.createContext([{}, () => {}]);

const getStepIndexFromProps = location =>
  parseInt(last(defaultTo('', path(['pathname'], location)).split('/'))) || 1;

export const useOnboardingState = (
  props,
  group = EMPLOYER_GROUP,
  finishPath
) => {
  const { navigateTo, location: navLocation } = useNavigation();

  const match = useMatch(
    group === `/${CANDIDATE_GROUP}`
      ? `${CANDIDATE_ONBOARDING}/*`
      : `${EMPLOYER_ONBOARDING}/*`
  );

  const [state, setState] = useContext(OnboardingContext);

  const [stepIndex, setStepIndex] = useState(
    getStepIndexFromProps(navLocation)
  );

  const { signUpFlow } = state;

  const setSignUpFlow = (_signUpFlow = 'main-site') =>
    setState({
      ...state,
      signUpFlow: _signUpFlow
    });

  const nextStep = async () => {
    const nextIndex = stepIndex + 1;
    setStepIndex(nextIndex);
    return navigateTo({
      pathname: `${match.pathnameBase}/${nextIndex}`,
      search: navLocation.search
    });
  };

  const finishOnboarding = async (navigateURI = null) => {
    return await navigateTo({
      pathname: navigateURI || finishPath,
      search: navLocation.search
    });
  };

  const previousStep = async () => {
    const nextIndex = stepIndex - 1;
    if (nextIndex === 0) {
      return navigateTo(`/${group}`);
    }
    setStepIndex(nextIndex);
    return navigateTo({
      pathname: `${match.pathnameBase}/${nextIndex}`,
      search: navLocation.search
    });
  };

  useEffect(() => {
    // Incase browser back button or browser forward button
    const stepIndexFromURL = getStepIndexFromProps(navLocation);
    if (stepIndex !== stepIndexFromURL) {
      setStepIndex(stepIndexFromURL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path(['pathname'], navLocation)]);

  return {
    signUpFlow,
    setSignUpFlow,
    stepIndex,
    setStepIndex,
    nextStep,
    previousStep,
    finishOnboarding
  };
};

export const OnboardingProvider = props => {
  const [state, setState] = useState({
    signUpFlow: 'main-site'
  });
  return (
    <OnboardingContext.Provider value={[state, setState]}>
      {props.children}
    </OnboardingContext.Provider>
  );
};
