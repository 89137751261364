import React from 'react';

import video_ic from '../../../images/video_icon.svg';

import { FOHImage } from '../../ingredients';

export interface FOVideoIconProps {
  style?: any;
}

export const FOHVideoIcon = (props: FOVideoIconProps) => (
  <FOHImage
    source={{ uri: video_ic }}
    style={
      props && !!props.style
        ? { width: 24, height: 24, ...props.style }
        : { width: 24, height: 24 }
    }
  />
);
