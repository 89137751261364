import React, { useEffect, useState } from 'react';

import { Navigate } from 'react-router-dom';
import { path, prop } from 'ramda';
import { useTranslation } from 'react-i18next';

import {
  FOHGoogleAuthErrorHandler,
  FOHLoaderWithText,
  FOHView
} from '../../components';
import {
  handleMutation,
  storeAuth,
  useIsMobile,
  getSessionStorageItem,
  getAuthFlowControlRedirectURI,
  removeAuthFlowControlRedirectURI
} from '../../utils';
import {
  CANDIDATE_ONBOARDING_2,
  EMPLOYER_ONBOARDING_1,
  EMPLOYER_ONBOARDING_2,
  FEED,
  GROUPS,
  LOGIN_URL,
  REGISTER_URL
} from '../../constants';
import {
  useGetMeQuery,
  useMutateGoogleOAuthMutation
} from '../../graphql/generated';
import { getTokenFromLocalStorage } from '../../apolloClient';
import { useNavigation } from '../../utils/navigation';

import { getSearchQuery } from '../Search';
import useGoogleSSOAuth, {
  AUTH_DETAIL_STATE,
  GOOGLE_OAUTH_STATE
} from '../SignIn/useGoogleSSOAuth';
import { useEmployerOnboardingContext } from '../EmployerOnboarding';
import { useOnboardingState } from '../Navigation/useOnboardingState';
import { storeLoginAuth } from '../SignIn/LoginScreen';

interface ErrorHandlerProps {
  error: {
    errorLabel: string;
    errorSublabel: string;
    errorDescription?: string;
  };
  isEmailAlreadyExist: boolean;
  onManageExistingEmail: () => void;
  onRetry: () => void;
  onContactHelp: () => void;
}

const ErrorHandler: React.FC<ErrorHandlerProps> = ({
  error,
  isEmailAlreadyExist,
  onManageExistingEmail,
  onRetry,
  onContactHelp
}) => {
  const { t } = useTranslation('GoogleSSOFeature');
  const { navigateTo } = useNavigation();

  const isUserNotFoundError = error?.errorLabel === t('useNotFoundErrorLabel');

  return (
    <FOHGoogleAuthErrorHandler
      label={error?.errorLabel || t('errorLabel')}
      sublabel={
        isEmailAlreadyExist
          ? t('alreadyEmailExistText')
          : error?.errorSublabel || t('errorSubLabel')
      }
      errorMessage={isEmailAlreadyExist ? '' : error?.errorDescription || ''}
      button1Label={
        isUserNotFoundError
          ? t('GoogleSSOFeature:createNewAccountLabel')
          : isEmailAlreadyExist
          ? t('oAuthSignInLabel')
          : t('retryBtnLabel')
      }
      onButton1Press={() => {
        if (isUserNotFoundError) {
          navigateTo(REGISTER_URL);
        } else if (isEmailAlreadyExist) {
          onManageExistingEmail();
        } else {
          onRetry();
        }
      }}
      button2Label={
        isUserNotFoundError
          ? t('retryBtnLabel')
          : isEmailAlreadyExist
          ? t('goBackLabel')
          : t('contactHelpLabel')
      }
      onButton2Press={() => {
        if (isUserNotFoundError) {
          onRetry();
        } else if (isEmailAlreadyExist) {
          navigateTo(REGISTER_URL);
        } else {
          onContactHelp();
        }
      }}
      button3Label={isUserNotFoundError ? t('contactHelpLabel') : undefined}
      onButton3Press={isUserNotFoundError ? onContactHelp : undefined}
    />
  );
};

interface GoogleSSORedirectScreenProps {}

const GoogleSSORedirectScreen = (props: GoogleSSORedirectScreenProps) => {
  const { t } = useTranslation('GoogleSSOFeature');

  const { isMobile } = useIsMobile();
  const { navigateTo, location: navLocation } = useNavigation();

  const meQuery = useGetMeQuery({
    context: {
      header: getTokenFromLocalStorage()
    }
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [error, setError] = useState<
    | { errorLabel: string; errorSublabel: string; errorDescription?: string }
    | undefined
  >();

  const [redirecting, setIsRedirecting] = useState<boolean>(false);
  const [isEmailAlreadyExist, setIsEmailAlreadyExist] =
    useState<boolean>(false);

  const authDetail = getSessionStorageItem(AUTH_DETAIL_STATE) || undefined;
  const storeRedirectUri = getAuthFlowControlRedirectURI();

  const isAuthTypeEqualToRegister = authDetail?.auth_type === 'register';

  const candidateProfile = path(['data', 'me', 'candidateProfile'], meQuery);

  const [googleOauthMutation] = useMutateGoogleOAuthMutation();

  const { clearSessionStates, initiateGoogleAuth } = useGoogleSSOAuth();

  const { setLocation, setEmployerOnboardingState } =
    useEmployerOnboardingContext();
  const { setSignUpFlow } = useOnboardingState(props);

  const removeSessionAndNavigateToOriginPath = () => {
    removeAuthFlowControlRedirectURI();
    navigateTo(storeRedirectUri, { replace: true });
  };

  const checkErrorMessage = (message: string) => {
    const errorMessageRegex = /'This email is already in use.'/;
    return errorMessageRegex.test(message);
  };

  // USED TO PERFORM STATE MATCH AND BE MUTATION CALL
  const performStateMatchAndMutation = async () => {
    setError(undefined);
    setIsEmailAlreadyExist(false);
    setIsLoading(true);

    const sessionState = getSessionStorageItem(GOOGLE_OAUTH_STATE) || undefined;
    const receivedState = getSearchQuery(navLocation, 'state') as string;
    const googleAuthCode = getSearchQuery(navLocation, 'code') as string;

    if (sessionState !== receivedState) {
      clearSessionStates();
      setIsRedirecting(true);
      return;
    }

    const [response, _error] = await handleMutation(
      googleOauthMutation({
        variables: {
          authCode: googleAuthCode,
          authType: authDetail?.auth_type,
          userGroup: authDetail?.group || undefined
        }
      })
    );

    if (_error || !response) {
      setIsLoading(false);
      const errorMessage = _error?.message;

      if (errorMessage && errorMessage.includes("User doesn't exist")) {
        setError({
          errorLabel: t('useNotFoundErrorLabel'),
          errorSublabel: t('useNotFoundErrorSublabel')
        });
      } else {
        setIsEmailAlreadyExist(checkErrorMessage(errorMessage));
        setError({
          errorLabel: t('errorLabel'),
          errorSublabel: t('errorSubLabel'),
          errorDescription: errorMessage
        });
      }
      return;
    }

    await storeAuth({ token: path(['googleOauth', 'token'], response) });
    meQuery && (await meQuery.refetch());

    clearSessionStates();
    setIsLoading(false);

    return response;
  };

  useEffect(() => {
    (async () => {
      const response = await performStateMatchAndMutation();

      // HANDLE FOR NAVIGATING AFTER SIGNUP
      if (isAuthTypeEqualToRegister && response) {
        setSignUpFlow('main-site');

        if (path(['googleOauth', 'invitedLocation'], response)) {
          setEmployerOnboardingState({
            location: {
              slug: path(['googleOauth', 'invitedLocation'], response)
            },
            invitedBy: path(['googleOauth', 'invitedBy'], response)
          });
          return navigateTo(EMPLOYER_ONBOARDING_2, { replace: true });
        }

        if (path(['googleOauth', 'existingLocation'], response)) {
          setLocation({
            slug: path(['googleOauth', 'existingLocation'], response)
          });
          return navigateTo(EMPLOYER_ONBOARDING_2, { replace: true });
        }

        const nextStep =
          authDetail?.group === GROUPS.employers
            ? EMPLOYER_ONBOARDING_1
            : CANDIDATE_ONBOARDING_2;

        return navigateTo(nextStep, { replace: true });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // HANDLE FOR NAVIGATING AFTER SIGNIN
  useEffect(() => {
    if (meQuery && meQuery?.data?.me) {
      if (!isAuthTypeEqualToRegister) {
        if (storeRedirectUri) {
          removeSessionAndNavigateToOriginPath();
        } else {
          if (candidateProfile) {
            navigateTo(`/u/${prop('handle', candidateProfile)}`, {
              replace: true
            });
          } else {
            navigateTo(FEED, { replace: true });
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meQuery.data]);

  return (
    <>
      {/* WHEN GOOGLE AUTH STATE DIDN'T MATCHED THEN REDIRECT TO /register or /login */}
      {!!redirecting && (
        <Navigate to={isAuthTypeEqualToRegister ? REGISTER_URL : LOGIN_URL} />
      )}

      <FOHView
        style={{
          width: '100%',
          alignItems: 'center',
          paddingTop: 40,
          height: '100vh',
          paddingHorizontal: isMobile ? 15 : 0
        }}
      >
        {isLoading && <FOHLoaderWithText />}
        {error ? (
          <ErrorHandler
            error={error}
            isEmailAlreadyExist={isEmailAlreadyExist}
            onManageExistingEmail={() => {
              localStorage.removeItem('auth');
              storeLoginAuth();
              setTimeout(() => {
                initiateGoogleAuth();
              }, 300);
            }}
            onRetry={() => {
              if (storeRedirectUri) {
                removeSessionAndNavigateToOriginPath();
              } else {
                if (isAuthTypeEqualToRegister) {
                  navigateTo(REGISTER_URL);
                } else {
                  navigateTo(LOGIN_URL);
                }
              }
              clearSessionStates();
            }}
            onContactHelp={() =>
              window?.open('https://help.fohandboh.com', '_blank')
            }
          />
        ) : null}
      </FOHView>
    </>
  );
};

export default GoogleSSORedirectScreen;
