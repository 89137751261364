import React, { useRef } from 'react';

import styled from 'styled-components/native';
import { Transforms } from 'slate';
import { useHover } from 'react-native-web-hooks';

import {
  FOHBlackAdd,
  FOHColors,
  FOHErrorLabel,
  FOHFonts,
  FOHLabel,
  FOHMessageTemplate,
  FOHMessageTemplateBlue,
  FOHMessageTemplateSelectDropdown,
  FOHSmallDetailLabel,
  FOHSpaceSmall,
  FOHSpaceSmallest,
  FOHTouchableOpacity,
  FOHView
} from '../../components';

import SlateInput from './SlateInput';

export const FOHMessageInputSection = ({
  open,
  setOpen,
  onClose,
  label,
  filteredTemplates,
  template,
  onEditTemplate,
  search,
  setSearch,
  resetForm,
  placeHolderError,
  editor,
  inputMessageContent,
  setInputMessageContent,
  handleOnUseTemplate,
  setTemplate,
  placeholder,
  testID,
  t,
  children
}) => {
  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);

  return (
    <FOHView testID={testID}>
      <FOHView style={{ zIndex: 12 }}>
        <FieldLabel>{t('ProfileFeature:messageLabel')}</FieldLabel>
        <FOHSpaceSmall />
        <FOHView style={{ zIndex: 12 }}>
          <FOHMessageTemplateSelectDropdown
            fieldLabel={t('MessageTemplatesFeature:headerTitle')}
            placeholder={t('common:selectTemplateLabel')}
            icon={FOHMessageTemplateBlue}
            open={open}
            selectedLabel={label}
            setOpen={setOpen}
          >
            {open ? (
              <FOHMessageTemplate
                templateTitle={t('common:setInterviewTemplateLabel')}
                templateCategory={t('common:interviews')}
                onChangeSearch={value => setSearch(value)}
                searchValue={search}
                searchPlaceholder={t('common:searchTemplateLabel')}
                scrollHeight={240}
                messageTemplates={filteredTemplates}
                selectedTemplate={template}
                editTemplateLabel={t('common:editTemplates')}
                useLabel={t('JobApplicationsFeature:applyCTALabel')}
                editTemplate={onEditTemplate}
                onUseTemplate={() => handleOnUseTemplate()}
                setTemplate={setTemplate}
                close={() => {
                  if (onClose) onClose();
                  else resetForm();
                }}
              />
            ) : null}
          </FOHMessageTemplateSelectDropdown>
        </FOHView>
      </FOHView>
      {children ? (
        <>
          <FOHView>{children}</FOHView>
        </>
      ) : (
        <></>
      )}
      <FOHSpaceSmall />
      <FOHView>
        {placeHolderError ? (
          <FOHView
            style={{
              justifyContent: 'center',
              alignItems: 'flex-start',
              wordBreak: 'break-word'
            }}
          >
            <FOHErrorLabel>{`• ${placeHolderError}`}</FOHErrorLabel>
            <FOHSpaceSmall />
          </FOHView>
        ) : null}
      </FOHView>
      <FOHView
        style={{
          borderRadius: 4,
          borderWidth: 1,
          borderColor: FOHColors.GRAYSCALE_300,
          backgroundColor: FOHColors.WHITE,
          paddingVertical: 6,
          paddingHorizontal: 8,
          minHeight: 300,
          zIndex: -1
        }}
      >
        <FOHView
          style={{
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <FOHSmallDetailLabel
            style={{
              fontSize: 12,
              lineHeight: 12,
              textTransform: 'uppercase'
            }}
          >
            {t('MessageTemplatesFeature:messageContentLabel')}
          </FOHSmallDetailLabel>
          <FOHTouchableOpacity
            ref={hoverRef}
            onPress={() => {
              Transforms.insertText(editor, `{{${placeholder}}}`);
            }}
          >
            <FOHView
              style={{
                backgroundColor: FOHColors.PACIFIC_BLUE,
                paddingHorizontal: 8,
                paddingVertical: 2,
                borderRadius: 14,
                position: 'relative'
              }}
            >
              <FOHSmallDetailLabel style={{ color: FOHColors.WHITE }}>
                {placeholder}
              </FOHSmallDetailLabel>
            </FOHView>
            {isHovered ? (
              <FOHView
                style={{
                  position: 'absolute',
                  width: '100%',
                  alignItems: 'center',
                  paddingHorizontal: 8,
                  paddingVertical: 2,
                  borderRadius: 14,
                  backgroundColor: FOHColors.PRIMARY_TEAL_10,
                  flexDirection: 'row',
                  justifyContent: 'center'
                }}
              >
                <FOHBlackAdd
                  style={{
                    width: 18,
                    height: 18,
                    tintColor: FOHColors.PRIMARY_TEAL_P1
                  }}
                />
                <FOHSpaceSmallest />
                <FOHSmallDetailLabel
                  style={{
                    color: FOHColors.PRIMARY_TEAL_P1,
                    textDecoration: 'underline'
                  }}
                >
                  {t('MessageTemplatesFeature:populateTemplateAddLabel')}
                </FOHSmallDetailLabel>
              </FOHView>
            ) : null}
          </FOHTouchableOpacity>
        </FOHView>
        <FOHSpaceSmall />
        <SlateInput
          maxCount={500}
          editor={editor}
          value={inputMessageContent}
          setValue={setInputMessageContent}
        />
      </FOHView>
    </FOHView>
  );
};

const FieldLabel = styled(FOHLabel)`
  font-family: ${FOHFonts.MEDIUM};
  line-height: 16px;
  text-transform: uppercase;
  color: ${FOHColors.PRIMARY_TEAL_P1};
`;
