import React from 'react';

import { FOHLabel } from '../text/FOHLabel';

interface FOHDownloadLinkProps {
  href: string;
  name?: string;
  children?: React.ReactNode;
  style?: any;
}

export const FOHDownloadLink: React.FC<FOHDownloadLinkProps> = ({
  href,
  name,
  children,
  style
}) => (
  <FOHLabel
    onPress={() => {
      // Fixes cors issue since link is already rendered on page but also needs to be downloaded
      // This can also be helpful if you have images that are rendered and need to be reuploaded
      fetch(`${href}?bustcache=true`).then(resp => {
        resp.blob().then(data => {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(data);
          link.setAttribute('download', name || href);
          link.click();
        });
      });
    }}
    style={{ ...style, textDecoration: 'underline' }}
  >
    {children}
  </FOHLabel>
);
