import React, { ReactNode } from 'react';

import { currencyFormat } from '../../utils';
import {
  FOHLabel,
  FullWidthCard,
  FOHSpaceSmall,
  FOHColors,
  FOHLabelBold,
  FOHZiprecruiterIcon,
  FOHIndeedIcon,
  ButtonText,
  FOHEditMarkerBlack,
  FOHView,
  FOHTouchableOpacity
} from '../../ingredients';
import { FOHProgressCampaignBar } from '../../recipes';

import { CampaignItem, FOHMessageIconView } from './styled';

export const platformType = {
  ziprecruiter: () => <FOHZiprecruiterIcon />,
  indeed: () => <FOHIndeedIcon />
};

export interface FOHCampaignCellProps {
  name: string;
  onPressName: (handle: string) => void;
  handle: string;
  selected: boolean;
  onCheckBoxPress: (handle: string) => void;
  campaignType?: string;
  jobCount?: number;
  clickCount: number;
  applyCount: number;
  avgCPA: number;
  totalBudget: number;
  spentBudget?: number;
  onPressJobs?: () => void;
  children?: Array<ReactNode>;
  hasStatusBackground?: boolean;
  platform?: string;
  editTemplate: (handle: string) => void;
  editLabel: string;
  progressLabel?: string;
  jobLabel?: string;
  selectedTab?: string;
  schedule?: string;
  position?: string;
}

export const FOHCampaignCell = (props: FOHCampaignCellProps) => {
  const platform = props.platform || 'ziprecruiter';
  const selectedTab = props.selectedTab;

  return (
    <FullWidthCard
      style={{
        border: 'none',
        height: 88,
        padding: 16,
        paddingTop: 12
      }}
    >
      <FOHView
        style={{
          flexDirection: 'row',
          alignItems: 'center'
        }}
        testID={`FOHCampaignCell-${props.name}`}
      >
        <FOHView
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            flex: selectedTab === 'DraftCampaigns' ? 2 : 6
          }}
        >
          <FOHView style={{ flex: 1 }}>
            <FOHView style={{ flexDirection: 'row', alignItems: 'center' }}>
              <FOHTouchableOpacity
                onPress={() => props.onPressName(props.handle)}
                style={{ maxWidth: 200 }}
                testID={'FOHCampaignCell-campaign-name'}
              >
                <FOHLabelBold
                  numberOfLines={1}
                  style={{
                    textDecorationLine: 'underline',
                    fontSize: 18,
                    fontWeight: '700'
                  }}
                >
                  {props.name}
                </FOHLabelBold>
              </FOHTouchableOpacity>
              <FOHSpaceSmall />
            </FOHView>
          </FOHView>
        </FOHView>
        {selectedTab === 'DraftCampaigns' ? (
          <CampaignItem>
            <FOHLabel
              numberOfLines={1}
              style={{
                fontSize: 16,
                fontWeight: '700'
              }}
            >
              {props.position}
            </FOHLabel>
          </CampaignItem>
        ) : (
          <CampaignItem>
            <FOHLabel
              style={{
                fontSize: 18,
                fontWeight: '700'
              }}
            >
              {props.clickCount && props.clickCount !== 0
                ? props.clickCount.toLocaleString()
                : '_'}
            </FOHLabel>
          </CampaignItem>
        )}
        {selectedTab === 'DraftCampaigns' ? (
          <CampaignItem>
            <FOHLabel
              style={{
                fontSize: 18,
                fontWeight: '700'
              }}
            >
              {props.platform === 'indeed' ? props.schedule : '_'}
            </FOHLabel>
          </CampaignItem>
        ) : (
          <CampaignItem>
            <FOHLabel
              style={{
                fontSize: 18,
                fontWeight: '700'
              }}
            >
              {props.applyCount && props.applyCount !== 0
                ? props.applyCount.toLocaleString()
                : '_'}
            </FOHLabel>
          </CampaignItem>
        )}
        {selectedTab !== 'DraftCampaigns' && (
          <CampaignItem>
            <FOHLabel
              style={{
                fontSize: 18,
                fontWeight: '700'
              }}
            >
              {props.avgCPA && props.avgCPA !== 0
                ? currencyFormat(props.avgCPA)
                : '_'}
            </FOHLabel>
          </CampaignItem>
        )}
        {selectedTab !== 'DraftCampaigns' && (
          <CampaignItem style={{ flex: 4 }}>
            <FOHProgressCampaignBar
              totalBudget={props.totalBudget}
              spentBudget={props.spentBudget}
              progressLabel={props.progressLabel}
            />
          </CampaignItem>
        )}
        <CampaignItem>
          <FOHLabel
            style={{
              fontSize: 18,
              fontWeight: '700'
            }}
          >
            {props.totalBudget && props.totalBudget !== 0
              ? currencyFormat(props.totalBudget)
              : '_'}
          </FOHLabel>
        </CampaignItem>
        <CampaignItem style={{ flex: 1 }}>
          {
            //@ts-ignore platformType
            platformType[platform]()
          }
        </CampaignItem>
        <CampaignItem style={{ flex: 1 }}>
          <FOHTouchableOpacity
            //@ts-ignore props.editTemplate
            onPress={props.editTemplate}
            disabled={false}
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'row'
            }}
          >
            <FOHMessageIconView>
              <FOHEditMarkerBlack />
            </FOHMessageIconView>
            <ButtonText
              style={{
                color: FOHColors.GRAYSCALE_700,
                fontSize: 16,
                textDecorationLine: 'underline'
              }}
            >
              {props.editLabel}
            </ButtonText>
          </FOHTouchableOpacity>
        </CampaignItem>
      </FOHView>
    </FullWidthCard>
  );
};
