import React from 'react';

import styled from 'styled-components/native';
import { View, TouchableOpacity, Image } from 'react-native';

import {
  FOHSectionLabel,
  FOHDetailText,
  FOHLinkOut,
  FOHEditMarker,
  FOHSpace,
  FOHBlankImage,
  FOHLabel,
  FOHColors
} from '../../ingredients';

interface FOHWorkHistoryCellSmallProps {
  position: string;
  employerName: string;
  start: string;
  image?: string;
  responsibilities?: string;
  end: string;
  id?: string;
  edit?: (id: string) => void;
  locationLink?: string;
}

export const FOHSmallWorkHistoryCell = (
  props: FOHWorkHistoryCellSmallProps
) => (
  <>
    <Row style={{ width: '100%' }}>
      {props.edit && props.id ? (
        <TouchableOpacity
          testID={`edit-work-history-${props.employerName}`}
          style={{
            position: 'absolute',
            right: 0,
            zIndex: 20
          }}
          onPress={() => {
            props.edit && props.id && props.edit(props.id);
          }}
        >
          <FOHEditMarker style={{ tintColor: FOHColors.PRIMARY_TEAL_P1 }} />
        </TouchableOpacity>
      ) : (
        <></>
      )}
      <View style={{ paddingRight: 16 }}>
        {!props.image ? (
          <FOHBlankImage style={{ width: 56, height: 56 }} />
        ) : (
          <Image
            source={{ uri: props.image }}
            style={{ width: 56, height: 56, borderRadius: 2 }}
          />
        )}
      </View>
      <Field>
        <View>
          <SafeTextBold>{props.position}</SafeTextBold>
        </View>
        <>
          {props.locationLink ? (
            <>
              <FOHLinkOut
                noIcon={true}
                href={props.locationLink}
                labelStyle={{ lineHeight: 24 }}
              >
                {props.employerName}
              </FOHLinkOut>
            </>
          ) : (
            <>
              <SafeText style={{ lineHeight: 24 }}>
                {props.employerName}
              </SafeText>
            </>
          )}
        </>
      </Field>
      <View style={{ flex: 1, flexDirection: 'row' }}>
        <SafeTextRight
          style={{
            top: props.edit && props.id ? 32 : 5
          }}
        >
          {props.start} {props.start && props.end ? '-' : ''} {props.end}
        </SafeTextRight>
      </View>
    </Row>
    {props.responsibilities ? (
      <>
        <FOHSpace />
        <FOHLabel
          style={{
            fontWeight: '400',
            lineHeight: 24,
            color: FOHColors.GRAYSCALE_700
          }}
        >
          {props.responsibilities}
        </FOHLabel>
      </>
    ) : (
      <></>
    )}
  </>
);

const Row = styled(View)`
  flex-direction: row;
  justify-content: flex-start;
  padding-top: 16px;
  white-space: pre-wrap;
`;

const Field = styled(View)`
  flex: 1;
  justify-content: flex-start;
`;

const SafeText = styled(FOHDetailText)`
  word-break: break-word;
  color: #171717;
  text-align: left;
  width: 90%;
`;

const SafeTextBold = styled(FOHSectionLabel)`
  word-wrap: break-word;
  white-space: pre-wrap;
  color: ${FOHColors.GRAYSCALE_G1};
  font-size:  18px;
  line-height: 28px
  text-align: left;
`;

const SafeTextRight = styled(FOHDetailText)`
  word-wrap: break-word;
  justify-content: flex-start;
  text-align: right;
  color: ${FOHColors.GRAYSCALE_500};
  font-weight: 400;
  right: 0px;
  position: absolute;
  display: block;
  flex: 1;
  top: 32px;
  z-index: 19;
  line-height: 24px;
`;
