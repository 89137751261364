import React from 'react';

import styled from 'styled-components/native';
import { View } from 'react-native';

import {
  FOHLabelSmall,
  FOHLabelSecondary,
  FOHTouchableOpacity
} from '../../ingredients';

export interface FOHSelectButtonCellProps {
  label: string;
  onPress: (value: string, section: string) => void;
  selected: boolean;
  value: string;
  section: string;
  small?: boolean;
}

export const FOHSelectButtonCell = (props: FOHSelectButtonCellProps) => (
  <Border
    style={{
      backgroundColor: props.selected ? '#51C1DB' : '#FFFFFF',
      width: props.small ? 93 : 160
    }}
    testID={'availability-choice'}
  >
    <FOHTouchableOpacity
      style={{
        width: '80%',
        height: 30,
        justifyContent: 'center',
        alignItems: 'center'
      }}
      onPress={e => {
        e.preventDefault();
        props.onPress(props.value, props.section);
      }}
    >
      {props.selected ? (
        <FOHLabelSecondary>{props.label}</FOHLabelSecondary>
      ) : (
        <FOHLabelSmall>{props.label}</FOHLabelSmall>
      )}
    </FOHTouchableOpacity>
  </Border>
);

const Border = styled(View)`
  align-items: center;
  border-radius: 6px;
  justify-content: center;
  width: 160px;
  height: 50px;
  border: 1px solid #e8e8e8;
  margin: 4px;
`;
