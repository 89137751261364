import React from 'react';

import restaurant from '../../../images/restaurant.svg';

import { FOHImage } from '../../ingredients';

export interface FOHRestaurantIconProps {
  style?: any;
}

export const FOHRestaurantIcon = (props: FOHRestaurantIconProps) => (
  <FOHImage
    source={{ uri: restaurant }}
    style={
      props && !!props.style
        ? { width: 20, height: 18, ...props.style }
        : { width: 20, height: 18 }
    }
  />
);
