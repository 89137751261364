import React, { FunctionComponent } from 'react';

import open from '../../../images/open.svg';

import { FOHImage } from '../../ingredients';
import { FOHIconProps } from '../../typings';

export const FOHOpenIcon: FunctionComponent<FOHIconProps> = ({ style }) => (
  <FOHImage source={{ uri: open }} style={[{ width: 18, height: 18 }, style]} />
);
