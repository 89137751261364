import React, { useState, useRef, useEffect } from 'react';

import { prop, path, compose, defaultTo } from 'ramda';
import { useTranslation } from 'react-i18next';

import {
  FOHScrollView,
  FOHView,
  FOHGuideBanner,
  FOHBannerType,
  FOHEmployerAccountForm
} from '../../components';
import {
  useGetMyPhotoQuery,
  useMutateProfileImageMutation,
  useRefreshSbTokenMutation,
  useUpdateUserMutation
} from '../../graphql/generated';
import {
  useIsMobile,
  handle,
  scrollToTop,
  handleMutation,
  formatPhoneNumber
} from '../../utils';
import { registerScreenErrorTransform } from '../../api/transformers/register';
import { passwordResetRequest } from '../../api/auth';

import { useBanner } from '../Navigation/useBanner';
import { getProfilePhoto } from '../Navigation/TopNav';
import { useGetMeData } from '../SignIn';

import { withEmployerSettings } from './withEmployerSettings';

const EmployerAccount = _props => {
  const { isMobile } = useIsMobile();
  const { data: me } = useGetMeData();
  const myPhotoQuery = useGetMyPhotoQuery({});
  const profileImage = getProfilePhoto(myPhotoQuery);

  const [mutateProfileImage] = useMutateProfileImageMutation();
  const [userMutation] = useUpdateUserMutation();
  const [refreshSBUser] = useRefreshSbTokenMutation();

  const { t } = useTranslation('EmployerAccountFeature');
  const scrollRef = useRef();

  const [photoLoading, setPhotoLoading] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [openWelcome, setOpenWelcome] = useState(false);
  const [photo, setPhoto] = useState('');
  const [fileToUpload, setFileToUpload] = useState(null);
  const [error, setError] = useState();

  const inputFile = useRef(null);
  const reader = new FileReader();
  reader.onload = e => {
    const blob = e.target.result;
    setPhoto(blob);
  };

  useEffect(() => {
    if (me) {
      setFirstName(prop('firstName', me));
      setLastName(prop('lastName', me));
      setPhone(defaultTo('', formatPhoneNumber(prop('phone', me))));
      setEmail(prop('email', me));
      setOpenWelcome(prop('unclaimed', me));
      setPhoto(profileImage);
    }
  }, [me, profileImage]);

  const { setBanner } = useBanner();

  const handleScrollToTop = () => {
    scrollToTop({});
    scrollRef.current &&
      scrollRef.current.scrollTo({
        y: 0,
        animated: true
      });
  };

  return (
    <>
      {/* make invisible input to trigger uploads */}
      <input
        type="file"
        id="file"
        accept="image/png, image/jpeg, image/jpg"
        onChange={e => {
          const file = e.target.files[0];
          file && setFileToUpload(file);
          file && reader.readAsDataURL(file);
        }}
        ref={inputFile}
        style={{ display: 'none' }}
      />
      <FOHView
        style={{
          backgroundColor: '#F5F5F5',
          alignItems: isMobile ? 'center' : 'flex-start'
        }}
      >
        <FOHScrollView
          style={{
            height: isMobile ? undefined : '80vh',
            width: '100%'
          }}
          ref={scrollRef}
        >
          <FOHView
            style={{
              paddingHorizontal: isMobile ? 14 : 28,
              paddingTop: isMobile ? 6 : 0
            }}
          >
            <FOHGuideBanner
              open={openWelcome}
              mobile={isMobile}
              close={() => setOpenWelcome(!openWelcome)}
              iconText={t('welcome')}
              title={t('letsGetYouSetUp')}
              body={t('instructions')}
              style={{
                marginTop: 0,
                marginRight: 0,
                marginLeft: 0,
                marginBottom: '4%'
              }}
            />
            <FOHEmployerAccountForm
              photo={photo}
              fullName={`${prop('firstName', me)} ${prop('lastName', me)}`}
              uploadLabel={
                photoLoading
                  ? t('UploadPhotoFeature:uploading')
                  : fileToUpload || photo
                  ? t('replacePhoto')
                  : t('uploadAPhoto')
              }
              pickPhoto={() => {
                inputFile && inputFile.current.click();
              }}
              error={error}
              firstName={firstName}
              firstNamePlaceholder={t('firstNamePlaceholder')}
              setFirstName={setFirstName}
              lastName={lastName}
              lastNamePlaceholder={t('lastNamePlaceholder')}
              setLastName={setLastName}
              phoneNumber={phone}
              phoneNumberPlaceholder={t('phoneNumberPlaceholder')}
              setPhoneNumber={setPhone}
              email={email}
              emailPlaceholder={t('emailPlaceholder')}
              setEmail={setEmail}
              submitLabel={
                isLoading ? `${t('CandidateFeed:saving')}...` : t('save')
              }
              disabledSubmit={isLoading || photoLoading}
              handleSubmit={async () => {
                setIsLoading(true);
                fileToUpload && setPhotoLoading(true);
                const [photoRes, imageCreateError] = fileToUpload
                  ? await handleMutation(
                      mutateProfileImage({
                        variables: {
                          fileType: fileToUpload.type,
                          fileName: fileToUpload.name
                        }
                      })
                    )
                  : [undefined, undefined];

                const [, imageUploadError] = fileToUpload
                  ? await handle(
                      fetch(
                        path(
                          ['mutateProfileImage', 'imageUploadUrl'],
                          photoRes
                        ),
                        {
                          method: 'PUT',
                          body: fileToUpload
                        }
                      )
                    )
                  : [undefined, undefined];

                if (imageUploadError || imageCreateError) return;

                const photoId = path(
                  ['mutateProfileImage', 'profileImage', 'rowId'],
                  photoRes
                );

                const variablesWithImage = photoId
                  ? {
                      profileImageId: photoId
                    }
                  : {};

                setError(null);

                const [response, errors] = await handleMutation(
                  userMutation({
                    variables: {
                      ...variablesWithImage,
                      id: prop('id', me),
                      firstName,
                      lastName,
                      phone,
                      email
                    }
                  })
                );

                if (response && !errors) {
                  if (prop('sendbirdAccessToken', me)) {
                    await handleMutation(refreshSBUser());
                  }

                  setIsLoading(false);
                  setPhotoLoading(false);

                  handleScrollToTop();

                  setBanner({
                    bannerType: FOHBannerType.SUCCESS,
                    bannerMessage: t('common:success')
                  });
                } else {
                  setError({
                    firstName: prop('first_name', errors),
                    lastName: prop('last_name', errors),
                    email:
                      prop('email', errors) ||
                      (errors?.message?.includes('Duplicate entry') &&
                        t('common:duplicateEntry')),
                    ...errors
                  });
                  setIsLoading(false);
                }
              }}
              resetPasswordHeader={t('resetPasswordHeader')}
              resetPasswordDescrition={t('resetPasswordDesc')}
              resetLabel={t('resetPasswordBtnLabel')}
              handleReset={async () => {
                const data = { email };
                const [response, respError] = await handle(
                  passwordResetRequest(data)
                );

                if (respError) {
                  const err = registerScreenErrorTransform(
                    respError.fieldErrors
                  );
                  return setError(err);
                } else {
                  handleScrollToTop();

                  setBanner({
                    bannerType: FOHBannerType.SUCCESS,
                    bannerMessage: response?.detail
                  });
                }
              }}
            />
          </FOHView>
        </FOHScrollView>
      </FOHView>
    </>
  );
};

export default compose(withEmployerSettings)(EmployerAccount);
