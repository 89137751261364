import React from 'react';

import styled from 'styled-components/native';
import { TouchableOpacity, View, Image } from 'react-native';
import { length } from 'ramda';

import {
  FOHLabel,
  FOHLabelBold,
  FOHSwitch,
  FOHTextButton,
  FOHSmallDetailLabel
} from '../../ingredients';
import { FOHPositionHiringStatusType } from '../../typings';

import {
  FOHPositionHiringStatusDropdown,
  FOHPositionHiringStatusSelectProps
} from '../select/FOHPositionHiringStatusDropdown';

export interface FOHTableRowProps {
  id: string;
  columns: Array<FOHTableColumn>;
  data: Array<FOHTableColumnData>;
}

export enum FOHTableColumnType {
  subtext = 'subtext',
  switch = 'switch',
  status = 'status',
  text = 'text',
  image = 'image',
  action = 'action',
  statusDropdown = 'statusDropdown'
}

export interface FOHTableColumn {
  columnType: FOHTableColumnType;
  columnName: string;
  onPress?: (val: any) => any;
  label?: string;
  hidden?: boolean;
  // statusDropdown
  statusOptions?: Array<FOHPositionHiringStatusSelectProps>;
  setOpen?: (open: boolean) => any;
}

export interface FOHTableColumnData {
  id?: string;
  text?: string;
  subtext?: string;
  value?: boolean;
  disabled?: boolean;
  statusType?: FOHPositionHiringStatusType;
  statusText?: string;
  open?: boolean;
  image?: string;
}

interface FOHTableColumnProps extends FOHTableColumn, FOHTableColumnData {
  numberOfColumns?: number;
  style?: any;
}

export const FOHColumn = (props: FOHTableColumnProps) => {
  return props.hidden ? (
    <TableCol {...props}></TableCol>
  ) : (
    <TableCol {...props}>
      {
        {
          subtext: (
            <View
              style={{
                maxWidth: '80%'
              }}
            >
              <TouchableOpacity
                onPress={() => props.onPress && props.onPress(props.id)}
                disabled={!props.onPress}
              >
                <FOHLabelBold
                  style={{
                    textAlign: 'left',
                    textDecorationLine: props.onPress ? 'underline' : undefined
                  }}
                >
                  {props.text}
                </FOHLabelBold>
              </TouchableOpacity>
              <FOHLabel>{props.subtext}</FOHLabel>
            </View>
          ),
          status: (
            <>
              <Status {...props}>{props.text}</Status>
              {props.subtext ? (
                <FOHSmallDetailLabel
                  style={{ textAlign: 'left', paddingLeft: 2, paddingTop: 4 }}
                >
                  {props.subtext}
                </FOHSmallDetailLabel>
              ) : (
                <></>
              )}
            </>
          ),
          text: <FOHLabel>{props.text}</FOHLabel>,
          // Actions
          action: (
            <FOHTextButton
              // Text value of specific row can overwrite the column's action label
              title={props.text || props.label || ''}
              disabled={props.disabled}
              onPress={() => props.onPress && props.onPress(props.id)}
            />
          ),
          switch: (
            <View>
              <FOHSwitch
                value={props.value || false}
                onValueChange={() => props.onPress && props.onPress(props.id)}
                label={props.label}
              />
            </View>
          ),
          statusDropdown: (
            <View>
              {props.setOpen &&
              props.statusText &&
              props.statusType &&
              props.statusOptions ? (
                <FOHPositionHiringStatusDropdown
                  openStatus={!!props.open}
                  statusText={props.statusText}
                  statusType={props.statusType}
                  setOpenStatus={props.setOpen}
                  selectStatus={status => {
                    props.onPress && props.onPress({ status, id: props.id });
                    props.setOpen && props.setOpen(false);
                  }}
                  statusOptions={props.statusOptions}
                />
              ) : (
                <></>
              )}
            </View>
          ),
          image: (
            <Image
              source={{ uri: props.image }}
              style={{
                backgroundColor: '#ffffff',
                ...props.style
              }}
            />
          )
        }[props.columnType]
      }
    </TableCol>
  );
};

export const FOHTableRow = (props: FOHTableRowProps) => {
  const dataColumns = props.columns.filter(
    column => column.columnType !== FOHTableColumnType.action
  );
  const actionColumns = props.columns.filter(
    column => column.columnType === FOHTableColumnType.action
  );
  return (
    <TableRow key={props.id} style={{ backgroundColor: '#ffffff' }}>
      {dataColumns.map((column, i) => {
        const columnProps = { ...column, ...props.data[i] };
        return (
          <FOHColumn
            key={`${i}${column.columnName}`}
            id={props.id}
            style={{
              zIndex:
                columnProps.columnType === FOHTableColumnType.statusDropdown
                  ? 50
                  : 1
            }}
            {...columnProps}
          />
        );
      })}
      {/* row actions */}
      <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
        {actionColumns.map((column, i) => {
          const columnProps = {
            ...column,
            ...props.data[i + length(dataColumns)]
          };
          return (
            <FOHColumn
              numberOfColumns={length(props.columns)}
              key={`${i}${column.columnName}`}
              id={props.id}
              style={{
                alignItems: 'flex-end',
                zIndex:
                  columnProps.columnType === FOHTableColumnType.statusDropdown
                    ? 50
                    : 1
              }}
              {...columnProps}
            />
          );
        })}
      </View>
    </TableRow>
  );
};

export const TableRow = styled(View)`
  min-height: 96px;
  width: 100%;
  border-radius: 6px;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  flex-wrap: wrap;
`;

export const TableCol = styled(View)`
  flex: ${(props: FOHTableColumnProps) =>
    props.columnType === FOHTableColumnType.action ? 1 : props.numberOfColumns};
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-right: 4px;
  min-width: ${(props: FOHTableColumnProps) =>
    props.columnType === FOHTableColumnType.action ||
    props.columnType === FOHTableColumnType.status
      ? '100px'
      : '150px'};
`;

const Status = styled(FOHLabel)`
  color: ${(props: FOHTableColumnProps) =>
    props.value ? '#38a56b' : '#F16110'};
  background-color: ${(props: FOHTableColumnProps) =>
    props.value ? '#dff1e7' : '#FEEDE4'};
  border-radius: 100px;
  padding: 4px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 12px;
  text-align: left;
  margin: 0;
`;
