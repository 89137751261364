import React from 'react';

import styled from 'styled-components/native';

import {
  FOHHeaderH1,
  FOHSpaceSmall,
  FOHSpace,
  FOHView,
  FOHDetailText,
  FOHInverseButton,
  FOHTealRocketIcon,
  FOHBoostIcon,
  FOHColors
} from '../../ingredients';

export interface FOHGetMoreApplicantsCardProps {
  headerText: string;
  subHeaderText: string;
  detailTextComp: JSX.Element;
  disabled?: boolean;
  helpText?: string;
  submitBtnLabel: string;
  onPressSubmit: () => void;
}

export const FOHGetMoreApplicantsCard = (
  props: FOHGetMoreApplicantsCardProps
) => (
  <Border>
    <FOHSpaceSmall />
    <Row>
      <FOHTealRocketIcon />
    </Row>
    <FOHSpace />
    <Row>
      <FOHView style={{ paddingHorizontal: 10 }}>
        <FOHHeaderH1>{props.headerText}</FOHHeaderH1>
        <FOHHeaderH1>{props.subHeaderText}</FOHHeaderH1>
      </FOHView>
    </Row>
    <FOHSpaceSmall />
    <Row>
      <FOHView style={{ paddingHorizontal: 30 }}>
        <FOHDetailText>{props.detailTextComp}</FOHDetailText>
      </FOHView>
    </Row>
    <FOHSpace />
    <Row>
      <FOHInverseButton
        icon={() => <FOHBoostIcon style={{ tintColor: FOHColors.WHITE }} />}
        color={props.disabled ? FOHColors.GREY : FOHColors.PRIMARY_TEAL_P1}
        style={{ maxWidth: 350 }}
        textColor={FOHColors.WHITE}
        onPress={props.onPressSubmit}
        title={props.submitBtnLabel}
        opaque={true}
        selected={true}
        disabled={props.disabled}
        enableHover={props.disabled}
        helpText={props.helpText}
        canSelect={props.disabled}
      />
    </Row>
    <FOHSpaceSmall />
  </Border>
);

const Border = styled(FOHView)`
  border-radius: 6px;
  padding-top: 6px;
  border: 1px solid #e8e8e8;
  min-height: 350px;
  max-height: 515px;
  padding: 20px;
  max-width: 600px;
  min-width: 293px;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: ${FOHColors.WHITE};
`;

const Row = styled(FOHView)`
  justify-content: center;
  align-items: center;
  width: 100%;
`;
