import React, { useEffect, useMemo } from 'react';

import { compose, defaultTo, path } from 'ramda';
import Loadable from '@loadable/component';
import { Navigate, useRoutes } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { FOHView } from './components';
import {
  ProtectedRoute,
  LoginScreen,
  RegisterScreen,
  PositionTypeScreen,
  AvailabilityScreen,
  UploadPhotoScreen,
  UploadVideoScreen,
  AddEmploymentScreen,
  WorkHistoryScreen,
  LocationScreen,
  PersonalSummaryScreen,
  AccountMenuScreen,
  AccountInformationScreen,
  SkillsScreen,
  RestaurantStyleScreen,
  TopThreeScreen,
  YearsExpScreen,
  ConfirmEmailScreen,
  InterviewResponseScreen,
  CandidateScheduleInterviewScreen,
  CandidateAddToCalendarScreen,
  NotificationPreferenceScreen,
  ProfileScreen,
  CandidateInboxFeed,
  NotFound404,
  PasswordResetScreen,
  PasswordResetReturnScreen,
  Screen403,
  LocationFeedScreen,
  EmployerOnboardingRouter,
  JobScreen,
  CandidateOnboardingRouter,
  CompleteApplicationRouter,
  ClaimAccountScreen,
  EmployerCandidateQuestionnaire,
  withNav,
  EmployerLocationsDashboard,
  CandidateOffer,
  CandidateWelcomeLetter,
  CandidateNetworkSearchScreen,
  CampaignHome,
  CreateCampaign,
  AddNewLocation,
  NotificationSettings,
  EmployerAccount,
  GoogleSSORedirectScreen,
  GroupSpecificSignUp,
  EmployerSettingsRouter,
  EmployerInterviewAvailability,
  PendingEmployerProfile,
  Subscribe,
  HelpRequestScreen
} from './features';
import LocationDetailScreen from './features/LocationFeed/LocationDetailScreen';
import NewPositionScreen from './features/EmployerPositions/NewPositionScreen';
import RequestEndorseScreen from './features/Endorse/RequestEndorseScreen';
import WorkPreferencesScreen from './features/CandidateProfile/WorkPreferencesScreen';
import CampaignDetails from './features/Campaigns/CampaignDetails';
import Integrations from './features/Integrations/IntegrationsScreen';
import IndeedOAuthRedirectScreen from './features/Integrations/IndeedOAuthRedirectScreen';
import { CandidateHome } from './features/CandidateHome';
import {
  CareerPageScreen,
  CareerPageFormScreen,
  CareerPageDashboard
} from './features/CareerPage';
import { PositionDashboard } from './features/EmployerDashbaord/PositionDashboard';
import { ResumeUploadScreen } from './features/CandidateProfile/ResumeUploadScreen';
import { withChatWindow } from './features/Chat/withChatWindow';
import { UpcomingAgenda } from './features/UpcomingAgenda/UpcomingAgenda';
import { OAuth2Popup } from './features/Campaigns';
import {
  LOGIN_URL,
  REGISTER_URL,
  CANDIDATE_PROFILE_FORM_URL,
  FEED_WITH_SEGMENTS,
  PASSWORD_RESET_URL,
  PASSWORD_RESET_RETURN_URL,
  CANDIDATE_POSITION_PATH,
  CANDIDATE_AVAILABILITY_PATH,
  CANDIDATE_PHOTO_PATH,
  CANDIDATE_VIDEO_PATH,
  CANDIDATE_WORK_HISTORY_PATH,
  CANDIDATE_ADD_EMPLOYMENT_PATH,
  CANDIDATE_LOCATION_PATH,
  CANDIDATE_PERSONAL_SUMMARY_PATH,
  CANDIDATE_ACCOUNT_MENU_PATH,
  CANDIDATE_ACCOUNT_INFO_PATH,
  CANDIDATE_SKILLS_PATH,
  CANDIDATE_TOP_THREE_PATH,
  CANDIDATE_RESTAURANT_STYLE_PATH,
  CANDIDATE_YEARS_EXPERIENCE_PATH,
  CANDIDATE_WORK_PREFERENCES_PATH,
  CANDIDATE_CONFIRM_EMAIL_PATH,
  INTERVIEW_DETAIL_PATH,
  CANDIDATE_NOTIFICATION_PREFERENCE_PATH,
  CANDIDATE_REQ_ENDORSE_PATH,
  INTERVIEW_ADD_TO_CALENDAR_PATH,
  CLAIM_ACCOUNT_PATH,
  CANDIDATE_RESUME,
  CANDIDATE_OFFERS,
  EMPLOYER_CANDIDATE_QUESTIONNAIRE,
  CANDIDATE_WELCOME_LETTER,
  CAMPAIGN_HOME,
  CAMPAIGN_DETAILS,
  CAMPAIGN_CREATE,
  ADD_NEW_LOCATION,
  EMPLOYER_SETTINGS_MENU,
  TEMPLATES,
  NOTIFICATIONS,
  INTEGRATIONS,
  SCHEDULE,
  PERSONAL_ACCOUNT,
  GOOGLE_OAUTH_CALLBACK,
  GROUP_SPECIFIC_REGISTER,
  CANDIDATE_DATABASE_MODAL,
  NETWORK,
  POSITIONS_V2,
  UPCOMING_INTERVIEWS_SCHEDULE_MODAL,
  EMPLOYER_DASHBOARD,
  EMPLOYER_DASHBOARD_MODAL,
  EMPLOYER_DASHBOARD_BY_LOCATION,
  SENDBIRD_CHAT_CHANNEL_MODAL,
  INDEED_OAUTH,
  CANDIDATE_HOME_TAB_EDIT_MODAL,
  CANDIDATE_HOME_TAB_HIRING_BY_SLUG,
  CANDIDATE_HOME_EDIT_MODAL,
  CANDIDATE_HOME_TAB,
  CANDIDATE_HOME,
  CANDIDATE_ACCOUNT_MENU_MODAL,
  RESTAURANT_JOBS_HIRING_BY_LOCATION,
  RESTAURANT_JOBS_LOCATION,
  RESTAURANT_JOBS,
  COMPLETE_APPLICATION,
  CANDIDATE_PROFILE_EDIT_MODAL_BY_HANDLE,
  CANDIDATE_PROFILE_MODAL_BY_HANDLE,
  CANDIDATE_PROFILE_BY_HANDLE,
  CAREER_PAGE_EDIT_BY_SLUG,
  CAREER_PAGE_BY_SLUG,
  CAREER_PAGE_EDIT_PREVIEW_BY_SLUG,
  CAREER_PAGE_MODAL_BY_SLUG,
  CAREER_PAGE_EDIT_MODAL_BY_SLUG,
  SEVEN_SHIFTS_OAUTH,
  LOGOUT,
  CAREER_DASHBOARD_WITH_SEGMENTS,
  CANDIDATE_ONBOARDING_WITH_PARAMS,
  JOBS_WITH_PARAMS,
  EMPLOYER_ONBOARDING_WITH_PARAMS,
  INTERVIEW_SCHEDULE_PATH_WITH_PARAMS,
  DEMO,
  PRICING,
  HOME_URL,
  PENDING_EMPLOYER_PROFILE,
  SUBSCRIBE_LOCATIONS_POSITIONS,
  HELP_REQUEST_FORM
} from './constants/urls';
import { ALLOWED_ROLES_GROUPS, ENVIRONMENT, GROUPS } from './constants';
import { employerSettingsRoutes } from './features/EmployerSettings/EmployerSettingsRouter';
import { useNavigation } from './utils/navigation';
import useLogoutRedirectContext from './features/SignIn/useLogoutRedirectContext';
import ErrorBoundary from './features/ErrorBoundary';
import { useGetMeQuery } from './graphql/generated';
import { getAuth } from './utils';

const LoadableSendBird = Loadable(() => import('./features/Chat'));
const LoadableMessageTemplateScreen = Loadable(() =>
  import('./features/EmployerMessageTemplate/MessageTemplateScreen')
);

const CommonRedirectComponent = () => <Navigate to={LOGIN_URL} />;

const PROD_EXCLUSIVE_MARKETING_ROUTES =
  ENVIRONMENT !== 'production'
    ? [
        {
          exact: false,
          path: HOME_URL,
          Component: CommonRedirectComponent
        },
        {
          exact: false,
          path: PRICING,
          Component: CommonRedirectComponent
        },
        {
          exact: false,
          path: DEMO,
          Component: CommonRedirectComponent
        }
      ]
    : [];

const ROUTES = {
  [GROUPS.employers]: [
    {
      exact: false,
      path: SUBSCRIBE_LOCATIONS_POSITIONS,
      Component: props => <Subscribe {...props} />,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.EMPLOYER],
      allowedRoles: ALLOWED_ROLES_GROUPS.ROLES.ADMIN_USER_INTERVIEWER,
      title: 'Subscribe'
    },
    {
      exact: false,
      path: PENDING_EMPLOYER_PROFILE,
      Component: PendingEmployerProfile,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.EMPLOYER],
      allowedRoles: ALLOWED_ROLES_GROUPS.ROLES.ADMIN_USER_INTERVIEWER,
      title: 'Pending Employer Profile'
    },
    {
      exact: false,
      strict: true,
      path: FEED_WITH_SEGMENTS,
      Component: props => <CandidateInboxFeed {...props} />,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.EMPLOYER],
      allowedRoles: ALLOWED_ROLES_GROUPS.ROLES.ADMIN_USER_INTERVIEWER
    },
    {
      exact: false,
      path: CANDIDATE_DATABASE_MODAL,
      Component: props => <CandidateNetworkSearchScreen {...props} />,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.EMPLOYER],
      allowedRoles: ALLOWED_ROLES_GROUPS.ROLES.ADMIN_USER_INTERVIEWER
    },
    {
      exact: false,
      path: NETWORK,
      Component: props => <CandidateNetworkSearchScreen {...props} />,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.EMPLOYER],
      allowedRoles: ALLOWED_ROLES_GROUPS.ROLES.ADMIN_USER_INTERVIEWER,
      title: 'Candidate Database'
    },
    {
      exact: false,
      path: POSITIONS_V2,
      Component: props => <NewPositionScreen {...props} />,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.EMPLOYER],
      allowedRoles: ALLOWED_ROLES_GROUPS.ROLES.ADMIN_USER,
      title: 'Add a position'
    },
    {
      exact: false,
      path: ADD_NEW_LOCATION,
      Component: props => <AddNewLocation {...props} />,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.EMPLOYER],
      allowedRoles: ALLOWED_ROLES_GROUPS.ROLES.ADMIN_USER,
      title: 'Add a location'
    },
    {
      exact: false,
      strict: true,
      path: UPCOMING_INTERVIEWS_SCHEDULE_MODAL,
      Component: props => <UpcomingAgenda {...props} />,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.EMPLOYER],
      allowedRoles: ALLOWED_ROLES_GROUPS.ROLES.ADMIN_USER_INTERVIEWER,
      title: 'Interview Calendar'
    },
    {
      exact: false,
      path: TEMPLATES,
      Component: props => <LoadableMessageTemplateScreen {...props} />,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.EMPLOYER],
      allowedRoles: ALLOWED_ROLES_GROUPS.ROLES.ADMIN_USER_INTERVIEWER,
      title: 'Message Template Settings'
    },
    {
      exact: false,
      path: `${EMPLOYER_SETTINGS_MENU}/:activeScreen?`,
      Component: props => <EmployerSettingsRouter {...props} />,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.EMPLOYER],
      allowedRoles: ALLOWED_ROLES_GROUPS.ROLES.ADMIN_USER,
      title: 'Location Settings'
    },
    ...employerSettingsRoutes.map(route => ({
      path: `${EMPLOYER_SETTINGS_MENU}/${route.route}`,
      Component: props => <route.Component {...props} />,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.EMPLOYER],
      allowedRoles: ALLOWED_ROLES_GROUPS.ROLES.ADMIN_USER
    })),
    {
      exact: false,
      path: `${EMPLOYER_SETTINGS_MENU}${NOTIFICATIONS}`,
      Component: NotificationSettings,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.EMPLOYER],
      allowedRoles: ALLOWED_ROLES_GROUPS.ROLES.ADMIN_USER_INTERVIEWER,
      title: 'Notifications Settings'
    },
    {
      exact: false,
      path: `${EMPLOYER_SETTINGS_MENU}${SCHEDULE}`,
      Component: EmployerInterviewAvailability,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.EMPLOYER],
      allowedRoles: ALLOWED_ROLES_GROUPS.ROLES.ADMIN_USER_INTERVIEWER,
      title: 'Interview Availability Settings'
    },
    {
      exact: false,
      path: `${EMPLOYER_SETTINGS_MENU}${PERSONAL_ACCOUNT}`,
      Component: EmployerAccount,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.EMPLOYER],
      allowedRoles: ALLOWED_ROLES_GROUPS.ROLES.ADMIN_USER_INTERVIEWER,
      title: 'Account Information'
    },
    {
      exact: false,
      path: `${EMPLOYER_SETTINGS_MENU}${INTEGRATIONS}`,
      Component: Integrations,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.EMPLOYER],
      allowedRoles: ALLOWED_ROLES_GROUPS.ROLES.ADMIN,
      title: 'Integrations Settings'
    },
    {
      exact: false,
      path: EMPLOYER_SETTINGS_MENU,
      Component: props => <EmployerSettingsRouter {...props} />,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.EMPLOYER],
      allowedRoles: ALLOWED_ROLES_GROUPS.ROLES.ADMIN_USER
    },
    {
      exact: true,
      path: EMPLOYER_DASHBOARD,
      Component: props => <EmployerLocationsDashboard {...props} />,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.EMPLOYER],
      allowedRoles: ALLOWED_ROLES_GROUPS.ROLES.ADMIN_USER_INTERVIEWER,
      title: 'Dashboard'
    },
    {
      exact: false,
      path: EMPLOYER_DASHBOARD_MODAL,
      Component: props => <PositionDashboard {...props} />,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.EMPLOYER],
      allowedRoles: ALLOWED_ROLES_GROUPS.ROLES.ADMIN_USER_INTERVIEWER,
      title: 'Dashboard'
    },
    {
      exact: false,
      path: EMPLOYER_DASHBOARD_BY_LOCATION,
      Component: props => <PositionDashboard {...props} />,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.EMPLOYER],
      allowedRoles: ALLOWED_ROLES_GROUPS.ROLES.ADMIN_USER_INTERVIEWER,
      title: 'Dashboard'
    },
    {
      exact: true,
      path: INDEED_OAUTH,
      Component: props => <IndeedOAuthRedirectScreen {...props} />,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.EMPLOYER],
      allowedRoles: ALLOWED_ROLES_GROUPS.ROLES.ADMIN_USER
    },
    {
      exact: true,
      path: CAMPAIGN_HOME,
      Component: props => <CampaignHome {...props} />,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.EMPLOYER],
      allowedRoles: ALLOWED_ROLES_GROUPS.ROLES.ADMIN,
      title: 'Campaigns'
    },
    {
      exact: true,
      strict: true,
      path: CAMPAIGN_CREATE,
      Component: props => <CreateCampaign {...props} />,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.EMPLOYER],
      allowedRoles: ALLOWED_ROLES_GROUPS.ROLES.ADMIN,
      title: 'Create a Campaign'
    },
    {
      exact: true,
      path: CAMPAIGN_DETAILS,
      Component: props => <CampaignDetails {...props} />,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.EMPLOYER],
      allowedRoles: ALLOWED_ROLES_GROUPS.ROLES.ADMIN,
      title: 'Campaign Details'
    },
    {
      exact: true,
      path: CAREER_DASHBOARD_WITH_SEGMENTS,
      Component: props => <CareerPageDashboard {...props} />,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.EMPLOYER],
      allowedRoles: ALLOWED_ROLES_GROUPS.ROLES.ADMIN_USER_INTERVIEWER,
      title: 'Career Pages'
    },
    {
      exact: true,
      path: CAREER_PAGE_EDIT_BY_SLUG,
      Component: props => <CareerPageFormScreen {...props} />,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.EMPLOYER],
      allowedRoles: ALLOWED_ROLES_GROUPS.ROLES.ADMIN_USER,
      title: 'Career Pages'
    },
    {
      exact: false,
      path: HELP_REQUEST_FORM,
      Component: props => <HelpRequestScreen {...props} />,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.EMPLOYER],
      allowedRoles: ALLOWED_ROLES_GROUPS.ROLES.ADMIN_USER_INTERVIEWER,
      title: 'Submit a help request'
    }
  ],
  [GROUPS.candidates]: [
    {
      exact: true,
      path: CANDIDATE_PROFILE_FORM_URL,
      Component: props => <AccountMenuScreen {...props} />,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.CANDIDATE],
      allowedRoles: [],
      title: 'Account Info'
    },
    {
      exact: true,
      path: CANDIDATE_POSITION_PATH,
      Component: props => (
        <FOHView style={{ paddingTop: 60 }}>
          <PositionTypeScreen {...props} />
        </FOHView>
      ),
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.CANDIDATE],
      allowedRoles: []
    },
    {
      exact: true,
      path: CANDIDATE_AVAILABILITY_PATH,
      Component: props => <AvailabilityScreen {...props} />,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.CANDIDATE],
      allowedRoles: []
    },
    {
      exact: true,
      path: CANDIDATE_VIDEO_PATH,
      Component: props => <UploadVideoScreen {...props} />,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.CANDIDATE],
      allowedRoles: []
    },
    {
      exact: true,
      path: CANDIDATE_PHOTO_PATH,
      Component: props => <UploadPhotoScreen {...props} />,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.CANDIDATE],
      allowedRoles: []
    },
    {
      exact: true,
      path: CANDIDATE_WORK_HISTORY_PATH,
      Component: props => <WorkHistoryScreen {...props} />,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.CANDIDATE],
      allowedRoles: []
    },
    {
      exact: true,
      path: CANDIDATE_ADD_EMPLOYMENT_PATH,
      Component: props => <AddEmploymentScreen {...props} />,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.CANDIDATE],
      allowedRoles: []
    },
    {
      exact: true,
      path: CANDIDATE_LOCATION_PATH,
      Component: props => <LocationScreen {...props} />,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.CANDIDATE],
      allowedRoles: []
    },
    {
      exact: true,
      path: CANDIDATE_RESTAURANT_STYLE_PATH,
      Component: props => <RestaurantStyleScreen {...props} />,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.CANDIDATE],
      allowedRoles: []
    },
    {
      exact: true,
      path: CANDIDATE_YEARS_EXPERIENCE_PATH,
      Component: props => <YearsExpScreen {...props} />,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.CANDIDATE],
      allowedRoles: []
    },
    {
      exact: true,
      path: CANDIDATE_TOP_THREE_PATH,
      Component: props => <TopThreeScreen {...props} />,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.CANDIDATE],
      allowedRoles: []
    },
    {
      exact: true,
      path: CANDIDATE_RESUME,
      Component: props => <ResumeUploadScreen {...props} />,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.CANDIDATE],
      allowedRoles: []
    },
    {
      exact: true,
      path: CANDIDATE_PERSONAL_SUMMARY_PATH,
      Component: props => (
        <FOHView style={{ paddingTop: 60 }}>
          <PersonalSummaryScreen {...props} />
        </FOHView>
      ),
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.CANDIDATE],
      allowedRoles: []
    },
    {
      exact: true,
      path: CANDIDATE_WORK_PREFERENCES_PATH,
      Component: props => <WorkPreferencesScreen {...props} />,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.CANDIDATE],
      allowedRoles: []
    },
    {
      exact: true,
      path: CANDIDATE_REQ_ENDORSE_PATH,
      Component: props => <RequestEndorseScreen {...props} />,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.CANDIDATE],
      allowedRoles: []
    },
    {
      exact: true,
      path: CANDIDATE_CONFIRM_EMAIL_PATH,
      Component: props => <ConfirmEmailScreen {...props} />,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.CANDIDATE],
      allowedRoles: []
    },
    {
      exact: true,
      path: CANDIDATE_ACCOUNT_MENU_PATH,
      Component: props => <AccountMenuScreen {...props} />,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.CANDIDATE],
      allowedRoles: [],
      title: 'Account Info'
    },
    {
      exact: true,
      path: CANDIDATE_ACCOUNT_MENU_MODAL,
      Component: props => <AccountMenuScreen {...props} />,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.CANDIDATE],
      allowedRoles: []
    },
    {
      exact: true,
      path: CANDIDATE_SKILLS_PATH,
      Component: props => <SkillsScreen {...props} />,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.CANDIDATE],
      allowedRoles: []
    },
    {
      exact: true,
      path: CANDIDATE_ACCOUNT_INFO_PATH,
      Component: props => <AccountInformationScreen {...props} />,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.CANDIDATE],
      allowedRoles: []
    },
    {
      exact: true,
      path: CANDIDATE_OFFERS,
      Component: props => <CandidateOffer {...props} />,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.CANDIDATE],
      allowedRoles: [],
      title: 'Job Offers'
    },
    {
      exact: true,
      path: CANDIDATE_WELCOME_LETTER,
      Component: props => <CandidateWelcomeLetter {...props} />,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.CANDIDATE],
      allowedRoles: []
    },
    {
      exact: true,
      path: CANDIDATE_NOTIFICATION_PREFERENCE_PATH,
      Component: props => <NotificationPreferenceScreen {...props} />,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.CANDIDATE],
      allowedRoles: []
    },
    {
      exact: true,
      path: CANDIDATE_HOME_TAB_EDIT_MODAL,
      Component: props => <CandidateHome {...props} />,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.CANDIDATE],
      allowedRoles: []
    },
    {
      exact: false,
      path: CANDIDATE_HOME_TAB_HIRING_BY_SLUG,
      Component: props => <CandidateHome {...props} />,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.CANDIDATE],
      allowedRoles: []
    },
    {
      exact: false,
      path: CANDIDATE_HOME_EDIT_MODAL,
      Component: props => <CandidateHome {...props} />,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.CANDIDATE],
      allowedRoles: []
    },
    {
      exact: false,
      path: CANDIDATE_HOME_TAB,
      Component: props => <CandidateHome {...props} />,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.CANDIDATE],
      allowedRoles: []
    },
    {
      exact: false,
      path: CANDIDATE_HOME,
      Component: props => <CandidateHome {...props} />,
      allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.CANDIDATE],
      allowedRoles: []
    }
  ],
  common: [
    {
      exact: false,
      path: `${INTERVIEW_ADD_TO_CALENDAR_PATH}/:uuid`,
      Component: props => <CandidateAddToCalendarScreen {...props} />
    },
    {
      exact: false,
      path: INTERVIEW_SCHEDULE_PATH_WITH_PARAMS,
      Component: props => <CandidateScheduleInterviewScreen {...props} />
    },
    {
      exact: false,
      path: `${INTERVIEW_DETAIL_PATH}/*`,
      Component: props => <InterviewResponseScreen {...props} />
    },
    {
      exact: true,
      path: RESTAURANT_JOBS,
      Component: props => <LocationFeedScreen {...props} />
    },
    {
      exact: true,
      path: RESTAURANT_JOBS_HIRING_BY_LOCATION,
      Component: props => <LocationFeedScreen {...props} />
    },
    {
      exact: false,
      path: RESTAURANT_JOBS_LOCATION,
      Component: props => <LocationDetailScreen {...props} />
    },

    {
      exact: true,
      path: LOGIN_URL,
      Component: props => <LoginScreen {...props} />
    },
    {
      exact: true,
      path: PASSWORD_RESET_URL,
      Component: props => <PasswordResetScreen {...props} />
    },
    {
      exact: true,
      path: PASSWORD_RESET_RETURN_URL,
      Component: props => <PasswordResetReturnScreen {...props} />
    },
    {
      exact: false,
      path: CANDIDATE_ONBOARDING_WITH_PARAMS,
      Component: props => <CandidateOnboardingRouter {...props} />
    },
    {
      exact: false,
      path: EMPLOYER_ONBOARDING_WITH_PARAMS,
      Component: props => <EmployerOnboardingRouter {...props} />
    },
    {
      exact: false,
      path: COMPLETE_APPLICATION,
      Component: props => <CompleteApplicationRouter {...props} />
    },
    {
      exact: true,
      path: CLAIM_ACCOUNT_PATH,
      Component: props => <ClaimAccountScreen {...props} />
    },
    {
      exact: true,
      path: EMPLOYER_CANDIDATE_QUESTIONNAIRE,
      Component: props => <EmployerCandidateQuestionnaire {...props} />
    },
    {
      exact: false,
      path: CANDIDATE_PROFILE_EDIT_MODAL_BY_HANDLE,
      Component: props => <ProfileScreen {...props} />
    },
    {
      exact: false,
      path: CANDIDATE_PROFILE_MODAL_BY_HANDLE,
      Component: props => <ProfileScreen {...props} />
    },
    {
      exact: false,
      path: CANDIDATE_PROFILE_BY_HANDLE,
      Component: props => <ProfileScreen {...props} />
    },
    {
      exact: false,
      path: JOBS_WITH_PARAMS,
      Component: props => <JobScreen {...props} />
    },
    {
      exact: true,
      path: CAREER_PAGE_EDIT_BY_SLUG,
      Component: props => <CareerPageFormScreen {...props} />
    },
    {
      exact: true,
      path: CAREER_PAGE_BY_SLUG,
      Component: props => <CareerPageScreen {...props} />
    },
    {
      exact: true,
      path: CAREER_PAGE_EDIT_PREVIEW_BY_SLUG,
      Component: props => <CareerPageScreen {...props} />
    },
    {
      exact: true,
      path: CAREER_PAGE_MODAL_BY_SLUG,
      Component: props => <CareerPageScreen {...props} />
    },
    {
      exact: true,
      path: CAREER_PAGE_EDIT_MODAL_BY_SLUG,
      Component: props => <CareerPageFormScreen {...props} />
    },
    {
      exact: true,
      path: SEVEN_SHIFTS_OAUTH,
      Component: props => <OAuth2Popup {...props} />
    },
    {
      exact: true,
      path: GOOGLE_OAUTH_CALLBACK,
      Component: props => <GoogleSSORedirectScreen {...props} />
    },
    {
      exact: true,
      path: GROUP_SPECIFIC_REGISTER,
      Component: props => <GroupSpecificSignUp {...props} />
    },
    {
      exact: true,
      path: REGISTER_URL,
      Component: props => <RegisterScreen {...props} />
    },
    {
      exact: true,
      path: LOGOUT,
      Component: props => <Screen403 {...props} />
    },
    {
      exact: false,
      path: SENDBIRD_CHAT_CHANNEL_MODAL,
      Component: props => <LoadableSendBird {...props} />,
      authRequired: true
    },
    ...PROD_EXCLUSIVE_MARKETING_ROUTES,
    {
      exact: false,
      path: '/*',
      Component: props => <NotFound404 {...props} />
    }
  ]
};

const candidateProtectedRoutes = [...ROUTES.candidates].map(route => ({
  ...route,
  allowedGroups: [ALLOWED_ROLES_GROUPS.GROUPS.CANDIDATE]
}));

const employersProtectedRoutes = [...ROUTES.employers].map(route => ({
  ...route,
  Component: () => (
    <ErrorBoundary>
      <route.Component />
    </ErrorBoundary>
  )
}));

const wrappedProtectedRoutes = [
  ...candidateProtectedRoutes,
  ...employersProtectedRoutes
].map(route => ({
  ...route,
  Component: () => (
    <AuthChecker title={route.title}>
      <ProtectedRoute
        component={route.Component ? route.Component : () => route.element}
        allowedGroups={route.allowedGroups}
        allowedRoles={route.allowedRoles}
        path={route.path}
      ></ProtectedRoute>
    </AuthChecker>
  )
}));

const commonRoutes = ROUTES.common.map(route => ({
  ...route,
  Component: route.authRequired
    ? () => (
        <AuthChecker title={route.title}>
          <route.Component />
        </AuthChecker>
      )
    : () => <route.Component />
}));

const Routes = React.memo(() => {
  const routes = useRoutes([...wrappedProtectedRoutes, ...commonRoutes]);

  return <>{routes}</>;
});

const AuthChecker = props => {
  const meQuery = useGetMeQuery({});
  const me = defaultTo({}, path(['data', 'me'], meQuery));
  const employerProfileStatus = defaultTo(
    null,
    path(['employerProfile', 'status'], me)
  );

  const routeTitle = props.title
    ? `${props.title} - foh&boh`
    : 'foh&boh - Hospitality Jobs';

  const { navigateTo, location: navLocation } = useNavigation();

  const {
    logoutWipeCacheClicked,
    setLogoutWipeCacheClicked,
    setIsRedirectToLoginCalled,
    redirectToLogin,
    isRedirectToLoginCalled
  } = useLogoutRedirectContext();

  useEffect(() => {
    (async () => {
      if (logoutWipeCacheClicked) {
        setLogoutWipeCacheClicked(false);
        await redirectToLogin(() => setIsRedirectToLoginCalled(true));
      }
    })();
  }, [logoutWipeCacheClicked]);

  useEffect(() => {
    if (isRedirectToLoginCalled) {
      setIsRedirectToLoginCalled(false);
    }
  }, [isRedirectToLoginCalled]);

  useEffect(() => {
    if (!meQuery.loading) {
      if (!me.id) {
        const authState = getAuth() || null;

        navigateTo(LOGIN_URL, {
          state: authState ? { from: location } : undefined
        });
      }
    }
  }, [meQuery.loading]);

  const isAccountSetupInProgress = useMemo(
    () =>
      employerProfileStatus &&
      ['PENDING', 'FRAUD'].includes(employerProfileStatus),
    [employerProfileStatus]
  );

  const isEmployerRegistered = useMemo(
    () =>
      (employerProfileStatus && employerProfileStatus === 'REGISTERED') ||
      false,
    [employerProfileStatus]
  );

  return (
    <>
      {isAccountSetupInProgress && <Navigate to={PENDING_EMPLOYER_PROFILE} />}
      {isEmployerRegistered && (
        <Navigate
          to={{
            ...navLocation,
            pathname: SUBSCRIBE_LOCATIONS_POSITIONS
          }}
        />
      )}
      <Helmet>
        <title>{routeTitle}</title>
      </Helmet>
      {props.children}
    </>
  );
};
export default compose(withChatWindow, withNav)(Routes);
