import React from 'react';

import support from '../../../images/ic_support.svg';

import { FOHImage } from '../../ingredients';

export const FOHSupportIcon = (props: any) => (
  <FOHImage
    source={{ uri: support }}
    style={{ width: 18, height: 18, ...props.style }}
  />
);
