import React from 'react';

import teal_rocket from '../../../images/teal_rocket.svg';

import { FOHImage } from '../../ingredients';

export interface FOHTealRocketIconProps {
  style?: any;
}

export const FOHTealRocketIcon = (props: FOHTealRocketIconProps) => (
  <FOHImage
    source={{ uri: teal_rocket }}
    style={
      props && !!props.style
        ? { width: 96, height: 96, ...props.style }
        : { width: 96, height: 96 }
    }
  />
);
