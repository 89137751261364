import React from 'react';

import { length } from 'ramda';
import { View, Dimensions, Platform, StyleProp, ViewStyle } from 'react-native';

import { FOHBannerType } from '../../typings';
import {
  FOHBackIcon,
  FOHDetailText,
  FOHHeaderH2,
  FOHSpaceSmallest,
  FOHTextButton,
  FOHColors,
  FOHView
} from '../../ingredients';
import { FOHTab } from '../../recipes';

import { FOHSelectItemProps } from '../select/FOHSelect';

import { FOHBanner } from './FOHBanner';

export interface FOHSettingsBannerProps {
  tabs?: Array<FOHSelectItemProps>;
  bannerType?: string;
  bannerMessage?: string;
  bannerStyle?: StyleProp<ViewStyle>;
  selectedTab?: string;
  setSelectedTab?: (val: string) => void;
  title: string;
  subTitle?: string;
  goBack?: () => void;
  children?: React.ReactNode;
  buttons?: any;
  sticky?: boolean;
  style?: StyleProp<ViewStyle>;
}

export const FOHSettingsBanner = (props: FOHSettingsBannerProps) => {
  const sticky = props?.sticky ?? true;
  const mobile = Dimensions.get('window').width <= 960;

  return (
    <>
      <FOHView
        style={{ position: 'absolute', top: 0, zIndex: 1, width: '100%' }}
      >
        {props.bannerMessage && props.bannerType ? (
          <FOHBanner
            message={props.bannerMessage}
            bannerType={props.bannerType as keyof typeof FOHBannerType}
            style={props.bannerStyle}
          />
        ) : (
          <></>
        )}
      </FOHView>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingTop: mobile ? 20 : 26,
          paddingHorizontal: mobile ? 24 : 26,
          paddingBottom:
            !props.tabs || length(props.tabs) === 0 ? (mobile ? 20 : 26) : 0,
          backgroundColor: FOHColors.WHITE,
          width: '100%',
          ...((props.style || {}) as ViewStyle)
        }}
      >
        <View
          style={{
            flexDirection: 'column',
            maxWidth: '100%',
            marginLeft: !props.goBack ? 10 : 0
          }}
        >
          <FOHHeaderH2
            style={{ textAlign: 'left', display: 'flex', alignItems: 'center' }}
          >
            {props.goBack ? (
              <>
                <FOHTextButton
                  onPress={props.goBack}
                  icon={() => (
                    <FOHBackIcon
                      style={{ height: 20, width: 20, marginHorizontal: 2 }}
                    />
                  )}
                  title=""
                />
                <FOHSpaceSmallest />
              </>
            ) : (
              <></>
            )}
            {props.title}
          </FOHHeaderH2>
          <FOHSpaceSmallest />
          <FOHDetailText
            style={{
              paddingLeft: mobile ? 0 : props.goBack ? 28 : 0,
              fontSize: 14,
              lineHeight: 20,
              textAlign: 'left',
              color: FOHColors.GRAYSCALE_600
            }}
          >
            {!mobile && props.goBack ? <FOHSpaceSmallest /> : null}
            {props.subTitle}
          </FOHDetailText>
          {props.tabs ? (
            <View style={{ flexDirection: 'row', paddingTop: 16 }}>
              {props.tabs.map(tab => (
                <View
                  key={tab.value}
                  style={{ paddingRight: 24 }}
                  testID={tab.testID}
                >
                  <FOHTab
                    onPress={() => {
                      props.setSelectedTab && props.setSelectedTab(tab.value);
                    }}
                    title={tab.label}
                    selected={tab.value === props.selectedTab}
                  />
                </View>
              ))}
            </View>
          ) : (
            <></>
          )}
        </View>

        {!sticky ? (
          <View
            style={{
              flexDirection: 'row',
              flex: 1,
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
              marginBottom: 10
            }}
          >
            {props.children}
          </View>
        ) : (
          <></>
        )}
      </View>
      {sticky && !!props.children ? (
        <View
          // @ts-ignore flexDirection & position
          style={{
            flexDirection: 'row',
            width: '100%',
            paddingRight: mobile ? undefined : 288,
            flex: 1,
            justifyContent: mobile ? 'center' : 'flex-start',
            // fixed only available on web, prevents safari back tabs from getting in the way
            position: Platform.OS === 'web' ? 'fixed' : 'absolute',
            zIndex: 999,
            padding: mobile ? 16 : 24,
            bottom: 0,
            backgroundColor: FOHColors.WHITE,
            marginTop: 140
          }}
        >
          {props.children}
        </View>
      ) : (
        <></>
      )}
    </>
  );
};
