import React from 'react';

import styled from 'styled-components/native';
import { Image } from 'react-native';

import {
  FullWidthCard,
  FOHView,
  FOHSpace,
  FOHSpaceSmallest,
  FOHSpaceSmall,
  FOHHeaderH3,
  FOHSmallDetailLabel,
  FOHButton,
  FOHDivider,
  FOHColors,
  FOHFonts
} from '../../ingredients';

export interface FOHNoRecommendationCellProps {
  isMobile?: boolean;
  image: string;
  headerText: string;
  detailText: string;
  submitBtnLabel: string;
  onPress: () => void;
}

export const FOHNoRecommendationCell = (
  props: FOHNoRecommendationCellProps
) => (
  <FullWidthCard noBorder={true} style={{ padding: 6, marginBottom: 0 }}>
    <Container>
      <ImageContainer mobile={props.isMobile}>
        <FOHImage
          source={{
            uri: props?.image
          }}
        />
      </ImageContainer>
      {!props.isMobile ? <FOHSpace /> : <FOHSpaceSmall />}
      <Block mobile={props.isMobile}>
        <FOHHeaderH3>{props.headerText}</FOHHeaderH3>
        {!props.isMobile ? <FOHSpaceSmallest /> : <FOHSpace />}
        {!props.isMobile ? (
          <FOHDivider />
        ) : (
          <FOHView style={{ maxWidth: 200, width: '100%' }}>
            <FOHDivider />
          </FOHView>
        )}
        {!props.isMobile ? <FOHSpaceSmallest /> : <FOHSpace />}
        <FOHDetail>{props.detailText}</FOHDetail>
        <FOHSpace />
        <FOHButton
          style={{
            maxWidth: props.isMobile ? '100%' : 348,
            width: '100%'
          }}
          title={props.submitBtnLabel}
          onPress={props.onPress}
          textColor={FOHColors.WHITE}
        />
      </Block>
    </Container>
  </FullWidthCard>
);

const Container = styled(FOHView)`
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
`;

const ImageContainer = styled(FOHView)<{ mobile?: boolean }>`
  width: ${(p: any) => (p.mobile ? '100%' : '156px')};
  height: ${(p: any) => (p.mobile ? '100%' : '152px')};
  align-items: center;
  justify-content: center;
`;

const FOHImage = styled(Image)`
  max-width: 156px;
  width: 100%;
  height: 152px;
  object-fit: cover;
`;

const Block = styled(FOHView)<{ mobile?: boolean }>`
  padding: ${(p: any) => (p.mobile ? '10px' : '5px')};
  align-items: ${(p: any) => (p.mobile ? 'center' : 'flex-start')};
  justify-content: ${(p: any) => (p.mobile ? 'center' : 'space-between')};
  max-width: ${(p: any) => (p.mobile ? '100%' : '348px')};
  width: 100%;
`;

const FOHDetail = styled(FOHSmallDetailLabel)`
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  max-width: 348px;
  text-align: left;
  color: ${FOHColors.NARO_GREY};
`;
