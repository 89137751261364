import React from 'react';

import { FOHSpace, FOHView } from '../../ingredients';

import { FOHSelect, FOHSelectItemProps, FOHSelectItem } from './FOHSelect';

export interface FOHFromToSelectProps extends FOHFromToWireFrameProps {}

export enum FOHTimeInputType {
  FROM = 'fromTime',
  TO = 'toTime'
}

export interface FOHFromToWireFrameProps {
  toTime: string;
  fromTime: string;
  timeChoices: Array<FOHSelectItemProps>;
  setToTime: (time: string, i?: number) => void;
  setFromTime: (time: string, i?: number) => void;
  placeholderTo: string;
  placeholderFrom: string;
}

export const FOHFromToSelect = (props: FOHFromToSelectProps) => (
  <FOHView
    style={{
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'center',
      flex: 1,
      maxWidth: 400
    }}
  >
    <FOHView
      style={{
        minWidth: 100,
        paddingTop: 8,
        paddingBottom: 8,
        flex: 1
      }}
    >
      <FOHSelect
        neverEmpty={true}
        placeholder={props.placeholderFrom}
        selectedValue={props.fromTime}
        onValueChange={props.setFromTime}
      >
        {props.timeChoices.map((time, i) => (
          <FOHSelectItem {...time} key={`${i}-time`} />
        ))}
      </FOHSelect>
    </FOHView>
    <FOHSpace />
    <FOHView
      style={{
        minWidth: 100,
        flex: 1
      }}
    >
      <FOHSelect
        neverEmpty={true}
        placeholder={props.placeholderTo}
        selectedValue={props.toTime}
        onValueChange={props.setToTime}
      >
        {props.timeChoices.map((time, i) => (
          <FOHSelectItem {...time} key={`${i}-time`} />
        ))}
      </FOHSelect>
    </FOHView>
    <FOHSpace />
  </FOHView>
);
