import React, { useMemo, useState } from 'react';

import { compose, defaultTo, includes, indexOf, length, prop } from 'ramda';
import { withTranslation } from 'react-i18next';
import { animated } from 'react-spring';

import {
  FOHColors,
  FOHEditMarkerBlack,
  FOHFonts,
  FOHIconButton,
  FOHLabel,
  FOHProfileImage,
  FOHSmallDetailLabel,
  FOHSpace,
  FOHSpaceLarge,
  FOHSpaceSmall,
  FOHSpaceSmallest,
  FOHTextButton,
  FOHTrashCanBlk,
  FOHView
} from '../../../components';
import { getInitials, useIsMobile } from '../../../utils';
import { ROLES } from '../../../constants';

import { useCandidateFeedContext } from '../../CandidateFeed/useCandidateFeedContext';

import FOHAddOrEditTeammate from './FOHAddOrEditTeammate';

const FOHHiringTeammateCard = ({
  t,
  isActivated,
  profileImage,
  fullName,
  email,
  inviteSentDate,
  onResendInvite,
  currentUserRole,
  accessOnSelectedLocations,
  onEditAction,
  onDeleteAction,
  firstName,
  lastName,
  permissionType,
  permissionOptions,
  locationFilters,
  locationOptions,
  employerUser,
  zIndex,
  handleOnEditFormSubmit,
  hideActions,
  handleOnViewLocations
}) => {
  const { isMobile } = useIsMobile();
  const userFullNameInitial = useMemo(
    () => getInitials(employerUser?.user),
    [employerUser]
  );

  const [fName, setFirstName] = useState(firstName);
  const [lName, setLastName] = useState(lastName);
  const [emailAddress, setEmailAddress] = useState(email);
  const [role, setRole] = useState(permissionType);
  const [isOpenLocationDropdown, setIsOpenLocationDropdown] = useState(false);
  const [isDisplayEditForm, setIsDisplayEditForm] = useState(false);
  const [selectedLocationIds, setSelectedLocationIds] =
    useState(locationFilters);

  const permissions = useMemo(() => defaultTo([], permissionOptions));

  const { setLocationSearch, locationSearch } = useCandidateFeedContext();

  const handleMultiSelect = locationId => {
    const indexToRemove = indexOf(locationId, selectedLocationIds);
    const selected = selectedLocationIds
      ? selectedLocationIds.filter(i => !!i)
      : [];

    if (!locationId) {
      return;
    }

    if (indexToRemove !== -1) {
      const newSelection = selected.filter(
        (_, index) => index !== indexToRemove
      );
      setSelectedLocationIds(newSelection);
    } else if (selected && includes(locationId, selected)) {
      const newSelection = selected.filter(item => item !== locationId);
      setSelectedLocationIds(newSelection);
    } else if (selected) {
      setSelectedLocationIds([...selected, locationId]);
    } else {
      setSelectedLocationIds([locationId]);
    }
  };

  const handleSelectAll = () => {
    if (length(selectedLocationIds) === length(locationOptions)) {
      setSelectedLocationIds([]);
    } else {
      setSelectedLocationIds(locationOptions.map(location => location.id));
    }
  };

  return (
    <>
      {!isDisplayEditForm ? (
        <FOHView
          style={{
            width: '100%',
            backgroundColor: isActivated
              ? FOHColors.WHITE
              : FOHColors.GRAYSCALE_200,
            borderRadius: 4,
            borderWidth: 1,
            borderColor: FOHColors.GRAYSCALE_300,
            paddingVertical: isMobile ? 8 : 12,
            flexDirection: 'row',
            alignItems: 'center',
            height: isMobile ? 96 : 75,
            zIndex: zIndex + 1
          }}
        >
          {isMobile ? (
            <FOHView style={{ flex: 3 }}>
              <FOHView
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  flex: 1
                }}
              >
                <FOHSpaceSmall />
                <AnimatedDonut
                  style={{
                    borderWidth: 1,
                    borderColor: FOHColors.PRIMARY_TEAL_400,
                    borderRadius: '50%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 28,
                    height: 28
                  }}
                >
                  <FOHView
                    style={{
                      borderRadius: '50%',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: FOHColors.GRAYSCALE_100,
                      height: 26,
                      width: 26
                    }}
                  >
                    {profileImage ? (
                      <FOHProfileImage
                        photo={profileImage || avatar}
                        style={{
                          height: 26,
                          width: 26
                        }}
                      />
                    ) : (
                      <FOHLabel>{userFullNameInitial}</FOHLabel>
                    )}
                  </FOHView>
                </AnimatedDonut>
                <FOHSpaceSmall />
                <FOHLabel
                  style={{
                    color: FOHColors.GRAYSCALE_900,
                    textWrap: 'balance'
                  }}
                >
                  {fullName}
                </FOHLabel>
              </FOHView>
              <FOHSmallDetailLabel
                style={{
                  paddingLeft: 12,
                  paddingRight: 8,
                  textWrap: 'balance'
                }}
              >
                {email}
              </FOHSmallDetailLabel>
            </FOHView>
          ) : (
            <FOHView
              style={{ flex: 3, flexDirection: 'row', alignItems: 'center' }}
            >
              <FOHSpaceSmall />
              <AnimatedDonut
                style={{
                  borderWidth: 1,
                  borderColor: FOHColors.PRIMARY_TEAL_400,
                  borderRadius: '50%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 47,
                  height: 47
                }}
              >
                <FOHView
                  style={{
                    borderRadius: '50%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: FOHColors.GRAYSCALE_100,
                    height: 45,
                    width: 45
                  }}
                >
                  {profileImage ? (
                    <FOHProfileImage
                      photo={profileImage || avatar}
                      style={{
                        height: 45,
                        width: 45
                      }}
                    />
                  ) : (
                    <FOHLabel>{userFullNameInitial}</FOHLabel>
                  )}
                </FOHView>
              </AnimatedDonut>
              <FOHSpaceSmall />
              <FOHView style={{ flex: 1 }}>
                <FOHLabel
                  style={{
                    color: FOHColors.GRAYSCALE_900,
                    textWrap: 'balance'
                  }}
                >
                  {fullName}
                </FOHLabel>
                {!isActivated ? (
                  <FOHView
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      flexWrap: 'wrap'
                    }}
                  >
                    <FOHSmallDetailLabel>
                      {t('inviteSentOn', {
                        sentOn: inviteSentDate
                      })}
                    </FOHSmallDetailLabel>
                    <FOHSpaceSmall />
                    <FOHTextButton
                      onPress={onResendInvite}
                      title={t('resend')}
                      style={{
                        color: FOHColors.PRIMARY_TEAL_P1,
                        fontSize: 14,
                        lineHeight: 20,
                        fontFamily: FOHFonts.REGULAR,
                        fontWeight: 400
                      }}
                      underline
                    />
                  </FOHView>
                ) : null}
              </FOHView>
            </FOHView>
          )}
          {!isMobile ? (
            <FOHView style={{ flex: 3, paddingRight: 8 }}>
              <FOHLabel
                style={{
                  fontSize: 14,
                  lineHeight: 20,
                  color: FOHColors.GRAYSCALE_600
                }}
              >
                {email}
              </FOHLabel>
            </FOHView>
          ) : null}
          <FOHView
            style={{ flex: isMobile ? 1.5 : 2, alignItems: 'flex-start' }}
          >
            <FOHLabel style={{ color: FOHColors.GRAYSCALE_900 }}>
              {currentUserRole}
            </FOHLabel>
            <FOHSpaceSmallest />
            {selectedLocationIds && length(selectedLocationIds) > 1 ? (
              <FOHTextButton
                style={{
                  color: FOHColors.PRIMARY_TEAL_P1,
                  fontSize: 14,
                  lineHeight: 20,
                  fontWeight: 600
                }}
                underline
                title={accessOnSelectedLocations}
                onPress={() => {
                  const selectedLocations = locationOptions.filter(location =>
                    selectedLocationIds.includes(location.id)
                  );
                  handleOnViewLocations(selectedLocations);
                }}
              />
            ) : (
              <FOHSmallDetailLabel>
                {accessOnSelectedLocations}
              </FOHSmallDetailLabel>
            )}
          </FOHView>
          <FOHView style={{ flex: 1 }}>
            {isMobile && !isActivated ? (
              <FOHView style={{ alignItems: 'flex-end' }}>
                <FOHTextButton
                  onPress={onResendInvite}
                  title={t('resend')}
                  style={{
                    color: FOHColors.GRAYSCALE_600,
                    fontSize: 14,
                    lineHeight: 20,
                    fontFamily: FOHFonts.REGULAR,
                    fontWeight: 400,
                    paddingRight: isMobile ? 8 : 16
                  }}
                  underline
                />
                <FOHSpaceSmallest />
              </FOHView>
            ) : null}
            {!hideActions ? (
              <FOHView
                style={{
                  width: '100%',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center'
                }}
              >
                <FOHIconButton
                  onPress={() => {
                    onEditAction(true);
                    setIsDisplayEditForm(true);
                  }}
                  disabled={!isActivated}
                  icon={() => (
                    <FOHEditMarkerBlack
                      style={{
                        tintColor: !isActivated
                          ? FOHColors.GRAYSCALE_400
                          : FOHColors.GRAYSCALE_600
                      }}
                    />
                  )}
                />
                {isMobile ? <FOHSpaceLarge /> : <FOHSpaceLarge />}
                <FOHIconButton
                  onPress={onDeleteAction}
                  icon={() => (
                    <FOHTrashCanBlk
                      style={{ tintColor: FOHColors.GRAYSCALE_600 }}
                    />
                  )}
                />
                {isMobile ? <FOHSpaceSmall /> : <FOHSpace />}
              </FOHView>
            ) : null}
          </FOHView>
        </FOHView>
      ) : (
        <FOHAddOrEditTeammate
          headerTitle={t('editModalTitle')}
          handleOnClose={() => {
            onEditAction(false);
            setIsDisplayEditForm(false);
          }}
          firstName={fName}
          setFirstName={setFirstName}
          lastName={lName}
          setLastName={setLastName}
          email={emailAddress}
          setEmail={setEmailAddress}
          permissionType={role}
          setPermissionType={setRole}
          permissionOptions={permissions}
          isOpenLocationDropdown={isOpenLocationDropdown}
          setIsOpenLocationDropdown={setIsOpenLocationDropdown}
          selectedLocationFilters={selectedLocationIds}
          locationSearch={locationSearch}
          setLocationSearch={setLocationSearch}
          handleMultiSelectLocation={handleMultiSelect}
          locationOptions={locationOptions}
          handleSelectAllLocations={handleSelectAll}
          submitBtnLabel={t('common:save')}
          handleOnSubmit={() => {
            // edit employer user payload
            const variables = {
              id: prop('id', employerUser),
              firstName: fName,
              lastName: lName,
              email: emailAddress,
              role: role,
              locations: role === ROLES.ADMIN ? undefined : selectedLocationIds
            };

            handleOnEditFormSubmit(variables);
            setIsDisplayEditForm(false);
          }}
          zIndex={zIndex + 1}
        />
      )}
    </>
  );
};

const AnimatedDonut = animated(FOHView);

export default compose(withTranslation('MyTeamFeature'))(FOHHiringTeammateCard);
