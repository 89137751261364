import React from 'react';

import arrow_back from '../../../images/arrow_back.svg';

import { FOHImage } from '../../ingredients';

export const FOHBackIcon = (props: any) => (
  <FOHImage
    source={{ uri: arrow_back }}
    style={{ width: 24, height: 24, ...props?.style }}
  />
);
