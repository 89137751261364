import React from 'react';

import edit_marker from '../../../images/black_edit_marker.svg';

import { FOHImage } from '../../ingredients';

export const FOHEditMarkerBlack = (props: any) => (
  <FOHImage
    source={{ uri: edit_marker }}
    style={{ width: 16, height: 16, ...props.style }}
  />
);
