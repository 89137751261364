import React from 'react';

import { View } from 'react-native';

import { FOHMoneyCellProps, FOHMoneyCell } from '../../recipes';
import { FOHAltSectionLabel, FOHSpace } from '../../ingredients';

export interface FOHHourlyRatesProps {
  hourlyRatesTitle: string;
  positionRates: Array<FOHMoneyCellProps>;
}

export const FOHHourlyRates = (props: FOHHourlyRatesProps) => (
  <View>
    <FOHAltSectionLabel>{props.hourlyRatesTitle}</FOHAltSectionLabel>
    <View style={{ padding: 4 }} />

    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'flex-start',
        flexWrap: 'wrap'
      }}
    >
      {props.positionRates.map(({ hourlyrate, position }, i) => (
        <>
          <FOHMoneyCell
            key={`moneycell${i}`}
            hourlyrate={hourlyrate}
            position={position}
          />
          <FOHSpace key={`moneycellspace${i}`} />
        </>
      ))}
    </View>
  </View>
);
