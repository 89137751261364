import React, { useState } from 'react';

import { StyleProp, View } from 'react-native';
import { path, pathOr } from 'ramda';

import {
  FOHTextField,
  FOHSpace,
  FOHSpaceSmall,
  FOHHeaderH3,
  FOHSpaceLarger,
  FOHSpaceSmallest,
  FOHSwitch,
  FOHErrorLabel,
  FOHSmallDetailLabel,
  FOHLocationPin
} from '../../ingredients';
import {
  FOHLabeledCheckbox,
  FOHSelectItem,
  FOHSelect,
  FOHSelectItemProps,
  FOHForm,
  FOHDropDownSearch,
  FOHSearchableSelectDropdown,
  FOHDropdownSelectOption
} from '../../recipes';

export interface FOHNewPositionFormProps {
  positionName?: string;
  positionNamePlaceholder: string;
  setPositionName: (value: string) => void;

  positionTypeText: string;
  setPositionTypeText: (text: string) => void;
  clearPositionTypes: () => void;
  positionTypes: Array<FOHSelectItemProps>;
  positionTypePlaceholder: string;
  selectedPositionTypes: Array<FOHSelectItemProps>;
  setSelectedPositionTypes: (value: FOHSelectItemProps) => void;

  locations?: Array<FOHDropdownSelectOption>;
  locationPlaceholder?: string;
  selectedLocation?: any;
  setSelectedLocation?: (value: any) => void;
  locationSeachPlaceholder?: string;

  includeSalaryLabel: string;
  includeSalaryValue: boolean;
  includeSalaryTermsText: string;
  toggleIncludeSalary: () => void;

  minPay?: string;
  minPayLabel: string;
  setMinPay: (value: string) => void;

  maxPay?: string;
  maxPayLabel: string;
  setMaxPay: (value: string) => void;

  paymentType?: string;
  paymentTypeLabel: string;
  paymentTypeOptions: Array<FOHSelectItemProps>;
  setPaymentType: (value: string) => void;

  sendNotifications: boolean;
  sendNotificationsLabel: string;
  toggleNotifications?: () => void;

  sendToJobBoards: boolean;
  sendToJobBoardsLabel: string;
  sendToJobBoardsText: string;
  toggleSendToJobBoards?: () => void;

  indeedEasyapplyOptout?: boolean;
  indeedEasyapplyOptoutLabel?: string;
  setIndeedEasyapplyOptout?: (value: boolean) => void;

  openDropdown: string;
  setOpenDropdown: (id: string) => void;
  mobile?: boolean;
  onSubmit?: () => void;
  submitLabel?: string;
  title?: string;
  error?: any;
  disabledSubmit?: boolean;
  align?: StyleProp<any>;
  closeLabel: string;
  children?: any;
}

export const FOHNewPositionForm = (props: FOHNewPositionFormProps) => {
  const [locationOpen, setLocationOpen] = useState(false);
  const [locationSearch, setLocationSearch] = useState('');

  return (
    <View style={{ width: '100%', padding: 16 }}>
      <FOHForm
        handleSubmit={props.onSubmit}
        disabledSubmit={props.disabledSubmit}
      >
        <FOHTextField
          required={true}
          placeholder={props.positionNamePlaceholder}
          value={props.positionName}
          onChangeText={props.setPositionName}
          label="Position"
          error={path(['error', 'position_name'], props)}
          testID={'new-position-position-name-field'}
        />
        <FOHSpace />
        <View style={{ zIndex: 999 }}>
          <FOHDropDownSearch
            required={true}
            onChangeSearch={props.setPositionTypeText}
            freeTextLabel={''}
            closeLabel={props.closeLabel}
            searchDetailLabel={'Click here to confirm the keyword.'}
            searchValue={props.positionTypeText}
            searchPlaceholder={props.positionTypePlaceholder}
            selectFreeTextLabel={`Search for`}
            truncationLimit={1}
            clearSearch={props.clearPositionTypes}
            sections={[
              {
                title: '',
                items: props.positionTypes
              }
            ]}
            selectedItems={props.selectedPositionTypes}
            selectItem={props.setSelectedPositionTypes}
            noIcon={true}
            noFreeText={true}
            testID={'new-position-position-type-field'}
          ></FOHDropDownSearch>
          {path(['error', 'position_type'], props) && (
            <FOHErrorLabel>
              {path(['error', 'position_type'], props)}
            </FOHErrorLabel>
          )}
        </View>
        <FOHSpace />
        <View style={{ zIndex: 998 }}>
          {props.locations && props.setSelectedLocation && (
            <FOHSearchableSelectDropdown
              style={{ width: '100%' }}
              open={locationOpen}
              setOpen={open => {
                setLocationOpen(open);
              }}
              selectedValue={[props.selectedLocation]}
              error={path(['error', 'locations'], props)}
              showLabel={'Show'}
              multiSelect={false}
              onSearchChange={setLocationSearch}
              searchValue={locationSearch}
              searchPlaceholder={props.locationSeachPlaceholder}
              onValueChange={value => {
                props.setSelectedLocation && props.setSelectedLocation(value);
              }}
              options={props.locations.filter(
                loc =>
                  (loc?.label &&
                    loc.label
                      .toLowerCase()
                      .includes(locationSearch.toLowerCase())) ||
                  (loc?.sublabel &&
                    loc.sublabel
                      .toLowerCase()
                      .includes(locationSearch.toLowerCase()))
              )}
              fieldLabel={'locations'.toUpperCase()}
              icon={() => <FOHLocationPin />}
              testID={'new-position-location-select'}
            ></FOHSearchableSelectDropdown>
          )}
        </View>
        <FOHSpace />
        <View style={{ width: 327 }}>
          <FOHLabeledCheckbox
            style={{ fontSize: 14 }}
            selected={props.includeSalaryValue}
            checkboxLabel={props.includeSalaryLabel}
            setSelected={props.toggleIncludeSalary}
          />
        </View>
        <View
          style={{
            maxWidth: '100%',
            paddingHorizontal: 16,

            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'flex-start'
          }}
        >
          <FOHErrorLabel>*</FOHErrorLabel>
          <FOHSmallDetailLabel
            style={{
              textAlign: 'left',
              justifyContent: 'flex-start',
              alignItems: 'flex-start'
            }}
          >
            {props.includeSalaryTermsText}
          </FOHSmallDetailLabel>
        </View>
        <FOHSpace />
        {props.includeSalaryValue && (
          <View style={{ flexDirection: 'row', flex: 1, flexWrap: 'wrap' }}>
            <View style={{ flex: 2, paddingRight: 6, minWidth: 100 }}>
              <FOHTextField
                value={props.minPay}
                onChangeText={props.setMinPay}
                placeholder={props.minPayLabel}
                error={pathOr(
                  path(['error', 'base_salary_min'], props),
                  ['error', 'minPay'],
                  props
                )}
                testID={'new-position-min-pay'}
              />
            </View>
            <View style={{ flex: 2, paddingRight: 6, minWidth: 100 }}>
              <FOHTextField
                value={props.maxPay}
                onChangeText={props.setMaxPay}
                placeholder={props.maxPayLabel}
                error={pathOr(
                  path(['error', 'base_salary_max'], props),
                  ['error', 'maxPay'],
                  props
                )}
                testID={'new-position-max-pay'}
              />
            </View>
            <View style={{ flex: 1, minWidth: 140 }}>
              <FOHSelect
                placeholder={props.paymentTypeLabel}
                selectedValue={props.paymentType}
                onValueChange={(value: string) => props.setPaymentType(value)}
                neverEmpty={true}
                error={pathOr(
                  path(['error', 'base_salary_unit_text'], props),
                  ['error', 'paymentType'],
                  props
                )}
                testID={'new-position-pay-type'}
              >
                {props.paymentTypeOptions.map((payment, idx) => (
                  <FOHSelectItem
                    key={idx}
                    value={payment.value}
                    label={payment.label}
                  />
                ))}
              </FOHSelect>
            </View>
          </View>
        )}
        <FOHSpaceLarger />
        <View style={{ paddingLeft: 2, paddingRight: 2 }}>
          <FOHHeaderH3
            style={{
              textAlign: props.align || 'center'
            }}
          ></FOHHeaderH3>
        </View>
        <FOHSpaceSmall />
        {props.children}
        <FOHSpaceSmall />
        <View style={{ justifyContent: 'center', alignItems: 'center' }}>
          <FOHSpace />
          {props.toggleSendToJobBoards && (
            <View
              style={{
                width: '100%',
                justifyContent: 'flex-start',
                alignItems: 'flex-start'
              }}
            >
              <FOHSwitch
                inverseLabel={true}
                value={props.sendToJobBoards}
                label={props.sendToJobBoardsLabel}
                onValueChange={props.toggleSendToJobBoards}
                width={'100%'}
              />
              <FOHSpaceSmallest />
              <FOHSmallDetailLabel
                style={{
                  textAlign: 'left',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start'
                }}
              >
                {props.sendToJobBoardsText}
              </FOHSmallDetailLabel>
              <FOHSpace />
            </View>
          )}
          {props.setIndeedEasyapplyOptout && (
            <>
              <View
                style={{
                  width: '100%',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start'
                }}
              >
                <FOHSwitch
                  inverseLabel={true}
                  value={props?.indeedEasyapplyOptout || false}
                  label={props.indeedEasyapplyOptoutLabel}
                  onValueChange={props.setIndeedEasyapplyOptout}
                  width={'100%'}
                />
              </View>
              <FOHSpace />
            </>
          )}
        </View>
      </FOHForm>
    </View>
  );
};
