import React, { useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { path } from 'ramda';
import { useHover } from 'react-native-web-hooks';

import {
  FOHColors,
  FOHToolTip,
  FOHToolTipType,
  FOHImage,
  FOHView,
  FOHSpace,
  FOHSpaceLarge,
  FOHInformationIcon,
  FOHDivider,
  FOHButtonBordered,
  FOHHeaderH3,
  FOHSmallDetailLabel
} from '../../components';
import {
  CampaignPredictionQueryResult,
  useCampaignPredictionQuery,
  useCampaignEstimationQuery
} from '../../graphql/generated';
import indeed_logo from '../../images/indeed_logo.svg';
import { toDate } from '../../utils';

interface CampaignPredictionsAndEstimatorProps {
  campaignId: string;
  setBudget: (amount: string) => void;
  budget: number;
  startDate?: Date;
  endDate?: Date;
}

interface SubComponentProps {
  headerLabel: string;
  subHeaderLabel?: string;
}

interface CampaignPredictionProps extends SubComponentProps {
  actionButtonLabel: string;
  onPressUseAction: VoidFunction;
  predictionTooltipLabel: string;
  isDisabled: boolean;
  hasMaxBudgetMessage: boolean;
  maxBudgetMessage: string;
}

interface CampaignEstimatorProps extends SubComponentProps {
  estimationTooltipLabel: string;
}

interface TooltipProps {
  tooltipLabel: string;
}

const PredictionTooltip = (props: TooltipProps) => {
  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);
  const { tooltipLabel } = props;

  return (
    <FOHView
      style={{
        height: 16,
        justifyContent: 'flex-end',
        marginLeft: 7
      }}
    >
      <FOHToolTip
        helpText={tooltipLabel}
        open={isHovered}
        type={FOHToolTipType.BOTTOM_CENTER}
        style={{ bottom: -117, left: -135, width: 300 }}
      />
      <FOHView ref={hoverRef}>
        <FOHInformationIcon
          style={{ width: 20, height: 20, tintColor: FOHColors.GRAYSCALE_300 }}
        />
      </FOHView>
    </FOHView>
  );
};

const EstimationsTooltip = (props: TooltipProps) => {
  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);
  const { tooltipLabel } = props;
  return (
    <FOHView
      style={{
        height: 16,
        justifyContent: 'flex-end',
        marginLeft: 7
      }}
    >
      <FOHToolTip
        helpText={tooltipLabel}
        open={isHovered}
        type={FOHToolTipType.BOTTOM_CENTER}
        style={{ bottom: -117, left: -135, width: 300 }}
      />
      <FOHView ref={hoverRef}>
        <FOHInformationIcon
          style={{ width: 20, height: 20, tintColor: FOHColors.GRAYSCALE_300 }}
        />
      </FOHView>
    </FOHView>
  );
};

const CampaignPrediction = (props: CampaignPredictionProps) => {
  const {
    headerLabel,
    subHeaderLabel,
    actionButtonLabel,
    onPressUseAction,
    isDisabled,
    predictionTooltipLabel,
    hasMaxBudgetMessage,
    maxBudgetMessage
  } = props;

  return (
    <FOHView style={{ zIndex: 2 }}>
      <FOHView
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          zIndex: 3
        }}
      >
        <FOHHeaderH3 style={{ textAlign: 'left' }}>
          {headerLabel}
          <PredictionTooltip tooltipLabel={predictionTooltipLabel} />
        </FOHHeaderH3>
        <FOHImage
          source={{ uri: indeed_logo }}
          style={{
            height: 32,
            width: 128
          }}
        />
      </FOHView>
      <FOHSpace />
      <>
        {subHeaderLabel && hasMaxBudgetMessage ? (
          <>
            <FOHView style={{ zIndex: 2 }}>
              <FOHSmallDetailLabel>
                {subHeaderLabel} {maxBudgetMessage}
              </FOHSmallDetailLabel>
            </FOHView>
            <FOHSpace />
          </>
        ) : subHeaderLabel ? (
          <>
            <FOHView style={{ zIndex: 2 }}>
              <FOHSmallDetailLabel>{subHeaderLabel}</FOHSmallDetailLabel>
            </FOHView>
            <FOHSpace />
          </>
        ) : (
          <></>
        )}
      </>
      <FOHView style={{ zIndex: 1 }}>
        <FOHButtonBordered
          title={actionButtonLabel}
          style={{
            width: 177,
            height: 40,
            //@ts-expect-error color error
            color: FOHColors.WHITE
          }}
          disabled={isDisabled}
          onPress={onPressUseAction}
        />
      </FOHView>
    </FOHView>
  );
};

const CampaignEstimator = (props: CampaignEstimatorProps) => {
  const { headerLabel, subHeaderLabel, estimationTooltipLabel } = props;
  return (
    <FOHView>
      <FOHHeaderH3 style={{ textAlign: 'left' }}>
        {headerLabel}
        <EstimationsTooltip tooltipLabel={estimationTooltipLabel} />
      </FOHHeaderH3>
      <FOHSpace />
      <FOHSmallDetailLabel style={{ fontStyle: 'italic' }}>
        {subHeaderLabel}
      </FOHSmallDetailLabel>
      <FOHSpace />
    </FOHView>
  );
};

export const CampaignPredictionsAndEstimator = (
  props: CampaignPredictionsAndEstimatorProps
) => {
  const { t } = useTranslation('CampaignsFeature');

  const { campaignId, budget, endDate, startDate, setBudget } = props;

  const estimationQuery = useCampaignEstimationQuery({
    skip: !campaignId || budget === 0,
    variables: {
      campaignId,
      budget: budget,
      startDate: toDate(startDate?.toISOString(), 'yyyy-MM-dd'),
      endDate: toDate(endDate?.toISOString(), 'yyyy-MM-dd')
    }
  });

  const predictionQuery = useCampaignPredictionQuery({
    skip: !campaignId,
    variables: {
      campaignId
    }
  });

  const estimationMessage = estimationQuery?.data?.campaignEstimation?.message;

  const predictionData = path(
    ['data'],
    predictionQuery
  ) as CampaignPredictionQueryResult['data'];
  const prediction = predictionData?.campaignPrediction;

  const handleOnPressUsePrediction = () => {
    if (prediction && prediction.budget) {
      setBudget(prediction.budget);
    }
  };

  const predictionQueryError = predictionQuery.error?.message;
  const estimationQueryError = estimationQuery.error?.message;

  return (
    <FOHView
      style={{
        backgroundColor: FOHColors.GRAYSCALE_10,
        borderRadius: 16,
        paddingHorizontal: 32,
        paddingBottom: 30,
        width: 608
      }}
    >
      <FOHSpaceLarge />
      <CampaignPrediction
        headerLabel={t('campaignPredictionsHeaderLabel')}
        subHeaderLabel={
          predictionQueryError
            ? predictionQueryError
            : t('campaignPredictionsSubHeaderLabel', {
                clicks: prediction?.clicks,
                budget: prediction?.budget,
                days: prediction?.days
              })
        }
        actionButtonLabel={t('campaignPredictionsActionLabel')}
        onPressUseAction={handleOnPressUsePrediction}
        isDisabled={!budget}
        predictionTooltipLabel={t('predictionTooltipLabel')}
        hasMaxBudgetMessage={false}
        maxBudgetMessage={t('maxBudgetMessage')}
      />

      <FOHSpace />

      <FOHDivider />
      <FOHSpace />

      <CampaignEstimator
        headerLabel={t('campaignEstimatorHeaderLabel')}
        subHeaderLabel={
          estimationQueryError
            ? estimationQueryError
            : (estimationMessage as string)
        }
        estimationTooltipLabel={t('estimationTooltipLabel')}
      />

      <FOHView
        style={{
          backgroundColor: FOHColors.GRAYSCALE_100,
          zIndex: 1
        }}
      >
        <FOHSmallDetailLabel style={{ padding: 16 }}>
          {t('predictionsWarning')}
        </FOHSmallDetailLabel>
      </FOHView>
    </FOHView>
  );
};

export default CampaignPredictionsAndEstimator;
