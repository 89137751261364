/* eslint-disable */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { path, prop, length } from 'ramda';

import {
  FOHFixedHeightScrollView,
  FOHView,
  FOHBannerType,
  FOHFormFlowButtons,
  FOHEmployerOnboardingQuestions
} from '../../components';

import {
  useGetQuestionsQuery,
  useMutateAnswerQuestionsMutation
} from '../../graphql/generated';

import { findById, handleMutation } from '../../utils';
import { EXTERNAL_APPLICATION_HEIGHTTODECREASE } from '../../constants';
import { useGetMeData } from '../SignIn';

const hasAnsweredRequiredQuestion = (
  questions,
  answers = [{ questionId: '' }]
) => {
  return (
    length(
      questions.filter(question => {
        const answer = findById(prop('id', question), answers, 'questionId');
        return (
          (prop('required', question) &&
            (prop('value', answer) || prop('freeText', answer))) ||
          !prop('required', question)
        );
      })
    ) !== length(questions)
  );
};

export const EmployerOnboardingQualifyQuestions = props => {
  const variables = {
    category: 'QUALIFYING'
  };
  const questionsQuery = useGetQuestionsQuery({
    variables: {
      ...variables
    }
  });
  const [answerQuestions] = useMutateAnswerQuestionsMutation();
  const { answers, setAnswers, triggerSave, setTriggerSave } = props;
  const { t } = useTranslation('EmployerQualifyQuestions');
  const { data: me } = useGetMeData();
  const questions = (
    path(['data', 'questions', 'edges'], questionsQuery) || []
  ).map(node => ({ ...prop('node', node) }));
  const questionIds = questions.map(question => question.id);
  const qualifyAnswers = answers.filter(answer =>
    questionIds.includes(answer.questionId)
  );

  const employerProfileId = path(['employerProfile', 'id'], me);

  const answerLocationQuestions = async () => {
    if (length(answers) > 0) {
      const responses = qualifyAnswers.map(ans => ({
        question: ans.questionId,
        option: ans.optionId,
        answer:
          length(prop('freeText', ans)) > 0 ? prop('freeText', ans) : ans.value
      }));
      await handleMutation(
        answerQuestions({
          variables: {
            location: '',
            employerProfile: employerProfileId,
            responses
          }
        })
      );
    }

    props.setSettingsBanner &&
      props.setSettingsBanner({
        bannerType: FOHBannerType.SUCCESS,
        bannerMessage: t('common:success')
      });

    props.scrollUp && props.scrollUp();

    return props.nextStep();
  };

  useEffect(() => {
    if (triggerSave) {
      answerLocationQuestions();
      setTriggerSave(false);
    }
  }, [answerLocationQuestions, setTriggerSave, triggerSave]);

  return (
    <FOHView
      style={{
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <FOHView
        style={{
          width: '100%'
        }}
      >
        <FOHFixedHeightScrollView
          isMobile={props.isMobile}
          heightToDecrease={EXTERNAL_APPLICATION_HEIGHTTODECREASE}
        >
          <FOHView
            style={{
              width: '100%'
            }}
          >
            <FOHEmployerOnboardingQuestions
              title={props.hideTitles ? '' : t('title')}
              // not in mock
              tableTitle={''}
              description={''}
              answers={answers}
              hideTitles={props.hideTitles || false}
              largeHeader={props.isMobile}
              loading={prop('loading', questionsQuery)}
              questions={questions}
              next={async () => await answerLocationQuestions()}
              updateAnswer={ans => {
                const restAnswers = answers.filter(
                  answer => prop('questionId', answer) !== ans.questionId
                );
                setAnswers([...restAnswers, ans]);
              }}
            />
          </FOHView>
        </FOHFixedHeightScrollView>
      </FOHView>
      <FOHView
        style={{
          width: '100%',
          marginBottom: props.isMobile ? 0 : 100
        }}
      >
        <FOHFormFlowButtons
          isMobile={true}
          nextButtonlabel={
            prop('loading', questionsQuery)
              ? `${t('common:loading')}...`
              : t('common:next')
          }
          onPressNextButton={
            props.hideTitles
              ? undefined
              : async () => await answerLocationQuestions()
          }
          isNextDisabled={hasAnsweredRequiredQuestion(questions, answers)}
          previousButtonLabel={t('common:previous')}
          onPressPreviousButton={props.previousStep}
        />
      </FOHView>
    </FOHView>
  );
};
