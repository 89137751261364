import React from 'react';

import { View } from 'react-native';
import styled from 'styled-components/native';

import avatar from '../../../assets/avatar.svg';

import {
  FOHLabel,
  FOHSectionLabel,
  FOHButton,
  FOHPlayIcon,
  FOHStatusType,
  FOHStatus,
  FOHEditMarker,
  FOHDirectMessageIcon,
  FOHDetailText,
  FOHButtonSecondary,
  FOHTouchableOpacity,
  FOHToolTipType
} from '../../ingredients';

import { FOHProfileImage, FOHVideoStoryPlayer, FOHShareProps } from '../';

const Circle = styled(View)`
  border-radius: 50%;
  justify-content: center;
  align-items: center;
`;

export interface FOHCandidateProfileBlockProps extends FOHShareProps {
  name: string;
  positions?: string;
  VideoComponent?: any;
  video?: string;
  videoOpen?: boolean;
  videoHasPlayed?: boolean;
  closeVideo?: () => any;
  openVideo?: () => any;
  toggleVideoPlayback?: () => any;
  personalSummary?: string;
  storyPhoto: string;
  mainProfileButtonPressed?: () => any;
  mainProfileButtonTitle?: string;
  vertical?: boolean;
  sharePressed?: () => any;
  shareCTA?: string;
  status?: string;
  statusType?: FOHStatusType;
  showShare?: boolean;
  disabledMainProfileButton?: boolean;
  children?: any;
  openEditPersonalSummary?: () => any;
  onPressMessage?: () => any;
  messageLabel?: string;
  removeCTA?: boolean;
  readOnly?: boolean;
  alignLeft?: boolean;
  padding?: number;
  StatusComponent?: any;
  canManageProfileOption?: () => any;
  OptionsComponent?: any;
  noInterviewScheduleForSchedulerText?: string;
}

export const FOHCandidateProfileBlock = (
  props: FOHCandidateProfileBlockProps
) => {
  const center = props.alignLeft ? false : props.vertical || !props.children;

  return (
    <>
      <View
        style={{
          flexDirection: props.vertical ? 'column' : 'row',
          paddingBottom: 24,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          zIndex: 10,
          paddingLeft: props.vertical ? 0 : 32,
          paddingRight: props.vertical ? 0 : 20,
          paddingTop: props.vertical ? 0 : 20,
          flex: 1
        }}
      >
        <View
          style={{
            flex: 3,
            flexDirection: 'column',
            paddingRight: props.vertical || !props.children ? 0 : 24
          }}
        >
          <View
            style={{
              paddingBottom: 12,
              justifyContent: center ? 'center' : 'flex-start',
              alignItems: center ? 'center' : 'flex-start',
              zIndex: 100
            }}
          >
            <FOHTouchableOpacity
              testID={
                !!props.canManageProfileOption && !props.readOnly
                  ? `manage-profile`
                  : `play-video`
              }
              onPress={() => {
                if (props.canManageProfileOption && !props.readOnly) {
                  props.canManageProfileOption();
                } else if (props.video) {
                  props.openVideo && props.openVideo();
                }
              }}
            >
              <Circle
                style={{
                  backgroundColor: '#51C1DB',
                  padding: 1,
                  width: 104,
                  height: 104
                }}
              >
                <FOHProfileImage
                  photo={props.storyPhoto || avatar}
                  style={{
                    height: 100,
                    width: 100
                  }}
                />
              </Circle>
              {props.video ||
              !!(props.canManageProfileOption && !props.readOnly) ? (
                <PlayCircle style={{ height: 30, width: 30 }}>
                  {props.canManageProfileOption && !props.readOnly ? (
                    <FOHEditMarker style={{ width: 14, height: 14 }} />
                  ) : props.video ? (
                    <FOHPlayIcon />
                  ) : (
                    <></>
                  )}
                </PlayCircle>
              ) : (
                <></>
              )}
            </FOHTouchableOpacity>
            {props.canManageProfileOption ? (
              <View
                style={{
                  justifyContent: 'center',
                  marginBottom: 12
                }}
              >
                {!!props.OptionsComponent && props.OptionsComponent}
              </View>
            ) : (
              <></>
            )}
          </View>
          <View
            style={{
              justifyContent: center ? 'center' : 'flex-start',
              alignItems: center ? 'center' : 'flex-start',
              marginBottom: 6,
              paddingLeft: 0,
              paddingTop: 0
            }}
          >
            <FOHSectionLabel
              numberOfLines={1}
              style={{
                fontSize: 24,
                maxWidth: 320,
                textAlign: center ? 'center' : 'left'
              }}
            >
              {props.name}
            </FOHSectionLabel>
            {props.positions ? (
              <FOHDetailText>{props.positions}</FOHDetailText>
            ) : (
              <></>
            )}
          </View>
          <View
            style={{
              paddingBottom: 12,
              paddingLeft: props.padding ? props.padding : center ? 32 : 0,
              paddingRight: props.padding ? props.padding : 32,
              maxWidth: '100%',
              justifyContent: center ? 'center' : 'flex-start',
              alignItems: center ? 'center' : 'flex-start',
              zIndex: 11
            }}
          >
            <View
              style={{
                justifyContent: 'center',
                marginBottom: 12,
                zIndex: 14
              }}
            >
              {(props.status && props.statusType) || !!props.StatusComponent ? (
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: center ? 'center' : 'flex-start'
                  }}
                >
                  {props.StatusComponent ? (
                    props.StatusComponent
                  ) : props.status && props.statusType ? (
                    <FOHStatus
                      statusType={props.statusType}
                      statusText={props.status}
                    />
                  ) : (
                    <></>
                  )}
                </View>
              ) : (
                <></>
              )}
            </View>
            <View
              style={{
                zIndex: 12
              }}
            >
              <FOHLabel
                style={{
                  textAlign: center ? 'center' : 'left',
                  justifyContent: center ? 'center' : 'flex-start',
                  alignItems: center ? 'center' : 'flex-start',
                  maxWidth: 500,
                  paddingBottom: 12,
                  lineHeight: props.openEditPersonalSummary ? 32 : 24
                }}
              >
                {props.personalSummary}
                {props.openEditPersonalSummary && !props.readOnly && (
                  <FOHTouchableOpacity
                    testID={`edit-summary`}
                    onPress={props.openEditPersonalSummary}
                    style={{
                      paddingLeft: 6,
                      height: 12,
                      top: 6
                    }}
                  >
                    <FOHEditMarker />
                  </FOHTouchableOpacity>
                )}
              </FOHLabel>
            </View>
          </View>

          {!props.removeCTA && (
            <View
              style={{
                justifyContent: center ? 'center' : 'flex-start',
                alignItems: center ? 'center' : 'flex-start',
                width: '100%',
                paddingLeft: props.vertical ? 24 : 0,
                paddingRight: props.vertical ? 24 : 0,
                flexDirection: 'row',
                flexWrap: props.onPressMessage ? 'wrap' : undefined,
                zIndex: 15
              }}
            >
              {props.mainProfileButtonPressed &&
              !!props.mainProfileButtonTitle ? (
                <FOHButton
                  onPress={props.mainProfileButtonPressed}
                  disabled={props.disabledMainProfileButton}
                  title={props.mainProfileButtonTitle}
                  style={{
                    marginRight: props.vertical ? 0 : 8,
                    maxWidth: props.vertical ? 240 : 184,
                    paddingBottom: 10
                  }}
                  enableHover={!!props.disabledMainProfileButton}
                  helpText={props?.noInterviewScheduleForSchedulerText}
                  toolTipType={FOHToolTipType.CENTER}
                />
              ) : (
                <></>
              )}
              {props.onPressMessage && !!props.messageLabel ? (
                <>
                  <FOHButtonSecondary
                    icon={FOHDirectMessageIcon}
                    testID={'on-press-message'}
                    onPress={props.onPressMessage}
                    title={props.messageLabel}
                    style={{
                      // TODO: uncomment when bitly works
                      // maxWidth: 184,

                      maxWidth: props.vertical ? 240 : 184,
                      paddingBottom: 10
                    }}
                  />
                </>
              ) : (
                <></>
              )}
              {/* TODO: uncomment when bitly works */}
              {/* <View
                style={{
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: 50,
                  marginLeft: 8
                }}
              >
                {props.sharePressed && (
                  <FOHInverseButton
                    icon={FOHShareIcon}
                    color={'#000000'}
                    centerIcon={true}
                    style={{
                      width: 50,
                      paddingBottom: 10
                    }}
                    onPress={props.sharePressed}
                    testID={'on-press-share'}
                    title={''}
                  ></FOHInverseButton>
                )}
                {props.showShare && (
                  <View
                    style={{
                      position: 'absolute',
                      top: 50,
                      paddingBottom: 24,
                      left: props.vertical ? -200 : undefined
                    }}
                  >
                    <FOHShare
                      left={props.vertical ? 80 : undefined}
                      facebookPressed={props.facebookPressed}
                      linkedInPressed={props.linkedInPressed}
                      twitterPressed={props.twitterPressed}
                      emailPressed={props.emailPressed}
                      linkPressed={props.linkPressed}
                    />
                  </View>
                )}
              </View> */}
            </View>
          )}
        </View>
        {!props.vertical && !!props.children ? (
          <View
            style={{
              flex: 2,
              justifyContent: 'flex-start',
              alignItems: 'flex-start'
            }}
          >
            {props.children}
          </View>
        ) : (
          <></>
        )}
      </View>
      {props.vertical && !!props.children ? (
        <View
          style={{
            flex: 3,
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            padding: 10
          }}
        >
          {props.children}
        </View>
      ) : (
        <></>
      )}
      {props.closeVideo && props.toggleVideoPlayback && props.VideoComponent ? (
        <FOHVideoStoryPlayer
          open={props.videoOpen}
          togglePlaying={props.toggleVideoPlayback}
          close={props.closeVideo}
          profilePhoto={props.storyPhoto}
          video={props.video}
          VideoComponent={props.VideoComponent}
        />
      ) : (
        <></>
      )}
    </>
  );
};

const PlayCircle = styled(View)`
  border-radius: 50%;
  border-width: 2px;
  border-color: #51c1db;
  justify-content: center;
  align-items: center;
  top: 74;
  left: 76;
  background-color: #ffffff;
  position: absolute;
`;
