import React, { FunctionComponent, useRef, useState } from 'react';

import { useOnClickOutside } from 'use-hooks';

import { formatFileSize } from '../../../features/CareerPage/utils';

import {
  FOHBlueAdd,
  FOHColorPicker,
  FOHColors,
  FOHEditMarker,
  FOHInverseButton,
  FOHScrollView,
  FOHSmallDetailLabel,
  FOHTouchableOpacity,
  FOHTrashCan,
  FOHView,
  FOHXIcon,
  SPACING_SCALE
} from '../../ingredients';
import { PreviewImage } from '../../templates';
import { FOH_INPUT_HEIGHT, TILECOLORS } from '../../constants';
import { FileFields } from '../../typings';

import {
  ImageSize,
  ImageNameHeader,
  NameSizeWrapper,
  SectionWrapper
} from '../fileinputs/FOHCareerPageCoverMediaInput.styled';
import { FOHPickStockPhotoForm } from '../forms/FOHPickStockPhotoForm';

import {
  SectionHeader,
  ColorBox,
  ColorsWrapper,
  MainContent,
  Wrapper
} from './FOHEditCoverPhotoButton.styled';

export interface FilePreview {
  fileName?: string;
  fileType?: string;
  fileSize?: string;
  url?: string;
}

export interface CoverAssetImage extends FilePreview {
  file?: File;
}

export type FilePreviewSetter = React.Dispatch<
  React.SetStateAction<FilePreview | null>
>;

export enum CoverMode {
  Blank = 'blank',
  Color = 'color',
  Image = 'image'
}

interface CoverAssetObj {
  color: string | null;
  image: CoverAssetImage | null;
  mode?: CoverMode;
}

export interface FOHEditCoverPhotoButtonProps {
  blobToFile: (dataURL: string, callback: (file: File) => void) => void;
  coverAsset: CoverAssetObj;
  loadMore: () => void;
  mediaInput: any;
  setCoverAsset: ({ color, image }: CoverAssetObj) => void;
  stockPhotos: string[];
  translations: {
    dimensionLabel: string;
    pickColorHeader: string;
    pickStockPhotoHeader: string;
    setCustomColorHeader: string;
    loadMoreButton: string;
    editCoverPhotoButton: string;
    changePhotoButton: string;
    uploadPhotoButton: string;
    mainHeader: string;
  };
}

export const FOHEditCoverPhotoButton: FunctionComponent<
  FOHEditCoverPhotoButtonProps
> = ({
  blobToFile,
  coverAsset,
  loadMore,
  mediaInput,
  setCoverAsset,
  stockPhotos,
  translations
}) => {
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [filePreview, setFilePreview] = useState<FilePreview | null>(
    coverAsset?.image
      ? {
          [FileFields.FileName]: coverAsset.image.fileName,
          [FileFields.FileSize]: coverAsset.image.fileSize,
          url: coverAsset.image.url
        }
      : null
  );

  const mainWrapperRef = useRef<Node>(null) as React.MutableRefObject<Node>;

  const handleClose = () => setIsOpenDropdown(false);

  useOnClickOutside(mainWrapperRef, handleClose);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    if (event.target.files) {
      const { files } = event.target;
      const file = files?.[0];

      if (file) {
        setFilePreview({
          [FileFields.FileName]: file.name,
          [FileFields.FileSize]: formatFileSize(file.size),
          url: URL.createObjectURL(file)
        });

        setCoverAsset({
          color: null,
          image: {
            file,
            [FileFields.FileName]: file.name,
            [FileFields.FileType]: file.type,
            url: URL.createObjectURL(event.target.files[0])
          },
          mode: CoverMode.Image
        });
      }
    }
  };

  const handleDeleteImg = () => {
    setCoverAsset({
      color: null,
      image: null,
      mode: CoverMode.Blank
    });

    setFilePreview(null);
  };

  const handleSetCoverColor = (color: string) => {
    setCoverAsset({
      color,
      image: null,
      mode: CoverMode.Color
    });
    setFilePreview(null);
  };

  return (
    <Wrapper ref={mainWrapperRef}>
      <FOHInverseButton
        color={FOHColors.WHITE}
        icon={() => (
          <FOHEditMarker
            style={{
              height: 18,
              tintColor: FOHColors.GRAYSCALE_700,
              width: 18
            }}
          />
        )}
        onPress={() => setIsOpenDropdown(prev => !prev)}
        opaque
        selected
        style={{
          height: FOH_INPUT_HEIGHT,
          marginLeft: 'auto',
          width: 200
        }}
        textColor={FOHColors.GRAYSCALE_700}
        title={translations.editCoverPhotoButton}
      />

      {isOpenDropdown && (
        <MainContent>
          <FOHTouchableOpacity onPress={handleClose}>
            <FOHXIcon
              style={{
                position: 'absolute',
                right: -SPACING_SCALE.large,
                top: -SPACING_SCALE.large
              }}
              testID="close-edit-cover-photo"
            />
          </FOHTouchableOpacity>

          <SectionHeader style={{ marginBottom: SPACING_SCALE.larger }}>
            {translations.mainHeader}
          </SectionHeader>

          <PickMedia
            filePreview={filePreview}
            handleChange={handleChange}
            handleDelete={handleDeleteImg}
            mediaInput={mediaInput}
            translations={translations}
          />

          <FOHSmallDetailLabel style={{ marginTop: 5 }}>
            {translations.dimensionLabel}
          </FOHSmallDetailLabel>

          <FOHPickStockPhotoForm
            blobToFile={blobToFile}
            setCoverAsset={setCoverAsset}
            setFilePreview={setFilePreview}
            stockPhotos={stockPhotos}
            translations={translations}
          />

          <FOHInverseButton
            color={FOHColors.BACKGROUND_GRAY}
            onPress={loadMore}
            opaque={true}
            selected={true}
            style={{ marginTop: 8 }}
            textColor={FOHColors.BLACK}
            title={translations.loadMoreButton}
          />

          <SectionHeader>{translations.pickColorHeader}</SectionHeader>
          <PickColorSection onCoverCoverColorChange={handleSetCoverColor} />

          <SectionHeader style={{ marginBottom: SPACING_SCALE.larger }}>
            {translations.setCustomColorHeader}
          </SectionHeader>

          <FOHColorPicker
            setValue={handleSetCoverColor}
            style={{ width: 163 }}
            value={coverAsset.color || ''}
          />
        </MainContent>
      )}
    </Wrapper>
  );
};

const PickColorSection = ({
  onCoverCoverColorChange
}: {
  onCoverCoverColorChange: (color: string) => void;
}) => (
  <ColorsWrapper>
    {TILECOLORS.map(tileColor => (
      <FOHTouchableOpacity
        key={tileColor}
        onPress={(): void => {
          onCoverCoverColorChange(tileColor);
        }}
        style={{ marginRight: 14, marginTop: 14 }}
      >
        <ColorBox color={`#${tileColor}`} />
      </FOHTouchableOpacity>
    ))}
  </ColorsWrapper>
);

export const PickMedia = <T extends FilePreview = FilePreview>({
  filePreview,
  handleChange,
  handleDelete,
  mediaInput: MediaInput,
  translations
}: Pick<FOHEditCoverPhotoButtonProps, 'mediaInput'> & {
  filePreview: T[] | T | null;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  handleDelete: (url?: string) => void;
  translations: Pick<
    Pick<FOHEditCoverPhotoButtonProps, 'translations'>['translations'],
    'changePhotoButton' | 'uploadPhotoButton'
  >;
}) => (
  <FOHView>
    {filePreview &&
      ('length' in filePreview ? (
        filePreview.length > 0 && (
          <FOHScrollView
            style={{
              maxHeight: 300
            }}
          >
            {filePreview.map(filePreviewItem => (
              <MediaPreviewItem
                filePreview={filePreviewItem}
                handleDelete={() => handleDelete(filePreviewItem.url)}
                key={filePreviewItem.url}
              />
            ))}
          </FOHScrollView>
        )
      ) : (
        <MediaPreviewItem
          filePreview={filePreview}
          handleDelete={handleDelete}
        />
      ))}

    <MediaInput
      handleChange={handleChange}
      icon={() => (filePreview ? <FOHEditMarker /> : <FOHBlueAdd />)}
      title={
        filePreview
          ? translations.changePhotoButton
          : translations.uploadPhotoButton
      }
    />
  </FOHView>
);

export const MediaPreviewItem = ({
  filePreview,
  handleDelete
}: {
  filePreview: FilePreview;
  handleDelete: () => void;
}) => (
  <SectionWrapper
    style={{
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: SPACING_SCALE.larger
    }}
  >
    <FOHView style={{ flexDirection: 'row' }}>
      <PreviewImage
        source={{ uri: filePreview.url }}
        type={filePreview.fileType}
      />
      <NameSizeWrapper>
        <ImageNameHeader>{filePreview.fileName}</ImageNameHeader>
        <ImageSize>{filePreview.fileSize}</ImageSize>
      </NameSizeWrapper>
    </FOHView>

    <FOHTouchableOpacity
      onPress={() => {
        // eslint-disable-next-line no-restricted-globals
        const result = confirm('Are you sure you want to delete this image?');

        if (result) handleDelete();
      }}
    >
      <FOHTrashCan />
    </FOHTouchableOpacity>
  </SectionWrapper>
);
