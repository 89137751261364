import React, { useState } from 'react';

import { defaultTo, prop } from 'ramda';
import { useTranslation } from 'react-i18next';
import { useWindowDimensions } from 'react-native-web';

import { ADD_NEW_LOCATION, ALLOWED_ROLES_GROUPS } from '../../constants';
import {
  FOHBlueAdd,
  FOHColors,
  FOHLink,
  FOHLocationPin,
  FOHSearchableSelectDropdown,
  FOHView,
  RestrictedView
} from '../../components';
import { useNavigation } from '../../utils/navigation';

import { useCurrentLocationContext } from '../Locations/useCurrentLocationContext';

import { useSidebarNav } from './useSidebar';

export const LocationContextSelector = ({ shouldShow = false, ...props }) => {
  const { navigateTo, location: navLocation } = useNavigation();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const { t } = useTranslation('GlobalLocationSelectorFeature');

  const { myLocations, currentLocation, selectLocationFilter } =
    useCurrentLocationContext({
      props,
      search
    });

  const locations = myLocations.map(location => ({
    label: prop('displayName', location),
    sublabel: prop('formattedAddress', location),
    value: prop('id', location)
  }));

  const { alwaysOpenNav, hasSideNav } = useSidebarNav(props);

  const { width } = useWindowDimensions();

  if (!hasSideNav && !shouldShow) {
    return <></>;
  }

  return (
    <FOHView
      style={{
        position: props.viewPosition || 'absolute',
        zIndex: 1,
        paddingLeft: props && navLocation.pathname.includes('/feed') ? 0 : 30,
        paddingTop: 10,
        width: alwaysOpenNav ? width - 240 : 'auto',
        height: 60,
        backgroundColor: navLocation.pathname.includes('/settings/')
          ? FOHColors.WHITE
          : 'transparent',
        ...defaultTo({}, props.containerStyle)
      }}
    >
      <FOHSearchableSelectDropdown
        backgroundColor={FOHColors.WHITE}
        open={open}
        setOpen={setOpen}
        selectedValue={prop('id', currentLocation)}
        selectedLabel={
          !prop('id', currentLocation) ? prop('name', currentLocation) : ''
        }
        onSearchChange={setSearch}
        searchValue={search}
        searchPlaceholder={t('common:search')}
        onValueChange={value => {
          selectLocationFilter(value);
          setOpen(!open);
        }}
        options={locations}
        fieldLabel={t('LocationDetailFeature:locationLabel')}
        icon={() => <FOHLocationPin style={{ width: 14, height: 14 }} />}
      >
        <RestrictedView
          allowedRoles={ALLOWED_ROLES_GROUPS.ROLES.ADMIN}
          allowedGroups={[ALLOWED_ROLES_GROUPS.GROUPS.EMPLOYER]}
        >
          <FOHLink
            onPress={() => {
              props && navigateTo(ADD_NEW_LOCATION);
            }}
            linkText={t('TopNavFeature:addLocation')}
            Icon={FOHBlueAdd}
            iconSize={24}
          />
        </RestrictedView>
      </FOHSearchableSelectDropdown>
    </FOHView>
  );
};
