import { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { findById } from '../../utils';
import { FOHPositionHiringStatusType } from '../../components';

export const usePositionHiringStatus = initialStatus => {
  const { t } = useTranslation('PricingScreenFeature');
  const [openStatusDropdown, setOpenStatusDropdown] = useState(false);
  const statusOptions = [
    {
      label: t('CandidateHomeFeature:posted'),
      description: t('postedDescription'),
      value: FOHPositionHiringStatusType.POSTED,
      selected: true
    },
    {
      label: t('notPostedLabel'),
      description: t('notPostedDescription'),
      value: FOHPositionHiringStatusType.NOT_POSTED,
      selected: false
    }
  ];

  const [status, setStatus] = useState(statusOptions[1]);

  useEffect(() => {
    initialStatus
      ? setStatus(findById(initialStatus, statusOptions, 'value'))
      : setStatus(statusOptions[1]);
  }, [initialStatus]);

  return {
    status,
    setStatus,
    openStatusDropdown,
    setOpenStatusDropdown,
    statusOptions
  };
};
