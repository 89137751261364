import React, { useEffect, useState } from 'react';

import { find, length, propEq, path, prop, defaultTo } from 'ramda';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import {
  FOHPositionBillingStatusType,
  FOHButton,
  FOHBannerType,
  FOHSettingsBanner,
  FOHScrollView,
  FOHView,
  FOHTable,
  FOHTableColumnType,
  FOHPositionHiringStatusType,
  FOHDismissedWarningKeys
} from '../../components';
import {
  useGetMyPositionsPlanQuery,
  GetMyPositionsPlanDocument,
  useDeletePositionMutation,
  MyPositionsDocument,
  useMutatePositionMutation
} from '../../graphql/generated';
import {
  ADD_NEW_POSITION,
  EMPLOYER_SETTINGS_MENU,
  POSITIONS_URL,
  UPGRADE
} from '../../constants';
import {
  checkIfWarningIsDismissedByKey,
  findById,
  handle,
  handleMutation,
  unwrapEdgesAt,
  useIsMobile
} from '../../utils';
import { useNavigation } from '../../utils/navigation';

import { PlanModals } from '../Billing/PlanModals';
import { LocationContextSelector } from '../Navigation/LocationContextSelector';
import { useCurrentLocationContext } from '../Locations/useCurrentLocationContext';
import { useBanner } from '../Navigation/useBanner';
import { usePaymentPlan } from '../Billing/usePaymentPlan';
import { usePositionTypes } from '../CandidateProfile/usePositionTypes';

import { usePositionHiringStatus } from './usePositionHiringStatus';
import { NotHiringConfirmationWarningModal } from './components';

const toDate = isoString => {
  return DateTime.fromISO(isoString, {
    zone: 'local'
  }).toFormat('M/dd/yyyy');
};

export const MyPositions = props => {
  const { navigateTo, location: navLocation } = useNavigation();
  const { selectLocationFilter, locationFilter, currentLocation } =
    useCurrentLocationContext({
      props
    });
  const [positionMutation] = useMutatePositionMutation();
  const [deletePosition] = useDeletePositionMutation();

  const [isOpenNotPostedWarningModal, setIsOpenNotPostedWarningModal] =
    useState(false);

  const { canChangeHiringFreely } = usePaymentPlan({
    locationFilter,
    currentPlanQ: true
  });

  const { getStringForPositionType } = usePositionTypes();

  const myPositionsQuery = useGetMyPositionsPlanQuery({
    skip: !locationFilter,
    variables: {
      location: locationFilter
    }
  });

  const myPositions = unwrapEdgesAt(
    ['data', 'myPositions', 'edges'],
    myPositionsQuery
  );

  const { statusOptions } = usePositionHiringStatus();

  const getFullStatus = hiringStatus =>
    findById(hiringStatus, statusOptions, 'value');

  const { t } = useTranslation('MyPositionsFeature');

  const { isMobile } = useIsMobile();

  const { banner, setBanner } = useBanner();

  const [hiringDropdown, setHiringDropdown] = useState('');

  const positions = myPositions.map(position => ({
    id: prop('id', position),
    columnData: [
      {
        text: prop('positionName', position),
        subtext: path(['formattedAddress'], currentLocation)
      },
      {
        text: `${prop('positionType', position)
          .slice(0, 2)
          .map(pos => getStringForPositionType(pos))
          .join('\n')}${
          length(prop('positionType', position)) > 2 ? '...' : ''
        }`
      },
      {
        statusText: defaultTo(
          statusOptions[1].label,
          prop('label', getFullStatus(prop('hiringStatus', position)))
        ),
        statusType: defaultTo(
          statusOptions[1].value,
          prop('value', getFullStatus(prop('hiringStatus', position)))
        ),
        open: hiringDropdown === prop('id', position),
        setOpen: () => {
          hiringDropdown === prop('id', position)
            ? setHiringDropdown(undefined)
            : setHiringDropdown(prop('id', position));
        }
      },
      { text: toDate(prop('createdAt', position)) }
    ]
  }));

  useEffect(() => {
    if (path(['state', 'success'], navLocation)) {
      setBanner({
        bannerType: FOHBannerType.SUCCESS,
        bannerMessage: t('common:success')
      });
    }
  }, [props, setBanner, t]);

  return (
    <>
      <LocationContextSelector {...props} />
      <FOHView
        style={{
          height: '100%',
          paddingTop: 50,
          backgroundColor: '#f5f5f5'
        }}
      >
        <FOHSettingsBanner title={t('bannerTitle')} {...banner}>
          <FOHButton
            title={t('createPosition')}
            onPress={async () => {
              navigateTo(ADD_NEW_POSITION);
            }}
          />
        </FOHSettingsBanner>
        <FOHScrollView
          style={{
            height: isMobile ? '72vh' : '80vh',
            width: isMobile ? undefined : '100%'
          }}
        >
          <FOHView
            style={{
              maxHeight: '100%',
              minHeight: '80vh',
              paddingBottom: 150,
              backgroundColor: '#f5f5f5',
              paddingLeft: 24,
              paddingRight: 24
            }}
          >
            <FOHTable
              hideTableHeader={isMobile}
              data={positions}
              columns={[
                {
                  columnType: FOHTableColumnType.subtext,
                  columnName: t('nameColumn'),
                  onPress: val => {
                    const position = find(propEq('id', val))(myPositions);
                    window && window.open(`/jobs/${prop('slug', position)}`);
                  }
                },
                {
                  columnType: FOHTableColumnType.text,
                  columnName: t('positionTypeColumn'),
                  hidden: isMobile
                },
                {
                  columnType: FOHTableColumnType.statusDropdown,
                  columnName: 'Hiring',
                  statusOptions: statusOptions,
                  onPress: async ({ status, id }) => {
                    const position = find(propEq('id', id))(myPositions);
                    const isSubscribed =
                      path(['paymentStatus'], position) ===
                        FOHPositionBillingStatusType.SUBSCRIBED ||
                      path(['location', 'paymentStatus'], position) ===
                        FOHPositionBillingStatusType.SUBSCRIBED;

                    setHiringDropdown(undefined);

                    if (
                      prop('value', status) ===
                        prop('hiringStatus', position) ||
                      (prop('value', status) ===
                        FOHPositionHiringStatusType.NOT_POSTED &&
                        !prop('hiringStatus', position))
                    ) {
                      return;
                    }

                    const refetchQueries = [
                      {
                        query: GetMyPositionsPlanDocument,
                        variables: {
                          location: locationFilter
                        }
                      }
                    ];

                    // selecting not posted marked hiring = False
                    if (
                      prop('value', status) ===
                      FOHPositionHiringStatusType.NOT_POSTED
                    ) {
                      const [response, error] = await handleMutation(
                        positionMutation({
                          variables: {
                            id: prop('id', position),
                            positionName: prop('positionName', position),
                            positionType: prop('positionType', position),
                            hiring: false,
                            location: locationFilter
                          },
                          refetchQueries
                        })
                      );

                      if (error || !response) {
                        return setBanner({
                          bannerType: FOHBannerType.DANGER,
                          bannerMessage:
                            error?.message ||
                            t('unableToChangeHiringStatusError')
                        });
                      }

                      if (
                        !checkIfWarningIsDismissedByKey(
                          FOHDismissedWarningKeys.DO_NOT_SHOW_ME_AGAIN
                        ) &&
                        !canChangeHiringFreely
                      ) {
                        setIsOpenNotPostedWarningModal(true);
                      }

                      return;
                    }

                    // selecting posted
                    if (
                      prop('value', status) ===
                      FOHPositionHiringStatusType.POSTED
                    ) {
                      if (isSubscribed) {
                        // if position or position/location is subscribed then marked hiring = True
                        return await handle(
                          positionMutation({
                            variables: {
                              id: prop('id', position),
                              positionName: prop('positionName', position),
                              positionType: prop('positionType', position),
                              hiring: true,
                              location: locationFilter
                            },
                            refetchQueries
                          })
                        );
                      } else {
                        // if position or position/location is not subscribed then navigating to upgrade
                        selectLocationFilter(
                          path(['location', 'id'], position)
                        );
                        navigateTo(
                          `${EMPLOYER_SETTINGS_MENU}${POSITIONS_URL}/status${UPGRADE}`
                        );
                      }
                    }
                  }
                },
                {
                  columnType: FOHTableColumnType.text,
                  columnName: t('createdAt'),
                  hidden: isMobile
                },
                // Table actions
                {
                  columnType: FOHTableColumnType.action,
                  columnName: '',
                  label: t('edit'),
                  onPress: val => {
                    navigateTo(`${navLocation.pathname}/${val}`);
                  }
                },
                {
                  columnType: FOHTableColumnType.action,
                  columnName: '',
                  label: t('remove'),
                  onPress: async id => {
                    const position = find(propEq('id', id))(myPositions);

                    if (
                      prop('paymentStatus', position) ===
                      FOHPositionBillingStatusType.SUBSCRIBED
                    ) {
                      return setBanner({
                        bannerMessage:
                          'Cannot archive an Actively Hiring Position',
                        bannerType: FOHBannerType.DANGER
                      });
                    }
                    await handleMutation(
                      deletePosition({
                        // TODO: replace with update for better performance
                        refetchQueries: [
                          {
                            query: MyPositionsDocument,
                            variables: {
                              location: locationFilter,
                              search: ''
                            }
                          },
                          {
                            query: GetMyPositionsPlanDocument,
                            variables: {
                              location: locationFilter
                            }
                          }
                        ],
                        variables: {
                          id
                        }
                      })
                    );
                  }
                }
              ]}
            />
          </FOHView>
        </FOHScrollView>
      </FOHView>
      <NotHiringConfirmationWarningModal
        confirmIUnderstand={() => setIsOpenNotPostedWarningModal(false)}
        open={isOpenNotPostedWarningModal}
      />
      <PlanModals
        {...props}
        backPath={`${EMPLOYER_SETTINGS_MENU}${POSITIONS_URL}`}
      />
    </>
  );
};
