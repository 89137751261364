import React, { useState, useEffect, useRef } from 'react';

import { path, defaultTo } from 'ramda';
import { useTranslation } from 'react-i18next';

import {
  FOHColors,
  FOHScrollView,
  FOHView,
  FOHSpace,
  FOHSpaceSmall,
  FOHSettingsBanner,
  FOHTextButton,
  FOHFetchIcon,
  FOHMoreInfoIcon,
  FOHOpenIcon,
  FOHSettingsGrayIcon,
  FOHButtonBordered,
  FOHInverseButton
} from '../../components';
import {
  JobBoardCampaignType,
  useSyncCampaignsMutation,
  GetCampaignsDataDocument,
  useGetCampaignsDataQuery
} from '../../graphql/generated';
import {
  useIsMobile,
  unwrapEdgesAt,
  toDateTime,
  handleMutation
} from '../../utils';
import { CAMPAIGN_CREATE } from '../../constants';
import { useNavigation } from '../../utils/navigation';

import { useGetMeData } from '../SignIn';

import { HOME_TABS, DEFAULT_TAB } from './constants';
import { CampaignList } from './CampaignList';

const getFilteredCampaigns = (
  campaigns: JobBoardCampaignType[],
  selectedTab: keyof typeof HOME_TABS,
  searchText?: string
) => {
  if (campaigns.length === 0) return campaigns;

  let filteredCampaigns = campaigns;

  if (selectedTab === HOME_TABS.ActiveCampaigns) {
    filteredCampaigns = filteredCampaigns.filter(campaign => campaign.active);
  } else if (selectedTab === HOME_TABS.CompletedCampaigns) {
    filteredCampaigns = filteredCampaigns.filter(
      campaign => campaign.campaignEndedOn !== null
    );
  } else if (selectedTab === HOME_TABS.DraftCampaigns) {
    filteredCampaigns = filteredCampaigns.filter(campaign => campaign.isDraft);
  }

  filteredCampaigns.filter(
    campaign =>
      defaultTo('', path(['name'], campaign))?.includes(searchText || '')
  );

  return filteredCampaigns;
};

export const CampaignHome = () => {
  const { t } = useTranslation('CampaignsFeature');
  const { navigateTo } = useNavigation();
  const { isMobile } = useIsMobile();
  const scrollRef = useRef();

  const [selectedTab, setSelectedTab] = useState<keyof typeof HOME_TABS>(
    DEFAULT_TAB as keyof typeof HOME_TABS
  );
  const [isRefresh, setIsRefresh] = useState(false);

  const [syncCampaigns] = useSyncCampaignsMutation();

  const { data: me } = useGetMeData();
  const employerProfileId = path(['employerProfile', 'id'], me);

  const campaignsDataQuery = useGetCampaignsDataQuery({
    skip: !employerProfileId,
    pollInterval: 3600000,
    variables: { employer: employerProfileId as string }
  });

  const campaignsList = defaultTo(
    [],
    unwrapEdgesAt(['data', 'jobBoardCampaigns', 'edges'], campaignsDataQuery)
  );

  //@ts-ignore oldestCampaign
  const oldestCampaign = campaignsList.reduce(
    (oldCampaign: any, campaign: any) => {
      if (!oldCampaign?.statsUpdatedAt) {
        return campaign;
      }
      return oldCampaign?.statsUpdatedAt > campaign.statsUpdatedAt
        ? campaign
        : oldCampaign;
    },
    [{}]
  );

  const oldestUpdatedAt = oldestCampaign?.statsUpdatedAt;
  const campaigns = getFilteredCampaigns(campaignsList, selectedTab);

  const handleSyncCampaigns = async () => {
    await handleMutation(
      syncCampaigns({
        variables: {
          employerId: employerProfileId as string
        },
        refetchQueries: [
          {
            query: GetCampaignsDataDocument,
            variables: { employer: employerProfileId }
          }
        ]
      })
    );
  };

  const checkCampaignUpdatedAt = () => {
    if (oldestUpdatedAt) {
      const timeDiff =
        new Date().getTime() - new Date(oldestUpdatedAt).getTime();
      if (timeDiff > 600000) {
        setIsRefresh(true);
      }
      if (timeDiff > 3600000) {
        handleSyncCampaigns();
      }
    }
  };

  useEffect(() => {
    campaignsDataQuery.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    checkCampaignUpdatedAt();

    const interval = setInterval(() => {
      checkCampaignUpdatedAt();
    }, 600000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignsDataQuery]);

  return (
    <>
      <FOHView
        style={{
          backgroundColor: FOHColors.BACKGROUND_GRAY,
          height: '100%',
          minHeight: '100vh'
        }}
      >
        <FOHSettingsBanner
          title={t('campaigns')}
          tabs={[
            {
              label: t('activeCampaigns'),
              value: HOME_TABS.ActiveCampaigns,
              testID: 'FOHSettingsBanner-active-campaigns'
            },
            {
              label: t('completedCampaigns'),
              value: HOME_TABS.CompletedCampaigns,
              testID: 'FOHSettingsBanner-completed-campaigns'
            },
            {
              label: t('draftCampaigns'),
              value: HOME_TABS.DraftCampaigns,
              testID: 'FOHSettingsBanner-draft-campaigns'
            }
          ]}
          setSelectedTab={(route: string) => {
            setSelectedTab(route as keyof typeof HOME_TABS);
          }}
          selectedTab={selectedTab}
          sticky={false}
        >
          <FOHView>
            <FOHTextButton
              title={
                oldestUpdatedAt &&
                `Stats Updated At: ${toDateTime(oldestUpdatedAt)}`
              }
              disabled={false}
              icon={() =>
                isRefresh && (
                  <FOHFetchIcon
                    style={{
                      tintColor: FOHColors.BLACK,
                      width: 15,
                      height: 15
                    }}
                  />
                )
              }
              reverseIcon={true}
              onPress={() => {
                handleSyncCampaigns();
              }}
              touchableStyle={{ width: 'fit-content' }}
            />
            <FOHView style={{ flexDirection: 'column' }}>
              <FOHView style={{ flexDirection: 'row' }}>
                <FOHButtonBordered
                  textColor={FOHColors.WHITE}
                  color={FOHColors.PRIMARY_TEAL_P1}
                  title={`+ ${t('newCampaign')}`}
                  onPress={() => {
                    navigateTo(CAMPAIGN_CREATE);
                  }}
                  style={{ height: 52, width: 187, margin: 4 }}
                />
                <FOHInverseButton
                  title={t('help')}
                  icon={() => <FOHMoreInfoIcon />}
                  onPress={() => {
                    window.open(
                      'https://help.fohandboh.com/en/articles/6884928-sponsoring-jobs-help'
                    );
                  }}
                  style={{ height: 52, width: 88, margin: 4 }}
                />
              </FOHView>
              <FOHSpaceSmall />
              <FOHView style={{ flexDirection: 'row' }}>
                <FOHTextButton
                  title={t('indeedAnalytics')}
                  disabled={false}
                  icon={() => (
                    <FOHOpenIcon
                      style={{
                        tintColor: FOHColors.BLACK,
                        height: 15,
                        width: 15
                      }}
                    />
                  )}
                  onPress={() => {
                    window &&
                      window.open &&
                      window.open(
                        `https://secure.indeed.com/auth?hl=en_US&co=US&service=emp&continue=https%3A%2F%2Fanalytics.indeed.com%2Fanalytics%2Fperformance%2Fads`,
                        '_blank'
                      );
                  }}
                  touchableStyle={{
                    justifyContent: 'flex-end'
                  }}
                  underline
                />
                <FOHSpace />
                <FOHTextButton
                  title={t('indeedAccountSetting')}
                  disabled={false}
                  icon={() => (
                    <FOHSettingsGrayIcon
                      style={{
                        tintColor: FOHColors.BLACK,
                        height: 15,
                        width: 15
                      }}
                    />
                  )}
                  onPress={() => {
                    window &&
                      window.open &&
                      window.open(
                        `https://secure.indeed.com/settings/account?hl=en_US`,
                        '_blank'
                      );
                  }}
                  touchableStyle={{
                    justifyContent: 'flex-end'
                  }}
                  underline
                />
              </FOHView>
            </FOHView>
          </FOHView>
        </FOHSettingsBanner>

        <FOHScrollView
          ref={scrollRef}
          style={{
            height: isMobile ? '60vh' : '80vh',
            width: isMobile ? undefined : '100%'
          }}
        >
          <FOHView
            style={{
              alignItems: isMobile ? 'center' : 'flex-start',
              paddingBottom: 200
            }}
          >
            <CampaignList campaigns={campaigns} selectedTab={selectedTab} />
          </FOHView>
        </FOHScrollView>
        <FOHSpace />
      </FOHView>
    </>
  );
};
