import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { prop, defaultTo } from 'ramda';

import { CANDIDATE_GROUP } from '../../constants';
import { useIsMobile } from '../../utils';
import {
  FOHButton,
  FOHInverseButton,
  FOHView,
  FOHSpace,
  FOHReactiveModal,
  FOHSignInForm,
  FOHColors,
  FOHDetailText,
  FOHHeaderH2,
  FOHRegisterForm
} from '../../components';
import { useNavigation } from '../../utils/navigation';

import { useSidebarNav } from '../Navigation/useSidebar';
import { useGetMeData } from '../SignIn';

import { useSetValidEmail } from './useSetValidEmail';

export const SetValidEmailModal = props => {
  const { onlySignIn } = props;
  const { location: navLocation } = useNavigation();

  const { data: me } = useGetMeData();
  const t = prop('t', useTranslation('IncompleteProfileFeature'));
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [phone, setPhone] = useState('');
  const [terms, setTerms] = useState(false);
  const [alreadyHasAccount, setAlreadyHasAccount] = useState(onlySignIn);

  useEffect(() => {
    if (prop('email', me) || prop('firstName', me) || prop('lastName', me)) {
      // do not expose indeed emails to candidate
      !defaultTo('', prop('email', me)).includes('indeedemail') &&
        setEmail(defaultTo('', prop('email', me)));
      setLastName(defaultTo('', prop('lastName', me)));
      setFirstName(defaultTo('', prop('firstName', me)));
      setPhone(defaultTo('', prop('phone', me)));
    }
  }, [me]);

  const isIndeedApplicant = defaultTo('', prop('email', me)).includes(
    'indeedemail'
  );

  const completeApplicationSignUp =
    navLocation.pathname.includes('/complete-application/1') ||
    navLocation.pathname.includes('/claim-account');

  useEffect(() => {
    if (isIndeedApplicant) {
      !completeApplicationSignUp && props.setOpen(true);
    } else {
      props.close && props.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIndeedApplicant, prop('email', me)]);

  const { isMobile } = useIsMobile();

  const interviewRequest = navLocation.pathname.includes('/interview-detail/');

  const Form = alreadyHasAccount ? FOHSignInForm : FOHRegisterForm;

  const { setValidEmail, error } = useSetValidEmail();
  const { contentShift } = useSidebarNav(props);

  return (
    <FOHReactiveModal
      leftContentShift={contentShift}
      open={props.open || (isIndeedApplicant && !completeApplicationSignUp)}
      dialog={!isMobile}
      key={prop('email', me)}
      close={() => {
        if (isIndeedApplicant && !completeApplicationSignUp) {
          alert('Please enter a valid email to complete your applicaiton.');
        }
        props.close && props.close();
      }}
    >
      <FOHView style={{ justifyContent: 'center', alignItems: 'center' }}>
        <FOHView
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: 450,
            paddingTop: 12
          }}
        >
          <FOHHeaderH2>
            {interviewRequest
              ? alreadyHasAccount
                ? t('loginInterviewRequestTitle')
                : t('interviewRequestTitle')
              : alreadyHasAccount
              ? t('loginSetPasswordTitle')
              : t('setPasswordTitle')}
          </FOHHeaderH2>
          {!alreadyHasAccount && (
            <FOHDetailText>
              {interviewRequest
                ? t('interviewRequestDetail')
                : t('setPasswordDetail')}
            </FOHDetailText>
          )}
        </FOHView>
        <FOHSpace />
        <Form
          needsName={true}
          firstName={firstName}
          firstNamePlaceholder="First Name"
          setFirstName={setFirstName}
          lastName={lastName}
          lastNamePlaceholder="Last Name"
          setLastName={setLastName}
          phoneNumber={phone}
          setPhoneNumber={setPhone}
          phoneNumberPlaceholder={t('RegisterFeature:phoneNumber')}
          // placeholders are the same
          emailPlaceholder={t('RegisterFeature:email')}
          passwordPlaceholder={t('RegisterFeature:password')}
          confirmPassword={confirmPassword}
          setConfirmPassword={setConfirmPassword}
          confirmPasswordPlaceholder={t('RegisterFeature:confirmPassword')}
          registerOptions={[]}
          registerType={CANDIDATE_GROUP}
          setRegisterType={() => {}}
          hideRegisterType={true}
          error={error}
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          submitLabel="Submit"
          disabledSubmit={
            !email ||
            !password ||
            (!terms && !alreadyHasAccount) ||
            (!firstName && !alreadyHasAccount) ||
            (!lastName && !alreadyHasAccount)
          }
          handleSignUp={
            onlySignIn
              ? () => {
                  props.close();
                }
              : () => {
                  setAlreadyHasAccount(false);
                }
          }
          noAccountLabel={alreadyHasAccount ? '' : t('noAccountLabel')}
          signUpLabel={onlySignIn ? t('common:cancel') : t('createNewProfile')}
          handleSubmit={async () => {
            // // this is not really a login or register, just a form to get indeed users to have real emails and phones
            // // these users are already logged in with an indeedemail
            const oldEmail = prop('email', me);
            await setValidEmail({
              id: prop('id', me),
              firstName,
              lastName,
              terms,
              phone,
              password,
              email,
              oldEmail
            });

            // close if state is being controlled from above
            props.onSubmit && props.onSubmit();
            props.close && props.close();
          }}
          terms={terms}
          termsText="I have reviewed and accept the"
          onPressTermsLink={() =>
            window.open(
              'https://fohboh-assets-prod.s3.us-east-1.amazonaws.com/terms_agreement.pdf?'
            )
          }
          termsLinkText={t('RegisterFeature:termsAgreement')}
          setTerms={() => setTerms(!terms)}
          hidePassText={t('common:hidePassText')}
          showPassText={t('common:showPassText')}
        />

        {!alreadyHasAccount && (
          <>
            <FOHButton
              title={t('common:next')}
              disabled={
                !firstName ||
                !lastName ||
                !phone ||
                !email ||
                !password ||
                !terms ||
                confirmPassword !== password
              }
              onPress={async () => {
                // // this is not really a login or register, just a form to get indeed users to have real emails and phones
                // // these users are already logged in with an indeedemail
                const oldEmail = prop('email', me);
                await setValidEmail({
                  id: prop('id', me),
                  firstName,
                  lastName,
                  terms,
                  phone,
                  password,
                  email,
                  oldEmail
                });

                // close if state is being controlled from above
                props.onSubmit && props.onSubmit();
                props.close && props.close();
              }}
            />
            <FOHSpace />
            <FOHInverseButton
              title={t('login')}
              style={{ width: '100%', maxWidth: 327 }}
              color={FOHColors.BLACK}
              textColor={FOHColors.WHITE}
              opaque={true}
              selected={true}
              onPress={() => {
                setAlreadyHasAccount(true);
              }}
            />
          </>
        )}
      </FOHView>
    </FOHReactiveModal>
  );
};
