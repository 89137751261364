import React from 'react';

import styled from 'styled-components/native';
import { View } from 'react-native';

import {
  FOHColors,
  FOHHeaderText,
  FOHNextIcon,
  FOHTouchableOpacity
} from '../../ingredients';

export interface FOHAccountMenuCellProps {
  label: string;
  onPress: () => void;
}

export const FOHAccountMenuCell = (props: FOHAccountMenuCellProps) => (
  <Border>
    <FOHTouchableOpacity
      style={{
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between'
      }}
      onPress={e => {
        e.preventDefault();
        props.onPress();
      }}
    >
      <CellText>{props.label}</CellText>
      <FOHNextIcon />
    </FOHTouchableOpacity>
  </Border>
);

const Border = styled(View)`
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  border: 1px solid ${FOHColors.BORDER_GRAY};
  padding-left: 20px;
  padding-right: 20px;
  margin-top: -1px;
  background-color: ${FOHColors.WHITE};
`;

const CellText = styled(FOHHeaderText)`
  font-size: 18px;
  text-align: left;
`;
