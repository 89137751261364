import React from 'react';

import { TouchableOpacity, View } from 'react-native';
import styled from 'styled-components/native';
import { useSpring, animated } from 'react-spring';
import { defaultTo, length, prop } from 'ramda';

import {
  FOHDropDownArrow,
  FOHLabel,
  FOHColors,
  FOHSpaceSmallest,
  FOHSpace,
  FOHSpaceSmall,
  FOHExpandIcon,
  FOHSettingsIcon
} from '../../ingredients';

const NavItemContainer = styled(View)`
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 6px;
  width: 100%;
  opacity; 0.16;
`;

const TouchableTitle = styled(TouchableOpacity)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const GroupContainer = styled(View)`
  // padding: 0 32px 0 32px;
`;

const ArrowContainer = styled(View)`
  right: 0;
`;
const AnimatedArrowView = animated(ArrowContainer);

const HorizontalSeparator = styled(View)`
  height: 1;
  color: #000;
  width: 100%;
`;

const GroupListItemText = styled(FOHLabel)`
  padding: 8px;
  font-size: 12px;
  line-height: 16px;
  border-radius: 6px;
`;

interface FOHSettingsRoute {
  label: string;
  route: string;
}

export interface FOHSettingsGroup {
  groupName: string;
  groupList: Array<FOHSettingsRoute>;
}

export interface FOHNavItemAccordionProps {
  title: string;
  subNavItems?: Array<FOHSettingsGroup>;
  children?: any;
  expanded: boolean;
  setExpand: (val: boolean) => void;
  currentRoute: string;
  onPressRoute: (val: string, title: string, groupName?: string) => void;
  testID?: string;
}

export const FOHNavItemAccordion = (props: FOHNavItemAccordionProps) => {
  const animatedProps = useSpring({
    from: { xyz: [0, 0, 0] },
    to: async (next: any) => {
      const isExpanded = props.expanded;
      await next({ xyz: isExpanded ? [0, 180, 0] : [0, 0, 0] });
    },
    config: { duration: 200 }
  }) as any;

  const [expandedGroups, setExpandedGroups] = React.useState<string[]>(['']);

  return (
    <NavItemContainer
      style={
        props.expanded && {
          backgroundColor: FOHColors.GRAYSCALE_100,
          paddingTop: 4
        }
      }
      testID={props.testID}
    >
      <TouchableTitle
        onPress={() => {
          props.expanded ? props.setExpand(false) : props.setExpand(true);
        }}
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <FOHSettingsIcon
            style={{
              tintColor: props.expanded
                ? FOHColors.PACIFIC_BLUE
                : FOHColors.GRAYSCALE_300
            }}
          />
          <FOHSpace />
          <FOHLabel bold={props.expanded}>
            {props.title && props.title}
          </FOHLabel>
        </View>
        <AnimatedArrowView
          style={{
            transform: animatedProps?.xyz?.to(
              (_x: number, y: number, _z: number) => `rotate(${y}deg)`
            )
          }}
        >
          <FOHExpandIcon />
        </AnimatedArrowView>
      </TouchableTitle>
      <HorizontalSeparator />
      {props.expanded ? (
        <GroupContainer>
          <FOHSpaceSmall />
          <>
            {props.subNavItems?.map(sub => {
              return (
                <React.Fragment key={sub.groupName}>
                  {length(sub.groupList) > 1 ? (
                    <TouchableOpacity
                      onPress={() => {
                        if (
                          expandedGroups.includes(
                            // defaultTo('', prop('groupName', sub))
                            defaultTo('', prop('groupName', sub)) as string
                          )
                        ) {
                          // const otherGroups = defaultTo([''], expandedGroups).filter(
                          //   group => group !== defaultTo('', prop('groupName', sub))
                          // );
                          // array in case we want to reenable multiple groups later on
                          setExpandedGroups(['']);
                        } else {
                          setExpandedGroups([sub.groupName]);
                        }
                      }}
                      testID={`nav-menu-${sub.groupName}`}
                    >
                      <FOHSpaceSmall />
                      <FOHSpaceSmallest />
                      <View
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'space-between'
                        }}
                      >
                        <FOHLabel
                          style={{
                            color: expandedGroups.includes(
                              defaultTo('', prop('groupName', sub)) as string
                            )
                              ? FOHColors.PRIMARY_TEAL_P1
                              : FOHColors.BLACK,
                            fontSize: 14,
                            lineHeight: 20
                          }}
                          heavy={
                            !expandedGroups.includes(
                              defaultTo('', prop('groupName', sub)) as string
                            )
                          }
                          bold={expandedGroups.includes(
                            defaultTo('', prop('groupName', sub)) as string
                          )}
                        >
                          {sub.groupName && sub.groupName}
                        </FOHLabel>
                        <AnimatedArrowView
                          style={{
                            transform: expandedGroups.includes(
                              defaultTo('', prop('groupName', sub)) as string
                            )
                              ? animatedProps?.xyz?.to(
                                  (_x: number, y: number, _z: number) =>
                                    `rotate(${y}deg)`
                                )
                              : undefined
                          }}
                        >
                          <FOHDropDownArrow />
                        </AnimatedArrowView>
                      </View>
                    </TouchableOpacity>
                  ) : (
                    <>
                      {sub.groupList ? (
                        sub.groupList.map((subOpt: any, index: number) =>
                          prop('label', subOpt) ? (
                            <TouchableOpacity
                              key={`${index}${subOpt.route}`}
                              onPress={() => {
                                const groupName =
                                  length(sub.groupList) > 1
                                    ? sub?.groupName
                                    : '';
                                if (
                                  expandedGroups.includes(
                                    defaultTo(
                                      '',
                                      prop('groupName', sub)
                                    ) as string
                                  )
                                ) {
                                  setExpandedGroups(['']);
                                } else {
                                  setExpandedGroups([sub.groupName]);
                                }
                                props.onPressRoute(
                                  subOpt.route,
                                  subOpt.label,
                                  groupName
                                );
                              }}
                              testID={`nav-item-${subOpt.label}`}
                            >
                              <FOHSpaceSmall />
                              <FOHSpaceSmallest />
                              <FOHLabel
                                style={{
                                  color: expandedGroups.includes(
                                    defaultTo(
                                      '',
                                      prop('groupName', sub)
                                    ) as string
                                  )
                                    ? FOHColors.PRIMARY_TEAL_P1
                                    : FOHColors.BLACK,
                                  fontSize: 14,
                                  lineHeight: 20
                                }}
                                heavy={
                                  !expandedGroups.includes(
                                    defaultTo(
                                      '',
                                      prop('groupName', sub)
                                    ) as string
                                  )
                                }
                                bold={expandedGroups.includes(
                                  defaultTo(
                                    '',
                                    prop('groupName', sub)
                                  ) as string
                                )}
                              >
                                {subOpt.label}
                              </FOHLabel>
                              <FOHSpaceSmallest />
                            </TouchableOpacity>
                          ) : (
                            <></>
                          )
                        )
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                  {length(sub.groupList) > 1 &&
                  expandedGroups.includes(
                    defaultTo('', prop('groupName', sub)) as string
                  ) ? (
                    <View>
                      {sub.groupList ? (
                        sub.groupList.map((subOpt: any, index: number) =>
                          prop('label', subOpt) ? (
                            <TouchableOpacity
                              key={`${index}${subOpt.route}`}
                              onPress={() =>
                                props.onPressRoute(
                                  subOpt.route,
                                  subOpt.label,
                                  length(sub.groupList) > 1
                                    ? sub?.groupName
                                    : ''
                                )
                              }
                              testID={`nav-item-${subOpt.label}`}
                              style={{ marginTop: 8 }}
                            >
                              <GroupListItemText
                                style={{
                                  color:
                                    props.currentRoute === subOpt.route
                                      ? FOHColors.PRIMARY_TEAL_300
                                      : FOHColors.GRAYSCALE_700,
                                  backgroundColor:
                                    props.currentRoute === subOpt.route
                                      ? FOHColors.PRIMARY_TEAL_10
                                      : undefined
                                }}
                              >
                                {subOpt.label}
                              </GroupListItemText>
                            </TouchableOpacity>
                          ) : (
                            <></>
                          )
                        )
                      ) : (
                        <></>
                      )}
                    </View>
                  ) : (
                    <></>
                  )}
                </React.Fragment>
              );
            })}
          </>
          <FOHSpace />
        </GroupContainer>
      ) : (
        <></>
      )}
    </NavItemContainer>
  );
};
