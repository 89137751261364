import React from 'react';

import logo from '../../../images/fblogo.svg';

import { FOHImage, FOHView } from '../../ingredients';

export const FOHFullLogo = (props: any) => (
  <FOHView style={{ width: '20' }}>
    <FOHImage
      source={{ uri: logo }}
      style={
        props && !!props.style
          ? { width: 96, height: 24, ...props?.style }
          : { width: 96, height: 24 }
      }
    />
  </FOHView>
);
