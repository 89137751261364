import { defaultTo } from 'ramda';

import { useGetMyLocationsQuery } from '../../graphql/generated';
import { unwrapEdgesAt } from '../../utils';

export const useMyLocationsData = props => {
  const { data, loading, error, refetch } = useGetMyLocationsQuery({
    skip: props?.skipLocationsQuery ?? false,
    variables: {
      search: props?.locationSearch ?? undefined
    }
  });

  const myLocations = defaultTo(
    [],
    unwrapEdgesAt(['me', 'myLocations', 'edges'], data)
  );

  return { data: myLocations, loading, error, refetch };
};
