import React, { useState } from 'react';

import { compose, prop, length, defaultTo, path } from 'ramda';
import { useTranslation } from 'react-i18next';

import { multiSelectItem, useIsMobile, toUSD, handle } from '../../utils';
import {
  BILLING,
  EMPLOYER_SETTINGS_MENU,
  PLAN,
  getAppBaseUrl
} from '../../constants';
import {
  FOHButton,
  FOHColors,
  FOHLabel,
  FOHLabelBold,
  FOHView,
  FOHSpace,
  FOHGoldQuarterDecoration,
  FOHDetailText,
  FOHHeaderH2,
  FOHSelectionTable,
  FOHErrorLabel,
  FOHSpaceSmall
} from '../../components';

import { withReactiveModal } from '../Navigation';
import { useCurrentLocationContext } from '../Locations/useCurrentLocationContext';

import { withStripe } from './withStripe';
import { useCheckout } from './useCheckout';
import { usePaymentPlan } from './usePaymentPlan';

const SelectPositions = props => {
  const { matchingPositions, successUrl } = props;
  const hasPositionMatches = !!matchingPositions;
  const { t } = useTranslation('PricingScreenFeature');
  const { isMobile } = useIsMobile();

  const [selected, setSelected] = useState([]);
  const [checkoutError, setCheckoutError] = useState(false);
  const monthly = true;

  const { locationFilter, currentLocation } = useCurrentLocationContext({
    props
  });
  const { unpaidPositions, singlePrice } = usePaymentPlan({
    locationFilter,
    positionsQ: true,
    singleQ: true
  });
  const { redirectToStripeCheckout } = useCheckout(props);

  const getCheckoutResponse = async checkoutData => {
    const [, error] = await handle(redirectToStripeCheckout(checkoutData));
    if (error) {
      setCheckoutError(true);
    }
  };

  const positionOptions = hasPositionMatches
    ? matchingPositions.map(pos => ({
        label: prop('positionName', pos),
        value: prop('rowId', pos)
      }))
    : unpaidPositions.map(pos => ({
        label: prop('positionName', pos),
        value: prop('rowId', pos)
      }));

  const price = monthly
    ? defaultTo(
        '',
        path(['monthlyPayAsYouGo', 'displayPerMonth'], singlePrice)
      ).replace('.00', '')
    : path(['annualPayAsYouGo', 'displayPerMonth'], singlePrice);

  const total = toUSD(
    parseFloat(defaultTo('', price).replace('$', '')) * length(selected)
  );

  return (
    <>
      <FOHView
        style={{
          alignItems: 'center',
          width: '100%',
          paddingLeft: 24,
          paddingBottom: 12,
          paddingTop: 12
        }}
      >
        <FOHHeaderH2 style={{ textAlign: 'center' }}>
          {hasPositionMatches
            ? t('positionMatches', {
                candidateName: prop('candidateName', props)
              })
            : t('selectPositionsFor', {
                location: prop('name', currentLocation)
              })}
        </FOHHeaderH2>
        <FOHDetailText>{t('selectPositionsDetail')}</FOHDetailText>
      </FOHView>
      <FOHSelectionTable
        items={positionOptions}
        selected={selected}
        selectItem={value => multiSelectItem(value, setSelected, selected)}
        selectAll={() => {
          if (length(positionOptions) === length(selected)) {
            setSelected([]);
          } else {
            setSelected(positionOptions.map(item => item.value));
          }
        }}
        allSelected={length(positionOptions) === length(selected)}
        selectAllLabel="Select All"
      />
      <FOHSpace />
      {checkoutError ? (
        <FOHView
          style={{
            justifyContent: 'center',
            alignItems: 'flex-start',
            wordBreak: 'break-word'
          }}
        >
          <FOHErrorLabel>{t('missingLocPosErrorMsg')}</FOHErrorLabel>
          <FOHSpaceSmall />
        </FOHView>
      ) : null}
      <FOHView style={{ flexDirection: isMobile ? 'column' : 'row' }}>
        <FOHView style={{ flex: 2, padding: 12, minWidth: 320 }}>
          <FOHLabel style={{ justifyContent: 'center', alignItems: 'center' }}>
            <FOHLabelBold>{price}</FOHLabelBold>
            <FOHLabel style={{ color: FOHColors.RED }}>*</FOHLabel>{' '}
            <>{monthly ? t('billedMonthly') : t('billedAnnually')}</>{' '}
            <FOHGoldQuarterDecoration
              style={{ width: 20, height: 20, top: 5 }}
            />
            <FOHLabelBold style={{ alignItems: 'center' }}>
              {' '}
              {t('payAsYouGo')}
            </FOHLabelBold>
          </FOHLabel>
          {length(selected) > 0 ? (
            <FOHLabel style={{ color: FOHColors.RED }}>
              {t('totalCalculation', { total, count: length(selected) })}
            </FOHLabel>
          ) : (
            <></>
          )}
        </FOHView>
        <FOHView style={{ flex: 1, padding: 12, minWidth: 250 }}>
          <FOHButton
            title={t('processPayment')}
            disabled={length(selected) === 0}
            onPress={() => {
              window &&
                getCheckoutResponse({
                  url:
                    successUrl ||
                    `${getAppBaseUrl()}${
                      props.backPath
                        ? props.backPath
                        : `${EMPLOYER_SETTINGS_MENU}${BILLING}${PLAN}`
                    }`,
                  positions: selected,
                  cycle: monthly ? 'monthly' : 'annual',
                  plan: 'payasyougo'
                });
            }}
            fullWidth={true}
          />
        </FOHView>
      </FOHView>
    </>
  );
};

export const SelectPositionsModal = compose(
  withReactiveModal,
  withStripe
)(SelectPositions);
