import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslations from './locales/en/translations.json';
import esTranslations from './locales/es_ES/translations.json';

export const i18nConfig = isServer => ({
  resources: {
    en: enTranslations,
    es: esTranslations
  },
  fallbackLng: 'en',
  ns: ['HomePage'],
  defaultNS: 'translations', // Don't have logical default since ns are page components

  debug: false,

  interpolation: {
    escapeValue: false // Not needed for react
  },

  react: {
    wait: isServer ? false : true,
    useSuspense: false
  }
});

i18n.use(initReactI18next).init(i18nConfig(false));

export default i18n;
