import { useState } from 'react';

import { path, prop, defaultTo, length } from 'ramda';

import { handleMutation } from '../../utils';
import {
  useMutateCreateEmploymentDocumentMutation,
  useMutateEmploymentDocumentFileMutation
} from '../../graphql/generated';

export const useLetter = () => {
  const [letter, setLetter] = useState({});
  const [customNote, setCustomNote] = useState('');
  const [files, setFiles] = useState([]);
  const [links, setLinks] = useState([]);
  const [includeCustomNote, setIncludeCustomNote] = useState(true);
  const [includeDocument, setIncludeDocument] = useState(true);
  const [includeLink, setIncludeLink] = useState(true);
  const [error, setError] = useState();

  const [mutateEmploymentDocument] =
    useMutateCreateEmploymentDocumentMutation();
  const [uploadDocumentFile] = useMutateEmploymentDocumentFileMutation();

  const createEmploymentDocument = async letterData => {
    const [createResponse, errorOnCreate] = await handleMutation(
      mutateEmploymentDocument({
        variables: letterData
      })
    );

    if (errorOnCreate || !createResponse) {
      return;
    }

    const employmentDocumentId = path(
      ['mutateEmploymentDocument', 'employmentDocumentType', 'id'],
      createResponse
    );

    let documentFiles = [];

    if (includeDocument) {
      documentFiles = files.map(file => ({
        id: file?.id,
        name: file?.name,
        fileName: file?.name,
        fileType: file?.type,
        url: ''
      }));
    }

    if (includeLink) {
      documentFiles = documentFiles.concat(
        links.map(link => ({
          name: '',
          fileName: '',
          fileType: '',
          url: link
        }))
      );
    }

    const [uploadResponse, errorOnUpload] = await handleMutation(
      uploadDocumentFile({
        variables: {
          employmentDocumentId: employmentDocumentId,
          documentFiles: documentFiles
        }
      })
    );

    if (errorOnUpload || !uploadResponse) {
      return [{ messages: 'Please enter a valid link.' }];
    }

    const urls = defaultTo(
      [],
      path(['uploadEmploymentDocument', 'urls'], uploadResponse)
    ).filter(url => url.fileName);
    await Promise.all(
      files.map(async file => {
        const signedS3Url = prop(
          'signedS3Url',
          urls.find(url => prop('fileName', url) === file.name)
        );
        await fetch(signedS3Url, {
          method: 'PUT',
          body: file
        });
      })
    );
  };

  const validateData = async (variables, letterStr) => {
    let validateDataError = [];

    const keyName = {
      documentType: '',
      status: '',
      position: 'Position',
      candidate: 'Candidate',
      startingAt: 'Candidate start date',
      contactName: 'Name',
      contactPhone: 'Phone number',
      messageContent: 'Message Content'
    };

    if (letterStr === 'letter-1') {
      for (let key in variables) {
        if (!variables[key]) {
          validateDataError.push({
            messages: `${keyName[key]} cannot be blank.`
          });
        }
        if (
          key === 'contactPhone' &&
          variables[key] &&
          length(variables[key]) < 14
        ) {
          validateDataError.push({
            messages: 'Please enter a complete phone number.'
          });
        }
      }
    } else {
      if (
        !(includeCustomNote && customNote) &&
        !(includeDocument && length(files) > 0) &&
        !(includeLink && length(links) > 0)
      ) {
        validateDataError.push({
          messages: 'Please enter at least one field.'
        });
      }
    }
    return validateDataError;
  };

  return {
    customNote,
    setCustomNote,
    letter,
    setLetter,
    files,
    setFiles,
    links,
    setLinks,
    createEmploymentDocument,
    includeCustomNote,
    setIncludeCustomNote,
    includeDocument,
    setIncludeDocument,
    includeLink,
    setIncludeLink,
    validateData,
    error,
    setError
  };
};
