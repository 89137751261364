import React from 'react';

import certifications_icon from '../../../images/certifications_icon.svg';

import { FOHImage } from '../../ingredients';

export const FOHProfileCertificationsIcon = (props: any) => (
  <FOHImage
    source={{ uri: certifications_icon }}
    style={{ width: 20, height: 20, ...props.style }}
  />
);
