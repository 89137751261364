import React, { useMemo, useState } from 'react';

import {
  WithTranslation,
  useTranslation,
  withTranslation
} from 'react-i18next';
import { compose, defaultTo, path } from 'ramda';

import {
  FOHBannerType,
  FOHButton,
  FOHColors,
  FOHDetailText,
  FOHDivider,
  FOHFonts,
  FOHHighlightLinkWithText,
  FOHImage,
  FOHSpace,
  FOHSpaceLarge,
  FOHView,
  FullWidthCard
} from '../../components';
import LinkedinIcon from '../../images/Linkedin_logo.svg';
import { handleMutation, useIsMobile } from '../../utils';
import {
  GetCompaniesDocument,
  useCreateUpdateIntegrationIdentifierMutation,
  useDeleteIntegrationIdentifierMutation
} from '../../graphql/generated';

import { useGetCompanies } from './useGetCompanies';
import {
  FOHAddOrEditIntegration,
  FOHIntegratedCompanyInfoView
} from './components';
import { ConnectedButton } from './IntegrationIndeed';

export enum IntegrationIdentifiersChoices {
  LINKEDIN = 'linkedin',
  X_AS_TWITTER = 'x_twitter'
}

interface FOHIntegrationCardProps {
  integration: IntegrationIdentifiersChoices;
  integrationDescription: string;
  learnMore: {
    label: string;
    link: string;
  } | null;
  iconSource: string;
  imageStyle: object;
  integrationIdentifier: {
    identifier: string;
    integration: string;
  } | null;
  handleOnDisconnect: (integration: IntegrationIdentifiersChoices) => void;
  handleOnConnectOrSave: (
    integration: IntegrationIdentifiersChoices,
    identifier: string
  ) => void;
}

const FOHIntegrationCard: React.FC<FOHIntegrationCardProps> = ({
  integration,
  integrationDescription,
  learnMore,
  iconSource,
  imageStyle,
  integrationIdentifier,
  handleOnDisconnect,
  handleOnConnectOrSave
}) => {
  const { t } = useTranslation('Integrations');

  const { isMobile, width } = useIsMobile();
  const mobile = isMobile && width <= 627;

  const [isDisplayAddNewForm, setIsDisplayAddForm] = useState(false);
  const [isDisplayEditForm, setIsDisplayEditForm] = useState(false);

  return (
    <FOHView style={{ width: '100%' }}>
      <FOHView style={{ paddingTop: isMobile ? 8 : 16, borderRadius: 16 }}>
        <FullWidthCard
          shadow
          noBorder
          style={{
            paddingHorizontal: isMobile ? 14 : 28,
            paddingTop: 28,
            paddingBottom:
              !integrationIdentifier &&
              !isDisplayAddNewForm &&
              !isDisplayEditForm
                ? 28
                : 'unset'
          }}
        >
          <FOHView
            style={{
              flexDirection: mobile ? 'column' : 'row',
              alignItems: mobile ? 'flex-start' : 'center',
              justifyContent: 'space-between',
              flex: 1,
              flexWrap: 'wrap'
            }}
          >
            <FOHView
              style={{
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: 'flex-start',
                alignItems: isMobile ? 'flex-start' : 'center',
                flex: isMobile ? 'unset' : 3,
                maxWidth: isMobile ? '100%' : 'unset'
              }}
            >
              <FOHImage source={{ uri: iconSource }} style={imageStyle} />
              {isMobile ? <FOHSpace /> : <FOHSpaceLarge />}
              <FOHView
                style={{
                  wordWrap: 'break-word',
                  flex: 1
                }}
              >
                {learnMore !== null ? (
                  <FOHHighlightLinkWithText
                    labelStyle={{
                      fontSize: 14,
                      lineHeight: 20,
                      textAlign: isMobile ? 'justify' : 'left',
                      display: 'block',
                      opacity: 1
                    }}
                    labelCmp={FOHDetailText}
                    linkStyle={{
                      fontSize: 13,
                      fontWeight: 600,
                      fontFamily: FOHFonts.REGULAR
                    }}
                    linkWord={learnMore.label}
                    highlightColor={FOHColors.PACIFIC_BLUE}
                    linkHref={learnMore.link}
                    iconStyle={{
                      width: 13,
                      height: 13
                    }}
                  >
                    {integrationDescription}
                  </FOHHighlightLinkWithText>
                ) : (
                  <FOHDetailText
                    style={{
                      fontSize: 14,
                      lineHeight: 20,
                      textAlign: isMobile ? 'justify' : 'left'
                    }}
                  >
                    {integrationDescription}
                  </FOHDetailText>
                )}
              </FOHView>
            </FOHView>
            {mobile && <FOHSpace />}
            <FOHView
              style={{
                flex: isMobile ? 'unset' : 1.5,
                alignItems: 'flex-end',
                width: isMobile ? '100%' : 'unset',
                marginTop: isMobile ? 15 : 'unset'
              }}
            >
              <FOHView style={{ width: 175, maxWidth: 175 }}>
                {integrationIdentifier ? (
                  <ConnectedButton connectedLabel={t('connected')} />
                ) : !isDisplayAddNewForm && !isDisplayEditForm ? (
                  <FOHButton
                    title={t('addIntegration')}
                    onPress={() => setIsDisplayAddForm(true)}
                    style={{ height: 40, maxWidth: 175 }}
                    textStyle={{ color: FOHColors.WHITE, fontWeight: '700' }}
                  />
                ) : null}
              </FOHView>
            </FOHView>
          </FOHView>
          {isDisplayAddNewForm ? (
            <>
              {isMobile ? <FOHSpace /> : <FOHSpaceLarge />}
              <FOHDivider color={FOHColors.BORDER_GRAY} />
              <FOHView style={{ width: '100%' }}>
                <FOHAddOrEditIntegration
                  integrationName={integration}
                  connectOrSaveBtnLabel={t('connect')}
                  onConnectOrSave={(integrationName, identifierID) => {
                    handleOnConnectOrSave(integrationName, identifierID);
                    setIsDisplayAddForm(false);
                  }}
                  onCancel={() => setIsDisplayAddForm(false)}
                />
              </FOHView>
            </>
          ) : integrationIdentifier ? (
            <>
              {isMobile ? <FOHSpace /> : <FOHSpaceLarge />}
              <FOHDivider color={FOHColors.BORDER_GRAY} />
              <FOHView style={{ width: '100%' }}>
                {!isDisplayEditForm ? (
                  <FOHIntegratedCompanyInfoView
                    integrationIdentifierID={integrationIdentifier.identifier}
                    integrationName={
                      integrationIdentifier.integration as IntegrationIdentifiersChoices
                    }
                    editLabel={t('MyTeamFeature:edit')}
                    onEditPress={() => setIsDisplayEditForm(true)}
                    disconnectLabel={t('disconnect')}
                    onDisconnectPress={handleOnDisconnect}
                  />
                ) : (
                  <FOHAddOrEditIntegration
                    integrationIdentifierID={integrationIdentifier.identifier}
                    integrationName={
                      integrationIdentifier.integration as IntegrationIdentifiersChoices
                    }
                    connectOrSaveBtnLabel={t('common:save')}
                    onConnectOrSave={(integrationName, identifierID) => {
                      handleOnConnectOrSave(integrationName, identifierID);
                      setIsDisplayEditForm(false);
                    }}
                    onCancel={() => setIsDisplayEditForm(false)}
                  />
                )}
              </FOHView>
            </>
          ) : null}
        </FullWidthCard>
      </FOHView>
    </FOHView>
  );
};

interface IntegrationIdentifiersProps extends WithTranslation {
  setBanner: React.Dispatch<
    React.SetStateAction<{
      bannerType: keyof typeof FOHBannerType;
      bannerMessage: string;
    }>
  >;
}

const IntegrationIdentifiers: React.FC<IntegrationIdentifiersProps> = ({
  t,
  setBanner
}) => {
  const { data } = useGetCompanies();

  const integrationIdentifiers = defaultTo(
    [],
    path(['integrationIdentifiers'], data) as any
  );

  const [deleteIntegrationIdentifierMutation] =
    useDeleteIntegrationIdentifierMutation();
  const [createUpdateIntegrationIdentifierMutation] =
    useCreateUpdateIntegrationIdentifierMutation();

  const onPressConnectOrSave = async (
    integration: IntegrationIdentifiersChoices,
    identifier: string
  ) => {
    try {
      const [response, error] = await handleMutation(
        createUpdateIntegrationIdentifierMutation({
          variables: {
            integration,
            identifier
          },
          refetchQueries: [
            {
              query: GetCompaniesDocument
            }
          ]
        })
      );
      if (error || !response) {
        throw new Error(
          error?.message || 'Failed to connect or save integration.'
        );
      }
    } catch (error) {
      setBanner({
        bannerType:
          FOHBannerType.DANGER as unknown as keyof typeof FOHBannerType,
        bannerMessage: error as any
      });
    }
  };

  const onPressDisconnect = async (
    integration: IntegrationIdentifiersChoices
  ) => {
    try {
      const [response, error] = await handleMutation(
        deleteIntegrationIdentifierMutation({
          variables: {
            integration
          },
          refetchQueries: [
            {
              query: GetCompaniesDocument
            }
          ]
        })
      );
      if (error || !response) {
        throw new Error(error?.message || 'Failed to disconnect integration.');
      }
    } catch (error) {
      setBanner({
        bannerType:
          FOHBannerType.DANGER as unknown as keyof typeof FOHBannerType,
        bannerMessage: error as any
      });
    }
  };

  const IDENTIFIERS = useMemo(() => {
    return [
      {
        integrationName: IntegrationIdentifiersChoices.LINKEDIN,
        integrationDescription: t('linkedinDescription'),
        learnMore: {
          label: t('linkedinLearnHow'),
          link: 'https://www.linkedin.com/help/linkedin/answer/a415420/associate-your-linkedin-company-id-with-the-linkedin-job-board-faqs'
        },
        icon: LinkedinIcon,
        iconStyle: {
          width: 130,
          height: 40
        },
        integrationDetails: null
      }
    ].map(identifier => {
      if (!integrationIdentifiers) return identifier;

      const matchingIntegration = integrationIdentifiers.find(
        (integration: any) =>
          integration.integration === identifier.integrationName
      );

      return {
        ...identifier,
        integrationDetails: matchingIntegration ?? null
      };
    });
  }, [integrationIdentifiers]);

  return (
    <FOHView>
      {IDENTIFIERS.map((identifier, keyIndex) => (
        <FOHIntegrationCard
          key={`${identifier.integrationName}-${keyIndex}`}
          iconSource={identifier.icon}
          imageStyle={identifier.iconStyle}
          integration={identifier.integrationName}
          learnMore={identifier.learnMore}
          integrationDescription={identifier.integrationDescription}
          integrationIdentifier={identifier.integrationDetails}
          handleOnDisconnect={onPressDisconnect}
          handleOnConnectOrSave={onPressConnectOrSave}
        />
      ))}
    </FOHView>
  );
};

export default compose(withTranslation('Integrations'))(IntegrationIdentifiers);
