import React, { useState } from 'react';

import { path } from 'ramda';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  GetEmployerRejectionReasonsDocument,
  useEmployerRejectionReasonMutation
} from '../../graphql/generated';
import { handleMutation } from '../../utils';
import { FEED } from '../../constants';
import { FOHAddRejectReasonModal } from '../../components';
import { useNavigation } from '../../utils/navigation';

import { useGetMeData } from '../SignIn';

export const AddRejectReasonModal = () => {
  const [rejectReasonText, setRejectReasonText] = useState('');

  const { navigateTo } = useNavigation();
  const params = useParams();
  const { modal } = params;

  const { t } = useTranslation('RejectReasonFeature');

  const { data: me } = useGetMeData();
  const employerProfileId = path(['employerProfile', 'id'], me);

  const [mutateEmployerRejectionReason] = useEmployerRejectionReasonMutation();

  return (
    <FOHAddRejectReasonModal
      open={modal === 'add-reject-reason'}
      close={() => {
        navigateTo(FEED);
      }}
      headerLabel={t('headerTitle')}
      detailLabel={t('detailLabel')}
      saveButtonLabel={t('submitLabel')}
      onSavePress={async () => {
        if (rejectReasonText && employerProfileId) {
          await handleMutation(
            mutateEmployerRejectionReason({
              variables: {
                employerProfile: employerProfileId,
                name: rejectReasonText
              },
              refetchQueries: [
                { query: GetEmployerRejectionReasonsDocument, variables: {} }
              ]
            })
          );
          navigateTo(FEED);
        }
      }}
      onTextChange={text => setRejectReasonText(text)}
      textValue={rejectReasonText}
      textPlaceholder={t('newPlaceholder')}
    />
  );
};
