import React from 'react';

import {
  FOHSpaceSmall,
  FOHLabelBold,
  FOHView,
  FOHSmallDetailLabel
} from '../../ingredients';

import { FOHProfileImage } from '../navigation/FOHProfileImage';

export interface FOHHiringManagerProps {
  hiringManagerName?: string;
  hiringManagerImage?: string;
  hiringManagerPosition?: string;
}
export interface FOHHiringManagerOverviewProps extends FOHHiringManagerProps {
  flexStart?: boolean;
}

export const FOHHiringManagerOverview = (
  props: FOHHiringManagerOverviewProps
) => (
  <FOHView
    style={{
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: props.flexStart ? 'flex-start' : 'flex-end',
      paddingBottom: 0,
      flexWrap: 'wrap'
    }}
  >
    <FOHView
      style={{
        alignItems: 'center',
        flexDirection: 'row'
      }}
    >
      {props.flexStart && (
        <>
          <FOHView style={{ alignItems: 'center', marginBottom: 10 }}>
            <FOHProfileImage
              highlight={true}
              photo={props.hiringManagerImage}
              key={props.hiringManagerImage}
              style={{
                width: 34,
                height: 34
              }}
            />
          </FOHView>
          <FOHSpaceSmall />
        </>
      )}
      <FOHView>
        <FOHSmallDetailLabel
          style={{
            marginBottom: 4,
            textAlign: props.flexStart ? 'left' : 'right'
          }}
        >
          {props.hiringManagerPosition}
        </FOHSmallDetailLabel>
        <FOHLabelBold
          style={{
            marginBottom: 4,
            textAlign: props.flexStart ? 'left' : 'right'
          }}
        >
          {props.hiringManagerName}
        </FOHLabelBold>
      </FOHView>
      {!props.flexStart && (
        <>
          <FOHSpaceSmall />
          <FOHView style={{ alignItems: 'center', marginBottom: 10 }}>
            <FOHProfileImage
              highlight={true}
              photo={props.hiringManagerImage}
              key={props.hiringManagerImage}
              style={{
                width: 34,
                height: 34
              }}
            />
          </FOHView>
        </>
      )}
    </FOHView>
  </FOHView>
);
