import React, { useState } from 'react';

import { path, prop } from 'ramda';
import { useTranslation } from 'react-i18next';

import {
  FOHLabel,
  FOHTouchableOpacity,
  FOHView,
  FOHSpaceSmall,
  FOHRightArrow,
  FOHCompoundDropdownButton
} from '../../components';
import {
  AmplitudeTrackingEvents,
  handleMutation,
  useTrackAmplitudeEvent
} from '../../utils';
import { useMutateCreateEmployerCandidatesMutation } from '../../graphql/generated';

export const InviteToApplyButton = props => {
  const {
    handle,
    selectedPosition,
    selectedLocation,
    stages,
    moveEmployerCandidates,
    passiveStage,
    onStagePress,
    title
  } = props;
  const { t } = useTranslation('CandidateNetworkSearch');

  const [openMove, setOpenMove] = useState();

  const { logEventMetrics } = useTrackAmplitudeEvent();

  const [createEmployerCandidatesMutation] =
    useMutateCreateEmployerCandidatesMutation();

  const handleMoveCandidates = async stage => {
    const moveCandidateEventDetail = {
      event_type: AmplitudeTrackingEvents.MOVE_CANDIDATE_CLICKED.EVENT_TYPE,
      page: 'database',
      method: 'individual'
    };

    const [res, error] = await handleMutation(
      createEmployerCandidatesMutation({
        variables: {
          candidateHandles: [handle],
          positionId: selectedPosition
        }
      })
    );

    if (!error) {
      const employerCandidates = path(
        ['createEmployerCandidates', 'employerCandidates'],
        res
      );

      const employerCandidate = employerCandidates.find(
        candidate => candidate.candidateProfile.handle === handle
      );

      if (employerCandidate.id) {
        await moveEmployerCandidates({
          employerCandidateIds: [prop('id', employerCandidate)],
          all: false,
          newStage: stage.id,
          oldStage: employerCandidate.stage.id
            ? employerCandidate.stage.id
            : passiveStage.id,
          positions: [selectedPosition],
          locations: [selectedLocation]
        });

        await logEventMetrics(
          AmplitudeTrackingEvents.MOVE_CANDIDATE_CLICKED.EVENT_NAME,
          moveCandidateEventDetail
        );
      }
    }

    setOpenMove(false);
  };

  return (
    <FOHCompoundDropdownButton
      title={title || t('inviteToApplyHeader')}
      menuTop={54}
      onPress={async () => {
        if (props.onPress) {
          props.onPress();
        }
      }}
      open={openMove}
      toggleOpen={() => {
        setOpenMove(!openMove);
      }}
      testID="foh-invite-to-apply-dropdown-button-main-action"
      style={{ height: 52, ...props.style }}
      cardStyle={{ paddingHorizontal: 24 }}
    >
      {stages.map((stage, ind) => (
        <FOHView
          key={stage.id}
          style={{
            paddingTop: ind !== 0 && 24,
            paddingLeft: 0,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center'
          }}
        >
          <FOHTouchableOpacity
            style={{
              flexDirection: 'row',
              alignItems: 'center'
            }}
            onPress={async () => {
              if (onStagePress) {
                onStagePress(stage);
              } else {
                handleMoveCandidates(stage);
              }
            }}
            testID={`InviteToApplyButton-${stage.name}`}
          >
            <FOHRightArrow />
            <FOHSpaceSmall />
            <FOHLabel>{prop('name', stage)}</FOHLabel>
          </FOHTouchableOpacity>
        </FOHView>
      ))}
    </FOHCompoundDropdownButton>
  );
};
