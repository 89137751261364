import React from 'react';

import notificationAlertOffers from '../../../images/notification-alert-offers.svg';

import { FOHImage } from '../../ingredients';

export interface FOHNotificationAlertOffersProps {}

export const FOHNotificationAlertOffers = (
  props: FOHNotificationAlertOffersProps
) => (
  <FOHImage
    {...props}
    source={{ uri: notificationAlertOffers }}
    style={{ width: 24, height: 24 }}
    testID={'offer-notification-badge'}
  />
);
