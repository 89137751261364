import React, { useEffect, useState } from 'react';

import styled from 'styled-components/native';
import { defaultTo, prop, length } from 'ramda';

import { FOHView } from '../containers/Containers';
import { FOHSmallDetailLabel } from '../text/FOHSmallDetailLabel';

import { FOHBareTextField } from './FOHTextField';

const MultiLineTextField = (props: any) => {
  const max = prop('max', props);
  const value = prop('value', props);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (max) {
      setCount(defaultTo(0, length(value)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <FOHView style={{ flex: 1 }}>
      <FOHBareTextField
        {...props}
        onChangeText={
          !max
            ? props.onChangeText
            : (text: string) => {
                // check incoming text not the current count
                if (max >= text.length) {
                  props.onChangeText(text);
                }
              }
        }
        multiline
      />
      {max ? (
        <FOHView
          style={{
            position: 'absolute',
            right: 10,
            bottom: 10,
            padding: 4,
            borderRadius: 4,
            backgroundColor: '#ffffff'
          }}
        >
          <FOHSmallDetailLabel>{`${count}/${max}`}</FOHSmallDetailLabel>
        </FOHView>
      ) : (
        <></>
      )}
    </FOHView>
  );
};

export const FOHMultiLineTextField = styled(MultiLineTextField)`
  border: 1px solid #dbdbdb;
  height: 140;
  width: 100%;
  line-height: 150%;
  padding-top: 6px;
`;
