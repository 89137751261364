import React from 'react';

import { withTranslation } from 'react-i18next';
import { compose } from 'ramda';

import { useIsMobile } from '../../utils';
import { FOHGuideBanner } from '../../components';
import { useNavigation } from '../../utils/navigation';

import { withStripe } from './withStripe';

const BillingBanner = props => {
  const { openBilling, setOpenBilling, t, pathname } = props;
  const { isMobile } = useIsMobile();
  const { navigateTo, location } = useNavigation();
  return (
    <>
      <FOHGuideBanner
        open={openBilling}
        mobile={isMobile}
        close={() => setOpenBilling(!openBilling)}
        iconText={t('iconText')}
        title={t('billingTitle')}
        buttonLabel={t('billingButtonLabel')}
        onButtonPress={() => navigateTo({ pathname, search: location.search })}
      />
    </>
  );
};

export default compose(
  withStripe,
  withTranslation('BillingFeature')
)(BillingBanner);
