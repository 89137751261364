import React from 'react';

import { FOHBannerText, FOHColors, FOHErrorIcon } from '../../ingredients';

import { FOHBannerProps, BannerView } from './FOHBanner';

export const FOHDangerBanner = (props: FOHBannerProps) => (
  <BannerView
    style={{
      backgroundColor: FOHColors.RED,
      borderRadius: props.rounded ? 6 : 0
    }}
    testID="FOHDangerBanner"
  >
    <FOHErrorIcon />
    <FOHBannerText>{props.message}</FOHBannerText>
  </BannerView>
);
