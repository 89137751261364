import React, { useMemo } from 'react';

import { View, TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';
import { defaultTo, length, path } from 'ramda';

import {
  FOHLabel,
  FOHSectionLabel,
  FOHPlayIcon,
  FOHStatus,
  FOHSpaceSmall,
  FOHSpace,
  FOHInverseButton,
  FOHSpaceSmallest,
  FOHHide,
  FOHFollowBadge,
  FOHNotificationRejection,
  FOHView,
  FOHIconButton,
  FOHPrintIcon,
  FOHColors,
  FOHFonts,
  FOHSpaceLarge,
  FOHSmallDetailLabel,
  FOHApplicantIcon,
  FOHRejectionTag
} from '../../ingredients';

import { FOHInterviewRequestFormProps, FOHProfileImage } from '../';

import { FOHCandidateProfileBlockProps } from './FOHCandidateProfileBlock';

export interface FOHProfileInterviewBlockProps
  extends FOHCandidateProfileBlockProps,
    FOHInterviewRequestFormProps {
  interviewRequestLabel: string;
  disabledSubmit?: boolean;
  onHidePress?: () => any;
  hideLabel?: string;
  hidden?: boolean;
  ProfileStateBannerComponent?: any;
  isFollowing?: boolean;
  followingBadgeLabel?: string;
  followingHelpText?: string;
  rejected?: boolean;
  children?: any;
  testID?: string;
  handlePrint?: () => any;
  preferredMatch: object | undefined;
  rejectionReasons?: Array<string>;
}

export const FOHProfileInterviewBlock = (
  props: FOHProfileInterviewBlockProps
) => {
  const appliedPosition = useMemo(
    () =>
      defaultTo('', path(['position', 'positionName'], props.preferredMatch)),
    [props.preferredMatch]
  );

  const appliedLocation = useMemo(() => {
    let location = '';

    if (
      path(['position', 'location', 'name'], props?.preferredMatch) &&
      path(['position', 'location', 'formattedAddress'], props?.preferredMatch)
    ) {
      location = `${path(
        ['position', 'location', 'name'],
        props?.preferredMatch
      )} 
        - ${path(
          ['position', 'location', 'formattedAddress'],
          props?.preferredMatch
        )}`;
    }

    return location;
  }, [props.preferredMatch]);

  return (
    <>
      {props.ProfileStateBannerComponent ? (
        <View style={{ padding: 16, paddingLeft: 32, paddingRight: 32 }}>
          {props.ProfileStateBannerComponent}
        </View>
      ) : (
        <></>
      )}
      <View
        style={{
          paddingBottom: 24,
          display: 'flex',
          justifyContent: 'flex-start',
          width: '100%',
          backgroundColor: '#FFFFFF',
          borderBottomWidth: 1,
          borderColor: '#E5E5E5',
          paddingLeft: 24,
          paddingRight: 24
        }}
        testID={props.testID}
      >
        <FOHView
          style={{
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'flex-start'
          }}
        >
          <FOHView
            style={{
              flex: props.children ? 5 : 'unset',
              width: props.children ? 'unset' : '100%',
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'space-between'
            }}
          >
            <FOHView
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start'
              }}
            >
              <FOHView style={{ paddingTop: 6, alignItems: 'center' }}>
                {props.openVideo ? (
                  <TouchableOpacity
                    onPress={() => !!props.openVideo && props.openVideo()}
                    disabled={!props.video}
                  >
                    <FOHProfileImage
                      photo={props.storyPhoto}
                      style={{
                        height: 100,
                        width: 100,
                        opacity:
                          props.hidden || props.rejected ? 0.3 : undefined
                      }}
                    />
                    {!!props.video && !props.rejected ? (
                      <PlayCircle {...props}>
                        <FOHPlayIcon />
                      </PlayCircle>
                    ) : null}
                    {props.rejected ? (
                      <PlayCircle {...props}>
                        <FOHNotificationRejection />
                      </PlayCircle>
                    ) : null}
                  </TouchableOpacity>
                ) : null}
              </FOHView>
              <FOHView
                style={{
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  paddingLeft: 12,
                  flex: 1
                }}
              >
                <FOHView style={{ paddingHorizontal: 12, width: '100%' }}>
                  <FOHView
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-start'
                    }}
                  >
                    <FOHSectionLabel
                      numberOfLines={1}
                      style={{
                        fontSize: 32,
                        lineHeight: 48,
                        textAlign: 'left',
                        fontFamily: FOHFonts.MEDIUM,
                        fontWeight: '600'
                      }}
                    >
                      {props.name}
                      {props.isFollowing && !props.hidden ? (
                        <>
                          <FOHSpaceSmall />
                          <FOHFollowBadge
                            small={true}
                            label={props.followingBadgeLabel!}
                            helpText={props.followingHelpText}
                          />
                        </>
                      ) : null}
                    </FOHSectionLabel>
                    <FOHSpaceLarge />
                    {props.status && props.statusType ? (
                      <>
                        <FOHStatus
                          statusText={props.status}
                          statusType={props.statusType}
                          hasBackground
                        />
                        <FOHSpace />
                      </>
                    ) : null}
                  </FOHView>
                  <FOHSpaceSmallest />
                  <FOHView
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-start'
                    }}
                  >
                    {!props.rejected && appliedPosition && appliedLocation ? (
                      <>
                        <FOHApplicantIcon
                          style={{
                            marginRight: 8,
                            marginLeft: 2,
                            width: 18,
                            height: 18
                          }}
                          active={true}
                        />
                        <FOHLabel
                          style={{
                            fontSize: 16,
                            lineHeight: 20,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            color: FOHColors.GRAYSCALE_700,
                            fontFamily: FOHFonts.MEDIUM,
                            maxWidth: '100%'
                          }}
                        >
                          {appliedPosition}
                          {'  '}
                          {appliedLocation ? (
                            <FOHSmallDetailLabel
                              style={{ color: FOHColors.GRAYSCALE_500 }}
                            >
                              {appliedLocation}
                            </FOHSmallDetailLabel>
                          ) : null}
                        </FOHLabel>
                      </>
                    ) : null}
                    {props.rejected &&
                    props?.rejectionReasons &&
                    length(props?.rejectionReasons) > 0 ? (
                      <>
                        {props.rejectionReasons.map(reason => (
                          <FOHRejectionTag
                            label={reason}
                            key={reason}
                            style={{ paddingRight: 8 }}
                          />
                        ))}
                      </>
                    ) : null}
                  </FOHView>
                </FOHView>
              </FOHView>
            </FOHView>
            <FOHView style={{ alignSelf: 'center' }}>
              {props.onHidePress ? (
                <View
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                    zIndex: 200
                  }}
                >
                  {props.onHidePress ? (
                    <>
                      <FOHInverseButton
                        onPress={props.onHidePress}
                        title={props.hideLabel || ''}
                        style={{
                          minWidth: 150,
                          height: 40,
                          padding: 0,
                          margin: 0,
                          maxHeight: 40,
                          zIndex: 10
                        }}
                        icon={() => FOHHide({ active: props.hidden })}
                        color={'#E5253C'}
                        centerIcon={true}
                        textColor={'#000000'}
                        canSelect={true}
                        selected={props.hidden}
                      />
                      <FOHSpaceSmallest />
                    </>
                  ) : (
                    <></>
                  )}
                </View>
              ) : null}
              {props.handlePrint ? (
                <FOHView
                  style={{
                    height: 40,
                    padding: 10,
                    marginLeft: props.onHidePress ? 10 : 0,
                    borderWidth: 1,
                    borderColor: FOHColors.GRAYSCALE_300,
                    borderRadius: 6,
                    backgroundColor: FOHColors.WHITE
                  }}
                >
                  <FOHIconButton
                    disabled={false}
                    onPress={props.handlePrint}
                    icon={() => (
                      <FOHPrintIcon
                        style={{ tintColor: FOHColors.GRAYSCALE_700 }}
                      />
                    )}
                  />
                </FOHView>
              ) : null}
            </FOHView>
          </FOHView>
          {props.children ? (
            <FOHView
              style={{
                alignItems: 'flex-end',
                flex: 3
              }}
            >
              <FOHView style={{ flex: 1 }}>{props.children}</FOHView>
            </FOHView>
          ) : null}
        </FOHView>
        {!props.rejected ? <FOHSpaceLarge /> : null}
        <FOHView
          style={{
            paddingLeft: 12,
            paddingRight: 45,
            flexDirection: 'row',
            alignItems: 'flexstart'
          }}
        >
          <FOHSpace />
          <FOHView style={{ flex: 1 }}>
            <FOHLabel
              style={{
                textAlign: 'left',
                justifyContent: 'flex-start',
                fontSize: 18,
                lineHeight: 28,
                fontWeight: '400',
                color: FOHColors.GRAYSCALE_700
              }}
            >
              {props.personalSummary}
            </FOHLabel>
          </FOHView>
        </FOHView>
      </View>
    </>
  );
};

const PlayCircle = styled(View)`
  border-radius: 50%;
  border-width: 2px;
  border-color: ${(props: any) =>
    props.hidden || props.rejected ? '#eeeeee' : '#51c1db'};
  justify-content: center;
  align-items: center;
  top: -34;
  left: 76;
  width: 30px;
  background-color: #ffffff;
  height: 30px;
`;
