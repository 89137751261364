import React from 'react';

import { isEmpty } from 'ramda';

import { UserProvider, userContext } from '../../context/UserContext';
import { getUser, storeUser, getAuth } from '../../utils';
import { useGetMyCandidateProfileFiltersQuery } from '../../graphql/generated';

import { getCurrentUser } from '../users';

const { useReducer, useEffect, useContext } = React;

export const userReducer = (state, action) => {
  switch (action.type) {
    case 'updateUser':
      return { user: { ...state.user, ...action.user } };
    default:
      return { user: {} };
  }
};

export const userActions = userDispatch => ({
  updateUser: user => {
    return userDispatch({ type: 'updateUser', user });
  }
});

//TODO: convert this HOC to use graphql so we won't have to refetch
export const withUpdateUser = Cmp => props => {
  const [userState, userDispatch] = useReducer(userReducer, {});
  const meQuery = useGetMyCandidateProfileFiltersQuery({ skip: true });
  const actions = userActions(userDispatch, meQuery);

  const retrieveUser = async ({ refresh }) => {
    const user = getUser();

    if (user && !isEmpty(user) && user !== 'undefined' && !refresh) {
      actions.updateUser(user);
      return user;
    }
    try {
      const auth = getAuth();
      if (!auth?.token) {
        return null;
      }

      const currentUser = await getCurrentUser();
      actions.updateUser(currentUser);
      return currentUser;
    } catch (error) {
      console.error(error);
    }
  };

  const cacheUser = user => {
    if (user && !isEmpty(user) && user !== 'undefined') {
      storeUser(user);
    }
  };

  useEffect(() => {
    retrieveUser({ refresh: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { user } = userState;

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    meQuery && meQuery.refetch();
    cacheUser(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.user]);

  return (
    <UserProvider value={user}>
      <Cmp
        {...props}
        {...actions}
        retrieveUser={() => retrieveUser({ refresh: false })}
        refreshUser={() => retrieveUser({ refresh: true })}
      />
    </UserProvider>
  );
};

export const withUser = Cmp => props => {
  const contextUser = useContext(userContext);
  return <Cmp {...props} user={contextUser} />;
};
