import React from 'react';

import { useTranslation } from 'react-i18next';

import { useIsMobile } from '../../../utils';
import {
  FOHColors,
  FOHFonts,
  FOHHighlightLinksWithTexts,
  FOHImage,
  FOHLabel,
  FOHSpace,
  FOHSpaceLarge,
  FOHView,
  FullWidthCard
} from '../../../components';
import XTwitterLogo from '../../../images/x_twitter_logo.svg';

interface FOHXIntegrationCardProps {
  employerProfileID: string | null | undefined;
}

const FOHXIntegrationCard: React.FC<FOHXIntegrationCardProps> = ({
  employerProfileID
}) => {
  const { isMobile, width } = useIsMobile();
  const mobile = isMobile && width <= 627;

  const { t } = useTranslation('Integrations');

  return (
    <FOHView style={{ width: '100%' }}>
      <FOHView style={{ paddingTop: isMobile ? 8 : 16, borderRadius: 16 }}>
        <FullWidthCard
          shadow
          noBorder
          style={{
            paddingHorizontal: isMobile ? 14 : 28,
            paddingVertical: 28
          }}
        >
          <FOHView
            style={{
              flexDirection: mobile ? 'column' : 'row',
              alignItems: mobile ? 'flex-start' : 'center',
              justifyContent: 'space-between',
              flex: 1,
              flexWrap: 'wrap'
            }}
          >
            <FOHView
              style={{
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: 'flex-start',
                alignItems: isMobile ? 'flex-start' : 'center',
                flex: isMobile ? 'unset' : 3,
                maxWidth: isMobile ? '100%' : 'unset'
              }}
            >
              <FOHImage
                source={{ uri: XTwitterLogo }}
                style={{
                  width: 53,
                  height: 46
                }}
              />
              {isMobile ? <FOHSpace /> : <FOHSpaceLarge />}
              <FOHView
                style={{
                  wordWrap: 'break-word',
                  flex: 1
                }}
              >
                <FOHHighlightLinksWithTexts
                  labelCmp={FOHLabel}
                  links={[
                    {
                      word: 'xhiringsupport@x.com',
                      href: 'mailto:support@fohandboh.com',
                      noIcon: true
                    },
                    {
                      word: t('becomeXVerifiedOrgText'),
                      href: 'https://help.x.com/en/using-x/verified-organizations'
                    }
                  ]}
                  highlightColor={FOHColors.PACIFIC_BLUE}
                  linkStyle={{
                    fontSize: 14,
                    lineHeight: 20,
                    textDecoration: 'underline',
                    fontFamily: FOHFonts.REGULAR
                  }}
                  iconStyle={{
                    width: 13,
                    height: 13
                  }}
                  labelStyle={{
                    fontSize: 14,
                    lineHeight: 20,
                    textAlign: isMobile ? 'justify' : 'left',
                    display: 'block'
                  }}
                  textColor={FOHColors.GRAYSCALE_500}
                  emphasizedWords={[`foh&boh company ID: ${employerProfileID}`]}
                  emphasizedStyle={{
                    fontSize: 14,
                    lineHeight: 20,
                    fontFamily: FOHFonts.BOLD
                  }}
                >
                  {t('helpInfoForX', {
                    employerProfileID: employerProfileID
                  })}
                </FOHHighlightLinksWithTexts>
              </FOHView>
            </FOHView>
          </FOHView>
        </FullWidthCard>
      </FOHView>
    </FOHView>
  );
};

export default FOHXIntegrationCard;
