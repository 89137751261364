export const FORM_ID = 'career-page-form';

export const MAX_DESCRIPTION_LENGTH = 1000;

export const ALL_GOOGLE_FONTS = [
  {
    id: 'roboto',
    name: 'Roboto'
  },
  {
    id: 'arial',
    name: 'Arial'
  }
];
