import React from 'react';

import styled from 'styled-components/native';

import {
  FOHHeaderText,
  Screen,
  FOHDetailText,
  FOHAlert,
  FOHView
} from '../../ingredients';
import { FOHAccountMenuCell } from '../../recipes';

export interface FOHAccountMenuScreenProps {
  title?: string;
  detailText?: string;
  navigation: Array<FOHNavigation>;
  navigateTo: (route: string) => void;
  disabledSubmit?: boolean;
  alertMessage?: string;
}

interface FOHNavigation {
  label: string;
  route: string;
}

export const FOHAccountMenuScreen = (props: FOHAccountMenuScreenProps) => (
  <Screen>
    <FOHView
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 60,
        width: '100%'
      }}
    >
      <FOHHeaderText style={{ width: '90%' }}>{props.title}</FOHHeaderText>
      <FOHView style={{ paddingTop: 8, width: 300, alignItems: 'center' }}>
        <FOHDetailText>{props.detailText}</FOHDetailText>
      </FOHView>
      {props.alertMessage ? <FOHAlert message={props.alertMessage} /> : null}
      <ContainerView style={{ paddingBottom: 100, paddingTop: 20 }}>
        {props.navigation.map(nav => (
          <FOHAccountMenuCell
            label={nav.label}
            key={`account-menu-cell-${nav.label}`}
            onPress={() => props.navigateTo(nav.route)}
          />
        ))}
      </ContainerView>
    </FOHView>
  </Screen>
);

const ContainerView = styled(FOHView)`
  width: 100%;
`;
