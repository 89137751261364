import React, { FunctionComponent } from 'react';

import { StyleProp, View, ViewStyle } from 'react-native';
import styled from 'styled-components/native';

import { FOHBannerType } from '../../typings';
import { FOHColors, FOHView } from '../../ingredients';

import { FOHSuccessBanner } from './FOHSuccessBanner';
import { FOHCautionBanner } from './FOHCautionBanner';
import { FOHDangerBanner } from './FOHDangerBanner';

export interface FOHBannerProps {
  message: string;
  rounded?: boolean;
}

export interface FOHBannerTypeProps extends FOHBannerProps {
  bannerType?: keyof typeof FOHBannerType;
  style?: StyleProp<ViewStyle>;
}

export const FOHBanner: FunctionComponent<FOHBannerTypeProps> = ({
  bannerType,
  message,
  rounded,
  style = {}
}) => {
  let BannerComp;
  const bannerCompProps = { message, rounded };

  switch (bannerType) {
    case FOHBannerType.DANGER as string:
      BannerComp = <FOHDangerBanner {...bannerCompProps} />;
      break;

    case FOHBannerType.CAUTION as string:
      BannerComp = <FOHCautionBanner {...bannerCompProps} />;
      break;

    default:
      BannerComp = <FOHSuccessBanner {...bannerCompProps} />;
  }

  return (
    <FOHView style={{ width: '100%', ...((style || {}) as ViewStyle) }}>
      {BannerComp}
    </FOHView>
  );
};

export const BannerView = styled(View)`
  width: 100%;
  height: 50px; // usign the TOP_NAV_HEIGHT doesn't work for some reason ¯\(ツ)/¯
  border-radius: 6px;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 12px;
  color: ${FOHColors.WHITE};
  background-color: ${FOHColors.GREEN};
`;
