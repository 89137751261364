import { path } from 'ramda';

const getEnv = () => {
  switch (path(['window', 'location', 'host'], global)) {
    case 'fohandboh.com':
      return 'production';
    case 'fohbohstage.club':
      return 'stage';
    case 'localhost:3000':
      return 'local';
    default:
      return 'unknown';
  }
};

export const getAPIEndpoint = () => {
  switch (path(['window', 'location', 'host'], global)) {
    case 'fohandboh.com':
      return 'https://fohandboh.com';
    case 'fohbohstage.club':
      return 'https://fohbohstage.club';
    case 'localhost':
      return 'http://localhost:8000';
    default:
      return 'http://localhost:8000';
  }
};

export const getAppBaseUrl = () => {
  switch (path(['window', 'location', 'host'], global)) {
    case 'fohandboh.com':
      return 'https://fohandboh.com';
    case 'fohbohstage.club':
      return 'https://fohbohstage.club';
    case 'localhost':
      return 'http://localhost:3000';
    default:
      return 'http://localhost:3000';
  }
};

export const ENVIRONMENT = getEnv();

export const envVariables = {
  AMPLITUDE_API_KEY: process.env.REACT_APP_AMPLITUDE_API_KEY,
  GOOGLE_MAPS_PLACES_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_PLACES_API_KEY,
  GOOGLE_SSO_CLIENT_ID: process.env.REACT_APP_GOOGLE_SSO_CLIENT_ID,
  AWS_S3_BUCKET_NAME: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
  STRIPE_PUBLISHABLE_KEY: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
  GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
  SENDBIRD_APP_ID: process.env.REACT_APP_SENDBIRD_APP_ID,
  INDEED_OAUTH_CLIENT_ID: process.env.REACT_APP_INDEED_OAUTH_CLIENT_ID,
  INDEED_ATS_KEY: process.env.REACT_APP_INDEED_ATS_KEY,
  SEVENSHIFTS_CLIENT_ID: process.env.REACT_APP_SEVENSHIFTS_CLIENT_ID,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  GITHUB_SHA: process.env.REACT_APP_GITHUB_SHA,
  CLOUDFRONT_DOMAIN: process.env.REACT_APP_CLOUDFRONT_DOMAIN
};
