import React from 'react';

import { ScrollView, StyleProp, TouchableOpacityProps } from 'react-native';

import {
  FOHBackToTopIcon,
  FOHColors,
  FOHInverseButton
} from '../../ingredients';

export interface FOHBackToTopButtonProps {
  label?: string;
  refObject: React.RefObject<ScrollView>;
  style?: StyleProp<TouchableOpacityProps>;
}

export const FOHBackToTopButton = ({
  label = 'Back to the top',
  refObject,
  style
}: FOHBackToTopButtonProps) => {
  const onPress = () =>
    refObject?.current?.scrollTo({ animated: true, x: 0, y: 0 });

  return (
    <FOHInverseButton
      color={FOHColors.LIGHT_BLUE}
      icon={FOHBackToTopIcon}
      onPress={onPress}
      opaque={true}
      selected={true}
      style={style}
      textColor={FOHColors.BLUE}
      title={label}
    />
  );
};
