import React from 'react';

import { prop, path, defaultTo, length } from 'ramda';
import { useTranslation } from 'react-i18next';

import {
  FOHColors,
  FOHNotificationBanner,
  FOHNotificationType,
  FOHSuccessIcon,
  FOHView
} from '../../components';
import { useDismissNotificationMutation } from '../../graphql/generated';
import { FEED, SEND_OR_SET } from '../../constants';
import { handleMutation, multiWordToCamelCase } from '../../utils';
import { useNavigation } from '../../utils/navigation';

import { getNotificationIcon } from './CandidateInboxCell';

export const NotificationBanner = props => {
  const {
    employerCandidate,
    lastInterview,
    notificationMessage,
    sendDirectMessageToCandidate,
    setSelectedTab
  } = props;
  const { t } = useTranslation('NotificationBanner');

  const { navigateTo } = useNavigation();

  const [dismissNotification] = useDismissNotificationMutation();

  const candidateFirstName =
    path(['candidateProfile', 'user', 'firstName'], employerCandidate) || '';

  const employerCandidateNotifications = defaultTo(
    [],
    path(['employercandidateNotifications', 'edges'], employerCandidate)
  )
    .filter(
      notification =>
        path(['node', 'notificationType'], notification) !== 'CHAT'
    )
    .filter(
      notification => path(['node', 'dismissedAt'], notification) === null
    )
    .map(node => ({ ...prop('node', node) }));

  const notifActions = {
    phoneScreen: '/feed/phone-screening',
    videoScreen: '/feed/video-screening-questions',
    screeningQuestions: '/feed/screening-questions',
    requestAgain: '/feed/screening-questions',
    sendOffer: '/feed/send-offer',
    resendOffer: '/feed/resend-offer',
    markAsHired: '/feed/markas-hired',

    sendWelcomeLetter: '/feed/welcome'
  };

  return (
    <>
      {notificationMessage ? (
        <FOHView
          style={{
            paddingHorizontal: 12,
            paddingBottom: 12,
            width: '100%'
          }}
        >
          <FOHNotificationBanner
            isMobile={false}
            type={FOHNotificationType.DONE}
            notificationIcon={() => (
              <FOHSuccessIcon style={{ tintColor: FOHColors.GREEN }} />
            )}
            emphasizedWord=""
            message={notificationMessage}
          />
        </FOHView>
      ) : (
        <></>
      )}
      {length(employerCandidateNotifications) > 0 ? (
        employerCandidateNotifications.map((notification, keyIndex) => {
          return (
            <FOHView
              style={{
                paddingHorizontal: 12,
                paddingBottom: 12,
                width: '100%'
              }}
              key={`${keyIndex}${notification.id}`}
            >
              <FOHNotificationBanner
                key={keyIndex}
                isMobile={false}
                type={prop('status', notification)}
                notificationIcon={getNotificationIcon(
                  prop('notificationType', notification),
                  prop('status', notification)
                )}
                emphasizedWord=""
                message={t(prop('message', notification), {
                  candidate: candidateFirstName
                })}
                actions={defaultTo([], prop('actions', notification)).map(
                  action => {
                    const actionName = multiWordToCamelCase(action);

                    const enabledActions = [
                      //screening actions
                      'videoScreen',
                      'screeningQuestions',
                      'requestAgain',
                      'review',
                      //offered actions
                      'sendOffer',
                      'resendOffer',
                      'markAsHired',
                      //hired actions
                      'sendWelcomeLetter',
                      // interview schedule actions
                      'schedule',
                      'sendMessage',
                      'resendInvitation',
                      'addToCalendar',
                      'dismiss'
                    ];

                    return enabledActions.includes(actionName)
                      ? {
                          title: t(actionName),
                          onPress: async () => {
                            if (actionName === 'dismiss') {
                              return await handleMutation(
                                dismissNotification({
                                  variables: {
                                    id: prop('id', notification)
                                  }
                                })
                              );
                              // use history to open other action modals
                            } else if (actionName === 'addToCalendar') {
                              window &&
                                window.open &&
                                window.open(prop('ics', lastInterview));
                            } else if (actionName === 'sendMessage') {
                              sendDirectMessageToCandidate();
                            } else if (actionName === 'review') {
                              setSelectedTab('screening-detail');
                            } else if (
                              prop('notificationType', notification) ===
                              'SCHEDULE'
                            ) {
                              navigateTo(`${FEED}${SEND_OR_SET}`);
                            } else if (
                              prop('notificationType', notification) ===
                              'SCREENING'
                            ) {
                              // screening actions
                              if (actionName in notifActions) {
                                navigateTo(notifActions[actionName]);
                              }
                            } else if (
                              prop('notificationType', notification) ===
                              'OFFERED'
                            ) {
                              // offered actions
                              if (actionName in notifActions) {
                                navigateTo(notifActions[actionName]);
                              }
                            } else if (
                              prop('notificationType', notification) === 'HIRED'
                            ) {
                              // hired actions
                              if (actionName in notifActions) {
                                navigateTo(notifActions[actionName]);
                              }
                            }
                          }
                        }
                      : { title: '', onPress: () => {} };
                  }
                )}
              />
            </FOHView>
          );
        })
      ) : (
        <></>
      )}
    </>
  );
};
