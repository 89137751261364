import React, { useState } from 'react';

import styled from 'styled-components/native';
import {
  View,
  TextInput,
  ScrollView,
  TouchableOpacity,
  Image
} from 'react-native';
import { defaultTo, path, prop } from 'ramda';

import {
  FOHBlankImage,
  FOHCircleLoader,
  FOHDivider,
  FOHGrayX,
  FOHLabel,
  FOHLabelBold,
  FOHSmallDetailLabel,
  FOHSpace,
  FOHSpaceSmall,
  FOHColors,
  FOHFonts
} from '../../ingredients';
import { FOH_INPUT_HEIGHT } from '../../constants';
import { useClickOutside } from '../../spices';

import { FOHSelectItemProps } from '../select/FOHSelect';

export interface FOHDropdownImageSearchProps {
  onChangeSearch: (val: string) => void;
  searchValue: string;
  searchPlaceholder: string;
  onKeyPressSearchBar?: (e: any) => void;
  options: Array<FOHDetailedSelectItemProps>;
  selectedItem?: FOHDetailedSelectItemProps;
  selectItem: (item?: FOHDetailedSelectItemProps) => void;
  selectFreeTextLabel: string;
  loading?: boolean;
  clearSearch: () => void;
  closeLabel: string;
  searchDetailLabel: string;
}

export interface FOHDetailedSelectItemProps extends FOHSelectItemProps {
  // pass extra params for list view
  image?: string;
  id?: string;
  sublabel?: string;
}

export const FOHDropdownImageSearch = (props: FOHDropdownImageSearchProps) => {
  const [open, setOpen] = useState(false);
  const close = (noSelect: boolean = false) => {
    setOpen(false);
    if (!noSelect && props.searchValue && !props.selectedItem) {
      props.selectItem({
        value: props.searchValue,
        label: `${props.searchValue}`,
        sublabel: ''
      });
    }
  };
  const openDropdown = () => setOpen(true);

  const [ref] = useClickOutside({
    close: () => !!open && close()
  });

  return (
    <View style={{ zIndex: 99, width: '100%' }} ref={ref}>
      <View
        style={{
          position: open ? 'absolute' : 'relative',
          width: open ? '100%' : undefined,
          borderColor: open ? FOHColors.BLUE : '#dbdbdb',
          borderWidth: 1,
          borderRadius: 4,
          padding: 1,
          paddingRight: 34,
          backgroundColor: '#ffffff'
        }}
      >
        <TouchableOpacity
          testID={'close-image-search'}
          onPress={() => {
            open ? close() : props.clearSearch();
          }}
          style={{
            position: 'absolute',
            right: 10,
            top: open ? 20 : 12,
            zIndex: 99
          }}
        >
          {open ? (
            <FOHLabel
              style={{
                color: FOHColors.BLUE,
                backgroundColor: '#ffffff'
              }}
            >
              {props.closeLabel}
            </FOHLabel>
          ) : (
            <FOHGrayX />
          )}
        </TouchableOpacity>
        <Container
          style={{
            minHeight: FOH_INPUT_HEIGHT,
            height: open ? undefined : FOH_INPUT_HEIGHT,
            flexWrap: open ? 'wrap' : undefined,
            padding: 10
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              width: '100%',
              justifyContent: 'flex-start'
            }}
          >
            {path(['selectedItem', 'image'], props) ? (
              <Image
                source={{ uri: path(['selectedItem', 'image'], props) }}
                style={{
                  height: 32,
                  width: 32,
                  borderRadius: 2
                }}
              />
            ) : (
              <FOHBlankImage />
            )}
            <View style={{ paddingLeft: 10 }} />

            <Input
              style={{
                maxWidth:
                  open || !path(['selectedItem', 'sublabel'], props)
                    ? '100%'
                    : '70%'
              }}
              onChangeText={(e: any) => {
                props.selectItem(undefined);
                props.onChangeSearch(e);
              }}
              value={props.searchValue || ''}
              placeholder={props.selectedItem ? '' : props.searchPlaceholder}
              onKeyPress={(e: any) => {
                const key = path(['nativeEvent', 'key'], e);
                if (key === 'Backspace') {
                  return props.selectItem(undefined);
                } else if (key === 'Enter' && props.searchValue) {
                  if (path(['options', 0], props)) {
                    props.selectItem({
                      ...path(['options', 0], props)
                    });
                  }
                }
                props.onKeyPressSearchBar && props.onKeyPressSearchBar(e);
              }}
              onFocus={openDropdown}
            />
            {!!path(['selectedItem', 'sublabel'], props) && !open && (
              <View>
                <FOHSmallDetailLabel
                  numberOfLines={1}
                  style={{
                    left: -10,
                    maxWidth: '20%',
                    overflow: 'hidden',
                    flexWrap: 'nowrap'
                  }}
                >
                  {path(['selectedItem', 'sublabel'], props)}
                </FOHSmallDetailLabel>
              </View>
            )}
          </View>
        </Container>
        {open && (
          <DropDown
            style={{
              height: open ? 300 : 0,
              width: '100%',
              zIndex:
                (defaultTo(0, path(['style', 'zIndex'], props)) as number) + 11
            }}
          >
            <FOHDivider />
            <ScrollView>
              <FOHSpace />
              {props.loading ? (
                <View
                  style={{
                    paddingRight: 24
                  }}
                >
                  <FOHCircleLoader />
                </View>
              ) : (
                props.options.map(item => (
                  <TouchableOpacity
                    testID={`select-${prop('label', item)}`}
                    key={prop('value', item)}
                    style={{ flexDirection: 'row', paddingBottom: 12 }}
                    onPress={() => {
                      props.selectItem({
                        ...item
                      });
                      close(true);
                    }}
                  >
                    <Image
                      source={{ uri: path(['image'], item) }}
                      style={{ height: 32, width: 32, borderRadius: 2 }}
                    />
                    <FOHSpace />
                    <View>
                      <FOHLabel style={{ width: '70%' }}>
                        {prop('label', item)}
                      </FOHLabel>
                      <FOHSmallDetailLabel
                        style={{ width: '60%' }}
                        numberOfLines={2}
                      >
                        {prop('sublabel', item)}
                      </FOHSmallDetailLabel>
                    </View>
                  </TouchableOpacity>
                ))
              )}
              {!!props.searchValue && !props.selectedItem && (
                <TouchableOpacity
                  testID={`select-free-text-${props.searchValue}`}
                  onPress={() => {
                    props.selectItem({
                      value: props.searchValue,
                      label: `${props.searchValue}`
                    });
                    close();
                  }}
                >
                  {!!props.searchValue && (
                    <FOHLabel>
                      {props.selectFreeTextLabel}
                      <FOHSpaceSmall />
                      <FOHLabelBold>{`"${props.searchValue}"`}</FOHLabelBold>
                      <FOHSpaceSmall />
                      <FOHSmallDetailLabel>
                        {props.searchDetailLabel}
                      </FOHSmallDetailLabel>
                    </FOHLabel>
                  )}
                  <FOHSpace />
                </TouchableOpacity>
              )}
            </ScrollView>
          </DropDown>
        )}
      </View>
    </View>
  );
};

const Input = styled(TextInput)`
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  display: flex;
  align-items: center;
  height: 14px;
  flex-direction: row;
  padding: 10px;
  padding-left: 0px;
  outline-color: transparent;
  flex: 1;
  outline-color: transparent;
`;

const Container = styled(View)`
  background: #ffffff;
  font-family: ${FOHFonts.REGULAR};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: row;
  padding: 10px;
  border-radius: 4px;
`;

const DropDown = styled(View)`
  height: 350px;
  background-color: #ffffff;
  width: 327px;
  justify-content: left;
  padding-left: 18px;
  border-radius: 4px;
  z-index: 11;
  border-radius: 4px;
`;
