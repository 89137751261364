import React from 'react';

import { FOHBannerText, FOHCautionIcon, FOHColors } from '../../ingredients';

import { FOHBannerProps, BannerView } from './FOHBanner';

export const FOHCautionBanner = (props: FOHBannerProps) => (
  <BannerView
    style={{
      backgroundColor: FOHColors.GOLD,
      borderRadius: props.rounded ? 6 : 0
    }}
    testID="FOHCautionBanner"
  >
    <FOHCautionIcon />
    <FOHBannerText>{props.message}</FOHBannerText>
  </BannerView>
);
