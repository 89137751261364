import React, { useRef } from 'react';

import { Image, TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';
import { useHover } from 'react-native-web-hooks';
import { defaultTo, prop } from 'ramda';

import {
  FOHBluePlayIcon,
  FOHSmallDetailLabel,
  FOHHeaderH3,
  FOHSpace,
  FOHSpaceSmall,
  FOHSpaceSmallest,
  FOHColors,
  FOHView
} from '../../ingredients';
import { trimText } from '../../utils';

export interface FOHVideoListCellProps extends FOHVideoCellProp {
  style?: any;
  selected?: boolean;
  enableHover?: boolean;
  isMobile?: boolean;
}

export interface FOHVideoCellProp {
  thumbImage: string;
  title: string;
  uploadedAt: string;
  totalMins: string;
  onPress?: () => void;
}

export const FOHVideoListCell = (props: FOHVideoListCellProps) => {
  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);
  const isSelected = defaultTo(false, prop('selected', props));

  return (
    <Container
      onPress={props.onPress}
      ref={hoverRef}
      style={
        props.enableHover
          ? {
              backgroundColor: !isHovered
                ? !isSelected
                  ? FOHColors.WHITE
                  : FOHColors.LIGHT_BLUE
                : FOHColors.LIGHT_BLUE,
              ...defaultTo({}, prop('style', props))
            }
          : {
              backgroundColor: isSelected
                ? FOHColors.LIGHT_BLUE
                : FOHColors.WHITE,
              ...defaultTo({}, prop('style', props))
            }
      }
    >
      {!props.isMobile && prop('thumbImage', props) ? (
        <>
          <ThumbnailImage source={{ uri: prop('thumbImage', props) }} />
          <FOHSpace />
        </>
      ) : (
        <></>
      )}
      <VideoPlayDetailContainer>
        <VideoDetailContainer>
          <FOHHeaderH3>{trimText(props.title, 35)}</FOHHeaderH3>
          <FOHSpaceSmallest />
          <FOHSmallDetailLabel
            style={{
              lineHeight: 18,
              color: FOHColors.CYBERSPACE_GREY,
              fontWeight: 600
            }}
          >
            {prop('uploadedAt', props)}
          </FOHSmallDetailLabel>
        </VideoDetailContainer>
        <FOHSpaceSmall />
        <PlayBackContainer>
          <IconContainer
            style={{ justifyContent: 'center', alignItems: 'center' }}
          >
            <FOHBluePlayIcon
              style={{ width: 8, height: 8, tintColor: FOHColors.WHITE }}
            />
          </IconContainer>
          <FOHSpaceSmallest />
          <FOHSmallDetailLabel
            style={{
              fontSize: 12,
              lineHeight: 18,
              color: FOHColors.GREY,
              fontWeight: 600
            }}
          >
            {prop('totalMins', props)}
          </FOHSmallDetailLabel>
        </PlayBackContainer>
      </VideoPlayDetailContainer>
    </Container>
  );
};

const Container = styled(TouchableOpacity)`
  cursor: pointer;
  width: 100%;
  flex-direction: row;
  align-items: center;
  display: flex;
  padding: 16px;
  border-radius: 3px;
  flex-wrap: wrap;
  width: 100%;
`;

const ThumbnailImage = styled(Image)`
  border-radius: 6px;
  width: 64px;
  height: 48px;
`;

const VideoPlayDetailContainer = styled(FOHView)`
  flex-direction: row;
  justify-content: space-between;
  flex: auto 1 0;
  display: flex;
  flex-wrap: wrap;
`;

const VideoDetailContainer = styled(FOHView)`
  align-items: flex-start;
  justify-content: center;
`;

const PlayBackContainer = styled(FOHView)`
  align-items: center;
  justify-content: center;
`;

const IconContainer = styled(FOHView)`
  border-radius: 50%;
  width: 24px;
  height: 24px;
  text-align: center;
  background-color: #51c1db;
  justify-content: center;
`;
