import React, { useMemo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components/native';
import { defaultTo, isEmpty, path, prop, length } from 'ramda';

import {
  FOHColors,
  FOHDetailText,
  FOHDivider,
  FOHLabel,
  FOHLabelSmall,
  FOHSmallDetailLabel,
  FOHSpace,
  FOHSpaceSmall,
  FOHSpaceSmallest,
  FOHVideoListCell,
  FOHVideoStoryPlayer,
  FOHView,
  Row
} from '../../components';
import { parseFileNameFromS3Url, toDateTime } from '../../utils';

const VideoComponent = ({ screeningVideoAnswer, vidRef, setVideoDuration }) => {
  if (!screeningVideoAnswer || !screeningVideoAnswer.file) {
    return null;
  }

  const handleLoadedMetadata = () => {
    if (vidRef.current) {
      const durationInSeconds = Math.floor(vidRef.current.duration);
      const minutes = Math.floor(durationInSeconds / 60);
      const seconds = durationInSeconds % 60;
      setVideoDuration(`${minutes}:${seconds.toString().padStart(2, '0')}`);
    }
  };

  return (
    <video
      controls
      ref={vidRef}
      webkit-playsinline={1} //eslint-disable-line
      playsInline
      style={{ width: '60%', height: 640 }}
      onLoadedMetadata={handleLoadedMetadata}
    >
      <track kind="captions" />
      <source src={prop('file', screeningVideoAnswer)} type="video/mp4" />
    </video>
  );
};

const ConditionalRender = ({ condition, children }) => {
  return condition ? <FOHView>{children}</FOHView> : null;
};

const ConditionalRenderedComponent = ({
  condition,
  screeningLabel,
  employerCandidateQuestionnaire,
  children,
  screeningCompletedOn,
  testID
}) => {
  const { t } = useTranslation('CandidateFeed');

  const getPositionAndLocation = () => {
    if (
      !employerCandidateQuestionnaire ||
      !employerCandidateQuestionnaire?.node?.position
    ) {
      return { position: null, location: null };
    }

    const positionName = defaultTo(
      null,
      path(['node', 'position', 'positionName'], employerCandidateQuestionnaire)
    );
    const locationName = defaultTo(
      null,
      path(
        ['node', 'position', 'location', 'name'],
        employerCandidateQuestionnaire
      )
    );
    const formattedAddress = defaultTo(
      null,
      path(
        ['node', 'position', 'location', 'formattedAddress'],
        employerCandidateQuestionnaire
      )
    );

    return {
      position: positionName,
      location: `${locationName}${' - '}${formattedAddress}`
    };
  };
  const { position, location } = useMemo(
    () => getPositionAndLocation(),
    [employerCandidateQuestionnaire]
  );

  return (
    <ConditionalRender condition={condition}>
      <FOHView testID={testID}>
        <FOHDetailText style={{ alignItems: 'flex-start' }}>
          {screeningLabel}
        </FOHDetailText>
        <FOHSpaceSmall />
        <ScreeningBlockContainer>
          <FOHRow style={{ width: '50%' }}>
            <FOHView
              style={{
                flex: 1,
                alignItems: 'flex-start',
                paddingHorizontal: 20,
                paddingVertical: 3
              }}
            >
              <FOHSmallDetailLabel
                style={{
                  alignItems: 'flex-start',
                  color: FOHColors.CYBERSPACE_GREY
                }}
              >
                {t('common:position')}
              </FOHSmallDetailLabel>
              <Block>
                <FOHLabel
                  style={{
                    color: FOHColors.GRAYSCALE_900,
                    lineHeight: 22,
                    maxWidth: 300,
                    minHeight: 24
                  }}
                >
                  {position}
                </FOHLabel>
                <FOHSmallDetailLabel
                  numberOfLines={1}
                  style={{
                    color: FOHColors.GRAYSCALE_600
                  }}
                >
                  {location}
                </FOHSmallDetailLabel>
              </Block>
              <FOHSpaceSmallest />
              <FOHDivider />
            </FOHView>
          </FOHRow>
          <FOHRow style={{ width: '50%' }}>
            <FOHView
              style={{
                flex: 1,
                alignItems: 'flex-start',
                paddingHorizontal: 20,
                paddingVertical: 3
              }}
            >
              <FOHSmallDetailLabel
                style={{
                  alignItems: 'flex-start',
                  color: FOHColors.CYBERSPACE_GREY
                }}
              >
                {`${t(
                  'JobBoardCampaignFeature:campaignCompletedLabel'
                )}${' '}${t('common:onLabel')}`}
              </FOHSmallDetailLabel>
              <Block>
                <FOHLabel
                  style={{
                    color: FOHColors.GRAYSCALE_900,
                    lineHeight: 22,
                    maxWidth: 300,
                    minHeight: 24
                  }}
                >
                  {screeningCompletedOn}
                </FOHLabel>
              </Block>
              <FOHSpaceSmallest />
              <FOHDivider />
            </FOHView>
          </FOHRow>
          {children}
        </ScreeningBlockContainer>
      </FOHView>
      <FOHSpace />
    </ConditionalRender>
  );
};

export const CandidateScreeningDetail = props => {
  const {
    employerCandidatePrescreenQuestionnaire,
    employerCandidateScreeningQuestionnaire,
    employerCandidateScreeningVideoQuestionnaire,
    prescreenAnswers,
    prescreenCompletedOn,
    questionnaire,
    screeningQuestionsCompletedOn,
    screeningVideoCompletedOn,
    screeningVideoAnswer,
    storyPhoto,
    setVideo
  } = props;

  const vidRef = useRef(null);

  const [videoOpen, setVideoOpen] = useState(false);
  const [videoDuration, setVideoDuration] = useState('');

  const toggleVideoPlayback = () => {
    vidRef && vidRef.current && !vidRef.current.paused
      ? vidRef.current.pause()
      : vidRef.current.play();
  };

  const openVideo = () => setVideo(prop('file', screeningVideoAnswer));
  const closeVideo = () => setVideoOpen(false);

  const { t } = useTranslation('CandidateFeed');

  return (
    <>
      <FOHView style={{ padding: 20 }}>
        <ConditionalRenderedComponent
          condition={screeningQuestionsCompletedOn && questionnaire}
          testID="screening-questions-block"
          employerCandidateQuestionnaire={
            employerCandidateScreeningQuestionnaire
          }
          screeningLabel={t('ScreeningQuestionnaireSettingFeature:HeaderTitle')}
          screeningCompletedOn={screeningQuestionsCompletedOn}
        >
          {!isEmpty(questionnaire) ? (
            <FOHRow>
              <FOHView
                style={{
                  flex: 1,
                  alignItems: 'flex-start',
                  paddingHorizontal: 20
                }}
              >
                <FOHSmallDetailLabel
                  style={{
                    alignItems: 'flex-start',
                    color: FOHColors.PRIMARY_TEAL_P1
                  }}
                >
                  {t('responsesLabel')}
                </FOHSmallDetailLabel>
                <FOHSpaceSmall />
                <FOHView
                  style={{
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    width: '100%'
                  }}
                >
                  {questionnaire.map((questionAnswer, keyIndex) => (
                    <FOHView
                      testID={`screening-question-${keyIndex + 1}`}
                      key={keyIndex}
                      style={{ width: '100%' }}
                    >
                      <FOHView>
                        <FOHLabel>
                          <>{`${keyIndex + 1}.`}</>
                          <FOHSpaceSmall />
                          {questionAnswer?.questionText}
                        </FOHLabel>
                        <FOHSpaceSmall />
                        {length(questionAnswer.answers) > 0 ? (
                          questionAnswer.answers.map((answer, idx) => (
                            <>
                              <FOHView style={{ width: '100%' }} key={idx}>
                                <FOHLabelSmall
                                  style={{
                                    borderRadius: 4,
                                    backgroundColor: FOHColors.PRIMARY_TEAL_10,
                                    padding: 16
                                  }}
                                >
                                  {answer}
                                </FOHLabelSmall>
                              </FOHView>
                              <FOHSpaceSmallest />
                            </>
                          ))
                        ) : (
                          <FOHView style={{ width: '100%' }}>
                            <FOHLabelSmall
                              style={{
                                borderRadius: 4,
                                backgroundColor: FOHColors.PRIMARY_TEAL_10,
                                padding: 16
                              }}
                            >
                              {t('noResponseLabel')}
                            </FOHLabelSmall>
                          </FOHView>
                        )}
                      </FOHView>
                      <FOHSpace />
                    </FOHView>
                  ))}
                </FOHView>
              </FOHView>
            </FOHRow>
          ) : null}
        </ConditionalRenderedComponent>

        <ConditionalRenderedComponent
          condition={prescreenCompletedOn && prescreenAnswers}
          testID="screening-questions-block"
          employerCandidateQuestionnaire={
            employerCandidatePrescreenQuestionnaire
          }
          screeningLabel={t(
            'ScreeningQuestionnaireSettingFeature:PrescreenTitle'
          )}
          screeningCompletedOn={prescreenCompletedOn}
        >
          {!isEmpty(prescreenAnswers) ? (
            <FOHRow>
              <FOHView
                style={{
                  flex: 1,
                  alignItems: 'flex-start',
                  paddingHorizontal: 20
                }}
              >
                <FOHSmallDetailLabel
                  style={{
                    alignItems: 'flex-start',
                    color: FOHColors.PRIMARY_TEAL_P1
                  }}
                >
                  {t('responsesLabel')}
                </FOHSmallDetailLabel>
                <FOHSpaceSmall />
                <FOHView
                  style={{
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    width: '100%'
                  }}
                >
                  {prescreenAnswers.map((questionAnswer, keyIndex) => (
                    <FOHView
                      testID={`screening-question-${keyIndex + 1}`}
                      key={keyIndex}
                      style={{ width: '100%' }}
                    >
                      <FOHView>
                        <FOHLabel>
                          <>{`${keyIndex + 1}.`}</>
                          <FOHSpaceSmall />
                          {questionAnswer?.questionText}
                        </FOHLabel>
                        <FOHSpaceSmall />
                        {length(questionAnswer.answers) > 0 ? (
                          questionAnswer.answers.map((answer, idx) => (
                            <>
                              <FOHView style={{ width: '100%' }} key={idx}>
                                <FOHLabelSmall
                                  style={{
                                    borderRadius: 4,
                                    backgroundColor: FOHColors.PRIMARY_TEAL_10,
                                    padding: 16
                                  }}
                                >
                                  {answer}
                                </FOHLabelSmall>
                              </FOHView>
                              <FOHSpaceSmallest />
                            </>
                          ))
                        ) : (
                          <FOHView style={{ width: '100%' }}>
                            <FOHLabelSmall
                              style={{
                                borderRadius: 4,
                                backgroundColor: FOHColors.PRIMARY_TEAL_10,
                                padding: 16
                              }}
                            >
                              {t('noResponseLabel')}
                            </FOHLabelSmall>
                          </FOHView>
                        )}
                      </FOHView>
                      <FOHSpace />
                    </FOHView>
                  ))}
                </FOHView>
              </FOHView>
            </FOHRow>
          ) : null}
        </ConditionalRenderedComponent>

        <ConditionalRenderedComponent
          condition={screeningVideoCompletedOn && screeningVideoAnswer}
          testID="screening-video-block"
          employerCandidateQuestionnaire={
            employerCandidateScreeningVideoQuestionnaire
          }
          screeningLabel={t('screeningVideosLabel')}
          screeningCompletedOn={screeningVideoCompletedOn}
        >
          {!isEmpty(screeningVideoAnswer) ? (
            <FOHRow style={{ width: '50%' }}>
              <FOHView
                style={{
                  flex: 1,
                  alignItems: 'flex-start',
                  paddingHorizontal: 20,
                  paddingVertical: 3
                }}
                testID={'FOHVideoListCell-screening-video'}
              >
                <FOHSmallDetailLabel
                  style={{
                    alignItems: 'flex-start',
                    color: FOHColors.PRIMARY_TEAL_P1
                  }}
                >
                  {t('ProfileFeature:videoLabel')}
                </FOHSmallDetailLabel>
                <Block style={{ flex: 1, width: '100%' }}>
                  <FOHView style={{ width: '100%', marginVertical: 4 }}>
                    <FOHVideoListCell
                      title={parseFileNameFromS3Url(
                        prop('file', screeningVideoAnswer)
                      )}
                      totalMins={
                        prop('file', screeningVideoAnswer) && videoDuration
                      }
                      uploadedAt={defaultTo(
                        '',
                        toDateTime(prop('uploadAt', screeningVideoAnswer))
                      )}
                      onPress={() => {
                        prop('file', screeningVideoAnswer) && openVideo();
                      }}
                    />
                  </FOHView>
                </Block>
                <FOHSpaceSmallest />
                <FOHDivider />
              </FOHView>
            </FOHRow>
          ) : null}
        </ConditionalRenderedComponent>

        <FOHView style={{ display: 'none' }}>
          <VideoComponent
            screeningVideoAnswer={screeningVideoAnswer}
            vidRef={vidRef}
            setVideoDuration={setVideoDuration}
          />
        </FOHView>
      </FOHView>
      <FOHVideoStoryPlayer
        open={videoOpen}
        togglePlaying={toggleVideoPlayback}
        close={closeVideo}
        video={prop('file', screeningVideoAnswer)}
        VideoComponent={VideoComponent}
        profilePhoto={storyPhoto ? storyPhoto : ''}
      />
    </>
  );
};

const ScreeningBlockContainer = styled(FOHView)`
  padding-horizontal: 16;
  background-color: ${FOHColors.LIGHT_GRAY};
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  border-width: 1;
  border-radius: 4;
  border-color: ${FOHColors.GRAYSCALE_300};
  width: 100%;
  padding-top: 16;
  padding-bottom: 6;
`;

const FOHRow = styled(Row)`
  width: 100%;
`;

const Block = styled(FOHView)`
  align-items: flex-start;
  justify-content: center;
  height: 50px;
`;
