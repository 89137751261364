import React, { FunctionComponent, useState } from 'react';

import { ScrollViewProps } from 'react-native';

import { useIsMobile } from '../../spices';
import { FOHSearchableSelectDropdownProps } from '../../recipes';
import {
  FOHLocationPin,
  FOHSectionLabel,
  FOHSquareTag,
  SPACING_SCALE
} from '../../ingredients';
import { FOHOpenPositionProps } from '../../recipes/blocks/FOHOpenPosition';
import { selectItem } from '../../utils';

import { FontProperty } from './FOHCareerPageLocations';
import {
  SearchCluster,
  SearchField,
  LocationDropdown,
  PositionsWrapper,
  InformationText
} from './styled/FOHCareerPagePositions.styled';
import { PositionList } from './parts';

export interface FOHCareerPagePositionsProps {
  fontProperty?: FontProperty;
  isLoading: boolean;
  listOnScrollHandler: ScrollViewProps['onScroll'];
  maxHeightOnDesktop: number;
  options: FOHSearchableSelectDropdownProps['options'];
  positions: FOHOpenPositionProps[];
  searchPhrase: FOHSearchableSelectDropdownProps['searchValue'];
  selectedLocations: FOHSearchableSelectDropdownProps['selectedValue'];
  setSearchPhrase: FOHSearchableSelectDropdownProps['onSearchChange'];
  setSelectedLocations: FOHSearchableSelectDropdownProps['onValueChange'];
  totalPositionsCount: number;
  translations: Record<string, string>;
  onPositionDetailsPress: (positionSlug: string) => void;
  onApplyPress: (positionSlug: string) => void;
}

export const FOHCareerPagePositions: FunctionComponent<
  FOHCareerPagePositionsProps
> = ({
  fontProperty,
  isLoading,
  listOnScrollHandler,
  maxHeightOnDesktop,
  options,
  positions,
  searchPhrase = '',
  selectedLocations,
  setSearchPhrase = () => null,
  setSelectedLocations,
  translations,
  onPositionDetailsPress,
  onApplyPress,
  totalPositionsCount,
  ...props
}) => {
  const isMobile = useIsMobile();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const computeLabel = (): string => {
    switch (selectedLocations.length) {
      case 0:
        return 'No location selected';
      case 1:
        return options.find(({ value }) => value === selectedLocations[0])!
          .label;
      default:
        return `Show all ${selectedLocations.length} locations`;
    }
  };

  const selectedLabel = computeLabel();
  const onValueChangeHandler = (value: string) =>
    selectItem(value, setSelectedLocations, selectedLocations);

  const isEveryOptionSelected = selectedLocations.length === options.length;
  const onSelectAllHandler = () =>
    isEveryOptionSelected
      ? setSelectedLocations([])
      : setSelectedLocations(options.map(location => location.value));

  return (
    <PositionsWrapper isMobile={isMobile} {...props}>
      {!isMobile && (
        <FOHSectionLabel
          style={{
            lineHeight: '1',
            marginBottom: SPACING_SCALE.larger,
            ...fontProperty
          }}
        >
          {translations.header}
          <FOHSquareTag
            label={`${totalPositionsCount || 0}`}
            style={{ marginLeft: SPACING_SCALE.regular }}
          />
        </FOHSectionLabel>
      )}

      <SearchCluster isMobile={isMobile}>
        <SearchField
          isMobile={isMobile}
          onChangeSearch={setSearchPhrase}
          searchPlaceholder=""
          searchValue={searchPhrase}
        />

        {options?.length > 1 && (
          <LocationDropdown
            fieldLabel="LOCATION"
            icon={FOHLocationPin}
            isMobile={isMobile}
            isSelectAllSelected={isEveryOptionSelected}
            multiSelect={true}
            onPressSelectAll={onSelectAllHandler}
            onValueChange={onValueChangeHandler}
            open={isDropdownOpen}
            options={options}
            selectAllLabel="Select All Locations"
            selectedLabel={selectedLabel}
            selectedValue={selectedLocations}
            setOpen={setIsDropdownOpen}
          />
        )}
      </SearchCluster>

      <PositionList
        fontProperty={fontProperty}
        isLoading={isLoading}
        maxHeight={maxHeightOnDesktop}
        onApplyPress={onApplyPress}
        onPositionDetailsPress={onPositionDetailsPress}
        onScrollHandler={listOnScrollHandler}
        positions={positions}
        translations={translations}
      />

      {selectedLocations?.length === 0 && (
        <InformationText style={{ ...fontProperty }}>
          {translations.noLocationSelected}
        </InformationText>
      )}

      {selectedLocations?.length !== 0 && positions?.length === 0 && (
        <InformationText style={{ ...fontProperty }}>
          {translations.noOpenPositionsFound}
        </InformationText>
      )}
    </PositionsWrapper>
  );
};
