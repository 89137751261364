import React from 'react';

import styled from 'styled-components/native';
import { Image } from 'react-native';
import { defaultTo, prop } from 'ramda';

export interface FOHImageCellProps {
  image: string;
  style?: any;
}

export const FOHImageCell = (props: FOHImageCellProps) => {
  return (
    <FOHImage
      source={{
        uri: props.image
      }}
      style={{
        width: 224,
        height: 200,
        ...defaultTo({}, prop('style', props))
      }}
    />
  );
};

const FOHImage = styled(Image)`
  object-fit: cover;
`;
