import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { path, prop } from 'ramda';

import {
  FOHScrollView,
  FOHView,
  FullWidthCard,
  FOHSpace,
  FOHBlackTag,
  FOHRestaurantLocationCell,
  FOHHeaderH3
} from '../../components';
import {
  formatFollowerCountLabel,
  isScrollCloseToBottom,
  unwrapEdgesAt,
  useIsMobile
} from '../../utils';
import { useSearchLocationsFeedQuery } from '../../graphql/generated';
import { DISCOVER, HOME_PAGE, RESTAURANT_JOBS } from '../../constants';
import { useNavigation } from '../../utils/navigation';

import { useLocationMutation } from '../LocationFeed/useLocationMutation';

import { fetchMoreLocations } from './fetchMoreLocations';
import { HomeScrollFooter } from './HomeScrollFooter';

export const CandidateFollowing = props => {
  const { t } = useTranslation('LocationFeedFeature');
  const { navigateTo } = useNavigation();

  const { isMobile } = useIsMobile();
  const [loadingMore, setLoadingMore] = useState(false);
  const variables = {
    following: true,
    first: 10,
    featured: 'any'
  };
  const locationQuery = useSearchLocationsFeedQuery({ variables });
  const followingLocations = unwrapEdgesAt(
    ['data', 'locations', 'edges'],
    locationQuery
  );
  const followingCount = path(
    ['data', 'locations', 'totalCount'],
    locationQuery
  );

  // User Actions
  const { optimisticToggleFavorite, optimisticToggleHidden } =
    useLocationMutation();

  // popup window for desktop
  const navigateToLocation = location => {
    const locationSlug = prop('slug', location);
    if (isMobile) {
      navigateTo({
        pathname: `${RESTAURANT_JOBS}/${locationSlug}`,
        search: path(['location', 'search'], props)
      });
    } else {
      props.openLocationDetailsModal(location.slug);
    }
  };

  useEffect(() => {
    if (
      props.scrolledToBottom &&
      !locationQuery.loading &&
      isMobile &&
      !loadingMore
    ) {
      setLoadingMore(true);
      fetchMoreLocations(locationQuery, variables).then(() => {
        setLoadingMore(false);
        props.setScrolledToBottom(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.scrolledToBottom]);

  return (
    <>
      <FOHView
        style={{
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          paddingTop: isMobile ? 10 : 24
        }}
      >
        <FOHHeaderH3
          style={{
            lineHeight: 'inherit',
            marginTop: 2,
            marginRight: 8
          }}
        >
          Your Following Restaurants
        </FOHHeaderH3>
        <FOHBlackTag label={followingCount} />
      </FOHView>
      <FOHScrollView
        onScroll={async ({ nativeEvent }) => {
          if (isScrollCloseToBottom(nativeEvent) && !loadingMore && !isMobile) {
            setLoadingMore(true);
            await fetchMoreLocations(locationQuery, variables);
            setLoadingMore(false);
          }
        }}
        scrollEventThrottle={200}
        style={{
          // no nested scrolling on mobile
          height: isMobile ? '100%' : '80vh',
          zIndex: -1
        }}
      >
        <FOHSpace />
        <>
          {locationQuery.loading ? (
            <></>
          ) : (
            followingLocations.map(location => (
              <FullWidthCard
                style={{
                  justifyContent: 'space-between',
                  width: '100%',
                  marginBottom: 24,
                  paddingLeft: 16,
                  paddingRight: 16
                }}
                key={`${location.id}-location`}
              >
                <FOHSpace />
                <FOHRestaurantLocationCell
                  id={location.id}
                  key={location.id}
                  isCard={false}
                  isHiring={location.isHiring}
                  hiringLabel={t('hiringButtonLabel')}
                  disabledHiringButton={
                    location?.hiringPositionCount === 0 || false
                  }
                  hiringBtnHelpText={t('viewJobsButtonHelpText')}
                  favoriteLabel={t('favoriteLabel')}
                  hideLabel={t('hideLabel')}
                  image={String(location.image)}
                  name={location.name}
                  vertical={isMobile}
                  formattedAddress={location.formattedAddress}
                  tags={location.tags}
                  isFavorited={location.isFavorited}
                  isHidden={location.isHidden}
                  seeMoreLabel={t('seeMoreLabel')}
                  favoritedHelpText={t('favoritedHelpText')}
                  hideMeHelpText={t('hideMeHelpText')}
                  followerCountLabel={formatFollowerCountLabel({
                    followerCount: prop('followerCount', location),
                    t
                  })}
                  seeMore={() => navigateToLocation(location)}
                  pressFavorite={() => optimisticToggleFavorite(location)}
                  pressHide={() => optimisticToggleHidden(location)}
                  openHiringModal={() => props.openViewJobsModal(location.slug)}
                />
                <FOHSpace key={`fohspace_${location.id}`} />
              </FullWidthCard>
            ))
          )}
        </>
        <HomeScrollFooter
          loading={locationQuery.loading || loadingMore}
          hasNextPage={path(
            ['data', 'locations', 'pageInfo', 'hasNextPage'],
            locationQuery
          )}
          noMorePositionsLabel={t('CandidateHomeFeature:noMoreFollowing')}
          noMorePositionsDetailLabel={t('CandidateHomeFeature:applyByYourOwn')}
          noMoreSubmitBtnLabel={t('CandidateHomeFeature:browseMoreJobs')}
          onSubmitPress={() => {
            if (path(['params', 'tab'], props.match) !== 'discover') {
              navigateTo(`${HOME_PAGE}${DISCOVER}`);
            }
          }}
        />
      </FOHScrollView>
    </>
  );
};
