export const fetchMoreLocations = async (query, variables) => {
  return query.data.locations.pageInfo.hasNextPage && !query.loading
    ? await query.fetchMore({
        variables: {
          ...variables,
          after: query.data.locations.pageInfo.endCursor,
          first: 10
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const newEdges = fetchMoreResult.locations.edges;
          const pageInfo = fetchMoreResult.locations.pageInfo;
          return newEdges.length
            ? {
                locations: {
                  __typename: previousResult.locations.__typename,
                  edges: [...previousResult.locations.edges, ...newEdges],
                  pageInfo
                }
              }
            : previousResult;
        }
      })
    : undefined;
};
