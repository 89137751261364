import { useState, useEffect } from 'react';

import { path } from 'ramda';
import * as Sentry from '@sentry/react';

import {
  useMutateCandidateResumeMutation,
  useMutateDeleteMyResumeMutation
} from '../../graphql/generated';
import { handleMutation } from '../../utils';

import { useCandidateProfileMutation } from './useCandidateProfileMutation';

const isFileTypeAllowed = file => {
  const allowedTypes = [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'text/plain',
    'text/rtf',
    'text/html',
    'application/vnd.apple.pages'
  ];

  return allowedTypes.includes(file.type);
};

export const useResumeMutations = (onUploadSuccess, onUploadError) => {
  const { me } = useCandidateProfileMutation();

  const [resumePreview, setResumePreview] = useState('');
  const [uploadResume] = useMutateCandidateResumeMutation();
  const [deleteResume] = useMutateDeleteMyResumeMutation();
  const [fileToUpload, setFileToUpload] = useState(null);

  useEffect(() => {
    if (fileToUpload && !isFileTypeAllowed(fileToUpload)) {
      setFileToUpload(null);
      setResumePreview('');
      onUploadError && onUploadError();
      return;
    }

    if (fileToUpload) {
      (async () => {
        const [response, error] = await handleMutation(
          uploadResume({
            variables: {
              id: path(['candidateProfile', 'id'], me),
              resumeFileName: fileToUpload.name,
              resumeFileType: fileToUpload.type
            }
          })
        );

        if (error || !response) {
          onUploadError && onUploadError();
          setFileToUpload(null);
          setResumePreview('');
          await handleMutation(deleteResume({}));
          Sentry.captureException(error);
          return;
        }

        await fetch(path(['mutateCandidate', 'resumeUploadUrl'], response), {
          method: 'PUT',
          body: fileToUpload
        });
        onUploadSuccess && onUploadSuccess();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileToUpload]);

  return {
    resumePreview,
    setResumePreview,
    deleteResume,
    fileToUpload,
    setFileToUpload
  };
};
