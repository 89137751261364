import React from 'react';

import { defaultTo, prop } from 'ramda';

import caution from '../../../images/caution.svg';

import { FOHImage } from '../../ingredients';

export const FOHCautionIcon = (props: any) => (
  <FOHImage
    source={{ uri: caution }}
    style={{ width: 24, height: 24, ...defaultTo({}, prop('style', props)) }}
  />
);
