import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { path, prop, toLower, length } from 'ramda';

import {
  FOHFixedHeightScrollView,
  FOHView,
  FOHSpace,
  FOHCircleLoader,
  FOHHotelIcon,
  FOHRestaurantIcon,
  FOHMultiSelectSearchForm,
  FOHFormFlowButtons,
  FOHExternalApplicationHeader
} from '../../components';
import { OPTIONS_INDUSTRY_SELECTION_RADIO } from '../../constants';
import { MAX_SCREEN_WIDTH } from '../../components/constants';
import { findById, handle, useIsMobile } from '../../utils';

import { useCandidateProfileMutation } from '../CandidateProfile/useCandidateProfileMutation';
import { usePositionTypes } from '../CandidateProfile/usePositionTypes';

const ExternalApplicationRole = props => {
  const { stepIndex, totalStepNumber, onSubmit } = props;

  const [text, setText] = useState('');
  const [selectedIndustry, setSelectedIndustry] = useState('');
  const [error, setError] = useState('');

  const [selectedItems, setSelectedItems] = useState([]);

  const { isMobile } = useIsMobile();

  const { t } = useTranslation('ExternalApplicationFeature');

  const { updateCandidate } = useCandidateProfileMutation(props);

  const { positionTypes, loadingPositionTypes } = usePositionTypes();

  const industries = OPTIONS_INDUSTRY_SELECTION_RADIO.map(industry => ({
    icon: industry.value === 'hotel' ? <FOHHotelIcon /> : <FOHRestaurantIcon />,
    label: t(industry.label),
    value: industry.value
  }));

  const naiveFilter = item =>
    (toLower(text).includes(toLower(item.label)) ||
      toLower(item.label).includes(toLower(text)) ||
      !text) &&
    ((selectedIndustry === 'hotel' && item.value.includes('hotel_')) ||
      (selectedIndustry === 'restaurant' && !item.value.includes('hotel_')) ||
      !selectedIndustry);

  const handleSubmit = async () => {
    const selectedPositions = selectedItems
      .filter(item => item.value !== null && item.value !== undefined)
      .map(item => item.value);

    const [resp] = await handle(
      updateCandidate({
        variables: {
          positions: selectedPositions
        }
      })
    );

    const err = path(['data', 'mutateCandidate', 'errors'], resp);

    if (resp || !err) onSubmit();
    else setError('Error submitting form');
  };

  useEffect(() => {
    setError(length(selectedItems) > 5 ? t('selectOnly5Positions') : '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  return (
    <FOHView
      style={{
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <FOHFixedHeightScrollView isMobile={isMobile} heightToDecrease={140}>
        <FOHView
          style={{
            width: '100%',
            maxWidth: isMobile ? '100%' : MAX_SCREEN_WIDTH,
            paddingHorizontal: 20
          }}
        >
          <FOHExternalApplicationHeader
            applicationTitle={t('applicationTitle')}
            applicationDetail={t('applicationDetail')}
            applicationTotalNumber={totalStepNumber}
            applicationNumber={stepIndex}
          />
        </FOHView>
        <FOHView
          style={{
            width: '100%',
            maxWidth: isMobile ? '100%' : MAX_SCREEN_WIDTH
          }}
        >
          {loadingPositionTypes ? (
            <FOHCircleLoader />
          ) : (
            <FOHMultiSelectSearchForm
              mobile={isMobile}
              formTitle={t('CandidatePositionFeature:positionSubTitle')}
              formDetailLabel={t('selectPositionsDetail')}
              options={industries}
              selectedIndustry={selectedIndustry}
              setSelectedIndustry={selectedInd =>
                selectedIndustry === selectedInd
                  ? setSelectedIndustry()
                  : setSelectedIndustry(selectedInd)
              }
              onChangeSearch={setText}
              mergeSections={true}
              noFreeText={true}
              searchValue={text}
              searchPlaceholder={t('common:search')}
              truncationLimit={2}
              clearSearch={() => {
                setText('');
                setSelectedItems(['']);
              }}
              sections={[
                {
                  title: 'positions',
                  items: positionTypes.filter(naiveFilter)
                }
              ]}
              selectedItems={selectedItems}
              error={error}
              selectItem={item => {
                setText('');
                const itemsWithoutDuplicates = selectedItems.filter(
                  oldItem => prop('value', oldItem) !== prop('value', item)
                );
                const hasItem = !!findById(
                  prop('value', item),
                  selectedItems,
                  'value'
                );

                // add and remove items
                hasItem
                  ? setSelectedItems(itemsWithoutDuplicates)
                  : setSelectedItems([...selectedItems, item]);
              }}
              handleSubmit={handleSubmit}
            ></FOHMultiSelectSearchForm>
          )}
        </FOHView>
        <FOHSpace />
      </FOHFixedHeightScrollView>
      <FOHView
        style={{
          width: '100%',
          maxWidth: isMobile ? '100%' : MAX_SCREEN_WIDTH,
          marginBottom: isMobile ? 0 : 10
        }}
      >
        <FOHFormFlowButtons
          isMobile={isMobile}
          nextButtonlabel={t('common:next')}
          onPressNextButton={handleSubmit}
          isNextDisabled={!!error}
        />
      </FOHView>
    </FOHView>
  );
};

export default ExternalApplicationRole;
