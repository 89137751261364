import React, { useState, useRef, useEffect } from 'react';

import { compose, path, length, prop } from 'ramda';
import { withTranslation } from 'react-i18next';

import {
  FOHView,
  FOHBannerType,
  FOHAccountInformationScreen
} from '../../components';
import {
  useMutateProfileImageMutation,
  useRefreshSbTokenMutation
} from '../../graphql/generated';
import { handle, withIsMobile, handleMutation } from '../../utils';
import {
  CANDIDATE_ACCOUNT_MENU_PATH,
  COMMUTE_PREFERENCES_DROPDOWN,
  CANDIDATE_ACCOUNT_INFO_PATH,
  CANDIDATE_ACCOUNT_DELETE
} from '../../constants';
import { useNavigation } from '../../utils/navigation';

import { useBanner } from '../Navigation/useBanner';

import { useCandidateProfileMutation } from './useCandidateProfileMutation';

const AccountInformationScreen = props => {
  const { navigateTo, location: navLocation } = useNavigation();

  const { me, updateCandidateWithPhoto, updateCandidate } =
    useCandidateProfileMutation();
  const [mutateProfileImage] = useMutateProfileImageMutation();
  const [refreshSBUser] = useRefreshSbTokenMutation();

  const reader = new FileReader();

  const [firstName, setFirstName] = useState(path(['firstName'], me));
  const [lastName, setLastName] = useState(path(['lastName'], me));
  const [phone, setPhone] = useState(path(['phone'], me));
  const [email, setEmail] = useState(path(['email'], me));

  const [zip, setZip] = useState(
    path(['candidateProfile', 'addressPostalCode'], me)
  );
  const [commuteTime, setCommuteTime] = useState(
    path(['candidateProfile', 'commutePreference'], me)
  );

  const [photo, setPhoto] = useState('');

  const [fileToUpload, setFileToUpload] = useState(null);
  const [error, setError] = useState();

  const { t } = props;

  const inputFile = useRef(null);

  reader.onload = e => {
    const blob = e.target.result;
    setPhoto(blob);
  };

  useEffect(() => {
    const userPhoto = prop('profileImage', me);
    if (prop('candidateProfile', me)) {
      setFirstName(path(['firstName'], me));
      setLastName(path(['lastName'], me));
      setPhone(path(['phone'], me));
      setEmail(path(['email'], me));
      setZip(path(['candidateProfile', 'addressPostalCode'], me));
      setCommuteTime(path(['candidateProfile', 'commutePreference'], me));
      userPhoto && setPhoto(userPhoto);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prop('candidateProfile', me), prop('profileImage', me)]);

  // Dirty state based on any information changed
  const [dirty, setDirty] = useState(false);
  useEffect(() => {
    setDirty(
      firstName !== path(['candidateProfile', 'firstName'], me) || // TODO: is this right?
        lastName !== path(['candidateProfile', 'lastName'], me) || // TODO: is this right?
        phone !== path(['candidateProfile', 'phone'], me) || // TODO: if the above are not right, this needs to change
        email !== path(['email'], me) ||
        zip !== path(['candidateProfile', 'addressPostalCode'], me) ||
        commuteTime !== path(['candidateProfile', 'commutePreference'], me) ||
        fileToUpload
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstName, lastName, phone, email, zip, fileToUpload, commuteTime]);

  const hasBack =
    navLocation.pathname === CANDIDATE_ACCOUNT_INFO_PATH &&
    !!path(['candidateProfile', 'onboardingCompletedAt'], me);

  const { setBanner } = useBanner();

  return (
    <>
      {/* make invisible input to trigger uploads */}
      <input
        type="file"
        id="file"
        accept="image/png, image/jpeg, image/jpg"
        onChange={e => {
          const file = e.target.files[0];
          file && setFileToUpload(file);
          file && reader.readAsDataURL(file);
        }}
        ref={inputFile}
        style={{ display: 'none' }}
      />
      <FOHView
        style={{
          alignItems: 'center'
        }}
      >
        <FOHAccountInformationScreen
          photo={photo}
          fullName={`${path(['firstName'], me)} ${path(['lastName'], me)}`}
          uploadLabel={t('UploadPhotoFeature:uploadPhoto')}
          pickPhoto={() => {
            inputFile && inputFile.current && inputFile.current.click();
          }}
          title={t('accountTitle')}
          submitLabel={t('submitLabel')}
          firstName={firstName}
          firstNamePlaceholder={t('RegisterFeature:firstName')}
          setFirstName={setFirstName}
          lastName={lastName}
          lastNamePlaceholder={t('RegisterFeature:lastName')}
          setLastName={setLastName}
          phoneNumber={phone}
          largeHeader={!props.isMobile}
          error={error}
          disabledSubmit={
            !firstName ||
            !lastName ||
            !zip ||
            !email ||
            !phone ||
            !commuteTime ||
            !dirty
          }
          phoneNumberPlaceholder={t('RegisterFeature:phoneNumber')}
          setPhoneNumber={setPhone}
          email={email}
          emailPlaceholder={t('RegisterFeature:email')}
          setEmail={setEmail}
          zip={zip}
          zipPlaceholder="Zip"
          setZip={value => {
            if (length(value) > 5) {
              return;
            }
            setZip(value);
          }}
          commuteTimeOptions={COMMUTE_PREFERENCES_DROPDOWN.map(time => ({
            label: t(time.label),
            value: time.value
          }))}
          commuteTime={commuteTime}
          commuteTimePlaceholder={t(
            'LocationFeature:desiredCommuteTimePlaceholder'
          )}
          setCommuteTime={setCommuteTime}
          handleSubmit={async () => {
            const [photoRes, imageCreateError] = fileToUpload
              ? await handleMutation(
                  mutateProfileImage({
                    variables: {
                      fileType: fileToUpload.type,
                      fileName: fileToUpload.name
                    }
                  })
                )
              : [undefined, undefined];

            const [, imageUploadError] = fileToUpload
              ? await handle(
                  fetch(
                    path(['mutateProfileImage', 'imageUploadUrl'], photoRes),
                    {
                      method: 'PUT',
                      body: fileToUpload
                    }
                  )
                )
              : [undefined, undefined];

            if (imageUploadError || imageCreateError) return;

            const userInfo = {
              firstName,
              lastName,
              email,
              phone,
              addressPostalCode: zip,
              commutePreference: commuteTime
            };

            const photoId = path(
              ['mutateProfileImage', 'profileImage', 'rowId'],
              photoRes
            );

            setError(null);

            const [response, errors] = await handleMutation(
              photoId
                ? updateCandidateWithPhoto({
                    variables: {
                      ...userInfo,
                      profileImageId: photoId
                    }
                  })
                : updateCandidate({
                    variables: {
                      ...userInfo
                    }
                  })
            );

            if (response && !errors) {
              if (prop('sendbirdAccessToken', me)) {
                await handleMutation(refreshSBUser());
              }
              setBanner({
                bannerType: FOHBannerType.SUCCESS,
                bannerMessage: t('common:success')
              });
              setDirty(false);
              navigateTo(CANDIDATE_ACCOUNT_MENU_PATH);
            } else {
              setError({
                ...errors,
                email:
                  prop('email', errors) ||
                  (errors?.message?.includes('Duplicate entry') &&
                    t('common:duplicateEntry')),
                firstName: prop('first_name', errors),
                lastName: prop('last_name', errors)
              });
            }
          }}
          goBack={
            hasBack ? () => navigateTo(CANDIDATE_ACCOUNT_MENU_PATH) : null
          }
          onDeleteAccountPress={() => navigateTo(CANDIDATE_ACCOUNT_DELETE)}
          deleteAccountLabel={t('deleteAccountLabel')}
          deleteButtonProps={{
            touchableStyle: {
              marginLeft: 'auto'
            }
          }}
        />
      </FOHView>
    </>
  );
};

export default compose(
  withIsMobile,
  withTranslation('AccountInfoFeature')
)(AccountInformationScreen);
