import React, { useEffect } from 'react';

import { LOGIN_URL } from '../../constants';
import { useNavigation } from '../../utils/navigation';

import { useLogout } from '../SignIn/useLogout';

const Screen403 = () => {
  const { logoutWipeCache } = useLogout();
  const { navigateTo } = useNavigation();

  useEffect(() => {
    logoutWipeCache(() => {
      navigateTo(LOGIN_URL);
    });
  });

  return <></>;
};

export default Screen403;
