import React from 'react';

import styled from 'styled-components/native';

import { FOHInverseButton, FOHView, withError } from '../../ingredients';

import { FOHVideoPlayerProps, FOHVideoPlayer } from './FOHVideoPlayer';

export interface FOHVideoInputProps extends FOHVideoPlayerProps {
  uploadLabel: string;
}

const VideoInput = (props: FOHVideoInputProps) => (
  <>
    <Container>
      <FOHVideoPlayer
        //@ts-ignore pickVideo
        pickVideo={props?.pickVideo}
        VideoComponent={props.VideoComponent}
        video={props?.video}
        height={170}
      />
    </Container>
    <FOHView style={{ paddingTop: 18 }} />
    {props.pickVideo && (
      <FOHInverseButton onPress={props.pickVideo} title={props.uploadLabel} />
    )}
  </>
);

const Container = styled(FOHView)`
  width: 327px;
  height: 170px;
`;

export const FOHVideoInput = withError(VideoInput);
