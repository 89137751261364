import React from 'react';

import { Image } from 'react-native';
import styled from 'styled-components/native';

import {
  FOHSpace,
  FOHSmallDetailLabel,
  FOHView,
  FOHHeaderH2,
  FOHLabelBold,
  FOHColors
} from '../../ingredients';

import { FOHSelectItem, FOHSelect, FOHSelectItemProps } from '..';

export interface FOHSendVideoScreeningFormProps {
  children?: React.ReactNode;
  titleDecorationImage?: string;
  header: string;
  candidateName: string;
  detailLabel: string;
  subTitle?: string;

  isMobile: boolean;

  locations?: Array<FOHSelectItemProps>;
  locationPlaceholder?: string;
  selectedLocation?: string;
  setSelectedLocation?: (value: string) => void;
  locationError?: string;

  postions?: Array<FOHSelectItemProps>;
  positionPlaceholder?: string;
  selectedPosition: string;
  setSelectedPosition?: (value: string) => void;
  positionError?: string;
}

export const FOHSendVideoScreeningForm = (
  props: FOHSendVideoScreeningFormProps
) => {
  return (
    <FOHView style={{ maxWidth: 716 }} testID={'FOHSendVideoScreeningForm'}>
      <FOHView
        style={{ flexDirection: 'row', alignItems: 'center', maxWidth: 716 }}
      >
        <FOHImage
          mobile={props.isMobile}
          resizeMode="contain"
          source={{ uri: props.titleDecorationImage }}
        />
        <FOHView style={{ width: '100%', alignItems: 'center' }}>
          <FOHView
            style={{
              maxWidth: props.isMobile ? '70%' : '100%'
            }}
          >
            <FOHHeaderH2
              style={{
                alignItems: 'center',
                textAlign: 'left',
                fontWeight: 400
              }}
            >
              {props.header}{' '}
              <FOHHeaderH2
                style={{
                  color: FOHColors.SILVER_POLISH,
                  alignItems: 'center',
                  textAlign: 'left',
                  fontWeight: 400
                }}
              >
                {props.candidateName && `· ${props.candidateName} ·`}
              </FOHHeaderH2>
            </FOHHeaderH2>
            <FOHSpace />
            <FOHSmallDetailLabel>{props.detailLabel}</FOHSmallDetailLabel>
          </FOHView>

          <FOHSpace />
        </FOHView>
      </FOHView>

      <FOHSpace />

      <FOHView
        style={{
          margin: 5,
          padding: 32,
          paddingTop: 0,
          borderRadius: 24,
          backgroundColor: '#FCFCFC'
        }}
      >
        <FOHLabelBold>{props.subTitle}</FOHLabelBold>
        <FOHSpace />
        {props.locations && props.setSelectedLocation && (
          <FOHSelect
            placeholder={props.locationPlaceholder || ''}
            selectedValue={props.selectedLocation}
            onValueChange={(value: string) =>
              props.setSelectedLocation && props.setSelectedLocation(value)
            }
            neverEmpty={true}
            error={props.locationError}
          >
            {props.locations.map(option => (
              <FOHSelectItem
                key={option.value}
                value={option.value}
                label={option.label}
              />
            ))}
          </FOHSelect>
        )}
        <FOHSpace />
        {props.postions && props.setSelectedPosition && (
          <FOHSelect
            placeholder={props.positionPlaceholder || ''}
            selectedValue={props.selectedPosition}
            onValueChange={(value: string) =>
              props.setSelectedPosition && props.setSelectedPosition(value)
            }
            neverEmpty={true}
            error={props.positionError}
          >
            {props.postions.map(option => (
              <FOHSelectItem
                key={option.value}
                value={option.value}
                label={option.label}
              />
            ))}
          </FOHSelect>
        )}
        <FOHSpace />
        {props.children}
      </FOHView>
    </FOHView>
  );
};

const FOHImage = styled(Image)<{ mobile?: boolean }>`
  width: ${(p: any) => (p.mobile ? '80px' : '185px')};
  height: ${(p: any) => (p.mobile ? '80px' : '185px')};
  borderwidth: 1;
  border-color: #eeeeee;
`;
