import styled from 'styled-components/native';

export const ActionBarWrapper = styled.View`
  align-items: center;
  background-color: #fff;
  bottom: 0;
  flex-direction: row;
  gap: 10px;
  height: 72px;
  justify-content: space-around;
  margin: 0 10px;
  padding-left: 92px;
  padding-right: 168px;
  position: fixed;
  width: 100%;
`;
