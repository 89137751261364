import { path, defaultTo, isNil } from 'ramda';
import { WithT } from 'i18next';

import { Position } from '../types/common';
import { SalaryUnitChoices } from '../components';

export const toUSD = (number: number) =>
  Intl.NumberFormat('us', {
    style: 'currency',
    currency: 'USD'
  }).format(defaultTo(0, number));

export const positionToSalaryText = (position: Position, { t }: WithT) => {
  const baseSalaryMin = path(['baseSalaryMin'], position) as unknown as number;
  const baseSalaryMax = path(['baseSalaryMax'], position) as unknown as number;

  if (
    isNil(baseSalaryMin) ||
    isNil(baseSalaryMax) ||
    baseSalaryMax === 0 ||
    baseSalaryMin === 0
  ) {
    return '';
  }

  return `${toUSD(baseSalaryMin)} - ${toUSD(baseSalaryMax)}  ${t(
    `JobApplicationsFeature:${
      path(['baseSalaryUnitText'], position) === SalaryUnitChoices.YEAR
        ? 'annualSalary'
        : path(['baseSalaryUnitText'], position) ===
          SalaryUnitChoices.HOURLY_TIPS
        ? 'hourlyTipsWage'
        : 'hourlyWage'
    }`
  )}`;
};

export const toUSDdString = (value: number): string => {
  return value.toLocaleString('en-US', { style: 'currency', currency: 'usd' });
};
