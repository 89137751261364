import React, { useEffect, useState } from 'react';

import { defaultTo, path, prop } from 'ramda';
import { Navigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  FOHLabel,
  FOHLabelBold,
  FOHLabelSmall,
  FOHColors,
  FOHBanner,
  FOHBannerType,
  FOHSpace,
  FOHSpaceLarger,
  FOHSpaceSmall,
  FOHSpaceHuge,
  FOHView,
  FOHImage,
  FOHScrollView,
  FOHButton,
  FOHSearchableSelectDropdown,
  FOHBoostIcon,
  FOHCalendarIcon,
  FOHCompensationIcon,
  FOHEditMarker,
  FOHLocationPin,
  FOHRequiredMark,
  FOHToolBoxIcon,
  FOHButtonBordered,
  FOHTextButton,
  FOHTextField,
  FOHDivider,
  FOHCurrencyInput,
  FOHProgressBar,
  FOHHeaderH2,
  FOHSmallDetailLabel,
  FOHHeaderH3,
  FOHCalendarPicker,
  FOHLabeledRadio,
  FOHMoneySelector
} from '../../components';
import indeed_logo from '../../images/indeed_logo.svg';
import ziprecruiter_logo from '../../images/ziprecruiter_logo.svg';
import {
  GetCampaignQuery,
  MutateJobBoardCampaignMutationVariables,
  useGetCampaignQuery,
  useMutateJobBoardCampaignMutation,
  GetCampaignsDataDocument,
  useSoftDeleteJobBoardCampaignMutation,
  useMutateEndCampaignMutation,
  GetCampaignDocument,
  useGetIndeedBillingStatusLazyQuery
} from '../../graphql/generated';
import {
  unwrapEdgesAt,
  handleMutation,
  toUSDdString,
  useIsMobile,
  toFullDate
} from '../../utils';
import { CAMPAIGN_HOME, FEED, getAppBaseUrl } from '../../constants';
import { useNavigation } from '../../utils/navigation';

import { useSelectPositionForSelectedLocationState } from '../EmployerPositions/useSelectPositionForSelectedLocationState';
import { useCheckout, withStripe } from '../Billing';
import { useBanner } from '../Navigation';
import { LogoLoader } from '../Loading/LogoLoader';
import { useGetMeData } from '../SignIn';

import CampaignRecommendationAndEstimator from './CampaignPredictionsAndEstimator';
import CampaignConfirmationModal from './CampaignConfirmationModal';
import { PLATFORM_TYPES } from './constants';

interface CampaignDetailsProps {}

interface SubComponentProps {
  headerLabel: string;
  subHeaderLabel: string;
}

enum CAMPAIGN_TYPES {
  ONE_TIME = 'ONE_TIME',
  MONTHLY = 'MONTHLY'
}

interface CampaignHeaderProps extends SubComponentProps {
  campaignTitle: string;
  displayPositionLocationName: string;
  formattedAddress: string;
  positionsLabel?: string;
  isEditCampaignName?: boolean;
  setIsEditCampaignName?: (isEditCampaignName: boolean) => void;
  isCampaignCompleted?: boolean;
}

interface CampaignScheduleProps extends SubComponentProps {
  startDate?: Date;
  setStartDate: (date: Date) => void;
  endDate?: Date;
  setEndDate: (date: Date) => void;
  campaignType: keyof typeof CAMPAIGN_TYPES;
  setCampaignType: (string: keyof typeof CAMPAIGN_TYPES) => void;
  oneTimeCampaignLabel: string;
  monthlyCampaignLabel: string;
  startDateLabel: string;
  endDateLabel: string;
}

interface CampaignBudgetProps extends SubComponentProps {
  budget: string;
  setBudget: (budget: string) => void;
  budgetError: string;
  setBudgetError: (budget: string) => void;
}

interface CampaignNamePositionProps {
  campaignId: string;
  campaignTitle: string;
  locationId: string;
  positionId: string;
  isEditCampaignName: boolean;
  setIsEditCampaignName: (isEditCampaignName: boolean) => void;
  isCampaignActive?: boolean;
}

interface CampaignScheduleViewProps {
  headerLabel: string;
  startDate?: Date;
  endDate?: Date;
  startDateLabel: string;
  endDateLabel: string;
}

interface CampaignBudgetViewProps {
  headerLabel: string;
  budget: number;
  amount: number;
  spendLabel: string;
  platform: string;
}

interface CampaignResultViewProps {
  headerLabel: string;
  clicks: number;
  applies: number;
  avgCpa: number;
  clickLabel: string;
  applyLabel: string;
  avgCPALabel: string;
}

const CampaignHeader = (props: CampaignHeaderProps) => {
  const {
    campaignTitle,
    displayPositionLocationName,
    formattedAddress,
    headerLabel,
    isEditCampaignName,
    setIsEditCampaignName,
    isCampaignCompleted
  } = props;

  return (
    <FOHView>
      <FOHHeaderH2 style={{ textAlign: 'left', paddingBottom: 24 }}>
        {headerLabel}
      </FOHHeaderH2>
      <FOHView>
        <FOHTextButton
          title={campaignTitle}
          disabled={false}
          icon={() =>
            !isEditCampaignName &&
            !isCampaignCompleted && (
              <FOHEditMarker
                style={{
                  tintColor: FOHColors.BLACK,
                  left: 5,
                  width: 12,
                  height: 12
                }}
              />
            )
          }
          reverseIcon={true}
          onPress={() => {
            setIsEditCampaignName && setIsEditCampaignName(!isEditCampaignName);
          }}
          touchableStyle={{ width: 'fit-content' }}
          testID="CampaignDetails-edit-campaign-name"
        />
        <FOHView>
          <FOHLabel
            style={{
              color: FOHColors.GRAYSCALE_900,
              lineHeight: 22,
              minHeight: 24
            }}
          >
            {displayPositionLocationName}
          </FOHLabel>
          <FOHSmallDetailLabel
            numberOfLines={1}
            style={{
              color: FOHColors.GRAYSCALE_600
            }}
          >
            {formattedAddress}
          </FOHSmallDetailLabel>
        </FOHView>
      </FOHView>
    </FOHView>
  );
};

const CampaignSchedule = (props: CampaignScheduleProps) => {
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    headerLabel,
    subHeaderLabel,
    oneTimeCampaignLabel,
    monthlyCampaignLabel,
    startDateLabel,
    endDateLabel
  } = props;

  const [isStartDateCalendarOpen, setStartDateCalendarOpen] = useState(false);
  const [isEndDateCalendarOpen, setEndDateCalendarOpen] = useState(false);

  return (
    <FOHView style={{ zIndex: 2 }}>
      <FOHHeaderH3 style={{ textAlign: 'left' }}>{headerLabel}</FOHHeaderH3>
      <FOHLabel>{subHeaderLabel}</FOHLabel>
      <FOHSpace />
      <FOHView style={{ zIndex: 2 }}>
        <FOHLabeledRadio
          selected={props.campaignType === CAMPAIGN_TYPES.ONE_TIME}
          label={oneTimeCampaignLabel}
          setSelected={() => {
            props.setCampaignType(CAMPAIGN_TYPES.ONE_TIME);
          }}
        />

        {props.campaignType === CAMPAIGN_TYPES.ONE_TIME && (
          <FOHView
            style={{ flexDirection: 'row', paddingLeft: 30, paddingTop: 12 }}
          >
            <FOHCalendarPicker
              open={isStartDateCalendarOpen}
              setOpen={setStartDateCalendarOpen}
              value={startDate}
              setValue={setStartDate}
              placeholder={startDateLabel}
              width="98%"
              calendarCompProps={{
                tileDisabled: ({ date }: any) => {
                  return (
                    new Date(date) <
                    new Date(new Date().setDate(new Date().getDate() - 1))
                  );
                }
              }}
            />
            <FOHSpace />
            <FOHCalendarPicker
              open={isEndDateCalendarOpen}
              setOpen={setEndDateCalendarOpen}
              value={endDate}
              setValue={setEndDate}
              placeholder={endDateLabel}
              width="98%"
              calendarCompProps={{
                tileDisabled: ({ date }: any) => {
                  return new Date(date) < startDate!;
                }
              }}
            />
          </FOHView>
        )}
        <FOHSpace />
      </FOHView>
      <FOHSpace />

      <FOHLabeledRadio
        selected={props.campaignType === CAMPAIGN_TYPES.MONTHLY}
        label={monthlyCampaignLabel}
        setSelected={() => {
          props.setCampaignType(CAMPAIGN_TYPES.MONTHLY);
        }}
      />
    </FOHView>
  );
};

const CampaignBudget = React.memo((props: CampaignBudgetProps) => {
  const {
    budget,
    setBudget,
    headerLabel,
    subHeaderLabel,
    budgetError,
    setBudgetError
  } = props;
  const { t } = useTranslation('CampaignsFeature');

  useEffect(() => {
    if (parseFloat(budget.replace('$', '').replace(',', '')) < 50) {
      setBudgetError(t('minInitialBudgetError'));
    } else {
      setBudgetError('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budget]);

  return (
    <FOHView>
      <FOHHeaderH3 style={{ textAlign: 'left' }}>{headerLabel}</FOHHeaderH3>
      <FOHLabel>{subHeaderLabel}</FOHLabel>
      <FOHSpace />
      <FOHView style={{ maxWidth: 294 }}>
        <FOHCurrencyInput
          error={budgetError}
          onChangeText={(text: string) => {
            setBudget(text);
          }}
          value={budget}
          placeholder={t('yourBudget')}
        ></FOHCurrencyInput>
      </FOHView>
    </FOHView>
  );
});

const CampaignNamePosition = (props: CampaignNamePositionProps) => {
  const {
    campaignId,
    campaignTitle,
    locationId,
    positionId,
    isEditCampaignName,
    setIsEditCampaignName,
    isCampaignActive
  } = props;
  const { t } = useTranslation('CampaignsFeature');
  const [name, setName] = useState('');

  const [locationOpen, setLocationOpen] = useState(false);
  const [positionOpen, setPositionOpen] = useState(false);

  const [mutateJobBoardCampaign] = useMutateJobBoardCampaignMutation();

  const {
    selectedLocation,
    myLocations,
    setSelectedLocation,
    selectedPosition,
    myPositions,
    setSelectedPosition,
    locationSearch,
    positionSearch,
    setLocationSearch,
    setPositionSearch
  } = useSelectPositionForSelectedLocationState();

  const locationOptions = myLocations.map(loc => ({
    label: prop('displayName', loc),
    value: prop('id', loc),
    sublabel: prop('formattedAddress', loc)
  }));

  const positionOptions = myPositions.map(pos => ({
    label: prop('positionName', pos),
    value: prop('id', pos),
    sublabel: '',
    // prompt for upgrade label
    upgrade:
      path(['paymentStatus'], pos) !== 'SUBSCRIBED' &&
      path(['location', 'paymentStatus'], pos) !== 'SUBSCRIBED',
    locationIdToUpgrade: path(['location', 'id'], pos)
  }));

  const saveCampaignNamePosition = async () => {
    await handleMutation(
      mutateJobBoardCampaign({
        variables: {
          id: campaignId,
          name: name,
          positionId: selectedPosition
        }
      })
    );
    setIsEditCampaignName(!isEditCampaignName);
  };

  useEffect(() => {
    setName(campaignTitle);
    if (locationId) {
      setSelectedLocation(locationId);
    }
    if (positionId) {
      setSelectedPosition(positionId);
    }
    //eslint-disable-next-line
  }, [campaignTitle, positionId, locationId]);

  return (
    <FOHView
      style={{ height: isEditCampaignName ? undefined : '100vh' }}
      testID="CampaignNamePosition"
    >
      <FOHHeaderH3 style={{ textAlign: 'left' }}>
        {isCampaignActive
          ? t('editCampaignNameLabel')
          : t('editCampaignNamePositionLabel')}
      </FOHHeaderH3>
      <FOHSpace />
      <FOHView style={{ zIndex: 2 }}>
        <FOHView style={{ zIndex: 3 }}>
          <FOHView style={{ maxWidth: 400 }}>
            <FOHView style={{ flexDirection: 'row', alignItems: 'center' }}>
              <FOHLabelBold>{t('campaignNameLabel')}</FOHLabelBold>
              <FOHRequiredMark
                style={{
                  alignSelf: 'flex-start',
                  marginTop: 1
                }}
              />
            </FOHView>
          </FOHView>
          <FOHTextField
            onChangeText={setName}
            value={name}
            placeholder={t('campaignNamePlaceholder')}
            required={true}
            style={{ width: 400 }}
          />
        </FOHView>

        <FOHSpace />
        {!isCampaignActive && (
          <FOHView style={{ width: 400, zIndex: 2 }}>
            <FOHView style={{ flexDirection: 'row', alignItems: 'center' }}>
              <FOHLabelBold>{t('selectPositionLabel')}</FOHLabelBold>
              <FOHRequiredMark
                style={{
                  alignSelf: 'flex-start',
                  marginTop: 1
                }}
              />
            </FOHView>
            <FOHSpace />
            <FOHView style={{ zIndex: 3, width: '100%' }}>
              <FOHSearchableSelectDropdown
                open={locationOpen}
                setOpen={(open: any) => {
                  setPositionOpen(false);
                  setLocationOpen(open);
                }}
                selectedValue={selectedLocation}
                selectedLabel={undefined}
                showLabel="show"
                onSearchChange={setLocationSearch}
                searchValue={locationSearch}
                multiSelect={false}
                searchPlaceholder={t('common:search')}
                onValueChange={(value: any) => {
                  setSelectedLocation(value);
                }}
                options={locationOptions}
                fieldLabel="location"
                icon={() => <FOHLocationPin />}
                style={{ width: '100%', backgroundColor: FOHColors.WHITE }}
              ></FOHSearchableSelectDropdown>
            </FOHView>
            <FOHSpace />
            <FOHView style={{ zIndex: 2, width: '100%' }}>
              <FOHSearchableSelectDropdown
                open={positionOpen}
                style={{ width: '100%', backgroundColor: FOHColors.WHITE }}
                setOpen={(open: any) => {
                  setPositionOpen(open);
                  setLocationOpen(false);
                }}
                selectedValue={selectedPosition}
                multiSelect={false}
                showLabel={t('Show')}
                onSearchChange={setPositionSearch}
                searchValue={positionSearch}
                searchPlaceholder={t('common:search')}
                onValueChange={(value: any) => {
                  setSelectedPosition(value);
                }}
                options={positionOptions}
                fieldLabel="position"
                upgradeLabel="upgrade"
                onPressUpgrade={() => {}}
                icon={() => (
                  <FOHToolBoxIcon
                    style={{
                      width: 14,
                      height: 14,
                      tintColor: FOHColors.PACIFIC_BLUE
                    }}
                  />
                )}
              ></FOHSearchableSelectDropdown>
            </FOHView>
          </FOHView>
        )}
      </FOHView>
      <FOHSpaceLarger />
      <FOHView
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%'
        }}
      >
        <FOHButtonBordered
          color={FOHColors.WHITE}
          style={{ width: 151 }}
          title={t('common:cancel')}
          onPress={() => {
            setIsEditCampaignName && setIsEditCampaignName(!isEditCampaignName);
          }}
        />
        <FOHView style={{ marginLeft: 'auto', flexDirection: 'row' }}>
          <FOHButton
            style={{
              width: 151
            }}
            title={t('common:save')}
            onPress={saveCampaignNamePosition}
          />
        </FOHView>
      </FOHView>
    </FOHView>
  );
};

const CampaignScheduleView = (props: CampaignScheduleViewProps) => {
  return (
    <FOHView style={{ flexDirection: 'row' }}>
      <FOHCalendarIcon
        style={{
          tintColor: FOHColors.PRIMARY_TEAL_P1,
          top: -2,
          width: 20,
          height: 19
        }}
      />
      <FOHView style={{ marginLeft: 10 }}>
        <FOHLabelBold>{props.headerLabel}</FOHLabelBold>
        <FOHView
          style={{
            flexDirection: 'row'
          }}
        >
          <FOHLabelBold style={{ fontSize: 14 }}>
            {props.startDateLabel}
          </FOHLabelBold>
          <FOHSpace />
          {props.startDate && (
            <FOHLabelSmall>
              {toFullDate(props.startDate.toISOString(), 'MMM/dd/yyyy')}
            </FOHLabelSmall>
          )}
        </FOHView>
        <FOHView
          style={{
            flexDirection: 'row'
          }}
        >
          <FOHLabelBold style={{ fontSize: 14 }}>
            {props.endDateLabel}
          </FOHLabelBold>
          <FOHSpace />
          {props.endDate && (
            <FOHLabelSmall>
              {toFullDate(props.endDate.toISOString(), 'MMM/dd/yyyy')}
            </FOHLabelSmall>
          )}
        </FOHView>
      </FOHView>
    </FOHView>
  );
};

const CampaignBudgetView = (props: CampaignBudgetViewProps) => {
  return (
    <FOHView style={{ flexDirection: 'row' }}>
      <FOHCompensationIcon
        style={{
          tintColor: FOHColors.PRIMARY_TEAL_P1,
          top: -2,
          width: 20,
          height: 19
        }}
      />
      <FOHView style={{ marginLeft: 10 }}>
        <FOHLabelBold>{props.headerLabel}</FOHLabelBold>
        <FOHView
          style={{
            flexDirection: 'row'
          }}
        >
          <FOHLabelBold style={{ fontSize: 14 }}>
            {props.spendLabel}
          </FOHLabelBold>
          <FOHSpace />
          {props.budget && (
            <FOHLabelSmall>
              {toUSDdString(props.budget)} / {toUSDdString(props.amount)}
            </FOHLabelSmall>
          )}
        </FOHView>
        {props.platform === PLATFORM_TYPES.ZipRecruiter.toUpperCase() ? (
          <></>
        ) : (
          <FOHProgressBar
            bgColor="#009DC1"
            completed={(props.budget / props.amount) * 100}
          />
        )}
      </FOHView>
    </FOHView>
  );
};

const CampaignResultView = (props: CampaignResultViewProps) => {
  return (
    <FOHView style={{ flexDirection: 'row' }}>
      <FOHBoostIcon
        style={{
          tintColor: FOHColors.PRIMARY_TEAL_P1,
          top: -2,
          width: 20,
          height: 19
        }}
      />
      <FOHView style={{ marginLeft: 10 }}>
        <FOHLabelBold>{props.headerLabel}</FOHLabelBold>
        <FOHView
          style={{
            flexDirection: 'row'
          }}
        >
          <FOHLabelBold style={{ fontSize: 14 }}>
            {props.clickLabel}
          </FOHLabelBold>
          <FOHSpace />
          {props.clicks && <FOHLabelSmall>{props.clicks}</FOHLabelSmall>}
        </FOHView>
        <FOHView
          style={{
            flexDirection: 'row'
          }}
        >
          <FOHLabelBold style={{ fontSize: 14 }}>
            {props.applyLabel}
          </FOHLabelBold>
          <FOHSpace />
          {props.applies && <FOHLabelSmall>{props.applies}</FOHLabelSmall>}
        </FOHView>
        <FOHView
          style={{
            flexDirection: 'row'
          }}
        >
          <FOHLabelBold style={{ fontSize: 14 }}>
            {props.avgCPALabel}
          </FOHLabelBold>
          <FOHSpace />
          {props.avgCpa && <FOHLabelSmall>{props.avgCpa}</FOHLabelSmall>}
        </FOHView>
      </FOHView>
    </FOHView>
  );
};

const CampaignDetails = (props: CampaignDetailsProps) => {
  const { t } = useTranslation('CampaignsFeature');
  const { modal, campaignId } = useParams<{
    modal: string;
    campaignId: string;
  }>();

  const { navigateTo, goBack } = useNavigation();
  const { banner, setBanner } = useBanner();
  const { isMobile } = useIsMobile();

  const [state, setState] = useState({
    campaignType: CAMPAIGN_TYPES.ONE_TIME
  });

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const twoWeeks = new Date(tomorrow);
  twoWeeks.setDate(twoWeeks.getDate() + 14);

  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();

  const [budget, setBudget] = useState('');
  const [isEditCampaignName, setIsEditCampaignName] = useState(false);

  const [minBudgetError, setMinBudgetError] = useState('');

  const defaultAmount = 48;
  const amountIncrementBy = 16;
  const [zipAmount, setZipAmount] = useState(0);

  const [mutateJobBoardCampaign] = useMutateJobBoardCampaignMutation();
  const [softDeleteJobBoardCampaign] = useSoftDeleteJobBoardCampaignMutation();

  const campaignQuery = useGetCampaignQuery({
    skip: !campaignId,
    variables: {
      campaignId: campaignId!
    }
  });

  const campaignDetails = path(
    ['data', 'jobBoardCampaign'],
    campaignQuery
  ) as GetCampaignQuery['jobBoardCampaign'];

  const { data: me } = useGetMeData();
  const employerProfileId = path(['employerProfile', 'id'], me);

  const campaignEmployerProfileId = path(
    ['employer', 'id'],
    campaignDetails
  ) as string;

  const [endCampaign] = useMutateEndCampaignMutation();

  const positions = unwrapEdgesAt(['positions', 'edges'], campaignDetails);
  const amount = path(['amount'], campaignDetails) as number;
  const spent = path(['spent'], campaignDetails) as number;
  const clicks = path(['clicks'], campaignDetails) as number;
  const applies = path(['numApplies'], campaignDetails) as number;
  const avgCpa = path(['avgCpa'], campaignDetails) as number;
  const willStartOn = path(['willStartOn'], campaignDetails) as string;
  const willEndOn = path(['campaignWillEndOn'], campaignDetails) as string;
  const endedOn = path(['campaignEndedOn'], campaignDetails) as string;
  const isDraft = path(['isDraft'], campaignDetails) as string;
  const isCampaignActive = !isDraft;
  const isCampaignCompleted = endedOn && !isDraft;
  const campaignTitle =
    campaignDetails?.name && campaignDetails?.name.length > 0
      ? campaignDetails?.name
      : 'Untitled';

  const positionId = defaultTo('', path(['0', 'id'], positions));
  const positionRowId = defaultTo('', path(['0', 'rowId'], positions));
  const locationId = defaultTo('', path(['0', 'location', 'id'], positions));
  const isNewCampaign = !willStartOn && !willEndOn && !amount;
  const campaignPlatform = defaultTo(
    undefined,
    path(['platform'], campaignDetails)
  ) as string;
  const hasZipRecruiter = campaignPlatform
    ? campaignPlatform === PLATFORM_TYPES.ZipRecruiter.toUpperCase()
    : undefined;

  const [indeedBillingStatusQuery, { data }] =
    useGetIndeedBillingStatusLazyQuery({});

  const isIndeedCampaign =
    campaignPlatform === PLATFORM_TYPES.Indeed.toUpperCase() || false;

  const { redirectToStripeCheckoutForBoost } = useCheckout(props);

  const saveCampaign = async (
    fields?: MutateJobBoardCampaignMutationVariables
  ) => {
    let variables = {
      id: campaignId,
      amount: !hasZipRecruiter
        ? parseFloat(budget.slice(1).replace(',', '')) * 100
        : zipAmount * 100,
      ...fields
    };
    if (!hasZipRecruiter) {
      variables = Object.assign(variables, {
        willStartOn: startDate,
        campaignWillEndOn: endDate
      });
    }

    const [response, error] = await handleMutation(
      mutateJobBoardCampaign({
        variables,
        refetchQueries: [
          {
            query: GetCampaignsDataDocument,
            variables: { employer: employerProfileId }
          }
        ]
      })
    );

    if (error || !response) {
      if (!isNewCampaign) {
        setBanner({
          bannerType:
            FOHBannerType.DANGER as unknown as keyof typeof FOHBannerType,
          bannerMessage: error?.message || t('saveCampaignErrorLabel')
        });
      } else {
        setBanner({
          bannerType:
            FOHBannerType.DANGER as unknown as keyof typeof FOHBannerType,
          bannerMessage: error?.message || t('startCampaignErrorLabel')
        });
      }
    }

    if (response) {
      if (!isNewCampaign) {
        setBanner({
          bannerType:
            FOHBannerType.SUCCESS as unknown as keyof typeof FOHBannerType,
          bannerMessage: t('saveCampaignSuccessLabel')
        });
      } else {
        setBanner({
          bannerType:
            FOHBannerType.SUCCESS as unknown as keyof typeof FOHBannerType,
          bannerMessage: t('startCampaignSuccessLabel')
        });
      }
    }
  };

  const goToCampaignHome = () => {
    navigateTo(CAMPAIGN_HOME);
  };

  const handleStartCampaignPress = async () => {
    if (hasZipRecruiter) {
      await redirectToStripeCheckoutForBoost({
        url: `${getAppBaseUrl()}${CAMPAIGN_HOME}/${campaignId}`,
        daysToBoost: zipAmount / amountIncrementBy,
        // rowId for rest endpoint
        position: positionRowId,
        campaignId
      });
      await campaignQuery.refetch();
    } else {
      const billingConfirmed = path(
        ['getIndeedBillingStatus', 'isBillingConfirmed'],
        data
      );

      if (billingConfirmed) {
        await saveCampaign({ isDraft: false });
        goToCampaignHome();
      } else {
        setBanner({
          bannerType:
            FOHBannerType.DANGER as unknown as keyof typeof FOHBannerType,
          bannerMessage: t('billingConfirmLabel')
        });
      }
    }
  };

  const handleCancelPress = async () => {
    if (isNewCampaign) {
      await handleMutation(
        softDeleteJobBoardCampaign({
          variables: {
            campaignId: campaignId!
          }
        })
      );
    }
    goToCampaignHome();
  };

  const handleSaveAsDraftPress = async () => {
    await saveCampaign();
    setTimeout(() => {
      goToCampaignHome();
    }, 2000);
  };

  const handleEndCampaignPress = async () => {
    const [response, error] = await handleMutation(
      endCampaign({
        variables: {
          campaignId: campaignId!
        },
        refetchQueries: [
          {
            query: GetCampaignDocument,
            variables: { campaignId: campaignId }
          }
        ]
      })
    );
    navigateTo(`${CAMPAIGN_HOME}/${campaignId}`);
    if (error || !response) {
      setBanner({
        bannerType:
          FOHBannerType.DANGER as unknown as keyof typeof FOHBannerType,
        bannerMessage: error?.message || t('endCampaignErrorLabel')
      });
    } else {
      setBanner({
        bannerType:
          FOHBannerType.SUCCESS as unknown as keyof typeof FOHBannerType,
        bannerMessage: t('endCampaignSuccessLabel')
      });
    }
  };

  useEffect(() => {
    if (!campaignQuery.loading) {
      setBudget(
        amount === null || amount === 0
          ? toUSDdString(50)
          : toUSDdString(amount / 100)
      );
      setZipAmount(
        amount === null || amount === 0 ? defaultAmount : amount / 100
      );
      setStartDate(willStartOn === null ? today : new Date(willStartOn));
      setEndDate(willEndOn === null ? twoWeeks : new Date(willEndOn));

      //FETCH QUERY ONLY IF PLATFORM IS INDEED
      if (isIndeedCampaign) {
        indeedBillingStatusQuery();
      }
    }
  }, [campaignQuery]); //eslint-disable-line

  // Redirected to feed if location user or admin who try to access campaign detail page that not associated with
  if (
    employerProfileId &&
    campaignEmployerProfileId &&
    employerProfileId !== campaignEmployerProfileId
  ) {
    return <Navigate to={FEED} />;
  }

  return (
    <FOHScrollView
      style={{
        height: isMobile ? '82vh' : '100vh',
        width: isMobile ? undefined : '100%'
      }}
    >
      <FOHView style={{ height: '100vh' }}>
        {banner?.bannerMessage ? (
          //@ts-ignore FOHBanner
          <FOHBanner
            style={{ marginBottom: 30 }}
            {...banner}
            message={banner?.bannerMessage}
          />
        ) : (
          <></>
        )}
        {campaignQuery?.loading ? (
          <FOHView
            style={{
              height: '100%',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <LogoLoader />
          </FOHView>
        ) : (
          <>
            <FOHView>
              <FOHView
                style={{
                  flexDirection: isMobile ? 'column-reverse' : ' row',
                  justifyContent: 'space-between',
                  paddingHorizontal: 32,
                  zIndex: 2,
                  flexWrap: 'wrap'
                }}
              >
                <FOHView style={{ maxWidth: 700, zIndex: 2 }}>
                  <FOHSpace />
                  <CampaignHeader
                    campaignTitle={campaignTitle}
                    displayPositionLocationName={defaultTo(
                      '',
                      `${defaultTo(
                        '',
                        path(['0', 'positionName'], positions)
                      )}${defaultTo(
                        '',
                        ` - ${path(
                          ['0', 'location', 'displayName'],
                          positions
                        )}`
                      )}`
                    )}
                    formattedAddress={defaultTo(
                      '',
                      path(['0', 'location', 'formattedAddress'], positions)
                    )}
                    headerLabel={t('mainHeaderLabel')}
                    subHeaderLabel={t('mainSubHeaderLabel')}
                    isEditCampaignName={isEditCampaignName}
                    setIsEditCampaignName={setIsEditCampaignName}
                    isCampaignCompleted={isCampaignCompleted as boolean}
                  />

                  <FOHSpace />
                  <>
                    {hasZipRecruiter === undefined ? (
                      <></>
                    ) : isCampaignActive || isCampaignCompleted ? (
                      <FOHView style={{ flexDirection: 'row' }}>
                        <FOHView>
                          <CampaignScheduleView
                            headerLabel={t('campaignSchedule')}
                            startDate={startDate}
                            endDate={endDate}
                            startDateLabel={t('startDateLabel')}
                            endDateLabel={t('endDateLabel')}
                          />
                          <FOHSpace />
                          <CampaignBudgetView
                            amount={(amount || 0) / 100}
                            budget={(spent || 0) / 100}
                            headerLabel={t('totalBudget')}
                            spendLabel={t('spendLabel')}
                            platform={campaignPlatform}
                          />
                        </FOHView>
                        <FOHSpace />
                        {!hasZipRecruiter && (
                          <CampaignResultView
                            headerLabel={t('campaignResult')}
                            clicks={clicks}
                            applies={applies}
                            avgCpa={avgCpa}
                            clickLabel={t('clicks')}
                            applyLabel={t('applies')}
                            avgCPALabel={t('avgCpa')}
                          />
                        )}
                      </FOHView>
                    ) : !hasZipRecruiter ? (
                      <FOHView
                        style={{
                          zIndex: 3,
                          display: isEditCampaignName ? 'none' : 'block',
                          pointerEvents: isCampaignActive ? 'none' : 'unset',
                          opacity: isCampaignActive ? 0.5 : 1
                        }}
                      >
                        <CampaignSchedule
                          campaignType={state.campaignType}
                          setCampaignType={(campaignType: any) =>
                            setState({ ...state, campaignType })
                          }
                          startDate={startDate}
                          setStartDate={date => {
                            if (endDate && date < endDate) {
                              setStartDate(date);
                            } else {
                              setStartDate(date);
                              const endOn = new Date(date);
                              endOn.setDate(endOn.getDate() + 30);
                              setEndDate(endOn);
                            }
                          }}
                          endDate={endDate}
                          setEndDate={setEndDate}
                          headerLabel={t('campaignScheduleHeader')}
                          subHeaderLabel={t('campaignScheduleSubHeader')}
                          oneTimeCampaignLabel={t('oneTimeCampaignLabel')}
                          monthlyCampaignLabel={t('monthlyCampaignLabel')}
                          startDateLabel={t('startDateLabel')}
                          endDateLabel={t('endDateLabel')}
                        />
                        <FOHSpace />
                        <FOHDivider />
                        <FOHSpace />
                        <CampaignBudget
                          setBudgetError={setMinBudgetError}
                          budgetError={minBudgetError}
                          budget={budget}
                          setBudget={setBudget}
                          headerLabel={t('CampaignBudgetHeader')}
                          subHeaderLabel={t('CampaignBudgetSubHeader')}
                        />
                        <FOHSpace />
                        <FOHDivider />
                      </FOHView>
                    ) : (
                      <FOHView
                        style={{
                          zIndex: 3
                        }}
                      >
                        <FOHView
                          style={{
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start'
                          }}
                        >
                          <FOHLabelSmall
                            style={{
                              fontWeight: '600'
                            }}
                          >
                            {t('CampaignBudgetHeader')}
                          </FOHLabelSmall>
                          <FOHSpaceSmall />
                          <FOHView
                            style={{
                              flexDirection: 'row',
                              alignItems: 'flex-start',
                              justifyContent: 'space-between',
                              flexWrap: 'wrap',
                              display: 'flex'
                            }}
                          >
                            <FOHSmallDetailLabel
                              style={{
                                lineHeight: 24,
                                width: '65%'
                              }}
                            >
                              {t(
                                'JobBoardCampaignFeature:extraBudgetDetailLabel'
                              )}
                            </FOHSmallDetailLabel>
                            <FOHMoneySelector
                              max={20000000}
                              min={defaultAmount}
                              default={defaultAmount}
                              value={(amount && amount / 100) || 0}
                              // in dollars
                              unityToChangeBy={amountIncrementBy}
                              onNumChange={setZipAmount}
                            />
                          </FOHView>
                        </FOHView>
                      </FOHView>
                    )}
                  </>
                  {isEditCampaignName && (
                    <CampaignNamePosition
                      campaignId={campaignId!}
                      campaignTitle={campaignTitle}
                      locationId={locationId as string}
                      positionId={positionId as string}
                      isEditCampaignName={isEditCampaignName}
                      setIsEditCampaignName={setIsEditCampaignName}
                      isCampaignActive={isCampaignActive as boolean}
                    />
                  )}
                </FOHView>

                <FOHView style={{ marginTop: 40 }}>
                  <FOHSpaceLarger />
                  {isIndeedCampaign &&
                  !(isCampaignActive || isCampaignCompleted) ? (
                    budget !== '' && (
                      <CampaignRecommendationAndEstimator
                        campaignId={campaignId!}
                        setBudget={val => {
                          setBudget(`$${val}`);
                        }}
                        budget={parseFloat(
                          budget.replace('$', '').replace(',', '')
                        )}
                        endDate={endDate}
                        startDate={startDate}
                      />
                    )
                  ) : (
                    <FOHView
                      style={{
                        width: 375
                      }}
                    >
                      {isIndeedCampaign ? (
                        <FOHImage
                          source={{ uri: indeed_logo }}
                          style={{
                            height: 45,
                            width: 180
                          }}
                        />
                      ) : (
                        <FOHImage
                          source={{ uri: ziprecruiter_logo }}
                          style={{
                            height: 45,
                            width: 180
                          }}
                        />
                      )}
                    </FOHView>
                  )}
                </FOHView>
              </FOHView>
              <FOHSpaceHuge />
              <FOHSpaceHuge />
            </FOHView>

            <FOHView
              style={{
                justifyContent: 'flex-end',
                paddingBottom: 15,
                backgroundColor: FOHColors.WHITE,
                width: '100%',
                height: 72,
                flex: 1,
                zIndex: 1
              }}
            >
              <FOHDivider />
              <FOHSpace />
              <FOHView style={{ paddingHorizontal: 32 }}>
                <FOHView
                  style={{
                    flexDirection: 'row'
                  }}
                >
                  <FOHView
                    style={{
                      width: '30%'
                    }}
                  >
                    <FOHButtonBordered
                      color={FOHColors.WHITE}
                      style={{ maxWidth: 151 }}
                      title={
                        isNewCampaign
                          ? t('common:cancel')
                          : t('goBackToCampaignHome')
                      }
                      onPress={handleCancelPress}
                    />
                  </FOHView>
                  <FOHView
                    style={{
                      width: '70%',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      flexDirection: 'row'
                    }}
                  >
                    {isDraft ? (
                      <>
                        <FOHButtonBordered
                          style={{
                            maxWidth: 151
                          }}
                          title={t('saveAsDraftLabel')}
                          onPress={handleSaveAsDraftPress}
                        />
                        <FOHSpace />
                        <FOHButton
                          style={{
                            maxWidth: 161
                          }}
                          color={
                            isIndeedCampaign
                              ? FOHColors.DARK_BLUE
                              : FOHColors.PRIMARY_TEAL_P1
                          }
                          title={
                            isIndeedCampaign
                              ? t('sponsorOnIndeedLabel')
                              : t('reviewCampaignLabel')
                          }
                          disabled={!hasZipRecruiter && minBudgetError}
                          onPress={() =>
                            navigateTo(
                              `${CAMPAIGN_HOME}/${campaignId}/confirm-start`
                            )
                          }
                        />
                        <FOHSpace />
                      </>
                    ) : isCampaignActive &&
                      !isCampaignCompleted &&
                      !hasZipRecruiter ? (
                      <>
                        <FOHButton
                          style={{
                            maxWidth: 151
                          }}
                          title={t('endCampaignLabel')}
                          onPress={() => {
                            navigateTo(
                              `${CAMPAIGN_HOME}/${campaignId}/confirm-end`
                            );
                          }}
                          color={FOHColors.RED}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </FOHView>
                </FOHView>
              </FOHView>
            </FOHView>
          </>
        )}
      </FOHView>
      <CampaignConfirmationModal
        confirmType="start"
        open={modal === 'confirm-start'}
        close={() => {
          goBack();
        }}
        onPress={handleStartCampaignPress}
        platformFor={campaignPlatform}
        t={t}
        modalText={{
          headerLabel: t('startConfirmationHeader'),
          indeedDetailLabel: t('startIndeedConfirmationDetail'),
          zipDetailLabel: t('startZipRecruiterConfirmationDetail'),
          buttonLabel: t('reviewCampaignLabel')
        }}
        style={{
          maxWidth: 664
        }}
      />
      <CampaignConfirmationModal
        confirmType="end"
        open={modal === 'confirm-end'}
        close={() => {
          goBack();
        }}
        onPress={handleEndCampaignPress}
        platformFor={campaignPlatform}
        t={t}
        modalText={{
          headerLabel: t('endConfirmationHeader'),
          indeedDetailLabel: t('endIndeedConfirmationDetail'),
          buttonLabel: t('endCampaignLabel')
        }}
        style={{
          maxWidth: 664
        }}
      />
    </FOHScrollView>
  );
};

export default withStripe(CampaignDetails);
