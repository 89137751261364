import * as amplitude from '@amplitude/analytics-browser';
import { defaultTo, path } from 'ramda';

import { ENVIRONMENT, envVariables } from '../constants';
import { useGetMeQuery } from '../graphql/generated';

interface TrackEventOptions {
  [key: string]: any;
}

interface UserAndAccountIds {
  user_id: string | null | undefined;
  account_id: string | null | undefined;
}

interface TrackAmplitudeEventHook {
  logEventMetrics: (
    eventName: string,
    eventProperties?: TrackEventOptions,
    eventOptions?: TrackEventOptions
  ) => void;
  resetAmplitudeState: () => void;
  initializeAmplitude: () => void;
  trackingUserDetail: UserAndAccountIds;
}

export const AmplitudeTrackingEvents = {
  PAGE_VIEWED: {
    EVENT_NAME: 'Page Viewed',
    EVENT_TYPE: 'page_viewed'
  },
  NAVIGATION_ITEM_CLICKED: {
    EVENT_NAME: 'Navigation Item Clicked',
    EVENT_TYPE: 'navigation_item_clicked'
  },
  STAGE_VIEWED: {
    EVENT_NAME: 'Stage Viewed',
    EVENT_TYPE: 'stage_viewed'
  },
  CANDIDATE_INVITED: {
    EVENT_NAME: 'Candidate Invited',
    EVENT_TYPE: 'candidate_invited'
  },
  MOVE_CANDIDATE_CLICKED: {
    EVENT_NAME: 'Move Candidate Clicked',
    EVENT_TYPE: 'move_candidate_clicked'
  },
  FEED_SORTBY_CHANGED: {
    EVENT_NAME: 'Feed Sortby Changed',
    EVENT_TYPE: 'feed_sortby_changed'
  },
  AI_EVALUATION_MODAL_VIEWED: {
    EVENT_NAME: 'Modal Viewed',
    EVENT_TYPE: 'modal_viewed'
  },
  AI_EVALUATION_BANNER_CTA_CLICKED: {
    EVENT_NAME: 'Banner CTA Clicked',
    EVENT_TYPE: 'banner_cta_clicked'
  }
};

export const useTrackAmplitudeEvent = (): TrackAmplitudeEventHook => {
  const meQuery = useGetMeQuery({});

  const userId = defaultTo(null, path(['data', 'me', 'id'], meQuery));
  const employerProfileId = defaultTo(
    null,
    path(['data', 'me', 'employerProfile', 'id'], meQuery)
  );

  const trackingUserDetail = {
    user_id: userId,
    account_id: employerProfileId
  };

  const logEventMetrics = (
    eventName: string,
    eventProperties: TrackEventOptions = {},
    eventOptions: TrackEventOptions = {}
  ) => {
    if (amplitude) {
      try {
        amplitude.track(
          eventName,
          { ...eventProperties, ...trackingUserDetail },
          { ...eventOptions, user_id: trackingUserDetail?.user_id || undefined }
        );
      } catch (error) {
        console.error('Error logging event to Amplitude:', error);
      }
    }
  };

  const resetAmplitudeState = () => amplitude.reset();

  const initializeAmplitude = () => {
    if (['production', 'stage'].includes(ENVIRONMENT)) {
      try {
        amplitude.init(envVariables.AMPLITUDE_API_KEY as string, {
          defaultTracking: false
        });
      } catch (error) {
        console.error('Error initializing Amplitude:', error);
      }
    }
  };

  return {
    logEventMetrics,
    resetAmplitudeState,
    initializeAmplitude,
    trackingUserDetail
  };
};
