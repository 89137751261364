import { get, post, destroy } from './index';

export const getStripeSession = ({
  url,
  success_url = null,
  cancel_url = null,
  positions,
  locations,
  plan,
  cycle,
  daysToBoost,
  campaignId = ''
}) => {
  return url || success_url
    ? get(`stripe`, {
        query: {
          success_url: url || success_url,
          cancel_url: cancel_url || undefined,
          positions, // array of ids
          locations, //array of ids
          plan, // payasyougo || unlimited
          cycle, // monthly || annual
          'days-to-boost': daysToBoost,
          campaign_id: campaignId
        }
      })
    : get(`stripe`);
};

export const getStripeCustomerPortal = ({ location, customer = '' }) => {
  return get(`stripe/customer`, {
    query: {
      location, // id
      customer
    }
  });
};
// reactivates plan if billing cycle has not ended.
export const reactivatePlan = () => {
  return post(`stripe`, {});
};
// deactivate plan for when next billing cycle hits.
export const deactivatePlan = () => {
  return destroy(`stripe`, {});
};
