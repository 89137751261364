import React from 'react';

import styled from 'styled-components/native';
import { View } from 'react-native';

import { FOHContrastText } from '../../ingredients';

export interface FOHPillProps {
  label: string;
  active?: boolean;
}

export const FOHPill = (props: FOHPillProps) => (
  <Pill style={{ backgroundColor: props.active ? '#38a56a' : '#C4C4C4' }}>
    <FOHContrastText
      style={{
        textAlign: 'center',
        width: '100%'
      }}
    >
      {props.label}
    </FOHContrastText>
  </Pill>
);

const Pill = styled(View)`
  border-radius: 24px;
  height: 30px;
  padding-left: 12px;
  padding-right: 12px;
  justify-content: center;
  margin-left: 4px;
  margin-top: 4px;
`;
