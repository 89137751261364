import React from 'react';

import { StripeProvider, Elements, injectStripe } from 'react-stripe-elements';
import { compose } from 'ramda';

import { envVariables } from '../../constants';

export const withStripeElements = Cmp => props => {
  return (
    <StripeProvider apiKey={envVariables.STRIPE_PUBLISHABLE_KEY}>
      <Elements>
        <Cmp {...props}>{props.children}</Cmp>
      </Elements>
    </StripeProvider>
  );
};

export const withStripe = compose(withStripeElements, injectStripe);
