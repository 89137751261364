import React from 'react';

import notificationDefaultChat from '../../../images/notification-default-chat.svg';

import { FOHImage } from '../../ingredients';

export interface FOHNotificationDefaultChatProps {}

export const FOHNotificationDefaultChat = (
  props: FOHNotificationDefaultChatProps
) => (
  <FOHImage
    {...props}
    source={{ uri: notificationDefaultChat }}
    style={{ width: 24, height: 24 }}
  />
);
